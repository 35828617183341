import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import { Order } from '@app/crud/apv/order.type';

import ROUTES from '@app/router/Routes';

interface CardInformationsInvoiceProps {
    order: Partial<Order>;
}

const formatContactInfo = (mobile: string | undefined, phone: string | undefined) => mobile || phone || '-';

const formatAddress = (address: string | undefined, zipcode: string | undefined, city: string | undefined) =>
    address ? `${address}, ${zipcode} ${city}` : '-';

const CardInformationsInvoice = ({ order }: CardInformationsInvoiceProps) => {
    const Intl = useIntl();

    const dataFields = [
        { labelId: 'APV.ORDER.VIEW.NAME', value: order.lastname },
        { labelId: 'APV.ORDER.VIEW.FIRSTNAME', value: order.firstname },
        { labelId: 'APV.ORDER.VIEW.LOGIN', value: order.autosphereId ? `Autosphere ${order.autosphereId}` : '-' },
        { labelId: 'APV.ORDER.VIEW.MAIL', value: order.email },
        { labelId: 'APV.ORDER.VIEW.MOBILE', value: formatContactInfo(order.mobile, order.phone) },
        { labelId: 'APV.ORDER.VIEW.ADDRESS', value: formatAddress(order.address, order.zipcode, order.city) },
    ];

    const renderButton = () => {
        if (order?.appointment) {
            return (
                <Link
                    to={routeTo(ROUTES.CUSTOMER.PATH, {
                        id: order.automanagerId,
                    })}
                >
                    <Button className="p-2 px-3" variant="outline-light">
                        {Intl.formatMessage({ id: 'DASHBOARD.CALENDAR.TIMELINE.VIEW_CLIENT_PAGE' })}
                    </Button>
                </Link>
            );
        }
        return null;
    };

    return (
        <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
                <div>
                    <i className="card__icon fas fa-file-invoice" />
                    {Intl.formatMessage({ id: 'APV.ORDER.VIEW.BILLING' })}
                </div>
                <div>{renderButton()}</div>
            </Card.Header>
            <Card.Body>
                {dataFields.map(({ labelId, value }) => (
                    <Row className="mb-4" key={labelId}>
                        <Col lg={6}>{Intl.formatMessage({ id: labelId })} :</Col>
                        <Col className="font-weight-semibold" lg={6}>
                            {value}
                        </Col>
                    </Row>
                ))}
            </Card.Body>
        </Card>
    );
};

export default CardInformationsInvoice;
