import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Intl } from '../../../../../_metronic/i18n/I18nProvider';
import Editor from '../../../../partials/content/Editor';

function QuotationGeneralComment({ quotation }) {
    return (
        <Row>
            <Col lg={12} className="mt-9">
                <h5>{Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.COMMENT.GENERAL' })}</h5>
                <Editor
                    value={
                        quotation?.comment !== ''
                            ? quotation?.comment
                            : Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.COMMENT' })
                    }
                    className="smart-repair-card__info-text mb-5 pt-5 pl-2"
                    config={{
                        minHeight: 25,
                        buttons: '',
                        statusbar: false,
                        readonly: true,
                        inline: true,
                    }}
                />
            </Col>
        </Row>
    );
}

export default QuotationGeneralComment;
