import { Intl } from '@src/_metronic/i18n/I18nProvider';

import toast from '@app/partials/content/Toast';

export const actionTypes = {
    AddPermission: 'ADD_PERMISSION',
    AddPermissionFailed: 'ADD_PERMISSION_FAILED',
    AddedPermission: 'ADDED_PERMISSION',
    UpdatePermission: 'UPDATE_PERMISSION',
    UpdatedPermission: 'UPDATED_PERMISSION',
    UpdatePermissionFailed: 'UPDATE_PERMISSION_FAILED',
    RemovePermission: 'REMOVE_PERMISSION',
    RemovedPermission: 'REMOVED_PERMISSION',
    RemovePermissionFailed: 'REMOVE_PERMISSION_FAILED',
};

const initialState = {
    loading: false,
    categories: [],
    parents: [],
    totalRecords: 0,
    segments: [],
    category: null,
    error: null,
};

export function permissionReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.AddPermission:
        case actionTypes.RemovePermission:
        case actionTypes.UpdatePermission:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.FailedPermission:
        case actionTypes.RemoveFailedPermission:
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'AUTOUSER.PERMISSIONS.IMPORT.FAILED' }),
            });
            return {
                initialState,
                loading: false,
            };
        case actionTypes.AddedPermission:
        case actionTypes.UpdatedPermission:
        case actionTypes.RemovedPermission:
            toast({
                variant: 'success',
                message: Intl.formatMessage({ id: 'AUTOUSER.PERMISSIONS.IMPORT.SUCCESS' }),
            });
            return {
                initialState,
                loading: false,
            };
        default:
            return {
                ...state,
                loading: false,
            };
    }
}

export const actions = {
    createPermission: (id, data) => ({ type: actionTypes.AddPermission, payload: { id, data } }),
    updatePermission: (id, data) => ({ type: actionTypes.UpdatePermission, payload: { id, data } }),
    addPermissionFailed: (error) => ({ type: actionTypes.AddPermissionFailed, payload: error }),
    addedPermission: (result) => ({ type: actionTypes.AddedPermission, payload: result }),
    updatedPermission: (result) => ({ type: actionTypes.UpdatedPermission, payload: result }),
    updatePermissionFailed: (error) => ({ type: actionTypes.UpdatePermissionFailed, payload: error }),
    removePermission: () => ({ type: actionTypes.RemovePermission }),
    removedPermission: (result) => ({ type: actionTypes.RemovedPermission, payload: result }),
    removePermissionFailed: (error) => ({ type: actionTypes.RemovePermissionFailed, payload: error }),
};
