import React from 'react';
import {Redirect, useLocation} from "react-router-dom";
import toast from "../content/Toast";
import {Intl} from "../../../_metronic/i18n/I18nProvider";

function ErrorFallback({prevLocationPath, resetErrorBoundary}) {
    const location = useLocation();

    toast({
        variant: 'danger',
        message: Intl.formatMessage({id: 'TRANSLATOR.ERROR'})
    });

    resetErrorBoundary();

    return (
        <Redirect to={prevLocationPath && prevLocationPath !== location.pathname ? prevLocationPath : '/error'} />
    )
}

export default ErrorFallback;
