import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Button, Card, Col, Image, Row } from 'react-bootstrap';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFilters from '@app/hooks/useFilters';
import useModal from '@app/hooks/useModal';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { getUsers, getUsersFromAd } from '@app/crud/autouser/user.crud';

import { useAppSelector } from '@app/hooks';
import ManageUserPartner from '@app/pages/autouser/modals/ManageUserPartner';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';
import SearchInput from '@app/partials/content/SearchInput';

import ManageUser from '../modals/ManageUser';

import ExternalSwitch from './Partials/ExternalSwitch';
import UsersDataTable from './UsersDataTable';

type UsersProps = {
    addToDealership?: boolean;
};

const Users = ({ addToDealership }: UsersProps) => {
    const { userData } = useAppSelector((state) => state.auth);
    const [isInternal, setIsInternal] = useState(
        addToDealership ? !userData?.external || userData?.role?.slug === 'super-administrateur' : false,
    );
    const [doSearch, setDoSearch] = useState(false);
    const [showManageUser, toggleManageUser] = useModal(false, 'manage_user');
    const [showManageUserPartner, toggleManageUserPartner] = useModal(false, 'manage_user_partner');
    const { items, start, filters, sortField, sortOrder, updatePendingFilters, releasePendingFilters } = useFilters({
        name: 'autouser_users_search',
        persisted: false,
    });

    const extraParams = useMemo(
        () => ({
            search: isInternal,
            doSearch,
        }),
        [isInternal, doSearch],
    );

    const { data, error, loading, handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: 'autouser_users_search',
        // @ts-ignore
        fetchAction: isInternal ? getUsersFromAd : getUsers,
        withStore: false,
        extraParams,
        // @ts-ignore
        doSearch: (params, _extraParams) => _extraParams?.doSearch,
        // @ts-ignore
        paramsInterceptor: (params, _filters, _extraParams) => {
            if (_extraParams?.search) {
                return {
                    ...params,
                    q: params?.research || '',
                };
            }
            return {
                ...params,
                start: _filters.start,
                limit: _filters.items,
            };
        },
    });

    const handleSearchRefresh = () => {
        setDoSearch(true);
        refresh();
    };

    const users = data?.result || null;

    return (
        <FiltersProvider value={filters}>
            <Card
                className={classNames({
                    'border-0': addToDealership,
                })}
                id="first-tour"
            >
                {loading && (!users || users?.length === 0) && (
                    <Loader style={{ width: '5rem', height: '5rem' }} overlay />
                )}
                {users && !error ? (
                    <Card.Body className="text-center">
                        <Row>
                            <Col lg={2}>
                                {!loading &&
                                    (isInternal ? (
                                        <Image
                                            className="mt-10 found-customers"
                                            src={`/media/pages/customers/${
                                                users?.length === 0
                                                    ? 'utilisateur-interne-non-trouve.svg'
                                                    : 'utilisateur-interne-trouve.svg'
                                            }`}
                                        />
                                    ) : (
                                        <Image
                                            className="mt-10 found-customers"
                                            src={`/media/pages/customers/${
                                                users?.length === 0
                                                    ? 'utilisateur-externe-non-trouve.svg'
                                                    : 'utilisateur-externe-trouve.svg'
                                            }`}
                                        />
                                    ))}
                            </Col>
                            <Col xl={{ span: 6, offset: 1 }} lg={{ span: 7, offset: 2 }}>
                                <div className="d-flex flex-column align-items-center">
                                    {/* @ts-ignore */}
                                    <ExternalSwitch
                                        className="mt-0 mb-5"
                                        isInternal={isInternal}
                                        setIsInternal={setIsInternal}
                                        hidden={
                                            addToDealership &&
                                            userData?.external &&
                                            userData?.role?.slug !== 'super-administrateur'
                                        }
                                        labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.AUTOMANAGER' })}
                                    />
                                    <SearchInput
                                        onChange={handleFilter}
                                        refresh={handleSearchRefresh}
                                        className="w-100 flex-grow-1"
                                        name="research"
                                        placeholder={Intl.formatMessage({
                                            id: 'AUTOUSER.USERS.TABLE.VIEW.RESEARCH.PLACEHOLDER',
                                        })}
                                    />
                                </div>
                                <div style={{ height: '120px' }}>
                                    {!loading && (
                                        <div className="mb-5 font-size-h4">
                                            <div>
                                                {Intl.formatMessage(
                                                    { id: 'AUTOUSER.USERS.TABLE.VIEW.FOUND.NUMBER' },
                                                    {
                                                        count: data?.count,
                                                        research: filters?.research?.value
                                                            ? `« ${filters?.research?.value} »`
                                                            : '',
                                                    },
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {!isInternal && (
                                        <Permission permissions={['AUTOUSER_CREATE_USER']}>
                                            <Button variant="outline-primary" onClick={() => toggleManageUser()}>
                                                {Intl.formatMessage({ id: 'AUTOUSER.USERS.CREATE' })}
                                            </Button>
                                        </Permission>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <UsersDataTable
                            // @ts-ignore
                            users={users}
                            totalRecords={data?.count || 0}
                            handleFilter={handleFilter}
                            updatePendingFilters={updatePendingFilters}
                            releasePendingFilters={releasePendingFilters}
                            handlePagination={handlePagination}
                            handleSort={handleSort}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            loading={loading}
                            items={items}
                            start={start}
                            filters={filters}
                            refresh={refresh}
                            addToDealership={addToDealership}
                            isInternal={isInternal}
                        />
                    </Card.Body>
                ) : (
                    <Card.Body
                        className={classNames('text-center', {
                            'mt-10': !addToDealership,
                        })}
                    >
                        <div className="font-size-h4 font-weight-bold mb-8 d-flex align-items-center justify-content-center">
                            {Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.VIEW.START' })}
                            {/* @ts-ignore */}
                            <ExternalSwitch
                                className="ml-2 my-0"
                                hidden={
                                    addToDealership &&
                                    userData?.external &&
                                    userData?.role?.slug !== 'super-administrateur'
                                }
                                isInternal={isInternal}
                                setIsInternal={setIsInternal}
                            />
                        </div>
                        <Col lg={{ span: 6, offset: 3 }}>
                            <SearchInput
                                onChange={handleFilter}
                                refresh={handleSearchRefresh}
                                name="research"
                                placeholder={Intl.formatMessage({
                                    id: 'AUTOUSER.USERS.TABLE.VIEW.RESEARCH.PLACEHOLDER',
                                })}
                            />
                            {isInternal ? (
                                <Image src="/media/pages/customers/utilisateur-interne.svg" className="mt-10" />
                            ) : (
                                <Image src="/media/pages/customers/utilisateur-externe.svg" className="mt-10" />
                            )}
                        </Col>
                    </Card.Body>
                )}
            </Card>
            {!addToDealership && (
                <ManageUser showModal={showManageUser} setShowModal={toggleManageUser} refresh={refresh} />
            )}
            <ManageUserPartner showModal={showManageUserPartner} setShowModal={toggleManageUserPartner} />
        </FiltersProvider>
    );
};

Users.defaultProps = {
    addToDealership: false,
};

export default Users;
