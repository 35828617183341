const reduceRollbackField = (fields) =>
    fields.reduce((acc, field) => {
        const histoField = field?.histoField;
        const label = histoField?.name.includes('consent') ? histoField.name : null;
        acc[histoField?.slug] = {
            label,
            field: histoField?.slug,
            old_value: field?.newValue,
            new_value: field?.oldValue,
        };
        return acc;
    }, {});

export default reduceRollbackField;
