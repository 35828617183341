import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';

import useFetch from '@app/hooks/useFetch';

import { getDashboardPerfomancesDetails } from '@app/crud/dashboard/dashboard.crud';

import DashboardOnlineStrongStats from '@app/pages/dashboard/partials/Stats/DashboardOnlineStrongStats';

import Permission from '@app/partials/content/Permission';

import { DashboardContext } from '../../DashboardProvider';
import { DashboardRanking } from '../Ranking';

import DashboardGeneralPerf from './DashboardGeneralPerf';
import DashboardLocalPerf from './DashboardLocalPerf';
import DashboardMarketingCampaignPerf from './DashboardMarketingCampaignPerf';
import DashboardOnlinePerf from './DashboardOnlinePerf';
import DashboardStock from './DashboardStock';

const DashboardStats = ({ className }) => {
    const { role, overriddenParams } = useContext(DashboardContext);

    const { data, loading, fetch } = useFetch({
        fetchAction: getDashboardPerfomancesDetails,
    });

    useEffect(() => {
        if (overriddenParams) {
            fetch({ params: overriddenParams });
        }
    }, [overriddenParams]);

    return (
        <div className={classNames('dashboard-stats d-flex flex-column', className)}>
            <div className="d-flex flex-lg-row flex-column">
                <div className="dashboard-stats__perf h-100 d-flex flex-column flex-lg-grow-1">
                    <DashboardGeneralPerf className="flex-lg-grow-1 mt-lg-2 mt-6" />
                    {loading ? (
                        <>
                            <DashboardOnlinePerf.Skeleton />
                            <DashboardOnlineStrongStats.Skeleton />
                        </>
                    ) : (
                        <>
                            <DashboardOnlinePerf data={data} className="flex-lg-grow-1 mt-lg-2 mt-6" />
                            <DashboardOnlineStrongStats data={data} className="flex-lg-grow-1 mt-lg-2 mt-6" />
                        </>
                    )}
                    {/* Disabled temporary waiting stats fix */}
                    {/* <DashboardProcessedLead className="flex-basis-0 flex-grow-1 flex-shrink-1 mt-lg-2 mt-6" /> */}
                </div>
                {!['directeur', 'responsable-marketing'].includes(role) && (
                    <div className="flex-basis-0 ml-lg-3 ml-0 mt-lg-0 mt-6 d-flex flex-column">
                        <DashboardRanking className="mt-2 flex-grow-1" />
                    </div>
                )}
            </div>
            <div className="d-flex flex-column">
                <div className="flex-grow-1 d-flex flex-lg-row flex-column">
                    {loading ? (
                        <DashboardLocalPerf.Skeleton />
                    ) : (
                        <DashboardLocalPerf data={data} className="mt-lg-2 mt-6 flex-grow-1 mr-2" />
                    )}
                    {loading ? (
                        <DashboardMarketingCampaignPerf.Skeleton />
                    ) : (
                        <DashboardMarketingCampaignPerf data={data} className="mt-lg-2 mt-6 flex-grow-1" />
                    )}
                </div>
                <Permission permissions={['STOCK_LIST_VEHICLES']}>
                    <DashboardStock className="flex-grow-1 mt-lg-2 mt-6" />
                </Permission>
            </div>
        </div>
    );
};

export default DashboardStats;
