import { RadioButton } from 'primereact/radiobutton';
import React, { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import ErrorForm from '@app/partials/layout/ErrorForm';

interface FormCivilityProps {
    label: ReactElement;
    name: string;
    required: boolean;
    disabled?: boolean;
}
const FormCivility = ({ label, name, required, disabled }: FormCivilityProps) => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { control, errors } = methods;

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Controller
                control={control}
                name={name}
                rules={{
                    required: required && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                }}
                render={({ value, onChange }) => (
                    <div className="d-flex flex-wrap gap-6">
                        <div className="d-flex align-items-center">
                            <RadioButton
                                inputId="mme"
                                value="Mme"
                                onChange={(e) => onChange(e.value)}
                                checked={value === 'Mme'}
                                disabled={disabled}
                            />
                            <label htmlFor="mme" className="ml-2 my-0">
                                <FormattedHTMLMessage id="TRANSLATOR.MRS" />
                            </label>
                        </div>
                        <div className="d-flex align-items-center">
                            <RadioButton
                                inputId="mr"
                                value="Mr"
                                onChange={(e) => onChange(e.value)}
                                checked={value === 'Mr'}
                                disabled={disabled}
                            />
                            <label htmlFor="mr" className="ml-2 my-0">
                                <FormattedHTMLMessage id="TRANSLATOR.MR" />
                            </label>
                        </div>
                    </div>
                )}
            />
            <ErrorForm errors={errors} name={name} />
        </Form.Group>
    );
};

FormCivility.defaultProps = {
    disabled: false,
};

export default FormCivility;
