import classNames from 'classnames';
import dayjs from 'dayjs';
import { Divider } from 'primereact/divider';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import KTUtil from '@src/_metronic/_assets/js/util';
import { Locale } from '@src/_metronic/i18n/I18nProvider';

import { getDateByFormat } from '@app/helpers/DateHelper';
import { htmlToString } from '@app/helpers/StringHelper';
import { empty } from '@app/helpers/ToolsHelper';

import { Agenda } from '@app/pages/agenda';
import useAction from '@app/pages/customers/Hooks/useAction';
import useLead from '@app/pages/customers/Hooks/useLead';
import CarSearch from '@app/pages/customers/Partials/CarSearch';
import DealerSelect from '@app/pages/customers/Partials/DealerSelect';
import DealershipSelect from '@app/pages/customers/Partials/DealershipSelect';
import LeadTypeSelect from '@app/pages/customers/Partials/LeadTypeSelect';
import { actions } from '@app/store/modal/modal.store';

import EventCaption from '@app/partials/content/Agenda/Event.Caption';
import { DateTimePicker } from '@app/partials/content/DateTimePicker';
import Editor from '@app/partials/content/Editor';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import Permission from '@app/partials/content/Permission';
import SwitchInput from '@app/partials/content/SwitchInput';
import toast from '@app/partials/content/Toast';
import { formatLeadVehicles } from '@app/partials/content/Vehicle/VehicleHelpers';
import ErrorForm from '@app/partials/layout/ErrorForm';

import customerAppointmentNotification from '../Helpers/Notification';

const Appointment = ({ showModal, setShowModal, disabledFields = [], defaultValues = {}, onSubmitOverride = null }) => {
    const methods = useForm();
    const dispatch = useDispatch();
    const calendar = useRef();
    const { register, getValues, setValue, reset, errors, handleSubmit, control, watch } = methods;
    const { params } = useSelector((state) => state.modal);
    const { data: customer } = useSelector((state) => state.customer.customer);
    const { userData } = useSelector((state) => state.auth);
    const [dealerSelected, setDealerSelected] = useState((defaultValues?.vendors && defaultValues.vendors[0]) || null);
    const currentLead = useLead(params?.leadId);
    const scheduleType = watch('scheduleType');
    const intl = useIntl();
    const [loading, createAction, setActionType] = useAction({
        type: 'appointment',
        success: () => {
            setShowModal(false);
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: intl.formatMessage({ id: 'CUSTOMERS.MODAL.APPOINTMENT.ERROR' }),
            });
        },
    });
    const [datesSetSelected, setDatesSetSelected] = useState(null);

    const onSubmit = async (data) => {
        if (onSubmitOverride) {
            onSubmitOverride(data);
            setShowModal(false);
            return;
        }
        const notifyCustomer = await customerAppointmentNotification(customer?.email);

        if (currentLead?.scheduleAppointment) {
            data.scheduleAppointmentId = currentLead.scheduleAppointment.id;
        }

        if (userData.id !== dealerSelected?.id) {
            data.dealer = dealerSelected;
        } else delete data.dealer;

        createAction({
            ...data,
            notifyCustomer,
        });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const handleClose = () => {
        if (params?.leadId) {
            dispatch(actions.rollBackModal());
        } else {
            setShowModal(false);
        }
    };

    useEffect(() => {
        if (params?.scheduleAppointmentId) {
            setActionType('update_appointment');
        } else {
            setActionType('appointment');
        }
    }, [setActionType, params]);

    const getScheduleType = useCallback((lead) => {
        if (lead.scheduleAppointment.isHomeTrial) {
            return 'appointment_home_trial';
        } else if (lead.scheduleAppointment.isPhysical) {
            return 'appointment_dealership';
        }
        return 'appointment_call';
    }, []);

    const getSubjectDefault = (type) => {
        let subject;
        switch (type) {
            case 'appointment_home_trial':
                subject = intl.formatMessage(
                    { id: 'CUSTOMERS.MODAL.APPOINTMENT.SUBJECT.DEFAULT.TRY' },
                    { client: `${customer.lastname || ''} ${customer.firstname || ''}` },
                );
                break;
            case 'appointment_call':
                subject = intl.formatMessage(
                    { id: 'CUSTOMERS.MODAL.APPOINTMENT.SUBJECT.DEFAULT.CALL' },
                    { client: `${customer.lastname || ''} ${customer.firstname || ''}` },
                );
                break;
            default:
                subject = intl.formatMessage(
                    { id: 'CUSTOMERS.MODAL.APPOINTMENT.SUBJECT.DEFAULT.APPOINTMENT' },
                    { client: `${customer.lastname || ''} ${customer.firstname || ''}` },
                );
                break;
        }
        return subject;
    };

    useEffect(() => {
        if (!empty(scheduleType)) {
            setValue('subject', getSubjectDefault(scheduleType));
        }
    }, [scheduleType]);

    useEffect(() => {
        const date = dayjs();
        const defaultStart = date.minute((Math.round(date.minute() / 30) * 30) % 60);

        let values = {
            dateStart: defaultStart.format('YYYY-MM-DD HH:mm'),
            dateEnd: defaultStart.add(30, 'minute').format('YYYY-MM-DD HH:mm'),
        };

        if (currentLead?.scheduleAppointment) {
            values = {
                ...values,
                dateStart: dayjs(currentLead.scheduleAppointment.dateStart, 'YYYY-MM-DD HH:mm').format(
                    'YYYY-MM-DD HH:mm',
                ),
                dateEnd: dayjs(currentLead.scheduleAppointment.dateEnd, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'),
                comment: htmlToString(currentLead.scheduleAppointment.comment) || '',
                subject: currentLead.scheduleAppointment.subject,
                scheduleType: getScheduleType(currentLead),
            };
        }

        if (currentLead?.leadElements) {
            values = {
                ...values,
                vehicles: formatLeadVehicles(currentLead),
            };
        }

        if (!values?.subject && !empty(customer)) {
            values.subject = getSubjectDefault(values?.scheduleType);
        }

        reset(values);
    }, [getScheduleType, reset, currentLead]);

    useEffect(() => {
        if (calendar.current && getValues('dateStart') && getValues('dateEnd')) {
            const calendarApi = calendar.current.getApi();
            calendarApi.select(getValues('dateStart'), getValues('dateEnd'));
            calendarApi.setOption('height', '100%');
        }
    }, [calendar.current]);

    const isDisabled = (field) => disabledFields.includes(field);

    return (
        <ModalDefault
            size="xl"
            loading={loading}
            show={showModal}
            hideModal={handleClose}
            body={
                <FormProvider {...methods}>
                    <Row>
                        <Col lg={7}>
                            <Agenda
                                ref={calendar}
                                className="card--noborder d-lg-flex d-none"
                                bodyClassName="p-0"
                                selectable
                                onSelect={(e) => {
                                    setValue('dateStart', dayjs(e.start).format('YYYY-MM-DD HH:mm'));
                                    setValue('dateEnd', dayjs(e.end).format('YYYY-MM-DD HH:mm'));
                                }}
                                weekView={{
                                    titleFormatStart: 'D',
                                    titleFormatEnd: 'D MMMM YYYY',
                                    titleRangeSeparator: ' au ',
                                    dayHeaderFormat: 'ddd DD MMM',
                                }}
                                slotDuration="00:30:00"
                                defaultVendor={defaultValues?.vendors && defaultValues.vendors[0]}
                                canSelectUser={false}
                                setDatesSetSelected={setDatesSetSelected}
                            />
                        </Col>
                        <Col>
                            <div className="modal-title h4 text-center mb-5">
                                <div className="text-primary text-center">
                                    <div>
                                        <i className="la la-2x text-primary la-calendar" />
                                    </div>
                                    {params?.scheduleAppointmentId
                                        ? intl.formatMessage({ id: 'CUSTOMERS.MODAL.APPOINTMENT.TITLE.UPDATE' })
                                        : intl.formatMessage({ id: 'CUSTOMERS.MODAL.APPOINTMENT.TITLE.CREATE' })}
                                </div>
                            </div>
                            <Row className="mb-2">
                                <Col
                                    lg={6}
                                    className={classNames({
                                        'd-none': isDisabled('dealership'),
                                    })}
                                >
                                    <DealershipSelect methods={methods} customer={customer} currentLead={currentLead} />
                                </Col>
                                <Permission permissions={['LEAD_VIEW_TEAM_CALENDAR']}>
                                    <Col lg={`${isDisabled('dealership') ? 12 : 6}`}>
                                        <DealerSelect
                                            methods={methods}
                                            datesSetSelected={datesSetSelected}
                                            setDealerSelected={setDealerSelected}
                                            defaultVendors={defaultValues.vendors}
                                        />
                                    </Col>
                                </Permission>
                            </Row>

                            {!isDisabled('leadType') && (
                                <Row className="mb-2">
                                    <LeadTypeSelect methods={methods} currentLead={currentLead} />
                                </Row>
                            )}

                            <Row className="mb-2">
                                <Col lg={12}>
                                    <Form.Group>
                                        <Form.Label>
                                            {intl.formatMessage({ id: 'CUSTOMERS.MODAL.APPOINTMENT.SUBJECT' })}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="subject"
                                            className={`${errors.subject ? 'is-invalid' : ''}`}
                                            ref={register({
                                                required: intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                            })}
                                        />
                                        <ErrorForm errors={errors} name="subject" />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {!isDisabled('type') && (
                                <Row className="mb-2">
                                    <Col lg={12}>
                                        <Form.Group>
                                            <Form.Label>
                                                {intl.formatMessage({ id: 'CUSTOMERS.MODAL.APPOINTMENT.TYPE' })}
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="scheduleType"
                                                defaultValue="appointment_dealership"
                                                render={(props) => (
                                                    <div className="d-flex flex-lg-nowrap flex-wrap">
                                                        <SwitchInput
                                                            name="actionType_dealership"
                                                            className="w-lg-auto w-100 switch-input--active-label-right-secondary"
                                                            onChange={() => {
                                                                props.onChange('appointment_dealership');
                                                            }}
                                                            labelFirst=""
                                                            labelSecond={intl.formatMessage({
                                                                id: 'CUSTOMERS.MODAL.APPOINTMENT.APPOINTMENT',
                                                            })}
                                                            disabled={props.value === 'appointment_dealership'}
                                                            active={props.value === 'appointment_dealership'}
                                                            form={false}
                                                        />
                                                        <SwitchInput
                                                            name="actionType_call"
                                                            className="mt-lg-0 mt-4 ml-lg-5 ml-0 w-lg-auto w-100 switch-input--active-label-right-secondary"
                                                            onChange={() => {
                                                                props.onChange('appointment_call');
                                                            }}
                                                            labelFirst=""
                                                            labelSecond={intl.formatMessage({
                                                                id: 'CUSTOMERS.MODAL.APPOINTMENT.PHONE',
                                                            })}
                                                            disabled={props.value === 'appointment_call'}
                                                            active={props.value === 'appointment_call'}
                                                            form={false}
                                                        />
                                                        <SwitchInput
                                                            name="actionType_home_trial"
                                                            className="mt-lg-0 mt-4 ml-lg-5 ml-0 w-lg-auto w-100 switch-input--active-label-right-secondary"
                                                            onChange={() => {
                                                                props.onChange('appointment_home_trial');
                                                            }}
                                                            labelFirst=""
                                                            labelSecond={intl.formatMessage({
                                                                id: 'CUSTOMERS.MODAL.APPOINTMENT.TRY_HOME',
                                                            })}
                                                            disabled={props.value === 'appointment_home_trial'}
                                                            active={props.value === 'appointment_home_trial'}
                                                            form={false}
                                                        />
                                                    </div>
                                                )}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}
                            <Row className="mb-2">
                                <Col lg={6}>
                                    <Form.Group className="mt-5">
                                        <Form.Label>
                                            {intl.formatMessage({ id: 'CUSTOMERS.MODAL.APPOINTMENT.DATE_START' })}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="dateStart"
                                            rules={{
                                                required: intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                            }}
                                            render={(props) => (
                                                <DateTimePicker
                                                    locale={Locale}
                                                    dateFormat="DD/MM/YYYY HH:mm"
                                                    showTime
                                                    onChange={(e) => {
                                                        const startValue = dayjs(e.value).isValid()
                                                            ? dayjs(e.value)
                                                            : '';

                                                        if (startValue !== '') {
                                                            setValue(
                                                                'dateEnd',
                                                                startValue.add(30, 'minute').format('YYYY-MM-DD HH:mm'),
                                                            );
                                                            props.onChange(startValue.format('YYYY-MM-DD HH:mm'));
                                                        } else {
                                                            setValue('dateEnd', '');
                                                            props.onChange('');
                                                        }
                                                    }}
                                                    value={getDateByFormat(props.value, 'YYYY-MM-DD HH:mm', '')}
                                                />
                                            )}
                                        />
                                        <ErrorForm errors={errors} name="dateStart" />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mt-5">
                                        <Form.Label>
                                            {intl.formatMessage({ id: 'CUSTOMERS.MODAL.APPOINTMENT.DATE_END' })}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="dateEnd"
                                            rules={{
                                                required: intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                            }}
                                            render={(props) => (
                                                <DateTimePicker
                                                    locale={Locale}
                                                    dateFormat="DD/MM/YYYY HH:mm"
                                                    showTime
                                                    minDate={dayjs(getValues('dateStart'), 'YYYY-MM-DD HH:mm').toDate()}
                                                    showSeconds={false}
                                                    onChange={(e) => {
                                                        const value = dayjs(e.value).isValid() ? dayjs(e.value) : '';
                                                        if (value !== '') {
                                                            props.onChange(value.format('YYYY-MM-DD HH:mm'));
                                                        } else {
                                                            props.onChange('');
                                                        }
                                                    }}
                                                    value={getDateByFormat(props.value, 'YYYY-MM-DD HH:mm', '')}
                                                />
                                            )}
                                        />
                                        <ErrorForm errors={errors} name="dateEnd" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col lg={12}>
                                    <Form.Group>
                                        <Form.Label>
                                            {intl.formatMessage({ id: 'CUSTOMERS.MODAL.APPOINTMENT.COMMENT' })}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="comment"
                                            defaultValue=""
                                            render={(props) => (
                                                <Editor
                                                    value={props.value}
                                                    className={`${errors.comment ? 'is-invalid' : ''}`}
                                                    onChange={props.onChange}
                                                    config={{
                                                        minHeight: 50,
                                                        buttons: '',
                                                        statusbar: false,
                                                    }}
                                                />
                                            )}
                                        />
                                        <ErrorForm errors={errors} name="comment" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {!isDisabled('carSearch') && (
                                <>
                                    <Divider className="my-5" type="dashed" />
                                    <CarSearch />
                                </>
                            )}
                        </Col>
                    </Row>
                </FormProvider>
            }
            footer={
                <div className="d-flex align-items-center justify-content-between">
                    {!KTUtil.isMobileDevice() && (
                        <div>
                            <EventCaption />
                        </div>
                    )}
                    <div>
                        <Button variant="outline-secondary" onClick={handleClose} className="mr-5">
                            {intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                        </Button>
                        <Button variant="primary" onClick={handleSubmit(onSubmit, onError)}>
                            {intl.formatMessage({ id: 'TRANSLATOR.PLAN' })}
                        </Button>
                    </div>
                </div>
            }
        />
    );
};

export default Appointment;
