import * as dayjs from 'dayjs';
import React, { useCallback, useEffect } from 'react';
import { Button, Card, Col, Form, Image, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import useModal from '@app/hooks/useModal';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';
import { fetchOrder } from '@app/store/manufacturer/order/order.thunk';

import { InfoCard } from '@app/partials/content/components';
import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';
import toast from '@app/partials/content/Toast';
import HeadBand from '@app/partials/layout/HeadBand';

import { appointmentDataFormatter, customerDataFormatter, vehiculeDataFormatter } from './Helpers/cardDataFormatter';
import { EXCLUDED_APPOINTMENT_STATUS, getStateBySlug, HIDDEN_STATUS } from './Helpers/Status';
import ConfirmAffectation from './Modals/ConfirmAffectation';
import ConfirmAppointment from './Modals/ConfirmAppointment';
import Update from './Modals/Update';
import ConfirmAffectationButton from './partials/ConfirmationAffectationButton';
import ConfirmButton from './partials/ConfirmButton';

function Order() {
    const history = useHistory();
    const Intl = useIntl();
    const params = useParams();
    const dispatch = useDispatch();
    const { order, error, loading } = useSelector((state) => state.manufacturer.order);
    const [showUpdateModal, toggleUpdateModal] = useModal(false);
    const [showConfirmAffectationModal, toggleConfirmAffectationModal] = useModal(false);
    const [showConfirmAppointmentModal, toggleConfirmAppointmentModal] = useModal(false);

    const showOrders = useCallback(() => {
        history.push(routeTo(ROUTES.MANUFACTURER_ORDERS.PATH.ALL));
    }, [history]);

    useEffect(() => {
        dispatch(fetchOrder(params.id));
    }, [params.id]);

    useEffect(() => {
        if (error !== null) {
            toast({
                onShow: showOrders,
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    }, [error, showOrders]);

    if (loading || order === null) {
        return <Loader style={{ width: '5rem', height: '5rem' }} />;
    }

    const confirmAffectation = () => {
        toggleConfirmAffectationModal(true);
    };
    const confirmAppointment = () => {
        toggleConfirmAppointmentModal(true);
    };
    const infoCardAppointmentMoreAction = (
        <>
            {!order?.dealership?.isActiveMecaplanning ? (
                <Permission permissions={['APV_CONFIRMATION_APPOINTMENT_ORDER_SITES_CE']}>
                    <ConfirmButton
                        orderAppointmentId={order?.appointment?.cryptedId}
                        isConfirmed={order?.appointment?.orderAppointmentStatus?.slug === 'confirmed'}
                        disabled={EXCLUDED_APPOINTMENT_STATUS.includes(
                            order?.appointment?.orderAppointmentStatus?.slug,
                        )}
                        confirm={confirmAppointment}
                        btnVariant="outline-primary"
                        displayLabel
                    />
                </Permission>
            ) : (
                order?.appointment?.affected !== null && (
                    <Permission permissions={['APV_CONFIRMATION_ASSIGN_ORDER_SITES_CE']}>
                        <ConfirmAffectationButton
                            orderAppointmentId={order.appointment.cryptedId}
                            isAffected={order.appointment.affected}
                            updateAffectation={confirmAffectation}
                            btnVariant="danger"
                            displayLabel
                        />
                    </Permission>
                )
            )}
            {order?.appointment && !HIDDEN_STATUS.includes(order?.orderStatus?.slug) && (
                <Permission permissions={['APV_UPDATE_ORDER_SITES_CE']}>
                    <Button
                        variant="outline-secondary"
                        disabled={EXCLUDED_APPOINTMENT_STATUS.includes(
                            order?.appointment?.orderAppointmentStatus?.slug,
                        )}
                        onClick={() => toggleUpdateModal(true)}
                    >
                        {Intl.formatMessage({ id: 'MANUFACTURER.ORDER.VIEW.UPDATE' })}
                    </Button>
                </Permission>
            )}
        </>
    );

    return (
        <Form className="package">
            <div className="mb-5">
                <HeadBand variant={getStateBySlug(order)}>
                    <Row className="col-lg-12">
                        <Col lg={7}>
                            <Row>
                                <Col lg={6}>
                                    <Image
                                        className="float-right"
                                        src="/media/illustrations/rendez-vous-confirme.svg"
                                    />
                                </Col>
                                <Col className="headband__col" lg={6}>
                                    <div className="headband__title">
                                        <i className="la la-bullhorn mr-3" />
                                        <span className="font-weight-semibold">
                                            {order?.appointment?.orderAppointmentStatus?.name}
                                        </span>
                                    </div>
                                    <span className="font-weight-semibold">
                                        {' '}
                                        {order?.customer?.firstName} {order?.customer?.lastName}{' '}
                                    </span>
                                    a rendez-vous
                                    <span className="font-weight-semibold">
                                        {order?.orderAppointment &&
                                            dayjs(order.orderAppointment.startDate).format(
                                                ' [le] DD MMMM[, Ã  ] HH [h] mm',
                                            )}
                                    </span>
                                    ,
                                    <br />
                                    dans la concession
                                    <span className="font-weight-semibold"> {order?.dealership?.usualName}</span>.
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </HeadBand>
            </div>
            <Row className="mb-5">
                <Col lg={4}>
                    <InfoCard
                        title={Intl.formatMessage({ id: 'MANUFACTURER.ORDER.VIEW.BILLING' })}
                        titleIcon={<i className="card__icon fas fa-file-invoice" />}
                        content={customerDataFormatter(order?.customer)}
                    />
                </Col>
                <Col lg={4}>
                    <InfoCard
                        title={Intl.formatMessage({ id: 'MANUFACTURER.ORDER.VIEW.APPOINTMENT' })}
                        titleIcon={<i className="card__icon fas fa-calendar-alt" />}
                        content={appointmentDataFormatter(order)}
                        moreAction={infoCardAppointmentMoreAction}
                    />
                </Col>
                <Col lg={4}>
                    <InfoCard
                        title={Intl.formatMessage({ id: 'MANUFACTURER.ORDER.VIEW.VEHICLE' })}
                        titleIcon={<i className="card__icon fas fa-car" />}
                        content={vehiculeDataFormatter(order)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Header className="border-bottom-0">
                            <i className="card__icon fas fa-tasks" />
                            {Intl.formatMessage({ id: 'MANUFACTURER.ORDER.VIEW.DETAILS' })}
                        </Card.Header>
                        <Card.Body className="p-0">
                            <Table className="m-0" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th width="10%">
                                            {Intl.formatMessage({ id: 'MANUFACTURER.ORDER.TABLE.HEAD.REFERENCE' })}
                                        </th>
                                        <th width="90%">
                                            {Intl.formatMessage({ id: 'MANUFACTURER.ORDER.TABLE.HEAD.PRODUCT' })}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {order?.orderPackages &&
                                        order.orderPackages.map((pack) => (
                                            <tr key={pack?.package?.slug}>
                                                <td>{pack?.package?.slug}</td>
                                                <td>
                                                    {pack?.package?.name}{' '}
                                                    {pack?.tire?.quantity && `(x${pack.tire.quantity})`}
                                                </td>
                                            </tr>
                                        ))}
                                    {order?.carRental && (
                                        <tr key="carRental">
                                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                            <td />
                                            <td>
                                                <FormattedMessage id="MANUFACTURER.ORDER.VIEW.TABLE.CAR_RENTAL" />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Update showModal={showUpdateModal} setShowModal={toggleUpdateModal} />
            <ConfirmAffectation
                showModal={showConfirmAffectationModal}
                setShowModal={toggleConfirmAffectationModal}
                selectedOrderAppointmentId={order.appointment.cryptedId}
                refreshMethod={() => dispatch(fetchOrder(params.id))}
            />
            <ConfirmAppointment
                showModal={showConfirmAppointmentModal}
                setShowModal={toggleConfirmAppointmentModal}
                selectedOrderAppointmentId={order.appointment.cryptedId}
                refreshMethod={() => dispatch(fetchOrder(params.id))}
            />
        </Form>
    );
}

export default Order;
