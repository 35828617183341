import React from 'react';
import {Intl} from "../../../_metronic/i18n/I18nProvider";
import {actions} from "../../store/modal/modal.store";
import {Button} from "react-bootstrap";
import {useDispatch} from "react-redux";

const CommentArea = (
    {
        comment,
        modal,
        callback,
        label,
        containerClassName,
        readOnly,
        params
    }) => {

    const dispatch = useDispatch();

    return (
        <div className={`d-flex flex-column comment ${containerClassName || ''}`}>
            <div className="d-flex justify-content-between">
                <span className="font-weight-bold"><i className="la la-comment la-lg mr-2"/>{Intl.formatMessage({ id: `${label}` })}</span>
                {
                    !readOnly &&
                        <Button className="p-0" onClick={() => dispatch(actions.modalChange(modal, {
                            callback: callback,
                            comment: comment,
                            params: params,
                        }))} variant="outline-link">
                            <i className="la la-pen p-0 text-muted"/>
                        </Button>
                }
            </div>
            <p className="mt-4">{comment || '-'}</p>
        </div>
    );
};

CommentArea.defaultProps = {
    label: 'TRANSLATOR.COMMENT',
}

export default CommentArea;
