import React from 'react';

type DashboardRankingIconProps = {
    color: string;
    idx: number;
};

const DashboardRankingIcon = ({ color, idx }: DashboardRankingIconProps) => (
    <div className="mr-3">
        <div className="position-relative">
            <i className={`position-relative text-${color} las la-2xl la-certificate`} />
            <span className={`dashboard-ranking__rank text-${color} font-weight-bold font-size-xs`}>{idx}</span>
        </div>
    </div>
);

export default DashboardRankingIcon;
