import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import { useAppSelector } from '@app/hooks';
import AccountHistory from '@app/pages/account/AccountHistory';
import { AccountMenu } from '@app/pages/account/AccountMenu';
import AccountPassword from '@app/pages/account/AccountPassword';
import AccountPerimeter from '@app/pages/account/AccountPerimeter';
import AccountPlanning from '@app/pages/account/AccountPlanning/AccountPlanning';
import { USER_MENUS } from '@app/pages/account/Helpers/Menu';
import ROUTES from '@app/router/Routes';
import { fetchUser } from '@app/store/autouser/user/user.thunk';

const User = () => {
    const { user } = useAppSelector((state) => state.user);
    const location = useLocation();
    const params = useParams<{ id: string }>();
    const dispatch = useDispatch();

    const rightComponents = [
        {
            url: routeTo(ROUTES.USER.PATH.PLANNING, { id: params?.id || user?.id }),
            component: AccountPlanning,
        },
        {
            url: routeTo(ROUTES.USER.PATH.PERIMETER, { id: params?.id || user?.id }),
            component: AccountPerimeter,
        },
        {
            url: routeTo(ROUTES.USER.PATH.HISTORY, { id: params?.id || user?.id }),
            component: AccountHistory,
        },
        {
            url: routeTo(ROUTES.USER.PATH.PASSWORD, { id: params?.id || user?.id }),
            component: AccountPassword,
        },
    ];

    useEffect(() => {
        dispatch(fetchUser(params.id));
    }, [dispatch]);

    const rightComponent = Object.entries(rightComponents).find(([, value]) => location.pathname === value.url);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    const Component = rightComponent?.length ? rightComponent[1].component : <></>;
    const defaultActiveMenu = rightComponent?.length ? parseInt(rightComponent[0], 10) : 0;

    return (
        user && (
            <div className="account">
                <Card>
                    <Card.Body className="p-0">
                        <Row>
                            <Col className="col-xxl-3 col-lg-4">
                                <AccountMenu userData={user} menus={USER_MENUS} defaultActiveMenu={defaultActiveMenu} />
                            </Col>
                            <Col className="p-0 col-lg-8">
                                {/* @ts-ignore */}
                                {user?.id && <Component userData={user} profile={false} />}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        )
    );
};

export default User;
