import React from 'react';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import Label from '@app/partials/content/Label';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';

export default function StockVehiclesEligibilityColumn({ vehicle }) {
    const labelHandler = (item, label, tooltipId) => (
        <OverlayTooltip
            label={`STOCK_VEHICLES.TABLE.${item ? '' : 'NO_'}${tooltipId.toUpperCase()}_TOOLTIP`}
            id={tooltipId}
            position="left"
        >
            <Label
                variant="light"
                className={`font-weight-bold mb-2 label-eligibility ${item ? 'text-success' : 'text-danger-lite'}`}
            >
                {item ? (
                    <i className="mr-2 las la-check la-2x text-success" />
                ) : (
                    <i className="mr-2 las la-times la-2x text-danger-lite" />
                )}
                {label}
            </Label>
        </OverlayTooltip>
    );
    return (
        <div className="d-flex flex-column">
            {labelHandler(
                vehicle.is_eligible_afr,
                Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.A_FR' }),
                'eligibility_afr',
            )}
            {labelHandler(
                vehicle.is_eligible_fw,
                Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.FW' }),
                'eligibility_fw',
            )}
            {labelHandler(
                vehicle.is_eligible_fluidite,
                Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.FLUIDITY' }),
                'eligibility_fluidity',
            )}
        </div>
    );
}
