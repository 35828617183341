import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

import useQueryParams from '@app/hooks/useQueryParams';

import { routeTo } from '@app/helpers/RoutesHelper';

import { FollowUpTaskStatus } from '@app/crud/customers/customer.type';

import { showDetailEvent } from '@app/pages/customers/Helpers/Events';
import useAction from '@app/pages/customers/Hooks/useAction';
import useLead from '@app/pages/customers/Hooks/useLead';
import ROUTES from '@app/router/Routes';
import { actions } from '@app/store/modal/modal.store';

import toast from '@app/partials/content/Toast';

const ActivityLead = ({ customer, activity }) => {
    const { userData } = useSelector((state) => state.auth);
    // avoid click on lead row on useEffect when already clicked
    const leadOpened = useRef(false);
    const queryParams = useQueryParams();
    const history = useHistory();
    const location = useLocation();
    const Intl = useIntl();
    const lead = useLead(queryParams?.leadId);
    const dispatch = useDispatch();

    const [loading, createAction] = useAction({
        type: 'take_lead',
        permission: 'LEAD_TAKE_LEAD_CLIENT',
        success: () => {
            toast({
                variant: 'success',
                message: Intl.formatMessage({ id: 'LEADS_ONLINE.TAKE_LEAD.SUCCESS' }),
            });
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'LEADS_ONLINE.TAKE_LEAD.FAILED' }),
            });
        },
    });

    useEffect(() => {
        if (!lead?.flags) return;

        const { flags } = lead;
        const modalTriggerStatus = [FollowUpTaskStatus.CLOSED, FollowUpTaskStatus.OPEN];
        const followUpTaskStatus = flags.find((flag) => modalTriggerStatus.includes(flag));

        if (followUpTaskStatus) {
            dispatch(
                actions.modalChange('follow_up_task', {
                    leadId: lead?.id,
                }),
            );
        }
    }, [lead]);

    // Handle activity lead
    useEffect(() => {
        if (!loading) {
            if (!customer?.leads) return;
            if (!lead) return;
            if (!userData?.id) return;

            if (queryParams?.action === 'take') {
                if (lead?.ownerId && userData?.id && lead?.ownerId !== userData?.id) {
                    Swal.fire({
                        text: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.TAKE_LEAD.TEXT' }),
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn mr-3 btn-primary',
                        },
                        buttonsStyling: false,
                    }).then(() => {
                        history.replace(routeTo(ROUTES.DASHBOARD.PATH));
                    });
                } else {
                    createAction({
                        lead: {
                            id: lead?.id,
                        },
                    });
                }
                history.replace(location.pathname);
            } else if (queryParams?.action === 'viewLead' && lead?.id) {
                const showDetailButton = document.querySelector(`#event-all-${lead?.id}`);

                if (showDetailButton && activity?.data?.length > 0 && !leadOpened.current) {
                    showDetailEvent(showDetailButton);
                    leadOpened.current = true;
                }
            }
        }
    }, [queryParams, customer, activity, location, userData, history, loading]);

    return null;
};

export default ActivityLead;
