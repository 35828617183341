import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { Order } from '@app/crud/apv/order.type';

import { getStateBySlugApvInStore } from '@app/pages/apv/apvinstore/Helpers/statut';
import { getStateBySlug } from '@app/pages/apv/orders/Helpers/OrderStatus';

import HeadBand from '@app/partials/layout/HeadBand';

interface HeadBandGenericProps {
    order: Order;
}

const formatDate = (order: Order) =>
    order?.appointment?.dateStart ? dayjs(order.appointment.dateStart).format(' [le] DD MMMM[, à ] HH [h] mm') : '';

const HeadBandGeneric = ({ order }: HeadBandGenericProps) => {
    const Intl = useIntl();

    const getEffectiveStatus = (orderData: Order): string =>
        getStateBySlugApvInStore(orderData?.appointment?.orderAppointmentStatusApvInStore?.slug) ||
        getStateBySlug(orderData);

    const getEffectiveStatusName = (orderData: Order) =>
        orderData?.appointment?.orderAppointmentStatusApvInStore || orderData.orderStatus;

    const effectiveStatus = getEffectiveStatus(order);
    const effectiveStatusName = getEffectiveStatusName(order);
    const statusVariant = effectiveStatus;
    const appointmentDate = useMemo(() => formatDate(order), [order?.appointment?.dateStart]);

    return (
        <HeadBand variant={statusVariant}>
            <Row className="col-lg-12">
                <Col lg={7}>
                    <Row>
                        <Col lg={6}>
                            <Image className="float-right" src="/media/illustrations/rendez-vous-confirme.svg" />
                        </Col>
                        <Col className="headband__col" lg={6}>
                            <div className="headband__title">
                                <i className="la la-bullhorn mr-3" />
                                <span className="font-weight-semibold">{effectiveStatusName?.name}</span>
                            </div>
                            <span className="font-weight-semibold">
                                {' '}
                                {order?.firstname} {order?.lastname}{' '}
                            </span>
                            {Intl.formatMessage({ id: 'APV.ORDER.VIEW.DETAILS.APPOINTMENT' })}
                            <span className="font-weight-semibold">{appointmentDate}</span>
                            ,
                            <br />
                            {Intl.formatMessage({ id: 'APV.ORDER.VIEW.DETAILS.APPOINTMENT.IN' })}
                            <span className="font-weight-semibold">
                                {' '}
                                {order?.dealership?.dealershipSource?.usual_name}
                            </span>
                            .
                        </Col>
                    </Row>
                </Col>
            </Row>
        </HeadBand>
    );
};

export default HeadBandGeneric;
