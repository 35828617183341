import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { LayoutContextConsumer } from '@src/_metronic';
import BreadCrumbs from '@src/_metronic/layout/sub-header/components/BreadCrumbs';

import subHeader from '@app/partials/HoC/subHeader';

function FluiditySubHeader(props) {
    const { subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle } = props;
    const params = useParams();
    const Intl = useIntl();

    const breadcrumb = [
        {
            title:
                params?.status === 'in_progress'
                    ? Intl.formatMessage({ id: 'MENU.FLUIDITY.IN_PROGRESS' })
                    : Intl.formatMessage({ id: 'MENU.FLUIDITY.CLOSED' }),
        },
    ];

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                            type="button"
                        >
                            <span />
                        </button>
                    )}

                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">{Intl.formatMessage({ id: 'MENU.FLUIDITY' })}</h3>
                                <BreadCrumbs items={breadcrumb} />
                            </>
                        )}
                    </LayoutContextConsumer>
                </div>
            </div>
        </div>
    );
}

export default subHeader(FluiditySubHeader);
