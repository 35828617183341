import * as dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';

import RowInfo from '@app/partials/content/RowInfo';

const RappelClientInfo = ({ event }) => {
    const Intl = useIntl();

    return (
        <>
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.CAMPAIGN_NAME' })}
                value={event?.product[0]?.campaign_name}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DATE_START' })}
                value={event?.product[0]?.date_start && dayjs(event?.product[0]?.date_start).format('DD/MM/YYYY')}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DATE_END' })}
                value={event?.product[0]?.date_end && dayjs(event?.product[0]?.date_end).format('DD/MM/YYYY')}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.NB_RECALL_PLANNED' })}
                value={event?.product[0]?.nb_recall_planned}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.NB_RECALL_REALIZED' })}
                value={event?.product[0]?.nb_recall_realized}
            />
        </>
    );
};

export default RappelClientInfo;
