import ApiGateway from '@app/helpers/AxiosHelper';

const { REACT_APP_APV_SC_URL } = process.env;

export function getPackageMecaplanningAction(params) {
    return ApiGateway.get(`${REACT_APP_APV_SC_URL}/api/package-mapping`, {
        params,
    });
}

export function updatePackageMecaplanningAction(data) {
    return ApiGateway.post(`${REACT_APP_APV_SC_URL}/api/package-mapping`, data);
}
