import React from 'react';

export const DashboardContext = React.createContext();

const DashboardProvider = ({value, children}) => {
    return (
        <DashboardContext.Provider value={value}>
            {children}
        </DashboardContext.Provider>
    );
};

export default DashboardProvider;
