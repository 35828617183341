import ApiGateway from '@app/helpers/AxiosHelper';
import { getFormData } from '@app/helpers/ObjectHelper';

const { REACT_APP_APV_URL } = process.env;

export function getCategories(params) {
    return ApiGateway.get(`${REACT_APP_APV_URL}/package/category`, {
        params,
    });
}

export function getCategory(id) {
    return ApiGateway.get(`${REACT_APP_APV_URL}/package/category/${id}`);
}

export function addCategory(data) {
    return ApiGateway.post(`${REACT_APP_APV_URL}/package/category`, getFormData(data));
}

export function updateCategoryById(id, data) {
    return ApiGateway.post(`${REACT_APP_APV_URL}/package/category/${id}`, getFormData(data));
}

export function deleteCategory(id) {
    return ApiGateway.delete(`${REACT_APP_APV_URL}/package/category/${id}`);
}
