import React from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

interface Props {
    event: VcuMappedEventDetails;
}
const FinancementInfo = ({ event }: Props) => {
    const Intl = useIntl();
    return (
        <div className="d-md-flex justify-content-md-between align-items-md-stretch">
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.RENT' })}
                    value={
                        <NumberFormat
                            value={event?.price || '-'}
                            decimalSeparator="."
                            thousandSeparator=" "
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" € / mois"
                            displayType="text"
                        />
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.BRING' })}
                    value={
                        <NumberFormat
                            value={event?.product[0]?.bring || '-'}
                            decimalSeparator="."
                            thousandSeparator=" "
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" €"
                            displayType="text"
                        />
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DURATION' })}
                    value={
                        <NumberFormat
                            value={event?.product[0]?.duration || '-'}
                            decimalSeparator="."
                            thousandSeparator=" "
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" mois"
                            displayType="text"
                        />
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.POLITICAL_CODE' })}
                    value={event?.product[0]?.political_code}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.PACK' })}
                    value={event?.product[0]?.pack}
                />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.REDEMPTION_VALUE' })}
                    value={
                        <NumberFormat
                            value={event?.product[0]?.redemption_value || '-'}
                            decimalSeparator="."
                            thousandSeparator=" "
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" €"
                            displayType="text"
                        />
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.KM_ANNUAL_PLANNED' })}
                    value={
                        <NumberFormat
                            value={event?.product[0]?.km_annual_planned || '-'}
                            decimalSeparator="."
                            thousandSeparator=" "
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" km"
                            displayType="text"
                        />
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.FUNDING_TYPE' })}
                    value={event?.product[0]?.funding_type}
                />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.VIVAFI_ID' })}
                    value={event?.product[0]?.vivafi_id}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.TELEFI_ID' })}
                    value={event?.product[0]?.telefi_id}
                />
            </div>
        </div>
    );
};

export default FinancementInfo;
