/** *
 * Display UI elements with valid/unvalid UI on svg car
 */

import React from 'react';
import { Col } from 'react-bootstrap';

import getVehicleProfile from '@app/helpers/getVehicleZone';

import { Intl } from '../../../../../_metronic/i18n/I18nProvider';
import { hideCarZones, showCarZones } from '../Helpers/hideShowVehicleZones';

function QuotationVehicleUi({ item, quotation }) {
    const compatibleSRVehicle = 'compatible';
    const imcompatibleSRVehicle = 'incompatible';
    const isPending = quotation?.orderStatus?.slug === 'pending';
    const isNewComplement = quotation?.orderStatus?.slug === 'new_complement';
    const orderStatusGroupSlug = quotation?.orderStatus?.groupSlug === 'to_be_appraised';
    const isNotPendingOrNewComplement = !(isPending || isNewComplement);

    const hideShowCss = `
        ${hideCarZones()}
        ${showCarZones(quotation?.orderRepairs)}
    `;

    return (
        <Col lg={6} className="smart-repair-card--separate">
            <div
                className={`d-flex justify-content-center h-100 smart-repair-card__car
                ${
                    item?.zoneBody.zone.slug === 'avant' || item?.zoneBody.zone.slug === 'arriere'
                        ? 'smart-repair-card__car--w-30'
                        : ''
                }`}
            >
                <style>{hideShowCss}</style>
                {getVehicleProfile(item?.zoneBody?.zone?.slug)}
                {orderStatusGroupSlug && item?.compatible === compatibleSRVehicle && (
                    <>
                        <i className="las la-check smart-repair-card__check smart-repair-card__check--active" />
                        <p className="smart-repair-card__info smart-repair-card__info--valid">
                            {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.VALID.INFO' })}
                        </p>
                    </>
                )}
                {orderStatusGroupSlug && item?.compatible === imcompatibleSRVehicle && (
                    <>
                        <i className="las la-times smart-repair-card__check smart-repair-card__check--disabled" />
                        <p className="smart-repair-card__info smart-repair-card__info--unvalid">
                            {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.UNVALID.INFO' })}
                        </p>
                    </>
                )}
                {isNotPendingOrNewComplement && item?.compatible && (
                    <i className="las la-check smart-repair-card__check smart-repair-card__check--active" />
                )}
                {isNotPendingOrNewComplement && item?.compatible === false && (
                    <i className="las la-times smart-repair-card__check smart-repair-card__check--disabled" />
                )}
            </div>
        </Col>
    );
}

export default QuotationVehicleUi;
