// eslint-disable-next-line import/prefer-default-export
export const mapUpdatePartExchange = (partExchange) => ({
    make: partExchange.make,
    model: partExchange.model,
    version: partExchange.version,
    fuelType: partExchange.fuelType,
    gearbox: partExchange.gearbox,
    mileage: partExchange.mileage,
    registration: partExchange.registration,
    dateFirstRegistration: partExchange.dateFirstRegistration,
    registrationCertificate: partExchange.registrationCertificate,
    registrationCertificateAbsenceReason: partExchange.registrationCertificateAbsenceReason,
    vehicleType: partExchange.vehicleType,
    vin: partExchange.vin,
    typeVarVersPrf: partExchange.typeVarVersPrf,
    coHolder: partExchange.coHolder,
    comment: partExchange.comment,
    estimatedPrice: partExchange.estimatedPrice,
});
