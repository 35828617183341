import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

const { REACT_APP_CITY } = process.env;

export interface City {
    id: Number;
    code: string;
    name: string;
    slugName: string;
    latitude: Number;
    longitude: Number;
    region: {
        id: Number;
        code: string;
        name: string;
        active: boolean;
        children: [];
        slug: string;
    };
    isMain: boolean;
}

export function getCities(query: string, country: string) {
    return ApiGateway.get<City[], CustomAxiosResponse<ApiResponse<City[]>>>(`${REACT_APP_CITY}/cities/search`, {
        params: { q: query, country: country },
    });
}
