import React, {useCallback, useEffect} from 'react';
import {getMetaFile} from "../../crud/meta.crud";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";

const CacheBouding = ({children}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const updateLastestVersion = useCallback(() => {
        getMetaFile()
            .then((response) => {
                if (response?.data?.version) {
                    const current_version = localStorage.getItem('APP_VERSION');

                    if (current_version !== response.data.version) {
                        dispatch({ type: 'RESET_STATE' });
                        localStorage.setItem('APP_VERSION', response.data.version);
                        window.location.href = window.location.href;
                    }
                }
            });
    }, []);

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            updateLastestVersion();
        });

        return () => {
            unlisten();
        }
    }, [updateLastestVersion]);

    useEffect(() => {
        updateLastestVersion();
    }, [updateLastestVersion]);

    return children;
};

export default CacheBouding;
