import dayjs from 'dayjs';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import CommentArea from '@app/partials/content/CommentArea';

import CampaignStatusRow from './CampaignStatusRow';

const CampaignInfos = ({ campaign, totalCount }) => {
    const dateStart =
        campaign?.dateStart && dayjs(campaign?.dateStart)?.isValid()
            ? dayjs(campaign?.dateStart)?.format('DD/MM/YYYY [à] HH[h]mm')
            : '-';
    const dateBloctel = dayjs(campaign?.dateStart)?.add(29, 'days')?.hour(23)?.minute(59)?.second(59);
    const dateEnd = dayjs(campaign?.dateStart)
        ?.add((campaign?.duration ?? 30) - 1, 'days')
        ?.hour(23)
        ?.minute(59)
        ?.second(59);
    return (
        <>
            <Row>
                <Col className="kt-shape-font-color-4">
                    <FormattedMessage id="CAMPAIGNS.TABLE.HEAD.SLUG" />
                </Col>
                <Col className="font-weight-semibold">{campaign?.slug}</Col>
            </Row>
            <Row className="mt-6">
                <Col className="kt-shape-font-color-4">
                    <FormattedMessage id="CAMPAIGN.INFOS.NAME" />
                </Col>
                <Col className="font-weight-semibold">
                    <u>{campaign?.name}</u>
                </Col>
            </Row>
            <Row className="mt-6">
                <Col className="kt-shape-font-color-4">
                    <FormattedMessage id="CAMPAIGN.INFOS.TYPE" />
                </Col>
                <Col>{campaign?.campaignType?.name || '-'}</Col>
            </Row>
            <CommentArea comment={campaign?.description} containerClassName="campaign-comment mt-6" readOnly />
            <Row className="mt-6">
                <Col className="kt-shape-font-color-4">
                    <FormattedHTMLMessage id="CAMPAIGN.INFOS.DATE_START" />
                </Col>
                <Col className="font-weight-semibold">{dateStart}</Col>
            </Row>
            <Row className="mt-6">
                <Col className="kt-shape-font-color-4">
                    <FormattedHTMLMessage id="CAMPAIGN.INFOS.DEADLINE" />
                </Col>
                <Col className="font-weight-semibold">
                    {campaign?.dateStart && dayjs(campaign?.dateStart)?.isValid()
                        ? dateBloctel?.format('DD/MM/YYYY [à] HH[h]mm')
                        : '-'}
                </Col>
            </Row>
            <Row className="mt-6">
                <Col className="kt-shape-font-color-4">
                    <FormattedMessage id="CAMPAIGN.INFOS.DATE_END" />
                </Col>
                <Col className="font-weight-semibold">
                    {campaign?.dateStart && dayjs(campaign?.dateStart)?.isValid()
                        ? (dateBloctel.isBefore(dateEnd) ? dateBloctel : dateEnd)?.format('DD/MM/YYYY [à] HH[h]mm')
                        : '-'}
                </Col>
            </Row>
            <Row className="mt-6">
                <Col className="kt-shape-font-color-4">
                    <FormattedMessage id="CAMPAIGN.INFOS.STATUS" />
                </Col>
                <Col className="font-weight-semibold">
                    <CampaignStatusRow
                        campaignStatusName={campaign?.campaignStatus?.name}
                        campaignStatusSlug={campaign?.campaignStatus?.slug}
                        campaignStatusSubName={campaign?.campaignStatus?.subName}
                    />
                </Col>
            </Row>
            <Row className="mt-6">
                <Col className="kt-shape-font-color-4">
                    <FormattedMessage id="CAMPAIGN.INFOS.CONTACTS_NUMBER" />
                </Col>
                <Col className="font-weight-semibold">{totalCount}</Col>
            </Row>
        </>
    );
};

CampaignInfos.defaultProps = {
    editable: true,
};
export default CampaignInfos;
