import React from 'react';
import { FormattedMessage } from 'react-intl';

import { usePermissions } from '@app/hooks';

type AddCommentActionProps = {
    leadItem: {
        leadStatus: {
            slug: string;
        };
    };
    // eslint-disable-next-line @typescript-eslint/ban-types
    handleActionClick: Function;
};

const AddCommentAction = ({ leadItem, handleActionClick }: AddCommentActionProps) => {
    const { hasPermission } = usePermissions();

    const pendingsStatus = ['pending', 'pending_30_min', 'pending_60_min', 'pending_90_min', 'pending_120_min'];

    let author: {
        type?: string;
        color?: string;
    } = {};

    if (leadItem?.leadStatus?.slug === 'in_progress_apv_to_do' && hasPermission('APV_LOCAL_TAKE_LEAD_CLIENT')) {
        author = {
            type: 'dealership',
            color: 'primary',
        };
    } else if (pendingsStatus.includes(leadItem?.leadStatus?.slug) && hasPermission('APV_LOCAL_LEAD_CREATE')) {
        author = {
            type: 'call',
            color: 'success',
        };
    }

    if (!author) {
        return null;
    }

    return (
        <div className={`timeline-item align-items-start timeline-item-${author.type} mb-n1`}>
            <div className="timeline-label w-50" style={{ fontSize: '13px' }}>
                <button
                    type="button"
                    className={`btn btn-link text-${author.color} text-underline p-0`}
                    onClick={() => {
                        handleActionClick('reply_local_apv');
                    }}
                >
                    <FormattedMessage id="CUSTOMERS.MODAL.CREATE_LOCAL_APV.COMMENT.ADD_COMMENT" />
                </button>
            </div>
            <div className="timeline-badge timeline-badge__activity">
                <i className={`fa fa-genderless icon-xl text-${author.color}`} />
            </div>
        </div>
    );
};

export default AddCommentAction;
