import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import { formatNameFromEmail } from '@app/pages/customers/Helpers/Events';

import RowInfo from '@app/partials/content/RowInfo';

interface Props {
    event: VcuMappedEventDetails;
}
const DetailIcare = ({ event }: Props) => {
    const Intl = useIntl();
    const { icare } = event;
    return (
        <div className="d-md-flex justify-content-md-between align-items-md-stretch">
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ICARE.TYPE.EVENT' })}
                    value={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.ICARE.TYPE.EVENT.TITLE',
                    })}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ICARE.TYPE.CONTRAT' })}
                    value={icare?.maintenanceContractNomCommercialFormule || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ICARE.PROVIDER' })}
                    value={icare?.maintenanceSrcApplicationName || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.ICARE.CONTRACT.NUMBER',
                    })}
                    value={icare?.maintenanceContractNumeroContract || '-'}
                />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.ICARE.SUBSCRIPTION.DATE',
                    })}
                    value={
                        icare?.maintenantSrcProcessMoment
                            ? dayjs(icare?.maintenantSrcProcessMoment).format('DD/MM/YYYY')
                            : '-'
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.ICARE.DURATION.OF.COVERAGE',
                    })}
                    value={`${icare?.maintenanceContractDureeCouvertureContract} mois` || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.ICARE.CONTRACT.STATUS',
                    })}
                    value={icare?.maintenanceContractStatutContract || '-'}
                />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.ICARE.THIRD.PARTY.DISTRIBUTOR.ID',
                    })}
                    value={icare?.maintenanceContractIdentifiantTiersDistributeur || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.ICARE.NAME.OF.THIRD.PARTY.DISTRIBUTOR',
                    })}
                    value={icare?.maintenanceRefDealershipLegalname || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.ICARE.SALEPERSON.COMMERCIAL',
                    })}
                    value={`${icare?.maintenanceContractPrenomCommercialPerso || '-'} ${
                        icare?.maintenanceContractNomCommercialPerso || '-'
                    }`}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ICARE.SELLER.CODE' })}
                    value={formatNameFromEmail(icare?.maintenanceContractCodeVendeurContract)}
                />
            </div>
        </div>
    );
};
export default DetailIcare;
