import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import ErrorForm from '@app/partials/layout/ErrorForm';

const LeadWonBillingTool = ({ params, listingParams }) => {
    const { register, errors } = useFormContext();
    const Intl = useIntl();

    return (
        <Form.Group>
            <Form.Label>
                <FormattedHTMLMessage id="CUSTOMERS.MODAL.LEAD_WON.BILLING" />
            </Form.Label>
            <Form.Control
                as="select"
                name="billingTool"
                defaultValue=""
                disabled={!!params?.event}
                className={`${errors.billingTool ? 'is-invalid' : ''}`}
                ref={register({
                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                })}
            >
                <option value="">{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_WON.BILLING.PLACEHOLDER' })}</option>
                {listingParams &&
                    listingParams.billing_order_tool &&
                    listingParams.billing_order_tool.listingParams.map((reason) => (
                        <option key={reason.id} value={reason.slug}>
                            {reason.name}
                        </option>
                    ))}
            </Form.Control>
            <ErrorForm errors={errors} name="billingTool" />
        </Form.Group>
    );
};

export default LeadWonBillingTool;
