import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { setToken } from '@app/helpers/User';

import { getCurrentUser, impersonate, login } from '@app/crud/auth.crud';

import toast from '@app/partials/content/Toast';

import { actions } from './login.store';

export const logUser =
    (username: string, password: string, context: string): ThunkAction<Promise<void>, object, object, AnyAction> =>
    (dispatch) => {
        dispatch(actions.logUser(username, password, context));

        return login(username, password, context)
            .then((response) => {
                setToken(dispatch, {
                    ...response,
                    'x-autouser-cache': response.axios.headers['x-autouser-cache'],
                });
                return getCurrentUser();
            })
            .then((response) => {
                dispatch(actions.fetchedCurrentUser(response.data));
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    // @ts-ignore
                    message: Intl.formatMessage({ id: 'AUTH.INCORRECT_LOGIN' }),
                });
            });
    };

export const impersonateUser =
    (mail: string): ThunkAction<Promise<void>, object, object, AnyAction> =>
    (dispatch) =>
        impersonate({ user: mail }).then((response) => {
            setToken(dispatch, {
                ...response,
            });
        });

export const stopImpersonateUser = (): ThunkAction<void, object, object, AnyAction> => (dispatch) => {
    dispatch(actions.stopImpersonateUser());
};

export const logoutUser = (): ThunkAction<void, object, object, AnyAction> => (dispatch) => {
    dispatch(actions.logoutUser());
};
