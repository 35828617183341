import React from "react";
import Day from "./Day";

export default function Days({ days, currentDay, onSelect }){
    return (
        <div className="days">
            {
                Object.entries(days).map((day, key) => {
                    return <Day
                                key={key}
                                selected={currentDay === day[0]}
                                onSelect={onSelect}
                                day={day[0]}
                                label={day[1].label}
                                number={day[1].day}
                                open={day[1].open}
                    />
                })
            }
        </div>
    )
}
