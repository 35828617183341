import dayjs from 'dayjs';

import { stripAccents } from '@app/helpers/StringHelper';

import { StatusContractSlug } from '@app/crud/securyweb/securyweb.types';

const mappingFieldsRequired = {
    id: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    offer: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    dealership: {
        [StatusContractSlug.DRAFT]: true,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    dealer: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    product: {
        [StatusContractSlug.DRAFT]: true,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    initialDuration: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    subscriptionDate: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    priceWithTaxes: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    priceWithoutTaxe: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    iban: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    bic: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    secretaryId: {
        [StatusContractSlug.DRAFT]: true,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    status: {
        [StatusContractSlug.DRAFT]: true,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.civility': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.language': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.lastname': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.firstname': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.address': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.additionalAddress': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.addressPlaceCalled': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.zipcode': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.city': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.localityComplement': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.phone': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.mobile': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.email': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.birthDate': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.maritalStatus': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.proCategory': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.siren': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.companyName': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.civility': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.lastname': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.firstname': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.addressFloor': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.addressBuilding': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.address': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.additionalAddress': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.addressPlaceCalled': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.zipcode': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.city': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.localityComplement': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.phone': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.mobile': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.email': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.birthDate': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.maritalStatus': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.proCategory': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.registrationNumber': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.registrationNumberDate': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.vin': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.registrationDocumentDate': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.mine': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.brand': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.vehicleCategory': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.model': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.fundingPrice': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.fundingMode': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
};

const mappingFieldsDisabled = {
    dealership: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    dealer: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    securywebId: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    product: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    initialDuration: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    subscriptionDate: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    priceWithTaxes: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    priceWithoutTaxe: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    iban: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    bic: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.civility': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.language': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.lastname': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.firstname': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.address': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.additionalAddress': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.addressPlaceCalled': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.zipcode': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.city': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.localityComplement': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.phone': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.mobile': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.email': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.birthDate': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.maritalStatus': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.proCategory': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.siren': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.companyName': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.civility': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.lastname': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.firstname': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.address': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.additionalAddress': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.addressPlaceCalled': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.zipcode': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.city': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.localityComplement': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.phone': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.mobile': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.email': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'beneficiary.birthDate': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.maritalStatus': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'beneficiary.proCategory': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.registrationNumber': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.registrationNumberDate': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.vin': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.registrationDocumentDate': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.mine': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.brand': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.vehicleCategory': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.model': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.fundingPrice': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.fundingMode': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
};

const mappingFieldsBorneRequired = {
    typeSolutionCharging: {
        [StatusContractSlug.DRAFT]: true,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    ageSolutionCharging: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    orderDate: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    iban: {
        ...mappingFieldsRequired.iban,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    bic: {
        ...mappingFieldsRequired.bic,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.language': {
        ...mappingFieldsRequired['subscriber.language'],
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'subscriber.housingType': {
        [StatusContractSlug.DRAFT]: true,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.housingMode': {
        [StatusContractSlug.DRAFT]: true,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    customerPath: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.siren': {
        ...mappingFieldsRequired['subscriber.siren'],
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.companyName': {
        ...mappingFieldsRequired['subscriber.companyName'],
        [StatusContractSlug.TO_SIGN]: true,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.brand': {
        ...mappingFieldsRequired['vehicle.brand'],
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
    'vehicle.model': {
        ...mappingFieldsRequired['vehicle.model'],
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: false,
    },
};

const mappingFieldsBorneDisabled = {
    dealership: {
        ...mappingFieldsDisabled.dealership,
        [StatusContractSlug.TO_SIGN]: false,
    },
    dealer: {
        ...mappingFieldsDisabled.dealer,
        [StatusContractSlug.TO_SIGN]: false,
    },
    product: {
        ...mappingFieldsDisabled.product,
        [StatusContractSlug.TO_SIGN]: false,
    },
    initialDuration: {
        ...mappingFieldsDisabled.initialDuration,
        [StatusContractSlug.TO_SIGN]: false,
    },
    orderDate: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    ageSolutionCharging: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    dateSolution: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    typeSolutionCharging: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    iban: {
        ...mappingFieldsDisabled.iban,
        [StatusContractSlug.TO_SIGN]: false,
    },
    bic: {
        ...mappingFieldsDisabled.bic,
        [StatusContractSlug.TO_SIGN]: false,
    },
    'subscriber.civility': {
        ...mappingFieldsDisabled['subscriber.civility'],
        [StatusContractSlug.TO_SIGN]: false,
    },
    'subscriber.language': {
        ...mappingFieldsDisabled['subscriber.language'],
    },
    'subscriber.lastname': {
        ...mappingFieldsDisabled['subscriber.lastname'],
        [StatusContractSlug.TO_SIGN]: false,
    },
    'subscriber.firstname': {
        ...mappingFieldsDisabled['subscriber.firstname'],
        [StatusContractSlug.TO_SIGN]: false,
    },
    'subscriber.address': {
        ...mappingFieldsDisabled['subscriber.address'],
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.additionalAddress': {
        ...mappingFieldsDisabled['subscriber.additionalAddress'],
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.zipcode': {
        ...mappingFieldsDisabled['subscriber.zipcode'],
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.city': {
        ...mappingFieldsDisabled['subscriber.city'],
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.localityComplement': {
        ...mappingFieldsDisabled['subscriber.localityComplement'],
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.phone': {
        ...mappingFieldsDisabled['subscriber.phone'],
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.mobile': {
        ...mappingFieldsDisabled['subscriber.mobile'],
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.email': {
        ...mappingFieldsDisabled['subscriber.email'],
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.housingType': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'subscriber.housingMode': {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    customerPath: {
        [StatusContractSlug.DRAFT]: false,
        [StatusContractSlug.TO_SIGN]: false,
        [StatusContractSlug.VALID]: true,
    },
    'vehicle.brand': {
        ...mappingFieldsDisabled['vehicle.brand'],
    },
    'vehicle.model': {
        ...mappingFieldsDisabled['vehicle.model'],
    },
};

export const isFieldRequired = (
    fieldName: keyof (typeof mappingFieldsRequired & typeof mappingFieldsBorneRequired),
    contractStatus: StatusContractSlug.DRAFT | StatusContractSlug.TO_SIGN | StatusContractSlug.VALID,
    isBorneProduct = false,
): boolean => {
    const mapping = isBorneProduct ? mappingFieldsBorneRequired : mappingFieldsRequired;
    const fieldMapping = mapping[fieldName as keyof typeof mapping];

    return fieldMapping?.[contractStatus] ?? true;
};
export const isFieldDisabled = (
    fieldName: keyof (typeof mappingFieldsDisabled & typeof mappingFieldsBorneDisabled),
    contractStatus: StatusContractSlug.DRAFT | StatusContractSlug.TO_SIGN | StatusContractSlug.VALID,
    isBorneProduct = false,
): boolean => {
    const mapping = isBorneProduct ? mappingFieldsBorneDisabled : mappingFieldsDisabled;
    const fieldMapping = mapping[fieldName as keyof typeof mapping];

    return fieldMapping?.[contractStatus] ?? true;
};

export const isBorneProduct = (product: string) => {
    const borneProducts = [210, 216, 226];
    return borneProducts.includes(Number(product));
};

const formatDate = (date: string) => dayjs(date).format('YYYY-MM-DD');

export const formatFieldBeforeSend = (data: any) => {
    data.subscriber.firstname = stripAccents(data?.subscriber?.firstname?.toUpperCase?.());
    data.subscriber.lastname = stripAccents(data?.subscriber?.lastname?.toUpperCase?.());
    if (!isBorneProduct(data.product)) {
        data.beneficiary.firstname = stripAccents(data?.beneficiary?.firstname?.toUpperCase?.());
        data.beneficiary.lastname = stripAccents(data?.beneficiary?.lastname?.toUpperCase?.());
    }
    data.offerFamily = data.offer === 'FAMILY';
    data.offerCollab = data.offer === 'COLLAB';

    if (data.dateSolution) {
        data.dateSolution = formatDate(data.dateSolution);
    }
    if (data.subscriptionDate) {
        data.subscriptionDate = formatDate(data.subscriptionDate);
    }
    if (data.orderDate) {
        data.orderDate = formatDate(data.orderDate);
    }
    if (data.subscriber?.birthDate) {
        data.subscriber.birthDate = formatDate(data.subscriber.birthDate);
    }
    if (data.beneficiary?.birthDate) {
        data.beneficiary.birthDate = formatDate(data.beneficiary.birthDate);
    }
    if (data.vehicle?.registrationDocumentDate) {
        data.vehicle.registrationDocumentDate = formatDate(data.vehicle.registrationDocumentDate);
    }
    if (data.vehicle?.registrationNumberDate) {
        data.vehicle.registrationNumberDate = formatDate(data.vehicle.registrationNumberDate);
    }

    return data;
};
