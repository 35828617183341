import React from 'react';
import { Table } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

import { Vehicle } from '@app/store/stock/stock.type';

import Price from '@app/components/formatters/price/Price';

import StockPriceLeadsButton from './StockPriceLeadsButton';
import { usePermissions } from '@app/hooks';

type StockPriceDetailsProps = {
    vehicle: Vehicle;
};

const StockPriceDetails = ({ vehicle }: StockPriceDetailsProps) => {
    const { hasPermission } = usePermissions();

    return (
        <div className="price-handler_detail-table pb-0">
            <Table className="mb-0">
                <thead>
                    <tr>
                        <th>
                            <FormattedHTMLMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.TABLE.ACTUAL_PRICE" />
                        </th>
                        <th>
                            <FormattedHTMLMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.TABLE.PRICE_SPOT" />
                        </th>
                        <th>
                            <FormattedHTMLMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.TABLE.AVERAGE_AGE" />
                        </th>
                        <th>
                            <FormattedHTMLMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.TABLE.LEADS_NUMBER" />
                        </th>
                        {hasPermission('STOCK_PRICING_RATING') && (
                            <th>
                                <FormattedHTMLMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.TABLE.TRUST_RATING" />
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="font-weight-bold">
                            <Price value={vehicle.price} />
                        </td>
                        <td className="font-weight-bold">
                            {vehicle.pricing?.spotPrice ? <Price value={vehicle.pricing?.spotPrice} /> : '-'}
                        </td>
                        <td className="font-weight-bold">
                            {vehicle.pricing?.averageSellingTime ? (
                                <>
                                    {vehicle.pricing?.averageSellingTime}{' '}
                                    <FormattedHTMLMessage id="STOCK_VEHICLES.TABLE.DAYS" />
                                </>
                            ) : (
                                '-'
                            )}
                        </td>
                        <td className="font-weight-bold">
                            <StockPriceLeadsButton vehicleRegistration={vehicle.license_number} variant="secondary" />
                        </td>
                        {vehicle?.pricing?.rating && hasPermission('STOCK_PRICING_RATING') && (
                            <td className="font-weight-bold">
                                <span>{vehicle.pricing.rating}</span>
                            </td>
                        )}
                    </tr>
                </tbody>
            </Table>
        </div>
    );
};

export default StockPriceDetails;
