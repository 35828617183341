import { Order, OrderInStore, Variant } from '@app/crud/apv/order.type';

/**
 * Get state color by order slug
 *
 * @param order
 * @returns {string}
 */
export function getStateBySlug(order: Order): Variant {
    switch (order.orderStatus.slug) {
        case 'expiree':
        case 'annulee':
        case 'rendez_vous_termine':
            return Variant.SECONDARY;
        case 'paiement_refuse':
            return Variant.DANGER;
        case 'paiement_en_attente_de_confirmation':
        case 'rendez_vous_a_confirmer':
            return Variant.WARNING;
        case 'rendez_vous_confirme':
            return Variant.SUCCESS;
        case 'en cours':
        default:
            return Variant.PRIMARY;
    }
}

/**
 * Get state color for an instore order
 */
export function getApvInStoreOrderStateBySlug(order: OrderInStore): Variant {
    switch (order.appointment?.orderAppointmentStatusApvInStore?.slug) {
        case 'confirmed':
            return Variant.SUCCESS;
        case 'closed':
        case 'canceled':
            return Variant.DARK;
        default:
            return Variant.PRIMARY;
    }
}

export const HIDDEN_STATUS = ['expiree', 'annulee', 'rendez_vous_termine'];
