import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import { actions } from '@app/store/modal/modal.store';

import Label from '@app/partials/content/Label';
import ModalDefault from '@app/partials/content/modals/Modal.default';

const ContactBackupAudi = ({ showModal, setShowModal, lead }) => {
    const dispatch = useDispatch();
    const Intl = useIntl();
    const sviBackupNumber = parsePhoneNumberFromString(lead?.sviBackupNumber || '', Locale.toUpperCase());

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-danger la-times-circle" />}
            titleVariant="text-danger"
            title={Intl.formatMessage({ id: 'CUSTOMER.MODAL.CONTACT.AUDI.BACKUP.TITLE' })}
            body={
                <div className="text-center">
                    <div className="pb-5 m-5">
                        {Intl.formatMessage({ id: 'CUSTOMER.MODAL.CONTACT.AUDI.BACKUP.COMPOSE.NUMBER' })}
                    </div>

                    <Label variant="primary" size="label-xxl" className="label-large-padding mb-5">
                        <span className="mb-0 h2 font-weight-bold">{sviBackupNumber?.formatNational?.()}</span>
                    </Label>

                    <div className="m-5">
                        <FormattedHTMLMessage id="CUSTOMER.MODAL.CONTACT.AUDI.TEXT" />
                    </div>
                </div>
            }
            footer={
                <div className="m-auto">
                    <Button variant="secondary" onClick={() => dispatch(actions.modalChange('contactAudi'))}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.PREVIOUS' })}
                    </Button>
                    <Button variant="primary" onClick={() => setShowModal(false)} className="ml-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default ContactBackupAudi;
