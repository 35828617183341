import React from 'react';
import { useIntl } from 'react-intl';

import { checkDateStatus } from '@app/helpers/UserHelper';

import { CustomerEvent } from '@app/crud/customers/customer.type';

import StatusLead from '@app/pages/customers/Partials/StatusLead';
import CommercialProposalStatus from './CommercialProposalStatus';

interface EventTypeFunctions {
    [key: string]: (event?: CustomerEvent) => string | JSX.Element;
}

const useStatusTemplate = () => {
    const Intl = useIntl();

    function statusIntervention(value: string) {
        switch (value) {
            case 'SENT':
                return Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.SENT' });
            case 'READ':
                return Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.READ' });
            case 'TO_BE_SENT':
                return Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.TO_BE_SENT' });
            case 'CLOSED':
                return Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.CLOSED' });
            case 'ANSWERED':
                return Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.ANSWERED' });
            default:
                return '-';
        }
    }
    // Here we create a default template for multiple events to avoid repeating the same template
    const autodeliveryRender = (event: CustomerEvent): JSX.Element => (
        <StatusLead statusName={event?.orderwebStatusLibelle && event?.orderwebStatusLibelle} />
    );

    const autopshereTechnicalRender = (event: CustomerEvent): JSX.Element => {
        let date = event?.leadStatusUpdated;
        date = event?.appointmentAppointmentDateStart;
        return (
            <StatusLead
                leadStatusUpdated={date}
                statusSlug={event?.appointmentOrderStatusSlug}
                statusName={event?.leadClosLibel || event?.appointmentOrderStatusName}
                table
            />
        );
    };

    const bcsAuditRender = (event: CustomerEvent): string => statusIntervention(event?.auditStatusIntervention) || '-';

    const statusTemplate: EventTypeFunctions = {
        DATASTORE_FUNDING: () => '-',
        ICARE_MAINTENANCE_CONTRACT: (event) => (
            <StatusLead statusName={event?.maintenanceContractStatutContract || '-'} />
        ),
        AUTODELIVERY_ORDERS_WEB_FOLLOWUP: (event) => autodeliveryRender(event),
        AUTODELIVERY_ORDERS_WEB_RESUME: (event) => autodeliveryRender(event),
        SECURYCAR_SC_TERMINATION: (event) => (
            <StatusLead
                statusSlug={
                    event?.scTerminationTerminationReasonSimple &&
                    event?.scTerminationTerminationReasonSimple === 'FINVALID' &&
                    ('fin de validité' || 'supprimé')
                }
                statusName={
                    event?.scTerminationTerminationReasonSimple &&
                    event?.scTerminationTerminationReasonSimple === 'FINVALID' &&
                    ('fin de validité' || 'supprimé')
                }
            />
        ),
        AUTOSPHERE_FR_TECHNICAL_INTERVENTION_APPOINTMENT_CANCELED: (event) => autopshereTechnicalRender(event),
        AUTOSPHERE_FR_TECHNICAL_INTERVENTION_APPOINTMENT: (event) => autopshereTechnicalRender(event),
        BCS_AUDIT_FOLLOWUP: (event) => bcsAuditRender(event),
        BCS_AUDIT_RESUME_FB: (event) => bcsAuditRender(event),
        BCS_AUDIT_RESUME_OTHER: (event) => bcsAuditRender(event),
        CIRANO_WARRANTY_CONTRACT: (event) => {
            const isActive = checkDateStatus(event?.endDate);
            const statusType = isActive ? 'success' : 'secondary';
            const statusName = isActive
                ? Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.STATUS.ACTIVE' })
                : Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.STATUS.INACTIVE' });
            return <StatusLead overridedStatus={statusType} statusName={statusName} />;
        },
        PLANET_VO2_COMMERCIAL_PROPOSAL: (event) => (
            <CommercialProposalStatus commercialProposal={event?.commercialProposal} />
        ),
    };

    // Function to retrieve the EventName template
    const getStatusTemplate = (event: CustomerEvent) => {
        const eventType = `${event?.srcApplicationName}_${event?.processType}`;
        const date = event?.leadStatusUpdated;

        const templateFunction =
            statusTemplate[eventType] ||
            (() => (
                <StatusLead
                    leadStatusUpdated={date}
                    statusSlug={event?.leadStatusSlug}
                    statusName={event?.leadClosLibel || event?.leadStatusName}
                    statusSubName={event?.leadStatusSubName}
                    table
                />
            ));

        return templateFunction(event);
    };
    return getStatusTemplate;
};

export default useStatusTemplate;
