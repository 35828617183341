import Swal from 'sweetalert2';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import {
    addOperation,
    deleteOperation,
    getOperation,
    getOperations,
    getSettings,
    updateOperationById,
} from '@app/crud/apv/commercial-operation.crud';
import { getDealerships } from '@app/crud/apv/dealership.crud';

import toast from '@app/partials/content/Toast';

import { actions } from './commercial-operation.store';

export function fetchOperations(params) {
    return (dispatch) => {
        dispatch(actions.fetchOperations());

        getOperations(params)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(
                        actions.fetchedOperations({
                            ...response.result,
                            count: response?.count || 0,
                        }),
                    );
                } else {
                    dispatch(actions.fetchOperationsFailed({}));
                }
            })
            .catch(() => {
                dispatch(actions.fetchOperationsFailed({}));
            });
    };
}

export function fetchOperation(id = null) {
    return (dispatch) => {
        dispatch(actions.fetchOperation());
        const values = [getSettings(), getDealerships()];
        if (id) {
            values.push(getOperation(id));
        }
        Promise.all(values)
            .then((v) => {
                const settings = v[0];
                const dealerships = v[1];
                const operation = id ? v[2] : null;

                if (settings?.result?.error) {
                    dispatch(actions.fetchOperationFailed(settings.result));
                } else if (dealerships?.result?.error) {
                    dispatch(actions.fetchOperationFailed(dealerships.result));
                } else if (operation?.result?.error) {
                    dispatch(actions.fetchOperationFailed(operation.result));
                } else {
                    let result = {
                        settings: settings.result,
                        dealerships: dealerships.result,
                    };
                    if (id) {
                        result = { ...result, operation: operation.result };
                    }
                    dispatch(actions.fetchedOperation(result));
                }
            })
            .catch(() => {
                dispatch(actions.fetchOperationFailed({}));
            });
    };
}

export function createOperation(params) {
    return (dispatch) => {
        dispatch(actions.addOperation());

        addOperation(params.data)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.addedOperation(response.result));
                    params.history.push(`/operations/${response.result.id}`);
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'APV.OPERATION.ADD.SUCCESS' }),
                    });
                } else {
                    dispatch(actions.addOperationFailed(response.result));
                    const msg = response.result.error_description
                        ? `${response.result.error_description} : ${response.result?.error_data?.image_name || null}`
                        : Intl.formatMessage({ id: 'APV.OPERATION.UPDATE.FAILED' });

                    toast({
                        variant: 'danger',
                        message: msg,
                    });
                }
            })
            .catch(() => {
                dispatch(actions.addOperationFailed({}));
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'APV.OPERATION.ADD.FAILED' }),
                });
            });
    };
}

export function updateOperation(id, data) {
    return (dispatch) => {
        dispatch(actions.updateOperation());

        updateOperationById(id, data)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.updatedOperation(response.result));
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'APV.OPERATION.UPDATE.SUCCESS' }),
                    });
                } else {
                    dispatch(actions.updateOperationFailed(response.result));

                    const msg = response.result.error_description
                        ? `${response.result.error_description} : ${response.result?.error_data?.image_name || null}`
                        : Intl.formatMessage({ id: 'APV.OPERATION.UPDATE.FAILED' });

                    toast({
                        variant: 'danger',
                        message: msg,
                    });
                }
            })
            .catch(() => {
                dispatch(actions.updateOperationFailed({}));
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'APV.OPERATION.UPDATE.FAILED' }),
                });
            });
    };
}

export function removeOperation(params) {
    return (dispatch) => {
        Swal.fire({
            title: Intl.formatMessage({ id: 'APV.OPERATION.REMOVE.ALERT.TITLE' }),
            text: Intl.formatMessage({ id: 'APV.OPERATION.REMOVE.ALERT.TEXT' }),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
            cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
            customClass: {
                confirmButton: 'btn mr-3 btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            preConfirm: () => {
                Swal.showLoading();
                dispatch(actions.removeOperation());

                return deleteOperation(params.id)
                    .then((response) => {
                        if (!response?.result?.error) {
                            params.history.push(`/operations`);
                            toast({
                                variant: 'success',
                                message: Intl.formatMessage({ id: 'APV.OPERATION.REMOVE.SUCCESS' }),
                            });
                        } else {
                            toast({
                                variant: 'danger',
                                message: Intl.formatMessage({ id: 'APV.OPERATION.REMOVE.FAILED' }),
                            });
                        }
                    })
                    .catch(() => {
                        toast({
                            variant: 'danger',
                            message: Intl.formatMessage({ id: 'APV.OPERATION.REMOVE.FAILED' }),
                        });
                    });
            },
        });
    };
}
