import dayjs from 'dayjs';
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { ReactElement, useEffect, useState } from 'react';
import { Image, Row } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import { capitalize } from '@app/helpers/StringHelper';
import { displayPhoneNumber } from '@app/helpers/ToolsHelper';

import { Customer } from '@app/crud/customers/customer.type';

import LeadStatusRow from '@app/pages/customers/Partials/LeadStatusRow';
import { Lead } from '@app/store/customers/customers.store';

import ActionsHandBand from './ActionsHandBand';

type AppointmentHandBandProps = {
    customer: Customer;
    lead: Lead;
    actions: ReactElement;
};
const AppointmentHandBand = ({ customer, lead, actions }: AppointmentHandBandProps) => {
    const UppercaseLocale = (Locale as string).toUpperCase() as CountryCode;
    const scheduleDate = dayjs(lead?.scheduleAppointment?.dateStart);

    const phone = parsePhoneNumberFromString(customer?.phone || '', UppercaseLocale);
    const mobile = parsePhoneNumberFromString(customer?.mobile || '', UppercaseLocale);
    const [isVisiblePhoneNumber, setIsVisiblePhoneNumber] = useState(undefined);

    useEffect(() => {
        setIsVisiblePhoneNumber(displayPhoneNumber(customer));
    }, [customer]);

    return (
        <div className="customer-handband my-lg-0 my-2">
            <Row className="font-size-h4 text-white headband__content">
                <div className="d-flex align-items-center col-4">
                    <Image
                        className="customer-handband__pic ml-5"
                        src="/media/pages/customers/fiche-client-rendez-vous.svg"
                    />
                    <div className="ml-5 mr-5 border-bottom">
                        <FormattedHTMLMessage
                            id="CUSTOMERS.HANDBAND.APPOINTMENT.NAME"
                            values={{
                                name: `${capitalize(customer?.firstname) || ''} ${
                                    capitalize(customer?.lastname) || ''
                                }`,
                            }}
                        />
                        <br />
                        <FormattedHTMLMessage
                            id="CUSTOMERS.HANDBAND.APPOINTMENT.DATE"
                            values={{
                                date: scheduleDate.isValid() ? scheduleDate.format('DD MMMM YYYY') : '',
                                hour: scheduleDate.isValid() ? scheduleDate.format('HH [h] mm') : '',
                            }}
                        />
                    </div>
                </div>
                {!customer.leads.some((leadItem) => ['constructeur'].includes(leadItem.leadType.slug)) ? (
                    <ActionsHandBand
                        actionTypes={[
                            'lead_manufacturer',
                            'local_apv',
                            'local_commerce',
                            'local_home_charge',
                            'contract_securycar',
                            'apv_in_store',
                        ]}
                    />
                ) : (
                    <ActionsHandBand
                        actionTypes={[
                            'local_apv',
                            'local_commerce',
                            'local_home_charge',
                            'contract_securycar',
                            'apv_in_store',
                        ]}
                    />
                )}
            </Row>
            <Row className="font-size-h4 text-white headband__content headband__content--scroll">
                {lead?.leadType?.name || ''}
                <LeadStatusRow
                    leadStatusSlug={lead?.leadStatus?.slug}
                    leadStatusName={lead?.leadStatus?.name}
                    leadStatusSubName={lead?.leadStatus?.subName}
                    variant="white"
                    className="d-flex align-items-center"
                />
                <Image
                    className="customer-handband__pic--small ml-5"
                    src="/media/pages/customers/fiche-client-rendez-vous.svg"
                />
                <div className="ml-lg-5 mx-lg-0 mx-5 text-lg-left text-center">
                    <FormattedHTMLMessage
                        id="CUSTOMERS.HANDBAND.APPOINTMENT.NAME"
                        values={{
                            name: `${customer?.civility || ''} ${capitalize(customer?.firstname) || ''} ${
                                capitalize(customer?.lastname) || ''
                            }`,
                        }}
                    />
                    {` `}
                    <FormattedHTMLMessage
                        id="CUSTOMERS.HANDBAND.APPOINTMENT.DATE"
                        values={{
                            date: scheduleDate.isValid() ? scheduleDate.format('DD/MM/YYYY') : '',
                            hour: scheduleDate.isValid() ? scheduleDate.format('HH [h] mm') : '',
                        }}
                    />
                </div>
                <div className="ml-lg-5 mx-lg-0 mx-5">{actions}</div>
                {(phone?.formatNational?.() || mobile?.formatNational?.()) && isVisiblePhoneNumber && (
                    <div className="ml-lg-5 mx-lg-0 mx-5">
                        <FormattedHTMLMessage id="CUSTOMERS.HANDBAND.PHONE" />
                        <span className="font-weight-bold text-warning">
                            {mobile?.formatNational?.() || phone?.formatNational?.()}
                        </span>
                    </div>
                )}
            </Row>
        </div>
    );
};

export default AppointmentHandBand;
