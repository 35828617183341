/**
 *
 * This component is copy paste from SR-front but here we only change class name to have specify class
 */

import React from 'react';

const CarBackSide = ({ width, height }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 837 703"
        xmlSpace="preserve"
        width={width}
        height={height}
    >
        <style type="text/css">
            {`
	.carBackSide0{fill:#124B84;}
	.carBackSide1{fill:none;}
	.carBackSide2{opacity:0.1;enable-background:new    ;}
	.carBackSide3{fill:#59595A;}
	.carBackSide4{fill:#808081;}
	.carBackSide5{fill:#124BB1;}
	.carBackSide6{fill:#EC004C;}
	.carBackSide7{fill:#540002;}
	.carBackSide8{fill:#3B3B3B;}
	.carBackSide9{fill:#8C9398;}
	.carBackSide10{fill:#FF0022;}
	.carBackSide11{fill:#AECFD4;}
	.carBackSide12{opacity:0.2;fill:#FFFFFF;enable-background:new    ;}
	.carBackSide13{fill:#FF6726;}
    `}
        </style>
        <g>
            <path
                className="carBackSide0"
                d="M828.4,443.7c-0.7-11-4.9-49.4-11.3-65.9l0,0c-4.8,3-9.7,5.8-14.7,8.4l-0.4,0.2c-67,34.9-148.6,37-157.8,38
		c-3,0.3-10.7,0.8-19.8,1.2v-0.1c-12,0.5-24.1,1-36.1,1.4c-14.1,0.5-28.3,0.9-42.4,1.2s-28.2,0.7-42.5,0.9l-84.9,0.2l-84.9-0.2
		c-14.2-0.1-28.3-0.6-42.5-0.9s-28.3-0.7-42.4-1.2c-10.9-0.4-21.7-0.8-32.6-1.2v0.1c-10.7-0.5-19.9-1-23.3-1.3
		c-9.2-1-90.8-3.1-157.8-38l-0.4-0.2c-5-2.6-9.9-5.4-14.7-8.4c-6.4,16.5-10.6,55-11.3,65.9s-0.4,38.7,0.1,50.1
		c0.4,9.7,2.9,37.6,4.8,54.8c0.3,3,0.6,5.6,0.9,7.8c1.9,14.9,7.3,42.7,9.1,51.3s6,8.3,6,8.3h5.9c0.7,0,0.9-0.6,0.8-1.2l53.7,1.8
		l28.5,1l9.8,0.3l-0.8-0.8l0,0l89.4,0.3h12.4v4.6h108.7l2.3-3h156.2l2.3,3h108.8v-4.6H620l89.4-0.3c0.7-0.7,1.3-1.3,1.9-2
		c-0.6,0.7-1.3,1.3-1.9,2l-0.8,0.8l9.8-0.3l28.5-1l53.7-1.9c0,0.6,0.1,1.2,0.8,1.2h5.9c0,0,4.2,0.3,6-8.3s7.2-36.4,9.1-51.3
		c0.3-2.2,0.6-4.8,0.9-7.8c1.9-17.2,4.4-45.1,4.8-54.8C828.8,482.4,829.1,454.6,828.4,443.7z"
            />
            <rect className="carBackSide1" width="837" height="703" />
            <path
                id="Tracé_2525"
                className="carBackSide2"
                d="M836.8,678.6c0.4,12.9-9.7,23.7-22.6,24.2H22.9c-12.9-0.5-23-11.3-22.6-24.2l0,0
		c-0.4-12.9,9.7-23.7,22.6-24.2h790.5c13.1,0.1,23.5,10.8,23.4,23.9L836.8,678.6z"
            />
            <path
                className="carBackSide0"
                d="M823.5,548.8v0.3c-0.1,0.6-0.1,1.2-0.2,1.8c0,0.1,0,0.2,0,0.4c-0.1,0.6-0.1,1.1-0.2,1.6c0,0.1,0,0.1,0,0.2
		c-0.1,0.6-0.1,1.1-0.2,1.6s-0.1,1.2-0.2,1.7c-1.9,14.9-7.3,42.7-9.1,51.3s-6,8.3-6,8.3h-5.9c-0.7,0-0.9-0.6-0.8-1.2l-53.8,1.9
		l-28.5,1l-9.8,0.3l0.8-0.8l1.3-1.3l-1.3,1.3l-89.4,0.3c0,0.2,0,0.4-0.1,0.7v0.1c-0.1,1-0.2,2.3-0.3,3.7c0,0.4-0.1,0.9-0.1,1.3
		c-0.1,0.7-0.1,1.5-0.2,2.2v0.2l-0.2,2.3c0,0,0,0.1,0,0.2c0,0.8-0.1,1.5-0.1,2.3v0.1c0,0.4,0,0.8,0,1.2c0,1.1-0.1,2.2-0.1,3.1l0,0
		c0,6.4,9.4,7.7,9.4,7.7s8.9,0.7,9.4,0.7c12.3,3.5,47.7-1.2,47.7-1.2c0.1,0.3,0.3,0.6,0.6,0.9c0.2,0.3,0.5,0.5,0.8,0.7
		c4.6,3.3,17.5,2.6,18.6,2.6l13-0.7v10.8l0,0c0,3.7,0.7,7.3,2,10.8c0.2,0.5,0.4,1,0.7,1.5c0.6,1.4,1.3,2.7,2.2,3.9
		c0.2,0.3,0.3,0.5,0.5,0.8c0.7,1,1.5,2,2.3,2.9c0.4,0.4,0.7,0.8,1.1,1.2c5.6,5.6,13.2,8.7,21.1,8.7h45.3c5.9,0,11.8-1.8,16.7-5.1
		c0.4-0.3,0.8-0.5,1.2-0.8c0.8-0.6,1.5-1.2,2.2-1.8c0.4-0.3,0.7-0.7,1-1s0.8-0.8,1.1-1.2c0.8-0.9,1.6-1.9,2.3-2.9
		c0.2-0.2,0.3-0.5,0.5-0.8c0.6-0.9,1.1-1.8,1.6-2.7c0.7-1.4,1.3-2.8,1.8-4.2c0.1-0.3,0.2-0.6,0.3-0.9c0.8-2.7,1.2-5.6,1.2-8.5l0,0
		L823.5,548.8L823.5,548.8z"
            />
            <path
                className="carBackSide0"
                d="M218,632.9L218,632.9c0-0.1,0-0.3,0-0.4v-0.7c0-0.2,0-0.3,0-0.5s0-0.4,0-0.7s0-0.3,0-0.5s0-0.4,0-0.7V629
		c0-0.2,0-0.5,0-0.7v-0.5c0-0.2,0-0.4,0-0.7s0-0.4,0-0.6s0-0.4,0-0.6s0-0.4,0-0.6s0-0.4-0.1-0.6v-0.5c0-0.2,0-0.4-0.1-0.6
		s0-0.4,0-0.5s0-0.4-0.1-0.6s0-0.3,0-0.5s0-0.4,0-0.6s0-0.3,0-0.5s0-0.3,0-0.5s0-0.3,0-0.5s0-0.4-0.1-0.6V619c0-0.2,0-0.5-0.1-0.7
		v-0.1c0-0.3,0-0.5-0.1-0.7l-89.4-0.3l0,0l0.8,0.8l-9.8-0.3l-28.5-1L37,614.8c0,0.6-0.1,1.2-0.8,1.2h-5.9c0,0-4.2,0.3-6-8.3
		s-7.2-36.4-9.1-51.3c-0.3-2.2-0.6-4.8-0.9-7.8v107.9l0,0c0,16.5,13.4,29.8,29.8,29.8l0,0h44.6c16.5,0,29.8-13.4,29.8-29.8l0,0l0,0
		v-10.8l13,0.7c1.2,0,17.8,1,20-4.2c0,0,35.4,4.7,47.7,1.2c0.5,0,9.4-0.7,9.4-0.7s9.4-1.2,9.4-7.7l0,0
		C218,634.3,218,633.6,218,632.9z M218,632.5v-0.7V632.5z M218,631.3c0-0.2,0-0.4,0-0.7C217.9,630.9,218,631.1,218,631.3z
		 M217.9,630.2c0-0.2,0-0.4,0-0.7C217.9,629.7,217.9,630,217.9,630.2L217.9,630.2z M217.9,629c0-0.2,0-0.4,0-0.7
		C217.8,628.5,217.8,628.8,217.9,629L217.9,629z M217.8,627.8c0-0.2,0-0.4,0-0.7C217.7,627.3,217.8,627.6,217.8,627.8L217.8,627.8z
		 M217.7,626.6c0-0.2,0-0.4,0-0.6C217.7,626.2,217.7,626.4,217.7,626.6L217.7,626.6z M217.6,625.4c0-0.2,0-0.4-0.1-0.6
		C217.6,624.9,217.6,625.2,217.6,625.4L217.6,625.4z M217.6,624.2c0-0.2,0-0.4,0-0.6C217.5,623.8,217.5,624,217.6,624.2L217.6,624.2
		z M217.5,623c0-0.2,0-0.4-0.1-0.6C217.4,622.6,217.4,622.8,217.5,623L217.5,623z M217.4,622c0-0.2,0-0.4,0-0.6
		C217.3,621.6,217.3,621.8,217.4,622L217.4,622z M217.3,620.9c0-0.2,0-0.3,0-0.5C217.2,620.6,217.2,620.7,217.3,620.9L217.3,620.9z
		 M217.2,619.9c0-0.2,0-0.4-0.1-0.5C217.1,619.5,217.2,619.7,217.2,619.9L217.2,619.9z M217.1,619c0-0.2,0-0.5-0.1-0.7
		C217.1,618.6,217.1,618.8,217.1,619L217.1,619z"
            />
            <path
                className="carBackSide0"
                d="M94,240.5c13.3-3.5,39.7,0.6,51.2,2.8h0.1c1.4,0.2,2.6,0.5,3.5,0.7l1.1,0.2l-9.2-25.1c0,0,1.4-4.1,3.4-9.4
		c0.3-0.8,0.6-1.6,0.9-2.4c0.9-2.4,1.8-5.1,2.8-7.6c0.5-1.3,0.9-2.6,1.4-3.8c1.1-2.9,2.1-5.5,3-7.6c3.9-9.7,10-24.1,10-24.1V164l0,0
		c0-0.1,0.1-0.2,0.1-0.4s0.2-0.6,0.4-1l0.1-0.3v-0.1c0.1-0.2,0.1-0.4,0.2-0.6v-0.1l0.3-0.6c0-0.1,0.1-0.2,0.1-0.3l0.3-0.6l0.1-0.2
		l0.3-0.7l0.1-0.3c0.1-0.2,0.2-0.5,0.3-0.7s0.1-0.3,0.2-0.4s0.2-0.4,0.3-0.7s0.1-0.3,0.2-0.4l0.3-0.7l0.2-0.5
		c0.1-0.2,0.2-0.5,0.3-0.8l0.2-0.5l0.3-0.8l0.2-0.5l0.3-0.8c0.1-0.2,0.2-0.4,0.2-0.6l0.3-0.8l0.3-0.6c0.1-0.3,0.2-0.5,0.3-0.8
		l0.3-0.6c0.1-0.3,0.2-0.5,0.4-0.8l0.3-0.6l0.4-0.8c0.1-0.2,0.2-0.4,0.3-0.7s0.2-0.5,0.4-0.8l0.3-0.7c0.1-0.3,0.2-0.5,0.4-0.8
		l0.3-0.6l0.4-0.8l0.3-0.6l0.4-0.8l0.3-0.6c0.1-0.2,0.2-0.5,0.4-0.8s0.2-0.4,0.3-0.6s0.2-0.5,0.4-0.8l0.3-0.6l0.4-0.7l0.3-0.6
		l0.4-0.7c0.1-0.2,0.2-0.4,0.3-0.6s0.2-0.4,0.4-0.7s0.2-0.4,0.3-0.5s0.2-0.4,0.4-0.7l0.3-0.5c0.1-0.2,0.2-0.4,0.4-0.6l0.2-0.4
		l0.4-0.7c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.6,0.6-0.9c6.2-9.4,12.2-19.1,27.8-24c0,0-0.7-2.2,3.7-3.9l0,0
		c-1.1-0.8-3.5-0.6-3.5-0.6c-31.5,6.4-47.6,38.1-54.4,48.8s-26.9,48.4-35.3,64.1c-2.4,4.7-5.2,9.1-8.3,13.3
		c-3.5,4.7-7.7,8.9-12.5,12.4C95,239.8,94.5,240.1,94,240.5L94,240.5L94,240.5z"
            />
            <path
                className="carBackSide0"
                d="M631.1,105.2L631.1,105.2L631.1,105.2c15.6,4.9,21.6,14.5,27.8,24c0.2,0.3,0.4,0.6,0.6,0.9
		c0.1,0.1,0.1,0.2,0.2,0.3l0.4,0.7l0.2,0.4l0.4,0.6l0.3,0.5c0.1,0.2,0.2,0.4,0.4,0.7s0.2,0.4,0.3,0.5s0.2,0.4,0.3,0.7
		s0.2,0.4,0.3,0.6l0.4,0.7l0.3,0.6c0.1,0.2,0.2,0.5,0.4,0.7l0.3,0.6c0.1,0.2,0.2,0.5,0.4,0.8s0.2,0.4,0.3,0.6s0.2,0.5,0.4,0.8
		l0.3,0.6l0.4,0.8l0.3,0.6l0.4,0.8l0.3,0.6c0.1,0.3,0.2,0.5,0.3,0.8l0.3,0.7c0.1,0.3,0.2,0.5,0.3,0.8s0.2,0.4,0.3,0.7l0.4,0.8
		l0.3,0.6c0.1,0.3,0.2,0.5,0.3,0.8l0.3,0.6c0.1,0.3,0.2,0.5,0.3,0.8l0.3,0.6l0.3,0.8c0.1,0.2,0.2,0.4,0.2,0.6l0.3,0.8l0.2,0.5
		l0.3,0.8l0.2,0.5c0.1,0.3,0.2,0.5,0.3,0.8l0.2,0.5l0.3,0.8l0.2,0.4l0.3,0.8c0,0.1,0.1,0.2,0.2,0.4s0.2,0.5,0.3,0.7l0.1,0.3l0.3,0.7
		l0.1,0.2l0.3,0.7c0,0.1,0,0.1,0.1,0.2l0.3,0.7v0.1c0.1,0.2,0.2,0.4,0.2,0.6v0.1l0.2,0.4l0.1,0.2c0.2,0.5,0.3,0.9,0.4,1v0.1
		c0,0,0.1,0.2,0.3,0.6l0.3,0.8c0.2,0.4,0.4,1,0.7,1.6c0.4,1,1,2.3,1.5,3.7c0.2,0.6,0.5,1.1,0.7,1.7l0.8,1.9l0.8,1.9l0.8,2l0.8,2
		l0.4,1l1.2,3c0.5,1.3,1.1,2.6,1.6,3.8c0.9,2.1,1.9,4.8,3,7.6c0.5,1.2,0.9,2.5,1.4,3.8c1,2.6,1.9,5.2,2.8,7.6
		c0.3,0.8,0.6,1.6,0.9,2.4c1.9,5.4,3.4,9.4,3.4,9.4l-9.2,25.2l0.8-0.1c7.7-1.5,39.6-7.4,54.9-3.6v-0.1l0,0c-4-2.7-7.6-5.9-10.7-9.6
		l-0.2-0.3c-0.5-0.6-1-1.2-1.6-1.9c-0.4-0.5-0.8-1-1.1-1.5c-0.8-1-1.6-2.2-2.4-3.3c-1.5-2.2-3-4.6-4.5-7.2c-0.5-0.9-1-1.8-1.5-2.8
		c-6.8-12.6-21.2-39.7-30.1-55.3l-1.7-3c-1.4-2.4-2.6-4.4-3.5-5.9c-0.5-0.8-1-1.6-1.6-2.6c-0.3-0.5-0.6-1-0.9-1.5
		c-0.5-0.8-1-1.7-1.6-2.6s-1.1-1.9-1.8-2.9c-0.9-1.5-1.9-3-3-4.6c-0.5-0.8-1-1.5-1.6-2.3c-1-1.4-2-2.9-3.2-4.3l-1.3-1.7
		c-9-11.3-21.8-22.7-39.4-26.3c0,0-2.4-0.3-3.5,0.6l0,0C631.8,103,631.1,105.2,631.1,105.2z"
            />
            <path
                className="carBackSide0"
                d="M802,386.4l0.4-0.2c5-2.6,9.9-5.4,14.7-8.4l0.1-0.1c-0.8-3.9-1.6-8.1-2.5-12.7c-0.1-0.3-0.1-0.6-0.2-0.8
		c-0.4-1.7-0.7-3.5-1.2-5.3c-0.7-3-1.4-6-2.2-9.1c-3.9-15.4-10-30.1-18.3-43.6c-3.6-5.8-7.6-11.3-12.1-16.5l0,0
		c0.4,9.6-0.5,8.4-0.5,8.4c0.2,2.1,0.2,4.2-0.1,6.3c-0.1,0.7-0.2,1.4-0.4,2.1s-0.4,1.4-0.7,2s-0.7,1.1-1.1,1.6
		c-0.2,0.2-0.5,0.5-0.7,0.6c-4.2,3-8.8,2.3-19.4,2.5c-4.2,0.1-37.9-0.5-77.2-1.3l19.7,2.5l-0.2,20v0.1c-0.2,1.2-0.4,2.4-0.6,3.5
		c-0.2,1.3-0.5,2.7-0.7,4l0,0c-0.4,2.1-0.8,4.3-1.2,6.4c0,0.2-0.1,0.3-0.1,0.5c-0.4,2.2-0.8,4.3-1.3,6.5c-1.4,7-3,13.9-4.8,20.7
		c-0.9,3.4-1.9,6.8-3,10.2c-0.5,1.7-1.2,3.4-1.8,5.1c-0.3,0.8-0.7,1.7-1.1,2.5s-0.7,1.7-1.1,2.5c-1.5,3.2-2.8,6.4-4.6,9.7
		c-3.3,7-9.3,12.3-16.7,14.6c-1.8,0.5-3.5,1.2-5.3,1.5l-2.7,0.6c-0.9,0.2-1.8,0.3-2.7,0.5c-7.1,1-14.2,1.6-21.4,1.9l-6.3,0.3v0.1
		c9.1-0.4,16.8-0.8,19.8-1.2C653.4,423.4,734.9,421.3,802,386.4z"
            />
            <path
                className="carBackSide0"
                d="M418,236.3c0.4-0.1,0.8-0.1,1.1-0.1h0.3c2.1,0.2,3.7,2,3.7,4.1v5c1.6-0.9,2.5-2.9,2.5-5.9c0-5.8-4.2-8-6.9-8
		l0,0c-2.7,0-5.3,1.2-7,3.3c-3.7,4.1-3.3,10.5,0.9,14.1c0.5,0.5,1.1,0.9,1.7,1.2c-1.4-1.6-2.3-3.7-2.3-5.8
		C411.9,239.4,415,236.8,418,236.3z"
            />
            <path
                className="carBackSide0"
                d="M423.2,232.4c1.7,1.3,3.1,3.5,3,7.3l0,0c-0.1,3.9-1.5,5.6-3.1,6.4l0,0c-1.4,0.6-3,0.7-4.5,0.5
		c-2.9-0.3-4.9-2.9-4.6-5.7c0.1-0.7,0.3-1.4,0.7-2.1c-1.3,1.5-2,3.5-2,5.5c-0.1,3.8,2.9,7,6.7,7.1c0.2,0,0.4,0,0.6,0
		c2.6,0.1,5.1-0.7,7.1-2.3c0.5-0.5,0.8-1.1,0.8-1.8v-7.1C427.9,237,426.1,233.9,423.2,232.4z"
            />
            <path
                className="carBackSide0"
                d="M35,386.4c67.1,34.9,148.6,37,157.8,38c3.4,0.4,12.7,0.9,23.3,1.3v-0.1l-9.8-0.4c-7.2-0.2-14.3-0.8-21.4-1.9
		c-0.9-0.1-1.8-0.3-2.7-0.5l-2.7-0.6c-1.8-0.3-3.6-1-5.3-1.5c-7.4-2.4-13.4-7.7-16.7-14.7c-1.7-3.2-3.1-6.5-4.6-9.7
		c-0.4-0.8-0.7-1.6-1.1-2.5s-0.7-1.7-1.1-2.5c-0.6-1.7-1.3-3.4-1.8-5.1c-1.1-3.4-2.1-6.8-3-10.2c-1.9-6.9-3.4-13.8-4.9-20.7
		c-0.4-2.2-0.9-4.3-1.3-6.5l-0.1-0.5c-0.4-2.1-0.8-4.3-1.2-6.4l0,0c-0.2-1.3-0.5-2.7-0.7-4l0,0c-0.2-1.2-0.4-2.3-0.6-3.5v-0.1
		l-0.2-20l19.6-2.5c-39.3,0.8-73,1.4-77.2,1.3c-10.7-0.2-15.3,0.4-19.4-2.5S56.8,298,56.8,298l0,0c-0.1,0.1-0.7,0.2-0.6-5.3l0,0
		c0-0.4,0-0.9,0-1.4v-0.1c0-0.5,0-1.1,0.1-1.7l0,0c-2.9,3.4-5.5,6.8-7.9,10.2c-1.4,2.1-2.8,4.1-4.1,6.2
		c-16.6,26.6-20.7,53.4-24.4,71.6l0,0c4.8,3,9.7,5.8,14.7,8.4L35,386.4z"
            />
            <path
                className="carBackSide3"
                d="M90,616.7l-53.7-1.9c0,0.6-0.1,1.2-0.8,1.2h-5.9c0,0-4.2,0.3-6-8.3s-7.2-36.4-9.1-51.3
		c-0.3-2.2-0.6-4.8-0.9-7.8v107.9l0,0c0,16.5,13.4,29.8,29.8,29.8l0,0h45.3c16.5,0,29.8-13.4,29.8-29.8l0,0l0,0v-10.8l-20.3-1
		L90,616.7z"
            />
            <path
                className="carBackSide3"
                d="M823.5,548.8v0.3c-0.1,0.6-0.1,1.2-0.2,1.8c0,0.1,0,0.2,0,0.4c-0.1,0.6-0.1,1.1-0.2,1.6c0,0.1,0,0.1,0,0.2
		c-0.1,0.6-0.1,1.1-0.2,1.6s-0.1,1.2-0.2,1.7c-1.9,14.9-7.3,42.7-9.1,51.3s-6,8.3-6,8.3h-5.9c-0.7,0-0.9-0.6-0.8-1.2l-53.8,1.9
		l-8.2,27.9l-20.3,1v10.8l0,0c0,3.7,0.7,7.3,2,10.8c0.2,0.5,0.4,1,0.7,1.5c0.6,1.4,1.3,2.7,2.2,3.9c0.2,0.3,0.3,0.5,0.5,0.8
		c0.7,1,1.5,2,2.3,2.9c0.4,0.4,0.7,0.8,1.1,1.2c5.6,5.6,13.2,8.7,21.1,8.7h45.3c5.9,0,11.8-1.8,16.7-5.1c0.4-0.3,0.8-0.5,1.2-0.8
		c0.8-0.6,1.5-1.2,2.2-1.8c0.4-0.3,0.7-0.7,1-1s0.8-0.8,1.1-1.2c0.8-0.9,1.6-1.9,2.3-2.9c0.2-0.2,0.3-0.5,0.5-0.8
		c0.6-0.9,1.1-1.8,1.6-2.7c0.7-1.4,1.3-2.8,1.8-4.2c0.1-0.3,0.2-0.6,0.3-0.9c0.8-2.7,1.2-5.6,1.2-8.5L823.5,548.8L823.5,548.8z"
            />
            <path
                d="M127.5,617.2l0.8,0.8l-9.8-0.3l-28.5-1l8.2,27.9l20.3,1l13,0.7c1.2,0,17.8,1,20-4.2c0,0,35.4,4.7,47.7,1.2
		c0.5,0,9.4-0.7,9.4-0.7s9.4-1.2,9.4-7.7l0,0c0-5-0.8-13.8-1.1-17.4L127.5,617.2z"
            />
            <path
                d="M718.5,617.7l-9.8,0.3l0.8-0.8l-89.5,0.3c0,0.2,0,0.4-0.1,0.7v0.1c-0.1,1-0.2,2.3-0.3,3.7c0,0.4-0.1,0.9-0.1,1.3
		c-0.1,0.7-0.1,1.5-0.2,2.2v0.2l-0.2,2.3c0,0,0,0.1,0,0.2c0,0.8-0.1,1.5-0.1,2.3v0.1c0,0.4,0,0.8,0,1.2c0,1.1-0.1,2.2-0.1,3.1l0,0
		c0,6.4,9.4,7.7,9.4,7.7s8.9,0.7,9.4,0.7c12.3,3.5,47.7-1.2,47.7-1.2c0.1,0.3,0.3,0.6,0.6,0.9c0.2,0.3,0.5,0.5,0.8,0.7
		c4.6,3.3,17.5,2.6,18.6,2.6l13-0.7l20.3-1l8.2-27.9L718.5,617.7z"
            />
            <path className="carBackSide0" d="M620,617.5l89.4-0.3l1.3-1.3l-1.3,1.3L620,617.5z" />
            <path
                className="carBackSide0"
                d="M823.5,656.4L823.5,656.4c0,2.9-0.4,5.7-1.2,8.5C823.1,662.1,823.5,659.3,823.5,656.4z"
            />
            <path className="carBackSide0" d="M218,633.9c-0.1-5.1-0.8-13.1-1.1-16.4C217.3,620.9,218,628.8,218,633.9z" />
            <path className="carBackSide0" d="M127.5,617.2l0.8,0.8L127.5,617.2z" />
            <path
                className="carBackSide0"
                d="M94,240.5c13.3-3.5,39.7,0.6,51.2,2.8h0.1c1.4,0.2,2.6,0.5,3.5,0.7l1.1,0.2l-9.2-25.1c0,0,1.4-4.1,3.4-9.4
		c0.3-0.8,0.6-1.6,0.9-2.4c0.9-2.4,1.8-5.1,2.8-7.6c0.5-1.3,0.9-2.6,1.4-3.8c1.1-2.9,2.1-5.5,3-7.6c3.9-9.7,10-24.1,10-24.1V164l0,0
		c0-0.1,0.1-0.2,0.1-0.4s0.2-0.6,0.4-1l0.1-0.3v-0.1c0.1-0.2,0.1-0.4,0.2-0.6v-0.1l0.3-0.6c0-0.1,0.1-0.2,0.1-0.3l0.3-0.6l0.1-0.2
		l0.3-0.7l0.1-0.3c0.1-0.2,0.2-0.5,0.3-0.7s0.1-0.3,0.2-0.4s0.2-0.4,0.3-0.7s0.1-0.3,0.2-0.4l0.3-0.7l0.2-0.5
		c0.1-0.2,0.2-0.5,0.3-0.8l0.2-0.5l0.3-0.8l0.2-0.5l0.3-0.8c0.1-0.2,0.2-0.4,0.2-0.6l0.3-0.8l0.3-0.6c0.1-0.3,0.2-0.5,0.3-0.8
		l0.3-0.6c0.1-0.3,0.2-0.5,0.4-0.8l0.3-0.6l0.4-0.8c0.1-0.2,0.2-0.4,0.3-0.7s0.2-0.5,0.4-0.8l0.3-0.7c0.1-0.3,0.2-0.5,0.4-0.8
		l0.3-0.6l0.4-0.8l0.3-0.6l0.4-0.8l0.3-0.6c0.1-0.2,0.2-0.5,0.4-0.8s0.2-0.4,0.3-0.6s0.2-0.5,0.4-0.8l0.3-0.6l0.4-0.7l0.3-0.6
		l0.4-0.7c0.1-0.2,0.2-0.4,0.3-0.6s0.2-0.4,0.4-0.7s0.2-0.4,0.3-0.5s0.2-0.4,0.4-0.7l0.3-0.5c0.1-0.2,0.2-0.4,0.4-0.6l0.2-0.4
		l0.4-0.7c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.6,0.6-0.9c6.2-9.4,12.2-19.1,27.8-24c0,0-0.7-2.2,3.7-3.9l0,0
		c-1.1-0.8-3.5-0.6-3.5-0.6c-31.5,6.4-47.6,38.1-54.4,48.8s-26.9,48.4-35.3,64.1c-2.4,4.7-5.2,9.1-8.3,13.3
		c-3.5,4.7-7.7,8.9-12.5,12.4C95,239.8,94.5,240.1,94,240.5L94,240.5L94,240.5z"
            />
            <path
                className="carBackSide0"
                d="M631.1,105.2L631.1,105.2L631.1,105.2c15.6,4.9,21.6,14.5,27.8,24c0.2,0.3,0.4,0.6,0.6,0.9
		c0.1,0.1,0.1,0.2,0.2,0.3l0.4,0.7l0.2,0.4l0.4,0.6l0.3,0.5c0.1,0.2,0.2,0.4,0.4,0.7s0.2,0.4,0.3,0.5s0.2,0.4,0.3,0.7
		s0.2,0.4,0.3,0.6l0.4,0.7l0.3,0.6c0.1,0.2,0.2,0.5,0.4,0.7l0.3,0.6c0.1,0.2,0.2,0.5,0.4,0.8s0.2,0.4,0.3,0.6s0.2,0.5,0.4,0.8
		l0.3,0.6l0.4,0.8l0.3,0.6l0.4,0.8l0.3,0.6c0.1,0.3,0.2,0.5,0.3,0.8l0.3,0.7c0.1,0.3,0.2,0.5,0.3,0.8s0.2,0.4,0.3,0.7l0.4,0.8
		l0.3,0.6c0.1,0.3,0.2,0.5,0.3,0.8l0.3,0.6c0.1,0.3,0.2,0.5,0.3,0.8l0.3,0.6l0.3,0.8c0.1,0.2,0.2,0.4,0.2,0.6l0.3,0.8l0.2,0.5
		l0.3,0.8l0.2,0.5c0.1,0.3,0.2,0.5,0.3,0.8l0.2,0.5l0.3,0.8l0.2,0.4l0.3,0.8c0,0.1,0.1,0.2,0.2,0.4s0.2,0.5,0.3,0.7l0.1,0.3l0.3,0.7
		l0.1,0.2l0.3,0.7c0,0.1,0,0.1,0.1,0.2l0.3,0.7v0.1c0.1,0.2,0.2,0.4,0.2,0.6v0.1l0.2,0.4l0.1,0.2c0.2,0.5,0.3,0.9,0.4,1v0.1
		c0,0,0.1,0.2,0.3,0.6l0.3,0.8c0.2,0.4,0.4,1,0.7,1.6c0.4,1,1,2.3,1.5,3.7c0.2,0.6,0.5,1.1,0.7,1.7l0.8,1.9l0.8,1.9l0.8,2l0.8,2
		l0.4,1l1.2,3c0.5,1.3,1.1,2.6,1.6,3.8c0.9,2.1,1.9,4.8,3,7.6c0.5,1.2,0.9,2.5,1.4,3.8c1,2.6,1.9,5.2,2.8,7.6
		c0.3,0.8,0.6,1.6,0.9,2.4c1.9,5.4,3.4,9.4,3.4,9.4l-9.2,25.2l0.8-0.1c7.7-1.5,39.6-7.4,54.9-3.6v-0.1l0,0c-4-2.7-7.6-5.9-10.7-9.6
		l-0.2-0.3c-0.5-0.6-1-1.2-1.6-1.9c-0.4-0.5-0.8-1-1.1-1.5c-0.8-1-1.6-2.2-2.4-3.3c-1.5-2.2-3-4.6-4.5-7.2c-0.5-0.9-1-1.8-1.5-2.8
		c-6.8-12.6-21.2-39.7-30.1-55.3l-1.7-3c-1.4-2.4-2.6-4.4-3.5-5.9c-0.5-0.8-1-1.6-1.6-2.6c-0.3-0.5-0.6-1-0.9-1.5
		c-0.5-0.8-1-1.7-1.6-2.6s-1.1-1.9-1.8-2.9c-0.9-1.5-1.9-3-3-4.6c-0.5-0.8-1-1.5-1.6-2.3c-1-1.4-2-2.9-3.2-4.3l-1.3-1.7
		c-9-11.3-21.8-22.7-39.4-26.3c0,0-2.4-0.3-3.5,0.6l0,0C631.8,103,631.1,105.2,631.1,105.2z"
            />
            <path
                className="carBackSide0"
                d="M814.4,364.2c-0.4-1.7-0.7-3.5-1.2-5.3c-0.7-3-1.4-6-2.2-9.1c-3.9-15.4-10-30.1-18.3-43.6
		c-3.6-5.8-7.6-11.3-12.1-16.5l0,0c0.4,9.6-0.5,8.4-0.5,8.4c0.2,2.1,0.2,4.2-0.1,6.3c-0.1,0.7-0.2,1.4-0.4,2.1s-0.4,1.4-0.7,2
		s-0.7,1.1-1.1,1.6c-0.2,0.2-0.5,0.5-0.7,0.6c-4.2,3-8.8,2.3-19.4,2.5c-4.2,0.1-37.9-0.5-77.2-1.3l19.7,2.5l-0.2,20v0.1
		c-0.2,1.2-0.4,2.4-0.6,3.5c9.2,1.9,18.4,3.9,27.6,6c8.4,2,16.7,4,25.1,6.2s16.7,4.3,24.9,7.2c4.1,1.4,8.1,3.3,11.8,5.7
		c3.7,2.4,6.8,5.7,9,9.5c0.5,1,1.1,1.9,1.5,2.9l1.2,3c0.7,2,1.3,4.1,1.8,6.2c0.1,0.5,0.2,1,0.3,1.5c5-2.6,9.9-5.4,14.7-8.4l0.1-0.1
		c-0.8-3.9-1.6-8.1-2.5-12.7C814.5,364.8,814.5,364.5,814.4,364.2z"
            />
            <path
                className="carBackSide0"
                d="M799.5,378.7l-1.3-2.9c-0.5-1-1.1-1.8-1.6-2.8c-2.3-3.6-5.3-6.6-8.9-8.8c-3.6-2.2-7.5-3.9-11.6-5.2
		c-8.1-2.6-16.5-4.4-24.9-6.3s-16.8-3.7-25.2-5.4c-9.2-1.9-18.3-3.6-27.5-5.3l0,0c-0.4,2.1-0.8,4.3-1.2,6.4c0,0.2-0.1,0.3-0.1,0.5
		c-0.4,2.2-0.9,4.3-1.3,6.5c-1.4,7-3,13.9-4.8,20.7c-0.9,3.4-1.9,6.9-3,10.2c-0.5,1.7-1.2,3.4-1.8,5.1c-0.3,0.8-0.7,1.7-1.1,2.5
		s-0.7,1.7-1.1,2.5c-1.5,3.2-2.8,6.4-4.6,9.7c-3.3,7-9.3,12.3-16.7,14.6c-1.8,0.5-3.5,1.2-5.3,1.5l-2.7,0.6
		c-0.9,0.2-1.8,0.3-2.7,0.5c-7.1,1-14.2,1.6-21.4,1.9l-6.3,0.3v0.1c9.1-0.4,16.8-0.8,19.8-1.2c9.2-1,90.7-3.1,157.8-38
		c-0.1-0.5-0.3-1.1-0.4-1.6C801,382.7,800.2,380.7,799.5,378.7z"
            />
            <path
                className="carBackSide0"
                d="M184.9,423.4c-0.9-0.1-1.8-0.3-2.7-0.5l-2.7-0.6c-1.8-0.3-3.6-1-5.3-1.5c-7.4-2.4-13.4-7.7-16.7-14.7
		c-1.7-3.2-3.1-6.5-4.6-9.7c-0.4-0.8-0.7-1.6-1.1-2.5s-0.7-1.7-1.1-2.5c-0.6-1.7-1.3-3.4-1.8-5.1c-1.1-3.4-2.1-6.8-3-10.2
		c-1.9-6.9-3.4-13.8-4.9-20.7c-0.4-2.2-0.9-4.3-1.3-6.5l-0.1-0.5c-0.4-2.1-0.8-4.3-1.2-6.4l0,0c-9.2,1.7-18.4,3.4-27.6,5.3
		c-8.4,1.7-16.8,3.5-25.2,5.4s-16.8,3.8-24.9,6.3c-4,1.3-7.9,3-11.6,5.1c-3.6,2.2-6.6,5.2-8.9,8.8c-0.5,0.9-1.1,1.8-1.6,2.8
		l-1.3,2.9c-0.8,2-1.4,4-2,6.1c-0.2,0.5-0.3,1.1-0.4,1.6c67.1,34.9,148.6,37,157.8,38c3.4,0.4,12.7,0.9,23.3,1.3v-0.1l-9.8-0.4
		C199.2,425,192,424.4,184.9,423.4z"
            />
            <path
                className="carBackSide0"
                d="M38,375.4c0.4-1,1-1.9,1.5-2.9c2.2-3.8,5.3-7,9-9.5c3.7-2.4,7.6-4.3,11.8-5.7c8.2-2.9,16.5-4.9,24.9-7.2
		s16.7-4.2,25.1-6.2c9.2-2.1,18.4-4.1,27.6-6c-0.2-1.2-0.4-2.3-0.6-3.5v-0.1l-0.2-20l19.6-2.5c-39.3,0.8-73,1.4-77.2,1.3
		c-10.7-0.2-15.3,0.4-19.4-2.5S57,298,57,298l0,0c-0.1,0.1-0.7,0.2-0.6-5.3l0,0c0-0.4,0-0.9,0-1.4v-0.1c0-0.5,0-1.1,0.1-1.7l0,0
		c-2.9,3.4-5.5,6.8-7.9,10.2c-1.4,2.1-2.8,4.1-4.1,6.2c-16.6,26.6-20.7,53.4-24.4,71.6l0,0c4.8,3,9.7,5.8,14.7,8.4
		c0.1-0.5,0.2-1,0.3-1.5c0.5-2.1,1.1-4.2,1.8-6.2L38,375.4z"
            />
            <path
                className="carBackSide4"
                d="M418,236.3c0.4-0.1,0.8-0.1,1.1-0.1h0.3c2.1,0.2,3.7,2,3.7,4.1v5c1.6-0.9,2.5-2.9,2.5-5.9c0-5.8-4.2-8-6.9-8
		l0,0c-2.7,0-5.3,1.2-7,3.3c-3.7,4.1-3.3,10.5,0.9,14.1c0.5,0.5,1.1,0.9,1.7,1.2c-1.4-1.6-2.3-3.7-2.3-5.8
		C411.9,239.4,415,236.8,418,236.3z"
            />
            <path
                className="carBackSide4"
                d="M423.2,232.4c1.7,1.3,3.1,3.5,3,7.3l0,0c-0.1,3.9-1.5,5.6-3.1,6.4l0,0c-1.4,0.6-3,0.7-4.5,0.5
		c-2.9-0.3-4.9-2.9-4.6-5.7c0.1-0.7,0.3-1.4,0.7-2.1c-1.3,1.5-2,3.5-2,5.5c-0.1,3.8,2.9,7,6.7,7.1c0.2,0,0.4,0,0.6,0
		c2.6,0.1,5.1-0.7,7.1-2.3c0.5-0.5,0.8-1.1,0.8-1.8v-7.1C427.9,237,426.1,233.9,423.2,232.4z"
            />
            <path
                className="carBackSide5"
                d="M802.1,384.6c-0.5-2.1-1.1-4.2-1.8-6.2l-1.2-3c-0.4-1-1-1.9-1.5-2.9c-2.2-3.8-5.3-7-9-9.5
		c-3.7-2.4-7.6-4.3-11.8-5.7c-8.2-2.9-16.5-5-24.9-7.2s-16.7-4.2-25.1-6.2c-9.2-2.1-18.3-4.1-27.6-6c-0.2,1.3-0.5,2.7-0.7,4l0,0
		c9.2,1.7,18.4,3.4,27.5,5.3c8.4,1.7,16.8,3.5,25.2,5.4s16.8,3.8,24.9,6.3c4,1.3,7.9,3,11.6,5.2c3.6,2.2,6.6,5.2,8.9,8.8
		c0.5,0.9,1.1,1.8,1.6,2.8l1.3,2.9c0.7,2,1.5,4,2,6.1c0.2,0.5,0.3,1.1,0.4,1.6l0.4-0.2C802.3,385.7,802.2,385.2,802.1,384.6z"
            />
            <path
                className="carBackSide5"
                d="M137.8,338.1L137.8,338.1c-9.2,1.9-18.4,3.9-27.6,6c-8.4,2-16.8,4-25.1,6.2s-16.7,4.3-24.9,7.2
		c-4.1,1.4-8.1,3.3-11.8,5.7c-3.7,2.4-6.8,5.7-9,9.5c-0.5,1-1.1,1.9-1.5,2.9l-1.2,3c-0.7,2-1.3,4.1-1.8,6.2c-0.1,0.5-0.2,1-0.3,1.5
		l0.4,0.2c0.1-0.5,0.3-1.1,0.4-1.6c0.6-2.1,1.2-4.1,2-6.1l1.3-2.9c0.5-1,1.1-1.8,1.6-2.8c2.3-3.6,5.3-6.6,8.9-8.8
		c3.6-2.2,7.5-3.9,11.6-5.1c8.1-2.6,16.5-4.4,24.9-6.4s16.8-3.7,25.2-5.4c9.2-1.9,18.4-3.6,27.6-5.3l0,0
		C138.3,340.7,138,339.4,137.8,338.1z"
            />
            <path
                className="carBackSide6"
                d="M333.1,587.2c-0.2-0.2-0.5-0.4-0.7-0.6C332.7,586.8,332.9,587,333.1,587.2z"
            />
            <path
                className="carBackSide6"
                d="M332.5,586.6c-0.2-0.2-0.4-0.4-0.5-0.5C332.1,586.2,332.3,586.4,332.5,586.6z"
            />
            <path className="carBackSide6" d="M664,570.3L664,570.3L664,570.3z" />
            <path className="carBackSide6" d="M485,597.6l-0.4,0.2L485,597.6z" />
            <path className="carBackSide6" d="M173.7,568.8L173.7,568.8C173.7,568.9,173.7,568.8,173.7,568.8z" />
            <path className="carBackSide6" d="M486.5,597.1l-0.4,0.2L486.5,597.1z" />
            <path className="carBackSide6" d="M173.8,569L173.8,569z" />
            <path className="carBackSide6" d="M718.5,582.9h-2.3H718.5z" />
            <path className="carBackSide6" d="M664.3,568.2h0.2H664.3z" />
            <path className="carBackSide6" d="M664.6,568.2h0.2H664.6z" />
            <path className="carBackSide6" d="M665.1,568.1L665.1,568.1z" />
            <path className="carBackSide6" d="M664.8,568.1h0.2H664.8z" />
            <path className="carBackSide6" d="M721,582.8h-1.6H721z" />
            <path className="carBackSide6" d="M663.6,569.9L663.6,569.9L663.6,569.9z" />
            <path className="carBackSide6" d="M722.6,582.8h-1.2H722.6z" />
            <path className="carBackSide6" d="M663.2,569L663.2,569z" />
            <path className="carBackSide6" d="M115.6,582.8h-1.2H115.6z" />
            <path className="carBackSide6" d="M663.4,569.6L663.4,569.6C663.3,569.5,663.4,569.6,663.4,569.6z" />
            <path className="carBackSide6" d="M664,568.3h0.2H664z" />
            <path className="carBackSide6" d="M663.7,568.4l0.2-0.1L663.7,568.4z" />
            <path className="carBackSide6" d="M663.3,568.8L663.3,568.8C663.3,568.7,663.3,568.8,663.3,568.8z" />
            <path className="carBackSide6" d="M663.3,569.3L663.3,569.3L663.3,569.3z" />
            <path
                className="carBackSide6"
                d="M126.3,498.3c0.2-0.4,0.5-0.9,0.7-1.3C126.8,497.4,126.6,497.8,126.3,498.3z"
            />
            <path className="carBackSide6" d="M131,490.9c0.9-1.2,1.8-2.4,2.7-3.5C132.8,488.5,131.9,489.7,131,490.9z" />
            <path className="carBackSide6" d="M127.4,496.5l1-1.6L127.4,496.5z" />
            <path className="carBackSide6" d="M719.6,514.5l-1.1-2.2l-1.7-3.2l1.7,3.2L719.6,514.5z" />
            <path
                className="carBackSide6"
                d="M718.5,607.4c-1.6,1.9-3.4,3.9-5.2,5.8C715.1,611.3,716.8,609.3,718.5,607.4z"
            />
            <path className="carBackSide6" d="M125,500.5l1-1.8L125,500.5z" />
            <path className="carBackSide6" d="M33.2,612.3c0-0.1-0.1-0.3-0.1-0.5C33.2,612,33.2,612.1,33.2,612.3z" />
            <path className="carBackSide6" d="M33.1,611.7c0-0.2-0.1-0.5-0.2-0.9C33,611.2,33.1,611.5,33.1,611.7z" />
            <polygon className="carBackSide6" points="258.6,472.8 258.6,472.8 274.6,559.5 	" />
            <path className="carBackSide6" d="M117.6,582.9H116H117.6z" />
            <path className="carBackSide6" d="M32.8,610.3c-0.1-0.4-0.2-0.8-0.3-1.3C32.6,609.5,32.7,610,32.8,610.3z" />
            <path className="carBackSide6" d="M173.8,569.2L173.8,569.2L173.8,569.2z" />
            <path className="carBackSide6" d="M123.8,583L123.8,583z" />
            <path className="carBackSide6" d="M120.9,582.9L120.9,582.9z" />
            <path className="carBackSide6" d="M119.9,582.9L119.9,582.9z" />
            <path className="carBackSide6" d="M125.1,583L125.1,583z" />
            <path className="carBackSide6" d="M122.2,583L122.2,583z" />
            <path className="carBackSide6" d="M173.5,569.8l-0.1,0.1L173.5,569.8z" />
            <path className="carBackSide6" d="M173.7,569.5c0,0,0,0.1-0.1,0.1C173.6,569.6,173.7,569.5,173.7,569.5z" />
            <path className="carBackSide6" d="M129.8,583h-3.2H129.8z" />
            <path className="carBackSide6" d="M173.1,570.2l-0.1,0.1L173.1,570.2z" />
            <path className="carBackSide6" d="M665.3,568.1L665.3,568.1z" />
            <path className="carBackSide6" d="M712,583h-1.5h2.3H712z" />
            <path className="carBackSide6" d="M663.6,568.4c-0.1,0-0.1,0.1-0.2,0.2l0,0L663.6,568.4z" />
            <path
                className="carBackSide0"
                d="M802,386.4c-67,34.9-148.6,37-157.8,38c-3,0.3-10.7,0.8-19.8,1.2v-0.1c-12,0.5-24.1,1-36.1,1.4
		c-14.1,0.5-28.3,0.9-42.4,1.2s-28.2,0.7-42.5,0.9l-84.9,0.2l-84.9-0.2c-14.2-0.1-28.3-0.6-42.5-0.9s-28.3-0.7-42.4-1.2
		c-10.9-0.4-21.7-0.8-32.6-1.2v0.1c-10.7-0.5-19.9-1-23.3-1.3c-9.2-1-90.8-3.1-157.8-38l-0.4-0.2c-5-2.6-9.9-5.4-14.7-8.4
		c-0.6,2.8-1.1,5.9-1.5,9.3s-0.9,7-1.2,10.9c-0.1,1-0.2,1.9-0.3,2.9c-0.2,2-0.3,4-0.5,6c-1.7,21.2-2.3,45.7-2.3,61.1
		c0,2.9,0,5.8,0,8.5c0,1.9,0.1,3.8,0.1,5.6c0,0.9,0.1,1.7,0.1,2.5c0.1,4,0.3,7.9,0.6,11.8c0.2,2.6,0.4,5.2,0.6,7.8
		c0.1,1.3,0.2,2.6,0.3,4c0.2,2.6,0.5,5.3,0.8,8.1c0.6,5.5,1.2,11.3,1.9,17.5c1.4,12,4.7,30.1,7.9,45.9c0.2,1,0.4,2,0.6,3
		s0.4,2,0.6,3c0.4,1.9,0.8,3.8,1.2,5.6c0.2,0.9,0.4,1.8,0.6,2.7c0.3,1.3,0.5,2.5,0.8,3.8c0.2,0.8,0.3,1.6,0.5,2.3
		c0.1,0.4,0.2,0.7,0.2,1.1c0.6,2.9,1.2,5.4,1.6,7.3c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0.5,0.2,0.9,0.3,1.3l0.1,0.5
		c0.1,0.3,0.1,0.6,0.2,0.9s0.1,0.4,0.1,0.5c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.1,0.4,0.2,0.5c0.2,0.3,0.4,0.6,0.6,0.9
		c0.1,0.1,0.3,0.2,0.5,0.3H35h0.2h0.1h1l53.8,2l28.5,1l9.8,0.3l-0.8-0.8c-2-2-3.8-4-5.6-6c-1.2-1.3-2.3-2.6-3.4-3.9l0,0
		c-0.5-0.6-1.1-1.2-1.6-1.9c-1.1-1.3-2.1-2.5-3-3.8c-10.5-13.4-15.8-25.5-15.8-36.4c0-4.3,1.5-10.2,3.9-17.1
		c0.5-1.5,1.1-3.1,1.7-4.7c0.6-1.6,1.3-3.2,2-4.8c2.4-5.7,5.2-11.7,8.1-17.5c0.8-1.7,1.7-3.3,2.5-4.9c0.7-1.3,1.4-2.6,2.1-3.9
		l0.5-0.9c0.8-1.6,1.7-3.1,2.5-4.6s1.6-2.9,2.4-4.3c0.4-0.7,0.8-1.4,1.2-2l1-1.8c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.4,0.5-0.9,0.7-1.3
		l0.3-0.5l0.9-1.6c0.9-1.4,1.8-2.8,2.6-4s1.8-2.4,2.7-3.5c0.4-0.4,0.8-0.9,1.1-1.2c0.8-0.8,1.5-1.5,2.3-2.3c0.4-0.4,0.8-0.7,1.2-1
		c1.1-1,2.3-1.8,3.5-2.7c0.8-0.5,1.6-1,2.4-1.5s1.6-0.9,2.4-1.2c1.2-0.6,2.4-1,3.7-1.5c1.6-0.6,3.3-1,5-1.4c1.2-0.3,2.5-0.5,3.8-0.7
		c2.1-0.3,4.2-0.5,6.2-0.6l1.2-0.1l2.6-0.1c0.9,0,1.8-0.1,2.8-0.1c1.4-0.1,2.9-0.1,4.4-0.1h1.5l3-0.1c3.5-0.1,7-0.1,10.1-0.1h3.8
		c4.7,0,7.9,0,7.9,0h432c0,0,2.7,0,6.8,0h3.6c3.4,0,7.4,0,11.4,0.1c5,0.1,10.1,0.2,14.2,0.5c13.2,0.8,27.2,3.3,38.2,21.6
		c1.7,2.9,3.6,6.2,5.7,9.8c0.8,1.4,1.6,2.9,2.5,4.5l1.7,3.2l1.1,2.2c0.7,1.4,1.5,2.8,2.2,4.2c1.2,2.5,2.5,5,3.7,7.5
		c0.8,1.7,1.6,3.3,2.4,5c1.2,2.5,2.3,5,3.3,7.5c4.5,10.6,7.6,20.4,7.6,26.5c0,10.9-5.4,23-15.7,36.4c-1.4,1.8-3,3.7-4.6,5.6l0,0
		c-1.6,1.9-3.4,3.9-5.2,5.8c-0.6,0.7-1.2,1.3-1.9,2c-0.6,0.7-1.3,1.3-1.9,2l-0.8,0.8l9.8-0.3l28.5-1l53.7-1.9h1c0,0,1.4,0.2,2-2.5
		c0.8-3.3,12-53.7,14.8-78.6c2.2-19.7,3.8-34.7,4.3-51.7c0.1-4.5,0.2-9.2,0.2-14.1c-0.2-23.4-1.5-68-5.9-90.1
		c-4.8,3-9.7,5.8-14.7,8.4L802,386.4z M182.1,458.7c-17.8,0.6-35.5,0.5-53.3-0.3c-4.4-0.2-8.9-0.4-13.3-0.7
		c-2.2-0.2-4.5-0.3-6.7-0.6s-4.5-0.4-6.7-0.8c-9-1.1-17.8-2.9-26.5-5.5c-8.7-2.5-17-6.2-24.7-11c-3.8-2.4-7.4-5.4-10.5-8.7
		c-1.5-1.7-2.9-3.5-4.1-5.4c-0.6-1-1.1-2-1.5-3.1c-0.4-1-0.6-2.2-0.6-3.3c0.5,2.1,1.5,4.1,3,5.8c1.4,1.7,2.9,3.2,4.6,4.6
		c3.3,2.8,7,5.2,10.9,7.1c3.8,2,7.8,3.7,11.9,5c4.1,1.4,8.3,2.6,12.5,3.7s8.5,1.8,12.8,2.5s8.6,1.3,13,1.7s8.7,0.8,13.1,1l13.2,0.6
		c17.6,0.8,35.3,1.3,53,1.8l53.1,1.1C217.7,456.8,199.9,458.2,182.1,458.7z M802.2,422.7c-0.4,1.1-0.9,2.1-1.5,3.1
		c-1.2,1.9-2.6,3.8-4.1,5.4c-3.1,3.3-6.6,6.2-10.5,8.7c-7.7,4.8-16,8.5-24.7,11c-8.6,2.6-17.5,4.4-26.5,5.5
		c-2.2,0.3-4.5,0.5-6.7,0.8s-4.5,0.4-6.7,0.6c-4.5,0.3-8.9,0.5-13.3,0.7c-17.8,0.8-35.5,0.9-53.3,0.3c-17.8-0.5-35.5-1.9-53.1-4.3
		l53.1-1.1c17.7-0.5,35.3-1,53-1.8l13.2-0.6c4.4-0.2,8.7-0.5,13.1-1s8.7-1,13-1.7s8.6-1.5,12.8-2.5s8.4-2.3,12.5-3.7
		c4.1-1.4,8.1-3.1,12-5.1c3.9-1.9,7.5-4.3,10.9-7.1c1.7-1.4,3.2-2.9,4.6-4.6c1.5-1.6,2.5-3.6,3-5.8
		C802.9,420.6,802.6,421.7,802.2,422.7L802.2,422.7z"
            />
            <path
                d="M35.2,614.7L35.2,614.7h-0.3h-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.3-0.5-0.6-0.6-0.9c-0.1-0.2-0.2-0.4-0.2-0.5
		c-0.1-0.2-0.1-0.5-0.2-0.7c0-0.1-0.1-0.3-0.1-0.5s-0.1-0.5-0.2-0.9l-0.1-0.5c-0.1-0.4-0.2-0.8-0.3-1.3c-0.1-0.2-0.1-0.5-0.2-0.8
		c-0.4-1.9-1-4.4-1.6-7.3c-0.1-0.4-0.1-0.7-0.2-1.1c-0.2-0.8-0.3-1.5-0.5-2.3c-0.3-1.2-0.5-2.5-0.8-3.8c-0.2-0.9-0.4-1.8-0.6-2.7
		c-0.4-1.8-0.8-3.7-1.2-5.6c-0.2-1-0.4-2-0.6-3s-0.4-2-0.6-3c-3.2-15.8-6.5-33.8-7.9-45.9c-0.7-6.2-1.3-12-1.9-17.5
		c-0.3-2.8-0.5-5.4-0.8-8.1c-0.1-1.3-0.2-2.6-0.3-4c-0.2-2.6-0.4-5.2-0.6-7.8c-0.3-3.9-0.5-7.8-0.6-11.8c0-0.8-0.1-1.7-0.1-2.5
		c0-1.8-0.1-3.7-0.1-5.6c0-2.8-0.1-5.6,0-8.5c0.1-15.4,0.7-39.9,2.4-61.1c0.2-2,0.3-4,0.5-6c0.1-1,0.2-2,0.3-2.9
		c0.4-3.8,0.8-7.5,1.2-10.9s1-6.5,1.5-9.3c-6.4,16.5-10.6,55-11.3,65.9s-0.4,38.7,0.1,50.1c0.4,9.7,2.9,37.6,4.8,54.8
		c0.3,3,0.6,5.6,0.9,7.8c1.9,14.9,7.3,42.7,9.1,51.3s6,8.3,6,8.3h5.9c0.7,0,0.9-0.6,0.8-1.2L35.2,614.7z"
            />
            <path
                d="M828.4,443.7c-0.7-11-4.9-49.4-11.3-65.9l0,0c4.4,22.1,5.8,66.7,5.9,90.1c0,4.9,0,9.6-0.2,14.1c-0.5,17-2.1,32-4.3,51.6
		c-2.8,24.9-14.1,75.2-14.8,78.6c-0.6,2.7-2,2.5-2,2.5h-1c0,0.6,0.1,1.2,0.8,1.2h5.9c0,0,4.2,0.3,6-8.3s7.2-36.4,9.1-51.3
		c0.3-2.2,0.6-4.8,0.9-7.8c1.9-17.2,4.4-45.1,4.8-54.8C828.8,482.4,829.1,454.6,828.4,443.7z"
            />
            <path
                d="M727.9,531.3c-0.8-1.7-1.6-3.3-2.4-5c-1.2-2.5-2.5-5-3.7-7.5c-0.7-1.4-1.4-2.8-2.2-4.2l-1.1-2.2l-1.7-3.2
		c-0.8-1.5-1.7-3-2.5-4.5c-2-3.6-3.9-6.9-5.7-9.8c-11-18.3-25-20.8-38.2-21.6c-4.1-0.3-9.2-0.4-14.2-0.5c-4-0.1-8-0.1-11.4-0.1h-3.6
		c-4.1,0-6.8,0-6.8,0h-56.2l-16,86.8H274.6l-16-86.8h-56.2c0,0-3.2,0-7.9,0h-3.8c-3.1,0-6.6,0-10.1,0.1l-3,0.1h-1.5
		c-1.5,0-2.9,0.1-4.4,0.1c-0.9,0-1.9,0.1-2.8,0.1l-2.6,0.1l-1.2,0.1c-2.1,0.1-4.2,0.4-6.2,0.6c-1.2,0.2-2.5,0.4-3.8,0.7
		c-1.7,0.4-3.4,0.8-5,1.4c-1.3,0.4-2.5,0.9-3.7,1.5c-0.8,0.4-1.6,0.8-2.4,1.2s-1.6,0.9-2.4,1.5c-1.2,0.8-2.4,1.7-3.5,2.7
		c-0.4,0.3-0.8,0.7-1.2,1c-0.8,0.7-1.5,1.5-2.3,2.3c-0.4,0.4-0.8,0.8-1.1,1.2c-0.9,1.1-1.8,2.2-2.7,3.5s-1.8,2.6-2.6,4l-0.9,1.6
		L127,497c-0.2,0.4-0.5,0.8-0.7,1.3c-0.1,0.2-0.2,0.3-0.3,0.5l-1,1.8c-0.4,0.7-0.8,1.4-1.2,2c-0.8,1.4-1.6,2.8-2.4,4.3
		s-1.7,3-2.5,4.6l-0.5,0.9c-0.7,1.3-1.4,2.6-2.1,3.9c-0.8,1.6-1.7,3.3-2.5,4.9c-2.9,5.8-5.7,11.8-8.1,17.5c-0.7,1.6-1.3,3.2-2,4.8
		s-1.2,3.1-1.7,4.7c-2.4,6.8-3.9,12.8-3.9,17.1c0,10.9,5.4,23,15.7,36.4c1,1.2,2,2.5,3,3.8c0.5,0.6,1,1.2,1.6,1.9l0,0
		c1.1,1.3,2.2,2.6,3.4,3.9c1.8,2,3.6,3.9,5.6,6l0,0l89.4,0.3h12.4v4.6H338l2.3-3h156.2l2.3,3h108.8v-4.6H620l89.4-0.3
		c0.7-0.7,1.3-1.3,1.9-2s1.2-1.3,1.9-2c1.8-2,3.6-3.9,5.2-5.8l0,0c1.6-1.9,3.2-3.8,4.6-5.6c10.4-13.4,15.7-25.5,15.7-36.4
		c0-6.2-3.2-15.9-7.6-26.5C730.2,536.3,729,533.8,727.9,531.3z M173.8,569.2L173.8,569.2c0,0.1,0,0.2-0.1,0.3c0,0,0,0.1-0.1,0.1
		c0,0.1-0.1,0.1-0.1,0.2l-0.1,0.1l-0.2,0.2l-0.1,0.1l-0.5,0.4l-0.7,0.6l-1.7,1.5c-0.3,0.3-0.6,0.6-1,0.9l-2.1,2c-1.1,1-2.3,2-3.5,3
		c-0.4,0.3-0.8,0.6-1.2,0.9s-0.8,0.6-1.2,0.9c-0.7,0.5-1.5,1-2.3,1.4c-0.4,0.2-0.7,0.4-1.1,0.5c-0.6,0.3-1.3,0.5-1.9,0.5
		c-1.2,0.1-4.4,0.2-8.5,0.2h-3.2h-5.3l-5.5-0.1h-3.6h-3.2h-1.5h-0.2h-1.1h-1.7h-1.3h-2.4h-0.9H116h-0.4h-1.2h-0.1h-0.2h-0.2
		c-0.4,0-0.8,0-1.2-0.2c-0.3-0.1-0.5-0.2-0.8-0.3s-0.6-0.3-0.8-0.5c-0.6-0.5-1.2-1.1-1.6-1.9c-1.6-3.1-3-6.4-4.2-9.7
		c-0.3-0.5-0.4-1.1-0.2-1.7c0.1-0.2,0.2-0.4,0.3-0.6c0.5-0.5,1.1-0.8,1.8-0.9c0.2,0,0.5-0.1,0.8-0.1s0.6,0,0.9,0l0,0l9.5,0.2
		l53.1,0.8l0,0c0.6,0,1.2,0.1,1.8,0.3c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.2c0,0,0,0,0,0.1v0.1v0.2
		C173.7,569.1,173.7,569.1,173.8,569.2L173.8,569.2z M228,549.3c-11.7,0.4-23.4,0.5-35.2,0.4c-2.9-0.1-5.8,0-8.8-0.2l-4.5-0.2
		c-1.5-0.1-3-0.2-4.4-0.4c-3-0.2-5.9-0.7-8.9-1.1s-5.9-1-8.7-1.8c-5.8-1.3-11.5-3.1-17-5.2c-5.5-2.1-10.8-4.8-15.8-8
		c5.4,2.3,10.9,4.1,16.6,5.7c5.6,1.5,11.3,2.8,17,3.8c2.8,0.5,5.7,0.8,8.6,1.2c2.9,0.2,5.7,0.7,8.6,0.8c1.4,0.1,2.9,0.2,4.3,0.3
		l4.3,0.1c2.9,0.1,5.8,0.1,8.8,0.1c11.7,0.1,23.4,0.3,35.1,0.7s23.4,0.8,35.2,1.7C251.4,548.3,239.7,549,228,549.3z M501.9,588.5
		c-4.6,2.8-8.3,5.3-12.5,7.3c-0.5,0.2-1,0.5-1.5,0.7s-0.9,0.4-1.4,0.6l-0.4,0.2l-1.1,0.4l-0.4,0.2l-1.5,0.5
		c-0.8,0.2-1.6,0.4-2.4,0.6c-1.3,0.3-2.6,0.6-4,0.8c-4.6,0.7-9.3,1-14,1h-4.5h-5c-10.5,0-23.4,0-34.6,0s-24.1,0-34.6,0h-5h-4.5
		c-12.6-0.1-19.8-1.8-25.8-4.4c-1.3-0.6-2.6-1.2-3.8-1.9c-0.6-0.3-1.2-0.7-1.8-1.1s-1.2-0.7-1.9-1.1l-1.9-1.2c-1-0.6-2-1.2-3-1.9
		l-1-0.7c-0.7-0.4-1.4-0.9-2-1.4c-0.2-0.2-0.5-0.4-0.7-0.6l0,0c-0.2-0.2-0.4-0.4-0.5-0.5c-0.2-0.2-0.3-0.5-0.4-0.8
		c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.5-0.3,0.8-0.3c0.3-0.1,0.6-0.2,0.9-0.3
		c0.9-0.2,1.7-0.3,2.6-0.4c0.4,0,0.7-0.1,1-0.1c1.3-0.1,2.2,0,2.2,0h157C497.1,582.5,512.2,582.2,501.9,588.5L501.9,588.5z
		 M657.6,549.4l-4.5,0.2c-3,0.1-5.9,0.1-8.8,0.2c-11.7,0.1-23.5,0-35.2-0.4s-23.5-1-35.2-2.2c11.7-0.9,23.5-1.4,35.2-1.7
		s23.4-0.6,35.1-0.7c2.9,0,5.9,0,8.8-0.1l4.3-0.1c1.4,0,2.9-0.2,4.3-0.3c2.9-0.1,5.7-0.5,8.6-0.8c2.9-0.4,5.7-0.7,8.6-1.2
		c5.7-1,11.4-2.2,17-3.8c5.6-1.5,11.2-3.4,16.6-5.7c-5,3.2-10.3,5.9-15.8,8c-5.5,2.2-11.2,3.9-17,5.2c-2.9,0.7-5.8,1.2-8.7,1.8
		s-5.9,0.9-8.8,1.1C660.5,549.1,659,549.3,657.6,549.4L657.6,549.4z M731.6,570.4c0,0-0.9,2.4-2,5.1c-0.2,0.5-0.5,1.1-0.7,1.6
		c-0.1,0.3-0.2,0.5-0.4,0.8c-0.4,0.8-0.7,1.5-1.1,2.2c-0.3,0.6-0.7,1.1-1.1,1.5c-0.1,0.1-0.3,0.2-0.4,0.3c-0.8,0.6-1.8,0.9-2.8,0.9
		h-0.2h-0.3h-1.2H721h-1.6h-0.9h-2.3h-1.3h-1.4h-0.7h-2.3h-1.6h-1.6l-9.1,0.1h-5.3h-3.2c-4.1,0-7.3,0-8.5-0.2
		c-2.1-0.2-4.9-2-7.7-4.3c-1.2-1-2.4-2-3.5-3l-2.2-2c-0.3-0.3-0.7-0.6-1-0.9l-1.7-1.5l-0.7-0.6L664,570l-0.1-0.1l-0.2-0.2l-0.1-0.1
		c-0.1-0.1-0.1-0.1-0.1-0.2l-0.1-0.1c0-0.1,0-0.1-0.1-0.2V569c0,0,0-0.1,0-0.2v0.2v-0.1c0,0,0,0,0-0.1s0.1-0.1,0.1-0.2
		c0.1-0.1,0.1-0.1,0.2-0.2h0.1l0.2-0.1h0.1h0.2h0.1h0.2h0.1h0.2h0.1h0.2h0.5l53-0.8l9.5-0.2C733.5,567.1,731.6,570.4,731.6,570.4
		L731.6,570.4z"
            />
            <path d="M407,558.5h152.9v-74.6H277.1v74.7L407,558.5L407,558.5z M285.9,495.1h265.3v53.2H285.9V495.1z" />
            <path
                className="carBackSide7"
                d="M173.7,568.8L173.7,568.8c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.6-0.3-1.2-0.4-1.8-0.3l0,0
		l-53.1-0.8l-9.5-0.2l0,0c-0.3,0-0.6,0-0.9,0s-0.6,0.1-0.8,0.1c-0.7,0.1-1.3,0.4-1.8,0.9c-0.1,0.2-0.2,0.4-0.3,0.6
		c-0.1,0.6,0,1.1,0.2,1.7c1.2,3.3,2.6,6.5,4.2,9.7c0.4,0.7,0.9,1.4,1.6,1.9c0.2,0.2,0.5,0.4,0.8,0.5c0.2,0.1,0.5,0.2,0.8,0.3
		c0.4,0.1,0.8,0.2,1.2,0.2h0.2h0.2h0.1h1.2h0.3h1.6h0.9h2.4h1.3h1.7h1.1h0.2h1.5h3.2h3.6l5.5,0.1h5.3h3.2c4.1,0,7.3,0,8.5-0.2
		c0.7-0.1,1.3-0.3,1.9-0.5c0.4-0.2,0.7-0.3,1.1-0.5c0.8-0.4,1.6-0.9,2.3-1.4c0.4-0.3,0.8-0.6,1.2-0.9s0.8-0.6,1.2-0.9
		c1.2-1,2.4-2,3.5-3l2.1-2c0.3-0.3,0.7-0.6,1-0.9l1.7-1.5l0.7-0.6l0.5-0.4l0.1-0.1l0.2-0.2l0.1-0.1c0.1-0.1,0.1-0.1,0.1-0.2l0.1-0.1
		c0-0.1,0.1-0.1,0.1-0.2L173.7,568.8L173.7,568.8v0.2C173.8,568.9,173.8,568.9,173.7,568.8z"
            />
            <path className="carBackSide7" d="M173.7,568.8L173.7,568.8C173.7,568.9,173.7,568.9,173.7,568.8z" />
            <path className="carBackSide7" d="M119.5,582.9L119.5,582.9z" />
            <path className="carBackSide7" d="M122.1,583h-1.3H122.1z" />
            <path className="carBackSide7" d="M120.7,582.9L120.7,582.9z" />
            <path className="carBackSide7" d="M116,582.8L116,582.8z" />
            <path
                className="carBackSide7"
                d="M171.6,568.1c0.6,0,1.2,0.1,1.8,0.3C172.8,568.2,172.2,568.1,171.6,568.1z"
            />
            <path
                className="carBackSide7"
                d="M173.6,569.6c0,0.1-0.1,0.1-0.1,0.2C173.5,569.7,173.6,569.7,173.6,569.6z"
            />
            <path className="carBackSide7" d="M173.4,569.9l-0.2,0.2L173.4,569.9z" />
            <path className="carBackSide7" d="M173,570.3l-0.5,0.4L173,570.3z" />
            <path className="carBackSide7" d="M173.8,569c0,0,0,0.1,0,0.2C173.8,569.1,173.8,569,173.8,569z" />
            <path className="carBackSide7" d="M173.7,569.3c0,0,0,0.1-0.1,0.2S173.7,569.3,173.7,569.3z" />
            <path className="carBackSide7" d="M124.9,583h-1.1H124.9z" />
            <path className="carBackSide7" d="M169.2,573.8l-2.1,2L169.2,573.8z" />
            <path className="carBackSide7" d="M173.6,568.6c0,0,0.1,0.1,0.1,0.2C173.7,568.7,173.6,568.6,173.6,568.6z" />
            <path className="carBackSide7" d="M126.5,583H125H126.5z" />
            <path className="carBackSide7" d="M123.4,583L123.4,583z" />
            <path
                className="carBackSide7"
                d="M712.7,583h2.1h1.3h2.3h0.9h1.6h0.4h1.2h0.3h0.2c1,0,2-0.3,2.8-0.9c0.1-0.1,0.3-0.2,0.4-0.3
		c0.5-0.4,0.8-1,1.1-1.5c0.3-0.6,0.7-1.4,1.1-2.2c0.1-0.3,0.2-0.5,0.4-0.8c0.2-0.5,0.5-1.1,0.7-1.6c1.1-2.7,2-5.1,2-5.1
		s1.9-3.3-3.6-3.3l-9.5,0.2l-53,0.8H665h-0.2h-0.1h-0.2h-0.1h-0.2h-0.1H664h-0.1l-0.2,0.1h-0.1l-0.2,0.2l0,0c-0.1,0-0.1,0.1-0.1,0.2
		c0,0,0,0,0,0.1v0.1v0.2c0,0,0,0.1,0,0.2v0.1c0,0,0,0.1,0.1,0.2c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2l0.1,0.1l0.2,0.2l0.1,0.1
		l0.5,0.4l0.7,0.6l1.7,1.5c0.3,0.3,0.6,0.6,1,0.9l2.2,2c1.1,1,2.3,2,3.5,3c2.8,2.2,5.6,4.1,7.7,4.3c1.2,0.1,4.4,0.2,8.5,0.2h3.2h5.3
		l9.1-0.1h3.2h1.5L712.7,583z"
            />
            <path className="carBackSide7" d="M708.9,583h1.6h-3.2H708.9z" />
            <path className="carBackSide7" d="M663.9,568.3L663.9,568.3L663.9,568.3z" />
            <path className="carBackSide7" d="M664.5,570.7l-0.5-0.4L664.5,570.7z" />
            <path className="carBackSide7" d="M663.6,568.4L663.6,568.4L663.6,568.4z" />
            <path className="carBackSide7" d="M663.2,569.2c0,0,0-0.1,0-0.2C663.2,569.1,663.2,569.1,663.2,569.2z" />
            <path className="carBackSide7" d="M663.9,570.2l-0.2-0.2L663.9,570.2z" />
            <path
                className="carBackSide7"
                d="M663.5,569.8c-0.1-0.1-0.1-0.1-0.1-0.2C663.4,569.7,663.5,569.7,663.5,569.8z"
            />
            <path className="carBackSide7" d="M663.3,569.5c0-0.1,0-0.1-0.1-0.2S663.3,569.4,663.3,569.5z" />
            <path
                className="carBackSide7"
                d="M663.3,568.8c0-0.1,0.1-0.1,0.1-0.2C663.4,568.6,663.3,568.7,663.3,568.8z"
            />
            <path className="carBackSide7" d="M665.2,568.1L665.2,568.1z" />
            <path className="carBackSide7" d="M721.4,582.8L721.4,582.8z" />
            <path className="carBackSide7" d="M664.1,568.2L664.1,568.2L664.1,568.2z" />
            <path className="carBackSide7" d="M665,568.1L665,568.1z" />
            <path className="carBackSide7" d="M664.7,568.1L664.7,568.1z" />
            <path className="carBackSide7" d="M664.4,568.2L664.4,568.2L664.4,568.2z" />
            <path className="carBackSide7" d="M663.2,569L663.2,569L663.2,569z" />
            <path className="carBackSide7" d="M713.5,583h1.4h-2.1H713.5z" />
            <path
                className="carBackSide7"
                d="M497.1,582.5H339.9c0,0-1,0-2.2,0c-0.3,0-0.6,0-1,0.1c-0.9,0.1-1.7,0.2-2.6,0.4c-0.3,0.1-0.6,0.2-0.9,0.3
		s-0.5,0.2-0.8,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.1,0.4,0,0.6c0.1,0.3,0.2,0.5,0.4,0.8
		c0.1,0.2,0.3,0.4,0.5,0.5l0,0c0.2,0.2,0.4,0.4,0.7,0.6c0.6,0.5,1.3,1,2,1.4l1,0.7c1,0.6,2,1.3,3,1.9l1.9,1.2
		c0.6,0.4,1.2,0.8,1.9,1.1s1.2,0.7,1.8,1.1c1.2,0.7,2.5,1.3,3.8,1.9c5.9,2.6,13.2,4.3,25.8,4.4h4.5h5c10.5,0,23.4,0,34.6,0
		s24.1,0,34.6,0h5h4.5c4.7,0,9.4-0.3,14-1c1.4-0.2,2.8-0.5,4-0.8c0.8-0.2,1.6-0.4,2.4-0.6l1.5-0.5l0.4-0.2l1.1-0.4l0.4-0.2
		c0.5-0.2,0.9-0.4,1.4-0.6s1-0.4,1.5-0.7c4.2-2,7.8-4.5,12.5-7.3C512.2,582.2,497.1,582.5,497.1,582.5z"
            />
            <path
                className="carBackSide7"
                d="M331.8,584.1c0.2-0.2,0.4-0.3,0.6-0.4C332.2,583.8,332,583.9,331.8,584.1z"
            />
            <path className="carBackSide7" d="M486.1,597.2l-1.1,0.4L486.1,597.2z" />
            <path className="carBackSide7" d="M484.6,597.8l-1.5,0.5L484.6,597.8z" />
            <path className="carBackSide7" d="M332.5,586.6" />
            <path
                className="carBackSide7"
                d="M487.9,596.5c-0.4,0.2-0.9,0.4-1.4,0.6C487,596.9,487.5,596.7,487.9,596.5z"
            />
            <path
                className="carBackSide8"
                d="M322.2,559.5h240.1l16-86.8H258.6l16,86.8H322.2z M277.1,483.9h282.7v74.7H277.1V483.9z"
            />
            <path
                className="carBackSide8"
                d="M192.8,544.8c-2.9,0-5.9,0-8.8-0.1l-4.3-0.1c-1.4,0-2.9-0.2-4.3-0.3c-2.9-0.1-5.7-0.5-8.6-0.8
		c-2.9-0.4-5.7-0.7-8.6-1.2c-5.7-1-11.4-2.2-17-3.8c-5.6-1.5-11.2-3.4-16.6-5.7c5,3.2,10.3,5.9,15.8,8c5.5,2.2,11.2,3.9,17,5.2
		c2.9,0.7,5.8,1.2,8.7,1.8s5.9,0.9,8.9,1.1c1.5,0.1,3,0.3,4.4,0.4l4.5,0.2c3,0.1,5.9,0.1,8.8,0.2c11.7,0.1,23.5,0,35.2-0.4
		s23.4-1,35.2-2.2c-11.7-0.9-23.4-1.4-35.2-1.7S204.5,544.9,192.8,544.8z"
            />
            <path
                className="carBackSide8"
                d="M679.5,546.1c5.8-1.3,11.5-3.1,17-5.2c5.5-2.1,10.8-4.8,15.8-8c-5.4,2.3-10.9,4.1-16.6,5.7
		c-5.6,1.5-11.3,2.8-17,3.8c-2.8,0.5-5.7,0.8-8.6,1.2c-2.9,0.2-5.7,0.7-8.6,0.8c-1.4,0.1-2.9,0.2-4.3,0.3l-4.3,0.1
		c-2.9,0.1-5.8,0.1-8.8,0.1c-11.7,0.1-23.4,0.3-35.1,0.7s-23.4,0.8-35.2,1.7c11.7,1.2,23.4,1.8,35.2,2.2s23.5,0.5,35.2,0.4
		c2.9-0.1,5.8,0,8.8-0.2l4.5-0.2c1.5-0.1,3-0.2,4.4-0.4c3-0.2,5.9-0.7,8.8-1.1S676.7,546.8,679.5,546.1z"
            />
            <rect x="285.9" y="495.1" className="carBackSide9" width="265.3" height="53.2" />
            <path
                className="carBackSide5"
                d="M36.3,425.8c1.2,1.9,2.6,3.8,4.1,5.4c3.1,3.3,6.6,6.2,10.5,8.7c7.7,4.8,16,8.5,24.7,11
		c8.6,2.6,17.5,4.4,26.5,5.5c2.2,0.3,4.5,0.5,6.7,0.8s4.5,0.4,6.7,0.6c4.5,0.3,8.9,0.5,13.3,0.7c17.8,0.8,35.5,0.9,53.3,0.3
		c17.8-0.5,35.5-1.9,53.1-4.3l-53.1-1.1c-17.7-0.5-35.3-1-53-1.8l-13.2-0.6c-4.4-0.2-8.7-0.5-13.1-1s-8.7-1-13-1.7
		s-8.6-1.5-12.8-2.5s-8.4-2.3-12.5-3.7c-4.1-1.4-8.1-3.1-12-5.1c-3.9-1.9-7.5-4.3-10.9-7.1c-1.7-1.4-3.2-2.9-4.6-4.6
		c-1.5-1.6-2.5-3.6-3-5.8c0,1.1,0.2,2.3,0.6,3.3C35.2,423.8,35.7,424.8,36.3,425.8z"
            />
            <path
                className="carBackSide5"
                d="M708.2,458.5c4.5-0.2,8.9-0.4,13.3-0.7c2.2-0.2,4.5-0.3,6.7-0.6s4.5-0.4,6.7-0.8c9-1.1,17.8-2.9,26.5-5.5
		c8.7-2.5,17-6.2,24.7-11c3.8-2.4,7.4-5.4,10.5-8.7c1.5-1.7,2.9-3.5,4.1-5.4c0.6-1,1.1-2,1.5-3.1c0.4-1,0.6-2.2,0.6-3.3
		c-0.5,2.1-1.5,4.1-3,5.8c-1.4,1.7-2.9,3.2-4.6,4.6c-3.3,2.8-7,5.2-10.9,7.1c-3.9,2-7.9,3.7-12,5.1s-8.3,2.6-12.5,3.7
		s-8.5,1.8-12.8,2.5s-8.6,1.3-13,1.7s-8.7,0.8-13.1,1l-13.2,0.6c-17.7,0.8-35.3,1.3-53,1.8l-53.1,1.1c17.6,2.4,35.3,3.8,53.1,4.3
		C672.6,459.4,690.4,459.3,708.2,458.5z"
            />
            <path className="carBackSide5" d="M35,386.4l-0.4-0.2" />
            <path className="carBackSide5" d="M802.4,386.2l-0.4,0.2" />
            <path
                className="carBackSide10"
                d="M780.7,289.7c0-1.1-0.1-2.3-0.2-3.7c-0.5-8.1-2.3-16.1-5.2-23.7c-6-8.2-32.2-21.8-32.2-21.8
		c-15.7-4.2-50,2.4-56,3.6l-6.7,67.7c39.3,0.8,73,1.4,77.2,1.3c10.7-0.2,15.3,0.4,19.4-2.5c0.3-0.2,0.5-0.4,0.7-0.6
		c0.5-0.5,0.8-1,1.1-1.6s0.5-1.3,0.7-2s0.3-1.4,0.4-2.1c0.3-2.1,0.3-4.2,0.1-6.3C780.1,298,781.1,299.2,780.7,289.7z"
            />
            <path
                className="carBackSide10"
                d="M93.9,240.5c0,0-26.1,13.5-32.2,21.8c-2.9,7.6-4.6,15.6-5.2,23.7c-0.1,1.4-0.2,2.6-0.2,3.7
		c-0.4,9.6,0.5,8.4,0.5,8.4s-1.1,9.7,3.1,12.6s8.8,2.3,19.4,2.5c4.2,0.1,37.9-0.5,77.2-1.3l-6.7-67.7
		C143.9,242.9,109.6,236.3,93.9,240.5z"
            />
            <path
                d="M631.1,105.2c0,0,0.7-2.2-3.7-3.9c-1-0.4-2-0.6-3-0.8c-7.7-1.6-35.2-7.4-63.8-9.2c-21-1.3-69.2-3.2-101-4.2
		c-8.5-0.3-15.8-0.5-21.1-0.6v-0.1h-5.4c-0.2-1-0.7-2.6-1.3-2.9c-0.9-0.5-4.2-1.3-5.9-2.2c-1-0.4-1.8-1-2.5-1.8
		c-0.7-1.2-1.1-2.7-1-4.1c-0.1-4.4-1.8-73-1.8-73c0.1-1.1-0.8-2.1-1.9-2.1h-0.1h-0.2c-1.1,0-2,0.8-2.1,1.9c0,0.1,0,0.1,0,0.2
		c0,0-1.7,68.6-1.8,73c0,2.2-0.5,3.5-1.1,4.3c-0.7,0.7-1.5,1.3-2.4,1.6c-1.7,0.9-5,1.7-5.9,2.2c-0.6,0.3-1,1.9-1.3,2.9h-5.4v0.1
		l-7.5,0.2c-7.3,0.2-16.5,0.5-26.5,0.8c-13.4,0.4-28.4,1-42.5,1.6c-18.3,0.8-35.1,1.5-45.4,2.2c-28.6,1.8-56.1,7.6-63.8,9.2
		c-1,0.2-2,0.5-3,0.8c-4.4,1.7-3.7,3.9-3.7,3.9h-0.1l7,4.5c6.2-2.6,12.7-4.5,19.3-5.7c1.8-0.3,3.6-0.7,5.4-1s3.8-0.6,5.7-0.9
		l2.9-0.4c2-0.3,4-0.5,6-0.8s4.1-0.5,6.2-0.7c3.1-0.3,6.4-0.6,9.6-0.9s6.5-0.6,9.9-0.9l6.7-0.6l10.1-0.9l6.8-0.6l3.4-0.3
		c4-0.4,8.2-0.8,12.5-1.1c14.2-1.1,29.4-1.9,43.2-2.5c9.8-0.4,18.9-0.7,26.6-0.9c8.4-0.2,15.2-0.3,19.2-0.4l4-0.1h16.5h1.6h0.5h1.9
		h3.1l3.9,0.1h2.2l7.5,0.2l8.7,0.2h0.1l6.4,0.2c20.9,0.7,47.5,1.9,69.7,4.1c35.2,3.4,71.6,4.8,92,13.6l7-4.5L631.1,105.2z"
            />
            <path
                className="carBackSide0"
                d="M680.4,311.9l6.7-67.7l9.2-25.2c0,0-1.4-4.1-3.4-9.4c-0.3-0.8-0.6-1.6-0.9-2.4c-0.9-2.4-1.8-5.1-2.8-7.6
		c-0.5-1.3-1-2.6-1.4-3.8c-1.1-2.9-2.1-5.5-3-7.6c-0.5-1.2-1-2.5-1.6-3.8l-1.2-3l-0.4-1l-0.8-2l-0.8-2l-0.8-1.9l-0.8-1.9
		c-0.2-0.6-0.5-1.2-0.7-1.7c-0.6-1.4-1.1-2.6-1.5-3.7c-0.3-0.6-0.5-1.2-0.7-1.6l-0.3-0.8c-0.2-0.4-0.3-0.6-0.3-0.6v-0.1
		c-0.6-1.6-10-25.6-16-34.7c-6.1-9.4-12.2-19-27.7-24l-7,4.5c-20.4-8.9-56.8-10.2-92-13.6c-22.2-2.2-48.8-3.4-69.7-4.1l-6.4-0.2H456
		l-8.7-0.2l-7.5-0.2h-2.2l-3.9-0.1h-3.1h-2.4h-1.6h-16.5l-4,0.1c-4,0.1-10.7,0.2-19.2,0.4c-7.7,0.2-16.8,0.5-26.6,0.9
		c-13.9,0.6-29,1.4-43.2,2.5c-4.3,0.3-8.5,0.7-12.5,1.1l-3.4,0.3l-6.8,0.6l-9.8,0.6l-6.7,0.6c-3.3,0.3-6.6,0.6-9.9,0.9
		c-3.2,0.3-6.5,0.6-9.6,0.9c-2.1,0.2-4.2,0.5-6.2,0.7s-4.1,0.5-6,0.8l-2.9,0.4c-1.9,0.3-3.8,0.6-5.7,0.9s-3.7,0.7-5.4,1
		c-6.6,1.2-13.1,3.1-19.3,5.7l-7-4.5c-15.5,4.9-21.5,14.5-27.7,24c-5.9,9.1-15.4,33.2-16,34.7v0.1c0,0-6.1,14.4-10,24.1
		c-0.9,2.1-1.9,4.8-3,7.6c-0.5,1.2-0.9,2.5-1.4,3.8c-1,2.6-1.9,5.2-2.8,7.6c-0.3,0.8-0.6,1.6-0.9,2.4c-1.9,5.4-3.4,9.4-3.4,9.4
		l9.2,25.2l6.7,67.7l-19.7,2.4l0.2,20v0.1c0.2,1.2,0.4,2.3,0.6,3.5c0.2,1.3,0.5,2.7,0.7,4c0.8,4.5,1.7,8.9,2.6,13.4
		c1.4,7,3,13.9,4.9,20.7c0.9,3.4,1.9,6.9,3,10.2c0.5,1.7,1.2,3.4,1.8,5.1c0.3,0.8,0.7,1.7,1.1,2.5s0.7,1.7,1.1,2.5
		c1.5,3.2,2.8,6.5,4.6,9.7c3.3,7,9.3,12.3,16.7,14.7c1.8,0.5,3.6,1.2,5.3,1.5l2.7,0.6c0.9,0.2,1.8,0.3,2.7,0.5
		c7.1,1,14.2,1.6,21.4,1.9c14.1,0.6,28.3,1.1,42.4,1.6s28.3,0.9,42.4,1.2s28.2,0.7,42.5,0.9l84.9,0.2l84.9-0.2
		c14.2-0.1,28.3-0.5,42.5-0.9s28.3-0.7,42.4-1.2s28.3-1,42.4-1.6c7.2-0.2,14.3-0.8,21.4-1.9c0.9-0.1,1.8-0.3,2.7-0.5l2.7-0.6
		c1.8-0.3,3.6-1,5.3-1.5c7.4-2.4,13.4-7.7,16.7-14.6c1.7-3.2,3.1-6.5,4.6-9.7c0.4-0.8,0.7-1.6,1.1-2.5s0.7-1.7,1.1-2.5
		c0.6-1.7,1.3-3.4,1.8-5.1c1.1-3.4,2.1-6.8,3-10.2c1.9-6.9,3.4-13.8,4.8-20.7c0.9-4.5,1.8-8.9,2.6-13.4c0.2-1.3,0.5-2.7,0.7-4
		c0.2-1.2,0.4-2.3,0.6-3.5v-0.1l0.2-20L680.4,311.9z M418.6,246.6c1.5,0.2,3.1,0.1,4.5-0.5l0,0c1.6-0.8,3-2.5,3.1-6.4l0,0
		c0-3.8-1.4-6-3-7.3c2.9,1.5,4.7,4.6,4.7,7.9v7.1c0,0.7-0.3,1.3-0.8,1.8c-2,1.6-4.5,2.5-7.1,2.3c-3.8,0.2-7.1-2.7-7.4-6.5
		c0-0.2,0-0.4,0-0.6c0-2,0.7-4,2-5.5c-0.4,0.6-0.6,1.3-0.7,2.1C413.6,243.7,415.7,246.3,418.6,246.6z M411.7,234.7
		c1.8-2.1,4.3-3.3,7-3.3l0,0c2.7,0,6.9,2.1,6.9,8c0,3-0.8,5-2.5,5.9v-5c0-2.1-1.6-3.9-3.7-4.1h-0.3c-0.4,0-0.8,0-1.1,0.1
		c-3,0.5-6,3.1-6,8c0,2.2,0.9,4.2,2.3,5.8c-4.8-2.7-6.5-8.8-3.8-13.6C410.8,235.9,411.3,235.3,411.7,234.7L411.7,234.7z
		 M167.9,163.7l-0.2,0.3L167.9,163.7z"
            />
            <path
                className="carBackSide0"
                d="M150.8,244.4c8.8,1.4,52.7,6.7,62.1,8.2s27.2,2.5,35.4,2.6c5.1,0.1,50.5,0.3,83.8,0.4l35.4,0.2h102.1l35.4-0.2
		c33.3-0.1,78.7-0.4,83.8-0.4c8.1-0.1,26-1.1,35.4-2.6c3.5-0.6,11.9-1.7,21.4-2.9l4.9-0.6l5-0.6l5-0.6l7.2-0.9l4.5-0.6l2.1-0.3
		l5.7-0.8l1.6-0.2l2.8-0.4c0.8-0.1,1.5-0.2,2-0.3l0.9-0.2l9.1-25.2c0,0-23.7,3.1-34.2,4s-44.2,4.2-58.8,4.9c-0.9,0-1.8,0.1-2.8,0.1
		c-2.8,0.1-5.8,0.1-8.8,0.1c-7,0-14.6-0.3-22.2-0.5c-3.3-0.1-6.5-0.1-9.8-0.2c-2.2,0-4.4,0-6.5,0c-9,0.1-36.3,0.1-60.3,0
		c-22.1,0-41.5,0-41.5,0h-65.9h-11.1h-62c-12,0-22.3,0-27.7,0h-1c-8.4-0.1-17,0.2-25.2,0.4c-8.8,0.2-17.3,0.4-24.9,0
		c-0.9,0-1.9-0.1-3-0.2l-3.3-0.2l-1.8-0.1l-3.8-0.3l-2-0.2l-1.7-0.1l-2.4-0.2l-6.4-0.5l-4.4-0.4l-4.3-0.4l-6.3-0.6l-4-0.4l-3.8-0.4
		l-3.5-0.3l-5.8-0.5l-2.2-0.2c-1-0.1-2.1-0.2-3.3-0.3c-2-0.2-4.2-0.4-6.5-0.7l-2.8-0.3l-2.9-0.3c-3.3-0.4-6.6-0.8-9.5-1.1l-2.4-0.3
		c-4.1-0.5-6.9-0.9-6.9-0.9l9.2,25.2L150.8,244.4z M427.9,240.2v7.1c0,0.7-0.3,1.3-0.8,1.8c-2,1.6-4.5,2.5-7.1,2.3
		c-3.8,0.2-7.1-2.7-7.4-6.5c0-0.2,0-0.4,0-0.6c0-2,0.7-4,2-5.5c-0.4,0.6-0.6,1.3-0.7,2.1c-0.3,2.9,1.8,5.4,4.6,5.7
		c1.5,0.2,3.1,0.1,4.5-0.5l0,0c1.6-0.8,3-2.5,3.1-6.4l0,0c0-3.8-1.4-6-3-7.3C426.1,234,427.9,237,427.9,240.2z M411.7,234.7
		c1.8-2.1,4.3-3.3,7-3.3l0,0c2.7,0,6.9,2.1,6.9,8c0,3-0.8,5-2.5,5.9v-5c0-2.1-1.6-3.9-3.7-4.1h-0.3c-0.4,0-0.8,0-1.1,0.1
		c-3,0.5-6,3.1-6,8c0,2.2,0.9,4.2,2.3,5.8c-4.8-2.7-6.5-8.8-3.8-13.6C410.8,235.9,411.3,235.3,411.7,234.7L411.7,234.7z"
            />
            <path
                className="carBackSide0"
                d="M680.4,311.9c-60.8-1.2-135.1-2.8-135.1-2.8H291.7c0,0-74.3,1.6-135.1,2.8l-19.6,2.5l1.3,20
		c3,13.8,6.4,27.5,10.5,41c1,3.4,2.1,6.7,3.4,10c0.6,1.7,1.3,3.2,1.9,4.9c0.4,0.8,0.7,1.5,1.1,2.3s0.7,1.5,1.1,2.4
		c1.6,3.2,3.1,6.4,4.8,9.4c1.5,2.9,3.6,5.5,6.1,7.7c2.6,2,5.5,3.5,8.6,4.5c1.6,0.4,3.2,1,4.9,1.2l2.5,0.5c0.8,0.1,1.7,0.2,2.6,0.4
		c6.9,0.8,13.9,1.2,20.9,1.2c14.1,0.4,28.2,0.6,42.4,0.8c28.2,0.3,56.6,0.9,84.8,0.9l84.9-0.2l84.9,0.2c28.2,0,56.5-0.6,84.8-0.9
		c14.1-0.2,28.3-0.4,42.4-0.8c7,0,13.9-0.4,20.9-1.2c0.8-0.1,1.7-0.2,2.6-0.4l2.5-0.5c1.7-0.3,3.3-0.8,4.9-1.2
		c6.4-1.8,11.7-6.3,14.6-12.2c1.7-3,3.2-6.2,4.8-9.3c0.4-0.8,0.8-1.6,1.2-2.4s0.7-1.5,1.1-2.3c0.6-1.6,1.4-3.2,1.9-4.9
		c1.2-3.3,2.3-6.6,3.4-10c4.1-13.5,7.5-27.2,10.5-41l1.3-20L680.4,311.9z"
            />
            <path
                className="carBackSide11"
                d="M693,209.5c-0.3-0.8-0.6-1.6-0.9-2.4c-0.9-2.4-1.8-5.1-2.8-7.6c-0.5-1.3-1-2.6-1.4-3.8
		c-1.1-2.9-2.1-5.5-3-7.6c-0.5-1.2-1-2.5-1.6-3.8l-1.2-3l-0.4-1l-0.8-2l-0.8-2l-0.8-1.9l-0.8-1.9c-0.2-0.6-0.5-1.2-0.7-1.7
		c-0.6-1.4-1.1-2.6-1.5-3.7c-0.3-0.6-0.5-1.2-0.7-1.6l-0.3-0.8c-0.2-0.4-0.3-0.6-0.3-0.6V164l-0.8,0.1h-4.8c0,0-19.1-29-33-45.8
		c-0.3-0.4-0.7-0.8-1-1.2c-0.7-0.8-1.5-1.5-2.4-2.2s-1.8-1.4-2.7-2c-1-0.6-2-1.2-3.1-1.8c-0.5-0.3-1.1-0.6-1.7-0.8l-1.3-0.6
		c-20.4-8.9-56.8-10.2-92-13.6c-22.2-2.2-48.8-3.4-69.7-4.1l-6.4-0.2H456l-8.7-0.2l-7.5-0.2h-2.2l-3.9-0.1h-3.1h-2.4h-1.6h-16.5
		l-4,0.1c-4,0.1-10.7,0.2-19.2,0.4c-7.7,0.2-16.8,0.5-26.6,0.9c-13.9,0.6-29,1.4-43.2,2.5c-4.3,0.3-8.5,0.7-12.5,1.1l-3.4,0.3
		l-6.8,0.6l-9.8,0.8l-6.7,0.6c-3.3,0.3-6.6,0.6-9.9,0.9c-3.2,0.3-6.5,0.6-9.6,0.9c-2.1,0.2-4.2,0.5-6.2,0.7s-4.1,0.5-6,0.8l-2.9,0.4
		c-1.9,0.3-3.8,0.6-5.7,0.9s-3.7,0.7-5.4,1c-6.6,1.2-13.1,3.1-19.3,5.7l-1.3,0.6c-4.1,1.8-7.8,4.5-10.8,7.9
		c-1.7,2.1-3.6,4.4-5.4,6.8c-0.9,1.2-1.9,2.4-2.8,3.7c-4.2,5.6-8.5,11.6-12.2,17c-0.8,1.2-1.7,2.4-2.4,3.5l-1.1,1.6l-2,3l-0.9,1.4
		l-0.8,1.2l-0.2,0.3l-0.6,0.8l-0.2,0.3l-0.7,1l-0.6,0.9l-0.2,0.3l-0.5,0.7l-0.1,0.1c-0.2,0.3-0.4,0.5-0.5,0.8s-0.3,0.4-0.4,0.6
		l-0.1,0.2l-0.6,1l-0.2,0.3l-0.2,0.3v0.1H163l-0.8-0.1v0.1c0,0-6.1,14.4-10,24.1c-0.9,2.1-1.9,4.8-3,7.6c-0.5,1.2-0.9,2.5-1.4,3.8
		c-1,2.6-1.9,5.2-2.8,7.6c-0.3,0.8-0.6,1.6-0.9,2.4c-1.9,5.4-3.4,9.4-3.4,9.4s2.8,0.4,6.9,0.9l2.4,0.3c2.9,0.4,6.2,0.8,9.5,1.1
		l2.9,0.3l2.8,0.3c2.3,0.3,4.5,0.5,6.5,0.7c1.2,0.1,2.3,0.2,3.3,0.3l2.2,0.2l5.8,0.5l3.5,0.3l3.8,0.4l4,0.4l6.3,0.6l4.3,0.4l4.4,0.4
		l6.4,0.5l2.4,0.2l1.7,0.1l2,0.2l3.8,0.3l1.8,0.1l3.3,0.2c1.1,0.1,2,0.1,3,0.2c7.6,0.4,16,0.2,24.9,0c8.3-0.2,16.8-0.5,25.2-0.4h1
		c5.4,0,15.7,0,27.7,0h62h11.1h65.9c0,0,19.4,0,41.5,0c24,0,51.3,0,60.3,0c2.2,0,4.4,0,6.5,0c3.3,0,6.6,0.1,9.8,0.2
		c7.6,0.2,15.1,0.4,22.2,0.5c3,0,6,0,8.8-0.1c0.9,0,1.9-0.1,2.8-0.1c14.6-0.8,48.3-4.1,58.8-4.9s34.2-4,34.2-4
		S694.9,214.9,693,209.5z"
            />
            <path
                className="carBackSide1"
                d="M611.7,330.7c-4.3-0.2-8.5-0.6-12.8-0.7l-6.4-0.3l-6.4-0.2c-4.3-0.2-8.6-0.3-12.9-0.4
		c-17.2-0.4-34.3-0.5-51.5-0.6s-34.4,0-51.5,0.2l-6.4,0.1h-3.2h-3.2h-12.9h-25.8h-25.8c-8.6,0-17.2-0.1-25.8-0.2
		c-17.2-0.1-34.4-0.1-51.5-0.1s-34.4,0.2-51.5,0.6c-4.3,0.1-8.6,0.2-12.9,0.4l-6.4,0.2l-6.4,0.3c-4.3,0.1-8.5,0.5-12.8,0.7
		s-8.5,0.6-12.8,1c-24.3,2.4-48.6,5.9-72.6,10.2c2.6,11.3,5.5,22.5,8.8,33.5c1,3.4,2.1,6.7,3.4,10c0.6,1.7,1.3,3.2,1.9,4.9
		c0.4,0.8,0.7,1.5,1.1,2.3s0.7,1.5,1.1,2.4c1.6,3.2,3.1,6.4,4.8,9.4c1.5,2.9,3.6,5.5,6.1,7.7c2.6,2,5.5,3.5,8.6,4.5
		c1.6,0.4,3.2,1,4.9,1.2l2.5,0.5c0.8,0.1,1.7,0.2,2.6,0.4c6.9,0.8,13.9,1.2,20.9,1.2c14.1,0.4,28.2,0.6,42.4,0.8
		c28.2,0.3,56.6,0.9,84.8,0.9l84.9-0.2l84.9,0.2c28.2,0,56.5-0.6,84.8-0.9c14.1-0.2,28.3-0.4,42.4-0.8c7,0,13.9-0.4,20.9-1.2
		c0.8-0.1,1.7-0.2,2.6-0.4l2.5-0.5c1.7-0.3,3.3-0.8,4.9-1.2c6.4-1.8,11.7-6.3,14.6-12.2c1.7-3,3.2-6.2,4.8-9.3
		c0.4-0.8,0.8-1.6,1.2-2.4s0.7-1.5,1.1-2.3c0.6-1.6,1.4-3.2,1.9-4.9c1.2-3.3,2.3-6.6,3.4-10c3.4-11,6.2-22.3,8.8-33.5
		c-24.1-4.3-48.4-7.8-72.7-10.1C620.2,331.3,615.9,331,611.7,330.7z"
            />
            <path
                className="carBackSide5"
                d="M212.6,331.7c4.3-0.4,8.5-0.7,12.8-1s8.5-0.5,12.8-0.7l6.4-0.3l6.4-0.2c4.3-0.2,8.6-0.2,12.9-0.4
		c17.2-0.4,34.3-0.5,51.5-0.6s34.4,0,51.5,0.1c8.6,0,17.2,0.2,25.8,0.2h25.8h25.8h12.9h3.2h3.2l6.4-0.1c17.2-0.2,34.4-0.2,51.5-0.2
		c17.2,0,34.3,0.2,51.5,0.6c4.3,0.1,8.6,0.2,12.9,0.4l6.4,0.2l6.4,0.3c4.3,0.1,8.5,0.5,12.8,0.7s8.5,0.6,12.8,1
		c24.3,2.3,48.6,5.8,72.7,10.1c0.3-1.3,0.6-2.6,0.9-4c-7.3-1.5-14.5-2.9-21.8-4.2c-17-3-34-5.5-51.2-7.4s-34.5-2.7-51.7-3.3
		s-34.4-0.9-51.6-1s-34.4-0.1-51.6,0l-6.5,0.1h-3.2h-3.2h-12.9h-25.8h-25.8c-8.6,0.1-17.2-0.1-25.8-0.1c-17.2-0.1-34.4-0.1-51.6,0.1
		s-34.4,0.5-51.6,1c-4.3,0.1-8.6,0.3-12.9,0.5l-6.5,0.3l-6.5,0.4c-4.3,0.2-8.6,0.6-12.9,0.9s-8.6,0.8-12.9,1.2
		c-17.2,1.9-34.2,4.3-51.2,7.4c-7.3,1.3-14.5,2.7-21.8,4.2l0.9,4C164,337.5,188.2,334,212.6,331.7z"
            />
            <path
                d="M291.7,309.1h253.5c0,0,74.3,1.6,135.1,2.8l6.7-67.7l-0.9,0.2c-0.5,0.1-1.2,0.2-2,0.3l-2.8,0.4l-1.6,0.2l-5.7,0.8l-2.1,0.3
		l-4.5,0.6l-7.2,0.9l-5,0.6l-5,0.6l-4.9,0.6c-9.6,1.2-17.9,2.3-21.4,2.9c-9.3,1.5-27.2,2.5-35.4,2.6c-5.1,0.1-50.5,0.3-83.8,0.4
		l-35.4,0.2H367.4l-35.4-0.2c-33.3-0.1-78.7-0.4-83.8-0.4c-8.1-0.1-26-1.1-35.4-2.6s-53.3-6.8-62.1-8.2l-0.9-0.2l6.7,67.7
		C217.4,310.7,291.7,309.1,291.7,309.1z"
            />
            <path
                d="M652.1,423.4c0.9-0.1,1.8-0.3,2.7-0.5l2.7-0.6c1.8-0.3,3.6-1,5.3-1.5c7.4-2.4,13.4-7.7,16.7-14.6c1.7-3.2,3.1-6.5,4.6-9.7
		c0.4-0.8,0.7-1.6,1.1-2.5s0.7-1.7,1.1-2.5c0.6-1.7,1.3-3.4,1.8-5.1c1.1-3.4,2.1-6.8,3-10.2c1.9-6.9,3.4-13.8,4.8-20.7
		c0.9-4.5,1.8-8.9,2.6-13.4l-0.8-0.1l-0.7-0.1c-2.6,11.3-5.5,22.5-8.8,33.5c-1,3.4-2.1,6.7-3.4,10c-0.6,1.7-1.3,3.2-1.9,4.9
		c-0.3,0.8-0.7,1.5-1.1,2.3s-0.7,1.5-1.2,2.4c-1.6,3.2-3.1,6.4-4.8,9.3c-2.9,5.9-8.3,10.4-14.6,12.2c-1.6,0.4-3.2,1-4.9,1.2
		l-2.5,0.5c-0.8,0.1-1.7,0.2-2.6,0.4c-6.9,0.8-13.9,1.2-20.9,1.2c-14.1,0.4-28.2,0.6-42.4,0.8c-28.2,0.3-56.6,0.9-84.8,0.9
		l-84.9-0.2l-84.9,0.2c-28.2,0-56.5-0.6-84.8-0.9c-14.1-0.2-28.3-0.4-42.4-0.8c-7,0-13.9-0.4-20.9-1.2c-0.9-0.1-1.7-0.2-2.6-0.4
		l-2.5-0.5c-1.7-0.3-3.3-0.8-4.9-1.2c-3.1-1-6-2.5-8.6-4.5c-2.5-2.2-4.5-4.8-6.1-7.7c-1.7-3-3.2-6.2-4.8-9.4
		c-0.4-0.8-0.8-1.6-1.1-2.4s-0.8-1.5-1.1-2.3c-0.6-1.6-1.4-3.2-1.9-4.9c-1.2-3.3-2.3-6.6-3.4-10c-3.4-11-6.2-22.3-8.8-33.5l-0.7,0.1
		L138,342c0.8,4.5,1.7,8.9,2.6,13.4c1.4,7,3,13.9,4.9,20.7c0.9,3.4,1.9,6.9,3,10.2c0.5,1.7,1.2,3.4,1.8,5.1c0.3,0.8,0.7,1.7,1.1,2.5
		s0.7,1.7,1.1,2.5c1.5,3.2,2.8,6.5,4.6,9.7c3.3,7,9.3,12.3,16.7,14.7c1.8,0.5,3.6,1.2,5.3,1.5l2.7,0.6c0.9,0.2,1.8,0.3,2.7,0.5
		c7.1,1,14.2,1.6,21.4,1.9c14.1,0.6,28.3,1.1,42.4,1.6s28.3,0.9,42.4,1.2s28.2,0.7,42.5,0.9l84.9,0.2L503,429
		c14.2-0.1,28.3-0.5,42.5-0.9s28.3-0.7,42.4-1.2s28.3-1,42.4-1.6C637.8,425,645,424.4,652.1,423.4z"
            />
            <path d="M699.2,338.1c0.2-1.2,0.4-2.3,0.6-3.5v-0.1l0.2-20l-1.3,20c-0.2,1.1-0.5,2.3-0.8,3.5l0.6,0.1H699.2z" />
            <path d="M139,337.8l-0.8-3.4l-1.2-19.9l0.2,20v0.1c0.2,1.2,0.4,2.3,0.6,3.5l0.6-0.1L139,337.8z" />
            <path d="M139.9,341.8l-0.9-4l-0.6,0.1l-0.6,0.1c0.2,1.3,0.5,2.7,0.7,4l0.7-0.1L139.9,341.8z" />
            <path d="M697.8,341.9l0.8,0.1c0.2-1.3,0.5-2.7,0.7-4l-0.7-0.1l-0.6-0.1c-0.3,1.3-0.6,2.6-0.9,4L697.8,341.9z" />
            <path
                d="M167.7,164.1L167.7,164.1l0.2-0.3l0.2-0.3l0.6-1l0.1-0.2c0.1-0.2,0.3-0.4,0.4-0.6l0.5-0.8l0.1-0.1l0.5-0.7l0.2-0.3l0.6-0.9
		l0.7-1l0.2-0.3l0.6-0.8l0.2-0.3l0.8-1.2l0.9-1.4l2-3l1.1-1.6c0.8-1.1,1.6-2.3,2.4-3.5c3.8-5.4,8-11.4,12.2-17
		c0.9-1.3,1.9-2.5,2.8-3.7c1.8-2.4,3.7-4.7,5.4-6.8c3-3.4,6.7-6.1,10.8-7.9l1.3-0.6l-7-4.5c-15.5,4.9-21.5,14.5-27.7,24
		c-5.9,9.1-15.4,33.2-16,34.7l0.8,0.1L167.7,164.1z"
            />
            <path
                d="M627.2,111.2c1.1,0.6,2.1,1.2,3.1,1.8c0.9,0.6,1.9,1.3,2.7,2s1.6,1.4,2.3,2.1c0.3,0.4,0.7,0.8,1,1.2
		c13.8,16.8,33,45.8,33,45.8h4.7l0.8-0.1c-0.6-1.6-10-25.6-16-34.7c-6.1-9.4-12.2-19-27.7-24l-7,4.5l1.3,0.6
		C626.1,110.7,626.6,110.9,627.2,111.2z"
            />
            <path
                className="carBackSide12"
                d="M317.3,95.1L218,226.8l1.7,0.1l2,0.2l3.8,0.3l1.8,0.1l3.3,0.2c1.1,0.1,2,0.1,3,0.2c7.6,0.4,16,0.2,24.9,0
		l102-135.3C346.7,93.2,331.5,94,317.3,95.1z"
            />
            <path
                className="carBackSide12"
                d="M316.8,227.5h37L456.1,91.9l-8.7-0.2l-7.5-0.2h-2.2l-3.9-0.1h-3.1h-2.4h-1.6h-16.5l-4,0.1
		c-4,0.1-10.7,0.2-19.2,0.4L284.7,227.4c5.4,0,15.7,0,27.7,0L316.8,227.5z"
            />
        </g>
        <g id="arriere-hayon">
            <path
                className="carBackSide0"
                d="M640.6,225l-45,3.1l-31.9-0.8L325.2,227l-85.5,1l-42.1-3.1l-56.8-5.9l9.2,25.2l6.7,67.7l-19.7,2.4l0.2,20v0.1
		c0.2,1.2,0.4,2.3,0.6,3.5c0.2,1.3,0.5,2.7,0.7,4c0.8,4.5,1.7,8.9,2.6,13.4c1.4,7,3,13.9,4.9,20.7c0.9,3.4,1.9,6.9,3,10.2
		c0.5,1.7,1.2,3.4,1.8,5.1c0.3,0.8,0.7,1.7,1.1,2.5s0.7,1.7,1.1,2.5c1.5,3.2,2.8,6.5,4.6,9.7c3.3,7,9.3,12.3,16.7,14.7
		c1.8,0.5,3.6,1.2,5.3,1.5l2.7,0.6c0.9,0.2,1.8,0.3,2.7,0.5c7.1,1,14.2,1.6,21.4,1.9c14.1,0.6,28.3,1.1,42.4,1.6s28.3,0.9,42.4,1.2
		s28.2,0.7,42.5,0.9l84.9,0.2l84.9-0.2c14.2-0.1,28.3-0.5,42.5-0.9s28.3-0.7,42.4-1.2s28.3-1,42.4-1.6c7.2-0.2,14.3-0.8,21.4-1.9
		c0.9-0.1,1.8-0.3,2.7-0.5l2.7-0.6c1.8-0.3,3.6-1,5.3-1.5c7.4-2.4,13.4-7.7,16.7-14.6c1.7-3.2,3.1-6.5,4.6-9.7
		c0.4-0.8,0.7-1.6,1.1-2.5s0.7-1.7,1.1-2.5c0.6-1.7,1.3-3.4,1.8-5.1c1.1-3.4,2.1-6.8,3-10.2c1.9-6.9,3.4-13.8,4.8-20.7
		c0.9-4.5,1.8-8.9,2.6-13.4c0.2-1.3,0.5-2.7,0.7-4c0.2-1.2,0.4-2.3,0.6-3.5v-0.1l0.2-20l-19.6-2.6l6.7-67.7l9.2-25.2L640.6,225z"
            />
            <path className="carBackSide13" d="M566.1,255.4L566.1,255.4z" />
            <path className="carBackSide13" d="M672,246.3l-1.1,0.1L672,246.3l1.1-0.1L672,246.3z" />
            <path
                className="carBackSide13"
                d="M204.4,251.4l-1.7-0.2l-0.5-0.1l-3.9-0.5l-2.2-0.3l-4.7-0.6l-7.3-0.9l-5-0.6l-4.9-0.6l-2.4-0.3l-11-1.4
		l-3.6-0.5l-3.1-0.4c-1.4-0.2-2.5-0.4-3.3-0.5h-0.2l-0.3-0.1l-0.4-0.1l0.5,5.2L156,307l0.5,5l11.5-0.2l20.6-0.4l20.5-0.4l2.9-0.1
		l37.4-0.8l11.4-0.2l21.2-0.5l4.6-0.1l5.1-0.1h253.5l5.1,0.1l4.6,0.1l21.2,0.5l11.4,0.2l12.6,0.3l19.1,0.4l5.7,0.1l5.8,0.1l5.8,0.1
		l5.9,0.1l5.9,0.1l2.9,0.1l17.6,0.4l11.5,0.2l0.5-5l5.7-57.6l0.5-5.2l-0.4,0.1l-0.5,0.1l-0.9,0.1l-1.1,0.2l-2.8,0.4l-1.6,0.2
		l-4.2,0.6l-1.5,0.2l-2.1,0.3l-2.2,0.3l-2.3,0.3l-3.5,0.4l-3.7,0.5l-5,0.6l-5,0.6l-4.9,0.6l4.9-0.6l-4.9,0.6l-3.3,0.4h-0.4l-3,0.4
		h-0.2l-1.6,0.2l0.9-0.1l-6.8,0.9l1.4-0.2l-1.8,0.2l-0.8,0.1l-0.5,0.1l-0.7,0.1l-0.5,0.1l-0.5,0.1l-0.5,0.1l-0.5,0.1l-0.5,0.1
		l-0.5,0.1l-0.4,0.1l-0.5,0.1l-0.3,0.1l-0.5,0.1h-0.1c-1.8,0.3-3.8,0.6-6,0.8c-1.1,0.1-2.3,0.2-3.5,0.4l-2.4,0.2l-2.5,0.2l-1.2,0.1
		l-3.8,0.3l-2.5,0.1l-1.2,0.1l-3.5,0.2l-2.2,0.1l-2.1,0.1c-1.7,0.1-3.2,0.1-4.5,0.1h-10.5h-6.9H567h-2.7H560h-3h-1.5h-3h-3.2H548
		h-3.3h-3.3h-5h1.7h-3.4h-3.4h-1.7h-8.4l0,0l-16.3,0.1h-9h-8.1h-5.6h-2.5h1.2h-3.5h-2H473H364h-2.5h-2H356h-1.3h-5.6H341h-9h-7.9
		h-0.4h-6.3h-1.7h-8.4h-1.7H303h-0.8h-3.4h1.7h-5h-3.3H289h-1.6H284h-2.9h-1.5h-3h-4.3h-2.7h-3.9h-6.9h-10.6c-1.3,0-2.8-0.1-4.4-0.1
		l-2.1-0.1l-2.2-0.1l-3.5-0.2l-1.2-0.1l-2.5-0.1l-3.7-0.3l-1.3-0.1l-2.5-0.2l-1.2-0.1l-1.3-0.1c-1.1-0.1-2.2-0.2-3.2-0.3h-0.2
		c-1-0.1-2-0.2-3-0.4h-0.4l-2.6-0.4l-2-0.3l-0.4-0.1l-2.4-0.4l-0.4-0.1l-1.7-0.2l0,0L204.4,251.4z"
            />
            <path
                className="carBackSide13"
                d="M688.8,239.5l2.9-7.9l2.7-7.3c-6.5,0.8-23.3,2.9-31.8,3.6L650,229c-15,1.4-35.6,3.3-46.4,3.8
		c-0.9,0-1.9,0.1-2.9,0.1c-2.4,0.1-5,0.1-7.9,0.1h-1.1c-5.5,0-11.1-0.2-17-0.3l-5.3-0.1c-3.9-0.1-6.9-0.2-9.8-0.2h-4.4h-2.1
		c-5.1,0-16.6,0-34.2,0H493c-22.1,0-41.5,0-41.5,0h-28.3c1.7,1,3.1,2.5,3.9,4.3c0.3,0.7,0.5,1.5,0.6,2.3c0.1,0.4,0.1,0.8,0.1,1.2
		v7.1c0,0.2,0,0.3-0.1,0.5c-0.1,0.3-0.2,0.6-0.4,0.9c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.3-0.6,0.5-1,0.7c-0.7,0.4-1.4,0.8-2.2,1h45.7
		l35.4-0.2c35.5-0.2,78.8-0.4,83.8-0.4c8.7-0.1,26-1.1,34.6-2.6c3.8-0.6,13.1-1.8,21.6-2.9l4.9-0.6l5-0.6l1.4-0.2l3.6-0.4l7.2-0.9
		l4.5-0.6l2.1-0.3l5.6-0.8l1.6-0.2l2.7-0.4c0.7-0.1,1.3-0.2,1.8-0.3l0.8-0.2c0.3-0.1,0.7-0.1,1-0.1
		C687.7,239.1,688.3,239.3,688.8,239.5z"
            />
            <path className="carBackSide13" d="M268.2,255.4L268.2,255.4z" />
            <path
                className="carBackSide13"
                d="M410.4,236.6C410.4,236.6,410.4,236.5,410.4,236.6c0.1-0.3,0.3-0.6,0.5-0.9l0.1-0.1c0.2-0.3,0.4-0.6,0.7-0.9
		l0,0c0.3-0.3,0.6-0.7,1-0.9c0.3-0.3,0.7-0.5,1-0.8c0.2-0.1,0.4-0.3,0.6-0.4l0.3-0.2h-29.1h-11.1h-62c-13.5,0-22.9,0-27.7,0h-3.1
		c-6.4,0-13,0.2-19.4,0.4l-3.6,0.1c-4.7,0.1-9.7,0.2-14.5,0.2c-4,0-7.6-0.1-10.7-0.2l-3-0.2l-3.4-0.2l-1.8-0.1
		c-1.2-0.1-2.5-0.2-3.8-0.3l-2-0.2l-1.8-0.1l-2.4-0.2l-6.4-0.6l-4.4-0.4l-4.3-0.4l-6.3-0.6l-4-0.4l-3.8-0.4l-3.5-0.3l-5.8-0.5
		l-2.2-0.2c-1-0.1-2.1-0.2-3.3-0.3c-1.9-0.2-4.1-0.4-6.6-0.7l-2.8-0.3l-2.9-0.3c-3.3-0.4-6.6-0.8-9.5-1.1l-2.4-0.3l-4.4-0.6
		l5.6,15.2c0.5-0.2,1.1-0.3,1.7-0.3c0.3,0,0.7,0,1,0.1l0.8,0.2c4.3,0.7,17,2.3,29.3,3.9c13.6,1.7,27.7,3.5,32.6,4.3
		c8.6,1.4,26,2.4,34.6,2.6c5,0.1,48.3,0.3,83.8,0.4l35.4,0.2h49.4c-0.4-0.2-0.8-0.4-1.2-0.6c-0.6-0.4-1.1-0.9-1.6-1.5
		c-0.3-0.4-0.5-0.7-0.7-1.1c-0.2-0.4-0.3-0.8-0.5-1.2c-0.1-0.4-0.2-0.8-0.2-1.1s-0.1-0.7-0.1-1c0-0.2,0-0.5,0-0.7s0-0.5,0.1-0.7
		s0.1-0.5,0.1-0.7c0.2-0.7,0.4-1.4,0.8-2.1c0.2-0.4,0.6-0.9,0.9-1.2l0,0c-0.2,0.3-0.3,0.7-0.4,1s-0.2,0.7-0.2,1.1
		c-0.1,0.7,0,1.4,0.2,2.1c0.1,0.5,0.4,0.9,0.6,1.4c0.3,0.4,0.6,0.8,1,1.1c0.5,0.4,1.1,0.8,1.8,1c0.3,0.1,0.7,0.2,1,0.2
		c0.4,0,0.8,0.1,1.1,0.1h0.3c0.4,0,0.7,0,1.1,0h0.3c0.2,0,0.5-0.1,0.7-0.1l0.3-0.1c0.3-0.1,0.5-0.2,0.8-0.3l0,0l0,0l0.3-0.2l0.4-0.2
		c0.3-0.2,0.5-0.4,0.7-0.6c0.3-0.3,0.5-0.6,0.7-1c0.1-0.3,0.3-0.5,0.4-0.8s0.2-0.6,0.3-1c0.1-0.4,0.2-0.8,0.2-1.2s0.1-0.9,0.1-1.4
		l0,0c0-1.2-0.1-2.5-0.5-3.6c-0.1-0.3-0.2-0.6-0.3-0.9v-0.1c0-0.1,0-0.1-0.1-0.1c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.1-0.1-0.1-0.1-0.2
		c-0.1-0.2-0.3-0.4-0.5-0.6c-0.3-0.4-0.7-0.8-1.2-1.1H422c0.4,0.2,0.7,0.5,1.1,0.8s0.7,0.7,1,1.1c0.5,0.7,0.8,1.4,1,2.2
		c0.3,0.9,0.4,1.9,0.4,2.9c0.1,1.8-0.4,3.5-1.4,5c-0.3,0.4-0.7,0.7-1.1,0.9l0,0v-5c0-0.3,0-0.5-0.1-0.8c-0.1-0.5-0.3-1-0.6-1.4
		c-0.3-0.4-0.6-0.8-1-1.1c-0.6-0.5-1.3-0.7-2.1-0.8h-0.3h-0.6l-0.6,0.1l-0.5,0.1c-0.2,0-0.4,0.1-0.5,0.2c-0.4,0.1-0.7,0.3-1.1,0.5
		s-0.7,0.4-1,0.7c-0.2,0.1-0.3,0.3-0.5,0.4c-1,1-1.7,2.2-2,3.6c-0.2,0.8-0.3,1.7-0.3,2.5c0,0.2,0,0.5,0,0.8c0.1,0.5,0.2,1,0.3,1.6
		c0.1,0.3,0.2,0.5,0.3,0.8s0.2,0.5,0.3,0.7c0.4,0.7,0.8,1.4,1.4,2c-0.7-0.4-1.3-0.8-1.9-1.4c-0.6-0.5-1.1-1.1-1.5-1.8
		c-0.3-0.5-0.6-1-0.8-1.5c-0.4-0.9-0.7-1.9-0.8-2.9c0-0.4-0.1-0.7-0.1-1.1c0-1,0.1-1.9,0.4-2.9C409.9,238,410.1,237.3,410.4,236.6z"
            />
            <path
                className="carBackSide13"
                d="M693.8,333.7l1.2-19.5c0-0.1,0-0.2,0-0.4l-9.8-1.3c-0.3,2.5-2.5,4.4-5,4.4h-0.1
		c-59.4-1.2-132.7-2.8-135.1-2.8H291.8c-2.4,0-75.7,1.6-135.1,2.8h-0.1c-2.5,0-4.6-1.9-4.9-4.4l-9.7,1.2c0,0.1,0,0.2,0.1,0.3
		l1.3,19.6c0.2,0.8,0.3,1.6,0.5,2.4c24.3-4.2,46.7-7.3,68.3-9.3c4.2-0.4,8.3-0.7,12.9-1h0.1l3.9-0.2c2.9-0.2,5.9-0.4,8.9-0.5
		l6.4-0.3l0,0l6.4-0.2c3.8-0.1,7.7-0.2,11.4-0.3h1.5c15.2-0.4,31.6-0.5,51.6-0.6h15.1c13.5,0,25.4,0,36.4,0.1
		c3.4,0,6.8,0.1,10.1,0.1c4.5,0,9.2,0.1,13.8,0.1h1.8h25.8h25.8H457h3.2h3.2l6.4-0.1c16.8-0.2,33.3-0.2,51.6-0.2l0,0
		c19.2,0,36.1,0.2,51.6,0.6l2.6,0.1c3.4,0.1,6.8,0.2,10.3,0.3l6.5,0.2l6.4,0.1c3,0.1,6,0.3,9,0.5l4,0.2l0,0c4.6,0.3,8.7,0.6,12.9,1
		c21.6,2,44,5.1,68.4,9.3C693.5,335.3,693.7,334.5,693.8,333.7z"
            />
            <path
                className="carBackSide13"
                d="M611.7,330.7c-2.1-0.1-4.3-0.3-6.4-0.4s-4.3-0.2-6.4-0.3l-6.4-0.3l-6.4-0.2c-4.3-0.2-8.6-0.3-12.9-0.4
		c-17.2-0.4-34.3-0.5-51.5-0.6h-12.9c-8.6,0-17.2,0-25.8,0.1c-4.3,0-8.6,0-12.9,0.1l-6.4,0.1h-3.2h-3.2h-12.9h-25.8h-25.8h-7.2h-4.2
		H379l-5.7-0.1H367c-7.8-0.1-15.6-0.1-23.4-0.1h-28.2c-12.9,0-25.8,0.1-38.6,0.3c-4.3,0.1-8.6,0.2-12.9,0.2
		c-4.3,0.1-8.6,0.2-12.9,0.4l-6.4,0.2l-6.4,0.3c-1.1,0-2.1,0.1-3.2,0.1c-2.1,0.1-4.3,0.2-6.4,0.4l-3.2,0.2l-6.4,0.5
		c-2.1,0.2-4.3,0.4-6.4,0.5c-3,0.3-6.1,0.6-9.1,0.9c-7.6,0.8-15.2,1.7-22.7,2.8s-15.1,2.1-22.7,3.3c-4.4,0.7-8.8,1.5-13.2,2.2l0,0
		c2.8,12.1,5.6,22.9,8.6,33c1,3.4,2.1,6.6,3.3,9.7v0.1c0.3,0.9,0.7,1.9,1.1,2.8l0.7,1.7c0.2,0.4,0.4,0.9,0.6,1.3s0.3,0.6,0.4,0.9
		c0.4,0.8,0.7,1.5,1.1,2.2l1.6,3.1c1.1,2.1,2.1,4.2,3.1,6.1c1.2,2.4,2.9,4.5,4.9,6.3c2,1.6,4.3,2.8,6.8,3.5l1.6,0.4
		c0.9,0.3,1.8,0.5,2.7,0.7h0.2l2.4,0.5l1.4,0.2l0.9,0.1c6.4,0.7,12.9,1.1,19.4,1.2h1c14.7,0.4,29.6,0.6,42.3,0.8
		c8.4,0.1,16.9,0.2,25.4,0.3c19.5,0.3,39.6,0.6,59.3,0.6l84.9-0.2l84.8,0.2c19.4,0,39.2-0.3,58.3-0.6c8.6-0.1,17.6-0.2,26.3-0.4
		c12.8-0.2,27.6-0.4,42.3-0.8h0.7c6.6,0,13.1-0.4,19.7-1.2l1-0.1l1.4-0.2l2.4-0.5h0.2c0.9-0.2,1.8-0.4,2.7-0.7l1.5-0.4
		c2.5-0.8,4.8-2,6.8-3.6c2-1.8,3.7-3.9,4.9-6.3c1.1-1.9,2.1-3.9,3.1-6.1c0.5-1,1-2.1,1.6-3.1c0.3-0.7,0.7-1.4,1.1-2.2
		c0.1-0.3,0.3-0.6,0.4-0.9s0.4-0.9,0.6-1.3c0.2-0.6,0.5-1.1,0.7-1.7c0.4-1,0.8-1.9,1.1-2.9v-0.1c1.2-3.1,2.2-6.3,3.3-9.7
		c3.1-10.1,5.9-20.9,8.7-33c-22.5-3.9-45.1-7.1-67.8-9.2C620.2,331.3,615.9,331,611.7,330.7z"
            />
            <path
                className="carBackSide13"
                d="M696.9,346.9h-0.3l-0.3-0.1c-0.1,0-0.2,0-0.2-0.1l0,0l-0.5,2.3c-0.2,0.8-0.4,1.5-0.5,2.3
		c-0.6,2.4-1.2,4.7-1.8,7l-0.2,0.7c-0.7,2.5-1.3,5-2,7.6l-0.4,1.3c-0.7,2.5-1.5,5.1-2.2,7.6c-1,3.4-2.1,6.7-3.4,10
		c-0.6,1.7-1.3,3.2-1.9,4.9c-0.3,0.8-0.7,1.5-1.1,2.3s-0.7,1.5-1.2,2.4s-0.8,1.6-1.2,2.4l-0.7,1.3c-0.6,1.1-1.1,2.3-1.7,3.4
		c-0.4,0.8-0.8,1.5-1.2,2.3s-0.8,1.3-1.2,1.9c-0.2,0.2-0.3,0.5-0.5,0.7l-0.5,0.7c-0.4,0.6-0.9,1.2-1.4,1.8c-0.8,0.9-1.6,1.8-2.6,2.5
		c-2.6,2-5.5,3.5-8.6,4.5c-0.4,0.1-0.8,0.2-1.2,0.3c-0.8,0.2-1.6,0.5-2.4,0.6c-0.4,0.1-0.8,0.2-1.2,0.2l-2.5,0.5
		c-0.4,0.1-0.8,0.1-1.3,0.2l-1.3,0.2c-2.6,0.3-5.1,0.6-7.7,0.7c-1.7,0.1-3.5,0.2-5.2,0.3l-2.6,0.1c-1.8,0.1-3.5,0.1-5.3,0.1
		c-14.1,0.4-28.2,0.6-42.4,0.8c-28.2,0.3-56.6,0.9-84.8,0.9l-84.9-0.2l-84.9,0.2c-7,0-14.1,0-21.1-0.1s-14.1-0.2-21.2-0.2
		c-7.1-0.1-14.1-0.2-21.2-0.3l-21.2-0.3c-14.1-0.2-28.3-0.4-42.4-0.8l-5.3-0.1l-2.6-0.1c-1.7-0.1-3.5-0.1-5.2-0.3
		c-2.6-0.2-5.2-0.4-7.7-0.7c-0.9-0.1-1.7-0.2-2.6-0.4l-2.5-0.5c-0.4-0.1-0.8-0.1-1.1-0.2l-0.4-0.1l-0.8-0.2L178,417l-1-0.3
		c-0.4-0.1-0.8-0.2-1.2-0.3c-1.2-0.4-2.3-0.8-3.4-1.4l-1.1-0.5l-0.6-0.3l0.6,0.3l-0.7-0.4l-0.4-0.2c-0.7-0.4-1.4-0.8-2-1.3
		c-0.3-0.2-0.6-0.5-0.9-0.7s-0.6-0.5-0.9-0.8s-0.6-0.6-0.9-0.9c-0.4-0.5-0.8-0.9-1.2-1.4c-0.2-0.3-0.4-0.6-0.6-0.9
		c-0.4-0.5-0.8-1.1-1.1-1.6l-0.2-0.3c-0.4-0.6-0.8-1.2-1.1-1.8l0,0c-0.8-1.5-1.6-3-2.4-4.6l0,0c-0.8-1.6-1.6-3.1-2.4-4.7l0,0
		c-0.4-0.8-0.8-1.6-1.1-2.4s-0.8-1.5-1.1-2.3c-0.2-0.5-0.4-0.9-0.6-1.4c-0.4-0.9-0.8-1.8-1.1-2.8c-0.1-0.2-0.2-0.5-0.3-0.7
		c-1.2-3.3-2.3-6.6-3.4-10l0,0c-0.8-2.5-1.5-5-2.2-7.5l0,0c-0.1-0.2-0.1-0.5-0.2-0.7l-0.1-0.5l0,0c-0.7-2.5-1.3-5-2-7.6l0,0
		c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.3-0.1-0.5l0,0c-0.5-1.8-0.9-3.7-1.4-5.5c-0.1-0.4-0.2-0.7-0.3-1c-0.4-1.7-0.8-3.4-1.2-5l0,0
		c-0.1,0-0.2,0.1-0.2,0.1l-0.4,0.1l-0.4,0.1l-0.3,0.1l-0.3,0.1c0.1,0.6,0.2,1.2,0.3,1.8c0.4,2.2,0.9,4.5,1.3,6.7
		c0.4,1.7,0.7,3.5,1.1,5.2s0.8,3.5,1.2,5.2s0.8,3.5,1.2,5.2s0.9,3.4,1.3,5.2s0.9,3.4,1.4,5.1s1,3.4,1.6,5.1l0.4,1.3l0.5,1.3
		c0.3,0.8,0.6,1.7,0.9,2.5c0.2,0.4,0.4,0.8,0.5,1.3l0.5,1.3l0.5,1.2c0.2,0.4,0.4,0.8,0.6,1.2c0.7,1.6,1.4,3.2,2.2,4.8
		c0.4,0.8,0.8,1.6,1.1,2.4s0.8,1.6,1.2,2.4c0.2,0.4,0.4,0.8,0.7,1.2s0.5,0.8,0.7,1.2c1,1.6,2.1,3.2,3.4,4.6c0.3,0.4,0.7,0.7,1,1
		l0.1,0.1c0.3,0.3,0.6,0.6,1,0.9s0.7,0.6,1.1,0.9c0.8,0.6,1.5,1.1,2.4,1.6c0.8,0.5,1.7,1,2.5,1.4c1.3,0.6,2.6,1.2,3.9,1.7
		c0.9,0.3,1.8,0.5,2.7,0.8s1.8,0.5,2.7,0.7l2.7,0.6c0.9,0.2,1.8,0.3,2.7,0.5h0.3l0,0c1.7,0.3,3.4,0.5,5,0.7l2.7,0.3
		c1.8,0.2,3.6,0.3,5.4,0.4c2.7,0.2,5.3,0.3,8,0.4c7.3,0.3,14.6,0.6,21.9,0.9c6.8,0.3,13.7,0.5,20.5,0.7c3.5,0.1,7.1,0.2,10.6,0.4
		l10.6,0.3c7.1,0.2,14.1,0.4,21.2,0.6l10.6,0.3l10.6,0.3h0.2c3.5,0.1,7.1,0.2,10.7,0.2s6.9,0.1,10.4,0.1l84.9,0.2l84.9-0.2
		c10.7-0.1,21.3-0.4,31.9-0.6l10.6-0.3c7.1-0.2,14.1-0.4,21.2-0.6c7.1-0.2,14.1-0.4,21.2-0.7l10.6-0.4l10.6-0.4l3-0.1
		c6.1-0.2,12.1-0.5,18.2-0.8c3.5-0.2,7.1-0.3,10.7-0.6l2.7-0.2l1.5-0.2l1-0.1c1.5-0.2,2.9-0.4,4.4-0.6l1.2-0.2l1.3-0.2l0.7-0.1
		l0.7-0.1l2.7-0.6c0.9-0.2,1.8-0.4,2.7-0.7c0.9-0.3,1.8-0.6,2.7-0.8c1.3-0.5,2.6-1,3.9-1.7c0.8-0.4,1.7-0.9,2.5-1.4
		c1.2-0.8,2.4-1.6,3.5-2.6c0.4-0.3,0.7-0.6,1.1-1c0.7-0.7,1.3-1.4,1.9-2.1c0.6-0.8,1.2-1.5,1.7-2.3c0.8-1.2,1.5-2.4,2.1-3.6
		c1.7-3.2,3.1-6.5,4.6-9.7c0.4-0.8,0.7-1.6,1.1-2.5s0.7-1.7,1.1-2.5s0.6-1.7,0.9-2.5s0.6-1.7,0.9-2.5c1.1-3.4,2.1-6.8,3-10.2
		c0.5-1.7,0.9-3.4,1.3-5.2s0.8-3.5,1.2-5.2c0.8-3.5,1.6-6.9,2.3-10.4c0.2-1.1,0.5-2.2,0.7-3.3c0.3-1.7,0.7-3.4,1-5.1l-0.4-0.1
		L696.9,346.9z M248.9,420.4c-14.1-0.2-28.3-0.4-42.4-0.8C220.7,420,234.8,420.2,248.9,420.4L248.9,420.4z"
            />
        </g>
        <g id="arriere-pare-choc-arriere">
            <path
                className="carBackSide0"
                d="M828.4,443.7c-0.7-11-4.9-49.4-11.3-65.9l0,0c-4.8,3-9.7,5.8-14.7,8.4l-0.4,0.2c-67,34.9-148.6,37-157.8,38
		c-3,0.3-10.7,0.8-19.8,1.2v-0.1c-12,0.5-24.1,1-36.1,1.4c-14.1,0.5-28.3,0.9-42.4,1.2s-28.2,0.7-42.5,0.9l-84.9,0.2l-84.9-0.2
		c-14.2-0.1-28.3-0.6-42.5-0.9s-28.3-0.7-42.4-1.2c-10.9-0.4-21.7-0.8-32.6-1.2v0.1c-10.7-0.5-19.9-1-23.3-1.3
		c-9.2-1-90.8-3.1-157.8-38l-0.4-0.2c-5-2.6-9.9-5.4-14.7-8.4c-6.4,16.5-10.6,55-11.3,65.9s-0.4,38.7,0.1,50.1
		c0.4,9.7,2.9,37.6,4.8,54.8c0.3,3,0.6,5.6,0.9,7.8c1.9,14.9,7.3,42.7,9.1,51.3s6,8.3,6,8.3h5.9c0.7,0,0.9-0.6,0.8-1.2l53.7,1.8
		l28.5,1l9.8,0.3l-0.8-0.8l0,0l89.4,0.3h12.4v4.6h108.7l2.3-3h156.2l2.3,3h108.8v-4.6H620l89.4-0.3c0.7-0.7,1.3-1.3,1.9-2
		c-0.6,0.7-1.3,1.3-1.9,2l-0.8,0.8l9.8-0.3l28.5-1l53.7-1.9c0,0.6,0.1,1.2,0.8,1.2h5.9c0,0,4.2,0.3,6-8.3s7.2-36.4,9.1-51.3
		c0.3-2.2,0.6-4.8,0.9-7.8c1.9-17.2,4.4-45.1,4.8-54.8C828.8,482.4,829.1,454.6,828.4,443.7z"
            />
            <polygon
                className="carBackSide13"
                points="407,553.5 408.1,553.5 428.9,553.5 430,553.5 514.8,553.5 554.9,553.5 554.9,488.9 514.8,488.9
		430,488.9 428.9,488.9 408.1,488.9 407,488.9 322.2,488.9 282.1,488.9 282.1,553.5 322.2,553.5 	"
            />
            <path
                className="carBackSide13"
                d="M817.9,481.9c0.1-4.7,0.2-9.2,0.2-13.9c-0.2-27.9-1.8-67-5.5-87.4l0,0c-1,0.6-2.1,1.2-3.2,1.8s-2,1.1-3.1,1.7
		l-1.7,0.9l0,0l-4.9,2.5l0,0c-1.4,0.7-2.9,1.4-4.3,2.1c-0.9,0.5-1.8,0.9-2.8,1.3C732,419.4,663,423,646.9,424.2h-0.1h-0.5h-0.2h-0.4
		h-0.3H645h-0.2h-0.5l-0.6,0.1l-0.7,0.1l-0.8,0.1l-0.9,0.1l-1,0.1l-1.1,0.1l-1.2,0.1l-2.6,0.1l-1.6,0.1h-0.5L632,425h-0.5h-0.9
		l-2.8,0.1l0,0l-3.2,0.1h-0.1v-0.1c-6,0.3-12,0.5-18,0.7l-18,0.6c-7.1,0.3-14.1,0.5-21.2,0.7s-14.1,0.4-21.2,0.6h-0.4
		c-7,0.2-13.9,0.3-20.8,0.5l-10.6,0.2c-3.5,0.1-7.1,0.1-10.6,0.1l-84.9,0.2l-84.9,0.1c-14.2-0.1-28.3-0.6-42.5-0.9
		c-7.1-0.2-14.1-0.4-21.2-0.6s-14.1-0.4-21.2-0.7c-5.4-0.2-10.9-0.4-16.3-0.6c-5.4-0.2-10.9-0.4-16.3-0.6v0.1l0,0l-2-0.1l-3.8-0.2
		l-7-0.4l-2-0.1h-0.5l-1.9-0.1l-1.3-0.1c-1.7-0.1-3-0.2-4.1-0.3l-0.7-0.1h-0.5h-0.2H192h-0.3h-0.4h-0.4H190h-0.5H189h-0.5l-0.9-0.1
		l-1-0.1h-0.3l-2.8-0.2h-0.4l-1.1-0.1h-0.5l-1.1-0.1l-0.7-0.1l-1.1-0.1l-0.7-0.1l-1.1-0.1l-0.7-0.1l-1.2-0.1L174,423l-1.4-0.1
		l-0.7-0.1l-2.1-0.2l-4.2-0.4l-4.4-0.5l-4.7-0.6l-2.8-0.4l-0.9-0.1l-2-0.3l-1-0.1l-2.1-0.3l-0.9-0.1l-3-0.4
		c-1.8-0.3-3.6-0.6-5.4-0.9c-1.2-0.2-2.5-0.4-3.7-0.6l-0.9-0.1c-1.3-0.2-2.5-0.4-3.8-0.7c-1.9-0.3-3.9-0.7-5.8-1.1l-4-0.8l-1-0.2
		c-1.3-0.3-2.7-0.6-4.1-0.9c-2-0.4-4.1-0.9-6.2-1.4l-3.1-0.8c-1.4-0.3-2.8-0.7-4.2-1l-1-0.3l-4.2-1.1c-2.1-0.6-4.3-1.2-6.4-1.8
		l-4.3-1.3l-1.1-0.3c-1.4-0.4-2.9-0.9-4.3-1.3s-2.9-0.9-4.3-1.4l-1.1-0.4l-4.3-1.5c-1.4-0.5-2.9-1-4.3-1.6l-1.2-0.4
		c-1.5-0.5-2.9-1.1-4.3-1.7c-2.2-0.9-4.3-1.7-6.5-2.6c-1.3-0.5-2.6-1.1-3.9-1.7l-0.9-0.4l-3.3-1.5l-0.6-0.3c-2.8-1.3-5.5-2.6-8.2-4
		l0,0l-4.8-2.5l0,0c-1.3-0.7-2.7-1.4-4-2.2l0,0c-1.3-0.7-2.6-1.5-3.9-2.3l0,0c-0.4,2.1-0.8,4.5-1.1,7.2c-0.4,3.2-0.9,6.8-1.2,10.7
		c-0.1,1-0.2,1.9-0.3,2.9c-0.2,1.9-0.3,3.9-0.5,5.9c-2,25.2-2.3,52.9-2.4,60.8c0,2.8,0,5.7,0,8.4c0,1.7,0.1,3.4,0.1,5.5v0.6l0.1,1.9
		c0.1,3.8,0.3,7.6,0.6,11.6c0.2,2.5,0.4,5,0.6,7.7c0.1,1.3,0.2,2.6,0.3,3.9c0.2,2.5,0.5,5.1,0.8,8c0.5,5.3,1.1,10.8,1.9,17.4
		c1.1,9.9,3.8,25.6,7.8,45.4c0.2,1,0.4,2,0.6,3c0.2,1,0.4,2,0.6,2.9c0.4,1.9,0.8,3.8,1.2,5.6l0.6,2.7c0.3,1.3,0.5,2.5,0.8,3.8
		l0.5,2.3l0.2,1.1c0.6,2.9,1.2,5.3,1.6,7.2l0.2,0.8l0.3,1.3l0.1,0.5l0.1,0.2c2,0.7,3.3,2.5,3.4,4.5c0,0.1,0,0.3,0,0.4l46.9,1.7h1.4
		H90l28.5,1h0.5l2.2,0.1c-1-1.1-2-2.1-2.9-3.2c-1.2-1.3-2.4-2.7-3.5-4l0,0c-0.6-0.7-1.1-1.3-1.6-1.9c-1.1-1.3-2.1-2.6-3.1-3.9
		c-11.3-14.6-16.8-27.5-16.8-39.5c0-4.6,1.4-10.7,4.2-18.7c0.5-1.5,1.2-3.2,1.8-4.8s1.3-3.3,2-5c2.3-5.5,5.1-11.5,8.3-17.8
		c0.8-1.6,1.7-3.3,2.5-5c0.7-1.3,1.4-2.7,2.1-4l0.2-0.3l0.3-0.5c0.8-1.6,1.7-3.1,2.5-4.6s1.7-3,2.4-4.4c0.4-0.7,0.8-1.4,1.2-2.1l0,0
		l1-1.7v-0.1l0.3-0.5l0,0l0.2-0.3l0.6-1l0,0l0.1-0.1l0.2-0.4l0.1-0.1c0.3-0.5,0.6-1,0.9-1.5l0,0c0.9-1.5,1.9-3,2.9-4.4
		c0.1-0.1,0.1-0.2,0.2-0.2c0.4-0.6,0.8-1.1,1.3-1.7l0.5-0.6c0.3-0.4,0.7-0.8,1-1.2l0,0c0.4-0.5,0.8-0.9,1.2-1.4
		c0.8-0.9,1.7-1.7,2.6-2.5c0.4-0.4,0.9-0.8,1.3-1.1c1.3-1.1,2.6-2.1,4-3c0.9-0.6,1.8-1.1,2.7-1.7s1.8-1,2.8-1.4
		c1.4-0.6,2.8-1.2,4.2-1.7c1.8-0.6,3.7-1.1,5.6-1.5c1.3-0.3,2.6-0.5,4.1-0.7c2-0.3,4.1-0.5,6.6-0.7l1.3-0.1c0.9-0.1,1.7-0.1,2.6-0.1
		l2.8-0.1l4.4-0.1h1.5l3-0.1c3.1-0.1,6.6-0.1,10.1-0.1h3.8c4.4,0,7.6,0,7.9,0h432c0.3,0,2.9,0,6.8,0h3.6c4.1,0,7.9,0,11.5,0.1
		c5.8,0.1,10.7,0.3,14.4,0.5c13.9,0.9,29.8,3.5,42.2,24c1.8,3,3.8,6.4,5.7,9.9c0.8,1.5,1.6,3,2.5,4.5l0,0c0.6,1,1.2,2.1,1.7,3.2
		c0.3,0.6,0.7,1.3,1,1.9l0.2,0.3l0,0c0.7,1.4,1.5,2.8,2.2,4.3c1.3,2.6,2.6,5.1,3.8,7.6c0.8,1.7,1.6,3.5,2.4,5.1
		c1.2,2.6,2.3,5.2,3.4,7.6c5.4,12.8,8,22.1,8,28.5c0,12-5.5,24.9-16.8,39.5c-1.5,1.9-3.1,3.9-4.7,5.8l-0.1,0.1l0,0
		c-0.6,0.7-1.2,1.3-1.8,2l-0.9,1c-0.8,0.9-1.6,1.8-2.5,2.7l-0.2,0.3l-1.1,1.1l2.7-0.1l28.5-1l48.8-1.6c0-0.1,0-0.3,0-0.4
		c0.1-2.1,1.4-3.9,3.4-4.5c2.4-10.7,12-54.9,14.5-76.9C815.8,513.6,817.4,498.6,817.9,481.9z"
            />
            <path
                className="carBackSide13"
                d="M279.6,559.5h282.7l15.1-81.8l0.9-5H258.6l0.9,5l15.1,81.8H279.6z M277.1,545.4L277.1,545.4v-61.5h282.7v74.7
		H277.1V545.4z"
            />
            <path
                className="carBackSide13"
                d="M828.4,443.7c0-0.4,0-0.8-0.1-1.2c0-0.1,0-0.2,0-0.4c0-0.3,0-0.6-0.1-1v-0.4c0-0.4-0.1-0.8-0.1-1.2v-0.3
		c0-0.4-0.1-0.9-0.1-1.4v-0.3c0-0.5-0.1-1.1-0.2-1.6v-0.2c-0.1-0.6-0.1-1.2-0.2-1.9l0,0c-0.1-0.7-0.2-1.4-0.2-2l0,0
		c-0.1-0.7-0.1-1.4-0.2-2.2c0-0.5-0.1-1-0.2-1.5v-0.2c-0.2-1.4-0.3-2.9-0.5-4.4c0-0.2,0-0.4-0.1-0.6c-0.1-0.4-0.1-0.9-0.2-1.3
		l-0.1-0.7c-0.1-0.7-0.2-1.5-0.3-2.2c0-0.3-0.1-0.5-0.1-0.8s-0.1-0.8-0.2-1.1s-0.1-0.7-0.1-1l-0.2-1.1l-0.2-1.4
		c-0.1-0.4-0.1-0.9-0.2-1.3l-0.2-1.4c0-0.3-0.1-0.6-0.1-0.9c-0.1-0.5-0.1-0.9-0.2-1.4l-0.1-0.8c-0.1-0.7-0.2-1.4-0.3-2.1
		c0-0.2-0.1-0.4-0.1-0.7l-0.3-1.6c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.6-0.2-1.2-0.3-1.7c0-0.1,0-0.3-0.1-0.5l-0.4-2.1l-0.1-0.6
		c-0.1-0.5-0.2-1.1-0.3-1.6c0-0.2-0.1-0.4-0.1-0.6l-0.4-2.1v-0.1c-0.1-0.6-0.3-1.3-0.4-1.9c0-0.2-0.1-0.4-0.1-0.6
		c-0.1-0.5-0.2-1-0.3-1.5c0-0.2-0.1-0.3-0.1-0.5c-0.2-0.6-0.3-1.3-0.5-1.9l-0.1-0.3l-0.4-1.5l-0.2-0.5c-0.1-0.5-0.3-1-0.4-1.5
		c0-0.1,0-0.2-0.1-0.3c-0.2-0.6-0.3-1.1-0.5-1.6c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.4-0.3-0.8-0.4-1.2l-0.2-0.4c-0.2-0.5-0.3-1-0.5-1.4
		l0,0c0.3,1.4,0.5,2.9,0.8,4.4c0.4,2.3,0.7,4.8,1.1,7.4c0.2,1.8,0.4,3.6,0.6,5.4c0.1,0.9,0.2,1.9,0.3,2.8c0.4,3.8,0.7,7.8,1,11.9
		c0.1,1,0.2,2.1,0.2,3.1c0.1,2.1,0.3,4.2,0.4,6.3c0.1,2.1,0.2,4.2,0.3,6.3s0.2,4.2,0.3,6.3s0.2,4.2,0.2,6.2
		c0.4,11.3,0.6,21.8,0.7,29.9c0,3.7,0,7.2-0.1,10.7c0,1.1,0,2.3-0.1,3.4c-0.1,4.2-0.3,8.4-0.6,12.5c-0.1,2-0.3,4.1-0.4,6.1
		c-0.2,3.1-0.5,6.2-0.8,9.3c-0.1,1-0.2,2.1-0.3,3.2c-0.6,6.4-1.4,13.1-2.2,20.5c-0.1,0.8-0.2,1.6-0.3,2.4c-0.4,2.9-0.8,6.1-1.3,9.4
		c-0.2,1.4-0.5,2.9-0.7,4.4c-0.1,0.5-0.2,1-0.2,1.5c-0.2,1.5-0.5,3-0.8,4.6s-0.6,3.1-0.8,4.7c-0.2,1-0.4,2.1-0.6,3.2
		c-0.7,3.7-1.4,7.4-2.1,11.1c-0.4,2.1-0.8,4.1-1.2,6.2l-1.2,5.9c-0.2,1-0.4,1.9-0.6,2.8c-0.3,1.4-0.6,2.7-0.8,4l-0.3,1.3
		c-0.2,0.8-0.3,1.7-0.5,2.5s-0.3,1.6-0.5,2.3c-0.7,3.4-1.4,6.2-1.8,8.4c-0.1,0.5-0.2,0.9-0.3,1.3c0,0.2-0.1,0.4-0.1,0.6l-0.3,1.3
		c-0.1,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.1,0.5-0.2,0.7v0.1c-0.1,0.2-0.1,0.3-0.2,0.5v0.1c-0.1,0.1-0.2,0.3-0.2,0.4l0,0
		c-0.1,0.1-0.2,0.2-0.2,0.3l0,0c-0.1,0.1-0.2,0.1-0.2,0.2l0,0c-0.1,0-0.1,0.1-0.2,0.1l0,0h-0.2h-0.2h-1c0,0.6,0.1,1.2,0.8,1.2h5.9
		h0.2h0.2l0,0h0.2h0.1l0.2-0.1h0.2l0,0l0.1-0.1h0.1l0.2-0.1l0.2-0.1l0.2-0.1h0.1l0.2-0.1H810l0,0l0.3-0.2l0,0l0.2-0.2l0.3-0.3
		l0.2-0.3l0.3-0.4c0.1-0.1,0.2-0.2,0.2-0.3l0.2-0.3l0.1-0.2l0,0l0.2-0.4l0,0c0.1-0.2,0.2-0.3,0.3-0.5l0,0c0.1-0.1,0.1-0.3,0.2-0.4
		l0.1-0.2c0.1-0.1,0.1-0.3,0.2-0.4l0.2-0.5c0-0.1,0-0.2,0.1-0.2s0.1-0.4,0.2-0.6l0,0c0-0.1,0.1-0.3,0.1-0.4c0.1-0.5,0.2-1,0.3-1.5
		c1.8-8.7,7.2-36.4,9.1-51.3c0.3-2.2,0.6-4.8,0.9-7.8c1.9-17.2,4.4-45.1,4.8-54.8C828.8,482.4,829.1,454.6,828.4,443.7z"
            />
            <path
                className="carBackSide13"
                d="M715.9,610.3l0.9-1l0,0l0,0l0.7-0.8l0.9-1.1l0,0c0.4-0.5,0.8-1,1.2-1.4l0.4-0.5c0.5-0.6,1-1.3,1.6-1.9
		l1.5-1.8c0.7-0.8,1.3-1.7,1.9-2.5c1.8-2.5,3.5-4.9,5-7.3c1-1.6,1.9-3.2,2.7-4.7s1.6-3.1,2.2-4.6s1.2-3,1.7-4.5s0.9-2.9,1.2-4.4
		c0.2-0.7,0.3-1.4,0.4-2.1c0.3-1.6,0.4-3.1,0.5-4.7c0-0.5,0-1,0-1.5s0-0.8,0-1.2s-0.1-0.8-0.1-1.3c-0.1-0.9-0.2-1.8-0.4-2.8
		c0.1,0.5,0.2,1,0.2,1.4c-0.1-0.5-0.2-1.1-0.3-1.7c-0.2-0.9-0.4-1.8-0.6-2.7c-0.4-1.6-0.9-3.3-1.4-5c-0.4-1.2-0.8-2.4-1.2-3.6
		c-0.7-1.8-1.4-3.7-2.1-5.7c-0.5-1.3-1.1-2.6-1.6-3.9l-0.8-1.9c-0.8-1.8-1.6-3.7-2.5-5.6c-0.8-1.7-1.6-3.3-2.4-5
		c-1.2-2.4-2.4-4.9-3.6-7.3l-0.1-0.2c-0.7-1.4-1.4-2.8-2.2-4.2l-1.1-2.2c-0.3-0.5-0.6-1.1-0.8-1.6s-0.6-1.1-0.8-1.6l-1.2-2.3
		c-0.4-0.7-0.8-1.4-1.2-2.1v-0.1l0,0l0,0c-0.5-0.8-0.9-1.6-1.3-2.4l-0.4-0.7c-0.3-0.6-0.6-1.1-1-1.7l-0.4-0.7l-1.2-2.3l-1.3-2.2
		c-1.9-3.1-4-6-6.5-8.7c-0.4-0.4-0.8-0.8-1.1-1.2c-1.1-1.1-2.3-2.1-3.5-3.1c-0.8-0.6-1.6-1.2-2.4-1.7c-8-5.3-16.5-6.4-24.8-6.9
		c-2.6-0.2-5.5-0.3-8.6-0.4c-1.2,0-2.5-0.1-3.7-0.1h-1.9h-3h-2.9H645h-61.5c0,0.4,0,0.7-0.1,1.1l-0.7,3.9l-15.3,82.7
		c-0.4,2.4-2.5,4.1-4.9,4.1H274.6c-0.6,0-1.2-0.1-1.7-0.3c-0.1-0.1-0.2-0.1-0.4-0.2l-0.4-0.2l-0.4-0.2c-0.1-0.1-0.2-0.2-0.4-0.2
		l-0.3-0.3l-0.3-0.3l-0.2-0.3c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.1-0.1-0.2-0.1-0.3s-0.2-0.3-0.2-0.5c-0.1-0.3-0.2-0.5-0.2-0.8
		c0,0,0,0,0-0.1l-15.3-82.7l-0.8-4.1l0,0c0-0.3-0.1-0.6-0.1-0.9l0,0h-62.9h-2.4c-1.6,0-3.3,0-5.1,0h-2.6h-0.1l-2.9,0.1h1.4h-1.5H176
		h-0.1l-4.1,0.1h-0.2c-0.9,0-1.9,0.1-2.8,0.1l-2.6,0.1l-1.2,0.1c-1,0.1-2.1,0.2-3.1,0.3s-2.1,0.2-3.1,0.4s-2.3,0.4-3.5,0.6h-0.1
		h-0.1c-0.8,0.2-1.7,0.4-2.5,0.6c0.8-0.2,1.7-0.4,2.5-0.6c-1.7,0.4-3.4,0.8-5,1.4c-0.5,0.2-1,0.4-1.4,0.5l-1.3,0.5l-0.2,0.1
		l-0.8,0.3c-0.6,0.3-1.2,0.6-1.8,0.9l0.6-0.3c-0.4,0.2-0.8,0.4-1.2,0.6l-1.2,0.7c-0.4,0.2-0.8,0.5-1.2,0.8s-0.6,0.4-0.9,0.6
		s-0.6,0.4-0.9,0.6c-0.6,0.4-1.2,0.9-1.8,1.4c-0.4,0.3-0.7,0.6-1.1,1h-0.1c-0.4,0.4-0.8,0.7-1.1,1.1c0.4-0.4,0.8-0.7,1.1-1.1h0.1
		h-0.1c-0.8,0.7-1.5,1.5-2.3,2.3c-0.4,0.4-0.8,0.8-1.1,1.2c-0.2,0.3-0.5,0.5-0.7,0.8l-0.1,0.1c-0.4,0.5-0.9,1.1-1.3,1.6l-0.6,0.8
		l-0.1,0.1c-0.4,0.6-0.8,1.2-1.2,1.8c0.3-0.5,0.7-1,1-1.5c-0.4,0.5-0.7,1-1,1.5s-0.7,1.1-1.1,1.7c-0.1,0.1-0.2,0.3-0.3,0.5l0,0
		l-0.1,0.1l-0.6,1l0.2-0.3c-0.2,0.3-0.3,0.5-0.5,0.8l-0.1,0.2l-0.2,0.3l-0.5,0.9L126,498l-0.1,0.1l-0.2,0.4l0,0l-1,1.8l-0.2,0.3
		l-0.7,1.3l-0.2,0.4l0,0l-1.2,2.1l-0.1,0.2l-1.1,2l-1.4,2.5l-0.7,1.3l-0.4,0.7l-0.2,0.3l-0.1,0.1l-0.2,0.4c-0.7,1.3-1.4,2.6-2.1,3.9
		c-0.8,1.6-1.7,3.3-2.5,4.9c-0.7,1.3-1.3,2.6-1.9,3.9c-0.2,0.4-0.4,0.8-0.6,1.2c-0.5,1-0.9,2-1.4,3c-0.1,0.2-0.2,0.5-0.3,0.7
		l-0.3,0.8c-0.4,1-0.9,1.9-1.3,2.9l-0.5,1.2c-0.6,1.3-1.2,2.6-1.7,3.9l0,0c-0.4,1-0.9,2.1-1.3,3.1c0,0.1-0.1,0.2-0.1,0.3
		c-0.2,0.5-0.4,1-0.6,1.4c-0.3,0.8-0.6,1.6-0.9,2.3s-0.6,1.5-0.8,2.3s-0.6,1.7-0.9,2.5c-0.8,2.5-1.5,4.8-2,7c-0.5,1.9-0.8,3.9-1,5.9
		c0,0.6-0.1,1.2-0.1,1.7l0,0c0,1,0,2,0.1,3.1s0.2,2.1,0.4,3.2c0.1,0.7,0.3,1.4,0.4,2.1c0.2,0.9,0.4,1.8,0.7,2.7
		c0.3,1.1,0.7,2.2,1.1,3.3c0.2,0.5,0.4,1,0.6,1.5c0.3,0.8,0.7,1.7,1.1,2.6l0,0c0.5,1.1,1,2.2,1.7,3.4c0.4,0.8,0.8,1.6,1.3,2.3
		l0.5,0.9c0.4,0.7,0.9,1.4,1.3,2.2l0,0c0.4,0.7,0.9,1.4,1.4,2.1l0,0l0.3,0.5c0.3,0.5,0.7,1,1.1,1.6l0.2,0.3c0.5,0.7,1,1.4,1.5,2.1
		c0,0,0,0.1,0.1,0.1c0.6,0.8,1.2,1.7,1.9,2.5c0.5,0.6,1,1.2,1.5,1.9l1.5,1.9l0.4,0.5l1.1,1.4l0,0c1.1,1.3,2.2,2.6,3.4,3.9
		c0.9,1,1.8,2,2.7,3s1.9,2,2.9,3h5.7l83.7,0.3h12.4v4.6h108.8l2.3-3h156.2l2.3,3h108.8v-4.6H620l83.7-0.3h5.7l0.3-0.4l0.1-0.1
		l1.5-1.6c0.6-0.7,1.3-1.3,1.9-2C714.2,612.2,715,611.2,715.9,610.3L715.9,610.3z M114.6,585.3h-0.3H114c-0.7,0-1.3-0.1-1.9-0.2
		c-0.4-0.1-0.8-0.3-1.2-0.5c-0.4-0.2-0.9-0.5-1.2-0.8c-0.9-0.7-1.7-1.6-2.2-2.6c-0.7-1.3-1.4-2.9-2.1-4.5c-1.1-2.5-1.9-4.8-2.2-5.4
		c-0.5-1-0.6-2.2-0.4-3.3c0.1-0.4,0.3-0.7,0.5-1c0.1-0.2,0.2-0.3,0.3-0.5c0.9-1,2-1.6,3.3-1.8c0.3-0.1,0.7-0.1,1-0.1
		c0.4,0,0.7,0,1.2,0l62.5,1h0.1c1.1,0,2.1,0.2,3.1,0.7c0.2,0.1,0.4,0.3,0.6,0.5c0.2,0.2,0.4,0.5,0.6,0.8c0.1,0.1,0.1,0.3,0.2,0.5
		c0.1,0.2,0.1,0.4,0.1,0.6c0.1,0.4,0.1,0.7,0,1.1l0,0c0,0.1-0.1,0.2-0.1,0.4c-0.1,0.2-0.1,0.4-0.2,0.5l-0.1,0.3
		c-0.1,0.1-0.2,0.3-0.3,0.4l-0.2,0.2c-0.1,0.1-0.2,0.2-0.4,0.4c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.2-0.4,0.4-0.6,0.6l-0.6,0.5
		l-1.6,1.5l-1,0.9c-0.7,0.6-1.4,1.3-2.2,2c-1.4,1.2-2.5,2.2-3.6,3.1c-0.4,0.3-0.8,0.7-1.3,1s-0.8,0.6-1.3,0.9
		c-0.8,0.6-1.7,1.1-2.5,1.6c-0.4,0.2-0.9,0.4-1.3,0.6c-0.9,0.4-1.8,0.6-2.7,0.7c-1.2,0.1-4,0.2-8.7,0.2h-3.2h-5.3
		c-1.8,0-3.7,0-5.5-0.1L114.6,585.3z M491.5,600.3c-0.5,0.3-1.1,0.5-1.6,0.8s-1,0.4-1.5,0.6l-0.4,0.1h-0.1l-1.2,0.4h-0.1l-0.4,0.1
		c-0.5,0.2-1.1,0.4-1.7,0.5c-0.9,0.3-1.8,0.5-2.7,0.7c-1.4,0.3-2.9,0.6-4.4,0.9c-4.9,0.7-9.8,1.1-14.7,1.1h-4.5h-5
		c-5.3,0-11.6,0-18.8,0h-15.8h-15.8c-7.2,0-13.5,0-18.8,0h-5h-4.5c-12.1-0.1-20.4-1.6-27.8-4.9c-1.4-0.6-2.7-1.3-4.2-2.1
		c-0.7-0.4-1.3-0.7-2-1.1l-1.9-1.2l-1.9-1.2l-0.7-0.4l-2.3-1.4l-1-0.6c-0.9-0.5-1.7-1.1-2.5-1.7c-0.4-0.3-0.7-0.6-1-0.9l0,0
		c-0.3-0.3-0.7-0.7-0.9-1.1c-0.5-0.7-1-1.5-1.2-2.3c-0.3-1.1-0.4-2.2-0.1-3.3c0.3-1,0.8-2,1.5-2.7c0.5-0.6,1.1-1,1.8-1.4
		c0.5-0.3,1-0.5,1.6-0.7c0.5-0.2,0.9-0.3,1.5-0.4c1.1-0.2,2.2-0.4,3.3-0.5l1.2-0.1c0.9,0,1.6,0,2.1,0h157.9c4.8,0,11.4,0.6,12.8,5.7
		s-4,8.5-5.8,9.6c-1.2,0.7-2.3,1.4-3.3,2.1C498.1,596.8,494.9,598.7,491.5,600.3L491.5,600.3z M712.8,585.4L712.8,585.4v-2.5V585.4
		l-5.5,0.1l-9.1,0.1h-5.3h-3.2c-4.7,0-7.5,0-8.7-0.2c-2.3-0.2-5.2-1.8-9-4.8c-1.1-0.9-2.3-1.9-3.6-3.1c-0.8-0.7-1.5-1.4-2.2-2
		l-1-0.9l-1.6-1.5l-0.6-0.5c-0.2-0.2-0.5-0.4-0.6-0.6l-0.2-0.2c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.1-0.1-0.1-0.2-0.2
		c-0.1-0.1-0.2-0.3-0.3-0.4l-0.2-0.3c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.1-0.1-0.3-0.1-0.4c0-0.2-0.1-0.4-0.1-0.6c0-0.1,0-0.3,0-0.4
		c0-0.2,0.1-0.4,0.2-0.7c0-0.1,0.1-0.3,0.2-0.4c0.2-0.3,0.3-0.5,0.6-0.7l0,0c0.2-0.2,0.5-0.4,0.7-0.5l0.2-0.1c0.1,0,0.2,0,0.4-0.1
		l0.2-0.1c0.1,0,0.2-0.1,0.3-0.1h0.2l0.3-0.1h0.2h0.3h0.3h0.7l62.5-1l0,0c2.9,0,4.9,0.8,5.9,2.4v0.1c0.7,1.3,0.7,3,0,4.3
		c-0.2,0.6-1,2.7-2,5.1c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.5-0.4,1-0.6,1.4c-0.1,0.3-0.2,0.5-0.4,0.8c-0.4,0.9-0.8,1.7-1.2,2.3
		c-0.4,0.8-1,1.5-1.6,2.1c-0.2,0.2-0.4,0.4-0.6,0.5c-0.7,0.6-1.6,1-2.5,1.2c-0.7,0.2-1.3,0.3-2,0.2h-0.3h-0.3L712.8,585.4z"
            />
            <path className="carBackSide13" d="M664.3,569.3L664.3,569.3L664.3,569.3z" />
            <path className="carBackSide13" d="M712.8,502.1l0.1,0.3l0,0C713,502.2,712.9,502.1,712.8,502.1z" />
            <polygon className="carBackSide13" points="664,568.6 664.1,568.6 664,568.3 	" />
            <path
                className="carBackSide13"
                d="M138.9,580.7h5.3h3.2c5.6,0,7.5-0.1,8.2-0.1c0.4-0.1,0.8-0.2,1.2-0.3c0.3-0.1,0.6-0.3,0.9-0.4
		c0.7-0.4,1.4-0.8,2-1.3l1.1-0.8l1.1-0.9c1-0.8,2.1-1.8,3.4-2.9c0.8-0.7,1.5-1.3,2.1-1.9l1-0.9l0.5-0.5l-60-1c-0.2,0-0.5,0-0.7,0
		s-0.3,0-0.5,0.1c1.2,3.1,2.5,6.2,4,9.2c0.2,0.4,0.5,0.8,0.9,1.1c0.1,0.1,0.2,0.2,0.4,0.2l0.3,0.1c0.2,0,0.3,0.1,0.5,0.1
		c0.1,0,0.3,0,0.4,0h0.2l19,0.3L138.9,580.7z"
            />
            <path
                className="carBackSide13"
                d="M502.7,588l0.2-0.2l0.5-0.3l0.2-0.2c0.1-0.1,0.2-0.2,0.4-0.3l0.2-0.2l0.3-0.3l0.2-0.2
		c0.1-0.1,0.1-0.2,0.2-0.2l0.1-0.2l0.1-0.2l0.1-0.2l0.1-0.2c0-0.1,0-0.1,0-0.2V585v-0.1c0-0.1,0-0.1,0-0.2v-0.1
		c0-0.1-0.1-0.2-0.1-0.3v-0.1c0-0.1-0.1-0.1-0.1-0.2l-0.1-0.1l-0.2-0.1l-0.1-0.1l-0.2-0.1l-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1l-0.2-0.1
		l-0.2-0.1l-0.2-0.1l-0.3-0.1h-0.2l-0.3-0.1h-0.2l-0.3-0.1h-0.2L502,583h-0.1l-0.5-0.1h-0.1h-0.4h-0.2h-0.3h-0.2h-0.3h-0.2h-0.3
		H338.2h-0.5c-0.3,0-0.6,0-1,0.1c-0.9,0.1-1.7,0.2-2.6,0.4c-0.3,0.1-0.6,0.2-0.9,0.3s-0.5,0.2-0.8,0.3c-0.2,0.1-0.4,0.3-0.6,0.4
		c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.1,0.4,0,0.6c0.1,0.3,0.2,0.5,0.4,0.8c0.1,0.2,0.3,0.4,0.5,0.5l0,0c0.2,0.2,0.4,0.4,0.7,0.6
		c0.6,0.5,1.3,1,2,1.4l1,0.7c1,0.6,2,1.3,3,1.9l1.9,1.2c0.6,0.4,1.2,0.8,1.9,1.1s1.2,0.7,1.8,1.1c1.2,0.7,2.5,1.3,3.8,1.9
		c5.9,2.6,13.2,4.3,25.8,4.4h4.5h5h4.1h3.8h53.4h3.8h4.1h5h4.5c4.7,0,9.4-0.3,14-1c1.4-0.2,2.8-0.5,4-0.8c0.8-0.2,1.6-0.4,2.4-0.6
		l1.5-0.5l0.4-0.2l1.1-0.4l0.4-0.2c0.5-0.2,0.9-0.4,1.4-0.6s1-0.4,1.5-0.7c4.2-2,7.8-4.5,12.5-7.3L502.7,588z"
            />
            <polygon className="carBackSide13" points="665.3,568.6 665.4,568.5 665.4,568.4 665.1,568.6 	" />
            <path
                className="carBackSide13"
                d="M723.1,580.4c0.2,0,0.4,0,0.5-0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.3,0.5-0.6,0.7-0.9
		c0.3-0.5,0.6-1.2,1-2l0.3-0.8c0.2-0.5,0.5-1,0.7-1.6c0.9-2.2,1.7-4.3,1.9-4.8c-0.4-0.1-0.8-0.1-1.2-0.1l-59.9,1l0.5,0.5l1,0.9
		l2.1,1.9c1.3,1.2,2.4,2.1,3.4,2.9c3.9,3.1,5.6,3.7,6.3,3.8s2.7,0.1,8.2,0.1h3.2h5.3l9.1-0.1l5.4-0.1l0,0l9.8-0.2
		C722.7,580.3,722.9,580.3,723.1,580.4z"
            />
            <path className="carBackSide13" d="M665.4,568.4L665.4,568.4h0.2v-0.1L665.4,568.4z" />
            <polygon className="carBackSide13" points="665,568.7 665,568.7 665.1,568.7 	" />
            <path
                className="carBackSide13"
                d="M35.2,614.7L35.2,614.7h-0.3h-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.5s-0.2-0.3-0.3-0.5
		s-0.2-0.4-0.2-0.5c-0.1-0.2-0.1-0.5-0.2-0.7V612l0,0c0-0.1,0-0.2-0.1-0.3v-0.2l0,0c0-0.2-0.1-0.4-0.1-0.6l-0.1-0.5l-0.1-0.3
		c-0.1-0.3-0.1-0.6-0.2-1l-0.1-0.4l-0.1-0.3c0-0.2-0.1-0.4-0.2-0.7s-0.1-0.5-0.2-0.7v-0.1c0-0.2-0.1-0.5-0.2-0.7
		c-0.1-0.6-0.3-1.2-0.4-1.9c-0.1-0.3-0.1-0.7-0.2-1c-0.1-0.7-0.3-1.4-0.5-2.1l-0.1-0.6c0-0.2-0.1-0.4-0.1-0.5
		c-0.2-0.8-0.3-1.5-0.5-2.3c-0.3-1.2-0.5-2.5-0.8-3.8c-0.1-0.5-0.2-1-0.3-1.5s-0.2-0.8-0.2-1.2c-0.2-0.9-0.4-1.8-0.6-2.8
		s-0.4-1.9-0.6-2.9l-0.2-1.2c-0.1-0.6-0.2-1.2-0.4-1.8c-0.2-1-0.4-2-0.6-3c-1.2-5.9-2.4-12.2-3.5-18.3c-0.4-2-0.7-4.1-1.1-6.1
		c-0.7-4-1.4-7.9-1.9-11.5s-1-7-1.4-10c-0.2-1.6-0.3-3.1-0.5-4.6s-0.3-3-0.5-4.4c-0.3-2.9-0.6-5.7-0.9-8.5s-0.5-5.4-0.8-8.1
		c-0.1-1.3-0.2-2.6-0.3-4c-0.2-2.6-0.4-5.2-0.6-7.8c-0.1-1.9-0.2-3.9-0.4-5.9s-0.2-3.9-0.2-5.9c0-0.8-0.1-1.7-0.1-2.5
		c0-1.4-0.1-2.8-0.1-4.2c0-0.5,0-0.9,0-1.4c0-1.4,0-2.8,0-4.2c0-1.4,0-2.9,0-4.3c0-1.9,0-4,0.1-6.2s0.1-4.5,0.1-6.9
		c0-1.2,0-2.4,0.1-3.7c0.1-2.5,0.1-5,0.2-7.7c0.2-6.6,0.5-13.5,0.8-20.4c0.1-1.4,0.1-2.8,0.2-4.1c0.2-4.1,0.5-8.2,0.8-12.2
		c0.1-0.9,0.1-1.9,0.2-2.8c0-0.2,0-0.3,0-0.5l0.2-2.7c0.1-0.9,0.2-1.8,0.2-2.7l0,0v-0.3c0.3-2.6,0.5-5.2,0.8-7.7
		c0.1-1,0.2-2,0.4-2.9s0.2-1.7,0.4-2.5s0.2-1.6,0.4-2.4c0.2-1.5,0.5-3,0.8-4.4l0,0c-0.2,0.5-0.4,0.9-0.5,1.4l-0.1,0.4
		c-0.1,0.4-0.3,0.8-0.4,1.2c0,0.1-0.1,0.3-0.1,0.4c-0.2,0.5-0.3,1.1-0.5,1.6l-0.1,0.3c-0.1,0.5-0.3,1-0.4,1.4c0,0.2-0.1,0.4-0.1,0.5
		c-0.1,0.5-0.3,1-0.4,1.5l-0.1,0.3c-0.1,0.6-0.3,1.2-0.5,1.9l-0.1,0.5c-0.1,0.5-0.2,1-0.4,1.5c0,0.2-0.1,0.4-0.1,0.6
		c-0.1,0.6-0.3,1.3-0.4,2v0.1c-0.1,0.7-0.3,1.3-0.4,2c0,0.2-0.1,0.4-0.1,0.6l-0.3,1.6l-0.1,0.6l-0.4,2.1c0,0.2-0.1,0.4-0.1,0.5
		c-0.1,0.5-0.2,1.1-0.3,1.6c-0.1,0.2-0.1,0.5-0.1,0.8c-0.1,0.5-0.2,1.1-0.3,1.6c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.7-0.2,1.4-0.3,2.1
		c0,0.3-0.1,0.5-0.1,0.8l-0.2,1.4l-0.1,0.9c-0.1,0.5-0.1,1-0.2,1.5s-0.1,0.8-0.2,1.2s-0.1,0.9-0.2,1.4l-0.1,1.1l-0.1,1l-0.1,1.1
		c0,0.3-0.1,0.6-0.1,0.9l-0.4,2c0,0.2-0.1,0.5-0.1,0.8c-0.1,0.4-0.1,0.8-0.2,1.2c0,0.2-0.1,0.4-0.1,0.6c-0.2,1.4-0.4,2.8-0.5,4.2
		c0,0.1,0,0.2,0,0.4c0,0.5-0.1,0.9-0.2,1.4c-0.1,0.7-0.2,1.5-0.2,2.2v0.1c-0.1,0.7-0.1,1.4-0.2,2v0.1c-0.1,0.6-0.1,1.2-0.2,1.8v0.2
		c0,0.5-0.1,1.1-0.1,1.6v0.3c0,0.5-0.1,0.9-0.1,1.4c0,0.1,0,0.2,0,0.4c0,0.4-0.1,0.8-0.1,1.2v0.3c0,0.4-0.1,0.7-0.1,1.1v0.3
		c0,0.4-0.1,0.8-0.1,1.2c-0.7,11-0.4,38.7,0.1,50.1c0.4,9.7,2.9,37.6,4.8,54.8c0.3,3,0.6,5.6,0.9,7.8c1.9,14.9,7.3,42.7,9.1,51.3
		c0.1,0.5,0.2,1,0.4,1.5l0,0c0,0.2,0.1,0.3,0.1,0.4l0,0c0.1,0.3,0.2,0.6,0.3,0.8l0,0c0.1,0.2,0.1,0.3,0.2,0.5l0.1,0.3
		c0,0.1,0.1,0.2,0.1,0.3l0,0c0.1,0.2,0.1,0.3,0.2,0.4l0.1,0.2c0,0.1,0.1,0.2,0.2,0.3l0,0l0.2,0.4c0.1,0.2,0.2,0.3,0.3,0.4l0.2,0.3
		l0.3,0.4l0.2,0.3l0.1,0.1l0.2,0.2l0.2,0.2l0,0l0,0c0,0.1,0,0.1,0,0.2l0.2,0.2l0.2,0.1h0.1l0,0l0.2,0.1l0.2,0.1h0.1l0,0l0.2,0.1l0,0
		h0.1l0.1,0.1h0.1l0,0l0.2,0.1h0.1l0,0H29l0,0h0.2h6.1c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2-0.1l0,0c0.1,0,0.1-0.1,0.2-0.2l0,0
		c0-0.1,0.1-0.2,0.1-0.2l0,0c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1s0-0.2,0-0.3h-1L35.2,614.7z"
            />
            <polygon className="carBackSide13" points="665,568.1 665,568.6 665,568.6 	" />
        </g>
        <g id="arriere-phare-arriere-droit">
            <path
                className="carBackSide13"
                d="M780.7,289.7c0-1.1-0.1-2.3-0.2-3.7c-0.5-8.1-2.3-16.1-5.2-23.7c-6-8.2-32.2-21.8-32.2-21.8
		c-15.7-4.2-50,2.4-56,3.6l-6.7,67.7c39.3,0.8,73,1.4,77.2,1.3c10.7-0.2,15.3,0.4,19.4-2.5c0.3-0.2,0.5-0.4,0.7-0.6
		c0.5-0.5,0.8-1,1.1-1.6s0.5-1.3,0.7-2s0.3-1.4,0.4-2.1c0.3-2.1,0.3-4.2,0.1-6.3C780.1,298,781.1,299.2,780.7,289.7z"
            />
        </g>
        <g id="arriere-phare-arriere-gauche">
            <path
                className="carBackSide13"
                d="M93.9,240.5c0,0-26.1,13.5-32.2,21.8c-2.9,7.6-4.6,15.6-5.2,23.7c-0.1,1.4-0.2,2.6-0.2,3.7
		c-0.4,9.6,0.5,8.4,0.5,8.4s-1.1,9.7,3.1,12.6s8.8,2.3,19.4,2.5c4.2,0.1,37.9-0.5,77.2-1.3l-6.7-67.7
		C143.9,242.9,109.6,236.3,93.9,240.5z"
            />
        </g>
        <g id="arriere-pare-brise-arriere">
            <path
                className="carBackSide0"
                d="M696.8,218.8c0,0-1.4-4.2-3.4-9.4l-0.9-2.4c-0.3-0.9-0.7-1.9-1-2.9c-0.6-1.6-1.1-3.2-1.8-4.7
		c-0.5-1.3-1-2.6-1.4-3.8c-1.2-3.2-2.2-5.6-3-7.6l-0.4-1.1c-0.4-0.9-0.7-1.8-1.2-2.7l-1.2-3l-3.6-8.8c-0.1-0.4-0.3-0.8-0.5-1.2
		c-0.1-0.2-0.2-0.4-0.2-0.6c-0.5-1.2-1.1-2.5-1.5-3.7c-0.2-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.2-0.5-0.3-0.6l-0.3-0.8l-0.2-0.5v-0.2
		c-0.1-0.2-9.8-25.4-16-34.8l-0.2-0.3c-6-9.3-12.3-18.9-27.8-23.9l-0.2-0.1l-7,4.5c-16.5-7.1-43-9.3-71.1-11.7
		c-6.8-0.6-13.9-1.2-20.9-1.8c-17.9-1.8-41.3-3.2-69.7-4.1l-22.7-0.6h-2.2l-3.9-0.1h-23.6l-4,0.1c-1.8,0-4.1,0.1-6.8,0.1
		c-3.5,0.1-7.6,0.1-12.4,0.3c-8.2,0.2-17.4,0.5-26.6,0.9c-16.6,0.7-30.8,1.5-43.2,2.5c-4.4,0.3-8.6,0.7-12.5,1.1l-10.2,0.9l-9.8,0.6
		l-19.8,1.8c-2.2,0.2-4.4,0.4-6.4,0.6c-1.1,0.1-2.2,0.2-3.4,0.4c-1,0.1-1.9,0.2-2.9,0.3c-1.7,0.2-3.7,0.4-6,0.8l-2.9,0.4l-5.7,0.9
		c-1.1,0.2-2.3,0.4-3.3,0.6c-0.7,0.1-1.4,0.3-2.1,0.4c-6.6,1.2-13.1,3.1-19.2,5.6l-7-4.5l-0.2,0.1c-15.4,4.9-21.6,14.4-27.6,23.6
		l-0.4,0.6c-5.3,8.2-13.5,28.5-15.9,34.5l-0.1,0.2v0.2c-0.5,1.3-6.3,14.8-10,24c-0.6,1.4-1.3,3.2-2,5c-0.3,0.9-0.7,1.7-1,2.6
		c-0.3,0.7-0.5,1.4-0.8,2.2c-0.2,0.5-0.4,1.1-0.6,1.6c-0.6,1.5-1.1,3-1.7,4.5c-0.4,1.1-0.8,2.1-1.1,3.1l-0.9,2.4
		c-1.9,5.3-3.4,9.4-3.4,9.4l0.5,0.2l1.9,5.1l0,0l-0.1,0.5l6.8,0.9c2.9,0.3,6.3,0.7,9.5,1.1l5.7,0.6c1.7,0.2,3.3,0.4,4.8,0.5l1.8,0.2
		c1.2,0.1,2.3,0.2,3.3,0.3l40.7,3.8l6.4,0.5c1.2,0.1,2.5,0.2,3.6,0.3l8.2,0.5c3.2,0.1,6.8,0.2,10.7,0.2c4.7,0,9.6-0.1,14.5-0.2
		l3.6-0.1c6-0.2,12.8-0.4,19.4-0.4h133c3.9,0,8.5,0,8.6,0h136.4c2.3,0,4.7,0.1,7.6,0.1l7.5,0.2c2.1,0,4.1,0.1,6.1,0.1
		c3.8,0.1,7.3,0.2,10.9,0.2h1.1c3,0,5.5,0,7.9-0.1c0.5,0,1,0,1.6-0.1c0.5,0,0.9,0,1.4,0c10.4-0.5,29.4-2.2,46.4-3.8l12.6-1.1
		c7.9-0.6,23-2.5,30.2-3.4l1.9-0.2l2.1-5.8L696.8,218.8z"
            />
            <path
                d="M631.1,105.2c0,0,0.7-2.2-3.7-3.9c-1-0.4-2-0.6-3-0.8c-7.7-1.6-35.2-7.4-63.8-9.2c-21-1.3-69.2-3.2-101-4.2
		c-8.5-0.3-15.8-0.5-21.1-0.6v-0.1h-5.4c-0.2-1-0.7-2.6-1.3-2.9c-0.9-0.5-4.2-1.3-5.9-2.2c-1-0.4-1.8-1-2.5-1.8
		c-0.7-1.2-1.1-2.7-1-4.1c-0.1-4.4-1.8-73-1.8-73c0.1-1.1-0.8-2.1-1.9-2.1h-0.1h-0.2c-1.1,0-2,0.8-2.1,1.9c0,0.1,0,0.1,0,0.2
		c0,0-1.7,68.6-1.8,73c0,2.2-0.5,3.5-1.1,4.3c-0.7,0.7-1.5,1.3-2.4,1.6c-1.7,0.9-5,1.7-5.9,2.2c-0.6,0.3-1,1.9-1.3,2.9h-5.4v0.1
		l-7.5,0.2c-7.3,0.2-16.5,0.5-26.5,0.8c-13.4,0.4-28.4,1-42.5,1.6c-18.3,0.8-35.1,1.5-45.4,2.2c-28.6,1.8-56.1,7.6-63.8,9.2
		c-1,0.2-2,0.5-3,0.8c-4.4,1.7-3.7,3.9-3.7,3.9h-0.1l7,4.5c6.2-2.6,12.7-4.5,19.3-5.7c1.8-0.3,3.6-0.7,5.4-1s3.8-0.6,5.7-0.9
		l2.9-0.4c2-0.3,4-0.5,6-0.8s4.1-0.5,6.2-0.7c3.1-0.3,6.4-0.6,9.6-0.9s6.5-0.6,9.9-0.9l6.7-0.6l10.1-0.9l6.8-0.6l3.4-0.3
		c4-0.4,8.2-0.8,12.5-1.1c14.2-1.1,29.4-1.9,43.2-2.5c9.8-0.4,18.9-0.7,26.6-0.9c8.4-0.2,15.2-0.3,19.2-0.4l4-0.1h16.5h1.6h0.5h1.9
		h3.1l3.9,0.1h2.2l7.5,0.2l8.7,0.2h0.1l6.4,0.2c20.9,0.7,47.5,1.9,69.7,4.1c35.2,3.4,71.6,4.8,92,13.6l7-4.5L631.1,105.2z"
            />
            <path
                className="carBackSide13"
                d="M693,209.5c-0.3-0.8-0.6-1.6-0.9-2.4c-0.9-2.4-1.8-5.1-2.8-7.6c-0.5-1.3-1-2.6-1.4-3.8
		c-1.1-2.9-2.1-5.5-3-7.6c-0.5-1.2-1-2.5-1.6-3.8l-1.2-3l-0.4-1l-0.8-2l-0.8-2l-0.8-1.9l-0.8-1.9c-0.2-0.6-0.5-1.2-0.7-1.7
		c-0.6-1.4-1.1-2.6-1.5-3.7c-0.3-0.6-0.5-1.2-0.7-1.6l-0.3-0.8c-0.2-0.4-0.3-0.6-0.3-0.6V164l-0.8,0.1h-4.8c0,0-19.1-29-33-45.8
		c-0.3-0.4-0.7-0.8-1-1.2c-0.7-0.8-1.5-1.5-2.4-2.2s-1.8-1.4-2.7-2c-1-0.6-2-1.2-3.1-1.8c-0.5-0.3-1.1-0.6-1.7-0.8l-1.3-0.6
		c-20.4-8.9-56.8-10.2-92-13.6c-22.2-2.2-48.8-3.4-69.7-4.1l-6.4-0.2H456l-8.7-0.2l-7.5-0.2h-2.2l-3.9-0.1h-3.1h-2.4h-1.6h-16.5
		l-4,0.1c-4,0.1-10.7,0.2-19.2,0.4c-7.7,0.2-16.8,0.5-26.6,0.9c-13.9,0.6-29,1.4-43.2,2.5c-4.3,0.3-8.5,0.7-12.5,1.1l-3.4,0.3
		l-6.8,0.6l-9.8,0.8l-6.7,0.6c-3.3,0.3-6.6,0.6-9.9,0.9c-3.2,0.3-6.5,0.6-9.6,0.9c-2.1,0.2-4.2,0.5-6.2,0.7s-4.1,0.5-6,0.8l-2.9,0.4
		c-1.9,0.3-3.8,0.6-5.7,0.9s-3.7,0.7-5.4,1c-6.6,1.2-13.1,3.1-19.3,5.7l-1.3,0.6c-4.1,1.8-7.8,4.5-10.8,7.9
		c-1.7,2.1-3.6,4.4-5.4,6.8c-0.9,1.2-1.9,2.4-2.8,3.7c-4.2,5.6-8.5,11.6-12.2,17c-0.8,1.2-1.7,2.4-2.4,3.5l-1.1,1.6l-2,3l-0.9,1.4
		l-0.8,1.2l-0.2,0.3l-0.6,0.8l-0.2,0.3l-0.7,1l-0.6,0.9l-0.2,0.3l-0.5,0.7l-0.1,0.1c-0.2,0.3-0.4,0.5-0.5,0.8s-0.3,0.4-0.4,0.6
		l-0.1,0.2l-0.6,1l-0.2,0.3l-0.2,0.3v0.1H163l-0.8-0.1v0.1c0,0-6.1,14.4-10,24.1c-0.9,2.1-1.9,4.8-3,7.6c-0.5,1.2-0.9,2.5-1.4,3.8
		c-1,2.6-1.9,5.2-2.8,7.6c-0.3,0.8-0.6,1.6-0.9,2.4c-1.9,5.4-3.4,9.4-3.4,9.4s2.8,0.4,6.9,0.9l2.4,0.3c2.9,0.4,6.2,0.8,9.5,1.1
		l2.9,0.3l2.8,0.3c2.3,0.3,4.5,0.5,6.5,0.7c1.2,0.1,2.3,0.2,3.3,0.3l2.2,0.2l5.8,0.5l3.5,0.3l3.8,0.4l4,0.4l6.3,0.6l4.3,0.4l4.4,0.4
		l6.4,0.5l2.4,0.2l1.7,0.1l2,0.2l3.8,0.3l1.8,0.1l3.3,0.2c1.1,0.1,2,0.1,3,0.2c7.6,0.4,16,0.2,24.9,0c8.3-0.2,16.8-0.5,25.2-0.4h1
		c5.4,0,15.7,0,27.7,0h62h11.1h65.9c0,0,19.4,0,41.5,0c24,0,51.3,0,60.3,0c2.2,0,4.4,0,6.5,0c3.3,0,6.6,0.1,9.8,0.2
		c7.6,0.2,15.1,0.4,22.2,0.5c3,0,6,0,8.8-0.1c0.9,0,1.9-0.1,2.8-0.1c14.6-0.8,48.3-4.1,58.8-4.9s34.2-4,34.2-4
		S694.9,214.9,693,209.5z"
            />
            <path
                d="M167.7,164.1L167.7,164.1l0.2-0.3l0.2-0.3l0.6-1l0.1-0.2c0.1-0.2,0.3-0.4,0.4-0.6l0.5-0.8l0.1-0.1l0.5-0.7l0.2-0.3l0.6-0.9
		l0.7-1l0.2-0.3l0.6-0.8l0.2-0.3l0.8-1.2l0.9-1.4l2-3l1.1-1.6c0.8-1.1,1.6-2.3,2.4-3.5c3.8-5.4,8-11.4,12.2-17
		c0.9-1.3,1.9-2.5,2.8-3.7c1.8-2.4,3.7-4.7,5.4-6.8c3-3.4,6.7-6.1,10.8-7.9l1.3-0.6l-7-4.5c-15.5,4.9-21.5,14.5-27.7,24
		c-5.9,9.1-15.4,33.2-16,34.7l0.8,0.1L167.7,164.1z"
            />
            <path
                d="M627.2,111.2c1.1,0.6,2.1,1.2,3.1,1.8c0.9,0.6,1.9,1.3,2.7,2s1.6,1.4,2.3,2.1c0.3,0.4,0.7,0.8,1,1.2
		c13.8,16.8,33,45.8,33,45.8h4.7l0.8-0.1c-0.6-1.6-10-25.6-16-34.7c-6.1-9.4-12.2-19-27.7-24l-7,4.5l1.3,0.6
		C626.1,110.7,626.6,110.9,627.2,111.2z"
            />
        </g>
    </svg>
);

export default CarBackSide;
