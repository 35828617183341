import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FormattedHTMLMessage} from "react-intl";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import SidebarWrapper from "../../../app/partials/layout/SidebarWrapper";
import {logoutUser} from "../../../app/store/login/login.thunk";
import {routeTo} from '../../../app/helpers/RoutesHelper';
import ROUTES from '../../../app/router/Routes';
import {Intl} from "../../i18n/I18nProvider";

const FooterMobile = () => {
  const {menuConfig} = useSelector((store) => store.builder);
  const [visibleBurgerMenu, setVisibleBurgerMenu] = useState(false);
  const dispatch = useDispatch();

  const checkPathnameStartWith = (page) => {
    return window.location.pathname.startsWith(page)
  };

  const isMenuItemIsActive = item => {
      if (item.submenu) {
          return isMenuRootItemIsActive(item);
      }
      if (!item.page) {
          return false;
      }
      return checkPathnameStartWith(item.page);
  };

  const isMenuRootItemIsActive = item => {
      for (const subItem of item.submenu) {
          if (isMenuItemIsActive(subItem)) {
              return true;
          }
      }
      return false;
  };

  return <div className="footer-mobile">
    <div className="d-flex">
        {
          menuConfig.aside.items.map((item, index) => {
            if (!item?.mobile) return false;

            return <Link to={item.mobile?.page || item.page} className={`footer-mobile__item${isMenuItemIsActive(item) ? ' kt-menu__item--active' : ''}`} key={`menuList${index}`}>
              {item.icon ? (
                  <i className={`kt-menu__link-icon ${item.icon}`} />
              ) : item.svg ? (
                  <span className="kt-menu__link-icon">
                    <item.svg />
                </span>
              ) : (
                  <></>
              )}

              <span className="kt-menu__link-text" style={{ whiteSpace: "nowrap" }}>
              <FormattedHTMLMessage id={item.mobile.translate}/>

            </span>
            </Link>;
          })
        }
    </div>
    <div className="align-self-center px-5" onClick={() => setVisibleBurgerMenu(!visibleBurgerMenu)}>
      <i className={`la la-bars la-3x${visibleBurgerMenu ? ' text-primary' : ''}`} />
    </div>
    <SidebarWrapper visible={visibleBurgerMenu}
                    setVisible={setVisibleBurgerMenu}
                    position="bottom"
                    className="sidebar__menu-mobile"
                    isMenu={true}
                    children={
                        <ul style={{listStyle: "none", padding: "0"}}>
                          <li className='sidebar__menu-mobile--menu-item'>
                            <Button variant="outline-link">
                              <Link to={routeTo(ROUTES.AGENDA.PATH)}>
                                <span className={`font-weight-semibold${checkPathnameStartWith(ROUTES.AGENDA.PATH) ? ' text-primary' : ''}`} style={{fontSize: "14px"}}>
                                  {Intl.formatMessage({id: 'MENU.AGENDA'})}
                                </span>
                              </Link>
                            </Button>
                          </li>
                          <li className='sidebar__menu-mobile--menu-item'>
                            <Button variant="outline-link"
                                    className="font-weight-semibold"
                                    style={{fontSize: '14px'}}
                                    onClick={() => dispatch(logoutUser())}>
                                {Intl.formatMessage({id: 'TRANSLATOR.LOGOUT'})}
                            </Button>
                          </li>
                        </ul>
                    } />
  </div>;
};

export default FooterMobile;
