import React from 'react';

type ExtendIconProps = {
    width?: string;
    height?: string;
    color?: string;
};

const ExtendIcon = ({ width, height, color }: ExtendIconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 36.163 36.163">
        <path
            d="M3307.358-428.348h11.126v11.127H3315.7v-7.3a16,16,0,0,0-5.086,5.52,14.808,14.808,0,0,0-1.869,7.345,14.738,14.738,0,0,0,4.477,10.823,14.737,14.737,0,0,0,10.822,4.477,14.743,14.743,0,0,0,10.823-4.477,14.745,14.745,0,0,0,4.477-10.823v-1.391h2.782v1.391a17.416,17.416,0,0,1-5.3,12.779,17.418,17.418,0,0,1-12.779,5.3,17.413,17.413,0,0,1-12.778-5.3,17.414,17.414,0,0,1-5.3-12.779,17.309,17.309,0,0,1,6.433-13.909h-5.042Zm16.69-1.391h1.391v2.782h-1.391Zm4.347.609,1.217.261v.087h.086l1.131.435H3331l.086.087-1.13,2.521h-.174l-.956-.348-.088-.087-1.043-.261Zm5.216,2.173.7.522h.087l.956.7v.087h.087l.435.348-1.913,2.086-.261-.261h-.087l-.782-.7h-.088l-.695-.434Zm4.173,3.651.348.348.087.087.782.956v.087l.521.783-2.433,1.478-.348-.608-.088-.174-.607-.783v-.087l-.348-.348Zm2.956,4.694.086.174h.087v.087l.348,1.13.087.174.261,1.13-2.607.782-.348-1.13v-.087l-.348-.956v-.087Z"
            transform="translate(-3305.967 429.739)"
            fill={color}
        />
    </svg>
);

ExtendIcon.defaultProps = {
    width: '20',
    height: '20',
    color: '#fff',
};

export default ExtendIcon;
