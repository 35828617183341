import { useDispatch } from 'react-redux';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { setToken } from '@app/helpers/User';

import toast from '@app/partials/content/Toast';

function Identifier() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const dispatch = useDispatch();

    if (params?.token && params?.refresh && params?.cache) {
        setToken(dispatch, {
            token: params.token,
            refresh_token: params.refresh,
            'x-autouser-cache': params.cache,
        });
        window.location.href = window.location.origin;
    } else {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'AUTOUSER.LOGIN.ERROR' }),
        });
    }
}

export default Identifier;
