import { isNil, omitBy } from 'lodash';
import { MultiSelect } from 'primereact/multiselect';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setOrderRequestComplement } from '@app/crud/smartRepair/smart-repair.crud';

import { fetchQuotation } from '@app/store/smart-repair/quotations/smart-repair.thunk';

import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

import { Intl } from '../../../../../_metronic/i18n/I18nProvider';

export default function AskAdditionalInfo({ quotation, showModal, setShowModal, acceptedQuotations, completeSend }) {
    const { register, handleSubmit, loading } = useForm();
    const [orderRepairSelected, setOrderRepairSelected] = useState();
    const [inputVal, setInputVal] = useState('');
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const params = useParams();
    const dispatch = useDispatch();

    const handleClose = () => {
        setShowModal(false);
    };

    const ordersRepairsOptions = quotation.orderRepairs.map((v) => ({
        id: v.id,
        label: v.zoneBody.name,
        orderId: v.order,
    }));

    const handleAdditionalInfo = async (data) => {
        // Send order complement with expertComment
        const acceptedQuotationsValues = Object.values(acceptedQuotations);
        const orderId = acceptedQuotationsValues?.[0][0].orderId;
        const myArrayToApi = [];
        if (!orderId) return;
        setLoadingUpdate(true);
        acceptedQuotationsValues.forEach((repairs) => {
            const repairIds = repairs.map((repair) => repair.id);
            const repairPrices = repairs.map((repair) => repair.slug);
            const compatible = repairs.every((repair) => repair.compatible);
            const { expertComment } = repairs[0];
            const complementComment = data[repairs[0].id] !== '' ? data[repairs[0].id] : null;
            myArrayToApi.push(
                omitBy(
                    {
                        id: repairIds[0],
                        price: repairPrices[0] !== null ? repairPrices : null,
                        compatible,
                        expertComment,
                        complementComment,
                    },
                    isNil,
                ),
            );
        });
        await setOrderRequestComplement(orderId, { orderRepairs: myArrayToApi })
            .then((response) => {
                setLoadingUpdate(false);
                if (response?.result) {
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.ADDITIONAL.INFO.VALID.MESSAGE.API' }),
                    });
                    dispatch(fetchQuotation(params.id));
                    completeSend(true);
                    setShowModal(false);
                } else {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({
                            id: 'SMART.REPAIR.QUOTATION.ADDITIONAL.INFO.UNVALID.MESSAGE.API',
                        }),
                    });
                }
            })
            .catch(() => {
                setLoadingUpdate(false);
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.ADDITIONAL.INFO.UNVALID.MESSAGE.API' }),
                });
            });
    };

    const handleComplementComment = async (data) => {
        // Send order complement without expertComment
        const QuotationsValues = quotation.orderRepairs;
        const orderId = QuotationsValues?.[0].order;
        const myArrayToApi = [];
        if (!orderId) return;
        setLoadingUpdate(true);
        QuotationsValues.forEach((value) => {
            myArrayToApi.push(
                omitBy(
                    {
                        id: value.id,
                        complementComment: data[value.id] !== '' ? data[value.id] : null,
                    },
                    isNil,
                ),
            );
        });
        await setOrderRequestComplement(orderId, { orderRepairs: myArrayToApi })
            .then((response) => {
                setLoadingUpdate(false);
                if (response?.result) {
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.ADDITIONAL.INFO.VALID.MESSAGE.API' }),
                    });
                    dispatch(fetchQuotation(params.id));
                    completeSend(true);
                    setShowModal(false);
                } else {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({
                            id: 'SMART.REPAIR.QUOTATION.ADDITIONAL.INFO.UNVALID.MESSAGE.API',
                        }),
                    });
                }
            })
            .catch(() => {
                setLoadingUpdate(false);
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.ADDITIONAL.INFO.UNVALID.MESSAGE.API' }),
                });
            });
    };
    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-envelope" />}
            title={Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.ASK' })}
            body={
                <div className="px-5">
                    {loadingUpdate && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.DEFAULTS.ITEMS' })}</Form.Label>
                        <MultiSelect
                            display="chip"
                            showSelectAll={false}
                            filter={false}
                            placeholder={Intl.formatMessage({ id: 'SMART.REPAIR.SELECT' })}
                            value={orderRepairSelected}
                            options={ordersRepairsOptions}
                            onChange={(e) => {
                                setOrderRepairSelected(e.value);
                            }}
                        />
                    </Form.Group>
                    {orderRepairSelected &&
                        Object.values(orderRepairSelected).length > 0 &&
                        Object.values(orderRepairSelected).map((item) => (
                            <Form.Group key={item.id}>
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.COMMENTS' })} <b>{item.label}</b>{' '}
                                    :
                                </Form.Label>
                                <Form.Control
                                    ref={register}
                                    as="textarea"
                                    type="text"
                                    rows={2}
                                    name={item.id}
                                    onChange={(e) => setInputVal(e.target.value)}
                                    placeholder={Intl.formatMessage({
                                        id: 'SMART.REPAIR.QUOTATION.TEXTAREA.PLACEHOLDER',
                                    })}
                                />
                            </Form.Group>
                        ))}
                </div>
            }
            footer={
                <div className="my-5 mr-5 d-flex justify-content-end">
                    <Button variant="btn btn-outline-primary mr-5" onClick={handleClose}>
                        {orderRepairSelected && Object.values(orderRepairSelected).length > 0
                            ? Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })
                            : Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                    <Button
                        variant="primary"
                        disabled={!inputVal}
                        onClick={
                            Object.values(acceptedQuotations).length > 0
                                ? handleSubmit(handleAdditionalInfo)
                                : handleSubmit(handleComplementComment)
                        }
                    >
                        {Intl.formatMessage({ id: 'TRANSLATOR.SEND' })}
                    </Button>
                </div>
            }
        />
    );
}
