import * as dayjs from 'dayjs';

export function apiToCalendarObject(appointment, teammate, user) {
    let color = '#494B73';
    if (appointment?.isOutlookSpecific) {
        color = '#0072C6';
    }
    const isCallback = appointment?.scheduleType?.slug === 'appointment_callback' || false;
    if (isCallback) {
        color = '#F84C00';
    }
    return {
        id: appointment.id,
        title: appointment.subject,
        start: appointment.dateStart,
        end: appointment.dateEnd,
        color,
        userId: appointment.userId,
        contact: appointment?.contact || null,
        attendees: appointment?.attendees || null,
        organizer: appointment?.organizer || null,
        outlookId: appointment?.outlookId || null,
        gmailId: appointment.gmailId,
        comment: appointment.comment,
        created: appointment.created,
        editable: !appointment?.isOutlookSpecific && teammate?.id === user?.id,
        isPrivate: appointment?.isPrivate || false,
        allDay: appointment.isAllDay,
        isOutlookSpecific: appointment?.isOutlookSpecific || false,
        isCallback,
    };
}

export function calendarToApiObject(event, user) {
    const appointment = {
        scheduleAppointmentId: event.id,
        subject: event.title,
        comment: event.extendedProps.comment,
        dateStart: dayjs(event.start).format('YYYY-MM-DD HH:mm'),
        dateEnd: dayjs(event.end).format('YYYY-MM-DD HH:mm'),
        user: {
            id: user.id,
            firstname: user.first_name,
            lastname: user.last_name,
            email: user.email,
        },
    };
    if (event.extendedProps?.outlookId) {
        appointment.outlookId = event.extendedProps.outlookId;
    }

    return appointment;
}

export function formToApiObject(event, formData, user) {
    const appointment = {
        scheduleAppointmentId: event.id,
        subject: formData.subject,
        comment: formData.comment,
        dateStart: `${formData.date} ${formData.timeStart}`,
        dateEnd: `${formData.date} ${formData.timeEnd}`,
        user: {
            id: user.id,
            firstname: user.first_name,
            lastname: user.last_name,
            email: user.email,
        },
    };
    if (event.extendedProps?.outlookId) {
        appointment.outlookId = event.extendedProps.outlookId;
    }

    return appointment;
}
