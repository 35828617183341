import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import { useAppSelector } from '@app/hooks';

import { ActionsBlockVehicleInfo } from '../../ActionsBlock.type';

const scrollToVehicle = (uuid: string) => {
    const element = document.querySelector(`[data-vehicle-uuid="${uuid}"]`);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
};

export default function ScrollToVehicleEventsButton({ vehicle }: { vehicle: ActionsBlockVehicleInfo }) {
    const { activity } = useAppSelector((state) => state.customer);

    return (
        <button
            type="button"
            disabled={activity.loading}
            className="btn btn-link text-primary p-0 text-underlined"
            style={{ fontSize: '12px', whiteSpace: 'nowrap' }}
            onClick={() => scrollToVehicle(vehicle.VEHICLE_UUID)}
        >
            <FormattedHTMLMessage id="ACTIONS_BLOCK.VEHICLE.VIEW_LINKED_EVENTS" values={{ count: vehicle.NB_EVENT }} />
        </button>
    );
}
