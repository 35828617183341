import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import ErrorForm from '@app/partials/layout/ErrorForm';

const LeadLooseReprise = ({ params, leadReasons, reason, lead }) => {
    const { register, watch, errors } = useFormContext();
    const Intl = useIntl();
    const { subReason } = watch(['subReason']);

    const reasons =
        leadReasons?.[
            ['achat_cash', 'achat_cash_abandon', 'local_achat_cash'].includes(lead?.leadType?.slug)
                ? 'close_lead_achat_cash_loose_reason'
                : 'close_lead_partexchange_loose_reason'
        ]?.leadReasons;
    const subReasonItem = reasons?.find?.((reason_item) => reason_item?.slug === reason);

    return (
        <>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="CUSTOMERS.MODAL.LEAD_LOOSE.PART_EXCHANGE.REASON" />
                </Form.Label>
                <Form.Control
                    as="select"
                    name="reason"
                    defaultValue=""
                    disabled={!!params?.event}
                    className={`${errors.reason ? 'is-invalid' : ''}`}
                    ref={register({
                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    })}
                >
                    <option value="">
                        {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_LOOSE.REACH.PLACEHOLDER' })}
                    </option>
                    {reasons &&
                        reasons.map((leadReason) => (
                            <option key={leadReason.id} value={leadReason.slug}>
                                {leadReason.name}
                            </option>
                        ))}
                </Form.Control>
                <ErrorForm errors={errors} name="reason" />
            </Form.Group>
            {subReasonItem?.leadReasons?.length > 0 && (
                <Form.Group>
                    <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_LOOSE.REASON' })}</Form.Label>
                    <Form.Control
                        as="select"
                        name="subReason"
                        defaultValue=""
                        disabled={!!params?.event}
                        className={`${errors.subReason ? 'is-invalid' : ''}`}
                        ref={register({
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                        })}
                    >
                        <option value="">
                            {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_LOOSE.REASON.PLACEHOLDER' })}
                        </option>
                        {subReasonItem?.leadReasons?.map?.((subreason) => (
                            <option key={subreason.id} value={subreason.slug}>
                                {subreason.name}
                            </option>
                        ))}
                    </Form.Control>
                    <ErrorForm errors={errors} name="subReason" />
                </Form.Group>
            )}
            {['autosphere_partexchange_estimated_price_too_low'].includes(subReason) && (
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="CUSTOMERS.MODAL.LEAD_LOOSE.PART_EXCHANGE.PRICE" />
                    </Form.Label>
                    <Form.Control
                        name="partExchangeAmount"
                        disabled={!!params?.event}
                        className={`${errors.reason ? 'is-invalid' : ''}`}
                        ref={register({
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                        })}
                    />
                    <ErrorForm errors={errors} name="partExchangeAmount" />
                </Form.Group>
            )}
            <Form.Group>
                <Form.Label>
                    {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_LOOSE.PART_EXCHANGE.COMMENT' })}
                </Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    name="result"
                    disabled={!!params?.event}
                    className={`${errors.result ? 'is-invalid' : ''}`}
                    ref={register({
                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    })}
                />
                <ErrorForm errors={errors} name="result" />
            </Form.Group>
        </>
    );
};

export default LeadLooseReprise;
