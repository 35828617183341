import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ApvFormProps, Vehicle, VehicleVersion } from '@app/crud/apv/vehicle.type';

import ErrorForm from '@app/partials/layout/ErrorForm';

interface FormVersionDropdownAutovistaProps extends ApvFormProps {
    vehicle: Vehicle;
}

const FormVersionDropdownAutovista = ({
    label,
    name,
    required,
    isInvalid,
    placeholder = '',
    className = '',
    validate,
    disabled = false,
    vehicle,
}: FormVersionDropdownAutovistaProps) => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { control, errors } = methods;

    const versions = vehicle?.version || [];

    // Log the versions to check their structure
    const selectVersionsOptions = (value: VehicleVersion[]) => value.map((version) => ({ ...version }));

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Controller
                control={control}
                name={name}
                rules={{ required: required && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }), validate }}
                render={({ value, onChange }) => (
                    <Dropdown
                        value={value}
                        options={selectVersionsOptions(versions)}
                        itemTemplate={(item) =>
                            item &&
                            `${item.Version || ''} (${item.ProductionPeriod.ProductionStartDate.Month}/${
                                item.ProductionPeriod.ProductionStartDate.Year
                            } - ${item.ProductionPeriod.ProductionEndDate.Month}/${
                                item.ProductionPeriod.ProductionEndDate.Year
                            })`
                        }
                        valueTemplate={(item) =>
                            item
                                ? `${item.Version || ''} (${item.ProductionPeriod.ProductionStartDate.Month}/${
                                      item.ProductionPeriod.ProductionStartDate.Year
                                  } - ${item.ProductionPeriod.ProductionEndDate.Month}/${
                                      item.ProductionPeriod.ProductionEndDate.Year
                                  })`
                                : Intl.formatMessage({ id: 'CUSTOMERS.MODAL.APVINSTORE.MODEL.PLACEHOLDER' })
                        }
                        disabled={disabled}
                        onChange={(e) => {
                            onChange(e.value);
                        }}
                        className={classNames(`form-control ${className}`, {
                            'is-invalid': isInvalid,
                        })}
                        optionLabel="Version"
                        emptyMessage={Intl.formatMessage({ id: 'TRANSLATOR.NO_RESULT' })}
                        placeholder={placeholder}
                    />
                )}
            />
            <ErrorForm errors={errors} name={name} />
        </Form.Group>
    );
};

export default FormVersionDropdownAutovista;
