import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom"
import DefaultSubHeader from "../../../_metronic/layout/sub-header/DefaultSubHeader";
import {FormProvider, useForm} from "react-hook-form";
import RouteSwitch from "../content/RouteSwitch";
import {ErrorBoundary} from "react-error-boundary";
import ErrorFallback from "./ErrorFallback";
import {withRouter} from "react-router-dom";
import {HeadBandHelper} from "../../helpers/HeadBandHelper";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "../../store/route/route.store";
import DimensionsHandler from "../content/DimensionsHandler";

function PageLayout(props) {
    const dispatch = useDispatch();
    const prevRoute = useSelector(state => state.route.prevRoute);
    const prevMatchPath = prevRoute?.match?.path;
    const prevLocationPath = prevRoute?.location?.pathname;
    const [subHeaderElement, setSubHeaderElement] = useState(undefined);
    const methods = useForm();

    useEffect(() => {
        setSubHeaderElement(document.getElementById('kt-content-subheader'));
    }, []);

    useEffect(() => {
        HeadBandHelper.updateSubHeader();
    });

    useEffect(() => {
        dispatch(actions.setRoute({
            match: props.match,
            location: props.location
        }));
    }, []);

    const SubHeader = props.subheader || DefaultSubHeader;
    const Main = props.main;

    return (
        <ErrorBoundary fallbackRender={({resetErrorBoundary}) => (
            <ErrorFallback prevLocationPath={prevLocationPath} resetErrorBoundary={resetErrorBoundary} />
        )}>
            <RouteSwitch prevMatchPath={prevMatchPath} />
            <FormProvider {...methods}>
                {subHeaderElement &&
                    /* Handle resize dimensions */
                    <DimensionsHandler>
                        { ReactDOM.createPortal(<SubHeader/>, subHeaderElement) }
                        <Main />
                    </DimensionsHandler>
                }
            </FormProvider>
        </ErrorBoundary>
    )
}

export default withRouter(PageLayout);
