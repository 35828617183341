import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import Label from '@app/partials/content/Label';

interface ConfirmationAffectionButtonProps {
    orderAppointmentId: string;
    isConfirmed: boolean;
    disabled: boolean;
    displayLabel?: boolean;
    btnVariant?: string;
    confirm: (id: string) => void;
}

const ConfirmButton = ({
    orderAppointmentId,
    isConfirmed,
    disabled,
    confirm,
    btnVariant,
    displayLabel,
}: ConfirmationAffectionButtonProps) => {
    const styleLabel = displayLabel ? 'label--valid--long' : 'label--valid--short btn-icon ml-2';

    return isConfirmed === true ? (
        <Label className={styleLabel} variant="success">
            <i className="las la-calendar-check" />
            {displayLabel && <FormattedMessage id="MANUFACTURER.ORDER.COMPANION_BUTTON.APPOINTMENT.CONFIRMED" />}
        </Label>
    ) : (
        <Button
            className={`${!displayLabel ? 'btn-icon' : ''} ml-2`}
            variant={btnVariant}
            disabled={disabled}
            onClick={() => confirm(orderAppointmentId)}
        >
            <i className="las la-calendar" />
            {displayLabel && <FormattedMessage id="MANUFACTURER.ORDER.COMPANION_BUTTON.APPOINTMENT.TO_CONFIRM" />}
        </Button>
    );
};

ConfirmButton.defaultProps = {
    displayLabel: false,
    btnVariant: 'outline-primary',
};

export default ConfirmButton;
