import { getCheckedDealerships, getUserPerimeter } from '@app/pages/autouser/users/Helpers/Dealership';

export const getNewPerimeter = (filters, perimeter) => {
    const group_id = filters?.group_internal_id?.id || perimeter?.[0]?.id;
    const newPerimeter = perimeter?.filter?.((group) => group?.id === group_id);

    return getUserPerimeter(newPerimeter);
};

export const getNewDealerIds = (filters, perimeter) => {
    const newPerimeter = getNewPerimeter(filters, perimeter);

    return getCheckedDealerships(newPerimeter)
        .map((dealership) => dealership?.id)
        .join(',');
};
