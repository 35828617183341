import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import { Intl } from '../../../../../_metronic/i18n/I18nProvider';
/**
 *
 * The component show prices of sum of Package(s) Selected on Packages Modal on certain conditions. The component has two main functions.
 * The first function, handlePriceClick, sets the selected order repair item and displays the package modal when the user clicks on the form group.
 *
 * The second function, handlePrice, sets the value of the input field based on certain conditions.
 * If the modifiedBtnAdding property of the orderRepairItem object is not equal to a specific string,
 * or if the quotationStored object's orderStatus property's groupSlug property is "to_be_appraised",
 * then the input field value will be set to the sum of packages or 0.
 * Otherwise, the input field value will be set to the priceTtc property of the orderRepairItem object when orders status is different to "to_be_appraised".
 *
 * orderRepairItem: an object that contains information about an orderRepair item.
 * quotationStored: an object that contains information about a quotation that is being stored.
 * setSelectedOrderRepairItem: a function that sets the selected orderRepair item.
 * displayPackageModal: a function that displays a package modal.
 * sumOfPackages: a variable that contains the sum of packages.
 */

function QuotationInputPrice({
    orderRepairItem,
    quotationStored,
    setSelectedOrderRepairItem,
    displayPackageModal,
    sumOfPackages,
}) {
    const handlePriceClick = (orderRepairItemProps) => {
        setSelectedOrderRepairItem(orderRepairItemProps);
        displayPackageModal(true);
    };

    const calculateValue = (quotationStoredProps, sumOfPackagesProps, orderRepairItemProps) => {
        if (quotationStoredProps?.orderStatus?.groupSlug === 'to_be_appraised') {
            return sumOfPackagesProps || 0;
        } else if (orderRepairItemProps.totalAmount === null) {
            return '0';
        }
        return orderRepairItemProps.totalAmount;
    };

    const handlePrice =
        orderRepairItem.modifiedBtnAdding || orderRepairItem?.modifiedBtnIncompatible
            ? (orderRepairItem.modifiedBtnAdding || orderRepairItem?.modifiedBtnIncompatible) !==
              Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.MODIFIED' })
            : quotationStored?.orderStatus?.groupSlug === 'to_be_appraised';

    return (
        <Form.Group {...(handlePrice && { onClick: () => handlePriceClick(orderRepairItem) })}>
            <Form.Label>{Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.LABEL.PRICE' })} :</Form.Label>
            <InputGroup className="mb-2">
                <Form.Control
                    className={`${
                        orderRepairItem?.modifiedBtnAdding !==
                        Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.MODIFIED' })
                            ? 'smart-repair-card__input'
                            : ''
                    } `}
                    type="text"
                    name="price"
                    value={calculateValue(quotationStored, sumOfPackages, orderRepairItem)}
                    disabled={
                        quotationStored?.orderStatus?.groupSlug !== 'to_be_appraised' ||
                        (orderRepairItem?.modifiedBtnAdding || orderRepairItem?.modifiedBtnIncompatible) ===
                            Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.MODIFIED' })
                    }
                />
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <i className="las la-euro-sign" />
                    </InputGroup.Text>
                </InputGroup.Prepend>
            </InputGroup>
        </Form.Group>
    );
}

export default QuotationInputPrice;
