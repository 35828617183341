import React, { useRef, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import usePermissions from '@app/hooks/usePermissions';

import { exportStockAndPricing, importStockAndPricing } from '@app/crud/stock/stock.crud';

import { useModal } from '@app/hooks';
import ActionsHandBand from '@app/pages/stock/headbands/ActionsHandBand';
import ImportPricingModal from '@app/pages/stock/pricing/modals/ImportPricingModal';
import store from '@app/store/store';

import toast from '@app/partials/content/Toast';

const PricingHandBand = ({ count, type, isHistory }: { count: number; type: string; isHistory: boolean }) => {
    const [showImportPricingModal, toggleImportPricingModal] = useModal(false, 'import_pricing_modal');
    const [isLoadingExport, setIsLoadingExport] = useState(false);
    const [isLoadingImport, setIsLoadingImport] = useState(false);
    const [file, setFile] = useState<File>(null);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const { hasPermission } = usePermissions();
    const Intl = useIntl();
    const { context } = store.getState().auth.userData;
    const title = isHistory ? (
        <FormattedHTMLMessage id="STOCK.PRICING.HEADBAND.HISTORY.TITLE" values={{ number: count }} />
    ) : (
        <>
            <FormattedHTMLMessage id="STOCK.PRICING.HEADBAND.PRICING_TO_DO.TITLE" values={{ number: count }} />
            {type === 'list' && <FormattedHTMLMessage id="STOCK.PRICING.HEADBAND.PRICING_TO_DO.TITLE.TREAT" />}
            {type === 'check' && <FormattedHTMLMessage id="STOCK.PRICING.HEADBAND.PRICING_TO_DO.TITLE.CHECK" />}
        </>
    );

    const importPricing = () => {
        setIsLoadingImport(true);
        importStockAndPricing(file)
            .then(() => {
                setIsLoadingImport(false);
                toggleImportPricingModal(false);
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'STOCK.PRICING.IMPORT_SUCCESS' }),
                });
            })
            .catch((error) => {
                setIsLoadingImport(false);
                toast({
                    variant: 'danger',
                    message: error.message,
                });
            });
    };

    const exportPricing = () => {
        setIsLoadingExport(true);
        exportStockAndPricing()
            .then((response) => {
                if (!response) {
                    return null;
                }
                setIsLoadingExport(false);

                const url = window.URL.createObjectURL(new Blob([response.data]));

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'stock-pricing.csv');

                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(() => {
                setIsLoadingExport(false);
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'STOCK.PRICING.EXPORT_FAIL' }),
                });
            });
    };

    const changeFile = () => {
        toggleImportPricingModal(false);
        inputFileRef.current.value = '';
        setFile(null);
        inputFileRef.current.click();
    };

    const handleClickImport = () => {
        if (inputFileRef.current) {
            inputFileRef.current.value = '';
            setFile(null);
            inputFileRef.current.click();
        }
    };

    return (
        <div className="customer-handband my-lg-0 my-2">
            <Row className="justify-content-between">
                <Col lg={10}>
                    <Row className="font-size-h4 text-white headband__content">
                        <Image
                            className="customer-handband__pic ml-5"
                            src="/media/pages/customers/fiche-client-aucune-activite-en-cours.svg"
                        />
                        <div className="ml-5 border-bottom">{title}</div>
                    </Row>
                    <Row className="font-size-h4 text-white headband__content headband__content--scroll">
                        <Image
                            className="customer-handband__pic--small ml-5"
                            src="/media/pages/customers/fiche-client-aucune-activite-en-cours.svg"
                        />
                        <div className="ml-lg-5 ml-0">{title}</div>
                    </Row>
                </Col>
                {context.toLowerCase() === 'bernard' && hasPermission('STOCK_IMPORT_PRICING') && (
                    <Col lg={2}>
                        <Row className="headband__content">
                            <ActionsHandBand
                                isLoadingExport={isLoadingExport}
                                exportPricing={exportPricing}
                                handleClickImport={handleClickImport}
                            />
                        </Row>
                        <Row className="font-size-h4 text-white headband__content headband__content--scroll">
                            <ActionsHandBand
                                isLoadingExport={isLoadingExport}
                                exportPricing={exportPricing}
                                handleClickImport={handleClickImport}
                                isInScrollHeadBand
                            />
                        </Row>
                        <ImportPricingModal
                            showModal={showImportPricingModal}
                            setShowModal={toggleImportPricingModal}
                            fileName={file?.name}
                            changeFile={changeFile}
                            importPricing={importPricing}
                            isLoading={isLoadingImport}
                        />
                        <input
                            type="file"
                            className="hide"
                            id="import-pricing"
                            accept=".csv"
                            ref={inputFileRef}
                            onChange={(event) => {
                                setFile(event.target.files[0]);
                                toggleImportPricingModal(true);
                            }}
                        />
                    </Col>
                )}
            </Row>
        </div>
    );
};

export default PricingHandBand;
