// eslint-disable-next-line import/prefer-default-export
export const LeadApvRequestTypes = [
    'Suivi de réparation',
    'Demande de rappel',
    'Demande de devis',
    'RDV modifié',
    'RDV annulé',
    'RDV fixé',
    'Information client (retard client, information complémentaire...)',
    'Demande urgente',
];
