import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { routeTo } from '@app/helpers/RoutesHelper';

import { addCustomer, associateContactToLead, getCustomers } from '@app/crud/customers/customer.crud';
import { mapCustomers } from '@app/crud/mapping/customer.map';

import ROUTES from '@app/router/Routes';
import { actions } from '@app/store/modal/modal.store';
import store from '@app/store/store';

import { getHistory } from '@app/partials/content/GlobalHistory';
import toast from '@app/partials/content/Toast';

import { mapVcuCustomer } from '../../customers/Helpers/Customer';

export const associateContactToLeadHandler = (lead, customer, takeLead) => {
    associateContactToLead(lead?.id, customer?.id)
        .then((response) => {
            if (response?.result) {
                store.dispatch(actions.closeModals());
                getHistory().push(
                    routeTo(
                        ROUTES.CUSTOMER.PATH,
                        {
                            id: customer?.id,
                        },
                        {
                            action: takeLead ? 'take' : 'viewLead',
                            leadId: lead?.id,
                        },
                    ),
                );
            } else {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CUSTOMERS.ADD.FAILED' }),
                });
            }
        })
        .catch(() => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.ADD.FAILED' }),
            });
        });
};

export const createCustomerHandler = (lead, customer, takeLead) => {
    const originData = {
        ...(lead?.contactOrigin?.firstname && { firstname: lead?.contactOrigin?.firstname }),
        ...(lead?.contactOrigin?.lastname && { lastname: lead?.contactOrigin?.lastname }),
        ...(lead?.contactOrigin?.phone && { phone: lead?.contactOrigin?.phone }),
        ...(lead?.contactOrigin?.mobile && { mobile: lead?.contactOrigin?.mobile }),
        ...(lead?.contactOrigin?.address && { address: lead?.contactOrigin?.address }),
        ...(lead?.contactOrigin?.zipcode && { zipcode: lead?.contactOrigin?.zipcode }),
        ...(lead?.contactOrigin?.city && { city: lead?.contactOrigin?.city }),
        ...(lead?.contactOrigin?.gdpr && { gdpr: lead?.contactOrigin?.gdpr }),
    };

    addCustomer({
        ...mapVcuCustomer({
            ...customer,
            ...originData,
        }),
        isClient: false,
    })
        .then((response) => {
            if (response?.result) {
                associateContactToLeadHandler(lead, response?.result, takeLead);
            } else {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CUSTOMERS.ADD.FAILED' }),
                });
            }
        })
        .catch(() => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.ADD.FAILED' }),
            });
        });
};

export const checkIdenticalData = (lead, takeLead = true, callback) => {
    if (lead?.contactOrigin) {
        if (callback) {
            callback(true);
        }

        getCustomers({
            email: lead?.contactOrigin?.email,
        }).then((response) => {
            if (callback) {
                callback(false);
            }

            if (response?.result) {
                store.dispatch(
                    actions.modalChange('create_already_exist', {
                        customers: mapCustomers(Object.values(response?.result)),
                        customer: lead?.contactOrigin,
                        onCustomerCreate: (customer) => createCustomerHandler(lead, customer, takeLead),
                    }),
                );
            }
        });
    }
};
