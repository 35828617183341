import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { FormattedHTMLMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';

import { getDealershipSignatures, updateDealershipSignatures } from '@app/crud/administration/signature.crud';

import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import SwitchInput from '@app/partials/content/SwitchInput';
import toast from '@app/partials/content/Toast';

import { formatEmailValue } from '../../administration/email-template/Helpers/email-tags';

const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};

const ManageDealershipSignatures = ({ showModal, setShowModal }) => {
    const { params } = useSelector((state) => state.modal);

    const [formParams, setFormParams] = useState({
        defaultSignature: '',
        signatureIds: {},
    });

    const { register, handleSubmit, control } = useForm();

    const [tagsData, setTagsData] = useState();
    const { userData } = useSelector((state) => state.auth);
    const [finalSignatures, setFinalSignatures] = useState([]);

    const {
        fetch: fetchSignatures,
        data: signatures,
        loading: loadingSignatures,
    } = useFetch({
        fetchAction: getDealershipSignatures,
    });

    useEffect(() => {
        if (!finalSignatures.length) {
            signatures.map((item) => {
                const data = {
                    id: item.id,
                    name: item.name,
                    value: formatEmailValue(item.value, tagsData),
                    isActive: item.isActive,
                    isDefault: item.isDefault,
                };
                return setFinalSignatures((prevState) => [...prevState, data]);
            });
        }
    }, [formParams, signatures]);

    useEffect(() => {
        setTagsData((prevState) => ({
            ...prevState,
            userData,
        }));
    }, [userData]);

    useEffect(() => {
        if (!finalSignatures.length) {
            signatures.map((item) => {
                const data = {
                    id: item.id,
                    name: item.name,
                    value: formatEmailValue(item.value, tagsData),
                };
                return setFinalSignatures((prevState) => [...prevState, data]);
            });
        }
    }, [tagsData]);

    useEffect(() => {
        if (params?.dealership?.ref_dealership_id) {
            fetchSignatures(params?.dealership.ref_dealership_id);
            setFinalSignatures([]);
        }
    }, [fetchSignatures, params]);

    const handleClick = () => {
        handleSubmit((data) => {
            const defaultSignature = parseInt(data.defaultSignature, 10);
            const formattedData = data.signatureIds.reduce((obj, item, key) => {
                const keyString = key.toString();
                obj[keyString] = item;
                return obj;
            }, {});
            updateDealershipSignatures(params?.dealership.ref_dealership_id, {
                defaultSignature,
                signatureIds: formattedData,
            })
                .then(() => {
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.UPDATE.SUCCESS' }),
                    });
                    setShowModal(false);
                })
                .catch(() => {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.UPDATE.ERROR' }),
                    });
                });
        })();
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-pencil-alt" />}
            title={Intl.formatMessage({ id: 'AUTOUSER.MODAL.DEALERSHIPS.SIGNATURE.TITLE' })}
            subtitle={Intl.formatMessage({ id: 'AUTOUSER.MODAL.DEALERSHIPS.SIGNATURE.SUBTITLE' })}
            body={
                <Form className="package">
                    {loadingSignatures && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                    <header className="d-flex font-weight-bold pb-2" style={{ borderBottom: '1px solid #E8E8E9' }}>
                        <div style={{ width: '30%' }}>
                            <FormattedHTMLMessage id="ADMINISTRATION.SIGNATURE_TEMPLATE.MANAGER.VIEW.ACTIVATIONS" />
                        </div>
                        <div style={{ width: '70%' }}>
                            <FormattedHTMLMessage id="ADMINISTRATION.SIGNATURE_TEMPLATE.MANAGER.VIEW.PREVIEW" />
                        </div>
                    </header>
                    <main>
                        <PerfectScrollbar
                            options={perfectScrollbarOptions}
                            style={{ maxHeight: '65vh', position: 'relative' }}
                        >
                            <div style={{ maxHeight: '65vh', position: 'relative' }}>
                                <ul className="p-0">
                                    {finalSignatures.length ? (
                                        finalSignatures?.map((item, index) => (
                                            <li
                                                key={item.id}
                                                className="d-flex py-5"
                                                style={{ borderBottom: '1px solid #E8E8E9' }}
                                            >
                                                <div style={{ width: '30%', paddingRight: '25px' }}>
                                                    <div>
                                                        <Form.Group>
                                                            <Controller
                                                                control={control}
                                                                defaultValue={item?.isActive}
                                                                name={`signatureIds.${item.id}`}
                                                                render={(props) => (
                                                                    <SwitchInput
                                                                        name={`isActive_${item.id}`}
                                                                        onChange={(value) => {
                                                                            item.isActive = value;
                                                                            props.onChange(value);
                                                                        }}
                                                                        form={false}
                                                                        labelFirst=""
                                                                        labelSecond="Active"
                                                                        active={item?.isActive}
                                                                        value={item?.isActive}
                                                                    />
                                                                )}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="mt-5 d-flex align-items-center">
                                                        <Form.Group className="d-flex">
                                                            <Form.Check
                                                                name="defaultSignature"
                                                                type="radio"
                                                                value={item.id}
                                                                ref={register({
                                                                    required: Intl.formatMessage({
                                                                        id: 'AGENDA.FORM.ERROR.REQUIRED',
                                                                    }),
                                                                })}
                                                                checked={
                                                                    item?.isDefault ||
                                                                    parseInt(formParams?.defaultSignature, 10) ===
                                                                        item.id
                                                                }
                                                                onChange={(e) => {
                                                                    setFormParams({
                                                                        ...formParams,
                                                                        defaultSignature: e.target.value,
                                                                    });
                                                                }}
                                                            />
                                                            <Form.Label>
                                                                <FormattedHTMLMessage id="ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.DEFAULT_SIGNATURE" />
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="mt-10">
                                                        <span className="font-weight-bold">
                                                            <FormattedHTMLMessage id="ADMINISTRATION.SIGNATURE_TEMPLATE.MANAGER.VIEW.TEMPLATE_NAME" />{' '}
                                                        </span>
                                                        <span>{item.name}</span>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        borderLeft: '1px dashed #E8E8E9',
                                                        width: '70%',
                                                        padding: '10px 20px',
                                                        opacity: finalSignatures[index].isActive ? '1' : '0.3',
                                                    }}
                                                >
                                                    {/* eslint-disable-next-line react/no-danger */}
                                                    <div dangerouslySetInnerHTML={{ __html: item.value }} />
                                                </div>
                                            </li>
                                        ))
                                    ) : (
                                        <div className="text-center mt-10">
                                            <FormattedHTMLMessage id="ADMINISTRATION.SIGNATURE_TEMPLATE.MANAGER.VIEW.NO_TEMPLATE" />
                                        </div>
                                    )}
                                </ul>
                            </div>
                        </PerfectScrollbar>
                    </main>
                </Form>
            }
            footer={
                <div className="w-100 d-flex justify-content-center">
                    {signatures.length ? (
                        <>
                            <Button className="m-2" variant="outline-secondary" onClick={() => setShowModal(false)}>
                                <FormattedHTMLMessage id="TRANSLATOR.CANCEL" />
                            </Button>

                            <Button className="m-2" variant="primary" onClick={handleClick}>
                                <FormattedHTMLMessage id="TRANSLATOR.SAVE" />
                            </Button>
                        </>
                    ) : (
                        <Button className="m-2" variant="primary" onClick={() => setShowModal(false)}>
                            <FormattedHTMLMessage id="TRANSLATOR.CLOSE" />
                        </Button>
                    )}
                </div>
            }
        />
    );
};

export default ManageDealershipSignatures;
