import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import React, { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext, Validate, ValidationRule } from 'react-hook-form';
import { useIntl } from 'react-intl';

import ErrorForm from '@app/partials/layout/ErrorForm';

import './forminputtext.scss';

interface FormInputTextProps {
    label: ReactElement;
    name: string;
    required: boolean;
    isInvalid: boolean;
    placeholder?: string;
    className?: string;
    validate?: Validate;
    pattern?: ValidationRule<RegExp>;
    disabled?: boolean;
    inputGroupBefore?: ReactElement;
    inputGroupAfter?: ReactElement;
}

const FormInputText = ({
    label,
    name,
    required,
    isInvalid,
    placeholder,
    className,
    validate,
    pattern,
    disabled,
    inputGroupBefore,
    inputGroupAfter,
}: FormInputTextProps) => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { control, errors } = methods;

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Controller
                control={control}
                name={name}
                rules={{
                    required: required && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    validate,
                    pattern,
                }}
                render={({ value, onChange }) => (
                    <div className="p-inputgroup flex-1">
                        {inputGroupBefore}
                        <InputText
                            value={value}
                            onChange={(e) => onChange(e.currentTarget.value)}
                            className={classNames(`form-control ${className}`, {
                                'is-invalid': isInvalid,
                            })}
                            placeholder={
                                placeholder
                                    ? Intl.formatMessage({
                                          id: placeholder,
                                      })
                                    : ''
                            }
                            disabled={disabled}
                        />
                        {inputGroupAfter}
                    </div>
                )}
            />
            <ErrorForm errors={errors} name={name} />
        </Form.Group>
    );
};

FormInputText.defaultProps = {
    placeholder: '',
    className: '',
    validate: null,
    pattern: null,
    disabled: false,
    inputGroupBefore: null,
    inputGroupAfter: null,
};

export default FormInputText;
