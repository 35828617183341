import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { ReactElement } from 'react';
import { Image, Row } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import { capitalize } from '@app/helpers/StringHelper';

import { Customer } from '@app/crud/customers/customer.type';

import LeadStatusRow from '@app/pages/customers/Partials/LeadStatusRow';
import { Lead } from '@app/store/customers/customers.store';

type CallbackMarketingHandBandProps = {
    customer: Customer;
    lead: Lead;
    actions: ReactElement;
};
const CallbackMarketingHandBand = ({ customer, lead, actions }: CallbackMarketingHandBandProps) => {
    const UppercaseLocale = (Locale as string).toUpperCase() as CountryCode;
    const phone = parsePhoneNumberFromString(customer?.phone || '', UppercaseLocale);
    const mobile = parsePhoneNumberFromString(customer?.mobile || '', UppercaseLocale);

    return (
        <div className="customer-handband my-lg-0 my-2">
            <Row className="font-size-h4 text-white headband__content headband__content--campaign">
                <Image
                    className="customer-handband__pic ml-5"
                    src="/media/pages/customers/fiche-client-marketing-campaign.svg"
                />
                <div className="ml-5 border-bottom">
                    <FormattedHTMLMessage id="CUSTOMERS.HANDBAND.CALLBACK.MARKETING" />
                </div>
            </Row>
            <Row className="font-size-h4 text-white headband__content headband__content--scroll">
                {lead?.leadType?.name || ''}
                <LeadStatusRow
                    leadStatusSlug={lead?.leadStatus?.slug}
                    leadStatusName={lead?.leadStatus?.name}
                    leadStatusSubName={lead?.leadStatus?.subName}
                    variant="white"
                    className="d-flex align-items-center"
                />
                <Image
                    className="customer-handband__pic--small ml-5"
                    src="/media/pages/customers/fiche-client-marketing-campaign.svg"
                />
                <div className="ml-lg-5 mx-lg-0 mx-5 text-lg-left text-center">
                    <FormattedHTMLMessage
                        id="CUSTOMERS.HANDBAND.CALLBACK.NAME"
                        values={{
                            name: `${capitalize(customer?.firstname) || ''} ${capitalize(customer?.lastname) || ''}`,
                        }}
                    />
                </div>
                <div className="ml-lg-5 mx-lg-0 mx-5">{actions}</div>
                {(phone?.formatNational?.() || mobile?.formatNational?.()) && (
                    <div className="ml-lg-5 mx-lg-0 mx-5">
                        <FormattedHTMLMessage id="CUSTOMERS.HANDBAND.PHONE" />
                        <span className="font-weight-bold text-warning">
                            {mobile?.formatNational?.() || phone?.formatNational?.()}
                        </span>
                    </div>
                )}
            </Row>
        </div>
    );
};

export default CallbackMarketingHandBand;
