import React from 'react';
import { Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getUserData } from '@app/helpers/UserHelper';

import { getLeadsDealerships } from '@app/pages/customers/Helpers/Lead';
import useAction from '@app/pages/customers/Hooks/useAction';
import { LeadRow } from '@app/pages/customers/Partials/LeadRow';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

const LinkLead = ({ showModal, setShowModal }) => {
    const userData = getUserData()?.userData;
    const { data: customer } = useSelector((state) => state.customer.customer);
    const Intl = useIntl();
    const methods = useForm();
    const { control, handleSubmit, watch } = methods;
    const [loading, createAction] = useAction({
        type: 'join',
        success: () => {
            setShowModal(false);
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LINK_LEAD.ERROR' }),
            });
        },
    });
    const dealerships = getLeadsDealerships(customer?.leads);
    const leads =
        customer?.leads?.length > 0
            ? customer.leads.filter((lead) => !!(dealerships?.[lead?.dealershipId] && lead?.ownerId === userData?.id))
            : [];
    const leadIds = watch('leadIds', leads);

    const onSubmit = (data) => {
        createAction({
            leadIds: data?.leadIds?.map?.((lead) => lead?.id),
        });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const getSelectedLeads = (currentValue, lead, state) => {
        const leadIdx = currentValue.findIndex((value) => value?.id === lead?.id);
        let newValue = [...currentValue];

        if (lead?.dealershipId !== currentValue?.[0]?.dealershipId) {
            newValue = [];
        }

        if (state && leadIdx === -1) {
            newValue.push(lead);
        } else if (leadIdx !== -1) {
            newValue.splice(leadIdx, 1);
        }

        return newValue;
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-link" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LINK_LEAD.TITLE' })}
            body={
                <>
                    <div className="text-center mb-8">
                        <FormattedHTMLMessage id="CUSTOMERS.MODAL.LINK_LEAD.SUBTITLE" />
                    </div>
                    <div style={{ width: '90%', margin: '0 auto' }}>
                        {customer?.leads?.length > 0 && (
                            <Controller
                                name="leadIds"
                                control={control}
                                defaultValue={leads?.filter?.(
                                    (lead) => lead?.dealershipId === Object.keys(dealerships)[0],
                                )}
                                render={(props) =>
                                    leads.map((lead) => (
                                        <LeadRow
                                            key={lead.id}
                                            lead={lead}
                                            isSelectable
                                            displayChild={false}
                                            isSelected={props.value.includes(lead)}
                                            onClick={(leadClicked, state) => {
                                                const selectedLeads = getSelectedLeads(props.value, leadClicked, state);
                                                props.onChange(selectedLeads);
                                            }}
                                            hasActions={false}
                                        />
                                    ))
                                }
                            />
                        )}
                    </div>
                </>
            }
            footer={
                <div className="float-right">
                    <Button
                        variant="outline-secondary"
                        style={{ width: '80px' }}
                        onClick={() => setShowModal(false)}
                        className="mr-5"
                    >
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button
                        variant="primary"
                        disabled={!leadIds || leadIds?.length <= 1}
                        style={{ width: '80px' }}
                        onClick={handleSubmit(onSubmit, onError)}
                    >
                        {Intl.formatMessage({ id: 'TRANSLATOR.LINK' })}
                    </Button>
                </div>
            }
        />
    );
};

export default LinkLead;
