import React from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';

const LeadLocalVehicleProject = ({ setShowVehicle, readOnly }) => {
    const Intl = useIntl();

    return (
        <div className="d-flex flex-column vehicle-container w-100 h-100">
            <div className="flex-grow-1 d-flex justify-content-center align-items-center">
                <i className="la la-car la-6x" />
            </div>

            {!readOnly && (
                <div className="d-flex justify-content-around p-6 button-container">
                    <Button variant="primary" onClick={() => setShowVehicle(true)}>
                        {Intl.formatMessage({ id: 'CUSTOMER.CAR_SEARCH.CHOICE' })}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default LeadLocalVehicleProject;
