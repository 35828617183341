import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import { getAdvancedPriceTagLink } from '@app/partials/content/Vehicle/VehicleHelpers';

const StockPriceTag = ({ showModal, setShowModal }) => {
    const { vehiclesSelected } = useSelector((state) => state.stock);

    const [params, setParams] = useState({});
    const [isMultiple, setIsMultiple] = useState(false);

    useEffect(() => {
        const vehiclesList = Object.keys(vehiclesSelected).filter((v) => vehiclesSelected[v] !== undefined);
        setIsMultiple(vehiclesList?.length > 1);
        setParams({
            comptant: true,
            location: true,
            credit: true,
            mode: vehiclesList?.length > 1 ? 'multiple' : 'simple',
            internal_id: vehiclesList.join(','),
        });
    }, [vehiclesSelected]);

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-print" />}
            title={Intl.formatMessage({ id: 'STOCK_VEHICLE.MODAL.PRICE_TAG.TITLE' })}
            body={
                <>
                    <div>
                        <p className="text-center">
                            <FormattedHTMLMessage id="STOCK_VEHICLE.MODAL.PRICE_TAG.TEXT" />
                        </p>
                    </div>
                    <div>
                        <h3 className="price-tag__subtitle">
                            <FormattedHTMLMessage id="STOCK_VEHICLE.MODAL.PRICE_TAG.FINANCE.TITLE" />
                        </h3>
                        <div className="pl-10">
                            <Form.Group className="d-flex">
                                <Form.Check
                                    type="checkbox"
                                    checked={params?.comptant && params?.location && params?.credit}
                                    onChange={(e) => {
                                        setParams({
                                            ...params,
                                            comptant: e.target.checked,
                                            location: e.target.checked,
                                            credit: e.target.checked,
                                        });
                                    }}
                                />
                                <Form.Label>
                                    <FormattedHTMLMessage id="STOCK_VEHICLE.MODAL.PRICE_TAG.FINANCE.ALL" />
                                </Form.Label>
                            </Form.Group>
                            <div className="pl-10">
                                <Form.Group className="d-flex">
                                    <Form.Check
                                        type="checkbox"
                                        checked={params?.comptant}
                                        onChange={(e) => {
                                            setParams({
                                                ...params,
                                                comptant: e.target.checked,
                                            });
                                        }}
                                    />
                                    <Form.Label>
                                        <FormattedHTMLMessage id="STOCK_VEHICLE.MODAL.PRICE_TAG.FINANCE.CASH" />
                                    </Form.Label>
                                </Form.Group>

                                <Form.Group className="d-flex">
                                    <Form.Check
                                        type="checkbox"
                                        checked={params?.location}
                                        onChange={(e) => {
                                            setParams({
                                                ...params,
                                                location: e.target.checked,
                                            });
                                        }}
                                    />
                                    <Form.Label>
                                        <FormattedHTMLMessage id="STOCK_VEHICLE.MODAL.PRICE_TAG.FINANCE.LOA" />
                                    </Form.Label>
                                </Form.Group>

                                <Form.Group className="d-flex">
                                    <Form.Check
                                        type="checkbox"
                                        checked={params?.credit}
                                        onChange={(e) => {
                                            setParams({
                                                ...params,
                                                credit: e.target.checked,
                                            });
                                        }}
                                    />
                                    <Form.Label>
                                        <FormattedHTMLMessage id="STOCK_VEHICLE.MODAL.PRICE_TAG.FINANCE.CREDIT" />
                                    </Form.Label>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    {isMultiple && (
                        <div>
                            <h3 className="price-tag__subtitle">
                                <FormattedHTMLMessage id="STOCK_VEHICLE.MODAL.PRICE_TAG.EXPORT" />
                            </h3>
                            <div className="pl-10">
                                <Form.Group className="d-flex">
                                    <Form.Check
                                        type="checkbox"
                                        checked={params?.mode === 'zip'}
                                        onChange={(e) => {
                                            setParams({
                                                ...params,
                                                mode: 'zip',
                                            });
                                        }}
                                    />
                                    <Form.Label>
                                        <FormattedHTMLMessage id="STOCK_VEHICLE.MODAL.PRICE_TAG.EXPORT.ONE_BY_ONE" />
                                    </Form.Label>
                                </Form.Group>
                                <Form.Group className="d-flex">
                                    <Form.Check
                                        type="checkbox"
                                        checked={params?.mode === 'multiple'}
                                        onChange={(e) => {
                                            setParams({
                                                ...params,
                                                mode: 'multiple',
                                            });
                                        }}
                                    />
                                    <Form.Label>
                                        <FormattedHTMLMessage id="STOCK_VEHICLE.MODAL.PRICE_TAG.EXPORT.ALL_IN_ONE" />
                                    </Form.Label>
                                </Form.Group>
                            </div>
                        </div>
                    )}
                </>
            }
            footer={
                <div className="w-100 d-flex justify-content-end">
                    <Button className="m-2" variant="outline-secondary" onClick={() => setShowModal(false)}>
                        <FormattedHTMLMessage id="TRANSLATOR.CANCEL" />
                    </Button>

                    <a href={getAdvancedPriceTagLink(params)} rel="noreferrer" className="px-1" download>
                        <Button className="m-2" variant="primary">
                            <FormattedHTMLMessage id="STOCK_VEHICLE.MODAL.PRICE_TAG.BUTTON.GENERATE" />
                        </Button>
                    </a>
                </div>
            }
        />
    );
};

export default StockPriceTag;
