import {createOperation, updateOperation} from "../../../../store/apv/commercial-operation/commercial-operation.thunk";
import * as dayjs from "dayjs";

/**
 * Handler radio button to packages or additionalService association
 *
 * @param {Object} submitData form data submit
 *
 * @return void
 */
function associationTypeHandler(submitData) {
    if (submitData.associationType === 'packageAssociation') {
        submitData.additionalServiceId = '';
    }
    if (submitData.associationType === 'additionalServiceAssociation') {
        submitData.packageId = '';
    }

    delete submitData.associationType;
}

/**
 * Format date
 *
 * @param {Object} submitData form data submit
 *
 * @return void
 */
function dateHandler(submitData) {
    if (submitData.dateStart) {
        submitData.dateStart =  dayjs(submitData.dateStart).format('YYYY-MM-DD HH:mm:ss');
    }
    if (submitData.dateEnd) {
        submitData.dateEnd =  dayjs(submitData.dateEnd).format('YYYY-MM-DD HH:mm:ss');
    }
}

/**
 * Handler radio button card discount component
 *
 * @param {Object} submitData form data submit
 *
 * @return void
 */
function discounHandler(submitData) {
    if (submitData.discount === 'percentageDiscount') {
        submitData.price = '';
    }
    if (submitData.discount === 'priceDiscount') {
        submitData.percentage = '';
    }
    if (submitData.discount === 'noDiscount') {
        submitData.percentage = '';
        submitData.price = '';
    }

    delete submitData.discount;
}

/**
 * Create / Update operation
 *
 * @param {Object} data        form data submit
 * @param {Object} currentData other current data {dispatch,history, params ....}
 *
 * @return void
 */
function submit(data, currentData) {
    if (!data) {
        return
    }

    const {
        commercialOperationTypeId,
        newOperation,
        dispatch,
        params,
        history
    } = currentData;

    data = {...data, commercialOperationTypeId};

    if (newOperation) {
        dispatch(createOperation({
            data,
            history
        }));
    } else {
        dispatch(updateOperation(params.id, data));
    }
}

/**
 * Submit form handler
 *
 * @param {Object} dataSubmit form data submit
 *
 * @constructor
 *
 * @return void
 */
export default function formSubmit(dataSubmit) {
    let values = {};
    const data = dataSubmit.data;

    // National operation
    if (data.commercialOperationTypeId === 1) {
        values = data.nationalForm.getValues();
        discounHandler(values);
        dateHandler(values);
        associationTypeHandler(values);
        // submit data
        data.nationalForm.trigger().then((result)=>{
            if (result) {
                submit(values, {
                    commercialOperationTypeId: data.commercialOperationTypeId,
                    data,
                    newOperation: dataSubmit.newOperation,
                    dispatch: dataSubmit.dispatch,
                    params: dataSubmit.params,
                    history: dataSubmit.history
                })
            }
        })
    }

    // Local operation
    if (data.commercialOperationTypeId === 2) {
        values = data.localForm.getValues();
        discounHandler(values);
        dateHandler(values);
        // submit data
        data.localForm.trigger().then((result)=>{
            if (result) {
                submit(values, {
                        commercialOperationTypeId: data.commercialOperationTypeId,
                        data,
                        newOperation: dataSubmit.newOperation,
                        dispatch: dataSubmit.dispatch,
                        params: dataSubmit.params,
                        history: dataSubmit.history
                    }
                )
            }
        })
    }
}
