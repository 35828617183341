import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import Swal from 'sweetalert2';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import usePermissions from '@app/hooks/usePermissions';

import { ucwords } from '@app/helpers/StringHelper';

import Permission from '@app/partials/content/Permission';

import { DEFAULT_WEEK, hasInactiveStatus, isEventPast } from '../Helpers/Planning';

import DayUpdate from './DayUpdate';

const WeekRow = ({
    days,
    month,
    format,
    availabilities,
    disableOuterDays,
    className,
    onUpdate,
    loading,
    permissions,
    canUpdate,
    section,
}) => {
    const { hasAnyPermissions } = usePermissions();
    const [selectedBox, setSelectedBox] = useState({});

    const getDaySchedules = (day) => {
        const dayName = day.locale('en').format('dddd').toLowerCase();
        return availabilities ? availabilities[dayName] : {};
    };

    const handleBoxClick = (target, day, hour, availabilityItems) => {
        if (isEventPast(day, availabilityItems, hour) && section !== DEFAULT_WEEK) {
            Swal.fire({
                title: Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.UPDATE_FORBIDDEN.TITLE' }),
                text: Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.UPDATE_FORBIDDEN.SUBTITLE' }),
                icon: 'warning',
            });
            return;
        }

        if (!hasAnyPermissions(permissions)) {
            return false;
        }

        if (selectedBox.day === day && selectedBox.hour === hour) {
            setSelectedBox({});
        } else {
            const schedules = getDaySchedules(day);

            setSelectedBox({
                ...schedules,
                target,
                day,
                hour,
            });
        }
    };

    return (
        <>
            <div className={classNames('week-row', className)}>
                <div className="week-col">
                    <div className="week-box week-box--day week-box--empty" />
                    <div className="week-box week-box--am week-box--hour week-box--primary">
                        {Intl.formatMessage({ id: 'TRANSLATOR.MORNING' })}
                    </div>
                    <div className="week-box week-box--pm week-box--hour week-box--primary">
                        {Intl.formatMessage({ id: 'TRANSLATOR.AFTERNOON' })}
                    </div>
                </div>
                {days.map((day) => {
                    const schedule = getDaySchedules(day);
                    const isBoxPast = dayjs().isAfter(day, 'date') && section !== DEFAULT_WEEK;
                    return (
                        <div className="week-col" key={`${day.$D}_${day.$M}`}>
                            <div
                                className={classNames('week-box week-box--day week-box--primary', {
                                    'week-box--disable': disableOuterDays && day.month() !== month,
                                })}
                            >
                                {day.format(format).toUpperCase()}
                            </div>
                            <div
                                role="button"
                                className={classNames('week-box week-box--hour week-box--light-gray', {
                                    'week-box--past': isBoxPast,
                                    'week-box--active': selectedBox?.day === day && selectedBox?.hour === 'am',
                                    'week-box--absent': schedule?.am?.absent,
                                    'week-box--disable': (disableOuterDays && day.month() !== month) || !canUpdate,
                                    'week-box--plat-success': schedule?.am?.absent === false,
                                    'week-box--editable': hasAnyPermissions(permissions),
                                    'week-box--notspecified':
                                        !availabilities || hasInactiveStatus(day, availabilities, 'am'),
                                })}
                                onClick={(e) => handleBoxClick(e.currentTarget, day, 'am', availabilities)}
                            >
                                {availabilities &&
                                    hasInactiveStatus(day, availabilities, 'am') &&
                                    `${ucwords(Intl.formatMessage({ id: 'TRANSLATOR.NOT_SPECIFIED' }))} !`}
                                {!availabilities &&
                                    `${ucwords(Intl.formatMessage({ id: 'TRANSLATOR.NOT_SPECIFIED' }))} !`}
                                {schedule?.am?.absent && 'Absent'}
                                {schedule?.am?.absent === false && (
                                    <>
                                        Présence
                                        <br />
                                        <span className="week-box__hour">
                                            {schedule?.am?.start || ''} - {schedule?.am?.end || ''}
                                        </span>
                                    </>
                                )}
                            </div>
                            <div
                                className={classNames('week-box week-box--hour week-box--light-gray', {
                                    'week-box--past': isBoxPast,
                                    'week-box--active': selectedBox?.day === day && selectedBox?.hour === 'pm',
                                    'week-box--absent': schedule?.pm?.absent,
                                    'week-box--disable': (disableOuterDays && day.month() !== month) || !canUpdate,
                                    'week-box--plat-success': schedule?.pm?.absent === false,
                                    'week-box--editable': hasAnyPermissions(permissions),
                                    'week-box--notspecified':
                                        !availabilities || hasInactiveStatus(day, availabilities, 'pm'),
                                })}
                                onClick={(e) => handleBoxClick(e.currentTarget, day, 'pm', availabilities)}
                            >
                                {availabilities &&
                                    hasInactiveStatus(day, availabilities, 'pm') &&
                                    `${ucwords(Intl.formatMessage({ id: 'TRANSLATOR.NOT_SPECIFIED' }))} !`}
                                {!availabilities &&
                                    `${ucwords(Intl.formatMessage({ id: 'TRANSLATOR.NOT_SPECIFIED' }))} !`}
                                {schedule?.pm?.absent && 'Absent'}
                                {schedule?.pm?.absent === false && (
                                    <>
                                        Présence
                                        <br />
                                        <span className="week-box__hour">
                                            {schedule?.pm?.start || ''} - {schedule?.pm?.end || ''}
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            <Permission permissions={permissions}>
                <DayUpdate
                    className="mt-8"
                    selectedBox={selectedBox}
                    onUpdate={onUpdate}
                    displayLoading={loading}
                    onClose={() => setSelectedBox({})}
                />
            </Permission>
        </>
    );
};
WeekRow.defaultProps = {
    format: 'ddd',
    disableOuterDays: false,
    month: undefined,
    loading: false,
    canUpdate: true,
};

export default WeekRow;
