import React from 'react';
import { useIntl } from 'react-intl';

import { LayoutContextConsumer } from '@src/_metronic';
import BreadCrumbs from '@src/_metronic/layout/sub-header/components/BreadCrumbs';

type AlertSubHeaderProps = {
    subheaderCssClasses?: string;
    subheaderContainerCssClasses?: string;
    subheaderMobileToggle?: boolean;
};

function AlertSubHeader({
    subheaderCssClasses,
    subheaderContainerCssClasses,
    subheaderMobileToggle,
}: AlertSubHeaderProps) {
    const Intl = useIntl();

    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: 'ADMINISTRATION.ALERTS.SUBHEADER.TITLE' }),
        },
    ];

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses} kt-container--fluid`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            type="submit"
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}
                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">
                                    {Intl.formatMessage({ id: 'MENU.ADMINISTRATION' })}
                                </h3>
                                <BreadCrumbs items={breadcrumb} />
                            </>
                        )}
                    </LayoutContextConsumer>
                </div>
            </div>
        </div>
    );
}

AlertSubHeader.defaultProps = {
    subheaderCssClasses: '',
    subheaderContainerCssClasses: '',
    subheaderMobileToggle: false,
};

export default AlertSubHeader;
