import {useSelector} from "react-redux";
import {useMemo} from "react";

const useTypologies = () => {
    const {userData} = useSelector((state) => state.auth);

    const typologies = useMemo(() => {
        if (!userData?.tags?.typologie_lead) {
            return [];
        }

        return userData.tags.typologie_lead;
    }, [userData]);

    const hasTypology = (typology) => {
        if (!typology) {
            return true;
        }

        return typologies.includes(typology);
    }

    const hasAllTypologies = (typologies) => {
        if (!typologies) {
            return true;
        }

        return typologies.every(typology => hasTypology(typology));
    }

    const hasAnyTypologies = (typologies) => {
        if (!typologies) {
            return true;
        }

        return typologies.some(typology => hasTypology(typology));
    }

    return {
        hasTypology,
        hasAllTypologies,
        hasAnyTypologies
    };
};

export default useTypologies;
