import dayjs from 'dayjs';
import { Dropdown } from 'primereact/dropdown';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { getPackagesCategoriesWithPackagesAndPrices } from '@app/crud/apv/package.crud';

import { useFetch } from '@app/hooks';
import { Package } from '@app/pages/apv/orders/Order';
import { PackageCategory } from '@app/pages/customers/Modals/APVInStore/APVInStore.type';
import APVInStoreContext from '@app/pages/customers/Modals/APVInStore/APVInStoreContext';

import Loader from '@app/partials/content/Loader';

type PackagesSelectProps = {
    onPackageSelect: (selectedPackage: Package) => void;
    selectedPackages: Package[];
    required: boolean;
};

const APVInStorePackageSelect = ({ onPackageSelect, selectedPackages, required = true }: PackagesSelectProps) => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { getValues, control } = methods;
    const {
        fetch: fetchPackagesCategoriesWithPackagesAndPrices,
        data: packagesSelected,
        loading,
    } = useFetch({
        fetchAction: getPackagesCategoriesWithPackagesAndPrices,
    });
    const { stepperState } = useContext(APVInStoreContext);

    const vehicleDetails = useMemo(
        () => ({
            dateFirstRegistration: dayjs(getValues('dateFirstRegistration')).format('DD/MM/YYYY'),
            departmentId: getValues('dealershipDepartmentId'),
            vehicleMakeSegmentId: getValues('version')?.VehicleMakeSegmentId,
            vehicleBodyTypeSegmentId: getValues('vehicleBodyTypeSegmentId'),
        }),
        [
            getValues('dateFirstRegistration'),
            getValues('departmentId'),
            getValues('vehicleMakeSegmentId'),
            getValues('vehicleBodyTypeSegmentId'),
        ],
    );

    useEffect(() => {
        if (stepperState.currentStep === 1) {
            fetchPackagesCategoriesWithPackagesAndPrices(vehicleDetails);
        }
    }, [fetchPackagesCategoriesWithPackagesAndPrices, vehicleDetails, stepperState.currentStep]);

    const packagesData = useMemo(() => {
        if (!packagesSelected?.categories?.length || !selectedPackages) return [];
        return packagesSelected.categories
            .map((category) => ({
                ...category,
                packages: category.packages.filter(
                    (pkg) => !selectedPackages.some((sp) => sp.packageId === pkg.packageId),
                ),
            }))
            .filter((category) => category.packages.length > 0);
    }, [packagesSelected, selectedPackages]);

    const [selectedValue, setSelectedValue] = useState(null); // State to handle the selected value in Dropdown

    useEffect(() => {
        setSelectedValue(null); // Reset selected value when packages change
    }, [selectedPackages]);

    const groupedItemTemplate = (option: PackageCategory) => (
        <div className="flex justify-content-between align-items-center">
            <div>{option.name}</div>
        </div>
    );

    return (
        <>
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="CUSTOMER.MODAL.APVINSTORE.PACKAGES.TITLE" />
                </Form.Label>
                <Controller
                    control={control}
                    name="packageSelected"
                    rules={{
                        required: required && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    }}
                    render={({ onChange }) => (
                        <Dropdown
                            value={selectedValue}
                            onChange={(e) => {
                                onChange(e.value);
                                onPackageSelect(e.value);
                                setSelectedValue(e.value);
                            }}
                            options={packagesData}
                            optionLabel="packageName"
                            optionGroupLabel="packageName"
                            optionGroupChildren="packages"
                            optionGroupTemplate={groupedItemTemplate}
                            itemTemplate={(option) => (
                                <div className="d-flex w-100 justify-content-between">
                                    <p>{option.packageName}</p>
                                    <p>
                                        {option.packagePricePrice} €{' '}
                                        <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.PACKAGE_PRICE_TTC" />
                                    </p>
                                </div>
                            )}
                            className="w-100"
                            filter
                            required
                            filterBy="packageName"
                            placeholder={Intl.formatMessage({ id: 'CUSTOMER.MODAL.APVINSTORE.PLACEHOLDER.SELECT' })}
                            disabled={!packagesData.length}
                        />
                    )}
                />
            </Form.Group>
        </>
    );
};

export default APVInStorePackageSelect;
