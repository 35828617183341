import Swal from 'sweetalert2';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { routeTo } from '@app/helpers/RoutesHelper';

import {
    addAdditionalService,
    deleteAdditionalService,
    getAdditionalService,
    getAdditionalServices,
    updateAdditionalServiceById,
} from '@app/crud/apv/additional-service.crud';
import { getSettings } from '@app/crud/apv/commercial-operation.crud';

import ROUTES from '@app/router/Routes';

import toast from '@app/partials/content/Toast';

import { actions } from './additional-service.store';

export function fetchAdditionalServices(params) {
    return (dispatch) => {
        dispatch(actions.fetchAdditionalServices());

        getAdditionalServices(params)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(
                        actions.fetchedAdditionalServices({
                            ...response.result,
                            count: response?.count || 0,
                        }),
                    );
                } else {
                    dispatch(actions.fetchAdditionalServicesFailed({}));
                }
            })
            .catch(() => {
                dispatch(actions.fetchAdditionalServicesFailed({}));
            });
    };
}

export function fetchAdditionalService(id) {
    return (dispatch) => {
        dispatch(actions.fetchAdditionalService());

        const promises = [getSettings()];

        if (id) {
            promises.push(getAdditionalService(id));
        }

        Promise.all(promises)
            .then((values) => {
                const settings = values[0];
                const additionalService = values[1] || null;

                if (settings?.result?.error) {
                    dispatch(actions.fetchAdditionalServiceFailed(settings.result));
                } else if (additionalService?.result?.error && id) {
                    dispatch(actions.fetchAdditionalServiceFailed(additionalService.result));
                } else {
                    dispatch(
                        actions.fetchedAdditionalService({
                            additionalService: additionalService?.result || [],
                            package: settings.result.package,
                            additionalServiceCategory: settings.result.additionalServiceCategory,
                        }),
                    );
                }
            })
            .catch(() => {
                dispatch(actions.fetchAdditionalServiceFailed({}));
            });
    };
}

export function createAdditionalService(params) {
    return (dispatch) => {
        dispatch(actions.addAdditionalService());

        addAdditionalService({
            ...params.data,
            excludedPackagesId: params.data?.excludedPackagesId ? params.data?.excludedPackagesId?.join(',') : '',
        })
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.addedAdditionalService(response.result));
                    params.history.push(
                        routeTo(ROUTES.ADDITIONAL_SERVICE.PATH.UPDATE, {
                            id: response.result.id,
                        }),
                    );
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.ADD.SUCCESS' }),
                    });
                } else {
                    dispatch(actions.addAdditionalServiceFailed());
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.ADD.FAILED' }),
                    });
                }
            })
            .catch(() => {
                dispatch(actions.addAdditionalServiceFailed());
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.ADD.FAILED' }),
                });
            });
    };
}

export function updateAdditionalService(id, data) {
    return (dispatch) => {
        dispatch(actions.updateAdditionalService());
        updateAdditionalServiceById(id, {
            ...data,
            excludedPackagesId: data?.excludedPackagesId ? data?.excludedPackagesId?.join(',') : '',
        })
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.updatedAdditionalService(response.result));
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.UPDATE.SUCCESS' }),
                    });
                } else {
                    dispatch(actions.updateAdditionalServiceFailed());
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.UPDATE.FAILED' }),
                    });
                }
            })
            .catch(() => {
                dispatch(actions.updateAdditionalServiceFailed());
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.UPDATE.FAILED' }),
                });
            });
    };
}

export function removeAdditionalService(params) {
    return (dispatch) => {
        Swal.fire({
            title: Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.REMOVE.TITLE' }),
            text: Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.REMOVE.TEXT' }),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
            cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
            customClass: {
                confirmButton: 'btn mr-3 btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            preConfirm: () => {
                Swal.showLoading();
                dispatch(actions.removeAdditionalService());

                return deleteAdditionalService(params.id)
                    .then((response) => {
                        if (!response?.result?.error) {
                            params.history.push(routeTo(ROUTES.ADDITIONAL_SERVICES.PATH));
                            toast({
                                variant: 'success',
                                message: Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.REMOVE.SUCCESS' }),
                            });
                        } else {
                            toast({
                                variant: 'danger',
                                message: Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.REMOVE.FAILED' }),
                            });
                        }
                    })
                    .catch(() => {
                        toast({
                            variant: 'danger',
                            message: Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.REMOVE.FAILED' }),
                        });
                    });
            },
        });
    };
}
