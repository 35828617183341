/***
 * Display a message on the button to inform the user
 */

import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

function OverlayBtn({ tooltipMessage, toggleColor, data, dataCompared, validMessage, unValidMessage, colorFirst, colorSecond, ctaOne, ctaTwo, position }) {
    return (
        <OverlayTrigger
            trigger={data < dataCompared ? 'hover' : null}
            placement={position}
            overlay={<Tooltip>{tooltipMessage}</Tooltip>}
        >
            <span>
                <Button
                    variant={`${toggleColor > 0 ? colorFirst : colorSecond}`}
                    onClick={toggleColor > 0 ? ctaOne : ctaTwo}
                    disabled={data < dataCompared}>
                    {`${toggleColor > 0 ? validMessage : unValidMessage}`}
                </Button>
            </span>
        </OverlayTrigger>
    )
}

export default OverlayBtn;