import { Column } from 'primereact/column';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import HoCDataTable from '@app/partials/content/HoCDataTable';

const EventDetailOrderDetail = ({ event, typeSlug }) => {
    const Intl = useIntl();

    const referenceBodyTemplate = (data) => data?.code;

    const productBodyTemplate = (data) => data?.name;

    const opeBodyTemplate = (data) => data?.commercialOperationName || '-';
    const priceTTCInitialBodyTemplate = (data) => (
        <NumberFormat value={data?.price} decimalSeparator="." thousandSeparator=" " displayType="text" suffix=" €" />
    );

    const priceTTCFinalBodyTemplate = (data) => (
        <NumberFormat
            value={data?.totalPrice}
            decimalSeparator="."
            thousandSeparator=" "
            displayType="text"
            suffix=" €"
        />
    );

    return (
        <Card className="card--shadowed">
            <Card.Header className={`event__origin--${typeSlug} position-relative`}>
                <div className={`event__origin-bg--${typeSlug} pin`}>
                    <i className="la la-newspaper-o text-white la-lg" />
                </div>
                <span className="ml-4">
                    {Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ORDER_DETAIL.TITLE' })}
                </span>
            </Card.Header>
            <Card.Body className="font-weight-bold">
                <HoCDataTable
                    value={event?.appointment.details.appointmentServices}
                    totalRecords={event?.appointment.details.appointmentServices?.length}
                    lazy
                    rows={25}
                    start={0}
                    first={0}
                    removableSort
                    emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                >
                    <Column
                        field="ref"
                        header={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ORDER_DETAIL.REFERENCE' })}
                        body={referenceBodyTemplate}
                    />
                    <Column
                        field="product"
                        header={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ORDER_DETAIL.PRODUCT' })}
                        style={{ width: '50%' }}
                        body={productBodyTemplate}
                    />
                    <Column
                        field="ope"
                        header={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ORDER_DETAIL.OPE' })}
                        body={opeBodyTemplate}
                    />
                    <Column
                        field="price_ttc_initial"
                        header={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ORDER_DETAIL.PRICE_TTC_INITIAL' })}
                        style={{ width: '15%' }}
                        body={priceTTCInitialBodyTemplate}
                    />
                    <Column
                        field="price_ttc_final"
                        header={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ORDER_DETAIL.PRICE_TTC_FINAL' })}
                        style={{ width: '15%' }}
                        body={priceTTCFinalBodyTemplate}
                    />
                </HoCDataTable>
                <div className="d-flex float-right  mt-4 total-price">
                    <span className="mr-2" style={{ width: '50%' }}>
                        {Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ORDER_DETAIL.TOTAL_TTC' })}
                    </span>
                    <NumberFormat
                        value={event?.appointment.details.appointmentTotalOrderAmount}
                        decimalSeparator="."
                        thousandSeparator=" "
                        displayType="text"
                        suffix=" €"
                    />
                </div>
            </Card.Body>
        </Card>
    );
};

export default EventDetailOrderDetail;
