import type { Dispatch, SetStateAction } from 'react';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { createSalesforceLead } from '@app/crud/customers/customer.crud';

import variantTypes from '@app/constants';
import type { Lead } from '@app/store/customers/customers.store';
import { LeadState } from '@app/store/lead/lead.store';

import toast from '@app/partials/content/Toast';

export type ValueOf<T> = T[keyof T];

// eslint-disable-next-line import/prefer-default-export
export const getCurrentActionfor = (state: LeadState, id: number): string | null =>
    state.currentActions.find((action) => action.id === id)?.action || null;

export const leadActions = {
    close_lead_won: 'close_lead_won',
    close_lead_lost: 'close_lead_lost',
};

export const leadStatus = {
    closed_won: 'closed_won',
    closed_lost: 'closed_lost',
};

const leadBMW = {
    BMW_GROUP_AMNG: 'bmw_group_amng',
    BMW_GROUP: 'bmw_group',
};

export const getOriginIdBMW = async (lead: Lead, setIsLoading: Dispatch<SetStateAction<boolean>>) => {
    if (lead?.leadSource?.slug !== leadBMW.BMW_GROUP) return null;

    const leadOrigin = lead?.leadOrigin?.slug;
    let leadOriginId;

    if (lead?.originId) {
        leadOriginId = lead.originId;
    } else if (leadOrigin === leadBMW.BMW_GROUP_AMNG) {
        if (!lead?.id) {
            return null;
        }

        setIsLoading(true);
        try {
            const response = await createSalesforceLead(lead?.id);
            if (response?.result?.origin_id) {
                leadOriginId = response.result.origin_id;
            }
        } catch {
            toast({
                variant: variantTypes.DANGER,
                message: Intl.formatMessage({
                    id: 'CUSTOMERS.ACTIONS.CREATE_OPPORTUNITY.ERROR',
                }),
            });
        } finally {
            setIsLoading(false);
        }
    }

    if (!leadOriginId) {
        return null;
    }

    return leadOriginId.replace('BMW_GROUP_', '');
};
