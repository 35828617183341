import React from 'react';
import { Button, Card, Col, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import useModal from '@app/hooks/useModal';
import useQueryParams from '@app/hooks/useQueryParams';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { routeTo } from '@app/helpers/RoutesHelper';
import { isAMail, isAPhoneNumber } from '@app/helpers/ToolsHelper';

import { mapCustomers } from '@app/crud/mapping/customer.map';

import Fusion from '@app/pages/customers/Modals/Fusion/Fusion';
import ROUTES from '@app/router/Routes';
import { createCustomer, fetchCustomers } from '@app/store/customers/customers.thunk';
import { actions as actionsModal } from '@app/store/modal/modal.store';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';

import CustomersSearch from '@app/components/forms/search/CustomersSearch';

import CustomerDataTable from './CustomerDataTable';
import { mapVcuCustomer } from './Helpers/Customer';
import { Create, CreateAlreadyExist, RollbackUpdate } from './Modals';

const Customers = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useQueryParams();
    const Intl = useIntl();
    const { selectedCustomers } = useSelector((state) => state.customer);
    const stateCustomers = useSelector((state) => ({
        ...state.customer.customers,
        data: mapCustomers(state.customer.customers.data),
    }));
    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: 'customers_search',
        path: ROUTES.CUSTOMERS.PATH,
        defaultValues: {
            filters: {
                research: { value: params?.research?.trim?.() || null },
                vehicle: { value: params?.vehicle?.trim?.() || null },
            },
            page: 1,
        },
        persisted: false,
    });

    const { refresh, handleFilter, handlePagination, handleSort } = useTableHandlers({
        filterStore: 'customers_search',
        fetchAction: fetchCustomers,
        doSearch: (searchParams) => searchParams?.research?.length > 0 || searchParams?.vehicle?.length,
        paramsInterceptor: (params, interceptorFilters) => ({
            ...params,
            page: interceptorFilters.page,
            items: interceptorFilters.items,
        }),
    });
    const [showCreateModal, toggleCreateModal] = useModal(false, 'create');
    const [showFusionModal, toggleFusionModal] = useModal(false, 'fusion');
    const [showCreateAlreadyExistModal, toggleCreateAlreadyExistModal, createAlreadyExistParams] = useModal(
        false,
        'create_already_exist',
    );
    const [rollbackUpdateModalOpen, toggleRollbackUpdateModalOpen] = useModal(false, 'rollback_update');

    const customers = stateCustomers.data;
    const { loading } = stateCustomers;

    const columns = {
        actions: {
            body: (customer) => {
                if (customer?.id) {
                    return (
                        <Permission permissions={['LEAD_VIEW_CONTACT']}>
                            <Link
                                to={routeTo(ROUTES.CUSTOMER.PATH, {
                                    id: customer.id,
                                })}
                            >
                                <Button className="p-2 px-3" variant="primary">
                                    {Intl.formatMessage({ id: 'TRANSLATOR.SHOW' })}
                                </Button>
                            </Link>
                        </Permission>
                    );
                }
                return (
                    <Permission permissions={['LEAD_CREATE_CONTACT']}>
                        <Button
                            className="p-2 px-3"
                            variant="primary"
                            onClick={() =>
                                dispatch(
                                    createCustomer({
                                        data: {
                                            ...mapVcuCustomer({
                                                ...customer,
                                            }),
                                        },
                                        history,
                                    }),
                                )
                            }
                        >
                            {Intl.formatMessage({ id: 'TRANSLATOR.SHOW' })}
                        </Button>
                    </Permission>
                );
            },
        },
    };

    const handleToggleCreateModal = () => {
        if (showCreateModal) {
            toggleCreateModal();
        } else {
            const isPhone = isAPhoneNumber(filters?.research?.value);
            const isMail = isAMail(filters?.research?.value);

            dispatch(
                actionsModal.modalChange('create', {
                    customer: {
                        phone: isPhone ? filters?.research?.value : '',
                        mobile: isPhone ? filters?.research?.value : '',
                        email: isMail ? filters?.research?.value : '',
                    },
                }),
            );
        }
    };

    const renderFusionTooltip = (props) => (
        <Tooltip id="fusion-button-tooltip" {...props}>
            <FormattedMessage id="CUSTOMER.VIEW.ACTIONS.FUSION_INVALID" />
        </Tooltip>
    );

    return (
        <FiltersProvider value={filters}>
            <Card id="first-tour">
                {filters?.research?.value || filters?.vehicle?.value ? (
                    <Card.Body className="text-center">
                        {loading && stateCustomers?.count === 0 && (
                            <Loader style={{ width: '5rem', height: '5rem' }} overlay />
                        )}
                        <Row className="justify-content-between">
                            <Col lg={1} className="d-lg-block d-none">
                                {!loading && (
                                    <Image
                                        className="found-customers"
                                        src={`/media/pages/customers/${
                                            customers?.length === 0
                                                ? 'clients-recherche-pas-de-resultat.svg'
                                                : 'clients-recherche-resultats-trouves.svg'
                                        }`}
                                    />
                                )}
                            </Col>
                            <Col lg={8}>
                                <CustomersSearch refresh={refresh} className="justify-content-center" colLg={5} />

                                {!loading && (
                                    <>
                                        <div className="mb-8 font-size-h4-lg font-size-h6">
                                            <div>
                                                <FormattedHTMLMessage
                                                    id="CUSTOMERS.TABLE.VIEW.FOUND.NUMBER"
                                                    values={{
                                                        count: stateCustomers?.count,
                                                        research: filters?.research?.value ?? filters?.vehicle?.value,
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                {Intl.formatMessage({ id: 'CUSTOMERS.TABLE.VIEW.FOUND.APPEAR' })}
                                                <span className="font-weight-bold">
                                                    {' '}
                                                    {Intl.formatMessage({ id: 'CUSTOMERS.TABLE.VIEW.FOUND.CREATE' })}
                                                </span>
                                            </div>
                                        </div>
                                        <Permission permissions={['LEAD_CREATE_CONTACT']}>
                                            <Button
                                                variant="outline-primary"
                                                onClick={handleToggleCreateModal}
                                                className="mb-8"
                                            >
                                                {Intl.formatMessage({ id: 'CUSTOMERS.TABLE.VIEW.CREATE' })}
                                            </Button>
                                        </Permission>
                                        {customers?.length === 0 && (
                                            <div className="d-lg-none d-block">
                                                <Image src="/media/pages/customers/clients-recherche-pas-de-resultat.svg" />
                                            </div>
                                        )}
                                    </>
                                )}
                            </Col>
                            <Col lg={1}>
                                <Permission permissions={['CONTACT_FUSION']}>
                                    <OverlayTrigger
                                        trigger={
                                            selectedCustomers.length < 2 || selectedCustomers.length > 10
                                                ? 'hover'
                                                : null
                                        }
                                        placement="top"
                                        overlay={renderFusionTooltip}
                                    >
                                        <div>
                                            <Button
                                                variant="primary"
                                                onClick={toggleFusionModal}
                                                disabled={selectedCustomers.length < 2 || selectedCustomers.length > 10}
                                            >
                                                <span className="d-flex">
                                                    <i className="las la-user-friends" />
                                                    <FormattedMessage id="CUSTOMER.VIEW.ACTIONS.FUSION" />
                                                </span>
                                            </Button>
                                        </div>
                                    </OverlayTrigger>
                                </Permission>
                            </Col>
                        </Row>
                        <CustomerDataTable
                            customers={customers}
                            totalRecords={stateCustomers?.count}
                            loading={loading}
                            handleFilter={handleFilter}
                            handlePagination={(e) => {
                                if (!loading) {
                                    handlePagination(e);
                                }
                            }}
                            handleSort={handleSort}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            items={items}
                            start={start}
                            columns={columns}
                            filters={filters}
                            displayCheckbox
                        />
                    </Card.Body>
                ) : (
                    <Card.Body className="text-center mt-10">
                        <div className="font-size-h4 font-weight-bold mb-8">
                            <FormattedHTMLMessage id="CUSTOMERS.TABLE.VIEW.START" />
                        </div>
                        <CustomersSearch refresh={refresh} className="justify-content-center" />
                        <Col lg={{ span: 6, offset: 3 }}>
                            <Image src="/media/pages/customers/clients-recherche.svg" className="mt-10" />
                        </Col>
                    </Card.Body>
                )}
                <Create showModal={showCreateModal} setShowModal={handleToggleCreateModal} />
                <Fusion showModal={showFusionModal} setShowModal={toggleFusionModal} refresh={refresh} />
                <CreateAlreadyExist
                    showModal={showCreateAlreadyExistModal}
                    setShowModal={toggleCreateAlreadyExistModal}
                    params={createAlreadyExistParams}
                />
                <RollbackUpdate
                    showModal={rollbackUpdateModalOpen}
                    setShowModal={toggleRollbackUpdateModalOpen}
                    alwaysValidate
                    validateLabel={Intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                    actualDataLabel={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.ROLLBACK.ENTERED_DATA' }).toUpperCase()}
                    previousDataLabel={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.ROLLBACK.KNOWN_DATA' }).toUpperCase()}
                    title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.ROLLBACK.TITLE.CREATE_USER' })}
                    subtitle={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.ROLLBACK.SUBTITLE.CREATE_USER' })}
                />
            </Card>
        </FiltersProvider>
    );
};

export default Customers;
