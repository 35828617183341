import React from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import { useAppDispatch, usePermissions } from '@app/hooks';
import { actions } from '@app/store/modal/modal.store';

import TchekBtn from './tchek-button';

export interface EventDetailValuationBlockProps {
    event: {
        informations: {
            identifier: number;
        };
        partExchange: {
            id: number;
            liveInspection: string | null;
            repairCosts: number | null;
            finalOffer: number | null;
            canExpertise: boolean;
            canGenerateContract: boolean;
            canGenerateCerfa: boolean;
            canSendContract: boolean;
            canSendCerfa: boolean;
            registration: string;
        }[];
    };
    typeSlug: string;
    reload: (all?: boolean) => void;
}

const EventDetailValuationBlock = ({ event, typeSlug, reload }: EventDetailValuationBlockProps) => {
    const { hasPermission } = usePermissions();
    const editGranted = hasPermission('LEAD_VALUATION_EDIT');
    const generateGranted = hasPermission('LEAD_VALUATION_GENERATE');

    const partExchange = event.partExchange[0];
    if (!partExchange) {
        return null;
    }

    const dispatch = useAppDispatch();

    const toggleEditModal = () => {
        dispatch(
            actions.modalChange('valuation.edit', {
                partExchange,
                reload: () => reload(),
            }),
        );
    };

    const toggleContractModal = () => {
        dispatch(
            actions.modalChange('valuation.contract', {
                lead: event.informations,
                reload: () => reload(true),
            }),
        );
    };

    const toggleCerfaModal = () => {
        dispatch(
            actions.modalChange('valuation.cerfa', {
                lead: event.informations,
                reload: () => reload(),
            }),
        );
    };

    let content = null;

    if (partExchange.canExpertise) {
        content = (
            <>
                <div className="d-flex mb-2">
                    <div className="mr-4 w-50 d-flex">
                        {partExchange.liveInspection && (
                            <a
                                href={partExchange.liveInspection}
                                className="btn btn-outline-primary mr-2"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.BTN_LIVE_INSPECTOR" />
                            </a>
                        )}
                        <TchekBtn partExchange={partExchange} />
                    </div>
                    <div className="ml-4 w-50">
                        {generateGranted && (
                            <>
                                <Button
                                    variant="primary"
                                    disabled={!partExchange.canGenerateContract && !partExchange.canSendContract}
                                    onClick={() => toggleContractModal()}
                                >
                                    <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.BTN_GENERATE_CONTRACT" />
                                </Button>
                                <Button
                                    variant="primary"
                                    className="ml-2"
                                    disabled={!partExchange.canGenerateCerfa && !partExchange.canSendCerfa}
                                    onClick={() => toggleCerfaModal()}
                                >
                                    <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.BTN_GENERATE_CERFA" />
                                </Button>
                            </>
                        )}
                    </div>
                </div>
                <div className="d-flex">
                    <div className="mt-4 border-bottom w-50 mr-4">
                        <div className="d-flex justify-content-between">
                            <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.RECONDITIONING_AMOUNT" />
                            <NumberFormat
                                value={partExchange.repairCosts}
                                className="font-weight-bold"
                                thousandSeparator=" "
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale
                                displayType="text"
                                suffix=" €"
                            />
                        </div>
                    </div>
                    <div className="mt-4 border-bottom w-50 ml-4">
                        <div className="d-flex justify-content-between">
                            <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.EXCHANGE_AMOUNT" />
                            <NumberFormat
                                value={partExchange.finalOffer}
                                className="font-weight-bold"
                                thousandSeparator=" "
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale
                                displayType="text"
                                suffix=" €"
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        content = (
            <Alert variant="warning">
                <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.NO_VALUATION" />
            </Alert>
        );
    }

    return (
        <Card className="card--shadowed mb-4">
            <Card.Header className={`event__origin--${typeSlug} position-relative d-flex justify-content-between`}>
                <div>
                    <div className={`event__origin-bg--${typeSlug} pin`}>
                        <i className="la la-award text-white la-lg" />
                    </div>
                    <span className="ml-4">
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.TITLE" />
                    </span>
                </div>
                <div>
                    {editGranted && (
                        <>
                            <Button
                                // @ts-ignore
                                variant="outline-link"
                                className="p-0"
                                onClick={() => toggleEditModal()}
                            >
                                <i className="la la-pen p-0 text-muted" />
                                <u className="ml-2 font-size-sm" />
                            </Button>
                        </>
                    )}
                </div>
            </Card.Header>
            <Card.Body className="position-relative d-flex flex-column">{content}</Card.Body>
        </Card>
    );
};

export default EventDetailValuationBlock;
