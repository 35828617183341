import React from 'react';

import { LayoutContextConsumer } from '../../../../_metronic';
import { Intl } from '../../../../_metronic/i18n/I18nProvider';
import BreadCrumbs from '../../../../_metronic/layout/sub-header/components/BreadCrumbs';
import subHeader from '../../../partials/HoC/subHeader';

function OrderSubHeader(props) {
    const { subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle } = props;

    const listTitle = Intl.formatMessage({ id: 'MENU.SMARTREPAIR.ORDERS' });

    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: 'MENU.APV' }),
        },
        {
            title: Intl.formatMessage({ id: 'MENU.APV.ONLINE_APPOINTEMENT' }),
        },
        {
            title: listTitle,
        },
    ];

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            type="button"
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}

                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">{listTitle}</h3>
                                <BreadCrumbs items={breadcrumb} />
                            </>
                        )}
                    </LayoutContextConsumer>
                </div>
            </div>
        </div>
    );
}

export default subHeader(OrderSubHeader);
