import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';
import { empty } from '@app/helpers/ToolsHelper';

import { getCustomerNote } from '@app/crud/note/note.crud';

import { useAppSelector, useFetch } from '@app/hooks';
import ROUTES from '@app/router/Routes';

import ModalDefault from '@app/partials/content/modals/Modal.default';

import { ChatItemProps } from '@app/components/ChatTimeline/ChatItem';
import ChatTimeline from '@app/components/ChatTimeline/ChatTimeline';

type NotesProps = {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    refresh: () => void;
};

const Notes = ({ showModal, setShowModal, refresh }: NotesProps) => {
    const Intl = useIntl();
    const { params } = useAppSelector((state) => state.modal);
    const [notedData, setNotedData] = useState([]);

    const {
        fetch: fetchNote,
        data: noteData,
        loading,
    } = useFetch({
        fetchAction: getCustomerNote,
        resultInterceptor: (response) => response.result,
    });

    useEffect(() => {
        if (params?.id) fetchNote(params.id);
    }, [params]);

    useEffect(() => {
        setNotedData([noteData]);
        if (noteData.takeable) refresh();
    }, [noteData]);

    const formattedNotes = useMemo(
        () =>
            notedData && !empty(notedData)
                ? notedData?.map(
                      (note) =>
                          ({
                              id: note.id,
                              author: `Accueil`,
                              job: 'Commerce',
                              date: note.created,
                              position: 'left',
                              color: 'primary',
                              message: note.message,
                              noteType: note.noteType || null,
                          } as ChatItemProps),
                  )
                : [],
        [Intl, notedData],
    );

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            icon={<i className="la la-2x text-primary la-edit" />}
            hideModal={() => setShowModal(false)}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.NOTE.TITLE' })}
            body={
                <ChatTimeline messages={formattedNotes} singleMode>
                    {params?.contact?.id && (
                        <Link
                            to={routeTo(ROUTES.CUSTOMER.PATH, {
                                id: params?.contact?.id,
                            })}
                        >
                            <Button className="mt-1" variant="link">
                                <FormattedMessage id="DASHBOARD.CALENDAR.TIMELINE.VIEW_CLIENT_PAGE" />
                            </Button>
                        </Link>
                    )}
                </ChatTimeline>
            }
            footer={
                <div className="text-center">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default Notes;
