import React, {useContext, useEffect, useState} from 'react';
import {Slider} from 'primereact/slider';
import {FiltersContext} from '../FiltersProvider';
import {Row, Col, Form} from 'react-bootstrap';
import NumberFormat from "react-number-format";

type SliderFilterProps = {
    name: string,
    className: string,
    onChange?: Function,
    min: number,
    max: number,
    suffix: string,
    pending: boolean,
    onPendingChange: Function,
    defaultValue?: number | number[]
}

const SliderFilter = (
    {
        name,
        className,
        onChange,
        min,
        max,
        suffix,
        pending,
        onPendingChange,
        defaultValue
    }: SliderFilterProps
) => {
    const filters = useContext(FiltersContext);
    let keyPressTimeOut: null | ReturnType<typeof setTimeout> = null;
    const [value, setValue] = useState(filters[name]?.value?.split?.(',') || [min, max]);

    const handleChange = (value: number[]) => {
        setValue(value || [min, max]);
        const valueFilter = {

            [name]: {suffix, value: value.join(',')}
        }

        if (!pending) {
            onChange({...valueFilter});
        } else {
            onPendingChange({...valueFilter});
        }
    }
    useEffect(() => {
        if (!filters[name] && defaultValue) {
            // @ts-ignore
            handleChange(defaultValue);
        } else if (!filters[name]) {
            setValue([min, max]);
        }

        if (filters[name]?.value) {
            setValue(filters[name]?.value?.split?.(','))
        }
    }, [defaultValue, filters]);

    return (
            <Form.Group as={Row}>
                <Col lg={6} className="d-flex justify-content-around align-items-center">
                    <div className="mr-2 text-gray-light">
                        Entre
                    </div>
                    <NumberFormat
                        className="w-100"
                        value={value[0]}
                        thousandSeparator=" "
                        suffix={suffix}
                        onValueChange={(e) => {
                            clearTimeout(keyPressTimeOut);
                            keyPressTimeOut = setTimeout(() => {
                                if (e.floatValue !== value[0]) {
                                    handleChange([e.floatValue, value[1]]);
                                }
                            }, 100)
                        }}/>
                    <div className="ml-2 mr-2 text-gray-light">
                        et
                    </div>
                    <NumberFormat
                        className="w-100"
                        value={value[1]}
                        thousandSeparator=" "
                        suffix={suffix}
                        onValueChange={(e) => {
                            clearTimeout(keyPressTimeOut);
                            keyPressTimeOut = setTimeout(() => {
                                if (e.floatValue !== value[1]) {
                                    handleChange([value[0], e.floatValue]);
                                }
                            }, 100)
                        }}/>
                </Col>
                <Col lg={6} className="d-flex align-items-center">
                    <Slider
                        min={min}
                        max={max}
                        value={value}
                        className={className}
                        onChange={(e) => setValue(e.value)}
                        // @ts-ignore
                        onSlideEnd={(e) => handleChange(e.value)}
                        range/>
                </Col>
            </Form.Group>
    );
};

SliderFilter.defaultProps = {
    pending: false,
    min: 0,
    max: 100,
    suffix: ""
}

export default SliderFilter;
