import { ErrorMessage } from '@hookform/error-message';
import React from 'react';

type ErrorFormProps = {
    errors: object;
    name: string;
};

export default function ErrorForm({ errors, name }: ErrorFormProps) {
    return (
        <div className="error-form">
            <ErrorMessage errors={errors} name={name} />
        </div>
    );
}
