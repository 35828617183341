import React, {useCallback, useEffect} from "react";
import {Card, Col, Form} from "react-bootstrap";
import {Intl} from "../../../../../_metronic/i18n/I18nProvider";
import CardGeneralInformation from "../card/Card.general.information";
import CardCriteria from "../card/Card.criteria";
import CardDiscount from "../card/Card.discount";
import CardDetail from "../card/Card.detail";
import PictureUpload from "../../../../partials/content/PictureUpload";
import {useFormContext} from "react-hook-form";
import SelectPackage from "../../../../partials/content/Select.package";
import SelectAdditionalService from "../../../../partials/content/Select.additionalService";
import * as dayjs from "dayjs";
import ErrorForm from "../../../../partials/layout/ErrorForm";

export default function FormNational({ operation, packages, additionalServices, children}) {
    const {reset, register, getValues, setValue, watch, errors} = useFormContext();
    const associationType = watch('associationType');

    const getAssociationType = useCallback(
        () => {
            let associationTypeValue = '';

            if (!operation || operation?.package?.id) {
                associationTypeValue = 'packageAssociation';
            } else if (operation?.additionalService?.id) {
                associationTypeValue = 'additionalServiceAssociation';
            } else {
                associationTypeValue = 'otherLinkAssociation';
            }

           return associationTypeValue;
        },
        [operation],
    );

    useEffect(() => {
        reset({
            packageId: operation?.package?.id || '',
            additionalServiceId: operation?.additionalService?.id || '',
            associationType: getAssociationType(),
            name: operation?.name || '',
            active: operation?.active || false,
            dateStart: operation && operation.dateStart ? dayjs(operation.dateStart).toDate() : null,
            dateEnd: operation && operation.dateEnd ? dayjs(operation.dateEnd).toDate() : null,
            discount: operation ? operation.percentage ? 'percentageDiscount' : operation.price ? 'priceDiscount' : 'noDiscount' : 'noDiscount',
            percentage: operation?.percentage || '',
            price: operation?.price || '',
            seoDescription: operation?.seoDescription || '',
            seoUrl: operation?.seoUrl || '',
        });
    }, [reset, getAssociationType, operation]);

    useEffect(() => {
        if (associationType === 'otherLinkAssociation') {
            setValue('packageId', '');
            setValue('additionalServiceId', '');
        }
    }, [setValue, associationType])

    return (
        <div>
            <Form.Row>
                <Col lg={3}>
                    <Form.Group>
                        <Form.Check
                            ref={register}
                            type="radio"
                            label={Intl.formatMessage({ id: "APV.OPERATION.FORM.LINK.TYPE.PACKAGE" })}
                            name="associationType"
                            id="packageAssociation"
                            value="packageAssociation"
                        />
                    </Form.Group>
                    <Form.Group>
                        <SelectPackage
                            packages={packages}
                            packageId={getValues('packageId')}
                            validate={associationType === 'packageAssociation'}
                            disabled={associationType !== 'packageAssociation'}
                        />
                        <ErrorForm errors={errors} name="packageId" />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>&nbsp;</Form.Group>
                    <Form.Group bsPrefix="text-center">
                        {Intl.formatMessage({ id: "APV.OPERATION.FORM.LINK.TYPE.OR" })}
                    </Form.Group>
                </Col>
                <Col lg={3}>
                    <Form.Group>
                        <Form.Check
                            ref={register}
                            type="radio"
                            label={Intl.formatMessage({ id: "APV.OPERATION.FORM.LINK.TYPE.PRODUCT" })}
                            name="associationType"
                            id="additionalServiceAssociation"
                            value="additionalServiceAssociation"
                        />
                    </Form.Group>
                    <Form.Group>
                        <SelectAdditionalService
                            additionalServices={additionalServices}
                            additionalServiceId={getValues('additionalServiceId')}
                            validate={associationType === 'additionalServiceAssociation'}
                            disabled={associationType !== 'additionalServiceAssociation'}
                        />
                        <ErrorForm errors={errors} name="additionalServiceId" />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>&nbsp;</Form.Group>
                    <Form.Group bsPrefix="text-center">
                        {Intl.formatMessage({ id: "APV.OPERATION.FORM.LINK.TYPE.OR" })}
                    </Form.Group>
                </Col>
                <Col lg={3}>
                    <Form.Group>
                        <Form.Check
                            ref={register}
                            type="radio"
                            label={Intl.formatMessage({ id: "APV.OPERATION.FORM.LINK.TYPE.LINK" })}
                            name="associationType"
                            id="otherLinkAssociation"
                            value="otherLinkAssociation"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control name="seoUrl"
                                      disabled={associationType !== 'otherLinkAssociation'}
                                      ref={register({
                                          register: associationType === 'otherLinkAssociation'
                                              ? Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                              : false
                                      })}
                                      type="text"/>
                        <ErrorForm errors={errors} name="seoUrl" />
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row className="mb-4">
                <Col lg={4}>
                    <CardGeneralInformation />
                </Col>
                <Col lg={4}>
                    <CardCriteria />
                </Col>
                <Col lg={4}>
                    <CardDiscount associationType={associationType} />
                </Col>
            </Form.Row>
            <Form.Row>
                <Col lg={4}>
                    <CardDetail />
                </Col>
                <Col>
                    <Card className="shadow-sm">
                        <Card.Header>
                            <i className="card__icon fas fa-image"/>
                            {Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.VISUALS.TITLE' })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <Col lg={6} className="border-right">
                                    <span className="text-center">
                                        <h6>{Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.VISUALS.SMALL.HOME.PAGE.BANNER' })}</h6>
                                        <p className="font-weight-light">
                                            {Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.VISUALS.COMPUTER' })} : 500 X 250 px
                                        </p>
                                    </span>
                                    <PictureUpload name="bannerHomeMedium" required={false} path={operation?.bannerHomeMedium}/>
                                    <ErrorForm errors={errors} name="bannerHomeMedium" />
                                </Col>
                                <Col lg={6}>
                                    <span className="text-center">
                                        <h6>{Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.VISUALS.SEARCH.PAGE.BANNER' })}</h6>
                                        <p className="font-weight-light">
                                            {Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.VISUALS.COMPUTER' })} : 898 X 314 px
                                        </p>
                                    </span>
                                    <PictureUpload name="bannerSearch" required={false} path={operation?.bannerSearch}/>
                                    <ErrorForm errors={errors} name="bannerSearch" />
                                    <span className="text-center">
                                        <p className="font-weight-light">
                                            {Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.VISUALS.MOBILE' })} : 500 X 250 px
                                        </p>
                                    </span>
                                    <PictureUpload name="bannerSearchMobile" required={false} path={operation?.bannerSearchMobile}/>
                                    <ErrorForm errors={errors} name="bannerSearchMobile" />
                                </Col>
                            </Form.Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Form.Row>
            {children}
        </div>
    )
}
