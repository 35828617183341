import React from 'react';
import {useHistory} from "react-router-dom";
import {Portlet} from "../content/Portlet";
import {Tabs} from "react-bootstrap";

const PortletTab = (
    {
        onClick,
        redirects,
        defaultActiveKey,
        activeKey,
        id,
        children
    }) => {
    const history = useHistory();
    const redirect = (element) => {
        if (typeof onClick === 'function') {
            onClick(element);
        }

        if(typeof redirects !== 'undefined'){
            const uri = redirects[element];
            if(typeof uri !== 'undefined'){
                history.push(uri);
            }
        }
    }

    return (
        <Portlet className="portlet-tab">
            <Tabs
                className="nav-tabs-line nav-tabs-line-primary nav-tabs-line-2x"
                defaultActiveKey={defaultActiveKey}
                activeKey={activeKey}
                id={id}
                onSelect={(e) => redirect(e)}
            >
                {children}
            </Tabs>
        </Portlet>
    );
}

export default PortletTab;
