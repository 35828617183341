import { isEmpty } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { updateLeadPartExchangeValuation } from '@app/crud/customers/customer.crud';

import { useAppSelector } from '@app/hooks';

import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

export interface ModalEditParams {
    partExchange: {
        id: number;
        liveInspection: string | null;
        repairCosts: number | null;
        finalOffer: number | null;
        canExpertise: boolean;
        canGenerateContract: boolean;
        canGenerateCerfa: boolean;
    };
    reload: () => void;
}

type FormValues = {
    repairCosts: number;
    finalOffer: number;
};

type ModalEditProps = {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
};

const ModalEdit = ({ showModal, setShowModal }: ModalEditProps) => {
    const { params } = useAppSelector((state) => state.modal);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormValues>({
        defaultValues: {
            repairCosts: params?.partExchange?.repairCosts,
            finalOffer: params?.partExchange?.finalOffer,
        },
    });

    const Intl = useIntl();

    const [updating, setUpdating] = useState(false);

    const onSubmit: SubmitHandler<FormValues> = (values) => {
        if (updating) {
            return;
        }

        setUpdating(true);

        updateLeadPartExchangeValuation(params.partExchange.id, values)
            .then(() => {
                setUpdating(false);
                setShowModal(false);
                params.reload();
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.EDIT_MODAL.SUCCESS' }),
                });
            })
            .catch(() => {
                setUpdating(false);
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.EDIT_MODAL.ERROR' }),
                });
            });
    };

    useEffect(() => {
        reset({
            repairCosts: params?.partExchange?.repairCosts,
            finalOffer: params?.partExchange?.finalOffer,
        });
    }, [params?.partExchange?.repairCosts, params?.partExchange?.finalOffer]);

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x la-award text-primary" />}
            title={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.EDIT_MODAL.TITLE' })}
            body={
                <>
                    {updating && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className={errors.repairCosts !== undefined ? 'is-invalid' : ''}>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.EDIT_MODAL.REPAIR_COSTS" /> :
                            </Form.Label>
                            <Form.Control
                                type="number"
                                name="repairCosts"
                                // @ts-ignore
                                ref={register({
                                    validate: (value) => {
                                        if (isEmpty(value)) {
                                            return Intl.formatMessage({
                                                id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.EDIT_MODAL.ERROR.REQUIRED',
                                            });
                                        }
                                        if (value < 0) {
                                            return Intl.formatMessage({
                                                id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.EDIT_MODAL.ERROR.NEGATIVE',
                                            });
                                        }

                                        return true;
                                    },
                                })}
                            />
                            <ErrorForm errors={errors} name="repairCosts" />
                        </Form.Group>
                        <Form.Group className={errors.finalOffer !== undefined ? 'is-invalid' : ''}>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.EDIT_MODAL.FINAL_OFFER" /> :
                            </Form.Label>
                            <Form.Control
                                type="number"
                                name="finalOffer"
                                // @ts-ignore
                                ref={register({
                                    validate: (value) => {
                                        if (value === '' || value === undefined) {
                                            return Intl.formatMessage({
                                                id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.EDIT_MODAL.ERROR.REQUIRED',
                                            });
                                        }
                                        if (value < 0) {
                                            return Intl.formatMessage({
                                                id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.EDIT_MODAL.ERROR.NEGATIVE',
                                            });
                                        }

                                        return true;
                                    },
                                })}
                            />
                            <ErrorForm errors={errors} name="finalOffer" />
                        </Form.Group>
                    </Form>
                </>
            }
            footer={
                <div className="d-flex justify-content-end">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.EDIT_MODAL.BTN_CANCEL" />
                    </Button>
                    <Button variant="primary" className="ml-2" onClick={handleSubmit(onSubmit)}>
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.EDIT_MODAL.BTN_VALIDATE" />
                    </Button>
                </div>
            }
        />
    );
};

export default ModalEdit;
