import classNames from 'classnames';
import { Column } from 'primereact/column';
import React, { useEffect, useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import HoCDataTable from '@app/partials/content/HoCDataTable';
import Loader from '@app/partials/content/Loader';

import CampaignSynthesisGroupHeader from './CampaignSynthesisGroupHeader';

const CampaignSynthesis = ({ campaign, statistics, className, loading }) => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [synthesisDetails, setSynthesisDetails] = useState([]);

    useEffect(() => {
        setSynthesisDetails(
            Object.keys(statistics?.dealerships || {})?.reduce?.((result, dealershipId) => {
                const dealership = statistics?.dealerships?.[dealershipId];
                if (Object.keys(dealership?.dealers || {})?.length > 0) {
                    Object.keys(dealership?.dealers || {})?.forEach?.((dealerId) => {
                        const dealer = dealership?.dealers?.[dealerId];
                        dealer.dealership = {
                            name: dealership?.name ?? campaign?.dealerships?.[dealershipId] ?? dealershipId,
                        };
                        result.push(dealer);
                    });
                } else {
                    result.push({
                        ...statistics?.dealerships?.[dealershipId],
                        isDealership: true,
                        dealership: { name: dealership?.name ?? campaign?.dealerships?.[dealershipId] ?? dealershipId },
                        dealershipId,
                        dealershipName: dealership?.name ?? campaign?.dealerships?.[dealershipId] ?? dealershipId,
                    });
                }
                return result;
            }, []),
        );
    }, [statistics]);

    const groups = useMemo(
        () =>
            synthesisDetails?.reduce?.((acc, user) => {
                if (!acc?.[user?.dealership?.name]) {
                    acc[user?.dealership?.name] = [];
                }

                acc[user?.dealership?.name].push(user);

                return acc;
            }, {}),
        [synthesisDetails],
    );

    const headerTemplate = (data) => {
        const users = data?.isDealership ? null : groups[data?.dealership?.name];
        const dealershipId = data?.isDealership
            ? data.dealershipId
            : Object.keys(statistics?.dealerships || {})?.find?.(
                  (dId) => statistics?.dealerships?.[dId]?.name === data?.dealership?.name,
              );
        const dealership = {
            ...statistics?.dealerships?.[dealershipId],
            name: data?.isDealership ? data.dealershipName : data?.dealership?.name,
            isDealership: data?.isDealership,
        };

        return (
            <CampaignSynthesisGroupHeader
                expandedRows={expandedRows}
                setExpandedRows={setExpandedRows}
                users={users}
                dealership={dealership}
            />
        );
    };

    const userBodyTemplate = (user) => (
        <div className="kt-shape-font-color-4">{`${user?.firstname || '-'} ${user?.lastname || '-'}`}</div>
    );

    const counterBodyTemplate = (user, counterName, totalName, color) => {
        const counter = user?.[counterName];
        const total = user?.[totalName] || 1;
        const percentage = ((counter || 0) / total) * 100;

        return (
            <div
                className={classNames('px-6 font-weight-bold d-flex justify-content-between align-items-center', color)}
            >
                <div>{counter}</div>
                <div>{!!(percentage && counter) && `${percentage.toFixed(1)} %`}</div>
            </div>
        );
    };

    const cols = [
        {
            field: 'dealerships',
            header: Intl.formatMessage({ id: 'CAMPAIGN.SYNTHESIS.DEALERSHIPS' }),
            body: userBodyTemplate,
            headerClassName: 'kt-shape-font-color-4 font-weight-semibold',
        },
        {
            field: 'contacts',
            header: Intl.formatHTMLMessage({ id: 'CAMPAIGN.SYNTHESIS.SALESFORCE_CONTACTS' }),
            body: (user) => counterBodyTemplate(user, 'contacts', 'dealershipContacts', 'kt-shape-font-color-4'),
            headerClassName: 'px-4 text-right kt-shape-font-color-4 font-weight-semibold',
            style: { width: '20%' },
        },
        {
            field: 'leads',
            header: Intl.formatMessage({ id: 'CAMPAIGN.SYNTHESIS.CREATED_LEAD' }),
            body: (user) => counterBodyTemplate(user, 'leads', 'dealershipLeads', 'text-success'),
            headerClassName: 'px-4 text-right text-success font-weight-semibold',
            style: { width: '20%' },
        },
        {
            field: 'bloctelKo',
            header: (
                <span
                    dangerouslySetInnerHTML={{
                        __html: Intl.formatMessage({ id: 'CAMPAIGN.SYNTHESIS.BLOCKED_CONTACTS' }),
                    }}
                />
            ),
            body: (user) => counterBodyTemplate(user, 'bloctelKo', 'dealershipBloctelKo', 'text-danger'),
            headerClassName: 'px-4 text-right text-danger font-weight-semibold',
            style: { width: '20%' },
        },
        {
            field: 'ignoredContacts',
            header: (
                <span
                    dangerouslySetInnerHTML={{
                        __html: Intl.formatMessage({ id: 'CAMPAIGN.SYNTHESIS.IGNORED_CONTACTS' }),
                    }}
                />
            ),
            body: (user) => counterBodyTemplate(user, 'ignoredContacts', 'contacts', 'text-gray'),
            headerClassName: 'px-4 text-right text-gray font-weight-semibold',
            style: { width: '20%' },
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <Card className={classNames('card--shadowed', className)} style={{ minHeight: '5rem' }}>
            <Card.Header className="text-closed position-relative">
                <div className="bg-closed pin">
                    <i className="la la-newspaper text-white la-lg" />
                </div>
                <span className="ml-5 font-weight-semibold">
                    <FormattedMessage id="CAMPAIGN.SYNTHESIS.TITLE" />
                </span>
            </Card.Header>
            <Card.Body>
                {loading ? (
                    <Loader style={{ width: '5rem', height: '5rem' }} wrapperStyle={{ marginTop: '-15px' }} overlay />
                ) : (
                    <>
                        <div className="mt-2 d-flex justify-content-between">
                            <div className="pr-4 pl-0 flex-grow-1 flex-basis-0">
                                <div className="campaign-card kt-bg-light-secondary kt-shape-font-color-4">
                                    <div className="d-flex justify-content-between align-items-end">
                                        <div>
                                            <div className="mt-1 font-size-h1 font-weight-bold">
                                                {statistics?.globalContact || 0}
                                            </div>
                                            <div className="font-size-h4 font-weight-bold">100&nbsp;%</div>
                                        </div>
                                        <span
                                            className="text-right font-size-lg font-weight-semibold"
                                            dangerouslySetInnerHTML={{
                                                __html: Intl.formatMessage({
                                                    id: 'CAMPAIGN.SYNTHESIS.SALESFORCE_CONTACTS',
                                                }).replace(/\s+/g, '<br>'),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 flex-grow-1 flex-basis-0">
                                <div className="campaign-card kt-bg-light-success text-success">
                                    <div className="d-flex justify-content-between align-items-end">
                                        <div>
                                            <div className="mt-1 font-size-h1 font-weight-bold">
                                                {statistics?.leads || 0}
                                            </div>
                                            <div className="font-size-h4 font-weight-bold">
                                                {(
                                                    ((statistics?.leads || 0) / (statistics?.globalContact || 1)) *
                                                    100
                                                ).toFixed(1) || '-'}
                                                &nbsp;%
                                            </div>
                                        </div>
                                        <span
                                            className="text-right font-size-lg font-weight-semibold"
                                            dangerouslySetInnerHTML={{
                                                __html: Intl.formatMessage({
                                                    id: 'CAMPAIGN.SYNTHESIS.CREATED_LEAD',
                                                }).replace(/\s+/g, '<br>'),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="pl-4 pr-0 flex-grow-1 flex-basis-0">
                                <div className="campaign-card kt-bg-light-danger text-danger">
                                    <div className="d-flex justify-content-between align-items-end">
                                        <div>
                                            <div className="mt-1 font-size-h1 font-weight-bold">
                                                {statistics?.bloctelKo || 0}
                                            </div>
                                            <div className="font-size-h4 font-weight-bold">
                                                {(
                                                    ((statistics?.bloctelKo || 0) / (statistics?.globalContact || 1)) *
                                                    100
                                                ).toFixed(1) || '-'}
                                                &nbsp;%
                                            </div>
                                        </div>
                                        <span
                                            className="text-right font-size-lg font-weight-semibold"
                                            dangerouslySetInnerHTML={{
                                                __html: Intl.formatMessage({
                                                    id: 'CAMPAIGN.SYNTHESIS.BLOCKED_CONTACTS',
                                                }).replace(/\s+/g, '<br>'),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="pl-4 pr-0 flex-grow-1 flex-basis-0">
                                <div className="campaign-card kt-bg-dark-light text-gray">
                                    <div className="d-flex justify-content-between align-items-end">
                                        <div>
                                            <div className="mt-1 font-size-h1 font-weight-bold">
                                                {statistics?.ignoredContact || '0'}
                                            </div>
                                            <div className="font-size-h4 font-weight-bold">
                                                {(
                                                    ((statistics?.ignoredContact || 0) /
                                                        (statistics?.globalContact || 1)) *
                                                    100
                                                ).toFixed(1) || '-'}
                                                &nbsp;%
                                            </div>
                                        </div>
                                        <span
                                            className="text-right font-size-lg font-weight-semibold"
                                            dangerouslySetInnerHTML={{
                                                __html: Intl.formatMessage({
                                                    id: 'CAMPAIGN.SYNTHESIS.IGNORED_CONTACTS',
                                                }).replace(/\s+/g, '<br>'),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <HoCDataTable
                            value={synthesisDetails}
                            emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                            autoLayout
                            className="mt-8 p-datatable-summary p-datatable--synthesis"
                            expandedRowGroups
                            rowGroupMode="subheader"
                            groupRowsBy="dealership.name"
                            rowGroupHeaderTemplate={headerTemplate}
                            rowGroupFooterTemplate={() => null}
                            expandableRowGroups
                            expandedRows={expandedRows}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                        >
                            {dynamicColumns}
                        </HoCDataTable>
                    </>
                )}
            </Card.Body>
        </Card>
    );
};

export default CampaignSynthesis;
