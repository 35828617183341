import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Link, useParams, withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';

import { availableLanguages } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';
import useLocales from '@app/hooks/useLocales';

import { alphabeticalSort } from '@app/helpers/ArrayHelper';
import { routeTo } from '@app/helpers/RoutesHelper';

import {
    deleteActionEmailType,
    getActionEmailCompagnies,
    getActionEmailType,
    getBrands,
} from '@app/crud/administration/action-email-type';

import ROUTES from '@app/router/Routes';

import Editor, { ALL_BUTTONS } from '@app/partials/content/Editor';
import { JoditButton, JoditCollection, JoditPopup } from '@app/partials/content/Jodit';
import Loader from '@app/partials/content/Loader';
import SwitchInput from '@app/partials/content/SwitchInput';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

import { formSubmit } from './Form/form.submit';
import EMAIL_TAGS, { formatEmailTag, getEmailLabel } from './Helpers/email-tags';

function ActionEmailType({ history }) {
    const Intl = useIntl();
    const refEditor = useRef();
    const { reset, register, handleSubmit, control, errors } = useFormContext();
    const params = useParams();
    const { locales, getLocaleByKey } = useLocales();
    const [brands, setBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const isCreate = !params?.id;
    const {
        fetch: fetchActionEmailType,
        data: actionEmailType,
        error: errorActionEmailType,
        loading: loadingActionEmailType,
    } = useFetch({
        fetchAction: getActionEmailType,
    });
    const {
        fetch: fetchActionEmailCompagnies,
        data: actionEmailCompagnies,
        error: errorActionEmailCompagnies,
    } = useFetch({
        fetchAction: getActionEmailCompagnies,
    });

    const onSubmit = (data) => {
        data.brands = brands
            .filter((item) => selectedBrands.some((brand) => item.slugName === brand))
            .map((filteredBrand) => ({ name: filteredBrand.name, slug: filteredBrand.slugName }));

        formSubmit({
            isCreate,
            data,
            history,
            id: params?.id,
        });
    };

    const handleDelete = () => {
        if (actionEmailType?.id) {
            Swal.fire({
                title: Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.REMOVE.TITLE' }),
                text: Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.REMOVE.TEXT' }),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
                cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
                customClass: {
                    confirmButton: 'btn mr-3 btn-primary',
                    cancelButton: 'btn btn-secondary',
                },
                buttonsStyling: false,
                preConfirm: () => {
                    Swal.showLoading();
                    return deleteActionEmailType(actionEmailType.id)
                        .then(() => {
                            history.replace(routeTo(ROUTES.EMAIL_TEMPLATES.PATH));
                            toast({
                                variant: 'success',
                                message: Intl.formatMessage({
                                    id: 'ADMINISTRATION.EMAIL_TEMPLATE.VIEW.REMOVE.SUCCESS',
                                }),
                            });
                        })
                        .catch(() => {
                            toast({
                                variant: 'danger',
                                message: Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.VIEW.REMOVE.ERROR' }),
                            });
                        });
                },
            });
        }
    };

    useEffect(() => {
        if (params?.id) {
            fetchActionEmailType(params.id);
        }
        fetchActionEmailCompagnies();
    }, [fetchActionEmailType, fetchActionEmailCompagnies]);

    useEffect(() => {
        getBrands().then((values) => {
            setBrands(values);
            setSelectedBrands(actionEmailType?.brands?.map((brand) => brand.slug) || []);
        });
    }, [actionEmailType]);

    useEffect(() => {
        if (errorActionEmailType || errorActionEmailCompagnies) {
            history.replace(routeTo(ROUTES.EMAIL_TEMPLATES.PATH));
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    }, [errorActionEmailType, errorActionEmailCompagnies]);

    useEffect(() => {
        reset({
            ...actionEmailType,
            actionEmailCompagnies: actionEmailType?.actionEmailCompagnies
                ? actionEmailType?.actionEmailCompagnies.map((emailTemplateCompagnie) => emailTemplateCompagnie.slug)
                : [],
            brands: selectedBrands,
        });
    }, [actionEmailType, reset]);

    return (
        <Form className="package" onSubmit={handleSubmit(onSubmit)}>
            {loadingActionEmailType && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <Row className="mb-4">
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-info-circle" />
                            {Intl.formatMessage({ id: 'APV.DEALERSHIP.INFORMATIONS' })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Group>
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.VIEW.LOCALE' })}
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="locale"
                                    defaultValue={null}
                                    render={(props) => (
                                        <Dropdown
                                            value={getLocaleByKey(props.value)}
                                            onChange={(e) => {
                                                props.onChange(e?.value?.key);
                                            }}
                                            options={
                                                locales
                                                    ? Object.values(locales).filter((elm) =>
                                                          availableLanguages.includes(elm.key),
                                                      )
                                                    : []
                                            }
                                            valueTemplate={(value) => value?.name || ''}
                                            showClear
                                            optionLabel="name"
                                        />
                                    )}
                                />
                            </Form.Group>
                            <Form.Group controlId="compagnies">
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.VIEW.COMPAGNIES' })}
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="actionEmailCompagnies"
                                    render={(props) => (
                                        <MultiSelect
                                            display="chip"
                                            optionLabel="name"
                                            optionValue="slug"
                                            value={actionEmailCompagnies?.length > 0 ? props.value : []}
                                            options={actionEmailCompagnies || []}
                                            onChange={(e) => props.onChange(e.value)}
                                        />
                                    )}
                                />
                            </Form.Group>
                            <Form.Group controlId="brands">
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.VIEW.BRANDS' })}
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="brands"
                                    render={(props) => (
                                        <MultiSelect
                                            display="chip"
                                            optionLabel="name"
                                            optionValue="slugName"
                                            filter
                                            value={selectedBrands}
                                            options={
                                                brands &&
                                                Object.entries(brands)
                                                    .sort(alphabeticalSort('slugName'))
                                                    .map(([code, brand]) => ({
                                                        ...brand,
                                                        code,
                                                    }))
                                            }
                                            onChange={(e) => {
                                                setSelectedBrands(e.value);
                                                return props.onChange(e.value);
                                            }}
                                        />
                                    )}
                                />
                            </Form.Group>
                            <Form.Group controlId="name">
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.VIEW.NAME' })}
                                </Form.Label>
                                <Form.Control
                                    name="name"
                                    type="text"
                                    className={`${errors.name ? 'is-invalid' : ''}`}
                                    ref={register({
                                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    })}
                                />
                                <ErrorForm errors={errors} name="name" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.VIEW.IS_ACTIVE' })}
                                </Form.Label>
                                <SwitchInput name="isActive" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.VIEW.VALUE' })}
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="value"
                                    rules={{
                                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    }}
                                    render={(props) => (
                                        <Editor
                                            ref={refEditor}
                                            value={props.value}
                                            onChange={props.onChange}
                                            config={{
                                                buttons: [
                                                    {
                                                        icon: 'tags',
                                                        tooltip: 'Ajouter un tag',
                                                        popup: (editor, _current, _self, close) =>
                                                            JoditPopup(
                                                                editor,
                                                                <JoditCollection>
                                                                    {Object.entries(EMAIL_TAGS).map(
                                                                        ([tag, emailTag], idx) => (
                                                                            <JoditButton
                                                                                // eslint-disable-next-line react/no-array-index-key
                                                                                key={idx}
                                                                                onClick={() => {
                                                                                    const current = editor.s.current();
                                                                                    if (
                                                                                        current?.parentNode?.classList
                                                                                    ) {
                                                                                        if (
                                                                                            current.parentNode.classList.contains(
                                                                                                'email-tag',
                                                                                            )
                                                                                        ) {
                                                                                            editor.s.setCursorAfter(
                                                                                                current.parentNode,
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                    const label = getEmailLabel(
                                                                                        emailTag?.label,
                                                                                    );
                                                                                    editor.s.insertHTML(
                                                                                        formatEmailTag(
                                                                                            tag,
                                                                                            label,
                                                                                            label,
                                                                                        ),
                                                                                    );
                                                                                    close();
                                                                                }}
                                                                            >
                                                                                {emailTag.label}
                                                                            </JoditButton>
                                                                        ),
                                                                    )}
                                                                </JoditCollection>,
                                                            ),
                                                    },
                                                    ...ALL_BUTTONS,
                                                ],
                                                extraIcons: {
                                                    tags: '<i class="las la-hashtag text-secondary"></i>',
                                                },
                                            }}
                                        />
                                    )}
                                />
                                <ErrorForm errors={errors} name="value" />
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Card>
                <Row className="m-6">
                    <Col className={isCreate ? 'text-center' : 'text-right'}>
                        <Link to="/administration/email-templates">
                            <Button variant="outline-primary">{Intl.formatMessage({ id: 'TRANSLATOR.BACK' })}</Button>
                        </Link>
                        <Button variant="primary" className="ml-2" type="submit">
                            {Intl.formatMessage({ id: 'TRANSLATOR.SAVE' })}
                        </Button>
                    </Col>
                    {!isCreate && (
                        <Col className="text-right">
                            <Button variant="outline-danger" onClick={handleDelete}>
                                {Intl.formatMessage({ id: 'TRANSLATOR.REMOVE' })}
                            </Button>
                        </Col>
                    )}
                </Row>
            </Card>
        </Form>
    );
}

export default withRouter(ActionEmailType);
