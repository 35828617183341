import objectPath from 'object-path';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as builder from '../../../_metronic/ducks/builder';

const mapStateToProps = (store) => ({
    config: store.builder.layoutConfig,
    menuConfig: store.builder.menuConfig,
    subheaderMobileToggle: objectPath.get(store.builder.layoutConfig, 'subheader.mobile-toggle'),
    subheaderCssClasses: builder.selectors.getClasses(store, {
        path: 'subheader',
        toString: true,
    }),
    subheaderContainerCssClasses: builder.selectors.getClasses(store, {
        path: 'subheader_container',
        toString: true,
    }),
});

const subHeader = (WrappedComponent) =>
    withRouter(connect(mapStateToProps)((props) => <WrappedComponent {...props} />));

export default subHeader;
