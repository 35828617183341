import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '@app/hooks';

import DashboardAgendaTabs from './DashboardAgendaTabs';

interface DashboardAgendaProps {
    className?: string;
    picture?: boolean;
}

const DashboardAgenda = ({ className, picture }: DashboardAgendaProps) => {
    const { userData } = useAppSelector((state) => state.auth);

    return (
        <div className={classNames('dashboard-agenda', className)}>
            <div className="font-size-lg font-weight-bold d-flex align-items-center">
                <i className="dashboard-agenda__icon las la-2x la-calendar mr-2" />
                <FormattedMessage id="DASHBOARD.CALENDAR.TITLE" />
            </div>
            <DashboardAgendaTabs picture={picture} userData={userData} />
        </div>
    );
};

DashboardAgenda.defaultProps = {
    className: null,
    picture: null,
};

export default DashboardAgenda;
