/**
 *
 * @param json
 * @returns {boolean}
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const IsValidJson = (json: string): boolean => {
    try {
        JSON.parse(json);
    } catch (e) {
        return false;
    }
    return true;
};
