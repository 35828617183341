import { Column } from 'primereact/column';
import React, { useMemo } from 'react';
import { Button, Card, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { alphabeticalSort } from '@app/helpers/ArrayHelper';
import { routeTo } from '@app/helpers/RoutesHelper';

import { getSignatures } from '@app/crud/administration/signature.crud';

import ROUTES from '@app/router/Routes';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import Label from '@app/partials/content/Label';
import MultiSelectFilter from '@app/partials/content/MultiSelectFilter';
import RefreshTable from '@app/partials/content/RefreshTable';
import SelectFilter from '@app/partials/content/SelectFilter';
import SwitchInput from '@app/partials/content/SwitchInput';
import { Filters } from '@app/partials/layout/Filters';

import { formSubmit } from './Form/form.submit';

function Signatures({ history }) {
    const Intl = useIntl();
    const { userData } = useSelector((state) => state.auth);
    const dealerships = useMemo(
        () =>
            Object.entries(userData?.dealerships)
                ?.sort?.(alphabeticalSort('title'))
                ?.map?.(([key, dealership]) => ({
                    ...dealership,
                    refDealershipId: key,
                })),
        [userData],
    );
    const { items, start, filters, sortField, sortOrder, updatePendingFilters, releasePendingFilters } = useFilters({
        name: 'administration_signature',
    });

    const { data, loading, handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: 'administration_signature',
        fetchAction: getSignatures,
        withStore: false,
        paramsInterceptor: (params, tableHandlerFilters) => ({
            ...params,
            page: tableHandlerFilters.page,
            limit: tableHandlerFilters.items,
        }),
    });

    const showAddSignatureTemplate = () => {
        history.push(routeTo(ROUTES.SIGNATURE_TEMPLATE.PATH.CREATE));
    };

    const inputFilter = (name) => <InputFilter name={name} onChange={handleFilter} />;

    const selectFilter = (name, options) => <SelectFilter name={name} options={options} onChange={handleFilter} />;

    const handleActiveChange = (signature) => {
        formSubmit({
            isCreate: false,
            data: {
                isActive: signature.isActive,
            },
            history,
            id: signature.id,
        }).then(() => {
            refresh();
        });
    };

    const selectCompagniesDropdown = () => (
        <MultiSelectFilter
            name="dealerships"
            display="chip"
            optionLabel="title"
            optionValue="refDealershipId"
            options={dealerships}
            pending
            filter
            onChange={handleFilter}
            onPendingChange={updatePendingFilters}
            onRelease={releasePendingFilters}
        />
    );

    const activeBodyTemplate = (signature) => (
        <SwitchInput
            name="isActive"
            form={false}
            onChange={(e) => {
                signature.isActive = e;
                handleActiveChange(signature);
            }}
            labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.NO' })}
            labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.YES' })}
            active={signature?.isActive || false}
        />
    );

    const actionsBodyTemplate = (signature) => (
        <Link to={`/administration/signature-templates/${signature?.id}`}>
            <Button className="p-2 px-3" variant="secondary">
                <i className="fa fa-pen" />
                {Intl.formatMessage({ id: 'TRANSLATOR.UPDATE' })}
            </Button>
        </Link>
    );

    const cols = [
        {
            field: 'name',
            header: Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.TABLE.HEAD.NAME' }),
            style: { width: '10%' },
            filter: true,
            filterElement: inputFilter('name'),
            body: (signature) => signature?.name,
        },
        {
            field: 'dealerships',
            header: Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.TABLE.HEAD.DEALERSHIPS' }),
            style: { width: '15%' },
            filter: true,
            filterElement: selectCompagniesDropdown(),
            body: (signature) =>
                signature?.dealerships &&
                signature?.dealerships.map((item) => (
                    <Label key={item} variant="primary" className="mr-4 my-1">
                        {item}
                    </Label>
                )),
        },
        {
            field: 'isActive',
            header: Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.TABLE.HEAD.IS_ACTIVE' }),
            style: { width: '10%' },
            filter: true,
            filterElement: selectFilter(
                'isActive',
                <>
                    <option value="">
                        {Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.ALL_STATUSES' })}
                    </option>
                    {/* eslint-disable-next-line react/jsx-boolean-value */}
                    <option value={true}>
                        {Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.ACTIVE_STATUS' })}
                    </option>
                    <option value={false}>
                        {Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.INACTIVE_STATUS' })}
                    </option>
                </>,
            ),
            body: activeBodyTemplate,
        },
        {
            field: 'actions',
            header: Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.TABLE.HEAD.ACTIONS' }),
            style: { width: '10%' },
            body: actionsBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <Card>
            <Card.Body>
                <FiltersProvider value={filters}>
                    <Filters>
                        <Row className="justify-content-between">
                            <RefreshTable count={data.count} onClick={refresh} />
                            <Button
                                variant="outline-primary"
                                className="mr-4"
                                onClick={() => showAddSignatureTemplate()}
                            >
                                {Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.CREATE' })}
                            </Button>
                        </Row>
                    </Filters>
                    <HoCDataTable
                        value={data?.result || []}
                        totalRecords={data?.count || 0}
                        lazy
                        loading={loading}
                        paginator
                        onFilter={handleFilter}
                        onPage={handlePagination}
                        onSort={handleSort}
                        paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                        currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                        rows={items}
                        first={start}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        removableSort
                        filterDisplay="row"
                        rowsPerPageOptions={[25, 50, 100]}
                        emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                    >
                        {dynamicColumns}
                    </HoCDataTable>
                </FiltersProvider>
            </Card.Body>
        </Card>
    );
}

export default withRouter(Signatures);
