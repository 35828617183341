import React from 'react';

import useTypologies from '@app/hooks/useTypologies';

type TypologieProps = {
    typologies: string[] | null;
    hasAny?: boolean;
    children: React.ReactNode;
    fallback?: Function;
    interceptor?: Function;
};

const Typologie = ({ typologies, hasAny, children, fallback, interceptor }: TypologieProps) => {
    const { hasAllTypologies, hasAnyTypologies } = useTypologies();
    const hasTypologie = hasAny ? hasAnyTypologies : hasAllTypologies;

    if (typologies && !hasTypologie(typologies)) {
        return fallback ? fallback() : null;
    }

    if (interceptor()) {
        return fallback ? fallback() : null;
    }

    return children;
};

Typologie.defaultProps = {
    hasAny: false,
    fallback: null,
    interceptor: () => false,
};

export default Typologie;
