import produce from 'immer';
import { AnyAction } from 'redux';

export const actionTypes = {
    StartAction: 'START_ACTION',
    StopAction: 'STOP_ACTION',
};

export interface Action {
    id: number;
    action: string;
}

export interface LeadState {
    currentActions: Action[];
}

const initialState: LeadState = {
    currentActions: [
        { id: 1, action: 'test' },
        { id: 2, action: 'test' },
        { id: 3, action: 'test' },
    ],
};

export function leadReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case actionTypes.StartAction: {
            return produce(state, (draftState) => {
                draftState.currentActions.push(action.payload);
            });
        }
        case actionTypes.StopAction: {
            return produce(state, (draftState) => {
                const index = state.currentActions.indexOf(action.payload);
                if (index >= 0) {
                    draftState.currentActions.splice(index, 1);
                }
            });
        }
        default:
            return state;
    }
}

export const actions = {
    startAction: (payload: Action) => ({ type: actionTypes.StartAction, payload }),
    stopAction: (payload: Action) => ({ type: actionTypes.StopAction, payload }),
};
