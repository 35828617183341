import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useLayoutContext} from "../../_metronic";
import useMutationObserver from "./useMutationObserver";

const useFadeOut = () => {
    const fadeOutRef = useRef();
    const [onBottom, setOnBottom] = useState(false);
    const [scrollable, setScrollable] = useState(false);
    const context = useLayoutContext();

    const observer = new ResizeObserver(function() {
        if (fadeOutRef?.current) {
            refresh(fadeOutRef.current);
        }
    });

    const refresh = useCallback((target) => {
        if (!target?.scrollHeight) {
            setOnBottom(true);
            setScrollable(false);
        } else if (target?.scrollHeight === target?.clientHeight) {
            setOnBottom(true);
            setScrollable(false);
        } else {
            const bottom = Math.ceil(target?.scrollHeight - target?.scrollTop) === Math.ceil(target?.clientHeight);
            setOnBottom(bottom);
            setScrollable(true);
        }
    },[]);

    useMutationObserver(fadeOutRef?.current, refresh);

    const handleScroll = useCallback((e) => {
        if (e?.target) {
            refresh(e?.target);
        }
    }, [refresh]);

    useEffect(() => {
        if (fadeOutRef?.current) {
            refresh(fadeOutRef.current);
        }
    }, [context]);

    useEffect(() => {
        if (fadeOutRef?.current) {
            observer.observe(fadeOutRef.current);
        }
    }, []);

    return {
        onBottom,
        scrollable,
        handleScroll,
        refresh,
        fadeOutRef
    };
};

export default useFadeOut;
