import dayjs from 'dayjs';
import { CalendarChangeEvent } from 'primereact/calendar';
import React, { useContext, useEffect, useState } from 'react';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import { DateTimePicker } from './DateTimePicker';
import { FiltersContext } from './FiltersProvider';

interface ChangeEvent {
    [key: string]: {
        value: string;
    };
}

type CalendarFilterProps = {
    onChange: (event: ChangeEvent) => void;
    dateFormat?: string;
    name?: string;
    selectionMode?: 'single' | 'range';
};

// eslint-disable-next-line import/prefer-default-export
export function CalendarFilter({ onChange, dateFormat, name, selectionMode }: CalendarFilterProps) {
    const filters = useContext(FiltersContext);
    const filterValue = filters[name]?.value || null;

    const [value, setValue] = useState<string | Date | Date[]>(
        dayjs(filterValue).isValid() ? dayjs(filterValue, dateFormat).toDate() : null,
    );

    const handleChange = (date: string | Date | Date[]) => {
        if (Array.isArray(date)) {
            const datesValid = date.filter((dateRange) => dayjs(dateRange).isValid());
            setValue(datesValid.length > 0 ? datesValid : null);
        } else {
            setValue(dayjs(date).isValid() ? date : null);
        }
    };

    useEffect(() => {
        if (typeof onChange !== 'undefined') {
            const date = value || null;

            let dateFilter = '';

            if (Array.isArray(date)) {
                dateFilter =
                    date?.length > 0
                        ? date
                              ?.filter((dateRange) => dayjs(dateRange).isValid())
                              .map((dateRange) => dayjs(dateRange).format(dateFormat))
                              .join(',')
                        : null;

                if (date.length === 1) {
                    dateFilter += `,${dayjs(date[0]).format(dateFormat)}`;
                }
            } else {
                dateFilter = dayjs(date).isValid() ? dayjs(date).format(dateFormat) : null;
            }

            onChange({
                [name]: {
                    value: dateFilter,
                },
            });
        }
    }, [value, onChange, dateFormat, name]);

    return (
        <DateTimePicker
            locale={Locale}
            dateFormat="DD/MM/YYYY"
            value={value}
            selectionMode={selectionMode}
            onChange={(e: CalendarChangeEvent) => {
                const date = e?.value || null;
                handleChange(date);
            }}
        />
    );
}

CalendarFilter.defaultProps = {
    name: 'dateSelected',
    dateFormat: 'YYYY-MM-DD',
    selectionMode: 'single',
};
