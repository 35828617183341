/* eslint-disable react/require-default-props */
import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import ErrorForm from '@app/partials/layout/ErrorForm';

type customInputProps = {
    name: string;
    label?: string;
    type?: string;
    validation: object;
    classNameFormGroup?: string;
    placeholder?: string;
    disabled?: boolean;
};

const CustomInput = ({
    name,
    label,
    type = 'text',
    validation,
    classNameFormGroup,
    placeholder,
    disabled,
}: customInputProps) => {
    const { register, errors } = useFormContext();

    return (
        <Form.Group controlId={`form_${name}`} className={classNameFormGroup}>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control
                type={type}
                name={name}
                className={`${errors[name] ? 'is-invalid' : ''}`}
                placeholder={placeholder}
                disabled={disabled}
                ref={register(validation)}
            />
            <ErrorForm errors={errors} name={name} />
        </Form.Group>
    );
};

export default CustomInput;
