import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { getCustomerNote } from '@app/crud/note/note.crud';
import { NoteReader, NoteType } from '@app/crud/note/note.type';

import { ChatMessage } from '@app/partials/content/ChatMessage';
import Loader from '@app/partials/content/Loader';
import toast from '@app/partials/content/Toast';

export type ChatItemProps = {
    id: number;
    author: string;
    job: string;
    message: string;
    date: string;
    position: 'left' | 'right';
    color: 'primary' | 'success' | 'danger';
    singleMode?: boolean;
    takeable: boolean;
    reader: NoteReader;
    noteType: { name: string; slug: NoteType };
    dateRead: string;
    referent: string | null;
};

const ChatItem = ({
    singleMode = false,
    id,
    position,
    color,
    message,
    author,
    date,
    job,
    takeable,
    reader,
    noteType,
    dateRead,
    referent,
}: ChatItemProps) => {
    const Intl = useIntl();
    let chatType = 'dealership';
    const [isLoading, setIsLoading] = useState(false);
    const [note, setNote] = useState<string>(null);

    const getNoteContent = () => {
        setIsLoading(true);
        getCustomerNote(id)
            .then((response) => {
                setNote(response.result.message);

                toast({
                    variant: 'success',
                    message: Intl.formatMessage({
                        id: 'CUSTOMERS.MODAL.NOTES.ACTIONS.TAKEN.SUCCESS',
                    }),
                });
            })
            .catch(() => {
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({
                        id: 'CUSTOMERS.MODAL.NOTES.ACTIONS.TAKEN.ERROR',
                    }),
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    if (!singleMode) {
        chatType = position === 'right' ? 'dealership' : 'call';
    }

    return (
        <div className={`timeline-item align-items-start timeline-item-${chatType}`}>
            <div className={`timeline-label w-${singleMode ? '100' : '50'}`} style={{ fontSize: '13px' }}>
                <div className="font-weight-normal">
                    <p className={`m-0 text-${color}`}>
                        <span className="font-weight-bold">
                            {author} • {job}
                        </span>
                    </p>
                    <p>
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.APV_ACTIVITIES_DETAIL.THE" />{' '}
                        {dayjs(date).format('DD/MM/YYYY [à] HH:mm')} {noteType && ` • ${noteType.name}`}
                        {referent && (
                            <span>
                                {' '}
                                • <FormattedMessage id="CUSTOMER.MODAL.ASSIGN.REFERENT" /> {referent}
                            </span>
                        )}
                        {reader && (
                            <>
                                <br />
                                <div className="font-italic" style={{ fontSize: '10px' }}>
                                    <FormattedMessage id="CUSTOMERS.MODAL.NOTES.READ_BY" /> {reader.firstname}{' '}
                                    {reader.lastname}
                                    <br />
                                    {dayjs(dateRead).format('DD/MM/YYYY [à] HH [h] mm')}
                                </div>
                            </>
                        )}
                    </p>
                </div>
                {isLoading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                {takeable && !note ? (
                    <Button
                        className="p-3 mb-2"
                        onClick={() => {
                            getNoteContent();
                        }}
                        variant="success"
                    >
                        <>
                            <i className="la la-hand-pointer mr-2 pr-0" />
                            <FormattedMessage id="LEADS_ONLINE.ACTION.TAKE" />
                        </>
                    </Button>
                ) : (
                    <ChatMessage message={message || note} arrowPosition="left" variant={color} />
                )}
            </div>
            <div className="timeline-badge timeline-badge__activity">
                <i className={`fa fa-genderless icon-xl text-${color}`} />
            </div>
        </div>
    );
};

export default ChatItem;
