export const getUserAvailabilites = (user) =>
    user?.dayAvailabilities ? Object.values(user.dayAvailabilities)[0] : null;
export const getUserName = (user) => `${user?.information?.firstname} ${user?.information?.lastname}`;
export const isUserHasAvailabilities = (user) => user?.availabilities;

/**
 * Get users presence (here / half-day / absent / not_specified_calendar)
 * @type {*}
 */
export const getPresenceSummary = (users) => {
    const summary = {
        all_day: [],
        half_day: [],
        absent: [],
        not_specified: [],
    };

    return users?.reduce?.((acc, user) => {
        const availabilities = getUserAvailabilites(user);

        if (!availabilities) {
            acc.not_specified.push(user);
        } else if (!availabilities?.am?.absent && !availabilities?.pm?.absent) {
            acc.all_day.push(user);
        } else if (!availabilities?.am?.absent || !availabilities?.pm?.absent) {
            acc.half_day.push(user);
        } else {
            acc.absent.push(user);
        }

        return acc;
    }, summary);
};

/**
 * Get user with no specified availabilities
 *
 * @param users
 * @returns {*}
 */
export const getNotSpecifiedAvailabilities = (users) => users?.filter?.((user) => !isUserHasAvailabilities(user));
