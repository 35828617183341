export const actionTypes = {
    FetchDealerships: 'FETCH_DEALERSHIPS',
    FetchedDealerships: 'FETCHED_DEALERSHIPS',
    FetchDealership: 'FETCH_DEALERSHIP',
    FetchedDealership: 'FETCHED_DEALERSHIP',
    FetchDealershipFailed: 'FETCH_DEALERSHIP_FAILED',
    ClearDealership: 'CLEAR_DEALERSHIP',
    UpdateDealership: 'UPDATE_ADDITIONAL_SERVICE',
    UpdatedDealership: 'UPDATED_ADDITIONAL_SERVICE',
    FetchDealershipsFailed: 'FETCH_DEALERSHIPS_FAILED',
    UpdateDealershipFailed: 'UPDATE_ADDITIONAL_SERVICE_FAILED',
};

const initialState = {
    loading: false,
    dealerships: [],
    totalRecords: 0,
    dealership: null,
    error: null,
};

export function dealershipReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FetchDealerships:
        case actionTypes.FetchDealership:
        case actionTypes.UpdateDealership: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }
        case actionTypes.ClearDealership: {
            return {
                ...state,
                dealership: null,
                loading: false,
                error: null,
            };
        }
        case actionTypes.FetchedDealerships: {
            return {
                ...state,
                loading: false,
                dealerships: action.payload.dealerships,
                totalRecords: action.payload.count,
            };
        }
        case actionTypes.FetchedDealership: {
            return {
                ...state,
                loading: false,
                dealership: action.payload,
            };
        }
        case actionTypes.UpdatedDealership: {
            return { ...state, loading: false, additionalService: action.payload };
        }
        case actionTypes.UpdateDealershipFailed: {
            return { ...state, loading: false };
        }
        case actionTypes.FetchDealershipsFailed:
        case actionTypes.FetchDealershipFailed: {
            return {
                ...state,
                loading: false,
                dealerships: [],
                error: action.payload,
            };
        }
        default:
            return state;
    }
}

export const actions = {
    fetchDealerships: () => ({ type: actionTypes.FetchDealerships }),
    fetchedDealerships: (dealerships) => ({ type: actionTypes.FetchedDealerships, payload: dealerships }),
    fetchDealership: () => ({ type: actionTypes.FetchDealership }),
    fetchedDealership: (dealership) => ({ type: actionTypes.FetchedDealership, payload: dealership }),
    fetchDealershipFailed: (error) => ({ type: actionTypes.FetchDealershipFailed, payload: error }),
    clearDealership: () => ({ type: actionTypes.ClearDealership }),
    updateDealership: () => ({ type: actionTypes.UpdateDealership }),
    updatedDealership: (result) => ({ type: actionTypes.UpdatedDealership, payload: result }),
    fetchDealershipsFailed: (error) => ({ type: actionTypes.FetchDealershipsFailed, payload: error }),
    updateDealershipFailed: (error) => ({ type: actionTypes.UpdateDealershipFailed, payload: error }),
};
