/**
 *
 * Check is order is compatible and calcul all packages selected
 *
 * @param {
 * } acceptedQuotations
 * @returns null || number
 */

// eslint-disable-next-line import/prefer-default-export
export const calculateTotalPrice = (acceptedQuotations) => {
    const allQuotations = Object.values(acceptedQuotations).flat();
    const isCompatible = allQuotations.every((obj) => obj.compatible);

    return isCompatible ? allQuotations.reduce((acc, curr) => acc + curr.priceTtc, 0) : null;
};
