import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { isPublishAutosphere } from '@app/pages/stock/helpers/StockRules';
import StockModalTimeLine from '@app/pages/stock/modals/StockModalTimeLine';

import Label from '@app/partials/content/Label';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';
import { getPublishDays } from '@app/partials/content/Vehicle/VehicleHelpers';

const StockVehicleStockTimeLine = ({ vehicle }) => {
    const [showModal, setShowModal] = useState({});

    const handleShowModal = (show, type = '') => {
        setShowModal(type && show ? { [type]: show } : {});
    };

    return (
        <Card>
            <Card.Header>
                <i className="card__icon la-lg las la-calendar text-dark" />
                <FormattedMessage id="STOCK_VEHICLE.CARD.STOCK.TITLE" />
                {isPublishAutosphere(vehicle) && (
                    <Label variant="success" className="font-weight-bold ml-2">
                        <FormattedMessage id="STOCK_VEHICLES.TABLE.PUBLISHED" />
                        {vehicle?.timeline?.days_last_publication && (
                            <>
                                &nbsp; ({getPublishDays(vehicle)}
                                &nbsp;
                                <FormattedMessage id="STOCK_VEHICLES.TABLE.DAYS" />
                            </>
                        )}
                    </Label>
                )}
            </Card.Header>
            <Card.Body>
                <div className="timeline timeline-hidden-bar timeline-horizontal timeline-horizontal-whole">
                    {vehicle?.timeline?.events &&
                        vehicle?.timeline?.events
                            .reduce((acc, event) => {
                                const previousEventIsFluidity = acc?.[acc?.length - 1]?.type === 'fluidity';
                                const hasSameDate = dayjs(acc?.[acc?.length - 1]?.date || null).isSame(
                                    event?.date || null,
                                );

                                if (previousEventIsFluidity && hasSameDate) {
                                    const popped = acc.pop();
                                    acc.push({
                                        ...event,
                                        label: popped.label,
                                    });
                                    acc.push({
                                        ...popped,
                                        label: event.label,
                                    });
                                } else {
                                    acc.push(event);
                                }

                                return acc;
                            }, [])
                            .map((item) => (
                                <div key={item.date} className="timeline-item">
                                    <div className="timeline-connector" style={{ '--color': item.color }} />
                                    <div
                                        className="timeline-label font-weight-bold font-size-lg"
                                        style={{ color: item.color, marginLeft: '50%' }}
                                    >
                                        {item.label || ''}
                                    </div>
                                    <div className="timeline-badge">
                                        <i className="fa fa-genderless icon-xl" style={{ color: item.color }} />
                                    </div>
                                    <div className="font-weight-normal font-size-lg timeline-content text-center">
                                        {item?.details ? (
                                            <>
                                                <button
                                                    className="btn font-size-lg p-0"
                                                    type="button"
                                                    onClick={() => handleShowModal(true, item?.type)}
                                                >
                                                    <span className="font-weight-bold" style={{ color: item.color }}>
                                                        {item.description}
                                                    </span>
                                                    &nbsp;
                                                    <i className="las la-info-circle" style={{ color: item.color }} />
                                                </button>
                                                <StockModalTimeLine
                                                    events={item?.details}
                                                    show={showModal[item?.type]}
                                                    setShowModal={handleShowModal}
                                                    title={
                                                        <FormattedHTMLMessage
                                                            id={`STOCK_VEHICLE.MODAL.TIMELINE.${item?.type.toUpperCase()}.DETAILS.TITLE`}
                                                            values={{ licenseNumber: vehicle?.license_number }}
                                                        />
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <span className="font-weight-bold" style={{ color: item.color }}>
                                                    {item.description}
                                                </span>
                                                {item?.purchase && (
                                                    <>
                                                        &nbsp;
                                                        <OverlayTooltip
                                                            id="event-stock"
                                                            position="top"
                                                            label="STOCK_VEHICLE.CARD.STOCK.TIMELINE.TOOLTIP"
                                                            paramLabel={{
                                                                description: item?.purchase?.description,
                                                                date: dayjs(item?.purchase?.date).format('DD/MM/YYYY'),
                                                            }}
                                                        >
                                                            <i
                                                                className="las la-info-circle"
                                                                style={{ color: item.color }}
                                                            />
                                                        </OverlayTooltip>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        <br />
                                        {dayjs(item.date).format('DD/MM/YYYY')}
                                    </div>
                                </div>
                            ))}
                </div>
            </Card.Body>
        </Card>
    );
};

export default StockVehicleStockTimeLine;
