import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import useFetch from '@app/hooks/useFetch';

import { getOneCustomerActivities } from '@app/crud/customers/customer.crud';

import Activities from '@app/pages/customers/Modals/Activities';

import Loader from '@app/partials/content/Loader';

import ActivitiesTimeline from './EventDetailActivities/ActivitiesTimeline';

const EventDetailActivities = ({ event, typeSlug }) => {
    const [showActivitiesModal, setShowActivitiesModal] = useState(false);
    const Intl = useIntl();

    const { fetch, data, loading } = useFetch({
        fetchAction: getOneCustomerActivities,
    });

    useEffect(() => {
        fetch(event?.informations?.identifier);
    }, [event]);

    const button = (
        <Button className="primary shadow m-4" onClick={() => setShowActivitiesModal(true)}>
            {Intl.formatMessage({ id: 'TRANSLATOR.SEE_MORE' })}
        </Button>
    );
    const modal = (
        <Activities show={showActivitiesModal} setShowActivitiesModal={setShowActivitiesModal} event={event} />
    );

    return (
        <Card className="card--shadowed">
            <Card.Header className={`event__origin--${typeSlug} position-relative`}>
                <div className={`event__origin-bg--${typeSlug} pin`}>
                    <i className="la la-clock-o text-white la-lg" />
                </div>
                <span className="ml-4">
                    {Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ACTIVITIES_DETAIL.TITLE' })}
                </span>
            </Card.Header>
            <Card.Body className="position-relative">
                {loading ? (
                    <Loader style={{ width: '5rem', height: '5rem' }} overlay />
                ) : (
                    <>
                        <ActivitiesTimeline data={data} event={event} />
                        <div
                            className="position-absolute w-100 text-center pt-12 pb-2"
                            style={{
                                bottom: '0',
                                left: '0',
                                background:
                                    'transparent linear-gradient(180deg, #FFFFFF00 0%, #fff 38%) 0% 0% no-repeat padding-box',
                            }}
                        >
                            {button}
                        </div>
                        {modal}
                    </>
                )}
            </Card.Body>
        </Card>
    );
};

export default EventDetailActivities;
