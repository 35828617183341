import qs from 'qs';
import { generatePath } from 'react-router-dom';

/**
 * Generate path width query parameters;
 *
 * @param path
 * @param params
 * @param query
 * @param anchor
 * @returns {string|*}
 */
// eslint-disable-next-line import/prefer-default-export
export const routeTo = (path, params = null, query = null, anchor = null) => {
    let url = generatePath(path, params);

    if (query) {
        url = `${url}?${qs.stringify(query)}`;
    }

    if (anchor) {
        url = `${url}#${anchor}`;
    }

    return url;
};
