/**
 * Get state color by slug for apvinstore
 *
 * @param slug
 * @returns {string}
 */
export function getStateBySlugApvInStore(slug) {
    let variant = '';

    switch (slug) {
        case 'canceled':
            variant = 'info';
            break;
        case 'confirmed':
            variant = 'success';
            break;
        case 'closed':
            variant = 'secondary';
            break;
        case 'expired':
        default:
            variant = 'secondary';
            break;
    }

    return variant;
}

export const HIDDEN_STATUS = ['expiree', 'annulee', 'rendez_vous_termine'];
