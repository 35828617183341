import ApiGateway, { CustomAxiosResponse, PureGateway } from '@app/helpers/AxiosHelper';

import {
    Alert,
    AlertApiResponse,
    AlertCreatePayload,
    AlertLevel,
    AlertSearchParams,
    AlertUpdatePayload,
} from '@app/pages/administration/alerts/alerts.type';

const { REACT_APP_LEAD_URL } = process.env;

export function showDocument(id: number) {
    return PureGateway.get(`${REACT_APP_LEAD_URL}/document/${id}/download`, {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
        },
    });
}

export function getAdminAlerts(params: AlertSearchParams) {
    return ApiGateway.get<AlertApiResponse, CustomAxiosResponse<AlertApiResponse>>(
        `${REACT_APP_LEAD_URL}/alert_notification`,
        {
            params,
        },
    );
}

export function createOrUpdateAlert(data: AlertCreatePayload | AlertUpdatePayload, id: number) {
    if (!id) return ApiGateway.post(`${REACT_APP_LEAD_URL}/alert_notification/create`, data);

    return ApiGateway.put(`${REACT_APP_LEAD_URL}/alert_notification/update/${id}`, data);
}

export function deleteAlert(id: number) {
    return ApiGateway.delete(`${REACT_APP_LEAD_URL}/alert_notification/delete/${id}`);
}

export function getAlertLevels() {
    return ApiGateway.get<AlertLevel[], CustomAxiosResponse<AlertLevel[]>>(`${REACT_APP_LEAD_URL}/alert_level`);
}

export function getAlerts() {
    return ApiGateway.get<Alert[], CustomAxiosResponse<Alert[]>>(`${REACT_APP_LEAD_URL}/alert_notification/to_display`);
}
