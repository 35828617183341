import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '@app/hooks';
import { getWonModal } from '@app/pages/customers/Helpers/Lead';
import useAction from '@app/pages/customers/Hooks/useAction';
import useLead from '@app/pages/customers/Hooks/useLead';
import { actions } from '@app/store/modal/modal.store';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

const LeadWon = ({ showModal, setShowModal }) => {
    const Intl = useIntl();
    const methods = useForm();
    const { handleSubmit } = methods;
    const { params } = useAppSelector((state) => state.modal);
    const dispatch = useDispatch();
    const { params: listingParams, leadReasons } = useAppSelector((state) => state.customer);
    const lead = useLead(params?.leadId);

    const [loading, createAction] = useAction({
        type: 'close_lead_won',
        success: () => {
            setShowModal(false);
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_WON.ERROR' }),
            });
        },
    });

    const onSubmit = (data) => {
        createAction({
            ...data,
            success: true,
        });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const handleClose = () => {
        dispatch(actions.rollBackModal());
    };

    const WonBody = getWonModal(lead);

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={handleClose}
            title={
                <div className="d-flex px-lg-0 px-5 flex-lg-nowrap flex-wrap mt-6 align-items-center justify-content-center">
                    <Image className="h-100 mr-10" src="/media/pages/customers/vente-reussie.svg" />
                    <div className="mt-lg-0 mt-5 font-size-h3 text-lg-left text-center text-success">
                        <FormattedHTMLMessage id="CUSTOMERS.MODAL.LEAD_WON.TITLE" />
                    </div>
                </div>
            }
            body={
                <FormProvider {...methods}>
                    <WonBody params={params} listingParams={listingParams} leadReasons={leadReasons} lead={lead} />
                </FormProvider>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={handleClose} className="mr-5">
                        <FormattedMessage id="TRANSLATOR.CANCEL" />
                    </Button>
                    <Button variant="primary" disabled={!!params?.event} onClick={handleSubmit(onSubmit, onError)}>
                        <FormattedMessage id="TRANSLATOR.SEND" />
                    </Button>
                </div>
            }
        />
    );
};

export default LeadWon;
