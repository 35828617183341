export const actionTypes = {
    FetchAdditionalServices: 'FETCH_ADDITIONAL_SERVICES',
    AddAdditionalService: 'ADD_ADDITIONAL_SERVICE',
    RemoveAdditionalService: 'REMOVE_ADDITIONAL_SERVICE',
    UpdateAdditionalService: 'UPDATE_ADDITIONAL_SERVICE',
    FetchAdditionalService: 'FETCH_ADDITIONAL_SERVICE',
    FetchedAdditionalServices: 'FETCHED_ADDITIONAL_SERVICES',
    FetchedAdditionalService: 'FETCHED_ADDITIONAL_SERVICE',
    UpdatedAdditionalService: 'UPDATED_ADDITIONAL_SERVICE',
    AddedAdditionalService: 'ADDED_ADDITIONAL_SERVICE',
    FetchAdditionalServicesFailed: 'FETCH_ADDITIONAL_SERVICES_FAILED',
    FetchAdditionalServiceFailed: 'FETCH_ADDITIONAL_SERVICE_FAILED',
    AddAdditionalServiceFailed: 'ADD_ADDITIONAL_SERVICE_FAILED',
    UpdateAdditionalServiceFailed: 'UPDATE_ADDITIONAL_SERVICE_FAILED',
};

const initialState = {
    loading: false,
    additionalServices: [],
    filtersContent: [],
    packages: [],
    additionalServiceCategory: [],
    totalRecords: 0,
    additionalService: null,
    error: null,
};

export function additionalServiceReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FetchAdditionalServices:
        case actionTypes.FetchAdditionalService: {
            return {
                ...state,
                loading: true,
                error: null,
                additionalService: null,
            };
        }
        case actionTypes.AddAdditionalService:
        case actionTypes.RemoveAdditionalService:
        case actionTypes.UpdateAdditionalService: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }
        case actionTypes.FetchedAdditionalServices: {
            return {
                ...state,
                loading: false,
                additionalServices: action.payload.additionalService,
                filtersContent: action.payload.filters,
                totalRecords: action.payload.count,
            };
        }
        case actionTypes.FetchedAdditionalService: {
            return {
                ...state,
                loading: false,
                additionalService: action.payload.additionalService,
                packages: action.payload.package,
                additionalServiceCategory: action.payload.additionalServiceCategory,
            };
        }
        case actionTypes.UpdatedAdditionalService:
        case actionTypes.AddedAdditionalService: {
            return { ...state, loading: false, additionalService: action.payload };
        }
        case actionTypes.AddAdditionalServiceFailed:
        case actionTypes.UpdateAdditionalServiceFailed: {
            return { ...state, loading: false };
        }
        case actionTypes.FetchAdditionalServicesFailed:
        case actionTypes.FetchAdditionalServiceFailed: {
            return {
                ...state,
                loading: false,
                additionalServices: [],
                error: action.payload,
            };
        }
        default:
            return state;
    }
}

export const actions = {
    fetchAdditionalServices: () => ({ type: actionTypes.FetchAdditionalServices }),
    fetchAdditionalService: () => ({ type: actionTypes.FetchAdditionalService }),
    addAdditionalService: () => ({ type: actionTypes.AddAdditionalService }),
    removeAdditionalService: () => ({ type: actionTypes.RemoveAdditionalService }),
    updateAdditionalService: () => ({ type: actionTypes.UpdateAdditionalService }),
    fetchedAdditionalService: (result) => ({ type: actionTypes.FetchedAdditionalService, payload: result }),
    fetchedAdditionalServices: (additionalServices) => ({
        type: actionTypes.FetchedAdditionalServices,
        payload: additionalServices,
    }),
    updatedAdditionalService: (result) => ({ type: actionTypes.UpdatedAdditionalService, payload: result }),
    addedAdditionalService: (result) => ({ type: actionTypes.AddedAdditionalService, payload: result }),
    fetchAdditionalServicesFailed: (error) => ({ type: actionTypes.FetchAdditionalServicesFailed, payload: error }),
    fetchAdditionalServiceFailed: (error) => ({ type: actionTypes.FetchAdditionalServiceFailed, payload: error }),
    addAdditionalServiceFailed: (error) => ({ type: actionTypes.AddAdditionalServiceFailed, payload: error }),
    updateAdditionalServiceFailed: (error) => ({ type: actionTypes.UpdateAdditionalServiceFailed, payload: error }),
};

export const getAdditionalServiceTree = (additionnalServices, additionalServiceId) => {
    const options = [];
    let selectedAdditionalService = null;

    additionnalServices.forEach((item) => {
        // initialise data
        if (additionalServiceId && additionalServiceId === item.id) {
            selectedAdditionalService = { name: item.name, id: item.id };
        }
        // check exist category
        const newAdditionalService = options.filter(
            (option) => options.length > 0 && option.code === item.additionalServiceCategory.id,
        );

        if (newAdditionalService.length > 0) {
            options.forEach((option) => {
                if (option.code === item.additionalServiceCategory.id) {
                    option.additionalService.push({
                        name: item.name,
                        id: item.id,
                    });
                }
            });
        } else {
            options.push({
                cname: item.additionalServiceCategory.name,
                code: item.additionalServiceCategory.id,
                additionalService: [
                    {
                        name: item.name,
                        id: item.id,
                    },
                ],
            });
        }
    });

    return [options, selectedAdditionalService];
};
