import { empty } from './ToolsHelper';

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!target[key]) target[key] = {};
                mergeDeep(target[key], source[key]);
            } else {
                target[key] = source[key];
            }
        });
    }

    return mergeDeep(target, ...sources);
}

/**
 * Build form data
 *
 * @param formData
 * @param data
 * @param parentKey
 */
function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}

/**
 * Get form data from object
 *
 * @param data
 * @returns {FormData}
 */
export const getFormData = (data) => {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
};

/**
 * Remove empty fields
 *
 * @param fields
 * @returns {{[p: string]: *}}
 */
export const removeEmptyFields = (fields) =>
    Object.entries(fields).reduce((acc, [name, field]) => (!empty(field) ? { ...acc, [name]: field } : acc), {});

/**
 * Converts a base64 content string to a Blob object.
 * @param {string} content - The base64 encoded content string.
 * @returns {Blob} - A Blob object representing the decoded content.
 */
export const convertBase64ToBlob = (content) => {
    const byteCharacters = atob(content); // Decode base64
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'application/pdf' });
};
