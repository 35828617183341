import React from 'react';

const BreadCrumbsHome = () => {
    return (
        <a href="#" className="kt-subheader__breadcrumbs-home">
            <i className="flaticon2-shelter" />
        </a>
    );
};

export default BreadCrumbsHome;
