import React, {useCallback, useEffect, useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import MakeSegments from "./MakeSegment";
import PictureUpload from "../../../partials/content/PictureUpload";
import {Controller, useFormContext} from "react-hook-form";
import {Intl} from "../../../../_metronic/i18n/I18nProvider";
import SwitchInput from "../../../partials/content/SwitchInput";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../partials/content/Loader";
import {fetchPackage, removePackage,} from "../../../store/apv/package/package.thunk";
import {Link, useParams, withRouter} from "react-router-dom";
import toast from "../../../partials/content/Toast";
import ErrorForm from "../../../partials/layout/ErrorForm";
import {formSubmit} from "./Form/Form.submit";
import {empty} from "../../../helpers/ToolsHelper";
import Editor from "../../../partials/content/Editor";
import {routeTo} from "../../../helpers/RoutesHelper";
import ROUTES from "../../../router/Routes";
import Permission from "../../../partials/content/Permission";
import {getPackages} from "../../../crud/apv/package.crud";
import useFetch from "../../../hooks/useFetch";
import { getActions } from '../../../crud/apv/mecaplanning.crud';

const defaultValues = {
    packageCategoryId: -1,
    name: '',
    code: '',
    active: false,
    seoTitle: '',
    seoUrl: '',
    seoH1: '',
    seoMetaDescription: '',
    seoIntroduction: '',
    seoDescription: '',
    prices: [],
    pathVideo: '',
    promote: false,
    packageParentId: -1,
    isDegressivity: false,
    mo: '',
    tmo: '',
    mecaplanningActionId: -1,
}

function Package({ history }) {
    const {reset, setValue, register, errors, handleSubmit, control} = useFormContext();
    const params = useParams();
    const dispatch = useDispatch();

    const newPackage = typeof params.id === 'undefined';
    let _package = useSelector((state) => state.package.package);
    let { categories, segments, error, loading } = useSelector((state) => state.package);
    const [isOption, setIsOption] = useState(false);
    const {fetch: fetchParentPackages, data: parentPackages, setData: setParentPackages, loading: loadingParentPackage} = useFetch({
        fetchAction: getPackages,
        resultInterceptor: (response) => {
            return response?.result?.packages?.filter?.((_package) => {
                return !_package?.packageParent;
            }) || [];
        }
    });

    const {data: mecaplanningActions} = useFetch({
        fetchAction: getActions,
        autoFetch: true,
    });

    const onSubmit = data => {
        formSubmit({
            newPackage,
            data,
            dispatch,
            history,
            id: params.id
        });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({id: 'FORM.ERROR.VALIDATE'})
        });
    }

    const deletePackage = () => {
        dispatch(removePackage({
            id: params.id,
            history: history
        }));
    }

    useEffect(() => {
        dispatch(fetchPackage(params.id));

        return () => {
            reset(defaultValues);
        }
    }, [dispatch, reset, params.id]);

    const generatePrices = useCallback(() => {
            const defaultPrices = [...defaultValues.prices];

            if (segments?.bodyTypeSegment) {
                segments.makeSegment.forEach((makeSegment) => {
                    segments.packagePriceZone.forEach((priceZone) => {
                        segments.bodyTypeSegment.forEach((bodyType) => {
                            defaultPrices.push({
                                makeSegment: makeSegment.id,
                                priceZone: priceZone.id,
                                bodyType: bodyType.id,
                                code: '',
                                price: undefined,
                                active: true
                            });
                        });
                    });
                });
            }

            return defaultPrices;
        },
        [segments]
    );

    const handleOptionChange = (value) => {
        if (value && !isOption) {
            fetchParentPackages({
                items: 10000
            });
        } else if (!isOption) {
            setParentPackages([]);
        }

        setIsOption(value);
    }

    const setFormValues = useCallback(() => {
        reset({
            packageCategoryId: _package?.packageCategory?.id || defaultValues.packageCategoryId,
            name: _package?.name || defaultValues.name,
            code: _package?.code || defaultValues.code,
            active: _package?.active || defaultValues.active,
            promote: _package?.promote || defaultValues.promote,
            seoTitle: _package?.seoTitle || defaultValues.seoTitle,
            seoUrl: _package?.seoUrl || defaultValues.seoUrl,
            seoH1: _package?.seoH1 || defaultValues.seoH1,
            seoMetaDescription: _package?.seoMetaDescription || defaultValues.seoMetaDescription,
            seoIntroduction: _package?.seoIntroduction || defaultValues.seoIntroduction,
            seoDescription: _package?.seoDescription || defaultValues.seoDescription,
            prices: _package?.prices ? _package.prices : generatePrices(),
            pathVideo: _package?.pathVideo ? _package.pathVideo : defaultValues.pathVideo,
            isDegressivity: _package?.isDegressivity ? _package?.isDegressivity : defaultValues.isDegressivity,
            mo: _package?.mo ? _package?.mo : defaultValues.mo,
            tmo: _package?.tmo ? _package?.tmo : defaultValues.tmo,
            rank: _package?.rank || '',
            packageParentId: _package?.packageParent?.id ? _package.packageParent.id : defaultValues.packageParentId,
            mecaplanningActionId: _package?.mecaplanningAction?.id ? _package.mecaplanningAction?.id : defaultValues.mecaplanningActionId,
        });
    },[generatePrices, _package, segments, reset]);

    useEffect(() => {
        setFormValues();

        if (_package?.packageParent?.id) {
            handleOptionChange(true);
        }
    }, [setFormValues]);

    useEffect(() => {
        if (error !== null) {
            toast({
                onShow: () => history.push(routeTo(ROUTES.PACKAGES.PATH.ALL)),
                variant: 'danger',
                message: Intl.formatMessage({id: 'TRANSLATOR.ERROR'})
            });
        }
    }, [history, error]);

    useEffect(() => {
        setValue('packageParentId', _package?.packageParent?.id ? _package.packageParent.id : defaultValues.packageParentId);
    }, [parentPackages]);

    return (
        <Form className="package" onSubmit={handleSubmit(onSubmit, onError)}>
            {(loading || loadingParentPackage) &&
                <Loader style={{width: '5rem', height: '5rem'}} overlay/>
            }
            <Row className="mb-5">
                <Col lg={6}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-wrench"></i>
                            {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.PACKAGE' })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Group controlId="packageCategoryId">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.FAMILY' })}</Form.Label>
                                <Form.Control name="packageCategoryId"
                                              as="select"
                                              className={`${errors.packageCategoryId ? 'is-invalid' : ''}`}
                                              ref={register({
                                                  validate: (value) => {
                                                      return parseInt(value) === -1 ? Intl.formatMessage({id: 'FORM.ERROR.REQUIRED'}) : true
                                                  }
                                              })}>
                                    <option value={-1} disabled>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.SELECT.FAMILY.DEFAULT' })}</option>
                                    { categories &&
                                        categories.map((category, key) => {
                                            return <option key={key} value={category.id}>{category.name}</option>
                                        })
                                    }
                                </Form.Control>
                                <ErrorForm errors={errors} name="packageCategoryId" />
                            </Form.Group>
                            <Form.Group controlId="name">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.NAME' })}</Form.Label>
                                <Form.Control name="name"
                                              type="text"
                                              className={`${errors.name ? 'is-invalid' : ''}`}
                                              ref={register({
                                                  required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                              })}/>
                                <ErrorForm errors={errors} name="name" />
                            </Form.Group>
                            <Form.Group controlId="code">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.CODE' })}</Form.Label>
                                <Form.Control name="code"
                                              type="text"
                                              className={`${errors.code ? 'is-invalid' : ''}`}
                                              ref={register({
                                                  required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                              })}/>
                                <ErrorForm errors={errors} name="code" />
                            </Form.Group>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.STATUS' })}</Form.Label>
                                        <SwitchInput name="active" active={_package?.active || false}/>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.PROMOTE' })}</Form.Label>
                                        <SwitchInput name="promote" active={_package?.promote || false}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.OPTION' })}</Form.Label>
                                        <SwitchInput name="isOption"
                                                     form={false}
                                                     active={isOption}
                                                     labelFirst={Intl.formatMessage({id: 'TRANSLATOR.NO'})}
                                                     labelSecond={Intl.formatMessage({id: 'TRANSLATOR.YES'})}
                                                     onChange={handleOptionChange}/>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    {isOption &&
                                        <Form.Group>
                                            <Form.Label>{Intl.formatMessage({id: 'APV.PACKAGE.VIEW.PARENT_PACKAGE'})}</Form.Label>
                                            <Form.Control name="packageParentId"
                                                          as="select"
                                                          className={`${errors.packageCategoryId ? 'is-invalid' : ''}`}
                                                          ref={register({
                                                              validate: (value) => {
                                                                  return parseInt(value) === -1 ? Intl.formatMessage({id: 'FORM.ERROR.REQUIRED'}) : true
                                                              }
                                                          })}>
                                                <option value={-1}
                                                        disabled>{Intl.formatMessage({id: 'APV.PACKAGE.VIEW.PARENT_PACKAGE.PLACEHOLDER'})}</option>
                                                {parentPackages?.length > 1 &&
                                                    parentPackages.map((_package, key) => {
                                                        return <option key={key} value={_package.id}>{_package.name}</option>
                                                    })
                                                }
                                            </Form.Control>
                                            <ErrorForm errors={errors} name="packageParentId"/>
                                        </Form.Group>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.IS_DEGRESSIVITY' })}</Form.Label>
                                        <SwitchInput name="isDegressivity"
                                                     labelFirst={Intl.formatMessage({id: 'TRANSLATOR.NO'})}
                                                     labelSecond={Intl.formatMessage({id: 'TRANSLATOR.YES'})}/>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({id: 'APV.PACKAGE.VIEW.MECAPLANNING_ACTION'})}</Form.Label>
                                        <Form.Control name="mecaplanningActionId"
                                                      as="select"
                                                      className={`${errors.mecaplanningActionId ? 'is-invalid' : ''}`}
                                                      ref={register({
                                                          validate: (value) => {
                                                              return parseInt(value) === -1 ? Intl.formatMessage({id: 'FORM.ERROR.REQUIRED'}) : true
                                                          }
                                                      })}>
                                            <option value={-1}
                                                    disabled>{Intl.formatMessage({id: 'APV.PACKAGE.VIEW.MECAPLANNING_ACTION.PLACEHOLDER'})}</option>
                                            {mecaplanningActions?.length > 1 &&
                                                mecaplanningActions.map((_action, key) => {
                                                    return <option key={key} value={_action.id}>{_action.name}</option>
                                                })
                                            }
                                        </Form.Control>
                                        <ErrorForm errors={errors} name="mecaplanningActionId"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.MO' })}</Form.Label>
                                        <Form.Control name="mo"
                                                      type="text"
                                                      ref={register}/>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.TMO' })}</Form.Label>
                                        <Form.Control name="tmo"
                                                      type="text"
                                                      ref={register}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.RANK' })}</Form.Label>
                                        <Form.Control name="rank"
                                                      type="text"
                                                      ref={register}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.ADDONS' })}</Form.Label>
                                        <SwitchInput name="additionalServicesEligible" active={_package?.additionalServicesEligible || false}/>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.AUTO_ADD_TO_CART' })}</Form.Label>
                                        <SwitchInput name="basketAutoAdd" active={_package?.basketAutoAdd || false}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-globe-europe"></i>
                            {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.SEO' })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Group controlId="seoTitle">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.TITLE' })}</Form.Label>
                                <Form.Control name="seoTitle"
                                              type="text"
                                              className={`${errors.seoTitle ? 'is-invalid' : ''}`}
                                              ref={register({
                                                  required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                              })}/>
                                <ErrorForm errors={errors} name="seoTitle" />
                            </Form.Group>
                            <Form.Group controlId="seoUrl">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.URL' })}</Form.Label>
                                <Form.Control name="seoUrl"
                                              type="text"
                                              className={`${errors.seoUrl ? 'is-invalid' : ''}`}
                                              ref={register({
                                                  required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                              })}/>
                                <ErrorForm errors={errors} name="seoUrl" />
                            </Form.Group>
                            <Form.Group controlId="seoH1">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.H1' })}</Form.Label>
                                <Form.Control name="seoH1"
                                              type="text"
                                              className={`${errors.seoH1 ? 'is-invalid' : ''}`}
                                              ref={register({
                                                  required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                              })}/>
                                <ErrorForm errors={errors} name="seoH1" />
                            </Form.Group>
                            <Form.Group controlId="seoMetaDescription">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.META_DESCRIPTION' })}</Form.Label>
                                <Form.Control name="seoMetaDescription"
                                              type="text"
                                              className={`${errors.seoMetaDescription ? 'is-invalid' : ''}`}
                                              ref={register({
                                                  required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                              })}/>
                                <ErrorForm errors={errors} name="seoMetaDescription" />
                            </Form.Group>
                            <Form.Group controlId="pathVideo">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.VIDEO' })}</Form.Label>
                                <Form.Control name="pathVideo"
                                              type="text"
                                              ref={register}/>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-edit"></i>
                            {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.INTRODUCTION' })}
                        </Card.Header>
                        <Card.Body>
                            <Controller
                                control={control}
                                name="seoIntroduction"
                                rules={{
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                }}
                                render={(props) => (
                                    <Editor
                                        value={props.value}
                                        onChange={props.onChange}
                                    />
                                )}/>
                            <ErrorForm errors={errors} name="seoIntroduction" />
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-edit"></i>
                            {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.DESCRIPTION' })}
                        </Card.Header>
                        <Card.Body>
                            <Controller
                                control={control}
                                name="seoDescription"
                                rules={{
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                }}
                                render={(props) => (
                                    <Editor
                                        value={props.value}
                                        onChange={props.onChange}
                                    />
                                )}/>
                            <ErrorForm errors={errors} name="seoDescription" />
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon far fa-image"></i>
                            {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.VISUALS' })}
                        </Card.Header>
                        <Card.Body>
                            <PictureUpload name="pathImg" path={_package?.pathImg}/>
                            <ErrorForm errors={errors} name="pathImg" />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-money-bill"></i>
                            {Intl.formatMessage({ id: 'APV.PACKAGE.VIEW.PRICES' })}
                        </Card.Header>
                        <Card.Body>
                            <Controller
                                control={control}
                                name="prices"
                                rules={{
                                    validate: (value) => {
                                        let hasEmptyValue = false;

                                        for (const price of value) {
                                            if ((empty(price.code) || empty(price.price)) && price.active) {
                                                hasEmptyValue = true;
                                                break;
                                            }
                                        }

                                        return hasEmptyValue ? Intl.formatMessage({ id: 'FORM.ERROR.PRICES_REQUIRED' }) : true;
                                    }
                                }}
                                render={(props) => (
                                    <>
                                        { segments?.makeSegment &&
                                            segments.makeSegment.map((makeSegment, key) => {
                                                return <MakeSegments key={key}
                                                                     segments={segments}
                                                                     name={props.name}
                                                                     id={makeSegment.id}
                                                                     title={makeSegment.name} />
                                            })
                                        }
                                    </>
                                )}/>
                            <ErrorForm errors={errors} name="prices" />
                            <Row className="mt-4">
                                <Col className={newPackage ? 'text-center' : 'text-right'}>
                                    <Link to={routeTo(ROUTES.PACKAGES.PATH.TYPE, {type: 'workshop'})}>
                                        <Button variant="outline-primary">
                                            {Intl.formatMessage({ id: 'TRANSLATOR.BACK' })}
                                        </Button>
                                    </Link>
                                    <Permission permissions={['APV_UPDATE_PACKAGE']}>
                                        <Button variant="primary"
                                                className="ml-2"
                                                type="submit">
                                            {Intl.formatMessage({ id: 'TRANSLATOR.SAVE' })}
                                        </Button>
                                    </Permission>
                                </Col>
                                {!newPackage &&
                                <Permission permissions={['APV_DELETE_PACKAGE']}>
                                    <Col className="text-right">
                                        <Button variant="outline-danger" onClick={() => deletePackage()}>
                                            {Intl.formatMessage({ id: 'TRANSLATOR.REMOVE' })}
                                        </Button>
                                    </Col>
                                </Permission>
                                }
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Form>
    )
}

export default withRouter(Package);
