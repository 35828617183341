import classNames from 'classnames';
import { motion, useAnimation } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useFetch from '@app/hooks/useFetch';
import useModal from '@app/hooks/useModal';

import { empty } from '@app/helpers/ToolsHelper';

import { getCustomerEventById, getCustomerEvents } from '@app/crud/customers/customer.crud';
import reduceRollbackField from '@app/crud/mapping/rollback.map';

import { mapVcuCustomer } from '@app/pages/customers/Helpers/Customer';
import { showDetailEvent } from '@app/pages/customers/Helpers/Events';
import { MailContent } from '@app/pages/customers/Modals';
import { updateCustomer } from '@app/store/customers/customers.thunk';
import { actions } from '@app/store/modal/modal.store';

import Loader from '@app/partials/content/Loader';
import Modal from '@app/partials/HoC/Modal';

import HistoryRow from './HistoryRow';

const DESC = 1;
const ASC = 2;

const variants = {
    show: {
        height: 'auto',
        overflow: 'hidden',
        transition: {
            ease: 'easeOut',
        },
    },
    hide: {
        height: 0,
        overflow: 'hidden',
        transition: {
            ease: 'easeOut',
        },
    },
};

const CustomerHistory = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [itemEnd, setItemEnd] = useState(false);
    const [sort, setSort] = useState(DESC);
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });
    const history = useHistory();
    const { userData } = useSelector((state) => state.auth);
    const { data: customer } = useSelector((state) => state.customer.customer);
    const { activity } = useSelector((state) => state.customer);
    const controls = useAnimation();
    const Intl = useIntl();
    const [mailContentModalOpen, setMailContentModalOpen, mailContentParams] = useModal(false);
    const { fetch, data, loading, setData } = useFetch({
        fetchAction: getCustomerEvents,
        resultInterceptor: (newData, oldData, params) => {
            const nextData = { ...newData };

            if (newData?.result && params?.start >= oldData?.result?.length) {
                nextData.result = [...oldData.result, ...newData.result];
            }

            setItemEnd(nextData?.count === nextData?.result?.length);

            return nextData;
        },
    });
    const histories = data?.result || [];

    const fetchCustomer = useCallback(
        (reset = true) => {
            if (customer?.id && open) {
                fetch({
                    id: customer?.id,
                    start: reset ? 0 : histories?.length,
                    items: 10,
                });
            }
        },
        [fetch, customer, histories, open],
    );

    useEffect(() => {
        if (histories?.length > 0) {
            fetchCustomer();
        }
    }, [customer]);

    useEffect(() => {
        if (open) {
            controls.start('show');
            fetchCustomer();
        } else {
            controls.start('hide');
            setData([]);
        }
    }, [open]);

    const updateCustomerCallback = (customerToChange) => {
        const customerToChangeMapped = mapVcuCustomer(customerToChange);
        customerToChangeMapped.user = {
            id: userData.id,
            firstname: userData.first_name,
            lastname: userData.last_name,
        };
        const id = customer?.id;
        dispatch(updateCustomer(id, customerToChangeMapped, history));
    };

    const onShowDetails = (eventData) => {
        switch (eventData.eventTypeSlug) {
            case 'update_consent_dealership':
            case 'update_consent_contact':
            case 'update_contact':
                getCustomerEventById(eventData.eventId).then((response) => {
                    if (response?.result) {
                        const histos = response?.result?.histos?.filter?.(
                            (histo) => !empty(histo?.newValue) || !empty(histo?.oldValue),
                        );

                        const updateType = response?.result?.eventType?.slug;

                        dispatch(
                            actions.modalChange('rollback_update', {
                                customerFieldChange: reduceRollbackField(histos),
                                callback: updateCustomerCallback,
                                updateType,
                            }),
                        );
                    }
                });
                break;
            case 'action_email':
                setMailContentModalOpen(true, {
                    content: eventData.emailQueueContent,
                    attachments: eventData.emailQueueAttachments,
                });
                break;
            default: {
                const allActivities = activity?.data.reduce(
                    (acc, customerEvent) => (customerEvent?.events ? acc.concat(customerEvent.events) : []),
                    [],
                );
                const event = allActivities?.find(
                    (act) =>
                        (eventData.leadId && act?.leadId === eventData.leadId) || act?.eventId === eventData.eventId,
                );
                if (event) {
                    const showDetailButton = document.querySelector(`#event-all-${event?.leadId || event?.eventId}`);
                    showDetailButton.click();
                    showDetailEvent(showDetailButton, true);
                }
                break;
            }
        }
    };

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setDimensions]);

    const breakpoints = {
        1500: {
            rows: 4,
        },
        1200: {
            rows: 3,
        },
        1100: {
            rows: 2,
        },
    };

    let params = Object.entries(breakpoints).find(([breakpoint]) => dimensions.width < parseInt(breakpoint, 10));

    if (!params) {
        params = {
            rows: 5,
        };
    } else {
        // eslint-disable-next-line prefer-destructuring
        params = params[1];
    }

    const splitHistory = (historiesToSplit) => {
        const historiesToSplitItems = [...historiesToSplit];

        if (sort === ASC) {
            historiesToSplitItems.reverse();
        }

        const splittedHistory = [];

        // Create rows
        for (let i = 0; i < Math.ceil(historiesToSplitItems.length / params.rows); i += 1) {
            const row = params.rows * i;
            const historyRow = historiesToSplitItems.slice(row, row + params.rows);
            splittedHistory.push(historyRow);
        }

        return splittedHistory;
    };

    const splittedHistory = splitHistory(histories);

    const loadMore = (
        <div
            className={classNames(
                'lead-history__col',
                'lead-history__col--newline',
                'lead-history__col--load',
                `lead-history__col--${params.rows}`,
            )}
        >
            <div className="lead-history__wrapper">
                <div className="lead-history__content">
                    <Button variant="outline-primary" onClick={() => fetchCustomer(false)}>
                        {Intl.formatMessage({ id: 'CUSTOMER.VIEW.HISTORY.LOAD_MORE' })}
                    </Button>
                    <div className="lead-history__end">
                        <i className="lar text-primary la-circle lead-history__icon" />
                    </div>
                </div>
            </div>
        </div>
    );

    const getArrowDirection = (rowIdx) => {
        if (sort === DESC) {
            return rowIdx % 2 === 0 ? 'left' : 'right';
        }
        return rowIdx % 2 === 0 ? 'right' : 'left';
    };

    const getIsStart = (rowIdx, historyRow, historyIdx) => {
        if (sort === DESC) {
            return rowIdx === 0 && historyIdx === 0;
        }
        return rowIdx + 1 === splittedHistory.length && historyIdx + 1 === historyRow.length && itemEnd;
    };

    const getIsEnd = (rowIdx, historyRow, historyIdx) => {
        if (sort === DESC) {
            return rowIdx + 1 === splittedHistory.length && historyIdx + 1 === historyRow.length && itemEnd;
        }
        return rowIdx === 0 && historyIdx === 0;
    };

    return (
        <>
            <Card>
                <Card.Header className="cursor-pointer" onClick={() => setOpen(!open)}>
                    <div className="h-100 d-flex align-items-center justify-content-between">
                        <div>
                            <i className="card__icon las la-newspaper" />
                            {Intl.formatMessage({ id: 'CUSTOMER.VIEW.HISTORY.TITLE' })}
                        </div>
                        {open ? (
                            <i className="las la-lg la-minus text-secondary" />
                        ) : (
                            <i className="las la-lg la-plus text-secondary" />
                        )}
                    </div>
                </Card.Header>
                <motion.div variants={variants} initial="hide" animate={controls}>
                    <Card.Body className="mb-10">
                        {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                        <Row className="mb-20 mx-10 d-flex align-items-center justify-content-between">
                            <Col
                                lg={7}
                                className="cursor-pointer d-flex align-items-center"
                                onClick={() => setSort(sort === DESC ? ASC : DESC)}
                            >
                                {Intl.formatMessage({ id: 'CUSTOMER.VIEW.HISTORY.SORT' })}
                                <i className="ml-3 mr-1 las la-angle-down" />
                                <i className="mr-3 ml-1 las la-angle-up" />
                                {sort === ASC
                                    ? Intl.formatMessage({ id: 'CUSTOMER.VIEW.HISTORY.SORT.ASC' })
                                    : Intl.formatMessage({ id: 'CUSTOMER.VIEW.HISTORY.SORT.DESC' })}
                            </Col>
                        </Row>
                        <div className={`lead-history lead-history--${params.rows}`}>
                            {splittedHistory.map((historyRow, rowIdx) => (
                                <div
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={rowIdx}
                                    className={classNames('lead-history__row', {
                                        'lead-history__row--first': rowIdx === 0,
                                        'lead-history__row--left': rowIdx > 0 && rowIdx % 2 === 0,
                                        'lead-history__row--right':
                                            (rowIdx > 0 && rowIdx % 2 !== 0) || splittedHistory.length === 0,
                                    })}
                                >
                                    {historyRow.map((historyValue) => (
                                        <HistoryRow
                                            key={historyValue.eventId}
                                            data={historyValue}
                                            onShowDetails={onShowDetails}
                                            arrowDirection={getArrowDirection(rowIdx)}
                                            isStart={getIsStart(rowIdx, historyRow, historyValue.eventId)}
                                            isEnd={getIsEnd(rowIdx, historyRow, historyValue.eventId)}
                                        />
                                    ))}
                                    {historyRow.length < params.rows && !itemEnd && loadMore}
                                </div>
                            ))}
                            {splittedHistory[splittedHistory.length - 1]?.length === params.rows && !itemEnd && (
                                <div
                                    className={classNames('lead-history__row lead-history__row--load', {
                                        'lead-history__row--left': splittedHistory.length % 2 === 0,
                                        'lead-history__row--right': splittedHistory.length % 2 !== 0,
                                    })}
                                >
                                    {loadMore}
                                </div>
                            )}
                        </div>
                    </Card.Body>
                </motion.div>
            </Card>
            <Modal
                As={MailContent}
                showModal={mailContentModalOpen}
                setShowModal={setMailContentModalOpen}
                params={mailContentParams}
            />
        </>
    );
};

export default CustomerHistory;
