import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationQueryFilter, Permission } from '@app/crud/autouser/application.types';

import { TreeSelect } from '@app/partials/content/Tree';

interface TreeSelectNode {
    checked: boolean;
    label: string;
    value: number;
    id: number;
    _children: number[];
    _depth: number;
    _focused: boolean;
}

interface PermissionsSelectProps {
    handleFilter: (args: ApplicationQueryFilter) => void;
    permissions: Array<Permission & { checked: boolean }>;
}

const PermissionsSelect = React.forwardRef(({ permissions, handleFilter }: PermissionsSelectProps, forwardedRef) => {
    const Intl = useIntl();

    const selectPermissions = useMemo(
        () => ({
            label: Intl.formatMessage({ id: 'AUTOUSER.PERMISSIONS.SELECT_ALL' }),
            id: 0,
            expanded: true,
            checked: permissions.every((perm) => perm.checked),
            children: permissions.map((perm) => ({
                label: perm.title,
                id: perm.id,
                checked: perm.checked,
            })),
        }),
        [permissions],
    );

    return (
        <TreeSelect
            // @ts-ignore
            data={selectPermissions}
            ref={forwardedRef}
            onChange={(currentNode: TreeSelectNode, selectedNodes: TreeSelectNode[]): void => {
                let selectedPermissions = selectedNodes.map((selected: TreeSelectNode) => selected.id);
                if (currentNode.id === 0) {
                    // eslint-disable-next-line no-underscore-dangle
                    selectedPermissions = currentNode.checked ? currentNode._children : [];
                }

                handleFilter({
                    permissions: {
                        value: selectedPermissions,
                    },
                });
            }}
        />
    );
});

export default PermissionsSelect;
