import React, { useEffect, useMemo, useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import useModal from '@app/hooks/useModal';
import useQueryParams from '@app/hooks/useQueryParams';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getVehiclesActionsToDo } from '@app/crud/stock/stock.crud';

import ActionHandBand from '@app/pages/stock/headbands/ActionHandBand';
import { getNewDealerIds } from '@app/pages/stock/helpers/Perimeter';
import StockActionToDoDetail from '@app/pages/stock/modals/StockActionToDoDetail';
import ROUTES from '@app/router/Routes';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import toast from '@app/partials/content/Toast';
import HeadBand from '@app/partials/layout/HeadBand';

import StockActionsTodoDatatable from './StockActionsTodoDatatable';
import StockActionsToDoHeader from './StockActionsToDoHeader';

const ALL = 'all';
const HEIGHT = '150px';
const SCROLL_HEIGHT = '60px';

const StockActionsTodo = () => {
    const [showDetailsModal, setShowDetailsModal] = useModal(false, 'stock_action_detail');
    const [loadedPerimeter, setLoadedPerimeter] = useState(false);
    const [activeTab, setActiveTab] = useState(ALL);
    const { getValues } = useFormContext();
    const history = useHistory();
    const { page } = useParams();
    const isHistoryPage = page === 'history';
    const queryParams = useQueryParams();
    const location = useLocation();

    const extraParams = useMemo(
        () => ({
            loadedPerimeter,
        }),
        [loadedPerimeter],
    );

    const {
        items,
        start,
        filters: actionsToDoFilters,
        sortField,
        sortOrder,
        resetFilter,
        updatePendingFilters,
        releasePendingFilters,
    } = useFilters({
        name: 'stock_action_to_do',
    });
    const { data, handleFilter, handlePagination, handleSort, loading, refresh } = useTableHandlers({
        filterStore: 'stock_action_to_do',
        fetchAction: getVehiclesActionsToDo,
        withStore: false,
        extraParams,
        doSearch: (params, extraParamsReturn) => extraParamsReturn?.loadedPerimeter,
        paramsInterceptor: (params, filters) => {
            const perimeter = getValues('perimeter');
            let dealerId = params?.dealerId;
            if (!dealerId) {
                dealerId = getNewDealerIds(filters?.filters, perimeter);
            }

            return {
                ...params,
                page: filters.page - 1, // to start page 0
                limit: filters.items,
                sortField: filters?.sortField ? filters.sortField : null,
                sortDirection: filters?.sortOrder === 1 ? 'asc' : 'desc',
                dealerId,
            };
        },
    });

    useEffect(() => {
        // Get queryParams from dashboard to display the right tabs
        if (queryParams?.activeTab) {
            setActiveTab(queryParams?.activeTab);
            history.replace(location.pathname);
        }
    }, [queryParams]);

    useEffect(() => {
        if (page !== 'list' && page !== 'history') {
            toast({
                onShow: history.push(routeTo(ROUTES.STOCK_ACTION_TO_DO.PATH, { page: 'list' })),
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        } else {
            handleFilter({
                inactive: {
                    value: isHistoryPage ? 1 : 0,
                },
            });
        }
    }, [page]);

    useEffect(() => {
        if (activeTab === ALL) {
            resetFilter('type');
        } else {
            handleFilter({
                type: {
                    value: data?.types?.find((type) => activeTab === type?.slug)?.id,
                },
            });
        }
        handlePagination({
            page: 0,
            start: 0,
            rows: items,
        });
    }, [activeTab]);

    return (
        <FiltersProvider value={actionsToDoFilters}>
            <Card className="p-4">
                <HeadBand
                    className="headband"
                    fixed
                    height={HEIGHT}
                    scrollHeight={SCROLL_HEIGHT}
                    parent="kt_subheader"
                    variant="customers-primary"
                >
                    <ActionHandBand
                        type={data?.types?.find((type) => activeTab === type?.slug)}
                        isHistory={isHistoryPage}
                        loading={loading}
                    />
                </HeadBand>
                <StockActionsToDoHeader
                    handleFilter={handleFilter}
                    updatePendingFilters={updatePendingFilters}
                    releasePendingFilters={releasePendingFilters}
                    setLoadedPerimeter={setLoadedPerimeter}
                    isHistory={isHistoryPage}
                />
                <Tabs
                    className="nav-tabs-line nav-tabs-line-primary nav-tabs-line-2x"
                    defaultActiveKey="all"
                    activeKey={activeTab}
                    id={activeTab}
                    onSelect={(e) => setActiveTab(e)}
                >
                    {data?.types?.map?.((type) => (
                        <Tab eventKey={type?.slug} title={`${type?.label} (${type?.total})`} key={type.id} />
                    ))}
                </Tabs>
                <StockActionsTodoDatatable
                    handleFilter={handleFilter}
                    handlePagination={handlePagination}
                    handleSort={handleSort}
                    loading={loading}
                    actions={data?.anomalies || []}
                    total={data?.types?.find?.((type) => type?.slug === activeTab)?.total}
                    items={items}
                    start={start}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    isHistory={isHistoryPage}
                />
            </Card>
            <StockActionToDoDetail
                showModal={showDetailsModal}
                setShowModal={setShowDetailsModal}
                refreshDataTable={refresh}
            />
        </FiltersProvider>
    );
};

export default StockActionsTodo;
