import React from 'react';
import { Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';

import Permission from '@app/partials/content/Permission';
import RowInfo from '@app/partials/content/RowInfo';

const StockVehicleTrade = ({ vehicle, isVN }) => {
    const isContextEFB = useSelector((state) => state.auth.userData.context === 'efb');

    return (
        <Card>
            <Card.Header>
                <i className="card__icon la-lg las la-money-bill-wave text-dark" />
                <FormattedMessage id="STOCK_VEHICLE.CARD.TRADE.TITLE" />
            </Card.Header>
            <Card.Body>
                {!isVN && (
                    <>
                        <RowInfo
                            label={<FormattedMessage id="STOCK_VEHICLE.CARD.TRADE.CONTENT.LEAD_IN_PROGRESS" />}
                            value={
                                <Link
                                    to={routeTo(
                                        ROUTES.LEADS_ONLINE.PATH,
                                        {
                                            statusType: 'all',
                                        },
                                        {
                                            vehicleIdentifier: !isContextEFB
                                                ? vehicle?.license_number
                                                : vehicle.serial_number,
                                        },
                                    )}
                                    target="_blank"
                                >
                                    <FormattedMessage id="STOCK_VEHICLE.CARD.TRADE.CONTENT.VIEW_LEAD" />
                                </Link>
                            }
                            colLabel={7}
                            colValue={5}
                        />
                        {!isContextEFB && (
                            <>
                                <RowInfo
                                    label={
                                        <FormattedMessage id="STOCK_VEHICLE.CARD.TRADE.CONTENT.ROAD_FW_IN_PROGRESS" />
                                    }
                                    value={<FormattedMessage id="TRANSLATOR.DATA_TO_COME" />}
                                    valueClassName="font-italic"
                                    colLabel={7}
                                    colValue={5}
                                />
                                <RowInfo
                                    label={<FormattedMessage id="STOCK_VEHICLE.CARD.TRADE.CONTENT.INVOICE_APV" />}
                                    value={<FormattedMessage id="TRANSLATOR.DATA_TO_COME" />}
                                    valueClassName="font-italic"
                                    colLabel={7}
                                    colValue={5}
                                />
                                <Permission permissions={['STOCK_VIEW_INVOICE_CRVO']}>
                                    <RowInfo
                                        label={
                                            <FormattedMessage id="STOCK_VEHICLE.CARD.TRADE.CONTENT.INVOICE_REPAIR" />
                                        }
                                        value={<FormattedMessage id="TRANSLATOR.DATA_TO_COME" />}
                                        valueClassName="font-italic"
                                        colLabel={7}
                                        colValue={5}
                                    />
                                </Permission>
                            </>
                        )}
                    </>
                )}
            </Card.Body>
        </Card>
    );
};

export default StockVehicleTrade;
