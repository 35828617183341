import clsx from "clsx";
import objectPath from "object-path";
import React from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";

import {
  addInterval,
  addMemoizeFetcher,
  clearIntervals,
  clearMemoizeFetchers,
  getRootActiveItem,
  getRootItem,
  isMemoized,
  isMenuItemActive,
  isMenuTreeActive
} from "@app/helpers/MenuHelper";

import Permission from "@app/partials/content/Permission";
import Typology from "@app/partials/content/Typology";

import MenuContext from "./MenuContext";
import MenuItemText from "./MenuItemText";
import MenuSubmenu from "./MenuSubmenu";


class MenuItem extends React.Component {
  static contextType = MenuContext

  asideLeftLIRef = React.createRef();

  isDropdown =  document.body.classList.contains("kt-aside-menu--dropdown");

  submenuToggle =
    this.props.item.toggle === "click"
      ? "click"
      : objectPath.get(this.props.item, "submenu.type") === "tabs"
      ? "tabs"
      : "hover";

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param event Event
   */
  mouseEnter = event => {
    if (!this.isDropdown) {
      return;
    }

    if (this.props.item.submenu) {
      this.asideLeftLIRef.current.classList.add("kt-menu__item--hover");
      this.asideLeftLIRef.current.setAttribute(
        "data-ktmenu-submenu-toggle",
        this.submenuToggle
      );
    }
  };

  /**
   * Mouse Leave event
   * @param event: MouseEvent
   */
  mouseLeave = event => {
    if (!this.isDropdown) {
      return;
    }

    if (this.props.item.submenu && this.submenuToggle === "hover") {
      this.asideLeftLIRef.current.classList.remove("kt-menu__item--hover");
      this.asideLeftLIRef.current.removeAttribute("data-ktmenu-submenu-toggle");
    }
  };

  clearCounterActivity() {
      return clearIntervals()
  }

  storeCounterActivity(item) {
      const {openedItems, setOpenedItems} = this.context
      // history menu opened
      if(openedItems.some(itm => itm.translate === item.translate)) {
        setOpenedItems([])
      } else {
        setOpenedItems([item])
      }
  }

  setCounterActivity() {
      const {openedItems} = this.context
      const {userData} = this.props.auth
      const rootItem = getRootItem(this.props.menuConfig, this.props.item)
      const activeRootItem = getRootActiveItem(this.props.menuConfig, this.props.location.pathname)
      if(!rootItem) return;
      if(!activeRootItem) return;

      const manageCounters = (menu) => {
          if(!userData?.id) return;

          if(!!menu.counter) {
              const wasOpened = openedItems?.some(itm => itm.translate === rootItem.translate)
              // if already opened menu then menu was closed
              if(!wasOpened) {
                  // if already fetched do not refetch
                  if(!isMemoized(menu)) {
                    addInterval(menu.counter, userData)
                    addMemoizeFetcher(menu)
                  }
              } else {
                  // clean memoizedFetchers to redo fetch
                  clearMemoizeFetchers(menu)
              }
          }

          if(menu.submenu) {
              return menu.submenu.map(m => manageCounters(m))
          }
      }

      // manage all counters from root menu and submenus
      manageCounters(activeRootItem)
      manageCounters(rootItem)
  }

  onClick = event => {
      event.stopPropagation();

      // get root item
      const rootItem = getRootItem(this.props.menuConfig, this.props.item)

      // clear all counters
      this.clearCounterActivity()
      // create intervals based on active menus counters
      this.setCounterActivity()
      this.storeCounterActivity(rootItem)
  }

  componentDidMount() {
      const isActive = isMenuItemActive(this.props.location.pathname, this.props.item);
      if(isActive) {
        const activeRootItem = getRootActiveItem(this.props.menuConfig, this.props.location.pathname)
        // create intervals based on active menu
        this.setCounterActivity()
        this.storeCounterActivity(activeRootItem)
      }
  }

  componentDidUpdate() {
      const isActive = isMenuItemActive(this.props.location.pathname, this.props.item);
      if(isActive) {
        // create intervals based on active menu
        this.setCounterActivity()
      }
  }

  componentWillUnmount() {
    this.clearCounterActivity()
  }

  render() {
    const { item, currentUrl, parentItem } = this.props;
    const isActive = isMenuTreeActive(this.props.location.pathname, item);

    return (
        <Permission permissions={item?.permissions}
                    hasAny={item?.hasAnyPermissions}>
            <Typology typologies={item?.typologies} hasAny>
                <li
                    ref={this.asideLeftLIRef}
                    aria-haspopup="true"
                    data-placement="right"
                    {...(item?.id ? {id: item.id} : {})}
                    data-ktmenu-submenu-mode={item.mode}
                    onMouseEnter={this.mouseEnter}
                    onMouseLeave={this.mouseLeave}
                    onClick={this.onClick}
                    className={clsx(
                    "kt-menu__item",
                    {
                        "kt-menu__item--submenu": item.submenu,
                        "kt-menu__item--open kt-menu__item--here": isActive && item.submenu,
                        "kt-menu__item--active kt-menu__item--here": isActive && !item.submenu,
                        "kt-menu__item--icon-only": item["icon-only"]
                    },
                    item["custom-class"]
                    )}
                    data-ktmenu-dropdown-toggle-class={item["dropdown-toggle-class"]}
                >
                    {!item.submenu && (
                    <Link to={item.page} className="kt-menu__link kt-menu__toggle">
                        <MenuItemText item={item} parentItem={parentItem} />
                    </Link>
                    )}

                    {item.submenu && (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a className="kt-menu__link kt-menu__toggle">
                        <MenuItemText item={item} parentItem={parentItem} />
                    </a>
                    )}

                    {item.submenu && (
                    <div className="kt-menu__submenu">
                        <span className="kt-menu__arrow" />

                        {item["custom-class"] === "kt-menu__item--submenu-fullheight" && (
                        <div className="kt-menu__wrapper">
                            <MenuSubmenu
                            item={item}
                            parentItem={item}
                            currentUrl={currentUrl}
                            />
                        </div>
                        )}

                        {item["custom-class"] !== "kt-menu__item--submenu-fullheight" && (
                        <MenuSubmenu
                            item={item}
                            parentItem={item}
                            currentUrl={currentUrl}
                        />
                        )}
                    </div>
                    )}
                </li>
            </Typology>
        </Permission>
    );
  }
}

const mapStateToProps = store => ({
  auth: store.auth,
  menuConfig: store.builder.menuConfig,
});


export default withRouter(connect(mapStateToProps)(MenuItem))
