import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import ErrorForm from '@app/partials/layout/ErrorForm';

const LeadWonReference = ({ params }) => {
    const { register, errors } = useFormContext();
    const Intl = useIntl();

    return (
        <Form.Group>
            <Form.Label>
                <FormattedHTMLMessage id="CUSTOMERS.MODAL.LEAD_WON.REFERENCE" />
            </Form.Label>
            <Form.Control
                type="text"
                name="bdcReference"
                disabled={!!params?.event}
                className={`${errors.bdcReference ? 'is-invalid' : ''}`}
                ref={register({
                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                })}
                placeholder="Ex: 105346574864"
            />
            <ErrorForm errors={errors} name="bdcReference" />
        </Form.Group>
    );
};

export default LeadWonReference;
