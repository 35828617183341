import { saveAs } from 'file-saver';
import React, { FC } from 'react';
import { Button, Image } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { convertBase64ToBlob } from '@app/helpers/ObjectHelper';

import { getOrderInStorePDF } from '@app/crud/apv/order.crud';
import { OrderInStore } from '@app/crud/apv/order.type';

import { useAppDispatch } from '@app/hooks';
import { actions } from '@app/store/modal/modal.store';

import toast from '@app/partials/content/Toast';

import ApvOrderRowContent from './ApvOrderRowContent';

interface ApvOrderRowProps {
    apvOrder: OrderInStore;
}
const ApvOrderRow: FC<ApvOrderRowProps> = ({ apvOrder }) => {
    const dispatch = useAppDispatch();
    const Intl = useIntl();

    const handleShowPdf = async (order: OrderInStore) => {
        try {
            const orderPdf = await getOrderInStorePDF(String(order.id));

            if (orderPdf?.pdfContent) {
                const blob = convertBase64ToBlob(orderPdf.pdfContent);
                saveAs(blob, `devis-${order.reference}.pdf`);
            } else {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'APV.IN_STORE.QUOTATIONS.NO_PDF' }),
                });
            }
        } catch (error) {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    };

    return (
        <div role="presentation" className="lead-row">
            <div className="lead-row__type lead-row__type--local" />
            <div className="lead-row__wrapper">
                <div className="lead-row__picture-wrapper">
                    <Image
                        className="lead-row__picture lead-row__picture--marketing-campaign"
                        src="/media/pages/customers/bonhomme-apv.svg"
                    />
                </div>
                <div className="lead-row__content py-4">
                    <ApvOrderRowContent order={apvOrder} />
                </div>
                <div className="lead-row__actions py-lg-4 pt-0 pb-4">
                    {apvOrder.orderStatusApvInStore.slug === 'draft' ? (
                        <Button
                            variant="danger"
                            onClick={() => dispatch(actions.modalChange('apv_in_store', { order: apvOrder }))}
                        >
                            <FormattedMessage id="CUSTOMERS.APV_ORDER_ROW.DRAFT_ACTION" />
                        </Button>
                    ) : (
                        <>
                            <Button
                                variant="danger"
                                onClick={() => dispatch(actions.modalChange('apv_in_store_close', { order: apvOrder }))}
                            >
                                <FormattedMessage id="CUSTOMERS.APV_ORDER_ROW.WAITING_ACTION" />
                            </Button>
                            <Button variant="outline-primary" className="mt-5" onClick={() => handleShowPdf(apvOrder)}>
                                <FormattedMessage id="CUSTOMERS.APV_ORDER_ROW.SEE_ACTION" />
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ApvOrderRow;
