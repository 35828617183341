import axios, { AxiosResponse } from 'axios';

import ApiGateway, { CustomAxiosResponse } from '@app/helpers/AxiosHelper';

const { REACT_APP_AUTOUSER_URL } = process.env;

export const LOGIN_URL = 'login_check';
export const REGISTER_URL = 'api/auth/register';
export const REQUEST_PASSWORD_URL = 'api/auth/forgot-password';

export const ME_URL = 'users/me';

export function getGlobalContexts() {
    return axios.get(`${REACT_APP_AUTOUSER_URL}/admin/contexts`);
}

interface LoginResponse {
    token: string;
    refresh_token: string;
    axios: AxiosResponse;
}
export function login(email: string, password: string, context: string) {
    return ApiGateway.post<LoginResponse, CustomAxiosResponse<LoginResponse>>(
        `${REACT_APP_AUTOUSER_URL}/${LOGIN_URL}`,
        { username: email, password, context },
    );
}

export function getCurrentUser() {
    return ApiGateway.post(`${REACT_APP_AUTOUSER_URL}/${ME_URL}?showInactiveDealerships=0`);
}

interface RefreshTokenResponse {
    token: string;
    refresh_token: string;
    axios: AxiosResponse;
}

interface RefreshTokenResponse {
    token: string;
    refresh_token: string;
    axios: AxiosResponse;
}

export function refreshToken(refresh_token: string) {
    return ApiGateway.post<RefreshTokenResponse, RefreshTokenResponse>(`${REACT_APP_AUTOUSER_URL}/token/refresh`, {
        refresh_token,
    });
}

export function register(email: string, fullname: string, username: string, password: string) {
    return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email: string) {
    return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    return axios.get(ME_URL);
}

interface ImpersonateResponse {
    token?: string;
    impersonator_token?: string;
    impersonated_by?: string;
    cache_key?: string;
    axios?: AxiosResponse;
}

export function impersonate(data: { user: string }) {
    return ApiGateway.post<ImpersonateResponse, CustomAxiosResponse<ImpersonateResponse>>(
        `${REACT_APP_AUTOUSER_URL}/impersonate`,
        data,
    );
}
