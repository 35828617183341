import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { deleteAlert } from '@app/crud/administration/alerts';

import { useAppSelector } from '@app/hooks';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

type AlertDeletionModalProps = {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    refresh: () => void;
};

const AlertDeletionModal = ({ showModal, setShowModal, refresh }: AlertDeletionModalProps) => {
    const Intl = useIntl();
    const { params } = useAppSelector((state) => state.modal);

    const onSubmit = () => {
        deleteAlert(params?.id)
            .then(() => {
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'ADMINISTRATION.ALERTS.MODAL.DELETE.SUCCESS' }),
                });
                setShowModal(false);
                refresh();
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'ADMINISTRATION.ALERTS.MODAL.DELETE.ERROR' }),
                });
            });
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-danger la-trash" />}
            title={<FormattedMessage id="ADMINISTRATION.ALERTS.MODAL.DELETE.TITLE" />}
            titleVariant="text-danger"
            body={
                <div className="text-center">
                    <FormattedMessage id="ADMINISTRATION.ALERTS.MODAL.DELETE.TEXT" />{' '}
                    <strong>« {params?.title} »</strong> ?
                </div>
            }
            footer={
                <div className="text-center">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        <FormattedMessage id="TRANSLATOR.CANCEL" />
                    </Button>
                    <Button variant="danger" onClick={() => onSubmit()}>
                        <FormattedMessage id="TRANSLATOR.REMOVE" />
                    </Button>
                </div>
            }
        />
    );
};

export default AlertDeletionModal;
