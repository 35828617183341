import ApiGateway from '@app/helpers/AxiosHelper';

const { REACT_APP_LEAD_URL } = process.env;

export function getSignatures(params) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/email-signature`, {
        params,
    });
}

export function getDealershipSignatures(id) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/email-signature/dealership/${id}`, {
        params: {
            isActive: true,
        },
    });
}

export function updateDealershipSignatures(id, data) {
    return ApiGateway.put(`${REACT_APP_LEAD_URL}/email-signature/dealership/${id}`, {
        ...data,
    });
}

export function getSignature(id) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/email-signature/${id}`);
}

export function getDealershipActiveSignatures(id) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/email-signature/dealership/${id}`, {
        params: {
            isActiveForDealership: true,
        },
    });
}

export function addSignature(data) {
    return ApiGateway.post(`${REACT_APP_LEAD_URL}/email-signature`, data);
}

export function updateSignature(id, data) {
    return ApiGateway.put(`${REACT_APP_LEAD_URL}/email-signature/${id}`, data);
}

export function deleteSignature(id) {
    return ApiGateway.delete(`${REACT_APP_LEAD_URL}/email-signature/${id}`);
}
