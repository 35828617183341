import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { leadTypes } from '@app/store/customers/customers.store';
import { actions } from '@app/store/modal/modal.store';
import { RootState } from '@app/store/rootDuck';

import ActionButton from '@app/partials/content/ActionButton';
import ModalDefault from '@app/partials/content/modals/Modal.default';

import { actionsNameType, getActions } from '../Helpers/Actions';

type FirstActionsProps = {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
};

const FirstActions = ({ showModal, setShowModal }: FirstActionsProps) => {
    const dispatch = useDispatch();
    const Intl = useIntl();
    const { userData } = useSelector((state: RootState) => state.auth);

    const handleClick = (action: keyof actionsNameType = undefined) => {
        switch (action) {
            case 'DEALERSHIP_VISIT':
                dispatch(actions.modalChange('visit'));
                break;
            case 'CALL':
                dispatch(actions.modalChange('call'));
                break;
            case 'SCHEDULE_APPOINTMENT':
                dispatch(actions.modalChange('appointment'));
                break;
            case 'CALLBACK':
                dispatch(actions.modalChange('callback'));
                break;
            case 'DECLARE_PURCHASE_ORDER':
                dispatch(actions.modalChange('purchase'));
                break;
            case 'SEND_EMAIL':
                dispatch(actions.modalChange('mail'));
                break;
            case 'LOCAL_COMMERCE':
                dispatch(actions.modalChange('local_commerce'));
                break;
            case 'LOCAL_APV':
                dispatch(actions.modalChange('local_apv'));
                break;
            case 'CONTRACT_SECURYCAR':
                dispatch(actions.modalChange('contract_securycar'));
                break;
            case 'LOCAL_HOME_CHARGE':
                dispatch(actions.modalChange('local_home_charge'));
                break;
            case 'APV_IN_STORE':
                dispatch(actions.modalChange('apv_in_store'));
                break;
            default:
                break;
        }
    };

    const closeFirstActionsModal = () => {
        setShowModal(false);
    };

    const firstActionsBtns = getActions([
        'dealership_visit',
        'call',
        'appointment',
        'callback',
        'order_offer',
        'email',
        'local_commerce',
        'local_apv',
        'contract_securycar',
        'local_home_charge',
        'apv_in_store',
    ]);

    const userHasActions = useMemo(() => {
        const userTypologies = userData?.tags?.typologie_lead || [];

        const excludedRoles = ['conseiller-commercial-services', 'responsable-apv'];

        return !(
            userTypologies.length === 1 &&
            userTypologies[0] === leadTypes.localApv &&
            excludedRoles.includes(userData?.role?.slug)
        );
    }, [userData]);

    if (!userHasActions) {
        return null;
    }

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => closeFirstActionsModal()}
            icon={<i className="la la-2x la-hand-point-right text-primary" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.NEXT_ACTIONS.TITLE' })}
            body={
                <div className={classNames('d-flex flex-wrap justify-content-center gap-4')}>
                    {firstActionsBtns &&
                        firstActionsBtns.length > 0 &&
                        firstActionsBtns.map(([action, properties], key) => (
                            <ActionButton
                                {...properties}
                                key={action}
                                onClick={() => handleClick(action)}
                                className={`flex-grow-1 m-lg-0 m-2 ${
                                    key + 1 < firstActionsBtns.length ? 'mr-lg-6' : ''
                                }`}
                            />
                        ))}
                </div>
            }
            footer={
                <div className="text-center">
                    <Button variant="outline-secondary" onClick={() => closeFirstActionsModal()} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default FirstActions;
