import * as dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';

import StatusLead from '@app/pages/customers/Partials/StatusLead';

import RowInfo from '@app/partials/content/RowInfo';

const RappelClientProject = ({ event }) => {
    const Intl = useIntl();

    return (
        <>
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.RELAUNCH_STATUS' })}
                value={<StatusLead leadStatusUpdated={null} />}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.DATE_RELAUNCH' })}
                value={event?.action?.date && dayjs(event?.action?.date).format('DD/MM/YYYY [à] HH [h] mm')}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.REFERENT' })}
                value={`${event?.user?.firstname} ${event?.user?.lastname}`}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.RESULT' })}
                value={event?.product?.result}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.STORAGE_PLACE' })}
                value={event?.dealership?.usual_name}
            />
        </>
    );
};

export default RappelClientProject;
