import { generatePath } from 'react-router-dom';
import Swal from 'sweetalert2';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { routeTo } from '@app/helpers/RoutesHelper';

import { addUser, associateUser, deleteUser, getUser } from '@app/crud/autouser/user.crud';

import ROUTES from '@app/router/Routes';

import toast from '@app/partials/content/Toast';

import { actions } from './user.store';

export const fetchUser = (id) => (dispatch) => {
    dispatch(actions.fetchUser());

    let user = null;

    if (id) {
        Promise.resolve(getUser(id))
            .then((values) => {
                user = values || null;

                if (user?.data?.error && id) {
                    dispatch(actions.fetchUserFailed(user.result));
                }
            })
            .catch(() => {
                dispatch(actions.fetchUserFailed({}));
            })
            .finally(() => {
                dispatch(
                    actions.fetchedUser({
                        user: user?.data || null,
                    }),
                );
            });
    }

    dispatch(
        actions.fetchedUser({
            user: null,
        }),
    );
};

export const createUser = (params) => (dispatch) => {
    const payload = {
        ...params.data,
        external: true,
        active: true,
    };

    dispatch(actions.createUser(payload));

    Promise.resolve(addUser(payload)).then((values) => {
        params.history.push(
            generatePath(ROUTES.USER.PATH.UPDATE, {
                id: values.id,
            }),
        );
        dispatch(actions.createdUser());
    });
};

export const linkUser = (params) => (dispatch) => {
    dispatch(actions.associateUser());

    if (params.username) {
        Promise.resolve(associateUser(params.username)).then((values) => {
            params.history.push(
                generatePath(ROUTES.USER.PATH.UPDATE, {
                    id: values.id,
                }),
            );
            dispatch(actions.associatedUser());
        });
    }
};

export const unlinkUser = (params) => (dispatch) => {
    Swal.fire({
        title: Intl.formatMessage({ id: 'AUTOUSER.USERS.REMOVE.TITLE' }),
        text: Intl.formatMessage({ id: 'AUTOUSER.USERS.REMOVE.TEXT' }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
        cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
        customClass: {
            confirmButton: 'btn mr-3 btn-primary',
            cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
        preConfirm: () => {
            Swal.showLoading();
            dispatch(actions.deleteUser());

            return deleteUser(params.userId)
                .then((response) => {
                    if (response?.message && response.message === `User ${params.userId} deleted`) {
                        params.history.push(routeTo(ROUTES.USERS.PATH));
                        toast({
                            variant: 'success',
                            message: Intl.formatMessage({ id: 'AUTOUSER.USERS.REMOVE.SUCCESS' }),
                        });
                    } else {
                        toast({
                            variant: 'danger',
                            message: Intl.formatMessage({ id: 'AUTOUSER.USERS.REMOVE.FAILED' }),
                        });
                    }
                })
                .catch(() => {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'AUTOUSER.USERS.REMOVE.FAILED' }),
                    });
                });
        },
    });
};
