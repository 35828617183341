import React from "react";
import Slot from "./Slot";

export default function Slots({unclickable, slots, onSelect, currentSlot}){
    return (
        <div className={`slots ${unclickable ? 'unclickable' : ''}`}>
            {
                slots.map((slot, key) => {
                    return <Slot key={key}
                                 selected={currentSlot === slot.hour}
                                 booked={slot.booked}
                                 onSelect={onSelect}
                                 slot={slot}/>
                })
            }
        </div>
    )
}
