import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import useFetch from '@app/hooks/useFetch';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getDashboardPendingCounters } from '@app/crud/dashboard/dashboard.crud';

import { DashboardContext } from '@app/pages/dashboard/DashboardProvider';
import ROUTES from '@app/router/Routes';

interface DashboardPendingCountersProps {
    className?: string;
    displayApv?: boolean;
}

const DashboardPendingCounters = ({ className, displayApv = true }: DashboardPendingCountersProps) => {
    const { overriddenParams } = useContext(DashboardContext);
    const {
        data: { result: pendingCounters },
        loading,
        fetch,
    } = useFetch({
        fetchAction: getDashboardPendingCounters,
        resultInterceptor: (response) => response,
    });

    useEffect(() => {
        if (overriddenParams) {
            fetch({ params: overriddenParams }).then();
        }
    }, [overriddenParams]);

    if (loading) {
        return <DashboardPendingCounters.Skeleton />;
    }

    return (
        <div className="container">
            <div className="row d-flex justify-content-between align-items-stretch gap-3">
                {pendingCounters?.lead !== null && (
                    <Link
                        className={classNames(
                            'dashboard-lead col-sm justify-content-center align-items-start',
                            { isReversed: pendingCounters?.lead === 0 },
                            className,
                        )}
                        to={{
                            pathname: routeTo(ROUTES.LEADS_ONLINE.PATH, {
                                statusType: 'new',
                            }),
                        }}
                    >
                        <div className="font-weight-bold d-flex align-items-center">
                            <div className="dashboard-lead-pending-counters__total font-weight-bold">
                                {pendingCounters?.lead}
                            </div>
                            <FormattedHTMLMessage id="DASHBOARD.LEAD.TITLE" />
                        </div>
                    </Link>
                )}
                {pendingCounters?.apv !== null && displayApv === true && (
                    <Link
                        className={classNames(
                            'dashboard-lead col-sm justify-content-center align-items-start',
                            { isReversed: pendingCounters?.apv === 0 },
                            className,
                        )}
                        to={{
                            pathname: routeTo(ROUTES.APV_LEADS.PATH, {
                                statusType: 'new',
                            }),
                        }}
                    >
                        <div className="font-weight-bold d-flex align-items-center justify-content-center">
                            <div className="dashboard-lead-pending-counters__total font-weight-bold">
                                {pendingCounters?.apv}
                            </div>
                            <FormattedHTMLMessage id="DASHBOARD.APV.TITLE" />
                        </div>
                    </Link>
                )}

                {pendingCounters?.note !== null && (
                    <Link
                        className={classNames(
                            'dashboard-lead col-sm justify-content-center align-items-start',
                            { isReversed: pendingCounters?.note === 0 },
                            className,
                        )}
                        to={{
                            pathname: routeTo(ROUTES.NOTES.PATH, {
                                status: 'toTake',
                            }),
                        }}
                    >
                        <div className="font-weight-bold d-flex align-items-center justify-content-center">
                            <div className="dashboard-lead-pending-counters__total font-weight-bold">
                                {pendingCounters?.note}
                            </div>
                            <FormattedHTMLMessage id="DASHBOARD.NOTE.TITLE" />
                        </div>
                    </Link>
                )}
            </div>
        </div>
    );
};

DashboardPendingCounters.Skeleton = () => <Skeleton borderRadius="10px" className="w-100 p-15" />;

export default DashboardPendingCounters;
