import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { empty } from '@app/helpers/ToolsHelper';

import { isPublishAutosphere } from '@app/pages/stock/helpers/StockRules';
import StockModalTimeLine from '@app/pages/stock/modals/StockModalTimeLine';
import PublishedLabel from '@app/pages/stock/partials/PublishedLabel';
import UnPublishedLabel from '@app/pages/stock/partials/UnPublishedLabel';

import Label from '@app/partials/content/Label';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';
import Permission from '@app/partials/content/Permission';
import { getPublishDays } from '@app/partials/content/Vehicle/VehicleHelpers';

export default function StockVehiclesPublicationStatusColumn({ vehicle }) {
    const Intl = useIntl();

    const [show, setShowModal] = useState(false);
    const isContextEFB = useSelector((state) => state.auth.userData.context === 'efb');

    const publishTagHandler = () => {
        if (isPublishAutosphere(vehicle) && vehicle.is_booked) {
            return (
                <div>
                    <PublishedLabel />
                    <div className="text-warning">
                        <b>.</b>&nbsp;{Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.IN_PROCESS_BOOKING_FW' })}
                    </div>
                </div>
            );
        } else if (isPublishAutosphere(vehicle, isContextEFB) && !vehicle.is_booked) {
            return (
                <OverlayTooltip
                    label={`STOCK_VEHICLES.TABLE.PUBLICATION_TOOLTIP${isContextEFB ? '_ABE' : ''}`}
                    id="publication"
                >
                    <Label variant="success" className="font-weight-bold">
                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.PUBLISHED' })}
                        &nbsp; ({getPublishDays(vehicle)}
                        &nbsp;
                        {Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.DAYS' })})
                    </Label>
                </OverlayTooltip>
            );
        }

        return (
            <>
                <UnPublishedLabel variant="danger-lite" vehicle={vehicle} />
                <div className="d-flex flex-column">
                    {!empty(vehicle?.anomaly) &&
                        vehicle.anomaly.split(',').map((anomaly, index) => (
                            <span className="text-danger-lite" key={index}>
                                .{' '}
                                {anomaly.includes('Prix') && Number.isInteger(vehicle.price) && vehicle.price === 0
                                    ? Intl.formatMessage({
                                          id: 'STOCK_VEHICLES.TABLE.REASON.NO_PUBLICATION_ZERO_PRICE',
                                      })
                                    : anomaly}
                            </span>
                        ))}
                </div>
            </>
        );
    };

    return (
        <>
            <Row>
                <Col lg={12}>{publishTagHandler()}</Col>
            </Row>
            {vehicle.crvo && (
                <Permission permissions={['STOCK_VIEW_CRVO']}>
                    <Row className="mt-2">
                        <Col lg={12}>
                            <Button
                                variant="primary-gray"
                                className="rounded-0 mb-2 py-1"
                                onClick={() => setShowModal(true)}
                            >
                                <img alt="crvo" src="/media/icons/CRVO-blanc.svg" />
                            </Button>
                        </Col>

                        {vehicle.crvo.description && (
                            <Col lg={12}>
                                <b>.</b>&nbsp;{vehicle.crvo.description}
                            </Col>
                        )}
                        <StockModalTimeLine
                            events={vehicle?.crvo?.events}
                            show={show}
                            setShowModal={setShowModal}
                            title={
                                <FormattedHTMLMessage
                                    id="STOCK_VEHICLE.MODAL.TIMELINE.CRVO.TITLE"
                                    values={{
                                        licenseNumber: vehicle?.license_number,
                                        crvoName: vehicle?.crvo?.site_name,
                                    }}
                                />
                            }
                        />
                    </Row>
                </Permission>
            )}
        </>
    );
}
