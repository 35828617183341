import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { OrderInStore } from '@app/crud/apv/order.type';

import Label from '@app/partials/content/Label';

interface ApvOrderRowContentProps {
    order: OrderInStore;
}

const ApvOrderRowContent: FC<ApvOrderRowContentProps> = ({ order }) => {
    const statusColor = order.orderStatusApvInStore.slug === 'draft' ? 'purple' : 'primary';

    return (
        <div className="d-lg-flex mb-lg-0 mb-2 d-block align-items-center">
            <div className="mb-lg-0 mb-2 d-block">
                <div className="font-size-h3 font-weight-bold text-nowrap">
                    {' '}
                    <FormattedMessage id="CUSTOMERS.APV_ORDER_ROW.TITLE" />
                </div>
                {order.orderStatusApvInStore.slug && (
                    <div className="mt-1 mb-2">
                        <Label
                            className={classNames('font-weight-bold label-lg', {
                                [`label-light-${statusColor}`]: statusColor,
                                'purple-background': statusColor === 'purple',
                            })}
                        >
                            {order.orderStatusApvInStore.name}
                        </Label>
                    </div>
                )}
                {order.dealershipName && <div>{order.dealershipName}</div>}
                {order.updated && (
                    <div>
                        <FormattedMessage id="CUSTOMERS.APV_ORDER_ROW.LAST_UPDATE" />{' '}
                        <span>{dayjs(order.updated).format('DD/MM/YYYY [à] HH [h] mm')}</span>
                    </div>
                )}
                {(order.autouserFirstName || order.autouserLastName) && (
                    <div>
                        <FormattedMessage id="CUSTOMERS.APV_ORDER_ROW.REFERENCE" />
                        {' : '}
                        <span>
                            {order.autouserFirstName} {order.autouserLastName}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ApvOrderRowContent;
