import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { leadActions, leadStatus } from '@app/helpers/LeadHelper';
import { getUserData } from '@app/helpers/UserHelper';

import { addAction } from '@app/crud/customers/customer.crud';

import { useAppSelector } from '@app/hooks';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

import LeadStatusRow from '../../../customers/Partials/LeadStatusRow';

const statusList = [
    {
        slug: leadStatus.closed_won,
        name: 'Clôturé',
        subName: 'Gagné',
        action: leadActions.close_lead_won,
        additionalData: {
            success: true,
        },
    },
    {
        slug: leadStatus.closed_lost,
        name: 'Clôturé',
        subName: 'Perdu',
        action: leadActions.close_lead_lost,
        additionalData: {
            success: false,
        },
    },
];

interface ForceLeadStatusProps {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    refresh: () => void;
}

const ForceLeadStatus = ({ showModal, setShowModal, refresh }: ForceLeadStatusProps) => {
    const Intl = useIntl();
    const [status, setStatus] = useState<number>();
    const [loading, setLoading] = useState(false);
    const { params } = useAppSelector((state) => state.modal);

    const lead = params?.lead;
    const onSubmit = () => {
        setLoading(true);
        const statusData = statusList[status];
        const user = getUserData()?.userData;

        addAction({
            adminForce: true,
            contactId: lead.contact.id,
            lead: {
                id: lead.id,
            },
            user: {
                id: user.id,
                firstname: user.first_name,
                lastname: user.last_name,
                email: user.email,
            },
            reason: 'forced',
            result: 'Forcé par un administrateur',
            success: true,
            actionType: statusData.action,
            ...statusData?.additionalData,
        })
            .then(async () => {
                setLoading(false);
                setShowModal(false);
                refresh?.();
            })
            .catch(() => {
                setLoading(false);
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_LOOSE.ERROR' }),
                });
            });
    };

    useEffect(() => {
        setStatus(undefined);
        setLoading(false);
    }, [params]);

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-sync" />}
            title={Intl.formatMessage({ id: 'CUSTOMER.MODAL.UPDATE_LEAD_STATUS.TITLE' })}
            body={
                <>
                    <Form.Group>
                        <Form.Control
                            as="select"
                            value={status}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStatus(Number(e.target.value))}
                        >
                            <option value="">
                                {Intl.formatMessage({ id: 'CUSTOMER.MODAL.UPDATE_LEAD_STATUS.STATUS.PLACEHOLDER' })}
                            </option>
                            {statusList.map((item, idx) => (
                                <option key={item.name} value={idx}>
                                    {item.name} - {item.subName}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <div className="mt-15 d-flex justify-content-center align-items-center">
                        <div style={{ height: '60px' }} className="text-right">
                            {Intl.formatMessage({ id: 'CUSTOMER.MODAL.UPDATE_LEAD_STATUS.STATUS.ACTUAL' })}
                            <LeadStatusRow
                                leadStatusSlug={lead?.leadStatus?.slug}
                                leadStatusName={lead?.leadStatus?.name}
                                leadStatusSubName={lead?.leadStatus?.subName}
                                className="mt-2 d-flex align-items-center"
                            />
                        </div>
                        <i className="mx-10 las la-sync la-6x" />
                        <div style={{ height: '60px' }}>
                            {Intl.formatMessage({ id: 'CUSTOMER.MODAL.UPDATE_LEAD_STATUS.STATUS.NEW' })}
                            {status !== undefined && (
                                <LeadStatusRow
                                    leadStatusSlug={statusList[status].slug}
                                    leadStatusName={statusList[status].name}
                                    leadStatusSubName={statusList[status].subName}
                                    className="mt-2 d-flex align-items-center"
                                />
                            )}
                        </div>
                    </div>
                </>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" disabled={status === undefined} onClick={onSubmit}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default ForceLeadStatus;
