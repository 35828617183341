import { Column } from 'primereact/column';
import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { toAbsoluteUrl } from '@src/_metronic';

import HoCDataTable from '@app/partials/content/HoCDataTable';
import Label from '@app/partials/content/Label';
import { getPictureTemplate } from '@app/partials/content/Vehicle/VehicleHelpers';

const VehicleDataTable = ({ data, onSelect, filters }) => {
    const Intl = useIntl();

    const imageBody = (vehicle) => (
        <>
            <Image
                className="w-100"
                onError={(e) => {
                    e.currentTarget.src = toAbsoluteUrl('/media/bg/default-veh-bg.svg');
                }}
                style={{
                    height: '80px',
                    objectFit: 'cover',
                }}
                src={getPictureTemplate(vehicle)}
            />
            {vehicle?.is_fluidite && filters?.is_fluidite && (
                <Label className="label-fluidity" variant="closed">
                    {Intl.formatMessage({ id: 'FLUIDITY.NAME' })}
                </Label>
            )}
        </>
    );

    const mileageBody = (vehicle) => (
        <NumberFormat value={vehicle.mileage} thousandSeparator=" " displayType="text" suffix=" km" />
    );

    const registrationBody = (vehicle) => (
        <>
            {vehicle.license_number}
            <br />({vehicle.serial_number})
        </>
    );

    const priceBody = (vehicle) => (
        <NumberFormat
            value={vehicle?.price}
            thousandSeparator=" "
            decimalSeparator=","
            decimalScale="2"
            fixedDecimalScale
            displayType="text"
            suffix=" €"
        />
    );

    const actionBody = (vehicle) => (
        <Button variant="primary" onClick={() => onSelect(vehicle)}>
            Choisir
        </Button>
    );

    const cols = [
        {
            field: 'photo',
            header: Intl.formatMessage({ id: 'CUSTOMERS.VEHICLE.TABLE.HEAD.PICTURE' }),
            style: { width: '150px' },
            className: 'position-relative',
            body: imageBody,
        },
        {
            field: 'brand',
            header: Intl.formatMessage({ id: 'CUSTOMERS.VEHICLE.TABLE.HEAD.BRAND' }),
            className: 'text-center',
            body: (vehicle) => `${vehicle.brand} ${vehicle.model} ${vehicle.version}`,
        },
        {
            field: 'mileage',
            header: Intl.formatMessage({ id: 'CUSTOMERS.VEHICLE.TABLE.HEAD.MILEAGE' }),
            className: 'text-nowrap',
            body: mileageBody,
        },
        {
            field: 'registration',
            header: Intl.formatMessage({ id: 'CUSTOMERS.VEHICLE.TABLE.HEAD.REGISTRATION' }),
            className: 'text-center',
            body: registrationBody,
        },
        {
            field: 'price',
            header: Intl.formatMessage({ id: 'CUSTOMERS.VEHICLE.TABLE.HEAD.PRICE' }),
            className: 'text-nowrap',
            body: priceBody,
        },
        {
            field: 'dealer_usual_name',
            header: Intl.formatMessage({ id: 'CUSTOMERS.VEHICLE.TABLE.HEAD.DEALERSHIP' }),
            className: 'text-center',
        },
        {
            field: 'action',
            header: Intl.formatMessage({ id: 'CUSTOMERS.VEHICLE.TABLE.HEAD.ACTION' }),
            body: actionBody,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <HoCDataTable
            value={data?.vehicles || []}
            totalRecords={100}
            removableSort
            className="p-datatable-striped p-datatable-auto-layout"
            emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
        >
            {dynamicColumns}
        </HoCDataTable>
    );
};

VehicleDataTable.defaultProps = {
    data: [],
};

export default VehicleDataTable;
