import React from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { routeTo } from '@app/helpers/RoutesHelper';

import { mapCustomers } from '@app/crud/mapping/customer.map';

import ROUTES from '@app/router/Routes';
import { fetchCustomersWithAction } from '@app/store/customers/customers.thunk';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';

import CustomersSearch from '@app/components/forms/search/CustomersSearch';

import CustomerDataTable from './CustomerDataTable';

const CustomersActions = () => {
    const Intl = useIntl();
    const stateCustomers = useSelector((state) => ({
        ...state.customer.customers,
        data: mapCustomers(state.customer.customers.data),
    }));
    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: 'customers_actions_search',
    });
    const { handleFilter, handlePagination, handleSort } = useTableHandlers({
        filterStore: 'customers_actions_search',
        fetchAction: fetchCustomersWithAction,
        paramsInterceptor: (params, interceptorFilters) => ({
            ...params,
            page: interceptorFilters.page,
            items: interceptorFilters.items,
            actionActive: true,
        }),
    });
    const actions = stateCustomers.data;
    const { facets } = stateCustomers;
    const { loading } = stateCustomers;

    const columns = {
        actions: {
            body: (customer) => (
                <Link
                    to={routeTo(ROUTES.CUSTOMER.PATH, {
                        id: customer.id,
                    })}
                >
                    <Button className="p-2 px-3" variant="primary">
                        {Intl.formatMessage({ id: 'TRANSLATOR.SHOW' })}
                    </Button>
                </Link>
            ),
        },
    };

    return (
        <FiltersProvider value={filters}>
            <Card>
                <Card.Body className="text-center">
                    {loading && stateCustomers?.count === 0 && (
                        <Loader style={{ width: '5rem', height: '5rem' }} overlay />
                    )}
                    <Permission permissions={['LEAD_SEARCH_CONTACT_ACTION_TODO']}>
                        <Col lg={{ span: 6, offset: 3 }} className="mb-10">
                            <CustomersSearch
                                research="customers_actions_search"
                                className="justify-content-center"
                                colLg={5}
                            />
                        </Col>
                    </Permission>
                    <CustomerDataTable
                        customers={actions}
                        totalRecords={stateCustomers?.count}
                        handleFilter={handleFilter}
                        handlePagination={(e) => {
                            if (!loading) {
                                handlePagination(e);
                            }
                        }}
                        handleSort={handleSort}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        loading={loading}
                        items={items}
                        start={start}
                        facets={facets}
                        columns={columns}
                        filters={filters}
                    />
                </Card.Body>
            </Card>
        </FiltersProvider>
    );
};

export default CustomersActions;
