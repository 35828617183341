import * as dayjs from 'dayjs';
import {Column} from "primereact/column";
import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

import { ucwords } from '@app/helpers/StringHelper';

import {Intl} from "../../../../_metronic/i18n/I18nProvider";
import {routeTo} from "../../../helpers/RoutesHelper";
import useFilters from "../../../hooks/useFilters";
import useTableHandlers from "../../../hooks/useTableHandlers";
import DropdownFilter from "../../../partials/content/DropdownFilter";
import FiltersProvider from "../../../partials/content/FiltersProvider";
import HoCDataTable from "../../../partials/content/HoCDataTable";
import Permission from "../../../partials/content/Permission";
import RefreshTable from "../../../partials/content/RefreshTable";
import SearchInput from "../../../partials/content/SearchInput";
import SelectFilter from "../../../partials/content/SelectFilter";
import {Filters} from "../../../partials/layout/Filters";
import ROUTES from "../../../router/Routes";
import {fetchPackages} from "../../../store/apv/package/package.thunk";

const PackageTable = ({history}) => {
    const { packages, totalRecords, filtersContent, loading } = useSelector((state) => state.package);
    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: 'apv_packages_workshop'
    });
    const { handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: 'apv_packages_workshop',
        fetchAction: fetchPackages,
        paramsInterceptor: (params, filters) => ({
                ...params,
                start: filters.start,
                items: filters.items
            })
    });

    const showAddPackage = () => {
        history.push(routeTo(ROUTES.PACKAGE.PATH.CREATE));
    }

    const selectDropdown = (name, filter) => (
            <DropdownFilter optionLabel="name"
                            filterBy="name"
                            name={name}
                            options={
                                filtersContent?.[filter] &&
                                Object.values(filtersContent[filter]).map((elm) => ({
                                    name: elm.name,
                                    key: elm.id
                                }))
                            }
                            onChange={handleFilter}/>
        )

    const selectFilter = (name, options) => (
            <SelectFilter name={name}
                          options={options}
                          onChange={handleFilter}/>
        )

    const categoryBodyTemplate = (pack) => {
        const getCategories = (category, list) => {
            list = [...list, category.name];

            if (category.packageCategoryParent) {
                return getCategories(category.packageCategoryParent, list);
            }

            return list;
        }
        return getCategories(pack.packageCategory, []).reverse().join(' • ');
    }

    const updatedBodyTemplate = (pack) => dayjs(pack.updated).format('DD/MM/YYYY [à] HH [h] mm')

    const actionsBodyTemplate = (pack) => <Link to={routeTo(ROUTES.PACKAGE.PATH.UPDATE, {
            id: pack.id
        })}>
            <Button className="p-2 px-3" variant="secondary">
                <i className="fa fa-pen"/>
                {Intl.formatMessage({id: "TRANSLATOR.UPDATE"})}
            </Button>
        </Link>

    const optionBodyTemplate = (pack) => (
            <>
                <span className={pack?.packageParent?.id ? 'text-success' : 'text-danger'}>
                    {pack?.packageParent?.id ? ucwords(Intl.formatMessage({id: 'TRANSLATOR.YES'}))
                        : ucwords(Intl.formatMessage({id: 'TRANSLATOR.NO'}))}
                </span>
                {pack?.packageParent?.name &&
                    <>
                        <br/>
                        {`(${pack?.packageParent?.name})`}
                    </>
                }
            </>
        )

    const statusBodyTemplate = (pack) => (
            <span className={pack?.active ? 'text-success' : 'text-danger'}>
                {pack?.active ? Intl.formatMessage({id: "APV.PACKAGE.TABLE.BODY.STATUS.ACTIVE"})
                    : Intl.formatMessage({id: "APV.PACKAGE.TABLE.BODY.STATUS.INACTIVE"})}
            </span>
        )

    const cols = [
        {
            field: 'categories',
            header: Intl.formatMessage({id: "APV.PACKAGE.TABLE.HEAD.FAMILY"}),
            style: {width: '25%'},
            filter: true,
            filterElement: selectDropdown('categoryId', 'categories'),
            body: categoryBodyTemplate
        },
        {
            field: 'name',
            header: Intl.formatMessage({id: "APV.PACKAGE.TABLE.HEAD.PACKAGE"}),
            style: {width: '25%'},
            filter: true,
            filterElement: selectDropdown('packageId', 'packages')
        },
        {
            field: 'option',
            header: Intl.formatMessage({id: "APV.PACKAGE.TABLE.HEAD.OPTION"}),
            style: {width: '25%'},
            filter: true,
            filterElement: selectFilter('packageOption', (
                <>
                    <option value=''>{Intl.formatMessage({id: 'TRANSLATOR.ALL'})}</option>
                    <option value>{ucwords(Intl.formatMessage({id: 'TRANSLATOR.YES'}))}</option>
                    <option value={false}>{ucwords(Intl.formatMessage({id: 'TRANSLATOR.NO'}))}</option>
                </>
            )),
            body: optionBodyTemplate
        },
        {
            field: 'status',
            header: Intl.formatMessage({id: "APV.PACKAGE.TABLE.HEAD.STATUS"}),
            style: {width: '15%'},
            body: statusBodyTemplate
        },
        {
            field: 'promote',
            header: Intl.formatMessage({id: "APV.PACKAGE.TABLE.HEAD.PROMOTE"}),
            style: {width: '15%'},
            filter: true,
            filterElement: selectFilter('promote', (
                <>
                    <option value=''>{Intl.formatMessage({id: 'TRANSLATOR.ALL'})}</option>
                    <option value>{ucwords(Intl.formatMessage({id: 'TRANSLATOR.YES'}))}</option>
                    <option value={false}>{ucwords(Intl.formatMessage({id: 'TRANSLATOR.NO'}))}</option>
                </>
            )),
            body: (_package) => _package?.promote ? 'Oui' : 'Non'
        },
        {
            field: 'updated',
            header: Intl.formatMessage({id: "APV.PACKAGE.TABLE.HEAD.DATEUPDATE"}),
            style: {width: '15%'},
            body: updatedBodyTemplate
        },
        {
            field: 'actions',
            header: Intl.formatMessage({id: "APV.PACKAGE.TABLE.HEAD.ACTIONS"}),
            style: {width: '15%', 'text-align': 'center'},
            body: actionsBodyTemplate
        }
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);


    return (
        <FiltersProvider value={filters}>
                <Filters>
                    <Row>
                        <RefreshTable count={totalRecords} onClick={refresh} />
                        <Col lg={5} offset={2}>
                            <SearchInput onChange={handleFilter}
                                         name="research"
                                         placeholder= {Intl.formatMessage({id: "APV.PACKAGE.SEARCHFIELD"})}/>
                        </Col>
                        <Permission permissions={['APV_ADD_PACKAGE']}>
                            <Button variant="outline-primary" onClick={() => showAddPackage()}>{Intl.formatMessage({id: "APV.PACKAGE.NEW"})}</Button>
                        </Permission>
                    </Row>
                </Filters>
                <HoCDataTable
                    value={packages}
                    totalRecords={totalRecords}
                    lazy
                    loading={loading}
                    paginator
                    onFilter={handleFilter}
                    onPage={handlePagination}
                    onSort={handleSort}
                    paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                    currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                    rows={items}
                    first={start}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    removableSort
                    filterDisplay="row"
                    rowsPerPageOptions={[25,50,100]}
                    emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                >
                    {dynamicColumns}
                </HoCDataTable>
            </FiltersProvider>
    );
};

export default PackageTable;
