interface Role {
    slug: string;
    name: string;
}

interface User {
    role?: Role;
}

interface RoleSlugs {
    [key: string]: string[];
}

interface SortedUsers {
    [key: string]: User[];
}

/**
 * Get users list sorted by role slug
 * @param users User[]
 * @returns User[]
 */
// eslint-disable-next-line import/prefer-default-export
export const getSortedUsersByRoleSlug = (users: User[]): User[] => {
    if (!users) return [];

    const roleSlugs: RoleSlugs = {
        fonctionnels: ['responsable-apv', 'responsable-marketing', 'conseiller-commercial-services', 'assistance'],
        supports: ['responsable-marketing', 'super-administrateur', 'admin-bu-apv', 'admin-bu-vo', 'assistance'],
        apv: ['responsable-apv', 'conseiller-commercial-services'],
    };

    const sortedUsers: SortedUsers = {
        fonctionnels: [],
        supports: [],
        apv: [],
    };

    users.forEach((user) => {
        const slug = user.role?.slug;
        if (slug) {
            if (!roleSlugs.fonctionnels.includes(slug)) {
                sortedUsers.fonctionnels.push(user);
            }
            if (roleSlugs.supports.includes(slug)) {
                sortedUsers.supports.push(user);
            }
            if (roleSlugs.apv.includes(slug)) {
                sortedUsers.apv.push(user);
            }
        }
    });

    return [
        ...sortedUsers.fonctionnels.sort((a, b) => b.role?.name?.localeCompare(a.role?.name)),
        ...sortedUsers.supports.sort((a, b) => a.role?.name?.localeCompare(b.role?.name)),
        ...sortedUsers.apv.sort((a, b) => a.role?.name?.localeCompare(b.role?.name)),
    ];
};
