import { Divider } from 'primereact/divider';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import ErrorForm from '@app/partials/layout/ErrorForm';

import LeadWonBuyVehicle from './Partials/LeadWonBuy';
import LeadWonComment from './Partials/LeadWonComment';

const LeadWonReprise = ({ params, listingParams, leadReasons }) => {
    const [type, setType] = useState(null);
    const { setValue, reset, watch, register, errors } = useFormContext();
    const Intl = useIntl();
    const { reason } = watch(['reason']);

    const handleReasonChange = useCallback(
        (reasonSelected) => {
            if (leadReasons && leadReasons.close_lead_partexchange_won_reason) {
                const selectedReason = leadReasons.close_lead_partexchange_won_reason.leadReasons.find(
                    (elm) => elm.slug === reasonSelected,
                );

                if (selectedReason) {
                    setType(selectedReason?.value?.toUpperCase?.());

                    // Reset selected vehicle
                    setValue('serialNumber', '');
                    setValue('licenseNumber', '');
                    setValue('vehicleInternalId', '');
                }
            }
        },
        [leadReasons],
    );

    useEffect(() => {
        handleReasonChange(reason);
    }, [handleReasonChange, leadReasons, reason]);

    useEffect(() => {
        if (params?.event) {
            reset({
                ...params?.event,
            });
        }
    }, [params, type]);

    return (
        <>
            <Form.Group>
                <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_WON.REPRISE.TYPE' })}</Form.Label>
                <Form.Control
                    as="select"
                    name="reason"
                    defaultValue=""
                    disabled={!!params?.event}
                    className={`${errors.reason ? 'is-invalid' : ''}`}
                    ref={register({
                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    })}
                >
                    <option value="">
                        {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_WON.REPRISE.TYPE.PLACEHOLDER' })}
                    </option>
                    {leadReasons &&
                        leadReasons.close_lead_partexchange_won_reason &&
                        leadReasons.close_lead_partexchange_won_reason.leadReasons.map((leadReason) => (
                            <option key={leadReason.id} value={leadReason.slug}>
                                {leadReason.name}
                            </option>
                        ))}
                </Form.Control>
                <ErrorForm errors={errors} name="reason" />
            </Form.Group>
            <LeadWonComment params={params} />
            {type && (
                <>
                    <Divider className="my-5" type="dashed" />
                    <LeadWonBuyVehicle params={params} listingParams={listingParams} type={type} />
                </>
            )}
        </>
    );
};

export default LeadWonReprise;
