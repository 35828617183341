import React from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';

import useModal from '@app/hooks/useModal';

import CustomerLinkToRPA from '@app/pages/customers/Modals/RPA/CustomerLinkToRPA';

import Modal from '@app/partials/HoC/Modal';

const RPAButton = ({ RPA, isCustomerInRpa, customer }) => {
    const [customerLinkToRPAModal, setCustomerLinkToRPAModal] = useModal(false);
    const Intl = useIntl();

    if (!RPA) return null;

    const errorField = () => {
        Swal.fire({
            title: Intl.formatMessage({ id: 'RPA.MODAL.CUSTOMER_LINK_TO_RPA.CUSTOMER_CONTACT_SEARCH.TITLE' }),
            html: Intl.formatMessage(
                { id: 'RPA.MODAL.CUSTOMER_LINK_TO_RPA.CUSTOMER_CONTACT_SEARCH.SUBTITLE' },
                { contact: customer?.isClient ? 'client' : 'prospect' },
            ),
            icon: 'error',
            confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' }),
            customClass: {
                confirmButton: 'btn mr-3 btn-primary',
            },
            buttonsStyling: false,
        });
    };

    return (
        <>
            <Button
                className={`mr-3 py-1 d-flex font-weight-bold position-relative btn-rpa-${RPA.tool.toLowerCase()}`}
                onClick={customer?.email || customer?.mobile ? () => setCustomerLinkToRPAModal(true) : errorField}
            >
                <i
                    style={{
                        backgroundImage: `url(${RPA.logo})`,
                        backgroundRepeat: 'no-repeat',
                        width: '27px',
                        height: '100%',
                    }}
                    className="p-0"
                />
                {RPA.tool}
                {!isCustomerInRpa && <span className="position-absolute px-2 no-customer-rpa">?</span>}
            </Button>
            <Modal
                As={CustomerLinkToRPA}
                showModal={customerLinkToRPAModal}
                setShowModal={setCustomerLinkToRPAModal}
                RPA={RPA}
                isCustomerInRpa={isCustomerInRpa}
            />
        </>
    );
};

export default RPAButton;
