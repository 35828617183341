import dayjs from 'dayjs';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { PricingEvent, Vehicle } from '@app/store/stock/stock.type';

import Label from '@app/partials/content/Label';

import Price from '@app/components/formatters/price/Price';

import eventStatusHandler from './helpers/EventStatusHandler';
import StockPricingToDoHistoryEventCheckStatus from './StockPricingToDoHistoryEventCheckStatus';

type StockPricingToDoCheckEventProps = {
    vehicle: Vehicle;
    event: PricingEvent;
};

const StockPricingToDoCheckEvent = ({ vehicle, event }: StockPricingToDoCheckEventProps) =>
    event && (
        <div className="d-flex flex-column align-items-start gap-1">
            <div>
                {event.newPrice ? (
                    <>
                        <FormattedMessage id="STOCK.PRICING.HISTORY.OLD_PRICE" />
                        <Price value={event.oldPrice} className="font-weight-bold" />
                        <br />
                        <Label variant={eventStatusHandler(event.status.slug)}>
                            <FormattedMessage id="STOCK.PRICING.CHECK.NEW_PRICE" />
                            &nbsp;
                            <Price value={event.newPrice} className="font-weight-bold" />
                        </Label>
                        &nbsp;&bull;&nbsp;Reco : <Price value={event.recoPrice} />
                    </>
                ) : (
                    <>
                        <FormattedMessage id="STOCK.PRICING.HISTORY.CURRENT_PRICE" />
                        <Price value={vehicle.price} className="font-weight-bold" />
                        <br />
                        <Label variant={eventStatusHandler(event.status.slug)}>
                            <FormattedMessage id="STOCK.PRICING.CHECK.REFUSED_PRICE" />
                            &nbsp;
                            <Price value={event.recoPrice} className="font-weight-bold" />
                        </Label>
                    </>
                )}
                <br />
            </div>
            <div className="font-size-sm">
                <FormattedHTMLMessage
                    id="STOCK.PRICING.CHECK.ACTION_DATE"
                    values={{
                        date: dayjs(event.createdAt.date).format('DD/MM/YYYY [à] HH[h]mm'),
                    }}
                />
            </div>
            {event.newPrice && (
                <div className={`text-${eventStatusHandler(event.status.slug)}`}>
                    <FormattedMessage id="STOCK.PRICING.CHECK.MODIFIED" />
                    <strong>
                        {event.variationNewOld > 0 && '+'}
                        {event.variationNewOld}%
                    </strong>
                </div>
            )}
            <StockPricingToDoHistoryEventCheckStatus event={event} />
        </div>
    );

export default StockPricingToDoCheckEvent;
