import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, withRouter } from 'react-router-dom';

import useFetch from '@app/hooks/useFetch';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getActions } from '@app/crud/apv/mecaplanning.crud';
import { getPrices, getZonesWithChildren } from '@app/crud/smartRepair/smart-repair.crud';

import ROUTES from '@app/router/Routes';
import { fetchPackage, removePackage } from '@app/store/smart-repair/package/package.thunk';

import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';
import SwitchInput from '@app/partials/content/SwitchInput';
import toast from '@app/partials/content/Toast';
import { TreeSelect } from '@app/partials/content/Tree';
import ErrorForm from '@app/partials/layout/ErrorForm';

import { Intl } from '../../../../_metronic/i18n/I18nProvider';

import { formSubmit } from './Form/Form.submit';

const defaultValues = {
    name: '',
    active: false,
    priceTtc: '',
    packageParentId: -1,
    mecaplanningAction: '-1',
    slug: '',
    zoneBody: [],
};

function Package({ history }) {
    const { reset, setValue, register, errors, handleSubmit, control } = useFormContext();
    const params = useParams();
    const dispatch = useDispatch();
    const [zoneBodySelected, setZoneBodySelected] = useState([]);
    const [optionsZoneBody, setOptionsZoneBody] = useState({});
    const [allZones, setAllZones] = useState({});

    const newPackage = typeof params.id === 'undefined';
    const packageSelected = useSelector((state) => state.smartRepair.package);
    const { error, loading } = useSelector((state) => state.package);
    const [isOption, setIsOption] = useState(false);
    const {
        fetch: fetchParentPackages,
        data: parentPackages,
        setData: setParentPackages,
        loading: loadingParentPackage,
    } = useFetch({
        fetchAction: getPrices,
        resultInterceptor: (response) =>
            response?.result?.packages?.filter?.((packageSelectedResult) => !packageSelectedResult?.packageParent) ||
            [],
    });

    useEffect(() => {
        getZonesWithChildren().then((data) => {
            setAllZones(data.result);
        });
    }, []);

    const treeRef = useRef();

    const { data: mecaplanningActions } = useFetch({
        fetchAction: getActions,
        autoFetch: true,
    });

    const onSubmit = (data) => {
        if (data?.zoneBody.length > 0) {
            data.zoneBody = data.zoneBody
                .map((item) => {
                    if (item.startsWith('zone-')) {
                        const index = optionsZoneBody.findIndex((zoneBody) => zoneBody.id === item);
                        if (optionsZoneBody[index]) {
                            return [...optionsZoneBody[index].children.map((child) => child.id)];
                        }
                    }
                    return item;
                })
                .flat();
        }

        formSubmit({
            newPackage,
            data,
            dispatch,
            history,
            id: params.id,
        });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const deletePackage = () => {
        dispatch(
            removePackage({
                id: params.id,
                history,
            }),
        );
    };

    useEffect(() => {
        dispatch(fetchPackage(params.id));

        return () => {
            reset(defaultValues);
        };
    }, [dispatch, reset, params.id]);

    const handleOptionChange = (value) => {
        if (value && !isOption) {
            fetchParentPackages({
                items: 10000,
            });
        } else if (!isOption) {
            setParentPackages([]);
        }

        setIsOption(value);
    };

    const setFormValues = useCallback(() => {
        let mecaplanningAction;
        const packageFetched = packageSelected?.package;

        if (packageFetched?.mecaplanningAction?.actionCode) {
            mecaplanningAction = packageFetched?.mecaplanningAction?.actionCode;
        } else if (typeof packageFetched?.mecaplanningAction === 'string') {
            mecaplanningAction = packageFetched?.mecaplanningAction;
        } else {
            mecaplanningAction = defaultValues.mecaplanningAction;
        }

        reset({
            name: packageFetched?.name || defaultValues.name,
            active: packageFetched?.active || defaultValues.active,
            mecaplanningAction,
            slug: packageFetched?.slug ? packageSelected.package.slug : defaultValues.slug,
            priceTtc: packageFetched?.priceTtc ? packageSelected?.package?.priceTtc : defaultValues.price,
            zoneBody: zoneBodySelected || defaultValues.zoneBody,
        });
    }, [packageSelected, zoneBodySelected, reset]);

    useEffect(() => {
        setFormValues();

        if (packageSelected?.packageParent?.id) {
            handleOptionChange(true);
        }
    }, [setFormValues]);

    useEffect(() => {
        if (error !== null) {
            toast({
                onShow: () => history.push(routeTo(ROUTES.PACKAGES.PATH.ALL)),
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    }, [history, error]);

    useEffect(() => {
        setValue(
            'packageParentId',
            packageSelected?.packageParent?.id ? packageSelected.packageParent.id : defaultValues.packageParentId,
        );
    }, [parentPackages]);

    useEffect(() => {
        if (allZones !== {} && (packageSelected?.package?.zoneBody || newPackage)) {
            const arrayOption = Object.values(allZones).map((zone) => {
                const children = zone?.zoneBodys.map((zoneBody) => {
                    let checkedChildrenOption = false;
                    if (!newPackage) {
                        checkedChildrenOption = packageSelected.package.zoneBody.some(
                            (item) => item.slug === zoneBody.slug,
                        );
                    }

                    return {
                        id: zoneBody.slug,
                        label: zoneBody.name,
                        value: zoneBody.slug,
                        checked: checkedChildrenOption,
                    };
                });

                return {
                    id: `zone-${zone.slug}`,
                    label: zone.name,
                    value: zone.slug,
                    checked: false,
                    children,
                };
            });

            setOptionsZoneBody(arrayOption);

            if (packageSelected?.package?.zoneBody) {
                const arrayZoneBodySelected = packageSelected?.package?.zoneBody.map((item) => item.slug || item);
                setZoneBodySelected(arrayZoneBodySelected);
            }
        }
    }, [packageSelected, allZones, newPackage]);

    return (
        <Form className="package" onSubmit={handleSubmit(onSubmit, onError)}>
            {(loading || loadingParentPackage) && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-wrench" />
                            {Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.VIEW.PACKAGE' })}
                        </Card.Header>
                        <Card.Body className="overflow-v">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group controlId="name">
                                        <Form.Label>
                                            {Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.VIEW.NAME' })}
                                        </Form.Label>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            className={`${errors.name ? 'is-invalid' : ''}`}
                                            ref={register({
                                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                            })}
                                        />
                                        <ErrorForm errors={errors} name="name" />
                                    </Form.Group>
                                    <Form.Group controlId="slug">
                                        <Form.Label>
                                            {Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.VIEW.CODE' })}
                                        </Form.Label>
                                        <Form.Control
                                            name="slug"
                                            type="text"
                                            className={`${errors.slug ? 'is-invalid' : ''}`}
                                            ref={register({
                                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                            })}
                                        />
                                        <ErrorForm errors={errors} name="slug" />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            {Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.VIEW.STATUS' })}
                                        </Form.Label>
                                        <SwitchInput name="active" active={packageSelected?.package?.active || false} />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            {Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.VIEW.ELIGIBLEZONES' })}
                                        </Form.Label>
                                        <Controller
                                            name="zoneBody"
                                            control={control}
                                            rules={{
                                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                            }}
                                            render={(props) => (
                                                <TreeSelect
                                                    data={optionsZoneBody}
                                                    texts={{
                                                        placeholder: Intl.formatMessage({
                                                            id: 'SMARTREPAIR.PACKAGE.VIEW.ELIGIBLEZONES.PLACEHOLDER',
                                                        }),
                                                    }}
                                                    showPartiallySelected
                                                    mode="multiselect"
                                                    ref={treeRef}
                                                    keepOpenOnSelect
                                                    onChange={(currentNode, selectedNodes) => {
                                                        const nodes = selectedNodes.map((node) => node.id);
                                                        props?.onChange(nodes?.length > 0 ? nodes : null);
                                                    }}
                                                />
                                            )}
                                        />
                                        <ErrorForm errors={errors} name="zoneBody" />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            {Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.VIEW.MECAPLANNING_ACTION' })}
                                        </Form.Label>
                                        <Form.Control
                                            name="mecaplanningAction"
                                            as="select"
                                            className={`${errors.mecaplanningAction ? 'is-invalid' : ''}`}
                                            ref={register({
                                                validate: (value) =>
                                                    parseInt(value, 10) === '-1'
                                                        ? Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                                        : true,
                                            })}
                                        >
                                            <option value="-1" disabled>
                                                {Intl.formatMessage({
                                                    id: 'SMARTREPAIR.PACKAGE.VIEW.MECAPLANNING_ACTION.PLACEHOLDER',
                                                })}
                                            </option>
                                            {mecaplanningActions?.length > 1 &&
                                                mecaplanningActions.map((_action) => (
                                                    <option key={_action.action_code} value={_action.action_code}>
                                                        {_action.name}
                                                    </option>
                                                ))}
                                        </Form.Control>
                                        <ErrorForm errors={errors} name="mecaplanningAction" />
                                    </Form.Group>
                                    <Form.Group controlId="priceTtc">
                                        <Form.Label>
                                            {Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.VIEW.PRICE' })}
                                        </Form.Label>
                                        <Controller
                                            name="priceTtc"
                                            defaultValue={defaultValues.priceTtc}
                                            rules={{
                                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                            }}
                                            control={control}
                                            render={(props) => (
                                                <InputGroup className="mb-2">
                                                    <NumberFormat
                                                        value={props.value}
                                                        className={`form-control ${
                                                            errors.financingType ? 'is-invalid' : ''
                                                        } text-right`}
                                                        thousandSeparator=" "
                                                        onValueChange={(values) => {
                                                            props.onChange(values.floatValue);
                                                        }}
                                                    />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text>€</InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            )}
                                        />
                                        <ErrorForm errors={errors} name="priceTtc" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className={newPackage ? 'text-center' : 'text-right'}>
                                    <Link to={routeTo(ROUTES.PACKAGES.PATH.TYPE, { type: 'smartrepair' })}>
                                        <Button variant="outline-primary">
                                            {Intl.formatMessage({ id: 'TRANSLATOR.BACK' })}
                                        </Button>
                                    </Link>
                                    <Permission permissions={['SR_UPDATE_PACKAGE']}>
                                        <Button variant="primary" className="ml-2" type="submit">
                                            {Intl.formatMessage({ id: 'TRANSLATOR.SAVE' })}
                                        </Button>
                                    </Permission>
                                </Col>
                                {!newPackage && (
                                    <Permission permissions={['SR_DELETE_PACKAGE']}>
                                        <Col className="text-right">
                                            <Button variant="outline-danger" onClick={() => deletePackage()}>
                                                {Intl.formatMessage({ id: 'TRANSLATOR.REMOVE' })}
                                            </Button>
                                        </Col>
                                    </Permission>
                                )}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Form>
    );
}

export default withRouter(Package);
