/**
 * Get event state color by order slug
 *
 * @param event
 * @returns {string}
 */
export function getEventStateBySlug(event) {
    let variant = '';

    switch(event.eventType.slug) {
        case 'creation_du_panier':
        case 'annulation_rendez_vous':
        case 'rendez_vous_termine':
        case 'commande_annulee':
        case 'commande_expiree':
        case 'email_client_annulation_de_commande':
        case 'email_atelier_rendez_vous_annule_par_le_call_center':
        case 'remboursement_paiement':
            variant = 'secondary';
            break;
        case 'paiement_refuse':
            variant = 'danger';
            break;
        case 'modification_du_rendez_vous':
        case 'email_client_modification_de_rendez_vous':
        case 'email_atelier_rendez_vous_mis_a_jour_par_le_call_center':
            variant = 'warning';
            break;
        case 'creation_de_la_commande':
        case 'confirmation_paiement':
        case 'confirmation_du_rendez_vous':
        case 'email_client_confirmation_de_commande_et_de_rendez_vous':
        case 'email_atelier_nouveau_rendez_vous':
        case 'email_atelier_nouveau_rendez_vous_via_le_call_center':
            variant = 'success';
            break;
        default:
            variant = 'secondary';
            break;
    }

    return variant;
}
