import React, {useCallback, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import {Intl} from "../../../_metronic/i18n/I18nProvider";
import dayjs from "dayjs";
import Loader from "./Loader";

const defaultProps = {
    'multiple': false,
    'fileSize' : 1,
    'maxAllowedSize': 1024 * 1024,
    'path': null,
    'required': true
}

function PictureUpload(props) {
    props = {...defaultProps, ...props};

    const {register, setValue, clearErrors, errors} = useFormContext();
    const [file, setFile] = useState(props.path);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        setFile(e.target.files[0]);
        e.value = '';
    }

    const removeFile = (key) => {
        setFile(null);
    }

    const updateRules = useCallback(
        () => {
            const rules = {
                validate: (value) => {
                    if (!value?.size)
                        return true;

                    return value.size > props.maxAllowedSize ? Intl.formatMessage({ id: 'FORM.ERROR.FILE_MAX_SIZE' }, { filesize: props.fileSize}) : true;
                }
            };

            if (props.required) {
                rules.required = Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' });
            }

            return rules;
        },
        [props.fileSize, props.maxAllowedSize, props.required],
    );


    useEffect(() => {
        let interval = undefined;

        if (loading) {
            interval = setInterval(() => {
                setFile(`${file.split('?')[0]}?${dayjs().unix()}`);
            }, 5000);
        }

        return () => clearInterval(interval);
    }, [loading, file]);

    useEffect(() => {
        setFile(props.path);
    }, [props.path]);

    useEffect(() => {
        if(typeof file !== 'string') {
            register(props.name, updateRules());
            setValue(props.name, file);
            clearErrors(props.name);
        }
    }, [register, setValue, clearErrors, file, props.name, updateRules]);

    return (
        <div className={`picture-upload picture-upload--single ${errors[props.name] ? 'is-invalid' : ''}`}>
            { !file &&
                <Form.Group className="picture-upload__wrapper" controlId={`file_${props.name}`}>
                    <Form.Label className="picture-upload__title">
                        {Intl.formatMessage({ id: 'FILE.UPLOAD.DRAG.PHOTOS.HERE' })}
                        <br/>
                        (
                            {Intl.formatMessage({ id: 'FILE.UPLOAD.FORMAT.PHOTOS' })}
                            &nbsp;{props.fileSize} Mo)
                        <div className="picture-upload__picture">
                            <i className="picture-upload__logo far fa-image"/>
                        </div>
                    </Form.Label>
                    <Form.Control onChange={(e) => handleInputChange(e)}
                                  className="picture-upload__input"
                                  multiple={props.multiple}
                                  type="file"/>
                </Form.Group>
            }
            <div className={`picture-upload__previews ${!file ? 'picture-upload__previews--empty' : ''}`}>
                <>
                    {loading &&
                        <Loader style={{width: '5rem', height: '5rem'}}
                                message={Intl.formatMessage({ id: 'FILE.UPLOAD.LOADING' })}/>
                    }
                    {file &&
                    <div className={`picture-upload__preview ${loading ? 'picture-upload__preview--loading' : ''}`}>
                        <div className="picture-upload__remove" onClick={() => removeFile()}>
                            <div className="picture-upload__remove-background"></div>
                            <i className="fas fa-times"/>
                        </div>
                        <img className="picture-upload__image"
                             onError={() => setLoading(true)}
                             onLoad={() => setLoading(false)}
                             alt=""
                             src={
                                 typeof file === 'string' ? file : URL.createObjectURL(file)
                             }/>
                    </div>
                    }
                </>
            </div>
        </div>
    );
}

export default PictureUpload;
