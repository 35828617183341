import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {getStorage, setStorage} from "../../../_metronic";
import { addUserConnection } from "../../crud/autouser/user.crud";

const CheckConnection = () => {
    const {userData} = useSelector((state) => state.auth);

    useEffect(() => {
        if (!userData?.id) {
            return;
        }

        if (getStorage('impersonatedBy') !== null) {
            return;
        }

        const handleConnection = async () => {
            const key = 'connectionFor_'+userData.email;

            if (getStorage(key) === null) {
                const response = await addUserConnection();
                if (response.success) {
                    setStorage(key, Date.now());
                }
                return;
            }

            const storageDate = new Date(Number(getStorage(key)));
            const currentDate = new Date();

            if (storageDate.getFullYear() !== currentDate.getFullYear() ||
                storageDate.getMonth() !== currentDate.getMonth() ||
                storageDate.getDate() !== currentDate.getDate()
            ) {
                const response = await addUserConnection();
                if (response.success) {
                    setStorage(key, Date.now());
                }
            }
        };

        const interval = setInterval(() => {
            handleConnection();
        }, 600000); // 10min

        handleConnection();

        return () => {
            clearInterval(interval);
        }
    }, [userData]);

    return null;
};

export default CheckConnection;
