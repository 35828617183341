import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { routeTo } from '@app/helpers/RoutesHelper';

import { addSignature, updateSignature } from '@app/crud/administration/signature.crud';

import ROUTES from '@app/router/Routes';

import toast from '@app/partials/content/Toast';

/**
 * Form submit
 *
 * @param params
 */
export const formSubmit = (params) => {
    const { isCreate, data, history, id } = params;

    if (isCreate) {
        return addSignature(data)
            .then((response) => {
                history.replace(
                    routeTo(ROUTES.SIGNATURE_TEMPLATE.PATH.UPDATE, {
                        id: response?.result?.id,
                    }),
                );
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.ADD.SUCCESS' }),
                });
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.ADD.ERROR' }),
                });
            });
    }
    return updateSignature(id, data)
        .then(() => {
            toast({
                variant: 'success',
                message: Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.UPDATE.SUCCESS' }),
            });
        })
        .catch(() => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.UPDATE.ERROR' }),
            });
        });
};
