import { UserRole } from '@app/crud/autouser/role.types';

import { createRole, updateRole } from '@app/store/autouser/role/role.thunk';

interface FormSubmitParams {
    newRole: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: any;
    data: Omit<UserRole, 'id'>;
    history: unknown;
    id: string;
}

/**
 * Form submit
 *
 * @param params
 */
// eslint-disable-next-line import/prefer-default-export
export const formSubmit = (params: FormSubmitParams) => {
    const { newRole, dispatch, data, history, id } = params;

    if (newRole) {
        dispatch(
            createRole({
                data,
                history,
            }),
        );
    } else {
        dispatch(updateRole(id, data));
    }
};
