import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { ReactElement, useEffect, useState } from 'react';
import { Image, Row } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import { displayPhoneNumber } from '@app/helpers/ToolsHelper';

import { Customer } from '@app/crud/customers/customer.type';

import { useAppSelector } from '@app/hooks';
import LeadStatusRow from '@app/pages/customers/Partials/LeadStatusRow';
import { Lead } from '@app/store/customers/customers.store';

import ActionsHandBand from './ActionsHandBand';

type ActionHandBandProps = {
    customer: Customer;
    lead: Lead;
    actions: ReactElement;
};
const ActionHandBand = ({ customer, lead, actions }: ActionHandBandProps) => {
    const UppercaseLocale = (Locale as string).toUpperCase() as CountryCode;

    const phone = parsePhoneNumberFromString(customer?.phone || '', UppercaseLocale);
    const mobile = parsePhoneNumberFromString(customer?.mobile || '', UppercaseLocale);
    const [isVisiblePhoneNumber, setIsVisiblePhoneNumber] = useState(undefined);
    const { userData } = useAppSelector((state) => state.auth);
    const isAgentFid = userData.role.slug === 'agent-fid';
    const isCentral = customer.leads.some((customerLead) => customerLead.flags.includes('is_central'));

    useEffect(() => {
        setIsVisiblePhoneNumber(displayPhoneNumber(customer));
    }, [customer]);

    const getText = () => {
        if (isAgentFid) {
            if (isCentral) {
                return <FormattedHTMLMessage id="CUSTOMERS.HANDBAND.TREATED_CENTRAL" />;
            }
            return <FormattedHTMLMessage id="CUSTOMERS.HANDBAND.TREATMENT_IN_PROGRESS" />;
        }

        return <FormattedHTMLMessage id="CUSTOMERS.HANDBAND.ACTION_TO_DO" />;
    };

    return (
        <div className="customer-handband my-lg-0 my-2">
            <Row className="font-size-h4 text-white headband__content">
                <div className="d-flex align-items-center col-4">
                    <Image
                        className="customer-handband__pic ml-5"
                        src={
                            isAgentFid
                                ? '/media/pages/customers/bonhomme-agent-fid.svg'
                                : '/media/pages/customers/fiche-client-aucune-activite-en-cours.svg'
                        }
                    />
                    <div className="ml-5 mr-5 border-bottom">{getText()}</div>
                </div>
                {!customer.leads.some((customerLead) =>
                    ['constructeur', 'fid_vo', 'fid_vn'].includes(customerLead.leadType.slug),
                ) && (
                    <ActionsHandBand
                        actionTypes={[
                            'lead_manufacturer',
                            'local_apv',
                            'local_commerce',
                            'local_home_charge',
                            'contract_securycar',
                            'apv_in_store',
                        ]}
                    />
                )}
            </Row>
            <Row className="font-size-h4 text-white headband__content headband__content--scroll">
                {lead?.leadType?.name || ''}
                <LeadStatusRow
                    leadStatusSlug={lead?.leadStatus?.slug}
                    leadStatusName={lead?.leadStatus?.name}
                    leadStatusSubName={lead?.leadStatus?.subName}
                    variant="white"
                    className="d-flex align-items-center"
                />
                <Image
                    className="customer-handband__pic--small ml-5"
                    src="/media/pages/customers/fiche-client-aucune-activite-en-cours.svg"
                />
                <div className="ml-lg-5 ml-0">
                    <FormattedHTMLMessage id="CUSTOMERS.HANDBAND.ACTION_TO_DO" />
                </div>
                <div className="ml-lg-5 ml-0">{actions}</div>
                {(phone?.formatNational?.() || mobile?.formatNational?.()) && isVisiblePhoneNumber && (
                    <div className="ml-lg-5 mx-lg-0 mx-5">
                        <FormattedHTMLMessage id="CUSTOMERS.HANDBAND.PHONE" />
                        <span className="font-weight-bold text-warning">
                            {mobile?.formatNational?.() || phone?.formatNational?.()}
                        </span>
                    </div>
                )}
            </Row>
        </div>
    );
};

export default ActionHandBand;
