import React, { useState } from "react";
import {connect} from "react-redux";

import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import { MenuProvider } from "./MenuContext";

const MenuList = ({ menuConfig }) => {
    const [openedItems, setOpenedItems] = useState([])
    return (
        <MenuProvider value={{openedItems, setOpenedItems}}>
            {menuConfig.aside.items.map((child, index) => (
                    <React.Fragment key={`menuList${index}`}>
                        {child.section && <MenuSection item={child}/>}
                        {child.separator && <MenuItemSeparator item={child}/>}
                        {(child.title || child.translate) && (
                            <MenuItem
                                item={child}
                            />
                        )}
                    </React.Fragment>
                )
            )}
        </MenuProvider>
    )
}

const mapStateToProps = store => ({
    menuConfig: store.builder.menuConfig,
});

export default connect(mapStateToProps)(MenuList);
