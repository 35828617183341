import * as dayjs from 'dayjs';
import React from "react";
import {createIntl, IntlProvider} from "react-intl";
import {useSelector} from "react-redux";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/fr";
import './Primereact';
import './DayJS';

import frMessages from "./messages/fr/index";

const customParseFormat = require('dayjs/plugin/customParseFormat');
const localeData = require('dayjs/plugin/localeData');
const weekday = require('dayjs/plugin/weekday')
const relativeTime = require('dayjs/plugin/relativeTime')
const isToday = require('dayjs/plugin/isToday')

const allMessages = {
  fr: frMessages,
};

/**
 * Global Intl access
 *
 * @type IntlShape
 */
export let Intl = undefined;

/**
 * Global Locale access
 *
 * @type {const}
 */
export let Locale = undefined;
export const availableLanguages = ['fr'];

export default function I18nProvider({ children }) {
  Locale = useSelector(({ i18n }) => i18n.lang);
  const messages = allMessages[Locale];

  //Set local date
  dayjs.locale(Locale);
  dayjs.extend(customParseFormat);
  dayjs.extend(localeData);
  dayjs.extend(weekday);
  dayjs.extend(relativeTime);
  dayjs.extend(isToday);

  //Export intl
  Intl = createIntl({ Locale, messages });

  return (
      <IntlProvider locale={Locale} messages={messages}>
        {children}
      </IntlProvider>
  );
}
