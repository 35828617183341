import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { getOrderStatus, updateOrder } from '@app/crud/apv/order.crud';
import { CompleteCounter } from '@app/crud/apv/order.type';

import { useAppSelector, useFetch } from '@app/hooks';
import { fetchOrder } from '@app/store/apv/order/order.thunk';

import ModalDefault from '@app/partials/content/modals/Modal.default';

interface CancelProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}

const Cancel = ({ showModal, setShowModal }: CancelProps) => {
    const Intl = useIntl();
    const params = useParams<{ id: string }>();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState<number | null>(null);

    const { order } = useAppSelector((state) => state.order);

    const { data, fetch } = useFetch({
        fetchAction: getOrderStatus,
    });

    useEffect(() => {
        fetch();
    }, []);

    const handleClose = () => {
        setShowModal(false);
    };

    const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setStatus(parseInt(event.target.value, 10));
    };

    const onConfirmCancel = () => {
        setLoading(true);

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            confirmButtonText: Intl.formatMessage({ id: 'CONFIRMATION' }),
            showCloseButton: true,
        });

        updateOrder(parseInt(params.id, 10), {
            action: 'canceled',
            orderStatusReasonId: status,
        }).then(() => {
            setStatus(null);
            setShowModal(false);
            swalWithBootstrapButtons.fire(
                `<i class="las la-times-circle fa-2x text-primary"></i> <br /> ${Intl.formatMessage({
                    id: 'SR.ORDER.MODAL.CANCEL.CONFIRM_TITLE',
                })}`,
                Intl.formatMessage({ id: 'SR.ORDER.MODAL.CANCEL.CONFIRM_TXT' }, { order: order?.reference }),
            );
            dispatch(fetchOrder(params.id));
        });
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            onEnter={() => data}
            hideModal={handleClose}
            icon={<i className="las la-wrench la-2x text-primary" />}
            title={Intl.formatMessage({ id: 'APV.ORDER.VIEW.CANCEL' })}
            body={
                <>
                    <span>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.CANCEL.REASON' })}</span>
                    <Form.Group className="mt-2" controlId="exampleForm.SelectCustom">
                        <Form.Control as="select" defaultValue={-1} onChange={handleStatusChange}>
                            <option value={-1} disabled>
                                {Intl.formatMessage({ id: 'SELECT.REASON' })}
                            </option>
                            {data.map((status: CompleteCounter, key: number) => (
                                <option key={key} value={status.id}>
                                    {status.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <div className="text-center">{Intl.formatMessage({ id: 'APV.ORDER.VIEW.CANCEL.VALIDATION' })}</div>
                </>
            }
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button
                        variant="outline-secondary"
                        className="mr-3"
                        style={{ width: '60px' }}
                        onClick={handleClose}
                    >
                        {Intl.formatMessage({ id: 'TRANSLATOR.NO' })}
                    </Button>
                    <Button
                        variant="primary"
                        className="position-relative"
                        disabled={!status || loading}
                        style={{ width: '60px' }}
                        onClick={onConfirmCancel}
                    >
                        {Intl.formatMessage({ id: 'TRANSLATOR.YES' })}
                    </Button>
                </div>
            }
        />
    );
};

export default Cancel;
