import {createPackage, updatePackage} from "../../../../store/apv/package/package.thunk";

/**
 * Handle prices updates
 *
 * @param data
 */
const handlePrices = (data) => {
    if (data?.prices) {
        data.prices = JSON.stringify(data.prices);
    }
}

/**
 * Form submit
 *
 * @param params
 */
export const formSubmit = (params) => {
    const {newPackage, dispatch, data, history, id} = params;

    handlePrices(data);

    if (newPackage) {
        dispatch(createPackage({
            data,
            history
        }));
    } else {
        dispatch(updatePackage(id, data));
    }
}
