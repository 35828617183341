import classNames from 'classnames';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getOriginIdBMW } from '@app/helpers/LeadHelper';

import { getActions } from '@app/pages/customers/Helpers/Actions';
import useLead from '@app/pages/customers/Hooks/useLead';
import { actions } from '@app/store/modal/modal.store';

import ActionButton from '@app/partials/content/ActionButton';
import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import Permission from '@app/partials/content/Permission';

const NextActions = ({ showModal, setShowModal }) => {
    const Intl = useIntl();
    const dispatch = useDispatch();
    const { params } = useSelector((state) => state.modal);
    const [isLoading, setIsLoading] = useState(false);

    const lead = useLead(params?.leadId);
    const listSecondaryActions = lead?.nextActions?.secondary;
    const buttonPrimaryActions = getActions(lead?.nextActions?.primary);
    const buttonSecondaryActions = getActions(listSecondaryActions);

    const isOpportunity = Object.values(listSecondaryActions ?? {}).includes('create_opportunity');
    const labelSecondaryActions = isOpportunity
        ? Intl.formatMessage({ id: 'CUSTOMERS.MODAL.NEXT_ACTIONS.OPPORTUNITY_LABEL' })
        : Intl.formatMessage({ id: 'CUSTOMERS.MODAL.NEXT_ACTIONS.CLOSE' });

    const handleClick = (action = undefined) => {
        switch (action) {
            case 'ASSIGN_TO':
                dispatch(actions.modalChange('assign_to', params));
                break;
            case 'SCHEDULE_APPOINTMENT':
                dispatch(actions.modalChange('appointment', params));
                break;
            case 'CALLBACK':
                dispatch(actions.modalChange('callback', params));
                break;
            case 'CALL':
                dispatch(actions.modalChange('call'));
                break;
            case 'SEND_EMAIL':
                dispatch(actions.modalChange('mail', params));
                break;
            case 'DECLARE_PURCHASE_ORDER':
                dispatch(actions.modalChange('purchase', params));
                break;
            case 'LEAD_WON':
                dispatch(actions.modalChange('lead_won', params));
                break;
            case 'LEAD_LOST':
                dispatch(actions.modalChange('lead_loose', params));
                break;
            case 'CONTACT':
                dispatch(actions.modalChange('consign_contact', params));
                break;
            case 'CONTRACT_EXTENSION':
                dispatch(actions.modalChange('contract_extension', params));
                break;
            case 'LEAD_POSTPONE':
                dispatch(actions.modalChange('lead_postpone', params));
                break;
            case 'CREATE_OPPORTUNITY':
                getOriginIdBMW(lead, setIsLoading).then((leadOriginId) => {
                    if (leadOriginId) {
                        dispatch(actions.modalChange('create_opportunity', { leadOriginId, leadId: params.leadId }));
                    }
                });
                break;
            default:
                break;
        }
    };

    return (
        <ModalDefault
            size={buttonPrimaryActions?.length > 6 ? 'xl' : 'lg'}
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x la-hand-point-right text-primary" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.NEXT_ACTIONS.TITLE' })}
            body={
                <>
                    <div
                        className={classNames('d-flex flex-lg-nowrap flex-wrap justify-content-center mt-lg-5', {
                            'mb-10': buttonSecondaryActions && buttonSecondaryActions?.length > 0,
                            'mb-5': !buttonSecondaryActions || buttonSecondaryActions?.length !== 0,
                        })}
                    >
                        {buttonPrimaryActions &&
                            buttonPrimaryActions?.length > 0 &&
                            buttonPrimaryActions.map(([action, properties], key) => (
                                <ActionButton
                                    {...properties}
                                    key={action}
                                    onClick={() => handleClick(action)}
                                    className={`flex-grow-1 m-lg-0 m-2 px-4 ${
                                        key + 1 < buttonPrimaryActions.length ? 'mr-lg-6' : ''
                                    }`}
                                />
                            ))}
                    </div>
                    {buttonSecondaryActions && buttonSecondaryActions?.length > 0 && (
                        <Permission
                            hasAny
                            permissions={buttonSecondaryActions.reduce(
                                (acc, [, curr]) => [...acc, ...(curr?.permissions || [])],
                                [],
                            )}
                        >
                            <div className="text-center font-size-lg text-secondary">{labelSecondaryActions}</div>
                            <div className="d-flex justify-content-center my-5">
                                {buttonSecondaryActions.map(([action, properties]) => (
                                    <ActionButton
                                        {...properties}
                                        key={action}
                                        onClick={() => handleClick(action)}
                                        style={{
                                            minWidth: '130px',
                                        }}
                                        className="mx-2 px-4 mx-lg-3"
                                    />
                                ))}
                            </div>
                        </Permission>
                    )}
                    {isLoading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                </>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.PREVIOUS' })}
                    </Button>
                </div>
            }
        />
    );
};

export default NextActions;
