import React, {useContext, useEffect} from 'react';
import {Button} from "react-bootstrap";
import {FiltersContext} from '../FiltersProvider';

const ButtonsFilter = (
    {
        name,
        values,
        onChange,
        defaultValue,
        className,
        activeVariant,
        inactiveVariant,
        unCheckable,
        suffix,
        resetFilter
    }) => {
    const filters = useContext(FiltersContext);

    const isChecked = currentValue => filters[name]?.value === currentValue;

    useEffect(() => {
        const hasFilter = filters?.[name] && values.map(({value}) => value).includes(filters?.[name]?.value);

        if (!hasFilter && defaultValue) {
            handleClick(defaultValue?.key, defaultValue?.value, defaultValue?.id);
        }
    }, [defaultValue, filters, onChange]);


    const handleClick = (key, value, id) => {
        if (isChecked(value) && unCheckable && resetFilter) {
            resetFilter(name)
        } else {
            onChange({
                filters: {
                    [name]: {
                        suffix,
                        id,
                        key,
                        value
                    }
                }
            }, defaultValue);
        }
    }

    return (
        values.map(({key, value, id, icon}) => {
            return (
            <Button
                key={key}
                variant={isChecked(value) ? activeVariant : inactiveVariant}
                className={className}
                onClick={() => handleClick(key, value, id)}
            >
                {icon && <i className={`las la-${icon}`}/>}
                {key}
            </Button>)
        })
    );
};

ButtonsFilter.defaultProps = {
    activeVariant: 'primary',
    inactiveVariant: 'secondary',
    unCheckable: true,
    suffix: null
}

export default ButtonsFilter;
