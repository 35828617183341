import { Dealership } from '@app/crud/autouser/dealerships.type';

export const getRolesFromDealerships = (dealerships: Dealership[]) => {
    if (!dealerships) return null;
    const roles = {} as { [key: string]: string };
    dealerships.forEach((dealership) => {
        dealership.users.forEach((user) => {
            roles[user.role.slug] = user.role.name;
        });
    });
    return roles;
};

export const apvRole = {
    rapv: 'responsable-apv',
    ccs: 'conseiller-commercial-services',
    agentCall: 'agent-autosphere-contact',
    managerCall: 'manager-autosphere-contact',
};

export const apvRoleReceiver = [apvRole.rapv, apvRole.ccs];
