import React from 'react';

import { Account } from '@app/pages/account';
import AchatCash from '@app/pages/achatCash/AchatCash';
import { Alerts, AlertSubHeader } from '@app/pages/administration/alerts';
import { ActionEmailType, ActionEmailTypes, ActionEmailTypeSubHeader } from '@app/pages/administration/email-template';
import ConsoleLeads from '@app/pages/administration/leads/ConsoleLeads';
import ConsoleRGPD from '@app/pages/administration/rgpd/ConsoleRGPD';
import ConsoleRGPDSubHeader from '@app/pages/administration/rgpd/ConsoleRGPDSubHeader';
import {
    ActionSignatureType,
    ActionSignatureTypes,
    ActionSignatureTypeSubHeader,
} from '@app/pages/administration/signature-template';
import { Agenda } from '@app/pages/agenda';
import { AdditionalService, AdditionalServices, AdditionalServiceSubHeader } from '@app/pages/apv/additionals-services';
import {
    Order as APVInStoreOrder,
    OrderSubHeader as APVInStoreOrderSubHeader,
} from '@app/pages/apv/apvinstore/APVInStoreDetails';
import { Categories, Category, CategorySubHeader } from '@app/pages/apv/categories';
import {
    CommercialOperation,
    CommercialOperations,
    CommercialOperationSubHeader,
} from '@app/pages/apv/commercial-operation';
import { Dealership, Dealerships, DealershipSubHeader } from '@app/pages/apv/dealerships';
import { Orders as ApvInStoreOrders } from '@app/pages/apv/instore_orders';
import {
    Quotations as ApvInStoreQuotations,
    QuotationsSubHeader as ApvInStoreQuotationsSubHeader,
} from '@app/pages/apv/instore_quotations';
import { Leads as APVLeads } from '@app/pages/apv/leads';
import { Order, Orders, OrdersSubHeader, OrderSubHeader } from '@app/pages/apv/orders';
import { Package, Packages, PackageSubHeader } from '@app/pages/apv/packages';
import PackagesSubHeader from '@app/pages/apv/packages/PackagesSubHeader';
import { Applications, ApplicationsSubHeader } from '@app/pages/autouser/applications';
import Application from '@app/pages/autouser/applications/Application';
import DealershipsPerimeter from '@app/pages/autouser/dealerships/DealershipsPerimeter';
import { History } from '@app/pages/autouser/history';
import { Role, Roles, RolesSubHeader } from '@app/pages/autouser/roles';
import { User, Users, UserSubHeader } from '@app/pages/autouser/users';
import { Campaigns, CampaignsSubHeader } from '@app/pages/campaigns';
import { Customer, Customers, CustomersActions, CustomersPortfolio, CustomerSubHeader } from '@app/pages/customers';
import CustomersNotes from '@app/pages/customers/CustomersNotes';
import Dashboard from '@app/pages/dashboard/Dashboard';
import { Fluidities, Fluidity, FluiditySubHeader } from '@app/pages/fluidity';
import LeadsOnline from '@app/pages/leadsOnline/LeadsOnline';
import {
    Order as ManufacturerOrder,
    Orders as ManufacturerOrders,
    OrdersSubHeader as ManufacturerOrdersSubHeader,
    OrderSubHeader as ManufacturerOrderSubHeader,
} from '@app/pages/manufacturer/orders';
import { PackageMappingSubHeader, PackagesMapping } from '@app/pages/manufacturer/package-mapping/';
import {
    Package as ManufacturerPackage,
    PackageSubHeader as ManufacturerPackageSubHeader,
} from '@app/pages/manufacturer/packages';
import SecuryWebContracts from '@app/pages/securyweb/SecurywebContracts';
import SecuryWebReporting from '@app/pages/securyweb/SecurywebReporting';
import { Settings, SettingsSubHeader } from '@app/pages/settings';
import LeadTags from '@app/pages/settings/tags/LeadTags';
import {
    Order as SROrder,
    Orders as SROrders,
    OrdersSubHeader as SROrdersSubHeader,
    OrderSubHeader as SROrderSubHeader,
} from '@app/pages/smartRepair/orders';
import { SRPackage, SRPackageSubHeader } from '@app/pages/smartRepair/packages';
import { Quotation, Quotations, QuotationsSubHeader, QuotationSubHeader } from '@app/pages/smartRepair/quotations';
import {
    StockActionsToDo,
    StockActionsToDoSubHeader,
    StockPricingToDo,
    StockVehicle,
    StockVehicles,
    StockVehiclesSubHeader,
    StockVehicleSubHeader,
} from '@app/pages/stock';

import PageLayout from '@app/partials/layout/PageLayout';

const ROUTES = {
    DASHBOARD: {
        exact: true,
        PATH: '/dashboard',
        permissions: ['PROFILE_VIEW_DASHBOARD'],
        render: () => <PageLayout main={Dashboard} />,
    },
    AGENDA: {
        exact: true,
        PATH: '/agenda',
        permissions: ['LEAD_VIEW_CALENDAR'],
        render: () => <PageLayout main={Agenda} />,
    },
    PACKAGES: {
        exact: true,
        permissions: ['APV_LIST_PACKAGE'],
        PATH: {
            ALL: '/settings/apv/package',
            TYPE: '/settings/apv/package/:type(workshop|smartrepair|manufacturer)',
        },
        render: () => <PageLayout main={Packages} subheader={PackagesSubHeader} />,
    },
    PACKAGE: {
        exact: true,
        permissions: ['APV_LIST_PACKAGE'],
        PATH: {
            CREATE: '/settings/apv/package/workshop/new',
            UPDATE: '/settings/apv/package/workshop/:id(\\d+)',
        },
        render: () => <PageLayout main={Package} subheader={PackageSubHeader} />,
    },
    PACKAGE_SMARTREPAIR: {
        exact: true,
        permissions: ['SR_ADD_PACKAGE', 'SR_UPDATE_PACKAGE'],
        PATH: {
            CREATE: '/settings/apv/package/smartrepair/new',
            UPDATE: '/settings/apv/package/smartrepair/:id([A-Za-z0-9\\-\\=\\_]+)',
        },
        render: () => <PageLayout main={SRPackage} subheader={SRPackageSubHeader} />,
    },
    PACKAGE_MANUFACTURER: {
        exact: true,
        PATH: {
            CREATE: '/settings/apv/package/manufacturer/new',
            UPDATE: '/settings/apv/package/manufacturer/:id([A-Za-z0-9\\-\\=\\_]+)',
        },
        render: () => <PageLayout main={ManufacturerPackage} subheader={ManufacturerPackageSubHeader} />,
    },
    ORDER: {
        exact: true,
        permissions: ['APV_DETAILS_ORDER'],
        PATH: '/orders/:id(\\d+)',
        render: () => <PageLayout main={Order} subheader={OrderSubHeader} />,
    },
    ORDERS: {
        exact: true,
        permissions: ['APV_LIST_ORDER'],
        PATH: {
            ALL: '/orders',
            TYPE: '/orders/:type(commande|panier)',
        },
        render: () => <PageLayout main={Orders} subheader={OrdersSubHeader} />,
    },
    APV_INSTORE_QUOTATIONS: {
        exact: true,
        permissions: ['APV_INSTORE_LIST_ORDER'],
        PATH: {
            ALL: '/apv/instore-quotations',
            TYPE: '/apv/instore-quotations/:type(draft|waiting_for_customer|closed)',
        },
        render: () => <PageLayout main={ApvInStoreQuotations} subheader={ApvInStoreQuotationsSubHeader} />,
    },
    APV_INSTORE_ORDERS: {
        exact: true,
        permissions: ['APV_INSTORE_LIST_ORDER'],
        PATH: '/apv/instore-orders',
        render: () => <PageLayout main={ApvInStoreOrders} />,
    },
    SMARTREPAIR_ORDER: {
        exact: true,
        permissions: ['SR_DETAILS_ORDER'],
        PATH: '/smartrepair/orders/:id([A-Za-z0-9\\-\\=\\_]+)',
        render: () => <PageLayout main={SROrder} subheader={SROrderSubHeader} />,
    },
    SMARTREPAIR_ORDERS: {
        exact: true,
        permissions: ['SR_LIST_ORDER'],
        PATH: {
            ALL: '/smartrepair/orders',
        },
        render: () => <PageLayout main={SROrders} subheader={SROrdersSubHeader} />,
    },
    MANUFACTURER_ORDER: {
        exact: true,
        PATH: '/manufacturer/orders/:id([A-Za-z0-9\\-\\=\\_]+)',
        render: () => <PageLayout main={ManufacturerOrder} subheader={ManufacturerOrderSubHeader} />,
    },
    MANUFACTURER_ORDERS: {
        exact: true,
        PATH: {
            ALL: '/manufacturer/orders',
        },
        render: () => <PageLayout main={ManufacturerOrders} subheader={ManufacturerOrdersSubHeader} />,
    },
    OPERATIONS: {
        exact: true,
        permissions: ['APV_LIST_COMMERCIAL_OPERATION'],
        PATH: '/settings/apv/operations',
        render: () => <PageLayout main={CommercialOperations} />,
    },
    OPERATION: {
        exact: true,
        permissions: ['APV_LIST_COMMERCIAL_OPERATION'],
        PATH: {
            CREATE: '/settings/apv/operations/new',
            UPDATE: '/settings/apv/operations/:id(\\d+)',
        },
        render: () => <PageLayout main={CommercialOperation} subheader={CommercialOperationSubHeader} />,
    },
    ADDITIONAL_SERVICES: {
        exact: true,
        permissions: ['APV_LIST_ADDITIONAL_SERVICE'],
        PATH: '/settings/apv/additional-services',
        render: () => <PageLayout main={AdditionalServices} />,
    },
    ADDITIONAL_SERVICE: {
        exact: true,
        permissions: ['APV_LIST_ADDITIONAL_SERVICE'],
        PATH: {
            CREATE: '/settings/apv/additional-services/new',
            UPDATE: '/settings/apv/additional-services/:id(\\d+)',
        },
        render: () => <PageLayout main={AdditionalService} subheader={AdditionalServiceSubHeader} />,
    },
    DEALERSHIPS: {
        exact: true,
        permissions: ['APV_LIST_PARAMS'],
        PATH: '/settings/apv/dealerships',
        render: () => <PageLayout main={Dealerships} />,
    },
    DEALERSHIP: {
        exact: true,
        permissions: ['APV_VIEW_PARAMS'],
        PATH: '/settings/apv/dealerships/:id',
        render: () => <PageLayout main={Dealership} subheader={DealershipSubHeader} />,
    },
    CATEGORIES: {
        exact: true,
        permissions: ['APV_LIST_CATEGORY'],
        PATH: '/settings/apv/categories',
        render: () => <PageLayout main={Categories} />,
    },
    CATEGORY: {
        exact: true,
        permissions: ['APV_LIST_CATEGORY'],
        PATH: {
            CREATE: '/settings/apv/categories/new',
            UPDATE: '/settings/apv/categories/:id(\\d+)',
        },
        render: () => <PageLayout main={Category} subheader={CategorySubHeader} />,
    },
    PACKAGES_MAPPING: {
        exact: true,
        PATH: '/settings/apv/package-mapping',
        render: () => <PageLayout main={PackagesMapping} subheader={PackageMappingSubHeader} />,
    },
    CUSTOMERS: {
        exact: true,
        PATH: '/customers',
        permissions: ['LEAD_SEARCH_CONTACT'],
        render: () => <PageLayout main={Customers} />,
    },
    SECURYWEB_CONTRACT: {
        exact: true,
        PATH: '/securyweb/contract',
        permissions: ['SECURYCAR_SEARCH_CONTRACT'],
        render: () => <PageLayout main={SecuryWebContracts} />,
    },
    SECURYWEB_REPORTING: {
        exact: true,
        PATH: '/securyweb/reporting',
        permissions: ['SECURYCAR_EXPORT_REPORT'],
        render: () => <PageLayout main={SecuryWebReporting} />,
    },
    ACTIONS: {
        exact: true,
        PATH: '/actions',
        permissions: ['LEAD_LIST_ACTION_TODO_CONTACT'],
        render: () => <PageLayout main={CustomersActions} />,
    },
    PORTFOLIO: {
        exact: true,
        PATH: '/portfolio',
        permissions: ['LEAD_VIEW_PORTFOLIO'],
        render: () => <PageLayout main={CustomersPortfolio} />,
    },
    NOTES: {
        exact: true,
        PATH: '/notes/:status(all|toTake|read)?',
        permissions: ['LEAD_LIST_CUSTOMER_NOTE'],
        render: () => <PageLayout main={CustomersNotes} />,
    },
    CUSTOMER: {
        exact: true,
        PATH: '/customers/:id(\\d+)',
        permissions: ['LEAD_VIEW_CONTACT'],
        render: () => <PageLayout main={Customer} subheader={CustomerSubHeader} />,
    },
    CAMPAIGNS: {
        exact: true,
        PATH: '/campaigns',
        render: () => <PageLayout main={Campaigns} subheader={CampaignsSubHeader} />,
    },
    LEADS_ONLINE: {
        exact: true,
        PATH: '/leads-online/:statusType',
        render: () => <PageLayout main={LeadsOnline} />,
    },
    LEADS_ACHAT_CASH: {
        exact: true,
        PATH: {
            MAIN: '/leads-achat-cash/:statusType',
            ABANDON: '/leads-abandon/:statusType',
        },
        render: () => <PageLayout main={AchatCash} />,
    },
    FLUIDITIES: {
        exact: true,
        PATH: '/fluidity/:status',
        permissions: ['LEAD_LIST_FLUIDITE'],
        render: () => <PageLayout main={Fluidities} subheader={FluiditySubHeader} />,
    },
    FLUIDITY: {
        exact: true,
        PATH: '/fluidity/:status/:id(\\d+)',
        permissions: ['LEAD_VIEW_FLUIDITE'],
        render: () => <PageLayout main={Fluidity} subheader={FluiditySubHeader} />,
    },
    ACCOUNT_SETTINGS: {
        exact: true,
        PATH: '/account/settings',
        permissions: ['LEAD_VIEW_PROFIL'],
        render: () => <PageLayout main={Account} />,
    },
    ACCOUNT_PASSWORD: {
        exact: true,
        PATH: '/account/password',
        hasAnyPermissions: true,
        permissions: ['LEAD_UPDATE_PASSWORD_EXTERNAL_PROFIL', 'LEAD_UPDATE_PASSWORD_INTERNAL_PROFIL'],
        render: () => <PageLayout main={Account} />,
    },
    ACCOUNT_PLANNING: {
        exact: true,
        PATH: '/account/planning',
        permissions: ['LEAD_VIEW_CALENDAR_PROFIL'],
        render: () => <PageLayout main={Account} />,
    },
    ACCOUNT_HISTORY: {
        exact: true,
        PATH: '/account/history',
        permissions: ['LEAD_VIEW_HISTO_PROFIL'],
        render: () => <PageLayout main={Account} />,
    },
    ACCOUNT_PERIMETER: {
        exact: true,
        PATH: '/account/perimeter',
        permissions: ['LEAD_VIEW_SCOPE_PROFIL'],
        render: () => <PageLayout main={Account} />,
    },
    ACCOUNT_TEAM: {
        exact: true,
        PATH: '/account/team',
        permissions: ['LEAD_VIEW_TEAM'],
        render: () => <PageLayout main={Account} />,
    },
    EMAIL_TEMPLATES: {
        exact: true,
        permissions: ['PARAMETER_MANAGE_MAILTEMPLATE'],
        PATH: '/administration/email-templates',
        render: () => <PageLayout main={ActionEmailTypes} />,
    },
    EMAIL_TEMPLATE: {
        exact: true,
        permissions: ['PARAMETER_MANAGE_MAILTEMPLATE'],
        PATH: {
            CREATE: '/administration/email-templates/new',
            UPDATE: '/administration/email-templates/:id(\\d+)',
        },
        render: () => <PageLayout main={ActionEmailType} subheader={ActionEmailTypeSubHeader} />,
    },
    SIGNATURE_TEMPLATES: {
        exact: true,
        permissions: ['PARAMETER_MANAGE_SIGNATURE'],
        PATH: '/administration/signature-templates',
        render: () => <PageLayout main={ActionSignatureTypes} />,
    },
    SIGNATURE_TEMPLATE: {
        exact: true,
        permissions: ['PARAMETER_MANAGE_SIGNATURE'],
        PATH: {
            CREATE: '/administration/signature-templates/new',
            UPDATE: '/administration/signature-templates/:id(\\d+)',
        },
        render: () => <PageLayout main={ActionSignatureType} subheader={ActionSignatureTypeSubHeader} />,
    },
    USERS: {
        exact: true,
        PATH: '/autouser/users',
        permissions: ['AUTOUSER_LIST_USER'],
        render: () => <PageLayout main={Users} />,
    },
    USER: {
        exact: true,
        hasAnyPermissions: true,
        permissions: ['AUTOUSER_CREATE_USER', 'AUTOUSER_EDIT_USER'],
        PATH: {
            PASSWORD: '/autouser/users/:id(\\d+)/password',
            PLANNING: '/autouser/users/:id(\\d+)/planning',
            HISTORY: '/autouser/users/:id(\\d+)/history',
            PERIMETER: '/autouser/users/:id(\\d+)/perimeter',
        },
        render: () => <PageLayout main={User} subheader={UserSubHeader} />,
    },
    APPLICATIONS: {
        exact: true,
        permissions: ['AUTOUSER_LIST_APP'],
        PATH: '/autouser/applications',
        render: () => <PageLayout main={Applications} subheader={ApplicationsSubHeader} />,
    },
    APPLICATION: {
        exact: true,
        permissions: ['AUTOUSER_CREATE_APP', 'AUTOUSER_EDIT_APP'],
        hasAnyPermissions: true,
        PATH: {
            CREATE: '/autouser/application/new',
            UPDATE: '/autouser/application/:id(\\d+)',
        },
        render: () => <PageLayout main={Application} subheader={ApplicationsSubHeader} />,
    },
    ROLES: {
        exact: true,
        permissions: ['AUTOUSER_LIST_ROLE'],
        PATH: '/autouser/roles',
        render: () => <PageLayout main={Roles} />,
    },
    ROLE: {
        exact: true,
        permissions: ['AUTOUSER_CREATE_ROLE', 'AUTOUSER_EDIT_ROLE'],
        hasAnyPermissions: true,
        PATH: {
            CREATE: '/autouser/role/new',
            UPDATE: '/autouser/role/:id(\\d+)',
        },
        render: () => <PageLayout main={Role} subheader={RolesSubHeader} />,
    },
    HISTORY: {
        exact: true,
        PATH: '/autouser/history',
        permissions: ['AUTOUSER_LIST_HISTORY'],
        render: () => <PageLayout main={History} />,
    },
    LEAD_TAGS: {
        exact: true,
        PATH: '/autouser/lead_tags',
        permissions: ['PARAMETER_MANAGE_FASTNOTES'],
        render: () => <PageLayout main={LeadTags} />,
    },
    ALERTS: {
        exact: true,
        PATH: {
            CURRENT: '/settings/global/alerts/',
            ARCHIVE: '/settings/global/alerts/:status(archive)',
        },
        permissions: ['PARAMETER_MANAGE_ALERTS'],
        render: () => <PageLayout main={Alerts} subheader={AlertSubHeader} />,
    },
    DEALERSHIPS_PERIMETER: {
        exact: true,
        PATH: '/autouser/dealerships',
        permissions: ['PARAMETER_MANAGE_DEALERSHIP'],
        render: () => <PageLayout main={DealershipsPerimeter} />,
    },
    SETTINGS: {
        exact: true,
        PATH: '/settings/:slug(global|apv)',
        permissions: ['PARAMETER_ACCESS_MENU'],
        render: () => <PageLayout main={Settings} subheader={SettingsSubHeader} />,
    },
    STOCK_VEHICLES: {
        exact: true,
        PATH: '/stock/vehicles',
        render: () => <PageLayout main={StockVehicles} subheader={StockVehiclesSubHeader} />,
    },
    STOCK_VEHICLE: {
        exact: true,
        PATH: '/stock/vehicle/:sku',
        render: () => <PageLayout main={StockVehicle} subheader={StockVehicleSubHeader} />,
    },
    STOCK_ACTION_TO_DO: {
        exact: true,
        PATH: '/stock/action-to-do/:page',
        render: () => <PageLayout main={StockActionsToDo} subheader={StockActionsToDoSubHeader} />,
    },
    STOCK_PRICING_TO_DO: {
        exact: true,
        PATH: '/stock/pricing/:page(list|check|history)',
        render: () => <PageLayout main={StockPricingToDo} />,
    },
    CONSOLE_LEADS: {
        exact: true,
        PATH: '/administration/console-leads',
        permissions: ['PARAMETER_ACCESS_CONSOLE'],
        render: () => <PageLayout main={ConsoleLeads} />,
    },
    QUOTATION: {
        exact: true,
        permissions: ['DEV_LIST_ORDER'],
        PATH: '/apv/quotations/:orderType/:id',
        render: () => <PageLayout main={Quotation} subheader={QuotationSubHeader} />,
    },
    QUOTATIONS: {
        exact: true,
        permissions: ['DEV_LIST_ORDER'],
        PATH: '/apv/quotations/:orderType(to_be_appraised|waiting_for_customer|closed)?',
        render: () => <PageLayout main={Quotations} subheader={QuotationsSubHeader} />,
    },
    APV_LEADS: {
        exact: true,
        PATH: '/apv/leads/:statusType(all|new|action_to_do|close)?',
        render: () => <PageLayout main={APVLeads} />,
    },
    CONSOLE_RGPD: {
        exact: true,
        PATH: '/administration/console-rgpd',
        permissions: ['PARAMETER_ACCESS_RGPD_CONSOLE'],
        render: () => <PageLayout main={ConsoleRGPD} subheader={ConsoleRGPDSubHeader} />,
    },
    APV_INSTORE_DETAIL: {
        exact: true,
        PATH: '/apv/orders/:id(\\d+)',
        render: () => <PageLayout main={APVInStoreOrder} subheader={APVInStoreOrderSubHeader} />,
    },
};

export default ROUTES;
