import React, {useRef} from 'react';
import classNames from "classnames";
import {usePrevious} from "../../hooks/usePrevious";
import {empty} from "../../helpers/ToolsHelper";

const SWITCH_INACTIVE = false;
const SWITCH_NEUTRAL = null;
const SWITCH_ACTIVE = true;

const TriSwitchInput = ({className, disabled, state, triState, onChange}) => {
    const sliderRef = useRef();
    const prevState = usePrevious(empty(state, [SWITCH_NEUTRAL]) ? SWITCH_INACTIVE : state);

    const handleChange = (e) => {
        switch (state) {
            case SWITCH_INACTIVE:
                onChange(triState ? SWITCH_NEUTRAL : SWITCH_ACTIVE);
                break;
            case SWITCH_ACTIVE:
                onChange(triState ? SWITCH_NEUTRAL : SWITCH_INACTIVE);
                break;
            default:
                const sliderBouding = sliderRef.current.getBoundingClientRect();
                const sliderHalfWidth = sliderBouding.width / 2;

                if (empty(state)) {
                    onChange(e.clientX < (sliderBouding.x + sliderHalfWidth) ? SWITCH_INACTIVE : SWITCH_ACTIVE);
                } else {
                    onChange(prevState === SWITCH_INACTIVE ? SWITCH_ACTIVE : SWITCH_INACTIVE)
                }
                break;
        }
    }

    return (
        <div className={classNames('p-inputswitch p-component', className, {
            'p-disabled': disabled,
            'p-inputswitch-checked': state === true
        })}
            onClick={handleChange}>
            <span ref={sliderRef} className="p-inputswitch-slider" />
        </div>
    );
};

TriSwitchInput.defaultProps = {
    disabled: false,
    state: SWITCH_INACTIVE,
    triState: false,
    className: ''
}

export default TriSwitchInput;
