import autouserApplications from './applications.json'
import autouserHistory from './history.json'
import autouserPermissions from './permissions.json'
import autouserRoles from './roles.json'
import autouserUsers from './users.json'
import autouserDealerships from './dealerships.json'
import autouserLogin from './login.json'

const frAutouser = {
  ...autouserApplications,
  ...autouserHistory,
  ...autouserPermissions,
  ...autouserRoles,
  ...autouserUsers,
  ...autouserDealerships,
  ...autouserLogin
}

export default frAutouser;
