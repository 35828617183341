import React from 'react';
import {Col, Row} from 'react-bootstrap';
import StockVehiclesFilterVehicleBlock from './partials/StockVehiclesFilterVehicleBlock';
import StockVehiclesFilterPictureBlock from './partials/StockVehiclesFilterPictureBlock';
import StockVehiclesFilterCanalsBlock from './partials/StockVehiclesFilterCanalsBlock';
import StockVehiclesFilterFundingBlock from './partials/StockVehiclesFilterFundingBlock';

function StockVehiclesFilters(
    {
        facets,
        updatePendingFilters,
        releasePendingFilters,
        updateFilters,
        setIsOpenFiltersBlock
    }) {

    return <>
        <Row className="p-4">
            <StockVehiclesFilterVehicleBlock
                facets={facets}
                updatePendingFilters={updatePendingFilters}
                releasePendingFilters={releasePendingFilters}
                updateFilters={updateFilters}/>
        </Row>
        <Row className="p-4">
            <Col lg={3}>
                <StockVehiclesFilterPictureBlock updatePendingFilters={updatePendingFilters}/>
            </Col>
            <Col lg={6}>
                <StockVehiclesFilterCanalsBlock updatePendingFilters={updatePendingFilters}/>
            </Col>
            <Col lg={3}>
                <StockVehiclesFilterFundingBlock updatePendingFilters={updatePendingFilters}
                                                 releasePendingFilters={releasePendingFilters}
                                                 setIsOpenFiltersBlock={setIsOpenFiltersBlock}/>
            </Col>
        </Row>
    </>
}

export default StockVehiclesFilters;
