const eventStatusHandler = (status: string) => {
    let variant = '';

    switch (status) {
        case 'confirmed':
            variant = 'success';
            break;
        case 'accepted':
            variant = 'success';
            break;
        case 'modified':
            variant = 'warning';
            break;
        case 'refused':
            variant = 'danger';
            break;
        default:
            break;
    }

    return variant;
};

export default eventStatusHandler;
