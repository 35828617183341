import produce from 'immer';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import toast from '@app/partials/content/Toast';

export const actionTypes = {
    AddUser: 'ADD_USER',
    AddedUser: 'ADDED_USER',
    AddUserFailed: 'ADD_USER_FAILED',
    AssociateUser: 'ASSOCIATE_USER',
    AssociatedUser: 'ASSOCIATED_USER',
    AssociateUserFailed: 'ASSOCIATE_USER_FAILED',
    ClearUsers: 'CLEAR_USERS',
    CreateUser: 'CREATE_USER',
    CreatedUser: 'CREATED_USER',
    DeleteUser: 'DELETE_USER',
    DeletedUser: 'DELETED_USER',
    FetchUser: 'FETCH_USER',
    FetchUserFailed: 'FETCH_USER_FAILED',
    FetchedUser: 'FETCHED_USER',
    RemoveUser: 'REMOVE_USER',
    UpdateUser: 'UPDATE_USER',
    UpdatedUser: 'UPDATED_USER',
    UpdateUserFailed: 'UPDATE_USER_FAILED',
};

const initialState = {
    loading: false,
    users: [],
    filtersContent: [],
    totalRecords: 0,
    user: null,
    error: null,
};

export function autoUserUserReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.AddUser: {
            const newState = {
                ...state,
                external: false,
                active: true,
            };
            if (action.payload.search.match(/@/)) {
                newState.user = { email: action.payload.search };
            } else {
                newState.user = { last_name: action.payload.search };
            }

            return {
                ...newState,
            };
        }
        case actionTypes.AssociateUser: {
            return {
                ...state,
            };
        }
        case actionTypes.AssociatedUser: {
            toast({
                variant: 'success',
                message: Intl.formatMessage({ id: 'AUTOUSER.USERS.ASSOCIATE.SUCCESS' }),
            });
            return {
                ...state,
            };
        }
        case actionTypes.FetchUser: {
            return {
                ...state,
                loading: true,
                user: null,
                error: true,
            };
        }
        case actionTypes.FetchedUser: {
            return {
                ...state,
                loading: false,
                user: action.payload.user,
            };
        }
        // case actionTypes.ClearUsers:
        //   return {
        //     ...state
        //   }
        case actionTypes.ClearUsers:
            return produce(state, (draftState) => {
                draftState = {
                    ...draftState,
                    users: [],
                };
            });
        default:
            return state;
    }
}

export const actions = {
    addUser: (payload) => ({ type: actionTypes.AddUser, payload }),
    addedUser: (result) => ({ type: actionTypes.AddedUser, payload: result }),
    addUserFailed: (error) => ({ type: actionTypes.AddUserFailed, payload: error }),
    associateUser: () => ({ type: actionTypes.AssociateUser }),
    associatedUser: () => ({ type: actionTypes.AssociatedUser }),
    createUser: (payload) => ({ type: actionTypes.CreateUser, payload }),
    createdUser: (payload) => ({ type: actionTypes.CreatedUser, payload }),
    fetchUser: () => ({ type: actionTypes.FetchUser }),
    clearUsers: () => ({ type: actionTypes.ClearUsers }),
    deleteUser: () => ({ type: actionTypes.DeleteUser }),
    deletedUser: () => ({ type: actionTypes.DeletedUser }),
    fetchedUser: (result) => ({ type: actionTypes.FetchedUser, payload: result }),
    fetchUserFailed: (error) => ({ type: actionTypes.FetchUserFailed, payload: error }),
    updateUser: () => ({ type: actionTypes.UpdateUser }),
    updatedUser: (result) => ({ type: actionTypes.UpdatedUser, payload: result }),
    updateUserFailed: (error) => ({ type: actionTypes.UpdateUserFailed, payload: error }),
};
