import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import { rootReducer } from './rootDuck';

let composeEnhancers = compose;

if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== 'undefined' && process.env.REACT_APP_ENV_PATH === 'dev') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 });
}

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

export default store;

export type AppDispatch = typeof store.dispatch;
