import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { htmlToString } from '@app/helpers/StringHelper';

import useTags from '@app/pages/customers/Hooks/useTags';
import TagsList from '@app/pages/customers/Partials/TagsList';

import Editor from '@app/partials/content/Editor';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

const EditComment = ({ showModal, setShowModal }) => {
    const methods = useForm();
    const { handleSubmit } = methods;
    const { params } = useSelector((state) => state.modal);
    const [loading, setLoading] = useState(false);
    const Intl = useIntl();
    const { tags, selectedTags, updateTagOnLead, linkTagToLead, unlinkTagToLead } = useTags({
        lead: params?.lead,
    });

    useEffect(() => {
        setLoading(false);
    }, [showModal]);

    const onSubmit = async (data) => {
        setLoading(true);

        data.comment = htmlToString(data.comment);

        if (params?.withTags) {
            await updateTagOnLead();
        }

        params.callback(data);
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-comment" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.EDIT_COMMENT.TITLE' })}
            loading={loading}
            body={
                <FormProvider {...methods}>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'TRANSLATOR.OBJECT' })}&nbsp;:</Form.Label>
                        {params?.withTags && (
                            <div className="mb-8">
                                <TagsList
                                    lead={params?.lead}
                                    tags={tags}
                                    selectedTags={selectedTags}
                                    linkTagToLead={linkTagToLead}
                                    unlinkTagToLead={unlinkTagToLead}
                                />
                            </div>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.EDIT_COMMENT.CONTENT' })}</Form.Label>
                        <Controller
                            name="comment"
                            defaultValue={params?.comment}
                            render={(props) => (
                                <Editor
                                    value={props.value}
                                    onChange={props.onChange}
                                    config={{
                                        minHeight: 150,
                                        buttons: '',
                                        statusbar: false,
                                        placeholder: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.EDIT_COMMENT.TITLE' }),
                                    }}
                                    html={false}
                                />
                            )}
                        />
                    </Form.Group>
                </FormProvider>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" type="submit" onClick={handleSubmit(onSubmit, onError)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default EditComment;
