import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import useModal from '@app/hooks/useModal';

import { updateFinance } from '@app/crud/customers/customer.crud';
import { mapUpdateFinance } from '@app/crud/mapping/finance.map';

import { Financing } from '@app/pages/customers/Modals';
import Comment from '@app/pages/customers/Partials/Comment';

import CollapsedCard from '@app/partials/content/CollapsedCard';
import Expander from '@app/partials/content/Expander';
import RowInfo from '@app/partials/content/RowInfo';
import toast from '@app/partials/content/Toast';

const EventDetailFinancement = ({ event, typeSlug, isLeadClosed, fetchData }) => {
    const [showFinancing, setShowFinancing] = useModal(false);
    const [, setEditCommentModalOpen] = useModal(false, 'comment');
    const { params: listingParams } = useSelector((state) => state.customer);
    const Intl = useIntl();
    const finance = event?.finance;
    const hasFinancing = Object.keys(finance)?.length > 0;

    const updateFinanceData = (data) => {
        const financeUpdated = {
            ...finance,
            ...data,
        };
        updateFinance(event?.informations?.identifier, financeUpdated?.id, mapUpdateFinance(financeUpdated))
            .then(() => {
                fetchData(event?.informations?.identifier);
                setEditCommentModalOpen(false);
                setShowFinancing(false);
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.FUNDING.UPDATE_SUCCESS' }),
                });
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.FUNDING.UPDATE_FAILED' }),
                });
            });
    };

    const getListingParam = (key, data) => {
        const param = listingParams[key].listingParams.find((type) => type.slug === data);
        return param?.name;
    };

    return (
        <>
            <CollapsedCard
                collapse={false}
                header={({ collapsed, setCollapsed }) => (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
                    <div
                        onClick={() => setCollapsed(!collapsed)}
                        className={`card-header d-flex justify-content-between align-items-center cursor-pointer event__origin--${typeSlug} position-relative`}
                    >
                        <div className="flex-grow-1">
                            <div className={`event__origin-bg--${typeSlug} pin`}>
                                <i className="la la-calculator text-white la-lg" />
                            </div>
                            <div className="d-flex justify-content-between align-items-center mr-15">
                                <span className="ml-4">
                                    {Intl.formatMessage({ id: `CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.TITLE` })}
                                </span>
                                {hasFinancing && (
                                    <Button
                                        className="p-0 ml-4"
                                        variant="outline-link d-flex align-items-center"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setShowFinancing();
                                        }}
                                    >
                                        <i className="mr-2 la la-pen p-0 text-muted" />
                                        <div className="text-primary">
                                            <u>
                                                <FormattedHTMLMessage id="TRANSLATOR.UPDATE" />
                                            </u>
                                        </div>
                                    </Button>
                                )}
                            </div>
                        </div>
                        {event?.informations?.identifier &&
                            (!isLeadClosed || (isLeadClosed && !Array.isArray(finance))) && (
                                <Expander collapsed={collapsed} />
                            )}
                    </div>
                )}
            >
                {!hasFinancing ? (
                    <div className="d-flex h-100 align-items-center justify-content-center">
                        <div className="text-dark-50 text-right mr-6">
                            <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.UNTITLED" />
                        </div>
                        <Button variant="primary" onClick={() => setShowFinancing()}>
                            <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.ADD" />
                        </Button>
                    </div>
                ) : (
                    <div className="d-flex flex-column h-100">
                        <RowInfo
                            label={Intl.formatMessage({ id: `CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.INTEREST` })}
                            value={finance?.interest || '-'}
                        />
                        <RowInfo
                            label={Intl.formatMessage({ id: `CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.MODE` })}
                            value={
                                finance?.financialType ? getListingParam('finance_type', finance.financialType) : '-'
                            }
                        />
                        <RowInfo
                            label={Intl.formatMessage({ id: `CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.CAPTIVE` })}
                            value={
                                finance?.financialAgency
                                    ? getListingParam('funding_agency', finance.financialAgency)
                                    : '-'
                            }
                        />
                        <RowInfo
                            label={Intl.formatMessage({ id: `CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.BUDGET` })}
                            value={
                                <NumberFormat
                                    value={finance?.financialBudget || '-'}
                                    decimalSeparator="."
                                    thousandSeparator=" "
                                    allowedDecimalSeparators={[',', '.']}
                                    suffix=" €"
                                    displayType="text"
                                />
                            }
                        />
                        <RowInfo
                            label={<FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.MONTHLY_PAYMENT" />}
                            value={
                                <NumberFormat
                                    value={finance?.financialRent || '-'}
                                    decimalSeparator="."
                                    thousandSeparator=" "
                                    allowedDecimalSeparators={[',', '.']}
                                    suffix=" €"
                                    displayType="text"
                                />
                            }
                        />
                        <RowInfo
                            label={Intl.formatMessage({ id: `CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.BRING` })}
                            value={
                                <NumberFormat
                                    value={finance?.financialInput || '-'}
                                    decimalSeparator="."
                                    thousandSeparator=" "
                                    allowedDecimalSeparators={[',', '.']}
                                    suffix=" €"
                                    displayType="text"
                                />
                            }
                        />
                        <RowInfo
                            label={Intl.formatMessage({ id: `CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.STATUS` })}
                            value={finance?.status || '-'}
                        />
                        <RowInfo
                            label={Intl.formatMessage({ id: `CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.DEADLINE` })}
                            value={finance?.deadline || '-'}
                        />
                        <Comment comment={finance?.comment || '-'} readOnly />
                    </div>
                )}
            </CollapsedCard>
            {event?.informations?.identifier && !isLeadClosed && (
                <Financing
                    showModal={showFinancing}
                    event={event}
                    setShowModal={setShowFinancing}
                    fetchData={fetchData}
                    updateFinance={updateFinanceData}
                />
            )}
        </>
    );
};

export default EventDetailFinancement;
