import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getRpaWorkflow, rpaAction } from '@app/crud/customers/customer.crud';

import { mapVcuCustomer } from '@app/pages/customers/Helpers/Customer';
import { SLUG_IDS } from '@app/pages/customers/Helpers/RPA';
import { fetchCustomer, updateCustomer } from '@app/store/customers/customers.thunk';

import ActionButton from '@app/partials/content/ActionButton';
import Label from '@app/partials/content/Label';
import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

const CustomerLinkToRPA = ({ showModal, setShowModal, RPA, isCustomerInRpa }) => {
    const { data: customer } = useSelector((state) => state.customer.customer);
    const { userData } = useSelector((state) => state.auth);
    const [step, setStep] = useState(null);
    const [selectedContact, setSelectedContact] = useState(null);
    const [rpaContacts, setRpaContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [workflowId, setWorkflowId] = useState(null);
    const [workFlowStatus, setWorkFlowStatus] = useState('send_to_rabbit_queue');
    const [isCustomerHasAllParams, setIsCustomerHasAllParams] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const Intl = useIntl();
    const methods = useForm({
        defaultValues: {
            lastname: customer?.lastname,
            firstname: customer?.firstname,
            phone: customer?.phone,
            mobile: customer?.mobile,
            email: customer?.email,
            city: customer?.city,
        },
    });
    const { register, getValues, errors, handleSubmit, watch } = methods;
    const watchAllFields = watch();
    let interval = null;

    const handleClose = () => {
        setShowModal(false);
    };

    const onSubmit = (data) => {
        const dataMapped = mapVcuCustomer({ ...customer, ...data });
        dataMapped.user = {
            id: userData.id,
            firstname: userData.first_name,
            lastname: userData.last_name,
        };
        const id = customer?.id;
        dispatch(updateCustomer(id, dataMapped, history, true));
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const rpaId = useMemo(
        () =>
            selectedContact
                ? Object.entries(selectedContact).reduce((acc, [key, value]) => {
                      let newAcc = acc;
                      if (SLUG_IDS.includes(key)) {
                          newAcc = value;
                      }

                      return newAcc;
                  }, undefined)
                : customer?.rpaIdentifiants?.find?.(
                      (rpaIdentiant) => rpaIdentiant?.app?.toLowerCase?.() === RPA.tool.toLowerCase(),
                  )?.identifiant,
        [selectedContact, customer],
    );

    const getWorkflow = (idWorkflow) => {
        getRpaWorkflow(idWorkflow)
            .then((response) => {
                const { pending } = response;
                const result = { ...response?.result };
                const { data, rpaStatus, rpaAction: _action } = result;

                if (!pending) {
                    clearInterval(interval);
                }

                if (['successful', 'cancelled'].includes(rpaStatus?.slug)) {
                    setLoading(false);

                    if (_action?.slug === 'contact_search') {
                        setStep(
                            (data && data?.length > 0) || isCustomerHasAllParams ? 'link-customer' : 'create_contact',
                        );
                        setRpaContacts(data || []);
                    }

                    if (_action?.slug === 'contact_create' && rpaStatus?.slug === 'successful') {
                        dispatch(fetchCustomer({ id: customer.id }));
                        setStep('actions');
                    }
                }

                if (rpaStatus?.slug !== workFlowStatus) {
                    setWorkFlowStatus(rpaStatus?.slug);
                }
            })
            .catch(() => {
                clearInterval(interval);
                setShowModal(false);
            });
    };

    const actionInRpa = (action) => {
        if (!customer?.id) return false;

        setLoading(true);

        rpaAction({
            action,
            app: RPA.tool.toLowerCase(),
            contactId: customer?.id,
            ...(workflowId && ['contact_link', 'cancel_action']?.includes(action) && { workflowId }),
            ...(rpaId && ['contact_link', 'contact_update']?.includes(action) && { rpaId }),
        })
            .then((response) => {
                setWorkflowId(response?.result?.id);
                if (action === 'contact_link') {
                    dispatch(fetchCustomer({ id: customer.id }));
                    setStep('actions');
                    setLoading(false);
                } else {
                    setWorkFlowStatus(action === 'cancel_action' ? 'cancel' : response?.result?.rpaStatus?.slug);

                    if (response?.result?.id) {
                        interval = setInterval(() => getWorkflow(response?.result?.id), 5000);
                    } else {
                        dispatch(fetchCustomer({ id: customer.id }));
                        setShowModal(false);
                    }
                }
            })
            .catch(() => {
                setLoading(false);
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({
                        id: `RPA.MODAL.CUSTOMER_LINK_TO_RPA.ERROR_${action.toUpperCase()}.TITLE`,
                    }),
                });
            });
    };

    const checkEmptyParams = (data) => {
        let emptyParams = false;
        for (const [key, value] of Object.entries(data)) {
            if ((key === 'phone' && !value && !data?.mobile) || (key === 'mobile' && !value && !data?.phone)) {
                emptyParams = true;
            } else if (!['mobile', 'phone'].includes(key)) {
                if (!value) {
                    emptyParams = true;
                }
            }
        }
        setIsCustomerHasAllParams(!emptyParams);
    };

    useEffect(() => {
        setStep(isCustomerInRpa ? 'actions' : 'preview-link');
    }, [isCustomerInRpa]);

    useEffect(() => {
        checkEmptyParams(watchAllFields);
    }, [watch]);

    useEffect(() => {
        checkEmptyParams({
            lastname: customer?.lastname,
            firstname: customer?.firstname,
            phone: customer?.phone,
            mobile: customer?.mobile,
            email: customer?.email,
            city: customer?.city,
        });

        if (step === 'create_contact') {
            actionInRpa('contact_create');
        }
    }, [customer]);

    const workFlowStatusLabel = () => {
        let variant = 'primary';
        if (['failed', 'cancelled'].includes(workFlowStatus)) {
            variant = 'danger';
        }
        return (
            <Label variant={variant} className="status-label">
                {Intl.formatMessage({
                    id: `RPA.WORKFLOW_STATUS.${workFlowStatus?.toUpperCase?.()}.TITLE`,
                    defaultMessage: Intl.formatMessage({ id: 'RPA.WORKFLOW_STATUS.REQUEST.TITLE' }),
                })}
            </Label>
        );
    };

    const userInfoTemplate = (user) => (
        <div className="infos-amng">
            <p className="d-flex text-break mb-1">
                <i className="las la-user text-secondary mr-2" />
                <span>
                    <strong>{user?.FullName || `${user?.lastname} ${user?.firstname}`}</strong>
                </span>
            </p>
            <p className="d-flex text-break mb-1">
                <i className="las la-phone text-secondary mr-2" />
                <span>
                    <NumberFormat
                        value={user?.Phone || user?.mobile}
                        displayType="text"
                        format={`${
                            user?.Phone?.startsWith?.('+33') || user?.mobile?.startsWith?.('+33')
                                ? '+## # ## ## ## ##'
                                : '## ## ## ## ##'
                        }`}
                    />
                </span>
            </p>
            <p className="d-flex text-break mb-1">
                <i className="las la-envelope text-secondary mr-2" />
                <span>{user?.Email || user?.email}</span>
            </p>
            <p className="d-flex text-break mb-0">
                <i className="las la-map-marker text-secondary mr-2" />
                <span>
                    {user?.address || ''} {user?.PostalCode || user?.zipcode} {user?.City || user?.city}
                </span>
            </p>
        </div>
    );

    return (
        step && (
            <ModalDefault
                show={showModal}
                hideModal={!loading && handleClose}
                icon={!loading && <i className="la la-2x text-primary la-terminal" />}
                title={
                    !loading &&
                    Intl.formatMessage({
                        id:
                            step === 'create_contact'
                                ? 'RPA.MODAL.CUSTOMER_LINK_TO_RPA.CUSTOMER_CONTACT_CREATE.TITLE'
                                : 'RPA.MODAL.CUSTOMER_LINK_TO_RPA.TITLE',
                    })
                }
                body={
                    <div style={loading ? { width: '500px', height: '300px' } : {}}>
                        {loading ? (
                            <>
                                <Loader style={{ width: '5rem', height: '5rem' }} />
                                {workFlowStatusLabel()}
                            </>
                        ) : (
                            <div className="customer-link-to-rpa-container">
                                <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ fontSize: '20px' }}
                                >
                                    <img src={RPA.logo} height={46} alt="Logo Marque" />
                                    <span className="font-weight-bold">{RPA.tool}</span>
                                </div>
                                {step === 'preview-link' && (
                                    <p className="text-center">
                                        <FormattedHTMLMessage
                                            id="RPA.MODAL.CUSTOMER_LINK_TO_RPA.SUBTITLE"
                                            values={{ rpa: RPA.tool }}
                                        />
                                    </p>
                                )}
                                {['link-customer', 'create_contact'].includes(step) && (
                                    <>
                                        <p className="text-center">
                                            {rpaContacts?.length > 0 ? (
                                                <FormattedHTMLMessage
                                                    id="RPA.MODAL.CUSTOMER_LINK_TO_RPA.SUBTITLE_RESULT"
                                                    values={{ name: `${customer?.lastname} ${customer?.firstname}` }}
                                                />
                                            ) : (
                                                <FormattedHTMLMessage
                                                    id={`RPA.MODAL.CUSTOMER_LINK_TO_RPA.${
                                                        step === 'create_contact'
                                                            ? 'CUSTOMER_CONTACT_CREATE.SUBTITLE'
                                                            : 'SUBTITLE_NORESULT'
                                                    }`}
                                                    values={{ name: `${customer?.lastname} ${customer?.firstname}` }}
                                                />
                                            )}
                                        </p>
                                        <div className="d-flex mt-8">
                                            <div
                                                className={classNames({
                                                    'mx-auto': rpaContacts?.length === 0 && isCustomerHasAllParams,
                                                    'col col-lg-4': rpaContacts?.length > 0 || !isCustomerHasAllParams,
                                                })}
                                            >
                                                <div className="infos-amng-container py-2 px-4">
                                                    <p className="pb-2 text-center font-weight-bold infos-amng-title">
                                                        {Intl.formatMessage({
                                                            id: 'RPA.MODAL.CUSTOMER_LINK_TO_RPA.AMNG_INFO',
                                                        })}
                                                    </p>
                                                    {userInfoTemplate(customer)}
                                                </div>
                                            </div>
                                            {step !== 'create_contact' ? (
                                                <div className="founded-customers">
                                                    {rpaContacts?.map?.((contact, index) => (
                                                        <Form.Check
                                                            type="radio"
                                                            className={`rpa-customer mb-4 ${
                                                                contact === selectedContact
                                                                    ? 'rpa-customer--active'
                                                                    : ''
                                                            }`}
                                                            name="rpaId"
                                                            label={userInfoTemplate(contact)}
                                                            onClick={() => {
                                                                setSelectedContact(contact);
                                                            }}
                                                            id={`contact_${index}`}
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            key={`contact_${index}`}
                                                            value={`contact_${index}`}
                                                        />
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className="d-flex flex-column flex-grow-1 ml-8">
                                                    <FormProvider {...methods}>
                                                        <Form.Group controlId="rpa_lastname">
                                                            <Form.Label>
                                                                {Intl.formatMessage({
                                                                    id: 'CUSTOMERS.MODAL.CREATE.LASTNAME',
                                                                })}
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="lastname"
                                                                className={`${errors.lastname ? 'is-invalid' : ''}`}
                                                                ref={register({
                                                                    required: Intl.formatMessage({
                                                                        id: 'FORM.ERROR.REQUIRED',
                                                                    }),
                                                                })}
                                                            />
                                                            <ErrorForm errors={errors} name="lastname" />
                                                        </Form.Group>
                                                        <Form.Group controlId="rpa_firstname">
                                                            <Form.Label>
                                                                {Intl.formatMessage({
                                                                    id: 'CUSTOMERS.MODAL.CREATE.FIRSTNAME',
                                                                })}
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="firstname"
                                                                className={`${errors.firstname ? 'is-invalid' : ''}`}
                                                                ref={register({
                                                                    required: Intl.formatMessage({
                                                                        id: 'FORM.ERROR.REQUIRED',
                                                                    }),
                                                                })}
                                                            />
                                                            <ErrorForm errors={errors} name="firstname" />
                                                        </Form.Group>
                                                        <Form.Group controlId="rpa_phone">
                                                            <Form.Label>
                                                                {Intl.formatMessage({
                                                                    id: `CUSTOMERS.MODAL.UPDATE.PHONE`,
                                                                })}
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="phone"
                                                                className={`${errors.phone ? 'is-invalid' : ''}`}
                                                                ref={register({
                                                                    validate: (value) =>
                                                                        getValues('mobile') || value
                                                                            ? true
                                                                            : Intl.formatMessage({
                                                                                  id: 'CUSTOMERS.MODAL.CREATE.MOBILE_OR_PHONE',
                                                                              }),
                                                                })}
                                                            />
                                                            <ErrorForm errors={errors} name="phone" />
                                                        </Form.Group>
                                                        <Form.Group controlId="rpa_mobile">
                                                            <Form.Label>
                                                                {Intl.formatMessage({
                                                                    id: `CUSTOMERS.MODAL.UPDATE.MOBILE`,
                                                                })}
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="mobile"
                                                                className={`${errors.mobile ? 'is-invalid' : ''}`}
                                                                ref={register({
                                                                    validate: (value) =>
                                                                        value || getValues('phone')
                                                                            ? true
                                                                            : Intl.formatMessage({
                                                                                  id: 'CUSTOMERS.MODAL.CREATE.MOBILE_OR_PHONE',
                                                                              }),
                                                                })}
                                                            />
                                                            <ErrorForm errors={errors} name="mobile" />
                                                        </Form.Group>
                                                        <Form.Group controlId="rpa_email">
                                                            <Form.Label>
                                                                {Intl.formatMessage({
                                                                    id: 'CUSTOMERS.MODAL.CREATE.EMAIL',
                                                                })}
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="email"
                                                                className={`${errors.email ? 'is-invalid' : ''}`}
                                                                ref={register({
                                                                    required: Intl.formatMessage({
                                                                        id: 'FORM.ERROR.REQUIRED',
                                                                    }),
                                                                })}
                                                            />
                                                            <ErrorForm errors={errors} name="email" />
                                                        </Form.Group>
                                                        <Form.Group controlId="rpa_city">
                                                            <Form.Label>
                                                                {Intl.formatMessage({
                                                                    id: 'CUSTOMERS.MODAL.UPDATE.CITY',
                                                                })}
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="city"
                                                                ref={register({
                                                                    required: Intl.formatMessage({
                                                                        id: 'FORM.ERROR.REQUIRED',
                                                                    }),
                                                                })}
                                                            />
                                                        </Form.Group>
                                                    </FormProvider>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                                {step === 'actions' && (
                                    <div className="d-flex justify-content-around mt-4">
                                        <ActionButton
                                            variant="primary"
                                            icon={<i className="las la-sync-alt la-2x" />}
                                            title={Intl.formatMessage(
                                                { id: 'RPA.ACTION_BUTTON.UPDATE_CUSTOMER.TITLE' },
                                                {
                                                    rpa: RPA.tool,
                                                },
                                            )}
                                            className="flex-grow-1"
                                            onClick={() => actionInRpa('contact_update')}
                                        />
                                        <ActionButton
                                            variant="primary"
                                            icon={<i className="las la-bullhorn la-2x" />}
                                            title={Intl.formatMessage({
                                                id: 'RPA.ACTION_BUTTON.IMPORT_CONSENTS.TITLE',
                                            })}
                                            className="flex-grow-1"
                                            disabled={() => true}
                                            onClick={() => {}}
                                        />
                                        <ActionButton
                                            variant="primary"
                                            icon={<i className="las la-external-link-alt la-2x" />}
                                            title={Intl.formatMessage(
                                                { id: 'RPA.ACTION_BUTTON.OPEN_RPA_DETAIL.TITLE' },
                                                {
                                                    rpa: RPA.tool,
                                                },
                                            )}
                                            className="flex-grow-1"
                                            onClick={() => {
                                                window.open(
                                                    `https://networkcty.force.com/NestSFA/s/account/${rpaId}`,
                                                    '_blank',
                                                );
                                            }}
                                        />
                                        <ActionButton
                                            variant="primary"
                                            icon={<i className="las la-unlink la-2x" />}
                                            title={Intl.formatMessage({ id: 'RPA.ACTION_BUTTON.DELETE_LINK.TITLE' })}
                                            className="flex-grow-1"
                                            onClick={() => actionInRpa('contact_unlink')}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                }
                footer={
                    loading ? (
                        ['send_to_rabbit_queue', 'submit_to_orchestrator'].includes(workFlowStatus) && (
                            <div className="m-auto">
                                <Button variant="danger" onClick={() => actionInRpa('cancel_action')}>
                                    {Intl.formatMessage({ id: 'RPA.BUTTON.CANCEL_ACTION' })}
                                </Button>
                            </div>
                        )
                    ) : (
                        <>
                            {step === 'preview-link' && (
                                <div className="m-auto">
                                    <Button variant="primary" onClick={() => actionInRpa('contact_search')}>
                                        {Intl.formatMessage({ id: 'TRANSLATOR.CONTINUE' })}
                                    </Button>
                                </div>
                            )}
                            {['link-customer', 'create_contact'].includes(step) && (
                                <div className="text-center w-100">
                                    <Button
                                        variant={step !== 'create_contact' ? 'outline-secondary' : 'primary'}
                                        className="mr-lg-5 mr-0 mb-lg-0 mb-5"
                                        style={{ width: '250px' }}
                                        disabled={step === 'create_contact' && !isCustomerHasAllParams}
                                        onClick={
                                            step === 'create_contact'
                                                ? handleSubmit(onSubmit, onError)
                                                : () =>
                                                      isCustomerHasAllParams
                                                          ? actionInRpa('contact_create')
                                                          : setStep('create_contact')
                                        }
                                    >
                                        {Intl.formatMessage({ id: 'RPA.MODAL.CUSTOMER_LINK_TO_RPA.BUTTON_CREATE' })}
                                    </Button>
                                    {step !== 'create_contact' && rpaContacts?.length > 0 && (
                                        <Button
                                            variant="primary"
                                            style={{ width: '250px' }}
                                            disabled={!selectedContact}
                                            onClick={() => actionInRpa('contact_link')}
                                        >
                                            {Intl.formatMessage({ id: 'RPA.MODAL.CUSTOMER_LINK_TO_RPA.BUTTON_SELECT' })}
                                        </Button>
                                    )}
                                </div>
                            )}
                            {step === 'actions' && (
                                <div className="float-right">
                                    <Button variant="outline-secondary" onClick={handleClose}>
                                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                                    </Button>
                                </div>
                            )}
                        </>
                    )
                }
            />
        )
    );
};

export default CustomerLinkToRPA;
