import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';

import { Customer, VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

type AccountInfoProps = {
    event: VcuMappedEventDetails;
    customer: Customer;
};

const AccountInfo = ({ event, customer }: AccountInfoProps) => {
    const Intl = useIntl();

    return (
        <>
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.INFO.LASTNAME' })}
                value={customer?.lastname || '-'}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.INFO.FIRSTNAME' })}
                value={customer?.firstname || '-'}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DATE_CREATED' })}
                value={
                    event?.informations?.created
                        ? dayjs(event?.informations?.created, 'DD/MM/YYYY - HH [h] mm').format(
                              'DD/MM/YYYY [à] HH [h] mm',
                          )
                        : '-'
                }
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.INFO.EMAIL' })}
                value={event?.informations?.emailAccountAfr || '-'}
            />
        </>
    );
};

export default AccountInfo;
