import { Intl } from '@src/_metronic/i18n/I18nProvider';

import {
    getDealership,
    getDealerships,
    getDealershipsByDepartment,
    updateDealershipById,
} from '@app/crud/apv/dealership.crud';

import toast from '@app/partials/content/Toast';

import { actions } from './dealership.store';

export function fetchDealerships(params) {
    return (dispatch) => {
        dispatch(actions.fetchDealerships());

        getDealerships(params)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(
                        actions.fetchedDealerships({
                            dealerships: response.result,
                            count: response?.count || 0,
                        }),
                    );
                } else {
                    dispatch(actions.fetchDealershipsFailed({}));
                }
            })
            .catch(() => {
                dispatch(actions.fetchDealershipsFailed({}));
            });
    };
}

export function fetchDealership(sourceId) {
    return (dispatch) => {
        dispatch(actions.fetchDealership());

        getDealership(sourceId)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.fetchedDealership(response.result));
                } else {
                    dispatch(actions.fetchDealershipFailed(response.result));
                }
            })
            .catch(() => {
                dispatch(actions.fetchDealershipFailed({}));
            });
    };
}

export function updateDealership(id, data) {
    return (dispatch) => {
        dispatch(actions.updateDealership());

        updateDealershipById(id, data)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.updatedDealership(response.result));
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'APV.DEALERSHIP.UPDATE.SUCCESS' }),
                    });
                } else {
                    dispatch(actions.updateDealershipFailed());
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'APV.DEALERSHIP.UPDATE.FAILED' }),
                    });
                }
            })
            .catch(() => {
                dispatch(actions.updateDealershipFailed());
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'APV.DEALERSHIP.UPDATE.FAILED' }),
                });
            });
    };
}

export function fetchDealershipByDepartment(department) {
    return (dispatch) => {
        dispatch(actions.fetchDealership());

        getDealershipsByDepartment(department)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.fetchedDealership(response.result));
                } else {
                    dispatch(actions.fetchDealershipFailed(response.result));
                }
            })
            .catch(() => {
                dispatch(actions.fetchDealershipFailed({}));
            });
    };
}
