import classNames from 'classnames';
import { Divider } from 'primereact/divider';
import React, { useContext, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import SwitchInput from '@app/partials/content/SwitchInput';

import { EQUAL_ASSIGN, MANUAL_ASSIGN } from '../../helpers/CampaignHelper';
import { OperationContext } from '../../OperationProvider';

import OperationAssignmentsEqualForm from './OperationAssignmentsEqualForm';
import OperationAssignmentsManualForm from './OperationAssignmentsManualForm';

const OperationAssignmentsForm = ({ dealershipId }) => {
    const { operationState, setOperationState } = useContext(OperationContext);
    const dealership = operationState?.dealerships?.[dealershipId];
    const affectedDealers = operationState?.affectedDealers?.[dealershipId] || {};
    const [assignType, setAssignType] = useState(
        Object.values(affectedDealers)?.length > 0 ? MANUAL_ASSIGN : EQUAL_ASSIGN,
    );

    const handleEqualFormUpdate = (dealer) => {
        let selectedDealers = operationState?.selectedDealers?.[dealershipId] || [];

        if (selectedDealers?.includes?.(dealer.id)) {
            selectedDealers = selectedDealers.filter((selectedDealer) => selectedDealer !== dealer.id);
        } else {
            selectedDealers = [...selectedDealers, dealer.id];
        }

        setOperationState({
            ...operationState,
            selectedDealers: {
                ...(operationState?.selectedDealers || {}),
                [dealershipId]: selectedDealers,
            },
        });
    };

    const handleManualFormUpdate = (dealer, contacts) => {
        const dealers = operationState?.affectedDealers?.[dealershipId] || {};

        contacts?.forEach?.((contact) => {
            dealers[contact?.campaignContactId] = dealer.id;
        });

        setOperationState({
            ...operationState,
            affectedDealers: {
                ...(operationState?.affectedDealers || {}),
                [dealershipId]: { ...dealers },
            },
        });
    };

    const resetForm = () => {
        setOperationState({
            ...operationState,
            selectedDealers: {
                ...(operationState?.selectedDealers || {}),
                [dealershipId]: [],
            },
            affectedDealers: {
                ...(operationState?.affectedDealers || {}),
                [dealershipId]: [],
            },
        });
    };
    return (
        <div className="py-4">
            <div className="d-flex justify-content-center">
                <SwitchInput
                    form={false}
                    labelFirst={
                        <div className="font-size-lg font-weight-semibold text-right">
                            <FormattedHTMLMessage id="CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.SWITCH.EQUAL_ASSIGN" />
                        </div>
                    }
                    labelSecond={
                        <div className="font-size-lg font-weight-semibold">
                            <FormattedHTMLMessage id="CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.SWITCH.MANUAL_ASSIGN" />
                        </div>
                    }
                    className={classNames({
                        'switch-input--inactive-label-left-primary': true,
                        'switch-input--inactive-toggle-primary': true,
                        'switch-input--active-label-right-primary': true,
                        'switch-input--active-toggle-primary': true,
                    })}
                    switchClassName="mx-4"
                    active={assignType === MANUAL_ASSIGN || !dealership?.name}
                    disabled={!dealership?.name}
                    onChange={(value) => {
                        setAssignType(value ? MANUAL_ASSIGN : EQUAL_ASSIGN);
                        resetForm();
                    }}
                />
            </div>
            <Divider />
            {assignType === EQUAL_ASSIGN && dealership?.name && (
                <OperationAssignmentsEqualForm dealershipId={dealershipId} onChange={handleEqualFormUpdate} />
            )}
            {(assignType === MANUAL_ASSIGN || !dealership?.name) && (
                <OperationAssignmentsManualForm dealershipId={dealershipId} onChange={handleManualFormUpdate} />
            )}
        </div>
    );
};

export default OperationAssignmentsForm;
