import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useFetch from '@app/hooks/useFetch';
import useVendors from '@app/hooks/useVendors';

import { alphabeticalSort } from '@app/helpers/ArrayHelper';

import { getUserPerimeter } from '@app/crud/autouser/user.crud';

import { setDefaultDealership, updateDatas } from '@app/pages/autouser/users/Helpers/Dealership';
import { getShortRole } from '@app/pages/dashboard/helpers/DashboardHelper';

import ErrorForm from '@app/partials/layout/ErrorForm';

const DealershipVendors = ({
    referent,
    setReferent,
    setReferents,
    setDealership,
    setLoading,
    className,
    dealership,
    labels,
    labelDealership,
    labelVendor,
    dealershipClassName,
    vendorsClassName,
    showClear,
    dealershipEmptyLabel,
    vendorsEmptyLabel,
    showReferent,
    page,
    referentsInterceptor,
    registerPerimeter,
    disableDealership,
    disableDealer,
    isRegistered,
    optionValueDealership,
}) => {
    const { fetchVendors, vendors, loading } = useVendors();
    const { register, setValue, errors } = useFormContext();
    const { userData } = useSelector((state) => state.auth);
    const [selectedDealership, setSelectedDealership] = useState(dealership);
    const Intl = useIntl();
    const { data: perimeter } = useFetch({
        fetchAction: getUserPerimeter,
        resultInterceptor: (response) => updateDatas(response, '', null, null, page),
        axios: false,
        autoFetch: true,
    });

    useEffect(() => {
        if (registerPerimeter) {
            register('perimeter');
        }
    }, [register]);

    useEffect(() => {
        if (selectedDealership) {
            if (setReferent && !isRegistered) {
                setReferent({});
            }
            fetchVendors(selectedDealership?.refDealershipId || selectedDealership);
        }
    }, [selectedDealership]);

    useEffect(() => {
        if (setLoading) {
            setLoading(loading);
        }
    }, [loading]);

    useEffect(() => {
        if (setReferents) {
            setReferents(vendors);
        }
    }, [vendors]);

    useEffect(() => {
        if (Object.keys(perimeter)?.length > 0) {
            setValue('perimeter', perimeter);
            setDefaultDealership(perimeter, userData.default_dealership);
        }
    }, [perimeter]);

    return (
        <>
            <Form.Group className={className}>
                {labels && <Form.Label>{Intl.formatMessage({ id: labelDealership })}</Form.Label>}
                <Dropdown
                    value={selectedDealership}
                    className={classNames(dealershipClassName, {
                        'is-invalid': isRegistered && errors.dealership,
                    })}
                    options={Object.entries(userData.dealerships)
                        .sort(alphabeticalSort('title'))
                        .map(([refDealershipId, userDealership]) => ({
                            ...userDealership,
                            refDealershipId,
                        }))}
                    onChange={(e) => {
                        setSelectedDealership(e?.value);

                        if (setDealership) {
                            setDealership(e?.value);
                        }
                    }}
                    valueTemplate={(userDealership) =>
                        userDealership ? `${userDealership?.title}` : dealershipEmptyLabel
                    }
                    disabled={disableDealership}
                    optionLabel="title"
                    optionValue={optionValueDealership}
                    showClear={showClear}
                    emptyMessage={Intl.formatMessage({ id: 'CUSTOMER.MODAL.ASSIGN.DEALERSHIP.SELECT_EMPTY' })}
                />
                {isRegistered && <ErrorForm errors={errors} name="dealership" />}
            </Form.Group>
            {showReferent && (
                <Form.Group className={className}>
                    {labels && <Form.Label>{Intl.formatMessage({ id: labelVendor })}</Form.Label>}
                    <Dropdown
                        value={referent?.id ? referent : null}
                        className={classNames(vendorsClassName, {
                            'is-invalid': isRegistered && errors.dealer,
                        })}
                        options={referentsInterceptor(vendors)}
                        onChange={(e) => setReferent(e.value)}
                        itemTemplate={(vendor) =>
                            `${vendor?.first_name} ${vendor?.last_name} (${
                                vendor?.role?.shortName ?? getShortRole(vendor)
                            })`
                        }
                        valueTemplate={(vendor) =>
                            vendor
                                ? `${vendor?.first_name} ${vendor?.last_name} (${
                                      vendor?.role?.shortName ?? getShortRole(vendor)
                                  })`
                                : vendorsEmptyLabel
                        }
                        optionLabel="id"
                        showClear={showClear}
                        disabled={disableDealer}
                        emptyMessage={
                            selectedDealership
                                ? Intl.formatMessage({ id: 'CUSTOMER.MODAL.ASSIGN.DEALERSHIP.SELECT_EMPTY' })
                                : Intl.formatMessage({ id: 'CUSTOMER.MODAL.ASSIGN.DEALERSHIP.SELECT_UNKNOWN' })
                        }
                    />
                    {isRegistered && <ErrorForm errors={errors} name="dealer" />}
                </Form.Group>
            )}
        </>
    );
};

DealershipVendors.defaultProps = {
    labels: true,
    dealershipClassName: '',
    vendorsClassName: '',
    showClear: false,
    dealershipEmptyLabel: 'Aucune concession sélectionnée',
    vendorsEmptyLabel: 'Aucun vendeur sélectionné',
    showReferent: true,
    referentsInterceptor: (vendors) => vendors,
    labelDealership: 'CUSTOMER.MODAL.ASSIGN.DEALERSHIP.SELECT',
    labelVendor: 'CUSTOMER.MODAL.ASSIGN.REFERENT.SELECT',
    registerPerimeter: true,
    disableDealership: false,
    disableDealer: false,
    optionValueDealership: '',
};

export default DealershipVendors;
