import React, {useCallback, useEffect, useState} from "react";
import {Button, Col, Form, Tab} from "react-bootstrap";
import PortletTab from "../../../partials/layout/PortletTab";
import {Link, useParams, withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchOperation, removeOperation} from "../../../store/apv/commercial-operation/commercial-operation.thunk";
import toast from "../../../partials/content/Toast";
import Loader from "../../../partials/content/Loader";
import {Intl} from "../../../../_metronic/i18n/I18nProvider";
import {Controller, FormProvider, useForm, useFormContext} from "react-hook-form";
import FormNational from "./form/Form.national";
import FormLocal from "./form/Form.local";
import formSubmit from "./form/Form.Submit";
import {routeTo} from "../../../helpers/RoutesHelper";
import ROUTES from "../../../router/Routes";
import Permission from "../../../partials/content/Permission";

function CommercialOperation({ history }) {
    const {reset, setValue, control, handleSubmit, register} = useFormContext();
    const dispatch = useDispatch();
    const params = useParams();
    const localForm = useForm();
    const nationalForm = useForm();

    const [defaultActiveKey, setDefaultActiveKey] = useState(1);
    const newOperation = typeof params.id === 'undefined';

    const {
        operation,
        settings,
        dealerships,
        error,
        loading
    } = useSelector((state) => state.commercialOperation);

    const showOperations = useCallback(
        () => {
            history.push(routeTo(ROUTES.OPERATIONS.PATH));
        },
        [history],
    );

    useEffect(() => {
        dispatch(fetchOperation(!newOperation ? params.id : null));
    }, [dispatch, newOperation, params.id]);

    useEffect(() => {
        register('commercialOperationTypeId');
    }, [register]);

    useEffect(() => {
        const commercialOperationTypeId = operation?.commercialOperationType?.id || 1;
        reset({
            commercialOperationTypeId
        });
        setDefaultActiveKey(commercialOperationTypeId);
    }, [reset, operation, setDefaultActiveKey]);

    useEffect(() => {
        if (error !== null) {
            //Manage toast
            toast({
                onShow: showOperations,
                variant: 'danger',
                message: Intl.formatMessage({id: 'TRANSLATOR.ERROR'})
            });
        }
    }, [error, showOperations]);

    const onSubmit = data => formSubmit({data, newOperation, dispatch, params, history});

    const onError = error => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({id: 'FORM.ERROR.VALIDATE'})
        });
    }

    const deleteOperation = () => {
        dispatch(removeOperation({
            id: params.id,
            history: history
        }));
    };

    const targetTabHandler = (e) => {
        const id = parseInt(e, 10);
        setValue('commercialOperationTypeId', id);
        setDefaultActiveKey(id);
    };

    const buttonBottom =
        <Form.Row className="mt-4">
            <Col className="text-right">
                <Link to={routeTo(ROUTES.OPERATIONS.PATH)}>
                    <Button variant="outline-primary" onClick={showOperations}>
                        {Intl.formatMessage({ id: 'APV.OPERATION.FORM.ACTION.BACK' })}
                    </Button>
                </Link>
                <Permission permissions={['APV_UPDATE_COMMERCIAL_OPERATION']}>
                    <Button
                        variant="primary"
                        className="ml-2"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                    >
                        {Intl.formatMessage({ id: 'APV.OPERATION.FORM.ACTION.SAVE' })}
                    </Button>
                </Permission>
            </Col>
            <Col className="text-right">
            {!newOperation ?
                <Permission permissions={['APV_DELETE_COMMERCIAL_OPERATION']}>
                    <Button variant="outline-danger" onClick={() => deleteOperation()}>
                        {Intl.formatMessage({ id: 'APV.OPERATION.FORM.ACTION.DELETE' })}
                    </Button>
                </Permission>
                :  <span>&nbsp;</span>
            }
            </Col>
        </Form.Row>
    ;

    return (
        <Form className="operation" onSubmit={handleSubmit(onSubmit, onError)}>
            {loading &&
                <Loader style={{width: '5rem', height: '5rem'}} overlay/>
            }
            <PortletTab
                        activeKey={defaultActiveKey}
                        id="operation-portlet"
                        onClick={targetTabHandler}
            >
                {!newOperation && operation?.commercialOperationType?.id !== 1 ? null :
                    <Tab
                        eventKey={1}
                        title={
                            <span>
                                <i className="far fa-map"/>
                                {Intl.formatMessage({ id: "APV.OPERATION.FORM.TAB.HEAD.NATIONAL" })}
                            </span>
                        }
                    >
                        {defaultActiveKey === 1 ?
                            <Controller
                                control={control}
                                name="nationalForm"
                                defaultValue={nationalForm}
                                render={(props) => (
                                    <FormProvider {...props.value}>
                                        <FormNational
                                            operation={operation}
                                            packages={settings?.package}
                                            additionalServices={settings?.additionalService}
                                            showOperations={showOperations}
                                        >
                                            {buttonBottom}
                                        </FormNational>
                                    </FormProvider>
                                )}/>
                        : null }
                    </Tab>
                }
                {!newOperation && operation?.commercialOperationType?.id !== 2 ? null :
                    <Tab
                        eventKey={2}
                        title={
                            <span>
                            <i className="fas fa-map-marker-alt"/>
                                {Intl.formatMessage({id: "APV.OPERATION.FORM.TAB.HEAD.LOCAL"})}
                        </span>
                        }
                    >
                        {defaultActiveKey === 2 ?
                            <Controller
                                control={control}
                                name="localForm"
                                defaultValue={localForm}
                                render={(props) => (
                                    <FormProvider {...props.value}>
                                        <FormLocal
                                            operation={operation}
                                            packages={settings?.package}
                                            dealerships={dealerships}
                                            showOperations={showOperations}
                                        >
                                            {buttonBottom}
                                        </FormLocal>
                                    </FormProvider>
                                )}/>
                            : null}
                    </Tab>
                }
            </PortletTab>
        </Form>
    )
}

export default withRouter(CommercialOperation);
