import { createStore } from 'redux';

export const actionTypes = {
    SetCounters: 'SET_COUNTERS',
};

const initialState = {};

export function counterReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SetCounters:
            return {
                ...state,
                ...action.payload.counters,
            };
        default:
            return state;
    }
}

export const actions = {
    setCounters: (counters) => ({ type: actionTypes.SetCounters, payload: { counters } }),
};

const counterStore = createStore(counterReducer);
export default counterStore;
