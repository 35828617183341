/**
 * Get state color by slug
 *
 * @param slug
 * @returns {string}
 */
export function getStateBySlug(slug) {
    let variant = '';

    switch (slug) {
        case 'waiting_complement':
        case 'waiting_quote':
        case 'waiting_rdv':
        case 'waiting_payment':
        case 'payment_waiting_confirm':
        case 'payment_refused':
            variant = 'primary';
            break;
        case 'pending':
        case 'new_complement':
        case 'new':
            variant = 'danger';
            break;
        case 'incompatible':
        case 'refused':
        case 'closed_lose':
            variant = 'closed';
            break;
        case 'closed_win':
            variant = 'success';
            break;
        case 'expired':
        case 'canceled':
        case 'email_customer_cancel':
            variant = 'secondary';
            break;
        default:
            variant = 'primary';
            break;
    }

    return variant;
}

export const HIDDEN_STATUS = ['expiree', 'annulee', 'rendez_vous_termine'];
