import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { Divider } from 'primereact/divider';
import React from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

interface Props {
    event: VcuMappedEventDetails;
}
const DetailDmsPrestation = ({ event }: Props) => {
    const Intl = useIntl();
    const { prestationApvDMS } = event;

    function translateValueDataSource(value: string) {
        switch (value) {
            case 'INVOICE':
            case 'F':
                return 'Facture';
            case 'A':
                return 'Avoir';
            case 'CREDIT':
                return 'Crédit';
            default:
                return '-';
        }
    }
    return (
        <div className="d-md-flex justify-content-md-between align-items-md-stretch">
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.EVENT' })}
                    value={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.EVENT.NAME' })}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.DATA.SOURCE' })}
                    value={event?.informations?.source || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.DOCUMENT.TYPE' })}
                    value={translateValueDataSource(event?.informations?.document)}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.INTERVENTION.NUMBER',
                    })}
                    value={prestationApvDMS?.tiNumber || '-'}
                />
                <Divider className="p-divider-dashed" />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.ATELIER' })}
                    value={prestationApvDMS?.dealership || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.INTERVENANT' })}
                    value={
                        prestationApvDMS?.userInfo || (
                            <span className="font-italic kt-label-font-color-1">
                                {Intl.formatMessage({ id: 'CUSTOMER.VIEW.NOT.SPECIFIED' })}
                            </span>
                        )
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.DATE.START.INTERVENTION',
                    })}
                    value={
                        isEmpty(prestationApvDMS?.interventionStartDate) ? (
                            <span className="font-italic kt-label-font-color-1">
                                {Intl.formatMessage({ id: 'CUSTOMER.VIEW.NOT.SPECIFIED' })}
                            </span>
                        ) : (
                            dayjs(prestationApvDMS?.interventionStartDate).format('DD/MM/YYYY')
                        )
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.DATE.END.INTERVENTION',
                    })}
                    value={
                        isEmpty(prestationApvDMS?.interventionEndDate) ? (
                            <span className="font-italic kt-label-font-color-1">
                                {Intl.formatMessage({ id: 'CUSTOMER.VIEW.NOT.SPECIFIED' })}
                            </span>
                        ) : (
                            dayjs(prestationApvDMS?.interventionEndDate).format('DD/MM/YYYY')
                        )
                    }
                />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.DESCRIPTION' })}
                    value={
                        event?.informations?.description === 'NON DISPONIBLE' ? (
                            <span className="font-italic kt-label-font-color-1">
                                {Intl.formatMessage({ id: 'CUSTOMER.VIEW.NOT.AVAILABLE' })}
                            </span>
                        ) : (
                            event?.informations?.description
                        )
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.COMMENT' })}
                    value={
                        prestationApvDMS?.review || (
                            <span className="font-italic kt-label-font-color-1">
                                {Intl.formatMessage({ id: 'CUSTOMER.VIEW.NOT.AVAILABLE' })}
                            </span>
                        )
                    }
                />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.PRICE' })}
                    value={
                        <NumberFormat
                            value={prestationApvDMS?.interventionPriceWorkforce || '-'}
                            decimalSeparator="."
                            thousandSeparator=" "
                            decimalScale={2}
                            fixedDecimalScale
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" €"
                            displayType="text"
                        />
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.PRICE.PARTS' })}
                    value={
                        <NumberFormat
                            value={prestationApvDMS?.interventionPricePiece || '-'}
                            decimalSeparator="."
                            thousandSeparator=" "
                            decimalScale={2}
                            fixedDecimalScale
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" €"
                            displayType="text"
                        />
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.TOTAL' })}
                    value={
                        <NumberFormat
                            value={prestationApvDMS?.interventionPriceWithTaxes || '-'}
                            decimalSeparator="."
                            decimalScale={2}
                            fixedDecimalScale
                            thousandSeparator=" "
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" €"
                            displayType="text"
                        />
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.TAXES' })}
                    value={
                        <NumberFormat
                            value={prestationApvDMS?.taxes || '-'}
                            decimalSeparator="."
                            decimalScale={2}
                            fixedDecimalScale
                            thousandSeparator=" "
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" €"
                            displayType="text"
                        />
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.PAYMENT.METHODE' })}
                    value={
                        prestationApvDMS?.paymentMode || (
                            <span className="font-italic kt-label-font-color-1">
                                {Intl.formatMessage({ id: 'CUSTOMER.VIEW.NOT.SPECIFIED' })}
                            </span>
                        )
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.PAYMENT.TYPE' })}
                    value={
                        prestationApvDMS?.paymentType || (
                            <span className="font-italic kt-label-font-color-1">
                                {Intl.formatMessage({ id: 'CUSTOMER.VIEW.NOT.SPECIFIED' })}
                            </span>
                        )
                    }
                />
                <Divider className="p-divider-dashed" />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DMS.MILEAGE' })}
                    value={
                        (
                            <NumberFormat
                                value={prestationApvDMS?.lastMileageKnown}
                                thousandSeparator=" "
                                displayType="text"
                                suffix=" km"
                            />
                        ) || (
                            <span className="font-italic kt-label-font-color-1">
                                {Intl.formatMessage({ id: 'CUSTOMER.VIEW.NOT.SPECIFIED' })}
                            </span>
                        )
                    }
                />
            </div>
        </div>
    );
};

export default DetailDmsPrestation;
