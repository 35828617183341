import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import DashboardPerfState from './DashboardPerfState';

const DashboardMarketingCampaignPerf = ({ data, className }) => {
    const leadType = 'marketing_campaign';
    const detail = data?.[leadType];

    return (
        <div className={classNames('dashboard-local-perf', className)}>
            <div className="font-size-lg font-weight-bold d-flex align-items-center">
                <i className="dashboard-general-perf__icon las la-2x la-building mr-2" />
                <FormattedMessage id="DASHBOARD.MARKETING_CAMPAIGN_PERF.TITLE" />
            </div>
            {data && (
                <div className="mt-2 d-flex flex-column justify-content-between">
                    <div>
                        <strong>{detail?.taken ?? 0}</strong>{' '}
                        <FormattedMessage id="DASHBOARD.MARKETING_CAMPAIGN_PERF.CREATED" />
                    </div>
                    <DashboardPerfState detail={detail} leadType={leadType} />
                </div>
            )}
        </div>
    );
};

DashboardMarketingCampaignPerf.Skeleton = () => (
    <Skeleton borderRadius="10px" className="d-flex flex-column flex-lg-grow-1 mt-lg-2 mt-6 p-15" />
);
export default DashboardMarketingCampaignPerf;
