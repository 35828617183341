import classNames from 'classnames';
import dayjs from 'dayjs';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

type EditableDatePickerProps = {
    value: Date | null;
    onChange: (event: { value: Date | null }) => void;
    dateFormat?: string;
    placeholder?: string;
    minDate?: Date;
    minDateError?: string | React.JSX.Element;
    maxDate?: Date;
    maxDateError?: string | React.JSX.Element;
    icon?: string;
    className?: string;
    canClear?: boolean;
    disabled?: boolean;
};

const EditableDatePicker = ({
    value,
    onChange,
    dateFormat,
    placeholder,
    minDate,
    minDateError,
    maxDate,
    maxDateError,
    icon,
    className,
    canClear,
    disabled,
}: EditableDatePickerProps) => {
    const overlayRef = useRef<OverlayPanel>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState('');
    const Intl = useIntl();
    const [errorMessage, setErrorMessage] = useState(null);

    const isAllowed = (newValue: Date) => {
        if (minDate && dayjs(newValue).isBefore(minDate, 'day')) {
            return { is_allowed: false, error: minDateError };
        }
        if (maxDate && dayjs(newValue).isAfter(maxDate, 'day')) {
            return { is_allowed: false, error: maxDateError };
        }
        return { is_allowed: true, error: null };
    };

    const formatInputValue = (valueToFormat: string) => {
        const cleanedValue = valueToFormat.replace(/\D/g, '');
        let formattedValue = cleanedValue;
        if (cleanedValue.length > 2) {
            formattedValue = `${cleanedValue.slice(0, 2)}/${cleanedValue.slice(2)}`;
        }
        if (cleanedValue.length > 4) {
            formattedValue = `${formattedValue.slice(0, 5)}/${cleanedValue.slice(4)}`;
        }
        return formattedValue;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;

        const newValue = e.target.value;
        const formattedValue = formatInputValue(newValue);
        setInputValue(formattedValue);

        if (dayjs(formattedValue, dateFormat, true).isValid() && formattedValue.length === 10) {
            const parsedDate = dayjs(formattedValue, dateFormat).toDate();
            const validation = isAllowed(parsedDate);

            if (validation.is_allowed) {
                setErrorMessage(null);
                onChange({ value: parsedDate });
            } else {
                setInputValue('');
                setErrorMessage(validation.error);
                onChange({ value: null });
            }
        } else if (formattedValue.length === 10) {
            setInputValue('');
            setErrorMessage(Intl.formatMessage({ id: 'TRANSLATOR.INVALID_DATE' }));
            onChange({ value: null });
        } else {
            setErrorMessage(null);
        }
    };

    const setVisible = (isVisible: boolean, event?: React.SyntheticEvent) => {
        if (disabled) return;

        if (isVisible) {
            overlayRef.current?.show(event, inputRef.current);
        } else {
            overlayRef.current?.hide();
        }
    };

    const handleCalendarChange = (newValue: Date) => {
        const validation = isAllowed(newValue);
        if (validation.is_allowed) {
            setErrorMessage(null);
            onChange({ value: newValue });
            setInputValue(dayjs(newValue).format(dateFormat));
        } else {
            setErrorMessage(validation.error);
        }
    };

    const handleInputClick = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        setInputValue('');
        onChange({ value: null });
        setErrorMessage(null);
    };

    useEffect(() => {
        if (value && dayjs(value).isValid()) {
            setInputValue(dayjs(value).format(dateFormat));
        }
    }, [value, dateFormat]);

    return (
        <div>
            <InputGroup className="mb-2">
                <Form.Control
                    type="text"
                    className={classNames(className, { 'pe-none': disabled })}
                    ref={inputRef}
                    value={inputValue}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    onClick={(e: React.SyntheticEvent) => setVisible(true, e)}
                    maxLength={10}
                    disabled={disabled}
                />
                <InputGroup.Append>
                    <InputGroup.Text onClick={(e: React.SyntheticEvent) => setVisible(true, e)}>
                        {value && canClear && !disabled ? (
                            <i
                                className="cursor-pointer p-datepicker-overlay__clear la la-times"
                                aria-hidden="true"
                                onClick={handleInputClick}
                            />
                        ) : (
                            <i
                                className={classNames('cursor-pointer text-primary', {
                                    [icon]: icon,
                                    'far fa-calendar-alt': !icon,
                                })}
                            />
                        )}
                    </InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
            {errorMessage && <div className="text-danger mt-1">{errorMessage}</div>}
            <OverlayPanel ref={overlayRef} className="p-datepicker-overlay">
                <Calendar
                    inline
                    value={value}
                    onChange={(e) => handleCalendarChange(e.value as Date)}
                    onHide={() => setVisible(false)}
                    dateFormat={dateFormat}
                    minDate={minDate}
                    maxDate={maxDate}
                    disabled={disabled}
                />
                <Button
                    variant="primary"
                    className="mx-auto mb-4"
                    onClick={() => setVisible(false)}
                    disabled={disabled}
                >
                    <FormattedMessage id="TRANSLATOR.VALIDATE" />
                </Button>
            </OverlayPanel>
        </div>
    );
};

EditableDatePicker.defaultProps = {
    dateFormat: 'DD/MM/YYYY',
    placeholder: '',
    minDate: undefined,
    minDateError: <FormattedMessage id="EDITABLEDATEPICKER.ERROR.MINDATE" />,
    maxDate: undefined,
    maxDateError: <FormattedMessage id="EDITABLEDATEPICKER.ERROR.MAXDATE" />,
    icon: '',
    className: '',
    canClear: true,
    disabled: false,
};

export default EditableDatePicker;
