// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { AlertDocument, AlertDocumentPayload, TempDocument } from '@app/pages/administration/alerts/alerts.type';

type MultipleUploadsFieldProps = {
    values: AlertDocument[];
};

/**
 * MultipleUploadsField
 * @params name: string
 */
const MultipleUploadsField = ({ values = null }: MultipleUploadsFieldProps) => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { register, setValue, watch } = methods;
    const { fields, append, remove } = useFieldArray({
        name: 'documents',
    });

    const watchFieldArray = watch('documents');
    const controlledFields = fields.map((field, index) => ({
        ...field,
        ...watchFieldArray[index],
    }));

    const addField = () => {
        append({ name: '', label: '', contentType: 'application/pdf', content: '', type: 'alert' });
    };

    const removeFile = (index: number) => {
        remove(index);
    };

    const encodeToBase64 = (file: File) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleFileChange = (index: number, file: File) => {
        encodeToBase64(file)
            // @ts-ignore
            .then((value: string) => {
                const fileParts = value.split(',');
                const fileHeaders = fileParts[0];
                const contentType = fileHeaders.split(';')[0].split(':')[1];
                const content = fileParts[1];
                setValue(`documents[${index}]`, {
                    name: file.name,
                    contentType,
                    content,
                    type: 'alert',
                });
            })
            .catch();
    };

    useEffect(() => {
        if (values && values.length > 0)
            setValue(
                'documents',
                values.map((item: AlertDocument) => ({
                    label: item.label,
                    fileId: item.document.id,
                    name: item.document.name,
                    contentType: item.document.contentType,
                    type: item.document.type,
                })),
            );
    }, [values]);

    return (
        <Form.Group>
            {controlledFields.map((field: AlertDocumentPayload & TempDocument, index: number) => {
                const prefix = `documents[${index}]`;
                return (
                    <div key={field.id}>
                        <Form.Label>
                            <FormattedMessage id="ADMINISTRATION.ALERTS.MODAL.ADD.FILE.LABEL" />* :
                        </Form.Label>
                        <div className="d-flex align-items-center justify-content-between">
                            <Form.Control
                                type="text"
                                name={`${prefix}.label`}
                                defaultValue={field.label || ''}
                                ref={register({
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                })}
                                style={{ width: '55%' }}
                            />
                            {field?.name ? (
                                <span style={{ width: '35%' }}>{field.name}</span>
                            ) : (
                                <Form.File
                                    label={Intl.formatMessage({ id: 'ADMINISTRATION.ALERTS.MODAL.ADD_FILE' })}
                                    custom
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handleFileChange(index, e.target.files[0]);
                                    }}
                                    style={{ width: '35%' }}
                                />
                            )}
                            <Form.Control
                                type="hidden"
                                defaultValue={field.fileId || ''}
                                name={`${prefix}.fileId`}
                                ref={register()}
                            />
                            <Form.Control
                                type="hidden"
                                defaultValue={field.content || ''}
                                name={`${prefix}.content`}
                                ref={register()}
                            />
                            <Form.Control
                                type="hidden"
                                defaultValue={field.name || ''}
                                name={`${prefix}.name`}
                                ref={register()}
                            />
                            <Form.Control
                                type="hidden"
                                defaultValue={field.type || ''}
                                name={`${prefix}.type`}
                                ref={register()}
                            />
                            <Form.Control
                                type="hidden"
                                defaultValue={field.content || ''}
                                name={`${prefix}.content`}
                                ref={register()}
                            />
                            <Form.Control
                                type="hidden"
                                defaultValue={field.contentType || ''}
                                name={`${prefix}.contentType`}
                                ref={register()}
                            />
                            <Button variant="link" className="mt-2" onClick={() => removeFile(index)}>
                                <i className="la la-trash" />
                            </Button>
                        </div>
                    </div>
                );
            })}
            <Button variant="primary" onClick={() => addField()}>
                <FormattedMessage id="ADMINISTRATION.ALERTS.MODAL.ADD_FILE" />
            </Button>
        </Form.Group>
    );
};

export default MultipleUploadsField;
