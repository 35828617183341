import React from "react";
import {LayoutContextConsumer} from "../LayoutContext";
import BreadCrumbs from "./components/BreadCrumbs";
import {FormattedMessage} from "react-intl";
import subHeader from "../../../app/partials/HoC/subHeader";

class DefaultSubHeader extends React.Component {
    render() {
        const {
            subheaderCssClasses,
            subheaderContainerCssClasses,
            subheaderMobileToggle
        } = this.props;

        return (
            <div
                id="kt_subheader"
                className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
                <div className={`kt-container ${subheaderContainerCssClasses}`}>
                    <div className="kt-subheader__main">
                        {subheaderMobileToggle && (
                            <button
                                className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                                id="kt_subheader_mobile_toggle"
                            >
                                <span />
                            </button>
                        )}

                        <LayoutContextConsumer>
                            {({ subheader: { title, translate, breadcrumb } }) => (
                                <>
                                    {!translate ? (
                                        <h3 className="kt-subheader__title">{title}</h3>
                                    ) : (
                                        <h3 className="kt-subheader__title">
                                            <FormattedMessage  id={translate} />
                                        </h3>
                                    )}

                                    {<BreadCrumbs items={breadcrumb} />}
                                </>
                            )}
                        </LayoutContextConsumer>
                    </div>
                </div>
            </div>
        );
    }
}

export default subHeader(DefaultSubHeader);
