import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import usePermissions from '@app/hooks/usePermissions';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { routeTo } from '@app/helpers/RoutesHelper';

import { fetchFluidities } from '@app/crud/customers/fluidity.crud';

import ROUTES from '@app/router/Routes';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import Label from '@app/partials/content/Label';
import MultiSelectFilter from '@app/partials/content/MultiSelectFilter';

import getStatusVariant from './Helpers/Event';

const FluidityTable = ({ status, counter }) => {
    const { hasPermission } = usePermissions();
    const Intl = useIntl();
    const { items, start, filters, sortField, sortOrder, updatePendingFilters, releasePendingFilters } = useFilters({
        name: `fluidity_${status}`,
        defaultValues: {
            filters: {
                sort: { value: 'created_desc' },
            },
        },
        persisted: false,
    });
    const { data, loading, handleFilter, handlePagination, handleSort } = useTableHandlers({
        filterStore: `fluidity_${status}`,
        fetchAction: fetchFluidities,
        counterName: counter,
        withStore: false,
        needUrlParams: true,
        paramsInterceptor: (params, interceptorFilters) => ({
            ...params,
            page: interceptorFilters.page,
            limit: interceptorFilters.items,
        }),
    });
    const fluidities = data?.result || [];
    const facets = data?.facets || [];

    useEffect(() => {
        handleFilter({
            leadStatus: {
                value: status,
            },
        });
    }, [status]);

    const inputFilter = (name) => <InputFilter name={name} onChange={handleFilter} />;

    const multiselectFilter = (name, options) => (
        <MultiSelectFilter
            name={name}
            display="chip"
            optionLabel="label"
            optionValue="key"
            options={options}
            pending
            onChange={handleFilter}
            onPendingChange={updatePendingFilters}
            onRelease={releasePendingFilters}
        />
    );

    const dealershipSaleBody = (fluidity) => fluidity?.nameDealershipSeller || '-';

    const dealershipBuyBody = (fluidity) => fluidity?.nameDealershipBuyer || '-';

    const dealershipBuyerBody = (fluidity) => fluidity?.clientFinalFullname || '';

    const statusBody = (fluidity) => (
        <>
            <Label
                size="label-lg"
                className={`text-nowrap label-light-${getStatusVariant(fluidity?.leadFluidityStatus?.slug)}`}
            >
                {fluidity?.leadFluidityStatus?.name || ''}
            </Label>
            <br />
            {fluidity?.updated ? dayjs(fluidity.updated).format('DD/MM/YYYY [à] HH [h] mm') : ''}
        </>
    );

    const receptionBody = (fluidity) => {
        if (!fluidity?.created) {
            return '-';
        }

        return (
            <>
                {dayjs(fluidity.created).format('DD/MM/YYYY')}
                <br />
                {dayjs(fluidity.created).format('[à] HH [h] mm')}
            </>
        );
    };

    const actionsBody = (fluidity) => (
        <Link
            to={routeTo(ROUTES.FLUIDITY.PATH, {
                status,
                id: fluidity?.id,
            })}
        >
            <Button variant="primary" className="text-nowrap">
                <i className="las la-eye mr-2" />
                {Intl.formatMessage({ id: 'TRANSLATOR.DETAILS' })}
            </Button>
        </Link>
    );

    const cols = [
        {
            field: 'leadType',
            header: Intl.formatMessage({ id: 'FLUIDITY.TABLE.HEAD.TYPE' }),
            filter: true,
            filterElement: multiselectFilter('leadType', facets?.leadTypes || []),
            body: (fluidity) => fluidity?.leadType?.name || '-',
        },
        {
            field: 'dealershipSeller',
            header: Intl.formatMessage({ id: 'FLUIDITY.TABLE.HEAD.DEALERSHIP_SALE' }),
            filter: true,
            filterElement: inputFilter('dealershipSeller'),
            body: dealershipSaleBody,
        },
        {
            field: 'dealershipBuyer',
            header: Intl.formatMessage({ id: 'FLUIDITY.TABLE.HEAD.DEALERSHIP_BUY' }),
            filter: true,
            filterElement: inputFilter('dealershipBuyer'),
            body: dealershipBuyBody,
        },
        {
            field: 'client',
            header: Intl.formatMessage({ id: 'FLUIDITY.TABLE.HEAD.CLIENT' }),
            filter: true,
            filterElement: inputFilter('client'),
            body: dealershipBuyerBody,
        },
        {
            field: 'vehicleIdentifier',
            header: Intl.formatMessage({ id: 'FLUIDITY.TABLE.HEAD.NUMBER' }),
            filter: true,
            filterElement: inputFilter('vehicleIdentifier'),
            body: (fluidity) => fluidity?.vehicle?.vehicleInternalId || '-',
        },
        {
            field: 'registration',
            header: Intl.formatMessage({ id: 'FLUIDITY.TABLE.HEAD.REGISTRATION' }),
            filter: true,
            filterElement: inputFilter('registration'),
            body: (fluidity) => fluidity?.vehicle?.registration || '-',
        },
        {
            field: 'leadFluidityStatus',
            header: Intl.formatMessage({ id: 'FLUIDITY.TABLE.HEAD.STATUS' }),
            style: { width: '200px' },
            filter: true,
            filterElement: multiselectFilter('leadFluidityStatus', facets?.leadFluidityStatus || []),
            body: statusBody,
        },
        {
            field: 'reception',
            header: Intl.formatMessage({ id: 'FLUIDITY.TABLE.HEAD.RECEPTION' }),
            body: receptionBody,
        },
    ];

    if (hasPermission('LEAD_VIEW_FLUIDITE')) {
        cols.push({
            field: 'actions',
            header: Intl.formatMessage({ id: 'FLUIDITY.TABLE.HEAD.ACTIONS' }),
            body: actionsBody,
        });
    }

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <FiltersProvider value={filters}>
            <HoCDataTable
                value={fluidities}
                totalRecords={data?.count || 0}
                lazy
                loading={loading}
                paginator
                onFilter={handleFilter}
                onPage={handlePagination}
                onSort={handleSort}
                paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                rows={items}
                first={start}
                sortField={sortField}
                sortOrder={sortOrder}
                removableSort
                filterDisplay="row"
                rowsPerPageOptions={[25, 50, 100]}
                emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
            >
                {dynamicColumns}
            </HoCDataTable>
        </FiltersProvider>
    );
};

export default FluidityTable;
