import { AnyAction } from 'redux';

import { Order } from '@app/crud/apv/order.type';

export const actionTypes = {
    FetchOrders: 'FETCH_ORDERS',
    FetchOrder: 'FETCH_ORDER',
    FetchedOrders: 'FETCHED_ORDERS',
    FetchedOrder: 'FETCHED_ORDER',
    FetchOrdersFailed: 'FETCH_ORDER_FAILED',
    FetchOrderFailed: 'FETCH_ORDER_FAILED',
    SetOrdersTotalRecords: 'SET_ORDERS_TOTALRECORDS',
};

interface OrderState {
    loading: boolean;
    orders: Order[];
    totalRecords: {
        commande?: number;
        panier?: number;
        draft?: number;
        waiting_for_client?: number;
        closed?: number;
    };
    filtersContent: {
        [key: string]: {
            id: number;
            name: string;
            slug: string;
            created: string;
            updated: string;
        };
    };
    order: Order | null;
    error: null;
}

const initialState: OrderState = {
    loading: false,
    orders: [],
    totalRecords: {},
    filtersContent: [] as any,
    order: null,
    error: null,
};

export function orderReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case actionTypes.FetchOrders:
        case actionTypes.FetchOrder: {
            return {
                ...state,
                loading: true,
                order: null,
                error: null,
            };
        }
        case actionTypes.FetchedOrders: {
            const { orderType } = action.payload;

            return {
                ...state,
                loading: false,
                orders: action.payload.orders,
                totalRecords: {
                    ...state.totalRecords,
                    [orderType]: action.payload.count,
                },
                filtersContent: action.payload.filters,
            };
        }
        case actionTypes.FetchedOrder: {
            return {
                ...state,
                loading: false,
                order: action.payload,
            };
        }
        case actionTypes.FetchOrderFailed:
        case actionTypes.FetchOrdersFailed: {
            return {
                ...state,
                loading: false,
                // @ts-ignore
                orders: [],
                error: action.payload.error,
            };
        }
        case actionTypes.SetOrdersTotalRecords: {
            return {
                ...state,
                totalRecords: action.payload,
            };
        }
        default:
            return state;
    }
}

export const actions = {
    fetchOrders: () => ({ type: actionTypes.FetchOrders }),
    fetchedOrders: (payload: { orderType: string; count: number; orders?: Order[]; filters?: object }) => ({
        type: actionTypes.FetchedOrders,
        payload,
    }),
    fetchOrder: () => ({ type: actionTypes.FetchOrder }),
    fetchedOrder: (order: Order) => ({ type: actionTypes.FetchedOrder, payload: order }),
    fetchOrderFailed: (error: object) => ({ type: actionTypes.FetchOrderFailed, payload: error }),
    fetchOrdersFailed: (error: object) => ({ type: actionTypes.FetchOrdersFailed, payload: error }),
    setOrdersTotalRecords: (totalRecords: number) => ({
        type: actionTypes.SetOrdersTotalRecords,
        payload: totalRecords,
    }),
};
