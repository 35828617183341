import React from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { generatePath, Link, useParams } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';
import { LayoutContextConsumer } from '@src/_metronic/layout/LayoutContext';
import BreadCrumbs from '@src/_metronic/layout/sub-header/components/BreadCrumbs';

import { useAppSelector } from '@app/hooks';
import ROUTES from '@app/router/Routes';

import toast from '@app/partials/content/Toast';
import subHeader from '@app/partials/HoC/subHeader';

import { formSubmit } from './Form/Form.submit';

function RolesSubHeader(props) {
    const methods = useFormContext();
    const params = useParams();
    const dispatch = useDispatch();
    const { history } = props;

    const newRole = typeof params.id === 'undefined';
    const { role } = useAppSelector((state) => state.role);

    const onSubmit = (data) => {
        formSubmit({
            newRole,
            data,
            dispatch,
            history,
            id: params.id,
        });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const { subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle } = props;

    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: 'MENU.AUTOUSER' }),
        },
        {
            title: Intl.formatMessage({ id: 'MENU.AUTOUSER.ROLES' }),
        },
        {
            title: Intl.formatMessage({ id: 'MENU.AUTOUSER.ROLES.LIST' }, { name: role?.name || '' }),
        },
    ];

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            type="button"
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}

                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">
                                    {Intl.formatMessage({ id: 'AUTOUSER.ROLES.CREATE' })}
                                </h3>
                                <BreadCrumbs items={breadcrumb} />
                            </>
                        )}
                    </LayoutContextConsumer>
                </div>
                <div className="d-flex align-items-center">
                    <Link to={generatePath(ROUTES.ROLES.PATH)}>
                        <Button className="mr-2" variant="outline-primary">
                            {Intl.formatMessage({ id: 'TRANSLATOR.BACK' })}
                        </Button>
                    </Link>
                    <Button className="mr-2" onClick={methods.handleSubmit(onSubmit, onError)} variant="primary">
                        {Intl.formatMessage({ id: 'TRANSLATOR.SAVE' })}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default subHeader(RolesSubHeader);
