import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { getBrands } from '@app/crud/manufacturer/brand.crud';
import { getMecaplanningAction } from '@app/crud/manufacturer/mecaplanningAction.crud';
import { getPackages } from '@app/crud/manufacturer/package.crud';
import {
    getPackageMecaplanningAction,
    updatePackageMecaplanningAction,
} from '@app/crud/manufacturer/packageMecaplanningAction.crud';

import HoCDataTable from '@app/partials/content/HoCDataTable';
import toast from '@app/partials/content/Toast';

const PackageMapping = () => {
    const methods = useForm();
    const { control, setValue, handleSubmit } = methods;
    const [brands, setBrands] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [mecaplanningActions, setMecaplanningActions] = useState([]);
    const [packages, setPackages] = useState([]);
    const [mapping, setMapping] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getBrands({ active: true, start: 0, items: 1 }).then((responseForPagination) => {
            const total = responseForPagination.count;
            getBrands({ active: true, start: 0, items: total }).then((res) => setBrands(res.result));
        });
        getPackages({ active: true, start: 0, items: 1 }).then((responseForPagination) => {
            const total = responseForPagination.count;
            getPackages({ active: true, start: 0, items: total }).then((res) => setPackages(res.result.packages));
        });
    }, []);

    useEffect(() => {
        if (selectedBrand) {
            setLoading(true);
            getMecaplanningAction({ brand: selectedBrand, start: 0, items: 1 }).then((responseForPagination) => {
                const total = responseForPagination.count;
                getMecaplanningAction({ brand: selectedBrand, start: 0, items: total }).then((res) =>
                    setMecaplanningActions(res.result),
                );
            });
            getPackageMecaplanningAction({ brand: selectedBrand, start: 0, items: 1 }).then((responseForPagination) => {
                const total = responseForPagination.count;
                getPackageMecaplanningAction({ brand: selectedBrand, start: 0, items: total }).then((res) => {
                    setMapping(res?.result);
                    setLoading(false);
                });
            });
        }
    }, [selectedBrand]);

    useEffect(() => {
        mapping.map((item) => {
            setValue(item?.package?.slug, item?.mecaplanningAction?.actionCode);
        });
    }, [mapping]);

    const onSubmit = (data) => {
        setLoading(true);
        const dataFormated = { brand: selectedBrand };
        dataFormated.packageMapping = Object.keys(data)
            .map((e) => {
                if (data[e]) {
                    let arr = {};
                    const itemIndex = mapping.findIndex((item) => item.package.slug === e);

                    if (itemIndex !== -1) {
                        arr.id = mapping[itemIndex].id;
                    }

                    arr = {
                        ...arr,
                        package: packages.find((pack) => pack.slug === e).id,
                        mecaplanningAction: mecaplanningActions.find((action) => action.actionCode === data[e]).id,
                    };

                    return arr;
                }
            })
            .filter((e) => e !== undefined);

        updatePackageMecaplanningAction(dataFormated)
            .then((res) => {
                if (res.result) {
                    setMapping(res.result);
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'MANUFACTURER.MAPPING_PACKAGE.UPDATE.SUCESS' }),
                    });
                } else {
                    throw new Error('Wrong return format');
                }
            })
            .catch(() =>
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'MANUFACTURER.MAPPING_PACKAGE.UPDATE.FAIL' }),
                }),
            );
        setLoading(false);
    };

    const packageBodyTemplate = (packageItem) => <label htmlFor={packageItem.slug}>{packageItem.name}</label>;

    const mecaplanningActionBodyTemplate = (packageItem) => (
        <Controller
            name={packageItem.slug}
            control={control}
            defaultValue={null}
            render={(props) => (
                <Dropdown
                    name={packageItem.slug}
                    value={props.value}
                    optionLabel="name"
                    optionValue="actionCode"
                    options={mecaplanningActions}
                    placeholder={Intl.formatMessage({ id: 'MANUFACTURER.MAPPING_PACKAGE.SELECT_ACTION' })}
                    onChange={(e) => props.onChange(e?.value)}
                />
            )}
        />
    );

    const cols = [
        {
            field: 'package',
            header: Intl.formatMessage({ id: 'MANUFACTURER.MAPPING_PACKAGE.TABLE.COLUMN.PACKAGE' }),
            style: { width: '25%' },
            body: packageBodyTemplate,
        },
        {
            field: 'mecaplanningAction',
            header: Intl.formatMessage({ id: 'MANUFACTURER.MAPPING_PACKAGE.TABLE.COLUMN.MECAPLANNING_ACTION' }),
            style: { width: '25%' },
            body: mecaplanningActionBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <Card>
            <Card.Body>
                <Row className="mb-7">
                    <Col>
                        <p className="font-size-lg font-weight-bold text-center">
                            {Intl.formatMessage({ id: 'MANUFACTURER.MAPPING_PACKAGE.TITLE' })}
                        </p>
                        <Dropdown
                            name="brand"
                            className="offset-lg-5 col-2"
                            optionLabel="name"
                            optionValue="slug"
                            value={selectedBrand}
                            options={brands}
                            onChange={(e) => setSelectedBrand(e.value)}
                            placeholder={Intl.formatMessage({ id: 'MANUFACTURER.MAPPING_PACKAGE.SELECT_BRAND' })}
                        />
                    </Col>
                </Row>
                {selectedBrand && (
                    <Form className="package" onSubmit={onSubmit}>
                        <Row>
                            <HoCDataTable
                                value={packages}
                                totalRecords={[]}
                                lazy
                                loading={loading}
                                paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                                currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                                removableSort
                                rowsPerPageOptions={[25, 50, 100]}
                                emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                            >
                                {dynamicColumns}
                            </HoCDataTable>
                        </Row>
                        <Row className="mt-4">
                            <Col className="text-center">
                                <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                                    {Intl.formatMessage({ id: 'TRANSLATOR.SAVE' })}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Card.Body>
        </Card>
    );
};

export default PackageMapping;
