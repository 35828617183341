import ApiGateway, { CustomAxiosResponse, PureGateway } from '@app/helpers/AxiosHelper';

const { REACT_APP_LEAD_URL } = process.env;

export interface ExpertiseData {
    cerfaStatus: {
        isSent: boolean;
        canSend: boolean;
        missingFields: string[];
    };
    contractStatus: {
        isSent: boolean;
        canSend: boolean;
        missingFields: string[];
    };
}

export interface ResponseLoadDocument {
    result: LoadDocument;
}

export interface LoadDocument {
    id: number;
    name: string;
    type: string;
    contentType: string;
    createdAt: string;
    updatedAt: string;
}

export function getExpertiseForLead(id: number) {
    return ApiGateway.get<ExpertiseData, CustomAxiosResponse<ExpertiseData>>(
        `${REACT_APP_LEAD_URL}/achat-cash/expertise/${id}`,
    );
}

export function getAchatCashContractForLead(id: number) {
    return ApiGateway.get<LoadDocument, CustomAxiosResponse<ResponseLoadDocument>>(
        `${REACT_APP_LEAD_URL}/achat-cash/contract/pdf/${id}`,
    );
}

export function getAndSendAchatCashContractForLead(id: number) {
    return ApiGateway.get<LoadDocument, CustomAxiosResponse<ResponseLoadDocument>>(
        `${REACT_APP_LEAD_URL}/achat-cash/contract/email/${id}`,
    );
}

export function getAchatCashCerfaForLead(id: number) {
    return ApiGateway.get<LoadDocument, CustomAxiosResponse<ResponseLoadDocument>>(
        `${REACT_APP_LEAD_URL}/achat-cash/cerfa/pdf/${id}`,
    );
}

export function getAndSendAchatCashCerfaForLead(id: number) {
    return ApiGateway.get<LoadDocument, CustomAxiosResponse<ResponseLoadDocument>>(
        `${REACT_APP_LEAD_URL}/achat-cash/cerfa/email/${id}`,
    );
}

export function downloadLeadDocument(id: number) {
    return PureGateway.get(`${REACT_APP_LEAD_URL}/lead-document/${id}/download`, {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
        },
    });
}
