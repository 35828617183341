import React, { ChangeEvent } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { ModalStepperType } from '@app/partials/content/modals/ModalStepper';
import ErrorForm from '@app/partials/layout/ErrorForm';

import { SubmittedProps } from '../CommerceRequest';

const requestTypes = {
    buying_project: "Projet d'achat",
    'suivi-post-livraison-administratif': 'Suivi post-livraison administratif',
    'suivi-avant-livraison': 'Suivi avant livraison',
    'suivi-post-livraison-vehicule': 'Suivi post-livraison véhicule',
    'autre-demande': 'Autre demande',
};

type CommerceRequestReasonProps = {
    data: Partial<SubmittedProps>;
    setStepperState?: (value: React.SetStateAction<ModalStepperType>) => void | null;
};

const CommerceRequestReason = ({ data, setStepperState }: CommerceRequestReasonProps) => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { register, errors, setValue } = methods;

    return (
        <Form.Group as={Col} md={12}>
            <Form.Label className="d-block mr-10 mb-5">
                <FormattedMessage id="CUSTOMERS.MODAL.LOCAL_COMMERCE.REASON.LABEL" />
            </Form.Label>
            {requestTypes &&
                Object.entries(requestTypes).map(([key, value]) => (
                    <Form.Check
                        id={key}
                        name="type"
                        inline={false}
                        type="radio"
                        defaultChecked={data.type && data.type === key}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setValue('type', e.target.value, { shouldValidate: true });
                            if (e.target.value !== 'buying_project') {
                                setStepperState((state) => {
                                    const newStepperState = { ...state };
                                    newStepperState.steps[1].status = 'disabled';
                                    return newStepperState;
                                });
                            } else {
                                setStepperState((state) => {
                                    const newStepperState = { ...state };
                                    newStepperState.steps[1].status = 'pending';
                                    return newStepperState;
                                });
                            }
                        }}
                        ref={register({
                            required: Intl.formatMessage({
                                id: 'FORM.ERROR.REQUIRED',
                            }),
                        })}
                        value={key}
                        label={value}
                        className="mb-4"
                    />
                ))}
            <ErrorForm errors={errors} name="type" />
        </Form.Group>
    );
};

export default CommerceRequestReason;
