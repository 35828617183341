import React from 'react';
import { Card, Image } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import KTUtil from '@src/_metronic/_assets/js/util';

import Lottie from '@app/partials/content/Lottie';

import { ApvOrderRow } from './ApvOrderRow';
import LeadRow from './LeadRow/LeadRow';

const APVActions = ({ leads, className, apvOrders }) => {
    const Intl = useIntl();

    return (
        <Card className={`card--shadowed ${className}`}>
            <Card.Header className="d-flex align-items-center">
                <i className="la la-2x text-light mr-3 la-wrench" />
                {Intl.formatMessage({ id: 'CUSTOMER.VIEW.APV_ACTIONS.TITLE' })}
            </Card.Header>
            <Card.Body>
                {leads && leads?.length > 0 && (
                    <div className="lead-rows">
                        {leads.map((lead) => (
                            <LeadRow key={lead.id} lead={lead} />
                        ))}
                    </div>
                )}

                {apvOrders?.length > 0 && (
                    <div className="lead-rows">
                        {apvOrders.map((apvOrder) => (
                            <ApvOrderRow key={apvOrder.id} apvOrder={apvOrder} />
                        ))}
                    </div>
                )}

                {!leads?.length && !apvOrders?.length && (
                    <div className="lead-rows h-100">
                        <div className="w-100 d-flex flex-lg-row flex-column align-items-center justify-content-center lead-row--noresult">
                            <div className="d-lg-block d-flex justify-content-between">
                                <Lottie
                                    options={{
                                        path: '/media/lotties/customers/fiche-client-aucune-activite-en-cours.json',
                                    }}
                                    style={{
                                        width: 'auto',
                                        height: KTUtil.isMobileDevice() ? '150px' : '250px',
                                    }}
                                />
                                {KTUtil.isMobileDevice() && (
                                    <Image
                                        className="ml-10"
                                        src="/media/pages/customers/fiche-client-fleche-moment-d-agir.svg"
                                    />
                                )}
                            </div>
                            <div className="mx-10 mt-lg-0 mt-5 text-lg-left text-center">
                                <div>
                                    <FormattedHTMLMessage id="CUSTOMER.VIEW.ACTIONS.NO_ACTIVTY" />
                                </div>
                                <div className="font-weight-semibold">
                                    {Intl.formatMessage({ id: 'CUSTOMER.VIEW.ACTIONS.CLICK_BUTTON' })}
                                </div>
                            </div>
                            {KTUtil.isDesktopDevice() && (
                                <Image src="/media/pages/customers/fiche-client-fleche-moment-d-agir.svg" />
                            )}
                        </div>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};

export default APVActions;
