export const actionTypes = {
    FetchOperations: 'FETCH_OPERATIONS',
    FetchedOperations: 'FETCHED_OPERATIONS',
    FetchOperation: 'FETCH_OPERATION',
    FetchedOperation: 'FETCHED_OPERATION',
    FetchOperationFailed: 'FETCH_OPERATION_FAILED',
    AddOperation: 'ADD_OPERATION',
    AddedOperation: 'ADDED_OPERATION',
    AddOperationFailed: 'ADD_OPERATION_FAILED',
    UpdateOperation: 'UPDATE_OPERATION',
    UpdatedOperation: 'UPDATED_OPERATION',
    UpdateOperationFailed: 'UPDATE_OPERATION_FAILED',
    RemoveOperation: 'REMOVE_OPERATION',
    FetchOperationsFailed: 'FETCH_OPERATIONS_FAILED',
};

const initialState = {
    operations: [],
    operation: null,
    settings: [],
    dealerships: [],
    totalRecords: 0,
    filtersContent: [],
    error: null,
    loading: false,
};

export function commercialOperationReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FetchOperations:
        case actionTypes.FetchOperation: {
            return {
                ...state,
                loading: true,
                operation: null,
                error: null,
            };
        }
        case actionTypes.AddOperation:
        case actionTypes.RemoveOperation:
        case actionTypes.UpdateOperation: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }
        case actionTypes.FetchedOperations: {
            return {
                ...state,
                loading: false,
                operations: action.payload.commercialOperation,
                totalRecords: action.payload.count,
                filtersContent: action.payload.filters,
            };
        }
        case actionTypes.FetchedOperation: {
            return {
                ...state,
                loading: false,
                operation: action.payload.operation || null,
                settings: action.payload.settings,
                dealerships: action.payload.dealerships,
            };
        }
        case actionTypes.UpdatedOperation:
        case actionTypes.AddedOperation: {
            return {
                ...state,
                loading: false,
                operation: action.payload,
            };
        }
        case actionTypes.UpdateOperationFailed:
        case actionTypes.AddOperationFailed: {
            return {
                ...state,
                loading: false,
            };
        }
        case actionTypes.FetchOperationsFailed:
        case actionTypes.FetchOperationFailed: {
            return {
                ...state,
                loading: false,
                operations: [],
                error: action.payload,
            };
        }
        default:
            return state;
    }
}

export const actions = {
    fetchOperations: () => ({ type: actionTypes.FetchOperations }),
    fetchedOperations: (operations) => ({ type: actionTypes.FetchedOperations, payload: operations }),
    fetchOperation: () => ({ type: actionTypes.FetchOperation }),
    fetchedOperation: (result) => ({ type: actionTypes.FetchedOperation, payload: result }),
    fetchOperationFailed: (error) => ({ type: actionTypes.FetchOperationFailed, payload: error }),
    fetchOperationsFailed: (error) => ({ type: actionTypes.FetchOperationsFailed, payload: error }),
    addOperation: () => ({ type: actionTypes.AddOperation }),
    addedOperation: (result) => ({ type: actionTypes.AddedOperation, payload: result }),
    addOperationFailed: (error) => ({ type: actionTypes.AddOperationFailed, payload: error }),
    updateOperation: () => ({ type: actionTypes.UpdateOperation }),
    updatedOperation: (result) => ({ type: actionTypes.UpdatedOperation, payload: result }),
    updateOperationFailed: (error) => ({ type: actionTypes.UpdateOperationFailed, payload: error }),
    removeOperation: () => ({ type: actionTypes.RemoveOperation }),
};
