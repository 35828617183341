import dayjs from 'dayjs';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import Label from '@app/partials/content/Label';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';
import Permission from '@app/partials/content/Permission';

export default function StockVehiclesPlaceDurationColumn({ vehicle }) {
    return (
        <>
            <Row>
                <Col lg={12} className="d-flex flex-column">
                    <span className="font-weight-bold">{vehicle.dealer_brand}</span>
                    <span className="font-weight-bold">{vehicle.city}</span>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    {vehicle?.dealer_id && (
                        <Permission permissions={['STOCK_VIEW_INTERNAL_DATA']}>
                            <OverlayTooltip label="STOCK_VEHICLES.TABLE.DEALERSHIP_ID_TOOLTIP" id="dealeship_id">
                                <Label variant="light" className="font-weight-bold text-dark mt-2">
                                    {vehicle.dealer_id}
                                </Label>
                            </OverlayTooltip>
                            &nbsp;
                        </Permission>
                    )}
                    {vehicle?.code_pvo && (
                        <OverlayTooltip label="STOCK_VEHICLES.TABLE.CODE_PVO_TOOLTIP" id="code_pvo">
                            <Label variant="light" className="font-weight-bold text-dark mt-2">
                                {vehicle.code_pvo}
                            </Label>
                        </OverlayTooltip>
                    )}
                </Col>
            </Row>
            <Row className="mt-2">
                <Col lg={12}>
                    <OverlayTooltip label="STOCK_VEHICLES.TABLE.DAYS_STOCK_TOOLTIP">
                        <Label variant="secondary" className="font-weight-bold text-dark">
                            {dayjs().diff(dayjs(vehicle.date_in_stock).format('YYYY-MM-DD'), 'day')}
                            &nbsp;
                            <FormattedMessage id="STOCK_VEHICLES.TABLE.DAYS" />
                        </Label>
                    </OverlayTooltip>
                </Col>
            </Row>
        </>
    );
}
