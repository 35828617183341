import React, {useEffect, useRef, useState} from 'react';
import {Locale} from "../../../_metronic/i18n/I18nProvider";
import JoditEditor from "jodit-react";
import {usePrevious} from "../../hooks/usePrevious";
import {htmlToString, nl2br} from "../../helpers/StringHelper";

export const LIGHT_BUTTONS = [
    'bold',
    'italic',
    'underline',
    'fontsize',
    'ul',
    'link',
    'brush'
];

export const ALL_BUTTONS = [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'eraser',
    'superscript',
    'subscript',
    'ul',
    'ol',
    'indent',
    'outdent',
    'left',
    'font',
    'fontsize',
    'paragraph',
    'classSpan',
    'brush',
    'image',
    'video',
    'file',
    'copyformat',
    'cut',
    'copy',
    'paste',
    'selectall',
    'hr',
    'table',
    'link',
    'symbol',
    'undo',
    'redo',
    'find',
    'source'
];

const Editor = React.forwardRef((
    {
        value,
        config,
        onChange,
        className,
        refresh,
        html,
        useNl2br
    }, ref) => {
    const jodit = useRef();
    const previousClass = usePrevious(className);
    const [defaultConfig, setDefaultConfig] = useState({
        minHeight: 500,
        language: Locale,
        buttons: ALL_BUTTONS,
        toolbarAdaptive: false,
        cleanHTML: {
            removeEmptyElements: true
        },
        events: {
            afterInit(instance) {
                if (ref) {
                    ref.current = instance;
                }

                jodit.current = instance;
            },
            change(value) {
                const text = htmlToString(value)
                onChange(text !== "" ? value : text);
            }
        },
        ...config
    });

    useEffect(() => {
        if (jodit?.current?.currentPlace) {
            const classes = className.split(' ');
            if (previousClass) {
                jodit.current.currentPlace.container.classList.remove(...classes);
            }
            if (className) {
                jodit.current.currentPlace.container.classList.add(...classes);
            }
        }
    }, [className, previousClass]);

    useEffect(() => {
        setDefaultConfig((prevState) => ({
            ...prevState,
            ...config
        }));
    }, [setDefaultConfig, refresh]);

    return (
        <JoditEditor config={defaultConfig}
                     value={useNl2br ? nl2br(value) : value}/>
    );
});

Editor.defaultProps = {
    value: '',
    className: '',
    config: {},
    onChange: () => {},
    html: true,
    useNl2br: false,
}

export default Editor;
