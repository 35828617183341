/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { ReactNode, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { getStorage, toAbsoluteUrl } from '@src/_metronic';
import { Intl } from '@src/_metronic/i18n/I18nProvider';

import usePermissions from '@app/hooks/usePermissions';

import { useAppSelector } from '@app/hooks';
import { ACCOUNT_MENUS } from '@app/pages/account/Helpers/Menu';
import { logoutUser, stopImpersonateUser } from '@app/store/login/login.thunk';

import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';

type UserProfileProps = {
    showHi: boolean;
    showBadge: boolean;
};

interface MenuItem {
    icon: string;
    title: () => ReactNode;
    subtitle: ReactNode;
    href: () => string;
    visible?: () => boolean;
    permissions?: string[];
}

const UserProfile = ({ showHi, showBadge }: UserProfileProps) => {
    const { hasAllPermissions } = usePermissions();
    const { userData } = useAppSelector((state) => state.auth);
    const [showDropdown, setShowDropdown] = useState(false);
    const dispatch = useDispatch();

    const MENUS = ACCOUNT_MENUS.filter(
        (menu: MenuItem) => (menu?.visible?.() ?? true) && hasAllPermissions(menu.permissions),
    );

    const impersonated = getStorage('impersonatedBy');

    const iconClass = impersonated !== null ? 'kt-header__topbar-icon-name-masked' : 'kt-header__topbar-icon-name';

    return (
        <Dropdown
            className="kt-header__topbar-item kt-header__topbar-item--user"
            show={showDropdown}
            onToggle={() => setShowDropdown(!showDropdown)}
            drop="up"
            alignRight
        >
            <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-user-profile">
                <div className="kt-header__topbar-user">
                    {showHi && <span className="kt-header__topbar-welcome d-lg-block d-none">Bonjour,</span>}

                    {showHi && (
                        <span className="kt-header__topbar-username d-lg-block d-none">
                            {userData?.first_name || ''}
                        </span>
                    )}

                    <div className={iconClass}>
                        {userData?.first_name ? userData.first_name.charAt(0).toUpperCase() : ''}
                    </div>

                    {showBadge && (
                        <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
                            {/* TODO: Should get from currentUser */}
                            {userData?.first_name ? userData.first_name.charAt(0).toUpperCase() : ''}{' '}
                            {userData?.last_name ? userData.last_name.charAt(0).toUpperCase() : ''}
                        </span>
                    )}
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
                <div
                    className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
                    style={{
                        backgroundImage: `url(${toAbsoluteUrl('/media/misc/bg-1.jpg')})`,
                    }}
                >
                    <div className="kt-user-card__avatar">
                        <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                            {userData?.first_name ? userData.first_name.charAt(0).toUpperCase() : ''}
                            {userData?.last_name ? userData.last_name.charAt(0).toUpperCase() : ''}
                        </span>
                    </div>
                    <div className="kt-user-card__name">
                        {userData.first_name} {userData.last_name}
                        {impersonated && (
                            <>
                                <br />
                                <cite className="kt-notification__item font-size-sm">Incarné par {impersonated}</cite>
                            </>
                        )}
                    </div>
                </div>
                <div className="kt-notification">
                    <div hidden={MENUS.length === 0}>
                        {MENUS.map((menu: MenuItem, idx) => (
                            <Link
                                to={menu.href()}
                                // eslint-disable-next-line react/no-array-index-key
                                key={idx}
                                className="kt-notification__item"
                                onClick={() => setShowDropdown(false)}
                            >
                                <div className="kt-notification__item-icon">
                                    <i className={menu.icon} />
                                </div>
                                <div className="kt-notification__item-details">
                                    <div className="kt-notification__item-title kt-font-bold">{menu.title()}</div>
                                    <div className="kt-notification__item-time">{menu.subtitle}</div>
                                </div>
                            </Link>
                        ))}
                    </div>
                    <div className="kt-notification__custom">
                        {impersonated === null && (
                            <Button
                                variant="label-brand "
                                className="btn-sm btn-bold"
                                onClick={() => dispatch(logoutUser())}
                            >
                                {Intl.formatMessage({ id: 'TRANSLATOR.SIGNOUT' })}
                            </Button>
                        )}
                        {impersonated && (
                            <Button
                                variant="danger"
                                className="btn-sm btn-bold"
                                onClick={() => dispatch(stopImpersonateUser())}
                            >
                                {Intl.formatMessage({ id: 'AUTOUSER.USERS.IMPERSONATE.STOP' })}
                            </Button>
                        )}
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UserProfile;
