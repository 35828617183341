export const RPA_BRANDS = [
    // To enable any RPA process uncomment or add a new one with this format
    // {
    //     brands: ['RENAULT', 'DACIA'],
    //     tool: 'MAEVA',
    //     logo: '/media/marques/renault.svg'
    // }
];

export const SLUG_IDS = ['MaevaId'];

export const getRPAByBrand = (brand) => RPA_BRANDS.find((rpa_brand) => rpa_brand.brands.includes(brand)) || undefined;
