import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import RadioButtonsFilter from '@app/partials/content/Filters/RadioButtonsFilter';
import SwitchInputFilter from '@app/partials/content/Filters/SwitchInputFilter';

const StockVehiclesFilterPictureBlock = ({ updatePendingFilters }) => {
    const isContextEFB = useSelector((state) => state.auth.userData.context === 'efb');

    return (
        <Card>
            <Card.Header>
                <i className="card__icon la-2x las la-camera-retro text-dark" />
                {Intl.formatMessage({ id: 'STOCK_VEHICLES.FILTERS.CARD.TITLE.PHOTOS' })}
            </Card.Header>
            <Card.Body className={`mx-${isContextEFB ? '8' : 'auto'} d-flex flex-column justify-content-around p-0`}>
                {isContextEFB ? (
                    <Table className="table-borderless table-condensed table-hover">
                        <thead>
                            <tr>
                                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                <th />
                                <th>
                                    <div className="d-flex justify-content-around">
                                        <span>
                                            {Intl.formatMessage({
                                                id: 'STOCK_VEHICLES.FILTERS.LABEL.BUTTON.RADIO.YES',
                                            })}
                                        </span>
                                        <span>
                                            {Intl.formatMessage({ id: 'STOCK_VEHICLES.FILTERS.LABEL.BUTTON.RADIO.NO' })}
                                        </span>
                                        <span>
                                            {Intl.formatMessage({
                                                id: 'STOCK_VEHICLES.FILTERS.LABEL.BUTTON.RADIO.ALL',
                                            })}
                                        </span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{Intl.formatMessage({ id: `STOCK_VEHICLES.FILTERS.CARD.TITLE.PHOTOS` })}</td>
                                <td>
                                    <div>
                                        <RadioButtonsFilter
                                            name="has_photos"
                                            className="d-flex justify-content-around"
                                            filterName={Intl.formatMessage({
                                                id: `STOCK_VEHICLES.FILTERS.CARD.TITLE.PHOTOS`,
                                            })}
                                            values={[
                                                {
                                                    key: 'oui',
                                                    value: true,
                                                },
                                                {
                                                    key: 'non',
                                                    value: false,
                                                },
                                                {
                                                    key: 'tous',
                                                    value: '',
                                                },
                                            ]}
                                            defaultValue=""
                                            pending
                                            onPendingChange={updatePendingFilters}
                                            displayLabel={false}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                ) : (
                    <>
                        <SwitchInputFilter
                            name="is_degraded_360"
                            className="mr-5 switch-input--inactive-label-left-secondary switch-input--inactive-toggle-lighten switch-input--active-toggle-primary w-100 justify-content-end"
                            values={[
                                {
                                    value: true,
                                    labelFirst: Intl.formatMessage({
                                        id: 'STOCK_VEHICLES.FILTERS.LABEL.PICTURE.360_DEGRADED',
                                    }),
                                },
                            ]}
                            pending
                            onPendingChange={updatePendingFilters}
                        />
                        <SwitchInputFilter
                            name="is_360"
                            className="mr-5 switch-input--inactive-label-left-secondary switch-input--inactive-toggle-lighten switch-input--active-toggle-primary w-100 justify-content-end"
                            values={[
                                {
                                    value: true,
                                    labelFirst: Intl.formatMessage({
                                        id: 'STOCK_VEHICLES.FILTERS.LABEL.PICTURE.360_FULL',
                                    }),
                                },
                            ]}
                            pending
                            onPendingChange={(valueFilter) => {
                                updatePendingFilters({
                                    ...valueFilter,
                                    is_degraded_360: {
                                        value: false,
                                        labelFirst: Intl.formatMessage({
                                            id: 'STOCK_VEHICLES.FILTERS.LABEL.PICTURE.360_DEGRADED',
                                        }),
                                    },
                                });
                            }}
                        />
                        <SwitchInputFilter
                            name="is_photos_classic"
                            className="mr-5 switch-input--inactive-label-left-secondary switch-input--inactive-toggle-lighten switch-input--active-toggle-primary w-100 justify-content-end"
                            values={[
                                {
                                    value: true,
                                    labelFirst: Intl.formatMessage({
                                        id: 'STOCK_VEHICLES.FILTERS.LABEL.PICTURE.CLASSICS',
                                    }),
                                },
                            ]}
                            pending
                            onPendingChange={updatePendingFilters}
                        />
                        <SwitchInputFilter
                            name="is_damages"
                            className="mr-5 switch-input--inactive-label-left-secondary switch-input--inactive-toggle-lighten switch-input--active-toggle-primary w-100 justify-content-end"
                            values={[
                                {
                                    value: true,
                                    labelFirst: Intl.formatMessage({
                                        id: 'STOCK_VEHICLES.FILTERS.LABEL.PICTURE.DEFAULTS',
                                    }),
                                },
                            ]}
                            pending
                            onPendingChange={updatePendingFilters}
                        />
                        <SwitchInputFilter
                            name="is_waiting_images"
                            className="mr-5 switch-input--inactive-label-left-secondary switch-input--inactive-toggle-lighten switch-input--active-toggle-primary w-100 justify-content-end"
                            values={[
                                {
                                    value: true,
                                    labelFirst: Intl.formatMessage({
                                        id: 'STOCK_VEHICLES.FILTERS.LABEL.PICTURE.WAITING_IMAGES',
                                    }),
                                },
                            ]}
                            pending
                            onPendingChange={updatePendingFilters}
                        />
                    </>
                )}
            </Card.Body>
        </Card>
    );
};

export default StockVehiclesFilterPictureBlock;
