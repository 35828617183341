import React from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { LayoutContextConsumer } from '@src/_metronic';
import { Intl } from '@src/_metronic/i18n/I18nProvider';
import BreadCrumbs from '@src/_metronic/layout/sub-header/components/BreadCrumbs';

import { routeTo } from '@app/helpers/RoutesHelper';

import { useAppSelector } from '@app/hooks';
import ROUTES from '@app/router/Routes';
import { updateApplication } from '@app/store/autouser/application/application.thunk';

import subHeader from '@app/partials/HoC/subHeader';

function ApplicationsSubHeader(props) {
    const methods = useFormContext();
    const params = useParams();
    const dispatch = useDispatch();

    const newApplication = typeof params.id === 'undefined';
    const { application } = useAppSelector((state) => state.application);

    const onSubmit = (data) => {
        dispatch(updateApplication(params.id, data));
    };

    const { subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle } = props;

    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: 'MENU.AUTOUSER' }),
        },
        {
            title: Intl.formatMessage({ id: 'MENU.AUTOUSER.APPS' }),
        },
        {
            title: Intl.formatMessage(
                {
                    id: newApplication ? 'AUTOUSER.APPLICATION.CREATE' : 'AUTOUSER.APPLICATION.EDIT',
                },
                { name: application?.name || '' },
            ),
        },
    ];

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            type="button"
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}

                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">{Intl.formatMessage({ id: 'MENU.AUTOUSER' })}</h3>
                                <BreadCrumbs items={breadcrumb} />
                            </>
                        )}
                    </LayoutContextConsumer>
                </div>
                {params.id && (
                    <div className="d-flex align-items-center">
                        <Link to={routeTo(ROUTES.APPLICATIONS.PATH)}>
                            <Button className="mr-2" variant="outline-primary">
                                {Intl.formatMessage({ id: 'TRANSLATOR.BACK' })}
                            </Button>
                        </Link>
                        <Button className="mr-2" onClick={methods.handleSubmit(onSubmit)} variant="primary">
                            {Intl.formatMessage({ id: 'TRANSLATOR.SAVE' })}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default subHeader(ApplicationsSubHeader);
