import { Toast } from 'primereact/toast';
import React, { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const Sticky = ({ ...props }) => {
    const [portalItem, setPortalItem] = useState(null);

    useLayoutEffect(() => {
        setPortalItem(document.getElementsByTagName('body')[0]);
    }, []);

    return portalItem ? ReactDOM.createPortal(<Toast {...props} />, portalItem) : null;
};

export default Sticky;
