import * as utils from '@src/_metronic/utils/utils';

const localStorageLastLocationKey = 'demo1-lastLocation';

function acceptLocation(lastLocation) {
    return !!(
        lastLocation &&
        lastLocation.pathname &&
        lastLocation.pathname !== '/' &&
        lastLocation.pathname.indexOf('auth') === -1 &&
        lastLocation.pathname !== '/logout'
    );
}

export function saveLastLocation(lastLocation) {
    const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
    const useLocations = localStorateLocations ? JSON.parse(localStorateLocations) : [];

    if (acceptLocation(lastLocation)) {
        useLocations.push(lastLocation);
        utils.setStorage(localStorageLastLocationKey, JSON.stringify(useLocations), 120);
    }
}

export function forgotLastLocation() {
    utils.removeStorage(localStorageLastLocationKey);
}

export function getLastLocation() {
    const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
    if (!localStorateLocations) {
        return '/';
    }

    const userLocations = JSON.parse(localStorateLocations);
    return userLocations.length > 0 ? userLocations.pop() : '/';
}
