import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";

export default class MenuSubmenu extends React.Component {
  render() {
    const { item } = this.props;

    return (
      <ul className="kt-menu__subnav">
        {item.submenu.map((child, index) => (
          <React.Fragment key={index}>
            {child.section && (
              <MenuSection
                item={child}
                parentItem={item}
              />
            )}

            {child.separator && (
              <MenuItemSeparator
                item={child}
                parentItem={item}
              />
            )}

            {(child.title || child.translate) && (
              <MenuItem
                item={child}
                parentItem={item}
              />
            )}
          </React.Fragment>
        ))}
      </ul>
    );
  }
}
