import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import store from '@app/store/store';

import SwitchInput from '@app/partials/content/SwitchInput';

const ExternalSwitch = ({ className, isInternal, setIsInternal, labelFirst, hidden }) => {
    const { context } = store.getState().auth.userData;

    if (hidden) {
        return '';
    }

    return (
        <SwitchInput
            name="external"
            labelFirst={labelFirst}
            labelSecond={Intl.formatMessage(
                { id: 'AUTOUSER.USERS.TABLE.VIEW.INTERNAL' },
                { context: context?.toUpperCase() },
            )}
            className={classNames(
                className,
                'font-size-h4',
                'font-weight-semibold',
                'switch-input--inactive-label-left-primary',
                'switch-input--inactive-toggle-primary',
                'switch-input--active-toggle-primary',
                'switch-input--active-label-right-primary',
            )}
            active={isInternal}
            form={false}
            onChange={(value) => {
                setIsInternal(value);
            }}
        />
    );
};

ExternalSwitch.defaultProps = {
    labelFirst: <FormattedMessage id="AUTOUSER.USERS.TABLE.VIEW.EXTERNAL" />,
    hidden: false,
};

export default ExternalSwitch;
