import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { updateAnomaly } from '@app/crud/stock/stock.crud';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

const StockActionToDoDetail = ({ showModal, setShowModal, refreshDataTable }) => {
    const [loading, setLoading] = useState(false);
    const { params } = useSelector((state) => state.modal);
    const { userData } = useSelector((state) => state.auth);
    const Intl = useIntl();

    const anomaly = params?.anomaly;
    const modal = anomaly?.reason?.action?.definition?.modal || {};

    const updateAnomalyHandler = () => {
        setLoading(true);
        updateAnomaly({
            anomalyId: anomaly.id,
            userId: userData.id,
            firstName: userData.first_name,
            lastName: userData.last_name,
        })
            .then((response) => {
                setLoading(false);
                const confirm = anomaly?.reason?.action?.definition?.modal?.button?.confirm;
                if (response?.id) {
                    if (confirm) {
                        toast({
                            variant: 'success',
                            message: confirm?.message,
                        });
                    }
                    refreshDataTable();
                    setShowModal(false);
                } else {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'STOCK.ACTION.MODAL.UPDATE.FAILED' }),
                    });
                }
            })
            .catch(() => {
                setLoading(false);
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'STOCK.ACTION.MODAL.UPDATE.FAILED' }),
                });
            });
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className={`las la-2x text-primary ${modal?.icon}`} />}
            title={modal?.title || ''}
            body={<div className="text-center" dangerouslySetInnerHTML={{ __html: modal?.message }} />}
            footer={
                <div className="d-flex justify-content-center w-100 text-center gap-3">
                    <Button onClick={updateAnomalyHandler}>
                        <span dangerouslySetInnerHTML={{ __html: modal?.button?.name }} />
                    </Button>
                    <Button variant="outline-primary" onClick={() => setShowModal(false)}>
                        <FormattedHTMLMessage id="STOCK.ACTION.CANCEL" />
                    </Button>
                </div>
            }
        />
    );
};

export default StockActionToDoDetail;
