import React from 'react';

import { Vehicle } from '@app/store/stock/stock.type';

import Label from '@app/partials/content/Label';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';

export default function StockVehiclesTypeColumn({ vehicle }: { vehicle: Vehicle }) {
    return (
        <div>
            <span>{vehicle.type}</span>
            <div className="mt-2">
                {vehicle?.product && vehicle?.stock_status && (
                    <OverlayTooltip
                        label="STOCK_VEHICLES.TABLE.STATUS_TOOLTIP"
                        paramLabel={{ product: vehicle.product }}
                        id="status"
                    >
                        <Label variant="light" className="position-relative">
                            <span className="text-dark">
                                <b>{vehicle.product}</b>
                                <span className="position-absolute w-100" style={{ top: '110%', left: '0' }}>
                                    &bull; {vehicle.stock_status}
                                </span>
                            </span>
                        </Label>
                    </OverlayTooltip>
                )}
            </div>
        </div>
    );
}
