import { PartExchange } from "@app/crud/customers/customer.type";
import dayjs from 'dayjs';
import { toAbsoluteUrl } from '../../../../_metronic';

export const getPictureTemplate = (partExchange: PartExchange, defaultValue: string | null = null) => {
    if (partExchange?.image) {
        return partExchange.image
    } else {
        return defaultValue !== null
            ? defaultValue
            : toAbsoluteUrl("/media/bg/default-veh-bg.svg");
    }
}

export const getBrandTemplate = (partExchange: PartExchange, defaultValue = '-') => {
    if (partExchange?.make) {
        return partExchange.make;
    } else {
        return defaultValue;
    }
}

export const getFuelTypeTemplate = (partExchange: PartExchange) => {
    if (partExchange?.fuelType) {
        return partExchange.fuelType;
    } else {
        return '-'
    }
}

export const getGearsTypeTemplate = (partExchange: PartExchange) => {
    if(partExchange?.gearbox) {
        return partExchange?.gearbox;
    } else {
        return '-'
    }
}

export const getProductionYearTemplate = (partExchange: PartExchange, defaultValue = '-') => {
    if(partExchange?.dateFirstRegistration) {
        return dayjs(partExchange?.dateFirstRegistration).format('YYYY');
    } else {
        return defaultValue;
    }
}
