import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React, { ReactNode, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import useFilters from '@app/hooks/useFilters';

import { ApiResponse } from '@app/helpers/AxiosHelper';
import { stripHtmlTags } from '@app/helpers/StringHelper';

import { actions as modalActions } from '@app/store/modal/modal.store';

import { CalendarFilter } from '@app/partials/content/CalendarFilter';
import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import SelectFilter from '@app/partials/content/SelectFilter';

import TextOverflow from '@app/components/formatters/text/TextOverflow';

import { Alert, AlertLevel } from './alerts.type';
import { alertSeverityColors } from './helpers/alerts.helper';

type AlertsDatatableProps = {
    data: ApiResponse<Alert[]>;
    status: string;
    loading: boolean;
    handleFilter: ({}: any) => void;
    handlePagination: (e: any) => void;
    alertLevels: AlertLevel[];
};

function AlertsDatatable({ data, status, loading, handleFilter, handlePagination, alertLevels }: AlertsDatatableProps) {
    const Intl = useIntl();
    const dispatch = useDispatch();

    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: 'administration_alerts',
    });

    useEffect(() => {
        handleFilter({
            isArchive: status && status === 'archive' ? 1 : 0,
        });
    }, [status]);

    const inputFilter = (name: string) => <InputFilter name={name} onChange={handleFilter} />;

    const selectFilter = (name: string, options: ReactNode) => (
        <SelectFilter name={name} options={options} onChange={handleFilter} />
    );

    const calendarFilter = (name: string) => <CalendarFilter name={name} onChange={handleFilter} />;

    const dateStartBodyTemplate = (alert: Alert) =>
        alert?.dateStart ? dayjs(alert?.dateStart).format('DD/MM/YYYY [à] HH[h]mm') : '-';

    const dateEndBodyTemplate = (alert: Alert) =>
        alert?.dateEnd ? dayjs(alert?.dateEnd).format('DD/MM/YYYY [à] HH[h]mm') : '-';

    const actionsBodyTemplate = (alert: Alert) => (
        <>
            <Button
                className="p-2 px-3"
                variant="primary"
                onClick={() => dispatch(modalActions.modalChange('alert_modal_edit', alert))}
            >
                {status && status === 'archive' ? (
                    <FormattedMessage id="TRANSLATOR.DUPLICATE" />
                ) : (
                    <FormattedMessage id="TRANSLATOR.UPDATE" />
                )}
            </Button>
            <Button
                variant="danger"
                className="p-2 ml-3"
                onClick={() => dispatch(modalActions.modalChange('alert_modal_delete', alert))}
            >
                <i className="las la-trash p-0" />
            </Button>
        </>
    );

    const cols = [
        {
            field: 'title',
            header: <FormattedMessage id="ADMINISTRATION.ALERTS.TITLE" />,
            style: { width: '20%' },
            filter: true,
            filterElement: inputFilter('title'),
            body: (alert: Alert) => alert.title,
        },
        {
            field: 'content',
            header: <FormattedMessage id="ADMINISTRATION.ALERTS.CONTENT" />,
            style: { width: '33%' },
            filter: true,
            filterElement: inputFilter('content'),
            body: (alert: Alert) => (
                <TextOverflow content={stripHtmlTags(alert.content).replace('&nbsp;', ' ')} lines={2} />
            ),
        },
        {
            field: 'alertLevel',
            header: <FormattedMessage id="ADMINISTRATION.ALERTS.ALERT_LEVEL" />,
            style: { width: '5%' },
            filter: true,
            filterElement: selectFilter(
                'alertLevel',
                <>
                    <option value="">
                        {Intl.formatMessage({ id: 'ADMINISTRATION.ALERTS.ALERT_LEVEL.PLACEHOLDER' })}
                    </option>
                    {alertLevels.map((item) => (
                        <option value={item.id} className={`text-${alertSeverityColors[item.slug]}`}>
                            {item.name}
                        </option>
                    ))}
                </>,
            ),
            body: (alert: Alert) => (
                <span className={`text-${alertSeverityColors[alert.alertLevel.slug]}`}>{alert.alertLevel.name}</span>
            ),
        },
        {
            field: 'receipted',
            header: <FormattedMessage id="ADMINISTRATION.ALERTS.START_DATE" />,
            body: dateStartBodyTemplate,
            filter: true,
            style: { width: '10%' },
            filterElement: calendarFilter('dateStart'),
        },
        {
            field: 'updated',
            header: <FormattedMessage id="ADMINISTRATION.ALERTS.END_DATE" />,
            body: dateEndBodyTemplate,
            filter: true,
            style: { width: '10%' },
            filterElement: calendarFilter('dateEnd'),
        },
        {
            field: 'actions',
            header: <FormattedMessage id="ADMINISTRATION.ALERTS.TABLE.HEAD.ACTIONS" />,
            style: { width: '9%' },
            body: actionsBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <FiltersProvider value={filters}>
            {/* @ts-ignore */}
            <HoCDataTable
                value={data?.result || []}
                totalRecords={data?.count || 0}
                lazy
                loading={loading}
                paginator
                onFilter={handleFilter}
                onPage={handlePagination}
                paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                rows={items}
                first={start}
                sortField={sortField}
                sortOrder={sortOrder}
                removableSort
                filterDisplay="row"
                rowsPerPageOptions={[25, 50, 100]}
                emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
            >
                {dynamicColumns}
            </HoCDataTable>
        </FiltersProvider>
    );
}

export default AlertsDatatable;
