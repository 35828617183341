import produce from 'immer';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import React, { useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import useFetch from '@app/hooks/useFetch';

import { getTags } from '@app/crud/autouser/tags.crud';

import useAction from '@app/pages/customers/Hooks/useAction';
import DealershipVendors from '@app/pages/customers/Partials/DealershipVendors';
import { actions } from '@app/store/modal/modal.store';

import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

const DEALERSHIP = 0;
const DEALER = 1;

const AssignTo = ({ showModal, setShowModal }) => {
    const dispatch = useDispatch();
    const Intl = useIntl();
    const [affectTo, setAffectTo] = useState(DEALERSHIP);
    const [type, setType] = useState(null);
    const [dealership, setDealership] = useState(null);
    const [dealer, setDealer] = useState(null);
    const { data: tags, loading: loadingTags } = useFetch({
        fetchAction: getTags,
        resultInterceptor: (response) =>
            response?.result?.filter?.(
                (tag) => tag?.category === 'typologie_lead' && !['fluidite', 'constructeur'].includes(tag?.slug),
            ),
        autoFetch: true,
    });
    const [loading, createAction] = useAction({
        type: 'assign_to',
        dataInterceptor: (data) =>
            produce(data, (draftData) => {
                draftData.lead.type = type;
            }),
        success: () => {
            setShowModal(false);
            toast({
                variant: 'success',
                message: Intl.formatMessage({ id: 'TRANSLATOR.SUCCESS' }),
            });
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        },
    });

    useEffect(() => {
        setType(tags?.[0]?.slug);
    }, [tags, setType]);

    useEffect(() => {
        setDealer(null);
    }, [affectTo]);

    const handleSumbit = () => {
        createAction({
            dealership: {
                id: dealership?.refDealershipId,
                name: dealership?.title,
            },
            ...(dealer?.id && {
                newOwner: {
                    id: dealer?.id,
                    firstname: dealer?.first_name,
                    lastname: dealer?.last_name,
                    email: dealer?.email,
                },
            }),
        });
    };

    /* eslint-disable jsx-a11y/label-has-associated-control */
    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-exchange-alt" />}
            title={<FormattedHTMLMessage id="CUSTOMER.MODAL.ASSIGN_TO.TITLE" />}
            body={
                <div>
                    {(loadingTags || loading) && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                    <div className="d-flex justify-content-center">
                        {affectTo === DEALERSHIP ? (
                            <Image src="/media/pages/customers/affecter-a-une-concession.svg" />
                        ) : (
                            <Image src="/media/pages/customers/affecter-a-un-vendeur.svg" />
                        )}
                    </div>
                    <div className="mt-8 d-flex justify-content-center">
                        <div className="d-flex align-items-center">
                            <RadioButton
                                inputId="dealership"
                                onChange={() => setAffectTo(DEALERSHIP)}
                                checked={affectTo === DEALERSHIP}
                            />
                            <label htmlFor="dealership" className="ml-5 my-0">
                                <FormattedHTMLMessage id="CUSTOMER.MODAL.ASSIGN_TO.DEALERSHIP" />
                            </label>
                        </div>
                        <div className="d-flex align-items-center ml-15">
                            <RadioButton
                                inputId="dealer"
                                onChange={() => setAffectTo(DEALER)}
                                checked={affectTo === DEALER}
                            />
                            <label htmlFor="dealer" className="ml-5 my-0">
                                <FormattedHTMLMessage id="CUSTOMER.MODAL.ASSIGN_TO.DEALER" />
                            </label>
                        </div>
                    </div>
                    <Dropdown
                        value={type}
                        options={tags}
                        optionValue="slug"
                        optionLabel="name"
                        className="mx-3 mt-10"
                        onChange={(e) => setType(e.value)}
                    />
                    <DealershipVendors
                        referent={dealer}
                        setReferent={setDealer}
                        setDealership={setDealership}
                        dealershipClassName="w-100 mt-5"
                        vendorsClassName="w-100 mt-2"
                        labels={false}
                        showReferent={affectTo === DEALER}
                        className="d-flex align-items-center flex-column mx-3"
                    />
                </div>
            }
            footer={
                <div className="w-100 d-flex justify-content-end">
                    <Button
                        variant="outline-secondary"
                        style={{ width: '100px' }}
                        onClick={() => dispatch(actions.rollBackModal())}
                    >
                        <FormattedHTMLMessage id="TRANSLATOR.PREVIOUS" />
                    </Button>
                    <Button
                        variant="primary"
                        className="ml-5"
                        style={{ width: '100px' }}
                        disabled={(affectTo === DEALERSHIP && !dealership) || (affectTo === DEALER && !dealer)}
                        onClick={handleSumbit}
                    >
                        <FormattedHTMLMessage id="TRANSLATOR.VALIDATE" />
                    </Button>
                </div>
            }
        />
    );
};

export default AssignTo;
