import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useModal from '@app/hooks/useModal';

import { getActions } from '@app/pages/customers/Helpers/Actions';

import ActionButton from '@app/partials/content/ActionButton';
import ModalDefault from '@app/partials/content/modals/Modal.default';

import { VehicleSearch } from './index';

import VehicleOutStock from './VehicleOutStock';

const InOutChoiceVehicle = ({ updateVehicle, addVehicle, showModal, setShowModal }) => {
    const [showVehicleSearch, setShowVehicleSearch] = useModal(false, null, true);
    const [showVehicleOutStock, setShowVehicleOutStock] = useModal(false, null, true);
    const [vehicleType, setVehicleType] = useState('vo');
    const { params } = useSelector((state) => state.modal);
    const Intl = useIntl();

    const actions = getActions(['in_stock', 'out_stock', 'fluidity']);

    const handleClick = (action) => {
        switch (action) {
            case 'IN_STOCK':
                setShowVehicleSearch(true);
                setVehicleType('in_stock');
                break;
            case 'OUT_STOCK':
                setShowVehicleOutStock(true);
                break;
            case 'FLUIDITY':
                setShowVehicleSearch(true);
                setVehicleType('is_fluidite');
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        setShowVehicleSearch(false);
        setShowVehicleOutStock(false);
    }, [showModal]);

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x la-car text-primary" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.IN_OUT_CHOICE.VEHICLE.CHOICE' })}
            body={
                <>
                    <div className="d-flex flex-lg-nowrap flex-wrap justify-content-center mt-lg-5 mt-0 mb-lg-8 mb-0">
                        {actions.map(([action, properties]) => (
                            <ActionButton
                                {...properties}
                                style={{
                                    minHeight: '110px',
                                    minWidth: '140px',
                                }}
                                key={action}
                                onClick={() => handleClick(action)}
                                className="mr-lg-6 mx-lg-0 mx-4 mt-lg-0 mt-8"
                            />
                        ))}
                    </div>
                    <VehicleSearch
                        showModal={showVehicleSearch}
                        onSelect={params?.vehicle ? updateVehicle : addVehicle}
                        setShowModal={setShowVehicleSearch}
                        vehicleType={vehicleType}
                    />
                    <VehicleOutStock
                        showModal={showVehicleOutStock}
                        onSelect={params?.vehicle ? updateVehicle : addVehicle}
                        setShowModal={setShowVehicleOutStock}
                    />
                </>
            }
        />
    );
};

export default InOutChoiceVehicle;
