import dayjs from 'dayjs';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import ModalDefault from '@app/partials/content/modals/Modal.default';

import { getEventStateBySlug } from '../Helpers/EventStatus';

export default function TimeLine({ order, showModal, setShowModal }) {
    const Intl = useIntl();
    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <ModalDefault
            show={showModal}
            size="xl"
            hideModal={handleClose}
            icon={<i className="las la-wrench la-2x text-primary" />}
            title={Intl.formatMessage({ id: 'APV.ORDER.MODAL.DETAILS' })}
            body={
                <div className="timeline w-100">
                    {order?.events &&
                        order?.events.map((event) => (
                            <div key={`apvTimeline-${event.id}`} className="timeline-item align-items-start">
                                <div className="timeline-label text-right font-weight-bold text-dark-75 font-size-lg">
                                    {dayjs(event.created).format('DD/MM/YY [•] HH:mm')} -
                                </div>
                                <div className="timeline-badge">
                                    {/* <i className={`fa fa-genderless text-${getEventStateBySlug(event)} icon-xl`} /> */}
                                    <i className={`fa fa-genderless text-${getEventStateBySlug(event)} icon-xl`} />
                                </div>
                                <div className="d-flex">
                                    {event?.name && (
                                        <div className="font-weight-normal font-size-lg timeline-content pl-3">
                                            {event.name}
                                        </div>
                                    )}
                                    {event?.user && (
                                        <div className="font-weight-normal font-size-lg timeline-content">
                                            &nbsp;|&nbsp;{event.user.lastName} {event.user.firstName}
                                        </div>
                                    )}
                                    {event?.eventSource?.name && (
                                        <div className="font-weight-normal font-size-lg timeline-content">
                                            &nbsp;|&nbsp;{event.eventSource.name}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                </div>
            }
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button variant="primary" onClick={handleClose}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                </div>
            }
        />
    );
}
