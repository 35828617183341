import classNames from 'classnames';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { ModalProps } from 'react-bootstrap/Modal';

import Loader from '../Loader';

interface ModalDefaultProps {
    body?: React.ReactNode;
    bodyClassName?: string;
    centered?: boolean;
    className?: string;
    closeButton?: boolean;
    footer?: React.ReactNode;
    hideModal?: () => void;
    icon?: React.ReactNode;
    loading?: boolean;
    onEnter?: () => void;
    show?: boolean;
    size?: ModalProps['size'];
    subtitle?: React.ReactNode;
    title?: React.ReactNode;
    titleVariant?: string;
    backdrop?: ModalProps['backdrop'];
}
const ModalDefault = ({
    body,
    bodyClassName,
    centered,
    className,
    closeButton,
    footer,
    hideModal,
    icon,
    loading,
    onEnter,
    show,
    size,
    subtitle,
    title,
    titleVariant,
    backdrop,
}: ModalDefaultProps) => (
    <Modal
        size={size}
        show={show}
        enforceFocus={false}
        onEnter={onEnter}
        onHide={() => hideModal?.()}
        centered={centered}
        className={className}
        backdrop={backdrop}
    >
        {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
        <Modal.Header className="border-bottom-0 text-center" closeButton={closeButton} />
        {(icon || title) && (
            <Modal.Title className="text-center">
                <div className={classNames('px-lg-0 px-5 text-center', titleVariant)}>
                    {icon ?? null}
                    <div>{title}</div>
                </div>
                {subtitle && <div className="font-size-base">{subtitle}</div>}
            </Modal.Title>
        )}
        <Modal.Body className={bodyClassName}>{body ?? null}</Modal.Body>
        {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
);

ModalDefault.defaultProps = {
    body: null,
    bodyClassName: '',
    centered: false,
    className: '',
    closeButton: true,
    footer: null,
    hideModal: () => {},
    icon: null,
    loading: false,
    onEnter: () => {},
    show: false,
    size: undefined,
    subtitle: null,
    title: null,
    titleVariant: 'text-primary',
    backdrop: undefined,
};
export default ModalDefault;
