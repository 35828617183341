import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import StockPrice from '@app/pages/stock/partials/price/StockPrice';
import { RootState } from '@app/store/rootDuck';
import { Vehicle } from '@app/store/stock/stock.type';

import Label from '@app/partials/content/Label';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';
import Permission from '@app/partials/content/Permission';

import Price from '@app/components/formatters/price/Price';

type VehicleProps = {
    vehicle: Vehicle;
};

export default function StockVehiclesPriceColumn({ vehicle }: VehicleProps) {
    const Intl = useIntl();
    const [vehicleItem, setVehicleItem] = useState(vehicle);
    const [showPricingModal, setShowPricingModal] = useState(false);
    const [showPricingHistoryModal, setShowPricingHistoryModal] = useState(false);
    const isContextEFB = useSelector((state: RootState) => state.auth.userData.context === 'efb');

    useEffect(() => {
        setVehicleItem(vehicle);
    }, [vehicle]);

    const evolutionPrice = (oldPrice: number, newPrice: number) => oldPrice !== newPrice;

    const rentHandler = () =>
        Number.isInteger(vehicleItem?.finance_amount) && (
            <OverlayTooltip
                label={`STOCK_VEHICLES.TABLE.RENT_${vehicleItem?.finance_loa ? 'LOA' : 'CREDIT'}`}
                id="loyer_stock"
            >
                <Label variant="light" className="font-weight-bold mb-2">
                    <Price value={vehicleItem?.finance_credit || vehicleItem?.finance_loa} suffix=" €/mois" />
                </Label>
            </OverlayTooltip>
        );

    if (vehicleItem.type && vehicleItem.type.toUpperCase() === 'VN') {
        return (
            <>
                {Number.isInteger(vehicleItem.price) && (
                    <>
                        {Number.isInteger(vehicleItem?.catalog_price) &&
                            vehicleItem.price !== vehicleItem.catalog_price && (
                                <div>
                                    <OverlayTooltip label="STOCK_VEHICLES.TABLE.PRICE_CATALOGUE" id="price_catalogue">
                                        <span style={{ textDecoration: 'line-through' }}>
                                            <Price value={vehicleItem.catalog_price} />
                                        </span>
                                    </OverlayTooltip>
                                </div>
                            )}
                        <div>
                            <OverlayTooltip label="STOCK_VEHICLES.TABLE.PRICE_TOOLTIP" id="price">
                                <Label
                                    variant={
                                        vehicleItem?.afr_publication_warning?.includes?.('price')
                                            ? 'danger-lite'
                                            : 'light'
                                    }
                                    className="font-weight-bold"
                                >
                                    <Price value={vehicleItem.price} />
                                </Label>
                            </OverlayTooltip>
                        </div>
                    </>
                )}
                {rentHandler()}
            </>
        );
    }

    return (
        <>
            {Number.isInteger(vehicleItem.price) && (
                <div className="mb-2">
                    <OverlayTooltip label="STOCK_VEHICLES.TABLE.PRICE_TOOLTIP" id="price">
                        <Label
                            variant={
                                vehicleItem?.afr_publication_warning?.includes?.('price') ? 'danger-lite' : 'light'
                            }
                            className="font-weight-bold"
                        >
                            <Price value={vehicleItem.price} />
                        </Label>
                    </OverlayTooltip>
                </div>
            )}
            {rentHandler()}
            {vehicleItem?.price !== vehicleItem?.previous_price &&
                evolutionPrice(vehicleItem.previous_price, vehicleItem.price) &&
                !vehicleItem?.pricing?.thumb &&
                !isContextEFB && (
                    <>
                        <button
                            type="button"
                            className="text-primary text-left font-size-sm font-weight-bold btn p-0"
                            onClick={() => setShowPricingHistoryModal(true)}
                        >
                            <u>
                                <FormattedMessage id="STOCK_VEHICLES.TABLE.PRICE_HISTORY" />
                            </u>
                        </button>
                        <StockPrice
                            vehicle={vehicleItem}
                            showDetails={false}
                            showTitleHistory={false}
                            asModal
                            show={showPricingHistoryModal}
                            setShowModal={setShowPricingHistoryModal}
                            setVehicleItem={setVehicleItem}
                            title={
                                <FormattedHTMLMessage
                                    id="STOCK_VEHICLE.MODAL.PRICE.HISTORICAL.TITLE"
                                    values={{ licenseNumber: vehicleItem?.license_number }}
                                />
                            }
                        />
                    </>
                )}
            {vehicleItem?.pricing && vehicleItem?.pricing?.thumb && !isContextEFB && (
                <Permission permissions={['STOCK_VIEW_PRICE_MANAGER']}>
                    <Row>
                        <Col lg={12}>
                            <Button
                                variant="primary-gray"
                                onClick={() => setShowPricingModal(true)}
                                className="p-1 rounded-0 bg-dark-orange border-dark-orange"
                            >
                                <FormattedMessage id="STOCK_VEHICLES.TABLE.PRICING" />
                                {vehicleItem.pricing.thumb && (
                                    <i className={`las la-hand-point-${vehicleItem.pricing.thumb} ml-1 p-0`} />
                                )}
                            </Button>
                        </Col>
                    </Row>
                    <StockPrice
                        vehicle={vehicleItem}
                        asModal
                        show={showPricingModal}
                        setShowModal={setShowPricingModal}
                        title={Intl.formatMessage({ id: 'STOCK_VEHICLES.MODAL.PRICE.TITLE' })}
                        setVehicleItem={setVehicleItem}
                    />
                </Permission>
            )}
        </>
    );
}
