// This event is specially created for a festival in AFR
import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

interface Props {
    event: VcuMappedEventDetails;
}
const DetailAdictiz = ({ event }: Props) => {
    const Intl = useIntl();
    const { adictiz } = event;
    return (
        <div className="d-md-flex justify-content-md-between align-items-md-stretch">
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ADICTIZ.SOURCE' })}
                    value={event?.informations?.source ? event?.informations?.source : '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ADICTIZ.ID.CONTRACT' })}
                    value={adictiz?.srcProcessId ? adictiz?.srcProcessId : '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ADICTIZ.DOTATION' })}
                    value={adictiz?.dotation ? adictiz?.dotation : '-'}
                />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ADICTIZ.VALIDATION_DURATION' })}
                    value={
                        (dayjs(adictiz?.validationDuration).isValid() &&
                            dayjs(adictiz?.validationDuration).format('DD/MM/YYYY à HH:mm')) ||
                        '-'
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ADICTIZ.CAMPAIGN_NAME' })}
                    value={adictiz?.marketingCampaignName ? adictiz?.marketingCampaignName : '-'}
                />
            </div>
        </div>
    );
};

export default DetailAdictiz;
