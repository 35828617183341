import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import useFetch from '@app/hooks/useFetch';

import { getDashboardOnlineLeadsProcessing } from '@app/crud/dashboard/dashboard.crud';

import { DashboardContext } from '../../DashboardProvider';
import { getTransformation } from '../../helpers/DashboardHelper';

const DashboardProcessedLead = ({ className }) => {
    const { overriddenParams } = useContext(DashboardContext);

    const { data, loading, fetch } = useFetch({
        fetchAction: getDashboardOnlineLeadsProcessing,
    });

    useEffect(() => {
        if (overriddenParams) {
            fetch({ params: overriddenParams });
        }
    }, [overriddenParams]);

    const takenInLessThan30min = data?.takenInLessThan30min;
    const callTransferOffHook = data?.callTransferOffHook;
    const closedInLessThan24hours = data?.closedInLessThan24hours;
    const inProgressSinceMoreThan21days = data?.inProgressSinceMoreThan21days;

    if (loading) {
        return <DashboardProcessedLead.Skeleton />;
    }

    return (
        <div className={classNames('dashboard-processed-lead', className)}>
            <div className="font-size-lg font-weight-bold d-flex align-items-center">
                <i className="dashboard-general-perf__icon las la-2x la-mouse-pointer mr-2" />
                <FormattedMessage id="DASHBOARD.PROCESSED_LEAD.TITLE" />
            </div>
            <div className="d-flex flex-wrap">
                <div className="w-sm-auto w-50 mr-sm-3 mr-0 mt-2 flex-grow-1">
                    <div className="dashboard-processed-lead__number text-success">
                        {getTransformation(takenInLessThan30min?.success, 0, takenInLessThan30min?.total)}&nbsp;%
                    </div>
                    <div className="line-height-md font-weight-semibold">
                        <FormattedHTMLMessage id="DASHBOARD.PROCESSED_LEAD.TAKEN" />
                    </div>
                </div>
                <div className="w-sm-auto w-50 mr-sm-3 mr-0 mt-2 flex-grow-1">
                    <div className="dashboard-processed-lead__number text-warning">
                        {getTransformation(callTransferOffHook?.success, 0, callTransferOffHook?.total)}&nbsp;%
                    </div>
                    <div className="line-height-md font-weight-semibold">
                        <FormattedHTMLMessage id="DASHBOARD.PROCESSED_LEAD.TRANSFERRED" />
                    </div>
                </div>
                <div className="w-sm-auto w-50 mr-sm-3 mr-0 mt-2 flex-grow-1">
                    <div className="dashboard-processed-lead__number">
                        <span className="text-danger">{closedInLessThan24hours?.success}</span>
                        <span className="dashboard-processed-lead__number--closed font-weight-bold">
                            {closedInLessThan24hours && '/'}
                            {closedInLessThan24hours?.total}
                        </span>
                    </div>
                    <div className="line-height-md font-weight-semibold">
                        <FormattedHTMLMessage id="DASHBOARD.PROCESSED_LEAD.CLOSED" />
                    </div>
                </div>
                <div className="w-sm-auto w-50 mt-2 flex-grow-1">
                    <div className="dashboard-processed-lead__number text-danger">{inProgressSinceMoreThan21days}</div>
                    <div className="line-height-md font-weight-semibold">
                        <FormattedHTMLMessage id="DASHBOARD.PROCESSED_LEAD.IN_PROGRESS" />
                    </div>
                </div>
            </div>
        </div>
    );
};

DashboardProcessedLead.Skeleton = () => (
    <Skeleton borderRadius="10px" className="flex-basis-0 flex-grow-1 flex-shrink-1 mt-lg-2 mt-6 p-20" />
);

export default DashboardProcessedLead;
