import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { Column } from 'primereact/column';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFilters from '@app/hooks/useFilters';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { convertBase64ToBlob } from '@app/helpers/ObjectHelper';
import { routeTo } from '@app/helpers/RoutesHelper';

import { getOrderInStorePDF } from '@app/crud/apv/order.crud';
import { Order } from '@app/crud/apv/order.type';

import { useAppSelector } from '@app/hooks';
import { fetchOrders } from '@app/store/apv/order/order.thunk';

import CalendarRangeFilter from '@app/partials/content/CalendarRangeFilter';
import DropdownFilter from '@app/partials/content/DropdownFilter';
import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import Label from '@app/partials/content/Label';
import toast from '@app/partials/content/Toast';

import { mapQuotationTypeSlug } from './Helpers/Quotations';
import { getDestinationRoute, getFontColorBySlug, getStateBySlug } from './Helpers/Status';

interface QuotationTableProps {
    type: string;
}

const QuotationTable = ({ type }: QuotationTableProps) => {
    const { orders, totalRecords, filtersContent, loading } = useAppSelector((state) => state.order);

    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: `apv_instore_quotations_${type}`,
    });
    const { handleFilter, handlePagination, handleSort } = useTableHandlers({
        filterStore: `apv_instore_quotations_${type}`,
        // @ts-ignore
        fetchAction: fetchOrders,
        paramsInterceptor: (params, filters) => {
            let quoteType = mapQuotationTypeSlug('all');
            if (type === 'draft') {
                quoteType = mapQuotationTypeSlug('draft');
            } else if (type === 'waiting_for_customer') {
                quoteType = mapQuotationTypeSlug('waiting_for_customer');
            } else if (type === 'closed') {
                quoteType = mapQuotationTypeSlug('closed');
            }
            return {
                ...params,
                start: filters.start,
                items: filters.items,
                statusApvInStoreByGroup: quoteType,
            };
        },
    });

    const handleShowPdf = async (order: Order) => {
        try {
            const orderPdf = await getOrderInStorePDF(String(order.id));

            if (orderPdf?.pdfContent) {
                const blob = convertBase64ToBlob(orderPdf?.pdfContent);
                saveAs(blob, `devis-${order.reference}.pdf`);
            } else {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'APV.IN_STORE.QUOTATIONS.NO_PDF' }),
                });
            }
        } catch (error) {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    };

    const selectStatusDropdown = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="name"
            name="statusApvInStore"
            options={
                filtersContent?.orderStatusApvInStore &&
                Object.values(filtersContent.orderStatusApvInStore).map(
                    (elm: { name?: string; sub_name?: string; slug?: string }) => ({
                        name: `${elm.name}${elm.sub_name ? ` ${elm.sub_name}` : ''}`,
                        key: elm.slug,
                    }),
                )
            }
            onChange={handleFilter}
        />
    );

    const selectDealershipDropdown = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="name"
            name="dealershipId"
            options={
                filtersContent?.orderDealerships &&
                Object.values(filtersContent.orderDealerships).map((elm: { name?: string; id?: string }) => ({
                    name: elm.name,
                    key: elm.id,
                }))
            }
            onChange={handleFilter}
        />
    );

    const inputFilter = (name: string) => <InputFilter name={name} onChange={handleFilter} />;

    const calendarRangeFilter = (start: string, end: string) => (
        <CalendarRangeFilter start={start} end={end} onChange={handleFilter} />
    );

    const vehicleBodyTemplate = (order: Order) => (
        <>
            {order.registration || ''}
            {order.vin && (
                <>
                    <br />
                    {order.vin}
                </>
            )}
        </>
    );

    const totalBodyTemplate = (order: Order) => {
        return order.totalOrderAmount > 0 ? (
            <NumberFormat
                value={order.totalOrderAmount}
                thousandSeparator=" "
                decimalSeparator=","
                displayType="text"
                suffix=" €"
            />
        ) : (
            <span>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.APVINSTORE.SUMMARY.FREE_PACKAGE' })}</span>
        );
    };

    const dealershipBodyTemplate = (order: Order) => {
        const title = order?.dealership?.dealershipSource?.usual_name || null;
        const id = order?.dealership?.dealershipSource?.id || null;
        return (
            <span className={`${title && 'mr-3'}`}>
                {id}
                <br />
                {title}
            </span>
        );
    };

    const referentBodyTemplate = (order: Order) => (
        <>
            {order.autouserFirstName || ''} {order.autouserLastName || ''}
        </>
    );

    const statusBodyTemplate = (order: Order) => (
        <>
            <Label variant={getStateBySlug(order)}>
                <span className="font-weight-bold" style={{ color: getFontColorBySlug(order) }}>
                    {order.orderStatusApvInStore?.name}
                </span>
            </Label>
            {order.orderStatusApvInStore?.subName && (
                <span className="ml-1 font-weight-bold" style={{ color: getFontColorBySlug(order) }}>
                    &bull; {order.orderStatusApvInStore?.subName}
                </span>
            )}
        </>
    );

    const updatedBodyTemplate = (order: Order) => dayjs(order.updated).format('DD/MM/YYYY [à] HH [h] mm');

    const actionsBodyTemplate = (order: Order) => {
        const destination = getDestinationRoute(order) as { path: string; params: object } | null;
        if (order?.orderStatusApvInStore?.slug === 'closed_lose') {
            return (
                <Button className="p-2 px-3" variant="primary" onClick={() => handleShowPdf(order)}>
                    <FormattedMessage id="TRANSLATOR.SHOW" />
                </Button>
            );
        }
        return (
            destination && (
                <Link to={routeTo(destination.path, destination.params)}>
                    <Button className="p-2 px-3" variant="primary">
                        <FormattedMessage id="TRANSLATOR.SHOW" />
                    </Button>
                </Link>
            )
        );
    };

    const cols = [
        {
            field: 'reference',
            header: Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.REFERENCE' }),
            style: { width: '8%' },
            filter: true,
            filterElement: inputFilter('reference'),
        },
        {
            field: 'dealership',
            header: Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.DEALERSHIP' }),
            style: { width: '15%' },
            filter: true,
            filterElement: selectDealershipDropdown(),
            body: dealershipBodyTemplate,
        },
        {
            field: 'registration',
            header: Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.REGISTRATION' }),
            style: { width: '12%' },
            filter: true,
            filterElement: inputFilter('registration'),
            body: vehicleBodyTemplate,
        },
        {
            field: 'customer',
            header: Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.CUSTOMER' }),
            style: { width: '12%' },
            filter: true,
            filterElement: inputFilter('customer'),
            body: (order: Order) => `${order?.firstname || ''} ${order?.lastname || ''}`,
        },
        {
            field: 'total',
            header: Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.TOTAL' }),
            style: { width: '8%' },
            body: totalBodyTemplate,
        },
        {
            field: 'dealer',
            header: Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.VENDOR' }),
            style: { width: '12%' },
            filter: true,
            filterElement: inputFilter('dealer'),
            body: referentBodyTemplate,
        },
        {
            field: 'status',
            header: Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.STATUS' }),
            style: { width: '10%' },
            filter: true,
            filterElement: selectStatusDropdown(),
            body: statusBodyTemplate,
        },
        {
            field: 'order_updated_date',
            header: Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.ORDER_UPDATED' }),
            style: { width: '10%' },
            filter: true,
            filterElement: calendarRangeFilter('dateUpdateStartOrder', 'dateUpdateEndOrder'),
            body: updatedBodyTemplate,
        },
        {
            field: 'actions',
            header: Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.ACTIONS' }),
            style: { width: '5%' },
            filter: false,
            body: actionsBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <FiltersProvider value={filters}>
            {/* @ts-ignore */}
            <HoCDataTable
                value={orders}
                totalRecords={totalRecords[mapQuotationTypeSlug(type)]}
                lazy
                loading={loading}
                paginator
                onFilter={handleFilter}
                onPage={handlePagination}
                onSort={handleSort}
                paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                rows={items}
                first={start}
                sortField={sortField}
                sortOrder={sortOrder}
                removableSort
                filterDisplay="row"
                rowsPerPageOptions={[25, 50, 100]}
                emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
            >
                {dynamicColumns}
            </HoCDataTable>
        </FiltersProvider>
    );
};

export default QuotationTable;
