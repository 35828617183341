export const MARITAL_STATUS = [
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.CELIBA',
        code: 'CELIBA',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.MARIE',
        code: 'MARIE',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.DIV',
        code: 'DIV',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.VEUF',
        code: 'VEUF',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.UNION',
        code: 'UNION',
    },
];

export const PRO_CATEGORY = [
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.AGRI',
        code: 'AGRI',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.CHFENT',
        code: 'CHFENT',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.CADRE',
        code: 'CADRE',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.INTERMED',
        code: 'INTERMED',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.EMPLOYE',
        code: 'EMPLOYE',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.OUVRIER',
        code: 'OUVRIER',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.RETRAITE',
        code: 'RETRAITE',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.AUTRE',
        code: 'AUTRE',
    },
];

export const LANGUAGE = [
    {
        title: 'FR - Français',
        code: 'FR',
    },
    {
        title: 'NL - Néerlandais',
        code: 'NL',
    },
    {
        title: 'DE - Allemand',
        code: 'DE',
    },
];

export const SUBSCRIPTION_DURATION = [
    {
        code: 'UnAn',
        value: 1,
        title: '12 mois',
    },
    {
        code: 'DeuxAns',
        value: 2,
        title: '24 mois',
    },
    {
        code: 'TroisAns',
        value: 3,
        title: '36 mois',
    },
];

export const TYPE_SOLUTION_RECHARGE = [
    {
        code: 'BorneRecharge',
        value: 'BORNERECHARGE',
        title: 'Borne de recharge',
    },
    {
        code: 'PriseRenforcee',
        value: 'PRISERENFORCE',
        title: 'Prise renforcée',
    },
];

export const HOUSING_TYPE = [
    {
        code: 'Individuel',
        value: 'INDIVIDUEL',
        title: 'Individuel',
    },
    {
        code: 'Copropriété',
        value: 'COMMUN',
        title: 'Copropriété',
    },
];

export const HOUSING_MODE = [
    {
        code: 'Proprietaire',
        value: 'PROPRIETAIRE',
        title: 'Proprietaire',
    },
    {
        code: 'Locataire',
        value: 'LOCATAIRE',
        title: 'Locataire',
    },
];

export const CUSTOMER_PATH = [
    {
        code: 'VN',
        value: 1,
        title: 'VN',
    },
    {
        code: 'VO',
        value: 2,
        title: 'VO',
    },
];

export const BORNE_CUSTOMER_PATH = [
    {
        code: 'VN',
        value: 'VN',
        title: 'Contrat avec achat VN-VD',
    },
    {
        code: 'VO',
        value: 'VO',
        title: 'Contrat avec achat VO',
    },
    {
        code: 'NV',
        value: 'SansAchat',
        title: 'Contrat sans achat de véhicule',
    },
];

export const OLD_RECHARGE_SOLUTION = [
    {
        code: 'SuperieurA2Ans',
        value: true,
        title: 'Supérieur à 2 ans',
    },
    {
        code: 'InferieurA2Ans',
        value: false,
        title: 'Inférieur à 2 ans',
    },
];

export const VEHICLE_CATEGORY = [
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.TRUCK',
        code: 'CAM',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.VAN',
        code: 'CTTE',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.VASP',
        code: 'VASP',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.VP',
        code: 'VP',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.VU',
        code: 'VU',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.QM',
        code: 'QM',
    },
];

export const FUNDING_MODE = [
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.CREDIT',
        code: 'CDT',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.LOA',
        code: 'LOA',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.LLD',
        code: 'LLD',
    },
    {
        title: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.COMPTANT',
        code: 'CPT',
    },
];
