import React from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';

import { LayoutContextConsumer } from '@src/_metronic/layout/LayoutContext';
import BreadCrumbs from '@src/_metronic/layout/sub-header/components/BreadCrumbs';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';

import subHeader from '@app/partials/HoC/subHeader';

import { formSubmit } from './Form/form.submit';

function ActionEmailTypeSubHeader({
    history,
    subheaderCssClasses,
    subheaderContainerCssClasses,
    subheaderMobileToggle,
}) {
    const Intl = useIntl();
    const methods = useFormContext();
    const params = useParams();
    const isCreate = !params?.id;
    const name = methods.watch('name', null);

    const onSubmit = (data) => {
        formSubmit({
            isCreate,
            data,
            history,
            id: params?.id,
        });
    };

    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: 'MENU.ADMINISTRATION.SIGNATURE_TEMPLATE' }),
        },
        {
            title: Intl.formatMessage(
                {
                    id: isCreate
                        ? 'MENU.ADMINISTRATION.SIGNATURE_TEMPLATE_CREATION'
                        : 'MENU.ADMINISTRATION.SIGNATURE_TEMPLATE_EDITION',
                },
                { name: name || '' },
            ),
        },
    ];

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            type="submit"
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}

                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">
                                    {Intl.formatMessage({ id: 'MENU.ADMINISTRATION' })}
                                </h3>
                                <BreadCrumbs items={breadcrumb} />
                            </>
                        )}
                    </LayoutContextConsumer>
                </div>
                <div className="d-flex align-items-center">
                    <Link to={routeTo(ROUTES.SIGNATURE_TEMPLATES.PATH)}>
                        <Button className="mr-2" variant="outline-primary">
                            {Intl.formatMessage({ id: 'TRANSLATOR.BACK' })}
                        </Button>
                    </Link>
                    <Button className="mr-2" onClick={methods.handleSubmit(onSubmit)} variant="primary">
                        {Intl.formatMessage({ id: 'TRANSLATOR.SAVE' })}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default subHeader(ActionEmailTypeSubHeader);
