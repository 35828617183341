import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';

export default function EventModalTooltip({ event }) {
    if (!event || (!event.extendedProps.contact && !event.extendedProps.attendees)) {
        return null;
    }

    // not display location
    const attendees = event.extendedProps.attendees
        ? event.extendedProps.attendees.filter((attendee) => attendee.type !== 'resource')
        : null;

    const contact = event?.extendedProps?.contact;

    return (
        <Form.Group>
            <Form.Label>
                {contact ? (
                    <>
                        {Intl.formatMessage({ id: 'AGENDA.FORM.EVENT.LABEL.CONTACT' })}
                        <Tooltip
                            target=".contactEmail"
                            baseZIndex={9999}
                            position="right"
                            style={{ maxWidth: 'none' }}
                            hideDelay={3000}
                        />
                        <span className="contactEmail ml-2" data-pr-tooltip={contact.email}>
                            {contact.firstname} {contact.lastname}
                        </span>
                    </>
                ) : null}
                {attendees ? Intl.formatMessage({ id: 'AGENDA.FORM.EVENT.LABEL.CONTACTS' }) : null}
            </Form.Label>
            <p>
                {attendees
                    ? attendees.map((attendee, i) => (
                          <span key={attendee.emailAddress.address}>
                              <Tooltip
                                  target=".contactEmail"
                                  baseZIndex={9999}
                                  position="top"
                                  style={{ maxWidth: 'none' }}
                                  hideDelay={0}
                              />
                              <span className="contactEmail" data-pr-tooltip={attendee.emailAddress.address}>
                                  {attendee.emailAddress.name}
                                  {attendees.length - 1 !== i ? ', ' : ''}
                              </span>
                          </span>
                      ))
                    : null}
                {contact ? (
                    <Link
                        to={routeTo(ROUTES.CUSTOMER.PATH, {
                            id: contact?.id,
                        })}
                    >
                        Voir la fiche
                    </Link>
                ) : null}
            </p>
        </Form.Group>
    );
}
