/**
 * Create new capitalize function
 *
 * @returns {string}
 */
export function capitalize(str: string) {
    if (str && typeof str === 'string') {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
}

/**
 * Converts each word's first character to uppercase in a given string.
 * Words are separated by spaces or hyphens.
 *
 * @param {string} str - The string to convert.
 * @returns {string} - The converted string.
 */
export function ucwords(str: string) {
    return str.toLowerCase().replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/gu, (s) => s.toUpperCase());
}

export function stripAccents(str: string) {
    if (!str) {
        return '';
    }

    const map = {
        a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
        e: 'é|è|ê|ë|É|È|Ê|Ë',
        i: 'í|ì|î|ï|Í|Ì|Î|Ï',
        o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
        u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        c: 'ç|Ç',
        n: 'ñ|Ñ',
    };

    // @ts-ignore
    return Object.keys(map).reduce((acc, cur) => acc.replace(new RegExp(map[cur], 'g'), cur), str);
}

export const htmlToString = (value: string) => {
    const div = document.createElement('div');
    div.innerHTML = value ? value?.replace?.(/<!--[\s\S]*?-->/g, '') : '';
    return div.textContent || div.innerText || '';
};

export const nl2br = (str: string, replaceMode: boolean, isXhtml: boolean) => {
    if (!str) return '';

    const breakTag = isXhtml ? '<br />' : '<br>';
    const replaceStr = replaceMode ? `$1${breakTag}` : `$1${breakTag}$2`;
    return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
};

export const stripHtmlTags = (htmlString: string) => htmlString.replace(/<[^>]*>?/gm, '');
