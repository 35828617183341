import classNames from 'classnames';
import { Divider } from 'primereact/divider';
import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getTransformation } from '../../helpers/DashboardHelper';

import DashboardPerfState from './DashboardPerfState';

const DashboardOnlinePerf = ({ data, className }) => {
    const excludedTypes = ['local', 'marketing_campaign'];
    const details = Object.entries(data || {})?.filter?.(
        ([type]) => !excludedTypes.some((excludedType) => type.startsWith(excludedType)),
    );
    const customOrder = ['reprise', 'vd', 'vn', 'vo'];

    details.sort((a, b) => {
        const aIndex = customOrder.indexOf(a[0]) ?? 100;
        const bIndex = customOrder.indexOf(b[0]) ?? 100;
        return bIndex - aIndex;
    });

    const isLeadConstructor = (leadType) => leadType === 'constructeur';

    return (
        <div className={classNames('dashboard-online-perf d-flex flex-column', className)}>
            <div className="flex-grow-1">
                <div className="font-size-lg font-weight-bold d-flex flex-wrap align-items-center justify-content-between">
                    <i className="dashboard-general-perf__icon las la-2x la-mouse-pointer mr-2" />
                    <div className="flex-grow-1 d-flex align-items-center">
                        <FormattedMessage id="DASHBOARD.ONLINE_DETAIL.TITLE" />
                    </div>
                </div>
            </div>
            {details?.length > 0 && (
                <div className="d-flex flex-lg-nowrap flex-wrap justify-content-between dashboard-online-perf__list">
                    {details.map(([leadType, detail]) => (
                        <div key={detail?.name} className="dashboard-online-perf__item p-0 mt-2">
                            <div className="d-inline-block">
                                <div className="font-weight-bold font-size-lg">{detail?.name}</div>
                                <Divider className="my-2" />
                                <div>
                                    <strong>{detail?.taken}</strong>{' '}
                                    <FormattedMessage id="DASHBOARD.ONLINE_PERF.TAKEN" />
                                </div>
                                <DashboardPerfState detail={detail} leadType={leadType} />
                                {/* hide other types until we get the right data for this section - cf: AMNG-2454 */}
                                {['vo', 'vn', 'vd', 'reprise'].includes(leadType) && (
                                    <div>
                                        <strong>
                                            {!isLeadConstructor(leadType)
                                                ? getTransformation(detail?.wonStrong, 0, detail?.takenStrong)
                                                : getTransformation(detail?.won, 0, detail?.taken)}
                                            &nbsp;% <FormattedMessage id="DASHBOARD.ONLINE_PERF.TRANSFORMATION" />
                                        </strong>
                                        {` `}
                                        {!isLeadConstructor(leadType) && (
                                            <FormattedMessage id="DASHBOARD.ONLINE_PERF.STRONG" />
                                        )}
                                        <span className="font-size-xs font-weight-bold">
                                            &nbsp;({!isLeadConstructor(leadType) ? detail?.wonStrong : detail?.won} /{' '}
                                            {!isLeadConstructor(leadType) ? detail?.takenStrong : detail?.taken})
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

DashboardOnlinePerf.Skeleton = () => (
    <Skeleton borderRadius="10px" height="200px" className="d-flex flex-column flex-lg-grow-1 mt-lg-2 mt-6 p-35" />
);
export default DashboardOnlinePerf;
