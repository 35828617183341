import React, { useCallback, useState } from 'react';
import { getDealershipVendors } from '../crud/autouser/user.crud';
import { User } from '@app/store/login/login.store';

const useVendors = () => {
    const [vendors, setVendors] = useState<User[]>();
    const [loading, setLoading] = useState(false);

    const fetchVendors = useCallback(async (dealership: string) => {
        setLoading(true);
        const newVendors = await getDealershipVendors(dealership);
        setLoading(false);
        if (newVendors?.result) {
            setVendors(newVendors.result);
        }
    }, []);

    return {
        fetchVendors,
        vendors,
        loading,
    };
};

export default useVendors;
