import KTUtil from '../../_metronic/_assets/js/util';

// eslint-disable-next-line import/prefer-default-export
export const HeadBandHelper = {
    // Update subheader
    updateDesktop: (removed) => {
        const wrapper = document.getElementById('kt_wrapper');
        const subheader = document.getElementById('kt_subheader');
        const header = document.getElementById('kt_header');
        const headband = document.querySelector('#kt_subheader .headband');

        if (wrapper && subheader && header) {
            if (removed && headband) {
                wrapper.style.paddingTop = `${
                    parseFloat(subheader?.offsetHeight) +
                    parseFloat(header?.offsetHeight) -
                    parseFloat(headband?.offsetHeight)
                }px`;
            } else {
                wrapper.style.paddingTop = `${
                    parseFloat(subheader?.offsetHeight) + parseFloat(header?.offsetHeight)
                }px`;
            }
        }
    },
    updateMobile: () => {
        const wrapper = document.getElementById('kt_content__wrapper');
        const subheader = document.getElementById('kt_subheader');
        const header = document.getElementById('kt_header');

        if (wrapper && subheader && header) {
            wrapper.style.paddingTop = `${parseFloat(subheader?.offsetHeight) + parseFloat(header?.offsetHeight)}px`;
        }
    },
    updateSubHeader: (removed = false) => {
        if (KTUtil.isMobileDevice()) {
            HeadBandHelper.updateMobile(removed);
        } else {
            HeadBandHelper.updateDesktop(removed);
        }
    },
};
