import { useState, useEffect } from 'react';

export const calculateTimeRemaining = (targetDate: number) => {
  const now = new Date().getTime();
  const distance = targetDate - now;

  if (distance < 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return { distance, days, hours, minutes, seconds };
}

function useCountdown(targetDate: number) {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(targetDate));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(targetDate));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [targetDate]);

  return timeRemaining;
}

export default useCountdown;