import React from 'react';
import {Button} from "react-bootstrap";
import dayjs from "dayjs";
import {TAB_DATE, TAB_HOUR} from "./Helpers/DatePickerHelper";
import classNames from "classnames";

const Footer = ({setDate, tab, setVisible, isBirthdayDate, size}) => {
    return (
        <div className={classNames('p-datepicker-footer d-flex', {
            'justify-content-between': tab === TAB_DATE,
            'justify-content-center': tab === TAB_HOUR
        })}>
            {tab === TAB_DATE && isBirthdayDate &&
            <Button variant="outline-secondary" onClick={() => setDate(dayjs().toDate())}>Aujourd'hui</Button>
            }
            <Button variant="primary" style={size} onClick={() => setVisible(false)}>Valider</Button>
        </div>
    );
};

export default Footer;
