import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { checkDateStatus } from '@app/helpers/UserHelper';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import StatusLead from '@app/pages/customers/Partials/StatusLead';

import RowInfo from '@app/partials/content/RowInfo';

interface Props {
    event: VcuMappedEventDetails;
}

export const warrantyType = (value: string) => {
    switch (value) {
        case 'GC':
            return <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.CIRANO.GC" />;
        default:
            return '';
    }
};

const DetailCirano = ({ event }: Props) => {
    const Intl = useIntl();
    const { cirano } = event;

    const eventStatus = (value: string) => {
        switch (value) {
            case 'CIRANO_WARRANTY_CONTRACT':
                return Intl.formatMessage({ id: 'TRANSLATOR.VO' });
            default:
                return '';
        }
    };

    const isActive = checkDateStatus(cirano?.endDate);
    const statusType = isActive ? 'success' : 'secondary';
    const statusName = isActive
        ? Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.STATUS.ACTIVE' })
        : Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.STATUS.INACTIVE' });

    return (
        <div className="d-md-flex justify-content-md-between align-items-md-stretch">
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.CIRANO.EVENT' })}
                    value={`${cirano?.eventName} ${eventStatus(
                        `${event?.informations?.source}_${event?.informations?.processType}`,
                    )}`}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.CIRANO.SOURCE.DATA' })}
                    value={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.CIRANO.NAME' })}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.CIRANO.ID' })}
                    value={cirano?.srcProcessId || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.CIRANO.TYPE.GTIE' })}
                    value={warrantyType(cirano?.typeGite) || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.CIRANO.NOM.GTIE' })}
                    value={cirano?.nameGtie || '-'}
                />
            </div>
            <div className="col-md-5">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.CIRANO.STATE' })}
                    value={<StatusLead overridedStatus={statusType} statusName={statusName} />}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.CIRANO.PRODUCT.FAMILY' })}
                    value={cirano?.libFamily || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.CIRANO.PRODUCT.LIB' })}
                    value={cirano?.libProduct || '-'}
                />
            </div>
            <div className="col-md-3">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.CIRANO.DATE.START.GUARANTY' })}
                    value={dayjs(cirano?.startDate).format('DD/MM/YYYY') || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.CIRANO.DATE.END.GUARANTY' })}
                    value={dayjs(cirano?.endDate).format('DD/MM/YYYY') || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.CIRANO.CREATEDBY' })}
                    value={cirano?.pdvContributor ? cirano.pdvContributor : '-'}
                />
            </div>
        </div>
    );
};

export default DetailCirano;
