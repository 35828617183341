import React, { useState } from 'react';
import { Button, Image, Row } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import useTypologies from '@app/hooks/useTypologies';

import getStatusVariant from '@app/pages/fluidity/Helpers/Event';

import Label from '@app/partials/content/Label';
import HeadBand from '@app/partials/layout/HeadBand';

const FluidityHandBand = ({ lead, handleAction }) => {
    const height = '150px';
    const scrollHeight = '60px';
    const [inScroll, setInScroll] = useState();
    const { hasTypology } = useTypologies();

    const handleScroll = (value) => {
        setInScroll(value);
    };

    const actions = hasTypology('fluidite') && (
        <>
            <Button variant="success" onClick={() => handleAction('transaction_finalized')} className="ml-0">
                <i className="las la-thumbs-up" />
                <FormattedMessage id="FLUIDITY.TRANSACTION.FINALIZE" />
            </Button>
            <Button variant="danger" onClick={() => handleAction('transaction_canceled')} className="ml-3">
                <i className="las la-thumbs-down" />
                <FormattedMessage id="FLUIDITY.TRANSACTION.CANCEL" />
            </Button>
        </>
    );

    return (
        <HeadBand
            className="headband"
            fixed
            height={height}
            scrollHeight={scrollHeight}
            onScroll={handleScroll}
            parent="kt_subheader"
            variant="customers-primary"
        >
            <Row className="font-size-h4 text-white headband__content flex-nowrap">
                <Image
                    className="ml-5"
                    style={{
                        height: inScroll ? scrollHeight : height,
                    }}
                    src="/media/illustrations/rendez-vous-confirme.svg"
                />
                <div className="d-flex align-items-center ml-5 flex-wrap">
                    <div className="mr-10 my-2 border-bottom">
                        <FormattedHTMLMessage id="FLUIDITY.HANDBAND.TITLE" />
                        <br />
                        <FormattedHTMLMessage id="FLUIDITY.HANDBAND.SUBTITLE" />
                    </div>
                    <div className="my-2 d-flex align-items-center">{actions}</div>
                </div>
            </Row>
            <Row className="font-size-h4 text-white headband__content headband__content--scroll">
                <FormattedMessage id="FLUIDITY.NAME" />
                <Label variant={getStatusVariant(lead?.leadFluidityStatus?.slug)} className="ml-5" size="label-lg">
                    {lead?.leadFluidityStatus?.name}
                </Label>
                <Image
                    className="ml-5"
                    style={{
                        height: inScroll ? scrollHeight : height,
                    }}
                    src="/media/illustrations/rendez-vous-confirme.svg"
                />
                <div className="ml-5">
                    <FormattedHTMLMessage id="FLUIDITY.HANDBAND.TITLE" />
                </div>
                <div className="ml-5">{actions}</div>
            </Row>
        </HeadBand>
    );
};

export default FluidityHandBand;
