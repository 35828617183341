import { AxiosResponse } from 'axios';

import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { RolesParams, UserRole, UserRoles } from '@app/crud/autouser/role.types';

const { REACT_APP_AUTOUSER_URL } = process.env;

export function getRoles(params: RolesParams) {
    return ApiGateway.get<ApiResponse<UserRoles[]>, CustomAxiosResponse<ApiResponse<UserRoles[]>>>(
        `${REACT_APP_AUTOUSER_URL}/admin/roles`,
        {
            params,
        },
    );
}

export function getRole(id: number) {
    return ApiGateway.get<UserRole, AxiosResponse<UserRole>>(`${REACT_APP_AUTOUSER_URL}/admin/roles/${id}`);
}

export function addRole(data: Omit<UserRole, 'id'>) {
    return ApiGateway.post(`${REACT_APP_AUTOUSER_URL}/admin/roles`, data);
}

export function updateRoleById(id: number, data: Omit<UserRole, 'id'>) {
    return ApiGateway.post(`${REACT_APP_AUTOUSER_URL}/admin/roles/${id}`, data);
}

export function deleteRole(id: number) {
    return ApiGateway.delete(`${REACT_APP_AUTOUSER_URL}/admin/roles/${id}`);
}
