import classNames from 'classnames';
import React from 'react';

import { useAppSelector } from '@app/hooks';

import { DashboardAgenda } from './Agenda';
import DashboardLead from './DashboardLead';
import { DashboardPriorities } from './Priorities';

interface DashboardActionsProps {
    className?: string;
}

const DashboardActions = ({ className }: DashboardActionsProps) => {
    const { userData } = useAppSelector((state) => state.auth);

    const roleForApvDisplay = [
        'manager-autosphere-contact',
        'responsable-apv',
        'agent-autosphere-contact',
        'conseiller-commercial-services',
    ];

    return (
        <div className={classNames('dashboard-actions', className)}>
            <DashboardAgenda className="h-100" />
            <div className="d-flex flex-column">
                <DashboardLead displayApv={roleForApvDisplay.includes(userData?.role?.slug)} />
                <DashboardPriorities className="mt-3 flex-grow-1" />
            </div>
        </div>
    );
};

DashboardActions.defaultProps = {
    className: '',
};

export default DashboardActions;
