import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React from 'react';
import { Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import HoCDataTable from '@app/partials/content/HoCDataTable';
import Loader from '@app/partials/content/Loader';

import CampaignStatusRow from './CampaignStatusRow';

const CampaignHistory = ({ events, loading }) => {
    const dateBodyTemplate = (event) => (
        <>
            <span>{dayjs(event?.date).format('DD/MM/YYYY')}</span>
            <br />
            <span>{dayjs(event?.date).format('HH [h] mm')}</span>
        </>
    );

    const userBodyTemplate = (event) => (
        <>
            <span>
                {event?.user?.firstname} {event?.user?.lastname}
            </span>
            <br />
            <span>Rôle : {event?.user?.role}</span>
        </>
    );

    const actionBodyTemplate = (event) => {
        const campaignStatus = event?.action?.data?.campaignStatus;

        return (
            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <div className="d-flex align-items-center">
                        <CampaignStatusRow
                            campaignStatusSlug={campaignStatus?.slug}
                            campaignStatusName={campaignStatus?.name}
                            campaignStatusSubName={campaignStatus?.subName}
                        />
                    </div>
                    {loading ? (
                        <Loader
                            style={{ width: '5rem', height: '5rem' }}
                            wrapperStyle={{ marginTop: '-15px' }}
                            overlay
                        />
                    ) : (
                        <span>
                            {event?.action?.name}
                            {[
                                'in_progress_appointment_to_come',
                                'in_progress_appointment_confirmed',
                                'in_progress_relaunch_to_come',
                                'in_progress_appointment_to_confirm',
                            ].includes(event?.action?.status?.slug) &&
                                ` le ${dayjs(event?.action?.scheduleAppointment?.dateStart).format(
                                    'DD/MM/YYYY [à] HH [h] mm',
                                )}`}
                        </span>
                    )}
                </div>
            </div>
        );
    };

    const cols = [
        {
            field: 'date',
            header: Intl.formatMessage({ id: 'CAMPAIGN.HISTORY.TABLE.HEAD.DATE' }),
            body: dateBodyTemplate,
        },
        {
            field: 'user',
            header: Intl.formatMessage({ id: 'CAMPAIGN.HISTORY.TABLE.HEAD.USER' }),
            body: userBodyTemplate,
        },
        {
            field: 'name',
            header: Intl.formatMessage({ id: 'CAMPAIGN.HISTORY.TABLE.HEAD.ACTION' }),
            body: actionBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <Card className="card--shadowed">
            <Card.Header className="text-closed position-relative">
                <div className="bg-closed pin" style={{ top: '20px' }}>
                    <i className="la la-clock text-white la-lg" />
                </div>
                <div className="ml-5 d-flex align-items-center justify-content-between font-weight-semibold">
                    <FormattedMessage id="CAMPAIGN.HISTORY.TITLE" />
                </div>
            </Card.Header>
            <Card.Body>
                <HoCDataTable
                    value={events}
                    emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                    autoLayout
                    className="p-datatable-light"
                >
                    {dynamicColumns}
                </HoCDataTable>
            </Card.Body>
        </Card>
    );
};

export default CampaignHistory;
