import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ApvFormProps, Vehicle } from '@app/crud/apv/vehicle.type';

import ErrorForm from '@app/partials/layout/ErrorForm';

interface FormModelDropdownAutovistaProps extends ApvFormProps {
    vehicle: Vehicle;
}

const FormModelDropdownAutovista = ({
    label,
    name,
    required,
    isInvalid,
    placeholder = '',
    className = '',
    validate,
    disabled = false,
    vehicle,
}: FormModelDropdownAutovistaProps) => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { control, errors } = methods;

    const selectModelOptions = (value: Vehicle) => [
        {
            name: value.model,
        },
    ];

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Controller
                control={control}
                name={name}
                rules={{ required: required && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }), validate }}
                render={({ value, onChange }) => (
                    <Dropdown
                        value={value}
                        disabled={disabled}
                        className={classNames(`form-control ${className}`, {
                            'is-invalid': isInvalid,
                        })}
                        emptyMessage={Intl.formatMessage({ id: 'TRANSLATOR.NO_RESULT' })}
                        options={selectModelOptions(vehicle)}
                        onChange={(e) => onChange(e.value)}
                        itemTemplate={(item) => item.name}
                        optionLabel="name"
                        placeholder={placeholder}
                    />
                )}
            />
            <ErrorForm errors={errors} name={name} />
        </Form.Group>
    );
};

export default FormModelDropdownAutovista;
