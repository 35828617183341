import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { Application, Permission, SimpleApplication } from '@app/crud/autouser/application.types';

const { REACT_APP_AUTOUSER_URL } = process.env;

export function getApplications() {
    return ApiGateway.get<ApiResponse<SimpleApplication[]>, CustomAxiosResponse<ApiResponse<SimpleApplication[]>>>(
        `${REACT_APP_AUTOUSER_URL}/admin/applications`,
    );
}

export function addApplication(data: Application) {
    return ApiGateway.post(`${REACT_APP_AUTOUSER_URL}/admin/applications`, data);
}

export function getApplication(id: string) {
    return ApiGateway.get(`${REACT_APP_AUTOUSER_URL}/admin/applications/${id}`);
}

export function getCategories(id: string) {
    return ApiGateway.get(`${REACT_APP_AUTOUSER_URL}/admin/applications/${id}/categories`);
}

export function updateApplicationById(id: string, data: Application) {
    return ApiGateway.post(`${REACT_APP_AUTOUSER_URL}/admin/applications/${id}`, data);
}

export function updateApplicationPermissionsById(data: { [key: string]: string | boolean }) {
    return ApiGateway.post(`${REACT_APP_AUTOUSER_URL}/admin/permissions`, data);
}

export function getApplicationPermissions(params: { id: string; sort?: string }) {
    const { id, ...paramsWithoutId } = params;
    if (id === undefined) {
        return Promise.resolve(null);
    }
    return ApiGateway.get<ApiResponse<Permission[]>, CustomAxiosResponse<ApiResponse<Permission[]>>>(
        `${REACT_APP_AUTOUSER_URL}/admin/applications/${id}/permissions`,
        {
            params: paramsWithoutId,
        },
    );
}
