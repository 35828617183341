import React from 'react';

import CarBackSide from '@app/pages/smartRepair/quotations/Vehicles/CarBackSide';
import CarFrontSide from '@app/pages/smartRepair/quotations/Vehicles/CarFrontSide';
import CarLeftSide from '@app/pages/smartRepair/quotations/Vehicles/CarLeftSide';
import CarRightSide from '@app/pages/smartRepair/quotations/Vehicles/CarRightSide';
import CarTopSide from '@app/pages/smartRepair/quotations/Vehicles/CarTopSide';

const getVehicleProfile = (zone: string) => {
    switch (zone) {
        case 'cote-gauche':
            return <CarLeftSide width="100%" height="100%" />;
        case 'cote-droit':
            return <CarRightSide width="100%" height="100%" />;
        case 'avant':
            return <CarFrontSide width="100%" height="100%" />;
        case 'arriere':
            return <CarBackSide width="100%" height="100%" />;
        case 'toit':
            return <CarTopSide width="100%" height="100%" />;
        default:
            return null;
    }
};

export default getVehicleProfile;
