import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import ErrorForm from '@app/partials/layout/ErrorForm';

type CustomCheckboxProps = {
    name: string;
    label: string;
    options: {
        value: string;
        labelId: string;
    }[];
    validation: object;
};

const CustomCheckbox = ({ name, label, options, validation }: CustomCheckboxProps) => {
    const Intl = useIntl();
    const { register, errors } = useFormContext();

    return (
        <Form.Group>
            <Form.Label className="mr-5">{label}</Form.Label>
            {options.map((option) => (
                <Form.Check
                    name={name}
                    inline
                    key={option.value}
                    type="radio"
                    id={`${name}_${option.value}`}
                    label={Intl.formatMessage({ id: option.labelId })}
                    value={option.value}
                    ref={register(validation)}
                />
            ))}
            <ErrorForm errors={errors} name={name} />
        </Form.Group>
    );
};

export default CustomCheckbox;
