import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

import HeadBand from '../../../../partials/layout/HeadBand';
import { getStateBySlug } from '../Helpers/Status';

function QuotationHeader({ quotation }) {
    const displayTemplate = () => {
        if (!quotation) return null;
        const civility = quotation?.customer?.civility.toUpperCase();
        const name = `${quotation?.customer?.firstName} ${quotation?.customer?.lastName}`;
        switch (quotation.orderStatus.slug) {
            case 'pending':
                return (
                    <FormattedHTMLMessage
                        id="SMART.REPAIR.APPOINTMENT.PENDING"
                        values={{ client: `${civility} ${name}` }}
                    />
                );
            case 'waiting_complement':
                return (
                    <FormattedHTMLMessage
                        id="SMART.REPAIR.APPOINTMENT.WAITING.COMPLEMENT"
                        values={{ client: `${civility} ${name}` }}
                    />
                );
            case 'new_complement':
                return (
                    <FormattedHTMLMessage
                        id="SMART.REPAIR.APPOINTMENT.NEW.COMPLEMENT"
                        values={{ client: `${civility} ${name}` }}
                    />
                );
            case 'waiting_quote':
                return (
                    <FormattedHTMLMessage
                        id="SMART.REPAIR.APPOINTMENT.WAITING.QUOTE"
                        values={{ client: `${civility} ${name}` }}
                    />
                );
            case 'waiting_rdv':
                return (
                    <FormattedHTMLMessage
                        id="SMART.REPAIR.APPOINTMENT.WAITING.RDV"
                        values={{ client: `${civility} ${name}` }}
                    />
                );
            case 'waiting_payment':
                return (
                    <FormattedHTMLMessage
                        id="SMART.REPAIR.APPOINTMENT.WAITING.PAYMENT"
                        values={{
                            client: `${civility} ${name}`,
                            date: quotation?.appointmentDateFormated,
                            dealership: quotation?.dealership?.name,
                            location: quotation?.dealership?.locality,
                        }}
                    />
                );
            case 'payment_waiting_confirm':
                return (
                    <FormattedHTMLMessage
                        id="SMART.REPAIR.APPOINTMENT.WAITING.PAYMENT.CONFIRM"
                        values={{
                            client: `${civility} ${name}`,
                            date: quotation?.appointmentDateFormated,
                            dealership: quotation?.dealership?.name,
                            location: quotation?.dealership?.locality,
                        }}
                    />
                );

            case 'payment_refused':
                return (
                    <FormattedHTMLMessage
                        id="SMART.REPAIR.APPOINTMENT.WAITING.PAYMENT.REFUSED"
                        values={{
                            client: `${civility} ${name}`,
                            date: quotation?.appointmentDateFormated,
                            dealership: quotation?.dealership?.name,
                            location: quotation?.dealership?.locality,
                        }}
                    />
                );
            case 'closed_win':
                return (
                    <FormattedHTMLMessage
                        id="SMART.REPAIR.APPOINTMENT.WAITING.PAYMENT.REFUSED"
                        values={{
                            client: `${civility} ${name}`,
                            date: quotation?.appointmentDateFormated,
                            dealership: quotation?.dealership?.name,
                            location: quotation?.dealership?.locality,
                        }}
                    />
                );
            case 'closed_lose':
                return (
                    <FormattedHTMLMessage
                        id="SMART.REPAIR.APPOINTMENT.CLOSED.LOSE"
                        values={{
                            client: `${civility} ${name}`,
                        }}
                    />
                );
            case 'incompatible':
                return (
                    <FormattedHTMLMessage
                        id="SMART.REPAIR.APPOINTMENT.INCOMPATIBLE"
                        values={{
                            client: `${civility} ${name}`,
                        }}
                    />
                );
            case 'refused':
                return (
                    <FormattedHTMLMessage
                        id="SMART.REPAIR.APPOINTMENT.REFUSED"
                        values={{
                            client: `${civility} ${name}`,
                        }}
                    />
                );
            case 'expired':
                return (
                    <FormattedHTMLMessage
                        id="SMART.REPAIR.APPOINTMENT.EXPIRED"
                        values={{
                            client: `${civility} ${name}`,
                        }}
                    />
                );
            case 'canceled':
                return (
                    <FormattedHTMLMessage
                        id="SMART.REPAIR.APPOINTMENT.CANCELED"
                        values={{
                            client: `${civility} ${name}`,
                        }}
                    />
                );
            default:
                return <FormattedHTMLMessage id="SMART.REPAIR.APPOINTMENT.ERROR" />;
        }
    };

    return (
        <HeadBand variant={getStateBySlug(quotation?.orderStatus?.slug)}>
            <Row className="col-lg-12">
                <Col lg={7}>
                    <Row>
                        <Col lg={6}>
                            <Image className="float-right" src="/media/illustrations/rendez-vous-confirme.svg" />
                        </Col>
                        {quotation && (
                            <Col className="headband__col" lg={6}>
                                <div className="headband__title">
                                    <i className="la la-bullhorn mr-3" />
                                    <span className="font-weight-semibold">
                                        {quotation?.orderStatus?.name} {quotation?.orderStatus?.sub_name}
                                    </span>
                                </div>
                                <p>{displayTemplate()}</p>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </HeadBand>
    );
}

export default QuotationHeader;
