import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { CustomAxiosResponse } from '@app/helpers/AxiosHelper';
import { routeTo } from '@app/helpers/RoutesHelper';

import { Performances as DataPerformances } from '@app/crud/dashboard/achat-cash-types';

import { useFetch } from '@app/hooks';
import ROUTES from '@app/router/Routes';

import { DashboardContext } from '../../DashboardProvider';
import { getStatsSortValues } from '../../helpers/DashboardHelper';

interface Props {
    title: string;
    icon: string;
    fetchAction: (params: any) => Promise<CustomAxiosResponse<DataPerformances>>;
    className?: string;
}

const PerformancesAbandon = ({ title, icon, className, fetchAction }: Props) => {
    const { params, overriddenParams } = useContext(DashboardContext);

    const { data, loading, fetch } = useFetch({
        fetchAction,
    });

    useEffect(() => {
        fetch(overriddenParams);
    }, [overriddenParams]);

    if (loading) {
        return <Skeleton borderRadius="10px" className="d-flex flex-column flex-lg-grow-1 mt-lg-2 mt-6 p-15" />;
    }

    const grossTransform =
        (data?.won || 0) + (data?.taken || 0) ? Math.round((100 * (data?.won || 0)) / (data?.taken || 0)) : '-';
    const netTransform = data?.withConfirmAppointment
        ? Math.round((100 * (data?.won || 0)) / (data?.withConfirmAppointment || 0))
        : '-';

    return (
        <div className={classNames('dashboard-remote-perf d-flex flex-column', className)}>
            <div className="font-size-lg font-weight-bold d-flex flex-wrap align-items-center justify-content-between">
                <div className="d-flex flex-wrap align-items-center">
                    <i className={classNames('dashboard-remote-perf__icon las la-2x mr-2', icon)} />
                    <span className="mr-4">
                        <FormattedMessage id={title} />
                    </span>
                </div>
            </div>
            <div className="dashboard-remote-perf__groups align-items-center flex-grow-1">
                <div className="dashboard-remote-perf__leads flex-grow-1 d-flex justify-content-between">
                    <div className="d-flex flex-column">
                        <span className="dashboard-remote-perf__state ml-2 line-height-sm text-nowrap">
                            <FormattedHTMLMessage id="DASHBOARD.GENERAL_PERF.LEAD_TOTAL" />
                        </span>
                        <span className="dashboard-remote-perf__lead ml-3 text-primary font-weight-semibold flex-grow-1">
                            {data?.total || 0}
                        </span>
                    </div>
                    <div className="d-flex flex-column">
                        <span className="dashboard-remote-perf__state ml-2 line-height-sm text-nowrap">
                            <FormattedHTMLMessage id="DASHBOARD.GENERAL_PERF.LEAD_TAKEN" />
                        </span>
                        <span className="dashboard-remote-perf__lead ml-3 text-primary font-weight-semibold flex-grow-1">
                            {data?.taken || 0}
                        </span>
                    </div>
                    <Link
                        className="d-flex flex-column text-initial"
                        to={{
                            pathname: routeTo(ROUTES.LEADS_ACHAT_CASH.PATH.ABANDON, {
                                statusType: 'all',
                            }),
                            state: getStatsSortValues(params),
                        }}
                    >
                        <span className="dashboard-remote-perf__state ml-2 line-height-sm text-nowrap">
                            <FormattedHTMLMessage id="DASHBOARD.GENERAL_PERF.LEAD_IN_PROGRESS" />
                        </span>
                        <span className="dashboard-remote-perf__lead ml-3 text-primary font-weight-semibold flex-grow-1">
                            {data?.inProgress || 0}
                        </span>
                    </Link>
                </div>
                <div className="dashboard-remote-perf__leads">
                    <Link
                        className="d-flex text-initial"
                        to={{
                            pathname: routeTo(ROUTES.LEADS_ACHAT_CASH.PATH.ABANDON, {
                                statusType: 'close',
                            }),
                            state: getStatsSortValues({
                                ...params,
                                leadStatus: 'closed_won',
                            }),
                        }}
                    >
                        <i className="text-success kt-opacity-5 las la-2x la-thumbs-up" />
                        <div>
                            <div className="dashboard-remote-perf__state ml-2 line-height-sm text-nowrap">
                                <FormattedHTMLMessage id="DASHBOARD.GENERAL_PERF.LEAD_WON" />
                            </div>
                            <div className="dashboard-remote-perf__lead ml-3 text-primary font-weight-semibold flex-grow-1">
                                {data?.won || 0}
                            </div>
                        </div>
                    </Link>
                    <Link
                        className="d-flex text-initial"
                        to={{
                            pathname: routeTo(ROUTES.LEADS_ACHAT_CASH.PATH.ABANDON, {
                                statusType: 'close',
                            }),
                            state: getStatsSortValues({
                                ...params,
                                leadStatus: 'closed_lost',
                            }),
                        }}
                    >
                        <i className="text-danger kt-opacity-5 las la-2x la-thumbs-down" />
                        <div>
                            <div className="dashboard-remote-perf__state ml-2 line-height-sm text-nowrap">
                                <FormattedHTMLMessage id="DASHBOARD.GENERAL_PERF.LEAD_LOST" />
                            </div>
                            <div className="dashboard-remote-perf__lead ml-3 text-primary font-weight-semibold flex-grow-1">
                                {data?.lost || 0}
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="dashboard-remote-perf__leads">
                    <div className="d-flex flex-column">
                        <span className="dashboard-remote-perf__lead ml-3 text-primary font-weight-semibold flex-grow-1">
                            {grossTransform}%
                        </span>
                        <span className="dashboard-remote-perf__state ml-1 line-height-sm text-nowrap">
                            <FormattedHTMLMessage id="DASHBOARD.GENERAL_PERF.GROSS_TRANSFORM" />
                        </span>
                    </div>
                    <div className="d-flex flex-column">
                        <span className="dashboard-remote-perf__lead ml-3 text-primary font-weight-semibold flex-grow-1">
                            {netTransform}%
                        </span>
                        <span className="dashboard-remote-perf__state ml-1 line-height-sm text-nowrap">
                            <FormattedHTMLMessage id="DASHBOARD.GENERAL_PERF.NET_TRANSFORM" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

PerformancesAbandon.defaultProps = {
    className: '',
};

export default PerformancesAbandon;
