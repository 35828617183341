import React from 'react'

const defaultValues = {}

const MenuContext = React.createContext(defaultValues)

export const MenuProvider = MenuContext.Provider
export const MenuConsumer = MenuContext.Consumer

export default MenuContext
