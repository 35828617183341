import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React from 'react';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

const OperationReconciliationUserRow = ({ source }) => {
    const mobile = parsePhoneNumberFromString(source?.mobile || '', Locale.toUpperCase());
    const phone = parsePhoneNumberFromString(source?.phone || '', Locale.toUpperCase());

    return (
        <>
            <div className="d-flex align-items-start font-weight-bold">
                <i className="mr-2 text-secundary las la-user" />
                {source?.lastname || ''} {source?.firstname || ''}
            </div>
            {(mobile || phone) && (
                <div className="mt-1 d-flex align-items-start">
                    <i className="mr-2 las la-phone" />
                    {mobile?.formatNational?.() || phone?.formatNational?.() || ''}
                </div>
            )}
            {source?.email && (
                <div className="mt-1 d-flex align-items-start">
                    <i className="mr-2 las la-envelope" />
                    {source?.email}
                </div>
            )}
            {(source?.address || source?.zipcode || source?.city) && (
                <div className="mt-1  d-flex align-items-start">
                    <i className="mr-2 las la-map-marker" />
                    <div className="">
                        {source?.address && (
                            <>
                                {source?.address || ''}
                                <br />
                            </>
                        )}
                        {source?.zipcode || ''} {source?.city || ''}
                    </div>
                </div>
            )}
        </>
    );
};

export default OperationReconciliationUserRow;
