import React from 'react';
import { useIntl } from 'react-intl';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

interface Props {
    event: VcuMappedEventDetails;
}
const DetailResume = ({ event }: Props) => {
    const Intl = useIntl();
    const { fleetback } = event;

    function statusIntervention(value: string) {
        switch (value) {
            case 'SENT':
                return Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.SENT' });
            case 'READ':
                return Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.READ' });
            case 'TO_BE_SENT':
                return Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.TO_BE_SENT' });
            case 'CLOSED':
                return Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.CLOSED' });
            case 'ANSWERED':
                return Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.ANSWERED' });
            default:
                return '-';
        }
    }

    return (
        <div className="d-md-flex justify-content-md-between align-items-md-stretch">
            <div className="col-md-6">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.EVENT' })}
                    value={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.EVENT.TITLE' })}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.SOURCE.DATA' })}
                    value={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.SOURCE.DATA.TITLE' })}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.TYPE' })}
                    value={fleetback?.auditNameModelFront || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.DESCRIPTION' })}
                    value={fleetback?.auditDescriptionModelFront || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.REFERENCE.ID' })}
                    value={fleetback?.auditReference || '-'}
                />
            </div>
            <div className="col-md-6">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.STATUS_INTERVENTION' })}
                    value={statusIntervention(fleetback?.auditStatusIntervention)}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.PREVIEWLINK' })}
                    value={
                        fleetback?.auditPreview ? (
                            <a href={`${fleetback?.auditPreview}`} target="_blank" rel="noreferrer">
                                {Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.LINK' })}
                            </a>
                        ) : (
                            '-'
                        )
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.CREATEDBY.ID' })}
                    value={fleetback?.auditCreatedbyId}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.CREATEDBY.FIRSTNAME.AND.LASTNAME',
                    })}
                    value={
                        fleetback?.auditCreatedbyFirstname.toLowerCase() !== 'fleetback' &&
                        fleetback?.auditCreatedbyLastname.toLowerCase() !== 'fleetback'
                            ? `${fleetback?.auditCreatedbyFirstname} ${fleetback?.auditCreatedbyLastname}`
                            : '-'
                    }
                />
            </div>
        </div>
    );
};
export default DetailResume;
