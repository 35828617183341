import { saveAs } from 'file-saver';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';

import {
    downloadLeadDocument,
    ExpertiseData,
    getAchatCashContractForLead,
    getAndSendAchatCashContractForLead,
    getExpertiseForLead,
} from '@app/crud/customers/achat-cash.crud';

import { useAppSelector, useFetch } from '@app/hooks';

import ButtonWithLoader from '@app/partials/content/ButtonWithLoader';
import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

export interface ModalContractParams {
    lead: {
        identifier: number;
    };
    reload: () => void;
}

type ModalContractProps = {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
};

const ModalContract = ({ showModal, setShowModal }: ModalContractProps) => {
    const [warning, setWarning] = useState('');
    const [downloading, setDownloading] = useState(false);
    const [sending, setSending] = useState(false);

    const { params } = useAppSelector((state) => state.modal);

    const {
        data,
        loading,
        fetch: fetchData,
    } = useFetch<number, ExpertiseData>({
        fetchAction: () => getExpertiseForLead(params.lead.identifier),
    });

    const Intl = useIntl();

    useEffect(() => {
        setWarning(data?.contractStatus?.isSent ? '.WARNING' : '');
    }, [data]);

    const download = () => {
        setDownloading(true);

        getAchatCashContractForLead(params.lead.identifier)
            .then((response) => {
                if (!response.result?.id) {
                    throw new Error('no document id');
                }
                return downloadLeadDocument(response.result.id);
            })
            .then((response) => {
                const filename = response.headers['content-disposition'].replace(/^.*filename=(.*)$/, '$1');
                setDownloading(false);
                saveAs(new Blob([response.data]), filename);
                setShowModal(false);
                params.reload();
            })
            .catch(() => {
                setDownloading(false);
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.CONTRACT_MODAL.DOWNLOADING_ERROR',
                    }),
                });
            });
    };

    const send = () => {
        if (!data?.contractStatus?.canSend) {
            return;
        }

        setSending(true);

        getAndSendAchatCashContractForLead(params.lead.identifier)
            .then((response) => {
                if (!response.result?.id) {
                    throw new Error('no document id');
                }
                return downloadLeadDocument(response.result.id);
            })
            .then((response) => {
                const filename = response.headers['content-disposition'].replace(/^.*filename=(.*)$/, '$1');
                setSending(false);
                saveAs(new Blob([response.data]), filename);
                setShowModal(false);
                params.reload();
            })
            .catch(() => {
                setSending(false);
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.CONTRACT_MODAL.SENDING_ERROR',
                    }),
                });
            });
    };

    useEffect(() => {
        if (showModal) {
            fetchData(params.lead.identifier, {});
        }
    }, [showModal]);

    const ModalBody = () => {
        if (loading || !data?.contractStatus) {
            return <Loader style={{ width: '5rem', height: '5rem' }} overlay />;
        } else if (data?.contractStatus?.missingFields.length === 0) {
            return (
                <p className="text-center">
                    <FormattedHTMLMessage id={`CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.CONTRACT_MODAL.CONTENT${warning}`} />
                </p>
            );
        }

        return (
            <div className="text-danger">
                <p className="text-center">
                    <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.CONTRACT_MODAL.CONTENT.MISSING_FIELDS" />
                </p>
                <ul>
                    {data.contractStatus.missingFields.map((field: string) => (
                        <li key={field.toLowerCase().replace(' ', '_')}>
                            <FormattedMessage id={`CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.MISSING_FIELDS.${field}`} />
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x la-award text-primary" />}
            title={Intl.formatMessage({ id: `CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.CONTRACT_MODAL.TITLE${warning}` })}
            body={<ModalBody />}
            footer={
                <div className="d-flex justify-content-between">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.CONTRACT_MODAL.BTN_CANCEL" />
                    </Button>
                    <div className="d-flex">
                        {!loading && (
                            <>
                                {data?.contractStatus?.missingFields.length === 0 && (
                                    <Button
                                        variant="primary"
                                        onClick={() => send()}
                                        className="d-flex align-items-center"
                                        disabled={!data?.contractStatus?.canSend}
                                    >
                                        {sending && (
                                            <span
                                                className="spinner-border spinner-border-sm mr-2"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.CONTRACT_MODAL.BTN_SEND" />
                                    </Button>
                                )}
                                <ButtonWithLoader
                                    variant="outline-primary"
                                    loading={downloading}
                                    onClick={download}
                                    className="ml-4 d-flex align-items-center"
                                >
                                    <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VALUATION.CONTRACT_MODAL.BTN_DOWNLOAD" />
                                </ButtonWithLoader>
                            </>
                        )}
                    </div>
                </div>
            }
        />
    );
};

export default ModalContract;
