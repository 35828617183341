import React, {useCallback, useEffect, useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import {Intl, Locale} from "../../../../_metronic/i18n/I18nProvider";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../partials/content/Loader";
import {Link, useParams, withRouter} from "react-router-dom";
import toast from "../../../partials/content/Toast";
import {
    createAdditionalService,
    fetchAdditionalService,
    removeAdditionalService,
    updateAdditionalService
} from "../../../store/apv/additional-service/additional-service.thunk";
import SwitchInput from "../../../partials/content/SwitchInput";
import SelectPackage from "../../../partials/content/Select.package";
import Editor from "../../../partials/content/Editor";
import PictureUpload from "../../../partials/content/PictureUpload";
import * as dayjs from "dayjs";
import ErrorForm from "../../../partials/layout/ErrorForm";
import {getDateByFormat} from "../../../helpers/DateHelper";
import {MultiSelect} from "primereact/multiselect";
import {routeTo} from "../../../helpers/RoutesHelper";
import ROUTES from "../../../router/Routes";
import Permission from "../../../partials/content/Permission";
import {DateTimePicker} from "../../../partials/content/DateTimePicker";

const defaultValues = {
    seoIntroduction: '',
    seoDescription: '',
    additionalServiceCategoryId: -1,
    name: '',
    active: false,
    mandatory: false,
    packageId: '',
    mileageMin: '',
    mileageMax: '',
    dateStart: '',
    dateEnd: '',
    seoUrl: '',
    seoTitle: '',
    seoMetaDescription: '',
    seoH1: '',
    code: '',
    price: '',
    excludedPackagesId: ''
}

function AdditionalService({ history }) {
    const {reset, clearErrors, handleSubmit, errors, register, control, setValue, watch} = useFormContext();
    const params = useParams();
    const dispatch = useDispatch();

    const newAdditionalService = typeof params.id === 'undefined';
    const [firstLoad, setFirstLoad] = useState(true);
    const [withPackage, setWithPackage] = useState(false);
    const { additionalService, packages, additionalServiceCategory, error, loading } = useSelector((state) => state.additionalService);
    const mandatory = watch('mandatory');

    const onSubmit = data => {
        if (newAdditionalService) {
            dispatch(createAdditionalService({
                data,
                history
            }));
        } else {
            dispatch(updateAdditionalService(params.id, data));
        }
    };

    const onError = error => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({id: 'FORM.ERROR.VALIDATE'})
        });
    }

    const deleteAdditionalService = () => {
        dispatch(removeAdditionalService({
            id: params.id,
            history: history
        }));
    }

    const showAdditionalServices = useCallback(() => {
            history.push(routeTo(ROUTES.ADDITIONAL_SERVICES.PATH));
        },
        [history]
    );

    useEffect(() => {
        if (mandatory && !withPackage && additionalService?.excludedPackage) {
            setValue('excludedPackagesId', Object.values(additionalService.excludedPackage).map(excludedPackage => excludedPackage.id));
        } else {
            setValue('excludedPackagesId', defaultValues.excludedPackagesId);
        }
        setValue('packageId', withPackage && additionalService?.package?.id ? additionalService?.package?.id : defaultValues.packageId);
    }, [setValue, withPackage, mandatory])

    useEffect(() => {
        dispatch(fetchAdditionalService(params.id));
        setFirstLoad(false);

        return () => {
            reset(defaultValues);
        }
    }, [reset, dispatch, params.id]);

    useEffect(() => {
        reset({
            seoIntroduction: additionalService?.seoIntroduction || defaultValues.seoIntroduction,
            seoDescription: additionalService?.seoDescription || defaultValues.seoDescription,
            additionalServiceCategoryId: additionalService?.additionalServiceCategory?.id || defaultValues.additionalServiceCategoryId,
            name: additionalService?.name || defaultValues.name,
            active: additionalService?.active || defaultValues.active,
            mandatory: additionalService?.mandatory || defaultValues.mandatory,
            packageId: additionalService?.package?.id || defaultValues.packageId,
            mileageMin: additionalService?.mileageMin || defaultValues.mileageMin,
            mileageMax: additionalService?.mileageMax || defaultValues.mileageMax,
            dateStart: additionalService?.dateStart ? dayjs(additionalService.dateStart).format('YYYY-MM-DD HH:mm:ss') : defaultValues.dateStart,
            dateEnd: additionalService?.dateEnd ? dayjs(additionalService.dateEnd).format('YYYY-MM-DD HH:mm:ss') : defaultValues.dateEnd,
            seoUrl: additionalService?.seoUrl || defaultValues.seoUrl,
            seoTitle: additionalService?.seoTitle || defaultValues.seoTitle,
            seoMetaDescription: additionalService?.seoMetaDescription || defaultValues.seoMetaDescription,
            seoH1: additionalService?.seoH1 || defaultValues.seoH1,
            code: additionalService?.code || defaultValues.code,
            price: additionalService?.price ?? defaultValues.price,
            excludedPackagesId: additionalService?.excludedPackage ? Object.values(additionalService.excludedPackage).map(excludedPackage => excludedPackage.id) : defaultValues.excludedPackagesId
        });

        setWithPackage(!!additionalService?.package);
    }, [reset, additionalService]);

    useEffect(() => {
        if (error !== null && !firstLoad) {
            toast({
                onShow: () => showAdditionalServices(),
                variant: 'danger',
                message: Intl.formatMessage({id: 'TRANSLATOR.ERROR'})
            });
        }
    }, [error, firstLoad, showAdditionalServices]);

    useEffect(() => {
        clearErrors();
    }, [clearErrors, withPackage]);

    return (
        <Form className="package" onSubmit={handleSubmit(onSubmit, onError)}>
            {loading &&
            <Loader style={{width: '5rem', height: '5rem'}} overlay/>
            }
            <Card>
                <Card.Body>
                    <Row className="mb-4">
                        <Col lg={4}>
                            <Form.Group>
                                <Form.Label>{Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.SELL_ASSOCIATED' })}</Form.Label>
                                <SwitchInput name="withPackage"
                                             form={false}
                                             onChange={(state) => setWithPackage(state)}
                                             labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.NO' })}
                                             labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.YES' })}
                                             active={withPackage}/>
                            </Form.Group>
                        </Col>
                        <Col lg={8}>
                            <Form.Row>
                                <Col lg={12}>
                                    <Form.Label>{Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.SELL_ASSOCIATE' })}</Form.Label>
                                </Col>
                                <Col lg={4}>
                                    <SelectPackage disabled={!withPackage}
                                                   packages={packages}
                                                   validate={withPackage}
                                                   packageId={withPackage && additionalService?.package?.id ? additionalService?.package?.id : defaultValues.packageId}
                                                   onError={showAdditionalServices}/>
                                    <ErrorForm errors={errors} name="packageId" />
                                </Col>
                            </Form.Row>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col lg={4}>
                            <Card>
                                <Card.Header>
                                    <i className="card__icon fas fa-info-circle"></i>
                                    {Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.INFORMATIONS' })}
                                </Card.Header>
                                <Card.Body>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.TARGET' })}</Form.Label>
                                        <Form.Control as="select"
                                                      name="additionalServiceCategoryId"
                                                      className={`${errors.additionalServiceCategoryId ? 'is-invalid' : ''}`}
                                                      ref={register({
                                                          validate: (value) => {
                                                              return parseInt(value) === -1
                                                                  ? Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                                                  : true
                                                          }
                                                      })}>
                                            <option value={-1} disabled>Sélectionnez la cible</option>
                                            {additionalServiceCategory &&
                                            additionalServiceCategory.map((category, key) => (
                                                <option key={key} value={category.id}>{category.name}</option>
                                            ))
                                            }
                                        </Form.Control>
                                        <ErrorForm errors={errors} name="additionalServiceCategoryId" />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.NAME' })}</Form.Label>
                                        <Form.Control type="text"
                                                      name="name"
                                                      className={`${errors.name ? 'is-invalid' : ''}`}
                                                      ref={register({
                                                          required: {
                                                              value: !withPackage,
                                                              message: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                                          }
                                                      })}/>
                                        <ErrorForm errors={errors} name="name" />
                                    </Form.Group>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group>
                                                <Form.Label>{Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.STATUS' })}</Form.Label>
                                                <SwitchInput name="active"
                                                             active={!!additionalService?.active}/>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group>
                                                <Form.Label>{Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.MANDATORY' })}</Form.Label>
                                                <SwitchInput name="mandatory"
                                                             labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.NO' })}
                                                             labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.YES' })}
                                                             active={!!additionalService?.mandatory}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card>
                                <Card.Header>
                                    <i className="card__icon fas fa-cog"></i>
                                    {Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.CRITERIA' })}
                                </Card.Header>
                                <Card.Body>
                                    {/*<Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.MIN_MILEAGE' })}</Form.Label>
                                        <Form.Control type="text" name="mileageMin" ref={register}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.MAX_MILEAGE' })}</Form.Label>
                                        <Form.Control type="text" name="mileageMax" ref={register}/>
                                    </Form.Group>*/}
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.DATE_START' })}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="dateStart"
                                            defaultValue={defaultValues.dateStart}
                                            render={(props) => (
                                                <DateTimePicker
                                                    locale={Locale}
                                                    showIcon
                                                    dateFormat="DD/MM/YYYY"
                                                    onChange={(e) => {
                                                        const value = dayjs(e.value).isValid() ? dayjs(e.value).format('YYYY-MM-DD HH:mm:ss') : defaultValues.dateStart;
                                                        props.onChange(value);
                                                    }}
                                                    value={getDateByFormat(props.value, 'YYYY-MM-DD HH:mm:ss', defaultValues.dateStart)}/>
                                            )}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.DATE_END' })}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="dateEnd"
                                            defaultValue={defaultValues.dateEnd}
                                            render={(props) => (
                                                <DateTimePicker
                                                    locale={Locale}
                                                    showIcon
                                                    dateFormat="DD/MM/YYYY"
                                                    onChange={(e) => {
                                                        const value = dayjs(e.value).isValid() ? dayjs(e.value).format('YYYY-MM-DD HH:mm:ss') : defaultValues.dateEnd;
                                                        props.onChange(value);
                                                    }}
                                                    value={getDateByFormat(props.value, 'YYYY-MM-DD HH:mm:ss', defaultValues.dateEnd)}/>
                                            )}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.EXCLUDED_PACKAGE' })}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="excludedPackagesId"
                                            render={(props) => (
                                                <MultiSelect
                                                    name='excludedPackagesId'
                                                    value={props.value}
                                                    options={packages.map((_package) => {
                                                        return {
                                                            name: _package.name,
                                                            id: _package.id
                                                        };
                                                    })}
                                                    disabled={withPackage || !mandatory}
                                                    onChange={(e) => props.onChange(e.value)}
                                                    optionLabel="name"
                                                    optionValue="id"
                                                    display="chip"
                                                    maxSelectedLabels={1}
                                                    filter
                                                    className={`${errors.dealerships ? 'is-invalid' : ''}`}
                                                />
                                            )}/>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card>
                                <Card.Header>
                                    <i className="card__icon fas fa-globe-europe"></i>
                                    {Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.SEO' })}
                                </Card.Header>
                                <Card.Body>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.TITLE' })}</Form.Label>
                                        <Form.Control type="text"
                                                      name="seoTitle"
                                                      ref={register}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.URL' })}</Form.Label>
                                        <Form.Control type="text"
                                                      name="seoUrl"
                                                      ref={register}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.H1' })}</Form.Label>
                                        <Form.Control type="text"
                                                      name="seoH1"
                                                      ref={register}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.META_DESCRIPTION' })}</Form.Label>
                                        <Form.Control type="text"
                                                      name="seoMetaDescription"
                                                      ref={register}/>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {!withPackage &&
                    <Row className="mb-4">
                        <Col lg={12}>
                            <Card>
                                <Card.Header>
                                    <i className="card__icon fas fa-money-bill"></i>
                                    {Intl.formatMessage({id: 'APV.ADDITIONAL_SERVICE.PRICES'})}
                                </Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Col lg={4} className="pr-5">
                                            <Form.Label>{Intl.formatMessage({id: 'APV.ADDITIONAL_SERVICE.REFERENCE'})}</Form.Label>
                                            <Form.Control type="text"
                                                          name="code"
                                                          ref={register}/>
                                        </Col>
                                        <Col lg={4} className="pr-5">
                                            <Form.Label>{Intl.formatMessage({id: 'APV.ADDITIONAL_SERVICE.NORMAL_PRICE'})}</Form.Label>
                                            <Form.Control type="text"
                                                          name="price"
                                                          ref={register}/>
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    }
                    <Row>
                        <Col lg={4}>
                            <Card>
                                <Card.Header>
                                    <i className="card__icon fas fa-edit"></i>
                                    {Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.INTRODUCTION' })}
                                </Card.Header>
                                <Card.Body>
                                    <Controller
                                        control={control}
                                        name="seoIntroduction"
                                        defaultValue={defaultValues.seoIntroduction}
                                        render={(props) => (
                                            <Editor
                                                value={props.value}
                                                onChange={props.onChange}
                                            />
                                        )}/>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card>
                                <Card.Header>
                                    <i className="card__icon fas fa-edit"></i>
                                    {Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.DESCRIPTION' })}
                                </Card.Header>
                                <Card.Body>
                                    <Controller
                                        control={control}
                                        name="seoDescription"
                                        defaultValue={defaultValues.seoDescription}
                                        render={(props) => (
                                            <Editor
                                                value={props.value}
                                                onChange={props.onChange}
                                            />
                                        )}/>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card>
                                <Card.Header>
                                    <i className="card__icon far fa-image"></i>
                                    {Intl.formatMessage({ id: 'APV.ADDITIONAL_SERVICE.VISUALS' })}
                                </Card.Header>
                                <Card.Body>
                                    <PictureUpload name="pathImg"
                                                required={false}
                                                path={additionalService?.pathImg}/>
                                    <ErrorForm errors={errors} name="pathImg" />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col className={newAdditionalService ? 'text-center' : 'text-right'}>
                            <Link to={routeTo(ROUTES.ADDITIONAL_SERVICES.PATH)}>
                                <Button variant="outline-primary">
                                    {Intl.formatMessage({ id: 'TRANSLATOR.BACK' })}
                                </Button>
                            </Link>
                            <Permission permissions={['APV_UPDATE_ADDITIONAL_SERVICE']}>
                                <Button variant="primary"
                                        className="ml-2"
                                        type="submit">
                                    {Intl.formatMessage({ id: 'TRANSLATOR.SAVE' })}
                                </Button>
                            </Permission>
                        </Col>
                        {!newAdditionalService &&
                        <Permission permissions={['APV_DELETE_ADDITIONAL_SERVICE']}>
                            <Col className="text-right">
                                <Button variant="outline-danger" onClick={() => deleteAdditionalService()}>
                                    {Intl.formatMessage({ id: 'TRANSLATOR.REMOVE' })}
                                </Button>
                            </Col>
                        </Permission>
                        }
                    </Row>
                </Card.Body>
            </Card>
        </Form>
    )
}

export default withRouter(AdditionalService);
