import ApiGateway from '@app/helpers/AxiosHelper';

const { REACT_APP_AUTOUSER_URL } = process.env;

export interface Permission {
    category: string;
    title: string;
    description: string;
    codename: string;
}

export function exportPermissions(id: string) {
    return ApiGateway.get(`${REACT_APP_AUTOUSER_URL}/admin/applications/${id}/permissions/export`);
}

export function addPermission(id: string, data: Permission) {
    return ApiGateway.post(`${REACT_APP_AUTOUSER_URL}/admin/applications/${id}/permission`, data);
}

export function updatePermissionById(id: string, permissionId: string, data: Permission) {
    return ApiGateway.put(`${REACT_APP_AUTOUSER_URL}/admin/applications/${id}/permission/${permissionId}`, data);
}

export function deletePermission(permissionId: string) {
    return ApiGateway.delete(`${REACT_APP_AUTOUSER_URL}/admin/permissions/${permissionId}`);
}
