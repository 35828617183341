import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import ErrorForm from '@app/partials/layout/ErrorForm';

const LeadWonName = ({ params }) => {
    const { register, errors } = useFormContext();
    const Intl = useIntl();

    return (
        <Form.Group>
            <Form.Label>
                <FormattedHTMLMessage id="CUSTOMERS.MODAL.LEAD_WON.NAME" />
            </Form.Label>
            <Form.Control
                type="text"
                name="customerName"
                disabled={!!params?.event}
                className={`${errors.customerName ? 'is-invalid' : ''}`}
                ref={register({
                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                })}
                placeholder="Jean Neuville"
            />
            <ErrorForm errors={errors} name="customerName" />
        </Form.Group>
    );
};

export default LeadWonName;
