import ApiGateway from '@app/helpers/AxiosHelper';

const { REACT_APP_AUTOUSER_URL } = process.env;

// eslint-disable-next-line import/prefer-default-export
export function getHistory(params) {
    return ApiGateway.get(`${REACT_APP_AUTOUSER_URL}/admin/history`, {
        params,
    });
}
