import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import useAction from '@app/pages/customers/Hooks/useAction';
import Comment from '@app/pages/customers/Partials/Comment';
import { actions } from '@app/store/modal/modal.store';

import toast from '@app/partials/content/Toast';

import { EventDetailContext } from './EventDetailProvider';
import { useAppSelector } from '@app/hooks';

const EventDetailDealershipNote = ({ event, typeSlug, isLeadClosed }) => {
    const { fetchData } = useContext(EventDetailContext);
    const dispatch = useDispatch();
    const Intl = useIntl();

    const [, updateComment] = useAction({
        type: 'update_comment',
        success: () => {
            toast({
                variant: 'success',
                message: Intl.formatMessage({ id: 'TRANSLATOR.SUCCESS' }),
            });
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        },
    });

    const updateProjectComment = async (data) => {
        await updateComment({
            ...data,
            lead: {
                id: event?.informations.identifier,
            },
        });
        dispatch(actions.closeModals());
        fetchData(event?.informations.identifier);
    };

    return (
        <Card className="card--shadowed">
            <Card.Header className={`event__origin--${typeSlug} position-relative`}>
                <div className={`event__origin-bg--${typeSlug} pin`}>
                    <i className="la la-building text-white la-lg" />
                </div>
                <span className="ml-4">
                    {Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DEALERSHIP_NOTE.TITLE' })}
                </span>
            </Card.Header>
            <Card.Body className="position-relative d-flex flex-column">
                <Comment
                    comment={event?.informations?.comment}
                    callback={updateProjectComment}
                    label="COMMENT_LEAD"
                    withTags
                    variant="text-primary"
                    lead={event?.informations}
                    readOnly={!event?.informations?.identifier || event?.informations?.processType || isLeadClosed}
                />
            </Card.Body>
        </Card>
    );
};

export default EventDetailDealershipNote;
