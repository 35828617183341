import classNames from 'classnames';
import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Pagination } from '@app/hooks/useTableHandlers';

import { routeTo } from '@app/helpers/RoutesHelper';

import { exportContractPdf, getContactId } from '@app/crud/securyweb/securyweb.crud';
import { Contract, StatusContractSlug } from '@app/crud/securyweb/securyweb.types';

import variantTypes from '@app/constants';
import { useFetch } from '@app/hooks';
import { getStatusClassName, getStatusLabelId } from '@app/pages/securyweb/Helpers/SecurywebHelper';
import ROUTES from '@app/router/Routes';

import DropdownFilter from '@app/partials/content/DropdownFilter';
import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import Label from '@app/partials/content/Label';
import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';
import toast from '@app/partials/content/Toast';

type SecurywebContractsFilter = {
    contractStatus: StatusContractSlug;
    concession: { value: string };
    nomTitulaire: { value: string };
    codeVin: { value: string };
    immatriculation: { value: string };
    numeroContrat: { value: string };
};

type SecurywebContractsDataTableProps = {
    items: number;
    start: number;
    filters: SecurywebContractsFilter;
    data: {
        contracts: Contract[];
        counter: {
            key: string;
            label: string;
        }[];
        totalContracts: number;
    } | null;
    loading: boolean;
    handleFilter: (value: unknown) => void;
    handlePagination: (e: Pagination) => void;
    onStatusChange: (newStatus: StatusContractSlug) => void;
};

const SecurywebContractsDataTable = ({
    items,
    start,
    filters,
    data,
    loading,
    handleFilter,
    handlePagination,
    onStatusChange,
}: SecurywebContractsDataTableProps) => {
    const Intl = useIntl();
    const history = useHistory();
    const { REACT_APP_SECURYWEB_AUTOSPHERE_URL } = process.env;
    const [isExportLoading, setIsExportLoading] = useState(false);
    const PRODUCT_WITHOUT_EXPORT = '210';

    const {
        fetch: fetchContactId,
        data: contactId,
        loading: loadingContactId,
    } = useFetch({
        fetchAction: getContactId,
        resultInterceptor: (response) => response.result,
    });

    const inputFilter = (name: keyof SecurywebContractsFilter) => (
        <InputFilter
            name={name}
            placeholder={
                name === 'nomTitulaire' &&
                Intl.formatMessage({ id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.LASTNAME' })
            }
            onChange={handleFilter}
        />
    );

    const dealershipBodyTemplate = (contract: Contract) => {
        const { code: id, denomination: usualName } = contract?.concession || {};

        if (!id && !usualName) return '-';
        return (
            <>
                {id && <div>{id}</div>}
                {usualName && <div>{usualName}</div>}
            </>
        );
    };

    const contractNumberBodyTemplate = (contract: Contract) => <span>{contract?.numero || '-'}</span>;

    const licenseNumberBody = (contract: Contract) => {
        const license_number = contract?.vehicule?.immatriculation;
        return <span>{license_number || '-'}</span>;
    };

    const serialNumberBody = (contract: Contract) => {
        const serial_number = contract?.vehicule?.codeVin;
        return <span>{serial_number || '-'}</span>;
    };

    const productBodyTemplate = (contract: Contract) => <span>{contract?.produit.libelle || '-'}</span>;

    const priceBodyTemplate = (contract: Contract) => (
        <span>{contract?.prixVenteTtc ? `${contract.prixVenteTtc} €` : '-'}</span>
    );

    const clientBodyTemplate = (contract: Contract) => {
        const { prenom: clientFirstname, nom: clientLastname } = contract?.souscripteur || {};

        if (!clientFirstname && !clientLastname) return '-';
        return <span>{`${clientFirstname || ''} ${clientLastname || ''}`}</span>;
    };

    const referentBodyTemplate = (contract: Contract) => {
        const { prenom: ownerFirstname, nom: ownerLastname } = contract?.vendeur || {};
        const { prenom: secretaryFirstname, nom: secretaryLastname } = contract?.secretaire || {};

        return (
            <>
                <span>{[ownerFirstname, ownerLastname]?.join(' ')}</span>
                {secretaryFirstname && secretaryLastname && (
                    <div className="font-italic font-size-sm">
                        <FormattedMessage id="SECURYCAR.CONTRACT.DATATABLE.INPUT_BY" />
                        {[secretaryFirstname, secretaryLastname]?.join(' ')}
                    </div>
                )}
            </>
        );
    };

    const statusBodyTemplate = (contract: Contract) => {
        const statusSlug = contract?.statut ?? StatusContractSlug.DRAFT;
        const statusClassName = getStatusClassName(statusSlug);
        const labelId = getStatusLabelId(statusSlug);
        const statusLabel = Intl.formatMessage({ id: labelId });

        return (
            <>
                <Label
                    className={classNames('font-weight-bold label-lg', {
                        [`label-light-${statusClassName}`]: statusClassName,
                    })}
                >
                    {statusLabel}
                </Label>
                {contract.sourceCreation === 'AUTOMANAGER' && (
                    <Image src="/media/logos/loader.svg" className="ml-2" style={{ width: '15px' }} />
                )}
            </>
        );
    };

    const dateCreatedBodyTemplate = (contract: Contract) => (
        <span>{contract?.dateSouscription ? dayjs(contract.dateSouscription).format('DD/MM/YYYY') : '-'}</span>
    );

    const toCustomerRoute = (id: number) => {
        history.push(
            routeTo(ROUTES.CUSTOMER.PATH, {
                id,
            }),
        );
    };

    const toSecuryWebRoute = () => {
        window.open(REACT_APP_SECURYWEB_AUTOSPHERE_URL, '_blank');
    };

    const handleShowButtonClick = (contract: Contract) => {
        // TODO : v1 add CreateAlreadyExist if multiple client (f827388adfe578c752577e2312636a4d9d7e9bab)

        const { id: idSubscriber } = contract?.souscripteur || {};

        if (idSubscriber) {
            fetchContactId(idSubscriber).then((result) => {
                if (!result) toSecuryWebRoute();
            });
        } else {
            toSecuryWebRoute();
        }
    };

    const handleExportButtonClick = (contract: Contract) => {
        setIsExportLoading(true);
        exportContractPdf(contract?.id, contract?.typeContrat)
            .catch(() => {
                toast({
                    variant: variantTypes.DANGER,
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                });
            })
            .finally(() => {
                setIsExportLoading(false);
            });
    };

    useEffect(() => {
        if (Array.isArray(contactId)) return;
        toCustomerRoute(contactId);
    }, [contactId]);

    const actionsBodyTemplate = (contract: Contract) => (
        <Permission permissions={['SECURYCAR_READ_CONTRACT']}>
            <Button className="p-2 px-3" variant="primary" onClick={() => handleShowButtonClick(contract)}>
                <FormattedMessage id="TRANSLATOR.SHOW" />
            </Button>
            {contract?.statut === StatusContractSlug.VALID && contract?.produit?.code !== PRODUCT_WITHOUT_EXPORT && (
                <Button className="p-2 mt-4" variant="primary" onClick={() => handleExportButtonClick(contract)}>
                    <FormattedMessage id="TRANSLATOR.EXPORT" />
                </Button>
            )}
        </Permission>
    );

    const selectStatusDropdown = () => {
        const contractStatusOptions = data?.counter
            ? data?.counter.map((status) => ({
                  name: status.label,
                  key: status.key,
              }))
            : [];

        return (
            <DropdownFilter
                optionLabel="name"
                filterBy="name"
                name="contractStatus"
                options={contractStatusOptions}
                onChange={(event: { contractStatus: { value: StatusContractSlug } }) => {
                    onStatusChange(event.contractStatus.value);
                }}
            />
        );
    };

    const cols = [
        {
            field: 'concession',
            header: Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.DEALERSHIP' }),
            body: dealershipBodyTemplate,
            filterElement: inputFilter('concession'),
            filter: true,
            style: { width: '15%' },
        },
        {
            field: 'numeroContrat',
            header: Intl.formatMessage({ id: 'SECURYCAR.CONTRACT.DATATABLE.CONTRACT_NUMBER' }),
            body: contractNumberBodyTemplate,
            filterElement: inputFilter('numeroContrat'),
            filter: true,
            style: { width: '10%' },
        },
        {
            field: 'immatriculation',
            header: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SEARCH.VEHICLE.IMMAT.TITLE' }),
            body: licenseNumberBody,
            // Note : no filter if borne product
            filterElement: inputFilter('immatriculation'),
            filter: true,
            style: { width: '10%' },
        },
        {
            field: 'codeVin',
            header: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SEARCH.VEHICLE.VIN.TITLE' }),
            body: serialNumberBody,
            // Note : no filter if borne product
            filterElement: inputFilter('codeVin'),
            filter: true,
            style: { width: '15%' },
        },
        {
            field: 'contact',
            header: Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.CUSTOMER' }),
            body: clientBodyTemplate,
            filterElement: inputFilter('nomTitulaire'),
            filter: true,
            style: { width: '12%' },
        },
        {
            field: 'product',
            header: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.CATEGORY.PRODUCT.TITLE' }),
            body: productBodyTemplate,
            style: { width: '10%' },
        },
        {
            field: 'price',
            header: Intl.formatMessage({ id: 'SECURYCAR.CONTRACT.DATATABLE.PRICE' }),
            body: priceBodyTemplate,
            style: { width: '8%' },
        },
        {
            field: 'user',
            header: Intl.formatMessage({ id: 'CUSTOMER.MODAL.ASSIGN.REFERENT.SELECT' }),
            body: referentBodyTemplate,
            style: { width: '10%' },
        },
        {
            field: 'contractStatus',
            header: Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.TABLE.STATUS' }),
            body: statusBodyTemplate,
            filterElement: selectStatusDropdown(),
            filter: true,
            style: { width: '10%' },
        },
        {
            field: 'created',
            header: Intl.formatMessage({ id: 'SECURYCAR.CONTRACT.DATATABLE.SUBSCRIPTION' }),
            body: dateCreatedBodyTemplate,
            style: { width: '15%' },
        },
        {
            field: 'actions',
            header: Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.ACTIONS' }),
            body: actionsBodyTemplate,
            bodyClassName: 'text-center',
            style: { width: '5%' },
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    if (!data) return null;
    return (
        <>
            {(loading || loadingContactId || isExportLoading) && (
                <Loader style={{ width: '5rem', height: '5rem' }} overlay />
            )}
            <FiltersProvider value={filters}>
                {/* @ts-ignore */}
                <HoCDataTable
                    value={data?.contracts}
                    totalRecords={data?.totalContracts}
                    lazy
                    rows={items}
                    start={start}
                    first={start}
                    loading={loading}
                    onFilter={handleFilter}
                    filterDisplay="row"
                    paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                    onPage={handlePagination}
                    paginator
                    rowsPerPageOptions={[25, 50, 100]}
                    emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                >
                    {dynamicColumns}
                </HoCDataTable>
            </FiltersProvider>
        </>
    );
};

export default SecurywebContractsDataTable;
