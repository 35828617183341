import { Column } from 'primereact/column';
import qs from 'qs';
import React, { useEffect } from 'react';
import { Button, Card, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { generatePath, Link, useHistory } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import usePermissions from '@app/hooks/usePermissions';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { ApiResponse } from '@app/helpers/AxiosHelper';

import { getApplications } from '@app/crud/autouser/application.crud';
import { SimpleApplication } from '@app/crud/autouser/application.types';
import { getRoles } from '@app/crud/autouser/role.crud';
import { RolesParams, UserRoles } from '@app/crud/autouser/role.types';

import { useAppSelector, useFetch } from '@app/hooks';
import ROUTES from '@app/router/Routes';

import HoCDataTable from '@app/partials/content/HoCDataTable';
import RefreshTable from '@app/partials/content/RefreshTable';
import { Filters } from '@app/partials/layout/Filters';

const VISIBLE_SLUG_ROLES = ['conseiller-commercial', 'manager', 'directeur'];

const Applications = () => {
    const history = useHistory();
    const Intl = useIntl();
    const globalFilters = useAppSelector((state) => state.filters);
    const { hasPermission } = usePermissions();
    const { items, start, sortField, sortOrder } = useFilters({
        name: 'autouser_applications_search',
    });
    const { data, loading, handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: 'autouser_applications_search',
        fetchAction: getApplications,
        withStore: false,
    });

    const applications = data?.result || [];
    const totalRecords = applications.length;

    const { fetch: fetchRoles, data: roles } = useFetch<RolesParams, ApiResponse<UserRoles[]>, UserRoles[]>({
        fetchAction: getRoles,
    });
    useEffect(() => {
        fetchRoles();
    }, [fetchRoles]);

    const showAddApplication = () => {
        history.push(generatePath(ROUTES.APPLICATION.PATH.CREATE));
    };

    const actionsBodyTemplate = (application: SimpleApplication) => {
        const { filters } = globalFilters[`autouser_applications_search_${application.id}`] || {};
        const selectedRoles =
            filters?.roles?.value ||
            roles.filter((role) => VISIBLE_SLUG_ROLES.includes(role.slug)).map((role) => role.id);
        const selectedPerms = filters?.permissions?.value || [];
        return (
            <Link
                to={`${generatePath(ROUTES.APPLICATION.PATH.UPDATE, { id: application.id })}?${qs.stringify(
                    { roles: selectedRoles, permissions: selectedPerms },
                    { arrayFormat: 'brackets', encode: false },
                )}`}
            >
                <Button className="p-2 px-3" variant="secondary">
                    <i className="fa fa-pen" />
                    {Intl.formatMessage({ id: 'TRANSLATOR.UPDATE' })}
                </Button>
            </Link>
        );
    };

    const cols = [
        {
            field: 'name',
            header: Intl.formatMessage({ id: 'AUTOUSER.APPLICATIONS.TABLE.HEAD.NAME' }),
            style: { width: '75%' },
        },
        // {
        //   field: 'active',
        //   header: Intl.formatMessage({ id: "AUTOUSER.APPLICATIONS.TABLE.HEAD.ACTIVE" }),
        //   style: {width: '25%'},
        //   body: statusBodyTemplate
        // },
        {
            field: 'actions',
            header: Intl.formatMessage({ id: 'AUTOUSER.APPLICATIONS.TABLE.HEAD.ACTIONS' }),
            style: { width: '15%' },
            body: actionsBodyTemplate,
        },
    ];

    const addApplicationButton = hasPermission('AUTOUSER_CREATE_APP') ? (
        <Button variant="outline-primary" onClick={() => showAddApplication()}>
            {Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.CREATE' })}
        </Button>
    ) : (
        ''
    );

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <Card>
            <Card.Body>
                <Filters>
                    <Row>
                        <RefreshTable count={totalRecords} onClick={refresh} />
                        {addApplicationButton}
                    </Row>
                </Filters>
                {/* @ts-ignore */}
                <HoCDataTable
                    value={applications}
                    totalRecords={totalRecords}
                    lazy
                    loading={loading}
                    paginator
                    onFilter={handleFilter}
                    onPage={handlePagination}
                    onSort={handleSort}
                    paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                    currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                    rows={items}
                    first={start}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    removableSort
                    rowsPerPageOptions={[25, 50, 100]}
                    emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                >
                    {dynamicColumns}
                </HoCDataTable>
            </Card.Body>
        </Card>
    );
};

export default Applications;
