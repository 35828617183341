import React from 'react';
import {TAB_DATE, TAB_HOUR} from "./Helpers/DatePickerHelper";

const Tabs = ({tab, setTab, props}) => {
    const showTab = (tabClassName) => {
        setTab(tabClassName);
    }

    return (
        <div className="p-datepicker-tabs">
            <div className="p-datepicker-tab">
                <span className="p-datepicker-tab__content" onClick={() => showTab(TAB_DATE)}>Choisir la date</span>
            </div>
            <div className="p-datepicker-tab">
                <span className="p-datepicker-tab__content" onClick={() => showTab(TAB_HOUR)}>Choisir l'heure</span>
            </div>
        </div>
    );
};

export default Tabs;
