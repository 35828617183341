import React from 'react';
import { useIntl } from 'react-intl';

import CustomInput from '@app/pages/customers/Modals/CreateCustomer/customForm/customInput';

type PackageNameProps = {
    required: boolean;
};

const PackageName = ({ required }: PackageNameProps) => {
    const Intl = useIntl();

    return (
        <CustomInput
            name="customPackageName"
            placeholder={Intl.formatMessage({
                id: 'CUSTOMER.MODAL.APVINSTORE.PLACEHOLDER.ADD.NAME',
            })}
            validation={{
                required: required && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
            }}
        />
    );
};

export default PackageName;
