import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { Dispatch, SetStateAction } from 'react';

const variants = {
    show: {
        opacity: 1,
        rotate: 0,
        scale: 1,
    },
    hide: {
        opacity: 0,
        rotate: 360,
        scale: 0,
    },
};

interface ExpanderProps extends React.HTMLAttributes<HTMLElement> {
    collapsed: boolean;
    setCollapsed?: Dispatch<SetStateAction<boolean>>;
    size?: string;
    variant?: string;
}
const Expander = ({ collapsed, setCollapsed, size, variant, className, ...props }: ExpanderProps) => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
        className={classNames('h-100 position-relative', className)}
        onClick={() => setCollapsed?.(!collapsed)}
        style={props.style}
    >
        <motion.div className="right-0 position-absolute" variants={variants} animate={collapsed ? 'show' : 'hide'}>
            <i className={`las ${size} text-${variant} la-plus`} />
        </motion.div>
        <motion.div className="right-0 position-absolute" variants={variants} animate={collapsed ? 'hide' : 'show'}>
            <i className={`las ${size} text-${variant} la-minus`} />
        </motion.div>
    </div>
);

export default Expander;
