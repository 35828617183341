import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';

import { getVehicleCounterDetail } from '@app/crud/stock/stock.crud';

import OverlayTooltip from '@app/partials/content/OverlayTooltip';
import toast from '@app/partials/content/Toast';

import StockVehicleChart from './chart/StockVehicleChart';

Chart.register(ChartDataLabels);

const StockVehiclePriceHistorical = ({ vehicle }) => {
    const [view, setView] = useState('month');
    const [chartType, setChartType] = useState('lead');
    const [dateRange, setDateRange] = useState();

    const { fetch, data, error } = useFetch({
        fetchAction: getVehicleCounterDetail,
    });

    useEffect(() => {
        if (error) {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.ERROR.API' }),
            });
        } else if (vehicle?.sku) {
            const dates = `${dayjs()
                .subtract(view === 'month' ? 31 : 7, 'day')
                .format('YYYY-MM-DD')},${dayjs().format('YYYY-MM-DD')}`;
            setDateRange(dates);
            fetch({
                internalId: vehicle.sku,
                view,
                type: chartType === 'lead' ? 'leads_online|leads_online_afr|leads_local' : 'views',
                dateRange: dates,
            });
        }
    }, [fetch, vehicle, view, error, chartType]);

    return (
        <Card>
            <Card.Header className="d-flex justify-content-between">
                <div>
                    <i className="card__icon la-lg las la-chart-bar text-dark" />
                    {Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.TITLE' })}
                </div>
                <div className="d-flex">
                    <div className="mr-5">
                        <Button
                            variant={`${chartType === 'lead' && 'primary'}`}
                            size="sm"
                            onClick={() => setChartType('lead')}
                        >
                            {Intl.formatMessage({
                                id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.BUTTON.LEADS_PRICES',
                            })}
                        </Button>
                        <OverlayTooltip
                            label="STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.TOOLTIPS.VIEWS.BUTTON"
                            position="top"
                        >
                            <Button
                                variant={`${chartType === 'views' && 'primary'}`}
                                size="sm"
                                onClick={() => setChartType('views')}
                            >
                                {Intl.formatMessage({
                                    id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.BUTTON.VIEWS_PRICES',
                                })}
                            </Button>
                        </OverlayTooltip>
                    </div>
                    <div className="ml-5">
                        <Button variant={`${view === 'month' && 'primary'}`} size="sm" onClick={() => setView('month')}>
                            {Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.BUTTON.MONTH' })}
                        </Button>
                        <Button variant={`${view === 'week' && 'primary'}`} size="sm" onClick={() => setView('week')}>
                            {Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.BUTTON.WEEK' })}
                        </Button>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <div className="d-flex flex-column">
                    <div>
                        <StockVehicleChart data={data} chartType={chartType} view={view} dateRange={dateRange} />
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default StockVehiclePriceHistorical;
