import React, { useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { CustomerEvent, CustomerVehicle } from '@app/crud/customers/customer.type';

import { filterCustomerEvents, isEventSameAsRow, tabs } from '@app/pages/customers/Helpers/Events';

import Loader from '@app/partials/content/Loader';

import { CustomerLifeProvider } from './CustomerLifeContext';
import VehicleDatatable from './VehicleDatatable';

interface Props {
    events: CustomerVehicle[];
    loading: boolean;
}

const CustomerLife = ({ events, loading }: Props) => {
    const Intl = useIntl();
    const [activeTab, setActiveTab] = useState('all');
    const allEventDetails = events?.reduce((acc, event) => [...acc, ...event.events], []);
    const tabAllEventDetails = filterCustomerEvents(allEventDetails, activeTab);

    const [expandedRows, setExpandedRows] = useState([]);

    const isExpandedRow = (data: CustomerEvent) =>
        !!expandedRows.find((expandedRow) => isEventSameAsRow(data, expandedRow));

    return (
        <CustomerLifeProvider value={{ expandedRows, setExpandedRows, isExpandedRow }}>
            <Card className="card--shadowed">
                <Card.Header>
                    <div className="d-flex align-items-center">
                        <i className="la la-clock-o la-2x text-light mr-2" />
                        <span>{Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.TITLE' })}</span>
                    </div>
                </Card.Header>
                <Card.Body
                    className="position-relative"
                    style={loading ? { paddingTop: '4.50rem', paddingBottom: '4.50rem' } : null}
                >
                    {loading ? (
                        <Loader
                            style={{ width: '5rem', height: '5rem' }}
                            overlay
                            wrapperStyle={{ top: '0', left: '0' }}
                        />
                    ) : (
                        <Tabs
                            className="nav-tabs-line nav-tabs-line-primary nav-tabs-line-2x"
                            defaultActiveKey="all"
                            activeKey={activeTab}
                            id={activeTab}
                            onSelect={(e) => setActiveTab(e)}
                        >
                            {tabs.map((tab) => (
                                <Tab
                                    eventKey={tab}
                                    title={`${Intl.formatMessage({
                                        id: `CUSTOMER.VIEW.LIFE.TAB.${tab.toUpperCase()}`,
                                    })} (${filterCustomerEvents(allEventDetails, tab)?.length})`}
                                    tabClassName={`link-tab-${tab}-event`}
                                    className={`content-tab-${tab}-event`}
                                    key={tab}
                                >
                                    {!tabAllEventDetails?.length ? (
                                        <div className="vehicle-datatable">
                                            <div className="vehicle-datatable__header">
                                                <FormattedMessage id="DATATABLE.EMPTY_RESULT" />
                                            </div>
                                        </div>
                                    ) : (
                                        events?.map((event) => (
                                            <VehicleDatatable
                                                key={`vehicle-${event?.vehicleUuid}`}
                                                event={event}
                                                activeTab={activeTab}
                                                tab={tab}
                                            />
                                        ))
                                    )}
                                </Tab>
                            ))}
                        </Tabs>
                    )}
                </Card.Body>
            </Card>
        </CustomerLifeProvider>
    );
};

export default CustomerLife;
