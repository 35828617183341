import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import { getOneCustomerActivity } from '@app/crud/customers/customer.crud';

import { useAppSelector, useFetch } from '@app/hooks';
import ExtendIcon from '@app/pages/customers/ExtendIcon';
import useAction from '@app/pages/customers/Hooks/useAction';

import { DateTimePicker } from '@app/partials/content/DateTimePicker';
import InformationNotice from '@app/partials/content/InformationNotice';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

type ContractExtensionProps = {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
};

const ContractExtension = ({ showModal, setShowModal }: ContractExtensionProps) => {
    const Intl = useIntl();
    const {
        fetch: fetchActivity,
        data: lead,
        loading: fetchLoading,
    } = useFetch({
        fetchAction: getOneCustomerActivity,
    });
    const { params } = useAppSelector((state) => state.modal);
    const shouldDisplayNotice = lead?.finance?.contractEndDate && lead?.finance?.maximumContractExtensionDate;

    const [selectedDate, setSelectedDate] = useState(null);

    const [loading, createAction] = useAction({
        type: 'contract_extension',
        success: () => {
            setShowModal(false);
            toast({
                variant: 'success',
                message: Intl.formatMessage(
                    { id: 'CUSTOMER.MODAL.CONTRACT_EXTENSION.SUCCESS' },
                    { date: dayjs(selectedDate).format('DD/MM/YYYY') },
                ),
            });
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMER.MODAL.CONTRACT_EXTENSION.ERROR' }),
            });
        },
    });

    useEffect(() => {
        if (params?.leadId && fetchActivity) {
            fetchActivity(params.leadId);
        }
    }, [params, fetchActivity]);

    const onSubmit = () => {
        // @ts-ignore
        createAction({
            contractExtensionDate: dayjs(selectedDate).format('YYYY-MM-DD'),
        });
    };

    useEffect(() => {
        if (!showModal) setSelectedDate(null);
    }, [showModal]);

    return (
        <ModalDefault
            show={showModal}
            loading={loading || fetchLoading}
            hideModal={() => setShowModal(false)}
            icon={<ExtendIcon width="36" height="36" color="#15b7ff" />}
            title={
                <>
                    <div>
                        <FormattedHTMLMessage id="CUSTOMER.MODAL.CONTRACT_EXTENSION.TITLE" />
                    </div>
                    {lead?.finance?.maximumContractExtensionDate && (
                        <div className="text-initial font-size-base">
                            <FormattedHTMLMessage id="CUSTOMER.MODAL.CONTRACT_EXTENSION.SUBTITLE" />
                            {dayjs(lead.finance.maximumContractExtensionDate).format('DD/MM/YYYY')}
                        </div>
                    )}
                </>
            }
            body={
                <Form.Group className="pr-2">
                    <Form.Label>
                        <FormattedHTMLMessage id="CUSTOMER.MODAL.CONTRACT_EXTENSION.LABEL" />
                    </Form.Label>
                    <DateTimePicker
                        locale={Locale}
                        dateFormat="D MMMM, YYYY"
                        onChange={(e: { value: string | number | Date | dayjs.Dayjs }) => {
                            const value = dayjs(e.value).isValid() ? dayjs(e.value).toDate() : null;
                            setSelectedDate(value);
                        }}
                        minDate={dayjs(lead?.finance?.contractEndDate).add(1, 'day').toDate()}
                        maxDate={
                            lead?.finance?.maximumContractExtensionDate &&
                            dayjs(lead?.finance?.maximumContractExtensionDate).toDate()
                        }
                        value={selectedDate}
                    />
                    {shouldDisplayNotice ? (
                        <InformationNotice>
                            <FormattedHTMLMessage
                                id="CUSTOMER.MODAL.CONTRACT_EXTENSION.NOTICE"
                                values={{
                                    contractEndDate: dayjs(lead?.finance?.contractEndDate)
                                        .add(1, 'day')
                                        .format('DD/MM/YYYY'),
                                    maxContractEndDate: dayjs(lead?.finance?.maximumContractExtensionDate).format(
                                        'DD/MM/YYYY',
                                    ),
                                }}
                            />
                        </InformationNotice>
                    ) : null}
                </Form.Group>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        <FormattedHTMLMessage id="TRANSLATOR.CANCEL" />
                    </Button>
                    <Button variant="primary" disabled={!selectedDate} onClick={onSubmit}>
                        <FormattedHTMLMessage id="TRANSLATOR.VALIDATE" />
                    </Button>
                </div>
            }
        />
    );
};

export default ContractExtension;
