import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import { EventDetailCommercialProposalType } from '@app/crud/customers/customer.type';

import Price from '@app/components/formatters/price/Price';
import { Col, Row } from 'react-bootstrap';
import CommercialProposalStatus from '../LifeDatatable/LifeDateTableRender/StatusTemplate/CommercialProposalStatus';

type DetailCommercialProposalOfferProps = {
    event: EventDetailCommercialProposalType;
};

const DetailCommercialProposalOffer = ({ event }: DetailCommercialProposalOfferProps) => {
    const { commercialProposal } = event;

    let upsalesTotal = 0;

    commercialProposal?.upsales?.map((upsale) => {
        const v = parseFloat(upsale.value);

        if (!isNaN(v)) {
            upsalesTotal += v;
        }
    });

    return (
        <>
            <div className="d-flex">
                <div className="flex-fill">
                    <Row className="mb-2 align-items-start">
                        <Col xs={5}>
                            <span className="kt-shape-font-color-4">
                                <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.OFFER.ID" />
                            </span>
                        </Col>
                        <Col xs={7}>
                            <span className={`font-weight-bold`}>{commercialProposal?.id || '-'}</span>
                            <a
                                href={`https://www.pvo2.fr/unitedsheetorder/offer/pga_${commercialProposal?.id}`}
                                target="_blank"
                                className="ml-1"
                            >
                                <u>
                                    <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.OFFER.LINK" />
                                </u>
                            </a>
                        </Col>
                    </Row>
                    <Row className="mb-2 align-items-start">
                        <Col xs={5}>
                            <span className="kt-shape-font-color-4">
                                <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.OFFER.NUMBER" />
                            </span>
                        </Col>
                        <Col xs={7}>
                            <span className={`font-weight-bold`}>{commercialProposal?.number || '-'}</span>
                        </Col>
                    </Row>
                    <Row className="mb-2 align-items-start">
                        <Col xs={5}>
                            <span className="kt-shape-font-color-4">
                                <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.OFFER.VALIDITY" />
                            </span>
                        </Col>
                        <Col xs={7}>
                            <span className={`font-weight-bold`}>
                                <FormattedHTMLMessage
                                    id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.OFFER.VALIDITY.TEMPLATE"
                                    values={{
                                        from: commercialProposal?.startDate?.format('DD/MM/YY'),
                                        to: commercialProposal?.endDate?.format('DD/MM/YY'),
                                    }}
                                />
                            </span>
                        </Col>
                    </Row>
                    <Row className="mb-2 align-items-start">
                        <Col xs={5}>
                            <span className="kt-shape-font-color-4">
                                <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.OFFER.STATUS" />
                            </span>
                        </Col>
                        <Col xs={7}>
                            <CommercialProposalStatus commercialProposal={commercialProposal} />
                        </Col>
                    </Row>
                    <Row className="mb-2 align-items-start">
                        <Col xs={5}>
                            <span className="kt-shape-font-color-4">
                                <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.OFFER.PRICE" />
                            </span>
                        </Col>
                        <Col xs={7}>
                            <span className={`font-weight-bold`}>
                                <Price value={commercialProposal?.price ?? '-'} />
                            </span>
                        </Col>
                    </Row>
                </div>
                <div className="flex-fill">
                    <Row className="mb-2 align-items-start">
                        <Col xs={5}>
                            <span className="kt-shape-font-color-4">
                                <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.OFFER.USER" />
                            </span>
                        </Col>
                        <Col xs={7}>
                            <span className={`font-weight-bold`}>{commercialProposal?.affectedUserLabel || '-'}</span>
                        </Col>
                    </Row>
                    <Row className="mb-2 align-items-start">
                        <Col xs={5}>
                            <span className="kt-shape-font-color-4">
                                <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.OFFER.COMMENT" />
                            </span>
                        </Col>
                        <Col xs={7}>
                            <span className={`font-weight-bold`}>{commercialProposal?.comment || '-'}</span>
                        </Col>
                    </Row>
                    {commercialProposal?.acceptanceDate && (
                        <Row className="mb-2 align-items-start">
                            <Col xs={5}>
                                <span className="kt-shape-font-color-4">
                                    <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.OFFER.ACCEPTED_AT" />
                                </span>
                            </Col>
                            <Col xs={7}>
                                <span className={`font-weight-bold`}>
                                    {commercialProposal?.acceptanceDate?.format('DD/MM/YY') || '-'}
                                </span>
                            </Col>
                        </Row>
                    )}
                    {commercialProposal?.cancellationDate && (
                        <Row className="mb-2 align-items-start">
                            <Col xs={5}>
                                <span className="kt-shape-font-color-4">
                                    <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.OFFER.CANCELED_AT" />
                                </span>
                            </Col>
                            <Col xs={7}>
                                <span className={`font-weight-bold`}>
                                    {commercialProposal?.cancellationDate?.format('DD/MM/YY') || '-'}
                                </span>
                            </Col>
                        </Row>
                    )}
                    {commercialProposal?.cancellationCause && (
                        <Row className="mb-2 align-items-start">
                            <Col xs={5}>
                                <span className="kt-shape-font-color-4">
                                    <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.OFFER.CANCELED_CAUSE" />
                                </span>
                            </Col>
                            <Col xs={7}>
                                <span className={`font-weight-bold`}>
                                    <FormattedHTMLMessage
                                        id={`CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.OFFER.CANCELED_CAUSE.${commercialProposal?.cancellationCause}`}
                                    />
                                </span>
                            </Col>
                        </Row>
                    )}
                </div>
                <div className="flex-fill">
                    {upsalesTotal > 0 && (
                        <>
                            <Row className="mb-2 align-items-start">
                                <Col xs={5}>
                                    <span className="kt-shape-font-color-4">
                                        <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.OFFER.UPSALES" />
                                    </span>
                                </Col>
                                <Col xs={7}>
                                    <div className="d-flex flex-column">
                                        {commercialProposal?.upsales?.map((upsale) => (
                                            <span className={`font-weight-bold`}>
                                                {upsale.label} (<Price value={upsale.value} />)
                                            </span>
                                        )) || '-'}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-2 align-items-start">
                                <Col xs={5}>
                                    <span className="kt-shape-font-color-4">
                                        <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.OFFER.UPSALES.TOTAL" />
                                    </span>
                                </Col>
                                <Col xs={7}>
                                    <span className={`font-weight-bold`}>
                                        <Price value={upsalesTotal} />
                                    </span>
                                </Col>
                            </Row>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default DetailCommercialProposalOffer;
