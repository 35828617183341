import {useEffect, useRef, useState} from "react";
import counterStore from '../store/counters/counters.store';

//This hook return -1 if no counters found
//This can be usefull if we need to remove something in translation like in this exemple :
//In progress {counter, plural, =-1 {} other {(#)}}
//We prevent the parentheses to be displayed with empty value
const useCounter = (name, triggerAtFirstLoad = true) => {
    const counters = counterStore.getState();
    const [counter, setCounter] = useState(typeof counters[name] !== 'undefined' ? counters[name] : -1);
    const firstLoad = useRef(true);

    useEffect(() => {
        counterStore.subscribe(() => {
            if ((triggerAtFirstLoad && firstLoad.current) || !firstLoad.current) {
                const counters = counterStore.getState();
                setCounter(typeof counters[name] !== 'undefined' ? counters[name] : -1);
            } else {
                firstLoad.current = false;
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCounter]);

    return counter;
};

export default useCounter;
