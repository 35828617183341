import produce from 'immer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { mergeDeep } from '@app/helpers/ObjectHelper';

import { stateIsOutToDate } from '../helpers/reducer';

const actionTypes = {
    SetFilters: 'SET_FILTERS',
    ResetAllFilters: 'RESET_ALL_FILTERS',
    ResetFilters: 'RESET_FILTERS',
};

export const datatableInitValues = {
    items: 25,
    filters: {},
    pendingFilters: {},
    sortField: undefined,
    sortOrder: 0,
    page: 1,
    start: 0,
    persisted: true,
};

const initialState = {};

export const filtersReducer = persistReducer(
    {
        storage,
        key: 'filters',
        version: localStorage.getItem('APP_VERSION'),
        migrate: (state) => Promise.resolve(stateIsOutToDate(state) ? datatableInitValues : state),
    },
    (state = initialState, action) => {
        switch (action.type) {
            case actionTypes.SetFilters:
                return produce(state, (draftState) => {
                    draftState[action.payload.list] = mergeDeep(
                        draftState[action.payload.list],
                        action.payload.filters,
                    );
                });
            case actionTypes.ResetAllFilters:
                return produce(state, (draftState) => {
                    draftState[action.payload.list] = {
                        ...datatableInitValues,
                        ...action.payload.defaultValues,
                    };
                });
            case actionTypes.ResetFilters:
                return produce(state, (draftState) => {
                    const { filters } = action.payload;

                    filters.forEach((filter) => {
                        const pendingFilters = draftState[action.payload.list]?.pendingFilters;
                        const stateFilters = draftState[action.payload.list]?.filters;

                        if (stateFilters?.[filter]) {
                            delete stateFilters[filter];
                        }
                        if (pendingFilters?.[filter]) {
                            delete pendingFilters[filter];
                        }
                    });
                });
            default:
                return state;
        }
    },
);

export const actions = {
    setFilters: (list, filters) => ({ type: actionTypes.SetFilters, payload: { list, filters } }),
    resetAllFilters: (list, defaultValues) => ({ type: actionTypes.ResetAllFilters, payload: { list, defaultValues } }),
    resetFilters: (list, filters) => ({ type: actionTypes.ResetFilters, payload: { list, filters } }),
};
