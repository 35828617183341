export default function FacetsHandler(facets) {
    const counter = {};
    const filterData = {};

    Object.entries(facets).forEach(([key, value]) => {
        // counter handler
        value.buckets?.forEach((item) => {
            if (item?.key_as_string) {
                counter[`${key}_${item.key_as_string}`] = item.doc_count;
            }
        });

        filterData[`${key}`] = value.buckets;
    });

    return {
        counter,
        filterData,
    };
}
