import { Intl } from '@src/_metronic/i18n/I18nProvider';

import {
    addAppointment,
    getAppointments,
    getAvailabilities,
    getSchedules,
    updateAppointmentById,
} from '@app/crud/schedule/schedule.crud';

import toast from '@app/partials/content/Toast';

import { actions } from './schedule.store';

export function fetchSchedules(params) {
    return (dispatch) => {
        dispatch(actions.fetchSchedules());

        getSchedules(params)
            .then((response) => {
                if (response?.result?.error) {
                    dispatch(actions.fetchSchedulesFailed({}));
                } else {
                    dispatch(actions.fetchedSchedules(response.result));
                }
            })
            .catch(() => {
                dispatch(actions.fetchSchedulesFailed({}));
            });
    };
}

export function fetchAvailabilities(userId, params) {
    return (dispatch) => {
        dispatch(actions.fetchAvailabilities());

        getAvailabilities(userId, params)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(
                        actions.fetchedAvailabilities({
                            ...response.result,
                        }),
                    );
                } else {
                    dispatch(actions.fetchAvailabilitiesFailed({}));
                }
            })
            .catch(() => {
                dispatch(actions.fetchAvailabilitiesFailed({}));
            });
    };
}

export function fetchAppointments(userId, params) {
    return (dispatch) => {
        dispatch(actions.fetchAppointments());

        getAppointments(userId, params)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.fetchedAppointments(response.result));
                } else {
                    dispatch(actions.fetchAppointmentsFailed({}));
                }
            })
            .catch(() => {
                dispatch(actions.fetchAppointmentsFailed({}));
            });
    };
}

export function createAppointment(params) {
    return (dispatch) => {
        dispatch(actions.addAppointment());
        addAppointment(params)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.addedAppointment(response.result));
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'AGENDA.APPOINTMENT.ADD.SUCCESS' }),
                    });
                } else {
                    dispatch(actions.addAppointmentFailed());
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'AGENDA.APPOINTMENT.ADD.FAILED' }),
                    });
                }
            })
            .catch(() => {
                dispatch(actions.addAppointmentFailed());
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'AGENDA.APPOINTMENT.ADD.FAILED' }),
                });
            });
    };
}

export function updateAppointment(data, actionCalendar = null) {
    return (dispatch) => {
        dispatch(actions.updateAppointment());
        updateAppointmentById(data)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.updatedAppointment(response.result.scheduleAppointment));
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'AGENDA.APPOINTMENT.UPDATE.SUCCESS' }),
                    });
                } else {
                    dispatch(actions.updateAppointmentFailed());
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'AGENDA.APPOINTMENT.UPDATE.FAILED' }),
                    });
                    if (actionCalendar) {
                        // remove drag and drop
                        actionCalendar.revert();
                    }
                }
            })
            .catch(() => {
                dispatch(actions.updateAppointmentFailed());
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'AGENDA.APPOINTMENT.UPDATE.FAILED' }),
                });
                if (actionCalendar) {
                    // remove drag and drop
                    actionCalendar.revert();
                }
            });
    };
}
