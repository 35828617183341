import { Divider } from 'primereact/divider';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import VehicleOutStock from '@app/pages/customers/Modals/VehicleOutStock';

import toast from '@app/partials/content/Toast';
import { MAX_VEHICLES } from '@app/partials/content/Vehicle/VehicleHelpers';

import Car from './Car';

const OutStockPreview = ({ vehicles, number, showVehicle, setShowVehicle }) => {
    const { setValue, getValues } = useFormContext();
    const Intl = useIntl();

    const handleSelect = (vehicle) => {
        if (number < MAX_VEHICLES) {
            setValue('vehicles', [...(getValues('vehicles') || []), vehicle]);
        } else {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CAR_SEARCH.ADD.TOO_MUCH' }),
            });
        }
    };

    const handleRemove = (data) => {
        const vehiclesToRemoveIn = [...(getValues('vehicles') || [])];
        const index = vehiclesToRemoveIn.indexOf(data);

        vehiclesToRemoveIn.splice(index, 1);

        setValue('vehicles', vehiclesToRemoveIn);
    };

    return (
        <>
            {vehicles && vehicles.length > 0 && (
                <div className="mb-5">
                    {vehicles.map((car, idx) => (
                        <div key={car.id}>
                            <Car data={car} onRemove={handleRemove} />
                            {idx + 1 !== vehicles.length && <Divider />}
                        </div>
                    ))}
                </div>
            )}
            <VehicleOutStock showModal={showVehicle} onSelect={handleSelect} setShowModal={setShowVehicle} />
        </>
    );
};

export default OutStockPreview;
