import { StatusContractSlug } from '@app/crud/securyweb/securyweb.types';

export const StatusMapper = {
    [StatusContractSlug.TO_SIGN]: { className: 'danger', labelId: 'SECURYCAR.CONTRACT.DATATABLE.STATUS.TO_SIGN' },
    [StatusContractSlug.DRAFT]: { className: 'closed', labelId: 'SECURYCAR.CONTRACT.DATATABLE.STATUS.DRAFT' },
    [StatusContractSlug.DELETED]: { className: 'closed_done', labelId: 'SECURYCAR.CONTRACT.DATATABLE.STATUS.DELETED' },
    [StatusContractSlug.VALID]: { className: 'success', labelId: 'SECURYCAR.CONTRACT.DATATABLE.STATUS.VALID' },
    [StatusContractSlug.END_VALID]: {
        className: 'closed_done',
        labelId: 'SECURYCAR.CONTRACT.DATATABLE.STATUS.END_VALID',
    },
};

export const getStatusClassName = (statusSlug: StatusContractSlug) =>
    StatusMapper[statusSlug]?.className || StatusMapper[StatusContractSlug.DRAFT].className;

export const getStatusLabelId = (statusSlug: StatusContractSlug) =>
    StatusMapper[statusSlug]?.labelId || StatusMapper[StatusContractSlug.DRAFT].labelId;
