import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { getCustomerNotes } from '@app/crud/customers/customer.crud';

import { useAppDispatch, useAppSelector, useFetch } from '@app/hooks';
import { actions } from '@app/store/modal/modal.store';

import ButtonCounter from '@app/components/forms/button-counter/ButtonCounter';

const CustomerNotes = () => {
    const dispatch = useAppDispatch();
    const Intl = useIntl();
    const { data: customer } = useAppSelector((state) => state.customer.customer);
    const { fetch, data } = useFetch({
        fetchAction: getCustomerNotes,
    });

    useEffect(() => {
        if (customer?.id) {
            fetch(customer.id);
        }
    }, [customer.id, fetch]);

    return (
        <ButtonCounter
            variant="primary"
            counter={data?.count?.total || 0}
            className="mt-3"
            onClick={() => dispatch(actions.modalChange('notes'))}
        >
            <i className="la la-edit" />
            {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.NOTES.TITLE' })}
        </ButtonCounter>
    );
};

export default CustomerNotes;
