import dayjs from 'dayjs';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { PricingEvent } from '@app/store/stock/stock.type';

import Label from '@app/partials/content/Label';

import Price from '@app/components/formatters/price/Price';

import eventStatusHandler from './helpers/EventStatusHandler';
import StockPricingToDoHistoryEventReason from './StockPricingToDoHistoryEventReason';

type StockPricingToDoHistoryEventCheckStatusProps = {
    event: PricingEvent;
};

const StockPricingToDoHistoryEventCheckStatus = ({ event }: StockPricingToDoHistoryEventCheckStatusProps) =>
    event.check ? (
        <>
            <div className="font-weight-bold">
                <span
                    className={`text-${
                        ['accepted', 'confirmed'].includes(event.check?.status.slug) ? 'success' : 'danger'
                    }`}
                >
                    • <FormattedMessage id="STOCK.PRICING.HISTORY.CONTROLLED" />
                </span>
                <FormattedMessage id="STOCK.PRICING.HISTORY.AND" />
                <Label variant={eventStatusHandler(event.check?.status.slug)} className="mx-1">
                    <strong>{event.check?.status.name}</strong>
                </Label>
                {event.check.event?.newPrice && (
                    <span className={`text-${eventStatusHandler(event.check.event?.status.slug)}`}>
                        • <Price value={event.check.event?.newPrice} />
                    </span>
                )}
            </div>
            <div className="text-primary">
                <Label variant="primary" className="font-weight-bold">
                    {event.check.author}
                </Label>{' '}
                • {event.check.role}
            </div>
            <div className="font-size-sm">
                <FormattedHTMLMessage
                    id="STOCK.PRICING.HISTORY.ACTION_DATE"
                    values={{
                        date: dayjs(event.check.date.date).format('DD/MM/YYYY [à] HH[h]mm'),
                    }}
                />
            </div>
            <StockPricingToDoHistoryEventReason
                reason={event.check.event?.reason}
                comment={event.check.event?.comment}
            />
        </>
    ) : (
        <StockPricingToDoHistoryEventReason reason={event.reason} comment={event.comment} />
    );

export default StockPricingToDoHistoryEventCheckStatus;
