import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { LeadDetailsInformations } from '@app/crud/customers/customer.type';

import { Lead } from '@app/store/customers/customers.store';

import Permission from '@app/partials/content/Permission';

export interface LeadFlag {
    icon: React.ReactElement;
    name: React.ReactElement;
    permissions?: string[];
    filterable?: boolean;
    eligible?: (lead: Lead | LeadDetailsInformations) => boolean;
}

export const LEAD_FLAGS: Record<string, LeadFlag> = {
    'transfert-a-ete-fait': {
        icon: <i className="la la-tty la-lg text-success" />,
        name: <FormattedMessage id="LEADS_ONLINE.ICONS.TRANSFERT_A_ETE_FAIT" />,
    },
    'transfert-refuse-par-le-conseiller': {
        icon: <i className="la la-tty la-lg text-warning" />,
        name: <FormattedMessage id="LEADS_ONLINE.ICONS.TRANSFERT_REFUSE_PAR_LE_CONSEILLER" />,
    },
    'transfert-a-echoue': {
        icon: <i className="la la-tty la-lg text-danger" />,
        name: <FormattedMessage id="LEADS_ONLINE.ICONS.TRANSFERT_A_ECHOUE" />,
    },
    hight_interest_level: {
        icon: <i className="la la-star-o la-lg text-warning" />,
        name: <FormattedMessage id="LEADS_ONLINE.ICONS.CERTIFICATION" />,
        permissions: ['LEAD_SHOW_CERTIFICATION'],
    },
    strong_commitment: {
        icon: <i className="la la-thumbs-up la-lg text-success" />,
        name: <FormattedMessage id="LEADS_ONLINE.ICONS.STRONG_COMMITMENT" />,
        permissions: ['LEAD_SHOW_STRONG_COMINTEMENT'],
    },
    finance_accepted: {
        icon: <i className="la la-money-bill la-lg text-success" />,
        name: <FormattedMessage id="LEADS_ONLINE.ICONS.ACCEPTED_FINANCING" />,
    },
    transfered_with_appointment: {
        icon: <i className="la la-calendar la-lg text-success" />,
        name: <FormattedMessage id="LEADS_ONLINE.ICONS.APPOINTMENT" />,
    },
    car_sale: {
        icon: <i className="la la-ban la-lg text-danger" />,
        name: <FormattedMessage id="LEADS_ONLINE.ICONS.CAR_SALE" />,
        filterable: false,
    },
    reopened: {
        icon: <i className="la la-redo-alt la-spin-once la-lg text-danger" />,
        name: <FormattedMessage id="LEADS_ONLINE.ICONS.LEAD_REOPENED" />,
        filterable: false,
    },
    is_affected_to_owner: {
        icon: <i className="las la-lg la-share text-success" />,
        name: <FormattedMessage id="LEADS_ONLINE.ICONS.TRANSFERED_VENDOR" />,
    },
    is_central: {
        icon: <i className="las la-lg la-street-view text-success" />,
        name: <FormattedMessage id="LEADS_ONLINE.ICONS.TREATED_CENTRAL" />,
    },
};

const getFlagIcon = (lead: Lead | LeadDetailsInformations, slug: string, params: LeadFlag) => {
    const eligible = params?.eligible?.(lead) ?? false;

    if (lead?.flags?.includes?.(slug) || eligible) {
        return [
            <Permission permissions={params?.permissions || []}>
                <OverlayTrigger placement="right" overlay={<Tooltip id={`flag_${slug}`}>{params.name}</Tooltip>}>
                    {params.icon}
                </OverlayTrigger>
            </Permission>,
        ];
    }

    return [];
};

/**
 * Get all flags icon available for one lead
 *
 * @param lead
 * @returns {[string, ({permissions: string[], icon: JSX.Element, name: JSX.Element} | {icon: JSX.Element, name: JSX.Element})]}
 */
export const getLeadFlagsIcon = (lead: Lead) =>
    Object.entries(LEAD_FLAGS).reduce((acc, [slug, params]) => {
        // eslint-disable-next-line no-param-reassign
        acc = [...acc, getFlagIcon(lead, slug, params)];

        return acc;
    }, []);

/**
 * Get specific flags icon available for one lead
 *
 * @param lead
 * @param flags
 * @returns {*}
 */
export const getFlagsIcon = (lead: Lead | LeadDetailsInformations, flags: string[]) =>
    flags.reduce((acc, flag) => {
        const params = LEAD_FLAGS[flag];

        // eslint-disable-next-line no-param-reassign
        acc = [...acc, getFlagIcon(lead, flag, params)];

        return acc;
    }, []);
