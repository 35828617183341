import classNames from 'classnames';
import dayjs from 'dayjs';
import { Divider } from 'primereact/divider';
import React, { useContext } from 'react';
import { Image } from 'react-bootstrap';

import { capitalize } from '@app/helpers/StringHelper';

import { useAppSelector } from '@app/hooks';

import { DashboardContext } from '../DashboardProvider';

type Pictures = {
    [key: string]: string;
};

const DashboardUser = ({ className }: { className?: string }) => {
    const { role } = useContext(DashboardContext);
    const today = dayjs();
    const { userData } = useAppSelector((state) => state.auth);

    const getPicture = () => {
        const pictures: Pictures = {
            manager: '/media/pages/dashboard/dashboard-bonjour-manager.svg',
            'conseiller-commercial': '/media/pages/dashboard/dashboard-bonjour-vendeur.svg',
            'conseiller-achat-cash-concession': '/media/pages/dashboard/dashboard-bonjour-vendeur.svg',
            'conseiller-achat-cash-distance': '/media/pages/dashboard/dashboard-bonjour-vendeur.svg',
            directeur: '/media/pages/dashboard/dashboard-bonjour-directeur.svg',
            'responsable-marketing': '/media/pages/dashboard/dashboard-bonjour-responsable-marketing.svg',
            'responsable-apv': '/media/pages/dashboard/dashboard-bonjour-responsable-apv.svg',
            'ccs-apv': '/media/pages/dashboard/dashboard-bonjour-ccs-apv.svg',
            'manager-autosphere-contact': '/media/pages/dashboard/dashboard-bonjour-manager-autosphere-contact.svg',
            'agent-autosphere-contact': '/media/pages/dashboard/dashboard-bonjour-agent-autosphere-contact.svg',
            'agent-fid': '/media/pages/dashboard/dashboard-bonjour-fidelisation.svg',
        };

        return pictures[role];
    };

    return (
        <div className={classNames(`dashboard-user dashboard-user--${role}`, className)}>
            <Image className="dashboard-user__picture" src={getPicture()} />
            <div className="d-flex h-100 text-center align-items-center px-10">
                <div className="w-100">
                    {capitalize(today.format('dddd'))}
                    <span className="font-weight-semibold">{today.format('[ ] DD MMMM')}</span>
                    {today.format('[ ] YYYY')}
                </div>
            </div>
            <Divider className="dashboard-user__divider w-auto p-0 px-xxl-5 h-100" layout="vertical" type="dashed" />
            <div className="dashboard-user__name d-flex flex-column justify-content-center font-weight-semibold flex-grow-1 h-100">
                <div>Bonjour {userData?.first_name} !</div>
                <div>Profil&nbsp;: {userData?.role?.name}</div>
            </div>
        </div>
    );
};

DashboardUser.defaultProps = {
    className: '',
};

export default DashboardUser;
