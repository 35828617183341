import store from '../../../../store/store';

const userData = () => store?.getState?.()?.auth?.userData;

export interface DealershipTreeSelectNode {
    checked: boolean;
    children: DealershipTreeSelectNode[];
    codeType: number;
    expanded: boolean;
    id: string;
    label: string;
    path: string;
    tagLabel: string;
    title: string;
    type: string;
    value: string;
    codePvo?: string;
}

/**
 * Get path of treeselect node
 * @param needle
 * @returns {*}
 */
export const getPath = (needle: { path: string }) => needle?.path?.split?.(/[.[\]]/).filter((item) => !!item);

/**
 * Tree selected nodes to dealership saved in bdd
 * @param selectedNodes
 * @returns {*}
 */
export const mapDealershipNodes = (selectedNodes: DealershipTreeSelectNode[]) =>
    selectedNodes.map((selectedNode) => ({
        type: selectedNode.type,
        code: selectedNode.id,
        name: selectedNode.title,
    }));

/**
 * Get perimeter node by node path
 * @param perimeter
 * @param path
 */
export const getPerimeterNode = (perimeter: DealershipTreeSelectNode[], path: string) => {
    const explodedPath = getPath({ path });
    return explodedPath.reduce<DealershipTreeSelectNode>(
        (acc, val) =>
            // @ts-ignore
            acc[val],
        // @ts-ignore
        perimeter as PerimeterNode,
    );
};

/**
 * Update dealerships perimeter
 *
 * @param datas
 * @param stack
 * @param checked
 * @param filters
 * @param page
 * @returns {unknown[]}
 */
export const updateDatas = (
    datas: DealershipTreeSelectNode[],
    stack = '',
    checked: boolean = null,
    filters: string[] = [],
    page = '',
): DealershipTreeSelectNode[] =>
    Object.values(datas).map((data, idx) => {
        const path = stack ? `${stack}.children[${idx}]` : `[${idx}]`;
        const isChecked = checked || filters?.includes?.(data?.id) || data?.checked;

        let children = [] as DealershipTreeSelectNode[];

        if (data?.children) {
            children = updateDatas(data.children, path, isChecked, filters, page);
        }

        return {
            ...data,
            path,
            ...(children ? { children } : {}),
            tagLabel: data.title,
            label: `${data?.title} - ${data?.id}${data?.codePvo ? ` - ${data?.codePvo}` : ''}`,
            value: data.id,
            checked: isChecked,
            expanded: children.some((child: DealershipTreeSelectNode) => child?.checked || child?.expanded),
        };
    });

/**
 * Get dealership in updated perimeter by refDealershipId
 */
export const getPerimeterDealership = (datas: DealershipTreeSelectNode[], refDealershipId: string) => {
    let dealership: DealershipTreeSelectNode;

    Object.values(datas).forEach((data) => {
        if (dealership) {
            return false;
        }

        if (data?.value === refDealershipId) {
            dealership = data;
        }

        if (data?.children) {
            const children = getPerimeterDealership(data.children, refDealershipId);

            if (children) {
                dealership = children;
            }
        }
    });

    return dealership;
};

/**
 * Set checked default dealership
 * @param children
 * @param defaultDealership
 * @returns
 */
export function setDefaultDealership(
    children: DealershipTreeSelectNode[],
    defaultDealership: string,
): DealershipTreeSelectNode {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of children) {
        if (item.id === defaultDealership) {
            item.checked = true;
            return item;
        }
        if (item.children) {
            const found = setDefaultDealership(item.children, defaultDealership);
            if (found) {
                return found;
            }
        }
    }
    return null;
}

/**
 * Get checked perimeter with groups
 *
 * @param nodes
 * @param selectedNodes
 * @returns {*}
 */
export const getCheckedGroups = (nodes: DealershipTreeSelectNode[], selectedNodes: DealershipTreeSelectNode[] = []) => {
    nodes?.forEach?.((node) => {
        selectedNodes.push(node);
        if (node?.children?.length > 0) {
            // eslint-disable-next-line no-param-reassign
            selectedNodes = [...selectedNodes, ...getCheckedGroups(node.children)];
        }
    });

    return selectedNodes;
};

/**
 * Set checked selected dealerships
 * @param children
 * @param selectedDealerships
 * @returns
 */
export function setPerimeterSelectedDealership(
    children: DealershipTreeSelectNode[],
    selectedDealerships: DealershipTreeSelectNode[],
): null {
    if (!Array.isArray(selectedDealerships)) return;
    const selectedDealershipIds = getCheckedGroups(selectedDealerships).map((dealership) => dealership?.id);
    // eslint-disable-next-line no-restricted-syntax
    for (const item of children) {
        if (selectedDealershipIds.includes(item.id)) {
            item.checked = true;
        }
        if (item.children) {
            setPerimeterSelectedDealership(item.children, selectedDealerships);
        }
    }
    return null;
}

/**
 * Get checked perimeter
 *
 * @param nodes
 * @param selectedNodes
 * @returns {*}
 */
export const getCheckedPerimeters = (
    nodes: DealershipTreeSelectNode[],
    selectedNodes: DealershipTreeSelectNode[] = [],
) => {
    nodes?.forEach?.((node) => {
        if (node?.checked) {
            selectedNodes?.push?.(node);
        } else if (node?.children) {
            // eslint-disable-next-line no-param-reassign
            selectedNodes = [...selectedNodes, ...getCheckedPerimeters(node.children)];
        }
    });

    return selectedNodes;
};

/**
 * Get checked perimeter
 *
 * @param nodes
 * @param selectedNodes
 * @returns {*}
 */
export const getCheckedDealerships = (
    nodes: DealershipTreeSelectNode[],
    selectedNodes: DealershipTreeSelectNode[] = [],
) => {
    nodes?.forEach?.((node) => {
        if (node?.children?.length > 0) {
            // eslint-disable-next-line no-param-reassign
            selectedNodes = [...selectedNodes, ...getCheckedDealerships(node.children)];
        } else {
            selectedNodes.push(node);
        }
    });

    return selectedNodes;
};

/** *
 * Remove dealership from perimeter and return new perimeter
 *
 * @param perimeter
 * @param node
 * @return Array new perimeter
 */
export const removeDealershipFromPerimeter = (
    perimeter: DealershipTreeSelectNode[],
    node: DealershipTreeSelectNode,
) => {
    const path = getPath(node);

    path?.reduce?.((acc: DealershipTreeSelectNode[] | DealershipTreeSelectNode, val) => {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const node = acc[val];

        if (node?.checked) {
            node.checked = false;
        }

        // @ts-ignore
        return acc[val];
    }, perimeter);

    return getCheckedPerimeters(updateDatas(perimeter));
};

/**
 * Add dealership from perimeter and return new perimeter
 *
 * @param perimeter
 * @param node
 * @returns {*}
 */
export const addDealershipFromPerimeter = (perimeter: DealershipTreeSelectNode[], node: DealershipTreeSelectNode) => {
    const path = getPath(node);

    path?.reduce?.((acc: DealershipTreeSelectNode[] | DealershipTreeSelectNode, val, idx) => {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const node = acc[val];

        // eslint-disable-next-line no-unsafe-optional-chaining
        if (path?.length - 1 === idx) {
            node.checked = true;
        }

        // @ts-ignore
        return acc[val];
    }, perimeter);

    return getCheckedPerimeters(updateDatas(perimeter));
};

/**
 * Get user perimeter
 *
 * @param nodes
 * @param selectedNodes
 */
export const getUserPerimeter = (nodes: DealershipTreeSelectNode[], selectedNodes: DealershipTreeSelectNode[] = []) => {
    const user = userData();

    nodes?.forEach?.((node) => {
        if (user?.dealership_perimeter.some((user_perimeter) => user_perimeter?.code === node?.id)) {
            selectedNodes.push(node);
        } else if (node?.children) {
            // eslint-disable-next-line no-param-reassign
            selectedNodes = [...selectedNodes, ...getUserPerimeter(node.children)];
        }
    });

    return selectedNodes;
};
