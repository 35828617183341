import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import RowInfo from '@app/partials/content/RowInfo';

const AFRPartExchangeDetail = ({ showModal, setShowModal }) => {
    const [activeTab, setActiveTab] = useState('info');
    const { params } = useSelector((state) => state.modal);
    const [afrPartExchange, setAfrPartExchange] = useState(null);
    const Intl = useIntl();

    useEffect(() => {
        if (params?.partExchange) {
            setAfrPartExchange(params.partExchange);
        }
    }, [params]);

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={handleClose}
            size="lg"
            icon={<i className="la la-2x text-primary la-car" />}
            title={Intl.formatMessage({ id: `CUSTOMERS.MODAL.PART_EXCHANGE_AFR.TITLE` })}
            body={
                <Tabs
                    className="nav-tabs-line nav-tabs-line-primary nav-tabs-line-2x detail-tabs"
                    defaultActiveKey={activeTab}
                    activeKey={activeTab}
                    id={activeTab}
                    onSelect={(e) => setActiveTab(e)}
                >
                    <Tab
                        eventKey="info"
                        title={Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.RECOVERY.AFR_DETAIL' })}
                    >
                        <div className="d-flex">
                            {afrPartExchange && (
                                <>
                                    <img
                                        src={afrPartExchange?.image}
                                        alt={`${afrPartExchange?.make} ${afrPartExchange?.model}`}
                                        style={{ width: '350px', height: 'max-content' }}
                                    />
                                    <div className="d-flex flex-column flex-grow-1">
                                        <RowInfo
                                            label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.BRAND' })}
                                            value={afrPartExchange?.make || '-'}
                                        />
                                        <RowInfo
                                            label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.MODEL' })}
                                            value={afrPartExchange?.model || '-'}
                                        />
                                        <RowInfo
                                            label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.FUEL' })}
                                            value={afrPartExchange?.fuelType || '-'}
                                        />
                                        <RowInfo
                                            label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.GEARBOX' })}
                                            value={afrPartExchange?.gearbox || '-'}
                                        />
                                        <RowInfo
                                            label={Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.HORSEPOWER',
                                            })}
                                            value={afrPartExchange?.fiscalHorsepower || '-'}
                                        />
                                        <RowInfo
                                            label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.MILEAGE' })}
                                            value={
                                                afrPartExchange?.mileage ? (
                                                    <NumberFormat
                                                        value={afrPartExchange?.mileage}
                                                        thousandSeparator=" "
                                                        displayType="text"
                                                        suffix=" km"
                                                    />
                                                ) : (
                                                    '-'
                                                )
                                            }
                                        />
                                        <RowInfo
                                            label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.COLOR' })}
                                            value={afrPartExchange?.color || '-'}
                                        />
                                        <RowInfo
                                            label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.MEC' })}
                                            value={
                                                afrPartExchange?.dateFirstRegistration
                                                    ? dayjs(afrPartExchange?.dateFirstRegistration).format('MM/YYYY')
                                                    : '-'
                                            }
                                        />
                                        <RowInfo
                                            label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.NB_DOOR' })}
                                            value={afrPartExchange?.numberDoor || '-'}
                                        />
                                        <RowInfo
                                            label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.NB_PLACE' })}
                                            value={afrPartExchange?.numberPlaces || '-'}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </Tab>
                </Tabs>
            }
            footer={
                <div className="m-auto">
                    <Button variant="primary" onClick={handleClose}>
                        {Intl.formatMessage({ id: `TRANSLATOR.CLOSE` })}
                    </Button>
                </div>
            }
        />
    );
};

export default AFRPartExchangeDetail;
