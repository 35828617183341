import { DevTool } from '@hookform/devtools';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import { getDateByFormat } from '@app/helpers/DateHelper';

import { createOrUpdateAlert } from '@app/crud/administration/alerts';

import { useAppSelector } from '@app/hooks';
import {
    AlertCreatePayload,
    AlertDocumentPayload,
    AlertLevel,
    AlertUpdatePayload,
    TempDocument,
} from '@app/pages/administration/alerts/alerts.type';
import { alertSeverityColors } from '@app/pages/administration/alerts/helpers/alerts.helper';

import { DateTimePicker } from '@app/partials/content/DateTimePicker';
import Editor from '@app/partials/content/Editor';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

import { MultipleUploadsField } from '@app/components/forms/fields/uploads';

type AlertModalProps = {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    alertLevels: AlertLevel[];
    refresh: () => void;
};

const AlertModal = ({ showModal, setShowModal, alertLevels, refresh }: AlertModalProps) => {
    const { params } = useAppSelector((state) => state.modal);
    const refEditor = useRef();

    const Intl = useIntl();
    const methods = useForm();
    const { register, errors, handleSubmit, control, setValue } = methods;

    const onSubmit = (data: AlertCreatePayload | AlertUpdatePayload) => {
        data.alertLevelId = parseInt(data.alertLevelId as unknown as string, 10);
        if (data.documents === undefined) {
            data.documents = [];
        } else {
            data.documents?.map((item: AlertDocumentPayload & TempDocument) => {
                // if file already exists
                if (item.fileId) {
                    item.id = parseInt(item.fileId, 10);
                    if (item.content) delete item.content;
                    delete item.contentType;
                    delete item.name;
                    delete item.type;
                    delete item.fileId;
                }
                return item;
            });
        }
        createOrUpdateAlert(data, params?.id || null)
            .then(() => {
                toast({
                    variant: 'success',
                    message: params?.id
                        ? Intl.formatMessage({ id: 'ADMINISTRATION.ALERTS.MODAL.UPDATE.SUCCESS' })
                        : Intl.formatMessage({ id: 'ADMINISTRATION.ALERTS.MODAL.ADD.SUCCESS' }),
                });
                setShowModal(false);
                refresh();
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: params?.id
                        ? Intl.formatMessage({ id: 'ADMINISTRATION.ALERTS.MODAL.UPDATE.ERROR' })
                        : Intl.formatMessage({ id: 'ADMINISTRATION.ALERTS.MODAL.ADD.ERROR' }),
                });
            });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-bell" />}
            title={Intl.formatMessage({
                id: params?.id ? 'ADMINISTRATION.ALERTS.MODAL.EDIT.TITLE' : 'ADMINISTRATION.ALERTS.MODAL.ADD.TITLE',
            })}
            body={
                <>
                    <FormProvider {...methods}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="ADMINISTRATION.ALERTS.ALERT_LEVEL" />
                            </Form.Label>
                            <Form.Control
                                as="select"
                                name="alertLevelId"
                                defaultValue={params?.alertLevel.id || ''}
                                ref={register({
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                })}
                            >
                                {alertLevels?.map((item) => (
                                    <option
                                        key={item.id}
                                        value={item.id}
                                        className={`text-${alertSeverityColors[item.slug]}`}
                                    >
                                        {item.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mt-5">
                                    <Form.Label>
                                        <FormattedMessage id="ADMINISTRATION.ALERTS.START_DATE" />* :
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="dateStart"
                                        value={params?.dateStart || ''}
                                        defaultValue={params?.dateStart || ''}
                                        rules={{
                                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                        }}
                                        render={({ onChange, value }) => (
                                            <DateTimePicker
                                                locale={Locale}
                                                dateFormat="DD/MM/YYYY HH:mm"
                                                showTime
                                                onChange={(e: { value: string }) => {
                                                    const startValue = dayjs(e.value).isValid() ? dayjs(e.value) : '';

                                                    if (startValue !== '') {
                                                        setValue('dateEnd', startValue.format('YYYY-MM-DD HH:mm'));
                                                        onChange(startValue.format('YYYY-MM-DD HH:mm'));
                                                    } else {
                                                        setValue('dateEnd', '');
                                                        onChange('');
                                                    }
                                                }}
                                                value={getDateByFormat(value, 'YYYY-MM-DD HH:mm', '')}
                                            />
                                        )}
                                    />
                                    <ErrorForm errors={errors} name="dateStart" />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mt-5">
                                    <Form.Label>
                                        <FormattedMessage id="ADMINISTRATION.ALERTS.END_DATE" />* :
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="dateEnd"
                                        value={params?.dateEnd || ''}
                                        defaultValue={params?.dateEnd || ''}
                                        rules={{
                                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                        }}
                                        render={({ onChange, value }) => (
                                            <DateTimePicker
                                                locale={Locale}
                                                dateFormat="DD/MM/YYYY HH:mm"
                                                showTime
                                                onChange={(e: { value: string }) => {
                                                    const startValue = dayjs(e.value).isValid() ? dayjs(e.value) : '';

                                                    if (startValue !== '') {
                                                        setValue('dateEnd', startValue.format('YYYY-MM-DD HH:mm'));
                                                        onChange(startValue.format('YYYY-MM-DD HH:mm'));
                                                    } else {
                                                        setValue('dateEnd', '');
                                                        onChange('');
                                                    }
                                                }}
                                                value={getDateByFormat(value, 'YYYY-MM-DD HH:mm', '')}
                                            />
                                        )}
                                    />
                                    <ErrorForm errors={errors} name="dateEnd" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="ADMINISTRATION.ALERTS.TITLE" />* :
                            </Form.Label>
                            <Form.Control
                                name="title"
                                ref={register({
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                })}
                                placeholder={Intl.formatMessage({ id: 'ADMINISTRATION.ALERTS.TITLE' })}
                                defaultValue={params?.title || ''}
                            />
                            <ErrorForm errors={errors} name="title" />
                        </Form.Group>
                        <MultipleUploadsField values={params?.alertDocuments} />
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="ADMINISTRATION.ALERTS.CONTENT" />* :
                            </Form.Label>
                            <Controller
                                control={control}
                                name="content"
                                value={params?.content || ''}
                                defaultValue={params?.content || ''}
                                rules={{
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                render={({ onChange, value }) => (
                                    <Editor
                                        ref={refEditor}
                                        // @ts-ignore
                                        value={value}
                                        onChange={onChange}
                                        config={{
                                            minHeight: 200,
                                            allowResizeY: true,
                                            buttons: ['bold', 'link'],
                                        }}
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name="value" />
                        </Form.Group>
                    </FormProvider>
                    {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
                </>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(onSubmit, onError)}>
                        {Intl.formatMessage({ id: params?.id ? 'TRANSLATOR.UPDATE' : 'TRANSLATOR.CREATE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default AlertModal;
