import classNames from 'classnames';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useFadeOut from '@app/hooks/useFadeOut';

import { mapCustomerFieldSelection, mapVcuCustomer } from '@app/pages/customers/Helpers/Customer';
import { createCustomer } from '@app/store/customers/customers.thunk';
import { actions, actions as actionsModal } from '@app/store/modal/modal.store';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

import AlreadyExistUserRow from './AlreadyExistUserRow';

import './CreateAlreadyExist.scss';

const CreateAlreadyExist = ({ showModal, setShowModal }) => {
    const { params } = useSelector((state) => state.modal);
    const { loading } = useSelector((state) => state.customer.customer);
    const dispatch = useDispatch();
    const history = useHistory();
    const methods = useForm();
    const Intl = useIntl();
    const [selectedContact, setSelectedContact] = useState(null);
    const { onBottom, handleScroll, fadeOutRef, refresh } = useFadeOut();
    const { register, handleSubmit } = methods;

    const createExistingUser = (contact) => {
        dispatch(
            createCustomer({
                data: {
                    ...mapVcuCustomer({
                        ...contact,
                        isClient: false,
                    }),
                },
                history,
            }),
        );
    };

    const onSubmitComplete = () => {
        if (params?.onCustomerCreate) {
            params.onCustomerCreate(selectedContact);
            return;
        }

        const new_customer = params.customer;
        const existing_customer = selectedContact;

        dispatch(
            actionsModal.modalChange('rollback_update', {
                customerFieldChange: mapCustomerFieldSelection(new_customer, existing_customer),
                dataInterceptor: (data) => {
                    const selectedValues = Object.entries(data).reduce((acc, [key, value]) => {
                        const splittedKey = key.split(/_(?=[^_]+$)/);
                        const property = splittedKey.length > 0 ? splittedKey[0] : undefined;

                        acc[property] = value ? existing_customer[property] : new_customer[property];

                        return acc;
                    }, {});

                    createExistingUser({
                        ...existing_customer,
                        ...selectedValues,
                    });
                },
            }),
        );
    };

    const onSubmitNew = () => {
        if (params?.onCustomerCreate) {
            params.onCustomerCreate({
                ...params.customer,
                isClient: false,
            });
            return;
        }

        if (params?.customer) {
            dispatch(
                createCustomer({
                    data: mapVcuCustomer({
                        ...params.customer,
                        isClient: false,
                    }),
                    history,
                }),
            );
        } else {
            dispatch(actions.modalChange('create', params));
        }
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    useEffect(() => {
        setSelectedContact(null);
        refresh(fadeOutRef?.current);
    }, [showModal]);

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-server" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE_ALREADY_EXIST.TITLE' })}
            body={
                <div className="text-center">
                    <FormattedHTMLMessage id="CUSTOMERS.MODAL.CREATE_ALREADY_EXIST.SUBTITLE" />
                    {!onBottom && <div className="already-exists__fadeout" />}
                    <Row className="mx-2 mt-10">
                        <Col lg={4}>
                            <div className="already-exists__reconciled mb-lg-0 mb-6">
                                <div className="font-weight-bold text-center">
                                    {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE_ALREADY_EXIST.RECONCILED' })}
                                </div>
                                <Divider className="my-2 mb-4 already-exists__divider" />
                                <AlreadyExistUserRow customer={params?.customer} />
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="mb-5 pr-5 already-exists" ref={fadeOutRef} onScroll={handleScroll}>
                                {params?.customers &&
                                    params.customers.map((customer, idx) => (
                                        <Form.Check
                                            type="radio"
                                            className={classNames('already-exists__row', {
                                                'mb-4': idx + 1 < params.customers.length,
                                                'already-exists__row--active': selectedContact === customer,
                                            })}
                                            ref={register}
                                            name="vcuId"
                                            label={
                                                <div className="ml-4 text-left">
                                                    <AlreadyExistUserRow customer={customer} />
                                                </div>
                                            }
                                            onClick={() => {
                                                setSelectedContact(customer);
                                            }}
                                            id={`contact_${idx}`}
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={`contact_${idx}`}
                                            value={`contact_${idx}`}
                                        />
                                    ))}
                            </div>
                        </Col>
                    </Row>
                </div>
            }
            footer={
                <div className="text-center w-100">
                    <Button
                        variant="outline-secondary"
                        className="mr-lg-5 mr-0 mb-lg-0 mb-5"
                        style={{ width: '250px' }}
                        onClick={handleSubmit(onSubmitNew, onError)}
                    >
                        {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE_ALREADY_EXIST.CREATE' })}
                    </Button>
                    <Button
                        variant="primary"
                        style={{ width: '250px' }}
                        type="submit"
                        disabled={!selectedContact}
                        onClick={handleSubmit(onSubmitComplete, onError)}
                    >
                        <FormattedMessage id="CUSTOMERS.MODAL.CREATE_ALREADY_EXIST.SELECT" />
                    </Button>
                </div>
            }
        />
    );
};

export default CreateAlreadyExist;
