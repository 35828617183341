/**
 * This component, `product`, is a collection of functions designed to render
 * different types of products associated with various customer events. Each function
 * within `productTemplate` corresponds to a specific event type and returns JSX elements for rendering.
 */

import React from 'react';

import { CustomerEvent } from '@app/crud/customers/customer.type';

import { warrantyType } from '../../../EventDetailCirano/DetailCirano';

interface ProductTemplateFunctions {
    [key: string]: (event: CustomerEvent) => JSX.Element | string;
}

const useProductTemplate = () => {
    // Here we create a default template for multiple events to avoid repeating the same template
    const warrantyTypeRender = (typeGite?: string): JSX.Element =>
        warrantyType(typeGite) && (
            <>
                ({warrantyType(typeGite)})<br />
            </>
        );

    const securycarRender = (event: CustomerEvent): JSX.Element => (
        <>
            <b>
                {event?.scServiceResumeProductLabel ||
                    event?.scDisasterProductLabel ||
                    event?.scRenewalProductLabel ||
                    event?.scTerminationProductLabel ||
                    '-'}
            </b>
            <br />
        </>
    );

    const bcsAuditRender = (event: CustomerEvent): JSX.Element => (
        <>
            <b>{event?.auditNameModelFront || ''}</b>
            <br />
        </>
    );

    const saleProductRender = (event: CustomerEvent): JSX.Element => (
        <>
            <b>{event?.processCategory || '-'}</b>
            <br />
        </>
    );

    const productTemplate: ProductTemplateFunctions = {
        DATASTORE_FUNDING: (event: CustomerEvent) => {
            const { fundingProductType, processCategory } = event || {};
            return (
                <>
                    <b>
                        {event?.fundingProductType && event?.processCategory
                            ? `${fundingProductType} - ${processCategory}`
                            : '-'}
                    </b>
                    <br />
                </>
            );
        },
        DCSNET_SALE_PRODUCT: (event) => saleProductRender(event),
        ICAR_SALE_PRODUCT: (event) => saleProductRender(event),
        SECURYCAR_SC_TERMINATION: (event) => securycarRender(event),
        SECURYCAR_SC_SERVICE_RESUME: (event) => securycarRender(event),
        SECURYCAR_SC_RENEWAL: (event) => securycarRender(event),
        SECURYCAR_SC_SERVICE_FOLLOWUP: (event) => securycarRender(event),
        SECURYCAR_SC_DISASTER: (event) => securycarRender(event),
        ICARE_MAINTENANCE_CONTRACT: (event) => (
            <>
                <b>{event?.maintenanceContractNomCommercialFormule || '-'}</b>
                <br />
            </>
        ),

        BCS_AUDIT_FOLLOWUP: (event) => bcsAuditRender(event),
        BCS_AUDIT_RESUME_FB: (event) => bcsAuditRender(event),
        BCS_AUDIT_RESUME_OTHER: (event) => bcsAuditRender(event),
        CIRANO_WARRANTY_CONTRACT: (event) => (
            <>
                <b>{event?.libProduct}</b>
                <br />
                {warrantyTypeRender(event?.typeGite)}
            </>
        ),
        OPTEVEN_WARRANTY_CONTRACT_OPTEVEN: (event) => <b>{event?.program}</b>,
    };

    const getProductTemplate = (event: CustomerEvent) => {
        const eventType = `${event?.srcApplicationName}_${event?.processType}`;
        const templateFunction = productTemplate[eventType] || (() => '');

        return templateFunction(event);
    };

    return getProductTemplate;
};

export default useProductTemplate;
