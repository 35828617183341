import Swal from 'sweetalert2';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { addPermission, deletePermission, updatePermissionById } from '@app/crud/autouser/permission.crud';

import toast from '@app/partials/content/Toast';

import { actions } from './permission.store';

export function createPermission(id, data, refresh, setShowModal) {
    return (dispatch) => {
        dispatch(actions.createPermission());

        addPermission(id, data)
            .then((response) => {
                if (!response?.result?.error) {
                    if (response?.result) {
                        response.result.prices = response?.prices || [];
                    }

                    refresh();

                    dispatch(actions.addedPermission(response.result));
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.PERMISSION.ADD.SUCCESS' }),
                    });
                    setShowModal(false);
                } else {
                    dispatch(actions.addPermissionFailed(response.result));
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.PERMISSION.ADD.FAILED' }),
                    });
                }
            })
            .catch((response) => {
                dispatch(actions.addPermissionFailed(response));
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({
                        id: response.includes('already exists')
                            ? 'AUTOUSER.APPLICATION.PERMISSION.ADD.ALREADY_EXISTS'
                            : 'AUTOUSER.APPLICATION.PERMISSION.ADD.FAILED',
                    }),
                });
            });
    };
}

export function updatePermission(applicationId, permissionId, data, refresh, setShowModal) {
    return (dispatch) => {
        dispatch(actions.updatePermission());

        updatePermissionById(applicationId, permissionId, data)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.updatedPermission(response.result));
                    refresh();
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.PERMISSION.UPDATE.SUCCESS' }),
                    });
                    setShowModal(false);
                } else {
                    dispatch(actions.updatePermissionFailed(response.result));
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.PERMISSION.UPDATE.FAILED' }),
                    });
                }
            })
            .catch(() => {
                dispatch(actions.updatePermissionFailed({}));
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.PERMISSION.UPDATE.FAILED' }),
                });
            });
    };
}

export function removePermission(id, code, refresh) {
    return (dispatch) => {
        Swal.fire({
            title: Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.PERMISSION.REMOVE.TITLE' }),
            text: Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.PERMISSION.REMOVE.TEXT' }, { permission: code }),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
            cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
            customClass: {
                confirmButton: 'btn mr-3 btn-danger',
                cancelButton: 'btn btn-outline-primary',
            },
            buttonsStyling: false,
            preConfirm: async () => {
                Swal.showLoading();
                dispatch(actions.removePermission());

                return deletePermission(id)
                    .then((response) => {
                        if (!response?.result?.error) {
                            dispatch(actions.removedPermission(response.result));
                            refresh();
                            toast({
                                variant: 'success',
                                message: Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.PERMISSION.REMOVE.SUCCESS' }),
                            });
                        } else {
                            dispatch(actions.removePermissionFailed(response.result));
                            toast({
                                variant: 'danger',
                                message: Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.PERMISSION.REMOVE.FAILED' }),
                            });
                        }
                    })
                    .catch(() => {
                        dispatch(actions.removePermissionFailed());
                        toast({
                            variant: 'danger',
                            message: Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.PERMISSION.REMOVE.FAILED' }),
                        });
                    });
            },
        });
    };
}
