export const actionTypes = {
    FetchRole: 'FETCH_ROLE',
    AddRole: 'ADD_ROLE',
    RemoveRole: 'REMOVE_ROLE',
    UpdateRole: 'UPDATE_ROLE',
    FetchedRole: 'FETCHED_ROLE',
    UpdatedRole: 'UPDATED_ROLE',
    AddedRole: 'ADDED_ROLE',
    FetchRoleFailed: 'FETCH_ROLE_FAILED',
    UpdateRoleFailed: 'UPDATE_ROLE_FAILED',
    AddRoleFailed: 'ADD_ROLE_FAILED',
    RemoveRoleFailed: 'REMOVE_ROLE_FAILED',
};

const initialState = {
    loading: false,
    roles: [],
    filtersContent: [],
    totalRecords: 0,
    role: null,
    error: null,
};

export function autoUserRoleReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FetchRole: {
            return {
                ...state,
                loading: true,
                role: null,
                error: null,
            };
        }
        case actionTypes.AddRole:
        case actionTypes.RemoveRole:
        case actionTypes.UpdateRole: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }
        case actionTypes.FetchedRole: {
            return {
                ...state,
                loading: false,
                role: action.payload.role,
            };
        }
        case actionTypes.UpdatedRole:
        case actionTypes.AddedRole: {
            return {
                ...state,
                loading: false,
                role: action.payload,
            };
        }
        case actionTypes.FetchRoleFailed: {
            return {
                ...state,
                loading: false,
                roles: [],
                error: action.payload,
            };
        }
        case actionTypes.UpdateRoleFailed:
        case actionTypes.AddRoleFailed: {
            return {
                ...state,
                loading: false,
            };
        }
        case actionTypes.RemoveRoleFailed: {
            return {
                ...state,
                loading: false,
                role: { ...state.role, active: action.payload ?? state.role.active },
            };
        }
        default:
            return state;
    }
}

export const actions = {
    fetchRole: () => ({ type: actionTypes.FetchRole }),
    fetchedRole: (result) => ({ type: actionTypes.FetchedRole, payload: result }),
    fetchRoleFailed: (error) => ({ type: actionTypes.FetchRoleFailed, payload: error }),
    addRole: () => ({ type: actionTypes.AddRole }),
    addedRole: (result) => ({ type: actionTypes.AddedRole, payload: result }),
    addRoleFailed: (error) => ({ type: actionTypes.AddRoleFailed, payload: error }),
    removeRole: () => ({ type: actionTypes.RemoveRole }),
    updateRole: () => ({ type: actionTypes.UpdateRole }),
    updatedRole: (result) => ({ type: actionTypes.UpdatedRole, payload: result }),
    updateRoleFailed: (error) => ({ type: actionTypes.UpdateRoleFailed, payload: error }),
    removeRoleFailed: (result) => ({ type: actionTypes.RemoveRoleFailed, payload: result }),
};
