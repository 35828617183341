import Swal from 'sweetalert2';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

const customerAppointmentNotification = async (email) => {
    if (!email) {
        return false;
    }

    const response = await Swal.fire({
        title: Intl.formatMessage({ id: 'CUSTOMERS.APPOINTMENT.NOTIFY' }, { email }),
        iconHtml: '<i class="las text-primary la-2x la-envelope"></i>',
        showCancelButton: true,
        confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
        cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
    });

    return response?.isConfirmed;
};

export default customerAppointmentNotification;
