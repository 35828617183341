import React from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';

type NoteCreateButtonsProps = {
    handleCreate: (type: string) => void;
};

const NoteCreateButtons = ({ handleCreate }: NoteCreateButtonsProps) => {
    const Intl = useIntl();

    return (
        <div>
            <Button variant="primary" className="mt-3" onClick={() => handleCreate('sales')}>
                {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.NOTES.ACTION.CREATE.SALES' })}
            </Button>
            <Button variant="success" className="mt-3 ml-4" onClick={() => handleCreate('apv')}>
                {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.NOTES.ACTION.CREATE.APV' })}
            </Button>
        </div>
    );
};

export default NoteCreateButtons;
