import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { CREATED, getReconciliationCounter, LINKED, TO_LINK } from '../../helpers/CampaignHelper';
import { OperationContext } from '../../OperationProvider';

import OperationPanel from './OperationPanel';
import OperationReconciliationToLink from './OperationReconciliationToLink';

const variants = {
    show: {
        height: 'auto',
        overflow: 'hidden',
        opacity: 1,
        transition: {
            ease: 'easeOut',
        },
    },
    hide: {
        height: 0,
        overflow: 'hidden',
        opacity: 0,
        transition: {
            ease: 'easeOut',
        },
    },
};

const OperationReconciliation = () => {
    const { operationState, setOperationState } = useContext(OperationContext);
    const [expandedPanel, setExpandedPanel] = useState(null);
    const [reconciliationCounter, setReconciliationCounter] = useState(null);
    const { oldContact, newContact, contactToClose } = operationState;

    const panels = [
        {
            state: LINKED,
            title: (
                <FormattedHTMLMessage
                    id="CAMPAIGN.MODALS.SETTINGS.RECONCILIATION.LINKED"
                    values={{
                        number: oldContact,
                    }}
                />
            ),
            variant: 'success',
            toggleable: false,
        },
        {
            state: CREATED,
            title: (
                <FormattedHTMLMessage
                    id="CAMPAIGN.MODALS.SETTINGS.RECONCILIATION.CREATED"
                    values={{
                        number: newContact,
                    }}
                />
            ),
            variant: 'success',
            toggleable: false,
        },
        {
            state: TO_LINK,
            title: (
                <FormattedHTMLMessage
                    id={`CAMPAIGN.MODALS.SETTINGS.RECONCILIATION.TO_LINK${reconciliationCounter === 0 ? '.DONE' : ''}`}
                    values={{
                        number: reconciliationCounter,
                    }}
                />
            ),
            variant: reconciliationCounter === 0 ? 'success' : 'danger',
            children: <OperationReconciliationToLink setExpandedPanel={setExpandedPanel} />,
            toggleable: reconciliationCounter > 0,
        },
    ];

    useEffect(() => {
        setReconciliationCounter(getReconciliationCounter(operationState));
    }, [operationState, setOperationState]);

    return (
        <div className="position-relative h-100">
            <motion.div variants={variants} initial="show" animate={!expandedPanel ? 'show' : 'hide'}>
                <div className="text-center">
                    <i className="las la-user-friends la-4x text-primary" />
                    <div className="text-primary font-size-h3 font-weight-semibold">
                        <FormattedMessage id="CAMPAIGN.MODALS.SETTINGS.RECONCILIATION.TITLE" />
                    </div>
                </div>
            </motion.div>
            <div
                className={classNames({
                    'mt-8': expandedPanel === null,
                })}
            >
                {panels.map((panel) => (
                    <OperationPanel
                        state={panel.state}
                        variant={panel.variant}
                        title={panel.title}
                        key={panel.state}
                        toggleable={panel.toggleable}
                        expandedPanel={expandedPanel}
                        setExpandedPanel={setExpandedPanel}
                    >
                        {panel?.children}
                    </OperationPanel>
                ))}
                <motion.div
                    variants={variants}
                    initial={!expandedPanel && reconciliationCounter > 0 ? 'show' : 'hide'}
                    animate={!expandedPanel && reconciliationCounter > 0 ? 'show' : 'hide'}
                >
                    <div className="mt-4 text-danger text-center font-size-sm">
                        <FormattedHTMLMessage
                            id="CAMPAIGN.MODALS.SETTINGS.RECONCILIATION.ERROR"
                            values={{
                                number: reconciliationCounter,
                            }}
                        />
                    </div>
                </motion.div>
                <motion.div
                    variants={variants}
                    initial={!expandedPanel && reconciliationCounter === 0 ? 'show' : 'hide'}
                    animate={!expandedPanel && reconciliationCounter === 0 ? 'show' : 'hide'}
                >
                    <div className="mt-4 text-center font-size-lg">
                        <FormattedHTMLMessage
                            id="CAMPAIGN.MODALS.SETTINGS.RECONCILIATION.DONE"
                            values={{
                                number: contactToClose?.length,
                            }}
                        />
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default OperationReconciliation;
