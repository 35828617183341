import React from "react";
import {Card, Form} from "react-bootstrap";
import {Intl, Locale} from "../../../../../_metronic/i18n/I18nProvider";
import {Controller, useFormContext} from "react-hook-form";
import ErrorForm from "../../../../partials/layout/ErrorForm";
import {DateTimePicker} from "../../../../partials/content/DateTimePicker";

export default function CardCriteria() {
    const methods = useFormContext();

    return (
        <Card className="shadow-sm">
            <Card.Header>
                <i className="card__icon fas fa-cog"/>
                {Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.CRITERIA.TITLE' })}
            </Card.Header>
            <Card.Body>
                <Form.Group>
                    <Form.Label>{Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.CRITERIA.OPENING.DATE' })}</Form.Label>
                    <Controller
                        control={methods.control}
                        name="dateStart"
                        rules={{
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                        }}
                        render={(props) => (
                            <DateTimePicker
                                locale={Locale}
                                showIcon
                                dateFormat="DD/MM/YYYY HH:mm"
                                onChange={(e) => {props.onChange(e.value)}}
                                value={props.value}
                                showTime
                                className={`${methods.errors.dateStart ? 'is-invalid' : ''}`}
                            />
                        )}/>
                    <ErrorForm errors={methods.errors} name="dateStart" />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.CRITERIA.CLOSING.DATE' })}</Form.Label>
                    <Controller
                        control={methods.control}
                        name="dateEnd"
                        rules={{
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                        }}
                        render={(props) => (
                            <DateTimePicker
                                locale={Locale}
                                showIcon
                                dateFormat="DD/MM/YYYY HH:mm"
                                onChange={(e) => {props.onChange(e.value)}}
                                value={props.value}
                                showTime
                                className={`${methods.errors.dateEnd ? 'is-invalid' : ''}`}
                            />
                        )}/>
                    <ErrorForm errors={methods.errors} name="dateEnd" />
                </Form.Group>
            </Card.Body>
        </Card>
    )
}
