import React from 'react';
import './consent-layout.scss';

export default function ConsentLayout({ headerImage, children, context }) {
    let logo;
    switch (context) {
        case 'BERNARD':
            logo = (
                <img className="consent-layout__logo" src="/media/contexts/logos/bernard.svg" alt="autobernard logo" />
            );
            break;
        case 'EFF':
            logo = (
                <svg
                    className="consent-layout__logo"
                    height={60}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 225 239"
                >
                    <path
                        fill="currentColor"
                        d="M168.004 11.45c34.352 18.352 55.864 54.027 56.12 92.91V190a28.455 28.455 0 0 1-9.44 21.13c-20.31 18.26-49.49 27.67-85.2 27.67-70.26 0-88.18-55.6-88.18-85.6 0-21.9 6.82-47.33 23.92-65.4a62.42 62.42 0 0 0 47.6 93.2c22.72 2.3 41.5.15 54.36-6v-.2c19.16-8.9 36.55-29.7 37.32-76.25h-.03c.65-45.71-16.09-71.36-36.47-87.1ZM114.807 0l.97.008c32.286.506 81.027 26.17 81.027 95.312 0 35.56-9.97 59.68-29.62 70.68v-60.47c0-25.433-19.583-47.19-43.824-48.856l-.736-.044c-1.1-.08-2.2-.08-3.3-.08a62.45 62.45 0 0 0-13.55 1.5c-35.41 6.41-72.25 36.64-72.25 95.17 0 24.06 10.36 51.1 27.56 69.78-32.71-18.37-56.08-51.69-60.35-90.59a120 120 0 0 1 29.67-93.07C52.44 14.689 81.71.29 113.839.004l.968-.004Z"
                    />
                </svg>
            );
            break;
        default:
            logo = '';
    }

    return (
        <div>
            <div className="consent-layout__header">
                {logo}

                <div className="consent-layout__banner">
                    <img src={headerImage} alt="Bannière consentement" />
                </div>
            </div>
            <div className="consent-layout__content">{children}</div>
        </div>
    );
}
