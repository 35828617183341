import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { validate } from 'uuid';

import useFetch from '@app/hooks/useFetch';
import { useCheckEmailValidity, useCheckPhoneValidity } from '@app/hooks/useFormErrors';

import { convertIsoToDate } from '@app/helpers/DateHelper';
import { displayPhoneNumber } from '@app/helpers/ToolsHelper';

import { getAlreadyExistedCustomers } from '@app/crud/customers/customer.crud';
import { mapCustomers } from '@app/crud/mapping/customer.map';

import { GDPR_FIELDS, getCustomerGdprs, mapVcuCustomer } from '@app/pages/customers/Helpers/Customer';
import { createCustomer } from '@app/store/customers/customers.thunk';
import { actions } from '@app/store/modal/modal.store';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import Modal from '@app/partials/HoC/Modal';

import ConsentConfirmation from './ConsentConfirmation';
import IndividualForm from './CreateCustomer/Individual/IndividualForm';
import ProfessionalForm from './CreateCustomer/Professional/ProfessionalForm';

const Create = ({ showModal, setShowModal }) => {
    const methods = useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const { fetch, loading: loadingExistedCustomers } = useFetch({
        fetchAction: getAlreadyExistedCustomers,
    });
    const { getValues, setValue, reset, handleSubmit, watch, setError, clearErrors } = methods;
    const { userData } = useSelector((state) => state.auth);
    const { params } = useSelector((state) => state.modal);
    const { loading: loadingUpdates } = useSelector((state) => state.customer.customer);
    const customer = params?.customer || null;
    const update = params?.update || null;
    const step = params?.step || 0;
    const { context } = userData;
    const values = watch();
    const [formStep, setFormStep] = useState(step);
    const [isPro, setIsPro] = useState(false);

    const [isVisiblePhoneNumber, setIsVisiblePhoneNumber] = useState(false);
    const [selectedOtherFunction, setSelectedOtherFunction] = useState(false);
    const [selectedOtherRole, setSelectedOtherRole] = useState(false);
    const [previousDealership, setPreviousDealership] = useState(values.dealership);
    const [consentChanged, setConsentChanged] = useState(false);
    const [dealershipChanged, setDealershipChanged] = useState(false);
    const [isConsentModal, setConsentModal] = useState(false);
    const Intl = useIntl();

    const proFunction = ['Directeur', 'Commercial', 'Responsable de parc', 'Autre'];
    const proRole = ['Décideur', 'Prescripteur', 'Autre'];

    useCheckPhoneValidity(values, setError, clearErrors, validate, context); // hook to check phone & mobile validity as the user is typing
    useCheckEmailValidity(values.email, setError, clearErrors, validate); // hook to check email validity as the user is typing

    // Management Stepper + toggle btw Form Individual/PRO
    const handleToggleFormProModal = (value) => {
        setIsPro(!!value);
    };
    const handleNext = () => {
        setFormStep((crv) => crv + 1);
    };

    const handlePrev = () => {
        setFormStep(formStep - 1);
    };

    const handleClose = () => {
        setShowModal(false);
        setIsPro(false);
    };

    const createCustomerHandler = (data = {}) => {
        const formValues = getValues();
        formValues.birthday = convertIsoToDate(formValues.birthday);
        if (params?.onCustomerCreate) {
            params.onCustomerCreate(formValues);
            return;
        }

        if (Object.values(data).length > 0) {
            dispatch(
                actions.modalChange('create_already_exist', {
                    customers: mapCustomers(Object.values(data)),
                    customer: formValues,
                }),
            );
        } else {
            // create contact and redirect to it
            dispatch(
                createCustomer({
                    data: {
                        ...mapVcuCustomer({
                            ...formValues,
                            isClient: false,
                        }),
                    },
                    history,
                }),
            );
        }
    };
    const onSubmit = (data) => {
        if (data.birthday) {
            data.birthday = convertIsoToDate(data.birthday);
        }
        if (data.ape && typeof data.ape === 'string') {
            const [apeCode, ...apeValues] = data.ape.split(' ');
            data.ape = { code: apeCode, value: apeValues.join(' ') };
        }
        if (update) {
            // eslint-disable-next-line no-unused-expressions
            params?.callbackCustomer({ ...customer, ...data });
        } else {
            const { firstname, lastname, email, phone, mobile, isPro: contactIsPro } = data;
            fetch({
                research: `${firstname} ${lastname} ${mobile ?? phone}`,
                email,
                contactIsPro,
            }).then((response) => {
                if (response?.result) {
                    createCustomerHandler(response?.result);
                }
            });
        }
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    // Check if is visible phone number
    // Fill customer fields for existing vcu customer
    useEffect(() => {
        setIsVisiblePhoneNumber(displayPhoneNumber(customer));

        if (customer) {
            setSelectedOtherFunction(!proFunction.includes(customer.function));
            setSelectedOtherRole(!proRole.includes(customer.role));
        }

        const refDealershipId = customer?.dealershipKnow?.[0]?.refDealershipId;
        let dealershipValue;

        if (refDealershipId && userData?.dealerships?.[refDealershipId] !== undefined) {
            dealershipValue = refDealershipId;
        } else if (userData?.default_dealership) {
            dealershipValue = userData.default_dealership;
        }
        reset({
            ...(customer || {}),
            civility: customer?.civility ? customer?.civility.toLowerCase() : '',
            dealership: dealershipValue,
            birthday: customer?.birthday && dayjs(customer.birthday).isValid() ? dayjs(customer.birthday) : '',
        });
        setIsPro(customer !== null && 'isPro' in customer ? customer.isPro : false);
        setFormStep(step);
    }, [reset, customer, userData]);

    // MANAGE CONFIRMATION CONSENT MODAL
    useEffect(() => {
        // Detect if dealership has changed
        if (values.dealership !== previousDealership) {
            setDealershipChanged(true);
            setPreviousDealership(values.dealership);
            // Reset consent change flag whenever dealership changes
            setConsentChanged(false);
        }
    }, [values.dealership, previousDealership]);

    const getConsentName = (dealership, gdprField) => `${dealership}_${gdprField}`;

    const customersConsents = useCallback(() => {
        const consents = getCustomerGdprs(customer || {}).reduce(
            (acc, consent) => ({
                ...acc,
                ...GDPR_FIELDS.reduce((accumulator, currVal) => {
                    accumulator[getConsentName(consent.refDealership, currVal.slug)] = consent[currVal.slug];
                    return accumulator;
                }, {}),
            }),
            {},
        );

        return Object.entries(consents);
    }, [customer]);

    const getDealershipConsent = (dealership, gdprField) =>
        customersConsents().find(([consent]) => consent === getConsentName(dealership, gdprField));

    const resetConsents = useCallback(
        (dealership) => {
            Object.values(GDPR_FIELDS).forEach((gdprField) => {
                const prevValue = getDealershipConsent(dealership, gdprField.slug);
                if (prevValue) {
                    setValue(`new_consents.${prevValue[0]}`, prevValue[1]);
                } else {
                    setValue(`new_consents.${getConsentName(dealership, gdprField.slug)}`, undefined);
                }
            });
        },
        [customer],
    );

    const handleBeforeSubmit = (submitMethod) => {
        if (consentChanged || (dealershipChanged && consentChanged)) {
            setConsentModal(true);
            return;
        }

        handleSubmit(submitMethod, onError)();
    };

    const handleConsentConfirmation = () => {
        setConsentModal(false);
        handleSubmit(isPro && formStep === 0 ? handleNext : onSubmit, onError)();
        // Reset flags after handling consent confirmation
        setDealershipChanged(false);
        setConsentChanged(false);
    };
    return (
        <>
            <FormProvider {...methods}>
                <ModalDefault
                    show={showModal}
                    size="lg"
                    loading={loadingExistedCustomers || loadingUpdates}
                    hideModal={() => setShowModal(false)}
                    icon={<i className="las la-3x la-user text-primary" />}
                    title={Intl.formatMessage({ id: `CUSTOMERS.MODAL.${update ? 'UPDATE' : 'CREATE'}.TITLE` })}
                    body={
                        isPro ? (
                            <ProfessionalForm
                                handleToggleFormProModal={handleToggleFormProModal}
                                isVisiblePhoneNumber={isVisiblePhoneNumber}
                                isPro={isPro}
                                update={update}
                                userData={userData}
                                gdpr_fields={GDPR_FIELDS}
                                values={values}
                                getConsentName={getConsentName}
                                getDealershipConsent={getDealershipConsent}
                                formStep={formStep}
                                proFunction={proFunction}
                                setSelectedOtherFunction={setSelectedOtherFunction}
                                selectedOtherFunction={selectedOtherFunction}
                                proRole={proRole}
                                setSelectedOtherRole={setSelectedOtherRole}
                                selectedOtherRole={selectedOtherRole}
                                setConsentChanged={setConsentChanged}
                            />
                        ) : (
                            <IndividualForm
                                handleToggleFormProModal={handleToggleFormProModal}
                                isVisiblePhoneNumber={isVisiblePhoneNumber}
                                isPro={isPro}
                                update={update}
                                userData={userData}
                                gdpr_fields={GDPR_FIELDS}
                                values={values}
                                getConsentName={getConsentName}
                                getDealershipConsent={getDealershipConsent}
                                context={context}
                                setConsentChanged={setConsentChanged}
                            />
                        )
                    }
                    footer={
                        <>
                            {isPro && formStep === 1 ? (
                                <div className="float-sm-left">
                                    <Button
                                        variant="outline-secondary"
                                        className="mr-5"
                                        style={{ width: '120px' }}
                                        onClick={() => handlePrev()}
                                    >
                                        {Intl.formatMessage({ id: 'TRANSLATOR.PREVIOUS' })}
                                    </Button>
                                </div>
                            ) : (
                                ''
                            )}
                            <div className="float-sm-right d-flex justify-content-between">
                                <Button
                                    variant="outline-secondary"
                                    className="mr-5"
                                    style={{ width: '120px' }}
                                    onClick={() => handleClose()}
                                >
                                    {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                                </Button>
                                <Button
                                    variant="primary"
                                    style={{ width: '120px' }}
                                    type="submit"
                                    onClick={() =>
                                        formStep === 0 && isPro
                                            ? handleBeforeSubmit(handleNext)
                                            : handleBeforeSubmit(onSubmit)
                                    }
                                >
                                    {formStep === 0 && isPro
                                        ? Intl.formatMessage({ id: 'TRANSLATOR.NEXT' })
                                        : Intl.formatMessage({ id: 'TRANSLATOR.SAVE' })}
                                </Button>
                            </div>
                        </>
                    }
                />
            </FormProvider>
            <Modal
                As={ConsentConfirmation}
                showModal={isConsentModal}
                setShowModal={setConsentModal}
                onConfirm={handleConsentConfirmation}
                onCancel={() => {
                    resetConsents(values.dealership);
                    handleConsentConfirmation();
                }}
            />
        </>
    );
};

export default Create;
