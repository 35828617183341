import React, {useCallback, useEffect, useRef, useState} from 'react';
import Loader from '../partials/content/Loader';

const useIframe = (
    {
        width,
        frameBorder,
    }) => {
    const [content, setContent] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const ref_360 = useRef();

    const setIframeHeight = useCallback(
        iFrame => {
            ref_360.current.height = ref_360.current.contentWindow.document.body.scrollHeight;
            setLoading(false);
        },
        [],
    );

    const loadEventCallBack = useCallback(() => {
        setIframeHeight();
        const target = ref_360.current.contentDocument.body;
        const observer = new MutationObserver(function(mutations) {
            setIframeHeight();
        });

        const config = {
            attributes: true,
            childList: true,
            characterData: true,
            subtree: true
        };
        observer.observe(target, config);
    }, [])

    useEffect(() => {
        if (ref_360.current) {
            ref_360.current.addEventListener('load', loadEventCallBack)
        }
        return () => {
            if (ref_360.current) {
                ref_360.current.removeEventListener('load', loadEventCallBack);
            }
        }
    }, [setIframeHeight, ref_360.current, loadEventCallBack])

    useEffect(() => {
        setLoading(true);
        if (ref_360.current && content) {
            ref_360.current.contentWindow.document.open();
            ref_360.current.contentWindow.document.write(content);
            ref_360.current.contentWindow.document.close();
        }
        return () => {
            setContent(undefined)
        }
    }, [content])

    return {
        iframe: <div>
            {loading && <Loader style={{width: '5rem', height: '5rem'}} overlay/> }
            <iframe ref={ref_360} frameBorder={frameBorder} width={width}/>
        </div>,
        setContent
    }
};

export default useIframe;
