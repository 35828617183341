import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import { routeTo } from '@app/helpers/RoutesHelper';

import { Order } from '@app/crud/apv/order.type';

import { useAppDispatch, useModal } from '@app/hooks';
import ROUTES from '@app/router/Routes';
import { actions } from '@app/store/modal/modal.store';

import ModalDefault from '@app/partials/content/modals/Modal.default';

interface Props {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}

function APVInStoreQuotationAcceptedConfirm({ showModal, setShowModal }: Props) {
    const Intl = useIntl();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [_show, _toggle, params] = useModal(false, 'apv_in_store_close_win_confirm') as [
        boolean,
        (show: boolean) => void,
        { order: Order; date: Dayjs },
    ];

    const onConfirm = () => {
        history.push(routeTo(ROUTES.APV_INSTORE_DETAIL.PATH, { id: params?.order?.id }));
        dispatch(actions.closeModals());
    };

    return (
        <ModalDefault
            show={showModal}
            size="lg"
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-wrench la-2x text-primary" />}
            title={Intl.formatMessage({ id: 'APV.IN_STORE.QUOTATIONS.MODAL.ACCEPTED.CONFIRM_TITLE' })}
            body={
                <div className="d-flex justify-content-center text-justify">
                    <FormattedHTMLMessage
                        id="APV.IN_STORE.QUOTATIONS.MODAL.ACCEPTED.CONFIRM_TXT"
                        values={{
                            dateAppointment: dayjs(params?.date).format('dddd D MMMM'),
                            hourAppointment: dayjs(params?.date).format('HH [h] mm'),
                        }}
                    />
                </div>
            }
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button variant="outline-secondary" className="mr-3" onClick={() => setShowModal(false)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                    <Button variant="primary" className="position-relative" onClick={onConfirm}>
                        {Intl.formatMessage({ id: 'APV.IN_STORE.QUOTATIONS.MODAL.ACCEPTED.CONFIRM_BUTTON' })}
                    </Button>
                </div>
            }
        />
    );
}

export default APVInStoreQuotationAcceptedConfirm;
