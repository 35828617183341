import React from 'react';
import { FormattedMessage } from 'react-intl';

type StockPricingToDoHistoryEventReasonProps = {
    reason: string;
    comment: string;
};

const StockPricingToDoHistoryEventReason = ({ reason, comment }: StockPricingToDoHistoryEventReasonProps) => (
    <>
        {reason && (
            <div className="font-size-sm">
                <FormattedMessage id="STOCK.PRICING.HISTORY.REASON" />
                <strong>{reason}</strong>
            </div>
        )}

        {comment && (
            <div className="font-size-sm">
                <FormattedMessage id="STOCK.PRICING.HISTORY.COMMENT" />
                <strong>{comment}</strong>
            </div>
        )}
    </>
);

export default StockPricingToDoHistoryEventReason;
