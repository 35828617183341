import { InputText } from 'primereact/inputtext';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { acceptVehiclePriceRecommendation, refuseVehiclePriceRecommendation } from '@app/crud/stock/stock.crud';

import { useAppDispatch, useModal } from '@app/hooks';
import { actions } from '@app/store/stock/stock.store';
import { VehiclePricing } from '@app/store/stock/stock.type';

import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

import { CancelModal, ConfirmModal } from '../../modals';

type StockPriceRecommendationProps = {
    sku: string;
    price: number;
    pricing: VehiclePricing;
    setShowModal?: Dispatch<SetStateAction<boolean>>;
    setSubModalActive?: Dispatch<SetStateAction<boolean>>;
    setVehicleItem?: Function;
};

const StockPriceRecommendation = ({
    sku,
    price,
    pricing,
    setShowModal,
    setSubModalActive,
    setVehicleItem,
}: StockPriceRecommendationProps) => {
    const Intl = useIntl();
    const methods = useForm();
    const { control, setValue, errors, handleSubmit } = methods;
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [reason, setReason] = useState();
    const [warning, setWarning] = useState(false);
    const [newPrice, setNewPrice] = useState(pricing.recoPrice);
    const [confirmModalOpen, setConfirmModalOpen] = useModal(false, 'confirm_price');
    const [cancelModalOpen, setCancelModalOpen] = useModal(false, 'cancel');
    const dispatch = useAppDispatch();

    const maxPriceDiffRatio = 0.1;

    const confirmPriceRecommendation = () => {
        setLoading(true);
        // @ts-ignore
        acceptVehiclePriceRecommendation({
            [sku]: {
                newPrice,
                reasonId: reason || undefined,
                comment: comment || undefined,
            },
        })
            .then((data) => {
                setShowModal?.(false);
                setConfirmModalOpen(false);
                setSubModalActive?.(false);
                setLoading(false);

                if (data?.statusCode === 400 || data?.statusCode === 500) {
                    if (data?.error) {
                        toast({
                            variant: 'danger',
                            message: data.error,
                        });
                        return;
                    }
                    throw new Error('Error with statusCode 400 or 500');
                }

                if (!setSubModalActive) dispatch(actions.updateVehiclePrice(newPrice));

                setVehicleItem?.((prevState: any) => ({
                    ...prevState,
                    price: newPrice,
                    pricing: {
                        ...pricing,
                        thumb: null,
                    },
                }));

                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'STOCK_VEHICLES.MODAL.PRICE.CONFIRM.REASON.CONFIRMED' }),
                });
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'STOCK_VEHICLES.MODAL.PRICE.CONFIRM.REASON.FAILED' }),
                });
            });
    };

    const cancelPriceRecommendation = () => {
        setLoading(true);
        // @ts-ignore
        refuseVehiclePriceRecommendation({
            [sku]: {
                reasonId: reason || undefined,
                comment: comment || undefined,
            },
        })
            .then(() => {
                setShowModal?.(false);
                setCancelModalOpen(false);
                setSubModalActive?.(false);
                setLoading(false);
                setVehicleItem?.((prevState: any) => ({
                    ...prevState,
                    pricing: {
                        ...pricing,
                        thumb: null,
                    },
                }));
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.CONFIRMED' }),
                });
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.RECOVERY.UPDATE_FAILED' }),
                });
            });
    };

    useEffect(() => {
        setValue('price', pricing.recoPrice);
    }, [pricing]);

    useEffect(() => {
        setWarning(Math.abs(newPrice - price) > maxPriceDiffRatio * price);
    }, [newPrice]);

    const renderTooltip = (props: any) => (
        <Tooltip {...props}>
            <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.SAME_PRICE" />
        </Tooltip>
    );

    const updatePricing = () => {
        setConfirmModalOpen(true);
        setSubModalActive?.(true);
    };

    return (
        <>
            <div className="border-top w-100 text-center pt-5 pb-10">
                <div className={`${!setSubModalActive && 'd-flex justify-content-between align-items-center'}`}>
                    {setSubModalActive ? (
                        <p className="text-center" style={{ fontSize: '18px', color: '#525672' }}>
                            <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.NEW" />
                        </p>
                    ) : (
                        <p className="text-center font-weight-bold mb-5" style={{ fontSize: '15px', color: '#525672' }}>
                            <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.RECOMMENDATION" />
                        </p>
                    )}
                    <div className="text-center">
                        <Controller
                            rules={{
                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                pattern: {
                                    value: new RegExp(`^[0-9]+99$`),
                                    message: Intl.formatMessage({
                                        id: 'STOCK.PRICING.MODAL.MODIFY.ERROR_FORMAT',
                                    }),
                                },
                            }}
                            render={() => (
                                <NumberFormat
                                    customInput={InputText}
                                    thousandSeparator=" "
                                    suffix=" €"
                                    defaultValue={pricing.recoPrice}
                                    onValueChange={(e) => {
                                        setValue('price', e.value);
                                        setNewPrice(Number(e.value));
                                    }}
                                    className="text-center mb-5"
                                    style={{
                                        fontSize: setSubModalActive ? '18px' : '16px',
                                        fontWeight: setSubModalActive ? 600 : 700,
                                        borderColor: '#E6E6E6',
                                        color: '#525672',
                                    }}
                                />
                            )}
                            name="price"
                            control={control}
                        />
                    </div>
                    <ErrorForm errors={errors} name="price" />
                </div>
                <hr className="mt-0" style={{ borderTop: '1px dashed #E8E8E9' }} />
                <div className="d-flex justify-content-center">
                    <OverlayTrigger
                        placement="top"
                        trigger={newPrice === price ? 'hover' : null}
                        overlay={renderTooltip}
                    >
                        <span>
                            <Button
                                disabled={loading || newPrice === price}
                                className="py-1 px-15 mx-3"
                                onClick={handleSubmit(updatePricing)}
                                style={{
                                    fontSize: '14px',
                                    backgroundColor: '#34BFA3',
                                    boxShadow: '0px 3px 6px #12BEA466',
                                    border: 0,
                                }}
                            >
                                <i className="las la-thumbs-up fa-3x" style={{ opacity: 0.25 }} />
                                <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.PRICE_UPDATE" />
                            </Button>
                        </span>
                    </OverlayTrigger>
                    <Button
                        disabled={loading}
                        onClick={() => {
                            setCancelModalOpen(true);
                            setSubModalActive?.(true);
                        }}
                        className="py-1 px-15 mx-3"
                        style={{
                            fontSize: '14px',
                            backgroundColor: '#F4516C',
                            boxShadow: '0px 3px 6px #FF4D4D66',
                            border: 0,
                        }}
                    >
                        <i className="las la-thumbs-down fa-3x" style={{ opacity: 0.25 }} />
                        <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.PRICE_CANCEL" />
                    </Button>
                </div>
            </div>
            <ConfirmModal
                showModal={confirmModalOpen}
                setShowModal={setConfirmModalOpen}
                setSubModalActive={setSubModalActive && setSubModalActive}
                setShowMainModal={setShowModal}
                setReason={setReason}
                setComment={setComment}
                modified={newPrice !== pricing.recoPrice}
                warning={warning}
                loading={loading}
                onConfirm={confirmPriceRecommendation}
            />
            <CancelModal
                showModal={cancelModalOpen}
                setShowModal={setCancelModalOpen}
                setSubModalActive={setSubModalActive && setSubModalActive}
                setShowMainModal={setShowModal}
                setComment={setComment}
                setReason={setReason}
                onConfirm={cancelPriceRecommendation}
                loading={loading}
            />
        </>
    );
};

export default StockPriceRecommendation;
