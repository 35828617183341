import React from 'react';

import { getPresenceSummary } from '../Helpers/Team';

import AccountPresenceRow from './AccountPresenceRow';

const AccountPresence = ({ users }) => {
    const summary = getPresenceSummary(users);

    return (
        <div className="mt-10">
            {summary?.all_day?.map?.((user) => (
                <AccountPresenceRow key={user.information.id} user={user} variant="success" />
            ))}
            {summary?.half_day?.map?.((user) => (
                <AccountPresenceRow key={user.information.id} user={user} variant="warning" />
            ))}
            {summary?.absent?.map?.((user) => (
                <AccountPresenceRow key={user.information.id} user={user} variant="danger" />
            ))}
            {summary?.not_specified?.map?.((user) => (
                <AccountPresenceRow key={user.information.id} user={user} variant="black" />
            ))}
        </div>
    );
};

export default AccountPresence;
