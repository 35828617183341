import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ExternalData } from '@app/crud/customers/customer.type';

import { fetchApvOrders } from '@app/store/customers/customers.thunk';
import { RootState } from '@app/store/rootDuck';

export function retrieveExternalData(externalData: ExternalData[], name: string) {
    return externalData?.filter(
        (externService) => externService?.source?.slug === name && externService?.active === true,
    );
}

export function manageApvInStoreListing(
    externalData: ExternalData[],
    dispatch: Dispatch<ThunkAction<void, RootState, unknown, AnyAction>>,
    triggerLoading = true,
) {
    const apvExternalData = retrieveExternalData(externalData, 'after_sales');
    dispatch(fetchApvOrders(apvExternalData, triggerLoading));
}
