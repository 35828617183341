const EXPIRY_TIME = 300000; // 5 minutes

export const getProcessingPricing = () => JSON.parse(localStorage.getItem('pricingProcessing')) || [];

export const setProcessingPricing = (pricingProcessing: any) => {
    localStorage.setItem('pricingProcessing', JSON.stringify(pricingProcessing));
};

export const checkProcessingPricingExpiration = (threshold: number) =>
    new Promise((resolve) => {
        // @ts-ignore
        const onGoingPricingProcessing: [] = getProcessingPricing().filter((item) => threshold <= item.expiry);

        setProcessingPricing(onGoingPricingProcessing);

        resolve(onGoingPricingProcessing);
    });

export const addProcessingPricing = (pricings: string | string[]) => {
    checkProcessingPricingExpiration(new Date().getTime()).then((onGoingPricingProcessing) => {
        for (const pricing of pricings) {
            // @ts-ignore
            onGoingPricingProcessing.push({
                sku: pricing,
                expiry: new Date().getTime() + EXPIRY_TIME,
            });
        }

        localStorage.setItem('pricingProcessing', JSON.stringify(onGoingPricingProcessing));
    });
};
