import React from 'react';
import {InputNumber} from "primereact/inputnumber";
import classNames from "classnames";

const HourInput = React.forwardRef((
    {
        value,
        step,
        min,
        max,
        onChange,
        inputClassName
    }, forwardRef) => {

    const isAllowed = (newValue, max) => {
        return newValue >= 0 && newValue <= max;
    }

    const handleValueChange = (e, withStep = false) => {
        if (isAllowed(e.value || min, max) && !withStep) {
            onChange(e.value);
        } else {
            if (e) {
                e.originalEvent.target.value = value;
            }
        }
    }

    return (
        <InputNumber inputClassName={classNames('p-datepicker-time__input', inputClassName)}
                     value={value}
                     {...(step ? {step:step} : {})}
                     showButtons={!!step}
                     readonly={!!step}
                     inputRef={(ref) => {
                         forwardRef.current = ref;
                     }}
                     onValueChange={(e) => {
                         handleValueChange(e);
                     }}
                     onChange={(e) => {
                         handleValueChange(e, !!step);
                     }}
                     min={0}
                     max={step}/>
    );
});

HourInput.defaultProps = {
    inputClassName: ""
}

export default HourInput;
