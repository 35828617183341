export const SORT_ORDER_DESC = -1;
export const SORT_ORDER_ASC = 1;
export const SORT_ORDER_DEFAULT = 0;

/**
 * Get value of sort
 *
 * @param value
 * @param defaultValue
 * @returns {*}
 */
export const getSortFieldValue = (value: string, defaultValue: string) => {
    if (!value) {
        return defaultValue;
    }

    return value;
}

/**
 * Get value of sort
 *
 * @param value
 * @param defaultValue
 * @returns {*}
 */
export const getSortOrderValue = (value: number, defaultValue: 'asc' | 'desc') => {
    if (!value || value === SORT_ORDER_DEFAULT) {
        return defaultValue;
    }

    return value === 1 ? 'asc' : 'desc';
}

/**
 * Get sort field and order
 *
 * @param sortField
 * @param sortOrder
 * @param defaultSortField
 * @param defaultSortOrder
 * @returns {{field, sort}}
 */
export const getSort = (sortField: string, sortOrder: number, defaultSortField: string, defaultSortOrder: 'asc' | 'desc') => ({
        field: getSortFieldValue(sortField, defaultSortField),
        order: getSortOrderValue(sortOrder, defaultSortOrder)
    })
