import { Accordion, AccordionTab } from 'primereact/accordion';
import { Divider } from 'primereact/divider';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormattedHTMLMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { getStorage, toAbsoluteUrl } from '@src/_metronic';
import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { getCurrentUser, getGlobalContexts } from '@app/crud/auth.crud';
import { authorize } from '@app/crud/autouser/user.crud';

import { actions } from '@app/store/login/login.store';
import { logUser } from '@app/store/login/login.thunk';

import Loader from '@app/partials/content/Loader';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

export default function Login() {
    const dispatch = useDispatch();
    const { register, errors, handleSubmit } = useForm();
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const [contexts, setContexts] = useState<{ id: number; name: string; slug: string }[]>(null);
    const [appContext, setAppContext] = useState(localStorage.getItem('APP_CONTEXT') || 'eff');

    const { REACT_APP_AUTOUSER_URL, REACT_APP_TWO_FACTOR_ENABLED } = process.env;

    useEffect(() => {
        getGlobalContexts().then((response) => {
            setContexts(response.data.result);
        });
    }, []);

    useEffect(() => {
        const handleMessage = (message: MessageEvent) => {
            const autouserRoot = REACT_APP_AUTOUSER_URL.replace('/user/api/v1', '');
            if (message.origin === autouserRoot) {
                const messageData = JSON.parse(message.data);

                if (messageData?.redirection) {
                    window.location.replace(messageData?.redirection);
                }

                if (messageData?.error) {
                    toast({
                        variant: 'danger',
                        message: messageData?.error,
                    });
                }
            }
        };
        window.addEventListener('message', handleMessage);
        return function cleanup() {
            window.removeEventListener('message', handleMessage);
        };
    });

    useEffect(() => {
        if (getStorage('accessToken')) {
            getCurrentUser()
                .then((response) => {
                    dispatch(actions.fetchedCurrentUser(response.data));
                })
                .catch(() => {
                    dispatch(actions.logoutUser());
                });
        }
    }, [dispatch]);

    const onSubmit = async (values: { username: string; password: string; context: string }) => {
        setLoading(true);
        await dispatch(logUser(values.username, values.password, values.context));
        setLoading(false);
    };

    const authorizePopup = () => {
        if (getStorage('accessToken')) localStorage.removeItem('accessToken');
        if (getStorage('refreshToken')) localStorage.removeItem('refreshToken');
        const windowReference = window.open();
        authorize(appContext)
            .then((response) => {
                windowReference.location = response.url;
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                });
            });
        return false;
    };

    const onClick = (itemIndex: number) => {
        const activeIndexItem = activeIndex ? [...activeIndex] : [];

        if (activeIndexItem.length === 0) {
            activeIndexItem.push(itemIndex);
        } else {
            const index = activeIndexItem.indexOf(itemIndex);
            if (index === -1) {
                activeIndexItem.push(itemIndex);
            } else {
                activeIndexItem.splice(index, 1);
            }
        }

        setActiveIndex(activeIndexItem);
    };

    const onContextChange = (e: DropdownChangeEvent) => {
        setAppContext(e.value.slug);
        localStorage.setItem('APP_CONTEXT', e.value.slug);
    };

    const resetContext = () => {
        setAppContext(null);
        localStorage.setItem('APP_CONTEXT', 'eff');
    };

    const getCurrentContext = () => appContext && appContext.toUpperCase();

    return (
        <div className="amplify">
            <img className="amplify__logo" src="/media/logos/logo-dark.svg" alt="Automanager" />
            <div className="d-none d-md-block w-100">
                <Divider />
            </div>
            {appContext ? (
                <button type="button" className="btn d-flex p-0 mr-0 context__selector" onClick={() => resetContext()}>
                    <i className="las la-pen" />
                    <span>{Intl.formatMessage({ id: 'AUTOUSER.LOGIN.CONTEXT.CURRENT' })}</span>
                    <img
                        className="context__selector__logo"
                        alt={`${contexts?.filter((ctx) => ctx.slug === appContext)[0].name} logo`}
                        src={toAbsoluteUrl(
                            `/media/contexts/logos/${contexts?.filter((ctx) => ctx.slug === appContext)[0].slug}.svg`,
                        )}
                    />
                </button>
            ) : (
                <Dropdown
                    options={contexts}
                    onChange={onContextChange}
                    optionLabel="name"
                    placeholder={Intl.formatMessage({ id: 'AUTOUSER.LOGIN.CONTEXT' })}
                    value={appContext}
                    className="w-100"
                    style={{ height: '40px' }}
                />
            )}
            <div className="d-none d-md-block w-100">
                <Divider />
            </div>
            <div className="login__container">
                {REACT_APP_TWO_FACTOR_ENABLED === 'true' && (
                    <div className="office-login">
                        <Form>
                            <span className="d-block text-center">
                                <FormattedHTMLMessage id="AUTOUSER.LOGIN.OFFICE.PRESENTATION" />
                            </span>
                            <Button
                                variant={
                                    activeIndex && activeIndex.some((index: number) => index === 0)
                                        ? 'outline-primary'
                                        : 'primary'
                                }
                                onClick={authorizePopup}
                                disabled={appContext === null}
                            >
                                <span>{Intl.formatMessage({ id: 'AMPLIFY.SIGN_IN_ACTION' })}</span>
                            </Button>
                        </Form>
                        {/* @ts-ignore */}
                        <Divider align="center">{Intl.formatMessage({ id: 'TRANSLATOR.OR' })}</Divider>
                    </div>
                )}
                <div className="amng-login">
                    <button
                        type="button"
                        onClick={() => onClick(0)}
                        className="btn btn--accordion text-primary text-center"
                    >
                        {activeIndex && activeIndex.some((index: number) => index === 0)
                            ? Intl.formatMessage({ id: 'AMPLIFY.HIDE_EMAIL_LOGIN' })
                            : Intl.formatMessage({ id: 'AMPLIFY.SHOW_EMAIL_LOGIN' })}
                    </button>
                    {/* @ts-ignore */}
                    <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        {/* @ts-ignore */}
                        <AccordionTab>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group controlId="username">
                                    <Form.Label>
                                        {Intl.formatMessage({ id: 'AUTOUSER.LOGIN.AUTOMANAGER.EMAIL_LABEL' })} :
                                    </Form.Label>
                                    <Form.Control
                                        name="username"
                                        type="text"
                                        placeholder={Intl.formatMessage({ id: 'AMPLIFY.USERNAME_PLACEHOLDER' })}
                                        className={`${errors.username ? 'is-invalid' : ''}`}
                                        ref={register({
                                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                        })}
                                    />
                                    <ErrorForm errors={errors} name="username" />
                                </Form.Group>
                                <Form.Group controlId="password" className="mb-0">
                                    <Form.Label>
                                        {Intl.formatMessage({ id: 'AUTOUSER.LOGIN.AUTOMANAGER.PWD_LABEL' })} :
                                    </Form.Label>
                                    <Form.Control
                                        name="password"
                                        type="password"
                                        placeholder={Intl.formatMessage({ id: 'AMPLIFY.PASSWORD_PLACEHOLDER' })}
                                        className={`${errors.password ? 'is-invalid' : ''}`}
                                        ref={register({
                                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                        })}
                                    />
                                    <ErrorForm errors={errors} name="password" />
                                </Form.Group>
                                <input type="hidden" name="context" value={appContext} ref={register({})} />
                                <div className="lostPassword text-right">
                                    {/* @ts-ignore */}
                                    <Tooltip
                                        target=".lostPassword"
                                        baseZIndex={9999}
                                        position="top"
                                        className="login__tooltip"
                                        hideDelay={1000}
                                    >
                                        {Intl.formatMessage({ id: 'AUTOUSER.LOGIN.PASSWORD.FORGOTTEN.TITLE' })}
                                        <br />
                                        <br />
                                        <FormattedHTMLMessage
                                            id={`AUTOUSER.LOGIN.PASSWORD.FORGOTTEN.TEXT_${getCurrentContext()}`}
                                        />
                                    </Tooltip>
                                    <span className="trigger text-primary">
                                        {Intl.formatMessage({ id: 'AUTOUSER.LOGIN.PASSWORD.FORGOTTEN.BUTTON' })}
                                    </span>
                                </div>
                                <Button type="submit" variant="primary" className="mt-5" disabled={appContext === null}>
                                    {loading ? (
                                        <Loader variant="default" style={{ width: '1.5rem', height: '1.5rem' }} />
                                    ) : (
                                        <span>{Intl.formatMessage({ id: 'AMPLIFY.SIGN_IN_ACTION' })}</span>
                                    )}
                                </Button>
                            </Form>
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>
        </div>
    );
}
