import React, { useEffect, useState } from 'react';
import { Card, Tab } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useParams, withRouter } from 'react-router-dom';

import { getOrders } from '@app/crud/apv/order.crud';

import PortletTab from '@app/partials/layout/PortletTab';

import { mapQuotationTypeSlug } from './Helpers/Quotations';
import QuotationTable from './QuotationTable';

function Quotations() {
    const { type } = useParams<{ type: string }>();

    const [totalQuotations, setTotalQuotations] = useState(0);
    const [totalQuotationsDraft, setTotalQuotationsDraft] = useState(0);
    const [totalQuotationsWaitingClient, setTotalQuotationsWaitingClient] = useState(0);
    const [totalQuotationsClosed, setTotalQuotationsClosed] = useState(0);
    const [activeKey, setActiveKey] = useState('all');

    //Get counts
    useEffect(() => {
        getOrders({ statusApvInStoreByGroup: mapQuotationTypeSlug('all') }).then((value) => {
            setTotalQuotations(value.count || 0);
        });
        getOrders({ statusApvInStoreByGroup: mapQuotationTypeSlug('draft') }).then((value) => {
            setTotalQuotationsDraft(value.count || 0);
        });
        getOrders({ statusApvInStoreByGroup: mapQuotationTypeSlug('waiting_for_customer') }).then((value) => {
            setTotalQuotationsWaitingClient(value.count || 0);
        });
        getOrders({ statusApvInStoreByGroup: mapQuotationTypeSlug('closed') }).then((value) => {
            setTotalQuotationsClosed(value.count || 0);
        });
    }, []);

    useEffect(() => {
        const newType = type || 'all';
        setActiveKey(newType);
    }, [setActiveKey, type]);

    return (
        <Card className="p-4 position-relative">
            <PortletTab
                defaultActiveKey={activeKey}
                activeKey={activeKey}
                onClick={setActiveKey}
                redirects={{
                    all: '/apv/instore-quotations',
                    draft: '/apv/instore-quotations/draft',
                    waiting_customer: '/apv/instore-quotations/waiting_customer',
                    closed: '/apv/instore-quotations/closed',
                }}
                id="quotationlist-portlet"
            >
                <Tab
                    eventKey="all"
                    title={
                        <>
                            <FormattedMessage id="APV.IN_STORE.QUOTATIONS.TABS.ALL" />
                            <span className="ml-5 label label-light-primary font-weight-bold">{totalQuotations}</span>
                        </>
                    }
                >
                    {activeKey === 'all' && <QuotationTable type="all" />}
                </Tab>
                <Tab
                    eventKey="draft"
                    title={
                        <>
                            <FormattedMessage id="APV.IN_STORE.QUOTATIONS.TABS.DRAFT" />
                            <span className="ml-5 label label-light-primary font-weight-bold">
                                {totalQuotationsDraft}
                            </span>
                        </>
                    }
                >
                    {activeKey === 'draft' && <QuotationTable type="draft" />}
                </Tab>
                <Tab
                    eventKey="waiting_for_customer"
                    title={
                        <>
                            <FormattedMessage id="APV.IN_STORE.QUOTATIONS.TABS.WAITING_CLIENT" />
                            <span className="ml-5 label label-light-primary font-weight-bold">
                                {totalQuotationsWaitingClient}
                            </span>
                        </>
                    }
                >
                    {activeKey === 'waiting_for_customer' && <QuotationTable type="waiting_for_customer" />}
                </Tab>
                <Tab
                    eventKey="closed"
                    title={
                        <>
                            <FormattedMessage id="APV.IN_STORE.QUOTATIONS.TABS.CLOSED" />
                            <span className="ml-5 label label-light-primary font-weight-bold">
                                {totalQuotationsClosed}
                            </span>
                        </>
                    }
                >
                    {activeKey === 'closed' && <QuotationTable type="closed" />}
                </Tab>
            </PortletTab>
        </Card>
    );
}

export default withRouter(Quotations);
