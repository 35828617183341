import React, {useEffect, useState} from "react";
import {Intl} from "../../../_metronic/i18n/I18nProvider";
import {Controller, useFormContext} from "react-hook-form";
import {CascadeSelect} from "primereact/cascadeselect";
import {withRouter} from "react-router-dom";
import {getAdditionalServiceTree} from "../../store/apv/additional-service/additional-service.store";

const defaultProps = {
    'disabled': false,
    'validate': true
}

function SelectAdditionalService(props) {
    const { additionalServices, additionalServiceId, disabled, validate }  = {...defaultProps, ...props};
    const methods = useFormContext();
    const [additionalService, setAdditionalService] = useState([]);
    const [_options, setOptions] = useState([]);

    useEffect(() => {
        if (additionalServices) {
            const [options, selectedAdditionalService] = getAdditionalServiceTree(additionalServices, additionalServiceId);
            setAdditionalService(selectedAdditionalService);
            setOptions(options);
        }
    }, [additionalServices, additionalServiceId])

    return (
        <Controller
            control={methods.control}
            name="additionalServiceId"
            rules={{
                validate: (value) => {
                    return !value && validate ? Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }) : true
                }
            }}
            render={(props) => (
                <CascadeSelect
                    value={additionalService}
                    options={_options}
                    optionLabel={"name"}
                    optionGroupLabel={"cname"}
                    optionGroupChildren={['additionalService']}
                    className={`${methods.errors.additionalServiceId ? 'is-invalid' : ''}`}
                    style={{width: '100%'}}
                    placeholder={Intl.formatMessage({ id: "APV.OPERATION.FORM.SELECT.ADDITIONAL.SERVICE.PLACEHOLDER" })}
                    onChange={(e) => {
                        setAdditionalService(e.value);
                        props.onChange(e.value.id);
                        methods.trigger('additionalServiceId');
                    }}
                    disabled={disabled}
                />
            )}
        />
    )
}

export default withRouter(SelectAdditionalService);
