import classNames from 'classnames';
import React, { useContext } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { actions } from '@app/store/modal/modal.store';

import { CampaignContext } from '../CampaignProvider';
import { CANCELED_STATUS, getStatusColor } from '../helpers/CampaignHelper';

const CampaignProgress = ({ current_step, className }) => {
    const dispatch = useDispatch();
    const { status } = useContext(CampaignContext);
    const steps = status?.sort((a, b) => (a.stepOrder > b.stepOrder ? 1 : -1));

    return (
        <Card className={classNames('card--shadowed', className)}>
            <Card.Header className="d-flex justify-content-between align-items-center text-closed position-relative">
                <div>
                    <div className="bg-closed pin" style={{ top: '20px' }}>
                        <i className="la la-rocket text-white la-lg" />
                    </div>
                    <span className="ml-5 font-weight-semibold">
                        <FormattedMessage id="CAMPAIGN.PROGRESS.TITLE" />
                    </span>
                </div>
                {current_step?.slug !== CANCELED_STATUS && (
                    <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => {
                            dispatch(actions.modalChange('campaign_cancel'));
                        }}
                    >
                        <FormattedMessage id="CAMPAIGN.PROGRESS.CANCEL" />
                    </Button>
                )}
            </Card.Header>
            <Card.Body>
                <div className="campaign-timeline">
                    {steps?.map((step) => {
                        const displayName = (
                            <>
                                {' '}
                                {step?.name} <br />
                                {step?.subName && '•'} {step?.subName}{' '}
                            </>
                        );
                        const isCurrent = step?.stepOrder === current_step?.stepOrder;

                        return (
                            <div
                                key={step?.id}
                                className={classNames('campaign-timeline__item', {
                                    'campaign-timeline__item--active': step?.stepOrder <= current_step?.stepOrder,
                                    [step?.slug
                                        ? `campaign-timeline__item--current--${getStatusColor(step?.slug)}`
                                        : 'campaign-timeline__item--current']: isCurrent,
                                })}
                            >
                                <div className="campaign-timeline__connector" />
                                <div className="campaign-timeline__badge">
                                    <i
                                        className={`fa fa-genderless icon-xl ${
                                            isCurrent
                                                ? `campaign-timeline__icon--${getStatusColor(step?.slug)}`
                                                : 'campaign-timeline__iconcampaign-timeline__icon'
                                        }`}
                                    />
                                </div>
                                <div className="campaign-timeline__content mt-2 font-weight-normal font-size-lg text-center">
                                    {displayName}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Card.Body>
        </Card>
    );
};

export default CampaignProgress;
