import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Dealership } from '@app/crud/autouser/dealerships.type';

import { TreeSelect } from '@app/partials/content/Tree';

interface TreeSelectNode {
    label: string;
    id: string;
    checked?: boolean;
    _children?: string[];
}

interface Filters {
    q?: { value: string };
    dealerships?: { value: string[] };
}

interface DealershipsSelectProps {
    dealerships: Dealership[];
    filters: Filters;
    handleFilter: (f: Filters) => void;
}
const DealershipsSelect = React.forwardRef(
    ({ dealerships, filters, handleFilter }: DealershipsSelectProps, forwardedRef) => {
        const Intl = useIntl();
        const selectDealerships = useMemo(() => {
            const neverSelected = !filters?.dealerships;
            const filteredDealerships = filters?.dealerships?.value || [];
            return {
                label: Intl.formatMessage({ id: 'AUTOUSER.DEALERSHIPS.SELECT_ALL' }),
                id: '0',
                expanded: true,
                checked: dealerships.length === filteredDealerships.length || neverSelected,
                children: dealerships
                    .sort((a, b) => a?.ref_dealership_usualname?.localeCompare(b?.ref_dealership_usualname))
                    .map((dealership) => ({
                        label: dealership.ref_dealership_usualname,
                        id: dealership.ref_dealership_id,
                        checked: filteredDealerships.includes(dealership.ref_dealership_id) || neverSelected,
                    })),
            };
        }, [dealerships]);

        const onChange = (currentNode: TreeSelectNode, selectedNodes: TreeSelectNode[]) => {
            let selectedDealerships = selectedNodes.map((selectedNode) => selectedNode.id);
            if (currentNode.id === '0') {
                selectedDealerships = currentNode.checked ? currentNode._children : [];
            }
            handleFilter({
                ...filters,
                dealerships: {
                    value: selectedDealerships,
                },
            });
        };

        return (
            <TreeSelect
                // @ts-ignore
                data={selectDealerships}
                ref={forwardedRef}
                onChange={onChange}
            />
        );
    },
);

export default DealershipsSelect;
