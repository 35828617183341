import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import ModalDefault from '@app/partials/content/modals/Modal.default';

import { Icon } from '@app/components/Icon';
import { IconSize } from '@app/components/Icon/Icon';

type ModalProps = {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    fileName: string;
    changeFile: () => void;
    importPricing: () => void;
    isLoading: boolean;
};

const ImportPricingModal = ({
    showModal,
    setShowModal,
    fileName,
    changeFile,
    importPricing,
    isLoading,
}: ModalProps) => {
    const exitModal = () => {
        setShowModal(false);
    };

    const modalIcon = <i className="las la-2x la-table text-primary" />;

    const modalTitle = (
        <p className="text-primary">
            <FormattedMessage id="STOCK.PRICING.MODAL.IMPORT.TITLE" />
        </p>
    );

    const modalBody = (
        <>
            <p className="text-center">
                <FormattedHTMLMessage id="STOCK.PRICING.MODAL.IMPORT.BODY" values={{ filename: fileName }} />
            </p>
            <div className="border-bottom border-top border-gray-700 d-flex justify-content-center mx-auto my-12 py-4 w-75">
                <p className="m-0 d-flex align-items-center">
                    <Icon icon="file" className="mr-2" size={IconSize.TwoX} />
                    {fileName}
                </p>
                <span className="ml-8 cursor-pointer" onClick={changeFile} role="presentation">
                    <Icon icon="trash" className="p-0 text-gray-500" size={IconSize.TwoX} style={{}} />
                </span>
            </div>
        </>
    );

    const modalFooter = (
        <div>
            <div className="d-flex justify-content-center">
                <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
                    <FormattedMessage id="TRANSLATOR.CANCEL" />
                </Button>
                <Button className="ml-4" onClick={importPricing} disabled={isLoading}>
                    <FormattedMessage id="TRANSLATOR.APPLY" />
                </Button>
            </div>
        </div>
    );

    return (
        <ModalDefault
            icon={modalIcon}
            show={showModal}
            hideModal={() => {
                exitModal();
            }}
            title={modalTitle}
            body={modalBody}
            footer={modalFooter}
        />
    );
};
export default ImportPricingModal;
