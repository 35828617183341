import React from 'react';
import { FormattedMessage } from 'react-intl';

import KTUtil from '@src/_metronic/_assets/js/util';

import sourcesPictures from '@app/pages/stock/helpers/SourcesPictures';
import { Vehicle } from '@app/store/stock/stock.type';

import Label from '@app/partials/content/Label';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';

export default function StockVehiclesPhotosColumn({ vehicle }: { vehicle: Vehicle }) {
    return (
        <>
            <div>
                <OverlayTooltip label="STOCK_VEHICLES.TABLE.PICTURE_TOOLTIP" id="picture">
                    {vehicle?.nb_photos > 0 ? (
                        <Label variant="light" className="font-weight-bold text-dark flex-wrap">
                            <i className="mr-2 las la-camera-retro la-2x text-dark" />
                            {vehicle.nb_photos}
                        </Label>
                    ) : (
                        <Label variant="danger-lite" className="font-weight-bold">
                            <i className="mr-2 las la-camera-retro la-2x text-danger-lite" />
                            {vehicle.nb_photos}
                        </Label>
                    )}
                </OverlayTooltip>
            </div>
            {vehicle?.photos_defaults && (
                <div className="mt-2">
                    <OverlayTooltip label="STOCK_VEHICLES.TABLE.DEFAULT_TOOLTIP" id="default">
                        <Label variant="light" className="font-weight-bold text-dark flex-wrap">
                            <i className="mr-2 las la-exclamation-triangle la-2x text-dark" />
                            {vehicle.photos_defaults.length}
                            &nbsp;
                            <FormattedMessage id="STOCK_VEHICLES.TABLE.DEFAULT" />
                            {vehicle.photos_defaults.length > 0 ? 's' : null}
                        </Label>
                    </OverlayTooltip>
                </div>
            )}

            {(vehicle?.is_360 || vehicle?.is_degraded_360) && (
                <div className={`mt-2 ${KTUtil.isSmallDesktopOrMobileDevice() ? 'text-center' : ''}`}>
                    <span className="text-primary">
                        {vehicle.is_degraded_360 ? (
                            <>
                                {' '}
                                360° <FormattedMessage id="STOCK_VEHICLES.TABLE.DEGRADED_PHOTO" />
                            </>
                        ) : (
                            vehicle.is_360 && (
                                <>
                                    . 360° <FormattedMessage id="STOCK_VEHICLES.TABLE.FULL_PHOTO" />
                                </>
                            )
                        )}
                    </span>
                </div>
            )}
            {Number(vehicle?.source_360) > 0 && (
                <div className="mt-2">
                    <Label variant="white" className="p-0 font-weight-bold text-dark flex-wrap">
                        <i className="mr-2 las la-hdd la-2x text-dark" />
                        {sourcesPictures[vehicle.source_360] || '-'}
                    </Label>
                </div>
            )}
        </>
    );
}
