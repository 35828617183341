import React, { useEffect } from 'react';
import { Button, Col, Image, Row, Table } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';

import useFadeOut from '@app/hooks/useFadeOut';
import useFetch from '@app/hooks/useFetch';

import { getPrices } from '@app/crud/smartRepair/smart-repair.crud';

import { actions } from '@app/store/smart-repair/quotations/smart-repair.store';

import ModalDefault from '@app/partials/content/modals/Modal.default';

/**
 * Packages modal is used: to add selectedPackages in redux store
 * when package is selected by user and add format an array of objects
 * into an object where the keys are repairId and the values are arrays of objects
 * to be used on Quotation.js
 */
export default function Packages({ quotation, showModal, setShowModal, setSelectedQuotations, selectedPackages }) {
    const { REACT_APP_SMART_REPAIR_URL } = process.env;
    const dispatch = useDispatch();
    const { handleScroll, fadeOutRef } = useFadeOut();
    const {
        fetch,
        data: packages,
        loading,
    } = useFetch({
        fetchAction: () => getPrices({ zoneBody: quotation?.zoneBody?.slug, active: 'true', items: 99 }),
    });
    const Intl = useIntl();

    useEffect(() => {
        if (quotation) {
            fetch();
        }
    }, [quotation]);

    const handleClose = () => {
        setShowModal(false);
    };

    const addPackage = () => {
        setSelectedQuotations(
            selectedPackages.reduce((acc, item) => {
                const { repairId, ...rest } = item;
                if (!acc[repairId]) {
                    acc[repairId] = [];
                }
                acc[repairId].push({ ...rest, repairId });
                return acc;
            }, {}),
        );
        setShowModal(false);
    };

    const selectedPackage = (e, _package) => {
        if (!e.target.checked) {
            dispatch(actions.setDeleteSelectedPackage(e.target.id));
        } else {
            dispatch(
                actions.setSelectedPackages([
                    ...selectedPackages,
                    {
                        ..._package,
                        orderId: quotation.order,
                        repairId: quotation.id,
                        inputId: e.target.id,
                        zoneName: quotation.zoneBody.name,
                    },
                ]),
            );
        }
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={handleClose}
            icon={<i className="las la-money-bill-wave fa-2x" />}
            title={Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.MODAL.DETAILS' })}
            body={
                <div className="px-5">
                    <Row>
                        <Col lg={5} className="d-flex justify-content-around">
                            {(quotation?.files?.damage?.url && (
                                <Image
                                    src={`${REACT_APP_SMART_REPAIR_URL}${quotation.files.damage.url}`}
                                    widht="130px"
                                    height="120px"
                                    className="smart-repair-modal__img"
                                />
                            )) ||
                                (quotation?.files?.addon?.url && (
                                    <Image
                                        src={`${REACT_APP_SMART_REPAIR_URL}${quotation.files.addon.url}`}
                                        widht="130px"
                                        height="120px"
                                    />
                                ))}
                        </Col>
                        <Col lg={7} className="d-flex justify-content-center flex-column">
                            <p className="smart-repair-modal__paragraph">
                                <FormattedMessage id="SMART.REPAIR.QUOTATION.MODAL.INDICATION" />
                            </p>
                            <p className="smart-repair-modal__paragraph font-weight-bold">
                                « {quotation?.zoneBody.name} »
                            </p>
                        </Col>
                    </Row>

                    <div className="already-exists" ref={fadeOutRef} onScroll={handleScroll}>
                        <Table className="m-0">
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th width="70%">
                                        <FormattedMessage id="SMART.REPAIR.QUOTATION.TABLE.HEAD.PACKAGE" />
                                    </th>
                                    <th width="15%">
                                        <FormattedMessage id="SMART.REPAIR.QUOTATION.TABLE.HEAD.PRICE.HT" />
                                    </th>
                                    <th width="15%">
                                        <FormattedMessage id="SMART.REPAIR.QUOTATION.TABLE.HEAD.FINAL_PRICE" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {packages.prices &&
                                    packages.prices.map((_package) => (
                                        <tr key={_package.slug}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    id={`${quotation.reference}_${_package.slug}`}
                                                    checked={selectedPackages.some(
                                                        (e) => e.inputId === `${quotation.reference}_${_package.slug}`,
                                                    )}
                                                    onChange={(e) => selectedPackage(e, _package)}
                                                />
                                            </td>
                                            <td>{_package.name}</td>
                                            <td>
                                                <NumberFormat
                                                    value={_package.priceHt ? _package.priceHt : '-'}
                                                    thousandSeparator=" "
                                                    decimalSeparator=","
                                                    decimalScale="2"
                                                    fixedDecimalScale
                                                    displayType="text"
                                                    suffix=" €"
                                                />
                                            </td>
                                            <td>
                                                <NumberFormat
                                                    value={_package.priceTtc ? _package.priceTtc : '-'}
                                                    thousandSeparator=" "
                                                    decimalSeparator=","
                                                    decimalScale="2"
                                                    fixedDecimalScale
                                                    displayType="text"
                                                    suffix=" €"
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            }
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button variant="btn btn-outline-primary" onClick={handleClose}>
                        <FormattedMessage id="TRANSLATOR.CLOSE" />
                    </Button>
                    <Button
                        className="ml-5"
                        variant="primary"
                        disabled={selectedPackages?.length <= 0}
                        onClick={addPackage}
                    >
                        <FormattedMessage id="TRANSLATOR.CHOOSE" />
                    </Button>
                </div>
            }
        />
    );
}
