import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';

import useFetch from '@app/hooks/useFetch';

import {
    getLivePerformances,
    getRemotePerformances,
    getAppointmentPerformances,
    getAbandonPerformances,
} from '@app/crud/dashboard/achat-cash';

import { DashboardContext } from '../../DashboardProvider';
import * as ACPartials from '../AchatCash';

import DashboardProcessedLead from './DashboardProcessedLead';

interface Props {
    className: string;
}

const DashboardAchatCash = ({ className }: Props) => {
    const { overriddenParams } = useContext(DashboardContext);

    const { data, loading, fetch } = useFetch({
        fetchAction: getAppointmentPerformances,
    });

    useEffect(() => {
        fetch(overriddenParams);
    }, [overriddenParams]);

    return (
        <div className={classNames('dashboard-stats d-flex flex-column', className)}>
            <div className="d-flex flex-lg-row flex-column">
                <div className="dashboard-stats__perf h-100 d-flex flex-column flex-lg-grow-1">
                    <ACPartials.Performances
                        fetchAction={getRemotePerformances}
                        title="DASHBOARD.ACHAT_CASH.REMOTE_PERF.TITLE"
                        icon="la-mobile"
                    />
                    <ACPartials.Performances
                        fetchAction={getLivePerformances}
                        title="DASHBOARD.ACHAT_CASH.LIVE_PERF.TITLE"
                        icon="la-building"
                        className="mt-2"
                    />
                    <ACPartials.PerformancesAbandon
                        fetchAction={getAbandonPerformances}
                        title="DASHBOARD.ACHAT_CASH.ABANDON_PERF.TITLE"
                        icon="la-times-circle"
                        className="mt-2"
                    />
                </div>
            </div>
            <div className="d-flex flex-lg-row flex-column mt-2">
                <div className="dashboard-stats__perf h-100 d-flex flex-column flex-lg-grow-1">
                    <ACPartials.PerformancesSources />
                    <DashboardProcessedLead className="flex-basis-0 flex-grow-1 flex-shrink-1 mt-lg-2 mt-6" />
                </div>
            </div>
            <div className="d-flex flex-lg-row flex-column mt-2">
                <ACPartials.AppointmentPerformances
                    loading={loading}
                    data={data.live}
                    title="DASHBOARD.ACHAT_CASH.LIVE_APPOINTMENT_PERF.TITLE"
                    icon="la-building"
                    className="mr-1 flex-grow-1"
                />
                <ACPartials.AppointmentPerformances
                    loading={loading}
                    data={data.remote}
                    title="DASHBOARD.ACHAT_CASH.REMOTE_APPOINTMENT_PERF.TITLE"
                    icon="la-mobile"
                    className="ml-1 flex-grow-1"
                />
            </div>
        </div>
    );
};

export default DashboardAchatCash;
