import { motion } from 'framer-motion';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import useVendors from '@app/hooks/useVendors';

import { apvRole } from '@app/helpers/RoleHelper';

import { getSortedUsersByRoleSlug } from '@app/pages/autouser/helpers/UserHelper';

import HoCDataTable from '@app/partials/content/HoCDataTable';
import Loader from '@app/partials/content/Loader';

const container = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
    },
};

const AccountPerimeter = ({ userData, profile }) => {
    const dealerships = useMemo(
        () =>
            Object.entries(userData?.dealerships)?.map?.(([key, dealership]) => ({
                ...dealership,
                refDealershipId: key,
            })),
        [userData],
    );
    const { fetchVendors, vendors: vendorsData, loading } = useVendors();
    const [dealership, setDealership] = useState(
        dealerships.find((dealershipItem) => dealershipItem?.refDealershipId === userData?.default_dealership) ||
            dealerships[0],
    );
    const headerClassName = 'bg-primary font-weight-bold text-white';

    useEffect(() => {
        fetchVendors(dealership?.refDealershipId);
    }, [dealership]);

    const vendors = useMemo(() => {
        if (!vendorsData?.length || !userData?.role?.slug) return [];

        if (userData.role.slug === apvRole.rapv) {
            return vendorsData.filter((vendor) => vendor?.role?.slug === apvRole.ccs);
        }

        return vendorsData;
    }, [vendorsData, userData]);

    const nameBodyTemplate = (user) => (
        <>
            {user?.first_name}
            <br />
            {user?.last_name}
        </>
    );

    const mailBodyTemplate = (user) => user?.email;

    const phoneBodyTemplate = (user) => {
        const phone = parsePhoneNumberFromString(user?.phone || '', Locale.toUpperCase());
        const mobile = parsePhoneNumberFromString(user?.cell_phone || '', Locale.toUpperCase());

        return (
            <>
                {phone?.formatNational?.() || ''}
                {phone && mobile && <br />}
                {mobile?.formatNational?.() || ''}
            </>
        );
    };

    const jobBodyTemplate = (user) => user?.job?.name;

    const roleBodyTemplate = (user) => user?.role?.name || user?.role;

    const perimeterBodyTemplate = (user) => (
        <div className="d-flex align-items-end justify-content-between">
            <div>
                {user?.dealership_perimeter?.slice?.(0, 2)?.map?.((perimeter) => (
                    <div className="label m-1 label-light-secondary label-inline label-lg">{perimeter?.name}</div>
                ))}
            </div>
            {user?.dealership_perimeter?.length > 2 && (
                <div className="label m-1 label-light-secondary label-inline label-lg">
                    +&nbsp;{user?.dealership_perimeter?.length - 2}
                </div>
            )}
        </div>
    );

    const cols = [
        {
            field: 'name',
            header: <FormattedHTMLMessage id="USER.PERIMETER.TABLE.HEAD.NAME" />,
            headerClassName,
            body: nameBodyTemplate,
        },
        {
            field: 'email',
            header: <FormattedHTMLMessage id="USER.PERIMETER.TABLE.HEAD.EMAIL" />,
            headerClassName,
            body: mailBodyTemplate,
        },
        {
            field: 'phone',
            header: <FormattedHTMLMessage id="USER.PERIMETER.TABLE.HEAD.PHONE" />,
            headerClassName,
            body: phoneBodyTemplate,
        },
        {
            field: 'job',
            header: <FormattedHTMLMessage id="USER.PERIMETER.TABLE.HEAD.JOB" />,
            headerClassName,
            body: jobBodyTemplate,
        },
        {
            field: 'role',
            header: <FormattedHTMLMessage id="USER.PERIMETER.TABLE.HEAD.ROLE" />,
            headerClassName,
            body: roleBodyTemplate,
        },
        {
            field: 'perimeter',
            header: <FormattedHTMLMessage id="USER.PERIMETER.TABLE.HEAD.PERIMETER" />,
            headerClassName,
            body: perimeterBodyTemplate,
            style: { minWidth: '120px' },
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    const validSupportRole = [
        'super-administrateur',
        'admin-bu-apv',
        'admin-bu-vo',
        'responsable-marketing',
        'assistance',
    ];

    const validApvRole = ['responsable-apv', 'conseiller-commercial-services'];

    return (
        <motion.div variants={container} initial="hidden" animate="visible" className="account-perimeter">
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <div className="mb-7 d-flex align-items-center justify-content-between">
                <div className="font-size-h5 font-weight-bold">
                    <FormattedHTMLMessage id="USER.PROFILE.PERIMETER.TITLE" values={{ suffix: profile ? 'Mon' : '' }} />
                </div>
                <Dropdown
                    value={dealership}
                    options={dealerships}
                    onChange={(e) => setDealership(e.value)}
                    style={{ height: '45px' }}
                    className="p-dropdown--light-primary"
                    optionLabel="title"
                />
            </div>
            <HoCDataTable
                value={getSortedUsersByRoleSlug(vendors)}
                rowGroupMode="subheader"
                groupRowsBy="role.type"
                rowGroupHeaderTemplate={(data) => {
                    if (data?.role?.slug && validSupportRole.includes(data.role.slug)) {
                        return (
                            <div className="py-4 kt-background font-size-lg font-weight-bold text-center">
                                <FormattedHTMLMessage id="AUTOUSER.ROLES.VIEW.TYPE.SUPPORT" />
                            </div>
                        );
                    } else if (data?.role?.slug && validApvRole.includes(data.role.slug)) {
                        return (
                            <div className="py-4 kt-background font-size-lg font-weight-bold text-center">
                                <FormattedHTMLMessage id="AUTOUSER.ROLES.VIEW.TYPE.APV" />
                            </div>
                        );
                    }
                    return null;
                }}
                rowGroupFooterTemplate={() => null}
                totalRecords={100}
                removableSort
                emptyMessage={<FormattedHTMLMessage id="DATATABLE.EMPTY_RESULT" />}
            >
                {dynamicColumns}
            </HoCDataTable>
        </motion.div>
    );
};

AccountPerimeter.defaultProps = {
    profile: true,
};

export default AccountPerimeter;
