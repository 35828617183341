import React from "react";
import Month from "./Month";

export default function Months({months, currentMonth, onSelect}){
    return (
        <div className="months">
            {
                Object.entries(months).map((month, key) => {
                    return <Month key={key}
                                  selected={currentMonth === month[0]}
                                  label={month[1]}
                                  onSelect={onSelect}
                                  month={month[0]}
                    />
                })
            }
        </div>
    )
}
