import React from 'react';

import usePermissions from '@app/hooks/usePermissions';

type PermissionProps = {
    permissions: string[];
    hasAny?: boolean;
    children: React.ReactNode;
    fallback?: Function;
    interceptor?: Function;
};

const Permission = ({ permissions, hasAny, children, fallback, interceptor }: PermissionProps) => {
    const { hasAllPermissions, hasAnyPermissions } = usePermissions();
    const hasPermission = hasAny ? hasAnyPermissions : hasAllPermissions;

    if (permissions && !hasPermission(permissions || [])) {
        return fallback ? fallback() : null;
    }

    if (interceptor()) {
        return fallback ? fallback() : null;
    }

    return children;
};

Permission.defaultProps = {
    hasAny: false,
    fallback: null,
    interceptor: () => false,
};

export default Permission;
