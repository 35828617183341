import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedHTMLMessage } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { updateConsentByToken } from '@app/crud/customers/customer.crud';

import { GDPR_FIELDS } from '@app/pages/customers/Helpers/Customer';

import SwitchInput from '@app/partials/content/SwitchInput';
import toast from '@app/partials/content/Toast';

const ConsentItem = ({ title, icon, active }) => (
    <span className={`label label-inline label-outline-${active ? 'success' : 'danger'} px-3 py-1 mr-2`}>
        <i className={`la la-lg ${icon} mr-1`} />
        {title}
    </span>
);

export default function EditForm({ token, data, context }) {
    const [isLoading, setLoading] = useState(false);
    const [isComplete, setComplete] = useState(false);
    const [updatedConsents, setUpdatedConsents] = useState({});
    const methods = useForm();
    const { getValues } = methods;
    const { entity } = data.dealership.name;
    const { consents } = data;

    const editConsent = () => {
        const { new_consents } = getValues();
        setLoading(true);

        updateConsentByToken(token, new_consents)
            .then((r) => {
                const { gdprEmail: email, gdprPhone: phone, gdprSms: sms, gdprPost: post } = r.result;
                setUpdatedConsents({
                    email,
                    phone,
                    sms,
                    post,
                });
                setComplete(true);
            })
            .catch(() => {
                // Display a toast error message
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'USER.CONSENT.UPDATE.FAILED' }),
                });

                setLoading(false);
            });
    };

    if (isComplete) {
        const { email, phone, sms, post } = updatedConsents;
        return (
            <div className="text-center">
                <h1 className="consent-layout__title">
                    <FormattedHTMLMessage id="CONSENT_PUBLIC.SUCCESS.TITLE" />
                </h1>
                <p>
                    <FormattedHTMLMessage id="CONSENT_PUBLIC.SUCCESS" values={{ entity }} />
                </p>

                <div className="consent-layout__consents">
                    <ConsentItem title="Email" icon="la-envelope" active={email} />
                    <ConsentItem title="Téléphone" icon="la-mobile" active={phone} />
                    <ConsentItem title="SMS" icon="la-comments" active={sms} />
                    <ConsentItem title="Courrier" icon="la-file" active={post} />
                </div>
            </div>
        );
    }

    return (
        <div className="text-center">
            <h1 className="consent-layout__title">
                <FormattedHTMLMessage id={`CONSENT_PUBLIC.TITLE.${context}`} tagName="div" />
                <span>{entity}</span>
            </h1>

            <p>
                <FormattedHTMLMessage id={`CONSENT_PUBLIC.INTRODUCTION.${context}`} values={{ entity }} />
            </p>

            <FormProvider {...methods}>
                <div className="consent-layout__edit-consents d-flex justify-content-center flex-wrap">
                    {Object.values(GDPR_FIELDS).map((gdprField) => (
                        <div key={gdprField.slug} className="mr-4">
                            <SwitchInput
                                name={`new_consents.${gdprField.map}`}
                                active={consents[gdprField.map]}
                                labelFirst=""
                                className="h-100 mr-1"
                                labelSecond={Intl.formatMessage({ id: gdprField.label })}
                            />
                        </div>
                    ))}
                </div>
            </FormProvider>
            <div className="consent-layout__submit">
                <button type="button" className="btn btn-success" onClick={editConsent} disabled={isLoading}>
                    {isLoading && <span className="spinner-border" />}
                    {!isLoading && <span>{Intl.formatMessage({ id: 'CONSENT_PUBLIC.SUBMIT' })}</span>}
                </button>
            </div>
        </div>
    );
}
