import dayjs from 'dayjs';
import produce from 'immer';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { updateDefaultSchedule } from '@app/crud/account/account.crud';

import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';
import toast from '@app/partials/content/Toast';

import { DEFAULT_WEEK, PLANNING } from '../Helpers/Planning';

import WeekRow from './WeekRow';

const DefaultWeek = ({ userData, defaultSchedule, onSectionChange, profile, fetchDefaultPlanning }) => {
    const methods = useForm();
    const { control, getValues, setValue } = methods;
    const date = dayjs().month(0).date(1).weekday(0);
    const [loading, setLoading] = useState(false);
    const days = new Array(7).fill(undefined).map((value, idx) => date.add(idx, 'day'));

    const handleUpdate = (day, start, end, absent, hour) =>
        new Promise((resolve) => {
            const dayName = day.locale('en').format('dddd').toLowerCase();
            const formValue = getValues('defaultSchedule');

            setValue(
                'defaultSchedule',
                produce(formValue, (draftFromValue) => {
                    if (!draftFromValue[dayName]) {
                        draftFromValue[dayName] = {};
                    }

                    draftFromValue[dayName][hour] = {
                        start,
                        end,
                        absent,
                    };
                }),
            );

            resolve();
        });

    const handleValidate = () => {
        setLoading(true);

        updateDefaultSchedule(userData.id, getValues('defaultSchedule'))
            .then((response) => {
                setLoading(false);
                if (response?.result) {
                    onSectionChange(PLANNING);
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.DEFAULT_WEEK.UPDATE.SUCCESS' }),
                    });
                } else {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.DEFAULT_WEEK.UPDATE.FAILED' }),
                    });
                }
                fetchDefaultPlanning(userData.id);
            })
            .catch((response) => {
                var errorMsg = '';
                var errorKey =
                        'USER.PROFILE.PLANNING.DEFAULT_WEEK.UPDATE.FAILED.' +
                        ('' + response.result?.error_data).toUpperCase().replaceAll(' ', '_'),
                    errorMsg = Intl.formatMessage({ id: errorKey });
                if (errorKey == errorMsg) {
                    errorMsg = Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.DEFAULT_WEEK.UPDATE.FAILED' });
                }

                setLoading(false);
                toast({
                    variant: 'danger',
                    message: errorMsg,
                });
            });
    };

    return (
        <FormProvider {...methods}>
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <div className="d-flex mb-7  align-items-center justify-content-between">
                <div className="font-size-h5 font-weight-bold">
                    {profile
                        ? Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.CONFIG' })
                        : Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.ADMIN.CONFIG' })}
                </div>
                {Object.keys(defaultSchedule).length > 0 && (
                    <Button variant="outline-primary" onClick={() => onSectionChange(PLANNING)}>
                        {Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.BACK' })}
                    </Button>
                )}
            </div>
            {profile && (
                <div className="mb-10 font-size-sm">
                    {Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.DEFAULT_WEEK.TITLE' })}
                    <br />
                    <span className="text-danger">
                        {' '}
                        {Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.PLANNING.SUBTITLE' })}
                    </span>
                </div>
            )}
            <Controller
                control={control}
                defaultValue={defaultSchedule}
                name="defaultSchedule"
                render={(props) => (
                    <WeekRow
                        days={days}
                        availabilities={props.value}
                        section={DEFAULT_WEEK}
                        onUpdate={handleUpdate}
                        permissions={['LEAD_ADD_TYPICAL_PLANNING_PROFIL', 'LEAD_UPDATE_TYPICAL_PLANNING_PROFIL']}
                    />
                )}
            />
            <Permission permissions={['LEAD_ADD_TYPICAL_PLANNING_PROFIL', 'LEAD_UPDATE_TYPICAL_PLANNING_PROFIL']}>
                <div className="mt-10 d-flex justify-content-end">
                    {Object.keys(defaultSchedule).length > 0 && (
                        <Button variant="outline-secondary mr-5" onClick={() => onSectionChange(PLANNING)}>
                            {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                        </Button>
                    )}
                    <Button variant="primary" onClick={() => handleValidate()}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                    </Button>
                </div>
            </Permission>
        </FormProvider>
    );
};

export default DefaultWeek;
