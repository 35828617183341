import React from 'react';

import { LayoutContextConsumer } from '@src/_metronic';
import { Intl } from '@src/_metronic/i18n/I18nProvider';
import BreadCrumbsHome from '@src/_metronic/layout/sub-header/components/BreadCrumbsHome';

import subHeader from '@app/partials/HoC/subHeader';

const CampaignsSubHeader = (props) => {
    const { subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle } = props;

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            type="submit"
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}

                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">{Intl.formatMessage({ id: 'MENU.CAMPAIGNS' })}</h3>
                                <BreadCrumbsHome />
                            </>
                        )}
                    </LayoutContextConsumer>
                </div>
            </div>
        </div>
    );
};

export default subHeader(CampaignsSubHeader);
