import React from 'react';
import { Form } from 'react-bootstrap';
import { UseFormMethods } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { RBRef } from '@app/pages/customers/Modals/APVRequest';

import ErrorForm from '@app/partials/layout/ErrorForm';

type CreateNoteFormProps = {
    title: string;
    form: UseFormMethods;
};

const CreateNoteForm = ({ title, form }: CreateNoteFormProps) => {
    const Intl = useIntl();

    return (
        <>
            <div>{title}</div>
            <Form.Group className="mt-5">
                <Form.Control
                    as="textarea"
                    ref={
                        form.register({
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                        }) as RBRef
                    }
                    name="message"
                    rows={10}
                />
                <ErrorForm errors={form.errors} name="message" />
            </Form.Group>
        </>
    );
};

export default CreateNoteForm;
