import produce from 'immer';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import useAction from '@app/pages/customers/Hooks/useAction';
import DealershipSelect from '@app/pages/customers/Partials/DealershipSelect';
import { actions } from '@app/store/modal/modal.store';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

const LeadManufacturer = ({ showModal, setShowModal }) => {
    const methods = useForm();
    const dispatch = useDispatch();
    const { handleSubmit } = methods;
    const Intl = useIntl();
    const { data: customer } = useSelector((state) => state.customer.customer);

    const [loading, createAction] = useAction({
        type: 'init',
        success: (response) => {
            dispatch(
                actions.modalChange('next_action', {
                    leadId: response.result.lead.id,
                }),
            );
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_MANUFACTURER.ERROR' }),
            });
        },
        dataInterceptor: (data) =>
            produce(data, (draftData) => {
                draftData.lead.type = 'constructeur';
                draftData.lead.source = 'manufacturer';
                draftData.lead.origin = 'operator';
            }),
    });

    const onSubmit = (data) => {
        createAction(data);
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-bullseye" />}
            title={Intl.formatMessage({ id: 'CUSTOMER.MODAL.LEAD_MANUFACTURER.TITLE' })}
            body={
                <FormProvider {...methods}>
                    <DealershipSelect methods={methods} customer={customer} />
                </FormProvider>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(onSubmit, onError)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default LeadManufacturer;
