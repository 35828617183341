import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { isPublishAutosphere } from '@app/pages/stock/helpers/StockRules';
import { RootState } from '@app/store/rootDuck';
import { Vehicle } from '@app/store/stock/stock.type';

import Label from '@app/partials/content/Label';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';
import { getFluidityUrl, getUrl } from '@app/partials/content/Vehicle/VehicleHelpers';

type StockVehiclesPublicationColumnProps = {
    vehicle: Vehicle;
};

export default function StockVehiclesPublicationColumn({ vehicle }: StockVehiclesPublicationColumnProps) {
    const Intl = useIntl();
    const currentState = (value: boolean) => isPublishAutosphere(vehicle) && value;
    const isContextEFB = useSelector((state: RootState) => state.auth.userData.context === 'efb');
    const currentStateEFB = isPublishAutosphere(vehicle, isContextEFB) && isContextEFB;

    const labelHandler = (item: boolean, label: string, url: string, tooltipId: string) => (
        <a target="_blank" {...(url ? { href: url } : {})} key={label} className="mb-2">
            <OverlayTooltip
                label={`STOCK_VEHICLES.TABLE.${item ? '' : 'NO_'}${tooltipId.toUpperCase()}_TOOLTIP`}
                id={tooltipId}
                position="left"
            >
                <Label
                    variant={item ? 'success' : 'light'}
                    className={`font-weight-bold label-publication ${
                        item ? 'text-success rounded-0 btn' : 'text-danger-lite'
                    }`}
                >
                    <i className={`mr-2 las la-eye la-2x ${item ? 'text-success' : 'text-danger-lite'} p-0`} />
                    {label}
                </Label>
            </OverlayTooltip>
        </a>
    );

    return (
        <div className="d-flex flex-column">
            {isContextEFB ? (
                labelHandler(
                    currentStateEFB,
                    Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.A_BE' }),
                    currentStateEFB && getUrl(vehicle, '-', true),
                    'publication_abe',
                )
            ) : (
                <>
                    {labelHandler(
                        currentState(vehicle.is_afr),
                        Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.A_FR' }),
                        currentState(vehicle.is_afr) && getUrl(vehicle),
                        'publication_afr',
                    )}
                    {labelHandler(
                        currentState(vehicle.is_delivery),
                        Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.FW' }),
                        currentState(vehicle.is_delivery) && getUrl(vehicle),
                        'publication_fw',
                    )}
                    {labelHandler(
                        currentState(vehicle.is_fluidite),
                        Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.FLUIDITY' }),
                        currentState(vehicle.is_fluidite) && getFluidityUrl(vehicle),
                        'publication_fluidity',
                    )}
                </>
            )}
        </div>
    );
}
