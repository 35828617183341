import classNames from 'classnames';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import useFetch from '@app/hooks/useFetch';

import { VehicleKnow } from '@app/crud/customers/customer.type';
import { checkCoyote } from '@app/crud/securyweb/securyweb.crud';
import { Vehicle } from '@app/crud/securyweb/securyweb.types';

import variantTypes from '@app/constants';
import { useAppSelector } from '@app/hooks';
import { FUNDING_MODE, VEHICLE_CATEGORY } from '@app/pages/customers/Modals/SecuryCarContract/FormOptions';
import {
    isFieldDisabled,
    isFieldRequired,
} from '@app/pages/customers/Modals/SecuryCarContract/Helpers/SecurywebHelper';
import useFilteredBrandsAndModels from '@app/pages/customers/Modals/SecuryCarContract/Hooks/useFilteredBrandsAndModels';
import SecuryCarContractStepSearchVehicle from '@app/pages/customers/Modals/SecuryCarContract/SecuryCarContractStepSearchVehicle';

import EditableDatePicker from '@app/partials/content/DateTimePicker/EditableDatePicker';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

import FormInputText from '@app/components/forms/FormInputText/FormInputText';

const SecuryCarContractStepVehicle = () => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { control, setValue, formState, watch } = methods;
    const { errors } = formState;

    const { params } = useAppSelector((state) => state.modal);
    const [showSearchVehicleModal, setShowSearchVehicleModal] = useState(false);
    const defaultVehicule = params ? params?.formValues?.vehicle : null;
    const [vehicleKnow, setVehicleKnow] = useState(defaultVehicule);
    const { filteredBrands, filteredModels, filterBrands, filterModels, loadModels } = useFilteredBrandsAndModels();

    const { data: customer } = useAppSelector((state) => state.customer.customer);

    const formBrandValue = watch('vehicle.brand');

    const productIsCoyote = watch('productIsCoyote');
    const vehicleVin = watch('vehicle.vin');
    const vehicleRegistrationNumber = watch('vehicle.registrationNumber');
    const showTracker = productIsCoyote && vehicleVin;

    const { fetch: fetchIsCoyote, data: isOkCoyote } = useFetch({
        fetchAction: checkCoyote,
        resultInterceptor: (response) => response?.result || false,
    });

    const onSelectVehicle = (vehicle: Vehicle) => {
        if (vehicle?.id) {
            setValue('vehicle.id', vehicle.id, {
                shouldValidate: formState.isSubmitted,
            });
        }
        if (vehicle?.modele?.id) {
            setValue('vehicle.modelId', vehicle.modele.id, {
                shouldValidate: formState.isSubmitted,
            });
        }
        if (vehicle?.modele?.marque?.libelle) {
            setValue('vehicle.brand', vehicle.modele.marque.libelle, {
                shouldValidate: formState.isSubmitted,
            });
        }
        if (vehicle?.modele?.libelle) {
            setValue('vehicle.model', vehicle.modele.libelle, {
                shouldValidate: formState.isSubmitted,
            });
        }
        if (vehicle?.immatriculation) {
            setValue('vehicle.registrationNumber', vehicle.immatriculation, {
                shouldValidate: formState.isSubmitted,
            });
        }
        if (vehicle?.codeVin) {
            setValue('vehicle.vin', vehicle.codeVin, {
                shouldValidate: formState.isSubmitted,
            });
        }
        if (vehicle?.datePremiereMiseEnCirculation) {
            setValue('vehicle.registrationNumberDate', vehicle.datePremiereMiseEnCirculation, {
                shouldValidate: formState.isSubmitted,
            });
        }
        if (vehicle?.typeCnit) {
            setValue('vehicle.mine', vehicle.typeCnit, {
                shouldValidate: formState.isSubmitted,
            });
        }
        if (vehicle?.genre) {
            setValue('vehicle.vehicleCategory', vehicle.genre, {
                shouldValidate: formState.isSubmitted,
            });
        }
    };

    const onChangeCheckCoyote = (vin: string) => {
        const productCode = watch('product') as string;

        if (productIsCoyote) {
            if (vin && productCode) {
                fetchIsCoyote({ vin, productCode }).catch(() => {
                    toast({
                        variant: variantTypes.DANGER,
                        message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                    });
                });
            }
        }
    };

    useEffect(() => {
        if (formBrandValue) {
            loadModels(formBrandValue);
        }
    }, [formBrandValue, loadModels]);

    useEffect(() => {
        onChangeCheckCoyote(vehicleVin);
    }, [vehicleVin, productIsCoyote]);

    const vehicleIsNew = dayjs(watch('vehicle.registrationNumberDate')).isSame(
        watch('vehicle.registrationDocumentDate'),
    );

    const selectVehicleKnowOptions = () => {
        const options = Array.isArray(customer.vehicleKnow) ? [...customer.vehicleKnow] : [];

        if (params && params?.formValues?.vehicle) {
            const index = options.findIndex(
                (option) =>
                    option.registrationNumber?.replaceAll('-', '') === params.formValues.vehicle.registrationNumber,
            );
            if (index !== -1 && params.formValues.vehicle.brand) {
                options[index] = { ...params.formValues.vehicle };
            } else {
                options.push({ ...params?.formValues?.vehicle });
            }
        }

        // Remove option with empty data if it exists
        const emptyOptionIndex = options.findIndex((option) => !option.brand);
        if (emptyOptionIndex !== -1) {
            options.splice(emptyOptionIndex, 1);
        }

        if (!options.some((option) => !option.brand)) {
            options.push({
                bodyType: '',
                brand: '',
                circulationDate: '',
                color: '',
                energy: '',
                lastActivityBetweenThem: '',
                lastMileageKnown: '',
                lastProductType: '',
                model: '',
                registrationNumber: '',
                registrationNumberDate: '',
                sellingPrice: '',
                vehicleUuid: '',
                vin: '',
                version: '',
            });
        }

        return options;
    };

    useEffect(() => {
        if (vehicleKnow) {
            setValue('vehicle.id', '');
            setValue('vehicle.modelId', '');
            setValue('vehicle.mine', '');
            setValue('vehicle.vin', '');
            setValue('vehicle.vehicleCategory', '');
            setValue('vehicle.registrationDocumentDate', '');
            Object.keys(vehicleKnow).forEach((key) => {
                setValue(`vehicle.${key}`, vehicleKnow[key as keyof VehicleKnow], {
                    shouldValidate: formState.isSubmitted,
                });
            });
        }
    }, [vehicleKnow]);

    useEffect(() => {
        const defaultIsOkCoyote = Array.isArray(isOkCoyote) ? null : isOkCoyote;
        setValue('isOkCoyote', defaultIsOkCoyote, { shouldValidate: formState.isSubmitted });
    }, [isOkCoyote]);

    const searchVehicleModal = () => {
        setShowSearchVehicleModal(true);
    };

    return (
        <>
            <Controller
                control={control}
                name="vehicle.id"
                render={({ value }) => <input type="hidden" value={value} />}
            />
            <Controller
                control={control}
                name="vehicle.modelId"
                render={({ value }) => <input type="hidden" value={value} />}
            />
            {vehicleKnow && (
                <Controller
                    control={control}
                    name="vehicle.paymentMode"
                    defaultValue="CPT"
                    render={({ value }) => <input type="hidden" value={value} />}
                />
            )}
            <Row>
                <Col lg={12}>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SELECT_VEHICLE" />
                        </Form.Label>
                        <Dropdown
                            value={vehicleKnow}
                            options={selectVehicleKnowOptions()}
                            onChange={(e) => setVehicleKnow(e.value)}
                            itemTemplate={(item) =>
                                item.vehicleUuid && item.brand ? (
                                    `${item.brand} ${item.model} ${
                                        item?.registrationNumber ? `(${item?.registrationNumber})` : ''
                                    }`
                                ) : (
                                    <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.NEW_VEHICLE" />
                                )
                            }
                            {...(vehicleKnow && {
                                valueTemplate: (item) =>
                                    item?.vehicleUuid && item?.brand ? (
                                        `${item.brand} ${item.model} ${
                                            item?.registrationNumber ? `(${item?.registrationNumber})` : ''
                                        }`
                                    ) : (
                                        <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.NEW_VEHICLE" />
                                    ),
                            })}
                            optionLabel="brand"
                            placeholder={Intl.formatMessage({
                                id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.SELECT_VEHICLE',
                            })}
                            className={classNames(`form-control`, {
                                'is-invalid': !!errors?.vehicle,
                            })}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <div
                className={classNames({
                    'd-none': !vehicleKnow,
                })}
            >
                <Row>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.REGISTRATION" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="vehicle.registrationNumber"
                                rules={{
                                    required:
                                        isFieldRequired('vehicle.registrationNumber', watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                render={({ value, onChange }) => (
                                    <Container fluid>
                                        <Row>
                                            <Col lg={10} className="px-0">
                                                <InputText
                                                    value={value}
                                                    onChange={(e) => onChange(e.currentTarget.value)}
                                                    className={classNames(`form-control`, {
                                                        'is-invalid': !!errors?.vehicle?.registrationNumber,
                                                    })}
                                                    placeholder={Intl.formatMessage({
                                                        id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.REGISTRATION',
                                                    })}
                                                    disabled={
                                                        !!vehicleKnow?.registrationNumber ||
                                                        isFieldDisabled('vehicle.registrationNumber', watch('statut'))
                                                    }
                                                />
                                            </Col>
                                            <Col lg={2}>
                                                <Button
                                                    onClick={() => searchVehicleModal()}
                                                    disabled={!!vehicleKnow?.brand}
                                                >
                                                    <i className="las la-search p-0" />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                )}
                            />
                            <ErrorForm errors={errors} name="vehicle.registrationNumber" />
                        </Form.Group>
                    </Col>
                    <Col lg={5}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.REGISTRATION_DATE" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="vehicle.registrationNumberDate"
                                rules={{
                                    required:
                                        isFieldRequired('vehicle.registrationNumberDate', watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                render={({ value, onChange }) => (
                                    <EditableDatePicker
                                        value={value || ''}
                                        placeholder="JJ/MM/AAAA"
                                        maxDate={dayjs().toDate()}
                                        className={classNames({
                                            'is-invalid': errors?.vehicle?.registrationNumberDate,
                                        })}
                                        onChange={(e: { value: Date }) => onChange(e.value)}
                                        canClear={!vehicleKnow?.registrationNumberDate}
                                        disabled={isFieldDisabled('vehicle.registrationNumberDate', watch('statut'))}
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name="vehicle.registrationNumberDate" />
                        </Form.Group>
                    </Col>
                    <Col lg={1}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.TYPE" />
                            </Form.Label>
                            <InputText className="p-3 w-100" value={vehicleIsNew ? 'VN' : 'VO'} disabled />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.VIN" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="vehicle.vin"
                                rules={{
                                    required:
                                        isFieldRequired('vehicle.vin', watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    validate: (value) => {
                                        if (value && value.length !== 17) {
                                            return Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ERROR.VIN',
                                            });
                                        }
                                    },
                                }}
                                render={({ value, onChange }) => (
                                    <Container fluid>
                                        <Row>
                                            <Col lg={10} className="px-0">
                                                <InputText
                                                    value={value}
                                                    onChange={(e) => onChange(e.currentTarget.value)}
                                                    className={classNames(`form-control`, {
                                                        'is-invalid': !!errors?.vehicle?.vin,
                                                    })}
                                                    placeholder={Intl.formatMessage({
                                                        id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.VIN',
                                                    })}
                                                    disabled={
                                                        !!vehicleKnow?.vin ||
                                                        isFieldDisabled('vehicle.vin', watch('statut'))
                                                    }
                                                />
                                            </Col>
                                            <Col lg={2}>
                                                <Button
                                                    onClick={() => searchVehicleModal()}
                                                    disabled={!!vehicleKnow?.brand}
                                                >
                                                    <i className="las la-search p-0" />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                )}
                            />
                            <ErrorForm errors={errors} name="vehicle.vin" />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.REGISTRATION_DOCUMENT" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="vehicle.registrationDocumentDate"
                                rules={{
                                    required:
                                        isFieldRequired('vehicle.registrationDocumentDate', watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                render={({ value, onChange }) => (
                                    <EditableDatePicker
                                        value={value || ''}
                                        placeholder="JJ/MM/AAAA"
                                        maxDate={dayjs().toDate()}
                                        className={classNames({
                                            'is-invalid': errors?.vehicle?.registrationDocumentDate,
                                        })}
                                        onChange={(e: { value: Date }) => onChange(e.value)}
                                        canClear={!vehicleKnow?.registrationDocumentDate}
                                        disabled={isFieldDisabled('vehicle.registrationDocumentDate', watch('statut'))}
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name="vehicle.registrationDocumentDate" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <Form.Group>
                            <Controller
                                control={control}
                                name="vehicle.mine"
                                rules={{
                                    required:
                                        isFieldRequired('vehicle.mine', watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    validate: (value) => {
                                        if (value && (value.length < 12 || value.length > 15)) {
                                            return Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ERROR.MINE',
                                            });
                                        }
                                    },
                                }}
                                render={() => (
                                    <FormInputText
                                        name="vehicle.mine"
                                        label={<FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.MINE" />}
                                        isInvalid={!!errors?.vehicle?.mine}
                                        required={isFieldRequired('vehicle.mine', watch('statut'))}
                                        placeholder="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.MINE"
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.BRAND" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="vehicle.brand"
                                rules={{
                                    required:
                                        isFieldRequired('vehicle.brand', watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                render={({ value, onChange }) => (
                                    <AutoComplete
                                        value={value}
                                        field="Name"
                                        suggestions={filteredBrands}
                                        completeMethod={filterBrands}
                                        className="w-100"
                                        inputClassName={classNames('form-control', {
                                            'is-invalid': errors?.vehicle?.brand,
                                        })}
                                        placeholder={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.BRAND',
                                        })}
                                        onSelect={(e) => onChange(e.value.Name)}
                                        onChange={(e) => onChange(e.value)}
                                        disabled={
                                            !!vehicleKnow?.brand || isFieldDisabled('vehicle.brand', watch('statut'))
                                        }
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name="vehicle.brand" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.VEHICLE_CATEGORY" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="vehicle.vehicleCategory"
                                rules={{
                                    required:
                                        isFieldRequired('vehicle.vehicleCategory', watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                render={({ value, onChange }) => (
                                    <Dropdown
                                        value={value}
                                        options={VEHICLE_CATEGORY}
                                        onChange={(e) => onChange(e.value)}
                                        className={classNames({
                                            'is-invalid': errors?.vehicle?.vehicleCategory,
                                        })}
                                        optionLabel="title"
                                        optionValue="code"
                                        itemTemplate={(item) => <FormattedMessage id={item.title} />}
                                        {...(value && {
                                            valueTemplate: (item, { placeholder }) => {
                                                if (!item) {
                                                    return placeholder;
                                                }
                                                return <FormattedMessage id={item.title} />;
                                            },
                                        })}
                                        placeholder={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.VEHICLE_CATEGORY',
                                        })}
                                        disabled={isFieldDisabled('vehicle.vehicleCategory', watch('statut'))}
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name="vehicle.vehicleCategory" />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.MODEL" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="vehicle.model"
                                rules={{
                                    required:
                                        isFieldRequired('vehicle.model', watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                render={({ value, onChange }) => (
                                    <AutoComplete
                                        value={value}
                                        field="Name"
                                        suggestions={filteredModels}
                                        completeMethod={filterModels}
                                        className="w-100"
                                        inputClassName={classNames('form-control', {
                                            'is-invalid': errors?.vehicle?.model,
                                        })}
                                        placeholder={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.MODEL',
                                        })}
                                        onSelect={(e) => onChange(e.value.Name)}
                                        onChange={(e) => onChange(e.value)}
                                        disabled={
                                            !!vehicleKnow?.model || isFieldDisabled('vehicle.model', watch('statut'))
                                        }
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name="vehicle.model" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.FUNDING_PRICE" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="vehicle.fundingPrice"
                                rules={{
                                    required:
                                        isFieldRequired('vehicle.fundingPrice', watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                render={({ value, onChange }) => (
                                    <NumberFormat
                                        value={value}
                                        className={classNames('form-control', {
                                            'is-invalid': errors?.vehicle?.fundingPrice,
                                        })}
                                        thousandSeparator=" "
                                        onValueChange={(e) => onChange(e.value)}
                                        placeholder={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.FUNDING_PRICE',
                                        })}
                                        disabled={isFieldDisabled('vehicle.fundingPrice', watch('statut'))}
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name="vehicle.fundingPrice" />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.FUNDING_MODE" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="vehicle.fundingMode"
                                rules={{
                                    required:
                                        isFieldRequired('vehicle.fundingMode', watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                render={({ value, onChange }) => (
                                    <Dropdown
                                        value={value}
                                        options={FUNDING_MODE}
                                        onChange={(e) => onChange(e.value)}
                                        className={classNames({
                                            'is-invalid': errors?.vehicle?.fundingMode,
                                        })}
                                        optionLabel="title"
                                        optionValue="code"
                                        itemTemplate={(item) => <FormattedMessage id={item.title} />}
                                        {...(value && {
                                            valueTemplate: (item, { placeholder }) => {
                                                if (!item) {
                                                    return placeholder;
                                                }
                                                return <FormattedMessage id={item.title} />;
                                            },
                                        })}
                                        placeholder={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.FUNDING_MODE',
                                        })}
                                        disabled={isFieldDisabled('vehicle.fundingMode', watch('statut'))}
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name="vehicle.fundingMode" />
                        </Form.Group>
                    </Col>
                </Row>
                {showTracker && !Array.isArray(isOkCoyote) && (
                    <>
                        <Row>
                            <Col>
                                <p>
                                    <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.VEHICLE.COYOTE.TITLE" /> :
                                </p>
                                <Alert
                                    variant={clsx({
                                        success: watch('isOkCoyote'),
                                        danger: !watch('isOkCoyote'),
                                    })}
                                >
                                    <div className="d-flex align-items-center">
                                        <div>
                                            {watch('isOkCoyote') ? (
                                                <i className="las la-check-circle la-2x text-white mr-2" />
                                            ) : (
                                                <i className="las la-times-circle la-2x text-white mr-2" />
                                            )}
                                        </div>
                                        <div>
                                            {watch('isOkCoyote') ? (
                                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.VEHICLE.COYOTE.FOUND" />
                                            ) : (
                                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.VEHICLE.COYOTE.NOT_FOUND" />
                                            )}
                                        </div>
                                    </div>
                                </Alert>
                            </Col>
                        </Row>
                        <Controller
                            control={control}
                            name="isOkCoyote"
                            defaultValue={isOkCoyote}
                            rules={{
                                required: true,
                                validate: (value) => value === true,
                            }}
                            render={({ value }) => <input type="hidden" required value={value} />}
                        />
                    </>
                )}
            </div>
            <SecuryCarContractStepSearchVehicle
                showModal={showSearchVehicleModal}
                setShowModal={setShowSearchVehicleModal}
                onSelectVehicle={onSelectVehicle}
                params={{ immat: vehicleRegistrationNumber, vin: vehicleVin }}
            />
        </>
    );
};

export default SecuryCarContractStepVehicle;
