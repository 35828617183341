export const actionTypes = {
    ModalChange: 'MODAL_CHANGE',
    RegisterModal: 'REGISTER_CHANGE',
    RollBackModal: 'ROLLBACK_MODAL',
    CloseModals: 'CLOSE_MODALS',
};

const initialState = {
    modal: null,
    params: null,
    history: [],
    modals: [],
};

export function modalReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.ModalChange: {
            let history = [...(state.history || [])];
            const { modal } = action.payload;
            const params = action.payload?.params || null;

            if (action.payload.modal) {
                history = [
                    ...history,
                    {
                        modal: action.payload.modal,
                        params,
                    },
                ];
            } else {
                history = [];
            }

            return {
                ...state,
                modal,
                history,
                params,
            };
        }
        case actionTypes.RollBackModal: {
            const history = [...(state.history || [])];

            history.pop();
            const modal = history.length > 0 ? history[history.length - 1].modal : null;
            const params = history.length > 0 ? history[history.length - 1].params : null;

            return {
                ...state,
                modal,
                history,
                params,
            };
        }
        case actionTypes.RegisterModal: {
            let { modals } = state;

            if (!state.modals.find((registeredModal) => registeredModal === action.payload)) {
                modals = [...(state.modals || []), action.payload];
            }

            return {
                ...state,
                modals,
            };
        }
        case actionTypes.CloseModals: {
            return {
                ...state,
                modal: null,
                history: [],
                params: null,
            };
        }
        default:
            return state;
    }
}

export const actions = {
    modalChange: (modal, params, roolback) => ({ type: actionTypes.ModalChange, payload: { modal, params, roolback } }),
    registerModal: (modal) => ({ type: actionTypes.RegisterModal, payload: modal }),
    rollBackModal: () => ({ type: actionTypes.RollBackModal }),
    closeModals: () => ({ type: actionTypes.CloseModals }),
};
