import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';

import { acceptVehiclePriceRecommendation, confirmVehiclePriceModification } from '@app/crud/stock/stock.crud';

import { useAppDispatch, useAppSelector } from '@app/hooks';
import { actions } from '@app/store/stock/stock.store';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

import { addProcessingPricing } from '../todo/helpers/ProcessingPricing';

type ConfirmModalProps = {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    refresh: () => void;
};

const ConfirmModal = ({ showModal, setShowModal, refresh }: ConfirmModalProps) => {
    const Intl = useIntl();
    const [loading, setLoading] = useState(false);
    const { params } = useAppSelector((state) => state.modal);
    const dispatch = useAppDispatch();

    const vehicles = params?.vehicles;
    const licenseNumber = params?.licenseNumber;
    const checkMode = params?.checkMode;
    const multiple = vehicles && Object.keys(vehicles).length > 1;
    const acceptMethod = checkMode ? confirmVehiclePriceModification : acceptVehiclePriceRecommendation;
    const closeModal = () => {
        setShowModal(false);
    };

    const onConfirm = () => {
        setLoading(true);

        const formattedVehicles = checkMode
            ? Object.keys(vehicles)
            : Object.values(vehicles).reduce((acc: any, item: any) => {
                  acc[item.sku] = { newPrice: multiple ? item.pricing.recoPrice : item.newPrice };
                  return acc;
              }, {});

        // @ts-ignore
        acceptMethod(checkMode ? Object.keys(vehicles) : multiple ? formattedVehicles : vehicles)
            .then(() => {
                dispatch(actions.UpdateVehicleSelected([]));
                addProcessingPricing(Object.keys(vehicles));
                setShowModal(false);
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'STOCK_VEHICLES.MODAL.PRICE.CONFIRM.REASON.CONFIRMED' }),
                });
                if (refresh) refresh();
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'STOCK_VEHICLES.MODAL.PRICE.CONFIRM.REASON.FAILED' }),
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {vehicles && (
                <ModalDefault
                    show={showModal}
                    loading={loading}
                    hideModal={() => closeModal()}
                    icon={
                        multiple ? (
                            <i className="text-success las la-money-bill-wave fa-2x" />
                        ) : (
                            <i className="text-success las la-check-circle fa-2x" />
                        )
                    }
                    title={
                        <span className="text-success">
                            {multiple ? (
                                <FormattedHTMLMessage id="STOCK.PRICING.MODAL.CONFIRM.MULTIPLE.TITLE" />
                            ) : checkMode ? (
                                <FormattedHTMLMessage id="STOCK_VEHICLES.MODAL.PRICE.CONFIRM.CHECK.TITLE" />
                            ) : (
                                <FormattedHTMLMessage id="STOCK_VEHICLES.MODAL.PRICE.CONFIRM.TITLE" />
                            )}
                            {licenseNumber && (
                                <>
                                    <br />
                                    <strong>{licenseNumber}</strong>
                                </>
                            )}
                        </span>
                    }
                    body={
                        <div className="d-flex flex-column align-items-center h-100" style={{ minHeight: '10vh' }}>
                            <p className="text-center">
                                {multiple ? (
                                    <FormattedHTMLMessage
                                        id="STOCK.PRICING.MODAL.CONFIRM.MULTIPLE.TEXT"
                                        values={{
                                            vehicles: Object.keys(vehicles).length,
                                            checkMode,
                                        }}
                                    />
                                ) : checkMode ? (
                                    <FormattedHTMLMessage id="STOCK_VEHICLES.MODAL.PRICE.CONFIRM.CHECK.TEXT" />
                                ) : (
                                    <FormattedHTMLMessage id="STOCK_VEHICLES.MODAL.PRICE.CONFIRM.TEXT" />
                                )}
                            </p>
                        </div>
                    }
                    footer={
                        <div className="d-flex justify-content-center">
                            <Button className="mr-2" variant="outline-primary" onClick={() => closeModal()}>
                                <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.CANCEL" />
                            </Button>
                            <Button variant="primary" onClick={() => onConfirm()}>
                                <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.CONFIRM" />
                            </Button>
                        </div>
                    }
                />
            )}
        </>
    );
};

export default ConfirmModal;
