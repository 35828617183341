import React from 'react';

import DashboardActivityManagerAutosphereContact from '../../partials/Activity/DashboardActivityManagerAutosphereContact';
import { DashboardAgenda } from '../../partials/Agenda';
import DashboardQlik from '../../partials/Stats/DashboardQlik';

import './_dashboardManagerAutosphereContact.scss';

const DashboardManagerAutopshereContact = () => (
    <div className="dashboard-role d-flex flex-column">
        <div className="dashboard-actions mt-8 h-100 d-flex">
            <div className="d-flex flex-column">
                <DashboardAgenda />
            </div>
            <DashboardActivityManagerAutosphereContact />
        </div>
        <DashboardQlik className="w-100 mt-8" />
    </div>
);

export default DashboardManagerAutopshereContact;
