import React from 'react';
import { FormattedMessage } from 'react-intl';

import Label from '@app/partials/content/Label';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';

type PublishedLabelProps = {
    variant: string;
};

const PublishedLabel = ({ variant }: PublishedLabelProps) => (
    <OverlayTooltip label="STOCK_VEHICLES.TABLE.PUBLICATION_TOOLTIP" id="publication">
        <Label variant={variant} className="font-weight-bold">
            <FormattedMessage id="STOCK_VEHICLES.TABLE.PUBLISHED" />
        </Label>
    </OverlayTooltip>
);

PublishedLabel.defaultProps = {
    variant: 'warning',
};

export default PublishedLabel;
