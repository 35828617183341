import React from 'react';
import {Form} from "react-bootstrap";
import {useFormContext} from "react-hook-form";

const LeadWonVehicleInternalId = () => {
    const {register} = useFormContext();

    return (
        <Form.Control name="vehicleInternalId"
                      className="kt-hidden"
                      ref={register} />
    );
};

export default LeadWonVehicleInternalId;
