import React from 'react';

import LeadWonBillingTool from './LeadWonBillingTool';
import LeadWonLicenseNumber from './LeadWonLicenseNumber';
import LeadWonName from './LeadWonName';
import LeadWonPurchaseOrderTool from './LeadWonPurchaseOrderTool';
import LeadWonReference from './LeadWonReference';
import LeadWonVehicleInternalId from './LeadWonVehicleInternalId';
import LeadWonWebAd from './LeadWonWebAd';

const LeadWonBuyVehicle = ({ params, listingParams, type, vehicles }) => (
    <>
        <LeadWonPurchaseOrderTool params={params} listingParams={listingParams} type={type} />
        <LeadWonReference params={params} />
        <LeadWonLicenseNumber vehicles={vehicles} type={type} params={params} />
        <LeadWonName params={params} />
        <LeadWonBillingTool params={params} listingParams={listingParams} />
        <LeadWonVehicleInternalId />
        <LeadWonWebAd params={params} type={type} />
    </>
);

LeadWonBuyVehicle.defaultProps = {
    vehicles: [],
};

export default LeadWonBuyVehicle;
