import * as dayjs from 'dayjs';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Divider } from 'primereact/divider';
import { Message } from 'primereact/message';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';

import { routeTo } from '@app/helpers/RoutesHelper';

import { fetchFluidity, updateFluidity } from '@app/crud/customers/fluidity.crud';

import ROUTES from '@app/router/Routes';

import Label from '@app/partials/content/Label';
import Loader from '@app/partials/content/Loader';
import Overflow from '@app/partials/content/Overflow';
import RowInfo from '@app/partials/content/RowInfo';
import toast from '@app/partials/content/Toast';
import VehicleInfo from '@app/partials/content/Vehicle/VehicleInfo';

import StockVehiclePictures from '../stock/vehicle/partials/StockVehiclePictures';

import FluidityHandBand from './HandBand/FluidityHandBand';
import getStatusVariant from './Helpers/Event';
import TransactionUpdate from './Modals/TransactionUpdate';

const Fluidity = () => {
    const { status, id } = useParams();
    const history = useHistory();
    const Intl = useIntl();
    const [type, setType] = useState('');
    const [showTransactionUpdate, setShowTransactionUpdate] = useState(null);
    const { fetch, data, loading, error, setLoading } = useFetch({
        fetchAction: fetchFluidity,
        resultInterceptor: (response) => response || [],
    });

    useEffect(() => {
        setLoading(true);

        updateFluidity(id, { isOpen: true })
            .then((response) => {
                if (response.result) {
                    fetch(id);
                } else {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                    });
                    history.replace(
                        routeTo(ROUTES.FLUIDITIES.PATH, {
                            status,
                        }),
                    );
                }
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                });
                history.replace(
                    routeTo(ROUTES.FLUIDITIES.PATH, {
                        status,
                    }),
                );
            });
    }, [fetch, id]);

    useEffect(() => {
        if (error) {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
            history.replace(
                routeTo(ROUTES.FLUIDITIES.PATH, {
                    status,
                }),
            );
        }
    }, [error]);

    const filterUser = (user) => {
        const isFonctionnal = user?.role?.type === 'fonctionnel';
        const isFluidity = user?.tags?.typologie_lead?.includes?.('fluidite');

        return isFonctionnal && isFluidity;
    };

    const lead = data?.result?.lead || [];

    const events = data?.result?.events || [];

    const dealershipBuyer = data?.result?.dealershipBuyer[0] || [];
    const buyerPhoneLandLine = parsePhoneNumberFromString(dealershipBuyer?.phoneLandline || '', Locale.toUpperCase());

    const dealershipSeller = data?.result?.dealershipSeller[0] || [];
    const sellerPhoneLandLine = parsePhoneNumberFromString(dealershipSeller?.phoneLandline || '', Locale.toUpperCase());

    const usersBuyer = data?.result?.usersBuyer || [];
    const usersSeller = data?.result?.usersSeller || [];

    return (
        <div>
            {loading ? (
                <Loader style={{ width: '5rem', height: '5rem' }} overlay />
            ) : (
                lead?.leadFluidityStatus?.slug === 'in_progress' && (
                    <>
                        <FluidityHandBand
                            lead={lead}
                            handleAction={(value) => {
                                setType(value);
                                setShowTransactionUpdate(true);
                            }}
                        />
                        <Message
                            className="p-inline-message-danger mb-10 mt-5"
                            content={
                                <div className="font-size-h4 d-flex">
                                    <i className="text-danger las la-3x la-exclamation-triangle" />
                                    <div className="ml-10">
                                        Le véhicule est bloqué dans Planet VO car il a été réservé par une autre
                                        concession.
                                        {` `}
                                        <strong>
                                            Merci de ne pas le vendre à un de vos client.
                                            <br />
                                            Ce lead est en cours de traitement par votre supérieur hiérarchique.
                                        </strong>
                                        {` `}
                                        Ce type de lead est uniquement gérable par celui-ci.
                                    </div>
                                </div>
                            }
                        />
                    </>
                )
            )}
            <Row>
                <Col xl={12} className="col-xxl-6">
                    <Card className="shadow-sm">
                        <Card.Header>
                            <i className="card__icon text-initial las la-2x la-user" />
                            {Intl.formatMessage({ id: 'FLUIDITY.VIEW.SELLER.TITLE' })}
                        </Card.Header>
                        <Card.Body className="d-flex flex-column">
                            <div className="flex-grow-1">
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'FLUIDITY.VIEW.SELLER.USUAL_NAME' })}
                                    value={dealershipSeller?.refDealershipUsualname || '-'}
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'FLUIDITY.VIEW.SELLER.ADDRESS' })}
                                    value={
                                        dealershipSeller?.streetnumber || dealershipSeller?.route
                                            ? `${dealershipSeller?.streetnumber || ''} ${dealershipSeller?.route || ''}`
                                            : '-'
                                    }
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'FLUIDITY.VIEW.SELLER.ZIP_CODE' })}
                                    value={dealershipSeller?.postalcode || '-'}
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'FLUIDITY.VIEW.SELLER.CITY' })}
                                    value={dealershipSeller?.locality || '-'}
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'FLUIDITY.VIEW.SELLER.PHONE' })}
                                    value={sellerPhoneLandLine ? sellerPhoneLandLine.formatNational() : '-'}
                                />
                            </div>
                            {usersSeller?.length > 0 && (
                                <div>
                                    <Divider type="dashed" className="my-8" />
                                    <div className="font-size-lg font-weight-semibold">
                                        {Intl.formatMessage({ id: 'FLUIDITY.VIEW.REFERENT' })}
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        {usersSeller.filter(filterUser).map((user) => {
                                            const phone = parsePhoneNumberFromString(
                                                user?.phone || '',
                                                Locale.toUpperCase(),
                                            );
                                            const mobile = parsePhoneNumberFromString(
                                                user?.cell_phone || '',
                                                Locale.toUpperCase(),
                                            );

                                            return (
                                                <div className="mt-6 w-50 d-flex align-items-start">
                                                    <div className="mr-5 p-5 rounded-circle font-weight-bold font-size-base label label-light-primary">
                                                        {user?.last_name?.[0]}
                                                        {user?.first_name?.[0]}
                                                    </div>
                                                    <div>
                                                        <div className="font-weight-bold">
                                                            {user?.last_name} {user?.first_name} - {user?.role?.name}{' '}
                                                        </div>
                                                        <div>{user?.email}</div>
                                                        <div>
                                                            {phone ? phone.formatNational() : ''}
                                                            {phone && mobile && '-'}
                                                            {mobile ? mobile.formatNational() : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={12} className="col-xxl-6">
                    <Card className="shadow-sm">
                        <Card.Header>
                            <i className="card__icon text-initial las la-2x la-building" />
                            {Intl.formatMessage({ id: 'FLUIDITY.VIEW.BUYER.TITLE' })}
                        </Card.Header>
                        <Card.Body className="d-flex flex-column">
                            <div className="flex-grow-1">
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'FLUIDITY.VIEW.BUYER.USUAL_NAME' })}
                                    value={dealershipBuyer?.refDealershipUsualname || '-'}
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'FLUIDITY.VIEW.BUYER.ADDRESS' })}
                                    value={
                                        dealershipBuyer?.streetnumber || dealershipBuyer?.route
                                            ? `${dealershipBuyer?.streetnumber || ''} ${dealershipBuyer?.route || ''}`
                                            : '-'
                                    }
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'FLUIDITY.VIEW.BUYER.ZIP_CODE' })}
                                    value={dealershipBuyer?.postalcode || '-'}
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'FLUIDITY.VIEW.BUYER.CITY' })}
                                    value={dealershipBuyer?.locality || '-'}
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'FLUIDITY.VIEW.BUYER.PHONE' })}
                                    value={buyerPhoneLandLine ? buyerPhoneLandLine.formatNational() : '-'}
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'FLUIDITY.VIEW.BUYER.NAME' })}
                                    value={lead?.userBuyerFullname || '-'}
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'FLUIDITY.VIEW.CLIENT.NAME' })}
                                    value={lead?.clientFinalFullname || '-'}
                                />
                            </div>
                            {usersBuyer?.length > 0 && (
                                <div>
                                    <Divider type="dashed" className="my-8" />
                                    <div className="font-size-lg font-weight-semibold">
                                        {Intl.formatMessage({ id: 'FLUIDITY.VIEW.REFERENT' })}
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        {usersBuyer.filter(filterUser).map((user) => {
                                            const phone = parsePhoneNumberFromString(
                                                user?.phone || '',
                                                Locale.toUpperCase(),
                                            );
                                            const mobile = parsePhoneNumberFromString(
                                                user?.cell_phone || '',
                                                Locale.toUpperCase(),
                                            );

                                            return (
                                                <div className="mt-6 w-50 d-flex align-items-start">
                                                    <div className="mr-5 p-5 rounded-circle font-weight-bold font-size-base label label-light-primary">
                                                        {user?.last_name?.[0]}
                                                        {user?.first_name?.[0]}
                                                    </div>
                                                    <div>
                                                        <div className="font-weight-bold">
                                                            {user?.last_name} {user?.first_name} - {user?.role?.name}{' '}
                                                        </div>
                                                        <div>{user?.email}</div>
                                                        <div>
                                                            {phone ? phone.formatNational() : ''}
                                                            {phone && mobile && '-'}
                                                            {mobile ? mobile.formatNational() : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-10 mb-5">
                <Col xl={12} className="col-xxl-4">
                    <Card className="shadow-sm">
                        <Card.Header>
                            <i className="card__icon text-initial las la-2x la-car" />
                            {Intl.formatMessage(
                                { id: 'FLUIDITY.VIEW.VEHICLE.TITLE' },
                                {
                                    registration: lead?.vehicle?.registration || '',
                                },
                            )}
                        </Card.Header>
                        <Card.Body className="p-0">
                            <VehicleInfo
                                vehicle={lead?.vehicle}
                                canUpdate={false}
                                canView
                                className="vehicle-container--full"
                            />
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={12} className="col-xxl-8">
                    <Card className="shadow-sm">
                        <Card.Header>
                            <i className="card__icon text-initial las la-2x la-history" />
                            {Intl.formatMessage({ id: 'FLUIDITY.VIEW.HISTORY.TITLE' })}
                        </Card.Header>
                        <Card.Body>
                            <Overflow maxHeight={450}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>{Intl.formatMessage({ id: 'FLUIDITY.VIEW.HISTORY.DATE' })}</th>
                                            <th>{Intl.formatMessage({ id: 'FLUIDITY.VIEW.HISTORY.USER' })}</th>
                                            <th>{Intl.formatMessage({ id: 'FLUIDITY.VIEW.HISTORY.ACTION' })}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {events?.length > 0 &&
                                            events.map((event) => (
                                                <tr key={event.id}>
                                                    <td>
                                                        {dayjs(event?.created).isValid()
                                                            ? dayjs(event.created).format('DD/MM/YYYY [à] HH [h] mm')
                                                            : '-'}
                                                    </td>
                                                    <td>
                                                        {event?.userFirstname || event?.userLastname
                                                            ? `${event?.userFirstname || ''} ${
                                                                  event?.userLastname || ''
                                                              }`
                                                            : '-'}
                                                    </td>
                                                    <td>
                                                        <Label
                                                            variant={getStatusVariant(
                                                                event?.snapshot?.leadFluidityStatus?.slug,
                                                            )}
                                                            size="label-lg"
                                                        >
                                                            {event?.name}
                                                        </Label>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </Overflow>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <StockVehiclePictures className="mt-10" vehicle={data?.result?.lead?.vehicle} />
                </Col>
            </Row>
            <TransactionUpdate
                showModal={showTransactionUpdate}
                setShowModal={setShowTransactionUpdate}
                reload={() => fetch(id)}
                type={type}
                lead={lead}
            />
        </div>
    );
};

export default Fluidity;
