/**
 * Remove duplicate from array of objects
 *
 * @param duplicates - Array of objects with potential duplicates
 * @param property - The property used to identify duplicates
 * @returns Array without duplicates
 */
export const removeDuplicate = <T, K extends keyof T>(duplicates: T[], property: K): T[] => {
    const array = Object.values(duplicates);
    const duplicateProperties = array.map((duplicate) => duplicate[property]);
    return array.filter((duplicate, index) => !duplicateProperties.includes(duplicate[property], index + 1));
};

/**
 * Sort array alphabetically
 *
 * @param key - Optional key to sort by if array elements are objects
 * @returns A function that compares two elements (either directly or by the specified key)
 */
export const alphabeticalSort =
    <T>(key?: keyof T) =>
    (a: T | [unknown, T], b: T | [unknown, T]): number => {
        const getElement = (element: T | [any, T]) => {
            if (Array.isArray(element)) {
                return element[1];
            }
            return element;
        };

        const valueA = key ? getElement(a)[key] : getElement(a);
        const valueB = key ? getElement(b)[key] : getElement(b);

        if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB);
        }

        // Handle non-string values or add additional error handling as needed
        return 0;
    };
