import ApiGateway from '@app/helpers/AxiosHelper';

const { REACT_APP_LEAD_URL } = process.env;

export function getCampaigns(params) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/campaign`, {
        params,
    });
}

export function getCampaign(id) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/campaign/${id}`);
}

export function getCampaignNotCloseContact(id) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/campaign/${id}/notCloseContact`);
}

export function getCampaignNotAssignedContact(id) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/campaign/${id}/notAssignedContact`);
}

export function getCampaignSettings() {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/campaign/settings`);
}

export function updateCampaign(campaignId, data) {
    return ApiGateway.put(`${REACT_APP_LEAD_URL}/campaign/${campaignId}`, {
        ...data,
    });
}

export function campaignContactClosing(campaignId, data) {
    return ApiGateway.put(`${REACT_APP_LEAD_URL}/campaign/${campaignId}/contactClosing`, {
        ...data,
    });
}

export function campaignAssignContact(campaignId, data) {
    return ApiGateway.put(`${REACT_APP_LEAD_URL}/campaign/${campaignId}/assignContact`, {
        ...data,
    });
}

export function getCampaignStats(id) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/campaign/${id}/stats`);
}

export function importCampaigns(params) {
    return ApiGateway.put(`${REACT_APP_LEAD_URL}/campaign/import`, {
        ...params,
    });
}
