import React, {useEffect, useState} from "react";
import {Tab} from "react-bootstrap";
import {Intl} from "@src/_metronic/i18n/I18nProvider";
import {useDispatch, useSelector} from "react-redux";
import {useParams, withRouter} from "react-router-dom";
import PortletTab from "../../../partials/layout/PortletTab";
import PackageTable from "./PackageTable";
import {SRPackageTable} from "../../smartRepair/packages/";
import {PackageTable as ManufacturerPackageTable} from "../../manufacturer/packages"
import {getPackages} from "@app/crud/apv/package.crud";
import {getPackages as getPackagesManufacturer} from "../../../crud/manufacturer/package.crud";
import {getPrices as getPackagesSR} from "../../../crud/smartRepair/smart-repair.crud";
import {actions} from "@app/store/apv/package/package.store";
import {actions as actionsManufacturer} from "../../../store/manufacturer/package/package.store";
import {actions as actionsSR} from "../../../store/smart-repair/package/package.store";

function Packages({ history }) {
    const {type} = useParams();
    const {totalRecords: totalPackageRecords} = useSelector((state) => state.package);
    const {totalRecords: totalPackageSmartRepairRecords} = useSelector((state) => state.smartRepair.package);
    const {totalRecords: totalPackageManufacturerRecords} = useSelector((state) => state.manufacturer.package);
    const [activeKey, setActiveKey] = useState('workshop');
    const dispatch = useDispatch();

    //Get counts
    useEffect(() =>{
        getPackages().then(value => {
            dispatch(actions.setPackagesTotalRecords(value.count || 0))
        })
        getPackagesSR().then(value => {
            dispatch(actionsSR.setPackagesTotalRecords(value.count || 0))
        })
        getPackagesManufacturer().then(value => {
            dispatch(actionsManufacturer.setPackagesTotalRecords(value.count || 0))
        })
    }, [dispatch])

    useEffect(() => {
        const newType = type || 'workshop';
        setActiveKey(newType);

    }, [setActiveKey, type]);

    return (
            <PortletTab
                defaultActiveKey={activeKey}
                activeKey={activeKey}
                onClick={setActiveKey}
                redirects={{
                    'workshop': '/settings/apv/package/workshop',
                    'smartrepair': '/settings/apv/package/smartrepair',
                    'manufacturer': '/settings/apv/package/manufacturer'
                }}
                id="packagelist-portlet"
            >
                <Tab eventKey="workshop" title={
                    <>
                        {Intl.formatMessage({id: 'APV.PACKAGE.TABS.WORKSHOP'})}
                        <span className="ml-5 label label-light-primary font-weight-bold">
                    {totalPackageRecords}
                </span>
                    </>
                }>
                    {activeKey === 'workshop' &&
                        <PackageTable type="workshop" history={history}/>
                    }
                </Tab>
                <Tab eventKey="smartrepair" title={
                    <>
                        {Intl.formatMessage({id: 'APV.PACKAGE.TABS.SMARTREPAIR'})}
                        <span className="ml-5 label label-light-primary font-weight-bold">
                    {totalPackageSmartRepairRecords}
                </span>
                    </>
                }>
                    {activeKey === 'smartrepair' &&
                        <SRPackageTable type="smartrepair" history={history}/>
                    }
                </Tab>
                <Tab eventKey="manufacturer" title={
                    <>
                        {Intl.formatMessage({id: 'APV.PACKAGE.TABS.MANUFACTURER'})}
                        <span className="ml-5 label label-light-primary font-weight-bold">
                    {totalPackageManufacturerRecords}
                </span>
                    </>
                }>
                    {activeKey === 'manufacturer' &&
                        <ManufacturerPackageTable type="manufacturer" history={history}/>
                    }
                </Tab>
            </PortletTab>
    );
}

export default withRouter(Packages);
