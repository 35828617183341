import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Toast as PrimeToast } from 'primereact/toast';

const mappedVariants = {
    success: 'success',
    danger: 'error',
    warning: 'warn',
    info: 'info',
};

function Toast(props) {
    const toasts = useRef(null);

    useEffect(() => {
        toasts.current.show({ severity: mappedVariants[props.variant], summary: props.message, life: props.life });
    }, [props]);

    return <PrimeToast ref={toasts} />;
}

export default function toast(props) {
    const portalContainer = document.getElementById('toast-container');
    const div = document.createElement('div');

    portalContainer.appendChild(div);

    ReactDOM.render(<Toast {...props} />, div);

    if (typeof props.onShow !== 'undefined') {
        props.onShow();
    }
}
