import smartRepairOrder from './smartRepairOrder.json'
import smartRepairPackage from './smartRepairPackage.json'
import smartRepairQuotation from './smartRepairQuotation.json'

const frSmartRepair = {
    ...smartRepairOrder,
    ...smartRepairPackage,
    ...smartRepairQuotation
}

export default frSmartRepair