import { AnimateSharedLayout } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

import usePermissions from '@app/hooks/usePermissions';

import AccountMenuItem from './AccountMenuItem';

const AccountMenu = ({ menus, userData, defaultActiveMenu }) => {
    const [activeMenu, setActiveMenu] = useState(defaultActiveMenu);
    const activeMenuRef = useRef();
    const { hasAllPermissions } = usePermissions();

    useEffect(() => {
        setActiveMenu(defaultActiveMenu);
    }, [setActiveMenu, defaultActiveMenu]);

    return (
        <div className="account-menu">
            <div className="account-menu__items">
                <AnimateSharedLayout>
                    {menus.map((menu, idx) => {
                        if ((menu?.visible?.(userData) ?? true) && hasAllPermissions(menu.permissions)) {
                            return (
                                <AccountMenuItem
                                    key={`${menu.href(userData?.id).replace(/\//g, '-')}`}
                                    userData={userData}
                                    idx={idx}
                                    active={activeMenu === idx}
                                    onMouseEnter={() => setActiveMenu(idx)}
                                    onMouseLeave={() => setActiveMenu(defaultActiveMenu)}
                                    updateBackgroundPosition={(e) => {
                                        activeMenuRef.current = e;
                                    }}
                                    menu={menu}
                                />
                            );
                        }
                        return null;
                    })}
                </AnimateSharedLayout>
            </div>
        </div>
    );
};

export default AccountMenu;
