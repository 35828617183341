/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import BreadCrumbsHome from "./BreadCrumbsHome";

function BreadCrumbs(props) {
    const { items } = props;
    if (!items || !items.length) {
        return null;
    }

    const length = items.length;
    return (
        <div className="kt-subheader__breadcrumbs">
            <BreadCrumbsHome />
            <span className="kt-subheader__breadcrumbs-separator" />
            {items.map((item, index) => (
                <React.Fragment key={`bc${index}`}>
                    {typeof item.page !== 'undefined' ? (
                        <Link className="kt-subheader__breadcrumbs-link" to={`/${item.page}`}>
                            {!item.translate ? (
                                item.title
                            ) : (
                                <FormattedMessage id={item.translate} defaultMessage={item.title} />
                            )}
                        </Link>
                    ) : (
                        <div className="kt-subheader__breadcrumbs-link">
                            {!item.translate ? (
                                item.title
                            ) : (
                                <FormattedMessage id={item.translate} defaultMessage={item.title} />
                            )}
                        </div>
                    )}
                    {length !== index + 1 && (
                        <span className="kt-subheader__breadcrumbs-separator" />
                    )}
                </React.Fragment>
            ))}
        </div>
    );
}

export default BreadCrumbs;
