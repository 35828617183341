import { Lead, leadTypes } from '@app/store/customers/customers.store';

// eslint-disable-next-line import/prefer-default-export
export const getClientBody = (lead: Lead) => {
    const clientName =
        lead?.contact?.firstname || lead?.contact?.lastname
            ? `${lead?.contact?.firstname || ''} ${lead?.contact?.lastname || ''}`
            : '-';

    const excluded_statuses = ['pending', 'pending_30_min', 'pending_90_min'];

    const includedTypes = [leadTypes.localApv];

    if (includedTypes.includes(lead?.leadType?.slug) || !excluded_statuses.includes(lead?.leadStatus?.slug)) {
        return clientName;
    }

    return '###';
};
