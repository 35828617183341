import { isNil } from 'lodash';
import React, { useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

import { Intl } from '../../../../../_metronic/i18n/I18nProvider';
import QuotationResumeComplementMsg from '../Modals/QuotationResumeComplementMsg';

export default function QuotationResumed({ quotation }) {
    const [showResumeModal, toggleShowResumeModal] = useState(false);

    const showResumeComplementModal = () => {
        toggleShowResumeModal(true);
    };

    return (
        <>
            <Row className="mt-8">
                <Col lg={12}>
                    <Card>
                        <Card.Header className="border-bottom-0 smart-repair-card--border-bottom">
                            <i className="card__icon fas fa-tasks" />
                            {Intl.formatMessage(
                                { id: 'SMART.REPAIR.QUOTATION.HEAD.DETAILS' },
                                { ref: quotation?.reference ? quotation?.reference : '' },
                            )}
                        </Card.Header>
                        <Card.Body className="p-0">
                            <Table className="m-0" borderless hover>
                                <thead>
                                    <tr>
                                        <th width="10%">
                                            {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.TABLE.HEAD.REF' })}
                                        </th>
                                        <th width="70%">
                                            {Intl.formatMessage({
                                                id: 'SMART.REPAIR.QUOTATION.TABLE.HEAD.REPAIR.NAME',
                                            })}
                                        </th>
                                        <th width="20%">
                                            {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.TABLE.HEAD.PRICE.TTC' })}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {quotation &&
                                        quotation.orderRepairs.map((item) => (
                                            <React.Fragment key={item.id}>
                                                <tr className="smart-repair-details__table-header">
                                                    <th>{item.reference}</th>
                                                    <th>{item.zoneBody.name}</th>
                                                    <th>
                                                        {item.compatible && item.totalAmount && (
                                                            <NumberFormat
                                                                value={item.totalAmount}
                                                                thousandSeparator=" "
                                                                decimalSeparator=","
                                                                decimalScale="2"
                                                                fixedDecimalScale
                                                                displayType="text"
                                                                suffix=" €"
                                                            />
                                                        )}
                                                        {item.compatible === false && (
                                                            <span className="text-danger">
                                                                {Intl.formatMessage({
                                                                    id: 'SMART.REPAIR.QUOTATION.INCOMPATIBLE',
                                                                })}
                                                            </span>
                                                        )}
                                                        {isNil(item.compatible) && (
                                                            <span className="text-danger"> - </span>
                                                        )}
                                                    </th>
                                                </tr>
                                                {item.price.map((price) => (
                                                    <tr key={price.slug}>
                                                        <td> </td>
                                                        <td>{price.name}</td>
                                                        {item.compatible && (
                                                            <td>
                                                                {price.priceTtc ? (
                                                                    <NumberFormat
                                                                        value={price.priceTtc}
                                                                        thousandSeparator=" "
                                                                        decimalSeparator=","
                                                                        decimalScale="2"
                                                                        fixedDecimalScale
                                                                        displayType="text"
                                                                        suffix=" €"
                                                                    />
                                                                ) : (
                                                                    <span className="text-danger">
                                                                        {Intl.formatMessage({
                                                                            id: 'SMART.REPAIR.QUOTATION.INCOMPATIBLE',
                                                                        })}
                                                                    </span>
                                                                )}
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                </tbody>
                                <tfoot className="tfoot tfoot--total">
                                    <tr>
                                        <td> </td>
                                        <td className="font-weight-bold">
                                            <div className="tfoot__bordered tfoot__float-right tfoot__total">
                                                {Intl.formatMessage({
                                                    id: 'SMART.REPAIR.QUOTATION.TABLE.HEAD.FINAL_PRICE',
                                                })}
                                                :
                                            </div>
                                        </td>
                                        <td className="font-weight-bold">
                                            <div className="tfoot__bordered tfoot__number">
                                                {quotation.totalAmount > 0 && (
                                                    <NumberFormat
                                                        value={quotation.totalAmount}
                                                        thousandSeparator=" "
                                                        decimalSeparator=","
                                                        decimalScale="2"
                                                        fixedDecimalScale
                                                        displayType="text"
                                                        suffix=" €"
                                                    />
                                                )}
                                                {quotation.totalAmount < 0 && (
                                                    <span className="text-danger">
                                                        {Intl.formatMessage({
                                                            id: 'SMART.REPAIR.QUOTATION.INCOMPATIBLE',
                                                        })}
                                                    </span>
                                                )}
                                                {quotation.totalAmount === null && (
                                                    <span className="text-danger">-</span>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Card.Body>
                        {quotation?.orderStatus?.slug === 'waiting_complement' && (
                            <div className="my-5 mr-5 d-flex justify-content-end align-items-center">
                                <i className="las la-info-circle text-primary smart-repair-card--info-size" />
                                <button
                                    type="button"
                                    className="ml-2 mb-0 text-primary smart-repair-card--underline smart-repair-card--cursor font-weight-bold"
                                    onClick={() => showResumeComplementModal()}
                                >
                                    {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.ADDITIONAL.BTN' })}
                                </button>
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>
            {showResumeModal && (
                <QuotationResumeComplementMsg
                    quotation={quotation}
                    showModal={showResumeModal}
                    setShowModal={toggleShowResumeModal}
                />
            )}
        </>
    );
}
