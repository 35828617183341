import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

interface Props {
    event: VcuMappedEventDetails;
}
const FactureInfo = ({ event }: Props) => {
    const Intl = useIntl();
    const { informations } = event;
    function translateValueDataSource(value: string) {
        switch (value) {
            case 'INVOICE':
            case 'F':
                return 'Facture';
            case 'A':
                return 'Avoir';
            case 'CREDIT':
                return 'Crédit';
            default:
                return '-';
        }
    }
    return (
        <div className="d-md-flex justify-content-md-between align-items-md-stretch">
            <div className="col-md-4">
                {informations?.leadTypeSlug !== 'achat_vo_online' && (
                    <RowInfo
                        label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DOCUMENT' })}
                        value={translateValueDataSource(informations?.document)}
                    />
                )}
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.BILL_TYPE' })}
                    value={informations?.billType || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.SOURCE' })}
                    value={informations?.source || '-'}
                />
                {informations?.leadTypeSlug === 'achat_vo_online' && (
                    <RowInfo
                        label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DATE_ORDER' })}
                        value={
                            event?.action?.date ? dayjs(event?.action?.date).format('DD/MM/YYYY [à] HH [h] mm') : '-'
                        }
                    />
                )}
                {informations?.leadTypeSlug === 'achat_vo_online' && (
                    <RowInfo
                        label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DATE_DELIVERY' })}
                        value={
                            event?.product[0]?.date_delivery
                                ? dayjs(event?.product[0]?.date_delivery).format('DD/MM/YYYY')
                                : '-'
                        }
                    />
                )}
                {informations?.leadTypeSlug !== 'achat_vo_online' && (
                    <RowInfo
                        label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.BILL_DATE' })}
                        value={
                            informations?.created
                                ? dayjs(informations?.created, 'DD/MM/YYYY - HH [h] mm').format('DD/MM/YYYY')
                                : '-'
                        }
                    />
                )}
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DEALERSHIP' })}
                    value={informations?.dealership?.name}
                />
                {informations?.leadTypeSlug === 'prestation_apv' && (
                    <RowInfo
                        label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DESCRIPTION' })}
                        value={informations?.description || '-'}
                    />
                )}
                {informations?.leadTypeSlug === 'prestation_apv' && (
                    <RowInfo
                        label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.PRICE' })}
                        value={
                            <NumberFormat
                                value={informations?.price || '-'}
                                decimalSeparator="."
                                thousandSeparator=" "
                                allowedDecimalSeparators={[',', '.']}
                                suffix=" €"
                                displayType="text"
                            />
                        }
                    />
                )}
            </div>
            <div className="col-md-4">
                {informations?.leadTypeSlug === 'achat_vo_online' && (
                    <RowInfo
                        label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.FUNDING' })}
                        value={event?.product[0]?.funding_type || '-'}
                    />
                )}
                {informations?.leadTypeSlug === 'achat_vo_online' && (
                    <RowInfo
                        label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.BRING' })}
                        value={
                            <NumberFormat
                                value={event?.product[0]?.bring || '-'}
                                decimalSeparator="."
                                thousandSeparator=" "
                                allowedDecimalSeparators={[',', '.']}
                                suffix=" €"
                                displayType="text"
                            />
                        }
                    />
                )}
                {informations?.leadTypeSlug === 'achat_vo_online' && (
                    <RowInfo
                        label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.AMOUNT_FINANCED' })}
                        value={
                            <NumberFormat
                                value={event?.product[0]?.amount_financed || '-'}
                                decimalSeparator="."
                                thousandSeparator=" "
                                allowedDecimalSeparators={[',', '.']}
                                suffix=" €"
                                displayType="text"
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};
export default FactureInfo;
