import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { toAbsoluteUrl } from '@src/_metronic';

import { Customer } from '@app/crud/customers/customer.type';

import { leadTypes } from '@app/store/customers/customers.store';

import EventCampaignInfo from './EventCampaignInfo';
import DetailAdictiz from './EventDetailAdictiz/DetailAdictiz';
import DetailResume from './EventDetailBcs/DetailResume';
import DetailTscan from './EventDetailBcs/DetailTscan';
import DetailCirano from './EventDetailCirano/DetailCirano';
import DetailDmsPrestation from './EventDetailDmsPrestation/DetailDmsPrestation';
import DetailFunding from './EventDetailFunding/DetailFunding';
import DetailIcare from './EventDetailIcare/DetailIcare';
import AccountInfo from './EventDetailInfo/AccountInfo';
import FactureInfo from './EventDetailInfo/FactureInfo';
import FinancementInfo from './EventDetailInfo/FinancementInfo';
import Fullweb from './EventDetailInfo/FullWebInfo';
import LeadApvInfo from './EventDetailInfo/LeadApvInfo';
import LeadAtelierInfo from './EventDetailInfo/LeadAtelierInfo';
import LeadInfo from './EventDetailInfo/LeadInfo';
import LeadLocalApvInfo from './EventDetailInfo/LeadLocalApvInfo';
import LeadLocalHomeChargeInfo from './EventDetailInfo/LeadLocalHomeChargeInfo';
import RappelClientInfo from './EventDetailInfo/RappelClientInfo';
import DetailMecaplanning from './EventDetailMecaplanning/DetailMecaplanning';
import DetailOpteven from './EventDetailOpteven/DetailOpteven';
import SecurycarDisaster from './SecuryCar/SecuryCarDisaster';
import SecurycarRenewal from './SecuryCar/SecuryCarRenewal';
import SecurycarResume from './SecuryCar/SecurycarResume';
import SecurycarTermination from './SecuryCar/SecuryCarTermination';
import DetailCommercialProposalOrder from './EventDetailInfo/DetailCommercialProposalOrder';
import DetailCommercialProposalOffer from './EventDetailInfo/DetailCommercialProposalOffer';

type EventDetailInfoProps = {
    event: any;
    typeSlug: string;
    isLeadClosed: boolean;
    customer: Customer;
};

const EventDetailInfo = ({ event, typeSlug, isLeadClosed, customer }: EventDetailInfoProps) => {
    const [template, setTemplate] = useState(null);
    const leadTypeCase = useCallback(() => {
        switch (typeSlug) {
            case 'lead':
                return <LeadInfo event={event} isLeadClosed={isLeadClosed} />;
            case 'achat':
            case 'planet_vo':
                return <FactureInfo event={event} />;
            case 'prestation_apv':
                return <DetailDmsPrestation event={event} />;
            case 'rappel_client':
                return <RappelClientInfo event={event} />;
            case 'financement':
                return <FinancementInfo event={event} />;
            case 'securycar_disaster':
                return <SecurycarDisaster event={event} />;
            case 'securycar_renewal':
                return <SecurycarRenewal event={event} />;
            case 'securycar_termination':
                return <SecurycarTermination event={event} />;
            case 'securycar_resume':
                return <SecurycarResume event={event} />;
            case 'lead_apv':
                return <LeadApvInfo event={event} />;
            case 'local_apv':
                return <LeadLocalApvInfo event={event} />;
            case 'local_home_charge':
                return <LeadLocalHomeChargeInfo event={event} />;
            case 'commande_full_web':
                return <Fullweb event={event} />;
            case 'prestation_atelier':
                return <LeadAtelierInfo event={event} />;
            case 'marketing_campaign_vo':
            case 'marketing_campaign_vn':
                return <EventCampaignInfo event={event} />;
            case 'captives_fi':
                return <DetailFunding event={event} />;
            case 'icare':
                return <DetailIcare event={event} />;
            case 'audit_tscans':
                return <DetailTscan event={event} />;
            case 'mecaplanning':
                return <DetailMecaplanning event={event} />;
            case 'fleetback':
                return <DetailResume event={event} />;
            case 'contrat_de_garantie':
                return <DetailCirano event={event} />;
            case 'adictiz':
                return <DetailAdictiz event={event} />;
            case 'contrat_de_service_opteven':
                return <DetailOpteven event={event} />;
            case 'proposition_commerciale':
                return event.commercialProposal?.order ? (
                    <DetailCommercialProposalOrder event={event} />
                ) : (
                    <DetailCommercialProposalOffer event={event} />
                );
            default:
                return <LeadInfo event={event} isLeadClosed={isLeadClosed} />;
        }
    }, [typeSlug, event]);

    function eventPartnerCode(value: string) {
        switch (value) {
            case 'BMW':
                return (
                    <img src={toAbsoluteUrl(`/media/logos/captives/fi-alphabet.svg`)} height={35} alt="Logo Marque" />
                );
            case 'CGI':
            case 'CGINISSAN':
            case 'CGIRENAULT':
            case 'CGIWEB':
            case 'CGIWEBREN':
            case 'PORSCHE':
                return (
                    <img
                        src={toAbsoluteUrl(`/media/logos/captives/fi-autosphere-financement.svg`)}
                        height={35}
                        alt="Logo Marque"
                    />
                );
            case 'CITROEN':
            case 'PEUGEOT':
                return (
                    <img src={toAbsoluteUrl(`/media/logos/captives/fi-credipar.svg`)} height={35} alt="Logo Marque" />
                );
            case 'NISS':
            case 'DIACREN':
                return (
                    <img
                        src={toAbsoluteUrl(`/media/logos/captives/fi-diac-renault.svg`)}
                        height={35}
                        alt="Logo Marque"
                    />
                );
            case 'FCA':
                return (
                    <img
                        src={toAbsoluteUrl(`/media/logos/captives/fi-fca-bank-jlr.svg`)}
                        height={35}
                        alt="Logo Marque"
                    />
                );
            case 'FCE':
                return (
                    <img
                        src={toAbsoluteUrl(`/media/logos/captives/fi-fce-ford-credit.svg`)}
                        height={35}
                        alt="Logo Marque"
                    />
                );
            case 'GMAC':
            case 'OPEL':
                return (
                    <img src={toAbsoluteUrl(`/media/logos/captives/fi-opel-bank.svg`)} height={35} alt="Logo Marque" />
                );
            case 'KIA':
                return (
                    <img
                        src={toAbsoluteUrl(`/media/logos/captives/fi-kia-finance.svg`)}
                        height={35}
                        alt="Logo Marque"
                    />
                );
            case 'MERCEDES':
                return (
                    <img
                        src={toAbsoluteUrl(`/media/logos/captives/fi-mercedes-benz-financial-services.svg`)}
                        height={35}
                        alt="Logo Marque"
                    />
                );
            case 'PLRENAULT':
                return (
                    <img
                        src={toAbsoluteUrl(`/media/logos/captives/fi-autosphere-lease.svg`)}
                        height={35}
                        alt="Logo Marque"
                    />
                );
            case 'VW BANK':
                return (
                    <img src={toAbsoluteUrl(`/media/logos/captives/fi-volkswagen.svg`)} height={35} alt="Logo Marque" />
                );
            default:
                return (
                    <img
                        src={toAbsoluteUrl(`/media/logos/captives/fi-financement-generique.svg`)}
                        height={18}
                        alt="fi-financement-generique logo"
                    />
                );
        }
    }

    useEffect(() => {
        if (!template) {
            setTemplate(
                event?.informations?.processType && event?.informations?.processType === 'AFR_SUBSCRIBER' ? (
                    <AccountInfo event={event} customer={customer} />
                ) : (
                    leadTypeCase()
                ),
            );
        }
    }, [leadTypeCase, setTemplate, template]);

    const processLeadTypeTabTitle = (leadType: string) => {
        if (leadType.startsWith('local')) {
            return leadTypes.local;
        }

        return leadType;
    };

    let title = '';

    if (
        event?.informations?.processType &&
        [
            'SC_RENEWAL',
            'SC_DISASTER',
            'SC_TERMINATION',
            'SC_SERVICE_RESUME',
            'PURCHASE_ORDER_PRODUCT',
            'COMMERCIAL_PROPOSITION_PRODUCT',
            'AUDIT',
            'LEAD_UNQUALIFIED',
        ].includes(event?.informations?.processType?.toUpperCase?.())
    ) {
        title = event?.informations?.processType?.toUpperCase?.();
    } else if ('COMMERCIAL_PROPOSAL' === event?.informations?.processType?.toUpperCase?.()) {
        title = 'COMMERCIAL_PROPOSAL_' + (event?.commercialProposal?.order ? 'ORDER' : 'OFFER');
    } else if (event?.leadType?.slug || event?.informations?.leadTypeSlug) {
        title = processLeadTypeTabTitle(
            (event?.leadType?.slug || event?.informations?.leadTypeSlug) ?? '',
        ).toUpperCase();
    }

    return (
        <Card className="card--shadowed">
            <Card.Header className={`event__origin--${typeSlug} position-relative`}>
                <div className={`event__origin-bg--${typeSlug} pin`}>
                    <i className="la la-bullseye text-white la-lg" />
                </div>
                <div className="d-flex ml-4 justify-content-between flex-wrap align-items-center">
                    <span>
                        <FormattedMessage id={`CUSTOMER.VIEW.LIFE.DETAIL.INFO.TITLE.${title}`} />
                    </span>
                    <span>
                        {event?.informations?.source === 'SECURYCAR' && (
                            <img
                                src={toAbsoluteUrl(`/media/logos/captives/securycar.svg`)}
                                height={30}
                                className="ml-4"
                                alt="Logo Marque"
                            />
                        )}
                    </span>
                    {event?.funding?.fundingPartnerCode && eventPartnerCode(event?.funding?.fundingPartnerCode)}
                    {event?.informations?.source === 'ICAR' && (
                        <img src={toAbsoluteUrl(`/media/logos/captives/i-car-dms.svg`)} height={30} alt="Logo Marque" />
                    )}
                    {event?.informations?.leadTypeSlug === 'audit_bcs' && (
                        <img src={toAbsoluteUrl(`/media/logos/captives/fleetback.svg`)} height={30} alt="Logo Marque" />
                    )}
                    {event?.informations?.leadTypeSlug === 'audit_tscans_vhc' && (
                        <img src={toAbsoluteUrl(`/media/logos/captives/tscan.svg`)} height={30} alt="Logo Marque" />
                    )}
                    {event?.informations?.source === 'MECAPLANNING' && (
                        <img
                            src={toAbsoluteUrl(`/media/logos/captives/mecaplanning.svg`)}
                            height={30}
                            alt="Logo Marque"
                        />
                    )}
                    {event?.informations?.source === 'DCSNET' && (
                        <img src={toAbsoluteUrl(`/media/logos/captives/dcsnet.svg`)} height={20} alt="Logo Marque" />
                    )}
                    {event?.informations?.source === 'ICARE' && (
                        <img src={toAbsoluteUrl(`/media/logos/captives/icare.svg`)} height={30} alt="Logo Marque" />
                    )}
                    {event?.informations?.source === 'CIRANO' && (
                        <img src={toAbsoluteUrl(`/media/logos/captives/cirano.svg`)} height={30} alt="Logo Marque" />
                    )}
                    {event?.informations?.source === 'OPTEVEN' && (
                        <img src={toAbsoluteUrl(`/media/logos/captives/opteven.svg`)} height={30} alt="Logo Marque" />
                    )}
                    {event?.homeCharge?.url && (
                        <Button href={event?.homeCharge?.url} target="_blank" variant="dark-blue">
                            <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.HOME_CHARGE.RESUME" />
                        </Button>
                    )}
                    {event?.informations?.source === 'PLANET_VO2' && (
                        <img src={toAbsoluteUrl(`/media/logos/captives/PVO2.svg`)} height={30} alt="Logo Marque" />
                    )}
                </div>
            </Card.Header>
            <Card.Body>{template}</Card.Body>
        </Card>
    );
};

export default EventDetailInfo;
