import { Dropdown } from 'primereact/dropdown';
import React, { useCallback, useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Controller, useFormContext, UseFormMethods } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import useFetch from '@app/hooks/useFetch';

import { getLeadMapping } from '@app/crud/autouser/lead.crud';

import { Lead, leadTypeSelectList } from '@app/store/customers/customers.store';

import ErrorForm from '@app/partials/layout/ErrorForm';

type LeadTypeSelectProps = {
    methods: UseFormMethods;
    currentLead: Lead;
};
export default function LeadTypeSelect({ methods, currentLead }: LeadTypeSelectProps) {
    const { control } = methods;
    const [selectedGroup, setSelectedGroup] = React.useState('default');
    const [selectedType, setSelectedType] = React.useState();
    const { setValue } = useFormContext();

    const {
        fetch: fetchData,
        data,
        loading: loadingMapping,
    } = useFetch({
        fetchAction: getLeadMapping,
    });
    const intl = useIntl();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        const refLeadType = currentLead?.leadType;
        const refLeadOrigin = currentLead?.leadOrigin;

        if (refLeadType && data?.lead_types?.length > 0) {
            setValue('lead_type', refLeadType.slug);
        }

        if (refLeadOrigin && data?.lead_origins?.length > 0) {
            setValue('lead_origin', refLeadOrigin.slug);
        }
    }, [setValue, currentLead, data]);

    const localLeadTypes = data?.lead_types?.filter((type) => leadTypeSelectList.includes(type.slug)) ?? [];
    const filteredOrigins = currentLead
        ? data?.lead_origins ?? []
        : data?.lead_origins
              ?.filter((origin) => origin.groupSlug === selectedGroup || origin.typeSlug === selectedType)
              .sort((a, b) => a.name.localeCompare(b.name)) ?? [];

    const getDropdownPlaceholder = useCallback(() => {
        if (loadingMapping) {
            return intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.LEAD_TYPE.LOADING' });
        }
        if (selectedGroup === 'default') {
            return intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.LEAD_TYPE.SELECT_LEAD_FIRST' });
        }
        if (filteredOrigins.length === 0 || !selectedGroup) {
            return intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.LEAD_TYPE.NO_ORIGINS_AVAILABLE' });
        }

        return intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.LEAD_TYPE.SELECT_ORIGIN' });
    }, [loadingMapping, selectedGroup, filteredOrigins.length, intl]);

    if (currentLead && !currentLead?.leadType?.slug.startsWith('local')) {
        return null;
    }

    /* eslint-disable react/prop-types */
    return (
        <>
            <Col lg={6}>
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="CUSTOMER.MODAL.TYPE_SELECT.TYPE_LABEL" />
                    </Form.Label>
                    <Controller
                        name="lead_type"
                        rules={{ required: intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }) }}
                        defaultValue={null}
                        control={control}
                        render={(props) => (
                            <Dropdown
                                className={methods.errors?.lead_type && 'is-invalid'}
                                value={props.value}
                                options={localLeadTypes}
                                onChange={(e) => {
                                    props.onChange(e.value);
                                    const relatedGroupSlug = data.lead_types.find(
                                        (type) => type.slug === e.value,
                                    ).groupSlug;
                                    setSelectedGroup(relatedGroupSlug);
                                    setSelectedType(e.value);
                                }}
                                filter
                                placeholder={
                                    loadingMapping
                                        ? intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.LEAD_TYPE.LOADING' })
                                        : intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.LEAD_TYPE.SELECT_LEAD_FIRST' })
                                }
                                optionLabel="name"
                                optionValue="slug"
                                disabled={!!currentLead}
                            />
                        )}
                    />

                    <ErrorForm errors={methods.errors} name="lead_type" />
                </Form.Group>
            </Col>

            <Col lg={6}>
                <Form.Group>
                    <Form.Label>
                        <FormattedMessage id="CUSTOMER.MODAL.TYPE_SELECT.SOURCE_LABEL" />
                    </Form.Label>
                    <Controller
                        name="lead_origin"
                        control={control}
                        render={(props) => (
                            <Dropdown
                                value={props.value}
                                disabled={filteredOrigins.length === 0 || !selectedGroup || !!currentLead}
                                options={filteredOrigins}
                                onChange={(e) => props.onChange(e.value)}
                                filter
                                placeholder={getDropdownPlaceholder()}
                                optionLabel="name"
                                optionValue="slug"
                            />
                        )}
                    />
                </Form.Group>
            </Col>
        </>
    );
}
