import React from 'react';
import {useDispatch} from "react-redux";
import {Button} from "react-bootstrap";
import NumberFormat from "react-number-format";
import {FormattedHTMLMessage} from 'react-intl';
import classNames from "classnames";
import {Intl} from "../../../../_metronic/i18n/I18nProvider";
import KTUtil from "../../../../_metronic/_assets/js/util";
import {actions} from "../../../store/modal/modal.store";
import {
    getPictureTemplate,
    getBrandTemplate,
    getFuelTypeTemplate,
    getGearsTypeTemplate,
    getProductionYearTemplate,
} from "./VehicleHelpers";

const VehicleInfoPartExchange = ({vehicle}) => {
    const dispatch = useDispatch();

    return (
      <div className="d-flex flex-column vehicle-container h-100 kt-ribbon kt-ribbon--round kt-ribbon--right">
          <div className={classNames("d-flex justify-content-center align-items-center", {
              'flex-column': KTUtil.isInResponsiveRange('mobile')
          })}>
              <img src={getPictureTemplate(vehicle)} width="120" alt="véhicule" />
              <div className=" d-flex flex-column px-5 py-3 marque-modele__part-exchange">
                  <span className="marque text-nowrap text-truncate">{getBrandTemplate(vehicle)} {vehicle?.model || ''}</span>
                  <span className="version">{vehicle ? vehicle?.version : 'Version'}</span>
                  <div className="veh-infos">
                      <span>{getFuelTypeTemplate(vehicle) === 'ND' ? '-' : getFuelTypeTemplate(vehicle)}</span>
                      <span> •&nbsp;
                          {vehicle?.mileage ?
                              <NumberFormat value={vehicle?.mileage}
                                            thousandSeparator=" "
                                            displayType={'text'}
                                            suffix=" km"/>
                          : '-'}
                      </span>
                      {getProductionYearTemplate(vehicle) &&
                          <span> • {getProductionYearTemplate(vehicle)}</span>
                      }
                      {getGearsTypeTemplate(vehicle) !== '-' && getGearsTypeTemplate(vehicle) &&
                          <span> • {getGearsTypeTemplate(vehicle)}</span>
                      }
                  </div>
              </div>

              <div className="d-flex flex-column align-items-center">
                  <div className="d-flex w-100 py-3 px-5 text-center justify-content-center estimate__part-exchange">
                      <span>{Intl.formatMessage({ id: "VEHICLE_INFO.VIEW.LIFE.DETAIL.RECOVERY.ESTIMATE" })}</span>
                      <strong className="ml-2">
                          {vehicle?.estimatedPrice ?
                              <NumberFormat value={vehicle?.estimatedPrice}
                                            decimalSeparator="."
                                            thousandSeparator=" "
                                            displayType="text"
                                            suffix=" €"/>
                              : '-'}
                      </strong>
                  </div>
              </div>
          </div>
          <div className="position-relative">
              {
                  vehicle?.partExchangeReference &&
                  <div className="text-center p-4 button-container w-100 d-flex justify-content-center align-items-center">
                      <span className="vehicle-recovery">
                          <FormattedHTMLMessage id={'VEHICLE_INFO.VIEW.LIFE.DETAIL.RECOVERY.WATCH_DETAIL'}/>
                      </span>
                      <Button variant="primary" className="ml-2" onClick={() => dispatch(actions.modalChange('afr_part_exchange_detail', {
                          reference: vehicle?.partExchangeReference
                      }))}>
                          <i className="la la-mouse-pointer"/>
                          {Intl.formatMessage({id: "VEHICLE_INFO.VIEW.LIFE.DETAIL.RECOVERY.RECOVERY_DETAIL"})}
                      </Button>
                  </div>
              }
          </div>
      </div>
    );
}

export default VehicleInfoPartExchange;
