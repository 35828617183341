import ApiGateway, { CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import * as Types from './achat-cash-types';

const { REACT_APP_LEAD_URL } = process.env;

export function getRemotePerformances(params: Types.PerformanceParams) {
    return ApiGateway.get<Types.Performances, CustomAxiosResponse<Types.Performances>>(
        `${REACT_APP_LEAD_URL}/dashboard/performances-achat-cash-remote`,
        {
            params,
        },
    );
}

export function getLivePerformances(params: Types.PerformanceParams) {
    return ApiGateway.get<Types.Performances, CustomAxiosResponse<Types.Performances>>(
        `${REACT_APP_LEAD_URL}/dashboard/performances-achat-cash-live`,
        {
            params,
        },
    );
}

export function getLocalPerformances(params: Types.PerformanceParams) {
    return ApiGateway.get<Types.OriginPerformances, CustomAxiosResponse<Types.OriginPerformances>>(
        `${REACT_APP_LEAD_URL}/dashboard/performances-achat-cash-local`,
        {
            params,
        },
    );
}

export function getOnlinePerformances(params: Types.PerformanceParams) {
    return ApiGateway.get<Types.OriginPerformances, CustomAxiosResponse<Types.OriginPerformances>>(
        `${REACT_APP_LEAD_URL}/dashboard/performances-achat-cash-online`,
        {
            params,
        },
    );
}

export function getAppointmentPerformances(params: Types.PerformanceParams) {
    return ApiGateway.get<Types.Stats, CustomAxiosResponse<Types.Stats>>(
        `${REACT_APP_LEAD_URL}/dashboard/performances-achat-cash-appointment`,
        {
            params,
        },
    );
}

export function getAbandonPerformances(params: Types.PerformanceParams) {
    return ApiGateway.get<Types.Performances, CustomAxiosResponse<Types.Performances>>(
        `${REACT_APP_LEAD_URL}/dashboard/performances-achat-cash-abandon`,
        {
            params,
        },
    );
}
