/* eslint-disable no-nested-ternary */
import { capitalize, isEmpty } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card, Col, Form, Image, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';
import useFilters from '@app/hooks/useFilters';
import useModal from '@app/hooks/useModal';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { ApiResponse } from '@app/helpers/AxiosHelper';
import { getRolesFromDealerships } from '@app/helpers/RoleHelper';
import { userIsAssociateToDealership } from '@app/helpers/UserHelper';

import { getDealershipsDirectory } from '@app/crud/autouser/dealerships.crud';
import { Dealership } from '@app/crud/autouser/dealerships.type';
import { getTags, Tag } from '@app/crud/autouser/tags.crud';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import Loader from '@app/partials/content/Loader';
import SearchInput from '@app/partials/content/SearchInput';

import AddUserToDealership from '../modals/AddUserToDealership';
import ManageDealershipSignatures from '../modals/ManageDealershipSignatures';
import ManageUser from '../modals/ManageUser';

import DealershipsPerimeterDataTable from './DealershipsPerimeterDataTable';
import DealershipsSelect from './partials/DealershipsSelect';
import RolesSelect from './partials/RolesSelect';

const DealershipsPerimeter = () => {
    const [dealerships, setDealerships] = useState<Dealership[]>();
    const [facetDealerships, setFacetDealerships] = useState([]);
    const [facetRoles, setFacetRoles] = useState<{ [key: string]: string }>({});
    const [showAddUser, setShowAddUser] = useModal(false, 'add_user_to_dealership');
    const [showManageSignatures, setShowManageSignatures] = useModal(false, 'manage_dealership_signature');
    const [showManageUser, toggleManageUser] = useModal(false, 'manage_user');
    const dealershipSelectorRef = useRef();
    const roleSelectorRef = useRef();
    const { filters, sortField, sortOrder } = useFilters({
        name: 'dealerships_perimeter_search',
        persisted: false,
    });

    const { data: tags } = useFetch<null, ApiResponse<Tag[]>, Tag[]>({
        fetchAction: getTags,
        autoFetch: true,
    });

    const { data, loading, error, refresh, handleFilter, handlePagination, handleSort } = useTableHandlers({
        filterStore: 'dealerships_perimeter_search',
        fetchAction: getDealershipsDirectory,
        withStore: false,
        doSearch: (params) => params?.q?.length > 0,
        paramsInterceptor: (params) => {
            // @TODO to remove when api manage filters
            const { q, ..._otherParams } = params;
            return {
                q,
            };
        },
    });

    const filterDealerships = (results: Dealership[], definedFilters: { dealerships?: { value: string[] } }) => {
        const definedFilterDealerships = definedFilters?.dealerships?.value;
        const filteredDealerships = results.filter((dealership) => {
            if (isEmpty(definedFilterDealerships)) return true;
            return definedFilterDealerships.includes(dealership.ref_dealership_id);
        });
        return filteredDealerships;
    };

    const filterRoles = (results: Dealership[], definedFilters: { roles?: { value: string[] } }) => {
        const definedFilterRoles = definedFilters?.roles?.value;
        const filteredDealerships = results.reduce((acc, dealership) => {
            if (isEmpty(definedFilterRoles)) return [...acc, dealership];
            const filteredUsers =
                dealership?.users?.filter((user) => definedFilterRoles.includes(user.role.slug)) || [];
            if (filteredUsers.length > 0) {
                return [...acc, { ...dealership, users: filteredUsers }];
            }
            return acc;
        }, []);
        return filteredDealerships;
    };

    // define filters
    useEffect(() => {
        if (data.result !== undefined) {
            let results = data.result;
            if (!Array.isArray(data.result)) {
                results = Object.values(data.result);
            }
            const resultsInPerimeter = results.filter((dealership) =>
                userIsAssociateToDealership(dealership?.ref_dealership_id),
            );

            // @TODO to remove when api manage facets
            // initialize filters
            setFacetDealerships(
                resultsInPerimeter.map((result) => ({
                    ref_dealership_usualname: capitalize(result.ref_dealership_usualname),
                    ref_dealership_id: result.ref_dealership_id,
                })),
            );
            setFacetRoles(getRolesFromDealerships(resultsInPerimeter));

            // @TODO to remove when api manage filters
            // filter dealerships
            let filteredResults = resultsInPerimeter;
            filteredResults = filterDealerships(filteredResults, filters);
            filteredResults = filterRoles(filteredResults, filters);
            setDealerships(filteredResults);
        }
    }, [data]);

    const dealershipDataTables = useMemo(
        () =>
            dealerships?.length > 0 &&
            dealerships.map((dealership) => (
                <DealershipsPerimeterDataTable
                    key={dealership.ref_dealership_id}
                    dealership={dealership}
                    handleFilter={handleFilter}
                    handlePagination={handlePagination}
                    handleSort={handleSort}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    loading={loading}
                    refresh={refresh}
                    tags={tags}
                />
            )),
        [dealerships, tags],
    );

    const dealershipFacets = () => (
        <Row className="mt-4">
            <Col lg={6}>
                <Form.Group className="text-left">
                    <Form.Label>
                        <FormattedMessage id="AUTOUSER.APPLICATION.VIEW.DEALERSHIP" />
                    </Form.Label>
                    <DealershipsSelect
                        ref={dealershipSelectorRef}
                        dealerships={facetDealerships}
                        filters={filters}
                        handleFilter={handleFilter}
                    />
                </Form.Group>
            </Col>
            <Col lg={6}>
                <Form.Group className="text-left">
                    <Form.Label>
                        <FormattedMessage id="AUTOUSER.APPLICATION.VIEW.ROLE" />
                    </Form.Label>
                    <RolesSelect
                        ref={roleSelectorRef}
                        roles={facetRoles}
                        filters={filters}
                        handleFilter={handleFilter}
                    />
                </Form.Group>
            </Col>
        </Row>
    );

    useEffect(() => {
        if (!showAddUser) {
            refresh();
        }
    }, [showAddUser]);

    return (
        <FiltersProvider value={filters}>
            <Card>
                {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                {dealershipDataTables?.length > 0 && !error ? (
                    <Card.Body className="text-center">
                        <Row>
                            <Col xl={{ span: 6, offset: 3 }} lg={{ span: 8, offset: 2 }}>
                                <SearchInput
                                    onChange={handleFilter}
                                    refresh={refresh}
                                    name="q"
                                    placeholder={Intl.formatMessage({
                                        id: 'AUTOUSER.DEALERSHIPS.TABLE.VIEW.RESEARCH.PLACEHOLDER',
                                    })}
                                />
                                {!loading && (
                                    <div className="font-size-h4">
                                        <div>
                                            {Intl.formatMessage(
                                                { id: 'AUTOUSER.DEALERSHIPS.TABLE.VIEW.FOUND.NUMBER' },
                                                {
                                                    count: dealershipDataTables?.length,
                                                    research: filters?.q?.value,
                                                },
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        {dealershipFacets()}
                        {dealershipDataTables}
                    </Card.Body>
                ) : error ||
                  (filters?.q?.value !== undefined && (!dealershipDataTables || dealershipDataTables.length === 0)) ? (
                    <Card.Body className="text-center">
                        <Row>
                            <Col xl={{ span: 6, offset: 3 }} lg={{ span: 8, offset: 2 }} className="mb-2">
                                <SearchInput
                                    onChange={handleFilter}
                                    refresh={refresh}
                                    name="q"
                                    placeholder={Intl.formatMessage({
                                        id: 'AUTOUSER.DEALERSHIPS.TABLE.VIEW.RESEARCH.PLACEHOLDER',
                                    })}
                                />
                            </Col>
                        </Row>
                        {dealershipFacets()}
                        {!loading && (
                            <>
                                <div>
                                    <div className="mb-8 font-size-h4 font-weight-bold">
                                        <div className="text-danger">
                                            {Intl.formatMessage(
                                                { id: 'AUTOUSER.DEALERSHIPS.TABLE.VIEW.NOT_FOUND.NUMBER' },
                                                {
                                                    research: filters?.q?.value,
                                                },
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <Image
                                    src="/media/pages/customers/clients-recherche-pas-de-resultat.svg"
                                    className="mt-10"
                                />
                            </>
                        )}
                    </Card.Body>
                ) : (
                    <Card.Body className="text-center mt-10">
                        <div className="font-size-h4 font-weight-bold mb-8">
                            {Intl.formatMessage({ id: 'AUTOUSER.DEALERSHIPS.TABLE.VIEW.START' })}
                        </div>
                        <Col lg={{ span: 6, offset: 3 }}>
                            <SearchInput
                                onChange={handleFilter}
                                refresh={refresh}
                                name="q"
                                placeholder={Intl.formatMessage({
                                    id: 'AUTOUSER.DEALERSHIPS.TABLE.VIEW.RESEARCH.PLACEHOLDER',
                                })}
                            />
                            <Image src="/media/pages/customers/clients-recherche.svg" className="mt-10" />
                        </Col>
                    </Card.Body>
                )}
            </Card>
            {/* @ts-ignore */}
            <ManageUser showModal={showManageUser} setShowModal={toggleManageUser} />
            <AddUserToDealership showModal={showAddUser} setShowModal={setShowAddUser} />
            <ManageDealershipSignatures showModal={showManageSignatures} setShowModal={setShowManageSignatures} />
        </FiltersProvider>
    );
};

export default DealershipsPerimeter;
