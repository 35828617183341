const actionTypes = {
  SetLanguage: "i18n/SET_LANGUAGE"
};

const initialState = {
  lang: "fr"
};

export function i18nReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SetLanguage:
      return {...state, lang: action.payload.lang};

    default:
      return state;
  }
};

export const actions = {
  setLanguage: lang => ({ type: actionTypes.SetLanguage, payload: { lang } })
};
