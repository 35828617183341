import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { LayoutContextConsumer } from '@src/_metronic';
import BreadCrumbs from '@src/_metronic/layout/sub-header/components/BreadCrumbs';

import useModal from '@app/hooks/useModal';

import { routeTo } from '@app/helpers/RoutesHelper';

import { useAppSelector } from '@app/hooks';
import { HIDDEN_STATUS } from '@app/pages/apv/apvinstore/Helpers/statut';
import Cancel from '@app/pages/customers/Modals/APVInStore/Modals/Cancel';
import ROUTES from '@app/router/Routes';

import Permission from '@app/partials/content/Permission';
import subHeader from '@app/partials/HoC/subHeader';

import TimeLine from '../../orders/Modals/TimeLine';

type OrderSubHeaderProps = {
    subheaderCssClasses: string;
    subheaderContainerCssClasses: string;
    subheaderMobileToggle: boolean;
};

function OrderSubHeader({
    subheaderCssClasses,
    subheaderContainerCssClasses,
    subheaderMobileToggle,
}: OrderSubHeaderProps) {
    const Intl = useIntl();
    const [showCancelModal, toggleCancelModal] = useModal(false);
    const [showTimeLineModal, toggleTimeLineModal] = useModal(false);

    const order = useAppSelector((state) => state.apvinstore.order.order);

    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: 'MENU.APV' }),
        },
        {
            title: Intl.formatMessage({ id: 'MENU.APVINSTORE.APPOINTMENT.IN.SHOP' }, { number: order?.id || '' }),
        },
    ];

    return (
        <>
            <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
                <div className={`kt-container ${subheaderContainerCssClasses}`}>
                    <div className="kt-subheader__main">
                        {subheaderMobileToggle && (
                            <button
                                type="button"
                                className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                                id="kt_subheader_mobile_toggle"
                            >
                                <span />
                            </button>
                        )}
                        <LayoutContextConsumer>
                            {() => (
                                <>
                                    <h3 className="kt-subheader__title">
                                        <FormattedMessage id="MENU.APVINSTORE" />
                                    </h3>
                                    <BreadCrumbs items={breadcrumb} />
                                </>
                            )}
                        </LayoutContextConsumer>
                    </div>
                    <div className="d-flex align-items-center">
                        <Link to={routeTo(ROUTES.APV_INSTORE_ORDERS.PATH)}>
                            <Button className="mr-2" variant="outline-light">
                                <FormattedMessage id="TRANSLATOR.BACK" />
                            </Button>
                        </Link>
                        {!HIDDEN_STATUS.includes(order?.orderStatus?.slug) && (
                            <Permission permissions={['APV_DETAILS_ORDER']}>
                                <Button
                                    className="mr-2"
                                    variant="outline-light"
                                    disabled={order?.dealership?.isActiveMecaplanning}
                                    onClick={() => toggleCancelModal(true)}
                                >
                                    <FormattedMessage id="SR.ORDER.VIEW.CANCEL" />
                                </Button>
                            </Permission>
                        )}
                        <Button variant="outline-light" onClick={() => toggleTimeLineModal(true)}>
                            <i className="fa fa-history" />
                            <FormattedMessage id="TIMELINE" />
                        </Button>
                    </div>
                </div>
                <Cancel showModal={showCancelModal} setShowModal={toggleCancelModal} />
            </div>
            <TimeLine showModal={showTimeLineModal} order={order} setShowModal={toggleTimeLineModal} />
        </>
    );
}

export default subHeader(OrderSubHeader);
