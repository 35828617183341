import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';

import { SettingsItem } from '../Settings.type';

const apvItems: SettingsItem[] = [
    {
        permissions: ['APV_LIST_PARAMS'],
        route: () => routeTo(ROUTES.DEALERSHIPS.PATH),
        color: 'primary',
        icon: 'la-building',
        messageId: 'SETTINGS.APV.DEALERSHIPS',
    },
    {
        permissions: ['APV_LIST_CATEGORY'],
        route: () => routeTo(ROUTES.CATEGORIES.PATH),
        color: 'success',
        icon: 'la-sitemap',
        messageId: 'SETTINGS.APV.PRODUCT_FAMILIES',
    },
    {
        permissions: ['APV_LIST_PACKAGE'],
        route: () => routeTo(ROUTES.PACKAGES.PATH.ALL),
        color: 'orange',
        icon: 'la-list-alt',
        messageId: 'SETTINGS.APV.PACKAGES',
    },
    {
        permissions: ['APV_LIST_ADDITIONAL_SERVICE'],
        route: () => routeTo(ROUTES.ADDITIONAL_SERVICES.PATH),
        color: 'danger-lite',
        icon: 'la-cart-plus',
        messageId: 'SETTINGS.APV.ADDITIONAL_SERVICES',
    },
    {
        permissions: ['APV_LIST_COMMERCIAL_OPERATION'],
        route: () => routeTo(ROUTES.OPERATIONS.PATH),
        color: 'dark-orange',
        icon: 'la-bullhorn',
        messageId: 'SETTINGS.APV.COMMERCIAL_OPERATIONS',
    },
    {
        permissions: ['APV_MAPPING_PACKAGE_SITES_CE'],
        route: () => routeTo(ROUTES.PACKAGES_MAPPING.PATH),
        color: 'closed',
        icon: 'la-car',
        messageId: 'SETTINGS.APV.PACKAGES_MAPPING_BY_BRAND',
    },
];

export default apvItems;
