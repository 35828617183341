import { createContext } from 'react';

import { OrderInStore } from '@app/crud/apv/order.type';

import { ModalStepperType } from '@app/partials/content/modals/ModalStepper';

interface APVInStoreContextInterface {
    stepperState: ModalStepperType;
    order: OrderInStore;
    setOrder: (order: OrderInStore) => void;
}

const APVInStoreContext = createContext({} as APVInStoreContextInterface);

export const APVInStoreProvider = APVInStoreContext.Provider;
export const APVInStoreConsumer = APVInStoreContext.Consumer;

export default APVInStoreContext;
