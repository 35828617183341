// library imports
import { useEffect } from 'react';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

// helpers imports
import { isMobile, isNotDigit, isPhone, parsedPhoneNumber } from '../helpers/FormHelper';
import { isAMail } from '../helpers/ToolsHelper';

export const useCheckPhoneValidity = (values, setError, clearErrors, validate, context) => {
    // check validity for 'phone' formField
    useEffect(() => {
        const parsedPhone = parsedPhoneNumber(values.phone, context === 'efb' ? 'BE' : 'FR');
        // Do not check if belgium phone is valid and isPhone
        const parsedPhoneValid =
            (context === 'efb' && values?.phone?.length >= 8) || (parsedPhone?.isValid?.() && isPhone(values.phone));

        if (values.phone && (!!isNotDigit(values.phone) || !parsedPhoneValid)) {
            setError('phone', {
                type: validate,
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.PHONE.FORMAT' }),
            });
        } else {
            clearErrors('phone');
        }
    }, [values.phone, clearErrors, setError, validate, context]);

    // check validity for 'mobile' field
    useEffect(() => {
        const parsedMobile = parsedPhoneNumber(values.mobile, context === 'efb' ? 'BE' : 'FR');
        // Do not check if belgium phone isMobile only valid
        const parsedMobileValid = parsedMobile?.isValid?.() && (context === 'efb' || isMobile(values.mobile));

        if (values.mobile && (!!isNotDigit(values.mobile) || !parsedMobileValid)) {
            setError('mobile', {
                type: validate,
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.PHONE.FORMAT' }),
            });
        } else {
            clearErrors('mobile');
        }
    }, [values.mobile, clearErrors, setError, validate, context]);

    return null;
};

export const useCheckEmailValidity = (email, setError, clearErrors, validate) => {
    // check validity for 'email' field
    useEffect(() => {
        if (email && !isAMail(email)) {
            setError('email', {
                type: validate,
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE.EMAIL.FORMAT' }),
            });
        } else {
            clearErrors('email');
        }
    }, [email, clearErrors, setError, validate]);

    return null;
};
