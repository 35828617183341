import React, {useRef, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {Intl} from "../../../../_metronic/i18n/I18nProvider";

export default function OpeningHours({daysValues}) {
    const days = useRef(['Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi','Dimanche']);
    const [values, setValues] = useState({});

    /*useEffect(() => {
        const newValues = {};

        days.current.forEach((day) => {
            const value = daysValues.find((value) => {
              return value.dayName === day;
            });

            if (value) {
                let timeEndStart = '-';

                value.closedAtNoon = !!value.timeEndClose;
                value.morning = {
                    start: dayjs(value.timeStart).format('HH:mm'),
                    end: value.closedAtNoon ? dayjs(value.timeStartClose).format('HH:mm') : '-'
                }

                if (value.closedAtNoon) {
                    const diff = value.nbHours - dayjs(value.timeStartClose).diff(value.timeStart, 'hour');
                    timeEndStart = dayjs(value.timeEndClose).add(diff, 'hour').format('HH:mm');
                }

                value.afternoon = {
                    start: value.closedAtNoon ? dayjs(value.timeEndClose).format('HH:mm') : '-',
                    end: value.closedAtNoon ? timeEndStart : dayjs(value.timeStartClose).format('HH:mm')
                }
            }

            newValues[day] = value;
        });

        setValues(newValues);
    }, [daysValues, days, setValues]);*/

    return (
        <>
            <Form.Label>{Intl.formatMessage({ id: 'APV.DEALERSHIP.HOURS' })}</Form.Label>
            <div className="dotted" />
            <Row>
                <Col></Col>
                {
                    days.current.map((day, key) => <Col key={key}>{day}</Col>)
                }
            </Row>
            <div className="dotted" />
            <Row>
                <Col></Col>
                {
                    days.current.map((day, key) =>
                        <Col key={key}>
                            <Form.Group className="mt-2 mb-3">
                                <Form.Check type="checkbox"
                                            label="Ouvert"
                                            checked={values[day]?.active || false}
                                            disabled/>
                            </Form.Group>
                        </Col>)
                }
            </Row>
            <Row>
                <Col>Nombre d'heures</Col>
                {
                    days.current.map((day, key) =>
                        <Col key={key}>
                            <Form.Group>
                                <Form.Control type="text"
                                              className="text-center"
                                              defaultValue={
                                                  values[day]?.active
                                                      ? `${values[day]?.nbHours} h`
                                                      : ''
                                              }
                                              disabled/>
                            </Form.Group>
                        </Col>)
                }
            </Row>
            <div className="dotted" />
            <Row>
                <Col>Matin</Col>
                {
                    days.current.map((day, key) =>
                        <Col key={key}>
                            <Form.Group>
                                <Form.Control type="text"
                                              className="text-center"
                                              defaultValue={
                                                  values[day]?.morning?.start && values[day]?.active
                                                      ? values[day]?.morning?.start
                                                      : ''
                                              }
                                              disabled/>
                            </Form.Group>
                        </Col>)
                }
            </Row>
            <Row>
                <Col></Col>
                {
                    days.current.map((day, key) =>
                        <Col key={key}>
                            <Form.Group className="mb-4">
                                <Form.Check type="checkbox"
                                            label="Fermeture midi"
                                            checked={(values[day]?.closedAtNoon && values[day]?.active) || false}
                                            disabled/>
                            </Form.Group>
                        </Col>)
                }
            </Row>
            <Row>
                <Col></Col>
                {
                    days.current.map((day, key) =>
                        <Col key={key}>
                            <Form.Group>
                                <Form.Control type="text"
                                              className="text-center"
                                              defaultValue={
                                                  values[day]?.morning?.end && values[day]?.active
                                                      ? values[day]?.morning?.end
                                                      : ''
                                              }
                                              disabled/>
                            </Form.Group>
                        </Col>)
                }
            </Row>
            <div className="dotted" />
            <Row>
                <Col>Soir</Col>
                {
                    days.current.map((day, key) =>
                        <Col key={key}>
                            <Form.Group>
                                <Form.Control type="text"
                                              className="text-center"
                                              defaultValue={
                                                  values[day]?.afternoon?.start && values[day]?.active
                                                      ? values[day]?.afternoon?.start
                                                      : ''
                                              }
                                              disabled/>
                            </Form.Group>
                        </Col>)
                }
            </Row>
            <Row>
                <Col></Col>
                {
                    days.current.map((day, key) =>
                        <Col key={key}>
                            <Form.Group>
                                <Form.Control type="text"
                                              className="text-center"
                                              defaultValue={
                                                  values[day]?.afternoon?.end && values[day]?.active
                                                      ? values[day]?.afternoon?.end
                                                      : ''
                                              }
                                              disabled/>
                            </Form.Group>
                        </Col>)
                }
            </Row>
        </>
    )
}
