import dayjs from 'dayjs';
import React from 'react';
import { Button } from 'react-bootstrap';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import ModalDefault from '@app/partials/content/modals/Modal.default';

const StockModalTimeLine = ({ show, setShowModal, events, title }) => {
    const timelineHandler = () => {
        const data = [...events];

        return data.reverse().map((event, key) => (
            <div key={key} className="timeline-item align-items-start">
                <div className="timeline-label text-right font-size-lg">
                    {dayjs(event.date).format('DD/MM/YY [à] HH:mm')}
                </div>
                <div className="timeline-badge">
                    <i className="fa fa-genderless icon-xl" style={{ color: event.color }} />
                </div>
                <div className="font-weight-normal font-size-lg timeline-content pl-3">
                    {(event.status || event.label || event.description) && (
                        <>
                            <span className="font-weight-bold" style={{ color: event.color }}>
                                {event.status || !event.label ? event?.description : event.label}
                            </span>
                            <br />
                        </>
                    )}
                    {event?.label ? event.description : ''}
                </div>
            </div>
        ));
    };

    return (
        <ModalDefault
            show={show}
            hideModal={() => setShowModal(false)}
            icon={<i className="text-primary las la-wrench fa-2x" />}
            title={title}
            body={
                <div className="timeline-handler-modal">
                    <div className="timeline h-100">{events && timelineHandler()}</div>
                </div>
            }
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button variant="primary" onClick={() => setShowModal(false)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default StockModalTimeLine;
