import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import useFetch from '@app/hooks/useFetch';

import { Dealership, getDashboardCrewActivity } from '@app/crud/dashboard/dashboard.crud';

import DashboardActivityDealershipCounter from '@app/pages/dashboard/partials/Activity/DashboardActivityDealershipCounter';
import DashboardActivityTimeline from '@app/pages/dashboard/partials/Activity/DashboardActivityTimeline';
import ROUTES from '@app/router/Routes';

import Expander from '@app/partials/content/Expander';
import Loader from '@app/partials/content/Loader';

import { Icon } from '@app/components/Icon';
import { IconSize } from '@app/components/Icon/Icon';

const variants = {
    shown: {
        opacity: 1,
        height: 'auto',
        overflow: 'hidden',
        transition: {
            ease: 'easeOut',
        },
    },
    hidden: {
        opacity: 0,
        height: 0,
        overflow: 'hidden',
        transition: {
            ease: 'easeOut',
        },
    },
};

type DashboardActivityDealershipProps = {
    dealership: Dealership;
};

const DashboardActivityDealership = ({ dealership }: DashboardActivityDealershipProps) => {
    const [collapsed, setCollapsed] = useState(true);

    const {
        fetch: fetchDealers,
        data: dealers,
        loading: loadingDealers,
        setData: setDealers,
    } = useFetch({
        fetchAction: getDashboardCrewActivity,
    });

    useEffect(() => {
        if (!collapsed) {
            fetchDealers(dealership.dealershipId);
        }
    }, [fetchDealers, dealership, collapsed]);

    return (
        <div className="mb-2">
            <Divider className="my-1" type="dashed" />
            <div className="d-flex flex-column flex-grow-1 font-weight-bold mt-4">
                <span className="font-size-lg">&bull;&nbsp;{dealership.usualName}</span>
            </div>

            {(dealership.newLeads > 0 ||
                dealership.newApvs > 0 ||
                dealership.leadsWithActionsToDo > 0 ||
                dealership.apvsWithActionsToDo > 0 ||
                dealership.unreadNotes > 0) && (
                <div className="d-flex flex-wrap my-1 align-items-center bullet-list">
                    <span className="d-flex align-items-center text-danger font-size-lg">
                        <Icon icon="hand-pointer" size={IconSize.XL} className="pr-1" />
                        <FormattedHTMLMessage
                            id="DASHBOARD.ACTIVITY.ACTIONS"
                            values={{
                                number: dealership.actionsToDo,
                            }}
                        />
                    </span>
                    {dealership.newLeads > 0 && (
                        <DashboardActivityDealershipCounter
                            message={
                                <FormattedHTMLMessage
                                    id="DASHBOARD.ACTIVITY.NEW_LEADS"
                                    values={{
                                        number: dealership.newLeads,
                                    }}
                                />
                            }
                            route={ROUTES.LEADS_ONLINE.PATH}
                            params={{
                                statusType: 'new',
                            }}
                            filter="leads_online_new"
                            filterValues={{
                                dealership: {
                                    value: dealership.usualName,
                                },
                                user: {
                                    value: null,
                                },
                            }}
                        />
                    )}
                    {dealership.newApvs > 0 && (
                        <DashboardActivityDealershipCounter
                            message={
                                <FormattedHTMLMessage
                                    id="DASHBOARD.ACTIVITY.NEW_APVS"
                                    values={{
                                        number: dealership.newApvs,
                                    }}
                                />
                            }
                            route={ROUTES.APV_LEADS.PATH}
                            params={{
                                statusType: 'new',
                            }}
                            filter="leads_apv_new"
                            filterValues={{
                                dealership: {
                                    value: dealership.usualName,
                                },
                                user: {
                                    value: null,
                                },
                            }}
                        />
                    )}
                    {dealership.leadsWithActionsToDo > 0 && (
                        <DashboardActivityDealershipCounter
                            message={
                                <FormattedHTMLMessage
                                    id="DASHBOARD.ACTIVITY.LEADS_WITH_ACTIONS_TO_DO"
                                    values={{
                                        number: dealership.leadsWithActionsToDo,
                                    }}
                                />
                            }
                            route={ROUTES.LEADS_ONLINE.PATH}
                            params={{
                                statusType: 'action-to-do',
                            }}
                            filter="leads_online_action-to-do"
                            filterValues={{
                                dealership: {
                                    value: dealership.usualName,
                                },
                                user: {
                                    value: null,
                                },
                            }}
                        />
                    )}
                    {dealership.apvsWithActionsToDo > 0 && (
                        <DashboardActivityDealershipCounter
                            message={
                                <FormattedHTMLMessage
                                    id="DASHBOARD.ACTIVITY.APVS_WITH_ACTIONS_TO_DO"
                                    values={{
                                        number: dealership.apvsWithActionsToDo,
                                    }}
                                />
                            }
                            route={ROUTES.APV_LEADS.PATH}
                            params={{
                                statusType: 'action_to_do',
                            }}
                            filter="leads_apv_action_to_do"
                            filterValues={{
                                dealership: {
                                    value: dealership.usualName,
                                },
                                user: {
                                    value: null,
                                },
                            }}
                        />
                    )}
                    {dealership.unreadNotes > 0 && (
                        <DashboardActivityDealershipCounter
                            message={
                                <FormattedHTMLMessage
                                    id="DASHBOARD.ACTIVITY.UNREAD_NOTES"
                                    values={{
                                        number: dealership.unreadNotes,
                                    }}
                                />
                            }
                            route={ROUTES.NOTES.PATH}
                            params={{
                                status: 'toTake',
                            }}
                            filter="customers_notes_search"
                            filterValues={{
                                dealershipName: {
                                    value: dealership.usualName,
                                },
                            }}
                        />
                    )}
                </div>
            )}
            <div className="d-flex flex-wrap my-1 align-items-center bullet-list">
                <span className="d-flex align-items-center font-size-lg font-weight-bold">
                    <Icon icon="redo-alt" size={IconSize.XL} className="pr-1" />
                    {dealership.leads > 0 || dealership.apvs > 0 ? (
                        <FormattedHTMLMessage id="DASHBOARD.IN.PROGRESS.TOTAL" />
                    ) : (
                        <FormattedHTMLMessage id="DASHBOARD.IN.PROGRESS.EMPTY" />
                    )}
                </span>
                {dealership.leads > 0 && (
                    <DashboardActivityDealershipCounter
                        message={
                            <FormattedHTMLMessage
                                id="DASHBOARD.ACTIVITY.LEADS"
                                values={{
                                    number: dealership.leads,
                                }}
                            />
                        }
                        route={ROUTES.LEADS_ONLINE.PATH}
                        params={{
                            statusType: 'all',
                        }}
                        filter="leads_online_all"
                        filterValues={{
                            dealership: {
                                value: dealership.usualName,
                            },
                            user: {
                                value: null,
                            },
                        }}
                    />
                )}
                {dealership.apvs > 0 && (
                    <DashboardActivityDealershipCounter
                        message={
                            <FormattedHTMLMessage
                                id="DASHBOARD.ACTIVITY.APVS"
                                values={{
                                    number: dealership.apvs,
                                }}
                            />
                        }
                        route={ROUTES.APV_LEADS.PATH}
                        params={{
                            statusType: 'all',
                        }}
                        filter="leads_apv_all"
                        filterValues={{
                            dealership: {
                                value: dealership.usualName,
                            },
                            user: {
                                value: null,
                            },
                        }}
                    />
                )}
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
                className="d-flex justify-content-between align-items-center cursor-pointer my-1"
                onClick={() => setCollapsed(!collapsed)}
            >
                <span className="d-flex align-items-center">
                    {dealership.appointments > 0 ? (
                        <>
                            <Icon icon="calendar" size={IconSize.XL} className="pr-1" />
                            <FormattedHTMLMessage
                                id="DASHBOARD.ACTIVITY.APPOINTMENTS"
                                values={{ number: dealership.appointments }}
                            />
                        </>
                    ) : (
                        <FormattedMessage id="DASHBOARD.ACTIVITY.APPOINTMENTS.EMPTY" />
                    )}
                </span>
                <div className="d-flex align-items-center" style={{ height: '32.5px' }}>
                    <Link className="ml-3 text-nowrap pr-2" onClick={(e) => e.preventDefault()} to="/#">
                        <u>
                            <FormattedHTMLMessage id="DASHBOARD.SEE.DETAILS" />
                        </u>
                    </Link>
                    <Expander
                        className="cursor-pointer"
                        variant="gray"
                        size="la-2xl"
                        collapsed={collapsed}
                        setCollapsed={(value) => {
                            setDealers([]);
                            setCollapsed(value);
                        }}
                        style={{ width: '32.5px' }}
                    />
                </div>
            </div>
            {!collapsed && <Divider className="my-2" type="dashed" />}
            <div className="position-relative">
                {loadingDealers && <Loader style={{ width: '5rem', height: '5rem' }} block />}
                <motion.div variants={variants} initial="hidden" animate={collapsed ? 'hidden' : 'shown'}>
                    {dealers && !collapsed && !loadingDealers && (
                        <DashboardActivityTimeline
                            data={dealers}
                            dealership={dealership}
                            timelineClassName={classNames('mt-0', {
                                'mt-4': collapsed,
                            })}
                            displayTransformationRate
                            displayOverlayInfo
                        />
                    )}
                    {(!dealers || dealers.length === 0) && !collapsed && !loadingDealers && (
                        <div className="my-2 ml-2 text-dark-light">
                            <span className="font-size-base">
                                <FormattedMessage id="DASHBOARD.NO_DATA" />
                            </span>
                        </div>
                    )}
                </motion.div>
            </div>
        </div>
    );
};

export default DashboardActivityDealership;
