import classNames from 'classnames';
import React from 'react';
import { Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const DashboardQlik = ({ className }) => {
    const userData = useSelector((state) => state.auth.userData);
    const context = userData?.context?.toUpperCase();
    const qlikURL = process.env[`REACT_APP_QLIK_URL_${context}`];

    if (!qlikURL) return null;

    return (
        <div
            className={classNames(
                'dashboard-qlik font-weight-semibold d-flex align-items-center justify-content-center',
                className,
            )}
        >
            <div className="text-right">
                <FormattedMessage id="DASHBOARD.QLIK.TITLE" />
            </div>
            <a href={qlikURL} target="_blank" className="ml-3 py-1" rel="noreferrer">
                <div className="dashboard-qlik__picture">
                    <Image src="/media/pages/dashboard/qlik.png" />
                </div>
            </a>
        </div>
    );
};

export default DashboardQlik;
