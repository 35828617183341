import CryptoJS from 'crypto-js';

interface URLParams {
    [key: string]: string | undefined;
}

const AES_BLOCK_SIZE = 16;
const keyString = process.env.REACT_APP_RADAR_SECRET;

const PKCS5_pad = (data: any) => {
    const padding = String.fromCharCode(AES_BLOCK_SIZE - (data.length % AES_BLOCK_SIZE)).repeat(
        AES_BLOCK_SIZE - (data.length % AES_BLOCK_SIZE),
    );
    const paddeddata = data.concat(padding);
    return paddeddata;
};

export const encrypt = (data: any) => {
    const md5KeyString = CryptoJS.MD5(keyString).toString();
    const paddeddata = PKCS5_pad(data);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(paddeddata), CryptoJS.enc.Hex.parse(md5KeyString), {
        mode: CryptoJS.mode.CBC,
        iv: CryptoJS.enc.Hex.parse(md5KeyString),
        padding: CryptoJS.pad.NoPadding,
    });

    // @ts-ignore
    let encryptedb64 = encrypted.toString(CryptoJS.enc.base64);
    encryptedb64 = encryptedb64.replace(/\+/g, '-');
    encryptedb64 = encryptedb64.replace(/\//g, '_');

    return encryptedb64;
};

const useRadarEncoder = (url: string) => {
    const splittedURL = url.split('?');
    const searchParams = new URLSearchParams(splittedURL[1]);
    let params: URLParams = {};

    for (const [key, value] of searchParams.entries()) {
        if (process.env.REACT_APP_ENV_PATH !== 'dev' && key === 'debugParameters') continue;

        params[key] = key !== 'debugParameters' && key !== 'partnerID' ? encrypt(value) : value;
    }

    const link =
        splittedURL[0] +
        '?' +
        Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

    return link;
};

export default useRadarEncoder;
