import metadata from 'libphonenumber-js/metadata.full.json';

import { leadSlug } from '@app/pages/customers/Helpers/Lead';
import { Lead } from '@app/store/customers/customers.store';

/**
 * Check if property is empty
 *
 * @param data - The data to check
 * @param excludes - Array of values to exclude from the empty check
 * @returns {boolean} - Returns true if the data is considered empty
 */
export function empty(data: any, excludes: any[] = []): boolean {
    if (data === '0') {
        return true;
    }
    if (typeof data === 'number' || typeof data === 'boolean') {
        return false;
    }
    if ((typeof data === 'undefined' || data === null) && !excludes.includes(null)) {
        return true;
    }
    if (typeof data?.length !== 'undefined') {
        return data?.length === 0;
    }

    return false;
}

/**
 * Check if string is possibly phone number
 *
 * @param phone
 * @returns {boolean}
 */
export const isAPhoneNumber = (phone: string) =>
    Object.values(metadata.countries).reduce((acc, country) => {
        const regex = new RegExp(country[2], 'g');

        if (regex.test(phone)) {
            acc.push(country);
        }

        return acc;
    }, []).length > 0;

/**
 * Check if string is possibly email
 * @param email
 * @returns {boolean}
 */
export const isAMail = (email: string) => {
    const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
};

/**
 * Check if string is possibly zipcode
 * @param zipcode
 * @returns {boolean}
 */
export const isAZipCode = (zipcode: string) => {
    const regex = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/;
    return regex.test(String(zipcode).toLowerCase());
};

/**
 * Add debounce timeout
 * @param fn - The function to be debounced
 * @param ms - The amount of time in milliseconds to delay
 * @returns A debounced version of the given function
 */

// Define a generic type for the debounced function
export const debounce = <T extends (...args: unknown[]) => unknown>(
    fn: T,
    ms: number,
): ((...args: Parameters<T>) => void) => {
    let timer: NodeJS.Timeout | null = null;

    return (...args: Parameters<T>) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            fn.apply(this, args);
        }, ms);
    };
};

/**
 * Check if location is a sublocation of precedent location
 */
export const isNewRootLocation = (nextPath: string, prevPath: string) => {
    const splittedPrevMatchPath = prevPath ? prevPath.split('/')[1] : '';
    const splittedPath = nextPath ? nextPath.split('/')[1] : '';

    return splittedPath !== splittedPrevMatchPath;
};

/** *
 * Parse JWT token
 *
 * @param token
 * @returns {any}
 */
export const parseJWT = (token: string) => {
    if (!token) return {};

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join(''),
    );

    return JSON.parse(jsonPayload);
};

interface Client {
    leads?: Lead[];
}
/** *
 * Check if the phone number is visible for Audi Leads
 *
 * @param client
 * @returns {boolean/undefined}
 */
export const displayPhoneNumber = (client: Client) => {
    let isVisible;
    if (client?.leads?.length > 0) {
        for (const lead of client.leads) {
            const isLeadAudi = lead.leadOrigin.slug === leadSlug.LEAD_AUDI_ORIGIN_SLUG;
            const isLeadOneLead = lead.leadSource.slug === leadSlug.LEAD_ONE_LEAD_ORIGIN_SLUG;

            const isCalledInTimeAudi = lead.callTrackingStatus === leadSlug.CALL_TRACKING_STATUS_AUDI_CALL_IN_TIME;
            const isCalledAfterDeadlineAudi =
                lead.callTrackingStatus === leadSlug.CALL_TRACKING_STATUS_AUDI_CALLED_AFTER_DEADLINE;
            const isCalledInTimeOneLead =
                lead.callTrackingStatus === leadSlug.CALL_TRACKING_STATUS_ONE_LEAD_CALL_IN_TIME;
            const isCalledAfterDeadlineOneLead =
                lead.callTrackingStatus === leadSlug.CALL_TRACKING_STATUS_ONE_LEAD_CALLED_AFTER_DEADLINE;
            const isJoint = lead.callTrackingComment === leadSlug.CALL_TRACKING_STATUS_COMMENT_JOINT;

            const showPhoneNumberForAudi = (isCalledInTimeAudi && isJoint) || (isCalledAfterDeadlineAudi && isJoint);
            const showPhoneNumberForOneLead =
                !lead.callTrackingStatus ||
                (lead.callTrackingStatus && (isCalledInTimeOneLead || isCalledAfterDeadlineOneLead));

            if ((isLeadAudi && !showPhoneNumberForAudi) || (isLeadOneLead && !showPhoneNumberForOneLead)) {
                return false;
            }

            isVisible = true;
        }
    } else if (client?.leads && client?.leads?.length === 0) {
        return true;
    }
    return isVisible;
};

/**
 * Check if string is possibly SIREN
 * @param siren
 * @returns {boolean}
 */
export const isASiren = (siren: string) => {
    const regex = /^[0-9]{9}$/g;
    return regex.test(String(siren).toLowerCase());
};

/**
 * Check if string is possibly SIRET
 * @param siret - The string or number to check
 * @returns {boolean} - Whether the input is a valid SIRET number
 */
export const isASiret = (siret: string | number): boolean => {
    const regex = /^[0-9]{14}$/g;
    return regex.test(String(siret).toLowerCase());
};

/**
 * Creates a promise that resolves after a specified number of milliseconds.
 * @param ms - The number of milliseconds to wait before resolving the promise
 * @returns {Promise<void>} - A promise that resolves after the specified time
 */
export const sleep = (ms: number): Promise<void> =>
    new Promise((r) => {
        setTimeout(r, ms);
    });
