import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

interface Props {
    event: VcuMappedEventDetails;
}
const SecurycarTermination = ({ event }: Props) => {
    const Intl = useIntl();
    return (
        <div className="d-md-flex justify-content-md-between align-items-md-stretch">
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.TERMINATION.CONTRACT.NUMBER' })}
                    value={
                        event?.securycar.termination[0].contractNumber
                            ? event?.securycar.termination[0].contractNumber
                            : '-'
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.TERMINATION.DATE' })}
                    value={
                        event?.securycar.termination[0].terminationDate
                            ? dayjs(event?.securycar.termination[0].terminationDate).format('DD/MM/YYYY')
                            : '-'
                    }
                />
            </div>
        </div>
    );
};

export default SecurycarTermination;
