import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import useFetch from '@app/hooks/useFetch';

import { getDashboardPriorityActions, PriorityActions } from '@app/crud/dashboard/dashboard.crud';

import { useAppSelector } from '@app/hooks';

import { DashboardContext } from '../../DashboardProvider';

import DashboardPrioritiesTimeline from './DashboardPrioritiesTimeline';

interface DashboardPrioritiesProps {
    className?: string;
    picture?: boolean;
}

const DashboardPriorities = ({ className, picture }: DashboardPrioritiesProps) => {
    const { overriddenParams } = useContext(DashboardContext);
    const { userData } = useAppSelector((state) => state.auth);

    const { data, loading, fetch } = useFetch({
        fetchAction: getDashboardPriorityActions,
    });

    useEffect(() => {
        if (overriddenParams) {
            fetch({ params: overriddenParams });
        }
    }, [overriddenParams]);

    if (loading) {
        return <DashboardPriorities.Skeleton />;
    }

    const filteredArray = (): PriorityActions[] =>
        data &&
        data.filter((item) => {
            const userRoleSlug = userData?.role?.slug;
            const leadTypeSlug = item.leadType?.slug;

            // Check if userRoleSlug is one of the specified values and leadTypeSlug is "local_apv"
            return (
                (userRoleSlug === 'conseiller-commercial-services' || userRoleSlug === 'agent-autosphere-contact') &&
                leadTypeSlug === 'local_apv'
            );
        });
    const usedData =
        userData?.role?.slug === 'conseiller-commercial-services' || userData?.role?.slug === 'agent-autosphere-contact'
            ? filteredArray()
            : data;

    return (
        <div className={classNames('dashboard-priorities', className)}>
            <div className="font-size-lg font-weight-bold d-flex text-danger align-items-center">
                <i className="text-danger las la-2x la-hand-point-up mr-2" />
                <FormattedMessage id="DASHBOARD.PRIORITIES.TITLE" values={{ counter: usedData?.length || 0 }} />
            </div>
            <DashboardPrioritiesTimeline actions={usedData || []} picture={picture} />
        </div>
    );
};

DashboardPriorities.defaultProps = {
    className: null,
    picture: null,
};

DashboardPriorities.Skeleton = () => <Skeleton borderRadius="10px" className="mt-8 p-30" />;

export default DashboardPriorities;
