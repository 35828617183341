export const actionTypes = {
    FetchCategories: 'FETCH_CATEGORIES',
    FetchCategory: 'FETCH_CATEGORY',
    AddCategory: 'ADD_CATEGORY',
    RemoveCategory: 'REMOVE_CATEGORY',
    UpdateCategory: 'UPDATE_CATEGORY',
    FetchedCategories: 'FETCHED_CATEGORIES',
    FetchedCategory: 'FETCHED_CATEGORY',
    UpdatedCategory: 'UPDATED_CATEGORY',
    AddedCategory: 'ADDED_CATEGORY',
    FetchCategoriesFailed: 'FETCH_CATEGORIES_FAILED',
    FetchCategoryFailed: 'FETCH_CATEGORY_FAILED',
    UpdateCategoryFailed: 'UPDATE_CATEGORY_FAILED',
    AddCategoryFailed: 'ADD_CATEGORY_FAILED',
};

const initialState = {
    loading: false,
    categories: [],
    parents: [],
    totalRecords: 0,
    segments: [],
    category: null,
    error: null,
};

export function categoryReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FetchCategories:
        case actionTypes.FetchCategory: {
            return {
                ...state,
                loading: true,
                category: null,
                error: null,
            };
        }
        case actionTypes.AddCategory:
        case actionTypes.RemoveCategory:
        case actionTypes.UpdateCategory: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }
        case actionTypes.FetchedCategories: {
            return {
                ...state,
                loading: false,
                totalRecords: action.payload.count,
                categories: action.payload.categories,
            };
        }
        case actionTypes.FetchedCategory: {
            return {
                ...state,
                loading: false,
                category: action.payload.category,
                parents: action.payload.categories,
            };
        }
        case actionTypes.UpdatedCategory:
        case actionTypes.AddedCategory: {
            return { ...state, loading: false, category: action.payload };
        }
        case actionTypes.FetchCategoriesFailed:
        case actionTypes.FetchCategoryFailed: {
            return { ...state, loading: false, error: action.payload };
        }
        case actionTypes.UpdateCategoryFailed:
        case actionTypes.AddCategoryFailed: {
            return { ...state, loading: false };
        }
        default:
            return state;
    }
}

export const actions = {
    fetchCategories: () => ({ type: actionTypes.FetchCategories }),
    fetchedCategories: (categories) => ({ type: actionTypes.FetchedCategories, payload: categories }),
    fetchCategoriesFailed: (categories) => ({ type: actionTypes.FetchCategoriesFailed, payload: categories }),
    fetchCategory: () => ({ type: actionTypes.FetchCategory }),
    fetchedCategory: (result) => ({ type: actionTypes.FetchedCategory, payload: result }),
    fetchCategoryFailed: (error) => ({ type: actionTypes.FetchCategoryFailed, payload: error }),
    addCategory: () => ({ type: actionTypes.AddCategory }),
    removeCategory: () => ({ type: actionTypes.RemoveCategory }),
    updateCategory: () => ({ type: actionTypes.UpdateCategory }),
    updatedCategory: (result) => ({ type: actionTypes.UpdatedCategory, payload: result }),
    addedCategory: (result) => ({ type: actionTypes.AddedCategory, payload: result }),
    updateCategoryFailed: (error) => ({ type: actionTypes.UpdateCategoryFailed, payload: error }),
    addCategoryFailed: (error) => ({ type: actionTypes.AddCategoryFailed, payload: error }),
};
