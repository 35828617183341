import { useState } from 'react';
import { useIntl } from 'react-intl';

import usePermissions from '@app/hooks/usePermissions';
import useTypologies from '@app/hooks/useTypologies';

import { mobilizeAuthenticate } from '@app/crud/homeCharge/home-charge.crud';

import toast from '@app/partials/content/Toast';

const useMobilize = () => {
    const Intl = useIntl();
    const { hasTypology } = useTypologies();
    const { hasPermission } = usePermissions();
    const [isLoading, setIsLoading] = useState(false);
    const isAllowedLeadHomeCharge = hasTypology('local_home_charge') && hasPermission('LEAD_HOME_CHARGE_CREATE');

    const handleResponse = (response: any) => {
        if (response.result) window.open(response.result);
    };

    const handleError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'MENU.FOOTER.BUTTON.HOME_CHARGE.ERROR' }),
        });
    };

    const handleFinally = () => {
        setIsLoading(false);
    };

    const redirectToMobilize = () => {
        setIsLoading(true);
        toast({
            variant: 'info',
            message: Intl.formatMessage({ id: 'MENU.FOOTER.BUTTON.HOME_CHARGE.CONNECTION' }),
        });

        mobilizeAuthenticate().then(handleResponse).catch(handleError).finally(handleFinally);
    };

    return {
        isLoading,
        isAllowedLeadHomeCharge,
        redirectToMobilize,
    };
};

export default useMobilize;
