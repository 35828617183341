import * as dayjs from 'dayjs';
import React from 'react';
import { Image, OverlayTrigger, Popover } from 'react-bootstrap';

import getAppointmentSource from '../helpers/AgendaHelper';

export default function EventRender({ event }) {
    const startTime = dayjs(event.start).format('HH:mm');
    const endTime = dayjs(event.end).format('HH:mm');
    const fullDateTime = !event.allDay ? `${startTime} - ${endTime}` : '';
    const src = getAppointmentSource(event.extendedProps);
    const contact = event.extendedProps.contact
        ? ` - ${event.extendedProps.contact.lastname} ${event.extendedProps.contact.firstname}`
        : '';

    if (event.extendedProps.isPrivate) {
        return (
            // private event
            <>
                <i className="fa fa-lock" />
                <div className="fc-event-main-frame">
                    <div className="fc-event-time" data-start={startTime}>
                        <span>{event.allDay ? null : startTime}</span>
                    </div>
                </div>
            </>
        );
    } else if (event.allDay) {
        return (
            // allDay event
            <div className="fc-event-main-frame">
                <div className="fc-event-time" data-start={startTime} />
                <div className="fc-event-title">{event.title}</div>
            </div>
        );
    }
    return (
        // default event with popover
        <OverlayTrigger
            trigger="hover"
            key={event.id}
            placement={event.allDay ? 'right' : 'bottom'}
            overlay={
                <Popover id={`popover-${event.id}`}>
                    <Popover.Title as="h3" className="event-popover_title">
                        <Image src={src} widht="15px" height="19px" />
                        &nbsp;
                        {fullDateTime}
                        {contact}
                    </Popover.Title>
                    <Popover.Content>{event.title}</Popover.Content>
                </Popover>
            }
        >
            <div className="fc-event-main-frame">
                <div className="fc-event-time" data-start={startTime}>
                    <span>
                        {startTime}
                        {contact}
                    </span>
                </div>
                <div className="fc-event-title">{event.title}</div>
            </div>
        </OverlayTrigger>
    );
}
