import dayjs from 'dayjs';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import ModalDefault from '@app/partials/content/modals/Modal.default';

export default function Details({ order, showModal, setShowModal }) {
    const Intl = useIntl();

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={handleClose}
            icon={<i className="las la-wrench la-2x text-primary" />}
            title={Intl.formatMessage({ id: 'APV.ORDER.MODAL.DETAILS' })}
            body={
                <div className="px-5">
                    <Row className="mb-4">
                        <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.MODAL.DETAILS.REFERENCE' })}</Col>
                        <Col className="font-weight-semibold" lg={6}>
                            {order?.orderPayment?.payid && <span>{order.orderPayment.payid}</span>}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.MODAL.DETAILS.DATE' })}</Col>
                        <Col className="font-weight-semibold" lg={6}>
                            {order?.orderPayment?.created && (
                                <span>{dayjs(order.orderPayment.created).format('DD/MM/YYYY [•] HH:mm:ss')}</span>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.MODAL.DETAILS.CODE' })}</Col>
                        <Col className="font-weight-semibold" lg={6}>
                            {order?.orderPayment?.acceptance && <span>{order.orderPayment.acceptance}</span>}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.MODAL.DETAILS.STATUS' })}</Col>
                        <Col className="font-weight-semibold" lg={6}>
                            {order?.orderStatus && (
                                <span>
                                    {order.orderStatus.id} • {order.orderStatus.name}
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.MODAL.DETAILS.AMOUNT' })}</Col>
                        <Col className="font-weight-semibold" lg={6}>
                            {order?.orderPayment?.amount && (
                                <NumberFormat
                                    value={order.orderPayment.amount}
                                    thousandSeparator=" "
                                    decimalSeparator=","
                                    decimalScale="2"
                                    fixedDecimalScale
                                    displayType="text"
                                    suffix=" €"
                                />
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.MODAL.DETAILS.METHOD' })}</Col>
                        <Col className="font-weight-semibold" lg={6}>
                            {order?.orderPayment && (
                                <span>
                                    {order.orderPayment?.pm || ''} • {order.orderPayment?.brand || ''}
                                </span>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.MODAL.DETAILS.BUYER' })}</Col>
                        <Col className="font-weight-semibold" lg={6}>
                            {order?.orderPayment?.cn && <span>{order.orderPayment.cn}</span>}
                        </Col>
                    </Row>
                </div>
            }
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button variant="primary" onClick={handleClose}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                </div>
            }
        />
    );
}
