export const carZonesRims = [
    'cote-gauche-jante-avant-gauche',
    'cote-gauche-jante-arriere-gauche',
    'cote-droit-jante-arriere-droite',
    'cote-droit-jante-avant-droite',
];

export const carZones = [
    'cote-gauche-bas-de-caisse-gauche',
    'cote-gauche-aile-arriere-gauche',
    'cote-gauche-aile-avant-gauche',
    'cote-gauche-porte-arriere-gauche',
    'cote-gauche-porte-avant-gauche',
    'cote-droit-bas-de-caisse-droit',
    'cote-droit-aile-avant-droite',
    'cote-droit-aile-arriere-droite',
    'cote-droit-porte-arriere-droite',
    'cote-droit-porte-avant-droite',
    'arriere-hayon',
    'arriere-pare-brise-arriere',
    'arriere-pare-choc-arriere',
    'arriere-phare-arriere-droit',
    'arriere-phare-arriere-gauche',
    'avant-capot-avant',
    'avant-pare-choc-avant',
    'avant-pare-brise',
    'avant-retroviseur-gauche',
    'avant-retroviseur-droit',
    'avant-phare-avant-gauche',
    'avant-phare-avant-droit',
    'toit-toit',
    'toit-antenne',
    'cote-droit-phare-avant-droit',
    'cote-gauche-phare-avant-gauche',
    'cote-droit-phare-arriere-droit',
    'cote-gauche-phare-arriere-gauche',
    'cote-gauche-retroviseur-gauche',
    'cote-droit-retroviseur-droit',
    'cote-gauche-custode-gauche',
    'cote-droit-custode-droite',
    ...carZonesRims,
];

/**
 * @description: hides all orange zones of the car svg
 *
 * @returns
 */
export const hideCarZones = () => {
    let createdZones = '';
    carZones.forEach((zone, index) => {
        createdZones += `#${zone}${index < carZones.length - 1 ? ',' : ''}`;
    });
    createdZones += `{visibility: hidden;}`;
    return createdZones;
};

/**
 * @description: shows all orange zones of the car svg
 *
 * @param {string[]} bodyZones - the slugs of bodyzones
 * @returns
 */
export const showCarZones = (bodyZones) => {
    let createdZones = '';
    bodyZones?.forEach((zone, index) => {
        createdZones += `#${zone.zoneBody.slug}${index < bodyZones.length - 1 ? ',' : ''}`;
    });
    createdZones += `{visibility: visible;}`;
    return `${createdZones}`;
};
