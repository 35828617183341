import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useParams, withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';

import useFetch from '@app/hooks/useFetch';

import { alphabeticalSort } from '@app/helpers/ArrayHelper';
import { routeTo } from '@app/helpers/RoutesHelper';

import { deleteSignature, getSignature } from '@app/crud/administration/signature.crud';

import ROUTES from '@app/router/Routes';

import Editor, { LIGHT_BUTTONS } from '@app/partials/content/Editor';
import { JoditButton, JoditCollection, JoditPopup } from '@app/partials/content/Jodit';
import Loader from '@app/partials/content/Loader';
import SwitchInput from '@app/partials/content/SwitchInput';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

import EMAIL_TAGS, { formatEmailTag, formatEmailValue, getEmailLabel } from '../email-template/Helpers/email-tags';

import { formSubmit } from './Form/form.submit';

function ActionSignatureType({ history }) {
    const Intl = useIntl();
    const refEditor = useRef();
    const { reset, register, handleSubmit, control, errors, watch } = useFormContext();
    const params = useParams();
    const isCreate = !params?.id;
    const allowedTags = ['DEALERSHIP_', 'DEALER_'];
    const filteredTags = Object.entries(EMAIL_TAGS).filter(
        ([key]) => allowedTags.includes(key) || allowedTags.some((str) => key.includes(str)),
    );
    const {
        fetch: fetchActionSignatureType,
        data: actionSignatureType,
        error: errorActionSignatureType,
        loading: loadingActionSignatureType,
    } = useFetch({
        fetchAction: getSignature,
    });
    const signatureContent = watch('value');

    const [signature, setSignature] = useState(actionSignatureType?.value);
    const [tagsData, setTagsData] = useState();

    const { userData } = useSelector((state) => state.auth);
    const dealerships = useMemo(
        () =>
            Object.entries(userData?.dealerships)
                ?.sort?.(alphabeticalSort('title'))
                ?.map?.(([key, dealership]) => ({
                    ...dealership,
                    refDealershipId: key,
                })),
        [userData],
    );

    const onSubmit = (data) => {
        formSubmit({
            isCreate,
            data,
            history,
            id: params?.id,
        });
    };

    useEffect(() => {
        setSignature(formatEmailValue(signatureContent, tagsData));
    }, [signatureContent, tagsData]);

    useEffect(() => {
        setTagsData((prevState) => ({
            ...prevState,
            userData,
            dealership: userData?.dealerships ? Object.keys(userData.dealerships)[0] : undefined,
        }));
    }, [signature, setTagsData, userData]);

    const handleDelete = () => {
        if (actionSignatureType?.id) {
            Swal.fire({
                title: Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.REMOVE.TITLE' }),
                text: Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.REMOVE.TEXT' }),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
                cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
                customClass: {
                    confirmButton: 'btn mr-3 btn-primary',
                    cancelButton: 'btn btn-secondary',
                },
                buttonsStyling: false,
                preConfirm: () => {
                    Swal.showLoading();
                    return deleteSignature(actionSignatureType?.id)
                        .then(() => {
                            history.replace(routeTo(ROUTES.SIGNATURE_TEMPLATES.PATH));
                            toast({
                                variant: 'success',
                                message: Intl.formatMessage({
                                    id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.REMOVE.SUCCESS',
                                }),
                            });
                        })
                        .catch(() => {
                            toast({
                                variant: 'danger',
                                message: Intl.formatMessage({
                                    id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.REMOVE.ERROR',
                                }),
                            });
                        });
                },
            });
        }
    };

    useEffect(() => {
        if (params?.id) {
            fetchActionSignatureType(params.id);
        }
    }, [fetchActionSignatureType]);

    useEffect(() => {
        if (errorActionSignatureType) {
            history.replace(routeTo(ROUTES.SIGNATURE_TEMPLATES.PATH));
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    }, [errorActionSignatureType]);

    useEffect(() => {
        reset({
            ...actionSignatureType,
            actionSignatureDealerships: actionSignatureType?.actionSignatureDealerships
                ? actionSignatureType?.actionSignatureDealerships.map(
                      (emailSignatureCompagnie) => emailSignatureCompagnie.slug,
                  )
                : [],
        });
    }, [actionSignatureType, reset]);

    return (
        <Form className="package" onSubmit={handleSubmit(onSubmit)}>
            {loadingActionSignatureType && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <Row className="mb-4">
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-info-circle" />
                            {Intl.formatMessage({ id: 'APV.DEALERSHIP.INFORMATIONS' })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Group controlId="name">
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.NAME' })}
                                </Form.Label>
                                <Form.Control
                                    name="name"
                                    type="text"
                                    className={`${errors.name ? 'is-invalid' : ''}`}
                                    defaultValue={actionSignatureType?.emailSignature?.name}
                                    ref={register({
                                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    })}
                                />
                                <ErrorForm errors={errors} name="name" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.DEALERSHIP' })}
                                </Form.Label>
                                <Controller
                                    name="dealerships"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    }}
                                    render={(props) => (
                                        <MultiSelect
                                            display="chip"
                                            optionLabel="title"
                                            optionValue="refDealershipId"
                                            filter
                                            value={dealerships?.length > 0 ? props.value : {}}
                                            options={dealerships || []}
                                            onChange={(e) => props.onChange(e.value)}
                                        />
                                    )}
                                />
                                <ErrorForm errors={errors} name="dealerships" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.IS_ACTIVE' })}
                                </Form.Label>
                                <SwitchInput name="isActive" />
                            </Form.Group>
                            <Form.Group>
                                <Row className="mb-4">
                                    <Col>
                                        <Form.Label>
                                            {Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.VALUE' })}
                                        </Form.Label>
                                    </Col>
                                    <Col className="text-right">
                                        <div className="text-white badge badge-pill badge-primary font-weight-bold p-3">
                                            {Intl.formatMessage({ id: 'ADMINISTRATION.SIGNATURE_TEMPLATE.VIEW.TIP' })}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <Controller
                                            control={control}
                                            name="value"
                                            defaultValue={actionSignatureType?.value}
                                            rules={{
                                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                            }}
                                            render={(props) => (
                                                <Editor
                                                    ref={refEditor}
                                                    value={props.value}
                                                    onChange={props.onChange}
                                                    config={{
                                                        minHeight: 200,
                                                        allowResizeY: true,
                                                        buttons: [
                                                            {
                                                                icon: 'tags',
                                                                tooltip: 'Ajouter un tag',
                                                                popup: (editor, _current, _self, close) =>
                                                                    JoditPopup(
                                                                        editor,
                                                                        <JoditCollection>
                                                                            {filteredTags.map(
                                                                                ([tag, emailTag], idx) => (
                                                                                    <JoditButton
                                                                                        // eslint-disable-next-line react/no-array-index-key
                                                                                        key={idx}
                                                                                        onClick={() => {
                                                                                            const current =
                                                                                                editor.s.current();
                                                                                            if (
                                                                                                current?.parentNode
                                                                                                    ?.classList
                                                                                            ) {
                                                                                                if (
                                                                                                    current.parentNode.classList.contains(
                                                                                                        'email-tag',
                                                                                                    )
                                                                                                ) {
                                                                                                    editor.s.setCursorAfter(
                                                                                                        current.parentNode,
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                            const label = getEmailLabel(
                                                                                                emailTag?.label,
                                                                                            );
                                                                                            editor.s.insertHTML(
                                                                                                formatEmailTag(
                                                                                                    tag,
                                                                                                    label,
                                                                                                    label,
                                                                                                    true,
                                                                                                    false,
                                                                                                ),
                                                                                            );
                                                                                            close();
                                                                                        }}
                                                                                    >
                                                                                        {emailTag.label}
                                                                                    </JoditButton>
                                                                                ),
                                                                            )}
                                                                        </JoditCollection>,
                                                                    ),
                                                            },
                                                            'font',
                                                            'image',
                                                            'table',
                                                            ...LIGHT_BUTTONS,
                                                        ],
                                                        colorPickerDefaultTab: 'text',
                                                        removeButtons: ['ul'],
                                                        uploader: {
                                                            insertImageAsBase64URI: true,
                                                        },
                                                        imageDefaultWidth: 75,
                                                        enableDragAndDropFileToEditor: true,
                                                        dtd: {
                                                            blockLimits: 1,
                                                            checkBlockNesting: false,
                                                            removeExtraBr: true,
                                                        },
                                                        cleanHTML: {
                                                            removeEmptyElements: true,
                                                        },
                                                        nl2brInPlainText: true,
                                                        enter: 'div',
                                                        enterBlock: 'div',
                                                        removeExtraBr: true,
                                                        extraIcons: {
                                                            tags: '<i class="las la-hashtag text-secondary" />',
                                                        },
                                                        wrapNodes: {
                                                            emptyBlockAfterInit: false,
                                                        },
                                                        createAttributes: {
                                                            td: {
                                                                class: 'align-top',
                                                            },
                                                        },
                                                        styleValues: {
                                                            colorBorder: 'transparent',
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                        <ErrorForm errors={errors} name="value" />
                                    </Col>
                                    <Col lg={6}>
                                        {/* eslint-disable-next-line react/no-danger */}
                                        <div dangerouslySetInnerHTML={{ __html: signature }} />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Card>
                <Row className="m-6">
                    <Col className={isCreate ? 'text-center' : 'text-right'}>
                        <Link to="/administration/signature-templates">
                            <Button variant="outline-primary">{Intl.formatMessage({ id: 'TRANSLATOR.BACK' })}</Button>
                        </Link>
                        <Button variant="primary" className="ml-2" type="submit">
                            {Intl.formatMessage({ id: 'TRANSLATOR.SAVE' })}
                        </Button>
                    </Col>
                    {!isCreate && (
                        <Col className="text-right">
                            <Button variant="outline-danger" onClick={handleDelete}>
                                {Intl.formatMessage({ id: 'TRANSLATOR.REMOVE' })}
                            </Button>
                        </Col>
                    )}
                </Row>
            </Card>
        </Form>
    );
}

export default withRouter(ActionSignatureType);
