import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useContext, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import { dealershipHasValidAssignments } from '../../helpers/CampaignHelper';
import { OperationContext } from '../../OperationProvider';

import OperationAssignmentsForm from './OperationAssignmentsForm';

const variants = {
    show: {
        opacity: 1,
        height: 'auto',
        overflow: 'hidden',
        transition: {
            ease: 'easeOut',
        },
    },
    hide: {
        opacity: 0,
        height: 0,
        overflow: 'hidden',
        border: 'none',
        transition: {
            ease: 'easeOut',
        },
    },
};

const OperationAssignmentsToLink = () => {
    const { operationState } = useContext(OperationContext);
    const [expanded, setExpanded] = useState(null);
    const dealerships = operationState?.dealerships;

    return (
        <div className="position-relative">
            <div className="campaign-dealerships my-4">
                {dealerships &&
                    Object.keys(dealerships)?.map?.((dealershipId, idx) => {
                        const dealership = dealerships?.[dealershipId];
                        const valid = dealershipHasValidAssignments(dealershipId, operationState);
                        const countContactsToAssigned = dealership?.contacts?.filter((c) => !c?.dealer)?.length || 0;
                        const countUnassignedContacts =
                            operationState?.selectedDealers?.[dealershipId]?.length > 0
                                ? 0
                                : countContactsToAssigned -
                                  (operationState?.affectedDealers?.[dealershipId]?.length || 0);

                        return (
                            <div key={`campaign-dealership-${dealershipId}`} className="campaign-dealership mb-3">
                                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
                                <div
                                    className="campaign-dealership__header cursor-pointer"
                                    role="button"
                                    onClick={
                                        dealership?.name ? () => setExpanded(expanded !== idx ? idx : null) : () => {}
                                    }
                                >
                                    <div className="d-flex align-items-center">
                                        <i className="la-lg las la-building mr-2 text-initial" />
                                        <div className="font-weight-bold font-size-lg">
                                            {dealership?.name ?? (
                                                <FormattedHTMLMessage
                                                    id="CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.NO_DEALERS"
                                                    values={{
                                                        dealershipId,
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={classNames('d-flex align-items-center', {
                                            'text-danger': !valid && dealership?.name,
                                            'text-success': valid,
                                            'text-muted': !dealership?.name,
                                        })}
                                    >
                                        <div className="mr-2">
                                            {dealership?.name ? (
                                                <FormattedHTMLMessage
                                                    id={`CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.TO_ASSIGN${
                                                        valid ? '.DONE' : ''
                                                    }`}
                                                    values={{
                                                        number: valid
                                                            ? countContactsToAssigned
                                                            : countUnassignedContacts,
                                                    }}
                                                />
                                            ) : (
                                                <FormattedHTMLMessage
                                                    id="CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.TO_IGNORE"
                                                    values={{
                                                        number: countUnassignedContacts,
                                                    }}
                                                />
                                            )}
                                        </div>

                                        {dealership?.name ? (
                                            <i
                                                className={classNames('campaign-dealership__toggler las', {
                                                    'la-angle-down': expanded !== idx,
                                                    'la-angle-up': expanded === idx,
                                                })}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                                <motion.div
                                    variants={variants}
                                    className="campaign-dealership__content"
                                    initial="hide"
                                    animate={expanded === idx ? 'show' : 'hide'}
                                >
                                    <OperationAssignmentsForm dealershipId={dealershipId} key={expanded} />
                                </motion.div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default OperationAssignmentsToLink;
