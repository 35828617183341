import React from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';

import Permission from '@app/partials/content/Permission';
import toast from '@app/partials/content/Toast';
import subHeader from '@app/partials/HoC/subHeader';

import { LayoutContextConsumer } from '../../../../_metronic';
import BreadCrumbs from '../../../../_metronic/layout/sub-header/components/BreadCrumbs';

import { formSubmit } from './Form/Form.submit';

function PackageSubHeader({ history, subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle }) {
    const methods = useFormContext();
    const params = useParams();
    const dispatch = useDispatch();
    const Intl = useIntl();

    const newPackage = typeof params.id === 'undefined';
    const packageSelected = useSelector((state) => state.smartRepair.package);

    const onSubmit = (data) => {
        formSubmit({
            newPackage,
            data,
            dispatch,
            history,
            id: params.id,
        });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: 'MENU.APV.COMMERCIAL_ANIMATION' }),
        },
        {
            title: Intl.formatMessage(
                {
                    id: newPackage ? 'MENU.APV.PACKAGE_CREATION' : 'MENU.APV.PACKAGE_EDITION',
                },
                { name: packageSelected?.package?.name || '' },
            ),
        },
    ];

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            type="button"
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}

                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">{Intl.formatMessage({ id: 'MENU.APV' })}</h3>
                                <BreadCrumbs items={breadcrumb} />
                            </>
                        )}
                    </LayoutContextConsumer>
                </div>
                <div className="d-flex align-items-center">
                    <Link to={routeTo(ROUTES.PACKAGES.PATH.TYPE, { type: 'smartrepair' })}>
                        <Button className="mr-2" variant="outline-primary">
                            {Intl.formatMessage({ id: 'TRANSLATOR.BACK' })}
                        </Button>
                    </Link>
                    <Permission permissions={['SR_UPDATE_PACKAGE']}>
                        <Button className="mr-2" onClick={methods.handleSubmit(onSubmit, onError)} variant="primary">
                            {Intl.formatMessage({ id: 'TRANSLATOR.SAVE' })}
                        </Button>
                    </Permission>
                </div>
            </div>
        </div>
    );
}

export default subHeader(PackageSubHeader);
