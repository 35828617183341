import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import useFetch from '@app/hooks/useFetch';

import { getOneCustomerActivity } from '@app/crud/customers/customer.crud';

import Loader from '@app/partials/content/Loader';

import APVActivitiesTimeline from './EventDetailAPVActivities/APVActivitiesTimeline';

const EventDetailAPVActivities = ({ event, typeSlug }) => {
    const { fetch, data, loading } = useFetch({
        fetchAction: getOneCustomerActivity,
    });

    useEffect(() => {
        fetch(event?.informations?.identifier);
    }, [event]);

    return (
        <Card className="card--shadowed">
            <Card.Header className={`event__origin--${typeSlug} position-relative`}>
                <div className={`event__origin-bg--${typeSlug} pin`}>
                    <i className="la la-comments text-white la-lg" />
                </div>
                <span className="ml-4">
                    <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.APV_ACTIVITIES_DETAIL.TITLE" />
                </span>
            </Card.Header>
            <Card.Body className="position-relative">
                {loading ? (
                    <Loader style={{ width: '5rem', height: '5rem' }} overlay />
                ) : (
                    <APVActivitiesTimeline data={data} event={event} />
                )}
            </Card.Body>
        </Card>
    );
};

export default EventDetailAPVActivities;
