import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

const { REACT_APP_AUTOUSER_URL } = process.env;

export interface Tag {
    id: number;
    name: string;
    category: string;
    slug: string;
}

// eslint-disable-next-line import/prefer-default-export
export function getTags() {
    return ApiGateway.get<ApiResponse<Tag[]>, CustomAxiosResponse<ApiResponse<Tag[]>>>(
        `${REACT_APP_AUTOUSER_URL}/admin/tags`,
    );
}
