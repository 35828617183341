import classNames from 'classnames';
import * as React from 'react';
import { Button } from 'react-bootstrap';

import { useAppSelector } from '@app/hooks/useAppSelector';
import usePermissions from '@app/hooks/usePermissions';
import useQueryParams from '@app/hooks/useQueryParams';

import { getCurrentActionfor } from '@app/helpers/LeadHelper';

import { leadsCanBeLinked } from '@app/pages/customers/Helpers/Lead';
import { Lead } from '@app/store/customers/customers.store';

interface LeadActionsProps {
    lead: Lead;
    // eslint-disable-next-line
    onClick: (action: string, params?: any) => void;
    details?: boolean;
}

const LeadActions = ({ lead, onClick, details }: LeadActionsProps) => {
    const { hasAllPermissions, hasAnyPermissions } = usePermissions();
    const { userData } = useAppSelector((state) => state.auth);
    const { data: customer } = useAppSelector((state) => state.customer.customer);
    const leadStore = useAppSelector((state) => state.lead);
    const force = lead?.leadType?.slug === 'local_apv' ? false : lead?.ownerId && lead?.ownerId !== userData?.id;
    const leadsToLink = leadsCanBeLinked(customer?.leads);
    const queryParams = useQueryParams();
    let permissions = [];
    let actions: React.ReactNode[] = [];
    let subActions: React.ReactNode[] = [];
    let title = '';
    let action = '';
    let key = 0;
    let disable = false;

    if (!lead?.leadStatus?.slug) {
        return null;
    }

    const inAction = getCurrentActionfor(leadStore, lead?.id) !== undefined;

    // Get first row
    if (lead?.leadType?.slug === 'local_apv') {
        switch (lead.leadStatus.slug) {
            case 'in_progress_action_to_do':
            case 'taken':
            case 'taken_30_min':
            case 'taken_60_min':
            case 'taken_90_min':
            case 'taken_120_min':
                permissions = ['APV_LOCAL_TAKE_LEAD_CLIENT'];
                title = 'Répondre à la demande';
                action = 'reply_local_apv';
                break;
            case 'in_progress_apv_to_do':
                permissions = ['APV_LOCAL_CLOSE_LEAD_CLIENT'];
                title = 'Clôturer la demande';
                action = 'close_local_apv';
                break;
            case 'pending':
            case 'pending_30_min':
            case 'pending_60_min':
            case 'pending_90_min':
            case 'pending_120_min':
                permissions = ['APV_LOCAL_TAKE_LEAD_CLIENT'];
                title = 'Je prends';
                action = 'take';
                disable =
                    queryParams?.leadId &&
                    parseInt(queryParams?.leadId as string, 10) === lead?.id &&
                    queryParams?.action === 'take';
                break;
            case 'closed_done':
                permissions = ['APV_LOCAL_LEAD_CREATE'];
                title = 'Rouvrir la demande';
                action = 'reopen_local_apv';
                break;
            default:
                return null;
        }
    } else {
        switch (lead?.leadStatus?.slug) {
            case 'in_progress_action_to_do':
            case 'taken':
            case 'taken_30_min':
            case 'taken_60_min':
            case 'taken_90_min':
            case 'taken_120_min':
            case 'taken_15_days':
            case 'taken_30_days':
            case 'created':
            case 'new_home_charge':
            case 'quotation':
            case 'quotation_3_day':
            case 'quotation_7_day':
            case 'estimate':
            case 'in_progress_contract_generated':
                permissions = ['LEAD_CONSIGN_ACTION_CONTACT', ...(force ? ['LEAD_FORCED_ACTION_CONTACT'] : [])];
                title = !force ? 'Consigner action / autre' : 'Forcer le traitement';
                action = 'next_action';
                break;
            case 'in_progress_appointment_to_come':
            case 'in_progress_appointment_confirmed':
            case 'in_progress_relaunch_to_come':
            case 'in_progress_appointment_to_qualify':
            case 'in_progress_relaunch_to_qualify':
            case 'in_progress_appointment_to_confirm':
                permissions = ['LEAD_CONSIGN_ACTION_CONTACT', ...(force ? ['LEAD_FORCED_ACTION_CONTACT'] : [])];
                title = !force ? 'Consigner action / autre' : 'Forcer le traitement';
                action = 'consign';
                break;
            case 'pending':
            case 'pending_30_min':
            case 'pending_60_min':
            case 'pending_90_min':
            case 'pending_120_min':
            case 'pending_15_days':
            case 'pending_30_days':
                permissions = ['LEAD_TAKE_LEAD_CLIENT', ...(force ? ['LEAD_FORCED_ACTION_CONTACT'] : [])];
                title = !force ? 'Je prends' : 'Forcer le traitement';
                action = 'take';
                disable =
                    queryParams?.leadId &&
                    parseInt(queryParams?.leadId as string, 10) === lead?.id &&
                    queryParams?.action === 'take';
                break;
            default:
                return null;
        }
    }

    actions = [
        ...actions,
        <Button
            key={(key += 1)}
            variant="danger"
            disabled={leadsToLink || !hasAllPermissions(permissions) || !inAction || disable}
            onClick={() => {
                onClick(action, {
                    force,
                });
            }}
        >
            {title}
            {!inAction && <i className="ml-1 p-0 las la-spinner la-spin" />}
        </Button>,
    ];

    // Get second row
    if (details) {
        if (hasAnyPermissions(['LEAD_AFFECT_LEAD', 'APV_LOCAL_AFFECT_LEAD'])) {
            subActions = [
                ...subActions,
                <Button
                    key={(key += 1)}
                    className="flex-grow-1 mt-5 mr-2"
                    variant="outline-primary"
                    disabled={
                        leadsToLink ||
                        !hasAnyPermissions(['LEAD_VIEW_LEAD_DETAILS', 'APV_LOCAL_LEAD_VIEW_LEAD_DETAILS'])
                    }
                    onClick={() =>
                        onClick('assign', {
                            update: !!lead?.ownerId,
                        })
                    }
                >
                    {lead?.ownerId ? 'Changer' : 'Affecter'}
                </Button>,
            ];
        }

        subActions = [
            ...subActions,
            <Button
                key={key + 1}
                className={classNames('flex-grow-1 mt-5', {
                    'ml-2': hasAnyPermissions(['LEAD_AFFECT_LEAD', 'APV_LOCAL_AFFECT_LEAD']),
                })}
                variant="outline-primary"
                disabled={
                    leadsToLink || !hasAnyPermissions(['LEAD_VIEW_LEAD_DETAILS', 'APV_LOCAL_LEAD_VIEW_LEAD_DETAILS'])
                }
                onClick={() => onClick('show_details')}
            >
                {subActions.length > 0 ? 'Voir détail' : 'Voir le détail du lead'}
            </Button>,
        ];
    }

    return (
        <>
            {actions}
            <div className="d-flex justify-content-between">{subActions}</div>
        </>
    );
};

LeadActions.defaultProps = {
    details: true,
};

export default LeadActions;
