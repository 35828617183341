import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import StockModalTimeLine from '@app/pages/stock/modals/StockModalTimeLine';

const StockVehicleCRVO = ({ vehicle }) => {
    const [showModal, setShowModal] = useState(false);

    const timelineHandler = () => {
        const crvo = vehicle?.crvo?.events;

        return crvo.splice(-4).map((item, key) => (
            <div key={key} className="timeline-item">
                <div className="timeline-connector" style={{ '--color': item.color || 'rgb(82, 86, 114)' }} />
                <div className="timeline-label font-weight-bold font-size-lg text-center" style={{ color: item.color }}>
                    {item.description || ' - '}
                </div>
                <div className="timeline-badge">
                    <i className="fa fa-genderless icon-xl" style={{ color: item.color || 'rgb(82, 86, 114)' }} />
                </div>

                <div className="font-weight-normal font-size-lg timeline-content">
                    {dayjs(item.date).format('DD/MM/YYYY')}
                </div>
            </div>
        ));
    };

    return (
        <Card className="crvo-handler">
            <Card.Header>
                <div className="d-flex justify-content-between">
                    <div>
                        <i className="card__icon la-lg las la-wrench text-dark" />
                        <span>{vehicle?.crvo?.site_name}</span>
                        <span className="ml-4 text-primary">{vehicle?.crvo && vehicle?.crvo?.description}</span>
                    </div>
                    <Button variant="outline-primary" onClick={() => setShowModal(true)}>
                        <i className="la la-eye" />
                        {Intl.formatMessage({ id: 'TRANSLATOR.SHOW_DETAILS' })}
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <div className="timeline timeline-hidden-bar timeline-horizontal timeline-horizontal-partial">
                    <div className="timeline-item">
                        <div className="timeline-connector" />
                        <div className="timeline-label font-weight-bold font-size-lg" />

                        <div className="font-weight-normal font-size-lg timeline-content" />
                    </div>
                    {vehicle?.crvo?.events && timelineHandler()}
                </div>
            </Card.Body>
            <StockModalTimeLine
                events={vehicle?.crvo?.events}
                show={showModal}
                setShowModal={setShowModal}
                title={
                    <FormattedHTMLMessage
                        id="STOCK_VEHICLE.MODAL.TIMELINE.CRVO.TITLE"
                        values={{
                            licenseNumber: vehicle?.license_number,
                            crvoName: vehicle?.crvo?.site_name,
                        }}
                    />
                }
            />
        </Card>
    );
};

export default StockVehicleCRVO;
