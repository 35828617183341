import dayjs from 'dayjs';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { Dealership, Order } from '@app/crud/apv/order.type';

interface CardAppointmentProps {
    order: Order;
    toggleUpdateModal: (value: boolean) => void;
}

const formatDate = (date: string | undefined) => (date ? dayjs(date).format(' [le] DD MMMM[, à ] HH [h] mm') : '-');

const formatAddress = (source: Dealership['dealershipSource'] | undefined) =>
    source ? `${source.usual_name}, ${source.address}, ${source.zipcode} ${source.city}` : '-';

const CardAppointment = ({ order, toggleUpdateModal }: CardAppointmentProps) => {
    const intl = useIntl();

    const renderButton = () => {
        if (order?.appointment) {
            return (
                <Button variant="outline-light" onClick={() => toggleUpdateModal(true)}>
                    {intl.formatMessage({ id: 'APV.ORDER.VIEW.UPDATE' })}
                </Button>
            );
        }
        return null;
    };

    const dataFields = [
        { labelId: 'APV.ORDER.VIEW.HOUR', value: formatDate(order?.appointment?.dateStart) },
        { labelId: 'APV.ORDER.VIEW.DEALERSHIP', value: formatAddress(order?.dealership?.dealershipSource) },
        { labelId: 'APV.ORDER.VIEW.COMMENT', value: order?.comment || '-' },
        { labelId: 'APV.ORDER.VIEW.APPOINTMENT.ID', value: order?.mecaplanning?.rdvId || '-' },
        { labelId: 'APV.ORDER.VIEW.STATUS', value: order?.mecaplanning?.status || '-' },
        { labelId: 'APV.ORDER.VIEW.RECEIVER', value: order?.mecaplanning?.reception || '-' },
    ];

    return (
        <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
                <div>
                    <i className="card__icon fas fa-calendar-alt" />
                    {intl.formatMessage({ id: 'APV.ORDER.VIEW.APPOINTMENT' })}
                </div>
                <div>{renderButton()}</div>
            </Card.Header>
            <Card.Body>
                {dataFields.map(({ labelId, value }) => (
                    <Row className="mb-4" key={labelId}>
                        <Col lg={6}>{intl.formatMessage({ id: labelId })} :</Col>
                        <Col className="font-weight-semibold" lg={6}>
                            {value}
                        </Col>
                    </Row>
                ))}
            </Card.Body>
        </Card>
    );
};

export default CardAppointment;
