import { sumBy } from 'lodash';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { actions } from '@app/store/smart-repair/quotations/smart-repair.store';

import { Intl } from '../../../../../_metronic/i18n/I18nProvider';

/**
 * The component renders a button that is used to add or modify a quotation.
 *
 * The compatibleOrder function checks if the button is already modified or not.
 * If it's already modified, it resets the modification and dispatches an action to delete the selected package and item. Otherwise,
 * it marks the button as modified and dispatches an action to accept the selected quotations. It also displays the summary of the quotation in table.
 * The button is enabled if there is at least one package selected and the quotation is not already modified.
 * When the button is clicked, it displays either "Add" or "Modified" depending on the state of the quotation.
 *
 * orderRepairItem: An object that represents the repair item in the quotation.
 * displayResume: A function that displays the summary of the quotation.
 * expertComment: A string that represents an expert comment associated with the quotation.
 * selectedQuotations: An object that contains selected quotations associated with the repair item.
 * sumOfPackages: A function that calculates the sum of prices of packages selected for the repair item.
 */

function QuotationCtaCompatibleOrder({
    orderRepairItem,
    displayResume,
    expertComment,
    selectedQuotations,
    sumOfPackages,
}) {
    const dispatch = useDispatch();

    const saveExpertComment = (orderId) => (orderId === expertComment?.name ? expertComment.value : '');
    const compatibleOrder = (orderRepairItemSelected) => {
        if (
            orderRepairItemSelected.modifiedBtnAdding ===
            Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.MODIFIED' })
        ) {
            orderRepairItemSelected.compatible = null;
            orderRepairItemSelected.modifiedBtnAdding = '';
            dispatch(actions.setDeleteSelectedPackage(orderRepairItemSelected.id));
            dispatch(actions.setDeleteItem(orderRepairItemSelected.id));
        } else {
            orderRepairItemSelected.compatible = 'compatible';
            orderRepairItemSelected.modifiedBtnAdding = Intl.formatMessage({
                id: 'SMART.REPAIR.QUOTATION.BTN.MODIFIED',
            });
            dispatch(
                actions.setAcceptedQuotations(
                    selectedQuotations[orderRepairItemSelected.id].map((selectedQuotation) => ({
                        id: selectedQuotation.repairId,
                        orderId: selectedQuotation.orderId,
                        priceTtc: selectedQuotation.priceTtc,
                        totalPackagesPrice: sumBy(selectedQuotations[orderRepairItemSelected.id], 'priceTtc'),
                        slug: selectedQuotation.slug,
                        zoneName: selectedQuotation.zoneName,
                        package: selectedQuotation.name,
                        compatible: true,
                        reference: orderRepairItemSelected.reference,
                        expertComment: saveExpertComment(selectedQuotation.repairId),
                    })),
                ),
            );
        }
        displayResume(true);
    };

    return (
        <Button
            variant="primary"
            className="mr-5"
            onClick={() => compatibleOrder(orderRepairItem)}
            disabled={!sumOfPackages && orderRepairItem?.compatible !== 'compatible'}
        >
            {orderRepairItem?.compatible === 'compatible' &&
            orderRepairItem?.modifiedBtnAdding === Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.MODIFIED' })
                ? Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.MODIFIED' })
                : Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.ADD' })}
        </Button>
    );
}

export default QuotationCtaCompatibleOrder;
