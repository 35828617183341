import dayjs from 'dayjs';
import { CalendarChangeEvent } from 'primereact/calendar';
import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import { getDateByFormat } from '@app/helpers/DateHelper';

import { useAppSelector } from '@app/hooks';

import { DateTimePicker } from '@app/partials/content/DateTimePicker';
import ErrorForm from '@app/partials/layout/ErrorForm';

import { LeadHomeChargeFormFields } from '../LeadHomeCharge';
import { CreateLeadHomeChargePayload } from '../LeadHomeCharge.type';

type LeadHomeChargeCustomerProps = {
    data: Partial<CreateLeadHomeChargePayload> & LeadHomeChargeFormFields;
};

const LeadHomeChargeCustomer = ({ data }: LeadHomeChargeCustomerProps) => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { control, errors, register, setValue } = methods;
    const [isPro, setIsPro] = useState(data.isProValue === 'true' || false);
    const { data: customer } = useAppSelector((state) => state.customer.customer);

    return (
        <div className="d-flex flex-wrap justify-content-between col-12 p-0">
            <Form.Group as={Col} md={6}>
                <Form.Label className="d-block mr-10">
                    {Intl.formatMessage({
                        id: 'CUSTOMERS.MODAL.CREATE.CIVILITY',
                    })}
                </Form.Label>
                <Form.Check
                    name="contactGender"
                    inline
                    type="radio"
                    defaultChecked={data?.contactGender === 'MME' || customer?.civility === 'Mme'}
                    ref={register({
                        required: Intl.formatMessage({
                            id: 'FORM.ERROR.REQUIRED',
                        }),
                    })}
                    value="MME"
                    label={Intl.formatMessage({
                        id: 'TRANSLATOR.MRS',
                    })}
                />
                <Form.Check
                    name="contactGender"
                    inline
                    type="radio"
                    value="MR"
                    defaultChecked={data?.contactGender === 'MR' || customer?.civility === 'Mr'}
                    ref={register({
                        required: Intl.formatMessage({
                            id: 'FORM.ERROR.REQUIRED',
                        }),
                    })}
                    label={Intl.formatMessage({
                        id: 'TRANSLATOR.MR',
                    })}
                />
                <ErrorForm errors={errors} name="contactGender" />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="type">
                <Form.Label>
                    <FormattedMessage id="CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.CUSTOMER_TYPE" />
                </Form.Label>
                <Form.Control
                    name="isProValue"
                    as="select"
                    defaultValue={data?.isProValue || -1}
                    onChange={(e) => {
                        setIsPro(e.target.value === 'true');
                        setValue('isPro', isPro);
                    }}
                    ref={register({
                        required: Intl.formatMessage({
                            id: 'FORM.ERROR.REQUIRED',
                        }),
                        validate: (value) =>
                            value !== '-1' ? true : Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    })}
                >
                    <option disabled value={-1}>
                        {Intl.formatMessage({ id: 'CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.CUSTOMER_TYPE.PLACEHOLDER' })}
                    </option>
                    <option value="false">
                        {Intl.formatMessage({ id: 'CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.CUSTOMER_TYPE.INDIVIDUAL' })}
                    </option>
                    <option value="true">
                        {Intl.formatMessage({ id: 'CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.CUSTOMER_TYPE.PRO' })}
                    </option>
                </Form.Control>
                <ErrorForm errors={errors} name="isProValue" />
            </Form.Group>
            {isPro && (
                <>
                    <Form.Group as={Col} md={6} controlId="create_company">
                        <Form.Label>
                            {Intl.formatMessage({
                                id: 'CUSTOMERS.MODAL.CREATE.COMPANY_NAME',
                            })}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="contactCompanyName"
                            defaultValue={data?.contactCompanyName || customer?.companyName}
                            className={`${errors.contactCompanyName ? 'is-invalid' : ''}`}
                            ref={register({
                                required: Intl.formatMessage({
                                    id: 'FORM.ERROR.REQUIRED',
                                }),
                            })}
                        />
                        <ErrorForm errors={errors} name="contactCompanyName" />
                    </Form.Group>
                    <Form.Group as={Col} md={6} controlId="create_siren">
                        <Form.Label>
                            {Intl.formatMessage({
                                id: 'CUSTOMERS.MODAL.CREATE.SIREN',
                            })}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="contactSiren"
                            defaultValue={data?.contactSiren || customer?.siren}
                            className={`${errors.contactSiren ? 'is-invalid' : ''}`}
                            onChange={(e) =>
                                e.target.value.length > 14 && setValue('contactSiren', e.target.value.substring(0, 14))
                            }
                            ref={register({
                                required: Intl.formatMessage({
                                    id: 'FORM.ERROR.REQUIRED',
                                }),
                                validate: (value) =>
                                    value.length <= 14
                                        ? true
                                        : Intl.formatMessage({ id: 'FORM.ERROR.MAX_LENGTH' }, { count: 14 }),
                            })}
                        />
                        <span className="font-size-xs text-muted">
                            <FormattedMessage id="CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.SIREN_LENGTH" />
                        </span>
                        <ErrorForm errors={errors} name="contactSiren" />
                    </Form.Group>
                </>
            )}
            <Form.Group as={Col} md={6} controlId="contactLastname">
                <Form.Label>
                    <FormattedMessage id="CUSTOMERS.MODAL.CREATE.LASTNAME" />
                </Form.Label>
                <Form.Control
                    type="text"
                    name="contactLastname"
                    readOnly
                    defaultValue={data?.contactLastname || customer?.lastname}
                    className={`${errors.contactLastname ? 'is-invalid' : ''} bg-disabled`}
                    ref={register({
                        required: Intl.formatMessage({
                            id: 'FORM.ERROR.REQUIRED',
                        }),
                    })}
                />
                <ErrorForm errors={errors} name="contactLastname" />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="contactFirstName">
                <Form.Label>
                    <FormattedMessage id="CUSTOMERS.MODAL.CREATE.FIRSTNAME" />
                </Form.Label>
                <Form.Control
                    type="text"
                    name="contactFirstName"
                    readOnly
                    defaultValue={data?.contactFirstName || customer?.firstname}
                    className={`${errors.contactFirstName ? 'is-invalid' : ''} bg-disabled`}
                    ref={register({
                        required: Intl.formatMessage({
                            id: 'FORM.ERROR.REQUIRED',
                        }),
                    })}
                />
                <ErrorForm errors={errors} name="contactFirstName" />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="contactEmail">
                <Form.Label>
                    {Intl.formatMessage({
                        id: 'CUSTOMERS.MODAL.CREATE.EMAIL_ALT',
                    })}
                </Form.Label>
                <Form.Control
                    type="email"
                    name="contactEmail"
                    defaultValue={data?.contactEmail || customer?.email}
                    className={`${errors.contactEmail ? 'is-invalid' : ''}`}
                    ref={register({
                        required: Intl.formatMessage({
                            id: 'FORM.ERROR.REQUIRED',
                        }),
                    })}
                />
                <ErrorForm errors={errors} name="contactEmail" />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="contactMobile">
                <Form.Label>
                    {Intl.formatMessage({
                        id: 'CUSTOMERS.MODAL.CREATE.MOBILE_ALT',
                    })}
                </Form.Label>
                <Form.Control
                    type="phone"
                    name="contactMobile"
                    defaultValue={data?.contactMobile || customer?.mobile}
                    className={`${errors.contactMobile ? 'is-invalid' : ''}`}
                    ref={register({
                        required: Intl.formatMessage({
                            id: 'FORM.ERROR.REQUIRED',
                        }),
                    })}
                />
                <ErrorForm errors={errors} name="contactMobile" />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="contactAddress">
                <Form.Label>
                    {Intl.formatMessage({
                        id: 'CUSTOMERS.MODAL.CREATE.ADDRESS',
                    })}
                </Form.Label>
                <Form.Control
                    type="text"
                    name="contactAddress"
                    defaultValue={data?.contactAddress || customer?.address}
                    className={`${errors.contactAddress ? 'is-invalid' : ''}`}
                    ref={register({
                        required: Intl.formatMessage({
                            id: 'FORM.ERROR.REQUIRED',
                        }),
                    })}
                />
                <ErrorForm errors={errors} name="contactAddress" />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="contactZipcode">
                <Form.Label>
                    {Intl.formatMessage({
                        id: 'CUSTOMERS.MODAL.CREATE.ZIPCODE',
                    })}
                </Form.Label>
                <Form.Control
                    type="number"
                    name="contactZipcode"
                    defaultValue={data?.contactZipcode || customer?.zipcode}
                    className={`${errors.contactZipcode ? 'is-invalid' : ''}`}
                    ref={register({
                        required: Intl.formatMessage({
                            id: 'FORM.ERROR.REQUIRED',
                        }),
                    })}
                />
                <ErrorForm errors={errors} name="contactZipcode" />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="contactCity">
                <Form.Label>
                    {Intl.formatMessage({
                        id: 'CUSTOMERS.MODAL.CREATE.CITY',
                    })}
                </Form.Label>
                <Form.Control
                    type="text"
                    name="contactCity"
                    defaultValue={data?.contactCity || customer?.city}
                    className={`${errors.contactCity ? 'is-invalid' : ''}`}
                    ref={register({
                        required: Intl.formatMessage({
                            id: 'FORM.ERROR.REQUIRED',
                        }),
                    })}
                />
                <ErrorForm errors={errors} name="contactCity" />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="deliveryDate">
                <Form.Label>
                    <FormattedMessage id="CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.VEHICLE_DELIVERY" />
                </Form.Label>
                <Controller
                    control={control}
                    name="deliveryDate"
                    defaultValue={data?.deliveryDate}
                    rules={{
                        required: Intl.formatMessage({
                            id: 'AGENDA.FORM.ERROR.REQUIRED',
                        }),
                    }}
                    render={(renderProps) => (
                        <DateTimePicker
                            locale={Locale}
                            showIcon
                            icon="far fa-calendar-alt"
                            dateFormat="DD/MM/YYYY"
                            className={`${errors.deliveryDate ? 'is-invalid' : ''}`}
                            onChange={(e: CalendarChangeEvent) => {
                                const value = dayjs(
                                    // @ts-ignore
                                    e.value,
                                ).isValid()
                                    ? // @ts-ignore
                                      dayjs(e.value).format('YYYY-MM-DD')
                                    : '';
                                renderProps.onChange(value);
                            }}
                            value={getDateByFormat(renderProps.value, 'YYYY-MM-DD', '')}
                        />
                    )}
                />
                <ErrorForm errors={errors} name="deliveryDate" />
            </Form.Group>
        </div>
    );
};

export default LeadHomeChargeCustomer;
