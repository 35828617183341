import { checkPermission, getPermissions } from '@app/hooks/usePermissions';

import counterStore, { actions } from '@app/store/counters/counters.store';
import { User } from '@app/store/login/login.store';

import CounterRegistry from './CounterRegistry';

type MenuConfig = {
    aside: {
        items: MenuConfigItem[];
    };
};

type MenuConfigItem = {
    svg: string;
    separator: boolean;
    translate: string;
    id: string;
    counter: CounterConfig;
    permissions: string[];
    hasAnyPermissions: boolean;
    bullet: string;
    page: string;
    mobile?: {
        translate: string;
        page: string;
    };
    submenu: MenuConfigItem[];
};

export type CounterConfig = {
    id: string;
    delay: number;
    fetcher: (...args: any) => any;
    permission?: string;
};

const counterRegistry = new CounterRegistry();

type FetcherFunction = () => Promise<any>;

const executeFetchWithInterval = async (id: string, fetcher: FetcherFunction, delay = 10000, enabled = true) => {
    if (!enabled) return;

    const call = async () => {
        try {
            const result = await fetcher();
            const new_counters = { [id]: result };
            counterStore.dispatch(actions.setCounters(new_counters));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };

    const interval = setInterval(async () => {
        await call();
    }, delay);

    await call();

    return interval;
};

export async function addInterval(counter: CounterConfig, userData: User) {
    const permissions = getPermissions(userData);
    const hasPermission = counter.permission ? checkPermission(permissions, counter.permission) : true;
    const interval = await executeFetchWithInterval(counter.id, counter.fetcher, counter.delay, hasPermission);
    counterRegistry.addInterval(counter.id, interval);
}

export function deleteInterval(counter: CounterConfig) {
    counterRegistry.deleteInterval(counter.id);
}

export function clearIntervals() {
    return counterRegistry.clearIntervals();
}

// Get the root item of the menu item
export function getRootItem(menuConfig: MenuConfig, item: MenuConfigItem) {
    const menusConfig = menuConfig.aside.items;

    const hasItem = (menuItem: MenuConfigItem, menu: MenuConfigItem): boolean => {
        if (menu.translate === menuItem.translate) return true;
        if (menu.submenu) {
            return menu.submenu.some((m: MenuConfigItem) => hasItem(menuItem, m));
        }
    };

    return menusConfig.find((menu) => hasItem(item, menu));
}

export function isMenuItemActive(pathname: string, item: MenuConfigItem) {
    if (!item.page) {
        return false;
    }

    return pathname === item.page;
}

export function isMenuRootItemIsActive(pathname: string, item: MenuConfigItem): boolean {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return item.submenu.some((subItem: MenuConfigItem) => isMenuTreeActive(pathname, subItem));
}

export function isMenuTreeActive(pathname: string, item: MenuConfigItem) {
    if (item.submenu) {
        return isMenuRootItemIsActive(pathname, item);
    }

    if (!item.page) {
        return false;
    }

    return pathname.startsWith(item.page);
}

// Get the root item of the menu item where page is pathname
export function getRootActiveItem(menuConfig: MenuConfig, pathname: string) {
    const menusConfig = menuConfig.aside.items;

    const findRootActiveItem = (menu: MenuConfigItem): MenuConfigItem => {
        if (isMenuTreeActive(pathname, menu)) return menu;

        if (menu.submenu) {
            return menu.submenu.find((m: MenuConfigItem) => findRootActiveItem(m));
        }
    };

    return menusConfig.find((menu) => findRootActiveItem(menu));
}

let memoizedFetchers: string[] = [];
export function isMemoized(menu: MenuConfigItem) {
    return memoizedFetchers.includes(menu.counter.id);
}
export function addMemoizeFetcher(menu: MenuConfigItem) {
    memoizedFetchers.push(menu.counter.id);
    return memoizedFetchers;
}
export function clearMemoizeFetchers(menu: MenuConfigItem) {
    memoizedFetchers = memoizedFetchers.filter((fetcherName) => fetcherName !== menu.counter.id);
    return memoizedFetchers;
}
