import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@app/hooks';
import { Vehicle } from '@app/store/stock/stock.type';

import Label from '@app/partials/content/Label';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';
import {
    getEligibleBalloon,
    getEligibleClassic,
    getEligibleCredit,
    getEligibleLOA,
    getHasCredit,
    getHasLOA,
} from '@app/partials/content/Vehicle/VehicleHelpers';

type StockVehiclesFundingColumnProps = {
    vehicle: Vehicle;
    rowsClassNames?: string;
};

const StockVehiclesFundingColumn = ({ vehicle, rowsClassNames }: StockVehiclesFundingColumnProps) => {
    const isContextEFB = useAppSelector((state) => state.auth.userData.context === 'efb');

    const Intl = useIntl();
    const [fundingMethods, setFundingMethods] = useState([]);

    useEffect(() => {
        if (isContextEFB) {
            setFundingMethods([
                {
                    name: 'classic',
                    vehicleEligible: getEligibleClassic(vehicle),
                    vehiclePublish: false,
                },
                {
                    name: 'balloon',
                    vehicleEligible: getEligibleBalloon(vehicle),
                    vehiclePublish: false,
                },
            ]);
        } else {
            setFundingMethods([
                {
                    name: 'credit',
                    vehicleEligible: getEligibleCredit(vehicle),
                    vehiclePublish: getHasCredit(vehicle),
                },
                {
                    name: 'loa',
                    vehicleEligible: getEligibleLOA(vehicle),
                    vehiclePublish: getHasLOA(vehicle),
                },
            ]);
        }
    }, [isContextEFB, vehicle]);

    return (
        <>
            {fundingMethods.map((method) => {
                const methodName = method.name.toUpperCase();

                return (
                    <div className={rowsClassNames || 'mb-2'}>
                        <div key={`${vehicle.internal_id}__${methodName}`}>
                            <OverlayTooltip
                                label={
                                    method.vehiclePublish
                                        ? `STOCK_VEHICLE.CARD.VEHICLE_INFO.RIBBON.PUBLISH_${methodName}`
                                        : `STOCK_VEHICLES.TABLE.${methodName}_TOOLTIP`
                                }
                                id={method.name}
                            >
                                {method.vehiclePublish ? (
                                    <div
                                        className="text-success bg-success-light mb-1 text-center"
                                        style={{
                                            padding: method.name === 'loa' ? '4px 5px 5px' : '5px',
                                            borderRadius: '13px',
                                        }}
                                    >
                                        <span className="font-weight-bold">
                                            {Intl.formatMessage({
                                                id: `STOCK_VEHICLE.CARD.VEHICLE_INFO.RIBBON.PUBLISH_${methodName}`,
                                            })}
                                        </span>
                                    </div>
                                ) : (
                                    <Label
                                        variant="light"
                                        className={`font-weight-bold ${
                                            method.vehicleEligible ? 'text-success' : 'text-danger'
                                        }`}
                                    >
                                        {method.vehicleEligible ? (
                                            <i className="mr-2 las la-check la-2x text-success" />
                                        ) : (
                                            <i className="mr-2 las la-times la-2x text-danger-lite" />
                                        )}
                                        {Intl.formatMessage({ id: `STOCK_VEHICLES.TABLE.${methodName}` })}
                                    </Label>
                                )}
                            </OverlayTooltip>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

StockVehiclesFundingColumn.defaultProps = {
    rowsClassNames: '',
};

export default StockVehiclesFundingColumn;
