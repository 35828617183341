import React from 'react';
import { useIntl } from 'react-intl';

type FormPartTitleProps = {
    title: string;
};

const FormPartTitle = ({ title }: FormPartTitleProps) => {
    const Intl = useIntl();

    return <p className="form-part-title">{Intl.formatMessage({ id: title })}</p>;
};

export default FormPartTitle;
