import React from 'react'
import {Card, Col, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

const InfoCard = ({title, titleIcon, content, moreAction}) => {


    return(
        <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
                <div>
                    {titleIcon}
                    {title}
                </div>
                {moreAction}
            </Card.Header>
            <Card.Body>
                {content.map((line, key) =>  (
                        <Row key={key} className="mb-4">
                            <Col lg={6}> <FormattedMessage id={line.labelId}/> :</Col>
                            <Col className="font-weight-semibold" lg={6}>
                                {line.value &&
                                    <span>{line.value}</span>
                                }
                            </Col>
                        </Row>
                    )
                )}
            </Card.Body>
        </Card>
    )
}

export default InfoCard;