import {useEffect, useRef} from "react";

/**
 * Hook to keep previous value of a component
 *
 * @param value
 * @returns {*}
 */
export function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}
