import { useIntl } from 'react-intl';

import { CustomerEvent } from '@app/crud/customers/customer.type';

import { getCustomerEventSource } from '@app/pages/customers/Helpers/Events';

interface sourceFunctions {
    [key: string]: (event?: CustomerEvent) => string;
}

interface SourceClassMap {
    [key: string]: string;
}

const useSourceTemplate = () => {
    const Intl = useIntl();
    const doesPropertyExist = (event: CustomerEvent, propertyName: string) =>
        Object.prototype.hasOwnProperty.call(event, propertyName);

    const sourceTemplate: sourceFunctions = {
        AUTODELIVERY_ORDERS_WEB: () => 'autosphere',
        DATASTORE_FUNDING: (event) => event?.fundingPartnerName || '-',
        ICARE_MAINTENANCE_CONTRACT: () => Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ICARE' }),
        CIRANO_WARRANTY_CONTRACT: () => Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.CIRANO.NAME' }),
        OPTEVEN_WARRANTY_CONTRACT_OPTEVEN: () => Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.OPTEVEN.NAME' }),
        afr_afr_financing: () => Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.MARKET.CAMPAIGN' }),
        PLANET_VO2_COMMERCIAL_PROPOSAL: () => Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO' }),
    };

    // Function to retrieve the source template
    const getSourceTemplate = (event: CustomerEvent) => {
        const source = getCustomerEventSource(event) as { name: string; slug: string; originName: string };
        const eventType = `${
            doesPropertyExist(event, 'srcApplicationName')
                ? `${event?.srcApplicationName}_${event?.processType}`
                : `${event?.leadSourceSlug}_${event?.leadTypeSlug}`
        }`;
        const templateFunction = sourceTemplate[eventType] || (() => source?.name || '-');

        return templateFunction(event);
    };

    const sourceClassMap: SourceClassMap = {
        afr_afr_financing: 'marketing',
    };
    // Function to retrieve the class name based on the event
    const getSourceClassName = (event: CustomerEvent) => {
        const source = getCustomerEventSource(event);
        const eventType = event?.srcApplicationName
            ? `${event?.srcApplicationName}_${event?.processType}`
            : `${event?.leadSourceSlug}_${event?.leadTypeSlug}`;
        return sourceClassMap[eventType] || source?.slug;
    };
    return { getSourceTemplate, getSourceClassName };
};

export default useSourceTemplate;
