import classNames from 'classnames';
import dayjs from 'dayjs';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { getOptins, getProducts } from '@app/crud/securyweb/securyweb.crud';
import { ContractType, StatusContractSlug } from '@app/crud/securyweb/securyweb.types';
import type { Product } from '@app/crud/securyweb/securyweb.types';

import { useAppSelector, useFetch } from '@app/hooks';
import {
    OLD_RECHARGE_SOLUTION,
    SUBSCRIPTION_DURATION,
    TYPE_SOLUTION_RECHARGE,
} from '@app/pages/customers/Modals/SecuryCarContract/FormOptions';
import FormPartTitle from '@app/pages/customers/Modals/SecuryCarContract/FormPartTitle';
import {
    isBorneProduct,
    isFieldDisabled,
    isFieldRequired,
} from '@app/pages/customers/Modals/SecuryCarContract/Helpers/SecurywebHelper';
import DealershipVendors from '@app/pages/customers/Partials/DealershipVendors';
import { User } from '@app/store/login/login.store';

import EditableDatePicker from '@app/partials/content/DateTimePicker/EditableDatePicker';
import SwitchInput from '@app/partials/content/SwitchInput';
import ErrorForm from '@app/partials/layout/ErrorForm';

const SecuryCarContractStepInfos = () => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { control, register, setValue, errors, watch } = methods;
    const { params } = useAppSelector((state) => state.modal);
    const [durationOptions, setDurationOptions] = useState([]);
    const [dealers, setDealers] = useState([]);
    const { userData } = useAppSelector((state) => state.auth);

    const formDealership = watch('dealership');
    const formProduct = watch('product');
    const idContract = watch('id');
    const [isBorne, setIsBorne] = useState(isBorneProduct(formProduct));
    const isLongerThan2Years = watch('ageSolutionCharging');
    const lgBorneCol = isLongerThan2Years ? 4 : 6;
    const subscriptionDateLabel = isBorne ? 'ORDER_DATE' : 'SUBSCRIPTION_DATE';
    const subscriptionDateName = isBorne ? 'orderDate' : 'subscriptionDate';
    const isIbanAndBorne = isBorne && watch('iban');
    const contractType = isBorne ? ContractType.ELECTRIQUE : ContractType.CLASSIQUE;

    const {
        fetch: fetchProducts,
        data: products,
        setData: setProducts,
        loading: loadingProducts,
    } = useFetch({
        fetchAction: getProducts,
        resultInterceptor: (response) => response.result,
    });

    const {
        fetch: fetchOptins,
        data: optins,
        loading: loadingOptions,
    } = useFetch({
        fetchAction: (id: string) => getOptins(id, contractType),
        resultInterceptor: (response) => response.result,
    });

    const updateValues = (key: string) => {
        if (params?.formValues?.[key] || params?.formValues?.[key] === 0) {
            setValue(key, params.formValues[key]);
        }
    };

    useEffect(() => {
        register('dealership', {
            required:
                isFieldRequired('dealership', watch('statut')) && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
        });
        register('dealer', {
            required: isFieldRequired('dealer', watch('statut')) && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
        });
    }, [register]);

    useEffect(() => {
        const isBorneProductSelected = isBorneProduct(formProduct);
        setIsBorne(isBorneProductSelected);
        Object.keys(params?.formValues).forEach((key) => {
            if (key === 'product') return;
            updateValues(key);
        });
        setValue('typeContrat', isBorneProductSelected ? ContractType.ELECTRIQUE : ContractType.CLASSIQUE);
    }, [formProduct]);

    useEffect(() => {
        if (isLongerThan2Years) setValue('dateSolution', params?.formValues?.dateSolution);
    }, [isLongerThan2Years]);

    const updateDurationOptions = (codeProduct: string) => {
        if (!codeProduct) {
            setDurationOptions(SUBSCRIPTION_DURATION);
            return;
        }

        const productsToFind: Product[] = products.length > 0 ? products : params.allProducts;
        const product = productsToFind.find((p) => p.code === codeProduct);

        if (Array.isArray(product?.dureeSouscription)) {
            product?.dureeSouscription.forEach((d: string) => {
                const duration = SUBSCRIPTION_DURATION.find((durationToFind) => durationToFind.code === d);
                if (!durationOptions.includes(duration)) {
                    durationOptions.push(duration);
                }
            });
        } else {
            const duration = SUBSCRIPTION_DURATION.find(
                (durationToFind) => durationToFind.code === product?.dureeSouscription,
            );

            if (!durationOptions.includes(duration)) {
                durationOptions.push(duration);
            }
        }
        setDurationOptions(durationOptions);
    };

    const updateIsCoyote = (codeProduct: string) => {
        const productsToFind: Product[] = products.length > 0 ? products : params.allProducts;
        const product = productsToFind.find((p) => p.code === codeProduct);

        setValue('productIsCoyote', product?.prestations.some((presta) => presta.type === 'COY') || false);
    };

    useEffect(() => {
        if (params && params?.formValues) {
            setProducts(params.allProducts);
            Object.keys(params?.formValues).forEach((key) => {
                updateValues(key);
            });
            updateDurationOptions(params?.formValues?.product);
        }
    }, [params]);

    useEffect(() => {
        if (formProduct) {
            updateIsCoyote(formProduct);
        }
    }, [formProduct]);

    useEffect(() => {
        if (params?.formValues?.securywebId && dealers) {
            const dealer = dealers.find(
                (dealerToFind) => dealerToFind.securyweb_id === params?.formValues?.securywebId,
            );

            if (dealer) {
                setValue('dealer', dealer);
            }
        }
    }, [dealers]);

    const onChangeDealership = (value: string) => {
        setValue('dealership', value);
        setValue('product', '');

        fetchProducts(value);
    };

    const onChangeDealer = (value: { securyweb_id: string }) => {
        setValue('dealer', value);
        setValue('securywebId', value.securyweb_id);
    };

    const referentsInterceptor = (vendors: User[]) => {
        if (vendors) {
            return vendors.filter((vendor) => Boolean(vendor.securyweb_id));
        }
    };

    useEffect(() => {
        if (idContract && watch('statut') === StatusContractSlug.VALID) {
            fetchOptins(idContract);
        }
    }, [idContract]);

    return (
        <>
            <FormPartTitle title="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.CATEGORY.DEALERSHIP.TITLE" />
            {/* @ts-ignore */}
            <DealershipVendors
                labelDealership="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.DEALERSHIP"
                labelVendor="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.VENDOR"
                referent={watch('dealer')}
                setReferent={onChangeDealer}
                setReferents={setDealers}
                setDealership={onChangeDealership}
                referentsInterceptor={referentsInterceptor}
                dealership={params?.formValues?.dealership}
                registerPerimeter={false}
                disableDealer={isFieldDisabled('dealer', watch('statut'), isBorne)}
                disableDealership={isFieldDisabled('dealership', watch('statut'), isBorne)}
                optionValueDealership="refDealershipId"
                isRegistered
            />

            <Controller control={control} name="id" render={({ value }) => <input type="hidden" value={value} />} />

            <Controller
                control={control}
                name="contractNumber"
                render={({ value }) => <input type="hidden" value={value} />}
            />

            <Controller
                control={control}
                name="typeContrat"
                render={({ value }) => <input type="hidden" value={value} />}
            />

            <Controller
                control={control}
                name="subscriberId"
                render={({ value }) => <input type="hidden" value={value} />}
            />

            <Controller
                control={control}
                name="securywebId"
                render={({ value }) => <input type="hidden" value={value} />}
            />

            <Controller
                control={control}
                name="secretaryId"
                {...(userData && { defaultValue: userData.securyweb_id })}
                render={({ value }) => <input type="hidden" value={value} />}
            />

            <Controller
                control={control}
                name="statut"
                defaultValue={StatusContractSlug.DRAFT}
                render={({ value }) => <input type="hidden" value={value} />}
            />

            <Controller
                control={control}
                name="productIsCoyote"
                defaultValue={false}
                render={({ value }) => <input type="hidden" value={value} />}
            />

            <FormPartTitle title="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.CATEGORY.CONTRACT.TITLE" />
            {formDealership === 'MUL027' && (
                <Row>
                    <Col lg={12}>
                        <Form.Group>
                            <Controller
                                control={control}
                                name="offer"
                                rules={{
                                    required:
                                        isFieldRequired('offer', watch('statut')) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                render={({ value, onChange }) => (
                                    <div className="d-flex flex-wrap gap-6">
                                        <div className="d-flex align-items-center">
                                            <RadioButton
                                                inputId="family"
                                                value="FAMILY"
                                                onChange={(e) => onChange(e.value)}
                                                checked={value === 'FAMILY'}
                                            />
                                            <label htmlFor="family" className="ml-2 my-0">
                                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OFFER_FAMILY" />
                                            </label>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <RadioButton
                                                inputId="collab"
                                                value="COLLAB"
                                                onChange={(e) => onChange(e.value)}
                                                checked={value === 'COLLAB'}
                                            />
                                            <label htmlFor="collab" className="ml-2 my-0">
                                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OFFER_COLLAB" />
                                            </label>
                                        </div>
                                    </div>
                                )}
                            />
                            <ErrorForm errors={errors} name="offer" />
                        </Form.Group>
                    </Col>
                </Row>
            )}
            <Row>
                <Col lg={8}>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PRODUCT" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="product"
                            rules={{
                                required:
                                    isFieldRequired('product', watch('statut')) &&
                                    Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            }}
                            render={({ value, onChange }) => (
                                <Dropdown
                                    value={value}
                                    options={products}
                                    onChange={(e) => {
                                        onChange(e.value);
                                        updateDurationOptions(e.value);
                                    }}
                                    className={classNames({
                                        'is-invalid': errors.product,
                                    })}
                                    optionLabel="libelle"
                                    optionValue="code"
                                    disabled={
                                        isFieldDisabled('product', watch('statut'), isBorne) || !watch('dealership')
                                    }
                                    itemTemplate={(item) => `${item?.code} - ${item?.libelle}`}
                                    {...(value && {
                                        valueTemplate: (item) => `${item?.code} - ${item?.libelle}`,
                                    })}
                                    placeholder={Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.PRODUCT',
                                    })}
                                    emptyMessage={
                                        loadingProducts
                                            ? Intl.formatMessage({
                                                  id: 'TRANSLATOR.LOADING',
                                              })
                                            : Intl.formatMessage({
                                                  id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SELECT_PRODUCT.EMPTY',
                                              })
                                    }
                                />
                            )}
                        />
                        <ErrorForm errors={errors} name="product" />
                    </Form.Group>
                </Col>
                <Col lg={4}>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.INITIAL_DURATION" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="initialDuration"
                            rules={{
                                required:
                                    isFieldRequired('initialDuration', watch('statut')) &&
                                    Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            }}
                            render={({ value, onChange }) => (
                                <Dropdown
                                    value={value}
                                    options={durationOptions}
                                    onChange={(e) => onChange(e.value)}
                                    className={classNames({
                                        'is-invalid': errors.initialDuration,
                                    })}
                                    optionLabel="title"
                                    disabled={isFieldDisabled('initialDuration', watch('statut'), isBorne)}
                                    placeholder={Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.INITIAL_DURATION',
                                    })}
                                    emptyMessage={Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SELECT_INITIAL_DURATION.EMPTY',
                                    })}
                                />
                            )}
                        />
                        <ErrorForm errors={errors} name="initialDuration" />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={4}>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id={`CUSTOMERS.MODAL.SECURYCAR_CONTRACT.${subscriptionDateLabel}`} />
                        </Form.Label>
                        <Controller
                            control={control}
                            name={subscriptionDateName}
                            rules={{
                                required:
                                    isFieldRequired(subscriptionDateName, watch('statut'), isBorne) &&
                                    Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                validate: (value) => {
                                    if (value && dayjs(value).isBefore(dayjs().startOf('day'))) {
                                        return Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ERROR.SUBSCRIPTION_DATE',
                                        });
                                    }
                                },
                            }}
                            render={({ value, onChange }) => (
                                <EditableDatePicker
                                    value={value || ''}
                                    minDate={dayjs().toDate()}
                                    placeholder="JJ/MM/AAAA"
                                    className={classNames({
                                        'is-invalid': errors[subscriptionDateName],
                                    })}
                                    onChange={(e: { value: Date }) => onChange(e.value)}
                                    disabled={isFieldDisabled(subscriptionDateName, watch('statut'), isBorne)}
                                />
                            )}
                        />
                        <ErrorForm errors={errors} name={subscriptionDateName} />
                    </Form.Group>
                </Col>
                {!isBorne && (
                    <>
                        <Col lg={4}>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PRICE_WITH_TAXES" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="priceWithTaxes"
                                    rules={{
                                        required:
                                            isFieldRequired('priceWithTaxes', watch('statut')) &&
                                            Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    }}
                                    render={({ value, onChange }) => (
                                        <NumberFormat
                                            value={value}
                                            className={classNames('form-control', {
                                                'is-invalid': errors.priceWithTaxes,
                                            })}
                                            thousandSeparator=" "
                                            onValueChange={(e) => onChange(parseFloat(e.value))}
                                            placeholder={Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.PRICE_WITH_TAXES',
                                            })}
                                            disabled={isFieldDisabled('priceWithTaxes', watch('statut'))}
                                        />
                                    )}
                                />
                                <ErrorForm errors={errors} name="priceWithTaxes" />
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PRICE_WITHOUT_TAXE" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="priceWithoutTaxe"
                                    rules={{
                                        required:
                                            isFieldRequired('priceWithoutTaxe', watch('statut')) &&
                                            Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    }}
                                    render={({ value, onChange }) => (
                                        <NumberFormat
                                            value={value}
                                            className={classNames('form-control', {
                                                'is-invalid': errors.priceWithoutTaxe,
                                            })}
                                            thousandSeparator=" "
                                            onValueChange={(e) => onChange(parseFloat(e.value))}
                                            placeholder={Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.PRICE_WITHOUT_TAXE',
                                            })}
                                            disabled={isFieldDisabled('priceWithoutTaxe', watch('statut'))}
                                        />
                                    )}
                                />
                                <ErrorForm errors={errors} name="priceWithoutTaxe" />
                            </Form.Group>
                        </Col>
                    </>
                )}
            </Row>
            {isBorne && (
                <Row>
                    <Col lg={lgBorneCol}>
                        <Form.Group>
                            <Form.Label className="text-nowrap">
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.AGE_SOLUTION_CHARGING" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="ageSolutionCharging"
                                rules={{
                                    required:
                                        isLongerThan2Years !== false &&
                                        isFieldRequired('ageSolutionCharging', watch('statut'), isBorne) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                defaultValue={params?.formValues?.ageSolutionCharging ?? null}
                                render={({ value, onChange }) => (
                                    <Dropdown
                                        value={value}
                                        options={OLD_RECHARGE_SOLUTION}
                                        onChange={(e) => onChange(e.value)}
                                        className={classNames('form-control', {
                                            'is-invalid': errors.ageSolutionCharging,
                                        })}
                                        optionLabel="title"
                                        placeholder={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.SOLUTION_CHARGING',
                                        })}
                                        disabled={isFieldDisabled('ageSolutionCharging', watch('statut'), isBorne)}
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name="ageSolutionCharging" />
                        </Form.Group>
                    </Col>
                    {isLongerThan2Years && (
                        <Col lg={4}>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.DATE_SOLUTION" />
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="dateSolution"
                                    rules={{
                                        required:
                                            isLongerThan2Years && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    }}
                                    render={({ value, onChange }) => (
                                        <EditableDatePicker
                                            value={value || ''}
                                            placeholder="JJ/MM/AAAA"
                                            className={classNames({
                                                'is-invalid': errors.dateSolution,
                                            })}
                                            onChange={(e: { value: Date }) => onChange(e.value)}
                                            disabled={isFieldDisabled('dateSolution', watch('statut'), isBorne)}
                                        />
                                    )}
                                />
                                <ErrorForm errors={errors} name="dateSolution" />
                            </Form.Group>
                        </Col>
                    )}
                    <Col lg={lgBorneCol}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.TYPE_SOLUTION_CHARGING" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="typeSolutionCharging"
                                rules={{
                                    required:
                                        isFieldRequired('typeSolutionCharging', watch('statut'), isBorne) &&
                                        Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }}
                                render={({ value, onChange }) => (
                                    <Dropdown
                                        value={value}
                                        options={TYPE_SOLUTION_RECHARGE}
                                        onChange={(e) => onChange(e.value)}
                                        className={classNames('form-control', {
                                            'is-invalid': errors.typeSolutionCharging,
                                        })}
                                        optionLabel="title"
                                        placeholder={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.SOLUTION_CHARGING',
                                        })}
                                        disabled={isFieldDisabled('typeSolutionCharging', watch('statut'), isBorne)}
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name="typeSolutionCharging" />
                        </Form.Group>
                    </Col>
                </Row>
            )}

            <FormPartTitle title="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.CATEGORY.BANK_REFERENCE.TITLE" />
            <Row>
                <Col lg={8}>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.IBAN" />
                            {isBorne && '*'}
                        </Form.Label>
                        <Controller
                            control={control}
                            name="iban"
                            rules={{
                                required:
                                    isFieldRequired('iban', watch('statut'), isBorne) &&
                                    Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                validate: (value) => {
                                    if (value && value.length > 0 && (value.length < 16 || value.length > 34)) {
                                        return Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ERROR.IBAN',
                                        });
                                    }
                                },
                            }}
                            render={({ value, onChange }) => (
                                <InputText
                                    value={value}
                                    onChange={(e) => onChange(e.currentTarget.value.toUpperCase())}
                                    className={classNames('form-control', {
                                        'is-invalid': errors.iban,
                                    })}
                                    placeholder={Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.IBAN',
                                    })}
                                    disabled={isFieldDisabled('iban', watch('statut'), isBorne)}
                                />
                            )}
                        />
                        <ErrorForm errors={errors} name="iban" />
                    </Form.Group>
                </Col>
                <Col lg={4}>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.BIC" />
                            {isBorne && '*'}
                        </Form.Label>
                        <Controller
                            control={control}
                            name="bic"
                            rules={{
                                required:
                                    (isIbanAndBorne || isFieldRequired('bic', watch('statut'), isBorne)) &&
                                    Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),

                                validate: (value) => {
                                    if (value && value.length > 0 && (value.length < 8 || value.length > 11)) {
                                        return Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ERROR.BIC',
                                        });
                                    }
                                },
                            }}
                            render={({ value, onChange }) => (
                                <InputText
                                    value={value}
                                    onChange={(e) => onChange(e.currentTarget.value.toUpperCase())}
                                    className={classNames('form-control', {
                                        'is-invalid': errors.bic,
                                    })}
                                    placeholder={Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.BIC',
                                    })}
                                    disabled={isFieldDisabled('bic', watch('statut'), isBorne)}
                                />
                            )}
                        />
                    </Form.Group>
                    <ErrorForm errors={errors} name="bic" />
                </Col>
            </Row>
            {optins && !loadingOptions && watch('statut') === StatusContractSlug.VALID && (
                <>
                    <FormPartTitle title="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.CATEGORY.OPTIN.TITLE" />
                    <Row className="mb-4">
                        <Col lg={2}>
                            <Form.Group>
                                <SwitchInput
                                    name="optin_group"
                                    labelFirst={
                                        <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTIN_GROUP" />
                                    }
                                    labelSecond=""
                                    className="switch-input--primary switch-input--inactive-toggle-lighten switch-input--inactive-label-left-secondary"
                                    active={optins.autoriseGroupe}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={2}>
                            <Form.Group>
                                <SwitchInput
                                    name="optin_partner"
                                    labelFirst={Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTIN_PARTNER',
                                    })}
                                    labelSecond=""
                                    className="switch-input--primary switch-input--inactive-toggle-lighten switch-input--inactive-label-left-secondary"
                                    active={optins.autorisePartenaires}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default SecuryCarContractStepInfos;
