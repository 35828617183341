import ApiGateway, { CustomAxiosResponse } from '@app/helpers/AxiosHelper';
import { getFormData } from '@app/helpers/ObjectHelper';

import { Filters, PackageCategory, PackageFilter } from '@app/pages/customers/Modals/APVInStore/APVInStore.type';

import { ApiResponse, FetchOrderParams, OrderInStore } from './order.type';

const { REACT_APP_APV_URL } = process.env;

export interface PackagesResponse {
    filters: Filters;
    packages: PackageFilter[];
}

export function getPackages(params: FetchOrderParams) {
    return ApiGateway.get<ApiResponse<PackagesResponse>, CustomAxiosResponse<PackagesResponse>>(
        `${REACT_APP_APV_URL}/package`,
        {
            params,
        },
    );
}

export function getPackage(id: number) {
    return ApiGateway.get(`${REACT_APP_APV_URL}/package/${id}`);
}

export function getCategories() {
    return ApiGateway.get(`${REACT_APP_APV_URL}/package/category`);
}

export function addPackage(data: any) {
    return ApiGateway.post(`${REACT_APP_APV_URL}/package`, getFormData(data));
}

export function updatePackageById(id: number, data: any) {
    return ApiGateway.post(`${REACT_APP_APV_URL}/package/${id}`, getFormData(data));
}

export function deletePackage(id: number) {
    return ApiGateway.delete(`${REACT_APP_APV_URL}/package/${id}`);
}

export interface PackageCategoryResponse {
    categories: PackageCategory[];
}

export function getPackagesCategoriesWithPackagesAndPrices({
    dateFirstRegistration,
    departmentId,
    vehicleMakeSegmentId,
    vehicleBodyTypeSegmentId,
}: {
    dateFirstRegistration: string;
    departmentId: string;
    vehicleMakeSegmentId: string;
    vehicleBodyTypeSegmentId: string;
}) {
    return ApiGateway.get<PackageCategoryResponse, CustomAxiosResponse<PackageCategoryResponse>>(
        `${REACT_APP_APV_URL}/package/price/categories?dateFirstRegistration=${dateFirstRegistration}&departmentId=${departmentId}&vehicleMakeSegmentId=${vehicleMakeSegmentId}&vehicleBodyTypeSegmentId=${vehicleBodyTypeSegmentId}`,
    );
}

export async function addOnePackage(orderId: number, packageId: number) {
    const response = await ApiGateway.post<ApiResponse<OrderInStore>, CustomAxiosResponse<ApiResponse<OrderInStore>>>(
        `${REACT_APP_APV_URL}/orderApvInStore/${orderId}/package`,
        { packageId },
    );
    if (response?.result?.error) {
        throw Error(response?.result?.error);
    } else {
        return response;
    }
}

interface AddPackageParams {
    orderId: number;
    packageSlug: string;
    name: string;
    price: number;
    duration: number;
}

export async function addOneCustomPackage({
    orderId,
    packageSlug,
    name,
    price,
    duration,
}: AddPackageParams): Promise<CustomAxiosResponse<ApiResponse<OrderInStore>>> {
    const response = await ApiGateway.post<ApiResponse<OrderInStore>, CustomAxiosResponse<ApiResponse<OrderInStore>>>(
        `${REACT_APP_APV_URL}/orderApvInStore/${orderId}/package`,
        { packageSlug, name, price, duration },
    );
    if (response?.result?.error) {
        throw new Error(response?.result?.error);
    } else {
        return response;
    }
}

export function deteleOnePackage(orderId: number, packageId: number) {
    return ApiGateway.delete<ApiResponse<OrderInStore>, CustomAxiosResponse<ApiResponse<OrderInStore>>>(
        `${REACT_APP_APV_URL}/orderApvInStore/${orderId}/package/${packageId}`,
    );
}
