import classNames from 'classnames';
import { Column } from 'primereact/column';
import React, { useMemo } from 'react';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import HoCDataTable from '@app/partials/content/HoCDataTable';

import CampaignCardCell from '../CampaignCardCell';

const CampaignSynthesisGroupHeader = ({ expandedRows, setExpandedRows, users, dealership }) => {
    const isExpandable = !!users;
    const isExpanded = useMemo(() => expandedRows?.includes?.(users?.[0]), [expandedRows, users]);

    const toggleClickHandler = () => {
        if (isExpandable) {
            const toggled = users?.[0];

            if (isExpanded) {
                setExpandedRows(expandedRows?.filter?.((expandedRow) => expandedRow !== toggled));
            } else {
                setExpandedRows([...expandedRows, toggled]);
            }
        }
    };

    const userBodyTemplate = () => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
        <div
            className={`${isExpandable ? 'cursor-pointer' : ''} kt-shape-font-color-4 d-flex align-items-center`}
            role="button"
            onClick={toggleClickHandler}
        >
            {isExpandable && (
                <i
                    className={classNames('mr-4 kt-shape-font-color-4 las la-2x', {
                        'la-plus': !isExpanded,
                        'la-minus': isExpanded,
                    })}
                />
            )}
            <div className="d-flex flex-column">
                <div>{Intl.formatMessage({ id: 'CAMPAIGN.SYNTHESIS.DEALERSHIP' })}</div>
                <div className="font-weight-bold">{dealership?.name}</div>
            </div>
        </div>
    );

    const salesForceBodyTemplate = () => (
        <CampaignCardCell
            className="kt-bg-light-secondary kt-shape-font-color-4"
            number={dealership?.contacts}
            percentage={100}
        />
    );

    const leadsBodyTemplate = () => (
        <CampaignCardCell
            className="kt-bg-light-success text-success"
            number={dealership?.leads ?? '-'}
            percentage={((dealership?.leads || 0) / dealership?.contacts) * 100}
        />
    );

    const bloctelBodyTemplate = () => (
        <CampaignCardCell
            className="kt-bg-light-danger text-danger"
            number={dealership?.bloctelKo ?? '-'}
            percentage={((dealership?.bloctelKo || 0) / dealership?.contacts) * 100}
        />
    );
    const ignoredBodyTemplate = () => (
        <CampaignCardCell
            className="kt-bg-dark-light text-gray"
            number={dealership?.ignoredContact ?? '-'}
            percentage={((dealership?.ignoredContact || 0) / dealership?.contacts) * 100}
        />
    );

    const cols = [
        {
            field: 'user',
            body: userBodyTemplate,
        },
        {
            field: 'salesforce',
            style: { width: '20%' },
            body: salesForceBodyTemplate,
        },
        {
            field: 'leads',
            style: { width: '20%' },
            body: leadsBodyTemplate,
        },
        {
            field: 'bloctel',
            style: { width: '20%' },
            body: bloctelBodyTemplate,
        },
        {
            field: 'ignored',
            style: { width: '20%' },
            body: ignoredBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <HoCDataTable value={isExpandable ? [users[0]] : [null]} className="p-datatable-row-header">
            {dynamicColumns}
        </HoCDataTable>
    );
};

export default CampaignSynthesisGroupHeader;
