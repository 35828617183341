import ApiGateway, { CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { Job } from '@app/store/login/login.store';

const { REACT_APP_AUTOUSER_URL } = process.env;

interface JobWithId extends Job {
    id: number;
}

// eslint-disable-next-line import/prefer-default-export
export function getJobs() {
    return ApiGateway.get<JobWithId[], CustomAxiosResponse<JobWithId[]>>(`${REACT_APP_AUTOUSER_URL}/admin/jobs`);
}
