type CounterInterval = { id: string; interval: NodeJS.Timeout };

class CounterIntervals {
    private intervals: Set<CounterInterval>;

    constructor() {
        this.intervals = new Set<CounterInterval>();
    }

    private hasCounterId(id: string): boolean {
        return Array.from(this.intervals).some((interval) => interval.id === id);
    }

    private getCounterById(id: string): CounterInterval {
        const interval = Array.from(this.intervals).find((intervalItem) => intervalItem.id === id);
        return interval || null;
    }

    private deleteCounterById(id: string): void {
        const cInterval = this.getCounterById(id);
        if (cInterval) {
            clearInterval(cInterval.interval);
            this.intervals.delete(cInterval);
        }
    }

    addInterval(id: string, interval: NodeJS.Timeout): void {
        if (this.hasCounterId(id)) {
            this.deleteCounterById(id);
        }
        this.intervals.add({ id, interval });
    }

    hasInterval(id: string) {
        return this.hasCounterId(id);
    }

    deleteInterval(id: string) {
        if (this.hasCounterId(id)) {
            const cInterval = this.getCounterById(id);
            clearInterval(cInterval.interval);
            this.deleteCounterById(id);
        }
    }

    clearIntervals() {
        Array.from(this.intervals).forEach((cInterval) => {
            clearInterval(cInterval.interval);
            this.intervals.delete(cInterval);
        });
    }
}

export default CounterIntervals;
