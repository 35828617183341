import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { getOrder, getOrders } from '@app/crud/manufacturer/order.crud';

import { actions } from './order.store';

export function fetchOrders(params: any): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        dispatch(actions.fetchOrders());

        getOrders(params)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(
                        actions.fetchedOrders({
                            ...response.result,
                            // @ts-ignore
                            count: response?.count || 0,
                        }),
                    );
                    dispatch(actions.setOrdersTotalRecords(response?.count || 0));
                } else {
                    dispatch(actions.fetchOrdersFailed({}));
                }
            })
            .catch(() => {
                dispatch(actions.fetchOrdersFailed({}));
            });
    };
}

export function fetchOrder(id: string): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        dispatch(actions.fetchOrder());

        getOrder(id)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.fetchedOrder(response.result));
                } else {
                    dispatch(actions.fetchOrderFailed(response.result));
                }
            })
            .catch(() => {
                dispatch(actions.fetchOrderFailed({}));
            });
    };
}
