import dayjs from 'dayjs';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

import { DateTimePicker } from '@app/partials/content/DateTimePicker';
import ErrorForm from '@app/partials/layout/ErrorForm';

type CustomDatePickerProps = {
    name: string;
    label: string;
    dateFormat: string;
    startYear: number;
    endYear: number;
    locale: string;
};

const CustomDatePicker = ({ name, label, dateFormat, startYear, endYear, locale }: CustomDatePickerProps) => {
    const { control, errors } = useFormContext();

    return (
        <Form.Group controlId={name}>
            <Form.Label>{label}</Form.Label>
            <Controller
                control={control}
                name={name}
                render={({ value, onChange }) => (
                    <DateTimePicker
                        locale={locale}
                        dateFormat={dateFormat}
                        isBirthdayDate={false}
                        monthNavigator
                        yearNavigator
                        yearRange={`${startYear}:${endYear}`}
                        value={value}
                        onChange={(e: { value: string | number | Date | dayjs.Dayjs }) => onChange(e.value)}
                    />
                )}
            />
            <ErrorForm errors={errors} name={name} />
        </Form.Group>
    );
};

export default CustomDatePicker;
