import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { updatePassword } from '@app/crud/autouser/user.crud';

import Loader from '@app/partials/content/Loader';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

const container = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
    },
};

const AccountPassword = ({ userData }) => {
    const { register, getValues, errors, handleSubmit } = useForm();
    const [updating, setUpdating] = useState(false);

    const onSubmit = async (form_values) => {
        setUpdating(true);
        await updatePassword(userData?.id, form_values.password);
        setUpdating(false);
        toast({
            variant: 'success',
            message: Intl.formatMessage({ id: 'USER.PROFILE.SETTINGS.UPDATE.SUCCESS' }),
        });
    };

    return (
        <motion.div className="account-settings" variants={container} initial="hidden" animate="visible">
            {updating && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <div className="mb-7 font-size-h5 font-weight-bold">
                {Intl.formatMessage({ id: 'USER.PROFILE.SETTINGS.UPDATE_PASSWORD' })}
            </div>
            <Form.Group>
                <Form.Label>{Intl.formatMessage({ id: 'USER.PROFILE.SETTINGS.NEW_PASSWORD' })} :</Form.Label>
                <Form.Control
                    type="password"
                    name="password"
                    ref={register({
                        validate: (value) =>
                            getValues('password_confirm') !== value
                                ? Intl.formatMessage({ id: 'USER.PROFILE.PASSWORD.IDENTICAL' })
                                : true,
                    })}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>{Intl.formatMessage({ id: 'USER.PROFILE.SETTINGS.CONFIRM_PASSWORD' })} :</Form.Label>
                <Form.Control type="password" name="password_confirm" ref={register} />
                <div className="mt-1 font-size-sm">
                    {Intl.formatMessage({ id: 'USER.PROFILE.SETTINGS.CONFIG_PASSWORD' })}
                </div>
            </Form.Group>
            <ErrorForm errors={errors} name="password" />
            <div className="mt-15 d-flex justify-content-end">
                <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                    {Intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                </Button>
            </div>
        </motion.div>
    );
};

export default AccountPassword;
