import { useCallback, useRef, useState } from 'react';

import { useAppDispatch } from '@app/hooks/useAppDispatch';
import usePermissions from '@app/hooks/usePermissions';

import { addAction, getCustomer } from '@app/crud/customers/customer.crud';

import { formatActionData } from '@app/pages/customers/Helpers/Actions';
import { actions } from '@app/store/customers/customers.store';
import { Action as LeadAction, actions as leadActions } from '@app/store/lead/lead.store';

/* eslint-disable @typescript-eslint/ban-types */
interface Action {
    type: string;
    success: Function;
    failed: Function;
    permission?: string;
    dataInterceptor?: Function;
}

const useAction = ({
    type,
    success,
    failed,
    permission = '',
    dataInterceptor,
}: Action): [boolean, (data?: object, form?: boolean) => Promise<unknown>, (actionTypeToSet: string) => void] => {
    const { hasPermission } = usePermissions();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const actionType = useRef(type);

    // @ts-ignore
    const createAction = useCallback(
        (data = {}, form: boolean) => {
            setLoading(true);

            if (actionType.current) {
                // @ts-ignore
                data.actionType = actionType.current;
            }

            let formattedData = formatActionData(data);

            if (typeof dataInterceptor !== 'undefined') {
                formattedData = dataInterceptor(formattedData);
            }

            if (!hasPermission(permission)) {
                setLoading(false);
                return new Promise<void>((resolve) => {
                    resolve();
                });
            }

            let leadAction: LeadAction | null = null;
            if (formattedData.actionType && formattedData.lead?.id) {
                leadAction = {
                    action: formattedData.actionType,
                    id: formattedData.lead.id,
                };

                dispatch(leadActions.startAction(leadAction));
            }

            return addAction(formattedData, form)
                .then(async (response) => {
                    const customer = await getCustomer(formattedData.contactId);

                    if (!customer?.result?.contact) {
                        failed(response);
                        return;
                    }

                    setLoading(false);

                    if (response?.result?.error) {
                        failed?.(response);
                        return response;
                    }

                    if (customer?.result?.leads) {
                        dispatch(actions.leadUpdated(customer.result.contact, customer.result.leads, type));
                    }

                    if (customer?.result?.leads?.length > 0 && response?.result?.lastLeadId) {
                        response.result.lead = customer.result.leads.find((lead) => {
                            return lead.id === response.result.lastLeadId;
                        });
                    }

                    success?.(response);

                    dispatch(leadActions.stopAction(leadAction));

                    return response;
                })
                .catch((response) => {
                    setLoading(false);
                    failed?.(response);
                    dispatch(leadActions.stopAction(leadAction));
                });
        },
        [dispatch, actionType, success, failed],
    );

    const setActionType = (actionTypeToSet: string): void => {
        actionType.current = actionTypeToSet;
    };

    return [loading, createAction, setActionType];
};

export default useAction;
