import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { getOneCustomerActivity } from '@app/crud/customers/customer.crud';
import { ApiLeadReason, CustomerEvent, LeadDetails } from '@app/crud/customers/customer.type';

import { useFetch } from '@app/hooks';
import LeadLooseDefault from '@app/pages/customers/Modals/LeadLoose/LeadLooseDefault';

import InformationNotice from '@app/partials/content/InformationNotice';
import Loader from '@app/partials/content/Loader';
import SwitchInput from '@app/partials/content/SwitchInput';
import ErrorForm from '@app/partials/layout/ErrorForm';

interface Props {
    params?: {
        leadId: number;
        event?: CustomerEvent;
    };
    leadReasons: ApiLeadReason;
    reason: string;
}

const LeadLooseFid = ({ params, leadReasons, reason }: Props) => {
    const Intl = useIntl();
    const { register, errors } = useFormContext();
    const {
        fetch: fetchActivity,
        data: leadData,
        loading,
    } = useFetch<string, LeadDetails>({
        fetchAction: getOneCustomerActivity,
    });

    useEffect(() => {
        if (params?.leadId && fetchActivity) {
            fetchActivity(params.leadId.toString());
        }
    }, [params, fetchActivity]);

    const isCredipar = leadData?.finance?.isCredipar;

    const reasons = ['vehicle_kept', 'rival', 'other'].map((key) => leadReasons[key]);
    const subReasonItem = reasons?.find?.((reason_item) => reason_item?.slug === reason);

    return (
        <>
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            {isCredipar ? (
                <Form.Group>
                    <Form.Label>
                        {Intl.formatMessage({
                            id: 'CUSTOMERS.MODAL.LEAD_WON.FID.FINANCING_AGENCY_LEAD_WON_LABEL',
                        })}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        defaultValue={Intl.formatMessage({
                            id: 'CUSTOMERS.MODAL.FID.CREDIPAR',
                        })}
                        disabled
                        className="mb-4"
                    />

                    <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_LOOSE.REACH' })}</Form.Label>
                    <Form.Control
                        as="select"
                        name="reason"
                        defaultValue=""
                        disabled={!!params?.event}
                        className={errors.reason ? 'is-invalid' : ''}
                        ref={register({
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                        })}
                    >
                        <option value="">
                            {Intl.formatMessage({
                                id: 'CUSTOMERS.MODAL.LEAD_LOOSE.REACH.PLACEHOLDER',
                            })}
                        </option>
                        {leadReasons &&
                            reasons.map((value) => (
                                <option key={value.id} value={value.slug}>
                                    {value.name}
                                </option>
                            ))}
                    </Form.Control>
                    <ErrorForm errors={errors} name="reason" />

                    {subReasonItem?.leadReasons?.length > 0 && (
                        <Form.Group>
                            <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_LOOSE.REASON' })}</Form.Label>
                            <Form.Control
                                as="select"
                                name="subReason"
                                defaultValue=""
                                disabled={!!params?.event}
                                className={errors.subReason ? 'is-invalid' : ''}
                                ref={register({
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                })}
                            >
                                <option value="">
                                    {Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.LEAD_LOOSE.REASON.PLACEHOLDER',
                                    })}
                                </option>
                                {subReasonItem?.leadReasons?.map((value) => (
                                    <option key={value.id} value={value.slug}>
                                        {value.name}
                                    </option>
                                ))}
                            </Form.Control>
                            <ErrorForm errors={errors} name="subReason" />
                        </Form.Group>
                    )}

                    <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_LOOSE.COMMENT' })}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        name="result"
                        disabled={!!params?.event}
                        className={errors.result ? 'is-invalid' : ''}
                        ref={register({
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                        })}
                    />
                    <ErrorForm errors={errors} name="result" />
                </Form.Group>
            ) : (
                <LeadLooseDefault params={params} leadReasons={leadReasons} reason={reason} />
            )}
            <Form.Group>
                <InformationNotice>
                    <>
                        {
                            // TODO: upgrade react-numeric-format to v5 and replace NumberFormat with numericFormatter
                        }
                        <FormattedHTMLMessage id="CUSTOMERS.MODAL.LEAD_WON.FID.NOTICE" />{' '}
                        <strong>
                            <NumberFormat
                                value={leadData?.finance?.residualValue}
                                thousandSeparator=" "
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale
                                displayType="text"
                                suffix=" €"
                            />
                        </strong>{' '}
                        <FormattedHTMLMessage id="CUSTOMERS.MODAL.LEAD_WON.FID.NOTICE_SUFFIX" />
                    </>
                </InformationNotice>
                <SwitchInput
                    name="isResidualBlocked"
                    labelFirst=""
                    labelSecond={Intl.formatMessage({
                        id: 'CUSTOMERS.MODAL.LEAD_CLOSE.FID.VR_BLOCK',
                    })}
                    className="mt-4"
                />
            </Form.Group>
        </>
    );
};

LeadLooseFid.defaultProps = {
    params: {},
};

export default LeadLooseFid;
