import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {Spinner} from "react-bootstrap";
import classNames from "classnames";

const Loader = ({ style = {}, wrapperStyle = {}, message = '', overlay = false, content = '', variant = '', className = '', block = false }) => {
    const [portalItem, setPortalItem] = useState(null);

    useEffect(() => {
        if (content) {
            setPortalItem(document.getElementById(typeof content === 'string' ? content : 'kt_content'));
        }
    }, [content]);

    const Loader = <div className={classNames('loader justify-content-center align-items-center', className, {
        'loader--overlay' : overlay,
        'loader--content' : content,
        'loader--block'   : block
    })} style={wrapperStyle}>
        {overlay &&
        <div className="loader__overlay" />
        }
        <div className="loader__content">
            <Spinner style={style}
                     animation="border"
                     className=""
                     variant={variant}
                     role="status"></Spinner>
            {message &&
            <div className="mt-5">{message}</div>
            }
        </div>
    </div>;

    if (content && !portalItem) {
        return null;
    }

    return portalItem ? ReactDOM.createPortal(Loader, portalItem) : Loader;
}

Loader.defaultProps = {
    overlay: false,
    content: false,
    block: false,
    style: {
        width: '2rem',
        height: '2rem'
    },
    message: null,
    className: '',
    variant: 'primary'
}

export default Loader;
