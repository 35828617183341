import React from 'react';

const CarFrontSide = ({ width, height }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 837 703"
        xmlSpace="preserve"
        width={width}
        height={height}
    >
        <style type="text/css">
            {`
	.st0{opacity:0.1;enable-background:new    ;}
	.st1{fill:#124B84;}
	.st2{fill:none;}
	.st3{fill:#A0A0A0;}
	.st4{fill:#AECFD4;}
	.st5{fill:#444445;}
	.st6{fill:#59595A;}
	.st7{fill:#E600B9;}
	.st8{fill:#808283;}
	.st9{fill:#E3E0E2;}
	.st10{opacity:0.2;}
	.st11{fill:#FFFFFF;}
	.st12{fill:#0051B9;}
	.st13{fill:#8E959A;}
	.st14{fill:#3B3B3B;}
	.st15{fill:#FF6726;}
    `}
        </style>
        <g>
            <path
                id="Tracé_2525"
                className="st0"
                d="M836.8,677.5c0.4,12.9-9.7,23.7-22.6,24.2H22.9c-12.9-0.5-23-11.3-22.6-24.2l0,0
		c-0.4-12.9,9.7-23.7,22.6-24.2h790.5c13.1,0.1,23.5,10.8,23.4,23.9L836.8,677.5z"
            />
            <path
                className="st1"
                d="M720.4,237.2l0.1-0.1c-24.6-61.3-59-107-73.6-123.5s-37.8-18.9-37.8-18.9c-50-9.7-173.4-12-189-12.3l0,0V69.3
		c0-0.3-0.3-0.6-0.6-0.6l0,0h-1.9c-0.3,0-0.6,0.3-0.6,0.6l0,0v13.1c-9.4,0.1-138.6,2.3-190.1,12.3c0,0-1.2,0.1-3.3,0.5l-0.6,0.3
		c-6.1,3.4-12.4,8.3-16.7,15.1l0,0c4.3-6.8,10.6-11.7,16.7-15.1c-7.6,1.7-22.4,6.2-32.9,18.1c-14.6,16.5-49,62.2-73.6,123.5h0.1
		c0,0,12-2,19,4h0.1l0.1-0.2c-0.1,0.1-0.1,0.3-0.1,0.4h0.2h0.7h0.5l2-0.1h0.2l7.6-0.2l2-0.1h1.1h1.4h0.2l7.3-0.2h0.8l3.9-0.1h0.6
		l6.4-0.2h0.6l5.3-0.1l3.4-0.1h0.6h1.1h1.1l2.1-0.1h0.3l4.4-0.1h1.4l1.9-0.1l4.9-0.1h1.3h1.8h0.4l26.4-0.6h0.3l6.1-0.1h0.1l2.9-0.1
		h0.5l5.8-0.1h0.6l2.2-0.1h0.9l16-0.3h0.2c10.8-0.2,21.8-0.5,32.7-0.7h0.3l17.8-0.3h0.1l17.5-0.3l0,0l11.6-0.2h0.1l5.2-0.1h0.4
		l5.2-0.1l0,0l5.1-0.1h0.2l6.6-0.1h0.1l6-0.1l0,0h3.5l0,0l4.5-0.1l0,0h4.4l0,0l8.4-0.1l0,0h2.5h21.5l0,0h2.8l0,0h4.9
		c7.7,0,17.1,0.1,27.5,0.2H452c10.3,0.1,21.5,0.3,33.4,0.5l12.6,0.2c30.9,0.5,64.8,1.2,95.7,1.9l12.7,0.3l32.8,0.8l0.4-0.1l4.4,0.1
		l9.7,0.2l6.4,0.1l3.2,0.1l12.1,0.3l6.3,0.1l6.3,0.2l3.2,0.1l7.8,0.2h0.8h0.6h0.9c0-0.1,0-0.1-0.1-0.2h0.1
		C708.4,235.2,720.4,237.2,720.4,237.2z M668.7,171.1c-1.3-2.3-2.6-4.5-3.8-6.7c0.7,1.1,1.3,2.3,2,3.5S668.1,170,668.7,171.1z
		 M643.1,129l-0.9-1.3L643.1,129z M637.7,120.8l0.2,0.2l-2.2-3.2l0.3,0.5L637.7,120.8z M630.7,110.6L630.7,110.6z M511.7,100.4
		L511.7,100.4z M562.7,102.9L562.7,102.9z M480,99.4l-4.8-0.1l0,0L480,99.4z M453.8,98.6l3.9,0.1L453.8,98.6z M423.4,98h4.8h-0.1
		H423.4z M438.2,98.2L438.2,98.2z M442.4,98.3l3.2,0.1L442.4,98.3z M448,98.4l3.5,0.1L448,98.4z M459.6,98.8l6,0.2l0,0L459.6,98.8z
		 M608.1,107L608.1,107z M405,98.1h-1.6H405z M400.1,98.2h-1.9H400.1z M395,98.3h-2H395z M389.8,98.4l-2.1,0.1L389.8,98.4z
		 M384.4,98.6l-2,0.1L384.4,98.6z M378.7,98.8l-1.8,0.1L378.7,98.8z M368.4,99.1h1.1l-2.3,0.1L368.4,99.1z M347.2,99.6h-0.7H347.2z
		 M313.6,100.8L313.6,100.8z M313.3,100.8H313l-3.6,0.2L313.3,100.8z M307.4,101.1h-0.2c-1.1,0-2.1,0.1-3.2,0.2L307.4,101.1z
		 M292,101.9L292,101.9l-1.2,0.1L292,101.9z M253.3,104.5h0.3l0,0h-0.5H253.3z M270.1,103.2l2.1-0.2L270.1,103.2z M264.9,103.6
		L264.9,103.6z M253.6,104.5h-0.2H253.6z M214,109l0.4-0.1L214,109z M210.2,109.6l1.1-0.2l0,0l1.6-0.3l-1.6,0.3l0,0L210.2,109.6z
		 M206.3,110.6L206.3,110.6c-0.8,1.1-3.6,5.2-7.9,11.4C202.7,115.8,205.5,111.7,206.3,110.6L206.3,110.6z M198.4,122.2l-0.8,1.2
		L198.4,122.2z M197,124.3c-0.1,0.2-0.3,0.4-0.4,0.6C196.7,124.7,196.9,124.5,197,124.3z M196.1,125.6c-0.2,0.3-0.4,0.5-0.5,0.8
		C195.8,126.2,195.9,125.9,196.1,125.6L196.1,125.6z M194.7,127.8l-0.1,0.1L194.7,127.8z M136.7,238.2l-0.1,0.2L136.7,238.2z
		 M137.7,235.6L137.7,235.6L137.7,235.6z M384.6,236.3l-34.2-26.6L384.6,236.3L384.6,236.3z M384.2,98.8h-0.6h-2h-0.4L377,99h-0.6
		h-2.1h-0.8h-2.1l0,0h2.1h0.8h2.1h0.6l4.1-0.1h0.4h2L384.2,98.8h6h0.3h2l0,0l5.8-0.1h0.2h4.9h2h4h5V98l0,0v0.5h-5h-4h-2h-4.9h-0.2
		l-5.8,0.1l0,0h-2h-0.3L384.2,98.8z M605.2,209.7L643,239l0,0L605.2,209.7z M647.6,135.9c0.1,0.1,0.2,0.3,0.2,0.4
		C647.8,136.2,647.7,136.1,647.6,135.9L647.6,135.9z M652.7,144l1.2,2L652.7,144L652.7,144z M655.3,148.2c0.3,0.6,0.7,1.1,1,1.7
		l-0.9-1.4C655.4,148.4,655.3,148.3,655.3,148.2L655.3,148.2z"
            />
            <path
                className="st1"
                d="M25.9,593.4c-1.7-8.3-8-56.7-10.8-109.2c-0.1-2-0.2-4-0.3-5.9c-0.9,2.8-1.3,5.8-1.3,8.7v168.3
		c0,16.5,13.4,29.8,29.8,29.8l0,0h45.3c16.5,0,29.8-13.4,29.8-29.8l0,0v-33.2c-45.2-2.8-58.4-6.5-68.5-7.4
		C34.4,613.3,28.7,607.6,25.9,593.4z"
            />
            <path
                className="st1"
                d="M822.2,478.3c-0.1,2-0.2,4-0.3,5.9c-2.7,52.5-9.1,100.9-10.8,109.2c-2.8,14.2-8.5,19.9-24.1,21.3
		c-10.1,0.9-23.3,4.6-68.5,7.4v33.2c0,16.5,13.4,29.8,29.8,29.8l0,0h45.3c16.5,0,29.8-13.4,29.8-29.8l0,0V487
		C823.5,484,823.1,481.1,822.2,478.3z"
            />
            <path
                className="st1"
                d="M776.5,304.4c18.8,20,23,48.6,23.3,67.1l19.8,10.9c-0.1-0.9-0.3-1.9-0.4-2.8c-11-67.3-39.1-79.8-40.6-80.4v0.1
		c-2.9,3.1-2.1,4.9-2.1,4.9V304.4z"
            />
            <path
                className="st1"
                d="M60.5,304.4v-0.2c0,0,0.8-1.8-2.1-4.9v-0.1c-2,0.9-29.6,13.8-40.6,80.4c-0.1,0.9-0.3,1.9-0.4,2.8l19.8-10.9
		C37.6,353,41.8,324.4,60.5,304.4z"
            />
            <polygon className="st1" points="176.9,383.8 177.7,383.8 177.2,383.6 	" />
            <polygon className="st1" points="659.4,383.8 660.1,383.8 659.8,383.6 	" />
            <path
                className="st1"
                d="M417.4,396c0.8-0.2,1.6-0.3,2.4-0.3c0.2,0,0.4,0,0.6,0c4.5,0.4,7.9,4.1,7.9,8.6V415c3.5-1.9,5.2-6.2,5.2-12.5
		c0-12.3-8.8-16.8-14.5-16.8l0,0c-5.7,0.1-11.2,2.6-14.9,6.9c-7.8,8.6-7.1,21.9,1.5,29.7c1.2,1.1,2.5,2,3.9,2.8
		c-3-3.4-4.8-7.8-4.9-12.3C404.6,402.5,411.1,397.2,417.4,396z"
            />
            <path
                className="st1"
                d="M428.4,387.8c3.6,2.8,6.5,7.3,6.4,15.4l0,0c-0.1,8.2-3.2,11.9-6.6,13.5l0,0c-2.3,1.1-5.6,1.5-9.6,1.1
		c-6-0.6-10.4-6-9.8-12.1c0.2-1.5,0.6-3,1.4-4.4c-2.8,3.2-4.3,7.3-4.2,11.6c0,5.2,3.1,14.8,15,15.1h1.2c6-0.1,10.9-1.8,14.4-4.9
		c1.1-0.9,1.7-2.3,1.7-3.7v-14.9C438.3,397.5,434.5,391.1,428.4,387.8z"
            />
            <path className="st2" d="M371.4,99.1L371.4,99.1L371.4,99.1z" />
            <path
                className="st3"
                d="M593.9,238.9c-4.3-26.5-29.2-44.5-55.7-40.3c-20,3.2-35.9,18.5-39.9,38.4v0.1c30.9,0.5,64.8,1.2,95.7,1.9
		C593.9,239,593.9,238.9,593.9,238.9L593.9,238.9z"
            />
            <path
                className="st3"
                d="M643,239l-37.8-29.4c-1.6-15-4.2-29.8-7.6-44.5c-5.7-24.6-17-21.8-17-21.8h-69.3c0,0-11.4-2.8-17,21.8
		c-3.4,14.7-5.9,29.5-7.6,44.5l-34.4,26.6h-0.1c10.3,0.1,21.5,0.3,33.4,0.5v-0.1c5.4-33.3,36.8-56,70.1-50.5
		c26.8,4.4,47.6,25.9,50.9,52.9v0.1l32.8,0.8l0.4-0.1l4.4,0.1L643,239z"
            />
            <path
                className="st3"
                d="M350.3,209.7c-1.6-15-4.2-29.8-7.6-44.5c-5.7-24.6-17-21.8-17-21.8h-69.3c0,0-11.4-2.8-17,21.8
		c-3.4,14.7-5.9,29.5-7.6,44.5L192.6,240l4.9-0.1h1.3h1.8h0.4l26.4-0.6h0.3l6.1-0.1h0.1l2.9-0.1h0.5l5.8-0.1h0.6l2.2-0.1h0.9l16-0.3
		h0.2c10.8-0.2,21.8-0.5,32.7-0.7h0.3l17.8-0.3h0.1l17.5-0.3l0,0l11.6-0.2h0.1l5.2-0.1h0.4l5.2-0.1l0,0l5.1-0.1h0.2l6.6-0.1h0.1
		l6-0.1l0,0h3.5l0,0l4.5-0.1l0,0h4.4l-0.1-0.1L350.3,209.7z"
            />
            <path
                className="st4"
                d="M701.3,240.9c-0.1-0.3-0.2-0.7-0.3-1s-0.3-0.9-0.5-1.4c-0.7-1.9-1.4-3.8-2.1-5.8l-0.2-0.4
		c-0.4-1.1-0.9-2.3-1.3-3.5l-0.3-0.8c-0.5-1.3-1.1-2.7-1.6-4c-0.7-1.7-1.4-3.3-2.1-5l-0.1-0.2c-0.7-1.6-1.4-3.1-2-4.7l-0.2-0.4
		l-0.7-1.5c-0.5-1-0.9-2-1.4-3.1l-0.3-0.6c-0.6-1.3-1.2-2.7-1.9-4l-0.4-0.8c-0.6-1.2-1.2-2.5-1.8-3.7l-0.4-0.8l-0.2-0.5l-2-4.1
		c0-0.1-0.1-0.2-0.1-0.3l-1-2.1l-1.1-2.1l-0.3-0.6c-0.7-1.4-1.4-2.7-2.1-4c-0.1-0.2-0.2-0.4-0.3-0.5l0,0c-1.5-2.7-2.9-5.5-4.4-8.1
		v-0.1l-0.3-0.5c-0.4-0.8-0.9-1.6-1.3-2.4c-0.3-0.5-0.6-1-0.8-1.5l-0.3-0.5c-1.4-2.6-2.9-5.1-4.3-7.6l0,0c-0.1-0.2-0.2-0.3-0.3-0.5
		c-0.4-0.7-0.8-1.4-1.2-2.1l-0.8-1.4l-0.1-0.1l-1.5-2.5l-0.5-0.9l-0.2-0.3l-2-3.4l-0.1-0.1l-0.1-0.1c-0.9-1.5-1.8-3-2.7-4.4
		l-0.1-0.2l-2.8-4.3l-0.1-0.1l-0.3-0.6c-0.3-0.5-0.6-0.9-0.8-1.4l-0.3-0.4l-0.9-1.4l-0.1-0.1l-0.3-0.5l-1-1.5l-0.3-0.5l-0.3-0.4
		l-0.7-1.1l-0.2-0.3c-0.6-1-1.2-1.9-1.8-2.8l0,0l-1.7-2.7v-0.1c-0.5-0.8-1.1-1.7-1.6-2.4c0-0.1-0.1-0.1-0.1-0.2l-1.4-2.2v-0.1
		l-0.1-0.1c-0.3-0.5-0.7-1-1-1.5l-0.4-0.6c0-0.1-0.1-0.2-0.2-0.3l-0.2-0.2c-0.3-0.5-0.7-1-1-1.5c-0.8-1.2-1.5-2.3-2.2-3.3l-0.6-0.9
		l-0.2-0.2c-0.2-0.4-0.5-0.7-0.7-1.1l-0.1-0.2l-0.8-1.1l-0.2-0.3l-0.3-0.4c-0.1-0.1-0.1-0.2-0.2-0.2l-0.3-0.4v0.1l-0.3-0.4
		c-0.1-0.2-0.2-0.3-0.4-0.5s-0.2-0.2-0.2-0.3l-0.2-0.4l-0.1-0.1l0,0L631,111l-0.2-0.3l0,0l-0.2-0.3l0,0l0,0l0,0l-0.6-0.1l-2.6-0.5
		l-0.4-0.1l-0.8-0.1h-0.3l0,0h-0.2l-2.5-0.4l-1.3-0.2l-2-0.3l-0.9-0.1l-1.1-0.2l-2-0.3l-2-0.3l-0.8-0.1H613l-1.3-0.2l-2-0.3
		l-1.4-0.2l0,0l-2.9-0.3h-0.1l-0.7-0.1l-0.8-0.1l-3.7-0.4h-0.5h-0.3l-3.6-0.4l-0.9-0.1l-1.3-0.1l-3.4-0.3l-1.6-0.2
		c-1.1-0.1-2.2-0.2-3.4-0.3l-0.9-0.1h-0.5l-3.2-0.3l-1.6-0.1h-0.5l-3.6-0.3l-0.8-0.1l0,0l-1.6-0.1l0,0l-3.5-0.3l-1.6-0.1H567
		l-3.8-0.3l0,0h-0.4l-0.8-0.1l0,0h-0.3L557,103l-1-0.1h-0.2l-1-0.1c-1-0.1-2-0.1-3-0.2l-1.7-0.1l-2.5-0.1l-1.2-0.1h-0.1l-1.5-0.1
		l-2.8-0.1l-2-0.1h-0.3l0,0l-5.2-0.3l-1.4-0.1l-1.6-0.1l-2.2-0.1l0,0l-1.6-0.1l-4-0.2h-1h-0.1l-1.3-0.1l-3.8-0.2h-0.4l-4.4-0.2h-0.3
		H512h-0.9h-0.2l-3.9-0.7l0,0h-1h-0.3h-1.5L501,100h-0.3l-1.8-0.1l-3.3-0.1h-0.1H495l0,0l-2.4-0.1h-1.9l0,0h-0.4h-0.6l-4-0.1l0,0
		h-0.8l0,0l-9.1-0.2h-1l-4-0.1l0,0h-0.6h-0.1l-4.3-0.1l0,0h-2.1h-0.8h-2.1H460l-4.1-0.1h-1.2h-1.1H452l-3.6-0.1h-1.6h-0.4h-2h-0.1
		h-1.2h-2.7h-1.9h-0.2h-3.2h-1.7h-2h-1.6h-6.5v13.6H437c6.1-0.1,11.2,4.7,11.3,10.9v0.4c-0.1,6.2-5.1,11.2-11.3,11.3h-37.3
		c-4.9-0.1-9.2-3.2-10.7-7.8c-0.4-1.1-0.6-2.3-0.6-3.5c0.1-6.2,5.1-11.2,11.3-11.3h14.7V98.5h-5h-4h-2h-4.9h-0.2l-5.8,0.1l0,0h-2
		h-0.3l-6.1,0.1h-0.6h-2h-0.4L377,99h-0.6h-2.1h-0.8h-2.1l0,0l-4.3,0.1h-0.7l-3.9,0.1h-0.9l-4.5,0.1h-0.3l-4.5,0.1h-0.8l-4,0.1h-1.1
		l-2.1,0.1H344h-2h-0.7l0,0l-2.3,0.3l-2.6,0.1h-0.5l-4.6,0.2H330l-3.6,0.1h-0.3h-1.2l0,0l-4.7,0.2h-0.4l-2,0.1l-3.2,0.1h-0.1h-0.6
		h-0.4H313l-3.6,0.2l0,0H309h-0.9h-0.4l0,0h-0.6c-1.1,0-2.1,0.1-3.2,0.2l-1.4,0.1l-5.2,0.3l0,0H297l0,0l-2.4,0.1l-2.4,0.1l0,0
		l-1.5,0.1h-0.1l-1,0.1l-2.7,0.2l0,0l-1.1,0.1h-0.6c-1.3,0.1-2.7,0.1-4,0.2l-1.2,0.1h-0.1l-5,0.3h-0.6h-0.6l-1.8,0.1l-2.1,0.2l0,0
		l-0.9,0.1l-0.7,0.1l-1,0.1l-2.5,0.2l-1.6,0.1l-4.4,0.3h-0.1h-0.4l-4.8,0.4h-0.5l0,0h-0.2h-0.2H252h-0.6l-2.8,0.2l-1.6,0.2l-1.6,0.2
		l-1.8,0.2l-1.3,0.1l-4.6,0.5l-0.5,0.1H237l-3.6,0.4h-0.2l-1,0.1H232l-1.3,0.2l-1.8,0.2l-0.6,0.1l-0.8,0.1l-2.3,0.3l-1,0.1l-0.9,0.1
		l-1.1,0.2l-3,0.4l-1.1,0.2l-1.4,0.2l-1.5,0.2l-0.9,0.1l-0.4,0.1l0,0l-2.7,0.4l0,0l-1.1,0.2c-1.2,0.2-2.4,0.4-3.6,0.6l-0.2,0.3l0,0
		c-3.6,5-50.7,72-70.8,130.8h0.2h0.7h0.4l2-0.1h0.2l7.6-0.2l2-0.1h1.1h1.4h0.2l7.3-0.2h0.8l3.9-0.1h0.6l6.4-0.2h0.6l5.3-0.1l3.4-0.1
		h0.6h1.1h1.1l2.1-0.1h0.3l4.4-0.1h1.4l1.9-0.1l39.2-30.3c1.6-15,4.2-29.8,7.6-44.5c5.7-24.6,17-21.8,17-21.8h69.3
		c0,0,11.4-2.8,17,21.8c3.4,14.7,5.9,29.5,7.6,44.5l34.3,26.6h-0.1l0.1,0.1l0,0l8.4-0.1l0,0h2.5H417l0,0h2.8l0,0h4.9
		c7.7,0,17.1,0.1,27.5,0.2l34.4-26.6c1.6-15,4.2-29.8,7.6-44.5c5.7-24.6,17-21.8,17-21.8h69.3c0,0,11.3-2.8,17,21.8
		c3.4,14.7,5.9,29.5,7.6,44.5l38,29.7l0,0l1.2,1l9.7,0.2l6.4,0.1l3.2,0.1l12.1,0.3l6.3,0.1l6.3,0.2l3.2,0.1l7.8,0.2h0.8h0.6h0.9
		C701.4,241.2,701.3,241.1,701.3,240.9z"
            />
            <path className="st4" d="M475.2,99.3L475.2,99.3z" />
            <path className="st4" d="M253.6,104.5H253H253.6z" />
            <path
                className="st5"
                d="M606.6,239.1c-4.1-33.5-34.6-57.3-68.1-53.3c-27,3.3-48.6,24-52.9,50.9c0,0,0,0,0,0.1l12.6,0.2v-0.1
		c5.3-26.3,30.9-43.3,57.2-38.1c19.9,4,35.2,19.9,38.4,39.9l0,0v0.1L606.6,239.1C606.6,239.3,606.6,239.2,606.6,239.1z"
            />
            <path
                className="st5"
                d="M437.1,112.7h-13.7V98.5l0,0V98c-1.3,0-2.6,0-3.9,0h-4.9v0.5l0,0v14.2h-14.7c-6.2,0.1-11.2,5.1-11.3,11.3
		c0,1.2,0.2,2.4,0.6,3.5c1.5,4.6,5.8,7.7,10.7,7.8h37.3c6.2-0.1,11.2-5.1,11.3-11.3v-0.4C448.3,117.5,443.3,112.6,437.1,112.7
		L437.1,112.7z"
            />
            <path
                d="M614.9,96c-2.3-0.6-4.6-1-6.9-1.3c-49-9.5-168.5-11.9-188-12.3l0,0V69.3c0-0.3-0.3-0.6-0.6-0.6l0,0h-1.9
		c-0.3,0-0.6,0.3-0.6,0.6l0,0v13.1c-9.4,0.1-138.6,2.3-190.1,12.3c0,0-1.2,0.1-3.3,0.5l-0.6,0.3c-6.1,3.4-12.4,8.3-16.7,15.1h0.1
		l0.2-0.3c1.2-0.2,2.4-0.4,3.6-0.6l0,0l1.1-0.2l0,0l2.7-0.4l0,0l0.4-0.1l0.9-0.1l0,0l1.5-0.2l1.4-0.2l0,0l1.1-0.2l0,0l3-0.4l1.1-0.2
		l0,0l0.9-0.1l0,0l3.4-0.4l0,0l0.8-0.1l0.6-0.1l0,0l3-0.4l0,0h0.4l1-0.1l0,0h0.2l3.6-0.4l0,0h0.2l0.5-0.1l0,0l4.6-0.5l0,0l1.3-0.1
		l0,0l1.8-0.2l1.6-0.2l0,0l1.6-0.2l0,0l2.2-0.2l1.2-0.1l0,0l0.9-0.1h0.5l0,0l4.8-0.4l0,0h0.4h0.1l1.5-0.1l2.9-0.2l0,0l-3.6,0.3h-0.4
		l4-0.3l1.6-0.1l0,0l2.5-0.2l1-0.1l0,0l0.7-0.1l0.9-0.1l0,0l2.1-0.2l1.8-0.1l0,0h0.6h0.6l5-0.3h0.1l1.2-0.1c1.3-0.1,2.7-0.2,4-0.2
		h0.6l1.1-0.1l0,0l2.7-0.2l1-0.1h0.1l1.2-0.1h0.3l0,0l2.4-0.1l2.4-0.1l0,0h0.3l0,0l5.2-0.3l1.4-0.1l3.8-0.2l0,0h0.4l1.2-0.1l0,0
		l4-0.2h0.1h0.9h0.1l3.2-0.1l2-0.1h0.4l2.7-0.1l2-0.1l0,0h1.2h0.3l3.6-0.1h1.3l4.6-0.2h0.5l2.6-0.6l2.4-0.1l0,0h0.7h1.8h0.3l2.1-0.1
		h0.1h0.7h0.3l4-0.1h0.8l4.5-0.1h0.3h1l3.6-0.1h0.8l3.9-0.1h0.7h1.2h1.1l-2.3,0.1l0,0l4.3-0.1l0,0c15.7-0.7,30.5-1.1,43.2-1h4.9
		c1.3,0,2.6,0,3.9,0c12.5,0,26.9,0.4,42.2,1l0,0l4.3,0.1h0.1h0.6l0,0l4,0.1h1l9.1,0.2l0,0h0.8l0,0l4,0.1h0.6h0.4l0,0h1.9l2.4,0.1
		l0,0h0.5h0.1l3.3,0.1l1.8,0.1h0.3l3.2,0.1h1.5h0.3h1l0,0l3.8,0.1h0.2h1.2l0,0h0.3l4.4,0.2h0.4l3.8,0.2l1.3,0.1h0.1h1l0,0l4,0.2l0,0
		l1.6,0.1l0,0l2.2,0.1l1.6,0.1l0,0l1.4,0.1l0,0l5.2,0.3l0,0h0.3l0,0l2,0.1l2.8,0.1l0,0l1.5,0.1h0.1l1.2,0.1l2.5,0.1l0,0l1.7,0.1l0,0
		c1,0,2,0.1,3,0.2l1,0.1h0.2l1,0.1l0,0l5,0.3l0,0l1.2,0.1l0,0l3.8,0.3h0.1l1.6,0.1l0,0h0.7l2.8,0.2l0,0l1.6,0.1l0,0l4.4,0.3l0,0h0.5
		l0,0l1.6,0.1l3.2,0.3l0,0h0.5l0.9,0.1l0,0c1.1,0.1,2.2,0.2,3.4,0.3l0,0l1.6,0.2l0,0l3.4,0.3l0,0l1.3,0.1l0,0l0.9,0.1l3.7,0.4l0,0
		h0.3h0.5l0,0l3.7,0.4l0,0l1.4,0.2l0,0l3,0.4l0,0l1.4,0.2l0,0l2,0.3l1.3,0.2h0.1l0.8,0.1l0,0l4.1,0.6l0,0l1.1,0.2l0,0l0.9,0.1l2,0.3
		l0,0l1.3,0.2l0,0l2.5,0.4h0.2l0,0h0.3l0.8,0.1l0,0l3,0.5l0.6,0.1l0,0l0,0l0.2,0.3l0,0C626.7,104.2,620.7,99.4,614.9,96z
		 M475.2,99.3L475.2,99.3l4.8,0.1L475.2,99.3z"
            />
            <path d="M264.9,103.6L264.9,103.6L264.9,103.6z" />
            <path
                className="st1"
                d="M373.5,99h0.8h2.1h0.6l4.1-0.1h0.4h2h0.6l6.1-0.1h0.3h2l0,0l5.8-0.1h0.2h4.9h2h4h5V98h-4.9
		c-11.5,0.1-24.6,0.5-38.3,1l0,0H373.5z"
            />
            <path className="st1" d="M474.6,99.3" />
            <path className="st1" d="M475.2,99.3L475.2,99.3z" />
            <path
                className="st1"
                d="M425.7,98.5h4.2h1.6h2h1.7h3.2h0.2h1.9h2.7h1.2h0.1h2h0.4h1.6l3.6,0.1h1.5h1.1h1.2L460,99h0.6h2.1h0.8h2.1l0,0
		c-8.4-0.3-16.5-0.6-24.1-0.8l-3.3-0.1c-3.4-0.1-6.8-0.1-10-0.2h-4.8v0.5L425.7,98.5L425.7,98.5z"
            />
            <path
                className="st1"
                d="M135.5,241.2h0.1c20.1-58.7,67.1-125.5,70.7-130.5h-0.1c4.3-6.8,10.6-11.7,16.7-15.1
		c-7.6,1.7-22.4,6.2-32.9,18.1c-14.6,16.5-49,62.2-73.6,123.5h0.1C116.5,237.2,128.5,235.2,135.5,241.2z"
            />
            <path
                className="st1"
                d="M720.4,237.2l0.1-0.1c-24.6-61.3-59-107-73.6-123.5s-37.8-18.9-37.8-18.9c-50-9.7-173.4-12-189-12.3l0,0
		c19.5,0.3,138.9,2.7,188,12.3c2.3,0.3,4.6,0.7,6.8,1.3c5.8,3.4,11.8,8.1,15.8,14.6l0,0c0,0.1,0.1,0.2,0.2,0.3l0.2,0.3l0.1,0.1
		l0.2,0.4c0.1,0.1,0.2,0.2,0.2,0.3s0.2,0.3,0.4,0.5l0.3,0.4l0.5,0.7l0.3,0.4l0.2,0.3l0,0c0.1,0.1,0.2,0.3,0.3,0.4l0.5,0.7l0.1,0.2
		l0,0l0,0l0.7,1l0.2,0.2l0.6,0.9l0.4,0.5l1.7,2.5l0.2,0.2c0.3,0.5,0.7,1,1,1.5l0.3,0.5l0.4,0.6l1.1,1.6l0,0v0.1
		c1,1.5,2.1,3.1,3.2,4.8l1.5,2.3l0.3,0.4l0,0l0,0c0.6,0.9,1.2,1.9,1.8,2.8c0.3,0.4,0.6,0.9,0.9,1.3l0.2,0.4l0,0l1.7,2.6l0.9,1.4
		c0.4,0.6,0.8,1.2,1.1,1.8l0.3,0.6c1.8,2.9,3.6,5.9,5.5,9.1c0.8,1.2,1.5,2.5,2.3,3.8l0,0c0.2,0.3,0.3,0.6,0.5,0.9
		c0.8,1.3,1.6,2.7,2.4,4.1c0.4,0.7,0.8,1.4,1.2,2.1c0.1,0.2,0.2,0.3,0.3,0.5l0,0c0.7,1.2,1.4,2.4,2.1,3.6c1.1,2,2.2,4,3.4,6
		c0.5,0.8,0.9,1.6,1.3,2.4l0.3,0.5v0.1c1.5,2.7,2.9,5.4,4.4,8.1l0,0c0.9,1.7,1.8,3.5,2.7,5.2l1.1,2.1c0.4,0.8,0.8,1.6,1.2,2.3l0,0
		c0.7,1.4,1.4,2.7,2,4.1l0,0c0.2,0.4,0.4,0.9,0.6,1.3c0.6,1.2,1.2,2.4,1.8,3.7c0.1,0.1,0.1,0.2,0.2,0.4l0.2,0.5
		c1.2,2.6,2.4,5.1,3.6,7.7l0.7,1.5c0.8,1.8,1.6,3.5,2.3,5.2l0,0c0.3,0.8,0.7,1.5,1,2.3s0.8,1.8,1.2,2.7c0.5,1.4,1.1,2.7,1.6,4
		c0.6,1.6,1.2,3.1,1.8,4.7c0.7,1.9,1.4,3.9,2.1,5.8c0.2,0.5,0.3,0.9,0.5,1.4l0.5,1.3h0.1C708.4,235.2,720.4,237.2,720.4,237.2z"
            />
            <path
                className="st6"
                d="M25.9,593.4c-1.7-8.3-8-56.7-10.8-109.2c-0.1-2-0.2-4-0.3-5.9c-0.9,2.8-1.3,5.8-1.3,8.7v168.3
		c0,16.5,13.4,29.8,29.8,29.8l0,0h45.3c16.5,0,29.8-13.4,29.8-29.8l0,0v-33.2c-45.2-2.8-58.4-6.5-68.5-7.4
		C34.4,613.3,28.7,607.6,25.9,593.4z"
            />
            <path
                className="st6"
                d="M822.2,478.3c-0.1,2-0.2,4-0.3,5.9c-2.7,52.5-9.1,100.9-10.8,109.2c-2.8,14.2-8.5,19.9-24.1,21.3
		c-10.1,0.9-23.3,4.6-68.5,7.4v33.2c0,16.5,13.4,29.8,29.8,29.8l0,0h45.3c16.5,0,29.8-13.4,29.8-29.8l0,0V487
		C823.5,484,823.1,481.1,822.2,478.3z"
            />
            <path
                className="st1"
                d="M819.2,379.6c-11-67.3-39.1-79.8-40.6-80.4v0.1c-2.9,3.1-2.1,4.9-2.1,4.9v0.2c18.8,20,23,48.6,23.3,67.1
		l19.8,10.9C819.5,381.4,819.3,380.5,819.2,379.6z"
            />
            <path
                className="st1"
                d="M60.5,304.4v-0.2c0,0,0.8-1.8-2.1-4.9v-0.1c-2,0.9-29.6,13.8-40.6,80.4c-0.1,0.9-0.3,1.9-0.4,2.8l19.8-10.9
		C37.6,353,41.8,324.4,60.5,304.4z"
            />
            <polygon className="st7" points="177.7,383.8 177.2,383.6 176.9,383.8 	" />
            <polygon className="st7" points="660.1,383.8 659.8,383.6 659.4,383.8 	" />
            <path
                className="st8"
                d="M417.4,396c0.8-0.2,1.6-0.3,2.4-0.3c0.2,0,0.4,0,0.6,0c4.5,0.4,7.9,4.1,7.9,8.6V415c3.5-1.9,5.2-6.2,5.2-12.5
		c0-12.3-8.8-16.8-14.5-16.8l0,0c-5.7,0.1-11.2,2.6-14.9,6.9c-7.8,8.6-7.1,21.9,1.5,29.7c1.2,1.1,2.5,2,3.9,2.8
		c-3-3.4-4.8-7.8-4.9-12.3C404.6,402.5,411.1,397.2,417.4,396z"
            />
            <path
                className="st8"
                d="M428.4,387.8c3.6,2.8,6.5,7.3,6.4,15.4l0,0c-0.1,8.2-3.2,11.9-6.6,13.5l0,0c-2.3,1.1-5.6,1.5-9.6,1.1
		c-6-0.6-10.4-6-9.8-12.1c0.2-1.5,0.6-3,1.4-4.4c-2.8,3.2-4.3,7.3-4.2,11.6c0,5.2,3.1,14.8,15,15.1h1.2c6-0.1,10.9-1.8,14.4-4.9
		c1.1-0.9,1.7-2.3,1.7-3.7v-14.9C438.3,397.5,434.5,391.1,428.4,387.8z"
            />
            <path
                className="st9"
                d="M42,428.8c0.7,4.6,4.2,15.2,9.1,28.4c4.7,12.5,10.5,27.3,16.5,41.4c9,21.2,18.1,40.7,23.2,48.1
		c0.4,0.6,0.9,1.2,1.4,1.8c1.6,1.9,3.6,4,5.7,6.1c9.6,9.7,21.6,19.2,16.3,3.9c-0.7-2.2-1.8-4.8-3.3-8l-0.2-0.5
		c-4.6-9.8-11.3-23.6-18.5-38.4c-8.7-18-17.9-37.7-24.6-54.3c-5.5-13.7-9.3-25.4-9.4-32.2c-0.2-9.7,0.2-19.4,1.4-29.1
		c-4.9-1.4-15.8-6.4-15.8-6.4C42.2,401,40.5,418.6,42,428.8z"
            />
            <path
                className="st9"
                d="M779.6,425c-0.2,6.8-3.9,18.5-9.4,32.2c-6.7,16.6-15.9,36.3-24.6,54.3c-7.2,14.9-13.9,28.6-18.5,38.4l-0.2,0.5
		c-1.5,3.2-2.5,5.8-3.3,8c-5.2,15.4,6.7,5.8,16.3-3.9c2.1-2.1,4-4.2,5.7-6.1c0.5-0.6,1-1.2,1.4-1.8c5.1-7.3,14.2-26.8,23.2-48.1
		c6-14.1,11.9-28.9,16.5-41.4c4.9-13.2,8.4-23.9,9.1-28.4c1.5-10.2-0.2-27.7-1.7-39.2c-2.5,1.6-15.8,6.4-15.8,6.4
		C779.4,405.6,779.9,415.3,779.6,425z"
            />
            <rect className="st2" width="837" height="703" />
            <g className="st10">
                <path
                    className="st11"
                    d="M303.1,101.3L198.7,239.9c11.6-0.3,24.3-0.6,37.6-0.9l105-139.2C329,100.2,316.1,100.7,303.1,101.3z"
                />
                <path
                    className="st11"
                    d="M418.5,98.5c0,0-22.4,0-53.6,0.7l-105,139.3c20.8-0.4,42.3-0.9,62.8-1.2L427.2,98.5
			C421.7,98.5,418.5,98.5,418.5,98.5z"
                />
            </g>
            <path
                d="M106.1,236.3c0.2-1.5,2.1-17.1,2.1-23.1s-1.4-15.4-19.4-16.5s-35,4.9-50,15c-10.1,6.8-9.6,8.8-9.4,11.9
		c0.1,2.1,0.4,4.3,0.9,6.4h0.8c0,0,17.6,4.2,50.6,5.5c0,0-1.5,3.5-2.1,3.5s-38.2-2.9-48-4.3c2.7,7.6,8.7,17,22.4,21.2
		c11.8,3.5,30.5,4.6,38.5,4.9c3-2.9,15.9-14.6,16.4-15.2s6-6.2,7.4-7.6l-7.4-0.2C108.8,237.7,105.9,237.8,106.1,236.3z"
            />
            <path
                className="st9"
                d="M81.8,235.4c-33-1.3-50.6-5.5-50.6-5.5h-0.8c0.3,1.6,0.8,3.2,1.3,4.7c9.8,1.4,47.5,4.3,48,4.3
		S81.8,235.4,81.8,235.4z"
            />
            <path
                d="M798.9,211.6c-15-10.1-32-16.1-50-15s-19.4,10.4-19.4,16.5s1.9,21.6,2.1,23.1s-2.8,1.4-2.8,1.4l-7.4,0.2
		c1.4,1.4,7,7,7.4,7.6s13.3,12.3,16.4,15.2c8.1-0.3,26.7-1.4,38.5-4.9c13.7-4.1,19.7-13.5,22.3-21.2c-9.8,1.4-47.5,4.3-48,4.3
		s-2.1-3.5-2.1-3.5c33-1.3,50.6-5.5,50.6-5.5h0.8c0.4-2.1,0.7-4.2,0.9-6.4C808.5,220.4,809,218.4,798.9,211.6z"
            />
            <path
                className="st9"
                d="M806.6,229.9c0,0-17.6,4.2-50.6,5.5c0,0,1.5,3.5,2.1,3.5s38.2-2.9,48-4.3c0.5-1.5,1-3.1,1.3-4.7H806.6z"
            />
            <path
                className="st9"
                d="M159.6,350.4l-0.7-1.1c0,0-17.5-7.1-55.4-19.9s-42.6-25.1-42.6-25.1c-22.7,24.1-24.1,61-23.2,77.1
		c0.2,3.1,2.4,5.7,6,8.1c0,0,10.9,5,15.8,6.4c18.7,5.2,44.9,7.9,60.6,10.1c26,3.8,35.5-5.7,35.5-5.7l22-16.8l2.2-1.7L159.6,350.4z"
            />
            <path
                className="st9"
                d="M800,381.5c1-16.1-0.5-53-23.2-77.1c0,0-4.7,12.3-42.6,25.1s-55.4,19.9-55.4,19.9l-0.7,1.1L658,381.9l2.2,1.7
		l22,16.8c0,0,9.5,9.5,35.5,5.7c15.7-2.3,42-4.9,60.6-10.1c0,0,13.2-4.8,15.8-6.4C797.6,387.2,799.8,384.5,800,381.5z"
            />
            <path
                className="st1"
                d="M819.6,382.4l-19.8-10.9c0.1,3.8,0,7.2-0.2,10c-0.2,3.1-2.4,5.7-6,8.1c1.5,11.5,3.2,29.1,1.7,39.2
		c-0.7,4.6-4.2,15.2-9.1,28.4c-4.7,12.5-10.5,27.3-16.5,41.4c-9,21.2-18.1,40.7-23.2,48.1c-0.4,0.6-0.9,1.2-1.4,1.8
		c-1.6,1.9-3.6,4-5.7,6.1c-9.6,9.7-21.6,19.2-16.3,3.9c0.7-2.2,1.8-4.8,3.3-8l0.2-0.5c4.6-9.8,11.3-23.6,18.5-38.4
		c8.7-18,17.9-37.7,24.6-54.3c5.5-13.8,9.3-25.4,9.4-32.2c0.2-9.7-0.2-19.4-1.4-29.1c-18.7,5.2-44.9,7.9-60.6,10.1
		c-26,3.8-35.5-5.7-35.5-5.7L660,383.7h-0.7l-10.6,4.2l10.6-4.2l0.5-0.2l-2.2-1.7l20.1-31.5c-5.2,1.5-16.6,4.9-34.1,9.8
		c-14.5,4.1-29.3,7.3-44.2,9.5h-362c-14.9-2.2-29.7-5.3-44.2-9.5c-17.5-4.9-28.9-8.3-34.1-9.8l20.1,31.5l-2.2,1.7l0.5,0.2h-0.8
		L155,400.3c0,0-9.5,9.5-35.5,5.7c-15.7-2.3-42-4.9-60.6-10.1c-1.2,9.7-1.6,19.4-1.4,29.1c0.2,6.8,3.9,18.5,9.4,32.2
		c6.7,16.6,15.9,36.3,24.6,54.3c7.2,14.9,13.9,28.6,18.5,38.4l0.2,0.5c1.5,3.2,2.5,5.8,3.3,8c5.2,15.4-6.7,5.8-16.3-3.9
		c-2.1-2.1-4-4.2-5.7-6.1c-0.5-0.6-1-1.2-1.4-1.8c-5.1-7.3-14.2-26.8-23.2-48.1c-6-14.1-11.9-28.9-16.5-41.4
		c-4.9-13.2-8.4-23.9-9.1-28.4c-1.5-10.2,0.2-27.7,1.7-39.2c-3.6-2.3-5.8-5-6-8.1c-0.2-2.8-0.3-6.2-0.2-10L17,382.3
		c-4,26.7-4.1,61.9-2.6,95.9c0.1,2,0.2,4,0.3,5.9c2.7,52.5,9.1,100.9,10.7,109.2c2.8,14.2,8.5,19.9,24.1,21.3
		c10.1,0.9,23.3,4.6,68.5,7.4h0.1c24.4,1.5,58.2,2.7,106,3c85.5,0.6,154.4,0.4,193.9,0.2c39.5,0.2,108.4,0.3,193.9-0.2
		c47.7-0.3,81.5-1.5,106-3h0.1c45.2-2.8,58.4-6.5,68.5-7.4c15.6-1.4,21.3-7.1,24.1-21.3c1.7-8.3,8-56.7,10.8-109.2
		c0.1-2,0.2-4,0.3-5.9C823.8,444.2,823.6,409.1,819.6,382.4z M438.3,404.4v14.9c0,1.4-0.6,2.8-1.7,3.7c-3.5,3.1-8.4,4.8-14.4,4.9
		H421c-11.9-0.3-15-9.9-15-15.1c-0.1-4.2,1.4-8.4,4.2-11.5c-3,5.3-1.2,12,4,15c1.3,0.8,2.8,1.3,4.4,1.4c4,0.4,7.3,0,9.6-1.1l0,0
		c3.4-1.6,6.5-5.2,6.6-13.5l0,0c0.1-8.1-2.8-12.6-6.4-15.4C434.5,391.1,438.3,397.5,438.3,404.4z M404.1,392.7
		c3.7-4.4,9.2-6.9,14.9-6.9l0,0c5.7,0,14.5,4.5,14.5,16.8c0,6.3-1.8,10.5-5.2,12.5v-10.7c0-4.5-3.4-8.3-7.9-8.6c-0.2,0-0.4,0-0.6,0
		c-0.8,0-1.6,0.1-2.4,0.3c-6.2,1.1-12.8,6.5-12.8,16.8c0.1,4.6,1.8,8.9,4.9,12.3c-10.1-5.6-13.8-18.4-8.2-28.6
		C402.1,395.2,403,393.9,404.1,392.7L404.1,392.7z"
            />
            <path
                className="st12"
                d="M149.9,448.1c1.5,0.2,3.1,0.4,4.6,0.6s3.5,0.4,4.8,0.4c2.9,0.1,6,0.5,9.2,0.5c1.7,0.1,3.3,0,5-0.1
		c1.8-0.1,3.6-0.6,5.3-1.3c1-0.4,1.9-1,2.6-1.7c0.8-0.8,1.5-1.7,2-2.7c0.8-1.8,1-3.8,0.8-5.7c-0.4-3.2-1.2-6.4-2.4-9.4
		c-1.1-2.9-2.5-5.8-4-8.5s-3.2-5.3-5.1-7.8c2.4,5.6,4.4,11.3,5.8,17.3c0.7,2.8,1,5.7,1.1,8.6c0,1.1-0.2,2.2-0.7,3.2
		c-0.5,0.8-1.3,1.3-2.2,1.6c-1.2,0.4-2.4,0.5-3.6,0.5c-1.4,0-2.8,0-4.2-0.2c-2.9-0.3-5.9-0.7-9.1-1c-1.8-0.1-2.9-0.3-4.4-0.5
		s-2.9-0.5-4.4-0.7c-3-0.5-6-1.1-9-1.6c-12-2.2-24.1-4.4-36.2-6.3s-24.3-3.7-36.6-4.8c11.7,4,23.6,7.2,35.5,10.2s23.9,5.6,36,7.8
		C143.8,447.1,146.9,447.6,149.9,448.1z"
            />
            <path
                className="st1"
                d="M819.6,382.4l-19.8-10.9c0.1,3.8,0,7.2-0.2,10c-0.2,3.1-2.4,5.7-6,8.1c1.5,11.5,3.2,29.1,1.7,39.2
		c-0.7,4.6-4.2,15.2-9.1,28.4c-4.7,12.5-10.5,27.3-16.5,41.4c-9,21.2-18.1,40.7-23.2,48.1c2.8-0.5,5.5-0.9,8.3-1.3
		c16.5-2.5,33.1-4.5,49.7-5.4l-48.7,10.8l-16.3,3.8c-9.6,9.7-21.6,19.2-16.3,3.9l-4.6,1.1h-0.1c-3.6,0.9-7.2,1.8-10.9,2.8
		c-7.9,2.2-15.9,4.4-23.6,7c-7.7,2.5-15.2,5.6-22.3,9.4c-7.3,4-14.7,8-22.3,11.5c-3.8,1.8-7.6,3.5-11.5,5.1s-7.9,2.9-12,3.9
		c7.5-3.7,14.3-8.4,21.4-12.7l21-13.4c7.4-4.4,15.2-7.9,23.3-10.6c8-2.8,16-5.2,24.1-7.3c4.2-1.1,8.5-2.1,12.7-3.1h0.2
		c2.6-0.6,5.2-1.2,7.8-1.7l0.2-0.5c4.6-9.8,11.3-23.6,18.5-38.4c8.7-18,17.9-37.7,24.6-54.3c5.5-13.8,9.3-25.4,9.4-32.2
		c0.2-9.7-0.2-19.4-1.4-29.1c-18.7,5.2-44.9,7.9-60.6,10.1c-26,3.8-35.5-5.7-35.5-5.7L660,383.7h-0.7l-10.6,4.2
		c0,0-3.2,65-22.7,89.6s-57.4,92.7-106.6,93.4H317.6c-49.2-0.6-87.1-68.8-106.6-93.4s-22.7-89.6-22.7-89.6l-10.6-4.1h-0.8
		l-21.7,16.6c0,0-9.5,9.5-35.5,5.7c-15.7-2.3-42-4.9-60.6-10.1c-1.2,9.7-1.6,19.4-1.4,29.1c0.2,6.8,3.9,18.5,9.4,32.2
		c6.7,16.6,15.9,36.3,24.6,54.3c7.2,14.9,13.9,28.6,18.5,38.4l0.2,0.5c2.6,0.5,5.2,1.1,7.8,1.7h0.2c4.3,1,8.5,2,12.7,3.1
		c8.1,2,16.2,4.5,24.1,7.3c8.1,2.7,15.9,6.3,23.3,10.6l21,13.4c7.1,4.3,13.9,9,21.4,12.7c-4.1-1-8.1-2.3-12-3.9
		c-3.9-1.6-7.7-3.3-11.5-5.1c-7.6-3.6-15-7.5-22.3-11.5c-7.2-3.7-14.6-6.9-22.3-9.3c-7.8-2.6-15.7-4.8-23.6-7
		c-3.6-1-7.2-1.9-10.9-2.8h-0.1l-4.6-1.1c5.2,15.4-6.7,5.8-16.3-3.9l-16.4-3.8L32.4,540c16.7,0.9,33.2,2.8,49.7,5.4
		c2.8,0.4,5.5,0.9,8.3,1.3c-5.1-7.3-14.2-26.8-23.2-48.1c-6-14.1-11.9-28.9-16.5-41.4c-4.9-13.2-8.4-23.9-9.1-28.4
		c-1.5-10.2,0.2-27.7,1.7-39.2c-3.6-2.3-5.8-5-6-8.1c-0.2-2.8-0.3-6.2-0.2-10l-19.8,10.9c-4,26.7-4.1,61.9-2.6,95.9
		c0.1,2,0.2,4,0.3,5.9c2.7,52.5,9.1,100.9,10.7,109.2c2.8,14.2,8.5,19.9,24.1,21.3c10.1,0.9,23.3,4.6,68.5,7.4h0.1
		c24.4,1.5,58.2,2.7,106,3c85.5,0.6,154.4,0.4,193.9,0.2c39.5,0.2,108.4,0.3,193.9-0.2c47.7-0.3,81.5-1.5,106-3h0.1
		c45.2-2.8,58.4-6.5,68.5-7.4c15.6-1.4,21.3-7.1,24.1-21.3c1.7-8.3,8-56.7,10.8-109.2c0.1-2,0.2-4,0.3-5.9
		C823.8,444.2,823.6,409.1,819.6,382.4z M652.7,438.2c0.4-3.2,1.2-6.4,2.3-9.4c1.1-2.9,2.5-5.8,4-8.5s3.2-5.3,5.1-7.8
		c-2.4,5.6-4.4,11.3-5.8,17.3c-0.7,2.8-1,5.7-1,8.6c0,1.1,0.2,2.2,0.7,3.2c0.5,0.8,1.3,1.3,2.2,1.6c1.2,0.4,2.4,0.5,3.6,0.5
		c1.4,0,2.8,0,4.2-0.2c2.9-0.3,5.9-0.7,9.1-1c1.8-0.1,2.9-0.3,4.3-0.5s2.9-0.5,4.4-0.7c3-0.5,6-1.1,9-1.6c12-2.2,24.1-4.4,36.2-6.3
		s24.3-3.7,36.6-4.8c-11.7,4-23.6,7.2-35.5,10.2s-23.9,5.6-36,7.8c-3,0.5-6.1,1.1-9.1,1.6c-1.5,0.2-3.1,0.4-4.7,0.6
		s-3.5,0.4-4.8,0.4c-2.9,0.1-6,0.5-9.2,0.5c-1.7,0.1-3.3,0-5-0.1c-1.8-0.1-3.6-0.6-5.3-1.3c-1-0.4-1.9-1-2.7-1.7
		c-0.8-0.8-1.5-1.7-2-2.7C652.7,442.1,652.4,440.1,652.7,438.2z M69.3,428.6c12.3,1.1,24.5,2.9,36.6,4.8s24.2,4.1,36.2,6.3
		c3,0.6,6,1.1,9,1.6c1.5,0.3,3,0.5,4.4,0.7s2.6,0.4,4.4,0.5c3.2,0.3,6.2,0.7,9.1,1c1.4,0.1,2.8,0.2,4.2,0.2c1.2,0,2.5-0.2,3.6-0.5
		c0.9-0.2,1.7-0.8,2.2-1.6c0.5-1,0.8-2.1,0.7-3.2c0-2.9-0.4-5.8-1.1-8.6c-1.4-5.9-3.4-11.7-5.8-17.3c1.9,2.5,3.6,5.1,5.1,7.8
		c1.5,2.7,2.9,5.6,4,8.5c1.2,3,2,6.2,2.4,9.4c0.2,1.9,0,3.9-0.8,5.7c-0.5,1-1.2,1.9-2,2.7c-0.8,0.7-1.7,1.3-2.6,1.7
		c-1.7,0.7-3.5,1.1-5.3,1.3c-1.7,0.1-3.3,0.2-5,0.1c-3.2-0.1-6.3-0.4-9.2-0.5c-1.3,0-3.3-0.2-4.8-0.4s-3.1-0.4-4.6-0.6
		c-3.1-0.5-6.1-1-9.1-1.6c-12.1-2.2-24.1-4.9-36-7.8S81,432.5,69.3,428.6z M569.3,606.2l-149.6,0.7h-2.4l-149.6-0.7
		c0,0-21.9,3-47.3-23.7c-27.5-28.8-45.9-55.8-70.5-61.5c-13.7-3.2-24.1-4.3-31.4-7.3c-5.9-2.2-10.4-7-12.1-13l12.1,1.9l112.8,17.9
		c0,0,36,59.6,75.7,60.1s111.4,0.4,111.5,0.4l0,0c0.1,0,71.8,0.1,111.5-0.4s75.7-60.1,75.7-60.1l112.8-18l12.1-1.9
		c-1.7,6-6.2,10.8-12.1,13c-7.4,3.1-17.7,4.2-31.4,7.3c-24.6,5.7-43,32.7-70.5,61.5C591.2,609.2,569.3,606.2,569.3,606.2
		L569.3,606.2z"
            />
            <path
                className="st12"
                d="M118.4,559.6L118.4,559.6c3.7,0.9,7.3,1.8,11,2.8c7.9,2.2,15.9,4.4,23.6,7c7.7,2.5,15.2,5.6,22.3,9.4
		c7.3,4,14.7,8,22.3,11.5c3.8,1.8,7.6,3.5,11.5,5.1s7.9,2.9,12,3.9c-7.5-3.7-14.3-8.4-21.4-12.7l-21-13.4
		c-7.4-4.4-15.2-7.9-23.3-10.6c-8-2.8-16-5.2-24.1-7.3c-4.2-1.1-8.5-2.1-12.7-3.1h-0.2c-2.6-0.6-5.2-1.2-7.8-1.7
		c1.5,3.2,2.5,5.8,3.3,8L118.4,559.6z"
            />
            <path
                className="st12"
                d="M90.4,546.7c-2.8-0.5-5.5-0.9-8.3-1.3c-16.5-2.5-33.1-4.5-49.7-5.4l48.7,10.8l16.3,3.8
		c-2.1-2.1-4-4.2-5.7-6.1C91.3,547.9,90.8,547.3,90.4,546.7z"
            />
            <path
                className="st12"
                d="M718.7,552.1h-0.2c-4.3,1-8.5,2-12.7,3.1c-8.1,2-16.2,4.5-24.1,7.3c-8.1,2.7-15.9,6.3-23.3,10.6l-21,13.4
		c-7.1,4.3-13.9,9-21.4,12.7c4.1-1,8.1-2.3,12-3.9c3.9-1.6,7.7-3.3,11.5-5.1c7.6-3.6,15-7.5,22.3-11.5c7.2-3.7,14.6-6.9,22.3-9.3
		c7.8-2.6,15.7-4.8,23.6-7c3.6-1,7.2-1.9,10.9-2.8h0.1l4.6-1.1c0.7-2.2,1.8-4.8,3.3-8C723.9,551,721.3,551.5,718.7,552.1z"
            />
            <path
                className="st12"
                d="M739.5,554.5l16.3-3.8l48.7-10.8c-16.7,0.9-33.2,2.8-49.7,5.4c-2.8,0.4-5.5,0.9-8.3,1.3
		c-0.4,0.6-0.9,1.2-1.4,1.8C743.6,550.3,741.6,552.4,739.5,554.5z"
            />
            <rect x="279.1" y="445.5" className="st13" width="278.8" height="55.9" />
            <path className="st8" d="M422.2,427.9H421h0.6C421.8,428,422,427.9,422.2,427.9z" />
            <path
                className="st12"
                d="M655.5,446.6c0.8,0.7,1.7,1.3,2.7,1.7c1.7,0.7,3.5,1.1,5.3,1.3c1.7,0.1,3.3,0.2,5,0.1
		c3.2-0.1,6.3-0.4,9.2-0.5c1.3,0,3.3-0.2,4.8-0.4s3.1-0.4,4.7-0.6c3.1-0.5,6.1-1,9.1-1.6c12.1-2.2,24.1-4.9,36-7.8
		s23.8-6.2,35.5-10.2c-12.3,1.1-24.5,2.9-36.6,4.8s-24.2,4.1-36.2,6.3c-3,0.6-6,1.1-9,1.6c-1.5,0.3-3,0.5-4.4,0.7s-2.6,0.4-4.3,0.5
		c-3.2,0.3-6.2,0.7-9.1,1c-1.4,0.1-2.8,0.2-4.2,0.2c-1.2,0-2.5-0.2-3.6-0.5c-0.9-0.2-1.7-0.8-2.2-1.6c-0.5-1-0.8-2.1-0.7-3.2
		c0-2.9,0.4-5.8,1-8.6c1.4-5.9,3.4-11.7,5.8-17.3c-1.9,2.5-3.6,5.1-5.1,7.8c-1.5,2.7-2.9,5.6-4,8.5c-1.2,3-2,6.2-2.3,9.4
		c-0.2,1.9,0,3.9,0.8,5.7C654,444.9,654.7,445.8,655.5,446.6z"
            />
            <path
                className="st14"
                d="M718.5,502.6l-112.8,18c0,0-36,59.6-75.7,60.1S418.6,581,418.5,581l0,0c-0.1,0-71.8,0.1-111.5-0.4
		s-75.7-60.1-75.7-60.1l-112.8-17.9l-12.1-1.9c1.7,6,6.2,10.8,12.1,13c7.4,3.1,17.7,4.2,31.4,7.3c24.6,5.7,43,32.7,70.5,61.5
		c25.4,26.7,47.3,23.7,47.3,23.7l149.6,0.7h2.4l149.6-0.7c0,0,21.9,3,47.3-23.7c27.5-28.8,45.9-55.8,70.5-61.5
		c13.7-3.2,24.1-4.3,31.4-7.3c5.9-2.2,10.4-7,12.1-13L718.5,502.6z"
            />
            <path
                className="st14"
                d="M177.2,383.6l0.5,0.2l10.6,4.1c0,0,3.1,65,22.7,89.6s57.4,92.7,106.6,93.4h201.9
		c49.2-0.6,87.1-68.8,106.6-93.4s22.7-89.6,22.7-89.6l10.6-4.2l0.5-0.2l-2.2-1.7l20.1-31.5c-5.2,1.5-16.6,4.9-34.1,9.8
		c-14.5,4.1-29.3,7.3-44.2,9.5H237.4c-14.9-2.2-29.7-5.3-44.2-9.5c-17.5-4.9-28.9-8.3-34.1-9.8l20.1,31.5L177.2,383.6z M418.6,417.8
		c4,0.4,7.3,0,9.6-1.1l0,0c3.4-1.6,6.5-5.2,6.6-13.5l0,0c0.1-8.1-2.8-12.6-6.4-15.4c6.1,3.3,9.9,9.7,9.9,16.6v14.9
		c0,1.4-0.6,2.8-1.7,3.7c-3.5,3.1-8.4,4.8-14.4,4.9c-0.2,0-0.4,0-0.7,0H421c-11.9-0.3-15-9.9-15-15.1c-0.1-4.2,1.4-8.4,4.2-11.5
		c-3,5.3-1.2,12,4,15C415.6,417.1,417.1,417.6,418.6,417.8L418.6,417.8z M433.5,402.6c0,6.3-1.8,10.5-5.2,12.5v-10.7
		c0-4.5-3.4-8.3-7.9-8.6c-0.2,0-0.4,0-0.6,0c-0.8,0-1.6,0.1-2.4,0.3c-6.2,1.1-12.8,6.5-12.8,16.8c0.1,4.6,1.8,8.9,4.9,12.3
		c-10.1-5.6-13.8-18.4-8.2-28.6c0.8-1.4,1.7-2.7,2.8-3.9c3.7-4.4,9.2-6.9,14.9-7l0,0C424.7,385.8,433.5,390.3,433.5,402.6z
		 M557.9,501.5H279.1v-55.9h278.8V501.5z"
            />
            <path
                className="st1"
                d="M778.6,299.1L778.6,299.1c-2.4-6.6-11.9-13.7-11.9-13.7c0.5-9-21.3-24.1-21.3-24.1h0.1
		c-0.1-0.1-0.3-0.3-0.6-0.6c-3-2.9-15.9-14.6-16.4-15.2s-6-6.2-7.4-7.6l-0.3-0.3l0,0l-0.2-0.5l-0.1,0.1c0,0-12-2-19,4h-0.1
		c0,0.1,0,0.1,0.1,0.2h-0.9H700h-0.8l-7.8-0.2l-3.2-0.1l-6.3-0.2l-6.3-0.1l-12.1-0.3l-3.2-0.1l-6.4-0.1l-9.7-0.2l-4.4-0.1l-0.4,0.1
		l-32.8-0.8l-12.7-0.3c-30.9-0.7-64.8-1.4-95.7-1.9l-12.6-0.2c-11.8-0.2-23.1-0.4-33.4-0.5h0.1c-10.5-0.1-19.8-0.2-27.5-0.2h-4.9
		l0,0h-2.8l0,0h-21.4h-2.5l0,0l-8.4,0.1l0,0h-4.4l0,0l-4.5,0.1l0,0h-3.5l0,0l-6,0.1h-0.1h-1.5l-5.1,0.1h-0.2l-5.1,0.1l0,0l-5.2,0.1
		h-0.4l-5.2,0.1h-0.1h-3h-0.9L332,237l0,0l-17.5,0.3h-0.2l-8.9,0.2l-8.9,0.2h-0.3l-4.8,0.1h-1.8l-3.4,0.1l-2,0.3h-1.1l-13.8,0.3
		h-0.8l-5.3,0.1H263l-16,0.3h-1l-2.2,0.1h-0.6l-5.8,0.1h-0.5L234,239h-0.1l-6.1,0.1h-0.3l-15.2,0.4l-11.3,0.3h-0.4h-1.8h-1.3
		l-4.9,0.1l-1.9,0.1h-1.4H185h-0.3l-2.1,0.1h-1.1h-1.1h-0.6l-3.4,0.1l-5.3,0.1h-0.6l-3.5,0.1h-0.3l-2.6,0.1h-0.5h-0.7h-0.1l-3,0.1
		H159l-2.5,0.1h-0.8h-0.9l-1.8,0.1h-0.1h-1.3h-1.9h-0.5l-2.4,0.1l-6.6,0.2H140h-0.8H139h-1.3h-1.2h-0.7h-0.2c0-0.1,0-0.1,0.1-0.2
		h-0.1c-7-6-19-4-19-4h-0.1l-0.2,0.4l0,0l0,0l0,0l-0.3,0.3c-1.4,1.4-7,7-7.4,7.6s-13.4,13-16.4,15.9c-0.3,0.3-0.5,0.5-0.6,0.6h0.1
		c0,0-21.8,15.1-21.3,24.1c0,0-9.5,7.1-11.8,13.7h-0.1v0.1c2.9,3.1,2.1,4.9,2.1,4.9v0.2l0.1-0.1c0,0,4.7,12.3,42.6,25.1
		s55.4,19.9,55.4,19.9l0.7,1.1c5.2,1.5,16.6,4.9,34.1,9.8c14.5,4.1,29.3,7.3,44.2,9.5h362.1c14.9-2.2,29.7-5.3,44.2-9.5
		c17.5-4.9,28.9-8.3,34.1-9.8l0.7-1.1c0,0,17.5-7.1,55.4-19.9s42.6-25.1,42.6-25.1l0,0v-0.2c0,0-0.8-1.8,2.1-4.9L778.6,299.1z"
            />
            <path
                className="st1"
                d="M60.5,304.2v0.2l0.1-0.1c0,0,4.7,12.3,42.6,25.1s55.4,19.9,55.4,19.9l0.7,1.1c5.2,1.5,16.6,4.9,34.1,9.8
		c14.5,4.1,29.3,7.3,44.2,9.5h362.1c14.9-2.2,29.7-5.3,44.2-9.5c17.5-4.9,28.9-8.3,34.1-9.8l0.7-1.1c0,0,17.5-7.1,55.4-19.9
		s42.6-25.1,42.6-25.1l0,0v-0.2c0,0-0.8-1.8,2.1-4.9v-0.1h-0.1c-2.4-6.6-11.8-13.7-11.8-13.7c0.5-9-21.3-24.1-21.3-24.1h0.1
		c-0.1-0.1-0.3-0.3-0.6-0.6c-3-2.9-15.9-14.6-16.4-15.2s-6-6.2-7.4-7.6l-0.3-0.3l0,0l-0.2-0.5l-0.1,0.1c0,0-12-2-19,4h-0.1
		c0,0.1,0,0.1,0.1,0.2h-0.9H700h-0.8l-7.8-0.2l-3.2-0.1l-6.3-0.2l-6.3-0.1l-12.1-0.3l-3.2-0.1l-6.4-0.1l-9.7-0.2l-4.4-0.1l-0.4,0.1
		l-32.8-0.8l-12.7-0.3c-30.9-0.7-64.8-1.4-95.7-1.9l-12.6-0.2c-11.8-0.2-23.1-0.4-33.4-0.5h0.1c-10.5-0.1-19.8-0.2-27.5-0.2h-4.9
		l0,0h-2.8l0,0h-21.4h-2.5l0,0l-8.4,0.1l0,0h-4.4l0,0l-4.5,0.1l0,0h-3.5l0,0l-6,0.1h-0.1h-1.5l-5.1,0.1h-0.2l-5.1,0.1l0,0l-5.2,0.1
		h-0.4l-5.2,0.1h-0.1h-3h-0.9L332,237l0,0l-17.5,0.3h-0.2l-8.9,0.2l-8.9,0.2h-0.3l-4.8,0.1h-1.8l-3.4,0.1l-2,0.3h-1.1l-13.8,0.3
		h-0.8l-5.3,0.1H263l-16,0.3h-1l-2.2,0.1h-0.6l-5.8,0.1h-0.5L234,239h-0.1l-6.1,0.1h-0.3l-15.2,0.4l-11.3,0.3h-0.4h-1.8h-1.3
		l-4.9,0.1l-1.9,0.1h-1.4H185h-0.3l-2.1,0.1h-1.1h-1.1h-0.6l-3.4,0.1l-5.3,0.1h-0.6l-3.5,0.1h-0.3l-2.6,0.1h-0.5h-0.7h-0.1l-3,0.1
		H159l-2.5,0.1h-0.8h-0.9l-1.8,0.1h-0.1h-1.3h-1.9h-0.5l-2.4,0.1l-6.6,0.2H140h-0.8H139h-1.3h-1.2h-0.7h-0.2c0-0.1,0-0.1,0.1-0.2
		h-0.1c-7-6-19-4-19-4h-0.1l-0.2,0.4l0,0l0,0l0,0l-0.3,0.3c-1.4,1.4-7,7-7.4,7.6s-13.4,13-16.4,15.9c-0.3,0.3-0.5,0.5-0.6,0.6h0.1
		c0,0-21.8,15.1-21.3,24.1c0,0-9.5,7.1-11.8,13.7h-0.1v0.1C61.3,302.4,60.5,304.2,60.5,304.2z"
            />
            <path
                className="st12"
                d="M254.1,279.9c-0.8-1.5-1.9-2.9-3-4.2c-0.6-0.7-1.2-1.3-1.9-1.9c-0.6-0.6-1.3-1.1-2-1.6
		c-5.2-3.9-10.5-7.5-16.1-10.8s-11.6-6-17.8-8.1c-3.1-1-6.3-1.8-9.6-2.3c-3.2-0.5-6.5-0.7-9.8-0.6c13,1.2,24.8,6.9,35.1,14
		c5.2,3.5,10.2,7.3,15,11.4c4.6,3.6,6.8,8.9,8,14.6c2.2,11.5,1.1,23.8-0.8,35.7c-0.9,6-2.1,12-3.4,18l-0.5,2.3l-0.2,1.2l-0.1,0.6
		c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.5-0.3,0.7-1.1,1.1c-1.6,0.8-4.7,0.8-7.6,0.5c-6.1-0.7-12.1-2.2-17.8-4.5l-1.1-0.4l-0.9-0.5l-1.8-0.9
		l-3.9-2.5c-2.6-1.7-5.2-3.5-7.8-5.3c-10.4-7.2-20.6-14.8-30.9-22.3c-20.6-15-41.1-30.1-62-44.8c18.9,16.9,38.4,33.2,58.3,49.2
		c9.9,8,20,15.7,30.4,23.4c2.6,1.9,5.2,3.7,7.9,5.6l4.1,2.7l2.5,1.3l1.2,0.6l1.2,0.5c6.3,2.5,13,4.3,19.7,5.1
		c1.8,0.2,3.6,0.2,5.5,0.2c2.1-0.1,4.2-0.5,6.1-1.4c1.2-0.5,2.2-1.2,3.1-2.1c0.4-0.5,0.8-1.1,1.2-1.7c0.3-0.6,0.6-1.2,0.7-1.8
		l0.3-0.9l0.1-0.5l0.2-1.1l0.4-2.2c1.2-6.1,2.4-12.2,3.3-18.4c0.9-6.2,1.3-12.5,1.4-18.8c0.2-6.4-0.5-12.8-2-19.1
		C257,286,255.7,282.9,254.1,279.9z"
            />
            <path
                className="st12"
                d="M620.5,351.5l1.2-0.6l2.5-1.3l4.2-2.7c2.7-1.8,5.3-3.7,7.9-5.6c10.3-7.6,20.4-15.4,30.4-23.4
		c19.9-15.9,39.4-32.2,58.4-49.1c-20.9,14.7-41.5,29.8-62,44.8c-10.3,7.5-20.5,15.1-31,22.3c-2.6,1.8-5.2,3.6-7.8,5.3l-3.9,2.5
		l-1.8,0.9l-0.9,0.5l-1.1,0.4c-5.7,2.2-11.7,3.7-17.8,4.5c-2.8,0.3-6,0.3-7.6-0.5c-0.8-0.4-1-0.5-1.1-1.1c0-0.1-0.1-0.1-0.1-0.2
		l-0.1-0.6l-0.2-1.2l-0.5-2.3c-1.3-6-2.5-12-3.4-18c-1.9-12-3-24.2-0.8-35.7c1.2-5.7,3.5-11,8-14.6c4.8-4.1,9.8-7.9,15-11.4
		c10.4-7,22.1-12.8,35.1-14c-3.3-0.1-6.5,0.2-9.8,0.6c-3.2,0.5-6.4,1.3-9.5,2.3c-6.2,2.1-12.1,4.8-17.8,8.1
		c-5.6,3.3-11,6.9-16.1,10.8c-0.7,0.5-1.4,1-2,1.6c-0.7,0.6-1.3,1.2-1.9,1.9c-1.1,1.3-2.2,2.7-3,4.2c-1.6,2.9-2.9,6-3.7,9.3
		c-1.5,6.2-2.2,12.7-2,19.1c0.1,6.3,0.6,12.6,1.4,18.8c0.8,6.2,2,12.4,3.3,18.4l0.4,2.2l0.2,1.1l0.1,0.5l0.3,0.9
		c0.2,0.6,0.4,1.2,0.7,1.8c0.4,0.6,0.8,1.1,1.2,1.7c0.9,0.9,1.9,1.6,3,2.1c1.9,0.8,4,1.3,6.1,1.4c1.8,0.1,3.7,0,5.5-0.2
		c6.8-0.9,13.4-2.6,19.7-5.1L620.5,351.5z"
            />
        </g>
        <g id="avant-capot-avant">
            <path
                className="st1"
                d="M778.6,299.1L778.6,299.1c-2.4-6.6-11.9-13.7-11.9-13.7c0.5-9-21.3-24.1-21.3-24.1h0.1
		c-0.1-0.1-0.3-0.3-0.6-0.6c-3-2.9-15.9-14.6-16.4-15.2s-6-6.2-7.4-7.6l-0.3-0.3l0,0l-0.2-0.5l-0.1,0.1c0,0-12-2-19,4h-0.1
		c0,0.1,0,0.1,0.1,0.2h-0.9H700h-0.8l-7.8-0.2l-3.2-0.1l-6.3-0.2l-6.3-0.1l-12.1-0.3l-3.2-0.1l-6.4-0.1l-9.7-0.2l-4.4-0.1l-0.4,0.1
		l-32.8-0.8l-12.7-0.3c-30.9-0.7-64.8-1.4-95.7-1.9l-12.6-0.2c-11.8-0.2-23.1-0.4-33.4-0.5h0.1c-10.5-0.1-19.8-0.2-27.5-0.2h-4.9
		l0,0h-2.8l0,0h-21.4h-2.5l0,0l-8.4,0.1l0,0h-4.4l0,0l-4.5,0.1l0,0h-3.5l0,0l-6,0.1h-0.1h-1.5l-5.1,0.1h-0.2l-5.1,0.1l0,0l-5.2,0.1
		h-0.4l-5.2,0.1h-0.1h-3h-0.9L332,237l0,0l-17.5,0.3h-0.2l-8.9,0.2l-8.9,0.2h-0.3l-4.8,0.1h-1.8l-3.4,0.1l-2,0.3h-1.1l-13.8,0.3
		h-0.8l-5.3,0.1H263l-16,0.3h-1l-2.2,0.1h-0.6l-5.8,0.1h-0.5L234,239h-0.1l-6.1,0.1h-0.3l-15.2,0.4l-11.3,0.3h-0.4h-1.8h-1.3
		l-4.9,0.1l-1.9,0.1h-1.4H185h-0.3l-2.1,0.1h-1.1h-1.1h-0.6l-3.4,0.1l-5.3,0.1h-0.6l-3.5,0.1h-0.3l-2.6,0.1h-0.5h-0.7h-0.1l-3,0.1
		H159l-2.5,0.1h-0.8h-0.9l-1.8,0.1h-0.1h-1.3h-1.9h-0.5l-2.4,0.1l-6.6,0.2H140h-0.8H139h-1.3h-1.2h-0.7h-0.2c0-0.1,0-0.1,0.1-0.2
		h-0.1c-7-6-19-4-19-4h-0.1l-0.2,0.4l0,0l0,0l0,0l-0.3,0.3c-1.4,1.4-7,7-7.4,7.6s-13.4,13-16.4,15.9c-0.3,0.3-0.5,0.5-0.6,0.6h0.1
		c0,0-21.8,15.1-21.3,24.1c0,0-9.5,7.1-11.8,13.7h-0.1v0.1c2.9,3.1,2.1,4.9,2.1,4.9v0.2l0.1-0.1c0,0,4.7,12.3,42.6,25.1
		s55.4,19.9,55.4,19.9l0.7,1.1c5.2,1.5,16.6,4.9,34.1,9.8c14.5,4.1,29.3,7.3,44.2,9.5h362.1c14.9-2.2,29.7-5.3,44.2-9.5
		c17.5-4.9,28.9-8.3,34.1-9.8l0.7-1.1c0,0,17.5-7.1,55.4-19.9s42.6-25.1,42.6-25.1l0,0v-0.2c0,0-0.8-1.8,2.1-4.9L778.6,299.1z"
            />
            <path
                className="st15"
                d="M778.6,299.1L778.6,299.1c-2.4-6.6-11.9-13.7-11.9-13.7c0.5-9-21.3-24.1-21.3-24.1h0.1
		c-0.1-0.1-0.3-0.3-0.6-0.6c-3-2.9-15.9-14.6-16.4-15.2s-6-6.2-7.4-7.6l-0.3-0.3l0,0l-0.2-0.5l-0.1,0.1c0,0-12-2-19,4h-0.1
		c0,0.1,0,0.1,0.1,0.2h-0.9H700h-0.8l-7.8-0.2l-3.2-0.1l-6.3-0.2l-6.3-0.1l-12.1-0.3l-3.2-0.1l-6.4-0.1l-9.7-0.2l-4.4-0.1l-0.4,0.1
		l-32.8-0.8l-12.7-0.3c-30.9-0.7-64.8-1.4-95.7-1.9l-12.6-0.2c-11.8-0.2-23.1-0.4-33.4-0.5h0.1c-10.5-0.1-19.8-0.2-27.5-0.2h-4.9
		l0,0h-2.8l0,0h-21.4h-2.5l0,0l-8.4,0.1l0,0h-4.4l0,0l-4.5,0.1l0,0h-3.5l0,0l-6,0.1h-0.1h-1.5l-5.1,0.1h-0.2l-5.1,0.1l0,0l-5.2,0.1
		h-0.4l-5.2,0.1h-0.1h-3h-0.9L332,237l0,0l-17.5,0.3h-0.2l-8.9,0.2l-8.9,0.2h-0.3l-4.8,0.1h-1.8l-3.4,0.1l-2,0.3h-1.1l-13.8,0.3
		h-0.8l-5.3,0.1H263l-16,0.3h-1l-2.2,0.1h-0.6l-5.8,0.1h-0.5L234,239h-0.1l-6.1,0.1h-0.3l-15.2,0.4l-11.3,0.3h-0.4h-1.8h-1.3
		l-4.9,0.1l-1.9,0.1h-1.4H185h-0.3l-2.1,0.1h-1.1h-1.1h-0.6l-3.4,0.1l-5.3,0.1h-0.6l-3.5,0.1h-0.3l-2.6,0.1h-0.5h-0.7h-0.1l-3,0.1
		H159l-2.5,0.1h-0.8h-0.9l-1.8,0.1h-0.1h-1.3h-1.9h-0.5l-2.4,0.1l-6.6,0.2H140h-0.8H139h-1.3h-1.2h-0.7h-0.2c0-0.1,0-0.1,0.1-0.2
		h-0.1c-7-6-19-4-19-4h-0.1l-0.2,0.4l0,0l0,0l0,0l-0.3,0.3c-1.4,1.4-7,7-7.4,7.6s-13.4,13-16.4,15.9c-0.3,0.3-0.5,0.5-0.6,0.6h0.1
		c0,0-21.8,15.1-21.3,24.1c0,0-9.5,7.1-11.8,13.7h-0.1v0.1c2.9,3.1,2.1,4.9,2.1,4.9v0.2l0.1-0.1c0,0,4.7,12.3,42.6,25.1
		s55.4,19.9,55.4,19.9l0.7,1.1c5.2,1.5,16.6,4.9,34.1,9.8c14.5,4.1,29.3,7.3,44.2,9.5h362.1c14.9-2.2,29.7-5.3,44.2-9.5
		c17.5-4.9,28.9-8.3,34.1-9.8l0.7-1.1c0,0,17.5-7.1,55.4-19.9s42.6-25.1,42.6-25.1l0,0v-0.2c0,0-0.8-1.8,2.1-4.9L778.6,299.1z"
            />
        </g>
        <g id="avant-pare-choc-avant">
            <path
                className="st1"
                d="M659.8,383.6l-2.2-1.7l20.1-31.5c-5.2,1.5-16.6,4.9-34.1,9.8c-14.5,4.1-29.3,7.3-44.2,9.5h-362
		c-14.9-2.2-29.7-5.3-44.2-9.5c-17.5-4.9-28.9-8.3-34.1-9.8l20.1,31.5l-2.2,1.7l0.5,0.2h-0.8L155,400.4c0,0-9.5,9.5-35.5,5.7
		c-15.7-2.3-42-4.9-60.6-10.1c-1.2,9.7-1.6,19.4-1.4,29.1c0.2,6.8,3.9,18.5,9.4,32.2c6.7,16.6,15.9,36.3,24.6,54.3
		c7.2,14.9,13.9,28.6,18.5,38.4l0.2,0.5c1.5,3.2,2.5,5.8,3.3,8c5.2,15.4-6.7,5.8-16.3-3.9c-2.1-2.1-4-4.2-5.7-6.1
		c-0.5-0.6-1-1.2-1.4-1.8c-5.1-7.3-14.2-26.8-23.2-48.1c-6-14.1-11.9-28.9-16.5-41.4c-4.9-13.2-8.4-23.9-9.1-28.4
		c-1.5-10.2,0.2-27.7,1.7-39.2c-3.6-2.3-5.8-5-6-8.1c-0.2-2.8-0.3-6.2-0.2-10L17,382.4c-4,26.7-4.1,61.9-2.6,95.9
		c0.1,2,0.2,4,0.3,5.9c2.7,52.5,9.1,100.9,10.7,109.2c2.8,14.2,8.5,19.9,24.1,21.3c10.1,0.9,23.3,4.6,68.5,7.4h0.1
		c24.4,1.5,58.2,2.7,106,3c85.5,0.6,154.4,0.4,193.9,0.2c39.5,0.2,108.4,0.3,193.9-0.2c47.7-0.3,81.5-1.5,106-3h0.1
		c45.2-2.8,58.4-6.5,68.5-7.4c15.6-1.4,21.3-7.1,24.1-21.3c1.7-8.3,8-56.7,10.8-109.2c0.1-2,0.2-4,0.3-5.9c1.6-34,1.5-69.2-2.6-95.9
		l-19.8-10.9c0.1,3.8,0,7.2-0.2,10c-0.2,3.1-2.4,5.7-6,8.1c1.5,11.5,3.2,29.1,1.7,39.2c-0.7,4.6-4.2,15.2-9.1,28.4
		c-4.7,12.5-10.5,27.3-16.5,41.4c-9,21.2-18.1,40.7-23.2,48.1c-0.4,0.6-0.9,1.2-1.4,1.8c-1.6,1.9-3.6,4-5.7,6.1
		c-9.6,9.7-21.6,19.2-16.3,3.9c0.7-2.2,1.8-4.8,3.3-8l0.2-0.5c4.6-9.8,11.3-23.6,18.5-38.4c8.7-18,17.9-37.7,24.6-54.3
		c5.5-13.8,9.3-25.4,9.4-32.2c0.2-9.7-0.2-19.4-1.4-29.1c-18.7,5.2-44.9,7.9-60.6,10.1c-26,3.8-35.5-5.7-35.5-5.7l-21.7-16.6
		L659.8,383.6z"
            />
            <path
                className="st2"
                d="M727.3,507.2c0.5-0.6,0.9-1.2,1.3-1.9c0.4-0.7,0.8-1.4,1.1-2.2c0.4-0.8,0.7-1.6,0.9-2.4l-12.1,1.9l-112.8,17.9
		c0,0-36,59.6-75.7,60.1h-2.3H527h-1.8h-0.7h-4.8H519h-2h-0.5h-2.3h-0.7H511h-0.4h-2.5h-0.4h-5.5h-3.6h-5.9h-3.4h-5.7H353.3h-5.7
		H344h-5.7H332h-2.7h-3.5h-2.3h-0.7h-2.1h-0.9H318h-0.7h-4.8h-0.9H310h-0.7H307c-6.9-0.2-13.8-1.9-20-4.9c-1.8-0.8-3.5-1.7-5.2-2.7
		c-1.1-0.6-2.3-1.3-3.4-2c-1.7-1.1-3.3-2.2-5-3.4c-0.5-0.4-1.1-0.8-1.6-1.2c-4.2-3.3-8.3-6.8-12.1-10.5c-1-0.9-1.9-1.8-2.8-2.8
		s-1.8-1.9-2.7-2.8c-1.3-1.4-2.6-2.8-3.8-4.2s-2.3-2.8-3.5-4.1c-2.6-3.2-5-6.2-7-8.9c-0.6-0.8-1.1-1.5-1.7-2.3
		c-4.5-6.2-7-10.4-7-10.4l-112.8-17.9l-12.1-1.9c0.3,0.8,0.6,1.6,0.9,2.4c0.3,0.7,0.7,1.5,1.1,2.2c1.3,2.2,3,4.2,5.1,5.7
		c0.4,0.3,0.7,0.5,1.1,0.8s1.1,0.6,1.6,0.9l0.4,0.2c0.6,0.3,1.1,0.6,1.8,0.8c1.2,0.5,2.4,0.9,3.7,1.3s2.7,0.8,4.1,1.1
		c1.2,0.3,2.4,0.5,3.6,0.8c4.4,0.9,9.4,1.8,15,3c1.6,0.3,3.3,0.7,5,1.1c2.3,0.5,4.6,1.3,6.8,2.1c0.7,0.3,1.5,0.6,2.2,1
		c1.5,0.7,2.9,1.4,4.3,2.2c2.2,1.2,4.3,2.6,6.3,4.1c1.4,1,2.8,2.1,4.2,3.2c2.1,1.7,4.2,3.6,6.3,5.5c1,0.9,2,1.9,3,2.9
		c0.7,0.7,1.4,1.4,2.1,2.1c1.1,1.1,2.1,2.1,3.1,3.2l2.1,2.2c1.3,1.4,2.7,2.9,4.1,4.4l2.4,2.6c2.2,2.4,4.4,4.9,6.7,7.5
		c4.6,5.1,9.3,10.4,14.3,15.8l2.6,2.7l0,0c0.7,0.8,1.4,1.5,2.2,2.2c0.2,0.2,0.5,0.5,0.7,0.7c0.5,0.5,1,1,1.5,1.4l0.8,0.7
		c0.5,0.4,0.9,0.9,1.4,1.3l0.7,0.7l1.6,1.4l0.5,0.4c0.7,0.6,1.4,1.1,2.1,1.6l0.5,0.4l1.5,1.1l0.7,0.5l1.3,0.9l0.7,0.5l1.4,0.9
		l0.6,0.4c0.6,0.4,1.3,0.8,1.9,1.1l0.2,0.1c0.6,0.3,1.1,0.6,1.7,0.9l0.6,0.3l1.2,0.6l0.7,0.3l1.2,0.5l0.6,0.3
		c0.5,0.2,0.9,0.4,1.4,0.6l0.4,0.2c0.6,0.2,1.1,0.4,1.6,0.6l0.5,0.2l1.1,0.4l0.6,0.2l1,0.3l0.5,0.2c0.4,0.1,0.7,0.2,1,0.3l0.4,0.1
		l1.4,0.3h0.2l1.1,0.2l0.4,0.1c0.3,0.1,0.5,0.1,0.8,0.2l0.4,0.1l0.8,0.1h0.4l0.9,0.1h0.2l1,0.1h0.3h0.6h0.3h0.5h2.6h0.3h0.3
		l149.6,0.7h2.4l149.6-0.7h0.3h0.3h2.5h0.5h0.3h0.6h0.3l1-0.1h0.2l0.9-0.1h0.4l0.8-0.1l0.4-0.1l0.8-0.2l0.4-0.1l1.1-0.2h0.2
		c0.5-0.1,0.9-0.2,1.4-0.3l0.4-0.1l1.1-0.3l0.5-0.2l1-0.3l0.5-0.2l1.2-0.4l0.4-0.2l1.7-0.6l0.3-0.1l1.4-0.6l0.6-0.3
		c0.4-0.2,0.8-0.4,1.2-0.6l0.6-0.3l1.2-0.6l0.6-0.3l1.7-1l0.1-0.1c0.6-0.4,1.3-0.7,1.9-1.1l0.6-0.4c0.5-0.3,0.9-0.6,1.4-0.9l0.7-0.5
		c0.4-0.3,0.9-0.6,1.3-0.9l0.7-0.5c0.5-0.4,1-0.7,1.5-1.1c0.2-0.1,0.3-0.2,0.5-0.4c0.7-0.5,1.4-1.1,2.1-1.6l0.5-0.4
		c0.5-0.5,1.1-0.9,1.6-1.4l0.7-0.6c0.5-0.4,1-0.8,1.4-1.3l0.8-0.7c0.5-0.5,1-0.9,1.5-1.4l0.7-0.7c0.7-0.7,1.4-1.5,2.2-2.2l0,0
		l2.5-2.7c5-5.4,9.8-10.7,14.3-15.8c2.3-2.5,4.5-5,6.7-7.5s4.3-4.8,6.5-7c1.4-1.5,2.8-3,4.2-4.4s2.8-2.8,4.2-4.2
		c2.8-2.7,5.5-5.2,8.3-7.4c5.1-4.3,10.8-7.9,17-10.5c2.2-0.9,4.5-1.6,6.8-2.1c1.7-0.4,3.4-0.8,5-1.1c5.6-1.2,10.6-2.1,15-3
		c1.2-0.3,2.5-0.5,3.6-0.8c0.7-0.2,1.4-0.3,2.1-0.5c0.9-0.2,1.8-0.5,2.6-0.8c1.1-0.3,2.1-0.7,3-1.1c0.5-0.2,1.1-0.5,1.6-0.7
		s1-0.5,1.5-0.8c0.9-0.5,1.8-1.2,2.7-1.9C725.3,509.5,726.4,508.4,727.3,507.2z M716.6,509.1c-4.3,1.8-9.9,2.9-17.1,4.3
		c-4,0.8-8.6,1.7-13.5,2.8c-20.6,4.8-36.8,22.9-55.6,44c-5.5,6.2-11.2,12.5-17.4,19c-19.6,20.6-36.8,22.2-41.6,22.2
		c-0.9,0-1.4,0-1.4,0l0,0c-0.2,0-0.4,0-0.7,0l0,0l-149.6,0.7h-2.3l-149.6-0.7l0,0c-0.2,0-0.4,0-0.6,0c-0.5,0-1,0.1-1.5,0.1
		c-4.8,0-22-1.6-41.6-22.2c-6.2-6.5-11.9-12.8-17.4-19c-18.8-21-35.1-39.2-55.6-44c-5-1.2-9.5-2-13.5-2.8
		c-7.2-1.4-12.8-2.5-17.1-4.3c-1.1-0.4-2.1-1-3.1-1.6l0.4,0.1l110.6,17.6c6.9,10.8,40.4,60,78.7,60.5c26.9,0.3,68.9,0.4,91.5,0.4
		h40.1c22.6,0,64.6,0,91.5-0.4c38.2-0.5,71.8-49.7,78.7-60.5l110.6-17.6l0.4-0.1C718.7,508.1,717.7,508.7,716.6,509.1z"
            />
            <path
                className="st2"
                d="M279.1,440.5h278.8c2.8,0,5,2.2,5,5v55.9c0,2.8-2.2,5-5,5H279.1c-2.8,0-5-2.2-5-5v-55.9
		C274.1,442.8,276.4,440.5,279.1,440.5z"
            />
            <path
                className="st15"
                d="M819.6,382.4l-19.8-10.9c0.1,3.8,0,7.2-0.2,10c-0.2,3.1-2.4,5.7-6,8.1c1.5,11.5,3.2,29.1,1.7,39.2
		c-0.7,4.6-4.2,15.2-9.1,28.4c-4.7,12.5-10.5,27.3-16.5,41.4c-9,21.2-18.1,40.7-23.2,48.1c-0.4,0.6-0.9,1.2-1.4,1.8
		c-1.6,1.9-3.6,4-5.7,6.1c-9.6,9.7-21.6,19.2-16.3,3.9c0.7-2.2,1.8-4.8,3.3-8l0.2-0.5c4.6-9.8,11.3-23.6,18.5-38.4
		c8.7-18,17.9-37.7,24.6-54.3c5.5-13.8,9.3-25.4,9.4-32.2c0.2-9.7-0.2-19.4-1.4-29.1c-18.7,5.2-44.9,7.9-60.6,10.1
		c-26,3.8-35.5-5.7-35.5-5.7L660,383.7l-0.2-0.2l-0.5,0.2l-10.6,4.2c0,0-3.2,65-22.7,89.6s-57.4,92.7-106.6,93.4H317.6
		c-49.2-0.6-87.1-68.8-106.6-93.4c-2.9-3.8-5.4-8-7.3-12.5c-0.5-1.1-0.9-2.1-1.4-3.2c-2.3-5.8-4.2-11.7-5.7-17.8
		c-0.3-1.2-0.6-2.5-0.9-3.7s-0.6-2.5-0.9-3.8c-0.1-0.6-0.3-1.2-0.4-1.9c-0.4-1.9-0.8-3.7-1.1-5.6s-0.7-3.7-1-5.5
		c-0.2-1.2-0.4-2.4-0.6-3.6s-0.4-2.4-0.5-3.5c-0.6-4-1.1-7.8-1.4-11.2c-0.1-0.5-0.1-1-0.1-1.4c-0.2-1.4-0.3-2.8-0.4-4
		c-0.1-0.9-0.1-1.7-0.2-2.4c-0.5-5.8-0.7-9.4-0.7-9.4l-10.6-4.1H177l-21.7,16.5c0,0-9.5,9.5-35.5,5.7c-15.7-2.3-42-4.9-60.6-10.1
		c-1.2,9.7-1.6,19.4-1.4,29.1c0.2,6.8,3.9,18.5,9.4,32.2c6.7,16.6,15.9,36.3,24.6,54.3c7.2,14.9,13.9,28.6,18.5,38.4l0.2,0.5
		c1.5,3.2,2.5,5.8,3.3,8c5.2,15.4-6.7,5.8-16.3-3.9c-2.1-2.1-4-4.2-5.7-6.1c-0.5-0.6-1-1.2-1.4-1.8c-5.1-7.3-14.2-26.8-23.2-48.1
		c-6-14.1-11.9-28.9-16.5-41.4c-4.9-13.2-8.4-23.9-9.1-28.4c-1.5-10.2,0.2-27.7,1.7-39.2c-3.6-2.3-5.8-5-6-8.1
		c-0.2-2.8-0.3-6.2-0.2-10l-19.8,10.9c-4,26.7-4.1,61.9-2.6,95.9c0.1,2,0.2,4,0.3,5.9c2.7,52.5,9.1,100.9,10.7,109.2
		c2.8,14.2,8.5,19.9,24.1,21.3c10.1,0.9,23.3,4.6,68.5,7.4h0.1c24.4,1.5,58.2,2.7,106,3c85.5,0.6,154.4,0.4,193.9,0.2
		c39.5,0.2,108.4,0.3,193.9-0.2c47.7-0.3,81.5-1.5,106-3h0.1c45.2-2.8,58.4-6.5,68.5-7.4c15.6-1.4,21.3-7.1,24.1-21.3
		c1.7-8.3,8-56.7,10.8-109.2c0.1-2,0.2-4,0.3-5.9C823.8,444.2,823.6,409.1,819.6,382.4z M720.1,513c-0.5,0.3-1,0.5-1.6,0.7
		c-1,0.4-2,0.8-3,1.1c-0.8,0.3-1.7,0.5-2.6,0.8c-0.7,0.2-1.4,0.4-2.1,0.5c-1.2,0.3-2.4,0.5-3.6,0.8c-4.4,0.9-9.4,1.8-15,3
		c-1.6,0.3-3.3,0.7-5,1.1c-2.3,0.5-4.6,1.3-6.8,2.1c-6.2,2.6-11.9,6.2-17,10.5c-2.8,2.3-5.5,4.7-8.3,7.4c-1.4,1.3-2.8,2.7-4.2,4.2
		c-1.4,1.4-2.8,2.9-4.2,4.4c-2.1,2.3-4.3,4.6-6.5,7s-4.4,4.9-6.7,7.5c-4.5,5.1-9.3,10.4-14.3,15.8l-2.5,2.7l0,0
		c-0.7,0.8-1.5,1.5-2.2,2.2l-0.7,0.7c-0.5,0.5-1,1-1.5,1.4l-0.8,0.7c-0.5,0.4-1,0.9-1.4,1.3l-0.7,0.6c-0.5,0.5-1.1,0.9-1.6,1.4
		l-0.5,0.4c-0.7,0.6-1.4,1.1-2.1,1.6c-0.2,0.1-0.3,0.3-0.5,0.4c-0.5,0.4-1,0.8-1.5,1.1l-0.7,0.5c-0.5,0.3-0.9,0.6-1.3,0.9l-0.7,0.5
		c-0.5,0.3-0.9,0.6-1.4,0.9l-0.6,0.4c-0.6,0.4-1.3,0.8-1.9,1.1l-0.1,0.1l-1.7,1l-0.6,0.3l-1.2,0.6l-1,0.3c-0.4,0.2-0.8,0.4-1.2,0.6
		l-0.6,0.3l-1.4,0.6l-0.3,0.1l-1.7,0.6l-0.4,0.2l-1.2,0.4l-0.5,0.2l-1,0.3l-0.5,0.2l-1.1,0.3l-0.4,0.1c-0.5,0.1-0.9,0.2-1.4,0.3
		h-0.2l-1.1,0.2l-0.4,0.1l-0.8,0.2l-0.4,0.1l-0.8,0.1h-0.4l-0.9,0.1h-0.2l-1,0.1h-0.3h-0.6h-0.3h-0.5h-2.5h-0.3h-0.3l-149.6,0.7
		h-2.4l-149.6-0.7h-0.3h-0.3h-2.6H264h-0.3h-0.6h-0.3l-1-0.1h-0.2l-0.9-0.1h-0.4l-0.8-0.1l-0.4-0.1c-0.3,0-0.5-0.1-0.8-0.2l-0.4-0.1
		l-1.1-0.2h-0.2l-1.4-0.3l-0.4-0.1c-0.3-0.1-0.7-0.2-1-0.3l-0.5-0.2l-1-0.3l-0.6-0.2l-1.1-0.4l-0.5-0.2c-0.5-0.2-1.1-0.4-1.6-0.6
		l-0.4-0.2c-0.4-0.2-0.9-0.4-1.4-0.6l-0.6-0.3L245,601l-0.7-0.3l-1.2-0.6l-0.6-0.3c-0.6-0.3-1.1-0.6-1.7-0.9l-0.2-0.1
		c-0.6-0.4-1.3-0.7-1.9-1.1l-0.6-0.4l-1.4-0.9l-0.7-0.5l-1.3-0.9l-0.7-0.5l-1.5-1.1L232,593c-0.7-0.5-1.4-1.1-2.1-1.6l-0.5-0.4
		l-1.6-1.4l-0.7-0.7c-0.5-0.4-1-0.8-1.4-1.3l-0.8-0.7c-0.5-0.5-1-0.9-1.5-1.4c-0.2-0.2-0.5-0.4-0.7-0.7c-0.7-0.7-1.4-1.4-2.2-2.2
		l0,0l-2.6-2.7c-5.1-5.4-9.8-10.7-14.3-15.8c-2.3-2.5-4.5-5-6.7-7.5l-2.4-2.6c-1.4-1.5-2.7-3-4.1-4.4l-2.1-2.2
		c-1.1-1.1-2.1-2.2-3.1-3.2c-0.7-0.7-1.4-1.4-2.1-2.1c-1-1-2-1.9-3-2.9c-2.1-2-4.2-3.8-6.3-5.5c-1.4-1.1-2.8-2.2-4.2-3.2
		c-2-1.5-4.2-2.9-6.3-4.1c-1.4-0.8-2.9-1.6-4.3-2.2c-0.7-0.3-1.5-0.7-2.2-1c-2.2-0.9-4.5-1.6-6.8-2.1c-1.7-0.4-3.4-0.8-5-1.1
		c-5.6-1.2-10.6-2.1-15-3c-1.2-0.3-2.5-0.5-3.6-0.8c-1.4-0.3-2.8-0.7-4.1-1.1s-2.5-0.8-3.7-1.3c-0.6-0.2-1.2-0.5-1.8-0.8l-0.4-0.2
		c-0.6-0.3-1.1-0.6-1.6-0.9s-0.8-0.5-1.1-0.8c-2.1-1.5-3.9-3.5-5.1-5.7c-0.4-0.7-0.8-1.4-1.1-2.2c-0.4-0.8-0.7-1.6-0.9-2.4l12.1,1.9
		l112.8,17.9c0,0,2.5,4.2,7,10.4c0.5,0.7,1.1,1.5,1.7,2.3c2,2.7,4.4,5.8,7,8.9c1.1,1.3,2.3,2.7,3.5,4.1s2.5,2.8,3.8,4.2
		c0.9,0.9,1.8,1.9,2.7,2.8s1.8,1.9,2.8,2.8c3.8,3.7,7.9,7.3,12.1,10.5c0.5,0.4,1.1,0.8,1.6,1.2c1.6,1.2,3.3,2.3,5,3.4
		c1.1,0.7,2.2,1.4,3.4,2c1.7,1,3.5,1.9,5.2,2.7c6.2,3,13.1,4.7,20,4.9h2.3h0.6h1.6h0.9h4.8h0.7h1.9h0.9h2.1h0.7h2.3h3.5h2.7h6.2h5.7
		h3.5h5.7h130.5h5.7h3.4h5.9h3.6h5.5h0.4h2.5h0.3h2.4h0.7h2.3h0.6h2h0.8h4.8h0.7h1.8h0.7h2.3c39.8-0.5,75.7-60.1,75.7-60.1l112.8-18
		l12.1-1.9c-0.3,0.8-0.6,1.6-0.9,2.4c-0.3,0.7-0.7,1.5-1.1,2.2c-0.4,0.7-0.8,1.3-1.3,1.9c-0.9,1.2-1.9,2.2-3,3.1
		c-0.9,0.7-1.8,1.3-2.7,1.9C721.1,512.5,720.6,512.7,720.1,513z"
            />
            <path
                className="st15"
                d="M608.7,525.1c-6.9,10.8-40.4,60-78.7,60.5c-26.9,0.3-68.9,0.4-91.5,0.4h-40.1c-22.6,0-64.6,0-91.5-0.4
		c-38.2-0.5-71.8-49.7-78.7-60.5l-110.6-17.6l-0.4-0.1c1,0.6,2,1.1,3.1,1.6c4.3,1.8,9.9,2.9,17.1,4.3c4,0.8,8.6,1.7,13.5,2.8
		c20.6,4.8,36.8,22.9,55.6,44c5.5,6.2,11.2,12.5,17.4,19c19.6,20.6,36.8,22.2,41.6,22.2c0.5,0,1,0,1.5-0.1c0.2,0,0.4,0,0.6,0l0,0
		l149.6,0.7h2.3l149.6-0.7l0,0c0.2,0,0.4,0,0.7,0l0,0c0,0,0.5,0,1.4,0c4.8,0,22-1.6,41.6-22.2c6.2-6.5,11.9-12.8,17.4-19
		c18.8-21,35.1-39.2,55.6-44c5-1.2,9.5-2,13.5-2.8c7.2-1.4,12.8-2.5,17.1-4.3c1.1-0.4,2.1-1,3.1-1.6l-0.4,0.1L608.7,525.1z"
            />
            <path className="st15" d="M411,581L411,581z" />
            <path className="st15" d="M212.5,365c1.3,0.3,2.6,0.6,3.8,0.8C215.1,365.6,213.8,365.3,212.5,365z" />
            <path
                className="st15"
                d="M184.2,381l5.8,2.3c1.8,0.7,3.1,2.4,3.2,4.4c0,0.6,3.3,63.7,21.6,86.7c3.5,4.4,7.4,10,12,16.5
		c21,29.7,52.6,74.5,90.7,75h201.8c38.1-0.5,69.8-45.3,90.7-75c4.6-6.5,8.5-12.1,12-16.5c18.3-23.1,21.6-86.1,21.6-86.7
		c0.1-2,1.3-3.7,3.2-4.4l5.8-2.3c0.1-0.6,0.4-1.2,0.7-1.8l13-20.3c-5.6,1.6-12.7,3.6-21.3,6.1c-14.7,4.2-29.7,7.4-44.9,9.6
		c-0.2,0-0.4,0-0.6,0H237.4c-0.2,0-0.4,0-0.6,0c-15.2-2.2-30.1-5.4-44.9-9.6c-8.6-2.4-15.7-4.4-21.4-6.1l13,20.3
		C183.9,379.8,184.1,380.4,184.2,381z M436.8,397c0.7,1.6,1.1,3.2,1.3,4.9c0.1,0.9,0.2,1.7,0.2,2.6v14.9c0,1.4-0.6,2.8-1.7,3.7
		c-0.9,0.8-1.8,1.5-2.9,2.1c-3.5,1.9-7.5,2.8-11.5,2.8c-0.2,0-0.4,0-0.7,0H421c-6.7-0.2-10.6-3.3-12.7-6.9c-0.5-0.8-0.9-1.6-1.2-2.5
		c-0.4-1.2-0.7-2.4-0.9-3.6c-0.1-0.4-0.1-0.8-0.1-1.1s0-0.7,0-1c0-1.5,0.2-3,0.5-4.5c0.1-0.5,0.3-1,0.4-1.5c0.3-1,0.8-2,1.3-2.9
		s1.2-1.8,1.9-2.6c-0.4,0.7-0.7,1.4-0.9,2.1c-0.2,0.7-0.4,1.5-0.5,2.2s-0.1,1.5,0,2.2c0.2,1.8,0.8,3.5,1.8,5c0.8,1.2,1.8,2.2,3,3
		c0.6,0.4,1.2,0.7,1.9,1c0.7,0.3,1.4,0.5,2.1,0.7c0.4,0.1,0.7,0.1,1.1,0.2l0,0c0.5,0,1,0.1,1.5,0.1h0.5h1h0.2c0.5,0,1,0,1.5-0.1h0.4
		c0.5,0,1-0.1,1.5-0.2h0.1c0.5-0.1,0.9-0.2,1.3-0.3l0.3-0.1c0.4-0.1,0.8-0.3,1.2-0.5l0,0c0.4-0.2,0.8-0.4,1.2-0.7l0.6-0.4
		c0.4-0.3,0.8-0.6,1.1-1c0.4-0.4,0.7-0.9,1-1.3c0.2-0.2,0.3-0.5,0.5-0.8c0.3-0.6,0.6-1.2,0.8-1.8c0.8-2.4,1.3-4.9,1.2-7.5l0,0
		c0-1,0-2-0.1-2.9c-0.1-0.8-0.2-1.6-0.3-2.4c0-0.1,0-0.3-0.1-0.4c-0.2-1-0.5-2.1-0.9-3.1c-0.2-0.5-0.4-0.9-0.6-1.3
		c-0.5-1.1-1.2-2.1-1.9-3c-0.8-0.9-1.6-1.7-2.5-2.4C432.1,389.9,435.1,393.1,436.8,397L436.8,397z M399,403.6
		c0.1-0.7,0.3-1.4,0.5-2.2c0.1-0.4,0.2-0.7,0.3-1.1c0.2-0.6,0.4-1.2,0.7-1.8c0.1-0.3,0.2-0.5,0.3-0.8c0.2-0.5,0.5-1,0.8-1.5
		c0.7-1.3,1.6-2.5,2.6-3.6c0.5-0.6,1-1.1,1.5-1.6c1.8-1.7,3.9-3.1,6.2-4c0.7-0.3,1.3-0.5,2-0.7c1.7-0.5,3.4-0.7,5.2-0.7l0,0
		c5.7,0,14.5,4.5,14.5,16.8c0,6.3-1.8,10.5-5.2,12.5v-10.6l0,0c0-1.1-0.2-2.2-0.6-3.2c-0.2-0.5-0.4-1-0.7-1.4
		c-0.3-0.5-0.6-0.9-1-1.3c-0.3-0.3-0.5-0.5-0.8-0.8c-0.4-0.4-0.9-0.7-1.4-1s-1.1-0.5-1.7-0.7c-0.5-0.1-1.1-0.2-1.6-0.2H420
		c-0.6,0-1.2,0.1-1.8,0.2c-0.2,0-0.4,0-0.6,0.1c-2,0.4-4,1.2-5.7,2.3c-0.7,0.5-1.4,1-2,1.6c-1,1-1.9,2.1-2.6,3.3
		c-0.3,0.4-0.5,0.9-0.7,1.3c-0.4,0.9-0.8,1.9-1.1,3c-0.4,1.7-0.6,3.5-0.6,5.3l0,0c0.1,3.4,1,6.7,2.8,9.6c0.6,1,1.2,1.9,2,2.7
		c-5.9-3.3-9.9-9.3-10.6-16C398.7,407.3,398.7,405.4,399,403.6z M274.1,445.5c0-2.8,2.2-5,5-5h278.8c2.8,0,5,2.2,5,5v55.9
		c0,2.8-2.2,5-5,5l0,0H279.1c-2.8,0-5-2.2-5-5l0,0V445.5z"
            />
        </g>
        <g id="avant-retroviseur-droit">
            <path
                id="retroviseurs_x5F_d"
                className="st15"
                d="M106.1,236.3c0.2-1.5,2.1-17.1,2.1-23.1c0-6.1-1.4-15.4-19.4-16.5
	c-17.9-1.1-35,4.9-50,15c-10.1,6.8-9.6,8.8-9.4,11.9c0.1,0.9,0.2,3.2,0.9,6.4h0.8c0,0,17.6,4.2,50.6,5.5c0,0-1.5,3.5-2.1,3.5
	c-0.5,0-38.2-2.9-48-4.3c2.7,7.6,8.7,17,22.4,21.2c11.8,3.6,30.5,4.6,38.5,4.9c3-2.9,15.9-14.6,16.4-15.2c0.4-0.6,6-6.2,7.4-7.6
	l-7.4-0.2C108.8,237.7,105.9,237.8,106.1,236.3z"
            />
        </g>
        <g id="avant-retroviseur-gauche">
            <path
                id="retroviseurs_x5F_g"
                className="st15"
                d="M798.9,211.6c-15-10.1-32-16.1-50-15c-17.9,1.1-19.4,10.5-19.4,16.5
	c0,6.1,1.9,21.6,2.1,23.1c0.2,1.5-2.8,1.4-2.8,1.4l-7.4,0.2c1.4,1.4,7,7,7.4,7.6s13.3,12.3,16.4,15.2c8.1-0.3,26.7-1.4,38.5-4.9
	c13.7-4.1,19.7-13.5,22.4-21.2c-9.8,1.4-47.5,4.3-48,4.3c-0.6,0-2.1-3.5-2.1-3.5c33-1.3,50.6-5.5,50.6-5.5h0.8
	c0.7-3.1,0.8-5.5,0.9-6.4C808.5,220.4,809,218.4,798.9,211.6z"
            />
        </g>
        <g id="avant-phare-avant-gauche">
            <path
                className="st15"
                d="M42,428.8c0.7,4.6,4.2,15.2,9.1,28.4c4.7,12.5,10.5,27.3,16.5,41.4c9,21.2,18.1,40.7,23.2,48.1
		c0.4,0.6,0.9,1.2,1.4,1.8c1.6,1.9,3.6,4,5.7,6.1c9.6,9.7,21.6,19.2,16.3,3.9c-0.7-2.2-1.8-4.8-3.3-8l-0.2-0.5
		c-4.6-9.8-11.3-23.6-18.5-38.4c-8.7-18-17.9-37.7-24.6-54.3c-5.5-13.7-9.3-25.4-9.4-32.2c-0.2-9.7,0.2-19.4,1.4-29.1
		c-4.9-1.4-15.8-6.4-15.8-6.4C42.2,401,40.5,418.6,42,428.8z"
            />
            <path
                className="st15"
                d="M159.6,350.4l-0.7-1.1c0,0-17.5-7.1-55.4-19.9s-42.6-25.1-42.6-25.1c-22.7,24.1-24.1,61-23.2,77.1
		c0.2,3.1,2.4,5.7,6,8.1c0,0,10.9,5,15.8,6.4c18.7,5.2,44.9,7.9,60.6,10.1c26,3.8,35.5-5.7,35.5-5.7l22-16.8l2.2-1.7L159.6,350.4z"
            />
        </g>
        <g id="avant-phare-avant-droit">
            <path
                className="st15"
                d="M779.6,425c-0.2,6.8-3.9,18.5-9.4,32.2c-6.7,16.6-15.9,36.3-24.6,54.3c-7.2,14.9-13.9,28.6-18.5,38.4
		l-0.2,0.5c-1.5,3.2-2.5,5.8-3.3,8c-5.2,15.4,6.7,5.8,16.3-3.9c2.1-2.1,4-4.2,5.7-6.1c0.5-0.6,1-1.2,1.4-1.8
		c5.1-7.3,14.2-26.8,23.2-48.1c6-14.1,11.9-28.9,16.5-41.4c4.9-13.2,8.4-23.9,9.1-28.4c1.5-10.2-0.2-27.7-1.7-39.2
		c-2.5,1.6-15.8,6.4-15.8,6.4C779.4,405.6,779.9,415.3,779.6,425z"
            />
            <path
                className="st15"
                d="M800,381.5c1-16.1-0.5-53-23.2-77.1c0,0-4.7,12.3-42.6,25.1s-55.4,19.9-55.4,19.9l-0.7,1.1L658,381.9l2.2,1.7
		l22,16.8c0,0,9.5,9.5,35.5,5.7c15.7-2.3,42-4.9,60.6-10.1c0,0,13.2-4.8,15.8-6.4C797.6,387.2,799.8,384.5,800,381.5z"
            />
        </g>
        <g id="avant-pare-brise">
            <path
                className="st15"
                d="M192.6,240l4.9-0.1h1.3h1.8h0.4l26.4-0.6h0.3l6.1-0.1h0.1l2.9-0.1h0.5l5.8-0.1h0.6l2.2-0.1h0.9l16-0.3h0.2
		c10.8-0.2,21.8-0.5,32.7-0.7h0.3l17.8-0.3h0.1l17.5-0.3l11.6-0.2h0.1l5.2-0.1h0.4l5.2-0.1l5.1-0.1h0.2l6.6-0.1h0.1l6-0.1h3.5
		l4.5-0.1h4.4l-0.1-0.1 M606.6,239L606.6,239l32.8,0.9l0.4-0.1l3.9,0.1l0.6,0.5l9.7,0.2l6.4,0.1l3.2,0.1l12.1,0.3l6.3,0.1l6.3,0.2
		l3.2,0.1l7.8,0.2h0.8h0.6h0.9c-0.2-0.5-0.3-0.6-0.3-0.8c-0.1-0.3-0.2-0.7-0.3-1s-0.3-0.9-0.5-1.4c-0.7-1.9-1.4-3.8-2.1-5.8
		l-0.2-0.4c-0.4-1.1-0.9-2.3-1.3-3.5l-0.3-0.8c-0.5-1.3-1.1-2.7-1.6-4c-0.7-1.7-1.4-3.3-2.1-5l-0.1-0.2c-0.7-1.6-1.4-3.1-2-4.7
		l-0.2-0.4l-0.7-1.5c-0.5-1-0.9-2-1.4-3.1l-0.3-0.6c-0.6-1.3-1.2-2.7-1.9-4l-0.4-0.8c-0.6-1.2-1.2-2.5-1.8-3.7l-0.4-0.8l-0.2-0.5
		l-2-4.1c0-0.1-0.1-0.2-0.1-0.3l-1-2.1l-1.1-2.1l-0.3-0.6c-0.7-1.4-1.4-2.7-2.1-4c-0.1-0.2-0.2-0.4-0.3-0.5
		c-1.5-2.7-2.9-5.5-4.4-8.1v-0.1l-0.3-0.5c-0.4-0.8-0.9-1.6-1.3-2.4c-0.3-0.5-0.6-1-0.8-1.5l-0.3-0.5c-1.4-2.6-2.9-5.1-4.3-7.6
		c-0.1-0.2-0.2-0.3-0.3-0.5c-0.4-0.7-0.8-1.4-1.2-2.1l-0.8-1.4l-0.1-0.1l-1.5-2.5l-0.5-0.9l-0.2-0.3l-2-3.4l-0.1-0.1l-0.1-0.1
		c-0.9-1.5-1.8-3-2.7-4.4l-0.1-0.2l-2.8-4.3l-0.1-0.1l-0.3-0.6c-0.3-0.5-0.6-0.9-0.8-1.4l-0.3-0.4l-0.9-1.4l-0.1-0.1l-0.3-0.5
		l-1-1.5l-0.3-0.5l-0.3-0.4l-0.7-1.1l-0.2-0.3c-0.6-1-1.2-1.9-1.8-2.8l-1.7-2.7v-0.1c-0.5-0.8-1.1-1.7-1.6-2.4
		c0-0.1-0.1-0.1-0.1-0.2l-1.4-2.2v-0.1l-0.1-0.1c-0.3-0.5-0.7-1-1-1.5l-0.4-0.6c0-0.1-0.1-0.2-0.2-0.3l-0.2-0.2
		c-0.3-0.5-0.7-1-1-1.5c-0.8-1.2-1.5-2.3-2.2-3.3l-0.6-0.9l-0.2-0.2c-0.2-0.4-0.5-0.7-0.7-1.1l-0.1-0.2l-0.8-1.1l-0.2-0.3l-0.3-0.4
		c-0.1-0.1-0.1-0.2-0.2-0.2l-0.3-0.4v0.1l-0.3-0.4c-0.1-0.2-0.2-0.3-0.4-0.5s-0.2-0.2-0.2-0.3l-0.2-0.4l-0.1-0.1L631,111l-0.2-0.3
		l-0.2-0.3l-0.6-0.1l-2.6-0.5l-0.4-0.1l-0.8-0.1h-0.3h-0.2l-2.5-0.4l-1.3-0.2l-2-0.3l-0.9-0.1l-1.1-0.2l-2-0.3l-2-0.3l-0.8-0.1H613
		l-1.3-0.2l-2-0.3l-1.4-0.2l-2.9-0.3h-0.1l-0.7-0.1l-0.8-0.1l-3.7-0.4h-0.5h-0.3l-3.6-0.4l-0.9-0.1l-1.3-0.1l-3.4-0.3l-1.6-0.2
		c-1.1-0.1-2.2-0.2-3.4-0.3l-0.9-0.1h-0.5l-3.2-0.3l-1.6-0.1h-0.5l-3.6-0.3l-0.8-0.1l-1.6-0.1l-3.5-0.3l-1.6-0.1H567l-3.8-0.3h-0.4
		l-0.8-0.1h-0.3L557,103l-1-0.1h-0.2l-1-0.1c-1-0.1-2-0.1-3-0.2l-1.7-0.1l-2.5-0.1l-1.2-0.1h-0.1l-1.5-0.1l-2.8-0.1l-2-0.1h-0.3
		l-5.2-0.3l-1.4-0.1l-1.6-0.1l-2.2-0.1l-1.6-0.1l-4-0.2h-1h-0.1l-1.3-0.1l-3.8-0.2h-0.4l-4.4-0.2h-0.3H512h-0.9h-0.2l-3.9-0.7h-1
		h-0.3h-1.5L501,100h-0.3l-1.8-0.1l-3.3-0.1h-0.1H495l-2.4-0.1h-1.9h-0.4h-0.6l-4-0.1h-0.8l-9.1-0.2h-1l-4-0.1h-0.6h-0.1l-4.3-0.1
		h-2.1h-0.8h-2.1H460l-4.1-0.1h-1.2h-1.1H452l-3.6-0.1h-1.6h-0.4h-2h-0.1h-1.2h-2.7h-1.9h-0.2h-3.2h-1.7h-2h-1.6h-3l0.4-0.5
		c-1.4,0-2.7,0-3.8,0V98c-1.3,0-2.6,0-3.9,0h-4.9v0.5l0,0c-0.1,0-0.1,0-0.2,0l0,0h-5h-4h-2h-4.9h-0.2l-5.8,0.1h-2h-0.3l-6.1,0.1
		h-0.6h-2h-0.4l-3.3,0.2c-1.1,0-2.2,0-3.3,0.1h-0.2h-0.8h-2.1l-4.3,0.1h-0.7l-3.9,0.1h-0.9l-4.5,0.1h-0.3l-4.5,0.1h-0.8l-4,0.1h-1.1
		l-2.1,0.1H344h-2h-0.7l-2.1,0.3c-3.3,0.1-6.6,0.2-10,0.4l-2.8,0.1h-0.3h-1.2l-4.7,0.2h-0.4l-2,0.1l-3.2,0.1h-0.1h-0.6h-0.4H313
		l-3.6,0.2H309h-0.9h-0.4h-0.6c-1.1,0-2.1,0.1-3.2,0.2l-1.4,0.1l-5.2,0.3H297l-2.4,0.1l-2.4,0.1l-1.5,0.1h-0.1l-1,0.1l-2.7,0.2
		l-1.1,0.1h-0.6c-1.3,0.1-2.7,0.1-4,0.2l-1.2,0.1h-0.1l-5,0.3h-0.6h-0.6l-1.8,0.1l-2.1,0.2l-0.9,0.1l-0.7,0.1l-1,0.1l-2.5,0.2
		l-1.6,0.1l-4.4,0.3h-0.1h-0.4l-4.8,0.4h-0.5h-0.2h-0.2H252h-0.6l-2.8,0.2l-1.6,0.2l-1.6,0.2l-1.8,0.2l-1.3,0.1l-4.6,0.5l-0.5,0.1
		H237l-3.6,0.4h-0.2l-1,0.1H232l-1.3,0.2l-1.8,0.2l-0.6,0.1l-0.8,0.1l-2.3,0.3l-1,0.1l-0.9,0.1l-1.1,0.2l-3,0.4l-1.1,0.2l-1.4,0.2
		l-1.5,0.2l-0.9,0.1l-0.4,0.1l-2.7,0.4l-1.1,0.2c-1.2,0.2-2.4,0.4-3.6,0.6l-0.2,0.3c-3.6,5-50.7,72-70.8,130.8h0.2h0.7h0.4l2-0.1
		h0.2l7.6-0.2l2-0.1h1.1h1.4h0.2l7.3-0.2h0.8l3.9-0.1h0.6l6.4-0.2h0.6l5.3-0.1l3.4-0.1h0.6h1.1h1.1l2.1-0.1h0.3l4.4-0.1h1.4l1.9-0.1
		 M498.3,237L498.3,237c30.9,0.6,64.8,1.3,95.7,2c-0.1,0-0.1-0.1-0.1-0.1v-0.1l12.6,0.3c0.1,0.2,0.1,0.1,0.1,0 M485.6,236.7
		L485.6,236.7l12.6,0.3v-0.1 M384.5,236.1h-0.1l0.1,0.1l8.4-0.1h2.5H417h2.8h4.9c7.7,0,17.1,0.1,27.5,0.2l0.1-0.1
		c10.3,0.1,21.4,0.3,33.3,0.5v-0.1"
            />
        </g>
    </svg>
);

export default CarFrontSide;
