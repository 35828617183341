import { AxiosResponse } from 'axios';
import Swal from 'sweetalert2';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { leadActions, ValueOf } from '@app/helpers/LeadHelper';

import { batchStatusUpdateLead } from '@app/crud/customers/customer.crud';

import { Lead } from '@app/store/customers/customers.store';

import toast from '@app/partials/content/Toast';

/**
 * Show Remove Validation
 * @param callback
 */
export const showRemoveValidation = (callback: () => Promise<void>) => {
    Swal.fire({
        title: Intl.formatMessage({ id: 'CONSOLE_LEADS.ACTION.REMOVE.TITLE' }),
        text: Intl.formatMessage({ id: 'CONSOLE_LEADS.ACTION.REMOVE.SUBTITLE' }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
        cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
        customClass: {
            confirmButton: 'btn mr-3 btn-primary',
            cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
        preConfirm: async () => {
            Swal.showLoading();
            await callback();
        },
    });
};

/**
 * Show Remove Validation
 * @param callback
 */
export const showUpdateValidation = (callback: (value: ValueOf<typeof leadActions>) => Promise<void>) => {
    Swal.fire({
        title: Intl.formatMessage({ id: 'CONSOLE_LEADS.ACTION.UPDATE.TITLE' }),
        icon: 'question',
        input: 'select',
        inputOptions: {
            [leadActions.close_lead_won]: Intl.formatMessage({ id: 'CONSOLE_LEADS.ACTION.UPDATE.OPTION.WON' }),
            [leadActions.close_lead_lost]: Intl.formatMessage({ id: 'CONSOLE_LEADS.ACTION.UPDATE.OPTION.LOST' }),
        },
        inputPlaceholder: Intl.formatMessage({ id: 'CONSOLE_LEADS.ACTION.UPDATE.OPTION.PLACEHOLDER' }),
        showCancelButton: true,
        confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
        cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
        customClass: {
            confirmButton: 'btn mr-3 btn-primary',
            cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
        preConfirm: async (value) => {
            Swal.showLoading();
            await callback(value);
        },
        inputValidator: (value) =>
            new Promise((resolve) => {
                if (value === leadActions.close_lead_won || value === leadActions.close_lead_lost) {
                    resolve();
                } else {
                    resolve(Intl.formatMessage({ id: 'CONSOLE_LEADS.ACTION.UPDATE.OPTION.ERROR' }));
                }
            }),
    });
};

/**
 * Get delete lead promises
 * @param selectedLeads
 * @param deleteLeadAction
 * @returns {Promise<unknown[]>}
 */
export const deleteLeads = (selectedLeads: Lead[], deleteLeadAction: (id: number) => Promise<AxiosResponse>) => {
    const deletePromises = selectedLeads?.map((selectedLead) => deleteLeadAction(selectedLead.id));

    return Promise.all(deletePromises)
        .then(() => {
            toast({
                variant: 'success',
                message: Intl.formatMessage({ id: 'CONSOLE_LEADS.ACTION.REMOVE.SUCCESS' }),
            });
        })
        .catch(() => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CONSOLE_LEADS.ACTION.REMOVE.FAILED' }),
            });
        });
};

export const updateLeadStatus = (selectedLeads: Lead[], action: ValueOf<typeof leadActions>) => {
    const selectedLeadIds = selectedLeads?.map((lead) => lead.id);
    if (!selectedLeadIds) return;
    return batchStatusUpdateLead(selectedLeadIds, action)
        .then(() => {
            toast({
                variant: 'success',
                message: Intl.formatMessage(
                    { id: 'CONSOLE_LEADS.ACTION.UPDATE.SUCCESS' },
                    { number: selectedLeadIds.length },
                ),
            });
        })
        .catch(() => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage(
                    { id: 'CONSOLE_LEADS.ACTION.UPDATE.FAILED' },
                    { number: selectedLeadIds.length },
                ),
            });
        });
};
