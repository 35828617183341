import ApiGateway from '@app/helpers/AxiosHelper';

const { REACT_APP_APV_SC_URL } = process.env;

// eslint-disable-next-line import/prefer-default-export
export function getMecaplanningAction(params) {
    return ApiGateway.get(`${REACT_APP_APV_SC_URL}/api/mecaplanning/action`, {
        params,
    });
}
