export const actionTypes = {
    FetchPackages: 'FETCH_PACKAGES',
    FetchPackage: 'FETCH_PACKAGE',
    AddPackage: 'ADD_PACKAGE',
    RemovePackage: 'REMOVE_PACKAGE',
    UpdatePackage: 'UPDATE_PACKAGE',
    FetchedPackages: 'FETCHED_PACKAGES',
    FetchedPackage: 'FETCHED_PACKAGE',
    UpdatedPackage: 'UPDATED_PACKAGE',
    AddedPackage: 'ADDED_PACKAGE',
    FetchPackageFailed: 'FETCH_PACKAGE_FAILED',
    UpdatePackageFailed: 'UPDATE_PACKAGE_FAILED',
    AddPackageFailed: 'ADD_PACKAGE_FAILED',
    FetchPackagesFailed: 'FETCH_PACKAGES_FAILED',
    SetPackagesTotalRecords: 'SET_PACKAGES_TOTALRECORDS',
};

const initialState = {
    loading: false,
    packages: [],
    filtersContent: [],
    totalRecords: 0,
    categories: [],
    segments: [],
    package: null,
    error: null,
};

export function packageReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FetchPackages:
        case actionTypes.FetchPackage: {
            return {
                ...state,
                loading: true,
                package: null,
                error: null,
            };
        }
        case actionTypes.AddPackage:
        case actionTypes.RemovePackage:
        case actionTypes.UpdatePackage: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }
        case actionTypes.FetchedPackages: {
            return {
                ...state,
                loading: false,
                filtersContent: action.payload.filters,
                totalRecords: action.payload.count,
                packages: action.payload.packages,
            };
        }
        case actionTypes.FetchedPackage: {
            return {
                ...state,
                loading: false,
                package: action.payload.package,
                categories: action.payload.categories,
                segments: action.payload.segments,
            };
        }
        case actionTypes.UpdatedPackage:
        case actionTypes.AddedPackage: {
            return {
                ...state,
                loading: false,
                package: action.payload,
            };
        }
        case actionTypes.FetchPackagesFailed:
        case actionTypes.FetchPackageFailed: {
            return {
                ...state,
                loading: false,
                packages: [],
                error: action.payload,
            };
        }
        case actionTypes.UpdatePackageFailed:
        case actionTypes.AddPackageFailed: {
            return {
                ...state,
                loading: false,
            };
        }
        case actionTypes.SetPackagesTotalRecords: {
            return {
                ...state,
                totalRecords: action.payload,
            };
        }
        default:
            return state;
    }
}

export const actions = {
    fetchPackages: () => ({ type: actionTypes.FetchPackages }),
    fetchedPackages: (packages) => ({ type: actionTypes.FetchedPackages, payload: packages }),
    fetchPackage: () => ({ type: actionTypes.FetchPackage }),
    fetchedPackage: (result) => ({ type: actionTypes.FetchedPackage, payload: result }),
    fetchPackageFailed: (error) => ({ type: actionTypes.FetchPackageFailed, payload: error }),
    addPackage: () => ({ type: actionTypes.AddPackage }),
    removePackage: () => ({ type: actionTypes.RemovePackage }),
    updatePackage: () => ({ type: actionTypes.UpdatePackage }),
    updatedPackage: (result) => ({ type: actionTypes.UpdatedPackage, payload: result }),
    addedPackage: (result) => ({ type: actionTypes.AddedPackage, payload: result }),
    updatePackageFailed: (error) => ({ type: actionTypes.UpdatePackageFailed, payload: error }),
    addPackageFailed: (error) => ({ type: actionTypes.AddPackageFailed, payload: error }),
    fetchPackagesFailed: (error) => ({ type: actionTypes.FetchPackagesFailed, payload: error }),
    setPackagesTotalRecords: (totalRecords) => ({ type: actionTypes.SetPackagesTotalRecords, payload: totalRecords }),
};

export const getPackagesTree = (packages, packageId) => {
    const options = [];
    let selectedPackage = null;

    packages.forEach((item) => {
        // initialise data
        if (packageId && packageId === item.id) {
            selectedPackage = { name: item.name, id: item.id };
        }
        // check exist category
        const newPackage = options.filter((option) => options.length > 0 && option.code === item.packageCategory.id);

        if (newPackage.length > 0) {
            options.forEach((option) => {
                if (option.code === item.packageCategory.id) {
                    option.package.push({
                        name: item.name,
                        id: item.id,
                    });
                }
            });
        } else {
            options.push({
                cname: item.packageCategory.name,
                code: item.packageCategory.id,
                package: [
                    {
                        name: item.name,
                        id: item.id,
                    },
                ],
            });
        }
    });

    return [options, selectedPackage];
};
