import { Divider } from 'primereact/divider';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { empty } from '@app/helpers/ToolsHelper';

import ErrorForm from '@app/partials/layout/ErrorForm';

import LeadWonBuyVehicle from './Partials/LeadWonBuy';
import LeadWonComment from './Partials/LeadWonComment';

const LeadWonDefault = ({ params, listingParams, leadReasons, lead }) => {
    const [type, setType] = useState(null);
    const [vehicles, setVehicles] = useState(null);
    const { setValue, register, watch, errors, reset } = useFormContext();
    const Intl = useIntl();
    const { reason } = watch(['reason']);

    const filterVehicles = useCallback(
        (reason_slug) => {
            if (!lead?.leadElements) {
                setVehicles(null);
                return;
            }

            switch (reason_slug) {
                case 'VO':
                    setVehicles(lead.leadElements.filter((element) => !empty(element.vehicle.registration)));
                    break;
                case 'VN':
                    setVehicles(lead.leadElements.filter((element) => empty(element.vehicle.registration)));
                    break;
                default:
                    setVehicles(null);
                    break;
            }
        },
        [setVehicles, lead],
    );

    const handleReasonChange = useCallback(
        (reasonSelected) => {
            if (leadReasons && leadReasons.close_lead_won_reason) {
                const selectedReason = leadReasons.close_lead_won_reason.leadReasons.find(
                    (elm) => elm.slug === reasonSelected,
                );

                if (selectedReason) {
                    filterVehicles(null);
                    setType(selectedReason.value.toUpperCase());

                    // Reset selected vehicle
                    setValue('serialNumber', '');
                    setValue('licenseNumber', '');
                    setValue('vehicleInternalId', '');

                    if (['customer_bought_origin_vo', 'customer_bought_origin_vn'].includes(selectedReason.slug)) {
                        filterVehicles(selectedReason.value.toUpperCase());
                    }
                }
            }
        },
        [filterVehicles, leadReasons],
    );

    useEffect(() => {
        handleReasonChange(reason);
    }, [handleReasonChange, leadReasons, reason]);

    useEffect(() => {
        if (params?.event) {
            reset({
                ...params?.event,
            });
        }
    }, [params, type]);

    return (
        <>
            <Form.Group>
                <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_WON.TYPE' })}</Form.Label>
                <Form.Control
                    as="select"
                    name="reason"
                    defaultValue=""
                    disabled={!!params?.event}
                    className={`${errors.reason ? 'is-invalid' : ''}`}
                    ref={register({
                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    })}
                >
                    <option value="">{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_WON.TYPE.PLACEHOLDER' })}</option>
                    {leadReasons &&
                        leadReasons.close_lead_won_reason &&
                        leadReasons.close_lead_won_reason.leadReasons.map((leadReason) => (
                            <option key={leadReason.id} value={leadReason.slug}>
                                {leadReason.name}
                            </option>
                        ))}
                </Form.Control>
                <ErrorForm errors={errors} name="reason" />
            </Form.Group>
            <LeadWonComment params={params} />
            <Divider className="my-5" type="dashed" />
            <LeadWonBuyVehicle params={params} listingParams={listingParams} type={type} vehicles={vehicles} />
        </>
    );
};

export default LeadWonDefault;
