import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import ErrorForm from '@app/partials/layout/ErrorForm';

const LeadLooseDefault = ({ params, leadReasons, reason }) => {
    const { register, errors } = useFormContext();
    const Intl = useIntl();

    const getSubReason = () => {
        if (leadReasons && leadReasons.close_lead_loose_reason) {
            const toolTypeIndex = leadReasons.close_lead_loose_reason.leadReasons.findIndex((toolType) =>
                toolType.slug.includes(reason),
            );

            if (toolTypeIndex !== -1) {
                return leadReasons.close_lead_loose_reason.leadReasons[toolTypeIndex].leadReasons;
            }
        }

        return [];
    };

    return (
        <>
            <Form.Group>
                <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_LOOSE.REACH' })}</Form.Label>
                <Form.Control
                    as="select"
                    name="reason"
                    defaultValue=""
                    disabled={!!params?.event}
                    className={`${errors.reason ? 'is-invalid' : ''}`}
                    ref={register({
                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    })}
                >
                    <option value="">
                        {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_LOOSE.REACH.PLACEHOLDER' })}
                    </option>
                    {leadReasons &&
                        leadReasons.close_lead_loose_reason &&
                        leadReasons.close_lead_loose_reason.leadReasons.map((leadReason) => (
                            <option key={leadReason.id} value={leadReason.slug}>
                                {leadReason.name}
                            </option>
                        ))}
                </Form.Control>
                <ErrorForm errors={errors} name="reason" />
            </Form.Group>
            {getSubReason().length > 0 && (
                <Form.Group>
                    <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_LOOSE.REASON' })}</Form.Label>
                    <Form.Control
                        as="select"
                        name="subReason"
                        defaultValue=""
                        disabled={!!params?.event}
                        className={`${errors.subReason ? 'is-invalid' : ''}`}
                        ref={register({
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                        })}
                    >
                        <option value="">
                            {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_LOOSE.REASON.PLACEHOLDER' })}
                        </option>
                        {leadReasons &&
                            leadReasons.close_lead_loose_reason &&
                            getSubReason().map((subReason) => (
                                <option key={subReason.id} value={subReason.slug}>
                                    {subReason.name}
                                </option>
                            ))}
                    </Form.Control>
                    <ErrorForm errors={errors} name="subReason" />
                </Form.Group>
            )}
            <Form.Group>
                <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_LOOSE.COMMENT' })}</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    name="result"
                    disabled={!!params?.event}
                    className={`${errors.result ? 'is-invalid' : ''}`}
                    ref={register({
                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    })}
                />
                <ErrorForm errors={errors} name="result" />
            </Form.Group>
        </>
    );
};

export default LeadLooseDefault;
