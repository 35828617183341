import * as dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Intl, Locale } from '@src/_metronic/i18n/I18nProvider';

import { getDateByFormat } from '@app/helpers/DateHelper';
import { htmlToString } from '@app/helpers/StringHelper';

import { DateTimePicker } from '@app/partials/content/DateTimePicker';
import Editor from '@app/partials/content/Editor';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

import { CampaignContext } from '../CampaignProvider';

const CampaignEdit = ({ showModal, setShowModal }) => {
    const methods = useForm();
    const { register, control, handleSubmit } = methods;
    const { params } = useSelector((state) => state.modal);
    const [loading, setLoading] = useState(false);
    const { types } = useContext(CampaignContext);

    const currentType = params?.campaign?.campaignType?.slug;
    const typeIdx = types?.findIndex((type) => type?.slug === currentType);
    const currentTypeObj = types?.[typeIdx];

    useEffect(() => {
        setLoading(false);
    }, [showModal]);

    const onSubmit = async (data) => {
        setLoading(true);
        data.description = htmlToString(data.description);
        data.duration = parseInt(data.duration, 10) || null;
        data.dateStart = dayjs(data.dateStart)?.isValid() ? dayjs(data.dateStart)?.format('YYYY-MM-DD HH:mm:ss') : null;
        data.campaignType = data.campaignType || null;

        params.callback({ ...data });
        setShowModal(false);
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-comment" />}
            title={Intl.formatMessage({ id: 'CAMPAIGN.MODAL.EDIT.TITLE' })}
            loading={loading}
            body={
                <FormProvider {...methods}>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CAMPAIGN.MODAL.EDIT.NAME' })}</Form.Label>
                        <Form.Control ref={register} name="name" defaultValue={params?.campaign?.name} />
                    </Form.Group>
                    <Form.Group className="mt-8">
                        <Form.Label>
                            <FormattedMessage id="CAMPAIGN.MODALS.SETTINGS.DATE.SENDING" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="dateStart"
                            defaultValue={
                                params?.campaign?.dateStart
                                    ? getDateByFormat(params?.campaign?.dateStart, 'YYYY-MM-DD HH:mm:ss', null)
                                    : null
                            }
                            render={(props) => (
                                <DateTimePicker
                                    locale={Locale}
                                    showIcon
                                    dateFormat="DD/MM/YYYY HH:mm"
                                    onChange={(e) => {
                                        props.onChange(e.value);
                                    }}
                                    value={props.value}
                                    showTime
                                    minDate={dayjs().add(2, 'days').toDate()}
                                    minDateError="minDateCampaign"
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="CAMPAIGN.MODALS.SETTINGS.DATE.DURATION" />
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            as="select"
                            name="duration"
                            defaultValue={params?.campaign?.duration ?? 30}
                        >
                            {[...Array(30).keys()].map((value) => (
                                <option value={value + 1}>{value + 1}</option>
                            ))}
                            ;
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="CAMPAIGN.MODALS.SETTINGS.DATE.TYPE" />
                        </Form.Label>
                        <Form.Control
                            ref={register}
                            as="select"
                            name="campaignType"
                            className="font-weight-semibold"
                            defaultValue={currentTypeObj?.name}
                        >
                            <option value={currentTypeObj?.slug ?? ''}>
                                {currentTypeObj?.name ?? Intl.formatMessage({ id: 'TRANSLATOR.SELECT' })}
                            </option>
                            {types?.map((type) => {
                                if (type?.slug !== currentTypeObj?.slug) {
                                    return (
                                        <option key={type?.slug} value={type?.slug}>
                                            {type?.name}
                                        </option>
                                    );
                                }
                            })}
                            ;
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CAMPAIGN.MODAL.EDIT.COMMENT' })}</Form.Label>
                        <Controller
                            name="description"
                            defaultValue={params?.campaign?.description}
                            render={(props) => (
                                <Editor
                                    value={props.value}
                                    onChange={props.onChange}
                                    config={{
                                        minHeight: 150,
                                        buttons: '',
                                        statusbar: false,
                                        placeholder: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.EDIT_COMMENT.TITLE' }),
                                    }}
                                />
                            )}
                        />
                    </Form.Group>
                </FormProvider>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" type="submit" onClick={handleSubmit(onSubmit, onError)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default CampaignEdit;
