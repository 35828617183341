import React from 'react';
import { Card, Image } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import KTUtil from '@src/_metronic/_assets/js/util';

import { leadsCanBeLinked } from '@app/pages/customers/Helpers/Lead';
import ContractRow from '@app/pages/securyweb/Partials/ContractRow';
import { actions } from '@app/store/modal/modal.store';

import Lottie from '@app/partials/content/Lottie';

import LeadRow from './LeadRow/LeadRow';

const Actions = ({ leads, contracts, className }) => {
    const dispatch = useDispatch();
    const Intl = useIntl();
    const toLink = leadsCanBeLinked(leads, [null, false]);
    const hasActions = (leads && leads?.length > 0) || (contracts && contracts.length > 0);

    return (
        <Card className={`card--shadowed ${className}`}>
            <Card.Header className="d-flex align-items-center">
                <i className="la la-2x text-light mr-3 la-bullseye" />
                {Intl.formatMessage({ id: 'CUSTOMER.VIEW.ACTIONS.TITLE' })}
            </Card.Header>
            <Card.Body>
                {hasActions ? (
                    <>
                        <div className="lead-rows">
                            {leads.map((lead) => (
                                <LeadRow key={lead.id} lead={lead} />
                            ))}
                        </div>
                        {contracts.map((contract) => (
                            <ContractRow key={contract.id} contract={contract} />
                        ))}
                    </>
                ) : (
                    <div className="lead-rows h-100">
                        <div className="w-100 d-flex flex-lg-row flex-column align-items-center justify-content-center lead-row--noresult">
                            <div className="d-lg-block d-flex justify-content-between">
                                <Lottie
                                    options={{
                                        path: '/media/lotties/customers/fiche-client-aucune-activite-en-cours.json',
                                    }}
                                    style={{
                                        width: 'auto',
                                        height: KTUtil.isMobileDevice() ? '150px' : '250px',
                                    }}
                                />
                                {KTUtil.isMobileDevice() && (
                                    <Image
                                        className="ml-10"
                                        src="/media/pages/customers/fiche-client-fleche-moment-d-agir.svg"
                                    />
                                )}
                            </div>
                            <div className="mx-10 mt-lg-0 mt-5 text-lg-left text-center">
                                <div>
                                    <FormattedHTMLMessage id="CUSTOMER.VIEW.ACTIONS.NO_ACTIVTY" />
                                </div>
                                <div className="font-weight-semibold">
                                    {Intl.formatMessage({ id: 'CUSTOMER.VIEW.ACTIONS.CLICK_BUTTON' })}
                                </div>
                            </div>
                            {KTUtil.isDesktopDevice() && (
                                <Image src="/media/pages/customers/fiche-client-fleche-moment-d-agir.svg" />
                            )}
                        </div>
                    </div>
                )}
                {toLink && (
                    <div className="text-center">
                        <div className="cursor-pointer d-inline-block">
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                            <div
                                className="d-flex align-items-center justify-content-center"
                                onClick={() => dispatch(actions.modalChange('link_lead'))}
                            >
                                Lier des Leads
                                <i className="ml-3 la la-link" />
                            </div>
                        </div>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};

export default Actions;
