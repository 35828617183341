import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getVehicleLeads } from '@app/crud/stock/stock.crud';

import { useFetch } from '@app/hooks';
import ROUTES from '@app/router/Routes';

type StockPriceLeadsButtonProps = {
    vehicleRegistration: string;
    icon?: boolean;
    variant?: string;
    label?: ReactElement;
    className?: string;
};

const StockPriceLeadsButton = ({
    vehicleRegistration,
    icon = false,
    variant = 'primary',
    label,
    className,
}: StockPriceLeadsButtonProps) => {
    const [displayLeadsNumber, setDisplayLeadsNumber] = useState(false);
    const {
        fetch: fetchVehicleLeads,
        data: leads,
        loading,
    } = useFetch({
        // @ts-ignore
        fetchAction: getVehicleLeads,
        resultInterceptor: (response) => response || [],
    });

    useEffect(() => {
        if (displayLeadsNumber) fetchVehicleLeads(vehicleRegistration);
    }, [displayLeadsNumber, vehicleRegistration]);

    return (
        <>
            {displayLeadsNumber && (
                <span className={className}>
                    {loading ? (
                        <Spinner animation="border" size="sm" variant="primary" />
                    ) : leads.count > 0 ? (
                        <Link
                            to={routeTo(
                                ROUTES.LEADS_ONLINE.PATH,
                                {
                                    statusType: 'all',
                                },
                                {
                                    vehicleIdentifier: vehicleRegistration,
                                },
                            )}
                            target="_blank"
                        >
                            <span className="font-weight-bold">{leads.count}</span>
                        </Link>
                    ) : (
                        <span className="font-weight-bold">{leads.count}</span>
                    )}
                </span>
            )}
            <Button
                variant={`outline-${variant}`}
                size="sm"
                className={`p-1 ${!icon && 'px-3'} font-weight-bold ${className}`}
                onClick={() => setDisplayLeadsNumber(true)}
                hidden={displayLeadsNumber}
            >
                {icon && <i className="las la-eye" />}
                {label || <FormattedMessage id="STOCK.PRICING.LEADS.BUTTON_DEFAULT" />}
            </Button>
        </>
    );
};

export default StockPriceLeadsButton;
