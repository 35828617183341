import { generatePath } from 'react-router-dom';
import Swal from 'sweetalert2';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { addRole, deleteRole, getRole, updateRoleById } from '@app/crud/autouser/role.crud';

import ROUTES from '@app/router/Routes';
import { actions } from '@app/store/autouser/role/role.store';

import toast from '@app/partials/content/Toast';

export function fetchRole(id) {
    return (dispatch) => {
        dispatch(actions.fetchRole());

        let role = null;

        if (id) {
            Promise.resolve(getRole(id))
                .then((values) => {
                    role = values || null;

                    if (role?.data?.error && id) {
                        dispatch(actions.fetchRoleFailed(role.result));
                    }
                })
                .catch(() => {
                    dispatch(actions.fetchRoleFailed({}));
                })
                .finally(() => {
                    dispatch(
                        actions.fetchedRole({
                            role: role?.data || null,
                        }),
                    );
                });
        }

        dispatch(
            actions.fetchedRole({
                role: null,
            }),
        );
    };
}

export function createRole(params) {
    return (dispatch) => {
        dispatch(actions.addRole());

        addRole(params.data)
            .then((response) => {
                if (response?.id) {
                    dispatch(actions.addedRole(response.result));
                    params.history.push(generatePath(ROUTES.ROLE.PATH.UPDATE, { id: response.id }));
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'AUTOUSER.ROLES.ADD.SUCCESS' }),
                    });
                } else {
                    dispatch(actions.addRoleFailed(response));
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'AUTOUSER.ROLES.ADD.FAILED' }),
                    });
                }
            })
            .catch((error) => {
                const { code } = error.response.data[0].name;
                dispatch(actions.addRoleFailed({}));
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: `${code}` }),
                });
            });
    };
}

export function updateRole(id, data) {
    return (dispatch) => {
        dispatch(actions.updateRole());

        updateRoleById(id, data)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.updatedRole(response));
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'AUTOUSER.ROLES.UPDATE.SUCCESS' }),
                    });
                } else {
                    dispatch(actions.updateRoleFailed(response.result));
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'AUTOUSER.ROLES.UPDATE.FAILED' }),
                    });
                }
            })
            .catch((error) => {
                if (error.code === 409) {
                    dispatch(actions.removeRoleFailed(true));
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'AUTOUSER.ROLES.REMOVE.CONFLICT' }),
                    });
                } else {
                    dispatch(actions.removeRoleFailed());
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'AUTOUSER.ROLES.UPDATE.FAILED' }),
                    });
                }
            });
    };
}

export function removeRole(params) {
    return (dispatch) => {
        Swal.fire({
            title: Intl.formatMessage({ id: 'AUTOUSER.ROLES.REMOVE.TITLE' }),
            text: Intl.formatMessage({ id: 'AUTOUSER.ROLES.REMOVE.TEXT' }),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
            cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
            customClass: {
                confirmButton: 'btn mr-3 btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            preConfirm: () => {
                Swal.showLoading();
                dispatch(actions.removeRole());

                return deleteRole(params.id)
                    .then((response) => {
                        if (!response?.result?.error) {
                            params.history.push(generatePath(ROUTES.ROLES.PATH));
                            toast({
                                variant: 'success',
                                message: Intl.formatMessage({ id: 'AUTOUSER.ROLES.REMOVE.SUCCESS' }),
                            });
                        } else {
                            dispatch(actions.removeRoleFailed());
                            toast({
                                variant: 'danger',
                                message: Intl.formatMessage({ id: 'AUTOUSER.ROLES.REMOVE.FAILED' }),
                            });
                        }
                    })
                    .catch((error) => {
                        dispatch(actions.removeRoleFailed());
                        toast({
                            variant: 'danger',
                            message: Intl.formatMessage({
                                id:
                                    error.code === 409
                                        ? 'AUTOUSER.ROLES.REMOVE.CONFLICT'
                                        : 'AUTOUSER.ROLES.REMOVE.FAILED',
                            }),
                        });
                    });
            },
        });
    };
}
