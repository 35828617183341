import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { Brand, Model, Vehicle, Version } from './vehicle.type';

const { REACT_APP_APV_URL } = process.env;

export function getBodyTypes() {
    return ApiGateway.get(`${REACT_APP_APV_URL}/vehicle/bodyType`);
}

export function getSegments() {
    return ApiGateway.get(`${REACT_APP_APV_URL}/vehicle/segment`);
}

export function getBrands(args: { makeName?: string }) {
    return ApiGateway.post<ApiResponse<Brand[]>, CustomAxiosResponse<ApiResponse<Brand[]>>>(
        `${REACT_APP_APV_URL}/vehicle/make`,
        args,
    );
}

export interface Models {
    [key: string]: Model;
}

export type GetModelsParams = Partial<{
    makeCode: string;
    modelCode: number;
    modelName: string;
    month: string;
    year: string;
}>;
export function getModels(args: GetModelsParams) {
    return ApiGateway.post<ApiResponse<Models>, CustomAxiosResponse<ApiResponse<Models>>>(
        `${REACT_APP_APV_URL}/vehicle/model`,
        args,
    );
}

export interface Versions {
    version: Version[];
}

export type GetVersionsParams = Partial<{
    makeCode: number;
    modelCode: number;
    vehicleTypeCode: number;
    month: string;
    year: string;
}>;

export function getVersions(args: GetVersionsParams) {
    return ApiGateway.post<ApiResponse<Versions>, CustomAxiosResponse<ApiResponse<Versions>>>(
        `${REACT_APP_APV_URL}/vehicle/version`,
        args,
    );
}

export async function getVehicleByPlate({ plate }: { plate: string }) {
    const response = await ApiGateway.get<ApiResponse<Vehicle>, CustomAxiosResponse<ApiResponse<Vehicle>>>(
        `${REACT_APP_APV_URL}/vehicle/plate/${plate}`,
    );

    if (response?.result?.error) {
        throw Error(response?.result?.error_description || response.result.error);
    } else {
        return response;
    }
}
