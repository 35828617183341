import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const AccountMenuItem = ({ userData, menu, idx, active, onMouseEnter, onMouseLeave, updateBackgroundPosition }) => {
    const item = useRef();

    useEffect(() => {
        if (active) {
            updateBackgroundPosition(item.current);
        }
    }, [updateBackgroundPosition, active]);

    return (
        <Link to={menu.href(userData?.id)}>
            <div
                ref={item}
                className={classNames('menu-item')}
                onMouseEnter={(e) => {
                    setTimeout(() => {
                        onMouseEnter(e, idx);
                    }, 50);
                }}
                onMouseLeave={(e) => {
                    setTimeout(() => {
                        onMouseLeave(e, idx);
                    }, 50);
                }}
            >
                {active ? (
                    <>
                        <motion.div layoutId="menu-item-background" className="menu-item__background" />
                        <motion.div
                            layout
                            className="menu-item__wrapper"
                            initial={{ color: '#000000' }}
                            animate={{ color: '#FFFFFF' }}
                        >
                            <div className={classNames('menu-item__icon', menu.icon)} />
                            <div className="menu-item__description">
                                <div className="menu-item__title">{menu.title(userData)}</div>
                                <div className="menu-item__subtitle">{menu.subtitle}</div>
                            </div>
                        </motion.div>
                    </>
                ) : (
                    <div className="menu-item__wrapper">
                        <div className={classNames('menu-item__icon', menu.icon)} />
                        <div className="menu-item__description">
                            <div className="menu-item__title">{menu.title(userData)}</div>
                            <div className="menu-item__subtitle">{menu.subtitle}</div>
                        </div>
                    </div>
                )}
            </div>
        </Link>
    );
};

export default AccountMenuItem;
