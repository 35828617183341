import * as H from 'history';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import { useAppSelector, useModal } from '@app/hooks';
import ROUTES from '@app/router/Routes';
import { fetchOrder } from '@app/store/apv/apvinstore/order/order.thunk';

import Loader from '@app/partials/content/Loader';
import toast from '@app/partials/content/Toast';
import CardAppointment from '@app/partials/content/viewDetail/CardAppointment';
import CardInformationsInvoice from '@app/partials/content/viewDetail/CardInformationsInvoice';
import CardVehicleInfo from '@app/partials/content/viewDetail/CardVehicleInfo';
import DetailsOrder from '@app/partials/content/viewDetail/DetailsOrder';
import HeadBandGeneric from '@app/partials/content/viewDetail/HeadBandGeneric';

import Update from './Update';

type OrderParams = {
    id: string;
};

function Order({ history }: { history?: H.History }) {
    const Intl = useIntl();
    const methods = useFormContext();
    const params = useParams<OrderParams>();
    const dispatch = useDispatch();
    const [firstLoad, setFirstLoad] = useState(true);
    const { order, error, loading } = useAppSelector((state) => state.apvinstore.order);
    const [showUpdateModal, toggleUpdateModal] = useModal(false);

    const showOrders = useCallback(() => {
        history.push(routeTo(ROUTES.APV_INSTORE_DETAIL.PATH));
    }, [history]);

    useEffect(() => {
        dispatch(fetchOrder(params.id));
        setFirstLoad(false);
    }, [dispatch, params.id]);

    useEffect(() => {
        if (error !== null && !firstLoad) {
            toast({
                onShow: showOrders,
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    }, [error, firstLoad, showOrders]);

    const onSubmit = (data: any) => console.log(data);

    if (loading) {
        return <Loader style={{ width: '5rem', height: '5rem' }} />;
    }

    if (!order) {
        return null;
    }

    return (
        <Form className="package" onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="mb-5">{!isEmpty(order) && <HeadBandGeneric order={order} />}</div>
            <Row className="mb-5">
                <Col lg={4}>
                    <CardInformationsInvoice order={order} />
                </Col>
                <Col lg={4}>
                    <CardAppointment order={order} toggleUpdateModal={toggleUpdateModal} />
                </Col>
                <Col lg={4}>
                    <CardVehicleInfo order={order} />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <DetailsOrder order={order} />
                </Col>
            </Row>
            <Update showModal={showUpdateModal} setShowModal={toggleUpdateModal} />
        </Form>
    );
}

export default withRouter(Order);
