import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getActions } from '@app/pages/customers/Helpers/Actions';
import useAction from '@app/pages/customers/Hooks/useAction';
import CarSearch from '@app/pages/customers/Partials/CarSearch';
import DealershipSelect from '@app/pages/customers/Partials/DealershipSelect';
import LeadTypeSelect from '@app/pages/customers/Partials/LeadTypeSelect';
import { actions } from '@app/store/modal/modal.store';

import ActionButton from '@app/partials/content/ActionButton';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

const CHOICE_VISIT = 1;
const FORM_VISIT = 2;

const Visit = ({ showModal, title: propsTitle, icon: propsIcon, subtitle: propsSubtitle, setShowModal }) => {
    const methods = useForm();
    const dispatch = useDispatch();
    const Intl = useIntl();
    const { register, errors, handleSubmit } = methods;
    const [step, setStep] = useState(CHOICE_VISIT);
    const [title, setTitle] = useState(propsTitle);
    const [icon, setIcon] = useState(propsIcon);
    const [subtitle, setSubtitle] = useState(propsSubtitle);
    const [objectDefaultValue, setObjectDefaultValue] = useState('');
    const { data: customer } = useSelector((state) => state.customer.customer);
    const [loading, createAction, setActionType] = useAction({
        type: 'visit',
        success: (response) => {
            dispatch(
                actions.modalChange('next_action', {
                    leadId: response.result.lead.id,
                }),
            );
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VISIT.ERROR' }),
            });
        },
    });

    const onSubmit = (data) => {
        createAction(data);
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const handleClick = (action = undefined) => {
        switch (action) {
            case 'VISIT_SIMPLE':
                setTitle(<FormattedHTMLMessage id="CUSTOMERS.MODAL.VISIT.SIMPLE_TITLE" />);
                setIcon(<i className="la la-2x la-eye text-primary" />);
                setSubtitle('');
                setStep(FORM_VISIT);
                setActionType('visit');
                setObjectDefaultValue(
                    Intl.formatMessage(
                        { id: 'CUSTOMERS.MODAL.VISIT.SIMPLE_SUBJECT.DEFAULT' },
                        {
                            client: `${customer.lastname || ''} ${customer.firstname || ''}`,
                        },
                    ),
                );
                break;
            case 'VISIT_TRY':
                setTitle(<FormattedHTMLMessage id="CUSTOMERS.MODAL.VISIT.TRY_TITLE" />);
                setIcon(<i className="la la-2x la-car text-primary" />);
                setSubtitle('');
                setStep(FORM_VISIT);
                setActionType('vehicle_test');
                setObjectDefaultValue(
                    Intl.formatMessage(
                        { id: 'CUSTOMERS.MODAL.VISIT.TRY_SUBJECT.DEFAULT' },
                        {
                            client: `${customer.lastname || ''} ${customer.firstname || ''}`,
                        },
                    ),
                );
                break;
            default:
                setStep(CHOICE_VISIT);
                setIcon(propsIcon);
                setTitle(propsTitle);
                setSubtitle(propsSubtitle);
                break;
        }
    };

    useEffect(() => {
        setStep(CHOICE_VISIT);
    }, [showModal, setStep]);

    const visitActions = getActions(['visit', 'vehicle_test']);

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={icon}
            title={title}
            subtitle={subtitle}
            body={
                step === 1 ? (
                    <div className="d-flex py-6 justify-content-lg-center justify-content-between">
                        {visitActions.map(([action, properties]) => (
                            <ActionButton
                                {...properties}
                                key={action}
                                onClick={() => handleClick(action)}
                                className="mr-lg-6"
                            />
                        ))}
                    </div>
                ) : (
                    <FormProvider {...methods}>
                        <LeadTypeSelect methods={methods} />

                        <DealershipSelect methods={methods} customer={customer} />
                        <Form.Group>
                            <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VISIT.OBJECT' })}</Form.Label>
                            <Form.Control
                                name="object"
                                type="text"
                                defaultValue={objectDefaultValue}
                                className={`${errors.object ? 'is-invalid' : ''}`}
                                ref={register({
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                })}
                            />
                            <ErrorForm errors={errors} name="object" />
                        </Form.Group>
                        <Form.Group className="m-0">
                            <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VISIT.COMMENT' })}</Form.Label>
                            <Form.Control
                                name="comment"
                                as="textarea"
                                rows={4}
                                className={`${errors.comment ? 'is-invalid' : ''}`}
                                ref={register({
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                })}
                            />
                            <ErrorForm errors={errors} name="comment" />
                        </Form.Group>
                        <Divider className="my-8" type="dashed" />
                        <CarSearch />
                        <br />
                        <span>{Intl.formatMessage({ id: 'TRANSLATOR.REQUIRED_FIELDS' })}</span>
                        <br />
                    </FormProvider>
                )
            }
            footer={
                step === 1 ? (
                    <div className="float-right">
                        <Button variant="outline-secondary" className="mr-5" onClick={() => setShowModal(false)}>
                            {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                        </Button>
                    </div>
                ) : (
                    <div className="float-right">
                        <Button variant="outline-secondary" onClick={() => handleClick()} className="mr-5">
                            {Intl.formatMessage({ id: 'TRANSLATOR.PREVIOUS' })}
                        </Button>
                        <Button variant="primary" onClick={handleSubmit(onSubmit, onError)}>
                            {Intl.formatMessage({ id: 'TRANSLATOR.NEXT' })}
                        </Button>
                    </div>
                )
            }
        />
    );
};

Visit.defaultProps = {
    title: <FormattedMessage id="CUSTOMERS.MODAL.VISIT.TITLE" />,
    subtitle: <FormattedMessage id="CUSTOMERS.MODAL.VISIT.SUBTITLE" />,
    icon: <i className="la la-2x text-primary la-map-marker" />,
};

export default Visit;
