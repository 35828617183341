import * as dayjs from 'dayjs';
import { Timeline } from 'primereact/timeline';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { getCivility } from '@app/pages/customers/Helpers/Customer';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import { getRegistrationTemplate } from '@app/partials/content/Vehicle/VehicleHelpers';

import './Feedback.scss';

const Feedback = ({ showModal, setShowModal, reviews, customer }) => {
    const [averageNote, setAverageNote] = useState(0);
    const Intl = useIntl();

    useEffect(() => {
        if (reviews?.length) {
            setAverageNote(reviews.reduce((a, b) => a + b.scoring, 0) / reviews.length || 0);
        }
    }, [reviews]);

    const getIcon = (service) => {
        switch (service) {
            case 'apv':
                return 'la-tools';
            case 'vo':
                return 'la-car-side';
            case 'ct':
                return 'la-check';
            case 'sav':
                return 'la-check';
            case 'vn':
                return 'la-car';
            default:
                return 'la-car';
        }
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const getImage = (index) => {
        if (index + 1 <= averageNote / 2) {
            return (
                <img key={index} src="/media/icons/filled-star-green.svg" alt="mileage" className="mr-1" width="20px" />
            );
        } else if (index + 1 === Math.ceil(averageNote / 2)) {
            return (
                <img key={index} src="/media/icons/half-star-green.svg" alt="mileage" className="mr-1" width="20px" />
            );
        }

        return <img key={index} src="/media/icons/empty-star.svg" alt="mileage" className="mr-1" width="20px" />;
    };

    /* eslint-disable react/no-array-index-key */
    return (
        <ModalDefault
            show={showModal}
            hideModal={handleClose}
            icon={<i className="la la-2x text-primary la-check-circle" />}
            title={Intl.formatMessage(
                { id: `CUSTOMERS.MODAL.FEEDBACK.TITLE` },
                {
                    value: `${getCivility(customer?.civility) || ''} ${customer?.firstname || ''} ${
                        customer?.lastname || ''
                    }`,
                },
            )}
            body={
                <div className="text-center">
                    <span className="font-weight-bold">Avis Fidcar, note moyenne :</span>
                    <div className="mt-2 star-raiting">{[...Array(5)].map((_el, index) => getImage(index))}</div>
                    <div className=" mt-6 timeline-feedback">
                        <Timeline
                            value={reviews}
                            marker={(item) => <i className={`la ${getIcon(item?.service)} la-lg my-2 text-success`} />}
                            opposite={(item) => (
                                <div className="d-flex flex-column font-weight-bold">
                                    <span>{dayjs(item?.created).format('DD/MM/YYYY')}</span>
                                    <span>{item?.dealershipName}</span>
                                </div>
                            )}
                            content={(item) => (
                                <div className="d-flex flex-column font-weight-bold">
                                    <span>
                                        {Intl.formatMessage({
                                            id: `CUSTOMERS.MODAL.FEEDBACK.${item?.service.toUpperCase()}`,
                                        })}{' '}
                                        {item?.registration && <>&bull; {getRegistrationTemplate(item)}</>}
                                    </span>
                                    <span className="text-success">{item?.scoring}/10</span>
                                    <span
                                        className={`font-weight-normal text-muted ${!item?.comment && 'font-italic'}`}
                                    >
                                        {item?.comment
                                            ? item.comment
                                            : Intl.formatMessage({ id: `CUSTOMERS.MODAL.FEEDBACK.NO_COMMENT` })}
                                    </span>
                                </div>
                            )}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="m-auto">
                    <Button variant="primary" onClick={handleClose}>
                        {Intl.formatMessage({ id: `TRANSLATOR.CLOSE` })}
                    </Button>
                </div>
            }
        />
    );
};

export default Feedback;
