import * as dayjs from 'dayjs';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import KTUtil from '@src/_metronic/_assets/js/util';
import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useModal from '@app/hooks/useModal';
import usePermissions from '@app/hooks/usePermissions';

import { routeTo } from '@app/helpers/RoutesHelper';

import { Create, CreateAlreadyExist } from '@app/pages/customers/Modals';
import StatusLead from '@app/pages/customers/Partials/StatusLead';
import { getLeadFlagsIcon, LEAD_FLAGS } from '@app/pages/leadsOnline/Helpers/Flags';
import { checkIdenticalData } from '@app/pages/leadsOnline/Helpers/LeadOnlineHelper';
import { getClientBody } from '@app/pages/leadsOnline/Helpers/LeadsOnlineHelper';
import LeadRow from '@app/pages/leadsOnline/Partials/LeadRow';
import ROUTES from '@app/router/Routes';

import { CalendarFilter } from '@app/partials/content/CalendarFilter';
import DropdownFilter from '@app/partials/content/DropdownFilter';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import Label from '@app/partials/content/Label';
import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';

import '../leadsOnline/lead.scss';

const LeadOnlineDataTable = ({
    statusType,
    items,
    start,
    sortField,
    sortOrder,
    data,
    loading,
    handleFilter,
    handlePagination,
    handleSort,
}) => {
    const { hasAllPermissions } = usePermissions();
    const [loadingExistedCustomers, setLoadingExistedCustomers] = useState();
    const [showCreateModal, toggleCreateModal] = useModal(false, 'create');
    const [showCreateAlreadyExistModal, toggleCreateAlreadyExistModal, createAlreadyExistParams] = useModal(
        false,
        'create_already_exist',
    );
    const leads = data?.result || [];
    const facets = data?.facets || null;

    const selectStatusDropdown = () => {
        const leadStatus = facets?.leadStatus
            ? facets.leadStatus.map((status) => ({
                  name: status.label,
                  key: status.key,
              }))
            : [];

        return (
            <DropdownFilter
                optionLabel="name"
                filterBy="name"
                name="leadStatus"
                options={leadStatus}
                onChange={handleFilter}
            />
        );
    };

    const selectSourceDropdown = () => {
        const leadSources = facets?.leadSources
            ? facets.leadSources.map((leadSource) => ({
                  name: leadSource.label,
                  key: leadSource.key,
              }))
            : [];

        return (
            <DropdownFilter
                optionLabel="name"
                filterBy="name"
                name="leadSource"
                options={leadSources}
                onChange={handleFilter}
            />
        );
    };

    const inputFilter = (name) => <InputFilter name={name} onChange={handleFilter} />;

    const calendarFilter = (name) => <CalendarFilter name={name} selectionMode="range" onChange={handleFilter} />;

    const flagsBodyTemplate = (lead) =>
        lead?.flags && (
            <div className="d-flex flex-wrap">
                {getLeadFlagsIcon(lead).map((flag, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="mt-2" key={index}>
                        {flag}
                    </div>
                ))}
            </div>
        );

    const sourceBodyTemplate = (lead) => (
        <>
            <div className={`flex-grow-1 lead-history__source--${lead?.leadSource?.slug}`}>
                &bull;&nbsp;{lead?.leadSource?.name}
            </div>
            {lead?.leadOrigin && lead?.leadSource?.slug !== lead?.leadOrigin?.slug && (
                <Permission permissions={['LEAD_SHOW_SOURCE']}>
                    <span className="font-italic">{lead?.leadOrigin?.name}</span>
                </Permission>
            )}
            {lead?.operations &&
                lead?.operations?.map((operation) => (
                    <Label key={operation.slug} className="font-weight-bold label-lg label-light-success">
                        {operation?.name}
                    </Label>
                ))}
        </>
    );

    const dealershipBodyTemplate = (lead) => lead?.dealership?.usualName || '-';

    const immatBodyTemplate = (lead) => {
        let registration = '';

        if (lead.partExchangesIdentifier) {
            registration = lead.partExchangesIdentifier;
        }

        return registration ? (
            <span>{registration}</span>
        ) : (
            Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.IMMAT.WITHOUT' })
        );
    };

    const clientBodyTemplate = (lead) => <span>{getClientBody(lead)}</span>;

    const zipcodeBodyTemplate = (lead) => <span>{lead?.contact?.zipcode || lead?.contactOrigin?.zipcode || '-'}</span>;

    const referentBodyTemplate = (lead) => (
        <span>
            {statusType === 'new' || (!lead?.ownerFirstname && !lead?.ownerLastname)
                ? '-'
                : `${lead?.ownerFirstname} ${lead?.ownerLastname}`}
        </span>
    );

    const statusBodyTemplate = (lead, withDate = true, params = {}) => {
        let date = lead?.lastStatusUpdate;

        if (
            [
                'in_progress_appointment_to_come',
                'in_progress_relaunch_to_come',
                'in_progress_appointment_confirmed',
                'in_progress_appointment_to_confirm',
            ].includes(lead?.leadStatus?.slug)
        ) {
            date = lead?.scheduleAppointment?.date;
        }

        return (
            <StatusLead
                leadStatusUpdated={withDate && date}
                statusSlug={lead?.leadStatus?.slug}
                statusName={lead?.leadStatus?.name}
                statusSubName={lead?.leadStatus?.subName}
                leadReason={lead?.leadReason}
                {...params}
                table
            />
        );
    };

    const dateCreatedBodyTemplate = (lead) =>
        lead?.receipted ? dayjs(lead?.receipted).format('DD/MM/YYYY [à] HH[h]mm') : '-';

    const dateAppointmentBodyTemplate = (lead) =>
        lead?.scheduleAppointment?.date ? dayjs(lead?.scheduleAppointment?.date).format('DD/MM/YYYY [à] HH[h]mm') : '-';

    const actionsBodyTemplate = (lead) => {
        if (!lead?.contact?.id) {
            return (
                <Permission
                    permissions={['LEAD_TAKE_LEAD']}
                    interceptor={() => !!lead?.ownerId}
                    fallback={() => (
                        <Button
                            className="p-2 px-3"
                            variant="primary"
                            onClick={() => checkIdenticalData(lead, false, setLoadingExistedCustomers)}
                        >
                            {Intl.formatMessage({ id: 'TRANSLATOR.SHOW' })}
                        </Button>
                    )}
                >
                    <Button
                        className="p-2 px-3"
                        onClick={() => checkIdenticalData(lead, true, setLoadingExistedCustomers)}
                        variant="success"
                    >
                        <i className="la la-hand-pointer mr-2 pr-0" />
                        {Intl.formatMessage({ id: 'LEADS_ONLINE.ACTION.TAKE' })}
                    </Button>
                </Permission>
            );
        }

        if (!lead?.ownerId) {
            return (
                <Permission
                    permissions={['LEAD_TAKE_LEAD']}
                    fallback={() => (
                        <Link
                            to={routeTo(
                                ROUTES.CUSTOMER.PATH,
                                {
                                    id: lead?.contact?.id,
                                },
                                {
                                    action: 'viewLead',
                                    leadId: lead?.id,
                                },
                            )}
                        >
                            <Button className="p-2 px-3" variant="primary">
                                {Intl.formatMessage({ id: 'TRANSLATOR.SHOW' })}
                            </Button>
                        </Link>
                    )}
                >
                    <Link
                        to={routeTo(
                            ROUTES.CUSTOMER.PATH,
                            {
                                id: lead?.contact?.id,
                            },
                            {
                                action: 'take',
                                leadId: lead.id,
                            },
                        )}
                    >
                        <Button className="p-2 px-3" variant="success">
                            <i className="la la-hand-pointer mr-2 pr-0" />
                            {Intl.formatMessage({ id: 'LEADS_ONLINE.ACTION.TAKE' })}
                        </Button>
                    </Link>
                </Permission>
            );
        }

        return (
            <Link
                to={routeTo(
                    ROUTES.CUSTOMER.PATH,
                    {
                        id: lead?.contact?.id,
                    },
                    {
                        action: 'viewLead',
                        leadId: lead?.id,
                    },
                )}
            >
                <Button className="p-2 px-3" variant="primary">
                    {Intl.formatMessage({ id: 'TRANSLATOR.SHOW' })}
                </Button>
            </Link>
        );
    };

    return (
        <>
            {loadingExistedCustomers && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            {KTUtil.isInResponsiveRange('mobile') ? (
                <div>
                    {leads?.map?.((lead) => (
                        <LeadRow
                            lead={lead}
                            key={lead.id}
                            setLoadingExistedCustomers={setLoadingExistedCustomers}
                            clientBodyTemplate={clientBodyTemplate}
                            immatBodyTemplate={immatBodyTemplate}
                            statusBodyTemplate={statusBodyTemplate}
                            dateCreatedBodyTemplate={dateCreatedBodyTemplate}
                            flagsBodyTemplate={flagsBodyTemplate}
                            dealershipBodyTemplate={dealershipBodyTemplate}
                        />
                    ))}
                    <Paginator
                        first={start}
                        rows={items}
                        totalRecords={data?.count}
                        onPageChange={(e) => handlePagination(e)}
                    />
                </div>
            ) : (
                <HoCDataTable
                    value={leads}
                    rowClassName={(lead) => {
                        const hasStrongFlag = lead?.flags?.includes?.('strong_commitment');
                        const hasStrongPermission = hasAllPermissions(LEAD_FLAGS?.strong_commitment?.permissions);

                        return {
                            'row-border-success': hasStrongFlag && hasStrongPermission,
                        };
                    }}
                    totalRecords={data?.count}
                    lazy
                    rows={items}
                    start={start}
                    first={start}
                    loading={loading}
                    paginator
                    onFilter={handleFilter}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onPage={handlePagination}
                    onSort={handleSort}
                    paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                    currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                    removableSort
                    rowsPerPageOptions={[25, 50, 100]}
                    filterDisplay="row"
                    emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                >
                    <Column
                        field="leadSource"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.SOURCE' })}
                        filter
                        filterElement={selectSourceDropdown()}
                        body={sourceBodyTemplate}
                        sortable
                    />

                    <Column
                        field="dealership"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.DEALERSHIP' })}
                        filter
                        filterElement={inputFilter('dealership')}
                        body={dealershipBodyTemplate}
                        sortable
                    />

                    <Column
                        field="partExchangeIdentifier"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.IMMAT' })}
                        filter={statusType !== 'new'}
                        filterElement={inputFilter('partExchangeIdentifier')}
                        style={{ width: '10%' }}
                        body={immatBodyTemplate}
                        sortable
                    />

                    <Column
                        field="contact"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.CUSTOMER' })}
                        filter={statusType !== 'new'}
                        filterElement={inputFilter('contact')}
                        body={clientBodyTemplate}
                        sortable
                    />

                    <Column
                        field="contactZipcode"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.ZIPCODE' })}
                        filter
                        filterElement={inputFilter('contactZipcode')}
                        body={zipcodeBodyTemplate}
                        sortable
                    />

                    <Column
                        field="user"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.REFERENT' })}
                        filter
                        filterElement={inputFilter('user')}
                        body={referentBodyTemplate}
                        sortable
                    />

                    <Column
                        field="leadStatus"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.LEAD_STATUS' })}
                        filter
                        filterElement={selectStatusDropdown()}
                        style={{ width: '18%' }}
                        body={statusBodyTemplate}
                        sortable
                    />

                    <Column
                        field="receipted"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.DATE_CREATED' })}
                        filter
                        filterElement={calendarFilter('receiptedPeriod')}
                        body={dateCreatedBodyTemplate}
                        sortable
                    />

                    <Column
                        field="scheduleAppointment"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.DATE_APPOINTMENT' })}
                        filter
                        filterElement={calendarFilter('scheduleAppointmentPeriod')}
                        body={dateAppointmentBodyTemplate}
                        sortable
                    />

                    <Column
                        field="actions"
                        header={Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.ACTIONS' })}
                        bodyClassName="text-center"
                        style={{ width: '8.5%' }}
                        body={actionsBodyTemplate}
                    />
                </HoCDataTable>
            )}
            <Create showModal={showCreateModal} setShowModal={toggleCreateModal} />
            <CreateAlreadyExist
                showModal={showCreateAlreadyExistModal}
                setShowModal={toggleCreateAlreadyExistModal}
                params={createAlreadyExistParams}
            />
        </>
    );
};

export default LeadOnlineDataTable;
