import React from 'react';

import ChatItem, { ChatItemProps } from '@app/components/ChatTimeline/ChatItem';

type Props = {
    singleMode: boolean;
    messages: ChatItemProps[];
    children?: React.ReactNode;
};

const ChatTimeline = ({ messages, singleMode = false, children = null }: Props) => (
    <div className="timeline-handler timeline-handler-scrollable mt-6">
        <div
            className={`timeline ${
                singleMode ? 'timeline-single' : 'timeline-reverse'
            } flex-column-reverse d-flex h-100`}
            style={{ maxWidth: 'none' }}
        >
            {messages?.map((message) => (
                <ChatItem singleMode={singleMode} {...message} />
            ))}
        </div>
        {children}
    </div>
);

export default ChatTimeline;
