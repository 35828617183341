import { empty } from '@app/helpers/ToolsHelper';

import { User } from '@app/store/login/login.store';

// eslint-disable-next-line import/prefer-default-export
export const formHandler = (params: User) => ({
    ...params,
    role: !empty(params?.role) ? params?.role : null,
    job: !empty(params?.job) ? params?.job : null,
    securyweb_email: !empty(params?.securyweb_email) ? params?.securyweb_email : undefined,
});
