import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';

import { Vehicle } from '@app/store/stock/stock.type';

import ModalDefault from '@app/partials/content/modals/Modal.default';

import StockPriceContent from './StockPriceContent';

type StockPriceProps = {
    vehicle: Vehicle;
    show: boolean;
    title?: string | ReactNode;
    setShowModal?: Dispatch<SetStateAction<boolean>>;
    setVehicleItem?: Function;
    showDetails: boolean;
    showHistory: boolean;
    showTitleHistory: boolean;
    asModal: boolean;
};

const StockPrice = ({
    vehicle,
    title,
    show = false,
    setShowModal,
    setVehicleItem,
    showDetails,
    showHistory,
    showTitleHistory,
    asModal = false,
}: StockPriceProps) => {
    const [subModalActive, setSubModalActive] = useState(false);

    const pricingContent = (
        <StockPriceContent
            vehicle={vehicle}
            vehiclePricing={vehicle.pricing}
            showDetails={showDetails}
            showHistory={showHistory}
            setShowModal={setShowModal}
            showTitleHistory={showTitleHistory}
            setSubModalActive={setSubModalActive}
            setVehicleItem={setVehicleItem}
        />
    );

    return (
        <>
            {show &&
                (asModal ? (
                    <ModalDefault
                        show={show}
                        hideModal={() => setShowModal(false)}
                        icon={
                            <i
                                className={`text-primary las ${showDetails ? 'la-money-bill-wave' : 'la-clock'} fa-2x`}
                            />
                        }
                        title={title}
                        body={pricingContent}
                        className={`${!showTitleHistory ? 'history' : ''} ${subModalActive ? 'modal-hidden' : ''}`}
                    />
                ) : (
                    pricingContent
                ))}
        </>
    );
};

StockPrice.defaultProps = {
    showDetails: true,
    showHistory: true,
    showTitleHistory: true,
};

export default StockPrice;
