import Swal from 'sweetalert2';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { routeTo } from '@app/helpers/RoutesHelper';

import {
    addCategory,
    deleteCategory,
    getCategories,
    getCategory,
    updateCategoryById,
} from '@app/crud/apv/category.crud';

import ROUTES from '@app/router/Routes';

import toast from '@app/partials/content/Toast';

import { actions } from './category.store';

export function fetchCategories(params) {
    return (dispatch) => {
        dispatch(actions.fetchCategories());

        getCategories(params)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(
                        actions.fetchedCategories({
                            categories: response.result,
                            count: response?.count || 0,
                        }),
                    );
                } else {
                    dispatch(actions.fetchCategoriesFailed({}));
                }
            })
            .catch(() => {
                dispatch(actions.fetchCategoriesFailed({}));
            });
    };
}

export function fetchCategory(id) {
    return (dispatch) => {
        dispatch(actions.fetchCategory());

        const promises = [
            getCategories({
                active: true,
            }),
        ];

        if (id) {
            promises.push(getCategory(id));
        }

        Promise.all(promises)
            .then((values) => {
                const categories = values[0];
                const category = values[1] || null;

                if (categories?.result?.error) {
                    dispatch(actions.fetchPackageFailed(categories.result));
                } else if (category?.result?.error && id) {
                    dispatch(actions.fetchPackageFailed(category.result));
                } else {
                    dispatch(
                        actions.fetchedCategory({
                            category: category?.result || [],
                            categories: categories.result,
                        }),
                    );
                }
            })
            .catch(() => {
                dispatch(actions.fetchCategoryFailed({}));
            });
    };
}

export function createCategory(params) {
    return (dispatch) => {
        dispatch(actions.addCategory());

        addCategory(params.data)
            .then((response) => {
                if (!response?.result?.error) {
                    if (response?.result) {
                        response.result.prices = response?.prices || [];
                    }

                    dispatch(actions.addedCategory(response.result));
                    params.history.push(
                        routeTo(ROUTES.CATEGORY.PATH.UPDATE, {
                            id: response.result.id,
                        }),
                    );
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'APV.CATEGORY.ADD.SUCCESS' }),
                    });
                } else {
                    dispatch(actions.addCategoryFailed(response.result));
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'APV.CATEGORY.ADD.FAILED' }),
                    });
                }
            })
            .catch(() => {
                dispatch(actions.addCategoryFailed({}));
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'APV.CATEGORY.ADD.FAILED' }),
                });
            });
    };
}

export function updateCategory(id, data) {
    return (dispatch) => {
        dispatch(actions.updateCategory());

        updateCategoryById(id, data)
            .then((response) => {
                if (!response?.result?.error) {
                    if (response?.result) {
                        response.result.prices = response?.prices || [];
                    }

                    dispatch(actions.updatedCategory(response.result));
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'APV.CATEGORY.UPDATE.SUCCESS' }),
                    });
                } else {
                    dispatch(actions.updateCategoryFailed(response.result));
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'APV.CATEGORY.UPDATE.FAILED' }),
                    });
                }
            })
            .catch(() => {
                dispatch(actions.updateCategoryFailed({}));
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'APV.CATEGORY.UPDATE.FAILED' }),
                });
            });
    };
}

export function removeCategory(params) {
    return (dispatch) => {
        Swal.fire({
            title: Intl.formatMessage({ id: 'APV.CATEGORY.REMOVE.TITLE' }),
            text: Intl.formatMessage({ id: 'APV.CATEGORY.REMOVE.TEXT' }),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
            cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
            customClass: {
                confirmButton: 'btn mr-3 btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            preConfirm: () => {
                Swal.showLoading();
                dispatch(actions.removeCategory());

                return deleteCategory(params.id)
                    .then((response) => {
                        if (!response?.result?.error) {
                            params.history.push(routeTo(ROUTES.CATEGORIES.PATH));
                            toast({
                                variant: 'success',
                                message: Intl.formatMessage({ id: 'APV.CATEGORY.REMOVE.SUCCESS' }),
                            });
                        } else {
                            toast({
                                variant: 'danger',
                                message: Intl.formatMessage({ id: 'APV.CATEGORY.REMOVE.FAILED' }),
                            });
                        }
                    })
                    .catch(() => {
                        toast({
                            variant: 'danger',
                            message: Intl.formatMessage({ id: 'APV.CATEGORY.REMOVE.FAILED' }),
                        });
                    });
            },
        });
    };
}
