import qs from 'qs';
import React, { useEffect, useRef } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import useModal from '@app/hooks/useModal';

import { ApiResponse } from '@app/helpers/AxiosHelper';
import { getSort } from '@app/helpers/DataTableHelper';
import { routeTo } from '@app/helpers/RoutesHelper';

import { getApplicationPermissions } from '@app/crud/autouser/application.crud';
import {
    ApplicationFormParams,
    ApplicationParams,
    ApplicationQueryFilter,
    ApplicationQueryParams,
    Permission,
} from '@app/crud/autouser/application.types';
import { getRoles } from '@app/crud/autouser/role.crud';
import { UserRoles } from '@app/crud/autouser/role.types';

import { useAppSelector, useFetch, useTableHandlers } from '@app/hooks';
import ApplicationPermissionDataTable from '@app/pages/autouser/applications/ApplicationPermissionDataTable';
import { formSubmit } from '@app/pages/autouser/applications/Form/Form.submit';
import PermissionsSelect from '@app/pages/autouser/applications/partials/PermissionsSelect';
import RolesSelect from '@app/pages/autouser/applications/partials/RolesSelect';
import PermissionModal from '@app/pages/autouser/modals/Permission';
import ROUTES from '@app/router/Routes';
import { fetchApplication } from '@app/store/autouser/application/application.thunk';

import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

const defaultValues = {
    name: '',
};

function Application() {
    const Intl = useIntl();
    const history = useHistory();
    const [showAddPermission, setShowAddPermission] = useModal(false, 'show_permission');
    const { reset, register, errors, handleSubmit } = useFormContext();
    const params = useParams<ApplicationParams>();
    const location = useLocation();

    const queryParams: ApplicationQueryParams = qs.parse(location?.search || '', { ignoreQueryPrefix: true });
    const queryRoles = queryParams.roles || [];
    const queryPermissions = queryParams.permissions || [];
    const dispatch = useDispatch();
    const roleSelectorRef = useRef();
    const permissionSelectorRef = useRef();
    const newApplication = typeof params.id === 'undefined';
    const application = useAppSelector((state) => state.application.application);
    const { loading: loadingPermissions } = useAppSelector((state) => state.permission);

    const { start, filters, sortField, sortOrder, updateFilters } = useFilters({
        name: `autouser_applications_search_${params.id}`,
        persisted: false,
        defaultValues: {
            sortField: 'category',
            sortOrder: 1,
            filters: {
                roles: {
                    value: queryParams.roles,
                },
                permissions: {
                    value: queryParams.permissions,
                },
            },
        },
    });
    const { data, loading, handleFilter, handleSort, refresh } = useTableHandlers({
        filterStore: `autouser_applications_search_${params.id}`,
        fetchAction: getApplicationPermissions,
        withStore: false,
        // @ts-ignore
        paramsInterceptor: (newParams, paramsFilters) => {
            const sort = getSort(paramsFilters?.sortField, paramsFilters?.sortOrder, 'createdAt', 'desc');
            const roles = paramsFilters?.filters?.roles?.value;
            const permissions = paramsFilters?.filters?.permissions?.value;
            return {
                ...params,
                ...newParams,
                roles,
                permissions,
                sort: `${sort?.field}_${sort?.order}`,
            };
        },
    });

    const { fetch: fetchRoles, data: roles } = useFetch<null, ApiResponse<UserRoles[]>, UserRoles[]>({
        fetchAction: getRoles,
        resultInterceptor: (response) => (response?.result || []).filter((role) => role?.active === true),
    });
    useEffect(() => {
        fetchRoles();
    }, [fetchRoles]);

    const { fetch: fetchAllPermissions, data: permissions } = useFetch<
        { id: string; sort?: string },
        ApiResponse<Permission[]>,
        Permission[]
    >({
        fetchAction: getApplicationPermissions,
    });
    useEffect(() => {
        fetchAllPermissions(params);
    }, [fetchAllPermissions]);

    useEffect(() => {
        if (queryParams.roles) {
            updateFilters({
                filters: {
                    roles: {
                        value: queryParams.roles,
                    },
                },
            });
        }
        if (queryParams.permissions) {
            updateFilters({
                filters: {
                    permissions: {
                        value: queryParams.permissions,
                    },
                },
            });
        }
    }, []);

    const isKeyOfQueryParams = (key: string): key is keyof ApplicationQueryParams =>
        key === 'roles' || key === 'permissions';

    const handleFilterQueryParam = (args: ApplicationQueryFilter) => {
        const queryFilters = { ...filters, ...args };
        const filterQueryParams = Object.keys(queryFilters).reduce((acc, key) => {
            if (isKeyOfQueryParams(key)) {
                acc[key] = queryFilters[key].value;
            }
            return acc;
        }, {} as ApplicationQueryParams);
        history.push({
            pathname: location.pathname,
            search: qs.stringify(filterQueryParams, { arrayFormat: 'brackets', encode: false }),
        });
        handleFilter(args);
    };

    const onSubmit = (appData: ApplicationFormParams) => {
        formSubmit({
            newApplication,
            data: appData,
            dispatch,
            history,
            id: params.id,
        });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    useEffect(() => {
        reset({
            name: application?.name || defaultValues.name,
        });
        return () => reset(defaultValues);
    }, [reset, application]);

    useEffect(() => {
        dispatch(fetchApplication(params.id));
    }, [dispatch, params.id]);

    // we shouldn't need that code anymore with react-bootstrap@^1.0.0
    type RBRef = string & ((ref: Element | null) => void);

    return (
        <Form className="application" onSubmit={handleSubmit(onSubmit, onError)}>
            <Row className="mb-5">
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-edit" />
                            {newApplication ? (
                                <FormattedMessage id="AUTOUSER.APPLICATION.CREATE" />
                            ) : (
                                <FormattedMessage id="AUTOUSER.APPLICATION.EDIT" />
                            )}
                        </Card.Header>
                        <Card.Body className="overflow-v">
                            <Row>
                                <Col>
                                    <Form.Group controlId="name">
                                        <Form.Label>
                                            <FormattedMessage id="AUTOUSER.APPLICATION.VIEW.NAME" />
                                        </Form.Label>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            placeholder={Intl.formatMessage({
                                                id: 'AUTOUSER.APPLICATION.VIEW.NAME.PLACEHOLDER',
                                            })}
                                            className={`${errors.name ? 'is-invalid' : ''}`}
                                            ref={
                                                register({
                                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                                    minLength: 3,
                                                }) as RBRef
                                            }
                                        />
                                        <ErrorForm errors={errors} name="name" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-4 align-items-end justify-content-center">
                                <Col lg={5}>
                                    <Form.Group controlId="name">
                                        <Form.Label>
                                            <FormattedMessage id="AUTOUSER.APPLICATION.VIEW.PERMISSION" />
                                        </Form.Label>
                                        <PermissionsSelect
                                            ref={permissionSelectorRef}
                                            permissions={permissions.map((perm) =>
                                                queryPermissions.includes(`${perm.id}`)
                                                    ? { ...perm, checked: true }
                                                    : { ...perm, checked: false },
                                            )}
                                            handleFilter={handleFilterQueryParam}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={5}>
                                    <Form.Group controlId="name">
                                        <Form.Label>
                                            <FormattedMessage id="AUTOUSER.APPLICATION.VIEW.ROLE" />
                                        </Form.Label>
                                        <RolesSelect
                                            ref={roleSelectorRef}
                                            roles={roles.map((role) =>
                                                queryRoles.includes(`${role.id}`)
                                                    ? { ...role, checked: true }
                                                    : { ...role, checked: false },
                                            )}
                                            handleFilter={handleFilterQueryParam}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={2}>
                                    <Form.Group>
                                        <Button
                                            variant="outline-primary"
                                            onClick={() => setShowAddPermission(true)}
                                            className="w-100"
                                        >
                                            <FormattedMessage id="AUTOUSER.APPLICATION.PERMISSION.ADD.TITLE" />
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group>
                                {!newApplication && (
                                    <ApplicationPermissionDataTable
                                        start={start}
                                        filters={filters}
                                        sortField={sortField}
                                        sortOrder={sortOrder}
                                        data={data}
                                        loading={loading || loadingPermissions}
                                        handleFilter={handleFilterQueryParam}
                                        handleSort={handleSort}
                                        refresh={refresh}
                                    />
                                )}
                            </Form.Group>
                            <Row className="mt-4">
                                <Col className="text-right">
                                    <Link to={routeTo(ROUTES.APPLICATIONS.PATH)}>
                                        <Button variant="outline-primary">
                                            <FormattedMessage id="TRANSLATOR.BACK" />
                                        </Button>
                                    </Link>
                                    <Button variant="primary" className="ml-2" type="submit">
                                        <FormattedMessage id="TRANSLATOR.SAVE" />
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <PermissionModal showModal={showAddPermission} setShowModal={setShowAddPermission} refresh={refresh} />
        </Form>
    );
}

export default Application;
