import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';

import { getPricingReasonsByStatusSlug } from '@app/crud/stock/stock.crud';

import { useFetch } from '@app/hooks';

import ModalDefault from '@app/partials/content/modals/Modal.default';

type CancelModalProps = {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    setSubModalActive?: Dispatch<SetStateAction<boolean>>;
    setShowMainModal?: Dispatch<SetStateAction<boolean>>;
    onConfirm?: Function;
    setComment?: Function;
    setReason?: Function;
    loading: boolean;
};

const CancelModal = ({
    showModal,
    setShowModal,
    setSubModalActive = null,
    setShowMainModal = null,
    setComment,
    setReason,
    onConfirm,
    loading,
}: CancelModalProps) => {
    const Intl = useIntl();
    const [internalReason, setInternalReason] = useState(null);
    const [internalComment, setInternalComment] = useState('');
    const [reasons, setReasons] = useState(null);
    const commentRequiredLength = 10;

    const { fetch: fetchReasons, data } = useFetch({
        // @ts-ignore
        fetchAction: getPricingReasonsByStatusSlug,
    });

    const closeModal = () => {
        setShowModal && setShowModal(false);
        setSubModalActive && setSubModalActive(false);
        setShowMainModal && setShowMainModal(true);
    };

    useEffect(() => {
        setInternalReason(null);
        setInternalComment('');
    }, [showModal]);

    useEffect(() => {
        fetchReasons('refused');
    }, [fetchReasons]);

    useEffect(() => {
        setReason(internalReason);
    }, [internalReason]);

    useEffect(() => {
        setComment(internalComment);
    }, [internalComment]);

    useEffect(() => {
        if (data) {
            setReasons(
                // @ts-ignore
                data.map((item: any) => ({
                    label: item.id,
                    value: item.description,
                })),
            );
        }
    }, [data]);

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => closeModal()}
            icon={<i className="text-danger las la-times-circle fa-2x" />}
            title={
                <span className="text-danger">
                    <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.TITLE" />
                </span>
            }
            body={
                <div className="d-flex flex-column align-items-center h-100" style={{ minHeight: '10vh' }}>
                    <Form.Group className="mt-2 w-100">
                        <Form.Label>
                            <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.TITLE" />
                        </Form.Label>
                        <Form.Control
                            as="select"
                            defaultValue={-1}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setInternalReason(parseInt(e.target.value));
                            }}
                        >
                            <option value={-1} disabled>
                                {Intl.formatMessage({ id: 'STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.PLACEHOLDER' })}
                            </option>
                            {reasons &&
                                reasons.map((reason: any, key: any) => (
                                    <option key={key} value={reason.label}>
                                        {reason.value}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className="mt-2 w-100">
                        <Form.Label>
                            <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.PRECISE" />
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setInternalComment(e.target.value);
                            }}
                        />
                        <p className="mt-1 mb-0">
                            <FormattedHTMLMessage
                                id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.PRECISE.REQUIRED"
                                values={{
                                    caracters: internalComment.trim().length,
                                    requiredLength: commentRequiredLength,
                                    color:
                                        internalComment.trim().length >= commentRequiredLength ? 'success' : 'danger',
                                }}
                            />
                        </p>
                    </Form.Group>
                </div>
            }
            footer={
                <div className="d-flex justify-content-center">
                    <Button className="mr-2" variant="outline-primary" onClick={() => closeModal()}>
                        <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.CANCEL" />
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => onConfirm()}
                        disabled={!internalReason || loading || internalComment.trim().length < commentRequiredLength}
                    >
                        <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.CONFIRM" />
                    </Button>
                </div>
            }
        />
    );
};

export default CancelModal;
