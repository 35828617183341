export const GROUPS = [
    {
        id: 'GRO001',
        value: '1,12',
    },
    {
        id: 'GRO003',
        value: '13',
    },
    {
        id: 'GRO002',
        value: '7',
    },
    {
        id: 'GRO005',
        value: '10',
    },
];

/**
 *
 * @param vehicle
 * @param isContextEFB
 * @returns {boolean}
 */
export const isPublishAutosphere = (vehicle, isContextEFB = false) =>
    vehicle?.publishable_in_indices?.filter(
        (item) => item.name.includes(`vehicles_${isContextEFB ? '1_' : '5'}`) && item.active,
    ).length > 0;

/**
 *
 * @returns {*}
 */
export const publishableIndicesAutosphere = (isContextEFB = false) =>
    isContextEFB ? process.env.REACT_APP_STOCK_ABE : process.env.REACT_APP_STOCK_AFR;
