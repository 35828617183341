import React from "react";
import {Column} from "primereact/column";
import {Button, Card, Col, Row} from "react-bootstrap";
import {Filters} from "../../../partials/layout/Filters";
import {Intl} from "../../../../_metronic/i18n/I18nProvider";
import SearchInput from "../../../partials/content/SearchInput";
import {useSelector} from "react-redux";
import * as dayjs from "dayjs";
import {Link, withRouter} from "react-router-dom";
import useTableHandlers from "../../../hooks/useTableHandlers";
import {fetchCategories} from "../../../store/apv/category/category.thunk";
import RefreshTable from "../../../partials/content/RefreshTable";
import useFilters from "../../../hooks/useFilters";
import FiltersProvider from "../../../partials/content/FiltersProvider";
import {routeTo} from "../../../helpers/RoutesHelper";
import ROUTES from "../../../router/Routes";
import Permission from "../../../partials/content/Permission";
import HoCDataTable from "../../../partials/content/HoCDataTable";

function Categories({ history }) {
    const { categories, totalRecords, loading } = useSelector((state) => state.category);
    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: 'apv_categories'
    });
    const { handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: 'apv_categories',
        fetchAction: fetchCategories
    });

    const showAddPackage = () => {
        history.push(routeTo(ROUTES.CATEGORY.PATH.CREATE));
    }

    const statusBodyTemplate = (category) => {
        return (
            <span className={category?.active ? 'text-success' : 'text-danger'}>
                {category?.active ? '• Actif' : '• Inactif'}
            </span>
        )
    }

    const updatedBodyTemplate = (category) => {
        return dayjs(category.updated).format('DD/MM/YYYY [à] HH [h] mm')
    }

    const actionsBodyTemplate = (category) => {
        return <Link to={routeTo(ROUTES.CATEGORY.PATH.UPDATE, {
            id: category.id
        })}>
            <Button className="p-2 px-3" variant="secondary">
                <i className="fa fa-pen" />
                {Intl.formatMessage({ id: "TRANSLATOR.UPDATE" })}
            </Button>
        </Link>
    }

    const cols = [
        {
            field: 'family',
            header: Intl.formatMessage({ id: "APV.CATEGORY.TABLE.HEAD.FAMILY" }),
            style: {width : '30%'},
            body: (category) => category?.name
        },
        {
            field: 'parent',
            header: Intl.formatMessage({ id: "APV.CATEGORY.TABLE.HEAD.PARENT" }),
            style: {width : '30%'},
            body: (category) => category?.packageCategoryParent?.name || '-'
        },
        {
            field: 'status',
            header: Intl.formatMessage({ id: "APV.CATEGORY.TABLE.HEAD.STATUS" }),
            style: {width: '10%'},
            body: statusBodyTemplate
        },
        {
            field: 'updated',
            header: Intl.formatMessage({ id: "APV.CATEGORY.TABLE.HEAD.DATEUPDATE" }),
            style: {width : '15%'},
            body: updatedBodyTemplate
        },
        {
            field: 'actions',
            header: Intl.formatMessage({ id: "APV.CATEGORY.TABLE.HEAD.ACTIONS" }),
            style: {width : '15%'},
            body: actionsBodyTemplate
        }
    ];

    const dynamicColumns = cols.map((col) => {
        return <Column key={col.field} {...col} />;
    });

    return (
        <Card>
            <Card.Body>
                <FiltersProvider value={filters}>
                    <Filters>
                        <Row>
                            <RefreshTable count={totalRecords} onClick={refresh} />
                            <Col className="mr-4" lg={5} offset={2}>
                                <SearchInput onChange={handleFilter}
                                             name="research"
                                             placeholder="Famille, forfait, etc."/>
                            </Col>
                            <Permission permissions={['APV_ADD_CATEGORY']}>
                                <Button variant="outline-primary" onClick={() => showAddPackage()}>Créer une nouvelle famille</Button>
                            </Permission>
                        </Row>
                    </Filters>
                    <HoCDataTable
                        value={categories}
                        totalRecords={totalRecords}
                        lazy
                        loading={loading}
                        paginator
                        onFilter={handleFilter}
                        onPage={handlePagination}
                        onSort={handleSort}
                        paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                        currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                        rows={items}
                        first={start}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        removableSort
                        rowsPerPageOptions={[25,50,100]}
                        emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                    >
                        {dynamicColumns}
                    </HoCDataTable>
                </FiltersProvider>
            </Card.Body>
        </Card>
    )
}

export default withRouter(Categories);
