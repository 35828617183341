import React, { useCallback, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-dayjs-3';

import { empty } from '@app/helpers/ToolsHelper';

import Loader from '@app/partials/content/Loader';

import { dataHandler } from './helpers/Configuration';

const StockVehicleChart = ({ data, chartType, view, dateRange }) => {
    const [chartConfig, setChartConfig] = useState(null);

    const chartDataHandler = useCallback(() => {
        if (!empty(data) && !empty(dateRange)) {
            setChartConfig(dataHandler(data, chartType, view, dateRange));
        }
    }, [data]);

    useEffect(() => {
        chartDataHandler();
    }, [chartDataHandler]);

    return empty(chartConfig) ? (
        <Loader style={{ width: '5rem', height: '5rem' }} overlay />
    ) : (
        <Bar
            type="bar"
            data={{ datasets: chartConfig.datasets }}
            options={chartConfig.options}
            plugins={[chartConfig.plugins]}
            height={75}
        />
    );
};

export default StockVehicleChart;
