import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext, Validate } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { DateTimePicker } from '@app/partials/content/DateTimePicker';
import ErrorForm from '@app/partials/layout/ErrorForm';

interface FormDateTimePickerProps {
    label: ReactElement;
    name: string;
    required: boolean;
    isInvalid: boolean;
    dateFormat: string;
    placeholder?: string;
    className?: string;
    validate?: Validate;
    disabled?: boolean;
    canClear?: boolean;
    selectionMode?: string;
    minDate?: Date;
    maxDate?: Date;
}
const FormDateTimePicker = ({
    label,
    name,
    required,
    isInvalid,
    placeholder,
    className,
    validate,
    disabled,
    canClear,
    ...props
}: FormDateTimePickerProps) => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { control, errors } = methods;

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Controller
                control={control}
                name={name}
                rules={{
                    required: required && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    validate,
                }}
                render={({ value, onChange }) => (
                    <DateTimePicker
                        value={value || ''}
                        className={classNames(`form-control ${className}`, {
                            'is-invalid': isInvalid,
                        })}
                        placeholder={placeholder}
                        disabled={disabled}
                        canClear={canClear}
                        onChange={(e: { value: string }) => {
                            onChange(e.value);
                        }}
                        {...props}
                    />
                )}
            />
            <ErrorForm errors={errors} name={name} />
        </Form.Group>
    );
};

FormDateTimePicker.defaultProps = {
    placeholder: '',
    className: '',
    canClear: false,
    validate: undefined,
    disabled: false,
    selectionMode: 'single',
    minDate: null,
    maxDate: null,
};

export default FormDateTimePicker;
