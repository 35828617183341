import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { cancelOrderAppointment, getOrderStatusReason, OrderStatusReason } from '@app/crud/manufacturer/order.crud';

import { useAppSelector } from '@app/hooks';
import { fetchOrder } from '@app/store/manufacturer/order/order.thunk';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

interface CancelProps {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
}

export default function Cancel({ showModal, setShowModal }: CancelProps) {
    const params = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const Intl = useIntl();

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState<string | null>(null);
    const [orderStatus, setOrderStatus] = useState<OrderStatusReason[]>([]);

    const { order } = useAppSelector((state) => state.manufacturer.order);

    const loadModalContent = () => {
        getOrderStatusReason({
            groupSlug: 'back',
        })
            .then((response) => {
                setOrderStatus(response.result);
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                });
            });
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setStatus(event.target.value);
    };

    const onConfirmCancel = () => {
        setLoading(true);

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            confirmButtonText: Intl.formatMessage({ id: 'CONFIRMATION' }),
            showCloseButton: true,
        });

        cancelOrderAppointment(order?.appointment?.cryptedId, {
            orderStatusReason: status,
            type: 'automanager',
        })
            .then(() => {
                setStatus(null);
                setShowModal(false);
                swalWithBootstrapButtons.fire(
                    `<i class="las la-times-circle fa-2x text-primary" /> <br /> ${Intl.formatMessage({
                        id: 'MANUFACTURER.ORDER.MODAL.CANCEL.CONFIRM_TITLE',
                    })}`,
                    Intl.formatMessage(
                        { id: 'MANUFACTURER.ORDER.MODAL.CANCEL.CONFIRM_TXT' },
                        { order: order?.reference },
                    ),
                );
                dispatch(fetchOrder(params.id));
            })
            .catch(() => {
                setLoading(false);
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                });
            });
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            onEnter={loadModalContent}
            hideModal={handleClose}
            icon={<i className="las la-wrench la-2x text-danger" />}
            title={<span className="text-danger">{Intl.formatMessage({ id: 'MANUFACTURER.ORDER.VIEW.CANCEL' })}</span>}
            body={
                <>
                    <FormattedMessage id="MANUFACTURER.ORDER.VIEW.CANCEL.REASON" />
                    <Form.Group className="mt-2" controlId="exampleForm.SelectCustom">
                        <Form.Control as="select" defaultValue={-1} onChange={handleStatusChange}>
                            <option value={-1} disabled>
                                {Intl.formatMessage({ id: 'SELECT.REASON' })}
                            </option>
                            {orderStatus.map((optionStatus) => (
                                <option key={optionStatus.slug} value={optionStatus.slug}>
                                    {optionStatus.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <div className="text-center">
                        {Intl.formatMessage({ id: 'MANUFACTURER.ORDER.VIEW.CANCEL.VALIDATION' })}
                    </div>
                </>
            }
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button
                        variant="outline-secondary"
                        className="mr-3"
                        style={{ width: '60px' }}
                        onClick={handleClose}
                    >
                        {Intl.formatMessage({ id: 'TRANSLATOR.NO' })}
                    </Button>
                    <Button
                        variant="primary"
                        className="position-relative"
                        disabled={!status || loading}
                        style={{ width: '60px' }}
                        onClick={onConfirmCancel}
                    >
                        {Intl.formatMessage({ id: 'TRANSLATOR.YES' })}
                    </Button>
                </div>
            }
        />
    );
}
