import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';

import { checkLeadAudiCallTrackingStates } from '@app/crud/customers/customer.crud';

import { leadSlug } from '@app/pages/customers/Helpers/Lead';
import { actions } from '@app/store/modal/modal.store';

import Label from '@app/partials/content/Label';
import ModalDefault from '@app/partials/content/modals/Modal.default';

const ContactAudi = ({ showModal, setShowModal, lead }) => {
    const dispatch = useDispatch();
    const Intl = useIntl();
    const { fetch } = useFetch({
        fetchAction: checkLeadAudiCallTrackingStates,
    });

    const sviCode = lead?.sviCode;
    const sviMainNumber = parsePhoneNumberFromString(lead?.sviMainNumber || '', Locale.toUpperCase());

    useEffect(() => {
        if (showModal && lead?.originId && lead?.leadOrigin?.slug === leadSlug.LEAD_AUDI_ORIGIN_SLUG) {
            fetch(lead.originId).then(() => {});
        }
    }, [showModal]);

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-phone" />}
            title={Intl.formatMessage({ id: 'CUSTOMER.MODAL.CONTACT.AUDI.TITLE' })}
            body={
                <div className="text-center">
                    <div className="pb-5">
                        {Intl.formatMessage({ id: 'CUSTOMER.MODAL.CONTACT.AUDI.COMPOSE.NUMBER' })}
                    </div>

                    <div className="h2 m-5 font-weight-bold">{sviMainNumber?.formatNational?.()}</div>

                    <Label variant="primary" size="label-xxl" className="ml-2 label-large-padding mb-5">
                        Code :
                        <NumberFormat
                            value={sviCode}
                            className="font-weight-bold ml-1"
                            thousandSeparator=" "
                            displayType="text"
                        />
                    </Label>

                    <div className="m-5">
                        <FormattedHTMLMessage id="CUSTOMER.MODAL.CONTACT.AUDI.TEXT" />
                    </div>

                    <Button
                        className="p-0 text-primary m-5"
                        onClick={() =>
                            dispatch(
                                actions.modalChange('contactBackupAudi', {
                                    lead,
                                }),
                            )
                        }
                        variant="outline-link"
                    >
                        <u>
                            <FormattedHTMLMessage id="CUSTOMER.SHOW_BACKUP_PHONE_NUMBER" />
                        </u>
                    </Button>
                </div>
            }
            footer={
                <Button variant="primary" onClick={() => setShowModal(false)} className="m-auto">
                    {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                </Button>
            }
        />
    );
};

export default ContactAudi;
