import classNames from 'classnames';
import React from 'react';

const CampaignCardCell = ({ className, number, percentage }) => (
    <div
        className={classNames(
            'campaign-card mx-2 font-weight-bold flex-row align-items-center justify-content-between',
            className,
        )}
    >
        <div className="font-size-h2">{number}</div>
        <div className="font-size-h4">{percentage ? `${percentage.toFixed(1)} %` : '-'}</div>
    </div>
);

export default CampaignCardCell;
