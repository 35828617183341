import React from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { setConfirmAffectationOrderAppointment } from '@app/crud/manufacturer/order.crud';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

interface ConfirmAffectationProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    selectedOrderAppointmentId: string;
    refreshMethod: () => void;
}
export default function ConfirmAffectation({
    showModal,
    setShowModal,
    selectedOrderAppointmentId,
    refreshMethod,
}: ConfirmAffectationProps) {
    const intl = useIntl();

    const handleClose = () => {
        setShowModal(false);
    };

    const onConfirm = () => {
        setConfirmAffectationOrderAppointment(selectedOrderAppointmentId, { affected: true })
            .then((r) => {
                if (r.axios.status === 200) {
                    setShowModal(false);
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    refreshMethod && refreshMethod();
                } else {
                    throw Error();
                }
            })
            .catch(() =>
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'MANUFACTURER.ORDER.MODAL.ERROR' }),
                }),
            );
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={handleClose}
            icon={<i className="las la-user la-2x text-primary" />}
            title={
                <span className="text-primary">
                    {intl.formatMessage({ id: 'MANUFACTURER.ORDER.MODAL.CONFIRM.AFFECTATION' })}
                </span>
            }
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button
                        variant="outline-secondary"
                        className="mr-3"
                        style={{ width: '100px' }}
                        onClick={handleClose}
                    >
                        {intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button
                        variant="primary"
                        className="position-relative"
                        style={{ width: '100px' }}
                        onClick={onConfirm}
                    >
                        {intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                    </Button>
                </div>
            }
        />
    );
}
