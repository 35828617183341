import dayjs from 'dayjs';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import ModalDefault from '@app/partials/content/modals/Modal.default';

export default function Details({ setShowModal, order, showModal }) {
    const handleClose = () => {
        setShowModal(false);
    };

    let idBuyer = null;
    if (order?.orderPayment?.cn) {
        idBuyer = <span>{order.orderPayment.cn}</span>;
    } else if (order?.customer) {
        idBuyer = (
            <>
                {order.customer.firstName} {order.customer.lastName}
            </>
        );
    }

    return (
        <ModalDefault
            show={showModal}
            hideModal={handleClose}
            icon={<i className="las la-wrench la-2x text-primary" />}
            title={Intl.formatMessage({ id: 'SR.ORDER.MODAL.DETAILS' })}
            body={
                <div className="px-5">
                    {order?.paymentOnline && (
                        <>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.MODAL.DETAILS.REFERENCE' })}</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.orderPayment?.payid && <span>{order.orderPayment.payid}</span>}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.MODAL.DETAILS.DATE' })}</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.orderPayment?.created && (
                                        <span>
                                            {dayjs(order.orderPayment.created).format('DD/MM/YYYY [•] HH:mm:ss')}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </>
                    )}
                    <Row className="mb-4">
                        <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.MODAL.DETAILS.STATUS' })}</Col>
                        <Col className="font-weight-semibold" lg={6}>
                            {order?.orderAppointment?.orderAppointmentStatus?.name && (
                                <span>{order.orderAppointment.orderAppointmentStatus.name}</span>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.MODAL.DETAILS.AMOUNT' })}</Col>
                        <Col className="font-weight-semibold" lg={6}>
                            {order?.totalAmount && (
                                <NumberFormat
                                    value={order?.totalAmount}
                                    thousandSeparator=" "
                                    decimalSeparator=","
                                    decimalScale="2"
                                    fixedDecimalScale
                                    displayType="text"
                                    suffix=" €"
                                />
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.MODAL.DETAILS.METHOD' })}</Col>
                        <Col className="font-weight-semibold" lg={6}>
                            {order?.orderPayment && order?.paymentOnline ? (
                                <span>
                                    {order.orderPayment?.pm || ''} • {order.orderPayment?.brand || ''}
                                </span>
                            ) : (
                                <span>{Intl.formatMessage({ id: 'SR.ORDER.MODAL.DETAILS.PAYEMENT.DEALERSHIP' })}</span>
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.MODAL.DETAILS.BUYER' })}</Col>
                        <Col className="font-weight-semibold" lg={6}>
                            {idBuyer}
                        </Col>
                    </Row>
                </div>
            }
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button variant="primary" onClick={handleClose}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                </div>
            }
        />
    );
}
