import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { getPresenceSummary } from '../Helpers/Team';
import { TeamSchedule } from '@app/crud/schedule/schedule.type';

const AccountTeamSummary = ({ users }: { users: TeamSchedule[] }) => {
    const summary = getPresenceSummary(users);

    return (
        <div className="text-center">
            <FormattedMessage id="USER.PROFILE.TEAM.VIEW.SUMMARY" />
            <span className="text-success">
                <FormattedHTMLMessage
                    id="USER.PROFILE.TEAM.VIEW.SUMMARY.HERE"
                    values={{ number: (summary?.all_day?.length || 0) + (summary?.half_day?.length || 0) }}
                />
            </span>
            &nbsp;
            <FormattedMessage id="TRANSLATOR.OF_WHICH" />
            <span className="text-warning">
                <FormattedHTMLMessage
                    id="USER.PROFILE.TEAM.VIEW.SUMMARY.HALF"
                    values={{ number: summary?.half_day?.length }}
                />
            </span>
            ,&nbsp;
            <span className="text-danger">
                <FormattedHTMLMessage
                    id="USER.PROFILE.TEAM.VIEW.SUMMARY.ABSENT"
                    values={{ number: summary?.absent?.length }}
                />
            </span>
            ,&nbsp;
            <FormattedHTMLMessage
                id="USER.PROFILE.TEAM.VIEW.SUMMARY.NOT_SPECIFIED"
                values={{ number: summary?.not_specified?.length }}
            />
        </div>
    );
};

export default AccountTeamSummary;
