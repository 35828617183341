import { useCallback, useEffect, useState } from 'react';

import { useAppSelector } from '@app/hooks';

const useLead = (leadId, defaultLead = null) => {
    const [lead, setLead] = useState(null);
    const { data: activity } = useAppSelector((state) => state.customer.activity);
    const { data: customer } = useAppSelector((state) => state.customer.customer);

    const findActiveLead = useCallback(
        (leads) => {
            let result = false;
            leads?.forEach?.((leadData) => {
                if (!result) {
                    if (parseInt(leadData.id, 10) === parseInt(leadId, 10)) {
                        result = leadData;
                    } else if (leadData?.linkedLeads?.length > 0) {
                        result = findActiveLead(leadData?.linkedLeads);
                    }
                }
            });
            return result;
        },
        [customer, leadId],
    );

    const findClosedLead = useCallback(
        (leads) => {
            let result = false;
            leads?.forEach?.((leadData) => {
                if (!result) {
                    if (parseInt(leadData.leadId, 10) === parseInt(leadId, 10)) {
                        result = leadData;
                    }
                }
            });
            return result;
        },
        [activity, leadId],
    );

    useEffect(() => {
        if (!leadId) {
            setLead(null);
        }

        if (customer?.leads && leadId) {
            let leadState = findActiveLead(customer.leads);
            if (!leadState) {
                const allActivities = activity.reduce(
                    (acc, customerEvent) => (customerEvent?.events ? acc.concat(customerEvent.events) : []),
                    [],
                );
                leadState = findClosedLead(allActivities);
            }

            if (leadState) {
                setLead({
                    ...leadState,
                    ...(leadState?.leadId && { id: leadState.leadId }),
                });
            }
        }
    }, [findActiveLead, findClosedLead, setLead, customer, leadId]);

    return lead || defaultLead;
};

export default useLead;
