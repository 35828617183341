const getTypeColor = (type) => {
    const colors = {
        vo: 'primary',
        vn: 'warning-gold',
    };

    return colors[type?.toLowerCase?.()] || 'primary';
};

export default getTypeColor;
