import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import subHeader from '@app/partials/HoC/subHeader';

import { LayoutContextConsumer } from '../../../../../_metronic';
import { Intl } from '../../../../../_metronic/i18n/I18nProvider';
import BreadCrumbs from '../../../../../_metronic/layout/sub-header/components/BreadCrumbs';

function QuotationsSubHeader(props) {
    const { orderType } = useParams();
    const { subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle } = props;

    const getOrderType = () => {
        switch (orderType) {
            case 'waiting_for_customer':
                return Intl.formatMessage({ id: 'MENU.QUOTATION.STATUS.WAITING_FOR_CUSTOMER' });
            case 'closed':
                return Intl.formatMessage({ id: 'MENU.QUOTATION.STATUS.CLOSED' });
            default:
                return Intl.formatMessage({ id: 'MENU.QUOTATION.STATUS.TO_BE_APPRAISED' });
        }
    };

    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: 'MENU.QUOTATION.SMART.REPAIR' }),
        },
        {
            title: getOrderType(),
        },
    ];

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            type="button"
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}

                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">{Intl.formatMessage({ id: 'MENU.QUOTATION' })}</h3>
                                <BreadCrumbs items={breadcrumb} />
                            </>
                        )}
                    </LayoutContextConsumer>
                </div>
            </div>
        </div>
    );
}

export default subHeader(QuotationsSubHeader);
