import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import { empty } from '@app/helpers/ToolsHelper';

import { downloadLeadDocument } from '@app/crud/customers/achat-cash.crud';

import StatusLead from '@app/pages/customers/Partials/StatusLead';
import { LEAD_FLAGS } from '@app/pages/leadsOnline/Helpers/Flags';

import DownloadButton from './download-button';

const ActivitiesTimeline = ({ data, event, modal = false }) => {
    const colorHandler = (slug) => {
        let color;

        switch (slug) {
            case 'closed_lost':
                color = 'text-closed';
                break;
            case 'closed_won':
                color = 'text-success';
                break;
            case 'pending_90_min':
            case 'pending_30_min':
            case 'pending':
            case 'taken_90_min':
            case 'taken_30_min':
            case 'taken':
                color = 'text-danger';
                break;
            default:
                color = 'text-primary';
        }

        return color;
    };

    const looseReasonHandler = (action) => (
        <>
            {action?.reason && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedMessage id="CUSTOMERS.MODAL.LEAD_LOOSE.REACH" />
                    </span>
                    <br />
                    &nbsp;{action.reason}
                </>
            )}
            {action?.subReason && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedMessage id="CUSTOMERS.MODAL.LEAD_LOOSE.REASON" />
                    </span>
                    <br />
                    &nbsp;{action.subReason}
                </>
            )}
            {action?.result && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.COMMENT" />
                    </span>
                    &nbsp;{action.result}
                </>
            )}
        </>
    );

    const wonReasonHandler = (action) => (
        <>
            {action?.reason && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedMessage id="CUSTOMERS.MODAL.LEAD_WON.TYPE" />
                    </span>
                    <br />
                    &nbsp;{action.reason}
                </>
            )}
            {action?.result && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.COMMENT" />
                    </span>
                    &nbsp;{action.result}
                </>
            )}
            {action?.resultTool && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedHTMLMessage id="CUSTOMERS.MODAL.LEAD_WON.TOOL" />
                    </span>
                    <br />
                    &nbsp;{action.resultTool}
                </>
            )}
            {action?.resultReference && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedHTMLMessage id="CUSTOMERS.MODAL.LEAD_WON.REFERENCE" />
                    </span>
                    <br />
                    &nbsp;{action.resultReference}
                </>
            )}
            {action?.resultVehicle && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedHTMLMessage id="CUSTOMERS.MODAL.LEAD_WON.REGISTRATION" />
                    </span>
                    <br />
                    &nbsp;{action.resultVehicle}
                </>
            )}
            {action?.resultClientName && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedHTMLMessage id="CUSTOMERS.MODAL.LEAD_WON.NAME" />
                    </span>
                    <br />
                    &nbsp;{action.resultClientName}
                </>
            )}
            {action?.resultBilling && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedHTMLMessage id="CUSTOMERS.MODAL.LEAD_WON.BILLING" />
                    </span>
                    <br />
                    &nbsp;{action.resultBilling}
                </>
            )}
        </>
    );

    const scheduleAppointmentHandler = (appointment) =>
        appointment.result ? (
            <>
                <br />
                <span className="font-weight-bold">
                    <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.COMMENT" />
                </span>
                &nbsp;{appointment.result}
            </>
        ) : (
            <>
                {appointment.dateStart && (
                    <>
                        <br />
                        <span className="font-weight-bold">
                            <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.DATE_AND_TIME" />
                        </span>
                        &nbsp;{dayjs(appointment.dateStart).format('DD/MM/YYYY [à] HH:mm')}
                    </>
                )}
                {appointment.subject && (
                    <>
                        <br />
                        <span className="font-weight-bold">
                            <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.OBJECT" />
                        </span>
                        &nbsp;{appointment.subject}
                    </>
                )}
                {appointment.comment && (
                    <>
                        <br />
                        <span className="font-weight-bold">
                            <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.COMMENT" />
                        </span>
                        &nbsp;
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{ __html: appointment.comment }} />
                    </>
                )}
            </>
        );

    const financingHandler = (action) => (
        <>
            <br />
            <span className="font-weight-bold">
                <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.FINANCING" />
            </span>
            &nbsp;{action.financingType}
            {action.make && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.FINANCING_MAKE" />
                    </span>
                    &nbsp;{action.make}
                </>
            )}
            {action.model && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.FINANCING_MODEL" />
                    </span>
                    &nbsp;{action.model}
                </>
            )}
            {action.price && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.FINANCING_PRICE" />
                    </span>
                    &nbsp;
                    <NumberFormat
                        value={action.price}
                        thousandSeparator=" "
                        fixedDecimalScale
                        displayType="text"
                        suffix=" €"
                    />
                </>
            )}
            {action.registration && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.FINANCING_REGISTRATION" />
                    </span>
                    &nbsp;{action.registration}
                </>
            )}
            {action.isWaxyol && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedMessage id="CUSTOMERS.MODAL.PURCHASE.WAXYOL" />
                    </span>
                    &nbsp;
                    <FormattedMessage id="TRANSLATOR.YES" />
                </>
            )}
            {action.isCertification && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedMessage id="CUSTOMERS.MODAL.PURCHASE.ENGRAVING" />
                    </span>
                    &nbsp;
                    <FormattedMessage id="TRANSLATOR.YES" />
                </>
            )}
        </>
    );

    const reassignmentHandler = (action) => (
        <>
            {action?.previousOwner && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.PREVIOUS_OWNER" />
                    </span>
                    &nbsp;{action.previousOwner.lastname}&nbsp;{action.previousOwner.firstname}
                </>
            )}
            {action?.currentOwner && (
                <>
                    <br />
                    <span className="font-weight-bold">
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.CURRENT_OWNER" />
                    </span>
                    &nbsp;{action.currentOwner.lastname}&nbsp;{action.currentOwner.firstname}
                </>
            )}
        </>
    );

    const achatCashContractHandler = (activity) => {
        if (!activity.documents?.[0]) {
            return (
                <>
                    <br />
                    <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.ACHAT_CASH.DOCUMENT_DELETED" />
                </>
            );
        }
        return (
            <>
                <br />
                <DownloadButton
                    label={
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.ACHAT_CASH.CONTRACT" />
                    }
                    disabled={!activity.documents?.[0]}
                    route={() => downloadLeadDocument(activity.documents[0])}
                />
            </>
        );
    };

    const achatCashCerfaHandler = (activity) => {
        if (!activity.documents?.[0]) {
            return (
                <>
                    <br />
                    <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.ACHAT_CASH.DOCUMENT_DELETED" />
                </>
            );
        }
        return (
            <>
                <br />
                <DownloadButton
                    label={
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.ACHAT_CASH.CERFA" />
                    }
                    route={() => downloadLeadDocument(activity.documents[0])}
                />
            </>
        );
    };

    const activityStatusHandler = (activityStatus) =>
        activityStatus?.status?.appointment?.success !== null && (
            <>
                <br />
                <span>
                    {activityStatus.status.appointment.success ? (
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.ACTIVITY.APPOINTEMENT.DONE" />
                    ) : (
                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.ACTIVITY.APPOINTEMENT.NOT.DONE" />
                    )}
                </span>
            </>
        );

    const timelineHandler = useMemo(
        () =>
            data.map((activity) => (
                <React.Fragment key={activity.id}>
                    {activity?.type?.slug !== 'update_lead_status' && (
                        <div className="timeline-item align-items-start">
                            <div className="timeline-label text-right" style={{ fontSize: '14px' }}>
                                {dayjs(activity.created).format('DD/MM/YYYY')}
                                <span className="font-weight-normal">
                                    &nbsp;{dayjs(activity.created).format('[à] HH:mm')}
                                </span>
                                <br />
                                <div className="font-weight-normal" style={{ fontSize: '12px' }}>
                                    {activity?.user?.firstname} {activity?.user?.lastname}
                                    <br />
                                    <span className="font-weight-bold">
                                        <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.ROLE" />
                                    </span>
                                    &nbsp;{activity?.user?.role}
                                </div>
                            </div>
                            <div className="timeline-badge timeline-badge__activity">
                                <i className={`fa fa-genderless icon-xl ${colorHandler(activity?.status?.slug)}`} />
                            </div>
                            <div className="font-weight-normal timeline-content pl-3">
                                <span className={`font-weight-bold ${colorHandler(activity?.status?.slug)}`}>
                                    {activity?.type?.slug === 'consign_lead_status' ? (
                                        <>
                                            {activity.status.name}&nbsp;{activity.status.subName}
                                        </>
                                    ) : (
                                        <>
                                            {activity?.type?.name}
                                            {activityStatusHandler(activity)}
                                        </>
                                    )}
                                </span>
                                {activity?.status?.slug === 'closed_won' && wonReasonHandler(activity?.action)}
                                {activity?.status?.slug === 'closed_lost' && looseReasonHandler(activity?.action)}
                                {activity?.action?.financingType && financingHandler(activity?.action)}
                                {activity?.action?.scheduleAppointment &&
                                    scheduleAppointmentHandler(activity.action.scheduleAppointment)}
                                {activity?.type?.slug === 'reassignment_lead' && reassignmentHandler(activity?.action)}
                                {activity?.type?.slug === 'transfer_lead_to_dealer' &&
                                    reassignmentHandler(activity?.action)}
                                {activity?.type?.slug === 'send_achat_cash_contract' &&
                                    achatCashContractHandler(activity)}
                                {activity?.type?.slug === 'send_achat_cash_cerfa' && achatCashCerfaHandler(activity)}
                                {activity?.type?.slug === 'generate_achat_cash_contract' &&
                                    achatCashContractHandler(activity)}
                                {activity?.type?.slug === 'generate_achat_cash_cerfa' &&
                                    achatCashCerfaHandler(activity)}

                                {activity?.action?.object && (
                                    <>
                                        <br />
                                        <span className="font-weight-bold">
                                            <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.OBJECT" />
                                        </span>
                                        &nbsp;{activity.action.object}
                                    </>
                                )}
                                {activity?.action?.comment && (
                                    <>
                                        <br />
                                        <span className="font-weight-bold">
                                            <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.COMMENT" />
                                        </span>
                                        &nbsp;{activity.action.comment}
                                    </>
                                )}
                                {activity?.status?.slug !== 'closed_won' &&
                                    activity?.status?.slug !== 'closed_lost' &&
                                    activity?.action?.result && (
                                        <>
                                            <br />
                                            <span className="font-weight-bold">
                                                <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.COMMENT" />
                                            </span>
                                            &nbsp;{activity.action.result}
                                        </>
                                    )}
                                {activity?.action?.didStatusSlug &&
                                    activity?.action?.didStatusSlug === 'transfert-a-ete-fait' && (
                                        <>
                                            <span className="ml-3">{LEAD_FLAGS['transfert-a-ete-fait']?.icon}</span>
                                            <br />
                                            <FormattedMessage
                                                id="CUSTOMER.VIEW.LIFE.DETAIL.HISTORY.CALL_TRANSFERT"
                                                values={{
                                                    lastname: activity?.user?.lastname,
                                                    firstname: activity?.user?.firstname,
                                                }}
                                            />
                                        </>
                                    )}
                                {event && (
                                    <StatusLead
                                        leadStatusUpdated={null}
                                        className="d-block d-xxl-flex align-items-center flex-wrap mt-2"
                                        statusSlug={activity?.status?.slug}
                                        statusName={activity?.status?.name}
                                        statusSubName={activity?.status?.subName}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )),
        [data],
    );

    return (
        !empty(data) && (
            <div className={`timeline-handler${modal ? '-modal' : ''}`}>
                <div className="timeline h-100" style={{ maxWidth: 'none' }}>
                    {timelineHandler}
                </div>
            </div>
        )
    );
};

export default ActivitiesTimeline;
