import ApiGateway, { CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { LeadMappingSourcesTypes } from '@app/crud/autouser/lead.types';

const { REACT_APP_LEAD_URL } = process.env;

// eslint-disable-next-line import/prefer-default-export
export function getLeadMapping() {
    return ApiGateway.get<LeadMappingSourcesTypes, CustomAxiosResponse<LeadMappingSourcesTypes>>(
        `${REACT_APP_LEAD_URL}/lead-mapping/types_sources`,
    );
}
