import classNames from 'classnames';
import dayjs from 'dayjs';
import { AnimateSharedLayout, motion } from 'framer-motion';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';

import { countHowManyDayIn } from '@app/helpers/DateHelper';
import { capitalize } from '@app/helpers/StringHelper';

import { searchAvailabilities, updateAvailability } from '@app/crud/account/account.crud';

import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';
import toast from '@app/partials/content/Toast';

import { DEFAULT_WEEK } from '../Helpers/Planning';

import WeekRow from './WeekRow';

const container = {
    hidden: { opacity: 1 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1,
        },
    },
};

const item = {
    hidden: { y: -20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
    },
};

const Planning = ({ userData, onSectionChange, profile }) => {
    const now = dayjs();
    const [selectedDate, setSelectedDate] = useState(now);
    const {
        fetch,
        data: availabilites,
        loading,
        setData,
    } = useFetch({
        fetchAction: searchAvailabilities,
    });

    const firstDayOfMonth = selectedDate.date(1);
    let firstMondayOfWeek = firstDayOfMonth.weekday(0);
    const lastDayOfMonth = selectedDate.date(selectedDate.daysInMonth());

    // Get how many monday in the month and add one week if first day of month isn't a monday
    const monday = 1;
    let weeksOnMonth = countHowManyDayIn(monday, firstDayOfMonth, lastDayOfMonth);

    if (firstDayOfMonth.day() !== monday) {
        weeksOnMonth += 1;
    }

    const months = new Array(12).fill(undefined).map((_, idx) => now.add(idx, 'month'));

    const weeks = new Array(weeksOnMonth).fill(undefined).map(() => {
        const days = new Array(7).fill(undefined).map((_, idx) => firstMondayOfWeek.add(idx, 'day'));
        firstMondayOfWeek = firstMondayOfWeek.add(7, 'day');
        return days;
    });

    const getWeekAvailabilities = (days) =>
        days.reduce((acc, day) => {
            const formattedDay = day.format('YYYY-MM-DD');

            if (availabilites[formattedDay]) {
                const dayName = day.locale('en').format('dddd').toLowerCase();
                acc[dayName] = availabilites[formattedDay];
            }

            return acc;
        }, {});

    useEffect(() => {
        fetch({
            dateStart: firstDayOfMonth.format('YYYY-MM-DD'),
            dateEnd: lastDayOfMonth.format('YYYY-MM-DD'),
            userId: userData.id,
        });
    }, [fetch, selectedDate]);

    const handleUpdate = (day, start, end, absent, hour) => {
        const availability = availabilites[day.format('YYYY-MM-DD')];
        const date = day.format('YYYY-MM-DD');

        return updateAvailability(userData.id, {
            date,
            data: {
                ...availability,
                [hour]: {
                    start,
                    end,
                    absent,
                },
            },
        })
            .then((response) => {
                if (response?.result) {
                    setData({
                        ...availabilites,
                        [date]: response.result.scheduleAvailability[date],
                    });
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage(
                            { id: 'USER.PROFILE.PLANNING.AVAILABILITY.UPDATE.SUCCESS' },
                            {
                                date: day.format('DD-MM-YYYY'),
                            },
                        ),
                    });
                } else {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.AVAILABILITY.UPDATE.FAILED' }),
                    });
                }
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.AVAILABILITY.UPDATE.FAILED' }),
                });
            });
    };

    return (
        <div>
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <div className="d-flex mb-7  align-items-center justify-content-between">
                <div className="font-size-h5 font-weight-bold">
                    {profile
                        ? Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.MANAGE' })
                        : Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.ADMIN.MANAGE' })}
                </div>
                <Permission permissions={['LEAD_UPDATE_TYPICAL_PLANNING_PROFIL']}>
                    <Button variant="outline-primary" onClick={() => onSectionChange(DEFAULT_WEEK)}>
                        {profile
                            ? Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.UPDATE' })
                            : Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.ADMIN.UPDATE' })}
                    </Button>
                </Permission>
            </div>
            {profile && (
                <div className="mb-10 font-size-sm">
                    <FormattedHTMLMessage id="USER.PROFILE.PLANNING.PLANNING.TITLE" />
                    <br />
                    <span className="text-danger">
                        {' '}
                        {Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.PLANNING.SUBTITLE' })}
                    </span>
                </div>
            )}
            <AnimateSharedLayout>
                <div className="day-update__months">
                    {months.map((month) => (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                        <div
                            key={month}
                            onClick={() => setSelectedDate(month)}
                            className={classNames('month', {
                                'month--active': selectedDate.month() === month.month(),
                            })}
                        >
                            {selectedDate.month() === month.month() && (
                                <motion.div className="month--selected" layoutId="selected-menu" />
                            )}
                            <span className="month__name">{capitalize(month.format('MMM'))}</span>
                        </div>
                    ))}
                </div>
            </AnimateSharedLayout>
            <Divider className="my-7 border-secondary" type="dotted" />
            <motion.div variants={container} initial="hidden" animate="visible">
                <AnimateSharedLayout>
                    {weeks.map((days, key) => {
                        const weekAvailabilities = getWeekAvailabilities(days);
                        return (
                            <motion.div key={key} variants={item} layout>
                                <WeekRow
                                    format="ddd D"
                                    availabilities={weekAvailabilities}
                                    month={selectedDate.month()}
                                    onUpdate={handleUpdate}
                                    loading
                                    disableOuterDays
                                    days={days}
                                    application="PROFIL"
                                    permissions={['LEAD_UPDATE_CALENDAR_PROFIL']}
                                />
                                {key + 1 < weeks.length && <Divider className="my-10 border-secondary" type="dotted" />}
                            </motion.div>
                        );
                    })}
                </AnimateSharedLayout>
            </motion.div>
        </div>
    );
};

export default Planning;
