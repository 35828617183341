import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { Button, Card, Tab, Tabs } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import usePermissions from '@app/hooks/usePermissions';
import useQueryParams from '@app/hooks/useQueryParams';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { getSort } from '@app/helpers/DataTableHelper';

import { getLeadByStatus } from '@app/crud/customers/customer.crud';

import { useModal } from '@app/hooks';
import { actions as modalActions } from '@app/store/modal/modal.store';

import FiltersProvider from '@app/partials/content/FiltersProvider';

import { getCurrentMonthPeriod, getPrevMonthPeriod } from '../dashboard/helpers/DashboardHelper';

import LeadOnlineDataTable from './LeadOnlineDataTable';
import ExportLeads from './Modals/ExportLeads';
import ActivityLeadOnline from './Partials/ActivityLeadOnline';
import LegendButton from './Partials/LegendButton';

const counters = {
    new: 'lead_new',
    'action-to-do': 'lead_action_to_do',
    'in-progress': 'lead_in_progress',
};

const LeadsOnline = () => {
    const Intl = useIntl();
    const [loadingActivity, setLoadingActivity] = useState(false);
    const [activeTab, setActiveTab] = useState('all');
    const { statusType } = useParams();
    const { hasPermission } = usePermissions();
    const queryParams = useQueryParams();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [showExportLeads, setShowExportLeads] = useModal(false, 'export_leads');

    const {
        items,
        start,
        filters,
        updateFilters,
        sortField,
        sortOrder,
        updatePendingFilters,
        releasePendingFilters,
        resetFilter,
    } = useFilters({
        name: `leads_online_${statusType}`,
    });

    const { data, loading, handleFilter, handlePagination, handleSort } = useTableHandlers({
        filterStore: `leads_online_${statusType}`,
        fetchAction: getLeadByStatus,
        counterName: counters[statusType],
        withStore: false,
        needUrlParams: true,
        paramsInterceptor: (params, interceptorFilters) => {
            // eslint-disable-next-line prefer-const
            let { leadType, leadTypeGroup, leadStatus, leadOrigin } = interceptorFilters?.filters || {};
            const sort = getSort(interceptorFilters?.sortField, interceptorFilters?.sortOrder, 'created', 'desc');

            if (leadTypeGroup?.value === 'operator') {
                leadOrigin = leadTypeGroup;
                leadTypeGroup = [];
            }

            return {
                ...params,
                page: interceptorFilters.page,
                limit: interceptorFilters.items,
                leadType: leadType && leadType?.value ? [leadType?.value] : [],
                notLeadType: ['achat_cash_abandon', 'local_apv', 'apv_in_store'],
                leadTypeGroup: leadTypeGroup && leadTypeGroup?.value ? [leadTypeGroup?.value] : [],
                leadStatus: leadStatus && leadStatus?.value ? [leadStatus?.value] : [],
                leadOrigin: leadOrigin && leadOrigin?.value ? leadOrigin?.value : [],
                sort: `${sort?.field}_${sort?.order}`,
            };
        },
    });

    const getFiltersDefaultValues = () => {
        if (!location?.state) {
            const defaultValues = {};
            Object.entries(queryParams).forEach(([key, value]) => {
                defaultValues[key] = { value };
            });
            return defaultValues;
        }
        return location?.state?.defaultFiltersValue;
    };

    if (Object.keys(queryParams).length) {
        if (queryParams?.leadTypeGroup) {
            setActiveTab(queryParams?.leadTypeGroup);
        }
        updateFilters({
            filters: {
                ...getFiltersDefaultValues(),
            },
        });
        history.replace(location.pathname);
    }

    useEffect(() => {
        setActiveTab('all');
    }, [statusType, queryParams]);

    useEffect(() => {
        handleFilter({
            leadTypeGroup: {
                value: activeTab !== 'all' ? activeTab : null,
            },
            leadOrigin: {
                value: null,
            },
            status: {
                value: statusType.replaceAll('-', '_'),
            },
        });
    }, [handleFilter, activeTab, statusType]);

    const getOriginOptions = () => {
        const options = [
            {
                title: Intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.RECEIPTED.ALL' }),
                value: null,
            },
        ];

        if (data?.facets?.leadOrigins) {
            data.facets.leadOrigins.forEach((facet) => {
                options.push({
                    title: facet?.label,
                    value: facet?.key,
                });
            });
        }

        return options;
    };

    const handleExportClick = () => {
        dispatch(modalActions.modalChange('export_leads'));
    };

    return (
        <FiltersProvider value={filters}>
            <Card className="p-4 position-relative" key={statusType}>
                <div className="btn-legend d-flex flex-wrap align-items-center justify-content-between">
                    {hasPermission('LEAD_EXPORT_CLOSE_LEAD_FID') && statusType === 'close' && (
                        <>
                            <Button variant="outline-primary" className="mr-4" onClick={handleExportClick}>
                                <FormattedMessage id="TRANSLATOR.EXPORT" />
                            </Button>
                            <ExportLeads showModal={showExportLeads} setShowModal={setShowExportLeads} />
                        </>
                    )}
                    {hasPermission('LEAD_SHOW_ORIGIN_FILTER') && (
                        <Dropdown
                            value={filters.leadOrigin?.value}
                            options={getOriginOptions()}
                            onChange={(e) => {
                                handleFilter({
                                    leadOrigin: {
                                        value: e.value,
                                    },
                                });
                            }}
                            optionLabel="title"
                            optionValue="value"
                            className="mr-4"
                            placeholder={Intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.ORIGIN.ALL' })}
                        />
                    )}
                    <Dropdown
                        value={filters.receiptedPeriod?.value}
                        options={[
                            {
                                title: Intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.RECEIPTED.ALL' }),
                                value: null,
                            },
                            {
                                title: Intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.RECEIPTED.CURRENT' }),
                                value: getCurrentMonthPeriod(),
                            },
                            {
                                title: Intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.RECEIPTED.PREV' }),
                                value: getPrevMonthPeriod(),
                            },
                        ]}
                        onChange={(e) => {
                            handleFilter({
                                receiptedPeriod: {
                                    value: e.value,
                                },
                            });
                        }}
                        optionLabel="title"
                        optionValue="value"
                        placeholder={Intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.RECEIPTED.ALL' })}
                    />
                    <LegendButton
                        updatePendingFilters={updatePendingFilters}
                        releasePendingFilters={releasePendingFilters}
                        resetFilter={resetFilter}
                        activeTab={activeTab}
                    />
                </div>

                <Tabs
                    className="nav-tabs-line nav-tabs-line-primary nav-tabs-line-2x"
                    defaultActiveKey="all"
                    activeKey={activeTab}
                    id={activeTab}
                    onSelect={(e) => setActiveTab(e)}
                >
                    {data?.counter?.length &&
                        data.counter.map((tab) => (
                            <Tab
                                tabClassName="px-4 font-weight-bold"
                                eventKey={tab.key}
                                title={`${tab.label} (${tab.doc_count})`}
                                key={`${tab.label}-${tab.key}`}
                            >
                                {activeTab === tab.key && (
                                    <LeadOnlineDataTable
                                        statusType={statusType}
                                        items={items}
                                        start={start}
                                        filters={filters}
                                        sortField={sortField}
                                        sortOrder={sortOrder}
                                        data={data}
                                        loading={loading || loadingActivity}
                                        handleFilter={handleFilter}
                                        handlePagination={handlePagination}
                                        handleSort={handleSort}
                                        facetType={tab.key}
                                    />
                                )}
                            </Tab>
                        ))}
                </Tabs>
            </Card>

            <ActivityLeadOnline loading={loadingActivity} setLoading={setLoadingActivity} />
        </FiltersProvider>
    );
};

export default LeadsOnline;
