import classNames from 'classnames';
import dayjs from 'dayjs';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import KTUtil from '@src/_metronic/_assets/js/util';
import { Intl, Locale } from '@src/_metronic/i18n/I18nProvider';

import { ucwords } from '@app/helpers/StringHelper';
import { empty } from '@app/helpers/ToolsHelper';

import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';
import Permission from '@app/partials/content/Permission';

import CustomerRow from './Partials/CustomerRow';
import LeadStatusRow from './Partials/LeadStatusRow';
import { actions } from '@app/store/customers/customers.store';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const CustomerDataTable = ({
    customers,
    totalRecords,
    handleFilter,
    handlePagination,
    handleSort,
    sortField,
    sortOrder,
    loading,
    items,
    start,
    columns,
    displayCheckbox = false,
}) => {
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.auth);
    const { selectedCustomers } = useSelector((state) => state.customer);
    const context = userData.context.toUpperCase();

    const inputFilter = (name) => <InputFilter name={name} onChange={handleFilter} />;

    const getCustomerId = (customer) => {
        if (customer?.id) {
            return customer.id;
        } else if (context === 'BERNARD') {
            return 'RCU';
        }

        return 'VCU';
    };

    const renderFusionTooltip = (props, id, message) => (
        <Tooltip id={id} {...props}>
            <FormattedMessage id={message} />
        </Tooltip>
    );

    const infoBody = (customer) => {
        const checked =
            selectedCustomers?.find((selectedCustomer) => {
                if (selectedCustomer.id) {
                    return selectedCustomer.id === customer.id;
                } else if (selectedCustomer.vcuId) {
                    return selectedCustomer.vcuId === customer.vcuId;
                }
                return false;
            }) !== undefined;
        const disabledCheckbox = customer?.isPro === true || (selectedCustomers.length >= 10 && checked === false);
        return (
            <div className="d-flex flex-nowrap align-items-start">
                {displayCheckbox && (
                    <Permission permissions={['CONTACT_FUSION']}>
                        <OverlayTrigger
                            trigger={disabledCheckbox ? 'hover' : null}
                            placement="top"
                            overlay={(props) =>
                                renderFusionTooltip(
                                    props,
                                    'fusion-checkbox-tooltip',
                                    customer?.isPro === true
                                        ? 'CUSTOMER.VIEW.ACTIONS.FUSION_RESTRICTION'
                                        : 'CUSTOMER.VIEW.ACTIONS.FUSION_LIMIT',
                                )
                            }
                        >
                            <input
                                type="checkbox"
                                aria-label={Intl.formatMessage({ id: 'CUSTOMERS.TABLE.CHECKBOX' })}
                                className="p-checkbox--small mr-3 mt-1"
                                checked={checked}
                                disabled={disabledCheckbox}
                                onClick={(e) => {
                                    if (e?.target?.checked) {
                                        dispatch(actions.addSelectedCustomers(customer));
                                    } else {
                                        dispatch(actions.removeSelectedCustomers(customer));
                                    }
                                }}
                            />
                        </OverlayTrigger>
                    </Permission>
                )}
                <div>
                    <span className="d-lg-block d-none">
                        Id: {getCustomerId(customer)}
                        <br />
                    </span>
                    <span
                        className={classNames({
                            'text-primary': customer?.isClient,
                            'text-danger': !customer?.isClient,
                        })}
                    >
                        &bull;&nbsp;
                        {(customer?.isPro &&
                            !customer?.isClient &&
                            `${Intl.formatMessage({ id: 'TRANSLATOR.PROSPECT' })} (${Intl.formatMessage({
                                id: 'TRANSLATOR.PRO',
                            })})`) ||
                            (!customer?.isClient && Intl.formatMessage({ id: 'TRANSLATOR.PROSPECT' })) ||
                            (customer?.isPro &&
                                customer?.isClient &&
                                `${Intl.formatMessage({ id: 'TRANSLATOR.CLIENT' })} ${Intl.formatMessage({
                                    id: 'TRANSLATOR.PRO',
                                })}`) ||
                            (customer?.isClient && Intl.formatMessage({ id: 'TRANSLATOR.CLIENT' }))}
                    </span>
                </div>
            </div>
        );
    };

    const nameBody = (customer) => {
        if (customer?.isPro) {
            if (!customer.companyName) {
                return '-';
            }

            return (
                <span>
                    {customer?.companyName ? (
                        <>
                            {customer.companyName}
                            <br className="d-lg-block d-none" />
                        </>
                    ) : (
                        ''
                    )}
                    <div className="d-lg-block d-inline-block ml-lg-none ml-1" />
                </span>
            );
        }
        if (!customer.lastname && !customer.firstname) {
            return '-';
        }

        return (
            <span>
                {customer?.lastname ? (
                    <>
                        {customer.lastname}
                        <br className="d-lg-block d-none" />
                    </>
                ) : (
                    ''
                )}
                <div className="d-lg-block d-inline-block ml-lg-none ml-1" />
                {customer?.firstname || ''}
            </span>
        );
    };

    const phoneBody = (customer, html = true) => {
        if (!customer.mobile && !customer.phone) {
            return null;
        }

        const phone = parsePhoneNumberFromString(customer?.phone || '', Locale.toUpperCase());
        const mobile = parsePhoneNumberFromString(customer?.mobile || '', Locale.toUpperCase());

        if (!html) {
            return (mobile && mobile.formatNational()) || (phone && phone.formatNational());
        }

        return (
            (mobile || phone) && (
                <>
                    {(mobile && mobile.formatNational()) || (phone && phone.formatNational())}
                    <br />
                </>
            )
        );
    };

    const cityBody = (customer) => {
        if (customer?.isPro) {
            return <span>{customer?.city && <span>{customer.city}</span>}</span>;
        }
        if (!customer.zipcode && !customer.city) {
            return (
                <i style={{ fontSize: '12px' }}>{Intl.formatMessage({ id: 'CUSTOMERS.TABLE.DATA.DEFAULT.CITY' })}</i>
            );
        }

        return (
            <span>
                {customer?.zipcode && (
                    <>
                        {customer.zipcode}
                        <br />
                    </>
                )}
                {customer?.city || ''}
            </span>
        );
    };

    const contactBody = (customer) => {
        if (customer?.isPro) {
            if (!customer.companyPhone) {
                return '-';
            }

            return (
                <>
                    {customer?.gdprDealerships && (
                        <div className="d-flex">
                            <div>
                                {customer?.gdprDealerships[0]?.gdprPhone ? (
                                    <i className="las la-mobile kt-font-success" />
                                ) : (
                                    <i className="las la-mobile kt-font-danger" />
                                )}
                                {customer?.gdprDealerships[0]?.gdprEmail ? (
                                    <i className="las la-comments kt-font-success" />
                                ) : (
                                    <i className="las la-comments kt-font-danger" />
                                )}
                                <br />
                                {customer?.gdprDealerships[0]?.gdprSms ? (
                                    <i className="las la-envelope kt-font-success" />
                                ) : (
                                    <i className="las la-envelope kt-font-danger" />
                                )}
                            </div>
                            <div>
                                &nbsp; {customer?.companyPhone || ''} <br />
                            </div>
                        </div>
                    )}
                    {!customer?.gdprDealerships && (
                        <>
                            &nbsp; {customer?.companyPhone || ''} <br />{' '}
                        </>
                    )}
                    {KTUtil.isSmallDesktopOrMobileDevice() && <div className="mt-1">{cityBody(customer)}</div>}
                </>
            );
        }
        if (!customer.mobile && !customer.phone && !customer.email) {
            return '-';
        }

        return (
            <>
                {customer?.gdprDealerships && (
                    <div className="d-flex">
                        {customer?.id && (
                            <div>
                                {customer?.gdprDealerships[0]?.gdprPhone ? (
                                    <i className="las la-mobile kt-font-success" />
                                ) : (
                                    <i className="las la-mobile kt-font-danger" />
                                )}
                                {customer?.gdprDealerships[0]?.gdprEmail ? (
                                    <i className="las la-comments kt-font-success" />
                                ) : (
                                    <i className="las la-comments kt-font-danger" />
                                )}
                                <br />
                                {customer?.gdprDealerships[0]?.gdprSms ? (
                                    <i className="las la-envelope kt-font-success" />
                                ) : (
                                    <i className="las la-envelope kt-font-danger" />
                                )}
                            </div>
                        )}
                        <div>
                            {customer?.phone || customer?.mobile ? <>&nbsp;{phoneBody(customer)}</> : <br />}
                            <p className="text-flowing">&nbsp;{customer?.email || ''}</p>
                        </div>
                    </div>
                )}

                {!customer?.gdprDealerships && (
                    <span>
                        {phoneBody(customer)}
                        {customer?.email || ''}
                    </span>
                )}
                {KTUtil.isSmallDesktopOrMobileDevice() && <div className="mt-1">{cityBody(customer)}</div>}
            </>
        );
    };

    const dealershipBody = (customer) => {
        if (!customer?.dealershipKnow?.length) {
            return <>-</>;
        }

        if (!customer.dealershipKnow[0].refDealershipName) {
            return <>-</>;
        }

        const othersDealerships = customer.dealershipKnow
            .filter((element, index) => index !== 0)
            .map((element) => element.refDealershipName);

        return (
            <span className="d-flex justify-content-between align-items-end">
                <span className="flex-grow-1">
                    {ucwords(customer.dealershipKnow[0].refDealershipName.toLowerCase())}
                </span>
                {!empty(othersDealerships) && (
                    <OverlayTooltip label={othersDealerships.join('<hr />')} position="top" translate={false}>
                        <span className="label label-primary">+{othersDealerships.length}</span>
                    </OverlayTooltip>
                )}
            </span>
        );
    };

    const vehicleBody = (customer) => {
        if (!customer?.vehicleKnow?.length || customer?.vehicleKnow?.length === 0) {
            return <>-</>;
        }

        if (empty(customer.vehicleKnow[0].brand) && empty(customer.vehicleKnow[0].model)) {
            return <>-</>;
        }

        const vehicles = customer.vehicleKnow
            .map((vehicle) => {
                let brand = '';
                let model = '';

                if (!empty(vehicle.brand)) {
                    brand = ucwords((vehicle?.brand ?? '').toLowerCase());
                }
                if (!empty(vehicle.model)) {
                    model = ucwords((vehicle?.model ?? '').toLowerCase());
                }

                const registrationNumber = vehicle?.registrationNumber ?? '';
                const vin = vehicle?.vin ?? '';

                return [
                    [brand, model].filter((e) => !empty(e)).join(' '),
                    [registrationNumber, vin].filter((e) => !empty(e)).join(' '),
                ];
            })
            .filter((e) => !empty(e));
        const firstVehicleData = vehicles.shift();

        return (
            <span className="d-flex justify-content-between align-items-end">
                <span className="d-flex justify-content-between align-items-center">
                    {firstVehicleData &&
                        firstVehicleData.map((row, index) => (
                            <>
                                {row}
                                {index !== firstVehicleData.length - 1 && <br />}
                            </>
                        ))}
                </span>

                {!empty(vehicles) && (
                    <OverlayTooltip
                        id="customer-vehicle-tooltip"
                        label={vehicles.map((vehicle) => vehicle.join(`<br>`)).join('<hr />')}
                        position="top"
                        translate={false}
                    >
                        <span className="label label-primary">+{vehicles.length}</span>
                    </OverlayTooltip>
                )}
            </span>
        );
    };

    const activityBody = (customer) => {
        const isOnline = ['Lead VO', 'Lead VD', 'Lead VN'].includes(customer?.leadTypeName);

        if (!customer?.leadStatusName && !customer?.leadStatusSubName) {
            return (
                <i style={{ fontSize: '12px' }}>
                    {Intl.formatMessage({ id: 'CUSTOMERS.TABLE.DATA.DEFAULT.ACTIVITY' })}
                </i>
            );
        }

        return (
            <>
                <Permission permissions={['LEAD_SHOW_SOURCE']}>
                    {(customer?.leadTypeName || customer?.leadTypeSlug) && (
                        <div className="d-block">
                            {customer?.leadTypeName && customer?.leadTypeName}
                            {isOnline ? ' Online' : ''}&nbsp;
                            {customer?.leadTypeSlug && (
                                <div className={`d-inline-flex lead-history__source--${customer?.leadSourceSlug}`}>
                                    {customer?.leadSourceName && <>&bull;&nbsp;{customer?.leadSourceName}</>}
                                </div>
                            )}
                        </div>
                    )}
                </Permission>
                <LeadStatusRow
                    leadStatusSlug={customer?.leadStatusSlug}
                    leadStatusName={customer?.leadStatusName}
                    leadStatusSubName={customer?.leadStatusSubName}
                    shift={false}
                    className="d-flex align-items-center"
                />
            </>
        );
    };

    const updatedBody = (customer) => {
        if (!customer?.updated) {
            return <>-</>;
        }

        return (
            <span>
                {customer?.leadStatusUpdate ? (
                    <>
                        {dayjs(customer.leadStatusUpdate).format('DD/MM/YYYY')}
                        <br className="d-lg-block d-none" />
                        {dayjs(customer.leadStatusUpdate).format(' [à] HH [h] mm')}
                    </>
                ) : (
                    <>
                        {dayjs(customer.updated).format('DD/MM/YYYY')}
                        <br className="d-lg-block d-none" />
                        {dayjs(customer.updated).hour() > 0 &&
                            dayjs(customer.updated).minute() > 0 &&
                            dayjs(customer.updated).format(' [à] HH [h] mm')}
                    </>
                )}
            </span>
        );
    };

    const referentBody = (customer) => {
        if (!customer?.ownerFirstname || !customer?.ownerLastname) {
            return '-';
        }

        return (
            <span>
                {customer.ownerFirstname} {customer.ownerLastname}
                {KTUtil.isSmallDesktopOrMobileDevice() && <div className="mt-1">{updatedBody(customer)}</div>}
            </span>
        );
    };

    const cols = [
        {
            field: 'contactId',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.INFO' }),
            body: infoBody,
            style: { width: '90px' },
            filter: true,
            filterElement: inputFilter('contactId'),
            ...(columns?.info ? columns.info : {}),
        },
        {
            field: 'contactName',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.NAME' }),
            body: nameBody,
            style: { width: '120px' },
            filter: true,
            filterElement: inputFilter('contactName'),
            ...(columns?.name ? columns.name : {}),
        },
        {
            field: 'contactInfoContact',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.CONTACT' }),
            body: contactBody,
            filter: true,
            filterElement: inputFilter('contactInfoContact'),
            ...(columns?.contact ? columns.contact : {}),
        },
        !KTUtil.isSmallDesktopOrMobileDevice() && {
            field: 'contactCity',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.CITY' }),
            body: cityBody,
            style: { width: '125px' },
            filter: true,
            filterElement: inputFilter('contactCity'),
            ...(columns?.contactCity ? columns.contactCity : {}),
        },
        {
            field: 'dealershipKnown',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.DEALERSHIP' }),
            body: dealershipBody,
            filter: false,
            filterElement: inputFilter('dealershipKnown'),
            ...(columns?.dealershipKnown ? columns.dealershipKnown : {}),
        },
        {
            field: 'vehicleKnown',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.VEHICLE' }),
            body: vehicleBody,
            filter: true,
            filterElement: inputFilter('vehicleKnown'),
            ...(columns?.vehicleKnown ? columns.vehicleKnown : {}),
        },
        {
            field: 'activity',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.ACTIVITY' }),
            body: activityBody,
            style: { wordBreak: 'break-all' },
            ...(columns?.activity ? columns.activity : {}),
        },
        {
            field: 'ownerName',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.REFERENT' }),
            body: referentBody,
            style: { width: '145px' },
            filter: true,
            filterElement: inputFilter('ownerName'),
            ...(columns?.referent ? columns.referent : {}),
        },
        !KTUtil.isSmallDesktopOrMobileDevice() && {
            field: 'updated',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.UPDATED' }),
            body: updatedBody,
            style: { width: '100px' },
            ...(columns?.updated ? columns.updated : {}),
        },
        {
            field: 'actions',
            header: Intl.formatMessage({ id: 'CUSTOMERS.TABLE.HEAD.ACTIONS' }),
            style: { width: '65px' },
            ...(columns?.actions ? columns.actions : {}),
        },
    ];

    const dynamicColumns = cols.map((col) => col && <Column key={col.field} {...col} />);

    return (
        <div>
            {KTUtil.isInResponsiveRange('tablet-and-mobile') ? (
                <>
                    {customers?.map?.((customer) => (
                        <CustomerRow
                            key={customer.id || customer.vcuId}
                            customer={customer}
                            infoBody={infoBody}
                            nameBody={nameBody}
                            phoneBody={phoneBody}
                            updatedBody={updatedBody}
                        />
                    ))}
                    {totalRecords > 0 && (
                        <Paginator
                            first={start}
                            rows={items}
                            totalRecords={totalRecords}
                            onPageChange={(e) => {
                                window.scrollTo(0, 0);
                                handlePagination(e);
                            }}
                        />
                    )}
                </>
            ) : (
                <HoCDataTable
                    value={customers}
                    totalRecords={totalRecords}
                    lazy
                    loading={loading}
                    paginator
                    onFilter={handleFilter}
                    onPage={handlePagination}
                    onSort={handleSort}
                    paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                    currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                    rows={items}
                    first={start}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    removableSort
                    filterDisplay="row"
                    rowsPerPageOptions={[25, 50, 100]}
                    emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                >
                    {dynamicColumns}
                </HoCDataTable>
            )}
        </div>
    );
};

export default CustomerDataTable;
