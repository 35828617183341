import classNames from 'classnames';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import React from 'react';
import { Card } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import HoCDataTable from '@app/partials/content/HoCDataTable';
import Loader from '@app/partials/content/Loader';

import CampaignCardCell from './CampaignCardCell';

const CampaignStats = ({ statistics, className, loading }) => {
    const dealershipBodyTemplate = (dealership) => (
        <div className="kt-shape-font-color-4">
            <FormattedMessage id="CAMPAIGN.STATS.LEAD_COUNT" />
            <div className="font-weight-bold">{dealership?.name}</div>
        </div>
    );

    const actionDoneBodyTemplate = (dealership) => (
        <CampaignCardCell
            className="kt-bg-light-secondary kt-shape-font-color-4"
            number={dealership?.leads}
            percentage={(dealership?.leads / dealership?.leads) * 100}
        />
    );

    const leadWonBodyTemplate = (dealership) => (
        <CampaignCardCell
            className="kt-bg-light-success text-success"
            number={dealership?.wonLeads}
            percentage={(dealership?.wonLeads / dealership?.leads) * 100}
        />
    );

    const leadLooseBodyTemplate = (dealership) => (
        <CampaignCardCell
            className="kt-bg-light-danger text-danger"
            number={dealership?.lostLeads}
            percentage={(dealership?.lostLeads / dealership?.leads) * 100}
        />
    );

    const autoLooseBodyTemplate = (dealership) => (
        <CampaignCardCell
            className="kt-bg-light-danger text-danger"
            number={dealership?.notDoneLeads}
            percentage={(dealership?.notDoneLeads / dealership?.leads) * 100}
        />
    );

    const cols = [
        {
            field: 'name',
            header: (
                <span
                    className="font-weight-semibold"
                    dangerouslySetInnerHTML={{
                        __html: Intl.formatMessage({ id: 'CAMPAIGN.STATS.DEALERSHIPS' }),
                    }}
                />
            ),
            body: dealershipBodyTemplate,
            headerClassName: 'kt-shape-font-color-4',
        },
        {
            field: 'leads',
            header: (
                <span
                    dangerouslySetInnerHTML={{
                        __html: Intl.formatMessage({ id: 'CAMPAIGN.STATS.LEADS_ACTION_DONE.LIGHT' }),
                    }}
                />
            ),
            body: actionDoneBodyTemplate,
            headerClassName: 'px-4 text-right kt-shape-font-color-4 font-weight-semibold',
        },
        {
            field: 'wonLeads',
            header: (
                <span
                    dangerouslySetInnerHTML={{
                        __html: Intl.formatMessage({ id: 'CAMPAIGN.STATS.LEADS_WON.LIGHT' }),
                    }}
                />
            ),
            body: leadWonBodyTemplate,
            headerClassName: 'px-4 text-right kt-shape-font-color-4 font-weight-semibold',
        },
        {
            field: 'lostLead',
            header: (
                <span
                    dangerouslySetInnerHTML={{
                        __html: Intl.formatMessage({ id: 'CAMPAIGN.STATS.LEADS_LOOSE.LIGHT' }),
                    }}
                />
            ),
            body: leadLooseBodyTemplate,
            headerClassName: 'px-4 text-right kt-shape-font-color-4 font-weight-semibold',
        },
        {
            field: 'notDoneLeads',
            header: (
                <span
                    dangerouslySetInnerHTML={{
                        __html: Intl.formatMessage({ id: 'CAMPAIGN.STATS.AUTO_LOOSE.LIGHT' }),
                    }}
                />
            ),
            body: autoLooseBodyTemplate,
            headerClassName: 'px-4 text-right kt-shape-font-color-4 font-weight-semibold',
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <Card className={classNames('card--shadowed', className)}>
            <Card.Header className="text-closed position-relative">
                <div className="bg-closed pin" style={{ top: '20px' }}>
                    <i className="la la-chart-line text-white la-lg" />
                </div>
                <span className="ml-5 d-flex align-items-center justify-content-between font-weight-semibold">
                    <FormattedMessage id="CAMPAIGN.STATS.TITLE" />
                </span>
            </Card.Header>
            <Card.Body>
                {loading ? (
                    <Loader style={{ width: '5rem', height: '5rem' }} wrapperStyle={{ marginTop: '-15px' }} overlay />
                ) : (
                    <>
                        <div className="d-flex justify-content-between">
                            <div className="pr-4 pl-0 flex-grow-1 flex-basis-0">
                                <div className="campaign-card kt-bg-light-secondary kt-shape-font-color-4">
                                    <div>
                                        <div className="text-center">
                                            <FormattedHTMLMessage id="CAMPAIGN.STATS.LEADS_ACTION_DONE" />
                                        </div>
                                        <Divider className="p-divider-white" />
                                        <div className="d-flex font-weight-bold justify-content-between align-items-center">
                                            <div className="font-size-h1">{statistics?.leads}</div>
                                            <div className="font-size-h4">
                                                {((statistics?.notDoneLeads / statistics?.leads) * 100 || 0).toFixed(1)}
                                                &nbsp;%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pr-4 pl-0 flex-grow-1 flex-basis-0">
                                <div className="campaign-card kt-bg-light-success text-success">
                                    <div>
                                        <div className="text-center">
                                            <FormattedHTMLMessage id="CAMPAIGN.STATS.LEADS_WON" />
                                        </div>
                                        <Divider className="p-divider-white" />
                                        <div className="d-flex font-weight-bold justify-content-between align-items-center">
                                            <div className="font-size-h1">{statistics?.wonLeads}</div>
                                            <div className="font-size-h4">
                                                {((statistics?.wonLeads / statistics?.leads) * 100 || 0).toFixed(1)}
                                                &nbsp;%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pr-4 pl-0 flex-grow-1 flex-basis-0">
                                <div className="campaign-card kt-bg-light-danger text-danger">
                                    <div>
                                        <div className="text-center">
                                            <FormattedHTMLMessage id="CAMPAIGN.STATS.LEADS_LOOSE" />
                                        </div>
                                        <Divider className="p-divider-white" />
                                        <div className="d-flex font-weight-bold justify-content-between align-items-center">
                                            <div className="font-size-h1">{statistics?.lostLeads}</div>
                                            <div className="font-size-h4">
                                                {((statistics?.lostLeads / statistics?.leads) * 100 || 0).toFixed(1)}
                                                &nbsp;%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pr-4 pl-0 flex-grow-1 flex-basis-0">
                                <div className="campaign-card kt-bg-light-danger text-danger">
                                    <div>
                                        <div className="text-center">
                                            <FormattedHTMLMessage id="CAMPAIGN.STATS.AUTO_LOOSE" />
                                        </div>
                                        <Divider className="p-divider-white" />
                                        <div className="d-flex font-weight-bold justify-content-between align-items-center">
                                            <div className="font-size-h1">{statistics?.notDoneLeads}</div>
                                            <div className="font-size-h4">
                                                {((statistics?.notDoneLeads / statistics?.leads) * 100 || 0).toFixed(1)}
                                                &nbsp;%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <HoCDataTable
                            value={Object.values(statistics?.dealerships || {})}
                            className="mt-8 p-datatable-stats"
                        >
                            {dynamicColumns}
                        </HoCDataTable>
                    </>
                )}
            </Card.Body>
        </Card>
    );
};

export default CampaignStats;
