import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import qs, { ParsedQs } from "qs";

const useQueryParams = () => {
    const [params, setParams] = useState<ParsedQs>({} );
    const location = useLocation();

    useEffect(() => {
        setParams(qs.parse(location?.search || '', { ignoreQueryPrefix: true }));
    }, [location]);

    return params;
};

export default useQueryParams;
