import React from 'react';
import { FormattedMessage } from 'react-intl';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';
import { User } from '@app/store/login/login.store';
import store from '@app/store/store';

const ACCOUNT_SETTINGS = {
    icon: 'las la-comment',
    title: () => <FormattedMessage id="USER.PROFILE.SETTINGS.TITLE" />,
    subtitle: <FormattedMessage id="USER.PROFILE.SETTINGS.SUBTITLE" />,
    href: () => routeTo(ROUTES.ACCOUNT_SETTINGS.PATH),
    permissions: ['LEAD_VIEW_PROFIL'],
};

const ACCOUNT_PLANNING = {
    icon: 'las la-calendar',
    title: () => <FormattedMessage id="USER.PROFILE.PLANNING.TITLE" />,
    subtitle: <FormattedMessage id="USER.PROFILE.PLANNING.SUBTITLE" />,
    href: () => routeTo(ROUTES.ACCOUNT_PLANNING.PATH),
    permissions: ['LEAD_VIEW_CALENDAR_PROFIL'],
};

const ACCOUNT_PERIMETER = {
    icon: 'las la-cog',
    title: () => <FormattedMessage id="USER.PROFILE.PERIMETER.TITLE" values={{ suffix: 'Mon' }} />,
    subtitle: <FormattedMessage id="USER.PROFILE.PERIMETER.SUBTITLE" />,
    href: () => routeTo(ROUTES.ACCOUNT_PERIMETER.PATH),
    permissions: ['LEAD_VIEW_SCOPE_PROFIL'],
};

const ACCOUNT_HISTORY = {
    icon: 'las la-history',
    title: () => <FormattedMessage id="USER.PROFILE.HISTORY.TITLE" values={{ suffix: 'Mon' }} />,
    subtitle: <FormattedMessage id="USER.PROFILE.HISTORY.SUBTITLE" />,
    href: () => routeTo(ROUTES.ACCOUNT_HISTORY.PATH),
    permissions: ['LEAD_VIEW_HISTO_PROFIL'],
};

const ACCOUNT_TEAM = {
    icon: 'las la-users',
    title: () => <FormattedMessage id="USER.PROFILE.TEAM.TITLE" />,
    subtitle: <FormattedMessage id="USER.PROFILE.TEAM.SUBTITLE" />,
    href: () => routeTo(ROUTES.ACCOUNT_TEAM.PATH),
    permissions: ['LEAD_VIEW_TEAM'],
};

const ACCOUNT_PASSWORD = {
    icon: 'las la-key',
    title: () => <FormattedMessage id="USER.PROFILE.PASSWORD.TITLE" />,
    subtitle: <FormattedMessage id="USER.PROFILE.PASSWORD.SUBTITLE" />,
    href: () => routeTo(ROUTES.ACCOUNT_PASSWORD.PATH),
    visible: () => {
        const { userData } = store.getState().auth;
        return userData?.external;
    },
};

export const ACCOUNT_MENUS = [
    ACCOUNT_SETTINGS,
    ACCOUNT_PLANNING,
    ACCOUNT_TEAM,
    ACCOUNT_PERIMETER,
    ACCOUNT_HISTORY,
    ACCOUNT_PASSWORD,
];

export const USER_MENUS = [
    {
        ...ACCOUNT_PLANNING,
        href: (userId: number) => routeTo(ROUTES.USER.PATH.PLANNING, { id: userId }),
        title: (userData: User) => (
            <FormattedMessage
                id="USER.PROFILE.ADMIN.PLANNING.TITLE"
                values={{
                    name: `${userData?.first_name} ${userData?.last_name}`,
                }}
            />
        ),
        subtitle: <FormattedMessage id="USER.PROFILE.ADMIN.PLANNING.SUBTITLE" />,
    },
    {
        ...ACCOUNT_PERIMETER,
        href: (userId: number) => routeTo(ROUTES.USER.PATH.PERIMETER, { id: userId }),
        title: (userData: User) => (
            <FormattedMessage
                id="USER.PROFILE.ADMIN.PERIMETER.TITLE"
                values={{
                    name: `${userData?.first_name} ${userData?.last_name}`,
                }}
            />
        ),
        subtitle: <FormattedMessage id="USER.PROFILE.ADMIN.PERIMETER.SUBTITLE" />,
    },
    {
        ...ACCOUNT_HISTORY,
        href: (userId: number) => routeTo(ROUTES.USER.PATH.HISTORY, { id: userId }),
        title: (userData: User) => (
            <FormattedMessage
                id="USER.PROFILE.ADMIN.HISTORY.TITLE"
                values={{
                    name: `${userData?.first_name} ${userData?.last_name}`,
                }}
            />
        ),
        subtitle: <FormattedMessage id="USER.PROFILE.ADMIN.HISTORY.SUBTITLE" />,
    },
    {
        ...ACCOUNT_PASSWORD,
        href: (userId: number) => routeTo(ROUTES.USER.PATH.PASSWORD, { id: userId }),
        visible: (userData: User) => userData?.external,
    },
];
