import React from "react";
import {Card, Form} from "react-bootstrap";
import {Intl} from "../../../../../_metronic/i18n/I18nProvider";
import SwitchInput from "../../../../partials/content/SwitchInput";
import {useFormContext} from "react-hook-form";
import ErrorForm from "../../../../partials/layout/ErrorForm";

export default function CardGeneralInformation() {

    const methods = useFormContext();

    return (
        <Card className="shadow-sm">
            <Card.Header>
                <i className="card__icon fas fa-info-circle"/>
                {Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.GENERAL.INFORMATION.TITLE' })}
            </Card.Header>
            <Card.Body>
                <Form.Group>
                    <Form.Label>{Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.GENERAL.INFORMATION.NAME' })}</Form.Label>
                    <Form.Control
                        ref={methods.register({
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                        })}
                        name="name"
                        type="text"
                        className={`${methods.errors.name ? 'is-invalid' : ''}`}
                    />
                    <ErrorForm errors={methods.errors} name="name" />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.GENERAL.INFORMATION.STATUS' })}</Form.Label>
                    <SwitchInput
                        name="active"
                        active={methods.watch('active')}
                    />
                </Form.Group>
            </Card.Body>
        </Card>
    )
}
