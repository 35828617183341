import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { Intl } from '../../../../../../_metronic/i18n/I18nProvider';

let currentCharType = '';
let currentNbLeadOnline = 0;
let currentNbLeadOnlineAfr = 0;
let currentNbLeadLocal = 0;
let currentNbViews = 0;

const getOrCreateLegendList = (chart) => {
    let listContainer = chart.canvas.parentNode.querySelector('ul');
    if (listContainer) {
        listContainer.remove();
    }
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.marginTop = '30px';
    listContainer.style.padding = 0;
    chart.canvas.parentNode.appendChild(listContainer);
    return listContainer;
};

const ChartLegend = ({ chart }) => {
    const [legends, setLegends] = useState(null);
    useEffect(() => {
        if (chart?.legend) {
            setLegends(chart.options.plugins.legend.labels.generateLabels(chart));
        }
    }, [chart]);

    const clickHandler = (item) => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
        } else {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
    };

    return (
        legends &&
        legends.map((legend, key) => (
            <li
                key={key}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: '10px',
                }}
            >
                <span
                    style={{
                        background: legend.fillStyle,
                        borderColor: legend.strokeStyle,
                        borderWidth: `${legend.lineWidth}px`,
                        display: 'inline-block',
                        height: '10px',
                        marginRight: '10px',
                        width: '10px',
                        borderRadius: '50%',
                    }}
                />
                <p
                    style={{
                        color: legend.fontColor,
                        margin: 0,
                        padding: 0,
                        textDecoration: legend.hidden ? 'line-through' : '',
                    }}
                >
                    {legend.text !==
                    Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.PRICE' }) ? (
                        <>
                            {legend.text ===
                                Intl.formatMessage({
                                    id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.ONLINE',
                                }) && (
                                <>
                                    {Intl.formatMessage(
                                        {
                                            id: `STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.${
                                                currentNbLeadOnline.length > 0 ? 'LEADS' : 'LEAD'
                                            }_TITLE`,
                                        },
                                        {
                                            content: Intl.formatMessage({
                                                id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.ONLINE',
                                            }),
                                        },
                                    )}
                                    &nbsp;{currentNbLeadOnline}
                                </>
                            )}
                            {legend.text ===
                                Intl.formatMessage({
                                    id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.AUTOSPHERE_FR',
                                }) && (
                                <>
                                    {legend.text}&nbsp;{currentNbLeadOnlineAfr}
                                </>
                            )}
                            {legend.text ===
                                Intl.formatMessage({
                                    id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.VEHICLE',
                                }) && (
                                <>
                                    {legend.text}&nbsp;{currentNbViews}
                                </>
                            )}
                            {legend.text ===
                                Intl.formatMessage(
                                    { id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.LEADS_TITLE' },
                                    {
                                        content: Intl.formatMessage({
                                            id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.LOCALS',
                                        }),
                                    },
                                ) && (
                                <>
                                    {legend.text}&nbsp;{currentNbLeadLocal}
                                </>
                            )}
                        </>
                    ) : (
                        legend.text
                    )}
                </p>
            </li>
        ))
    );
};

// eslint-disable-next-line import/prefer-default-export
export const stockVehicleChartLegend = (chartType, nbLeadOnline, nbLeadOnlineAfr, nbLeadLocal, nbViews) => {
    currentCharType = chartType;
    currentNbLeadOnline = nbLeadOnline;
    currentNbLeadOnlineAfr = nbLeadOnlineAfr;
    currentNbLeadLocal = nbLeadLocal;
    currentNbViews = nbViews;

    return {
        afterUpdate(chart) {
            const ul = getOrCreateLegendList(chart);
            ReactDOM.render(<ChartLegend chart={chart} />, ul);
        },
    };
};
