import React, {useRef, useState} from 'react';
import dayjs from "dayjs";
import HourInput from "./HourInput";
import {TIME} from "./Helpers/DatePickerHelper";
import {FormattedHTMLMessage} from "react-intl";

const HourGroup = (
    {
        date,
        setDate,
        stepMinute,
        stepHour,
        subtitle,
        title,
        isAllowed
    }) => {
    const hourRef = useRef();
    const minuteRef = useRef();
    const [error, setError] = useState({});
    const day = dayjs(date).isValid() ? dayjs(date) : dayjs();

    const getActualDate = () => {
        const hour = hourRef?.current ? hourRef?.current?.value || 0 : day.hour();
        const minute = minuteRef?.current ? minuteRef?.current?.value || 0 : day.minute();

        return day.hour(hour).minute(minute);
    }

    const handleChange = () => {
        const newDate = getActualDate();
        setError(_ => ({...{
                text: isAllowed(newDate)?.error
        }}));
        setDate(newDate.toDate(), TIME);
    }

    const getSubTitle = () => {
        const matches = [...subtitle.matchAll(/{[^}]*}/g)];
        const date = getActualDate();

        if (matches?.length > 0) {
            matches.forEach((match) => {
                const format = match[0].substring(1, match[0].length-1);
                subtitle = subtitle.replace(match[0], date.format(format));
            });
        }

        return subtitle;
    }

    return (
        <div className="p-datepicker-time text-center">
            <div className="mb-10 font-size-h5-lg">{title}</div>
            <div className="mb-5 d-flex align-items-center justify-content-center">
                <HourInput value={day.format('H')}
                           min={0}
                           max={23}
                           ref={hourRef}
                           onChange={handleChange}
                           step={stepHour}
                           inputClassName={error?.text === 'minDate' && 'border-danger text-danger shadow-none'}/>
                <div className="mx-5">h</div>
                <HourInput value={day.format('m')}
                           min={0}
                           max={59}
                           ref={minuteRef}
                           onChange={handleChange}
                           step={stepMinute} />
            </div>
            {error?.text &&
                <div className="mb-5 text-danger font-italic">
                    <FormattedHTMLMessage id={`DATEPICKER.ERROR.${error?.text?.toUpperCase?.()}`} />
                </div>
            }
            <div className="font-size-h5-lg" dangerouslySetInnerHTML={{__html: getSubTitle()}} />
        </div>
    );
};

HourGroup.defaultProps = {
    title: 'On se dit quelle heure ?',
    subtitle: 'Votre rendez-vous sera planifié <br /> le {dddd} <strong>{DD MMMM YYYY}</strong> à <strong>{HH [h] mm}</strong>.'
}

export default HourGroup;
