import * as dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { FormattedHTMLMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Intl, Locale } from '@src/_metronic/i18n/I18nProvider';

import { getDateByFormat } from '@app/helpers/DateHelper';
import { empty } from '@app/helpers/ToolsHelper';

import { updateAppointment } from '@app/store/schedule/schedule.thunk';

import Editor from '@app/partials/content/Editor';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import Permission from '@app/partials/content/Permission';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

import { DateTimePicker } from '../../../partials/content/DateTimePicker';

import { formToApiObject } from './Event.Handler';
import EventModalTooltip from './Event.Modal.Tooltip';

const defaultValues = {
    id: '',
    subject: '',
    date: '',
    timeStart: '',
    timeEnd: '',
    userId: '',
    comment: '',
};

export default function EventModal({ event, user }) {
    const dispatch = useDispatch();
    const methods = useForm();
    const { reset, register, errors, getValues, control, handleSubmit } = methods;
    const [disable, setDisable] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };

    const onSubmit = (data) => {
        dispatch(updateAppointment(formToApiObject(event, data, user)));
        setShow(false);
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'AGENDA.FORM.ERROR.VALIDATE' }),
        });
    };

    const validateTimeHandler = (timeStart, timeEnd) => {
        const startTimeObject = new Date();
        let split = timeStart.split(':');
        startTimeObject.setHours(split[0], split[1]);

        const endTimeObject = new Date();
        split = timeEnd.split(':');
        endTimeObject.setHours(split[0], split[1]);

        if (startTimeObject > endTimeObject) {
            return Intl.formatMessage({ id: 'AGENDA.FORM.EVENT.ERROR.STARTTIME.BEFORE.ENDTIME' });
        }

        return true;
    };

    const subtitleHandler = (subTitleEvent) =>
        subTitleEvent.extendedProps?.organizer ? (
            <FormattedHTMLMessage
                id="AGENDA.MODAL.EVENT.SUBTITLE.CREATED.APPOINTMENT.BY"
                values={{
                    date: dayjs(subTitleEvent.extendedProps.created).format('DD/MM/YYYY'),
                    organizer: subTitleEvent.extendedProps.organizer,
                }}
            />
        ) : (
            Intl.formatMessage({ id: 'AGENDA.MODAL.EVENT.SUBTITLE.CREATED.APPOINTMENT' }) +
            dayjs(subTitleEvent.extendedProps.created).format('DD/MM/YYYY')
        );

    useEffect(() => {
        reset({
            subject: event?.title || defaultValues.subject,
            date: event?.start ? dayjs(event.start).format('YYYY-MM-DD') : defaultValues.date,
            timeStart: event?.start ? dayjs(event.start).format('HH:mm') : defaultValues.start,
            timeEnd: event?.end ? dayjs(event.end).format('HH:mm') : defaultValues.end,
            comment: event?.extendedProps?.comment || defaultValues.comment,
        });
        setDisable(!!event && !event.durationEditable);
        setShow(!!event);
    }, [reset, event]);

    return (
        <ModalDefault
            show={show}
            hideModal={handleClose}
            icon={<i className="text-primary far fa-calendar-check fa-2x" />}
            size="lg"
            title={Intl.formatMessage({ id: 'AGENDA.MODAL.EVENT.TITLE.UPDATE.APPOINTMENT' })}
            subtitle={event ? subtitleHandler(event) : null}
            body={
                <Form>
                    <Form.Group controlId="subject">
                        <Form.Label>{Intl.formatMessage({ id: 'AGENDA.FORM.EVENT.LABEL.SUBJECT' })}</Form.Label>
                        <Form.Control
                            disabled={disable}
                            ref={register({
                                required: Intl.formatMessage({ id: 'AGENDA.FORM.ERROR.REQUIRED' }),
                            })}
                            name="subject"
                            type="text"
                            placeholder={Intl.formatMessage({ id: 'AGENDA.FORM.EVENT.PLACEHOLDER.SUBJECT' })}
                            className={`${errors.subject ? 'is-invalid' : ''}`}
                        />
                        <ErrorForm errors={errors} name="subject" />
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col} md={4} controlId="day">
                            <Form.Label>{Intl.formatMessage({ id: 'AGENDA.FORM.EVENT.LABEL.DAY' })}</Form.Label>
                            <Controller
                                control={control}
                                name="date"
                                rules={{
                                    required: Intl.formatMessage({ id: 'AGENDA.FORM.ERROR.REQUIRED' }),
                                }}
                                render={(props) => (
                                    <DateTimePicker
                                        disabled={!!disable}
                                        locale={Locale}
                                        showIcon
                                        icon="far fa-calendar-alt"
                                        dateFormat="DD/MM/YYYY"
                                        className={`${errors.date ? 'is-invalid' : ''}`}
                                        onChange={(e) => {
                                            const value = dayjs(e.value).isValid()
                                                ? dayjs(e.value).format('YYYY-MM-DD')
                                                : defaultValues.dateEnd;
                                            props.onChange(value);
                                        }}
                                        value={getDateByFormat(props.value, 'YYYY-MM-DD', defaultValues.dateEnd)}
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name="date" />
                        </Form.Group>

                        <Form.Group as={Col} md={4} controlId="start">
                            <Form.Label>{Intl.formatMessage({ id: 'AGENDA.FORM.EVENT.LABEL.TIME.START' })}</Form.Label>
                            <Controller
                                control={control}
                                name="timeStart"
                                rules={{
                                    validate: (value) => {
                                        const values = getValues();

                                        if (empty(value)) {
                                            return Intl.formatMessage({ id: 'AGENDA.FORM.ERROR.REQUIRED' });
                                        }

                                        if (!empty(values.timeEnd)) {
                                            return validateTimeHandler(value, values.timeEnd);
                                        }

                                        return true;
                                    },
                                }}
                                render={(props) => (
                                    <DateTimePicker
                                        disabled={!!disable}
                                        locale={Locale}
                                        showIcon
                                        dateFormat="HH:mm"
                                        icon="far fa-clock"
                                        timeOnly
                                        className={`${errors.timeStart ? 'is-invalid' : ''}`}
                                        onChange={(e) => {
                                            const value = dayjs(e.value).isValid()
                                                ? dayjs(e.value).format('HH:mm')
                                                : defaultValues.dateEnd;
                                            props.onChange(value);
                                        }}
                                        value={getDateByFormat(props.value, 'HH:mm', defaultValues.dateEnd)}
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name="timeStart" />
                        </Form.Group>

                        <Form.Group as={Col} md={4} controlId="end">
                            <Form.Label>{Intl.formatMessage({ id: 'AGENDA.FORM.EVENT.LABEL.TIME.END' })}</Form.Label>
                            <Controller
                                control={control}
                                name="timeEnd"
                                rules={{
                                    validate: (value) => {
                                        const values = getValues();

                                        if (empty(value)) {
                                            return Intl.formatMessage({ id: 'AGENDA.FORM.ERROR.REQUIRED' });
                                        }

                                        if (!empty(values.timeStart)) {
                                            return validateTimeHandler(values.timeStart, value);
                                        }

                                        return true;
                                    },
                                }}
                                render={(props) => (
                                    <DateTimePicker
                                        disabled={!!disable}
                                        locale={Locale}
                                        showIcon
                                        dateFormat="HH:mm"
                                        icon="far fa-clock"
                                        timeOnly
                                        className={`${errors.timeEnd ? 'is-invalid' : ''}`}
                                        onChange={(e) => {
                                            const value = dayjs(e.value).isValid()
                                                ? dayjs(e.value).format('HH:mm')
                                                : defaultValues.dateEnd;
                                            props.onChange(value);
                                        }}
                                        value={getDateByFormat(props.value, 'HH:mm', defaultValues.dateEnd)}
                                    />
                                )}
                            />
                            <ErrorForm errors={errors} name="timeEnd" />
                        </Form.Group>
                    </Form.Row>
                    <EventModalTooltip event={event} />
                    <Form.Group controlId="comment">
                        <Form.Label>{Intl.formatMessage({ id: 'AGENDA.FORM.EVENT.LABEL.COMMENT' })}</Form.Label>
                        <Controller
                            control={control}
                            name="comment"
                            rules={{
                                required: disable ? true : Intl.formatMessage({ id: 'AGENDA.FORM.ERROR.REQUIRED' }),
                            }}
                            defaultValue=""
                            render={(props) => (
                                <Editor
                                    value={props.value}
                                    className={`${errors.comment ? 'is-invalid' : ''}`}
                                    onChange={props.onChange}
                                    config={{
                                        minHeight: 150,
                                        readonly: disable,
                                        buttons: '',
                                        statusbar: false,
                                        placeholder: Intl.formatMessage({
                                            id: 'AGENDA.FORM.EVENT.PLACEHOLDER.COMMENT',
                                        }),
                                    }}
                                />
                            )}
                        />
                        <ErrorForm errors={errors} name="comment" />
                    </Form.Group>
                </Form>
            }
            footer={
                <>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                    {!disable && (
                        <Permission permissions={['LEAD_UPDATE_APPOINTMENT_CALENDAR']}>
                            <Button variant="primary" onClick={handleSubmit(onSubmit, onError)}>
                                {Intl.formatMessage({ id: 'TRANSLATOR.UPDATE' })}
                            </Button>
                        </Permission>
                    )}
                </>
            }
        />
    );
}
