import React, { ReactNode, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';

type ConfirmModalProps = {
    icon?: ReactNode;
    title: ReactNode;
    description: ReactNode;
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    onConfirm?: () => void;
    onCancel?: () => void;
    loading?: boolean;
    validationCheckbox?: boolean;
    checkboxLabel?: ReactNode;
    loadingTitle?: ReactNode;
    loadingDescription?: ReactNode;
};

const ConfirmModal = ({
    icon,
    title,
    description,
    onConfirm,
    onCancel,
    showModal,
    setShowModal,
    loading,
    validationCheckbox,
    checkboxLabel,
    loadingTitle,
    loadingDescription,
}: ConfirmModalProps) => {
    const [confirmationValid, setConfirmationValid] = useState(false);

    useEffect(() => {
        setConfirmationValid(false);
    }, [showModal]);

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => {
                setShowModal(false);
            }}
            backdrop="static"
            closeButton={!loading}
            icon={icon}
            title={loading ? loadingTitle : title}
            body={
                <div className="d-flex justify-content-center align-items-center flex-column">
                    {loading ? (
                        <>
                            {loadingDescription && <div className="text-center">{loadingDescription}</div>}
                            <Loader style={{ width: '5rem', height: '5rem' }} block />
                        </>
                    ) : (
                        <>
                            <div className="text-center">{description}</div>

                            {validationCheckbox && (
                                <div className="form-check mt-5">
                                    <input
                                        aria-labelledby="validationCheckboxLabel"
                                        className="form-check-input"
                                        name="validationCheckbox"
                                        type="checkbox"
                                        id="validationCheckbox"
                                        onChange={(e) => setConfirmationValid(e.target.checked)}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="validationCheckbox"
                                        id="validationCheckboxLabel"
                                    >
                                        {checkboxLabel}
                                    </label>
                                </div>
                            )}
                        </>
                    )}
                </div>
            }
            footer={
                <div className="d-flex justify-content-center">
                    <Button
                        variant="outline-primary"
                        disabled={loading}
                        onClick={() => {
                            if (onCancel) {
                                onCancel();
                            } else {
                                setShowModal(false);
                            }
                        }}
                        className="mr-5"
                    >
                        <FormattedMessage id="TRANSLATOR.BACK" />
                    </Button>
                    <Button
                        variant="primary"
                        disabled={(validationCheckbox === true && confirmationValid === false) || loading}
                        onClick={() => {
                            if (onConfirm) {
                                onConfirm();
                            } else {
                                setShowModal(false);
                            }
                        }}
                    >
                        <FormattedMessage id="TRANSLATOR.VALIDATE" />
                    </Button>
                </div>
            }
        />
    );
};

ConfirmModal.defaultProps = {
    icon: undefined,
    onConfirm: undefined,
    onCancel: undefined,
    loading: false,
    validationCheckbox: false,
    checkboxLabel: <FormattedMessage id="TRANSLATOR.CONFIRM" />,
    loadingTitle: <FormattedMessage id="TRANSLATOR.LOADING" />,
    loadingDescription: undefined,
};

export default ConfirmModal;
