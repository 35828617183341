import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import KTUtil from '@src/_metronic/_assets/js/util';

import { useApvInStore } from '@app/hooks/apvInStore';
import { useMobilize } from '@app/hooks/mobilize';
import useFetch from '@app/hooks/useFetch';
import useModal from '@app/hooks/useModal';
import useTypologies from '@app/hooks/useTypologies';

import { getCustomerReviews } from '@app/crud/customers/customer.crud';

import { useAppSelector, usePermissions } from '@app/hooks';
import SecuryCarContractCGV from '@app/pages/customers//Modals/SecuryCarContract/SecuryCarContractCGV';
import CustomerHandBand from '@app/pages/customers/HandBands/CustomerHandBand';
import { showDetailEvent } from '@app/pages/customers/Helpers/Events';
import { manageApvInStoreListing } from '@app/pages/customers/Helpers/ExternalData';
import { doActivityRefresh } from '@app/pages/customers/Helpers/Lead';
import {
    AFRPartExchangeDetail,
    Appointment,
    APVRequest,
    APVRequestClose,
    APVRequestReopen,
    APVRequestReply,
    AssignLead,
    AssignTo,
    Call,
    Callback,
    CommerceRequest,
    Consign,
    Contact,
    ContactAudi,
    ContactBackupAudi,
    ContractExtension,
    Create,
    EditComment,
    Feedback,
    FirstActions,
    ForceTreatment,
    LeadHomeCharge,
    LeadLoose,
    LeadManufacturer,
    LeadPostpone,
    LeadWon,
    LinkLead,
    Mail,
    NextActions,
    Purchase,
    RollbackUpdate,
    UnlinkLead,
    UpdateLeadStatus,
    VehicleKnow,
    Visit,
} from '@app/pages/customers/Modals';
import APVInStore from '@app/pages/customers/Modals/APVInStore/APVInStore';
import APVInStoreClose from '@app/pages/customers/Modals/APVInStore/Modals/APVInStoreClose';
import APVInStoreQuotationAccepted from '@app/pages/customers/Modals/APVInStore/Modals/APVInStoreQuotationAccepted';
import APVInStoreQuotationAcceptedConfirm from '@app/pages/customers/Modals/APVInStore/Modals/APVInStoreQuotationAcceptedConfirm';
import CreateOpportunity from '@app/pages/customers/Modals/CreateOpportunity';
import FollowUpTask from '@app/pages/customers/Modals/FollowUpTask';
import Notes from '@app/pages/customers/Modals/Notes';
import SecuryCarContract from '@app/pages/customers/Modals/SecuryCarContract/SecuryCarContract';
import SecuryCarContractSteps from '@app/pages/customers/Modals/SecuryCarContract/SecuryCarContractSteps';
import Actions from '@app/pages/customers/Partials/Actions';
import ActivityLead from '@app/pages/customers/Partials/ActivityLead';
import APVActions from '@app/pages/customers/Partials/APVActions';
import CustomerBusiness from '@app/pages/customers/Partials/customer-business';
import CustomerLife from '@app/pages/customers/Partials/customer-life';
import {
    ModalCerfa,
    ModalContract,
    ModalEdit as ModalPartExchangeOfferEdit,
} from '@app/pages/customers/Partials/customer-life/EventDetailValuation';
import CustomerHistory from '@app/pages/customers/Partials/CustomerHistory/CustomerHistory';
import CustomerInfo from '@app/pages/customers/Partials/CustomerInfo';
import { leadCommerceTypes } from '@app/store/customers/customers.store';
import { fetchContracts, fetchCustomer, fetchCustomerLife, resetCustomer } from '@app/store/customers/customers.thunk';
import { actions } from '@app/store/modal/modal.store';

import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';
import Modal from '@app/partials/HoC/Modal';

import CustomerActions from './Partials/ActionsBlock/CustomerActions';

const containerWidth = (() => {
    if (KTUtil.isInResponsiveRange('mobile')) {
        return '100%';
    } else if (KTUtil.isInResponsiveRange('minimal-desktop-and-below')) {
        return '50%';
    }
    return '29.1666666667%';
})();

const Customer = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const history = useHistory();
    const { hasAnyTypologies, hasTypology } = useTypologies();
    const { hasPermission, hasAnyPermissions } = usePermissions();
    const {
        data: customer,
        last_action,
        loading,
        contracts,
        apvOrders,
        externalData,
    } = useAppSelector((state) => state.customer.customer);
    const { activity } = useAppSelector((state) => state.customer);
    const [visitModalOpen, setVisitModalOpen] = useModal(false, 'visit');
    const [appointmentModalOpen, setAppointmentModalOpen] = useModal(false, 'appointment');
    const [purchaseModalOpen, setPurchaseModalOpen] = useModal(false, 'purchase');
    const [mailModalOpen, setMailModalOpen, mailParams] = useModal(false, 'mail');
    const [callModalOpen, setCallModalOpen] = useModal(false, 'call');
    const [apvRequestModalOpen, setApvRequestModalOpen] = useModal(false, 'local_apv');
    const [apvRequestReplyModalOpen, setApvRequestReplyModalOpen] = useModal(false, 'reply_local_apv');
    const [apvRequestCloseModalOpen, setApvRequestCloseModalOpen] = useModal(false, 'close_local_apv');
    const [apvRequestReopenModalOpen, setApvRequestReopenModalOpen] = useModal(false, 'reopen_local_apv');
    const [commerceModalOpen, setCommerceModalOpen] = useModal(false, 'local_commerce');
    const [notesModalOpen, setNotesModalOpen] = useModal(false, 'notes');
    const [nextActionModalOpen, setNextActionModalOpen] = useModal(false, 'next_action');
    const [firstActionsModalOpen, setFirstActionsModalOpen] = useModal(false, 'first_action');
    const [consignModalOpen, setConsignModalOpen] = useModal(false, 'consign');
    const [extendContractModalOpen, setExtendContractModalOpen] = useModal(false, 'contract_extension');
    const [postponeModalOpen, setPostponeModalOpen] = useModal(false, 'lead_postpone');
    const [apvinstoreModalOpen, setApvinstoreModalOpen] = useModal(false, 'apv_in_store');
    const [apvinstoreCloseModalOpen, setApvinstoreCloseModalOpen] = useModal(false, 'apv_in_store_close');
    const [apvinstoreWonModalOpen, setApvinstoreWonModalOpen] = useModal(false, 'apv_in_store_close_win');
    const [apvinstoreWonConfirmModalOpen, setApvinstoreWonConfirmModalOpen] = useModal(
        false,
        'apv_in_store_close_win_confirm',
    );

    const [leadwonModalOpen, setLeadwonModalOpen, leadwonParams] = useModal(false, 'lead_won');
    const [leadlooseModalOpen, setLeadlooseModalOpen, leadlooseParams] = useModal(false, 'lead_loose');
    const [callbackModalOpen, setCallbackModalOpen] = useModal(false, 'callback');
    const [linkleadModalOpen, setLinkleadModalOpen] = useModal(false, 'link_lead');
    const [unlinkleadModalOpen, setUnlinkleadModalOpen] = useModal(false, 'unlink_lead');
    const [rollbackUpdateModalOpen, setRollbackUpdateModalOpen] = useModal(false, 'rollback_update');
    const [feedbackModalOpen, setFeedBackModalOpen] = useModal(false, 'feedback');
    const [vehicleKnowModalOpen, setVehicleKnowModalOpen] = useModal(false, 'vehicle_know');
    const [editCommentModalOpen, setEditCommentModalOpen] = useModal(false, 'comment');
    const [showCreateModal, toggleCreateModal] = useModal(false, 'create');
    const [showForceTreatmentModal, toggleForceTreatmentModal] = useModal(false, 'force_treatment');
    const [assignLeadModal, setAssignLeadModal] = useModal(false, 'assign_lead');
    const [updateLeadStatusModal, setUpdateLeadStatusModal] = useModal(false, 'update_lead_status');
    const [afrPartExchangeDetailModal, setAfrPartExchangeDetailModal] = useModal(false, 'afr_part_exchange_detail');
    const [consignContactModal, setConsignContactModal] = useModal(false, 'consign_contact');
    const [assignToModal, setAssignToModal] = useModal(false, 'assign_to');
    const [contactAudiModal, setContactAudiModal] = useModal(false, 'contactAudi');
    const [contactBackupAudiModal, setContactBackupAudiModal] = useModal(false, 'contactBackupAudi');
    const [leadManufacturerModal, setLeadManufacturerModal] = useModal(false, 'manufacturer');
    const [contractSecurycarModal, setcontractSecurycarModal] = useModal(false, 'contract_securycar');
    const [contractSecurycarStepsModal, setcontractSecurycarStepsModal] = useModal(false, 'contract_securycar_steps');
    const [contractSecurycarCGVModal, setContractSecurycarCGV] = useModal(false, 'contract_securycar_cgv');
    const [leadHomeChargeModal, setLeadHomeChargeModal] = useModal(false, 'local_home_charge');
    const [createOpportunity, setCreateOpportunity, createOpportunityParams] = useModal(false, 'create_opportunity');
    const [followUpTaskModal, setFollowUpTaskModal, followUpTaskModalParams] = useModal(false, 'follow_up_task');
    const { isAllowedApvInStore } = useApvInStore();
    const [valuationEditModal, setValuationEditModal] = useModal(false, 'valuation.edit');
    const [valuationContractModal, setValuationContractModal] = useModal(false, 'valuation.contract');
    const [valuationCerfaModal, setValuationCerfaModal] = useModal(false, 'valuation.cerfa');

    const { isAllowedLeadHomeCharge } = useMobilize();

    const { fetch, data: reviews } = useFetch<string, unknown>({
        // @ts-ignore
        fetchAction: getCustomerReviews,
    });

    const { email } = customer;

    useEffect(() => {
        if (email) {
            fetch(email);
        }
    }, [email, fetch]);

    useEffect(() => {
        dispatch(fetchCustomer({ id, history }));
        if (hasPermission('SECURYCAR_READ_CONTRACT') && hasTypology('securycar')) {
            dispatch(fetchContracts(id));
        }

        return () => {
            dispatch(resetCustomer());
        };
    }, [id, dispatch, history]);

    useEffect(() => {
        if (customer?.id && customer.id.toString() === id && doActivityRefresh(last_action)) {
            dispatch(fetchCustomerLife(customer.id.toString()));

            if (
                last_action === 'order_offer' ||
                last_action === 'dealership_visit' ||
                last_action === 'call' ||
                last_action === 'appointment' ||
                last_action === 'email' ||
                last_action === 'local_apv' ||
                last_action === 'generate_contract' ||
                last_action === 'local_home_charge'
            ) {
                dispatch(actions.closeModals());

                const scrollTimer = setTimeout(() => {
                    if (document.querySelector('.link-tab-all-event')) {
                        document.querySelector<HTMLElement>('.link-tab-all-event').click();
                        const showDetailButtons = document.querySelector(
                            `#event-all-${customer.leads[customer.leads.length - 1]?.id}`,
                        );
                        showDetailEvent(showDetailButtons, false);
                    }
                }, 750);

                return () => {
                    clearTimeout(scrollTimer);
                };
            }
        }
    }, [customer, dispatch]);

    useEffect(() => {
        if (isAllowedApvInStore) {
            manageApvInStoreListing(externalData, dispatch);
        }
    }, [externalData]);

    const excludedLeadTypes = ['local_apv', 'local_home_charge'];
    const customerLeadsWithoutAPV = customer?.leads?.filter((lead) => lead.leadType.slug !== 'local_apv');
    const customerCommerceLeads = customer?.leads?.filter((lead) => !excludedLeadTypes.includes(lead.leadType.slug));
    const customerLeadsAPV = customer?.leads?.filter((lead) => lead.leadType.slug === 'local_apv');

    return (
        <>
            {loading && (
                <Loader style={{ width: '5rem', height: '5rem' }} wrapperStyle={{ marginTop: '-15px' }} overlay />
            )}
            {!loading && (
                <CustomerHandBand
                    customer={customer}
                    lead={customerCommerceLeads ? customerCommerceLeads[customerCommerceLeads.length - 1] : null}
                />
            )}
            <Row className="flex-xl-nowrap">
                <Col
                    xl={4}
                    style={
                        hasPermission('VIEW_ACTIONS_BLOCK')
                            ? {
                                  flex: `0 0 ${containerWidth}`,
                                  maxWidth: containerWidth,
                              }
                            : {}
                    }
                >
                    <CustomerInfo
                        client={customer}
                        leads={customer?.leads || []}
                        reviews={reviews}
                        activity={activity}
                    />
                    <CustomerBusiness />
                </Col>
                {hasPermission('VIEW_ACTIONS_BLOCK') && (
                    <Col
                        lg={6}
                        style={{
                            flex: `0 0 ${containerWidth}`,
                            maxWidth: containerWidth,
                            marginTop: KTUtil.isInResponsiveRange('mobile') ? '25px' : '0',
                        }}
                    >
                        <CustomerActions />
                    </Col>
                )}
                <Col xl={hasPermission('VIEW_ACTIONS_BLOCK') ? 5 : 8} lg={12} className="mt-8 mt-xl-0">
                    {hasAnyTypologies(leadCommerceTypes) && (
                        <Actions
                            leads={customerLeadsWithoutAPV || []}
                            contracts={contracts}
                            className={`customer__actions ${
                                hasAnyTypologies(leadCommerceTypes) &&
                                hasAnyPermissions(['APV_LOCAL_TAKE_LEAD_CLIENT', 'APV_LOCAL_CLOSE_LEAD_CLIENT'])
                                    ? 'multi'
                                    : ''
                            }`}
                        />
                    )}
                    <Permission permissions={['APV_LOCAL_TAKE_LEAD_CLIENT', 'APV_LOCAL_CLOSE_LEAD_CLIENT']} hasAny>
                        <APVActions
                            leads={customerLeadsAPV || []}
                            apvOrders={apvOrders}
                            className={`customer__actions ${
                                hasAnyTypologies(leadCommerceTypes) &&
                                hasAnyPermissions(['APV_LOCAL_TAKE_LEAD_CLIENT', 'APV_LOCAL_CLOSE_LEAD_CLIENT'])
                                    ? 'multi'
                                    : ''
                            }`}
                        />
                    </Permission>
                </Col>
            </Row>
            <Row className="mt-8">
                <Col className="customer-life">
                    <CustomerLife events={activity?.data || []} loading={activity?.loading} />
                </Col>
            </Row>
            <Row className="mt-8 d-lg-block d-none">
                <Col>
                    <CustomerHistory />
                </Col>
            </Row>
            <Visit showModal={visitModalOpen} setShowModal={setVisitModalOpen} />
            <Appointment showModal={appointmentModalOpen} setShowModal={setAppointmentModalOpen} />
            <Purchase showModal={purchaseModalOpen} setShowModal={setPurchaseModalOpen} />
            <Modal As={Mail} showModal={mailModalOpen} setShowModal={setMailModalOpen} params={mailParams} />
            <Call showModal={callModalOpen} setShowModal={setCallModalOpen} />
            <NextActions showModal={nextActionModalOpen} setShowModal={setNextActionModalOpen} />
            <FirstActions showModal={firstActionsModalOpen} setShowModal={setFirstActionsModalOpen} />
            <Consign showModal={consignModalOpen} setShowModal={setConsignModalOpen} />
            <Modal
                As={LeadWon}
                showModal={leadwonModalOpen}
                setShowModal={setLeadwonModalOpen}
                params={leadwonParams}
            />
            <Modal
                As={LeadLoose}
                showModal={leadlooseModalOpen}
                setShowModal={setLeadlooseModalOpen}
                params={leadlooseParams}
            />
            <Callback showModal={callbackModalOpen} setShowModal={setCallbackModalOpen} />
            <LinkLead showModal={linkleadModalOpen} setShowModal={setLinkleadModalOpen} />
            <UnlinkLead showModal={unlinkleadModalOpen} setShowModal={setUnlinkleadModalOpen} />
            <Create showModal={showCreateModal} setShowModal={toggleCreateModal} />
            <RollbackUpdate showModal={rollbackUpdateModalOpen} setShowModal={setRollbackUpdateModalOpen} />
            <Feedback
                showModal={feedbackModalOpen}
                setShowModal={setFeedBackModalOpen}
                customer={customer}
                reviews={reviews}
            />
            <ForceTreatment showModal={showForceTreatmentModal} setShowModal={toggleForceTreatmentModal} />
            <VehicleKnow showModal={vehicleKnowModalOpen} setShowModal={setVehicleKnowModalOpen} />
            <EditComment showModal={editCommentModalOpen} setShowModal={setEditCommentModalOpen} />
            <ActivityLead customer={customer} activity={activity} />
            <Contact showModal={consignContactModal} setShowModal={setConsignContactModal} />
            <AFRPartExchangeDetail
                showModal={afrPartExchangeDetailModal}
                setShowModal={setAfrPartExchangeDetailModal}
            />
            <ContactAudi showModal={contactAudiModal} setShowModal={setContactAudiModal} lead={customer?.leads?.[0]} />
            <ContactBackupAudi
                showModal={contactBackupAudiModal}
                setShowModal={setContactBackupAudiModal}
                lead={customer?.leads?.[0]}
            />
            <LeadManufacturer showModal={leadManufacturerModal} setShowModal={setLeadManufacturerModal} />

            <Permission permissions={['APV_LOCAL_LEAD_CREATE']}>
                <APVRequest showModal={apvRequestModalOpen} setShowModal={setApvRequestModalOpen} />
            </Permission>

            <Permission permissions={['APV_LOCAL_LEAD_CREATE']}>
                <CommerceRequest showModal={commerceModalOpen} setShowModal={setCommerceModalOpen} />
            </Permission>

            <APVRequestReply showModal={apvRequestReplyModalOpen} setShowModal={setApvRequestReplyModalOpen} />
            <APVRequestReopen showModal={apvRequestReopenModalOpen} setShowModal={setApvRequestReopenModalOpen} />
            <APVRequestClose showModal={apvRequestCloseModalOpen} setShowModal={setApvRequestCloseModalOpen} />

            <Notes showModal={notesModalOpen} setShowModal={setNotesModalOpen} />

            <Permission permissions={['LEAD_AFFECT_LEAD', 'APV_LOCAL_AFFECT_LEAD']} hasAny>
                <AssignLead showModal={assignLeadModal} setShowModal={setAssignLeadModal} />
            </Permission>
            <Permission permissions={['LEAD_CHANGE_STATUT']}>
                <UpdateLeadStatus showModal={updateLeadStatusModal} setShowModal={setUpdateLeadStatusModal} />
            </Permission>
            <ContractExtension showModal={extendContractModalOpen} setShowModal={setExtendContractModalOpen} />
            <LeadPostpone showModal={postponeModalOpen} setShowModal={setPostponeModalOpen} />

            <Permission permissions={['SECURYCAR_MANAGE_CONTRACT']}>
                <SecuryCarContract showModal={contractSecurycarModal} setShowModal={setcontractSecurycarModal} />
                <SecuryCarContractSteps
                    showModal={contractSecurycarStepsModal}
                    setShowModal={setcontractSecurycarStepsModal}
                />
                <SecuryCarContractCGV showModal={contractSecurycarCGVModal} setShowModal={setContractSecurycarCGV} />
            </Permission>

            {isAllowedLeadHomeCharge && (
                <LeadHomeCharge showModal={leadHomeChargeModal} setShowModal={setLeadHomeChargeModal} />
            )}

            {isAllowedApvInStore && (
                <>
                    <APVInStore showModal={apvinstoreModalOpen} setShowModal={setApvinstoreModalOpen} />
                    <APVInStoreClose showModal={apvinstoreCloseModalOpen} setShowModal={setApvinstoreCloseModalOpen} />
                    <APVInStoreQuotationAccepted
                        showModal={apvinstoreWonModalOpen}
                        setShowModal={setApvinstoreWonModalOpen}
                    />
                    <APVInStoreQuotationAcceptedConfirm
                        showModal={apvinstoreWonConfirmModalOpen}
                        setShowModal={setApvinstoreWonConfirmModalOpen}
                    />
                </>
            )}
            <CreateOpportunity
                showModal={createOpportunity}
                setShowModal={setCreateOpportunity}
                params={createOpportunityParams}
            />
            <FollowUpTask
                showModal={followUpTaskModal}
                setShowModal={setFollowUpTaskModal}
                params={followUpTaskModalParams}
            />

            <Modal As={AssignTo} showModal={assignToModal} setShowModal={setAssignToModal} />

            <Permission permissions={['LEAD_VALUATION_GENERATE']}>
                <ModalContract showModal={valuationContractModal} setShowModal={setValuationContractModal} />
                <ModalCerfa showModal={valuationCerfaModal} setShowModal={setValuationCerfaModal} />
            </Permission>
            <Permission permissions={['LEAD_VALUATION_EDIT']}>
                <ModalPartExchangeOfferEdit showModal={valuationEditModal} setShowModal={setValuationEditModal} />
            </Permission>
        </>
    );
};

export default Customer;
