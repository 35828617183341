import React, {useContext} from 'react';
import {Form} from "react-bootstrap";
import {FiltersContext} from "./FiltersProvider";

const SelectFilter = ({options, onChange, name}) => {
    const filters = useContext(FiltersContext);

    return (
        <Form.Control as="select"
                      value={filters[name]?.value || ''}
                      onChange={(e) => {
                          onChange({
                              [name]: {
                                  value: e.target.value
                              }
                          })
                      }}
        >
            {options}
        </Form.Control>
    );
};

export default SelectFilter;
