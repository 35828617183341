import * as dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';

import RowInfo from '@app/partials/content/RowInfo';

const LeadApvInfo = ({ event }) => {
    const Intl = useIntl();
    return (
        <>
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DATE_HOUR' })}
                value={event?.product[0]?.date && dayjs(event?.product[0]?.date).format('DD/MM/YYYY [à] HH [h] mm')}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DEALERSHIP' })}
                value={event?.dealership?.usual_name}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DEALERSHIP_ADDRESS' })}
                value={`${event?.dealership?.address} ${event?.dealership?.zipcode} ${event?.dealership?.city}`}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.OBJECT' })}
                value={event?.product[0]?.title}
            />
            <div className="comment">
                <span>
                    <i className="la la-comment la-lg mr-2" />
                    {Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.COMMENT' })}
                </span>
                <p className="mt-4">{event?.comment || '-'}</p>
            </div>
        </>
    );
};

export default LeadApvInfo;
