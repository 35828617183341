import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

interface Props {
    event: VcuMappedEventDetails;
}
const DetailFunding = ({ event }: Props) => {
    const Intl = useIntl();
    const { funding } = event;
    return (
        <div className="d-md-flex justify-content-md-between align-items-md-stretch">
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.TYPE.EVENT' })}
                    value={funding?.fundingProcessLabel || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.TYPE.CONTRAT' })}
                    value={funding?.fundingProductType || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.CATEGORIE' })}
                    value={funding?.fundingCategory || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.PARTNER' })}
                    value={funding?.fundingPartnerName || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.NUMBER.CONTRACT',
                    })}
                    value={funding?.fundingContractNumber || '-'}
                />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.DATE.CONTRACT',
                    })}
                    value={funding?.fundingCreatedDate ? dayjs(funding?.fundingCreatedDate).format('DD/MM/YYYY') : '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.DURATION.OF.FINANCING',
                    })}
                    value={`${funding?.fundingDuration ?? '-'} mois`}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.TOTAL.PRODUCTION' })}
                    value={
                        <NumberFormat
                            value={funding?.fundingPrice || '-'}
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            thousandSeparator=" "
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" €"
                            displayType="text"
                        />
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.SUBSCRIBED.SERVICES',
                    })}
                    value={
                        (funding?.fundingPrestations &&
                            funding?.fundingPrestations.map(
                                (item: {
                                    fundingPrestationBenefitCode: string | null;
                                    fundingPrestationAmount: string | null;
                                }) => `${item.fundingPrestationBenefitCode}(${item.fundingPrestationAmount})`,
                            )) ||
                        '-'
                    }
                />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.DEALERSHIP' })}
                    value={event?.informations.dealership.name || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.CODE.DEALERSHIP',
                    })}
                    value={event?.informations?.refDealershipId || '-'}
                />
            </div>
        </div>
    );
};

export default DetailFunding;
