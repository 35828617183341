import React, {useEffect} from "react";
import {Card, Col, Form} from "react-bootstrap";
import {Intl} from "../../../../../_metronic/i18n/I18nProvider";
import {useFormContext} from "react-hook-form";
import ErrorForm from "../../../../partials/layout/ErrorForm";

function CardDiscount({associationType}) {
    const {register, setValue, watch, errors} = useFormContext();
    const discount = watch('discount');

    useEffect(() => {
        if (associationType === 'otherLinkAssociation') {
            setValue('discount', 'noDiscount');
            setValue('percentage', '');
            setValue('price', '');
        }
    }, [setValue, associationType])

    return (
        <Card className="shadow-sm">
            <Card.Header>
                <i className="card__icon fas fa-money-bill-wave"/>
                {Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.DISCOUNT.TITLE' })}
            </Card.Header>
            <Card.Body>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Check
                                ref={register}
                                type="radio"
                                disabled={associationType === 'otherLinkAssociation'}
                                label={Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.DISCOUNT.PERCENTAGE' })}
                                name="discount"
                                id="percentageDiscount"
                                value="percentageDiscount"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                ref={register({
                                    validate: (value) => {
                                        return discount === 'percentageDiscount' && !parseInt(value, 10) ? Intl.formatMessage({id: 'FORM.ERROR.NUMBER'}) : true
                                    }
                                })}
                                name="percentage"
                                type="number"
                                className={`${errors.percentage ? 'is-invalid' : ''}`}
                                disabled={discount !== 'percentageDiscount'}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Check
                                ref={register}
                                type="radio"
                                disabled={associationType === 'otherLinkAssociation'}
                                label={Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.DISCOUNT.PRICE.VALUE' })}
                                name="discount"
                                id="priceDiscount"
                                value="priceDiscount"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                ref={register({
                                    validate: (value) => {
                                        return discount === 'priceDiscount' && !parseInt(value, 10) ? Intl.formatMessage({id: 'FORM.ERROR.NUMBER'}) : true
                                    }
                                })}
                                name="price"
                                type="number"
                                className={`${errors.price ? 'is-invalid' : ''}`}
                                disabled={discount !== 'priceDiscount'}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Check
                                ref={register}
                                type="radio"
                                label={Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.DISCOUNT.NO.DISCOUNT' })}
                                name="discount"
                                id="noDiscount"
                                value="noDiscount"
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <ErrorForm errors={errors} name="price" />
                        <ErrorForm errors={errors} name="percentage" />
                    </Col>
                </Form.Row>
            </Card.Body>
        </Card>
    )
}

CardDiscount.defaultProps = {
    associationType: undefined
}

export default CardDiscount;
