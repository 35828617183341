import classNames from 'classnames';
import React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import variantTypes from '@app/constants';
import useAction from '@app/pages/customers/Hooks/useAction';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

type CreateOpportunityProps = {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    params: { leadOriginId: string };
};

const CreateOpportunity = ({ showModal, setShowModal, params }: CreateOpportunityProps) => {
    const { REACT_APP_SALESFORCE_BMW } = process.env;
    const Intl = useIntl();
    const { register, errors, handleSubmit, watch } = useFormContext();

    const opportunityNumber = watch('opportunityNumber');
    const isInvalid = opportunityNumber?.trim().length !== 10 && opportunityNumber?.trim().length > 0;

    const [loading, createAction] = useAction({
        type: 'create_opportunity',
        success: () => {
            setShowModal(false);
            toast({
                variant: variantTypes.SUCCESS,
                message: Intl.formatMessage({ id: 'TRANSLATOR.SUCCESS' }),
            });
        },
        failed: () => {
            toast({
                variant: variantTypes.DANGER,
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        },
    });

    const onSubmit = () => {
        // @ts-ignore
        createAction({
            opportunityNumber,
        });
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            bodyClassName="d-flex align-items-center flex-column"
            hideModal={() => setShowModal(false)}
            title={
                <div>
                    <Image className="mb-4" src="/media/pages/customers/bonhomme-opportunite.svg" />
                    <span className="text-success px-8">
                        <FormattedHTMLMessage id="CUSTOMER.MODAL.CREATE_OPPORTUNITY.TITLE" />
                    </span>
                </div>
            }
            body={
                <>
                    <Form.Group className="pr-2">
                        <Form.Label>
                            <FormattedHTMLMessage id="CUSTOMER.MODAL.CREATE_OPPORTUNITY.LABEL" />
                            <a
                                target="_blank"
                                href={`${REACT_APP_SALESFORCE_BMW}/${params?.leadOriginId}/view`}
                                rel="noreferrer"
                                className="px-1"
                            >
                                <FormattedHTMLMessage id="CUSTOMER.MODAL.CREATE_OPPORTUNITY.CLICK" />
                            </a>
                        </Form.Label>
                        <Form.Label>
                            <FormattedHTMLMessage id="CUSTOMER.MODAL.CREATE_OPPORTUNITY.INPUT_LABEL" />
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="opportunityNumber"
                            placeholder={Intl.formatMessage({
                                id: 'CUSTOMER.MODAL.CREATE_OPPORTUNITY.INPUT_PLACEHOLDER',
                            })}
                            className={classNames({ 'is-invalid': isInvalid })}
                            ref={register({
                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                validate: () => {
                                    if (isInvalid) {
                                        return Intl.formatMessage({
                                            id: 'CUSTOMER.MODAL.CREATE_OPPORTUNITY.ERROR',
                                        });
                                    }
                                    return true;
                                },
                            })}
                        />
                        <ErrorForm errors={errors} name="opportunityNumber" />
                    </Form.Group>
                    <Image className="w-50" src="/media/pages/customers/salesforce-apercu.png" />
                </>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        <FormattedHTMLMessage id="TRANSLATOR.CANCEL" />
                    </Button>
                    <Button variant="primary" disabled={!opportunityNumber} onClick={handleSubmit(onSubmit)}>
                        <FormattedHTMLMessage id="TRANSLATOR.VALIDATE" />
                    </Button>
                </div>
            }
        />
    );
};

export default CreateOpportunity;
