import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import ErrorForm from '@app/partials/layout/ErrorForm';

const LeadWonComment = ({ params }) => {
    const { register, errors } = useFormContext();
    const Intl = useIntl();

    return (
        <Form.Group>
            <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_WON.COMMENT' })}</Form.Label>
            <Form.Control
                as="textarea"
                rows={2}
                name="result"
                disabled={!!params?.event}
                className={`${errors.result ? 'is-invalid' : ''}`}
                placeholder={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_WON.COMMENT.PLACEHOLDER' })}
                ref={register({
                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                })}
            />
            <ErrorForm errors={errors} name="result" />
        </Form.Group>
    );
};

export default LeadWonComment;
