import React from 'react';

import StockVehiclesEligibilityColumn from './StockVehiclesEligibilityColumn';
import StockVehiclesPublicationColumn from './StockVehiclesPublicationColumn';

const StockVehiclesEligibilityPublicationResponsiveColumn = ({ vehicle }) => (
    <>
        <StockVehiclesEligibilityColumn vehicle={vehicle} />
        <hr />
        <StockVehiclesPublicationColumn vehicle={vehicle} />
    </>
);

export default StockVehiclesEligibilityPublicationResponsiveColumn;
