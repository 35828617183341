import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import ExtendIcon from '@app/pages/customers/ExtendIcon';
import useAction from '@app/pages/customers/Hooks/useAction';

import { DateTimePicker } from '@app/partials/content/DateTimePicker';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

type LeadPostponeProps = {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
};

const LeadPostpone = ({ showModal, setShowModal }: LeadPostponeProps) => {
    const Intl = useIntl();
    const [selectedDate, setSelectedDate] = useState(null);

    const [loading, createAction] = useAction({
        type: 'close_lead_postponed',
        success: () => {
            setShowModal(false);
            toast({
                variant: 'success',
                message: Intl.formatMessage(
                    { id: 'CUSTOMER.MODAL.LEAD_POSTPONE.SUCCESS' },
                    { date: dayjs(selectedDate).format('DD/MM/YYYY') },
                ),
            });
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMER.MODAL.LEAD_POSTPONE.ERROR' }),
            });
        },
    });

    const onSubmit = () => {
        // @ts-ignore
        createAction({
            postponeDate: dayjs(selectedDate).format('YYYY-MM-DD'),
        });
    };

    useEffect(() => {
        if (!showModal) setSelectedDate(null);
    }, [showModal]);

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<ExtendIcon width="36" height="36" color="#15b7ff" />}
            title={<FormattedHTMLMessage id="CUSTOMER.MODAL.LEAD_POSTPONE.TITLE" />}
            body={
                <Form.Group className="pr-2">
                    <Form.Label>
                        <FormattedHTMLMessage id="CUSTOMER.MODAL.LEAD_POSTPONE.LABEL" />
                    </Form.Label>
                    <DateTimePicker
                        locale={Locale}
                        dateFormat="D MMMM, YYYY"
                        onChange={(e: { value: string | number | Date | dayjs.Dayjs }) => {
                            const value = dayjs(e.value).isValid() ? dayjs(e.value).toDate() : null;
                            setSelectedDate(value);
                        }}
                        minDate={dayjs().toDate()}
                        value={selectedDate}
                    />
                </Form.Group>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        <FormattedHTMLMessage id="TRANSLATOR.CANCEL" />
                    </Button>
                    <Button variant="primary" disabled={!selectedDate} onClick={onSubmit}>
                        <FormattedHTMLMessage id="TRANSLATOR.VALIDATE" />
                    </Button>
                </div>
            }
        />
    );
};

export default LeadPostpone;
