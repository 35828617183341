import React from "react";
import {Card, Form} from "react-bootstrap";
import {Intl} from "../../../../../_metronic/i18n/I18nProvider";
import Editor from "../../../../partials/content/Editor";
import {Controller, useFormContext} from "react-hook-form";

export default function CardDetail({ height }) {
    const {control} = useFormContext();

    return (
        <Card className="shadow-sm">
            <Card.Header>
                <i className="card__icon fas fa-edit"/>
                {Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.DETAIL.TITLE' })}
            </Card.Header>
            <Card.Body>
                <Form.Group>
                    <Controller
                        control={control}
                        name="seoDescription"
                        defaultValue=""
                        render={(props) => (
                            <Editor
                                value={props.value}
                                config={{
                                    minHeight: typeof height !== "undefined" ? height : 500
                                }}
                                onChange={props.onChange}
                            />
                        )}/>
                </Form.Group>
            </Card.Body>
        </Card>
    )
}
