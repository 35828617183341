import React, { useEffect, useState } from 'react';

import useFetch from '@app/hooks/useFetch';

import { getDefaultSchedule } from '@app/crud/account/account.crud';

import Loader from '@app/partials/content/Loader';

import { DEFAULT_WEEK, PLANNING } from '../Helpers/Planning';

import DefaultWeek from './DefaultWeek';
import Planning from './Planning';

const AccountPlanning = ({ userData, profile }) => {
    const [section, setSection] = useState(DEFAULT_WEEK);
    const [firstLoad, setFirsLoad] = useState(true);
    const {
        fetch,
        data: defaultSchedule,
        loading,
    } = useFetch({
        fetchAction: getDefaultSchedule,
    });

    const content = {
        [DEFAULT_WEEK]: (
            <DefaultWeek
                userData={userData}
                profile={profile}
                defaultSchedule={defaultSchedule[userData.id] || {}}
                onSectionChange={(nextSection) => changeSection(nextSection)}
                fetchDefaultPlanning={fetch}
            />
        ),
        [PLANNING]: (
            <Planning
                userData={userData}
                profile={profile}
                onSectionChange={(nextSection) => changeSection(nextSection)}
            />
        ),
    };

    const changeSection = (nextSection) => {
        setSection(defaultSchedule[userData.id] ? nextSection : DEFAULT_WEEK);
    };

    useEffect(() => {
        if (section === DEFAULT_WEEK) {
            fetch(userData.id);
        }
    }, [fetch, section]);

    useEffect(() => {
        if (firstLoad && defaultSchedule[userData.id]) {
            setSection(defaultSchedule[userData.id] ? PLANNING : DEFAULT_WEEK);
            setFirsLoad(false);
        }
    }, [defaultSchedule, setSection, setFirsLoad, firstLoad]);

    return (
        <div className="account-planning">
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            {content[section]}
        </div>
    );
};

AccountPlanning.defaultProps = {
    profile: true,
};

export default AccountPlanning;
