import React, { ReactNode } from 'react';

import { CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { LeadDetails } from '@app/crud/customers/customer.type';

export const EventDetailContext = React.createContext({});

type EventDetailProviderProps = {
    value: (id: string) => Promise<CustomAxiosResponse<LeadDetails>>;
    children: ReactNode;
};

const EventDetailProvider = ({ value, children }: EventDetailProviderProps) => (
    <EventDetailContext.Provider value={value}>{children}</EventDetailContext.Provider>
);

export default EventDetailProvider;
