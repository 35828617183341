import {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useDispatch} from "react-redux";
import {isNewRootLocation} from "../../helpers/ToolsHelper";

function RouteSwitch(props) {
    const { path } = props.match;
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.prevMatchPath && path !== props.prevMatchPath) {
            /**
             * Reset state on each path change
             */
            dispatch({ type: 'RESET_MODAL' });
        }
        if (isNewRootLocation(path, props.prevMatchPath)) {
            /**
             * Reset state on each path change
             */
            dispatch({ type: 'RESET_STATE' });

            /**
             * Scroll to top on each path change
             */
           window.scrollTo(0, 0);
        }
    }, [dispatch, path, props.prevMatchPath])

    return null;
}

export default withRouter(RouteSwitch);
