import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

type FullWebInfoProps = {
    event: VcuMappedEventDetails;
};

const FullWebInfo = ({ event }: FullWebInfoProps) => {
    const { REACT_APP_AUTODELIVERY_URL } = process.env;
    const Intl = useIntl();

    return (
        <>
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FULLWEB.STATUS' })}
                value={event?.fullWeb.orderwebStatusLibelle ? event?.fullWeb.orderwebStatusLibelle : '-'}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FULLWEB.SOURCE' })}
                value={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FULLWEB.SOURCE.VALUE' })}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FULLWEB.ORDER.VALIDATE.DATE' })}
                value={
                    event?.fullWeb.orderwebDateCreate &&
                    dayjs(event?.fullWeb.orderwebDateCreate).format('DD/MM/YYYY [à] HH [h] mm')
                }
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FULLWEB.ORDER.DELIVERY.DATE' })}
                value={
                    event?.fullWeb.orderwebDateMaj && event?.fullWeb.orderwebDateMaj
                        ? dayjs(event?.fullWeb.orderwebDateMaj).format('DD/MM/YYYY')
                        : '-'
                }
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FULLWEB.DEALERSHIP' })}
                value={
                    event?.informations.dealership.name
                        ? `${event?.informations.dealership.name} (${event?.informations.refDealershipId})`
                        : '-'
                }
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FULLWEB.FUNDING' })}
                value={event?.fullWeb.orderwebFinancementMode ? event?.fullWeb.orderwebFinancementMode : '-'}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FULLWEB.BRING' })}
                value={
                    event?.fullWeb.orderwebApport ? (
                        <NumberFormat
                            value={event?.fullWeb.orderwebApport}
                            decimalSeparator="."
                            thousandSeparator=" "
                            className="text-nowrap"
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" €"
                            displayType="text"
                        />
                    ) : (
                        '-'
                    )
                }
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FULLWEB.AMOUNT_FINANCED' })}
                value={
                    event?.fullWeb.orderwebFinancement ? (
                        <NumberFormat
                            value={event?.fullWeb.orderwebFinancement}
                            decimalSeparator="."
                            thousandSeparator=" "
                            className="text-nowrap"
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" €"
                            displayType="text"
                        />
                    ) : (
                        '-'
                    )
                }
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FULLWEB.CARTE_GRISE_TARIF' })}
                value={
                    event?.fullWeb.orderwebCartegriseTarif ? (
                        <NumberFormat
                            value={event?.fullWeb.orderwebCartegriseTarif}
                            decimalSeparator="."
                            thousandSeparator=" "
                            className="text-nowrap"
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" €"
                            displayType="text"
                        />
                    ) : (
                        '-'
                    )
                }
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FULLWEB.TOTAL.ORDER' })}
                value={
                    event?.fullWeb.orderwebTotal ? (
                        <NumberFormat
                            value={event?.fullWeb.orderwebTotal}
                            decimalSeparator="."
                            thousandSeparator=" "
                            className="text-nowrap"
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" €"
                            displayType="text"
                        />
                    ) : (
                        '-'
                    )
                }
            />
            <a
                href={`${REACT_APP_AUTODELIVERY_URL}/admin/order/view/${event?.fullWeb.orderwebOrderId}`}
                target="_blank"
                rel="noreferrer"
            >
                Voir le commande sur AutoDelivery
            </a>
        </>
    );
};

export default FullWebInfo;
