import React, {KeyboardEvent, ReactNode, useContext, useEffect, useRef} from 'react';
import {Button, Form, InputGroup, FormControl} from "react-bootstrap";
import {FiltersContext} from "./FiltersProvider";

type Value = {
    [name: string]: {value: string}
}
type InputFilterProps = {
    name: string,
    onChange?: (value: Value) => void,
    pending?: boolean,
    onPendingChange?: (value: Value) => void,
    placeholder?: string
    prepend?: React.ReactNode
}
const InputFilter = (
    {
        name,
        onChange,
        pending,
        onPendingChange,
        placeholder,
        prepend
    }: InputFilterProps) => {
    const filters = useContext(FiltersContext);
    const inputRef = useRef<HTMLInputElement & typeof FormControl>(null);

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.value = filters[name]?.value || '';
        }
    }, [filters, inputRef])

    const onResetFilter = () => {
        onChange({[name]: {value: ''}})
    }

    return (
        <InputGroup>
            { prepend && (
                <InputGroup.Prepend>
                    { prepend }
                </InputGroup.Prepend>
            )}
            <Form.Control type="text"
                          ref={inputRef}
                          defaultValue={filters[name]?.value || ''}
                          placeholder={placeholder}
                          onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
                              const value = {
                                  [name]: {
                                      value: (e.target as HTMLInputElement).value
                                  }
                              }
                              if (!pending) {
                                  if (e.which === 13) {
                                      onChange({...value});
                                  }
                              } else {
                                  onPendingChange({...value});
                              }
                          }}/>
            {filters[name]?.value && (
                <InputGroup.Append>
                    <Button type='button' size='sm' variant='outline-primary' onClick={onResetFilter}>
                        <i className="pi pi-times"></i>
                    </Button>
                </InputGroup.Append>
            )}
        </InputGroup>
    );
};

InputFilter.defaultProps = {
    pending: false
}

export default InputFilter;
