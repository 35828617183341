import React, { ChangeEvent, useEffect } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import InStockPreview from '@app/pages/customers/Partials/InStockPreview';
import OutStockPreview from '@app/pages/customers/Partials/OutStockPreview';
import { Vehicle } from '@app/store/stock/stock.type';

import { getVehicleInternalIdTemplate } from '@app/partials/content/Vehicle/VehicleHelpers';
import ErrorForm from '@app/partials/layout/ErrorForm';

import { SubmittedProps } from '../CommerceRequest';

type CommerceRequestVehicleProps = {
    data: Partial<SubmittedProps>;
};

const vehicleTypes = {
    vehicle_in_stock: 'Véhicule en stock (à sélectionner)',
    other_vehicle: 'Autre véhicule (Marque, modèle, etc. à préciser)',
    not_sure: 'Ne sait pas',
};

const CommerceRequestVehicle = ({ data }: CommerceRequestVehicleProps) => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { register, errors, getValues, setValue, watch, trigger } = methods;
    const [vehicleType, setVehicleType] = React.useState(data?.vehicleType || undefined);
    const [showVehicle, setShowVehicle] = React.useState(false);
    const [showOutStockVehicle, setShowOutStockVehicle] = React.useState(false);
    const vehicles = watch('vehicles');

    useEffect(() => {
        register('vehicles');

        if (!getValues('vehicles')) {
            setValue('vehicles', []);
        }
    }, [register, setValue]);

    useEffect(() => {
        trigger('vehicleType');
    }, [vehicles]);

    const inStockVehicle = vehicles
        ? vehicles.filter((vehicle: Vehicle) => getVehicleInternalIdTemplate(vehicle, null))
        : [];
    const outStockVehicle = vehicles
        ? vehicles.filter((vehicle: Vehicle) => !getVehicleInternalIdTemplate(vehicle, null))
        : [];

    return (
        <>
            <Form.Group as={Col} md={12}>
                <Form.Label className="d-block mr-10 mb-5">
                    <FormattedMessage id="CUSTOMERS.MODAL.LOCAL_COMMERCE.VEHICLE_TYPE.LABEL" />
                </Form.Label>
                {vehicleTypes &&
                    Object.entries(vehicleTypes).map(([key, value]) => (
                        <Form.Check
                            id={key}
                            name="vehicleType"
                            inline={false}
                            type="radio"
                            defaultChecked={(data?.vehicleType as string) === key}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setVehicleType(e.target.value);
                                setValue('vehicles', []);
                            }}
                            ref={register({
                                required: Intl.formatMessage({
                                    id: 'FORM.ERROR.REQUIRED',
                                }),
                                validate: (fieldValue: keyof typeof vehicleTypes) => {
                                    if (fieldValue === 'not_sure' || vehicles.length > 0) {
                                        return true;
                                    }

                                    return Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LOCAL_COMMERCE.VEHICLES.ERROR' });
                                },
                            })}
                            value={key}
                            label={value}
                            className="mb-4"
                        />
                    ))}
                <ErrorForm errors={errors} name="vehicleType" />
            </Form.Group>
            {vehicleType === 'vehicle_in_stock' && (
                <>
                    <Button className="mb-5" onClick={() => setShowVehicle(true)}>
                        <FormattedMessage id="CUSTOMERS.MODAL.LOCAL_COMMERCE.VEHICLES.IN_STOCK" />
                    </Button>
                    <InStockPreview
                        vehicles={inStockVehicle}
                        number={vehicles?.length}
                        showVehicle={showVehicle}
                        setShowVehicle={setShowVehicle}
                    />
                </>
            )}
            {vehicleType === 'other_vehicle' && (
                <>
                    <Button className="mb-5" onClick={() => setShowOutStockVehicle(true)}>
                        <FormattedMessage id="CUSTOMERS.MODAL.LOCAL_COMMERCE.VEHICLES.OUT_STOCK" />
                    </Button>
                    <OutStockPreview
                        vehicles={outStockVehicle}
                        number={vehicles?.length}
                        showVehicle={showOutStockVehicle}
                        setShowVehicle={setShowOutStockVehicle}
                    />
                </>
            )}
        </>
    );
};

export default CommerceRequestVehicle;
