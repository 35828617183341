import React from 'react';
import { Link } from 'react-router-dom';

import { getBrandTemplate, getUrl } from '@app/partials/content/Vehicle/VehicleHelpers';

const Car = ({ data, onRemove }) => (
    <div className=" d-flex align-items-end">
        <div className="flex-grow-1 pr-5">
            <div className="font-weight-semibold font-size-lg">
                {getBrandTemplate(data)} {data.model}
            </div>
            <div>{data.version}</div>
        </div>
        <div className="text-nowrap">
            {getUrl(data) && (
                <Link to={{ pathname: getUrl(data) }} target="_blank">
                    <i className="cursor-pointer la la-lg la-eye mr-3" />
                </Link>
            )}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <i className="cursor-pointer la la-lg la-remove" onClick={() => onRemove(data)} />
        </div>
    </div>
);

export default Car;
