import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from '@app/hooks';

import { AccountHistory, AccountSettings, AccountPassword, AccountPerimeter } from './index';

import { AccountMenu } from './AccountMenu';
import { AccountPlanning } from './AccountPlanning';
import { AccountTeam } from './AccountTeam';
import { ACCOUNT_MENUS } from './Helpers/Menu';

import './Account.scss';

const Account = () => {
    const location = useLocation();
    const { userData } = useAppSelector((state) => state.auth);

    const rightComponents = [
        {
            url: 'settings',
            component: AccountSettings,
        },
        {
            url: 'planning',
            component: AccountPlanning,
        },
        {
            url: 'team',
            component: AccountTeam,
        },
        {
            url: 'perimeter',
            component: AccountPerimeter,
        },
        {
            url: 'history',
            component: AccountHistory,
        },
        {
            url: 'password',
            component: AccountPassword,
        },
    ];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const rightComponent = Object.entries(rightComponents).find(([_, value]) => location.pathname.includes(value.url));

    const Component = rightComponent?.length ? rightComponent[1].component : null;
    const defaultActiveMenu = rightComponent?.length ? parseInt(rightComponent[0], 10) : 0;

    return (
        <div className="account">
            <Card>
                <Card.Body className="p-0">
                    <Row>
                        <Col className="col-xxl-3 col-lg-4">
                            <AccountMenu
                                userData={userData}
                                menus={ACCOUNT_MENUS}
                                defaultActiveMenu={defaultActiveMenu}
                            />
                        </Col>
                        <Col className="p-0 col-lg-8">
                            <Component userData={userData} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
};

export default Account;
