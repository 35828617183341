import React from 'react';

const JoditButton = ({children, onClick}) => {
    return (
        <span className="jodit-toolbar-button jodit-toolbar-button_size_middle jodit-toolbar-button_text-icons_true"
              role="listitem">
            <button className="jodit-toolbar-button__button"
                    type="button"
                    role="button"
                    aria-pressed="false"
                    onClick={onClick}
                    tabIndex="-1">
                <span className="jodit-toolbar-button__text">
                    <span>{children}</span>
                </span>
            </button>
        </span>
    );
};

export default JoditButton;
