import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { getLocalPerformances, getOnlinePerformances } from '@app/crud/dashboard/achat-cash';

import { useFetch } from '@app/hooks';

import { DashboardContext } from '../../DashboardProvider';

import PerformancesBySource from './PerformancesBySource';

interface Props {
    className?: string;
}

const PerformancesSources = ({ className }: Props) => {
    const { overriddenParams } = useContext(DashboardContext);

    const {
        data: dataLocal,
        loading: loadingLocal,
        fetch: fetchLocal,
    } = useFetch({
        fetchAction: getLocalPerformances,
    });

    const {
        data: dataOnline,
        loading: loadingOnline,
        fetch: fetchOnline,
    } = useFetch({
        fetchAction: getOnlinePerformances,
    });

    useEffect(() => {
        fetchLocal(overriddenParams);
        fetchOnline(overriddenParams);
    }, [overriddenParams]);

    if (loadingLocal || loadingOnline) {
        return (
            <Skeleton
                borderRadius="10px"
                height="200px"
                className="d-flex flex-column flex-lg-grow-1 mt-lg-2 mt-6 p-35"
            />
        );
    }

    return (
        <div className={classNames('dashboard-online-perf d-flex flex-column', className)}>
            <div className="flex-grow-1">
                <div className="font-size-lg font-weight-bold d-flex flex-wrap align-items-center justify-content-between">
                    <i className="dashboard-general-perf__icon las la-2x la-bullseye mr-2" />
                    <div className="flex-grow-1 d-flex align-items-center">
                        <FormattedMessage id="DASHBOARD.SOURCES_PERF.TITLE" />
                    </div>
                </div>

                <div className="d-flex flex-lg-nowrap flex-wrap justify-content-start dashboard-achat-cash-local-perf__list">
                    {Object.keys(dataLocal).map((origin) => {
                        const performances = dataLocal[origin];

                        return <PerformancesBySource key={origin} performances={performances} className="mr-4" />;
                    })}
                </div>
                <div className="d-flex flex-lg-nowrap flex-wrap justify-content-start dashboard-achat-cash-online-perf__list">
                    {Object.keys(dataOnline).map((origin) => {
                        const performances = dataOnline[origin];

                        return <PerformancesBySource key={origin} performances={performances} className="mr-4" />;
                    })}
                </div>
            </div>
        </div>
    );
};

PerformancesSources.defaultProps = {
    className: '',
};

export default PerformancesSources;
