import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Button } from 'react-bootstrap';

import './modalstepper.scss';

export type ModalStepperType = {
    currentStep: number;
    steps: ModalStep[];
};

interface ModalStep {
    name: string;
    status: string;
    content?: ReactNode;
}

type ModalStepperProps = {
    state: ModalStepperType;
    arrows?: boolean;
    navigate?: boolean;
    setStep?: (step: number) => void;
};

const ModalStepper = ({ state, arrows, navigate, setStep }: ModalStepperProps) => {
    let stepIndex = 0;
    const isValid = (index: number) => state.steps[index] && state.steps[index].status === 'valid';
    const isError = (index: number) => state.steps[index] && state.steps[index].status === 'error';
    const isCurrent = (index: number) => index === state.currentStep;
    const icon = (index: number) => {
        if (isCurrent(index)) {
            return <i className="la la-pen ml-1 text-primary" />;
        } else if (isValid(index)) {
            return <i className="la la-check-circle ml-1 text-success" />;
        } else if (isError(index)) {
            return <i className="la la-exclamation-circle ml-1 text-danger" />;
        }
    };

    const handlePrevStep = () => {
        const { currentStep } = state;

        if (currentStep > 0) {
            setStep(currentStep - 1);
        }
    };

    const handleNextStep = () => {
        const { currentStep, steps } = state;

        if (currentStep < steps.length - 1) {
            setStep(currentStep + 1);
        }
    };
    return (
        <nav className="modal__stepper d-flex justify-content-center align-items-end mb-4">
            {arrows && (
                <Button
                    disabled={state.currentStep === 0}
                    variant="link"
                    style={{ padding: '0 0 3px 0' }}
                    onClick={handlePrevStep}
                >
                    <i className="la la-angle-double-left la-2x pr-2 kt-shape-font-color-4" />
                </Button>
            )}
            <ol className="d-flex list-unstyled m-0">
                {state.steps.map((step) => {
                    if (step.status && step.status !== 'disabled') {
                        const currentStep = stepIndex;
                        stepIndex += 1;
                        return (
                            <li key={`step-${stepIndex}`}>
                                {navigate ? (
                                    <button
                                        type="button"
                                        onClick={() => setStep(currentStep)}
                                        className={classNames('modal__stepper__item list-style-none', {
                                            'modal__stepper__item--isActive': isCurrent(currentStep),
                                            'modal__stepper__item--navigate': navigate,
                                        })}
                                    >
                                        {stepIndex} &bull; {step.name}
                                        {icon(currentStep)}
                                    </button>
                                ) : (
                                    <span
                                        className={classNames('modal__stepper__item list-style-none', {
                                            'modal__stepper__item--isActive': isCurrent(currentStep),
                                            'modal__stepper__item--navigate': navigate,
                                        })}
                                    >
                                        {stepIndex} &bull; {step.name}
                                        {icon(currentStep)}
                                    </span>
                                )}
                            </li>
                        );
                    }

                    return null;
                })}
            </ol>
            {arrows && (
                <Button
                    disabled={state.currentStep === state.steps.length - 1}
                    variant="link"
                    style={{ padding: '0 0 3px 0' }}
                    onClick={handleNextStep}
                >
                    <i className="la la-angle-double-right la-2x pl-2 kt-shape-font-color-4" />
                </Button>
            )}
        </nav>
    );
};
ModalStepper.defaultProps = {
    arrows: false,
    navigate: true,
    setStep: () => {},
};
export default ModalStepper;
