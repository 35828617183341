import { getQuotation, getQuotations } from '@app/crud/smartRepair/smart-repair.crud';

import { actions } from './smart-repair.store';

export function fetchQuotations(params) {
    return (dispatch) => {
        dispatch(actions.fetchQuotations());

        getQuotations(params)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(
                        actions.fetchedQuotations({
                            ...response.result,
                            orderType: params.orderType,
                            count: response?.count || 0,
                        }),
                    );
                } else {
                    dispatch(actions.fetchQuotationsFailed({}));
                }
            })
            .catch(() => {
                dispatch(actions.fetchQuotationsFailed({}));
            });
    };
}

export function fetchQuotation(id) {
    return (dispatch) => {
        dispatch(actions.fetchQuotation());

        getQuotation(id)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.fetchedQuotation(response.result));
                } else {
                    dispatch(actions.fetchQuotationFailed(response.result));
                }
            })
            .catch(() => {
                dispatch(actions.fetchQuotationFailed({}));
            });
    };
}
