import { Dropdown } from 'primereact/dropdown';
import React, { useCallback, useContext, useEffect } from 'react';

import { FiltersContext } from './FiltersProvider';

const DropdownFilter = ({
    optionLabel,
    name,
    filterBy,
    options,
    onChange,
    pending,
    onPendingChange = () => {},
    showClear,
}) => {
    const filters = useContext(FiltersContext);

    const getValue = useCallback(
        (value) => ({
            [name]: {
                value: value?.key ?? '',
                componentValue: value ?? null,
            },
        }),
        [],
    );

    useEffect(() => {
        if (options?.length === 0) return;

        if (filters[name]?.value && !filters[name]?.componentValue) {
            const componentValue = options.find((option) => option?.key === filters[name]?.value);
            const value = getValue(componentValue);

            onChange({ ...value });
        }
    }, [filters[name], options]);

    return (
        <Dropdown
            value={filters[name]?.componentValue || null}
            filter
            showClear={showClear}
            optionLabel={optionLabel}
            filterBy={filterBy}
            options={options}
            valueTemplate={(e) => (e ? e[optionLabel] : '')}
            onChange={(e) => {
                const value = getValue(e?.value);

                if (!pending) {
                    onChange({ ...value });
                } else {
                    onPendingChange({ ...value });
                }
            }}
        />
    );
};

DropdownFilter.defaultProps = {
    pending: false,
    showClear: true,
};

export default DropdownFilter;
