import dayjs from 'dayjs';
import { Divider } from 'primereact/divider';
import React from 'react';
import { useIntl } from 'react-intl';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

interface Props {
    event: VcuMappedEventDetails;
}
const DetailMecaplanning = ({ event }: Props) => {
    const Intl = useIntl();
    const { mecaplanning } = event;
    return (
        <div className="d-md-flex justify-content-md-between align-items-md-stretch">
            <div className="col-md-3">
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.MECAPLANNING.EVENT',
                    })}
                    value={mecaplanning?.processLabel || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.MECAPLANNING.DATE.EVENT',
                    })}
                    value={dayjs(mecaplanning?.srcProcessMoment).format('DD/MM/YYYY [à] HH [h] mm') || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.MECAPLANNING.ID.DEALERSHIP',
                    })}
                    value={mecaplanning?.refDealershipId || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.MECAPLANNING.NAME.DEALERSHIP',
                    })}
                    value={mecaplanning?.refDealershipLegalname || '-'}
                />
                <Divider className="p-divider-dashed" />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.MECAPLANNING.ID.APPOINTMENT',
                    })}
                    value={mecaplanning?.workfilerdvid || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.MECAPLANNING.MAKE.APPOINTMENT.ONLINE',
                    })}
                    value={mecaplanning?.workfilebookedonline === '0' ? 'Non' : 'Oui' || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.MECAPLANNING.START.DATE',
                    })}
                    value={dayjs(mecaplanning?.workfilestart).format('DD/MM/YYYY [à] HH [h] mm') || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.MECAPLANNING.END.DATE',
                    })}
                    value={dayjs(mecaplanning?.workfilefinish).format('DD/MM/YYYY [à] HH [h] mm') || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.MECAPLANNING.MAIN.INTERVENTION',
                    })}
                    value={mecaplanning?.workfilemainwork || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.MECAPLANNING.COMMENT',
                    })}
                    value={mecaplanning?.workfilecomment || '-'}
                />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.MECAPLANNING.COURTESY.VEHICLE',
                    })}
                    value={mecaplanning?.workfileloaner === '0' ? 'Non' : 'Oui' || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.MECAPLANNING.ID.OF.WORKSHOP',
                    })}
                    value={mecaplanning?.workshopid !== '0' ? mecaplanning?.workshopid : '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.MECAPLANNING.NAME.WORKSHOP',
                    })}
                    value={mecaplanning?.workshopname || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.MECAPLANNING.RECEIVER.ID',
                    })}
                    value={mecaplanning?.receivingid !== '0' ? mecaplanning?.receivingid : '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.MECAPLANNING.RECEIVER.NAME',
                    })}
                    value={mecaplanning?.receivingname || '-'}
                />
            </div>
        </div>
    );
};

export default DetailMecaplanning;
