import React, {useEffect} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import Editor from "../../../partials/content/Editor";
import PictureUpload from "../../../partials/content/PictureUpload";
import {Controller, useFormContext} from "react-hook-form";
import {Intl} from "../../../../_metronic/i18n/I18nProvider";
import SwitchInput from "../../../partials/content/SwitchInput";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../partials/content/Loader";
import {Link, useParams, withRouter} from "react-router-dom";
import toast from "../../../partials/content/Toast";
import ErrorForm from "../../../partials/layout/ErrorForm";
import {
    createCategory,
    fetchCategory,
    removeCategory,
    updateCategory
} from "../../../store/apv/category/category.thunk";
import {routeTo} from "../../../helpers/RoutesHelper";
import ROUTES from "../../../router/Routes";
import Permission from "../../../partials/content/Permission";

const defaultValues = {
    packageCategoryParentId: '',
    name: '',
    active: false,
    seoTitle: '',
    seoUrl: '',
    seoH1: '',
    seoMetaDescription: '',
    seoIntroduction: '',
    seoDescription: ''
}

function Category({ history }) {
    const {reset, register, errors, handleSubmit, control} = useFormContext();
    const params = useParams();
    const dispatch = useDispatch();

    const newCategory = typeof params.id === 'undefined';
    let { category, parents, error, loading } = useSelector((state) => state.category);

    const onSubmit = data => {
        if (newCategory) {
            dispatch(createCategory({
                data,
                history
            }));
        } else {
            dispatch(updateCategory(params.id, data));
        }
    };

    const onError = error => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({id: 'FORM.ERROR.VALIDATE'})
        });
    }

    const deleteCategory = () => {
        dispatch(removeCategory({
            id: params.id,
            history: history
        }));
    }

    useEffect(() => {
        dispatch(fetchCategory(params.id));

        return () => {
            reset(defaultValues);
        }
    }, [dispatch, reset, params.id]);

    useEffect(() => {
        reset({
            packageCategoryParentId: category?.packageCategoryParent?.id || defaultValues.packageCategoryParentId,
            name: category?.name || defaultValues.name,
            active: category?.active || defaultValues.active,
            seoTitle: category?.seoTitle || defaultValues.seoTitle,
            seoUrl: category?.seoUrl || defaultValues.seoUrl,
            seoH1: category?.seoH1 || defaultValues.seoH1,
            seoMetaDescription: category?.seoMetaDescription || defaultValues.seoMetaDescription,
            seoIntroduction: category?.seoIntroduction || defaultValues.seoIntroduction,
            seoDescription: category?.seoDescription || defaultValues.seoDescription
        });
    }, [reset, category]);

    useEffect(() => {
        if (error !== null) {
            toast({
                onShow: () => history.push(routeTo(ROUTES.CATEGORIES.PATH)),
                variant: 'danger',
                message: Intl.formatMessage({id: 'TRANSLATOR.ERROR'})
            });
        }
    }, [history, error]);

    return (
        <Form className="package" onSubmit={handleSubmit(onSubmit, onError)}>
            {loading &&
                <Loader style={{width: '5rem', height: '5rem'}} overlay/>
            }
            <Row className="mb-5">
                <Col lg={6}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-wrench"></i>
                            {Intl.formatMessage({ id: 'APV.CATEGORY.VIEW.PACKAGE' })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Group controlId="packageCategoryParentId">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.CATEGORY.VIEW.FAMILY' })}</Form.Label>
                                <Form.Control name="packageCategoryParentId"
                                              as="select"
                                              ref={register}>
                                    <option value={''}>Aucun parent</option>
                                    { parents &&
                                        parents.map((category, key) => {
                                            return <option key={key} value={category.id}>{category.name}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="name">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.CATEGORY.VIEW.NAME' })}</Form.Label>
                                <Form.Control name="name"
                                              type="text"
                                              className={`${errors.name ? 'is-invalid' : ''}`}
                                              ref={register({
                                                  required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                              })}/>
                                <ErrorForm errors={errors} name="name" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{Intl.formatMessage({ id: 'APV.CATEGORY.VIEW.STATUS' })}</Form.Label>
                                <SwitchInput name="active" active={category?.active || false}/>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-globe-europe"></i>
                            {Intl.formatMessage({ id: 'APV.CATEGORY.VIEW.SEO' })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Group controlId="seoTitle">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.CATEGORY.VIEW.TITLE' })}</Form.Label>
                                <Form.Control name="seoTitle"
                                              type="text"
                                              className={`${errors.seoTitle ? 'is-invalid' : ''}`}
                                              ref={register({
                                                  required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                              })}/>
                                <ErrorForm errors={errors} name="seoTitle" />
                            </Form.Group>
                            <Form.Group controlId="seoUrl">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.CATEGORY.VIEW.URL' })}</Form.Label>
                                <Form.Control name="seoUrl"
                                              type="text"
                                              className={`${errors.seoUrl ? 'is-invalid' : ''}`}
                                              ref={register({
                                                  required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                              })}/>
                                <ErrorForm errors={errors} name="seoUrl" />
                            </Form.Group>
                            <Form.Group controlId="seoH1">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.CATEGORY.VIEW.H1' })}</Form.Label>
                                <Form.Control name="seoH1"
                                              type="text"
                                              className={`${errors.seoH1 ? 'is-invalid' : ''}`}
                                              ref={register({
                                                  required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                              })}/>
                                <ErrorForm errors={errors} name="seoH1" />
                            </Form.Group>
                            <Form.Group controlId="seoMetaDescription">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.CATEGORY.VIEW.META_DESCRIPTION' })}</Form.Label>
                                <Form.Control name="seoMetaDescription"
                                              type="text"
                                              className={`${errors.seoMetaDescription ? 'is-invalid' : ''}`}
                                              ref={register({
                                                  required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                              })}/>
                                <ErrorForm errors={errors} name="seoMetaDescription" />
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-edit"></i>
                            {Intl.formatMessage({ id: 'APV.CATEGORY.VIEW.INTRODUCTION' })}
                        </Card.Header>
                        <Card.Body>
                            <Controller
                                control={control}
                                name="seoIntroduction"
                                rules={{
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                }}
                                render={(props) => (
                                    <Editor
                                        value={props.value}
                                        onChange={props.onChange}
                                    />
                                )}/>
                            <ErrorForm errors={errors} name="seoIntroduction" />
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-edit"></i>
                            {Intl.formatMessage({ id: 'APV.CATEGORY.VIEW.DESCRIPTION' })}
                        </Card.Header>
                        <Card.Body>
                            <Controller
                                control={control}
                                name="seoDescription"
                                rules={{
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                }}
                                render={(props) => (
                                    <Editor
                                        value={props.value}
                                        onChange={props.onChange}
                                    />
                                )}/>
                            <ErrorForm errors={errors} name="seoDescription" />
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon far fa-image"></i>
                            {Intl.formatMessage({ id: 'APV.CATEGORY.VIEW.VISUALS' })}
                        </Card.Header>
                        <Card.Body>
                            <PictureUpload name="pathImg" path={category?.pathImg}/>
                            <ErrorForm errors={errors} name="pathImg" />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Card>
                <Row className="m-6">
                    <Col className={newCategory ? 'text-center' : 'text-right'}>
                        <Link to={routeTo(ROUTES.CATEGORIES.PATH)}>
                            <Button variant="outline-primary">
                                {Intl.formatMessage({ id: 'TRANSLATOR.BACK' })}
                            </Button>
                        </Link>
                        <Permission permissions={['APV_UPDATE_CATEGORY']}>
                            <Button variant="primary"
                                    className="ml-2"
                                    type="submit">
                                {Intl.formatMessage({ id: 'TRANSLATOR.SAVE' })}
                            </Button>
                        </Permission>
                    </Col>
                    {!newCategory &&
                    <Permission permissions={['APV_DELETE_CATEGORY']}>
                        <Col className="text-right">
                            <Button variant="outline-danger" onClick={() => deleteCategory()}>
                                {Intl.formatMessage({ id: 'TRANSLATOR.REMOVE' })}
                            </Button>
                        </Col>
                    </Permission>
                    }
                </Row>
            </Card>
        </Form>
    )
}

export default withRouter(Category);
