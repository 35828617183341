export const actionTypes = {
    FetchSchedules: 'FETCH_SCHEDULES',
    FetchedSchedules: 'FETCHED_SCHEDULES',
    FetchSchedulesFailed: 'FETCH_SCHEDULES_FAILED',
    FetchAvailabilities: 'FETCH_AVAILABILITIES',
    FetchedAvailabilities: 'FETCHED_AVAILABILITIES',
    FetchAvailabilitiesFailed: 'FETCH_AVAILABILITIES_FAILED',
    FetchAppointments: 'FETCH_APPOINTMENT',
    FetchedAppointments: 'FETCHED_APPOINTMENT',
    FetchAppointmentsFailed: 'FETCH_APPOINTMENT_FAILED',
    AddAppointment: 'ADD_APPOINTMENT',
    AddedAppointment: 'ADDED_APPOINTMENT',
    AddAppointmentFailed: 'ADD_APPOINTMENT_FAILED',
    UpdateAppointment: 'UPDATE_APPOINTMENT',
    UpdatedAppointment: 'UPDATED_APPOINTMENT',
    UpdateAppointmentFailed: 'UPDATE_APPOINTMENT_FAILED',
};

const initialState = {
    availabilities: [],
    appointments: [],
    microsoftConnection: null,
    error: null,
    loading: false,
};

export function scheduleReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FetchSchedules:
        case actionTypes.FetchAvailabilities:
        case actionTypes.FetchAppointments:
        case actionTypes.AddAppointment:
        case actionTypes.UpdateAppointment: {
            return {
                ...state,
                error: null,
                loading: true,
            };
        }
        case actionTypes.FetchedSchedules: {
            return {
                ...state,
                loading: false,
                microsoftConnection: action.payload.microsoftConnection,
                availabilities: action.payload.availabilities,
                appointments: action.payload.appointments,
            };
        }
        case actionTypes.FetchedAvailabilities: {
            return {
                ...state,
                loading: false,
                availabilities: action.payload,
            };
        }
        case actionTypes.FetchedAppointments: {
            return {
                ...state,
                loading: false,
                microsoftConnection: action.payload.microsoftConnection,
                appointments: action.payload.appointments,
            };
        }
        case actionTypes.AddedAppointment: {
            return {
                ...state,
                loading: false,
                appointments: [...state.appointments, action.payload],
            };
        }
        case actionTypes.UpdatedAppointment: {
            state.appointments = state.appointments.filter((appointment) => appointment.id !== action.payload.id);
            return {
                ...state,
                loading: false,
                appointments: [...state.appointments, action.payload],
            };
        }
        case actionTypes.FetchSchedulesFailed: {
            return {
                ...state,
                appointments: [],
                availabilities: [],
                loading: false,
                microsoftConnection: null,
                error: action.payload,
            };
        }
        case actionTypes.FetchAvailabilitiesFailed: {
            return {
                ...state,
                availabilities: [],
                loading: false,
                error: action.payload,
            };
        }
        case actionTypes.FetchAppointmentsFailed: {
            return {
                ...state,
                appointments: [],
                loading: false,
                microsoftConnection: null,
                error: action.payload,
            };
        }
        case actionTypes.AddAppointmentFailed:
        case actionTypes.UpdateAppointmentFailed: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        default:
            return state;
    }
}

export const actions = {
    fetchSchedules: () => ({ type: actionTypes.FetchSchedules }),
    fetchedSchedules: (schedules) => ({ type: actionTypes.FetchedSchedules, payload: schedules }),
    fetchSchedulesFailed: (error) => ({ type: actionTypes.FetchSchedulesFailed, payload: error }),
    fetchAvailabilities: () => ({ type: actionTypes.FetchAvailabilities }),
    fetchedAvailabilities: (availabilities) => ({ type: actionTypes.FetchedAvailabilities, payload: availabilities }),
    fetchAvailabilitiesFailed: (error) => ({ type: actionTypes.FetchAvailabilitiesFailed, payload: error }),
    fetchAppointments: () => ({ type: actionTypes.FetchAppointments }),
    fetchedAppointments: (appointments) => ({ type: actionTypes.FetchedAppointments, payload: appointments }),
    fetchAppointmentsFailed: (error) => ({ type: actionTypes.FetchAppointmentsFailed, payload: error }),
    addAppointment: () => ({ type: actionTypes.AddAppointment }),
    addedAppointment: (appointment) => ({ type: actionTypes.AddedAppointment, payload: appointment }),
    addAppointmentFailed: (error) => ({ type: actionTypes.AddAppointmentFailed, payload: error }),
    updateAppointment: () => ({ type: actionTypes.UpdateAppointment }),
    updatedAppointment: (appointment) => ({ type: actionTypes.UpdatedAppointment, payload: appointment }),
    updateAppointmentFailed: (error) => ({ type: actionTypes.UpdateAppointmentFailed, payload: error }),
};
