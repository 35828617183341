import dayjs from 'dayjs';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { stockVehicleChartLegend } from '../StockVehicleChartLegend';
import { externalTooltipHandler } from '../StockVehicleChartTooltip';

let nbLeadOnline;
let nbLeadOnlineAfr;
let nbLeadLocal;
let nbViews;
let maxYLeft;
let maxYRight;

const dataSetsHandler = (data, dateRange) => {
    const defaultBarData = {
        views: {
            type: 'bar',
            label: Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.VEHICLE' }),
            backgroundColor: '#F24A64',
            data: [],
            borderWidth: 1,
            barPercentage: 0.5,
            barThickness: 15,
            maxBarThickness: 10,
            minBarLength: 5,
            datalabels: {
                display: false,
            },
        },
        leadsOnline: {
            type: 'bar',
            label: Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.ONLINE' }),
            backgroundColor: '#80D8FF',
            data: [],
            borderWidth: 1,
            barPercentage: 0.5,
            barThickness: 15,
            maxBarThickness: 10,
            minBarLength: 5,
            datalabels: {
                display: false,
            },
        },
        leadsLocal: {
            type: 'bar',
            label: Intl.formatMessage(
                { id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.LEADS_TITLE' },
                { content: Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.LOCALS' }) },
            ),
            backgroundColor: '#000EE3',
            data: [],
            borderWidth: 1,
            barPercentage: 0.5,
            barThickness: 15,
            maxBarThickness: 10,
            minBarLength: 5,
            datalabels: {
                display: false,
            },
        },
    };

    if (data.counters.length > 0) {
        data.counters.forEach((item) => {
            if (item?.views) {
                nbViews += item.views;
                defaultBarData.views.data = [...defaultBarData.views.data, { date: item.date, value: item.views }];
                if (item.views > maxYLeft) {
                    maxYLeft = item.views;
                }
            }
            if (item?.leads_online) {
                nbLeadOnline += item.leads_online;
                defaultBarData.leadsOnline.data = [
                    ...defaultBarData.leadsOnline.data,
                    { date: item.date, value: item.leads_online },
                ];
                if (item.leads_online > maxYLeft) {
                    maxYLeft = item.leads_online;
                }
            }
            if (item?.leads_local) {
                nbLeadLocal += item.leads_local;
                defaultBarData.leadsLocal.data = [
                    ...defaultBarData.leadsLocal.data,
                    { date: item.date, value: item.leads_local },
                ];
                if (item.leads_local > maxYLeft) {
                    maxYLeft = item.leads_local;
                }
            }
        });
    }

    let datasets = Object.values(defaultBarData).filter((bar) => bar.data.length > 0);

    let priceHistory = data?.priceHistory;

    if (priceHistory && priceHistory.length > 0) {
        const datesRange = dateRange.split(',');
        maxYRight = Math.max(...priceHistory.map((o) => o.value));
        // add fake first value to set continue line on start graph
        priceHistory.unshift({
            date: dayjs(datesRange[0]).subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
            value: priceHistory[0].value,
        });
        // add fake last value to set continue line on end graph
        priceHistory = [
            ...priceHistory,
            {
                date: dayjs(datesRange[1]).add(1, 'month').endOf('month').format('YYYY-MM-DD'),
                value: priceHistory.slice(-1)[0].value,
            },
        ];
        datasets = [
            ...datasets,
            {
                type: 'line',
                fill: true,
                label: Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.PRICE' }),
                data: priceHistory,
                backgroundColor: '#E1F0FF',
                borderColor: '#E1F0FF',
                pointBackgroundColor: '#15B7FF',
                pointRadius: 5,
                steppedLine: true,
                lineTension: 0,
                borderWidth: 2,
                datalabels: {
                    formatter: (value, context) => {
                        // transform to number and format with space ex: 30 000
                        const number = +context.dataset.data[context.dataIndex].value;
                        return `${number.toLocaleString()} €`;
                    },
                    color: '#868AA8',
                    font: {
                        size: 14,
                    },
                    align: 'end',
                    display: 'auto',
                },
                yAxisID: 'yAxisLine',
            },
        ];
    }

    return datasets;
};

const optionsHandler = (dateRange, view, chartType) => ({
    parsing: {
        xAxisKey: 'date',
        yAxisKey: 'value',
    },
    scales: {
        y: {
            max: Math.ceil(maxYLeft + maxYLeft * 0.3),
            position: 'left',
            grid: {
                display: false,
            },
            ticks: {
                font: {
                    size: 14,
                },
                color: '#868AA8',
                stepSize: 1,
            },
        },
        x: {
            beginAtZero: true,
            min: dateRange.split(',')[0],
            max: dateRange.split(',')[1],
            type: 'time',
            time: {
                unit: view === 'month' ? 'week' : 'day',
                tooltipFormat: 'YYYY-MM-DD',
                displayFormats: {
                    week: 'Do MMMM',
                    day: 'ddd DD MMM',
                },
            },
            grid: {
                display: false,
            },
            ticks: {
                font: {
                    size: 14,
                },
                color: '#868AA8',
            },
        },
        yAxisLine: {
            max: Math.ceil((maxYRight + maxYRight * 0.3) / 10000) * 10000,
            beginAtZero: true,
            position: 'right',
            grid: {
                display: false,
            },
            ticks: {
                callback: (value) => {
                    // transform to number and format with space ex: 30 000
                    const number = +value;
                    return `${number.toLocaleString()} ${number > 0 ? ' €' : ''}`;
                },
                font: {
                    size: 14,
                },
                color: '#868AA8',
            },
        },
    },
    interaction: {
        mode: 'x',
        intersect: false,
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false,
            position: 'nearest',
            external: (context) => {
                externalTooltipHandler(context, chartType, view);
            },
        },
    },
});

// eslint-disable-next-line import/prefer-default-export
export const dataHandler = (data, chartType, view, dateRange) => {
    nbLeadOnline = 0;
    nbLeadOnlineAfr = 0;
    nbLeadLocal = 0;
    nbViews = 0;
    maxYLeft = 0;
    maxYRight = 0;

    return {
        datasets: dataSetsHandler(data, dateRange),
        options: optionsHandler(dateRange, view, chartType),
        plugins: stockVehicleChartLegend(chartType, nbLeadOnline, nbLeadOnlineAfr, nbLeadLocal, nbViews),
    };
};
