import { AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { removeDuplicate } from '@app/helpers/ArrayHelper';
import { CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { getBrands, getModels } from '@app/crud/customers/stock.crud';
import { Brand, Model } from '@app/crud/customers/stock.type';

import variantTypes from '@app/constants';
import { useFetch } from '@app/hooks';

import toast from '@app/partials/content/Toast';

const useFilteredBrandsAndModels = () => {
    const Intl = useIntl();
    const [filteredBrands, setFilteredBrands] = useState<Brand[]>([]);
    const [filteredModels, setFilteredModels] = useState<Model[]>([]);

    const { fetch: fetchBrands, data: allBrands } = useFetch<void, Brand[]>({
        fetchAction: getBrands,
        resultInterceptor: (response: CustomAxiosResponse<Brand[]>) =>
            (response ? removeDuplicate(response, 'MakeCode').slice(0, -1) : []) as Brand[],
    });

    const { fetch: fetchModels, data: models } = useFetch<{ makeCode: string }, Model[]>({
        fetchAction: getModels,
        resultInterceptor: (response: CustomAxiosResponse<Model[]>) => {
            const result = response ? (removeDuplicate(response, 'Name').slice(0, -1) as Model[]) : [];
            result.sort((a, b) => a?.Name?.localeCompare(b?.Name));
            return result;
        },
    });

    useEffect(() => {
        if (models) {
            setFilteredModels(models);
        }
    }, [models]);

    const onError = () => {
        toast({
            variant: variantTypes.DANGER,
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const loadModels = useCallback(
        (formBrandValue: string) => {
            if (formBrandValue) {
                const brand = allBrands.find(
                    (brandToSearch) => brandToSearch.Name.toLowerCase() === formBrandValue.toLowerCase(),
                );
                if (brand) {
                    fetchModels({ makeCode: brand.MakeCode }).catch(onError);
                }
            }
        },
        [allBrands, fetchModels],
    );

    const filterBrands = (event: AutoCompleteCompleteEvent) => {
        const query = event?.query || '';
        if (query.trim().length) {
            setFilteredBrands(allBrands.filter((brand) => brand.Name.toLowerCase().startsWith(query.toLowerCase())));
        } else {
            setFilteredBrands(allBrands);
        }
    };

    const filterModels = (event: AutoCompleteCompleteEvent) => {
        const query = event?.query?.toLowerCase() || '';
        if (models && models.length > 0) {
            const filtered = models.filter((model) => model.Name.toLowerCase().startsWith(query));
            setFilteredModels(filtered);
        }
    };

    useEffect(() => {
        fetchBrands().catch(onError);
    }, [fetchBrands]);

    return {
        filteredBrands,
        filteredModels,
        filterBrands,
        filterModels,
        loadModels,
    };
};

export default useFilteredBrandsAndModels;
