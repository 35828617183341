import classNames from 'classnames';
import { motion, useAnimation } from 'framer-motion';
import { Divider } from 'primereact/divider';
import { InputMask } from 'primereact/inputmask';
import { Slider } from 'primereact/slider';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { decimalToTime, timeToDecimal } from '@app/helpers/DateHelper';

import Loader from '@app/partials/content/Loader';

const AM_MIN_RANGE = 0;
const AM_MAX_RANGE = 16;
const PM_MIN_RANGE = 12;
const PM_MAX_RANGE = 24;
const STEP = 2;

const variants = {
    show: {
        height: 'auto',
        overflow: 'hidden',
        transition: {
            ease: 'easeOut',
        },
    },
    hide: {
        height: 0,
        overflow: 'hidden',
        transition: {
            ease: 'easeOut',
        },
    },
};

const DayUpdate = ({ className, selectedBox, onClose, onUpdate, displayLoading }) => {
    const arrowRef = useRef();
    const dayUpdateRef = useRef();
    const hourInputStartRef = useRef();
    const hourInputEndRef = useRef();
    const [range, setRange] = useState([9 * STEP, 12 * STEP]);
    const [date, setDate] = useState([9, 12]);
    const [minRange, setMinRange] = useState(0);
    const [maxRange, setMaxRange] = useState(0);
    const [absent, setAbsent] = useState(false);
    const [loading, setLoading] = useState(false);
    const controls = useAnimation();

    // Show / Hide day update
    useEffect(() => {
        if (selectedBox?.target && arrowRef?.current) {
            let position = selectedBox.target.offsetLeft + selectedBox.target.offsetWidth / 2;
            position -= arrowRef.current.offsetWidth;
            arrowRef.current.style.left = `${position}px`;
            controls.start('show');
        } else {
            controls.start('hide');
        }
    }, [selectedBox, controls]);

    // Update min/max range in function of selectedBox
    useEffect(() => {
        if (selectedBox?.hour) {
            if (selectedBox.hour === 'am') {
                setMinRange(AM_MIN_RANGE * STEP);
                setMaxRange(AM_MAX_RANGE * STEP);
            } else {
                setMinRange(PM_MIN_RANGE * STEP);
                setMaxRange(PM_MAX_RANGE * STEP);
            }
        }
    }, [setRange, selectedBox]);

    // Update range in function of minRange and maxRange
    useEffect(() => {
        if (selectedBox?.hour) {
            if (selectedBox.hour === 'am') {
                if (selectedBox?.am && selectedBox.am.start && selectedBox.am.end) {
                    setRange([timeToDecimal(selectedBox.am.start) * STEP, timeToDecimal(selectedBox.am.end) * STEP]);
                } else {
                    setRange([9 * STEP, 12 * STEP]);
                }
                setAbsent(selectedBox?.am?.absent || false);
            } else {
                if (selectedBox?.pm && selectedBox.pm.start && selectedBox.pm.end) {
                    setRange([timeToDecimal(selectedBox.pm.start) * STEP, timeToDecimal(selectedBox.pm.end) * STEP]);
                } else {
                    setRange([14 * STEP, 19 * STEP]);
                }
                setAbsent(selectedBox?.pm?.absent || false);
            }
        }
    }, [setRange, selectedBox, minRange, maxRange]);

    // Update date on range update
    useEffect(() => {
        setDate([range[0] / STEP, range[1] / STEP]);
    }, [setDate, range]);

    // Update date
    useEffect(() => {
        if (dayUpdateRef?.current) {
            const sliderStart = dayUpdateRef.current.querySelector('.p-slider-handle-start');
            const sliderEnd = dayUpdateRef.current.querySelector('.p-slider-handle-end');

            sliderStart.setAttribute('data-title', decimalToTime(date[0]));
            sliderEnd.setAttribute('data-title', decimalToTime(date[1]));
        }
    }, [date]);

    const handleUpdate = () => {
        if (onUpdate) {
            if (displayLoading) {
                setLoading(true);
            }

            onUpdate(selectedBox.day, decimalToTime(date[0]), decimalToTime(date[1]), absent, selectedBox.hour)
                .then(() => {
                    setLoading(false);
                    onClose();
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <motion.div variants={variants} initial="hide" animate={controls} layout>
            <div ref={dayUpdateRef} className={classNames('day-update', className)}>
                {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                <div ref={arrowRef} className="day-update__arrow" />
                <div className="day-update__params">
                    <Form.Group className="m-0 mr-5" controlId="absent">
                        <Form.Check
                            type="checkbox"
                            checked={absent}
                            onChange={() => setAbsent(!absent)}
                            label={Intl.formatMessage({ id: 'USER.PROFILE.PLANNING.ABSENT' })}
                        />
                    </Form.Group>
                    <InputGroup className="day-update__input">
                        <InputMask
                            mask="99:99"
                            slotChar="00:00"
                            inputRef={hourInputStartRef}
                            className="form-control"
                            value={decimalToTime(range[0] / STEP)}
                            onChange={(e) => {
                                const newValue = timeToDecimal(e.value);
                                const min = selectedBox.hour === 'am' ? AM_MIN_RANGE : PM_MIN_RANGE;
                                const max = range[1] / STEP;

                                if (newValue >= min && newValue <= max) {
                                    hourInputStartRef?.current?.classList?.remove('p-inputmask-danger');
                                    setRange([newValue * STEP, range[1]]);
                                } else {
                                    hourInputStartRef?.current?.classList?.add('p-inputmask-danger');
                                }
                            }}
                        />
                        <InputGroup.Append>
                            <InputGroup.Text>
                                <i className="las la-calendar text-primary" />
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                    <InputGroup className="day-update__input ml-5">
                        <InputMask
                            mask="99:99"
                            slotChar="00:00"
                            inputRef={hourInputEndRef}
                            className="form-control"
                            value={decimalToTime(range[1] / STEP)}
                            onChange={(e) => {
                                const newValue = timeToDecimal(e.value);
                                const min = range[0] / STEP;
                                const max = selectedBox.hour === 'am' ? AM_MAX_RANGE : PM_MAX_RANGE;

                                if (newValue >= min && newValue <= max) {
                                    hourInputEndRef?.current?.classList?.remove('p-inputmask-danger');
                                    setRange([range[0], newValue * STEP]);
                                } else {
                                    hourInputEndRef?.current?.classList?.add('p-inputmask-danger');
                                }
                            }}
                        />
                        <InputGroup.Append>
                            <InputGroup.Text>
                                <i className="las la-calendar text-primary" />
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                    <Slider
                        value={range}
                        className="ml-5 day-update__slider"
                        min={minRange}
                        max={maxRange}
                        onChange={(e) => {
                            setRange(e.value);
                        }}
                        range
                    />
                </div>
                <Divider className="my-7" />
                <div className="d-flex justify-content-end">
                    <Button variant="light-gray mr-5" onClick={() => onClose()}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" onClick={handleUpdate}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                    </Button>
                </div>
            </div>
        </motion.div>
    );
};

DayUpdate.defaultProps = {
    displayLoading: false,
};

export default DayUpdate;
