import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useFetch from '@app/hooks/useFetch';

import { getCategories } from '@app/crud/autouser/application.crud';
import { Permission as PermissionType } from '@app/crud/autouser/permission.crud';

import { createPermission, updatePermission } from '@app/store/autouser/permission/permission.thunk';
import { RootState } from '@app/store/rootDuck';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

const defaultValues = {
    category: '',
    title: '',
    description: '',
    codename: '',
};

type PermissionParams = {
    id: string;
};

type PermissionModalProps = {
    refresh: unknown;
    showModal: boolean;
    setShowModal: (value: boolean) => void;
};

// we shouldn't need that code anymore with react-bootstrap@^1.0.0
type RBRef = string & ((ref: Element | null) => void);

const PermissionModal = ({ refresh, showModal, setShowModal }: PermissionModalProps) => {
    const Intl = useIntl();
    const { params } = useSelector((state: RootState) => state.modal);
    const { id } = useParams<PermissionParams>();

    let newPermission: boolean;

    if (params) newPermission = false;
    else newPermission = true;

    const dispatch = useDispatch();

    const [category, setCategory] = useState('');
    const { register, errors, handleSubmit, setValue, control, reset } = useForm();

    const { fetch: fetchCategories, data: categories } = useFetch({
        // @ts-ignore
        fetchAction: getCategories,
    });

    const onCategoryChange = (changedCategory: string) => {
        setCategory(changedCategory);
        setValue('category', changedCategory);
    };

    const onCodenameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = e.target.value
            .replace(/[^A-Za-z_0-9 ]/g, '')
            .replace(/\s/g, '_')
            .toUpperCase();
    };

    useEffect(() => {
        if (id && showModal) {
            fetchCategories(id);
        }
    }, [fetchCategories, id, showModal]);

    useEffect(() => {
        setCategory(params?.permission?.category);
        setValue('category', category);
    }, [params]);

    useEffect(() => {
        setValue('category', category);
    }, [categories, category]);

    const onSubmit = async (data: PermissionType) => {
        if (newPermission) {
            dispatch(createPermission(id, data, refresh, setShowModal));
        } else {
            dispatch(updatePermission(id, params.permission.id, data, refresh, setShowModal));
        }

        return () => {
            reset(defaultValues);
        };
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-user-check" />}
            title={
                newPermission
                    ? Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.MODAL.ADD.TITLE' })
                    : Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.MODAL.EDIT.TITLE' })
            }
            body={
                <div>
                    <Form.Group>
                        <Form.Label>
                            {Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.MODAL.ADD.FORM.CATEGORY' })}
                        </Form.Label>
                        <Controller
                            control={control}
                            name="category"
                            rules={{
                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            }}
                            render={(field) => (
                                <Dropdown
                                    value={category}
                                    editable
                                    // @ts-ignore
                                    options={categories}
                                    onChange={(e) => {
                                        field.onChange(e.value);
                                        onCategoryChange(e.value);
                                    }}
                                    placeholder={Intl.formatMessage({
                                        id: 'AUTOUSER.APPLICATION.MODAL.ADD.FORM.CATEGORY.PLACEHOLDER',
                                    })}
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            {Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.MODAL.ADD.FORM.TITLE' })}
                        </Form.Label>
                        <Form.Control
                            name="title"
                            ref={
                                register({
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }) as RBRef
                            }
                            defaultValue={params?.permission?.title}
                        />
                        <ErrorForm errors={errors} name="title" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            {Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.MODAL.ADD.FORM.DESCRIPTION' })}
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            name="description"
                            ref={register}
                            defaultValue={params?.permission?.permission}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            {Intl.formatMessage({ id: 'AUTOUSER.APPLICATION.MODAL.ADD.FORM.CODE' })}
                        </Form.Label>
                        <Form.Control
                            name="codename"
                            ref={
                                register({
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    minLength: 3,
                                }) as RBRef
                            }
                            onChange={onCodenameChange}
                            defaultValue={params?.permission?.code}
                            readOnly={!!params?.permission?.code}
                        />
                        <ErrorForm errors={errors} name="codename" />
                    </Form.Group>
                </div>
            }
            footer={
                <div className="d-flex justify-content-center">
                    <Button variant="outline-primary" onClick={() => setShowModal(false)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" className="ml-2" onClick={handleSubmit(onSubmit, onError)}>
                        {params?.permission
                            ? Intl.formatMessage({ id: 'TRANSLATOR.SAVE' })
                            : Intl.formatMessage({ id: 'TRANSLATOR.ADD' })}
                    </Button>
                </div>
            }
        />
    );
};

export default PermissionModal;
