import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl, FormattedMessage } from 'react-intl';

import DealershipVendors from '@app/pages/customers/Partials/DealershipVendors';

import ModalDefault from '@app/partials/content/modals/Modal.default';

interface Teammate {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
}

interface MobileDealershipVendorModalProps {
    show?: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    teammate: Teammate;
    setTeammate: Dispatch<SetStateAction<Teammate>>;
    setTeammates: Dispatch<SetStateAction<Teammate[]>>;
    dealership: string;
}
const MobileDealershipVendorModal = ({
    show,
    setShow,
    teammate,
    setTeammate,
    setTeammates,
    dealership,
}: MobileDealershipVendorModalProps) => {
    const Intl = useIntl();

    const handleClose = () => {
        setShow(false);
    };

    return (
        <ModalDefault
            show={show}
            hideModal={handleClose}
            icon={<i className="las la-2x text-primary la-user" />}
            title={Intl.formatMessage({ id: 'AGENDA.MODAL.MOBILE.DEALERSHIP.TITLE' })}
            subtitle={Intl.formatMessage({ id: 'AGENDA.MODAL.MOBILE.DEALERSHIP.SUBTITLE' })}
            body={
                <div>
                    {/* @ts-ignore TODO type dealership vendors */}
                    <DealershipVendors
                        referent={teammate}
                        setReferent={setTeammate}
                        setReferents={setTeammates}
                        dealership={dealership}
                    />
                </div>
            }
            footer={
                <div className="w-100">
                    <Button variant="primary" className="w-100" onClick={handleClose}>
                        <FormattedMessage id="AGENDA.MODAL.MOBILE.DEALERSHIP.FOOTER.BUTTON" />
                    </Button>
                </div>
            }
        />
    );
};

MobileDealershipVendorModal.defaultProps = {
    show: false,
};

export default MobileDealershipVendorModal;
