import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { CreateLeadHomeChargePayload } from '@app/pages/customers/Modals/LeadHomeCharge/LeadHomeCharge.type';

import { HomeChargeLead } from '../customers/lead.type';

const { REACT_APP_LEAD_URL } = process.env;

export function mobilizeAuthenticate() {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/home-charge/profile`);
}

export function createHomeChargeLead(contactId: number, dealershipId: string, data: CreateLeadHomeChargePayload) {
    return ApiGateway.post<ApiResponse<HomeChargeLead>, CustomAxiosResponse<ApiResponse<HomeChargeLead>>>(
        `${REACT_APP_LEAD_URL}/home-charge/create/${contactId}/${dealershipId}`,
        { ...data },
    );
}
