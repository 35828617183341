import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '@app/hooks';

import ModalDefault from '@app/partials/content/modals/Modal.default';

import { Users } from '../users';

type AddUserToDealershipProps = {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
};

const AddUserToDealership = ({ showModal, setShowModal }: AddUserToDealershipProps) => {
    const { params } = useAppSelector((state) => state.modal);

    return (
        <ModalDefault
            show={showModal}
            size="xl"
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-user" />}
            title={<FormattedMessage id="AUTOUSER.MODAL.DEALERSHIPS.TITLE" />}
            // @ts-ignore
            body={<Users addToDealership={params?.dealership || true} />}
        />
    );
};

export default AddUserToDealership;
