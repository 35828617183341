import stockVehiclesTable from "./vehicles/table.json";
import stockVehiclesFilters from "./vehicles/filters.json"
import stockVehiclesModal from "./vehicles/modal.json"
import stockIndicator from "./indicator.json"
import stockVehicle from "./vehicle.json"
import stockAction from "./action.json"
import stockPricing from "./pricing.json"

const frStock = {
    ...stockVehiclesTable,
    ...stockVehiclesFilters,
    ...stockVehiclesModal,
    ...stockIndicator,
    ...stockVehicle,
    ...stockAction,
    ...stockPricing,
}

export default frStock;
