import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Customer } from '@app/crud/customers/customer.type';

import ErrorForm from '@app/partials/layout/ErrorForm';

interface VehicleKnowSelectProps {
    customer: Customer;
    required?: boolean;
}
const VehicleKnowSelect = ({ customer, required = false }: VehicleKnowSelectProps) => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { control } = methods;

    const selectVehicleKnowOptions = () => {
        const options = Array.isArray(customer.vehicleKnow) ? [...customer.vehicleKnow] : [];

        if (!options.some((option) => !option.brand)) {
            options.push({
                bodyType: '',
                brand: '',
                circulationDate: '',
                color: '',
                energy: '',
                lastActivityBetweenThem: '',
                lastMileageKnown: '',
                lastProductType: '',
                model: '',
                registrationNumber: '',
                registrationNumberDate: '',
                sellingPrice: '',
                vehicleUuid: '',
                vin: '',
                version: '',
            });
        }

        return options;
    };

    return (
        <Form.Group>
            <Form.Label>
                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SELECT_VEHICLE" />
            </Form.Label>
            <Controller
                control={control}
                name="vehicleKnow"
                rules={{
                    required: required && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                }}
                render={({ value, onChange }) => (
                    <Dropdown
                        value={value}
                        required={required}
                        options={selectVehicleKnowOptions()}
                        onChange={(e) => onChange(e.value)}
                        itemTemplate={(item) =>
                            item.vehicleUuid || item.brand ? (
                                `${item.brand} ${item.model} ${
                                    item?.registrationNumber ? `(${item?.registrationNumber})` : ''
                                }`
                            ) : (
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.NEW_VEHICLE" />
                            )
                        }
                        {...(value && {
                            valueTemplate: (item) =>
                                item?.vehicleUuid || item?.brand ? (
                                    `${item.brand} ${item.model} ${
                                        item?.registrationNumber ? `(${item?.registrationNumber})` : ''
                                    }`
                                ) : (
                                    <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.OPTION.NEW_VEHICLE" />
                                ),
                        })}
                        optionLabel="brand"
                        placeholder={Intl.formatMessage({
                            id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.PLACEHOLDER.SELECT_VEHICLE',
                        })}
                    />
                )}
            />
            <ErrorForm errors={methods.errors} name="vehicleKnow" />
        </Form.Group>
    );
};

VehicleKnowSelect.defaultProps = { required: false };

export default VehicleKnowSelect;
