import dayjs from 'dayjs';

/**
 * Get Date Object by string date and format
 *
 * @param date - The date string
 * @param format - The format string
 * @param defaultValue - The default value if the date is invalid
 * @returns {Date|*}
 */
export function getDateByFormat(date: string, format: string, defaultValue: any = ''): Date | typeof defaultValue {
    return dayjs(date, format).isValid() ? dayjs(date, format).toDate() : defaultValue;
}

/**
 * Convert num to time HH:mm
 *
 * @param number
 * @returns {*}
 */
export function decimalToTime(minutes: number) {
    const adjustedMinutes = minutes || 0;

    const sign = adjustedMinutes < 0 ? '-' : '';
    const min = Math.floor(Math.abs(adjustedMinutes));
    const sec = Math.floor((Math.abs(adjustedMinutes) * 60) % 60);
    return `${sign + (min < 10 ? '0' : '') + min}:${sec < 10 ? '0' : ''}${sec}`;
}

/**
 * Time to decimal
 *
 * @param string
 * @returns {number}
 */
export function timeToDecimal(time: string) {
    const hoursMinutes = time.split(/[.:]/);
    const hours = parseInt(hoursMinutes[0], 10);
    const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
}

export const countHowManyDayIn = (day: number, dateStart: dayjs.Dayjs, dateEnd: dayjs.Dayjs) => {
    let numberOfDay = 0;
    let currentDate = dateStart;
    while (currentDate <= dateEnd) {
        if (currentDate.day() === day) {
            numberOfDay += 1;
        }
        currentDate = currentDate.add(1, 'day');
    }
    return numberOfDay;
};

/**
 * ISO8601 string to (yy-mm-dd)
 *
 * @param string
 * @returns {string}
 */
export function convertIsoToDate(date: string): string {
    const d = new Date(date);
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
}
