import { Package } from '@app/pages/apv/orders/Order';

export const displayOrderPackageCode = (item: Package, displayResult = true) => {
    const excludedPackageReference = ['bilan_controle_securite'];

    if (excludedPackageReference.includes(item.slug)) {
        return displayResult ? '' : false;
    }

    return displayResult ? item.code : true;
};
