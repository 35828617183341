import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, withRouter } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getCategories } from '@app/crud/manufacturer/package.crud';

import ROUTES from '@app/router/Routes';
import { fetchPackage, removePackage } from '@app/store/manufacturer/package/package.thunk';

import Editor from '@app/partials/content/Editor';
import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';
import PictureUpload from '@app/partials/content/PictureUpload';
import SwitchInput from '@app/partials/content/SwitchInput';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

import { formSubmit } from './Form/Form.submit';

const defaultValues = {
    packageCategory: -1,
    name: '',
    slug: '',
    active: false,
    seoTitle: '',
    seoUrl: '',
    seoH1: '',
    seoMetaDescription: '',
    seoIntroduction: '',
    seoDescription: '',
    pathImg: '',
    isIndexed: false,
    isMandatory: false,
    isFree: false,
};

function Package({ history }) {
    const intl = useIntl();
    const { reset, register, errors, handleSubmit, control, watch } = useFormContext();
    const params = useParams();
    const dispatch = useDispatch();
    const active = watch('active');

    const newPackage = typeof params.id === 'undefined';
    const { package: _package, error, loading } = useSelector((state) => state.manufacturer.package);
    const [packageCategories, setPackageCategories] = useState([]);

    const onSubmit = (data) => {
        formSubmit({
            newPackage,
            data,
            dispatch,
            history,
            id: params.id,
        });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const deletePackage = () => {
        dispatch(
            removePackage({
                id: params.id,
                history,
            }),
        );
    };

    useEffect(() => {
        dispatch(fetchPackage(params.id));

        return () => {
            reset(defaultValues);
        };
    }, [dispatch, reset, params.id]);

    useEffect(() => {
        getCategories().then((res) => {
            setPackageCategories(res.result);
        });
    }, []);

    const setFormValues = useCallback(() => {
        reset({
            packageCategory: _package?.packageCategory?.slug || defaultValues.packageCategory,
            name: _package?.name || defaultValues.name,
            slug: _package?.slug || defaultValues.slug,
            active: _package?.active || defaultValues.active,
            seoTitle: _package?.seoTitle || defaultValues.seoTitle,
            seoUrl: _package?.seoUrl || defaultValues.seoUrl,
            seoH1: _package?.seoH1 || defaultValues.seoH1,
            seoMetaDescription: _package?.seoMetaDescription || defaultValues.seoMetaDescription,
            seoIntroduction: _package?.seoIntroduction || defaultValues.seoIntroduction,
            seoDescription: _package?.seoDescription || defaultValues.seoDescription,
            fileUrl: _package?.pathImg ? _package.pathImg : defaultValues.pathImg,
            isIndexed: _package?.isIndexed ? _package.isIndexed : defaultValues.isIndexed,
            isMandatory: _package?.isMandatory ? _package.isMandatory : defaultValues.isMandatory,
            isFree: _package?.isFree ? _package.isFree : defaultValues.isFree,
        });
    }, [_package, reset]);

    useEffect(() => {
        setFormValues();
    }, [setFormValues]);

    useEffect(() => {
        if (error !== null) {
            toast({
                onShow: () =>
                    history.push(
                        routeTo(ROUTES.PACKAGES.PATH.TYPE, {
                            type: 'manufacturer',
                        }),
                    ),
                variant: 'danger',
                message: intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    }, [history, error]);

    // TODO Change permissions
    return (
        <Form className="package" onSubmit={handleSubmit(onSubmit, onError)}>
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <Row className="mb-5">
                <Col lg={6}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-wrench" />
                            {intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.PACKAGE' })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Group controlId="packageCategory">
                                <Form.Label>
                                    {intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.FAMILY' })}
                                </Form.Label>
                                <Form.Control
                                    name="packageCategory"
                                    as="select"
                                    className={`${errors.packageCategory ? 'is-invalid' : ''}`}
                                    ref={register({
                                        validate: (value) =>
                                            parseInt(value, 10) === ''
                                                ? intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                                : true,
                                    })}
                                >
                                    <option value={-1} disabled>
                                        {intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.SELECT.FAMILY.DEFAULT' })}
                                    </option>
                                    {packageCategories &&
                                        packageCategories.map((category) => (
                                            <option key={category.slug} value={category.slug}>
                                                {category.name}
                                            </option>
                                        ))}
                                </Form.Control>
                                <ErrorForm errors={errors} name="packageCategory" />
                            </Form.Group>
                            <Form.Group controlId="name">
                                <Form.Label>{intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.NAME' })}</Form.Label>
                                <Form.Control
                                    name="name"
                                    type="text"
                                    className={`${errors.name ? 'is-invalid' : ''}`}
                                    ref={register({
                                        required: intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    })}
                                />
                                <ErrorForm errors={errors} name="name" />
                            </Form.Group>
                            <Form.Group controlId="slug">
                                <Form.Label>{intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.CODE' })}</Form.Label>
                                <Form.Control
                                    name="slug"
                                    type="text"
                                    className={`${errors.name ? 'is-invalid' : ''}`}
                                    ref={register({
                                        required: intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    })}
                                />
                                <ErrorForm errors={errors} name="slug" />
                            </Form.Group>
                            <Row>
                                <Col lg={4}>
                                    <Form.Group>
                                        <Form.Label>
                                            {intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.STATUS' })}
                                        </Form.Label>
                                        <SwitchInput name="active" active={_package?.active || false} />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group>
                                        <Form.Label>
                                            {intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.MANDATORY' })}
                                        </Form.Label>
                                        <SwitchInput name="isMandatory" active={_package?.isMandatory || false} />
                                    </Form.Group>
                                </Col>
                                <Col lg={4}>
                                    <Form.Group>
                                        <Form.Label>
                                            {intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.FREE' })}
                                        </Form.Label>
                                        <SwitchInput name="isFree" active={_package?.isFree || false} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-globe-europe" />
                            {intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.SEO' })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Group controlId="seoTitle">
                                <Form.Label>{intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.TITLE' })}</Form.Label>
                                <Form.Control
                                    name="seoTitle"
                                    type="text"
                                    className={`${errors.seoTitle ? 'is-invalid' : ''}`}
                                    ref={register({
                                        required: active ? intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }) : false,
                                    })}
                                />
                                <ErrorForm errors={errors} name="seoTitle" />
                            </Form.Group>
                            <Form.Group controlId="seoUrl">
                                <Form.Label>{intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.URL' })}</Form.Label>
                                <Form.Control
                                    name="seoUrl"
                                    type="text"
                                    className={`${errors.seoUrl ? 'is-invalid' : ''}`}
                                    ref={register({
                                        required: active ? intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }) : false,
                                    })}
                                />
                                <ErrorForm errors={errors} name="seoUrl" />
                            </Form.Group>
                            <Form.Group controlId="seoH1">
                                <Form.Label>{intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.H1' })}</Form.Label>
                                <Form.Control
                                    name="seoH1"
                                    type="text"
                                    className={`${errors.seoH1 ? 'is-invalid' : ''}`}
                                    ref={register({
                                        required: active ? intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }) : false,
                                    })}
                                />
                                <ErrorForm errors={errors} name="seoH1" />
                            </Form.Group>
                            <Form.Group controlId="seoMetaDescription">
                                <Form.Label>
                                    {intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.META_DESCRIPTION' })}
                                </Form.Label>
                                <Form.Control
                                    name="seoMetaDescription"
                                    type="text"
                                    className={`${errors.seoMetaDescription ? 'is-invalid' : ''}`}
                                    ref={register({
                                        required: active ? intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }) : false,
                                    })}
                                />
                                <ErrorForm errors={errors} name="seoMetaDescription" />
                            </Form.Group>
                            <Row>
                                <Col lg={4}>
                                    <Form.Group>
                                        <Form.Label>
                                            {intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.NOINDEX' })}
                                        </Form.Label>
                                        <SwitchInput
                                            name="isIndexed"
                                            active={_package?.package?.isIndexed || false}
                                            labelFirst={intl.formatMessage({
                                                id: 'MANUFACTURER.PACKAGE.VIEW.NOINDEX.DONT_INDEX',
                                            })}
                                            labelSecond={intl.formatMessage({
                                                id: 'MANUFACTURER.PACKAGE.VIEW.NOINDEX.INDEX',
                                            })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-edit" />
                            {intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.INTRODUCTION' })}
                        </Card.Header>
                        <Card.Body>
                            <Controller
                                control={control}
                                name="seoIntroduction"
                                render={(props) => <Editor value={props.value} onChange={props.onChange} />}
                            />
                            <ErrorForm errors={errors} name="seoIntroduction" />
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-edit" />
                            {intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.DESCRIPTION' })}
                        </Card.Header>
                        <Card.Body>
                            <Controller
                                control={control}
                                name="seoDescription"
                                render={(props) => <Editor value={props.value} onChange={props.onChange} />}
                            />
                            <ErrorForm errors={errors} name="seoDescription" />
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon far fa-image" />
                            {intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.VIEW.VISUALS' })}
                        </Card.Header>
                        <Card.Body>
                            <PictureUpload name="file" path={_package?.pathImg} required={false} />
                            <ErrorForm errors={errors} name="file" />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <Row className="mt-4">
                                <Col className={newPackage ? 'text-center' : 'text-right'}>
                                    <Link to={routeTo(ROUTES.PACKAGES.PATH.TYPE, { type: 'manufacturer' })}>
                                        <Button variant="outline-primary">
                                            {intl.formatMessage({ id: 'TRANSLATOR.BACK' })}
                                        </Button>
                                    </Link>
                                    <Permission permissions={['APV_UPDATE_PACKAGE']}>
                                        <Button variant="primary" className="ml-2" type="submit">
                                            {intl.formatMessage({ id: 'TRANSLATOR.SAVE' })}
                                        </Button>
                                    </Permission>
                                </Col>
                                {!newPackage && (
                                    <Permission permissions={['APV_DELETE_PACKAGE']}>
                                        <Col className="text-right">
                                            <Button variant="outline-danger" onClick={() => deletePackage()}>
                                                {intl.formatMessage({ id: 'TRANSLATOR.REMOVE' })}
                                            </Button>
                                        </Col>
                                    </Permission>
                                )}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Form>
    );
}

export default withRouter(Package);
