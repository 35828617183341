import classNames from 'classnames';
import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import type { ColumnProps } from 'primereact/column';
import React, { useState } from 'react';
import { Button, Card, Dropdown } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import useModal from '@app/hooks/useModal';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { leadActions, ValueOf } from '@app/helpers/LeadHelper';
import { routeTo } from '@app/helpers/RoutesHelper';

import { getAllLeads, rollBackLead } from '@app/crud/customers/customer.crud';

import ROUTES from '@app/router/Routes';
import { Lead } from '@app/store/customers/customers.store';
import { actions as modalActions } from '@app/store/modal/modal.store';

import { CalendarFilter } from '@app/partials/content/CalendarFilter';
import DropdownFilter from '@app/partials/content/DropdownFilter';
import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import Label from '@app/partials/content/Label';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';
import Permission from '@app/partials/content/Permission';

import { AssignLead } from '../../customers/Modals';
import StatusLead from '../../customers/Partials/StatusLead';
import { getLeadFlagsIcon } from '../../leadsOnline/Helpers/Flags';

import { deleteLeads, showRemoveValidation, showUpdateValidation, updateLeadStatus } from './helpers/ConsoleHelper';
import ForceLeadStatus from './modals/ForceLeadStatus';

interface Owner {
    newOwner: {
        id: string;
        firstname: string;
        lastname: string;
        email: string;
    };
    targetDealership: {
        id: string;
    };
    lead: {
        id: number;
    };
    contactId: number;
}

const ConsoleLeads = () => {
    const Intl = useIntl();
    const dispatch = useDispatch();
    const [assignLeadModal, setAssignLeadModal] = useModal(false, 'assign_lead');
    const [updateLeadStatusModal, setUpdateLeadStatusModal] = useModal(false, 'force_lead_status');
    const [selectedLeads, setSelectedLeads] = useState<Lead[]>([]);
    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: 'administration_console_leads',
    });
    const {
        data: leads,
        loading,
        handleFilter,
        handlePagination,
        handleSort,
        refresh,
    } = useTableHandlers({
        filterStore: 'administration_console_leads',
        fetchAction: getAllLeads,
        withStore: false,
        paramsInterceptor: (params, tableHandlerFilters) => {
            setSelectedLeads([]);

            return {
                ...params,
                page: tableHandlerFilters.page,
                limit: tableHandlerFilters.items,
                sort: 'id_DESC',
            };
        },
    });

    const handleRemove = () => {
        showRemoveValidation(async () => {
            await deleteLeads(selectedLeads, rollBackLead);
            refresh();
        });
    };

    const handleChangeStatus = () => {
        showUpdateValidation(async (action: ValueOf<typeof leadActions>) => {
            await updateLeadStatus(selectedLeads, action);
            refresh();
        });
    };

    const handleAssign = (lead: Lead) => {
        dispatch(
            modalActions.modalChange('assign_lead', {
                ...(lead?.ownerId ? { update: true } : {}),
                lead,
                submitInterceptor: async (action: (data: Owner) => void, newOwner: Owner) => {
                    await action({
                        ...newOwner,
                        lead: {
                            id: lead.id,
                        },
                        contactId: lead?.contact?.id,
                    });
                    refresh();
                },
            }),
        );
    };

    const handleUpdateLeadStatus = (lead: Lead) => {
        dispatch(
            modalActions.modalChange('force_lead_status', {
                lead,
            }),
        );
    };

    const inputFilter = (name: string) => <InputFilter name={name} onChange={handleFilter} />;

    const calendarFilter = (name: string) => <CalendarFilter name={name} onChange={handleFilter} />;

    const flagsBodyTemplate = (lead: Lead) =>
        lead?.flags && (
            <div className="d-flex flex-wrap">
                {getLeadFlagsIcon(lead).map((flag, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="mt-2" key={index}>
                        {flag}
                    </div>
                ))}
            </div>
        );

    const selectTypeDropdown = () => {
        const leadTypes = leads?.facets?.leadTypes
            ? leads?.facets?.leadTypes?.map((leadType) => ({
                  name: leadType.label,
                  key: leadType.key,
              }))
            : [];

        return (
            // @ts-ignore
            <DropdownFilter
                optionLabel="name"
                filterBy="name"
                name="leadType"
                options={leadTypes}
                onChange={handleFilter}
            />
        );
    };

    const idBodyTemplate = (lead: Lead) => (
        <div className="d-flex flex-wrap align-items-center">
            <span className="mr-2">{lead?.id}</span>
            {lead?.isDeleted && (
                <OverlayTooltip id={`${lead.id}`} label="CONSOLE_LEADS.TOOLTIP.DELETED">
                    <i className="text-danger las la-xl la-trash-alt" />
                </OverlayTooltip>
            )}
        </div>
    );

    const dealershipBodyTemplate = (lead: Lead) =>
        lead?.dealership.make || lead?.dealership.usualName ? (
            <div className="d-flex flex-column">
                <span>{lead?.dealership?.make || ''}</span>
                <span>{lead?.dealership?.usualName || ''}</span>
            </div>
        ) : (
            '-'
        );

    const registrationBodyTemplate = (lead: Lead) => {
        let registration = '';

        if (lead.leadElements?.[0]?.vehicle?.registration || lead.leadElements?.[0]?.vehicle?.serialNumber) {
            registration = lead.leadElements[0].vehicle?.registration || lead.leadElements[0].vehicle?.serialNumber;
        }

        return registration ? (
            <>
                <span>
                    {lead.leadElements[0].vehicle?.make} {lead.leadElements[0].vehicle?.model}
                </span>
                <br />
                <span>{registration}</span>
                {lead.leadElements[0].vehicle?.operations &&
                    lead?.leadElements[0].vehicle?.operations.map((operation) => (
                        <React.Fragment key={operation?.name}>
                            <br />
                            <Label className="font-weight-bold label-lg label-light-primary">{operation?.name}</Label>
                        </React.Fragment>
                    ))}
                <br />
                {lead.leadElements[0].vehicle?.isSold && <i className="la la-ban la-lg text-danger" />}
            </>
        ) : (
            'Projet Client'
        );
    };

    const customerBodyTemplate = (lead: Lead) => {
        const clientName =
            lead?.contact?.firstname || lead?.contact?.lastname
                ? `${lead?.contact?.firstname || ''} ${lead?.contact?.lastname || ''}`
                : '-';
        return <span>{clientName}</span>;
    };

    const referentBodyTemplate = (lead: Lead) => {
        if (!lead?.ownerFirstname && !lead?.ownerLastname) {
            return '-';
        }

        return <span>{`${lead?.ownerFirstname} ${lead?.ownerLastname}`}</span>;
    };

    const statusBodyTemplate = (lead: Lead) => {
        let date = lead?.lastStatusUpdate;
        if (
            [
                'in_progress_appointment_to_come',
                'in_progress_appointment_confirmed',
                'in_progress_relaunch_to_come',
                'in_progress_appointment_to_confirm',
            ].includes(lead?.leadStatus?.slug)
        ) {
            date = lead?.scheduleAppointment?.date;
        }
        return (
            <StatusLead
                leadStatusUpdated={date}
                statusSlug={lead?.leadStatus?.slug}
                statusName={lead?.leadStatus?.name}
                statusSubName={lead?.leadStatus?.subName}
                divider={false}
                className="d-flex flex-wrap align-items-center"
                table
            />
        );
    };

    const dateReceiptedBodyTemplate = (lead: Lead) =>
        lead?.receipted ? dayjs(lead?.receipted).format('DD/MM/YYYY [à] HH[h]mm') : '-';

    const dateUpdatedBodyTemplate = (lead: Lead) =>
        lead?.updated ? dayjs(lead?.updated).format('DD/MM/YYYY [à] HH[h]mm') : '-';

    const actionsBodyTemplate = (lead: Lead) => {
        if (lead?.isAnonymized) return;

        return (
            <div className="d-flex">
                <Dropdown alignRight>
                    <Dropdown.Toggle size="sm" variant="outline-secondary">
                        {Intl.formatMessage({ id: 'TRANSLATOR.ACTIONS' })}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleAssign(lead)}>
                            {Intl.formatMessage({ id: 'CONSOLE_LEADS.ACTION.ASSIGN' })}
                        </Dropdown.Item>
                        { /* prettier-ignore */ }
                        <Permission permissions={['PARAMETER_CONSOLE_FORCE_LEAD']}>
                            {!['created', 'closed_won', 'closed_lost', 'closed_auto'].includes(
                                lead?.leadStatus?.slug
                            ) && (
                                <Dropdown.Item onClick={() => handleUpdateLeadStatus(lead)}>
                                    {Intl.formatMessage({ id: 'CONSOLE_LEADS.ACTION.STATUS' })}
                                </Dropdown.Item>
                            )}
                        </Permission>
                    </Dropdown.Menu>
                </Dropdown>
                {lead?.contact?.id !== null && (
                    <Link
                        to={routeTo(ROUTES.CUSTOMER.PATH, {
                            id: lead?.contact?.id,
                        })}
                    >
                        <Button className="p-2 ml-3">
                            <i className="las la-eye p-0" />
                        </Button>
                    </Link>
                )}
            </div>
        );
    };

    const typeBodyTemplate = (lead: Lead) => {
        const isOnline = ['Lead VO', 'Lead VD', 'Lead VN'].includes(lead?.leadType?.name);
        return (
            <div className="d-flex flex-column">
                <span>{lead?.leadType?.name ? `${lead?.leadType?.name}${isOnline ? ' Online' : ''}` : '-'}</span>
                {flagsBodyTemplate(lead)}
            </div>
        );
    };

    const cols = [
        {
            selectionMode: 'multiple' as ColumnProps['selectionMode'],
            style: { width: '50px' },
        },
        {
            field: 'id',
            header: <FormattedHTMLMessage id="CONSOLE_LEADS.TABLE.HEAD.ID" />,
            filter: true,
            filterElement: inputFilter('id'),
            body: idBodyTemplate,
        },
        {
            field: 'leadType',
            header: <FormattedHTMLMessage id="LEADS_ONLINE.TABLE.TYPE" />,
            filter: true,
            filterElement: selectTypeDropdown(),
            body: typeBodyTemplate,
        },
        {
            field: 'dealership',
            header: <FormattedHTMLMessage id="CONSOLE_LEADS.TABLE.HEAD.DEALERSHIP" />,
            body: dealershipBodyTemplate,
            filter: true,
            filterElement: inputFilter('dealership'),
        },
        {
            field: 'registration',
            header: <FormattedHTMLMessage id="CONSOLE_LEADS.TABLE.HEAD.REGISTRATION" />,
            body: registrationBodyTemplate,
            filter: true,
            filterElement: inputFilter('vehicleIdentifier'),
        },
        {
            field: 'customer',
            header: <FormattedHTMLMessage id="CONSOLE_LEADS.TABLE.HEAD.CUSTOMER" />,
            body: customerBodyTemplate,
            filter: true,
            filterElement: inputFilter('contact'),
        },
        {
            field: 'user',
            header: <FormattedHTMLMessage id="CONSOLE_LEADS.TABLE.HEAD.USER" />,
            body: referentBodyTemplate,
        },
        {
            field: 'status',
            header: <FormattedHTMLMessage id="CONSOLE_LEADS.TABLE.HEAD.STATUS" />,
            body: statusBodyTemplate,
            style: { width: '20%' },
        },
        {
            field: 'receipted',
            header: <FormattedHTMLMessage id="CONSOLE_LEADS.TABLE.HEAD.RECEIPTED" />,
            body: dateReceiptedBodyTemplate,
            filter: true,
            filterElement: calendarFilter('receipted'),
        },
        {
            field: 'updated',
            header: <FormattedHTMLMessage id="CONSOLE_LEADS.TABLE.HEAD.UPDATED" />,
            body: dateUpdatedBodyTemplate,
        },
        {
            field: 'action',
            header: <FormattedHTMLMessage id="CONSOLE_LEADS.TABLE.HEAD.ACTIONS" />,
            body: actionsBodyTemplate,
            style: { width: '150px' },
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
                <FormattedHTMLMessage id="CONSOLE_LEADS.TITLE" />
                <Dropdown
                    alignRight
                    className={classNames({
                        'dropdown-disabled': selectedLeads?.length === 0,
                    })}
                >
                    <Dropdown.Toggle size="sm" variant="outline-secondary">
                        {Intl.formatMessage({ id: 'TRANSLATOR.ACTIONS' })}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={handleRemove}>
                            {Intl.formatMessage(
                                { id: 'CONSOLE_LEADS.ACTION.REMOVE' },
                                { number: selectedLeads?.length },
                            )}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleChangeStatus}>
                            {Intl.formatMessage(
                                { id: 'CONSOLE_LEADS.ACTION.UPDATE' },
                                { number: selectedLeads?.length },
                            )}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Card.Header>
            <Card.Body>
                <FiltersProvider value={filters}>
                    {/* @ts-ignore */}
                    <HoCDataTable
                        value={leads?.result}
                        totalRecords={leads?.count}
                        selection={selectedLeads}
                        onSelectionChange={(e: { value: Lead[] }) => setSelectedLeads(e.value)}
                        lazy
                        loading={loading}
                        paginator
                        onFilter={handleFilter}
                        onPage={handlePagination}
                        onSort={handleSort}
                        paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                        currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                        rows={items}
                        first={start}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        removableSort
                        filterDisplay="row"
                        rowsPerPageOptions={[25, 50, 100]}
                        emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                    >
                        {dynamicColumns}
                    </HoCDataTable>
                </FiltersProvider>
                <AssignLead showModal={assignLeadModal} setShowModal={setAssignLeadModal} />
                <ForceLeadStatus
                    showModal={updateLeadStatusModal}
                    setShowModal={setUpdateLeadStatusModal}
                    refresh={refresh}
                />
            </Card.Body>
        </Card>
    );
};

export default ConsoleLeads;
