import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { exportCompletionReport, exportStatisticsReport } from '@app/crud/securyweb/securyweb.crud';

import ReportingCard from '@app/pages/securyweb/Partials/ReportingCard';

import Loader from '@app/partials/content/Loader';
import toast from '@app/partials/content/Toast';

const SecuryWebReporting = () => {
    const Intl = useIntl();
    const [isLoading, setLoading] = useState(false);
    const [startDateComplet, setStartDateComplet] = useState(null);
    const [endDateComplet, setEndDateComplet] = useState(null);

    const [startDateStat, setStartDateStat] = useState(null);
    const [endDateStat, setEndDateStat] = useState(null);

    const handleStatExportClick = () => {
        setLoading(true);
        exportStatisticsReport(
            startDateStat && dayjs(startDateStat).format('YYYY-MM-DD'),
            endDateStat && dayjs(endDateStat).format('YYYY-MM-DD'),
        )
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCompletExportClick = () => {
        setLoading(true);
        exportCompletionReport(
            startDateComplet && dayjs(startDateComplet).format('YYYY-MM-DD'),
            endDateComplet && dayjs(endDateComplet).format('YYYY-MM-DD'),
        )
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {isLoading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <ReportingCard
                headerIcon="la text-light la-file-alt"
                headerTitleId="SECURYCAR.REPORTING.COMPLET"
                startDate={startDateComplet}
                setStartDate={setStartDateComplet}
                endDate={endDateComplet}
                setEndDate={setEndDateComplet}
                onExportClick={handleCompletExportClick}
            />
            <ReportingCard
                headerIcon="la text-light la-chart-bar"
                headerTitleId="SECURYCAR.REPORTING.STAT"
                startDate={startDateStat}
                setStartDate={setStartDateStat}
                endDate={endDateStat}
                setEndDate={setEndDateStat}
                onExportClick={handleStatExportClick}
            />
        </>
    );
};
export default SecuryWebReporting;
