import React, {useEffect, useState} from "react";
import {Card, Col, Form, InputGroup, Row} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import {Intl, Locale} from "../../../../_metronic/i18n/I18nProvider";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../partials/content/Loader";
import {useParams, withRouter} from "react-router-dom";
import toast from "../../../partials/content/Toast";
import {fetchDealership, updateDealership} from "../../../store/apv/dealership/dealership.thunk";
import {actions} from "../../../store/apv/dealership/dealership.store";
import SwitchInput from "../../../partials/content/SwitchInput";
import * as dayjs from "dayjs";
import OpeningHours from "./OpeningHours";
import {routeTo} from "../../../helpers/RoutesHelper";
import ROUTES from "../../../router/Routes";
import {DateTimePicker} from "../../../partials/content/DateTimePicker";

const defaultValues = {
    minDays: '',
    nbSlots: '',
    active: false,
    dateCloseStart: '',
    dateCloseEnd: '',
}

function Dealership({ history }) {
    const {reset, register, handleSubmit, control} = useFormContext();
    const params = useParams();
    const dispatch = useDispatch();

    const [firstLoad, setFirstLoad] = useState(true);
    const { dealership, error, loading } = useSelector((state) => state.dealership);

    const onSubmit = data => {
        dispatch(updateDealership(params.id, data));
    };

    useEffect(() => {
        dispatch(fetchDealership(params.id));

        setFirstLoad(false);

        return () => {
            dispatch(actions.clearDealership());
        }
    }, [dispatch, params.id]);

    useEffect(() => {
        reset({
            minDays: dealership?.minDays || defaultValues.minDays,
            nbSlots: dealership?.nbSlots || defaultValues.nbSlots,
            active: dealership?.active || defaultValues.active,
            sendToCall: dealership?.sendToCall || defaultValues.sendToCall,
            dateCloseStart: dealership?.dateCloseStart ? dayjs(dealership.dateCloseStart).format('YYYY-MM-DD') : defaultValues.dateCloseStart,
            dateCloseEnd: dealership?.dateCloseEnd ? dayjs(dealership.dateCloseEnd).format('YYYY-MM-DD') : defaultValues.dateCloseEnd,
        });
    }, [reset, dealership]);

    useEffect(() => {
        if (error !== null && !firstLoad) {
            toast({
                onShow: () => history.push(routeTo(ROUTES.DEALERSHIPS.PATH)),
                variant: 'danger',
                message: Intl.formatMessage({id: 'TRANSLATOR.ERROR'})
            });
        }
    }, [error, history, firstLoad]);

    return (
        <Form className="package" onSubmit={handleSubmit(onSubmit)}>
            {loading &&
                <Loader style={{width: '5rem', height: '5rem'}} overlay/>
            }
            <Row className="mb-4">
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-info-circle"></i>
                            {Intl.formatMessage({ id: 'APV.DEALERSHIP.INFORMATIONS' })}
                        </Card.Header>
                        <Card.Body>
                            {dealership?.dealershipSource?.id} • {dealership?.dealershipSource?.usual_name}
                            <br />
                            {dealership?.dealershipSource?.address}
                            <br />
                            {dealership?.dealershipSource?.zipcode} {dealership?.dealershipSource?.city}
                            <Form.Group className="mt-3">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.DEALERSHIP.STATUS_APV' })}</Form.Label>
                                <SwitchInput name="active"/>
                            </Form.Group>
                            <Form.Group className="mt-3 mb-3">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.DEALERSHIP.DEPLOY_CALL' })}</Form.Label>
                                <SwitchInput name="sendToCall" disabled/>
                            </Form.Group>
                            Téléphone concession : {dealership?.phoneLandLine}
                            <br />
                            Téléphone concession SDA : {dealership?.phone}
                            <Form.Group className="mt-3">
                                <Form.Label>{Intl.formatMessage({ id: 'APV.DEALERSHIP.BROADCAST_ADDRESS' })}</Form.Label>
                                <Form.Control as="textarea"
                                              rows={dealership?.ListingEmailRecipient?.length}
                                              style={{height: '100px'}}
                                              value={
                                                    (dealership?.ListingEmailRecipient &&
                                                        dealership?.ListingEmailRecipient.map((email) => {
                                                            return `${email}\r\n`
                                                        })
                                                    )
                                                }
                                              type="text"
                                              disabled/>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={8}>
                    <Card>
                        <Card.Header>
                            <i className="card__icon fas fa-calendar-alt"/>
                            {Intl.formatMessage({ id: 'APV.DEALERSHIP.AVAILABILITY' })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <Col lg={6}>
                                    <Form.Group className="pr-2">
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.DEALERSHIP.DELAY' })}</Form.Label>
                                        <InputGroup>
                                            <Form.Control type="text" name="minDays" ref={register} />
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>jours</InputGroup.Text>
                                            </InputGroup.Prepend>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="pl-2">
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.DEALERSHIP.SLOTS' })}</Form.Label>
                                        <Form.Control type="text" name="nbSlots" ref={register} />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <OpeningHours daysValues={dealership?.dealershipDays || []}/>
                            <Form.Row className="mt-3">
                                <Col lg={6}>
                                    <Form.Group className="pr-2">
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.DEALERSHIP.DATE_START_CLOSE' })}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="dateCloseStart"
                                            render={(props) => (
                                                <DateTimePicker
                                                    locale={Locale}
                                                    dateFormat="DD/MM/YYYY"
                                                    onChange={(e) => {
                                                        const value = dayjs(e.value).isValid() ? dayjs(e.value).format('YYYY-MM-DD') : null;
                                                        props.onChange(value);
                                                    }}
                                                    value={dayjs(props.value, 'YYYY-MM-DD').toDate()}/>
                                            )}/>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="pl-2">
                                        <Form.Label>{Intl.formatMessage({ id: 'APV.DEALERSHIP.DATE_END_CLOSE' })}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="dateCloseEnd"
                                            render={(props) => (
                                                <DateTimePicker
                                                    locale={Locale}
                                                    dateFormat="DD/MM/YYYY"
                                                    onChange={(e) => {
                                                        const value = dayjs(e.value).isValid() ? dayjs(e.value).format('YYYY-MM-DD') : null;
                                                        props.onChange(value);
                                                    }}
                                                    value={dayjs(props.value, 'YYYY-MM-DD').toDate()}/>
                                            )}/>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Form>
    )
}

export default withRouter(Dealership);
