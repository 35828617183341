import React, { useEffect, useState } from 'react';
import { Button, Card, Row, Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import RadioButtonsFilter from '@app/partials/content/Filters/RadioButtonsFilter';

const StockVehiclesFilterFundingBlock = ({ updatePendingFilters, releasePendingFilters, setIsOpenFiltersBlock }) => {
    const isContextEFB = useSelector((state) => state.auth.userData.context === 'efb');

    const [fundingMethod, setFundingMethod] = useState([]);

    useEffect(() => {
        if (isContextEFB) {
            setFundingMethod(['classic', 'balloon']);
        } else {
            setFundingMethod(['credit', 'loa']);
        }
    }, [isContextEFB]);

    return (
        <Row className="h-100 d-flex flex-column justify-content-between">
            <div>
                <Card>
                    <Card.Header>
                        <i className="card__icon la-2x las la-money-bill text-dark" />
                        <FormattedMessage id="STOCK_VEHICLES.FILTERS.CARD.TITLE.FUNDING" />
                    </Card.Header>
                    <Card.Body className="p-0 pl-20 pr-20">
                        <Table className="table-borderless table-condensed table-hover">
                            <thead>
                                <tr>
                                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                    <th />
                                    <th>
                                        <div className="d-flex justify-content-around">
                                            <span>
                                                <FormattedMessage id="STOCK_VEHICLES.FILTERS.LABEL.BUTTON.RADIO.YES" />
                                            </span>
                                            <span>
                                                <FormattedMessage id="STOCK_VEHICLES.FILTERS.LABEL.BUTTON.RADIO.NO" />
                                            </span>
                                            <span>
                                                <FormattedMessage id="STOCK_VEHICLES.FILTERS.LABEL.BUTTON.RADIO.ALL" />
                                            </span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {fundingMethod.map((method) => (
                                    <tr>
                                        <td>
                                            <FormattedMessage
                                                id={`STOCK_VEHICLES.FILTERS.LABEL.FUNDING.${method.toUpperCase()}`}
                                            />
                                        </td>
                                        <td>
                                            <div>
                                                <RadioButtonsFilter
                                                    name={`is_eligible_${method}`}
                                                    className="d-flex justify-content-around"
                                                    filterName={Intl.formatMessage({
                                                        id: `STOCK_VEHICLES.FILTERS.LABEL.FUNDING.${method.toUpperCase()}`,
                                                    })}
                                                    values={[
                                                        {
                                                            key: 'oui',
                                                            value: true,
                                                        },
                                                        {
                                                            key: 'non',
                                                            value: false,
                                                        },
                                                        {
                                                            key: 'tous',
                                                            value: '',
                                                        },
                                                    ]}
                                                    defaultValue=""
                                                    pending
                                                    onPendingChange={updatePendingFilters}
                                                    displayLabel={false}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
            <div>
                <Button
                    className="btn btn-primary"
                    onClick={() => {
                        releasePendingFilters();
                        setIsOpenFiltersBlock();
                    }}
                    block
                >
                    <FormattedMessage id="TRANSLATOR.VALIDATE" />
                </Button>
            </div>
        </Row>
    );
};

export default StockVehiclesFilterFundingBlock;
