import ApiGateway from '@app/helpers/AxiosHelper';

import store from '@app/store/store';

const { REACT_APP_LEAD_URL } = process.env;

const getUserData = () => store.getState().auth.userData;

export function fetchFluidities(params) {
    const status = params?.leadStatus;
    delete params?.leadStatus;

    return ApiGateway.get(`${REACT_APP_LEAD_URL}/lead-fluidity/status/${status}`, {
        params,
    });
}

export function fetchFluidity(fluidityId) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/lead-fluidity/${fluidityId}`);
}

export function updateFluidity(fluidityId, params) {
    const user = getUserData();

    return ApiGateway.put(`${REACT_APP_LEAD_URL}/lead-fluidity/${fluidityId}`, {
        ...params,
        userId: user.id,
        userFirstname: user.first_name,
        userLastname: user.last_name,
    });
}
