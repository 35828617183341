import dayjs from 'dayjs';
import React, { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

import { Vehicle } from '@app/store/stock/stock.type';

import Label from '@app/partials/content/Label';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';

export default function StockVehiclesVehicleGuaranteeColumn({ vehicle }: { vehicle: Vehicle }) {
    const labelHandler = (value: string | ReactNode, fieldName?: string, icon?: string) => {
        const isWarning =
            vehicle?.type !== 'VN'
                ? vehicle?.fw_eligibility_warning?.filter((warning) => warning === fieldName).length > 0
                : false;

        let label = `STOCK_VEHICLES.TABLE.${fieldName.toUpperCase()}_TOOLTIP`;

        if (isWarning) {
            if (fieldName === 'mileage') label = 'STOCK_VEHICLES.TABLE.MILEAGE_NOT_ELIGIBLE_FULL_WEB_TOOLTIP';
            if (fieldName === 'date_first_circulation')
                label = 'STOCK_VEHICLES.TABLE.DATE_FIRST_CIRCULATION_NOT_ELIGIBLE_FULL_WEB_TOOLTIP';
            if (fieldName === 'warranty_label') label = 'STOCK_VEHICLES.TABLE.WARRANTY_LABEL_TOOLTIP';
        }

        return value ? (
            <OverlayTooltip label={label} id={fieldName}>
                <Label
                    variant={`${isWarning ? 'danger-lite' : 'light'}`}
                    className={`font-weight-bold mt-2 ${isWarning ? '' : 'text-dark'}`}
                >
                    <>
                        <i className={`mr-2 las la-${icon} la-2x ${isWarning ? 'text-danger-lite' : 'text-dark'}`} />
                        {value}
                    </>
                </Label>
            </OverlayTooltip>
        ) : (
            ''
        );
    };

    return (
        <>
            <p>
                {vehicle.brand} {vehicle.model}
            </p>
            <p>{vehicle.version}</p>
            {vehicle?.warranty_label && <>{labelHandler(vehicle.warranty_label, 'warranty_label', 'umbrella')}</>}
            {vehicle.date_first_circulation && vehicle.mileage > 0 && (
                <Row>
                    <Col lg={12}>
                        {labelHandler(
                            dayjs(vehicle.date_first_circulation).format('DD/MM/YYYY'),
                            'date_first_circulation',
                            'history',
                        )}
                        &nbsp;
                        {labelHandler(
                            <NumberFormat
                                value={vehicle.mileage}
                                decimalSeparator="."
                                thousandSeparator=" "
                                suffix=" km"
                                displayType="text"
                            />,
                            'mileage',
                            'tachometer-alt',
                        )}
                    </Col>
                </Row>
            )}
        </>
    );
}
