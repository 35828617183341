import React, { useMemo } from 'react';

import { ApplicationQueryFilter } from '@app/crud/autouser/application.types';
import { UserRoles } from '@app/crud/autouser/role.types';

import { TreeSelect } from '@app/partials/content/Tree';

interface TreeSelectNode {
    checked: boolean;
    label: string;
    value: number;
    _depth: number;
    _focused: boolean;
    _id: string;
}

interface RolesSelectProps {
    handleFilter: (args: ApplicationQueryFilter) => void;
    roles: Array<UserRoles & { checked: boolean }>;
}

const RolesSelect = React.forwardRef(({ roles, handleFilter }: RolesSelectProps, forwardedRef) => {
    const selectRoles = useMemo(
        () =>
            roles.map((role) => ({
                label: role.name,
                value: role.id,
                checked: role.checked,
            })),
        [roles],
    );

    return (
        <TreeSelect
            // @ts-ignore
            data={selectRoles}
            ref={forwardedRef}
            onChange={(_currentNode: TreeSelectNode, selectedNodes: TreeSelectNode[]): void => {
                const selectedRoles = selectedNodes.map((selected: TreeSelectNode) => selected.value);
                handleFilter({
                    roles: {
                        value: selectedRoles,
                    },
                });
            }}
        />
    );
});

export default RolesSelect;
