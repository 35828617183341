import * as dayjs from 'dayjs';
import React from 'react';

export const customerDataFormatter = (customer) => [
    {
        labelId: 'MANUFACTURER.ORDER.VIEW.NAME',
        value: customer?.lastName,
    },
    {
        labelId: 'MANUFACTURER.ORDER.VIEW.FIRSTNAME',
        value: customer?.firstName,
    },
    {
        labelId: 'MANUFACTURER.ORDER.VIEW.MAIL',
        value: customer?.email,
    },
    {
        labelId: 'MANUFACTURER.ORDER.VIEW.MOBILE',
        value: customer?.phone,
    },
    {
        labelId: 'MANUFACTURER.ORDER.VIEW.ADDRESS',
        value: customer?.postalCode,
    },
];

export const appointmentDataFormatter = (order) => [
    {
        labelId: 'MANUFACTURER.ORDER.VIEW.HOUR',
        value: dayjs(order?.appointment?.startDate).format(' [le] DD MMMM[, À  ] HH [h] mm'),
    },
    {
        labelId: 'MANUFACTURER.ORDER.VIEW.DEALERSHIP',
        value: order?.dealership?.usualName,
    },
    {
        labelId: 'MANUFACTURER.ORDER.VIEW.DEALERSHIP_ADDRESS',
        value: (
            <>
                {order?.dealership?.address}
                <br />
                {order?.dealership?.zipCode} {order?.dealership?.city}
            </>
        ),
    },
    {
        labelId: 'MANUFACTURER.ORDER.VIEW.COMMENT',
        value: order?.comment,
    },
    {
        labelId: 'MANUFACTURER.ORDER.VIEW.MECAPLANNING_ID',
        value: order?.appointment?.rdv,
    },
];

export const vehiculeDataFormatter = (order) => [
    {
        labelId: 'MANUFACTURER.ORDER.VIEW.BRAND',
        value: order?.brand,
    },
    {
        labelId: 'MANUFACTURER.ORDER.VIEW.MODEL',
        value: order?.model,
    },
    {
        labelId: 'MANUFACTURER.ORDER.VIEW.REGISTRATION',
        value: order?.registration,
    },
    {
        labelId: 'MANUFACTURER.ORDER.VIEW.MILEAGE',
        value: order?.mileage && `${order?.mileage} km`,
    },
];
