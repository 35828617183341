import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PricingEvent } from '@app/store/stock/stock.type';

import Price from '@app/components/formatters/price/Price';

type StockPricingToDoHistoryPriceProps = {
    price: number;
    event: PricingEvent;
};

const StockPricingToDoHistoryPrice = ({ price, event }: StockPricingToDoHistoryPriceProps) => (
    <div>
        {event?.newPrice ? (
            <>
                <FormattedMessage id="STOCK.PRICING.HISTORY.OLD_PRICE" />
                <Price value={event.oldPrice} className="font-weight-bold" />
            </>
        ) : (
            <>
                <FormattedMessage id="STOCK.PRICING.HISTORY.CURRENT_PRICE" />
                <Price value={price} className="font-weight-bold" />
            </>
        )}
        <br />
        <FormattedMessage id="STOCK.PRICING.HISTORY.RECO_PRICE" />
        <Price value={event.recoPrice} className="font-weight-bold" />
        {event.variationReco > 0 && (
            <>
                &nbsp;({event.variationReco > 0 && '+'}
                {event.variationReco} %)
            </>
        )}
    </div>
);

export default StockPricingToDoHistoryPrice;
