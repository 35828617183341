import { City, getCities } from '@app/crud/city/city.crud';
import { useState, useEffect } from 'react';

export const useCities = (query: string) => {
    const [cities, setCities] = useState<City[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchCities = async () => {
            if (!query || query.length < 2) {
                setCities([]);
                return;
            }

            setLoading(true);
            setError(null);

            try {
                const response = await getCities(query, 'FRA');
                if (Array.isArray(response.result)) {
                    setCities(response.result);
                } else {
                    setCities([]);
                    setError(new Error('Unexpected response format'));
                    console.error('Unexpected response format:', response);
                }
            } catch (err) {
                setError(err instanceof Error ? err : new Error('An error occurred'));
                setCities([]);
            } finally {
                setLoading(false);
            }
        };

        fetchCities();
    }, [query]);

    return { cities, loading, error };
};
