import React from 'react';

import DashboardActivityStock from '@app/pages/dashboard/partials/Activity/DashboardActivityStock';

import Permission from '@app/partials/content/Permission';

import { DashboardActivityManager } from '../../partials/Activity';
import { DashboardAgenda } from '../../partials/Agenda';
import DashboardProgress from '../../partials/DashboardProgress';
import { DashboardPriorities } from '../../partials/Priorities';
import DashboardQlik from '../../partials/Stats/DashboardQlik';

import './_dashboardManager.scss';

const DashboardManager = () => (
    <div className="dashboard-role d-flex flex-column">
        <DashboardProgress className="d-flex align-items-end" />
        <div className="dashboard-actions mt-8 h-100 d-flex">
            <div className="d-flex flex-column">
                <DashboardAgenda picture={false} />
                <DashboardPriorities className="mt-8" picture={false} />
            </div>
            <DashboardActivityManager />
        </div>
        <Permission permissions={['STOCK_LIST_VEHICLES']}>
            <DashboardActivityStock />
        </Permission>
        <DashboardQlik className="w-100 mt-8" />
    </div>
);

export default DashboardManager;
