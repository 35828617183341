import ApiGateway, { CustomAxiosResponse } from '@app/helpers/AxiosHelper';
import { getFormData } from '@app/helpers/ObjectHelper';

import { ScheduleEntry, TeamSchedule } from '@app/crud/schedule/schedule.type';

const { REACT_APP_LEAD_URL } = process.env;

export function getSchedules(params: { userId: number; userEmail: string; dateStart: string; dateEnd: string }) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/schedule/search`, {
        params,
    });
}

export function getAvailabilities(userId: number, params: { userId: number; dateStart: string; dateEnd: string }) {
    return ApiGateway.get<ScheduleEntry[], CustomAxiosResponse<ScheduleEntry>>(
        `${REACT_APP_LEAD_URL}/schedule/search-availabilities/${userId}`,
        {
            params,
        },
    );
}

export function getAppointments(userId: number, params: { userId: number; userEmail: string; dateStart: string }) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/schedule/search-appointments/${userId}`, {
        params,
    });
}

export function addAppointment(data: unknown) {
    return ApiGateway.post(`${REACT_APP_LEAD_URL}/additional/service`, getFormData(data));
}

export function updateAppointmentById(data: unknown) {
    return ApiGateway.put(`${REACT_APP_LEAD_URL}/schedule/update-appointment`, data);
}

type TeamScheduleParams = {
    dateStart: string;
    dateEnd: string;
    dayView: string;
    dealershipId: string;
};

export function getTeamSchedules(params: TeamScheduleParams) {
    return ApiGateway.get<TeamSchedule[], CustomAxiosResponse<TeamSchedule[]>>(
        `${REACT_APP_LEAD_URL}/schedule/search-team-schedule`,
        {
            params,
        },
    );
}
