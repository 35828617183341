import { Galleria } from 'primereact/galleria';
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useIframe from '@app/hooks/useIframe';

import ModalDefault from '@app/partials/content/modals/Modal.default';

const StockModalPictures = ({ show, setShowModal, medias, title, type, activeIndex }) => {
    const { iframe, setContent } = useIframe({
        width: '100%',
        frameBorder: 0,
    });

    const itemTemplate = (item) => <img src={item} style={{ width: '100%', display: 'block' }} />;

    const thumbnailTemplate = (item) => <img src={item} width="80px" height="60px" style={{ display: 'block' }} />;

    const mediasHandler = () => {
        switch (type) {
            case 3:
            case 1:
                return iframe;
            case 2:
                return (
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: `<iframe frameBorder={0} width="100%" height="426" src="${medias}"/>`,
                        }}
                    />
                );
            default:
                return (
                    <Galleria
                        value={medias}
                        activeIndex={activeIndex}
                        numVisible={5}
                        showItemNavigators
                        showItemNavigatorsOnHover
                        circular
                        item={itemTemplate}
                        thumbnail={thumbnailTemplate}
                    />
                );
        }
    };

    useEffect(() => {
        setContent(medias);
    }, [medias, show]);

    return (
        <ModalDefault
            show={show}
            hideModal={() => setShowModal(false)}
            title={title}
            size="lg"
            body={mediasHandler()}
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button variant="primary" onClick={() => setShowModal(false)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                </div>
            }
        />
    );
};

StockModalPictures.defaultPictures = {
    activeIndex: 0,
};

export default StockModalPictures;
