import dayjs from 'dayjs';
import React from 'react';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import { PartExchange } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

type InfosAchatCashProps = {
    partExchange: PartExchange;
};
const InfosAchatCash = ({ partExchange }: InfosAchatCashProps) => {
    const Intl = useIntl();

    const registrationCertificate = () => {
        if (partExchange?.registrationCertificate) {
            const yes = Intl.formatMessage({ id: 'TRANSLATOR.YES' });
            return yes.charAt(0) + yes.slice(1).toLowerCase();
        } else if (partExchange?.registrationCertificateAbsenceReason) {
            const no = Intl.formatMessage({ id: 'TRANSLATOR.NO' });
            return no.charAt(0) + no.slice(1).toLowerCase();
        }

        return '-';
    };

    return (
        <>
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.REFERENCE' })}
                value={partExchange?.partExchangeReference || '-'}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.REGISTRATION' })}
                value={partExchange?.registration || '-'}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.VIN' })}
                value={partExchange?.vin || '-'}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.DATE_FIRST_REGISTRATION' })}
                value={
                    partExchange?.dateFirstRegistration
                        ? dayjs(partExchange?.dateFirstRegistration).format('DD/MM/YYYY')
                        : '-'
                }
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.VEHICLE_TYPE' })}
                value={partExchange?.vehicleType || '-'}
            />
            <RowInfo
                label={<FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.HAS_REGISTRATION_CERTIFICATE" />}
                value={registrationCertificate()}
            />
            <RowInfo
                label={<FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.REGISTRATION_CERTIFICATE" />}
                value={partExchange?.registrationCertificate || '-'}
            />
            <RowInfo
                label={<FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.CO_HOLDER" />}
                value={partExchange?.coHolder || '-'}
            />
        </>
    );
};

export default InfosAchatCash;
