import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import StockVehicleDealership from './StockVehicleDealership';
import StockVehicleInfo from './StockVehicleInfo';
import StockVehicleStatistic from './StockVehicleStatistic';
import StockVehicleStockTimeLine from './StockVehicleStockTimeLine';

const StockVehicleVN = ({ vehicle }) => {
    const isContextEFB = useSelector((state) => state.auth.userData.context === 'efb');

    return (
        <Row>
            <Col lg={4}>
                <StockVehicleInfo vehicle={vehicle} isVN />
            </Col>
            <Col lg={8} className="d-flex flex-column justify-content-between">
                <Row className="flex-grow-1">
                    <Col>
                        <StockVehicleDealership vehicle={vehicle} />
                    </Col>
                    <Col lg={6}>
                        <StockVehicleStatistic vehicle={vehicle} />
                    </Col>
                </Row>
                {((isContextEFB && vehicle?.timeline?.events) || !isContextEFB) && (
                    <Row className="flex-grow-1">
                        <Col lg={12} className="mt-8">
                            <StockVehicleStockTimeLine vehicle={vehicle} />
                        </Col>
                    </Row>
                )}
            </Col>
        </Row>
    );
};

export default StockVehicleVN;
