import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { actions } from '@app/store/modal/modal.store';

import Loader from '@app/partials/content/Loader';

import { campaignBloctelProcessed, campaignIsInFinalState, CONFIG_REQUIRED_STATUS } from '../helpers/CampaignHelper';

import CampaignAssignmentBlock from './CampaignAssignmentBlock';

const CampaignAssignment = ({ current_step, statistics, loading }) => {
    const dispatch = useDispatch();
    const withContact = statistics?.newContact + statistics?.oldContact;
    const withoutContact = statistics?.withoutContact - (statistics?.ignoredContact || 0);

    const disabled = campaignIsInFinalState(current_step);
    const bloctelDone = campaignBloctelProcessed(current_step);

    return (
        <Card className="card--shadowed">
            <Card.Header className="text-closed position-relative">
                <div className="bg-closed pin">
                    <i className="la la-cog text-white la-lg" />
                </div>
                <span className="ml-5 font-weight-semibold">
                    <FormattedMessage id="CAMPAIGN.ASSIGNMENT.TITLE" />
                </span>
            </Card.Header>
            <Card.Body>
                {loading ? (
                    <Loader style={{ width: '5rem', height: '5rem' }} wrapperStyle={{ marginTop: '-15px' }} overlay />
                ) : (
                    <>
                        <div className="kt-shape-font-color-4">
                            <FormattedMessage id="CAMPAIGN.ASSIGNMENT.RELAUNCH" />
                        </div>
                        <div className="mt-2 d-flex justify-content-between">
                            <div className="pr-4 pl-0 flex-grow-1 flex-basis-0">
                                <div className="campaign-card kt-bg-light-secondary kt-shape-font-color-4">
                                    <FormattedMessage id="TRANSLATOR.TOTAL" />
                                    <div className="mt-1 font-size-lg font-weight-bold">
                                        {statistics?.globalContact ?? '-'}
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 flex-grow-1 flex-basis-0">
                                <div className="campaign-card kt-bg-light-success text-success">
                                    <FormattedMessage id="CAMPAIGN.ASSIGNMENT.RECONCILED" />
                                    <div className="mt-1 font-size-lg font-weight-bold">{statistics?.oldContact}</div>
                                </div>
                            </div>
                            <div className="px-4 flex-grow-1 flex-basis-0">
                                <div className="campaign-card kt-bg-light-success text-success">
                                    <FormattedMessage id="CAMPAIGN.ASSIGNMENT.NEW" />
                                    <div className="mt-1 font-size-lg font-weight-bold">{statistics?.newContact}</div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 kt-shape-font-color-4">
                            <FormattedMessage id="CAMPAIGN.ASSIGNMENT.RECONCILIATIONS" />
                        </div>
                        {withoutContact > 0 ? (
                            <div className="mt-2 d-flex justify-content-between">
                                <CampaignAssignmentBlock
                                    text="CAMPAIGN.ASSIGNMENT.CONTACTS_RECONCILED"
                                    color="success"
                                    value={withContact}
                                    total={statistics?.globalContact}
                                    icon="la-bullseye"
                                />
                                <CampaignAssignmentBlock
                                    text="CAMPAIGN.ASSIGNMENT.CONTACTS_TO_RECONCILE"
                                    color="danger"
                                    value={withoutContact}
                                    total={statistics?.globalContact}
                                    icon="la-hand-point-right"
                                />
                            </div>
                        ) : statistics?.withoutAssignment > 0 ? (
                            <div className="mt-2 d-flex justify-content-between">
                                <CampaignAssignmentBlock
                                    text="CAMPAIGN.ASSIGNMENT.CONTACTS_ASSIGNED_AUTO"
                                    color={disabled ? `dark-light` : `success`}
                                    value={statistics?.withAssignment}
                                    total={statistics?.globalContact}
                                    icon="la-bullseye"
                                />
                                <CampaignAssignmentBlock
                                    text="CAMPAIGN.ASSIGNMENT.CONTACTS_TO_ASSIGN"
                                    color={disabled ? `dark-light` : `danger`}
                                    value={statistics?.withoutAssignment}
                                    total={statistics?.globalContact}
                                    icon="la-hand-point-right"
                                />
                            </div>
                        ) : (
                            <div className="mt-2 d-flex justify-content-between">
                                <CampaignAssignmentBlock
                                    text="CAMPAIGN.ASSIGNMENT.CONTACTS_ASSIGNED"
                                    color="success"
                                    value={statistics?.withAssignment}
                                    total={statistics?.globalContact}
                                    icon="la-bullseye"
                                />
                                <CampaignAssignmentBlock
                                    text="CAMPAIGN.ASSIGNMENT.CONTACTS_IGNORED"
                                    color="danger"
                                    value={statistics?.ignoredContact}
                                    total={statistics?.globalContact}
                                    icon="la-ban"
                                />
                            </div>
                        )}
                        <div className="mt-6 kt-shape-font-color-4">
                            <FormattedMessage id="CAMPAIGN.ASSIGNMENT.BLOCTEL" />
                        </div>
                        <div className="mt-2 d-flex justify-content-between">
                            <CampaignAssignmentBlock
                                text="CAMPAIGN.ASSIGNMENT.CONTACTS_AUTHORIZED"
                                color={bloctelDone ? `success` : `dark-light`}
                                value={statistics?.bloctelOk}
                                total={!bloctelDone || disabled ? null : statistics?.withAssignment}
                                icon="la-check-circle"
                            />
                            <CampaignAssignmentBlock
                                text="CAMPAIGN.ASSIGNMENT.CONTACTS_BLOCKED"
                                color={bloctelDone ? `danger` : `dark-light`}
                                value={statistics?.bloctelKo}
                                total={!bloctelDone || disabled ? null : statistics?.withAssignment}
                                icon="la-times-circle"
                            />
                        </div>
                        <div className="mt-8 text-center">
                            <Button
                                className="font-size-lg px-10"
                                variant="danger"
                                disabled={disabled || current_step?.slug !== CONFIG_REQUIRED_STATUS}
                                onClick={() => {
                                    dispatch(actions.modalChange('operation_settings'));
                                }}
                            >
                                <FormattedMessage id="CAMPAIGN.ASSIGNMENT.FINALIZE" />
                            </Button>
                        </div>
                    </>
                )}
            </Card.Body>
        </Card>
    );
};

export default CampaignAssignment;
