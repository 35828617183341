import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Locale } from '@src/_metronic/i18n/I18nProvider';
import { FiltersContext } from './FiltersProvider';
import { DateTimePicker } from './DateTimePicker';

const CalendarRangeFilter = ({ onChange, start, startFormat, end, endFormat, dateFormat }) => {
    const filters = useContext(FiltersContext);
    const startValue = filters[start]?.value || null;
    const endValue = filters[end]?.value || null;

    const [value, setValue] = useState(
        dayjs(startValue).isValid()
            ? [
                  dayjs(startValue, dateFormat).toDate(),
                  dayjs(endValue).isValid() ? dayjs(endValue, dateFormat).toDate() : null,
              ]
            : null,
    );

    const handleChange = (start, end) => {
        if (dayjs(start).isValid()) {
            setValue([dayjs(start).isValid() ? start : null, dayjs(end).isValid() ? end : null]);
        } else {
            setValue(null);
        }
    };

    useEffect(() => {
        if (typeof onChange !== 'undefined') {
            const startDate = value ? value[0] : null;
            const endDate = value ? value[1] : null;

            onChange({
                [start]: {
                    value: dayjs(startDate).isValid() ? dayjs(startDate).format(startFormat) : null,
                },
                [end]: {
                    value: dayjs(endDate).isValid() ? dayjs(endDate).format(endFormat) : null,
                },
            });
        }
    }, [value, onChange, start, startFormat, end, endFormat]);

    return (
        <DateTimePicker
            locale={Locale}
            selectionMode="range"
            value={value}
            dateFormat="DD/MM/YYYY"
            onChange={(e) => {
                const start = e?.value ? e.value[0] : null;
                const end = e?.value ? e.value[1] : null;
                handleChange(start, end);
            }}
        />
    );
};

CalendarRangeFilter.defaultProps = {
    start: 'dateStart',
    end: 'dateEnd',
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
    startFormat: 'YYYY-MM-DD [00:00:01]',
    endFormat: 'YYYY-MM-DD [23:59:59]',
    value: null,
};

export default CalendarRangeFilter;
