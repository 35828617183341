import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import ModalDefault from '@app/partials/content/modals/Modal.default';

const { REACT_APP_LEAD_URL } = process.env;

const MailContent = ({ params, showModal, setShowModal }) => {
    const Intl = useIntl();

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={handleClose}
            icon={<i className="la la-2x text-primary la-envelope-open-text" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.MAIL.VIEW.TITLE' })}
            body={
                <>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: params?.content }} />
                    {params?.attachments && (
                        <>
                            <div className="d-block mt-10 mb-2">
                                <FormattedHTMLMessage
                                    id="CUSTOMERS.MODAL.MAIL.VIEW.ATTACHMENTS"
                                    values={{
                                        count: params?.attachments.length,
                                    }}
                                />
                            </div>
                            {params?.attachments.map((item) => (
                                <a
                                    href={`${REACT_APP_LEAD_URL}/email_queue/download-attachment/${item.url}`}
                                    className="d-inline-flex align-items-center mr-5"
                                >
                                    {item.filename}
                                    <i
                                        className="la la-2x la-cloud-download-alt text-primary ml-1"
                                        style={{ fontSize: '14px' }}
                                    />
                                </a>
                            ))}
                        </>
                    )}
                </>
            }
            footer={
                <div className="d-flex justify-content-center">
                    <Button variant="outline-primary" onClick={handleClose}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default MailContent;
