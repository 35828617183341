import produce from 'immer';

export const actionTypes = {
    SetRoute: '@route/SET_ROUTE',
};

const initialState = {
    route: null,
    prevRoute: null,
};

export function routesReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SetRoute: {
            return produce(state, (draftState) => {
                draftState.prevRoute = draftState.route;
                draftState.route = action.payload;
            });
        }
        default:
            return state;
    }
}

export const actions = {
    setRoute: (route) => ({ type: actionTypes.SetRoute, payload: route }),
};
