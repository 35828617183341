import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';

import { getStorage, removeStorage, setStorage } from '../../_metronic';
import { actions } from '../store/login/login.store';

/**
 * Get autouser tokens in local storage
 *
 * @returns {{autouserCache: *, accessToken: *, refreshToken: *}}
 */
export const getToken = () => ({
    accessToken: getStorage('accessToken'),
    refreshToken: getStorage('refreshToken'),
    autouserCache: getStorage('autouserCache'),
});

interface ApiResponse {
    token?: string;
    refresh_token?: string;
    axios?: AxiosResponse;
    cache_key?: string;
    impersonator_token?: string;
    impersonated_by?: string;
    'x-autouser-cache'?: string;
}

/**
 * Set autouser tokens in local storage
 *
 * @param dispatch
 * @param tokens
 */
export const setToken = (dispatch: Dispatch, tokens: ApiResponse) => {
    setStorage('accessToken', tokens.token);

    if (tokens?.refresh_token) {
        setStorage('refreshToken', tokens.refresh_token);
    }

    if (tokens?.cache_key) {
        setStorage('impersonatorCache', getStorage('autouserCache'));
        setStorage('autouserCache', tokens.cache_key);
    } else {
        setStorage('autouserCache', tokens['x-autouser-cache']);
    }

    if (tokens?.impersonator_token) {
        setStorage('impersonatorToken', tokens.impersonator_token);
    }

    if (tokens?.impersonated_by) {
        setStorage('impersonatedBy', tokens.impersonated_by);
    }

    dispatch(
        actions.loggedUser({
            token: tokens.token || null,
        }),
    );
};

/**
 * Clear autouser tokens in local storage
 */
export const clearToken = () => {
    removeStorage('accessToken');
    removeStorage('refreshToken');
    removeStorage('autouserCache');
    removeStorage('impersonatedBy');
    removeStorage('impersonatorCache');
    removeStorage('impersonatorToken');
};

/**
 * Restore real user token (used to stop impersonation)
 */
export const restoreToken = () => {
    setStorage('accessToken', getStorage('impersonatorToken'));
    setStorage('autouserCache', getStorage('impersonatorCache'));
    removeStorage('impersonatedBy');
    removeStorage('impersonatorCache');
    removeStorage('impersonatorToken');
    window.location.reload();
};
