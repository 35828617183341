import React, { ReactNode } from 'react';

import useFilters from '@app/hooks/useFilters';

import { routeTo } from '@app/helpers/RoutesHelper';

type DashboardActivityDealershipCounterProps = {
    message: ReactNode;
    route: string;
    params: NonNullable<unknown>;
    filter: string;
    filterValues: NonNullable<unknown>;
};

const DashboardActivityDealershipCounter = ({
    message,
    route,
    params,
    filter,
    filterValues,
}: DashboardActivityDealershipCounterProps) => {
    const { updateFilters } = useFilters({
        name: filter,
    });

    const generateDashboardActivityLink = async () => {
        await updateFilters({
            filters: filterValues,
        });
        window.open(routeTo(route, params), '_blank');
    };

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <span onClick={() => generateDashboardActivityLink()} className="pl-2 cursor-pointer">
            <strong className="bullet">&bull; &nbsp;</strong>
            {message}
        </span>
    );
};

export default DashboardActivityDealershipCounter;
