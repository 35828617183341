import React, { useMemo, useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { getVehicles, VehiclesResponse } from '@app/crud/stock/stock.crud';

import { useAppSelector } from '@app/hooks';

import FiltersProvider from '@app/partials/content/FiltersProvider';

import '@src/_metronic/_assets/sass/pages/stock/stock.scss';

import { getNewDealerIds } from '../helpers/Perimeter';

import StockVehiclesDataTable from './dataTable/StockVehiclesDataTable';
import StockVehiclesHeader from './header/StockVehiclesHeader';

const StockVehicles = () => {
    const [loadedPerimeter, setLoadedPerimeter] = useState(false);
    const { userData } = useAppSelector((state) => state.auth);
    const { getValues } = useFormContext();
    const { state: defaultFilters } = useLocation();
    const extraParams = useMemo(
        () => ({
            loadedPerimeter,
        }),
        [loadedPerimeter],
    );

    const stockFilters = useFilters({
        name: 'stock_vehicle',
    });
    const stockTableHandler = useTableHandlers<{ context: string }, VehiclesResponse>({
        filterStore: 'stock_vehicle',
        fetchAction: getVehicles,
        withStore: false,
        extraParams,
        doSearch: (params, extraParams) => extraParams?.loadedPerimeter,
        paramsInterceptor: (params, filters) => {
            const perimeter = getValues('perimeter');
            let dealer_id = params?.dealer_id;

            if (!dealer_id) {
                dealer_id = getNewDealerIds(filters?.filters, perimeter);
            }

            return {
                ...params,
                from: filters.start,
                size: filters.items,
                sortField: filters?.sortField ? filters.sortField : null,
                sortDirection: filters?.sortOrder === 1 ? 'asc' : 'desc',
                dealer_id,
                context: userData.context,
            };
        },
    });

    useEffect(() => {
        if (defaultFilters) {
            stockFilters.resetAllFilters();
            stockTableHandler.handleFilter(defaultFilters);
        }
    }, [defaultFilters]);

    return (
        <FiltersProvider value={stockFilters.filters}>
            <Card className="p-4 stock-vehicles">
                <StockVehiclesHeader
                    handleFilter={stockTableHandler.handleFilter}
                    stockFilters={stockFilters}
                    facets={stockTableHandler.data.facets}
                    loading={stockTableHandler.loading}
                    setLoadedPerimeter={setLoadedPerimeter}
                />
                <StockVehiclesDataTable stockFilters={stockFilters} stockTableHandler={stockTableHandler} />
            </Card>
        </FiltersProvider>
    );
};

export default StockVehicles;
