import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import KTUtil from '@src/_metronic/_assets/js/util';

import { actions } from '@app/store/stock/stock.store';

import Label from '@app/partials/content/Label';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';
import Permission from '@app/partials/content/Permission';
import { getEligibleCredit, getEligibleLOA } from '@app/partials/content/Vehicle/VehicleHelpers';

import StockVehiclesTypeColumn from './StockVehiclesTypeColumn';

export default function StockVehiclesVehicleIdColumn({ vehicle, forceRerender, setForceRerender }) {
    const dispatch = useDispatch();
    let { vehiclesSelected } = useSelector((state) => state.stock);

    const identifier = vehicle?.sku;
    const updateCheckedList = (vehicleItem, checked) => {
        vehiclesSelected = {
            ...vehiclesSelected,
            [identifier]: checked
                ? {
                      comptant: true,
                      credit: getEligibleCredit(vehicleItem),
                      location: getEligibleLOA(vehicleItem),
                  }
                : undefined,
        };
        dispatch(actions.UpdateVehicleSelected(vehiclesSelected));
        setForceRerender(!forceRerender);
    };

    if (['VN', 'NEUF'].includes(vehicle.type.toUpperCase())) {
        return (
            <>
                <Row className="mb-2">
                    <Col lg={12}>
                        <input
                            type="checkbox"
                            className="p-checkbox--small mr-3"
                            checked={vehiclesSelected?.[identifier] !== undefined}
                            onClick={(e) => {
                                updateCheckedList(vehicle, e.target.checked);
                            }}
                        />
                        <span className="font-weight-bold">{vehicle.serial_number}</span>
                    </Col>
                </Row>
                <Permission permissions={['STOCK_VIEW_INTERNAL_DATA']}>
                    <Row className="mb-2">
                        <Col lg={12}>
                            <OverlayTooltip label="STOCK_VEHICLES.TABLE.SKU_TOOLTIP" id="sku">
                                <Label variant="light" className="font-weight-bold text-dark">
                                    {vehicle.sku}
                                </Label>
                            </OverlayTooltip>
                        </Col>
                    </Row>
                </Permission>
                {KTUtil.isSmallDesktopOrMobileDevice() && <StockVehiclesTypeColumn vehicle={vehicle} />}
                {vehicle.default_image.length > 0 && (
                    <Row>
                        <Col lg={12}>
                            <Image src={vehicle.default_image} widht="85px" height="55px" />
                        </Col>
                    </Row>
                )}
            </>
        );
    }
    return (
        <>
            <Row>
                <Col lg={12}>
                    <input
                        type="checkbox"
                        className="p-checkbox--small mr-3"
                        checked={vehiclesSelected?.[identifier] !== undefined}
                        onChange={(e) => updateCheckedList(vehicle, e.target.checked)}
                    />
                    <span className="font-weight-bold text-primary font-size-lg">{vehicle.license_number}</span>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    {vehicle?.sku && (
                        <Permission permissions={['STOCK_VIEW_INTERNAL_DATA']}>
                            <OverlayTooltip label="STOCK_VEHICLES.TABLE.SKU_TOOLTIP" id="sku">
                                <Label variant="light" className="font-weight-bold text-dark mt-2">
                                    n°{vehicle.sku}
                                </Label>
                            </OverlayTooltip>
                            &nbsp;
                        </Permission>
                    )}
                    {vehicle?.police_number && (
                        <OverlayTooltip label="STOCK_VEHICLES.FILTERS.LABEL.VEHICLE.POLICE_NUMBER" id="police_number">
                            <Label variant="light" className="font-weight-bold text-dark mt-2">
                                {vehicle.police_number}
                            </Label>
                        </OverlayTooltip>
                    )}
                </Col>
            </Row>
            {KTUtil.isSmallDesktopOrMobileDevice() && <StockVehiclesTypeColumn vehicle={vehicle} />}
            {vehicle.default_image.length > 0 && (
                <Row className="mt-2">
                    <Col>
                        <Image src={vehicle.default_image} widht="85px" height="55px" />
                    </Col>
                </Row>
            )}
        </>
    );
}
