import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { Button, Card, Row } from 'react-bootstrap';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFilters from '@app/hooks/useFilters';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { isObject } from '@app/helpers/ObjectHelper';
import { empty } from '@app/helpers/ToolsHelper';

import { getHistory } from '@app/crud/autouser/history.crud';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import RefreshTable from '@app/partials/content/RefreshTable';
import SelectFilter from '@app/partials/content/SelectFilter';
import { Filters } from '@app/partials/layout/Filters';

const History = () => {
    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: 'autouser_history_search',
    });
    const [historyDialog, setHistoryDialog] = useState(false);
    const [historyDialogHeader, setHistoryDialogHeader] = useState('');
    const [historyElement, setHistoryElement] = useState(null);

    const { data, loading, handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: 'autouser_history_search',
        fetchAction: getHistory,
        withStore: false,
        paramsInterceptor: (params, interceptorFilters) => ({
            ...params,
            start: interceptorFilters.start,
            limit: interceptorFilters.items,
        }),
    });

    const hideDetail = () => {
        setHistoryElement({});
        setHistoryDialog(false);
    };

    const openDetail = (delta) => {
        const historyArray = [];
        let oldValue = '';
        let newValue = '';
        Object.entries(delta.detail.values).forEach(([key, value]) => {
            const isValueValid =
                !isObject(value[0]) &&
                !Array.isArray(value[0]) &&
                !isObject(value[1]) &&
                !Array.isArray(value[1]) &&
                (!empty(value[0]) || !empty(value[1]));

            if (isValueValid) {
                const isDeleteAction = delta.action === 'delete';
                oldValue = (isDeleteAction ? value[1] : value[0])?.toString?.() || '-';
                newValue = (isDeleteAction ? value[0] : value[1])?.toString?.() || '-';

                historyArray.push({ name: key, oldValue, newValue });
            }
        });
        const label = delta.action.toUpperCase();
        setHistoryDialogHeader(`Détail de l'historique : ${label}`);
        setHistoryElement(historyArray);
        setHistoryDialog(true);
    };

    const datetimeBodyTemplate = (_history) =>
        dayjs(_history.create_datetime.replace(/T/, ' ').replace(/\+.*$/, '')).format('DD/MM/YYYY [•] HH:mm:ss');

    const detailBodyTemplate = (_history) => {
        if (!Array.isArray(_history.detail.values)) {
            return (
                <Button className="p-2 px-3" variant="primary" onClick={() => openDetail(_history)}>
                    <i className="fa fa-eye" />
                    Voir le détail
                </Button>
            );
        }
    };

    const actionBodyTemplate = (_history) => <div>{_history.action}</div>;

    const targetBodyTemplate = (_history) => _history.detail.target;

    const userBodyTemplate = (_history) => {
        if (_history.user === null) {
            return 'Auto Bot';
        }
        return _history.user;
    };

    const selectFilter = (name, options) => (
        <SelectFilter value={filters?.[name]?.value} name={name} options={options} onChange={handleFilter} />
    );

    const history = data?.result || [];

    const totalRecords = data?.count || 0;

    const cols = [
        {
            field: 'create_datetime',
            header: Intl.formatMessage({ id: 'AUTOUSER.HISTORY.TABLE.HEAD.DATE_TIME' }),
            style: { width: '15%' },
            body: datetimeBodyTemplate,
        },
        {
            field: 'user',
            header: Intl.formatMessage({ id: 'AUTOUSER.HISTORY.TABLE.HEAD.USER' }),
            style: { width: '25%' },
            body: userBodyTemplate,
        },
        {
            field: 'action',
            header: Intl.formatMessage({ id: 'AUTOUSER.HISTORY.TABLE.HEAD.ACTION' }),
            style: { width: '25%', textAlign: 'center' },
            body: actionBodyTemplate,
            filter: true,
            filterElement: selectFilter(
                'action',
                <>
                    <option value="">{Intl.formatMessage({ id: 'AUTOUSER.HISTORY.TABLE.HEADER.ALL_ACTIONS' })}</option>
                    <option value="create">{Intl.formatMessage({ id: 'AUTOUSER.HISTORY.TABLE.ACTION.CREATE' })}</option>
                    <option value="update">{Intl.formatMessage({ id: 'AUTOUSER.HISTORY.TABLE.ACTION.UPDATE' })}</option>
                    <option value="delete">{Intl.formatMessage({ id: 'AUTOUSER.HISTORY.TABLE.ACTION.DELETE' })}</option>
                </>,
            ),
        },
        {
            field: 'target',
            header: Intl.formatMessage({ id: 'AUTOUSER.HISTORY.TABLE.HEAD.TARGET' }),
            style: { width: '25%' },
            body: targetBodyTemplate,
        },
        {
            field: 'detail',
            header: Intl.formatMessage({ id: 'AUTOUSER.HISTORY.TABLE.HEAD.DETAIL' }),
            style: { width: '10%' },
            body: detailBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <Card>
            <Card.Body>
                <FiltersProvider value={filters}>
                    <Filters>
                        <Row>
                            <RefreshTable count={totalRecords} onClick={refresh} />
                        </Row>
                    </Filters>
                    <HoCDataTable
                        value={history}
                        totalRecords={totalRecords}
                        lazy
                        loading={loading}
                        paginator
                        onFilter={handleFilter}
                        onPage={handlePagination}
                        onSort={handleSort}
                        paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                        currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                        rows={items}
                        first={start}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        removableSort
                        filterDisplay="row"
                        rowsPerPageOptions={[25, 50, 100]}
                        emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                    >
                        {dynamicColumns}
                    </HoCDataTable>
                </FiltersProvider>
                <Dialog
                    visible={historyDialog}
                    style={{ width: '850px' }}
                    header={historyDialogHeader}
                    modal
                    className="p-fluid"
                    onHide={hideDetail}
                >
                    <HoCDataTable value={historyElement}>
                        <Column field="name" header="Champ" />
                        <Column
                            field="oldValue"
                            className="text-truncate"
                            header={Intl.formatMessage({ id: 'AUTOUSER.HISTORY.TABLE.HEADER.OLD_VALUE' })}
                        />
                        <Column
                            field="newValue"
                            className="text-truncate"
                            header={Intl.formatMessage({ id: 'AUTOUSER.HISTORY.TABLE.HEADER.NEW_VALUE' })}
                        />
                    </HoCDataTable>
                </Dialog>
            </Card.Body>
        </Card>
    );
};

export default History;
