import ApiGateway from '@app/helpers/AxiosHelper';

const { REACT_APP_LEAD_URL, REACT_APP_STOCK_URL } = process.env;

export function getActionEmailTypes(params) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/action-email-type`, {
        params,
    });
}

export function getActionEmailType(id) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/action-email-type/${id}`);
}

export function addActionEmailType(data) {
    return ApiGateway.post(`${REACT_APP_LEAD_URL}/action-email-type`, data);
}

export function updateActionEmailType(id, data) {
    return ApiGateway.put(`${REACT_APP_LEAD_URL}/action-email-type/${id}`, data);
}

export function deleteActionEmailType(id) {
    return ApiGateway.delete(`${REACT_APP_LEAD_URL}/action-email-type/${id}`);
}

export function getActionEmailCompagnies() {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/action-email-company`);
}

export function getBrands() {
    return ApiGateway.get(`${REACT_APP_STOCK_URL}/brands`);
}
