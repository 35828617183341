import React, { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import ModalDefault from '@app/partials/content/modals/Modal.default';

const ConsentConfirmation = ({ showModal, onConfirm, onCancel, setShowModal }) => {
    const modalRef = useRef(null);
    const Intl = useIntl();

    useEffect(() => {
        if (modalRef.current) {
            // adjust z-index of modal wrapper to be above the sidebar
            modalRef.current.closest('.modal.fade').style.zIndex = 9999;
        }
    }, [modalRef]);

    const cancelValidation = () => {
        onCancel();
        setShowModal(false);
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            title={
                <div className="customer__consent-modal__title">
                    <i className="la la-bullhorn" />
                    <div className="mt-lg-0 mt-5 font-size-h3 text-center text-primary">
                        <FormattedHTMLMessage id="CUSTOMER.MODAL.CONSENT.TITLE" />
                    </div>
                </div>
            }
            body={
                <div ref={modalRef} className="text-center pb-5">
                    <FormattedHTMLMessage id="CUSTOMER.MODAL.CONSENT.INTRO" />
                </div>
            }
            footer={
                <div className="d-flex justify-content-center">
                    <Button variant="outline-secondary" className="mr-5" onClick={cancelValidation}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" onClick={onConfirm}>
                        {Intl.formatMessage({ id: 'CUSTOMER.MODAL.CONSENT.CTA.ACCEPT' })}
                    </Button>
                </div>
            }
        />
    );
};

export default ConsentConfirmation;
