import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { fetchVehicle } from '@app/store/stock/stock.thunk';

import Loader from '@app/partials/content/Loader';

import StockVehiclePictures from './partials/StockVehiclePictures';
import StockVehiclePriceHistorical from './partials/StockVehiclePriceHistorical';
import StockVehicleTrade from './partials/StockVehicleTrade';
import StockVehicleVN from './partials/StockVehicleVN';
import StockVehicleVO from './partials/StockVehicleVO';

const StockVehicle = () => {
    const { sku } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const { data: vehicle, loading } = useSelector((state) => state.stock.vehicle);
    const { userData } = useSelector((state) => state.auth);
    const { context } = userData;
    const isContextEFB = useSelector((state) => state.auth.userData.context === 'efb');
    const isVN = ['VN', 'NEUF'].includes(vehicle?.type?.toUpperCase());

    useEffect(() => {
        dispatch(fetchVehicle({ sku, history, context }));
    }, [sku, dispatch, history, context]);

    return (
        <div className="stock-vehicle">
            {loading ? (
                <Loader style={{ width: '5rem', height: '5rem' }} overlay />
            ) : (
                <>
                    {isVN ? (
                        <StockVehicleVN vehicle={vehicle} />
                    ) : (
                        <StockVehicleVO vehicle={vehicle} history={history} dispatch={dispatch} />
                    )}
                    {!isContextEFB && (
                        <Row className="mt-8">
                            <Col lg={12}>
                                <StockVehiclePriceHistorical vehicle={vehicle} />
                            </Col>
                        </Row>
                    )}
                    <Row className="mt-8">
                        {isVN && (
                            <Col lg={3}>
                                <StockVehicleTrade vehicle={vehicle} isVN />
                            </Col>
                        )}
                        <Col>
                            <StockVehiclePictures vehicle={vehicle} isVN={isVN} />
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
};

export default StockVehicle;
