import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { getStorage } from '@src/_metronic';
import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';

import { routeTo } from '@app/helpers/RoutesHelper';

import { associateUser, getAllPerimeter, updateUser } from '@app/crud/autouser/user.crud';

import ROUTES from '@app/router/Routes';
import { unlinkUser } from '@app/store/autouser/user/user.thunk';
import { impersonateUser } from '@app/store/login/login.thunk';
import { actions } from '@app/store/modal/modal.store';

import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';
import toast from '@app/partials/content/Toast';

import {
    addDealershipFromPerimeter,
    getPerimeterDealership,
    mapDealershipNodes,
    removeDealershipFromPerimeter,
    updateDatas,
} from '../Helpers/Dealership';

const ActionsButtons = ({ _user, setCreateLoading, refresh, addToDealership, dealership }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { fetch: fetchPerimeter, loading: loadingPerimeter } = useFetch({
        fetchAction: getAllPerimeter,
        resultInterceptor: (response) => updateDatas(response),
        interceptResponse: true,
        axios: false,
    });
    const impersonated = getStorage('impersonatedBy');

    const createInternalUser = async (user, params) => {
        setCreateLoading(true);
        const associatedUser = await associateUser(user?.email, params);
        setCreateLoading(false);

        dispatch(
            actions.modalChange('manage_user', {
                user: associatedUser,
            }),
        );
    };

    const handleConfiguration = (user) => {
        if (user?.associated === false) {
            createInternalUser(user);
        } else {
            dispatch(
                actions.modalChange('manage_user', {
                    user: {
                        ...user,
                        ...(user?.associated ? { id: user.associated } : {}),
                    },
                    ...(dealership && refresh ? { refresh } : {}),
                }),
            );
        }
    };

    const handlePartnerConfiguration = (user) => {
        dispatch(
            actions.modalChange('manage_user_partner', {
                user,
            }),
        );
    };

    const handleImpersonate = (user) => {
        // const redirectLink = () => history.push(routeTo(ROUTES.ACCOUNT_PLANNING.PATH));
        Swal.fire({
            title: Intl.formatMessage({ id: `AUTOUSER.USERS.IMPERSONATE` }),
            text: Intl.formatMessage(
                { id: `AUTOUSER.USERS.IMPERSONATE.TEXT` },
                { name: `${user?.first_name} ${user?.last_name}` || '' },
            ),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
            cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
            customClass: {
                confirmButton: 'btn mr-3 btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            preConfirm: async () => {
                Swal.showLoading();
                try {
                    // const impersonateToken = await impersonateUser({user: _user?.email});
                    await dispatch(impersonateUser(user?.email));
                    window.location.reload();
                    // redirectLink();
                } catch {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                    });
                }
            },
        });
    };

    const updateActiveUser = (user) => {
        Swal.fire({
            title: Intl.formatMessage({ id: `AUTOUSER.USERS.${user?.active ? 'DISABLE' : 'ACTIVE'}.TITLE` }),
            text: Intl.formatMessage({ id: `AUTOUSER.USERS.${user?.active ? 'DISABLE' : 'ACTIVE'}.TEXT` }),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
            cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
            customClass: {
                confirmButton: 'btn mr-3 btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            preConfirm: async () => {
                Swal.showLoading();

                try {
                    await updateUser(user?.id, {
                        active: !user?.active,
                    });
                } catch {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                    });
                }

                refresh();
            },
        });
    };

    const takeOffUserFromDealership = (user) => {
        Swal.fire({
            title: Intl.formatMessage({ id: `AUTOUSER.USERS.TAKE_OFF.TITLE` }),
            text: Intl.formatMessage(
                { id: `AUTOUSER.USERS.TAKE_OFF.TEXT` },
                {
                    user_name: `${user?.firstName} ${user?.lastName}`,
                    dealership_name: dealership?.ref_dealership_usualname,
                },
            ),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
            cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
            customClass: {
                confirmButton: 'btn mr-3 btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            preConfirm: async () => {
                Swal.showLoading();
                try {
                    const perimeter = await fetchPerimeter(_user?.id);
                    const perimeterDealership = getPerimeterDealership(perimeter, dealership?.ref_dealership_id);

                    if (perimeterDealership) {
                        const newPerimeter = removeDealershipFromPerimeter(perimeter, perimeterDealership);
                        await updateUser(_user?.id, {
                            dealership_perimeter: mapDealershipNodes(newPerimeter),
                        });

                        refresh();
                    }
                } catch {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                    });
                }
            },
        });
    };

    const addUserToDealership = async (user) => {
        setCreateLoading(true);

        try {
            const userId = user?.associated || user?.id;
            const perimeter = await fetchPerimeter(userId);
            const perimeterDealership = getPerimeterDealership(perimeter, addToDealership?.ref_dealership_id);

            if (perimeterDealership) {
                const newPerimeter = addDealershipFromPerimeter(perimeter, perimeterDealership);

                if (!userId) {
                    await createInternalUser(user, {
                        dealership_perimeter: mapDealershipNodes(newPerimeter),
                        default_dealership: addToDealership?.ref_dealership_id,
                        role: 'conseiller-commercial',
                    });
                } else {
                    const updatedUser = await updateUser(userId, {
                        dealership_perimeter: mapDealershipNodes(newPerimeter),
                    });
                    dispatch(actions.closeModals());
                    if (!updatedUser?.active) {
                        dispatch(
                            actions.modalChange('manage_user', {
                                user: updatedUser,
                            }),
                        );
                    }
                }
            }
        } catch {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }

        setCreateLoading(false);
    };

    if (addToDealership) {
        return (
            <Button variant="primary" onClick={() => addUserToDealership(_user)}>
                {Intl.formatMessage({ id: 'TRANSLATOR.ADD' })}
            </Button>
        );
    }

    if (_user?.associated === false) {
        return (
            <Button variant="primary" onClick={() => handleConfiguration(_user)} size="sm">
                {Intl.formatMessage({ id: 'TRANSLATOR.ACTIVATE' })}
            </Button>
        );
    }

    return (
        <>
            {loadingPerimeter && <Loader style={{ width: '5rem', height: '5rem' }} overlay content="root" />}
            <Dropdown>
                <Dropdown.Toggle id="dropdown-button-dark-example1" size="sm" variant="primary">
                    {Intl.formatMessage({ id: 'TRANSLATOR.ACTIONS' })}
                </Dropdown.Toggle>

                <Dropdown.Menu variant="dark">
                    {_user?.id && (
                        <Permission permissions={['AUTOUSER_EDIT_USER']}>
                            <Link
                                className="dropdown-item kt-label-font-color-2"
                                to={routeTo(ROUTES.USER.PATH.PLANNING, {
                                    id: _user?.id,
                                })}
                            >
                                {Intl.formatMessage({ id: 'AUTOUSER.USERS.VIEW.SHOW_PROFILE' })}
                            </Link>
                        </Permission>
                    )}
                    <Dropdown.Item onClick={() => handleConfiguration(_user)}>
                        {Intl.formatMessage({ id: 'AUTOUSER.USERS.CONFIGURE' })}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handlePartnerConfiguration(_user)}>
                        {Intl.formatMessage({ id: 'AUTOUSER.USERS.PARTNER.CONFIGURE' })}
                    </Dropdown.Item>
                    {dealership && (
                        <Dropdown.Item onClick={() => takeOffUserFromDealership(_user)}>
                            {Intl.formatMessage({ id: 'AUTOUSER.USERS.TAKE_OFF' })}
                        </Dropdown.Item>
                    )}
                    <Permission permissions={['AUTOUSER_DISABLE_USER']}>
                        <Dropdown.Item onClick={() => updateActiveUser(_user)}>
                            {_user?.active
                                ? Intl.formatMessage({ id: 'AUTOUSER.USERS.DISABLE' })
                                : Intl.formatMessage({ id: 'AUTOUSER.USERS.ACTIVATE' })}
                        </Dropdown.Item>
                    </Permission>
                    {_user?.can_be_impersonated && !impersonated && (
                        <Permission permissions={['AUTOUSER_IMPERSONATE_USER']}>
                            <Dropdown.Item onClick={() => handleImpersonate(_user)}>
                                {Intl.formatMessage({ id: 'AUTOUSER.USERS.IMPERSONATE' })}
                            </Dropdown.Item>
                        </Permission>
                    )}
                    {_user.external && (
                        <Permission permissions={['AUTOUSER_DELETE_USER']}>
                            <Dropdown.Item
                                onClick={() => {
                                    dispatch(unlinkUser({ history, userId: _user.id }));
                                }}
                            >
                                {Intl.formatMessage({ id: 'TRANSLATOR.REMOVE' })}
                            </Dropdown.Item>
                        </Permission>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};

ActionsButtons.defaultProps = {
    addToDealership: false,
};

export default ActionsButtons;
