import React from 'react';
import { Card } from 'react-bootstrap';

import { OrderType } from '@app/crud/apv/order.type';

import OrderTable from '../orders/OrderTable';

const Orders = () => (
    <Card>
        <Card.Body>
            <OrderTable type={OrderType.ORDER_DESK} />
        </Card.Body>
    </Card>
);

export default Orders;
