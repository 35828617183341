import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';
import { RootState } from '@app/store/rootDuck';
import { Vehicle } from '@app/store/stock/stock.type';

import { getPriceTagLink } from '@app/partials/content/Vehicle/VehicleHelpers';

type StockVehiclesActionsColumnProps = {
    vehicle: Vehicle;
};

export default function StockVehiclesActionsColumn({ vehicle }: StockVehiclesActionsColumnProps) {
    const isContextEFB = useSelector((state: RootState) => state.auth.userData.context === 'efb');

    return (
        <div className="d-flex flex-column">
            <Link
                to={routeTo(ROUTES.STOCK_VEHICLE.PATH, {
                    sku: vehicle?.sku,
                })}
            >
                <Button className="w-100 pt-1 pb-1 m-1 rounded-0" variant="primary">
                    {Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.DETAILS' })}
                </Button>
            </Link>
            {!isContextEFB && (
                <a href={getPriceTagLink(vehicle)} rel="noreferrer" className="px-1" download>
                    <Button className="w-100 pt-0 pb-0 m-1 rounded-0 " variant="primary">
                        <i className="p-1 las la-print" />
                    </Button>
                </a>
            )}
        </div>
    );
}
