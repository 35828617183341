import React, { useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';

import { updateFluidity } from '@app/crud/customers/fluidity.crud';

import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

const TransactionUpdate = ({ lead, showModal, setShowModal, type, reload }) => {
    const [loading, setLoading] = useState(false);
    const Intl = useIntl();

    const handleEnter = () => {
        if (type) {
            setLoading(true);

            updateFluidity(lead?.id, { isOpen: false, statusSlug: type })
                .then(() => {
                    setLoading(false);
                    reload();
                })
                .catch(() => {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                    });
                    setLoading(false);
                    setShowModal(false);
                });
        }
    };

    return (
        <ModalDefault
            show={showModal}
            onEnter={handleEnter}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-car" />}
            title={Intl.formatMessage({
                id:
                    type === 'transaction_finalized'
                        ? 'FLUIDITY.MODAL.TRANSACTION.FINALIZE.TITLE'
                        : 'FLUIDITY.MODAL.TRANSACTION.CANCEL.TITLE',
            })}
            body={
                <div
                    style={{
                        minHeight: '150px',
                    }}
                >
                    {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                    <div className="text-center">
                        {type === 'transaction_finalized' ? (
                            <>
                                <FormattedHTMLMessage
                                    id="FLUIDITY.MODAL.TRANSACTION.FINALIZE.SUBTITLE"
                                    values={{
                                        registration: lead?.vehicle?.registration,
                                        dealership: lead?.nameDealershipSeller,
                                    }}
                                />
                                <br />
                                <Image className="mt-10" src="/media/pages/fluidity/transaction-finalisee.svg" />
                            </>
                        ) : (
                            <>
                                <FormattedHTMLMessage
                                    id="FLUIDITY.MODAL.TRANSACTION.CANCEL.SUBTITLE"
                                    values={{
                                        registration: lead?.vehicle?.registration,
                                        dealership: lead?.nameDealershipSeller,
                                    }}
                                />
                                <br />
                                <Image className="mt-10" src="/media/pages/fluidity/transaction-annulee.svg" />
                            </>
                        )}
                    </div>
                </div>
            }
            footer={
                <div>
                    <Button variant="primary" onClick={() => setShowModal(false)}>
                        <FormattedMessage id="TRANSLATOR.CLOSE" />
                    </Button>
                </div>
            }
        />
    );
};

export default TransactionUpdate;
