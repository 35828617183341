import { Dropdown } from 'primereact/dropdown';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useIntl } from 'react-intl';

type APVInStoreTimingListProps = {
    methods: UseFormMethods;
    required: boolean;
};

// Move generateTimes function outside the component
const generateTimes = () => {
    const times = [];
    for (let minutes = 15; minutes <= 600; minutes += 15) {
        let timing = '';
        const hours = Math.floor(minutes / 60);
        const remainderMinutes = minutes % 60;

        if (hours > 0) {
            timing = `${hours}h`;
            if (remainderMinutes > 0) {
                timing += ` ${remainderMinutes}min`;
            }
        } else {
            timing = `${remainderMinutes} min`; // Use only minutes for values less than 60
        }

        times.push({
            id: times.length + 1,
            timing,
            value: minutes,
        });
    }
    return times;
};

const APVInStoreTimingList = ({ methods, required }: APVInStoreTimingListProps) => {
    const Intl = useIntl();
    const { control } = methods;

    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (selectOption: string) => {
        setSelectedOption(selectOption);
    };

    return (
        <Form.Group>
            <Controller
                control={control}
                name="selectedCustomedTiming"
                rules={{
                    required: required && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                }}
                render={({ onChange }) => (
                    <Dropdown
                        value={selectedOption}
                        options={generateTimes()}
                        optionLabel="timing"
                        itemTemplate={(option) => (
                            <div>
                                <p>Libre - {option.timing}</p>
                            </div>
                        )}
                        onChange={(e) => {
                            onChange(e.value);
                            handleChange(e.value);
                        }}
                        placeholder={Intl.formatMessage({
                            id: 'CUSTOMERS.MODAL.APVINSTORE.PLACEHOLDER.INITIAL_DURATION',
                        })}
                        disabled={!generateTimes().length}
                    />
                )}
            />
        </Form.Group>
    );
};

export default APVInStoreTimingList;
