import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import DashboardActivityTimeline from '@app/pages/dashboard/partials/Activity/DashboardActivityTimeline';
import DashboardPendingCounters from '@app/pages/dashboard/partials/DashboardPendingCounters';

interface Props {
    className?: string;
}

const DashboardActivityManager = ({ className }: Props) => (
    <div className={classNames('dashboard-activity mt-8 mt-xxl-0', className)}>
        <div className="d-flex align-items-center">
            <i className="dashboard-activity__icon las la-2x la-sitemap mr-2" />
            <span className="font-size-lg font-weight-bold">
                <FormattedMessage id="DASHBOARD.ACTIVITY.TITLE" />
            </span>
        </div>
        <DashboardPendingCounters displayApv={false} className="font-size-base" />
        <DashboardActivityTimeline displayTransformationRate displayOverlayInfo />
    </div>
);

export default DashboardActivityManager;
