import ApiGateway, { CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { TimeSlotResponse } from './dealership.crud';
import {
    ApiResponse,
    CompleteCounter,
    ConfirmQuoteAndShowResponse,
    FetchOrderParams,
    Order,
    OrderInStore,
    OrdersApiResponse,
    OrdersApiResponseData,
    UpdateAppointmentOrderParams,
    UpdateOrderParams,
} from './order.type';

const { REACT_APP_APV_URL } = process.env;

export function getOrders(params: FetchOrderParams) {
    return ApiGateway.get<
        OrdersApiResponse<OrdersApiResponseData>,
        CustomAxiosResponse<OrdersApiResponse<OrdersApiResponseData>>
    >(`${REACT_APP_APV_URL}/order`, { params });
}
export function getOrder(id: string) {
    return ApiGateway.get<ApiResponse<Order>, CustomAxiosResponse<ApiResponse<Order>>>(
        `${REACT_APP_APV_URL}/order/${id}`,
    );
}

export function confirmQuoteAndShow(id: string) {
    return ApiGateway.post<ConfirmQuoteAndShowResponse, CustomAxiosResponse<ConfirmQuoteAndShowResponse>>(
        `${REACT_APP_APV_URL}/orderApvInStore/${id}/confirmQuoteAndShow`,
    );
}

export async function confirmQuoteAndSend(id: string) {
    const response = await ApiGateway.post<OrderInStore, CustomAxiosResponse<OrderInStore>>(
        `${REACT_APP_APV_URL}/orderApvInStore/${id}/confirmQuoteAndSend`,
    );
    if (response?.result?.error) {
        throw Error(response.result.error);
    } else {
        return response;
    }
}

export function updateOrder(id: number, params: UpdateOrderParams) {
    return ApiGateway.put(`${REACT_APP_APV_URL}/order/${id}/mail`, params);
}

export async function updateOrderStatusToClosed(id: string) {
    const response = await ApiGateway.put<ApiResponse<Order>, CustomAxiosResponse<ApiResponse<Order>>>(
        `${REACT_APP_APV_URL}/orderApvInStore/${id}/cancel`,
    );
    if (response?.result?.error) {
        throw Error(response?.result?.error_description || response.result.error);
    }
    return response;
}

export function getOrderStatus() {
    return ApiGateway.get<CompleteCounter[], CustomAxiosResponse<CompleteCounter[]>>(
        `${REACT_APP_APV_URL}/order/status/reason`,
    );
}

export async function getOrderCompleteCounter() {
    const { result } = await ApiGateway.get<ApiResponse<number>, CustomAxiosResponse<ApiResponse<number>>>(
        `${REACT_APP_APV_URL}/counter/orders?orderType=commande`,
    );
    return result;
}

export async function createOrderInStore(params: object) {
    const response = await ApiGateway.post<ApiResponse<OrderInStore>, CustomAxiosResponse<ApiResponse<OrderInStore>>>(
        `${REACT_APP_APV_URL}/orderApvInStore`,
        params,
    );
    if (response?.result?.error) {
        throw Error(response?.result?.error_description || response.result.error);
    } else {
        return response;
    }
}

export async function updateOrderInStore(id: string, params: object) {
    const response = await ApiGateway.put<ApiResponse<OrderInStore>, CustomAxiosResponse<ApiResponse<OrderInStore>>>(
        `${REACT_APP_APV_URL}/orderApvInStore/${id}`,
        params,
    );
    if (response?.result?.error) {
        throw Error(response?.result?.error_description || response.result.error);
    } else {
        return response;
    }
}

export async function confirmOrderInStore(id: string, params: object) {
    const response = await ApiGateway.post<ApiResponse<OrderInStore>, CustomAxiosResponse<ApiResponse<OrderInStore>>>(
        `${REACT_APP_APV_URL}/orderApvInStore/${id}/confirm`,
        params,
    );
    if (response?.result?.error) {
        throw Error(response?.result?.error_description || response.result.error);
    } else {
        return response;
    }
}

export async function getOrderInStorePDF(id: string) {
    return ApiGateway.get<{ pdfContent: string }, CustomAxiosResponse<{ pdfContent: string }>>(
        `${REACT_APP_APV_URL}/orderApvInStore/${id}/pdf`,
    );
}

export async function getQuotesApvInStoreCounter() {
    const { result } = await ApiGateway.get<ApiResponse<number>, CustomAxiosResponse<ApiResponse<number>>>(
        `${REACT_APP_APV_URL}/counter/orders?orderType=quote`,
    );
    return result;
}

export async function getOrdersApvInStoreCounter() {
    const { result } = await ApiGateway.get<ApiResponse<number>, CustomAxiosResponse<ApiResponse<number>>>(
        `${REACT_APP_APV_URL}/counter/orders?orderType=order-desk`,
    );
    return result;
}

export async function updateAppointmentOrder(id: number, params: UpdateAppointmentOrderParams) {
    const response = await ApiGateway.put<ApiResponse<OrderInStore>, CustomAxiosResponse<ApiResponse<OrderInStore>>>(
        `${REACT_APP_APV_URL}/appointment/${id}`,
        params,
    );
    if (response?.result?.error) {
        throw Error(response?.result?.error_description || response.result.error);
    } else {
        return response;
    }
}

export function getSlotsByOrderAppointmentId(id: number, date: string) {
    return ApiGateway.get<ApiResponse<TimeSlotResponse>, CustomAxiosResponse<ApiResponse<TimeSlotResponse>>>(
        `${REACT_APP_APV_URL}/order/${id}/timeslot/${date}`,
    );
}
