import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import ModalDefault from '@app/partials/content/modals/Modal.default';

const StockModalReasonPublicationVeh = ({ showModal, setShowModal, vehicle, slugOrigin }) => {
    const [labelOrigin, setLabelOrigin] = useState();
    const [isPublication, setIsPublication] = useState(false);
    const [reasons, setReasons] = useState([]);
    const [isErrorsData, setIsErrorsData] = useState(false);

    useEffect(() => {
        let errorsInData = false;
        switch (slugOrigin) {
            case 'eligibility_fw':
                setLabelOrigin('le full web');
                setReasons(isPublication ? [] : vehicle?.fw_eligibility_warning);
                break;
            case 'eligibility_afr':
            case 'publication_afr':
                setLabelOrigin('autosphere.fr');
                setReasons(vehicle?.anomaly?.split(','));
                errorsInData = true;
                break;
            default:
                setLabelOrigin('');
                setReasons([]);
        }

        setIsErrorsData(errorsInData);
        setIsPublication(!!slugOrigin?.includes('publication'));
    }, [slugOrigin]);

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="text-primary las la-exclamation-triangle fa-2x" />}
            title={Intl.formatMessage({
                id: `STOCK_VEHICLE.MODAL.REASON_${isPublication ? 'PUBLICATION' : 'ELIGIBILITY'}.TITLE`,
            })}
            body={
                <div>
                    <p className="text-center">
                        Voici les raisons de non {isPublication ? 'publication' : 'éligibilité'} de ce véhicule{' '}
                        <strong>
                            {vehicle?.brand} {vehicle?.model}
                        </strong>
                        <br />
                        immatriculé<strong> {vehicle?.license_number}</strong> sur {labelOrigin}.
                    </p>
                    <ul className="my-9">
                        {reasons &&
                            reasons.map((reason, index) =>
                                isErrorsData ? (
                                    <li key={index}>{reason}</li>
                                ) : (
                                    <li key={index}>
                                        {Intl.formatMessage({
                                            id: `STOCK_VEHICLE.MODAL.REASON.${reason?.toUpperCase?.()}`,
                                        })}
                                    </li>
                                ),
                            )}
                    </ul>
                    <p className="text-center">
                        Si vous constatez une erreur, <br />
                        merci de faire les ajustements nécessaires.
                    </p>
                </div>
            }
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button variant="primary" onClick={() => setShowModal(false)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default StockModalReasonPublicationVeh;
