export const vehicleType = [
    {
        'code': 'VP',
        'title': 'Voitures particulières'
    },
    {
        'code': 'CTTE',
        'title': 'Camionnettes (véhicules d\'un PTAC inférieur ou égal à 3 500 kg autres que les tracteurs routiers)'
    },
    {
        'code': 'CAM',
        'title': 'Camions (véhicules d\'un PTAC supérieur à 3,5 tonnes autres que les tracteurs routiers)'
    },
    {
        'code': 'CL',
        'title': 'Cyclomoteurs à deux roues ou cyclomoteurs non carrossés à trois roues'
    },
    {
        'code': 'CYCL',
        'title': 'Cyclomoteurs à trois roues'
    },
    {
        'code': 'MAGA',
        'title': 'Machines agricoles automotrices'
    },
    {
        'code': 'MIAR',
        'title': 'Machines et instruments remorqués'
    },
    {
        'code': 'MTL',
        'title': 'Motocyclettes légères'
    },
    {
        'code': 'MTT1',
        'title': 'Motocyclettes dont la puissance maximale nette CE est inférieure ou égale à 25 kW avec un rapport puissance /poids ne dépasse pas 0,16 kW/kg'
    },
    {
        'code': 'MTT2',
        'title': 'Autres motocyclettes'
    },
    {
        'code': 'QM',
        'title': 'Quadricycles à moteur'
    },
    {
        'code': 'REA',
        'title': 'Remorques agricoles'
    },
    {
        'code': 'REM',
        'title': 'Remorques routières'
    },
    {
        'code': 'RESP',
        'title': 'Remorques spécialisées'
    },
    {
        'code': 'RETC',
        'title': 'Remorques pour transports combinés'
    },
    {
        'code': 'SRAT',
        'title': 'Semi-remorques avant-train'
    },
    {
        'code': 'SREA',
        'title': 'Semi-remorques agricoles'
    },
    {
        'code': 'SREM',
        'title': 'Semi-remorques routières'
    },
    {
        'code': 'SRSP',
        'title': 'Semi-remorques spécialisées'
    },
    {
        'code': 'SRTC',
        'title': 'Semi-remorques pour transports combinés'
    },
    {
        'code': 'TCP',
        'title': 'Transports en commun de personne'
    },
    {
        'code': 'TM',
        'title': 'Tricycles à moteur'
    },
    {
        'code': 'TRA',
        'title': 'Tracteurs agricoles'
    },
    {
        'code': 'TRR',
        'title': 'Tracteurs routiers'
    },
    {
        'code': 'VASP',
        'title': 'Véhicules automoteur spécialisés'
    },
];