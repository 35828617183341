import React, {ReactNode} from 'react';
import {Col, Row} from "react-bootstrap";
import Editor from './Editor';

type RowInfoProps = {
    label: ReactNode,
    colLabel: number,
    value: ReactNode,
    colValue: number,
    subComponent?: ReactNode,
    status?: boolean,
    valueClassName?: string,
    useEditor?: boolean,
    editorClassName?: string
}
const RowInfo = (
    {
        label,
        colLabel,
        value = '-',
        colValue,
        subComponent,
        status,
        valueClassName,
        useEditor,
        editorClassName
    }: RowInfoProps
    ) => {
    return (
        <Row className="mb-2 align-items-start">
            <Col xs={colLabel} sm={colLabel} md={colLabel} lg={colLabel} xl={colLabel}>
                <span className="kt-shape-font-color-4">{ label }</span>
            </Col>
            <Col xs={colValue} sm={colValue} md={colValue} lg={colValue} xl={colValue}>
                <span className={`font-weight-bold ${valueClassName || ''}`}>
                    {
                        useEditor ?
                            //@ts-ignore
                            <Editor value={value}
                                    className={editorClassName}
                                    config={{
                                        minHeight: 50,
                                        buttons: '',
                                        statusbar: false,
                                        readonly: true,
                                        inline: true
                                    }}
                                    useNl2br={true}
                            />
                            :
                            value
                    }
                </span>
                {
                    subComponent &&
                        <>
                        {!status && <br /> }
                            { subComponent }
                        </>
                }
            </Col>
        </Row>
    );
};
RowInfo.defaultProps = {
    colLabel: 5,
    colValue: 7
};
export default RowInfo;
