import React from 'react';
import { useParams } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';
import { LayoutContextConsumer } from '@src/_metronic/layout/LayoutContext';
import BreadCrumbs from '@src/_metronic/layout/sub-header/components/BreadCrumbs';

import subHeader from '@app/partials/HoC/subHeader';

interface QuotationsSubHeaderProps {
    subheaderCssClasses: string;
    subheaderContainerCssClasses: string;
    subheaderMobileToggle: string;
}

function QuotationsSubHeader(props: QuotationsSubHeaderProps) {
    const params = useParams<{ type: string }>();

    const { subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle } = props;

    let listTitle = Intl.formatMessage({ id: 'APV.IN_STORE.QUOTATIONS.TABS.ALL' });
    if (params.type === 'draft') {
        listTitle = Intl.formatMessage({ id: 'APV.IN_STORE.QUOTATIONS.TABS.DRAFT' });
    } else if (params.type === 'waiting_for_customer') {
        listTitle = Intl.formatMessage({ id: 'APV.IN_STORE.QUOTATIONS.TABS.WAITING_CLIENT' });
    } else if (params.type === 'closed') {
        listTitle = Intl.formatMessage({ id: 'APV.IN_STORE.QUOTATIONS.TABS.CLOSED' });
    }

    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: 'MENU.APV' }),
        },
        {
            title: Intl.formatMessage({ id: 'MENU.QUOTATION' }),
        },
        {
            title: Intl.formatMessage({ id: 'MENU.APVINSTORE.ORDERS' }),
        },
    ];

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                            type="button"
                        >
                            <span />
                        </button>
                    )}

                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">{listTitle}</h3>
                                <BreadCrumbs items={breadcrumb} />
                            </>
                        )}
                    </LayoutContextConsumer>
                </div>
            </div>
        </div>
    );
}

export default subHeader(QuotationsSubHeader);
