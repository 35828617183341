import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useAppSelector } from '@app/hooks';

import { Intl } from '../../../../../_metronic/i18n/I18nProvider';
import { getSlotsByOrderAppointmentId, updateOrderAppointment } from '../../../../crud/smartRepair/order.crud';
import Days from '../../../../partials/content/Day/Days';
import ModalDefault from '../../../../partials/content/modals/Modal.default';
import Months from '../../../../partials/content/Month/Months';
import Slots from '../../../../partials/content/Slot/Slots';
import toast from '../../../../partials/content/Toast';
import { fetchOrder } from '../../../../store/smart-repair/order/order.thunk';

interface UpdateProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Update({ showModal, setShowModal }: UpdateProps): JSX.Element {
    const params = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const { order } = useAppSelector((state) => state.smartRepair.order);

    const [loading, setLoading] = useState(false);

    const startDate = dayjs(order?.orderAppointment?.startDate).toDate();
    const [currentSlot, setCurrentSlot] = useState<string | undefined>();
    const [currentDay, setCurrentDay] = useState<string | undefined>();
    const [currentMonth, setCurrentMonth] = useState<string | undefined>();
    const [date, setDate] = useState<string | undefined>();

    const initialize = () => {
        setCurrentSlot(dayjs(startDate).format('HH:mm'));
        setCurrentDay(dayjs(startDate).format('YYYY-MM-DD'));
        setCurrentMonth(dayjs(startDate).format('YYYY-MM'));
        setDate(dayjs(startDate).format('YYYY-MM-DD'));
    };

    useEffect(() => {
        initialize();
    }, []);

    const [slots, setSlots] = useState([]);
    const [days, setDays] = useState([]);
    const [months, setMonths] = useState([]);

    const loadModalContent = useCallback(() => {
        setLoading(true);

        if (order?.orderAppointment?.order && date !== undefined) {
            getSlotsByOrderAppointmentId(order.orderAppointment.order, date)
                .then((response) => {
                    setMonths(response.result.months);
                    setDays(response.result.days);
                    setSlots(response.result.slots);
                    setLoading(false);
                })
                .catch(() => {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                    });
                });
        }
    }, [date, order]);

    useEffect(() => {
        loadModalContent();
    }, [loadModalContent]);

    const handleCurrentMonth = (selectedMonth: string) => {
        setCurrentMonth(selectedMonth);
        setCurrentDay(undefined);
        setCurrentSlot(undefined);
        setDate(selectedMonth);
    };

    const handleCurrentDay = (selectedDay: string) => {
        setCurrentDay(selectedDay);
        setCurrentSlot(undefined);
        setDate(selectedDay);
    };

    const handleClose = () => {
        setShowModal(false);
        initialize();
    };

    const onConfirmUpdate = () => {
        setLoading(true);

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            confirmButtonText: Intl.formatMessage({ id: 'CONFIRMATION' }),
            showCloseButton: true,
        });

        updateOrderAppointment(order?.orderAppointment.id, {
            type: 'automanager',
            startDate: `${dayjs(date).format('YYYY-MM-DD')} ${currentSlot}:00`,
        }).then(
            () => {
                setShowModal(false);
                swalWithBootstrapButtons.fire(
                    `<i class="las la-check-circle fa-2x text-primary"></i> <br /> ${Intl.formatMessage({
                        id: 'SR.ORDER.MODAL.UPDATE.CONFIRM_TITLE',
                    })}`,
                    Intl.formatMessage({ id: 'SR.ORDER.MODAL.UPDATE.CONFIRM_TXT' }, { order: order?.reference }),
                );
                dispatch(fetchOrder(params.id));
            },
            () => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'SR.ORDER.MODAL.UPDATE.TOAST.ERROR' }),
                });
                dispatch(fetchOrder(params.id));
            },
        );
    };

    const isUpdateDisabled = !currentMonth || !currentDay || !currentSlot;

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            onEnter={loadModalContent}
            hideModal={handleClose}
            icon={<i className="las la-wrench la-2x text-primary" />}
            title={Intl.formatMessage({ id: 'SR.ORDER.VIEW.MODAL.UPDATE' })}
            body={
                <div className="px-5">
                    <div className="mt-4 mb-3">
                        <Months months={months} currentMonth={currentMonth} onSelect={handleCurrentMonth} />
                    </div>
                    <hr />
                    <div className="mt-4 mb-3">
                        <Days days={days} currentDay={currentDay} onSelect={handleCurrentDay} />
                    </div>
                    <hr />
                    <div className="mt-4 mb-3">
                        <Slots
                            slots={slots}
                            currentSlot={currentSlot}
                            onSelect={setCurrentSlot}
                            unclickable={currentDay === ''}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button
                        variant="outline-secondary"
                        className="mr-3"
                        style={{ width: '100px' }}
                        onClick={handleClose}
                    >
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button
                        variant="primary"
                        className="position-relative"
                        style={{ width: '100px' }}
                        onClick={onConfirmUpdate}
                        disabled={isUpdateDisabled}
                    >
                        {Intl.formatMessage({ id: 'TRANSLATOR.UPDATE' })}
                    </Button>
                </div>
            }
        />
    );
}
