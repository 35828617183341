import React from 'react';

const FilePreview = ({name, id, onRemove}) => {
    return (
        <div className="files-upload__wrapper">
            <div className="files-upload__preview">
                <i className="la la-2x mb-2 la-file" />
                <div className="files-upload__name">
                    {name}
                </div>
                <div className="files-upload__remove" onClick={() => onRemove(id)}>
                    <i className="la la-lg la-trash files-upload__remove-icon" />
                </div>
            </div>
        </div>
    );
};

FilePreview.defaultProps = {
    file: {}
}

export default FilePreview;
