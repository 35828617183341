import React from 'react';
import { useIntl } from 'react-intl';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

interface Props {
    event: VcuMappedEventDetails;
}
const DetailTscan = ({ event }: Props) => {
    const Intl = useIntl();
    const { tscan } = event;
    function tireSelected(value: string) {
        switch (value) {
            case 'A1':
                return Intl.formatMessage({
                    id: 'CUSTOMER.VIEW.LIFE.DETAIL.TSCAN.TIRE.FRONT.LEFT',
                });
            case 'A2':
                return Intl.formatMessage({
                    id: 'CUSTOMER.VIEW.LIFE.DETAIL.TSCAN.TIRE.FRONT.RIGHT',
                });
            case 'B1':
                return Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.TSCAN.BACK.LEFT' });
            case 'B2':
                return Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.TSCAN.BACK.RIGHT' });
            default:
                return '-';
        }
    }

    function translateRecommandation(value: string) {
        switch (value) {
            case 'GOOD':
                return (
                    <span className="text-success">
                        {Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.GOOD' })}
                    </span>
                );
            case 'REPLACE_SOON':
            case 'MARGINAL':
                return (
                    <span className="text-warning">
                        {Intl.formatMessage({
                            id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.REPLACE.SOON',
                        })}
                    </span>
                );
            case 'REPLACE_NOW':
                return (
                    <span className="text-danger">
                        {Intl.formatMessage({
                            id: 'CUSTOMER.VIEW.LIFE.DETAIL.FLEETBACK.REPLACE_NOW',
                        })}
                    </span>
                );
            default:
                return '-';
        }
    }
    return (
        <div className="d-md-flex justify-content-md-between align-items-md-stretch">
            <div className="col-md-6">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.TSCAN.ID' })}
                    value={tscan?.tscanId || '-'}
                />
                {tscan?.tires &&
                    tscan.tires.map((tire: { positionAxle: string; positionIndex: string; recommandation: string }) => (
                        <RowInfo
                            label={Intl.formatMessage({
                                id: tireSelected(`${tire?.positionAxle}${tire?.positionIndex}`),
                            })}
                            value={translateRecommandation(tire?.recommandation)}
                        />
                    ))}
            </div>
            <div className="col-md-6">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.PREVIEWLINK' })}
                    value={
                        <a href={`${tscan?.fileLink}`} target="_blank" rel="noreferrer">
                            {Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.LINK' })}
                        </a>
                    }
                />
            </div>
        </div>
    );
};
export default DetailTscan;
