import React, {useContext} from 'react';
import {RadioButton} from 'primereact/radiobutton';
import {FiltersContext} from '../FiltersProvider';

type RadioButtonsFilterProps = {
    type: string,
    values: {
        key: string,
        value: boolean | string
    }[],
    name: string,
    className: string,
    defaultValue: string,
    onChange?: Function,
    pending: boolean,
    onPendingChange: Function,
    displayLabel: boolean,
    inline: boolean,
    filterName: string
}

const RadioButtonsFilter = (
    {
        type,
        values,
        name,
        className,
        defaultValue,
        onChange,
        pending,
        onPendingChange,
        displayLabel,
        inline,
        filterName
    }: RadioButtonsFilterProps) => {
    const filters = useContext(FiltersContext);
    const isChecked = (currentValue: string) => (filters?.[name]?.value ?? defaultValue) === currentValue;

    const handleClick = (value: string) => {
        const valueFilter = {
            [name]: {
                filterName,
                value: value
            }
        }

        if (!pending) {
            onChange({...valueFilter}, defaultValue);
        } else {
            onPendingChange({...valueFilter}, defaultValue);
        }
    }

    return (
        <div key={`inline-${type}`} className={className}>
            {
                values.map(({key, value}) => {
                    return (<RadioButton
                        key={key}
                        name={name}
                        value={value}
                        // @ts-ignore
                        onChange={() => handleClick(value)}
                        // @ts-ignore
                        checked={isChecked(value)}
                    />)
                })
            }
        </div>
    );
};

RadioButtonsFilter.defaultProps = {
    type: 'radio',
    className: 'mb-3',
    displayLabel: true,
    inline: false,
    filterName: null
}

export default RadioButtonsFilter;
