import React from "react";
import { FormattedHTMLMessage } from "react-intl";

import { useMobilize } from "@app/hooks/mobilize";

import MenuButton from "./MenuButton";

const MenuFooter = () => {
    const {isLoading, redirectToMobilize, isAllowedLeadHomeCharge} = useMobilize();

    return (
        <div className="px-2">
            {isAllowedLeadHomeCharge &&
                <MenuButton
                    backgroundColor="#12BEA4"
                    label={<><i className="la la-plug" style={{fontSize: '24px'}} /><span className="text-left"><FormattedHTMLMessage id='MENU.FOOTER.BUTTON.HOME_CHARGE.LABEL'/></span></>}
                    disabled={isLoading}
                    onClick={() => redirectToMobilize()}
                />
            }
        </div>
    )
}

export default MenuFooter;
