import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import useFetch from '@app/hooks/useFetch';

import { getOneCustomerActivity } from '@app/crud/customers/customer.crud';
import { mapEventDetail } from '@app/crud/mapping/eventDetail.map';

import { getTypeSlug, showDetailEvent } from '@app/pages/customers/Helpers/Events';
import { refreshLeads } from '@app/store/customers/customers.thunk';

import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';

import EventDetailActivities from './EventDetailActivities';
import EventDetailAPVActivities from './EventDetailAPVActivities';
import EventDetailDealershipNote from './EventDetailDealershipNote';
import EventDetailFinancement from './EventDetailFinancement';
import EventDetailInfo from './EventDetailInfo';
import EventDetailOrderDetail from './EventDetailOrderDetail';
import EventDetailPartExchange from './EventDetailPartExchange';
import EventDetailProject from './EventDetailProject';
import EventDetailProvider from './EventDetailProvider';
import EventDetailValuationBlock from './EventDetailValuation';

const EventDetail = ({ data, customer, activeTab, tab }) => {
    const {
        fetch: fetchData,
        data: event,
        setData: setEvent,
        loading: loadingEventDetail,
    } = useFetch({
        fetchAction: getOneCustomerActivity,
    });

    const typeSlug = getTypeSlug(
        event?.informations?.processType,
        event?.leadType?.slug || event?.informations?.leadTypeSlug,
        event?.informations?.source,
    );

    const isLeadClosed =
        event?.informations?.statusSlug === 'closed_won' || event?.informations?.statusSlug === 'closed_lost';
    const dispatch = useDispatch();

    useEffect(() => {
        if (data?.leadId && !data?.processType && !loadingEventDetail) {
            fetchData(data.leadId);
        } else {
            setEvent(mapEventDetail(data));
        }
    }, [data]);

    useEffect(() => {
        if (event?.id || event?.informations) {
            setTimeout(() => {
                const showDetailButton = document.querySelector(`#event-${activeTab}-${data?.leadId || data?.eventId}`);
                showDetailEvent(showDetailButton, true);
            }, 50);
        }
    }, [activeTab, event]);

    return (
        <div
            className="event-detail-container"
            id={`event-detail-${tab}-${event?.id || event?.informations?.identifier}`}
        >
            {!typeSlug ? (
                <Loader style={{ width: '5rem', height: '5rem' }} overlay wrapperStyle={{ top: '0', left: '0' }} />
            ) : (
                event && (
                    <EventDetailProvider value={{ fetchData }}>
                        {loadingEventDetail && (
                            <Loader
                                style={{ width: '5rem', height: '5rem' }}
                                overlay
                                wrapperStyle={{ top: '0', left: '0' }}
                            />
                        )}
                        <div className="pt-8 pb-8">
                            <Row>
                                <Col
                                    xl={12}
                                    className={clsx('event', {
                                        'col-xxl-0': event?.informations?.processType === 'AFR_SUBSCRIBER',
                                        'col-xxl-8': event?.informations?.leadType === undefined,
                                        'col-xxl-4': event?.informations?.leadType !== undefined,
                                        'col-xxl-12': !data?.leadId || event?.leadType?.slug === 'local_home_charge',
                                    })}
                                >
                                    <EventDetailInfo
                                        event={event}
                                        typeSlug={typeSlug}
                                        isLeadClosed={isLeadClosed}
                                        customer={customer}
                                    />
                                </Col>
                                {data?.leadId && (
                                    <>
                                        {typeSlug !== 'financement' &&
                                            typeSlug !== 'local_apv' &&
                                            typeSlug !== 'local_home_charge' &&
                                            ((event?.informations?.processType &&
                                                event?.informations?.processType !== 'AFR_SUBSCRIBER') ||
                                                !event?.informations?.processType) && (
                                                <Col className="event mt-xxl-0 mt-8">
                                                    {[
                                                        'reprise',
                                                        'achat_cash',
                                                        'achat_cash_abandon',
                                                        'local_achat_cash',
                                                    ].includes(typeSlug) ? (
                                                        <EventDetailPartExchange
                                                            event={event}
                                                            typeSlug={typeSlug}
                                                            isLeadClosed={isLeadClosed}
                                                            fetchData={fetchData}
                                                        />
                                                    ) : (
                                                        <EventDetailProject
                                                            event={event}
                                                            typeSlug={typeSlug}
                                                            isLeadClosed={isLeadClosed}
                                                            isVcuEvent={data?.isVcuEvent}
                                                        />
                                                    )}
                                                </Col>
                                            )}
                                        {typeSlug === 'local_apv' && (
                                            <Col xl={12} className="col-xxl-8">
                                                <EventDetailAPVActivities event={event} typeSlug={typeSlug} />
                                            </Col>
                                        )}
                                    </>
                                )}
                            </Row>
                            {!event?.informations?.processType &&
                                typeSlug !== 'local_apv' &&
                                typeSlug !== 'local_home_charge' && (
                                    <>
                                        {((typeSlug.startsWith('local') &&
                                            !['local_achat_cash', 'local_reprise'].includes(typeSlug)) ||
                                            [
                                                'vo',
                                                'vd',
                                                'vn',
                                                'constructeur',
                                                'marketing_campaign_vo',
                                                'marketing_campaign_vn',
                                            ].includes(typeSlug)) && (
                                            <Row className="mt-8">
                                                <Col className="event">
                                                    <EventDetailPartExchange
                                                        event={event}
                                                        typeSlug={typeSlug}
                                                        isLeadClosed={isLeadClosed}
                                                        fetchData={fetchData}
                                                    />
                                                </Col>
                                                <Col className="col-xxl-4 mt-xxl-0 mt-8" xl={12}>
                                                    <EventDetailFinancement
                                                        event={event}
                                                        typeSlug={typeSlug}
                                                        isLeadClosed={isLeadClosed}
                                                        fetchData={fetchData}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                        {typeSlug === 'lead_apv' && (
                                            <Row className="mt-8">
                                                <Col className="event">
                                                    <EventDetailOrderDetail event={event} />
                                                </Col>
                                            </Row>
                                        )}
                                        <Row>
                                            <Col xl={12} className="col-xxl-4 mt-8">
                                                <EventDetailActivities event={event} typeSlug={typeSlug} />
                                            </Col>
                                            <Col className="mt-8 d-flex flex-column">
                                                <Permission permissions={['LEAD_VALUATION_SHOW']}>
                                                    <EventDetailValuationBlock
                                                        event={event}
                                                        typeSlug={typeSlug}
                                                        reload={(all) =>
                                                            all
                                                                ? dispatch(
                                                                      refreshLeads(customer.id, 'generate_contract'),
                                                                  )
                                                                : fetchData(data.leadId)
                                                        }
                                                    />
                                                </Permission>
                                                <EventDetailDealershipNote
                                                    event={event}
                                                    typeSlug={typeSlug}
                                                    isLeadClosed={isLeadClosed}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            {[
                                'TECHNICAL_INTERVENTION_APPOINTMENT',
                                'TECHNICAL_INTERVENTION_APPOINTMENT_CANCELED',
                            ].includes(event?.informations?.processType) &&
                                typeSlug === 'prestation_atelier' && (
                                    <Row className="mt-8">
                                        <Col className="event">
                                            <EventDetailOrderDetail event={event} typeSlug={typeSlug} />
                                        </Col>
                                    </Row>
                                )}
                        </div>
                    </EventDetailProvider>
                )
            )}
        </div>
    );
};

export default EventDetail;
