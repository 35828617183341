import React from 'react';
import { Button, Form } from 'react-bootstrap';

import Editor from '@app/partials/content/Editor';
import ModalDefault from '@app/partials/content/modals/Modal.default';

import { Intl } from '../../../../../_metronic/i18n/I18nProvider';

export default function QuotationResumeComplementMsg({ quotation, showModal, setShowModal }) {
    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-envelope" />}
            title={Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.ASK.SENDED' })}
            body={
                <div className="px-5">
                    {quotation &&
                        quotation.orderRepairs.map((item) => (
                            <Form.Group key={item.id}>
                                {item.complementComment !== null && (
                                    <>
                                        <Form.Label>
                                            {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.COMMENTS' })}{' '}
                                            <b>{item.zoneBody.name}</b> :
                                        </Form.Label>
                                        <Editor
                                            value={item.complementComment}
                                            className="smart-repair-card__info-text mb-5 pt-5 pl-2"
                                            config={{
                                                minHeight: 25,
                                                buttons: '',
                                                statusbar: false,
                                                readonly: true,
                                                inline: true,
                                            }}
                                        />
                                    </>
                                )}
                            </Form.Group>
                        ))}
                </div>
            }
            footer={
                <div className="my-5 mr-5 d-flex justify-content-center">
                    <Button variant="primary mr-5" onClick={handleClose}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                </div>
            }
        />
    );
}
