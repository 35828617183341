import React from 'react';

import DashboardActions from '../../partials/DashboardActions';
import DashboardProgress from '../../partials/DashboardProgress';

const DashboardApv = () => (
    <div className="dashboard-role d-flex flex-column">
        <DashboardProgress className="d-flex align-items-end" />
        <DashboardActions className="d-flex mt-8 h-100" />
    </div>
);

export default DashboardApv;
