import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import useFetch from '@app/hooks/useFetch';

import { getConsentByToken } from '@app/crud/customers/customer.crud';

import ConsentLayout from './ConsentLayout';
import EditForm from './partials/EditForm';

const HEADER_BANNERS = {
    200: '/media/pages/consent/consent-edit.jpg',
    404: '/media/pages/consent/consent-not-found.jpg',
};

export default function Consent() {
    const { token } = useParams();
    const Intl = useIntl();
    const [loading, setLoading] = useState(true);

    const {
        fetch: fetchData,
        data,
        error,
    } = useFetch({
        fetchAction: getConsentByToken,
    });
    const context = data?.dealership?.context?.toUpperCase() || null;

    useEffect(() => {
        if (token) {
            fetchData(token).then(() => {
                setLoading(false);
            });
        }
    }, [token]);

    if (loading && data.length === 0) {
        return <div>{Intl.formatMessage({ id: 'CONSENT_PUBLIC.LOADING' })}</div>;
    }

    return (
        <ConsentLayout headerImage={HEADER_BANNERS[error ? 404 : 200]} context={context}>
            {!error && data && <EditForm token={token} data={data} context={context} />}
            {error && Array.isArray(data) && data.length === 0 && (
                <div className="consent-layout__notfound">
                    <h1 className="consent-layout__title">
                        {Intl.formatMessage({ id: 'CONSENT_PUBLIC.NO_ACTION.TITLE' })}
                    </h1>
                    <p className="mt-5">{Intl.formatMessage({ id: 'CONSENT_PUBLIC.NO_ACTION.TEXT' })}</p>
                </div>
            )}
        </ConsentLayout>
    );
}
