export enum RoleType {
    FONCTIONNEL = 'fonctionnel',
    SUPPORT = 'support',
}

export interface RolesParams {
    research?: string;
    type?: RoleType;
    start: number;
    limit: number;
}

export interface UserRole {
    id: number;
    name: string;
    description: string;
    type: RoleType;
    slug: string;
    active: boolean;
    impersonate: boolean;
    visibleForDealership: boolean;
}

export type UserRoles = Omit<UserRole, 'impersonate' | 'visibleForDealership'>;
