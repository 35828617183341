import classNames from 'classnames';
import React, { ReactNode, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { empty } from '@app/helpers/ToolsHelper';

import TriSwitchInput from './TriSwitchInput';

type SwitchInputProps = {
    form?: boolean;
    name: string;
    active?: boolean;
    className?: string;
    triState?: boolean;
    disabled?: boolean;
    onChange?: Function;
    labelFirst?: string | ReactNode;
    labelSecond?: string | ReactNode;
    rules?: null;
    handleChange?: Function;
    switchClassName?: string;
};

const SwitchInput = ({
    form,
    name,
    active: propsActive,
    className,
    triState,
    disabled,
    onChange,
    labelFirst,
    labelSecond,
    rules,
    handleChange,
    switchClassName,
}: SwitchInputProps) => {
    const methods = useFormContext();
    const { setValue, watch, control } = methods;

    const active = form ? watch(name) : propsActive;

    useEffect(() => {
        if (name) {
            setValue(name, propsActive);
        }
    }, [setValue, form, propsActive, name]);

    return (
        <div
            className={classNames('switch-input', className, {
                'switch-input--active': active === true,
                'switch-input--inactive': active === false || empty(active),
                'switch-input--neutral': active === null && triState,
            })}
        >
            {labelFirst && (
                <Form.Label className={`switch-input__label ${active ? '' : 'p-inputswitch-checked'}`}>
                    {labelFirst}
                </Form.Label>
            )}
            {form ? (
                <Controller
                    control={control}
                    name={name}
                    defaultValue={propsActive}
                    {...(rules ? { rules } : {})}
                    render={({ onChange: onChangeValue, value }) => (
                        <TriSwitchInput
                            disabled={disabled}
                            state={value}
                            className={switchClassName}
                            triState={triState}
                            onChange={(val: string) => {
                                if (handleChange !== undefined) {
                                    handleChange();
                                }

                                onChangeValue(val);
                                if (typeof onChange !== 'undefined') {
                                    onChange(val);
                                }
                            }}
                        />
                    )}
                />
            ) : (
                <TriSwitchInput
                    disabled={disabled}
                    state={propsActive}
                    className={switchClassName}
                    triState={triState}
                    onChange={(value: string) => {
                        if (typeof onChange !== 'undefined') {
                            onChange(value);
                        }
                    }}
                />
            )}
            {labelSecond && (
                <Form.Label className={`switch-input__label ${active ? 'p-inputswitch-checked' : ''}`}>
                    {labelSecond}
                </Form.Label>
            )}
        </div>
    );
};

SwitchInput.defaultProps = {
    labelFirst: <FormattedMessage id="SWITCH.INPUT.LABEL.FIRST" />,
    labelSecond: <FormattedMessage id="SWITCH.INPUT.LABEL.SECOND" />,
    active: false,
    disabled: false,
    className: '',
    onChange: undefined,
    form: true,
    triState: false,
    switchClassName: '',
    handleChange: undefined,
    rules: null,
};

export default SwitchInput;
