import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

type LeadAtelierInfoProps = {
    event: VcuMappedEventDetails;
};

const LeadAtelierInfo = ({ event }: LeadAtelierInfoProps) => {
    const Intl = useIntl();

    return (
        <>
            {['TECHNICAL_INTERVENTION_APPOINTMENT', 'TECHNICAL_INTERVENTION_APPOINTMENT_CANCELED'].includes(
                event?.informations.processType,
            ) ? (
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.SOURCE' })}
                    value="Atelier Autosphere"
                />
            ) : (
                ''
            )}
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DATE_HOUR' })}
                value={
                    event?.appointment.details.appointmentAppointmentDateStart &&
                    dayjs(event?.appointment.details.appointmentAppointmentDateStart).format('DD/MM/YYYY [à] HH [h] mm')
                }
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DEALERSHIP' })}
                value={`${event?.project.vehicles[0].make} ${event?.informations.dealership?.name}`}
            />
            {event?.appointment.details.comment !== '' && (
                <div className="comment">
                    <span>
                        <i className="la la-comment la-lg mr-2" />
                        {Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.COMMENT' })}
                    </span>
                    <p className="mt-4">{event?.appointment.details.comment || ''}</p>
                </div>
            )}
        </>
    );
};

export default LeadAtelierInfo;
