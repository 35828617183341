import React from 'react';
import {Form} from "react-bootstrap";
import FilePreview from "./FilePreview";
import {v4 as uuid} from 'uuid';

const FilesUpload = React.forwardRef(({value, onChange, multiple, className}, ref) => {
    const handleInputChange = (e) => {
        if (onChange) {
            const files = Array.from(e.target.files).map((file) => {
               file.id = uuid();
               return file;
            });
            onChange(files);
        }
        e.value = '';
    }

    const handleRemoveFile = (id) => {
        const files = value.filter(function(file) {
            return file.id !== id;
        });
        onChange(files);
    }

    return (
        <div className="files-upload">
            { (value && value.length > 0) &&
                <div className={`files-upload__previews ${className}`}>
                    {
                        value.map(({id, name}) => {
                            return <FilePreview key={id}
                                                id={id}
                                                name={name}
                                                onRemove={handleRemoveFile}/>
                        })
                    }
                </div>
            }
            <Form.Control onChange={(e) => handleInputChange(e)}
                          ref={ref}
                          className="files-upload__input"
                          multiple={multiple}
                          type="file"/>
        </div>
    );
});

FilesUpload.defaultProps = {
    multiple: true
}

export default FilesUpload;
