import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import Label from '@app/partials/content/Label';

import { getLeadStatus, getLeadStatusIcon } from '../Helpers/Lead';

interface Props {
    leadStatusSlug: string;
    leadStatusName: string;
    leadStatusSubName: string;
    leadFlags?: string[];
    className?: string;
    divider?: ReactNode;
    shift?: string;
    variant?: string;
    overridedStatus?: string;
}
const LeadStatusRow = ({
    leadStatusSlug,
    leadStatusName,
    leadStatusSubName,
    leadFlags = undefined,
    className,
    divider = undefined,
    shift,
    variant,
    overridedStatus,
}: Props) => {
    const status = overridedStatus || getLeadStatus(leadStatusSlug);
    const icon = getLeadStatusIcon(leadStatusSlug);
    const isCentral = leadFlags?.includes('is_central');
    const isOptOut = leadFlags?.includes('is_opt_out');
    const isAwaitingOpportunity = ['in_progress_awaiting_opportunity_update'].includes(leadStatusSlug);

    return (
        <span
            className={classNames('flex-wrap font-size-sm', className, {
                [`text-${variant || status}`]: variant || status,
            })}
        >
            <Label
                className={classNames('font-weight-bold label-lg', {
                    [shift]: shift,
                    [`label-light-${status}`]: status,
                    'text-nowrap': leadStatusSlug,
                })}
            >
                {leadStatusName || ''}
            </Label>
            {leadStatusSubName && !isAwaitingOpportunity && (
                <>
                    {divider && divider}
                    <div>
                        <span className="ml-2 font-weight-bold">&bull;</span>
                        {icon && (
                            <i
                                className={classNames('ml-1', icon, {
                                    [`text-${variant || status}`]: variant || status,
                                })}
                            />
                        )}
                        <span
                            className={classNames('font-weight-bold', {
                                'ml-2': !icon,
                                'ml-1': icon,
                            })}
                        >
                            {leadStatusSubName}
                        </span>
                    </div>
                </>
            )}
            {isCentral && (
                <Label
                    className={classNames('font-weight-bold label-lg label-green', {
                        [shift]: shift,
                    })}
                >
                    <FormattedMessage id="LEADS_ONLINE.LABEL.TREATMENT_CENTRAL" />
                    {isOptOut && (
                        <>
                            <span className="ml-2 mr-2 font-weight-bold">&bull;</span>
                            <FormattedMessage id="LEADS_ONLINE.LABEL.OPT_OUT" />
                        </>
                    )}
                </Label>
            )}
        </span>
    );
};

LeadStatusRow.defaultProps = {
    className: '',
    shift: 'ml-3',
    variant: null,
    overridedStatus: null,
    leadFlags: [],
    divider: '',
};

export default LeadStatusRow;
