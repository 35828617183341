import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { alphabeticalSort } from '@app/helpers/ArrayHelper';

import { User } from '@app/store/login/login.store';

import Permission from '@app/partials/content/Permission';
import SwitchInput from '@app/partials/content/SwitchInput';
import ErrorForm from '@app/partials/layout/ErrorForm';

import { CompanyInfo, GDPRField } from './Helpers/CreateCustomer';

type DealershipListingProps = {
    userData: User;
    values: CompanyInfo;
    getDealershipConsent: (dealership: string, gdprField: string) => ['string', boolean];
    gdpr_fields: GDPRField;
    getConsentName: (dealership: string, gdprField: string) => string;
    setConsentChanged: React.Dispatch<React.SetStateAction<boolean>>;
};

const DealershipListing = ({
    userData,
    values,
    getDealershipConsent,
    gdpr_fields,
    getConsentName,
    setConsentChanged,
}: DealershipListingProps) => {
    const Intl = useIntl();
    const { getValues, setValue, control, errors } = useFormContext();

    const handleConsent = (name: string) => {
        Object.entries(values.new_consents).forEach(([key, value]) => {
            if (value === null && name !== key) {
                setValue(`new_consents.${key}`, false);
            }
        });
        setConsentChanged(true);
    };

    return (
        <>
            <Permission permissions={['LEAD_UPDATE_CONSENT_CONTACT']}>
                <Form.Group>
                    <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.UPDATE.DEALERSHIP' })}</Form.Label>
                    <Controller
                        control={control}
                        name="dealership"
                        render={({ value, onChange }) => (
                            <Dropdown
                                value={value}
                                options={
                                    userData?.dealerships &&
                                    Object.entries(userData.dealerships)
                                        .sort(alphabeticalSort('title'))
                                        .map(([code, dealership]) => ({
                                            ...dealership,
                                            code,
                                        }))
                                }
                                onChange={(e) => onChange(e.value)}
                                optionLabel="title"
                                optionValue="code"
                                filter
                                filterBy="title"
                                valueTemplate={(val) => val?.title || ''}
                            />
                        )}
                    />
                </Form.Group>

                {/* Error display */}
                <Form.Group className="mb-5">
                    {(values.email === '' || errors?.email) && (
                        <div className="alert alert-danger font-size-base mb-2">
                            {Intl.formatMessage({
                                id: 'CUSTOMER.SIDEBAR_CONSENT.ALERT_EMAIL.USER',
                            })}
                        </div>
                    )}

                    {values.email && !errors?.email && (
                        <>
                            <Form.Label>
                                {Intl.formatMessage(
                                    { id: 'CUSTOMERS.MODAL.UPDATE.CONSENT' },
                                    {
                                        name: userData?.dealerships?.[values?.dealership]?.title || '',
                                    },
                                )}
                            </Form.Label>
                            <div className="d-flex flex-lg-nowrap flex-wrap">
                                {values.dealership &&
                                    Object.values(gdpr_fields).map((gdprField) => {
                                        const dealershipConsent = getDealershipConsent(
                                            values.dealership,
                                            gdprField.slug,
                                        );
                                        return (
                                            <div
                                                key={gdprField.slug}
                                                className={classNames('w-lg-auto w-50 mt-lg-0 mt-2 mr-10')}
                                            >
                                                <SwitchInput
                                                    name={`new_consents.${getConsentName(
                                                        values.dealership,
                                                        gdprField.slug,
                                                    )}`}
                                                    active={
                                                        dealershipConsent !== undefined ? dealershipConsent[1] : null
                                                    }
                                                    triState={
                                                        typeof getValues(
                                                            `new_consents.${getConsentName(
                                                                values.dealership,
                                                                gdprField.slug,
                                                            )}`,
                                                        ) !== 'boolean'
                                                    }
                                                    labelFirst=""
                                                    labelSecond={Intl.formatMessage({
                                                        id: gdprField.label,
                                                    })}
                                                    handleChange={() =>
                                                        handleConsent(getConsentName(values.dealership, gdprField.slug))
                                                    }
                                                />
                                            </div>
                                        );
                                    }, {})}
                            </div>
                            <div>
                                {values.dealership &&
                                    [
                                        Object.values(gdpr_fields).find(
                                            (gdprField) =>
                                                typeof getValues(
                                                    `new_consents.${getConsentName(values.dealership, gdprField.slug)}`,
                                                ) !== 'boolean',
                                        ),
                                    ].map(
                                        (gdprField) =>
                                            gdprField?.slug && (
                                                <ErrorForm
                                                    key={gdprField.slug}
                                                    errors={errors}
                                                    name={`new_consents.${getConsentName(
                                                        values.dealership,
                                                        gdprField.slug,
                                                    )}`}
                                                />
                                            ),
                                    )}
                            </div>
                        </>
                    )}
                </Form.Group>
            </Permission>
            <span>{Intl.formatMessage({ id: 'TRANSLATOR.REQUIRED_FIELDS' })}</span>
            <br />
            <span>{Intl.formatMessage({ id: 'TRANSLATOR.REQUIRED_FIELDS.PHONE_EMAIL.LEAST_ONE' })}</span>
        </>
    );
};

export default DealershipListing;
