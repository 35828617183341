import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

interface Props {
    event: VcuMappedEventDetails;
}
const SecurycarDisaster = ({ event }: Props) => {
    const Intl = useIntl();
    return (
        <div className="d-md-flex justify-content-md-between align-items-md-stretch">
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DISASTER.SOURCE' })}
                    value={event?.securycar.disaster[0].source ? event?.securycar.disaster[0].source : '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DISASTER.TYPE' })}
                    value={event?.securycar.disaster[0].type ? event?.securycar.disaster[0].type : '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DISASTER.DATE' })}
                    value={
                        event?.securycar.disaster[0].disasterDate
                            ? dayjs(event?.securycar.disaster[0].disasterDate).format('DD/MM/YYYY')
                            : '-'
                    }
                />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DISASTER.DECLARATION.DATE' })}
                    value={
                        event?.securycar.disaster[0].declarationDate
                            ? dayjs(event?.securycar.disaster[0].declarationDate).format('DD/MM/YYYY')
                            : '-'
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DISASTER.DECLARATION.CLOSE.DATE' })}
                    value={
                        event?.securycar.disaster[0].closureDate
                            ? dayjs(event?.securycar.disaster[0].closureDate).format('DD/MM/YYYY')
                            : '-'
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DISASTER.GUARANTEE.END' })}
                    value={
                        event?.securycar.disaster[0].guaranteeEndDate
                            ? dayjs(event?.securycar.disaster[0].guaranteeEndDate).format('DD/MM/YYYY')
                            : '-'
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DISASTER.INSURANCE.COMPANY' })}
                    value={
                        event?.securycar.disaster[0].insuranceCompany
                            ? event?.securycar.disaster[0].insuranceCompany
                            : '-'
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DISASTER.ISSUPPORTED' })}
                    value={event?.securycar.disaster[0].isSupported ? event?.securycar.disaster[0].isSupported : '-'}
                />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DISASTER.CONTRACT.NUMBER' })}
                    value={
                        event?.securycar.disaster[0].contratNumber ? event?.securycar.disaster[0].contratNumber : '-'
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.DISASTER.SUPPORTED.AMOUNTWITHOUTTAXES',
                    })}
                    value={
                        <NumberFormat
                            value={event?.securycar.disaster[0].supportedAmountWithTaxes || '-'}
                            decimalSeparator="."
                            thousandSeparator=" "
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" €"
                            displayType="text"
                        />
                    }
                />
            </div>
        </div>
    );
};

export default SecurycarDisaster;
