import React from 'react';
import { useIntl } from 'react-intl';

import { LayoutContextConsumer } from '@src/_metronic';
import BreadCrumbs from '@src/_metronic/layout/sub-header/components/BreadCrumbs';

import subHeader from '@app/partials/HoC/subHeader';

function OrdersSubHeader(props) {
    const Intl = useIntl();

    const { subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle } = props;

    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: 'MENU.APV' }),
        },
        {
            title: Intl.formatMessage({ id: 'MENU.APV.ONLINE_APPOINTEMENT' }),
        },
        {
            title: Intl.formatMessage({ id: 'MENU.APV.MANUFACTURER' }),
        },
    ];

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        // eslint-disable-next-line react/self-closing-comp, jsx-a11y/control-has-associated-label
                        <button
                            type="submit"
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                        ></button>
                    )}

                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">
                                    {Intl.formatMessage({ id: 'MENU.MANUFACTURER.ORDERS' })}
                                </h3>
                                <BreadCrumbs items={breadcrumb} />
                            </>
                        )}
                    </LayoutContextConsumer>
                </div>
            </div>
        </div>
    );
}

export default subHeader(OrdersSubHeader);
