import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import ErrorForm from '@app/partials/layout/ErrorForm';

const LeadWonPurchaseOrderTool = ({ params, listingParams, type }) => {
    const { register, errors } = useFormContext();
    const Intl = useIntl();
    const [purchaseOrderTools, setPurchaseOrderTools] = useState([]);

    useEffect(() => {
        let newPurchaseOrderTool = [];

        if (listingParams && listingParams.purchase_order_tool) {
            const toolTypeIndex = listingParams.purchase_order_tool.listingParams.findIndex((toolType) =>
                toolType.slug.includes(type?.toLowerCase?.()),
            );

            if (toolTypeIndex !== -1) {
                newPurchaseOrderTool = listingParams.purchase_order_tool.listingParams[toolTypeIndex].listingParams;
            }
        }

        setPurchaseOrderTools(newPurchaseOrderTool);
    }, [setPurchaseOrderTools, type]);

    return (
        <Form.Group>
            <Form.Label>
                <FormattedHTMLMessage id="CUSTOMERS.MODAL.LEAD_WON.TOOL" />
            </Form.Label>
            <Form.Control
                as="select"
                name="tool"
                defaultValue=""
                disabled={!!params?.event}
                className={`${errors.tool ? 'is-invalid' : ''}`}
                ref={register({
                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                })}
            >
                <option value="">{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_WON.TOOL.PLACEHOLDER' })}</option>
                {listingParams &&
                    listingParams.purchase_order_tool &&
                    type &&
                    purchaseOrderTools.map((tool) => (
                        <option key={tool.id} value={tool.slug}>
                            {tool.name}
                        </option>
                    ))}
            </Form.Control>
            <ErrorForm errors={errors} name="tool" />
        </Form.Group>
    );
};

export default LeadWonPurchaseOrderTool;
