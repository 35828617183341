import classNames from 'classnames';
import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

import { OperationContext } from '../../OperationProvider';

const OperationAssignmentsEqualForm = ({ dealershipId, onChange }) => {
    const { operationState } = useContext(OperationContext);
    const dealership = operationState?.dealerships?.[dealershipId];
    const selectedDealers = operationState?.selectedDealers?.[dealershipId] || [];
    const contactCount = dealership?.contacts?.filter((c) => !c?.dealer)?.length || 0;
    const dealersSelectedCount = selectedDealers.length || 1;
    const quantityPerDealers = Math.floor(contactCount / dealersSelectedCount);
    let remainders = contactCount % dealersSelectedCount;

    return (
        <>
            <div className="font-size-lg font-weight-normal text-center mb-4 py-2">
                <FormattedHTMLMessage id="CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.DEALERS" />
            </div>
            <div>
                {dealership?.dealers?.map((dealer) => {
                    const selected = selectedDealers?.includes?.(dealer?.id);

                    return (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                        <div
                            key={dealer.id}
                            className="cursor-pointer"
                            onClick={() => {
                                onChange(dealer);
                            }}
                        >
                            <Form.Check
                                type="radio"
                                className={classNames('campaign-dealership__dealer mb-2', {
                                    'campaign-dealership__dealer--checked': selectedDealers?.includes?.(dealer.id),
                                })}
                                id={`dealer_${dealer.id}`}
                                key={`dealer_${dealer.id}`}
                                value={`dealer_${dealer.id}`}
                                checked={selected}
                                label={
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="ml-2">
                                            <i className="las la-user mr-2" />
                                            <span className="font-weight-bold">{`${dealer?.firstname} ${dealer?.lastname}`}</span>
                                        </div>
                                        <div>
                                            <FormattedHTMLMessage
                                                id="CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.ALREADY_ASSIGNED"
                                                values={{
                                                    quantity: operationState?.dealersContact?.[dealer.id]?.length || 0,
                                                }}
                                            />
                                        </div>
                                        <div className="d-flex align-items-centertext-success">
                                            <div className="mr-2">
                                                {selected &&
                                                    `${quantityPerDealers + (remainders-- > 0 ? 1 : 0)} contacts`}
                                            </div>
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default OperationAssignmentsEqualForm;
