import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { getAllParams } from '@app/crud/customers/customer.crud';

import { useAppSelector, useFetch } from '@app/hooks';

import BrandModelVersion from '@app/partials/content/BrandModelVersion';
import ErrorForm from '@app/partials/layout/ErrorForm';

const LeadHomeChargeVehicle = () => {
    const Intl = useIntl();
    const [vehicle, setVehicle] = useState({
        brand: '',
        model: '',
    });

    const { fetch, data: params } = useFetch({
        fetchAction: getAllParams,
    });

    const methods = useFormContext();
    const { errors, register } = methods;
    const { data: customer } = useAppSelector((state) => state.customer.customer);

    useEffect(() => {
        fetch({ groupSlug: 'home_charge' });
    }, [fetch]);

    return (
        customer && (
            <>
                <Form.Group as={Col} md={12}>
                    <Form.Label>
                        <FormattedMessage id="CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.VEHICLE_SELECT" />
                    </Form.Label>
                    <Form.Control
                        as="select"
                        onChange={(e) => {
                            setVehicle({
                                brand: e.target.value.split(' - ')[0] || '',
                                model: e.target.value.split(' - ')[1] || '',
                            });
                        }}
                    >
                        <option selected disabled>
                            {Intl.formatMessage({
                                id: 'CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.VEHICLE_SELECT.PLACEHOLDER',
                            })}
                        </option>
                        <option value="other">
                            {Intl.formatMessage({ id: 'CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.VEHICLE_SELECT.OTHER' })}
                        </option>
                        {Array.isArray(customer.vehicleKnow) &&
                            customer.vehicleKnow?.map(
                                (item) =>
                                    item.brand &&
                                    item.model &&
                                    item.registrationNumber && (
                                        <option
                                            key={item.vin}
                                            value={`${item.brand} - ${item.model}`}
                                        >{`${item.brand} ${item.model} (${item.registrationNumber})`}</option>
                                    ),
                            )}
                    </Form.Control>
                </Form.Group>
                <div className="d-flex row col-md-12 m-auto px-0">
                    {vehicle.brand !== '' && vehicle.model !== '' ? (
                        <>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.VEHICLE_BRAND" />
                                </Form.Label>
                                <Form.Control
                                    name="vehicleBrand"
                                    defaultValue={vehicle.brand}
                                    ref={register({
                                        required: Intl.formatMessage({
                                            id: 'FORM.ERROR.REQUIRED',
                                        }),
                                    })}
                                />
                                <ErrorForm errors={errors} name="vehicleModel" />
                            </Form.Group>
                            <Form.Group as={Col} md={4}>
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.VEHICLE_MODEL" />
                                </Form.Label>
                                <Form.Control
                                    name="vehicleModel"
                                    defaultValue={vehicle.model}
                                    ref={register({
                                        required: Intl.formatMessage({
                                            id: 'FORM.ERROR.REQUIRED',
                                        }),
                                    })}
                                />
                                <ErrorForm errors={errors} name="vehicleModel" />
                            </Form.Group>
                        </>
                    ) : (
                        <Form.Group as={Col} md={8} className="px-0 overflow-hidden">
                            <BrandModelVersion
                                onLoading={() => false}
                                brandLabel={Intl.formatMessage({
                                    id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.BRAND',
                                })}
                                modelLabel={Intl.formatMessage({
                                    id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.MODEL',
                                })}
                                modelRequired
                                noVersion
                                defaultsValues={{
                                    make: '',
                                    model: '',
                                    version: null,
                                    dateFirstRegistration: dayjs('').format('MM/YYYY'),
                                }}
                                textFieldVersion
                                containerClassName="d-flex col-md-12 px-0"
                                fieldClassName="col-md-6 mb-0"
                            />
                        </Form.Group>
                    )}
                    <Form.Group as={Col} md={4}>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.VEHICLE_TYPE" />
                        </Form.Label>
                        <Form.Control
                            name="usage"
                            as="select"
                            ref={register({
                                required: Intl.formatMessage({
                                    id: 'FORM.ERROR.REQUIRED',
                                }),
                            })}
                        >
                            {params?.usage?.listingParams?.map((item) => (
                                <option key={item.id} value={item.value}>
                                    {item.name}
                                </option>
                            ))}
                        </Form.Control>
                        <ErrorForm errors={errors} name="usage" />
                    </Form.Group>
                </div>
                <Row className="px-4">
                    <Form.Group as={Col} md={4}>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.VEHICLE_ENERGY" />
                        </Form.Label>
                        <Form.Control
                            name="vehicleType"
                            as="select"
                            ref={register({
                                required: Intl.formatMessage({
                                    id: 'FORM.ERROR.REQUIRED',
                                }),
                            })}
                        >
                            {params?.vehicle_type?.listingParams?.map((item) => (
                                <option key={item.id} value={item.value}>
                                    {item.name}
                                </option>
                            ))}
                        </Form.Control>
                        <ErrorForm errors={errors} name="vehicleType" />
                    </Form.Group>
                    <Form.Group as={Col} md={4}>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.BATTERY_CAPACITY" />
                        </Form.Label>
                        <InputGroup className="mb-1">
                            <Form.Control
                                name="batteryCapacity"
                                type="number"
                                placeholder={Intl.formatMessage({
                                    id: 'CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.BATTERY_CAPACITY.PLACEHOLDER',
                                })}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value, 10);
                                    if (value > 150) e.target.value = '150';
                                }}
                                ref={register({
                                    required: Intl.formatMessage({
                                        id: 'FORM.ERROR.REQUIRED',
                                    }),
                                    valueAsNumber: true,
                                })}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>kW</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        <span className="font-size-xs text-muted">
                            <FormattedMessage id="CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.BATTERY_CAPACITY.LEGEND" />
                        </span>
                        <ErrorForm errors={errors} name="batteryCapacity" />
                    </Form.Group>
                    <Form.Group as={Col} md={4}>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.CHARGER_TYPE" />
                        </Form.Label>
                        <Form.Control
                            as="select"
                            name="chargerType"
                            defaultValue={-1}
                            ref={register({
                                required: Intl.formatMessage({
                                    id: 'FORM.ERROR.REQUIRED',
                                }),
                                validate: (value) =>
                                    value !== '-1' ? true : Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            })}
                        >
                            <option disabled value={-1}>
                                {Intl.formatMessage({
                                    id: 'CUSTOMER.MODAL.LEAD_HOME_CHARGE.FORM.CHARGER_TYPE.PLACEHOLDER',
                                })}
                            </option>
                            {params?.charger_type?.listingParams?.map((item) => (
                                <option key={item.id} value={item.value}>
                                    {item.name}
                                </option>
                            ))}
                        </Form.Control>
                        <ErrorForm errors={errors} name="chargerType" />
                    </Form.Group>
                </Row>
            </>
        )
    );
};

export default LeadHomeChargeVehicle;
