import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { Intl } from '../../../../../_metronic/i18n/I18nProvider';
import { actions } from '../../../../store/smart-repair/quotations/smart-repair.store';

/**
 * The component renders a button that is used to make quotation "incompatible".
 *
 * When the button is clicked, the "incompatibleOrder" function is called which checks whether the repair item is already marked as incompatible or not.
 * If it is, the function removes the mark and deletes the corresponding quotation in redux store.
 * If it is not, the function updates the repair item as incompatible and creates a new quotation with the updated information and add to redux store.
 *
 * orderRepairItem: An object that represents the repair item in the quotation.
 * displayResume: A function that displays the summary of the quotation.
 * expertComment: A string that represents an expert comment associated with the quotation.
 * selectedQuotations: An object that contains selected quotations associated with the repair item.
 * sumOfPackages: A function that calculates the sum of prices of packages selected for the repair item.
 */

function QuotationCtaIncompatibleOrder({ orderRepairItem, displayResume, expertComment }) {
    const dispatch = useDispatch();

    const saveExpertComment = (orderId) => (orderId === expertComment?.name ? expertComment.value : '');
    const incompatibleOrder = (orderRepairItemSelected) => {
        if (
            orderRepairItemSelected.modifiedBtnIncompatible ===
            Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.MODIFIED' })
        ) {
            orderRepairItemSelected.compatible = null;
            orderRepairItemSelected.modifiedBtnIncompatible = '';
            dispatch(actions.setDeleteItem(orderRepairItemSelected.id));
        } else {
            orderRepairItemSelected.compatible = 'incompatible';
            orderRepairItemSelected.modifiedBtnIncompatible = Intl.formatMessage({
                id: 'SMART.REPAIR.QUOTATION.BTN.MODIFIED',
            });
            dispatch(
                actions.setAcceptedQuotations([
                    {
                        id: orderRepairItemSelected.id,
                        orderId: orderRepairItemSelected.order,
                        priceTtc: null,
                        totalPackagesPrice: null,
                        slug: null,
                        zoneName: orderRepairItemSelected.zoneBody.name,
                        package: null,
                        compatible: false,
                        reference: orderRepairItemSelected.reference,
                        expertComment: saveExpertComment(orderRepairItemSelected.id),
                    },
                ]),
            );
        }
        displayResume(true);
    };

    return (
        <Button
            variant="danger"
            onClick={() => incompatibleOrder(orderRepairItem)}
            disabled={
                orderRepairItem?.modifiedBtnAdding === Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.MODIFIED' })
            }
        >
            {orderRepairItem?.compatible === 'incompatible' &&
            orderRepairItem?.modifiedBtnIncompatible ===
                Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.MODIFIED' })
                ? Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.MODIFIED' })
                : Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.IMPROPER' })}
        </Button>
    );
}

export default QuotationCtaIncompatibleOrder;
