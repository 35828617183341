import React from 'react';
import { useParams } from 'react-router-dom';

import { apvItems, globalItems } from './items';
import { SettingsCategories, SettingsItems } from './Settings.type';
import SettingsCard from './SettingsCard';

const Settings = () => {
    const { slug } = useParams<{ slug: SettingsCategories }>();

    const settings: SettingsItems = {
        global: globalItems,
        apv: apvItems,
    };

    return (
        settings[slug] && (
            <div className="settings-card-wrapper">
                {Object.values(settings[slug]).map((item) => (
                    <SettingsCard item={item} />
                ))}
            </div>
        )
    );
};

export default Settings;
