import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedHTMLMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { LayoutContextConsumer } from '@src/_metronic';
import { Intl } from '@src/_metronic/i18n/I18nProvider';
import BreadCrumbs from '@src/_metronic/layout/sub-header/components/BreadCrumbs';

import useFilters from '@app/hooks/useFilters';
import useModal from '@app/hooks/useModal';

import ButtonsFilter from '@app/partials/content/Filters/ButtonsFilter';
import FiltersProvider from '@app/partials/content/FiltersProvider';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';
import subHeader from '@app/partials/HoC/subHeader';

import { GROUPS } from '../helpers/StockRules';
import StockPriceTag from '../modals/StockPriceTag';

function StockVehiclesSubHeader({ subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle }) {
    const { watch } = useFormContext();

    const { vehiclesSelected } = useSelector((state) => state.stock);
    const isContextEFB = useSelector((state) => state.auth.userData.context === 'efb');
    const vehiclesList = Object.keys(vehiclesSelected).filter((v) => vehiclesSelected[v] !== undefined);
    const disableListingActions = vehiclesList?.length < 1 || vehiclesList?.length > 25;

    const [showPriceTagModal, setShowPriceTagModal] = useModal(false, 'price_tag');
    const perimeter = watch('perimeter');
    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: 'MENU.STOCK.DASHBOARD' }),
        },
    ];

    const { updateFilters, filters } = useFilters({
        name: 'stock_vehicle',
        initialize: false,
    });

    const groups = GROUPS?.map?.((group) => {
        const hasPerimeter = perimeter?.find?.((perimeterItem) => perimeterItem?.id === group?.id);

        if (hasPerimeter) {
            group.key = hasPerimeter?.title;
            return group;
        }

        return null;
    })?.filter?.((group) => !!group);

    useEffect(() => {
        setShowPriceTagModal(false);
    }, [vehiclesSelected]);

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            type="button"
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}

                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">{Intl.formatMessage({ id: 'MENU.STOCK' })}</h3>
                                <BreadCrumbs items={breadcrumb} />
                            </>
                        )}
                    </LayoutContextConsumer>
                    {groups?.length > 0 && (
                        <FiltersProvider value={filters}>
                            <div className="ml-2">
                                <ButtonsFilter
                                    name="group_internal_id"
                                    values={groups}
                                    onChange={updateFilters}
                                    defaultValue={groups[0]}
                                    unCheckable={false}
                                />
                            </div>
                        </FiltersProvider>
                    )}
                </div>
                {!isContextEFB && (
                    <div className="kt-subheader__toolbar">
                        <OverlayTooltip
                            label={Intl.formatMessage({ id: 'MENU.STOCK.PRICE_TAG.HOVER' })}
                            position="bottom"
                        >
                            <span>
                                <Button
                                    className="p-2 px-3"
                                    variant="primary"
                                    disabled={disableListingActions}
                                    onClick={() => setShowPriceTagModal(true)}
                                >
                                    <i className="p-1 las la-print" />
                                    <FormattedHTMLMessage id="MENU.STOCK.PRICE_TAG" />
                                </Button>
                            </span>
                        </OverlayTooltip>
                    </div>
                )}
            </div>
            {!isContextEFB && <StockPriceTag showModal={showPriceTagModal} setShowModal={setShowPriceTagModal} />}
        </div>
    );
}

export default subHeader(StockVehiclesSubHeader);
