import { Order } from '@app/crud/apv/order.type';

import ROUTES from '@app/router/Routes';

/**
 * Get state color by order slug
 *
 * @param order
 * @returns {string}
 */
export function getStateBySlug(order: Order) {
    let variant = '';

    switch (order.orderStatusApvInStore?.slug) {
        case 'draft':
        case 'closed_lose':
            variant = 'info';
            break;
        case 'waiting_for_customer':
            variant = 'primary';
            break;
        case 'closed_win':
            variant = 'success';
            break;
        default:
            variant = 'info';
            break;
    }

    return variant;
}

/**
 * Get state color by order slug
 *
 * @param order
 * @returns {string}
 */
export function getFontColorBySlug(order: Order) {
    let color = '';

    switch (order.orderStatusApvInStore?.slug) {
        case 'draft':
        case 'closed_lose':
            color = '#9747FF';
            break;
        case 'waiting_for_customer':
            color = '#15B7FF';
            break;
        case 'closed_win':
            color = '#12BEA4';
            break;
        default:
            color = '#9747FF';
            break;
    }

    return color;
}

/**
 * Get destination route based on state
 *
 * @param order
 * @returns {object | null}
 */
export function getDestinationRoute(order: Order) {
    let destination = null;
    if (['draft', 'waiting_for_customer'].includes(order?.orderStatusApvInStore?.slug)) {
        if (order.automanagerId) {
            destination = { path: ROUTES.CUSTOMER.PATH, params: { id: order.automanagerId } };
        }
    } else if (order?.orderStatusApvInStore?.slug === 'closed_win') {
        destination = { path: ROUTES.APV_INSTORE_DETAIL.PATH, params: { id: order.id } };
    }
    return destination;
}
