import { Alert } from '@app/pages/administration/alerts/alerts.type';

export const getSeenAlerts = () => {
    const alertsSeen = localStorage.getItem('alertsSeen');
    return alertsSeen ? JSON.parse(alertsSeen) : [];
};

export const setSeenAlerts = (ids: string) => {
    localStorage.setItem('alertsSeen', ids);
};

export const addSeenAlert = (id: string) => {
    const seenAlerts = getSeenAlerts();

    if (!seenAlerts.includes(id)) seenAlerts.push(id);

    localStorage.setItem('alertsSeen', JSON.stringify(seenAlerts));
};

export const checkForExpiredAlerts = (currentAlerts: Alert[]) => {
    const seenAlerts = getSeenAlerts();

    const onGoingAlerts = currentAlerts
        .filter((item) => seenAlerts.includes(item.id.toString()))
        .map((item) => item.id.toString());

    localStorage.setItem('alertsSeen', JSON.stringify(onGoingAlerts));
};
