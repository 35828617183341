import { useIntl } from 'react-intl';

import { Lead } from '@app/store/customers/customers.store';

interface SourceClassMap {
    [key: string]: string;
}
interface sourceFunctions {
    [key: string]: (event?: Event) => string;
}

const useSourceBodyTemplate = () => {
    const Intl = useIntl();
    const sourceClassMap: SourceClassMap = {
        afr_financing: 'marketing',
    };
    const getSourceClassName = (event: Lead) => {
        const eventType = event?.leadType?.slug;
        const classNameFromMap = sourceClassMap[eventType];
        return classNameFromMap || event?.leadSource?.slug || '';
    };

    const sourceTemplate: sourceFunctions = {
        afr_financing: () => Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.MARKET.CAMPAIGN' }),
    };

    // Function to retrieve the source name or custome name template
    const getSourceTemplate = (event: Lead) => {
        const eventType = event?.leadType?.slug;
        const templateResult = sourceTemplate[eventType] ? sourceTemplate[eventType]() : event?.leadSource?.name || '-';
        return templateResult;
    };

    return { getSourceClassName, getSourceTemplate };
};

export default useSourceBodyTemplate;
