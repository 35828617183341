import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';
import type { FusionPayload } from '@app/crud/customers/FusionPayload.type';

const { REACT_APP_LEAD_URL } = process.env;

export interface FusionResult {
    contactId: number;
}
export function ContactFusion(data: FusionPayload) {
    return ApiGateway.post<ApiResponse<FusionResult>, CustomAxiosResponse<ApiResponse<FusionResult>>>(
        `${REACT_APP_LEAD_URL}/contact/fusion`,
        { ...data },
    );
}
