import React, { useEffect, useState } from 'react';
import { Image, Row } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

const ActionHandBand = ({ type, isHistory, loading }) => {
    const [category, setCategory] = useState(0);

    useEffect(() => {
        if (!loading) {
            setCategory(isHistory ? 1 : 0);
        }
    }, [loading]);

    return (
        <div className="customer-handband my-lg-0 my-2">
            <Row className="font-size-h4 text-white headband__content">
                <Image
                    className="customer-handband__pic ml-5"
                    src="/media/pages/customers/fiche-client-aucune-activite-en-cours.svg"
                />
                {category === 1 ? (
                    <div className="ml-5 ">
                        <FormattedHTMLMessage id="STOCK.ACTION.HEADER_HISTORY" values={{ number: type?.total }} />
                    </div>
                ) : (
                    // eslint-disable-next-line react/no-danger
                    <div className="ml-5 border-bottom" dangerouslySetInnerHTML={{ __html: type?.banner }} />
                )}
            </Row>
            <Row className="font-size-h4 text-white headband__content headband__content--scroll">
                <Image
                    className="customer-handband__pic--small ml-5"
                    src="/media/pages/customers/fiche-client-aucune-activite-en-cours.svg"
                />
                {/* eslint-disable-next-line react/no-danger */}
                <div className="ml-lg-5 ml-0" dangerouslySetInnerHTML={{ __html: type?.banner }} />
            </Row>
        </div>
    );
};

export default ActionHandBand;
