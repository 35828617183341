import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import useFetch from '@app/hooks/useFetch';

import { getDashboardAdvancement } from '@app/crud/dashboard/dashboard.crud';

import { DashboardContext } from '../DashboardProvider';
import { getTransformation } from '../helpers/DashboardHelper';

const DashboardProgress = ({ className }) => {
    const { overriddenParams } = useContext(DashboardContext);
    const { data, loading, fetch } = useFetch({
        fetchAction: getDashboardAdvancement,
    });

    useEffect(() => {
        if (overriddenParams) {
            fetch({ params: overriddenParams });
        }
    }, [overriddenParams]);

    if (loading) {
        return <DashboardProgress.Skeleton />;
    }
    const progress = data?.total ? getTransformation(data?.done, 0, data?.total) : 100;

    const colors = {
        100: '#0ABB87',
        50: '#FFB822',
        0: '#FF4D4D',
    };
    const getBackGroundColor = (backgroundProgress) => {
        const color = Object.entries(colors).find(([percentage]) => percentage <= backgroundProgress);

        return color[1];
    };

    return (
        <div className={classNames('dashboard-progress w-100', className)}>
            <div className="flex-grow-1">
                <div className="d-flex">
                    <div className="flex-grow-1">
                        <div className="d-flex align-items-center">
                            <i className="dashboard-progress__icon las la-2x la-rocket mr-2" />
                            <span className="font-size-lg font-weight-bold">
                                <FormattedMessage id="DASHBOARD.PROGRESS.TITLE" />
                            </span>
                        </div>
                        <div>
                            <ProgressBar className="dashboard-progress__bar">
                                <ProgressBar style={{ background: getBackGroundColor(progress) }} now={progress} />
                            </ProgressBar>
                        </div>
                    </div>
                    <div
                        className={`dashboard-progress__title font-weight-semibold mt-3 ${
                            data?.done === 0 ? 'text-danger' : ''
                        }`}
                    >
                        <NumberFormat value={progress} displayType="text" suffix="&nbsp;%" />
                    </div>
                </div>
                <div className="font-weight-normal d-flex align-items-center flex-wrap">
                    <FormattedMessage id="DASHBOARD.PROGRESS.COMPUTE" />
                    &nbsp;
                    <div className={`text-${data?.done > 0 ? 'success' : 'danger'}`}>
                        <FormattedMessage
                            id="DASHBOARD.PROGRESS.DONE"
                            values={{
                                number: data?.done,
                            }}
                        />
                    </div>
                    &nbsp;
                    <FormattedMessage id="TRANSLATOR.ON" />
                    &nbsp;
                    <div className="text-warning">
                        <FormattedMessage
                            id="DASHBOARD.PROGRESS.TOTAL"
                            values={{
                                number: data?.total,
                            }}
                        />
                    </div>
                    &nbsp;
                    <FormattedMessage id="DASHBOARD.PROGRESS.TODAY" />
                </div>
            </div>
        </div>
    );
};

DashboardProgress.Skeleton = () => <Skeleton borderRadius="10px" className=" p-15" />;
export default DashboardProgress;
