import React, { FC } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

interface ButtonWithLoaderProps extends ButtonProps {
    loading: boolean;
}
const ButtonWithLoader: FC<ButtonWithLoaderProps> = ({ loading, disabled, children, ...props }) => (
    <Button disabled={disabled || loading} {...props}>
        {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />}
        {children}
    </Button>
);

export default ButtonWithLoader;
