import React from "react";
import {Button} from "react-bootstrap";
import clsx from "clsx";
import "./ButtonCounter.scss";
import {ButtonProps} from "react-bootstrap";

interface ButtonCounterProps extends ButtonProps {
    counter?: number;
    hideIfZero?: boolean;
}
const ButtonCounter = (props: ButtonCounterProps) => {
    const {counter = 0, hideIfZero = true, className, ...rest} = props;

    return <Button
        className={clsx("btn-counter", className)}
        {...rest}
    >
        {(counter > 0 || !hideIfZero) && <span className="btn-counter__mark">{counter}</span>}
        {props.children}
    </Button>
}

export default ButtonCounter;
