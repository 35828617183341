import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { removeDuplicate } from '@app/helpers/ArrayHelper';
import { CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { getBrands, getModels, getVersions } from '@app/crud/customers/stock.crud';
import { Brand, Model, Versions } from '@app/crud/customers/stock.type';

import useFetch from '../../hooks/useFetch';
import ErrorForm from '../layout/ErrorForm';

type BrandModelVersionFinishProps = {
    onLoading: Function;
    onBrandUpdated?: Function;
    onModelUpdated?: Function;
    onVersionUpdated?: Function;
    onBrandsUpdated?: Function;
    onModelsUpdated?: Function;
    onVersionsUpdated?: Function;
    defaultsValues?: {
        make: string;
        model: string;
        version: string;
        typeVarVersPrf: string;
        dateFirstRegistration: string;
    };
    editableModel?: boolean;
};
const BrandModelVersionFinish = ({
    onLoading,
    onBrandUpdated,
    onModelUpdated,
    onVersionUpdated,
    onBrandsUpdated,
    onModelsUpdated,
    onVersionsUpdated,
    defaultsValues,
}: BrandModelVersionFinishProps) => {
    const { register, setValue, errors, control, getValues } = useFormContext();
    const [selectedModel, setSelectedModel] = useState(defaultsValues?.model || '');
    const defaultFieldsValues = useRef<{
        make: string;
        model: string;
        version: string;
        typeVarVersPrf: string;
        dateFirstRegistration: string;
    }>({ ...defaultsValues });
    const intl = useIntl();

    const {
        fetch: fetchBrands,
        data: brands,
        loading: loadingBrands,
    } = useFetch<void, Brand[]>({
        // @ts-ignore
        fetchAction: getBrands,
        resultInterceptor: (response: CustomAxiosResponse<Brand[]>) => {
            const result = (response ? removeDuplicate(response, 'MakeCode') : []) as Brand[];

            if (onBrandsUpdated) {
                onBrandsUpdated(result);
            }

            if (defaultFieldsValues.current.make) {
                const brand = result?.find((brand) => brand.Name === defaultFieldsValues.current.make);

                if (brand) {
                    const date = getValues().dateFirstRegistration;

                    fetchModels({
                        makeCode: brand.MakeCode,
                        month: date ? date.split('-')[1] : null,
                        year: date ? date.split('-')[0] : null,
                    });
                }

                defaultFieldsValues.current.make = '';
            }

            return result;
        },
    });
    const {
        fetch: fetchModels,
        data: models,
        loading: loadingModels,
    } = useFetch<{ makeCode: string; month?: string; year?: string }, Model[]>({
        // @ts-ignore
        fetchAction: getModels,
        resultInterceptor: (response: CustomAxiosResponse<Model[]>) => {
            const result: Model[] = response ? (removeDuplicate(response, 'Name') as Model[]) : [];
            result.sort((a, b) => a?.Name?.localeCompare(b?.Name));
            if (onModelsUpdated) {
                onModelsUpdated(result);
            }

            if (defaultFieldsValues.current.model) {
                const model = result?.find((model) => model.Name === defaultFieldsValues.current.model);

                if (model) {
                    const date = getValues().dateFirstRegistration;
                    fetchVersions({
                        vehicleTypeCode: parseInt(model.VehicleTypeCode),
                        makeCode: parseInt(model.MakeCode),
                        modelCode: parseInt(model.ModelCode),
                        month: date ? date.split('-')[1] : null,
                        year: date ? date.split('-')[0] : null,
                    });
                    defaultFieldsValues.current.model = '';
                } else {
                    setValue('model', intl.formatMessage({ id: 'CUSTOMERS.MODAL.PART_EXCHANGE.NONE' }));
                }
            }

            return result;
        },
    });
    const {
        fetch: fetchVersions,
        data: versions,
        loading: loadingVersions,
    } = useFetch<
        { vehicleTypeCode: number; makeCode: number; modelCode: number; month: string; year: string },
        Versions
    >({
        // @ts-ignore
        fetchAction: getVersions,
        resultInterceptor: (response: CustomAxiosResponse<Versions>) => {
            if (onVersionsUpdated) {
                onVersionsUpdated(response || []);
            }

            if (defaultFieldsValues.current.version) {
                defaultFieldsValues.current.version = '';
            }

            return response || [];
        },
    });

    const isOtherModel = (modelName: string) =>
        !models.some((model) => model.Name === modelName) ||
        modelName === intl.formatMessage({ id: 'CUSTOMERS.MODAL.PART_EXCHANGE.NONE' });

    useEffect(() => {
        fetchBrands();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        defaultFieldsValues.current = defaultsValues;
        fetchBrands();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultsValues.make, defaultsValues.model, defaultsValues.version, getValues().dateFirstRegistration]);

    useEffect(() => {
        onLoading(loadingBrands || loadingModels || loadingVersions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingBrands, loadingModels, loadingVersions]);

    const onBrandChange = (e: any) => {
        if (onBrandUpdated) {
            onBrandUpdated(e);
            return;
        }

        const brand = brands?.find((brand) => brand.Name === e.value);

        if (brand) {
            const date = getValues().dateFirstRegistration;
            fetchModels({
                makeCode: brand.MakeCode,
                month: date ? date.split('-')[1] : null,
                year: date ? date.split('-')[0] : null,
            });
        }

        setValue('model', '');
    };

    const onModelChange = (e: any) => {
        if (onModelUpdated) {
            onModelUpdated(e);
            return;
        }

        if (!isOtherModel(e.value)) {
            const model = models?.find((model) => model.Name === e.value);

            if (model) {
                const date = getValues().dateFirstRegistration;
                fetchVersions({
                    vehicleTypeCode: parseInt(model.VehicleTypeCode),
                    makeCode: parseInt(model.MakeCode),
                    modelCode: parseInt(model.ModelCode),
                    month: date ? date.split('-')[1] : null,
                    year: date ? date.split('-')[0] : null,
                });
            }
        }

        setValue('version', '');
        setSelectedModel(e.value);
    };

    const onVersionChange = (e: any) => {
        if (onVersionUpdated) {
            onVersionUpdated(e);
        }
    };

    return (
        <div>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.BRAND" />
                </Form.Label>
                <Controller
                    control={control}
                    name="make"
                    defaultValue=""
                    rules={{ required: true }}
                    render={(props) => (
                        <Dropdown
                            value={props.value}
                            options={brands.map((brand) => ({
                                value: brand.Name,
                                label: brand.Name,
                            }))}
                            onChange={(e) => {
                                props.onChange(e.value);
                                onBrandChange(e);
                            }}
                            optionLabel="label"
                            optionValue="value"
                            placeholder={intl.formatMessage({ id: 'CUSTOMERS.MODAL.APE_DROPDOWN.TITLE' })}
                        />
                    )}
                />
                <ErrorForm errors={errors} name="make" />
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.VERSION" />
                </Form.Label>
                <Form.Control
                    type="text"
                    name="typeVarVersPrf"
                    defaultValue={defaultsValues.typeVarVersPrf}
                    ref={register}
                />
                <ErrorForm errors={errors} name="typeVarVersPrf" />
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.MODEL" />
                </Form.Label>
                <Controller
                    control={control}
                    name="model"
                    defaultValue=""
                    rules={{ required: true }}
                    render={(props) => (
                        <Dropdown
                            value={props.value}
                            options={models.map((model) => ({
                                value: model.Name,
                                label: model.Name,
                            }))}
                            onChange={(e) => {
                                props.onChange(e.value);
                                onModelChange(e);
                            }}
                            editable
                            optionLabel="label"
                            optionValue="value"
                            placeholder={intl.formatMessage({ id: 'CUSTOMERS.MODAL.APE_DROPDOWN.TITLE' })}
                        />
                    )}
                />
                <ErrorForm errors={errors} name="model" />
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.FINISH" />
                </Form.Label>
                <Controller
                    control={control}
                    name="version"
                    rules={{ required: !isOtherModel(getValues().model) }}
                    render={(props) => (
                        <Dropdown
                            value={props.value}
                            // @ts-ignore
                            options={versions?.version?.map((version) => ({
                                value: version.Name + (version.TrimLineName ? ` ${version.TrimLineName}` : ''),
                                label: version.Name + (version.TrimLineName ? ` ${version.TrimLineName}` : ''),
                            }))}
                            disabled={isOtherModel(getValues().model)}
                            onChange={(e) => {
                                props.onChange(e.value);
                                onVersionChange(e);
                            }}
                            optionLabel="label"
                            optionValue="value"
                            placeholder={intl.formatMessage({ id: 'CUSTOMERS.MODAL.APE_DROPDOWN.TITLE' })}
                        />
                    )}
                />
            </Form.Group>
        </div>
    );
};

export default BrandModelVersionFinish;
