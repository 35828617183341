import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { PartExchange } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

type InfosPartExchangeProps = {
    partExchange: PartExchange;
};
const InfosPartExchange = ({ partExchange }: InfosPartExchangeProps) => {
    const intl = useIntl();

    return (
        <>
            <RowInfo
                label={intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.ESTIMATE_DATE' })}
                value={
                    partExchange?.estimationDate && partExchange?.estimatedPrice
                        ? dayjs(partExchange?.estimationDate).format('DD/MM/YYYY')
                        : '-'
                }
            />
            <RowInfo
                label={intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.REFERENCE' })}
                value={partExchange?.partExchangeReference || '-'}
            />
            <RowInfo
                label={intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.VEHICLE' })}
                value={partExchange?.model ? `${partExchange?.make} ${partExchange.model}` : '-'}
            />
            <RowInfo
                label={intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.YEAR' })}
                value={
                    partExchange?.dateFirstRegistration
                        ? dayjs(partExchange?.dateFirstRegistration).format('YYYY')
                        : '-'
                }
            />
            <RowInfo
                label={intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.MILEAGE' })}
                value={
                    partExchange?.mileage ? (
                        <NumberFormat
                            value={partExchange?.mileage}
                            thousandSeparator=" "
                            displayType="text"
                            suffix=" km"
                        />
                    ) : (
                        '-'
                    )
                }
            />
            <RowInfo
                label={intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.FUEL' })}
                value={partExchange?.fuelType || '-'}
            />
            <RowInfo
                label={intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.REGISTRATION' })}
                value={partExchange?.registration || '-'}
            />
            <RowInfo
                label={intl.formatMessage({ id: 'VEHICLE_INFO.VIEW.LIFE.DETAIL.RECOVERY.ESTIMATE' })}
                value={
                    partExchange?.estimatedPrice ? (
                        <>
                            <NumberFormat
                                value={partExchange.estimatedPrice + (partExchange?.estimatedPriceRebate || 0)}
                                decimalSeparator="."
                                thousandSeparator=" "
                                displayType="text"
                                suffix=" €"
                            />
                            {partExchange?.estimatedPriceRebate !== 0 &&
                                partExchange?.estimatedPriceRebate !== null && (
                                    <>
                                        &nbsp;
                                        <FormattedMessage
                                            id="VEHICLE_INFO.VIEW.LIFE.DETAIL.RECOVERY.ESTIMATE.REBATE"
                                            values={{
                                                rebate: (
                                                    <NumberFormat
                                                        value={partExchange?.estimatedPriceRebate}
                                                        decimalSeparator="."
                                                        thousandSeparator=" "
                                                        displayType="text"
                                                        suffix=" €"
                                                    />
                                                ),
                                            }}
                                        />
                                    </>
                                )}
                        </>
                    ) : (
                        '-'
                    )
                }
            />
        </>
    );
};

export default InfosPartExchange;
