import Swal from 'sweetalert2';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { routeTo } from '@app/helpers/RoutesHelper';

import { addPrice, deletePrice, getPrice, getPrices, updatePriceById } from '@app/crud/smartRepair/smart-repair.crud';

import ROUTES from '@app/router/Routes';

import toast from '@app/partials/content/Toast';

import { actions } from './package.store';

export function fetchPackages(params) {
    return (dispatch) => {
        dispatch(actions.fetchPackages());

        getPrices(params)
            .then((response) => {
                if (!response?.result?.error) {
                    const { result } = response;
                    result.packages = result.prices;
                    dispatch(
                        actions.fetchedPackages({
                            ...result,
                            count: response?.count || 0,
                        }),
                    );
                } else {
                    dispatch(actions.fetchPackagesFailed({}));
                }
            })
            .catch(() => {
                dispatch(actions.fetchPackagesFailed({}));
            });
    };
}

export function fetchPackage(id) {
    return (dispatch) => {
        dispatch(actions.fetchPackage());

        if (id) {
            getPrice(id)
                .then((value) => {
                    const packageItem = value || null;
                    if (packageItem?.result?.error && id) {
                        dispatch(actions.fetchPackageFailed(packageItem.result));
                    } else {
                        dispatch(
                            actions.fetchedPackage({
                                package: packageItem?.result || [],
                            }),
                        );
                    }
                })
                .catch(() => {
                    dispatch(actions.fetchPackageFailed({}));
                });
        }
    };
}

export function createPackage(params) {
    return (dispatch) => {
        dispatch(actions.addPackage());

        addPrice(params.data)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(
                        actions.addedPackage({
                            id: response.result.id,
                            ...params.data,
                        }),
                    );
                    params.history.push(
                        routeTo(ROUTES.PACKAGE_SMARTREPAIR.PATH.UPDATE, {
                            id: response.result.id,
                            ...params.data,
                        }),
                    );
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.ADD.SUCCESS' }),
                    });
                } else {
                    dispatch(actions.addPackageFailed(response.result));
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.ADD.FAILED' }),
                    });
                }
            })
            .catch(() => {
                dispatch(actions.addPackageFailed({}));
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.ADD.FAILED' }),
                });
            });
    };
}

export function updatePackage(id, data) {
    return (dispatch) => {
        dispatch(actions.updatePackage());

        updatePriceById(id, data)
            .then((response) => {
                if (!response?.result?.error) {
                    const result = {
                        name: data.name,
                        active: data.active,
                        slug: data.slug,
                        priceTtc: data.priceTtc,
                        zoneBody: data.zoneBody.map((item) => ({ slug: item })),
                        mecaplanningAction: data.mecaplanningAction,
                    };

                    dispatch(actions.updatedPackage(result));
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.UPDATE.SUCCESS' }),
                    });
                } else {
                    dispatch(actions.updatePackageFailed(response.result));
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.UPDATE.FAILED' }),
                    });
                }
            })
            .catch(() => {
                dispatch(actions.updatePackageFailed({}));
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.UPDATE.FAILED' }),
                });
            });
    };
}

export function removePackage(params) {
    return (dispatch) => {
        Swal.fire({
            title: Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.REMOVE.TITLE' }),
            text: Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.REMOVE.TEXT' }),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
            cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
            customClass: {
                confirmButton: 'btn mr-3 btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            preConfirm: () => {
                Swal.showLoading();
                dispatch(actions.removePackage());

                return deletePrice(params.id)
                    .then((response) => {
                        if (!response?.result?.error) {
                            params.history.push(routeTo(ROUTES.PACKAGES.PATH.TYPE, { type: 'smartrepair' }));
                            toast({
                                variant: 'success',
                                message: Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.REMOVE.SUCCESS' }),
                            });
                        } else {
                            toast({
                                variant: 'danger',
                                message: Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.REMOVE.FAILED' }),
                            });
                        }
                    })
                    .catch(() => {
                        toast({
                            variant: 'danger',
                            message: Intl.formatMessage({ id: 'SMARTREPAIR.PACKAGE.REMOVE.FAILED' }),
                        });
                    });
            },
        });
    };
}
