import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import { EventDetailCommercialProposalType } from '@app/crud/customers/customer.type';

import Price from '@app/components/formatters/price/Price';
import { Col, Row } from 'react-bootstrap';
import CommercialProposalStatus from '../LifeDatatable/LifeDateTableRender/StatusTemplate/CommercialProposalStatus';

type DetailCommercialProposalOrderProps = {
    event: EventDetailCommercialProposalType;
};

const DetailCommercialProposalOrder = ({ event }: DetailCommercialProposalOrderProps) => {
    const { commercialProposal } = event;

    let upsalesTotal = 0;

    commercialProposal?.upsales?.map((upsale) => {
        const v = parseFloat(upsale.value);

        if (!isNaN(v)) {
            upsalesTotal += v;
        }
    });

    return (
        <>
            <div className="d-flex">
                <div className="flex-fill">
                    <Row className="mb-2 align-items-start">
                        <Col xs={5}>
                            <span className="kt-shape-font-color-4">
                                <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.ORDER.ID" />
                            </span>
                        </Col>
                        <Col xs={7}>
                            <span className={`font-weight-bold`}>{commercialProposal?.id || '-'}</span>
                            <a
                                href={`https://www.pvo2.fr/unitedsheetorder/purchaseorder/pga_${commercialProposal?.id}`}
                                target="_blank"
                                className="ml-1"
                            >
                                <u>
                                    <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.ORDER.LINK" />
                                </u>
                            </a>
                        </Col>
                    </Row>
                    <Row className="mb-2 align-items-start">
                        <Col xs={5}>
                            <span className="kt-shape-font-color-4">
                                <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.ORDER.NUMBER" />
                            </span>
                        </Col>
                        <Col xs={7}>
                            <span className={`font-weight-bold`}>{commercialProposal?.number || '-'}</span>
                        </Col>
                    </Row>
                    <Row className="mb-2 align-items-start">
                        <Col xs={5}>
                            <span className="kt-shape-font-color-4">
                                <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.ORDER.STATUS" />
                            </span>
                        </Col>
                        <Col xs={7}>
                            <CommercialProposalStatus commercialProposal={commercialProposal} />
                        </Col>
                    </Row>
                    <Row className="mb-2 align-items-start">
                        <Col xs={5}>
                            <span className="kt-shape-font-color-4">
                                <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.ORDER.PRICE" />
                            </span>
                        </Col>
                        <Col xs={7}>
                            <span className={`font-weight-bold`}>
                                <Price value={commercialProposal?.price ?? '-'} />
                            </span>
                        </Col>
                    </Row>
                </div>
                <div className="flex-fill">
                    <Row className="mb-2 align-items-start">
                        <Col xs={5}>
                            <span className="kt-shape-font-color-4">
                                <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.ORDER.USER" />
                            </span>
                        </Col>
                        <Col xs={7}>
                            <span className={`font-weight-bold`}>{commercialProposal?.affectedUserLabel || '-'}</span>
                        </Col>
                    </Row>
                    <Row className="mb-2 align-items-start">
                        <Col xs={5}>
                            <span className="kt-shape-font-color-4">
                                <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.ORDER.COMMENT" />
                            </span>
                        </Col>
                        <Col xs={7}>
                            <span className={`font-weight-bold`}>{commercialProposal?.comment || '-'}</span>
                        </Col>
                    </Row>
                    {commercialProposal?.closedDate && (
                        <Row className="mb-2 align-items-start">
                            <Col xs={5}>
                                <span className="kt-shape-font-color-4">
                                    <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.ORDER.CLOSED_AT" />
                                </span>
                            </Col>
                            <Col xs={7}>
                                <span className={`font-weight-bold`}>
                                    {commercialProposal?.closedDate?.format('DD/MM/YY') || '-'}
                                </span>
                            </Col>
                        </Row>
                    )}
                    {commercialProposal?.cancellationDate && (
                        <Row className="mb-2 align-items-start">
                            <Col xs={5}>
                                <span className="kt-shape-font-color-4">
                                    <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.ORDER.CANCELED_AT" />
                                </span>
                            </Col>
                            <Col xs={7}>
                                <span className={`font-weight-bold`}>
                                    {commercialProposal?.cancellationDate?.format('DD/MM/YY') || '-'}
                                </span>
                            </Col>
                        </Row>
                    )}
                    {commercialProposal?.cancellationCause && (
                        <Row className="mb-2 align-items-start">
                            <Col xs={5}>
                                <span className="kt-shape-font-color-4">
                                    <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.ORDER.CANCELED_CAUSE" />
                                </span>
                            </Col>
                            <Col xs={7}>
                                <span className={`font-weight-bold`}>
                                    <FormattedHTMLMessage
                                        id={`CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.ORDER.CANCELED_CAUSE.${commercialProposal?.cancellationCause}`}
                                    />
                                </span>
                            </Col>
                        </Row>
                    )}
                    {commercialProposal?.orderDeliveryDate && (
                        <Row className="mb-2 align-items-start">
                            <Col xs={5}>
                                <span className="kt-shape-font-color-4">
                                    <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.ORDER.DELIVERED_AT" />
                                </span>
                            </Col>
                            <Col xs={7}>
                                <span className={`font-weight-bold`}>
                                    {commercialProposal?.orderDeliveryDate?.format('DD/MM/YY') || '-'}
                                </span>
                            </Col>
                        </Row>
                    )}
                </div>
                <div className="flex-fill">
                    {upsalesTotal > 0 && (
                        <>
                            <Row className="mb-2 align-items-start">
                                <Col xs={5}>
                                    <span className="kt-shape-font-color-4">
                                        <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.ORDER.UPSALES" />
                                    </span>
                                </Col>
                                <Col xs={7}>
                                    <div className="d-flex flex-column">
                                        {commercialProposal?.upsales?.map((upsale) => (
                                            <span className={`font-weight-bold`}>
                                                {upsale.label} (<Price value={upsale.value} />)
                                            </span>
                                        )) || '-'}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-2 align-items-start">
                                <Col xs={5}>
                                    <span className="kt-shape-font-color-4">
                                        <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.ORDER.UPSALES.TOTAL" />
                                    </span>
                                </Col>
                                <Col xs={7}>
                                    <span className={`font-weight-bold`}>
                                        <Price value={upsalesTotal} />
                                    </span>
                                </Col>
                            </Row>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default DetailCommercialProposalOrder;
