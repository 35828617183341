import React from 'react';

type Props = {
    name?: string;
    image?: string;
};

const DefaultWipTemplate = ({ name, image }: Props) => (
    <div className="dashboard-wip d-flex flex-column">
        <div className="dashboard-actions h-100 d-flex flex-column">
            <div className="w-100 h-100 d-flex flex-column">
                <div className="dashboard-wip__container mt-8 mt-xxl-0">
                    <div className="dashboard-wip__content">
                        <img src={`/media/pages/dashboard/${image}`} alt="" className="dashboard-wip__img" />
                        <p className="dashboard-wip__title text-center mt-10 font-weight-bold">
                            {`Votre dashboard dédié ${name} arrive très bientôt !`}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default DefaultWipTemplate;

DefaultWipTemplate.defaultProps = {
    name: 'APV',
    image: 'dashboard-is-coming.svg',
};
