import { Dropdown, DropdownProps } from 'primereact/dropdown';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useVendors from '@app/hooks/useVendors';

import { alphabeticalSort } from '@app/helpers/ArrayHelper';
import { apvRoleReceiver } from '@app/helpers/RoleHelper';

import { getShortRole } from '@app/pages/dashboard/helpers/DashboardHelper';
import { LeadApvRequestTypes } from '@app/store/lead/lead-apv.store';
import { User } from '@app/store/login/login.store';
import { RootState } from '@app/store/rootDuck';

import BrandModelVersion from '@app/partials/content/BrandModelVersion';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import SwitchInput from '@app/partials/content/SwitchInput';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

import useAction from '../Hooks/useAction';

type APVRequestProps = {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
};

type OptionType = {
    title: string;
};

export type RBRef = string & ((ref: Element | null) => void);

type SubmittedProps = {
    default_dealership: string;
    lead_type: string;
    referent?:
        | {
              id: string | number;
              first_name: string;
              last_name: string;
          }
        | string;
};

const getActionSourceOptions = (role: string): string[] => {
    const autosphereContactOptions = ['autosphere.fr', 'Sites concessions'];
    const defaultOptions = ['Concession'];
    const adminOptions = [...defaultOptions, ...autosphereContactOptions];

    switch (role) {
        case 'manager-autosphere-contact':
        case 'agent-autosphere-contact':
            return autosphereContactOptions;
        case 'administrateur':
        case 'super-administrateur':
            return adminOptions;
        default:
            return defaultOptions;
    }
};

const APVRequest = ({ showModal, setShowModal }: APVRequestProps) => {
    const [_, setLoading] = useState(false);
    const { fetchVendors, vendors, loading: vendorLoading } = useVendors();
    const Intl = useIntl();
    const [dealerships, setDealerships] = useState([]);
    const [availableVendors, setAvailableVendors] = useState<User[]>([]);

    const [isMultipleVehicle, setIsMultipleVehicle] = useState(false);
    const [registrationUnavailable, setRegistrationUnavailable] = useState(false);
    const methods = useForm();
    const { register, errors, handleSubmit, control } = methods;
    const { userData } = useSelector((state: RootState) => state.auth);
    const [loading, createAction] = useAction({
        type: 'local_apv',
        success: () => {
            setShowModal(false);
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CONTACT.ERROR' }),
            });
        },
    });

    const selectedDealerShip = methods.watch('dealership');

    const servicesOptions = ['Mécanique', 'Carrosserie', 'Rapide'];

    const actionTypeOptions = LeadApvRequestTypes;

    const actionSourceOptions = getActionSourceOptions(userData?.role?.slug);

    const onSubmit = (data: SubmittedProps) => {
        delete data.default_dealership;
        data.lead_type = 'local_apv';

        if (typeof data.referent === 'object') {
            const refData = data.referent;

            data.referent = refData.id !== '' ? `${refData?.first_name} ${refData?.last_name}` : '';
        }

        // @ts-ignore
        createAction(data);
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const onPlateChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.value = e.target.value.toUpperCase();
    };

    useEffect(() => {
        if (vendors && !vendorLoading) {
            const restrictedVendors = vendors.filter((vendor) => apvRoleReceiver.includes(vendor?.role?.slug));
            setAvailableVendors(restrictedVendors);
        }
    }, [vendors]);

    useEffect(() => {
        if (selectedDealerShip) {
            fetchVendors(selectedDealerShip);
        }
    }, [selectedDealerShip]);

    useEffect(() => {
        setDealerships(
            userData?.dealerships &&
                Object.entries(userData.dealerships)
                    .sort(alphabeticalSort('title'))
                    .map(([code, dealership]) => ({
                        ...dealership,
                        code,
                    })),
        );
    }, [userData]);

    const multiVehicleLabel = Intl.formatMessage({
        id: 'CUSTOMERS.MODAL.CREATE_LOCAL_APV.VEHICLE_REGISTRATION.MULTI_VEHICLE',
    });
    const unavailableLabel = Intl.formatMessage({
        id: 'CUSTOMERS.MODAL.CREATE_LOCAL_APV.VEHICLE_REGISTRATION.UNAVAILABLE',
    });

    const selectedDealershipTemplate = (option: OptionType | null, propsC: DropdownProps) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div>{option.title}</div>
                </div>
            );
        }
        return <span>{propsC.placeholder}</span>;
    };

    /* eslint-disable jsx-a11y/label-has-associated-control */
    /* eslint-disable jsx-a11y/control-has-associated-label */
    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-pen" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE_LOCAL_APV.TITLE' })}
            body={
                <FormProvider {...methods}>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.DEALERSHIP' })}</Form.Label>
                        <Controller
                            control={control}
                            name="dealership"
                            rules={{
                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            }}
                            render={({ onChange, value }) => (
                                <Dropdown
                                    value={value}
                                    onChange={(e) => onChange(e.value)}
                                    options={dealerships}
                                    optionLabel="title"
                                    placeholder={Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.CREATE_LOCAL_APV.SELECT_DEFAULT',
                                    })}
                                    filter
                                    optionValue="code"
                                    required
                                    filterBy="title"
                                    valueTemplate={selectedDealershipTemplate}
                                />
                            )}
                        />
                        <ErrorForm errors={errors} name="dealership" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE_LOCAL_APV.CONTACT' })}
                        </Form.Label>
                        <Controller
                            control={control}
                            name="referent"
                            rules={{
                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            }}
                            render={({ onChange, value }) => (
                                <Dropdown
                                    value={value}
                                    onChange={(e) => onChange(e.value)}
                                    options={[
                                        {
                                            id: '',
                                            first_name: Intl.formatMessage({
                                                id: 'CUSTOMER.MODAL.ASSIGN.REFERENT.NONE',
                                            }),
                                            last_name: '',
                                            role: { shortName: '' },
                                        },
                                        ...availableVendors,
                                    ]}
                                    placeholder={Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.CREATE_LOCAL_APV.SELECT_DEFAULT',
                                    })}
                                    optionLabel="id"
                                    itemTemplate={(vendor) =>
                                        vendor.id !== ''
                                            ? `${vendor?.first_name} ${vendor?.last_name} (${
                                                  vendor?.role?.shortName ?? getShortRole(vendor)
                                              })`
                                            : vendor.first_name
                                    }
                                    valueTemplate={(vendor) => {
                                        if (!vendor) {
                                            return Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.CREATE_LOCAL_APV.SELECT_DEFAULT',
                                            });
                                        }

                                        return vendor.id !== ''
                                            ? `${vendor?.first_name} ${vendor?.last_name} (${
                                                  vendor?.role?.shortName ?? getShortRole(vendor)
                                              })`
                                            : vendor.first_name;
                                    }}
                                />
                            )}
                        />
                        <ErrorForm errors={errors} name="referent" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMERS.MODAL.CREATE_LOCAL_APV.VEHICLE_REGISTRATION.LABEL" />
                        </Form.Label>
                        <Form.Control
                            as="input"
                            ref={
                                register({
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }) as RBRef
                            }
                            name="vehicle_registration"
                            placeholder={Intl.formatMessage({
                                id: 'CUSTOMERS.MODAL.CREATE_LOCAL_APV.VEHICLE_REGISTRATION.PLACEHOLDER',
                            })}
                            onChange={onPlateChange}
                            readOnly={registrationUnavailable || isMultipleVehicle}
                            className={registrationUnavailable || isMultipleVehicle ? 'font-italic' : ''}
                        />
                        <div className="d-flex mt-3">
                            <label>
                                <input
                                    checked={isMultipleVehicle}
                                    type="checkbox"
                                    onClick={() => {
                                        methods.setValue(
                                            'vehicle_registration',
                                            methods.getValues('vehicle_registration') === multiVehicleLabel &&
                                                isMultipleVehicle
                                                ? ''
                                                : multiVehicleLabel,
                                        );
                                        setRegistrationUnavailable(false);
                                        setIsMultipleVehicle(!isMultipleVehicle);
                                    }}
                                />{' '}
                                {multiVehicleLabel}
                            </label>
                            <label className="ml-3">
                                <input
                                    checked={registrationUnavailable}
                                    type="checkbox"
                                    onClick={() => {
                                        methods.setValue(
                                            'vehicle_registration',
                                            methods.getValues('vehicle_registration') === unavailableLabel &&
                                                registrationUnavailable
                                                ? ''
                                                : unavailableLabel,
                                        );
                                        setIsMultipleVehicle(false);
                                        setRegistrationUnavailable(!registrationUnavailable);
                                    }}
                                />{' '}
                                {unavailableLabel}
                            </label>
                        </div>
                        <ErrorForm errors={errors} name="vehicle_registration" />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.VEHICLE.AT_DEALERSHIP" />
                        </Form.Label>
                        <SwitchInput
                            name="vehicleInDealership"
                            labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.NO' })}
                            labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.YES' })}
                        />
                    </Form.Group>

                    <BrandModelVersion
                        onLoading={setLoading}
                        brandLabel={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.PART_EXCHANGE.BRAND' })}
                        modelLabel={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.PART_EXCHANGE.MODEL' })}
                        modelRequired
                        noVersion
                        editableModel
                        defaultsValues={{ make: '', model: '', version: '', dateFirstRegistration: '' }}
                    />
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMERS.MODAL.CREATE_LOCAL_APV.SERVICE.LABEL" />
                        </Form.Label>
                        <Form.Control
                            as="select"
                            ref={
                                register({
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }) as RBRef
                            }
                            name="service"
                        >
                            {servicesOptions.map((service) => (
                                <option value={service} key={service}>
                                    {service}
                                </option>
                            ))}
                        </Form.Control>
                        <ErrorForm errors={errors} name="service" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMERS.MODAL.CREATE_LOCAL_APV.ACTION_TYPE.LABEL" />
                        </Form.Label>
                        <Form.Control
                            as="select"
                            ref={
                                register({
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }) as RBRef
                            }
                            name="action_type"
                        >
                            {actionTypeOptions.map((actionType) => (
                                <option value={actionType} key={actionType}>
                                    {actionType}
                                </option>
                            ))}
                        </Form.Control>
                        <ErrorForm errors={errors} name="action_type" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMERS.MODAL.CREATE_LOCAL_APV.SOURCE_TYPE.LABEL" />
                        </Form.Label>
                        <Form.Control
                            as="select"
                            readOnly={actionSourceOptions.length < 2}
                            ref={
                                register({
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }) as RBRef
                            }
                            className={actionSourceOptions.length < 2 ? ' bg-disabled' : ''}
                            name="action_source"
                        >
                            {actionSourceOptions.map((actionSource) => (
                                <option value={actionSource} key={actionSource}>
                                    {actionSource}
                                </option>
                            ))}
                        </Form.Control>
                        <ErrorForm errors={errors} name="action_source" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMERS.MODAL.CREATE_LOCAL_APV.COMMENT.LABEL" />
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            ref={
                                register({
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }) as RBRef
                            }
                            name="comment"
                            placeholder={Intl.formatMessage({
                                id: 'CUSTOMERS.MODAL.CREATE_LOCAL_APV.COMMENT.PLACEHOLDER',
                            })}
                            rows={4}
                        />
                        <ErrorForm errors={errors} name="comment" />
                    </Form.Group>
                </FormProvider>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(onSubmit, onError)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CREATE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default APVRequest;
