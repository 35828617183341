import ApiGateway from '@app/helpers/AxiosHelper';
import { getFormData } from '@app/helpers/ObjectHelper';

const { REACT_APP_APV_URL } = process.env;

export function getAdditionalServices(params) {
    return ApiGateway.get(`${REACT_APP_APV_URL}/additional/service/back`, {
        params,
    });
}

export function getAdditionalService(id) {
    return ApiGateway.get(`${REACT_APP_APV_URL}/additional/service/${id}`);
}

export function addAdditionalService(data) {
    return ApiGateway.post(`${REACT_APP_APV_URL}/additional/service`, getFormData(data));
}

export function updateAdditionalServiceById(id, data) {
    return ApiGateway.post(`${REACT_APP_APV_URL}/additional/service/${id}`, getFormData(data));
}

export function deleteAdditionalService(id) {
    return ApiGateway.delete(`${REACT_APP_APV_URL}/additional/service/${id}`);
}
