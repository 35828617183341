import { getOrder, getOrders } from '@app/crud/smartRepair/order.crud';

import { actions } from './order.store';

export function fetchOrders(params) {
    return (dispatch) => {
        dispatch(actions.fetchOrders());

        getOrders(params)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(
                        actions.fetchedOrders({
                            ...response.result,
                            orderType: params.orderType,
                        }),
                    );
                    dispatch(
                        actions.setOrdersTotalRecords({
                            in_progress:
                                response?.result?.filters?.orderAppointmentStatusCount.find(
                                    (item) => item.groupSlug === 'in_progress',
                                )?.count || 0,
                            closed:
                                response?.result?.filters.orderAppointmentStatusCount.find(
                                    (item) => item.groupSlug === 'closed',
                                )?.count || 0,
                        }),
                    );
                } else {
                    dispatch(actions.fetchOrdersFailed({}));
                }
            })
            .catch(() => {
                dispatch(actions.fetchOrdersFailed({}));
            });
    };
}

export function fetchOrder(id) {
    return (dispatch) => {
        dispatch(actions.fetchOrder());

        getOrder(id)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.fetchedOrder(response.result));
                } else {
                    dispatch(actions.fetchOrderFailed(response.result));
                }
            })
            .catch(() => {
                dispatch(actions.fetchOrderFailed({}));
            });
    };
}
