import { omit, reject } from 'lodash';

export const actionTypes = {
    FetchQuotations: 'FETCH_QUOTATIONS',
    FetchQuotation: 'FETCH_QUOTATION',
    FetchedQuotations: 'FETCHED_QUOTATIONS',
    FetchedQuotation: 'FETCHED_QUOTATION',
    FetchQuotationsFailed: 'FETCH_QUOTATION_FAILED',
    FetchQuotationFailed: 'FETCH_QUOTATION_FAILED',
    SetQuotationsTotalRecords: 'SET_QUOTATIONS_TOTALRECORDS',
    SetAcceptedQuotations: 'SET_ACCEPTED_QUOTATIONS',
    SetDeleteItem: 'SET_DELETE_QUOTATION',
    SetClearAcceptedQuotationsAndSelectedPackages: 'SET_CLEAR_ACCEPTED_QUOTATION',
    SetDeleteSelectedPackage: 'SET_DELETE_SELECTED_PACKAGE',
    SetSelectedPackages: 'SET_SELECTED_PACKAGES',
};

const initialState = {
    loading: false,
    orders: [],
    totalRecords: {
        to_be_appraised: 0,
        waiting_for_customer: 0,
        closed: 0,
    },
    filtersContent: [],
    order: null,
    acceptedQuotations: null,
    selectedPackages: [],
    error: null,
};

export function quotationReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FetchQuotations:
        case actionTypes.FetchQuotation: {
            return {
                ...state,
                loading: true,
                order: null,
                error: null,
            };
        }
        case actionTypes.FetchedQuotations: {
            const { orderType } = action.payload;

            return {
                ...state,
                loading: false,
                orders: action.payload.orders,
                totalRecords: {
                    ...state.totalRecords,
                    [orderType]: action.payload.count,
                },
                filtersContent: action.payload.filters,
            };
        }
        case actionTypes.FetchedQuotation: {
            return {
                ...state,
                loading: false,
                quotation: action.payload,
            };
        }
        case actionTypes.FetchQuotationFailed:
        case actionTypes.FetchQuotationsFailed: {
            return {
                ...state,
                loading: false,
                orders: [],
                error: action.payload.error,
            };
        }
        case actionTypes.SetQuotationsTotalRecords: {
            return {
                ...state,
                totalRecords: action.payload,
            };
        }
        case actionTypes.SetAcceptedQuotations: {
            return {
                ...state,
                acceptedQuotations: {
                    ...state.acceptedQuotations,
                    [action.payload[0].id]: action.payload,
                },
            };
        }
        case actionTypes.SetDeleteItem: {
            return {
                ...state,
                acceptedQuotations: omit(state.acceptedQuotations, action.payload),
            };
        }
        case actionTypes.SetClearAcceptedQuotationsAndSelectedPackages: {
            return {
                ...state,
                acceptedQuotations: {},
                selectedPackages: [],
            };
        }
        case actionTypes.SetSelectedPackages: {
            return {
                ...state,
                selectedPackages: action.payload,
            };
        }
        case actionTypes.SetDeleteSelectedPackage: {
            return {
                ...state,
                selectedPackages: reject(state.selectedPackages, { inputId: action.payload }),
            };
        }
        default:
            return state;
    }
}

export const actions = {
    fetchQuotations: () => ({ type: actionTypes.FetchQuotations }),
    fetchedQuotations: (orders) => ({ type: actionTypes.FetchedQuotations, payload: orders }),
    fetchQuotation: () => ({ type: actionTypes.FetchQuotation }),
    fetchedQuotation: (order) => ({ type: actionTypes.FetchedQuotation, payload: order }),
    fetchQuotationFailed: (error) => ({ type: actionTypes.FetchQuotationFailed, payload: error }),
    fetchQuotationsFailed: (error) => ({ type: actionTypes.FetchQuotationsFailed, payload: error }),
    setQuotationsTotalRecords: (totalRecords) => ({
        type: actionTypes.SetQuotationsTotalRecords,
        payload: totalRecords,
    }),
    setAcceptedQuotations: (quotation) => ({ type: actionTypes.SetAcceptedQuotations, payload: quotation }),
    setDeleteItem: (quotation) => ({ type: actionTypes.SetDeleteItem, payload: quotation }),
    setClearAcceptedQuotationsAndSelectedPackages: () => ({
        type: actionTypes.SetClearAcceptedQuotationsAndSelectedPackages,
    }),
    setSelectedPackages: (packages) => ({ type: actionTypes.SetSelectedPackages, payload: packages }),
    setDeleteSelectedPackage: (packages) => ({ type: actionTypes.SetDeleteSelectedPackage, payload: packages }),
};
