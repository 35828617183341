import { AutoComplete } from 'primereact/autocomplete';
import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';

import useFetch from '@app/hooks/useFetch';

import { getStock } from '@app/crud/customers/stock.crud';

import useAction from '@app/pages/customers/Hooks/useAction';
import useLead from '@app/pages/customers/Hooks/useLead';
import DealershipSelect from '@app/pages/customers/Partials/DealershipSelect';
import LeadTypeSelect from '@app/pages/customers/Partials/LeadTypeSelect';
import { actions } from '@app/store/modal/modal.store';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import SwitchInput from '@app/partials/content/SwitchInput';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

const Purchase = ({ showModal, setShowModal }) => {
    const dispatch = useDispatch();
    const methods = useForm();
    const { register, setValue, errors, handleSubmit, control, trigger } = methods;
    const { params } = useSelector((state) => state.modal);
    const currentLead = useLead(params?.leadId);
    const Intl = useIntl();
    const { params: listingParams } = useSelector((state) => state.customer);
    const { data: customer } = useSelector((state) => state.customer.customer);
    const [type, setType] = useState(false);
    const { fetch, data } = useFetch({
        fetchAction: getStock,
    });
    const [loading, createAction] = useAction({
        type: 'order_offer',
        success: (response) => {
            dispatch(
                actions.modalChange('next_action', {
                    leadId: response.result.lead.id,
                }),
            );
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.PURCHASE.ERROR' }),
            });
        },
    });

    const onSubmit = (dataSubmitted) => {
        createAction(dataSubmitted);
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const vehicleItemTemplate = (vehicle) => `${vehicle.brand} ${vehicle.model} ${vehicle.version}`;

    const handleClose = () => {
        if (params?.leadId) {
            dispatch(actions.rollBackModal());
        } else {
            setShowModal(false);
        }
    };

    useEffect(() => {
        setValue('registration', '');
        setValue('make', '');
        setValue('model', '');
    }, [setValue, type]);

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={handleClose}
            icon={<i className="la la-2x text-primary la-file" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.PURCHASE.TITLE' })}
            body={
                <FormProvider {...methods}>
                    <LeadTypeSelect methods={methods} currentLead={currentLead} />
                    <DealershipSelect methods={methods} customer={customer} currentLead={currentLead} />
                    <div className="mb-5">
                        <SwitchInput
                            name="type"
                            className="switch-input--primary"
                            labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.VO' })}
                            labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.VN' })}
                            active={type}
                            onChange={setType}
                            form={false}
                        />
                    </div>
                    <Form.Group>
                        <Form.Label>
                            {!type
                                ? Intl.formatMessage({ id: 'CUSTOMERS.MODAL.PURCHASE.REGISTRATION' })
                                : Intl.formatMessage({ id: 'CUSTOMERS.MODAL.PURCHASE.VIN' })}
                        </Form.Label>
                        <Controller
                            name="registration"
                            control={control}
                            defaultValue={[]}
                            render={(props) => (
                                <AutoComplete
                                    value={props.value}
                                    onChange={(e) => {
                                        setValue('make', '');
                                        setValue('model', '');
                                        setValue('vehicleInternalId', '');
                                        props.onChange(e.value);
                                    }}
                                    suggestions={data?.vehicles || null}
                                    completeMethod={(e) => {
                                        const param = !type ? 'license_number' : 'serial_number';

                                        fetch({
                                            [param]: e.query,
                                        });
                                    }}
                                    onSelect={(e) => {
                                        const param = !type ? 'license_number' : 'serial_number';

                                        setValue('make', e.value.brand);
                                        setValue('model', e.value.model);
                                        setValue('vehicleInternalId', e.value.sku);
                                        props.onChange(e.value[param]);
                                        trigger(['make', 'model', 'vehicleInternalId']);
                                    }}
                                    itemTemplate={vehicleItemTemplate}
                                    selectedItemTemplate={(vehicle) => {
                                        const param = !type ? 'license_number' : 'serial_number';

                                        return vehicle[param];
                                    }}
                                    className="w-100"
                                    inputClassName={`form-control ${errors.vehicleInternalId ? 'is-invalid' : ''}`}
                                />
                            )}
                        />
                        <ErrorForm errors={errors} name="registration" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.PURCHASE.BRAND' })}</Form.Label>
                        <Form.Control
                            type="text"
                            name="make"
                            className={`${errors.make ? 'is-invalid' : ''}`}
                            ref={register({
                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            })}
                        />
                        <ErrorForm errors={errors} name="make" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.PURCHASE.MODEL' })}</Form.Label>
                        <Form.Control
                            type="text"
                            name="model"
                            className={`${errors.model ? 'is-invalid' : ''}`}
                            ref={register({
                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            })}
                        />
                        <ErrorForm errors={errors} name="model" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.PURCHASE.SERVICES' })}</Form.Label>
                        <div className="d-flex">
                            <SwitchInput
                                name="isWaxyol"
                                className="switch-input--inactive-toggle-lighten"
                                labelFirst=""
                                labelSecond={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.PURCHASE.WAXYOL' })}
                                active={false}
                            />
                            <SwitchInput
                                name="isCertification"
                                className="ml-5 switch-input--inactive-toggle-lighten"
                                labelFirst=""
                                labelSecond={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.PURCHASE.ENGRAVING' })}
                                active={false}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.PURCHASE.FINANCING' })}</Form.Label>
                        <Form.Control
                            as="select"
                            name="financingType"
                            className={`${errors.financingType ? 'is-invalid' : ''}`}
                            ref={register({
                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            })}
                        >
                            <option value="">
                                {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.PURCHASE.FINANCING.PLACEHOLDER' })}
                            </option>
                            {listingParams &&
                                listingParams.finance_type &&
                                listingParams.finance_type.listingParams
                                    .filter((param) => param.groupSlug === 'order_offer_finance')
                                    .map((reason) => (
                                        <option key={reason.id} value={reason.slug}>
                                            {reason.name}
                                        </option>
                                    ))}
                        </Form.Control>
                        <ErrorForm errors={errors} name="financingType" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.PURCHASE.AMOUNT' })}</Form.Label>
                        <Controller
                            name="price"
                            defaultValue=""
                            rules={{
                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            }}
                            control={control}
                            render={(props) => (
                                <InputGroup className="mb-2">
                                    <NumberFormat
                                        value={props.value}
                                        className={`form-control ${errors.financingType ? 'is-invalid' : ''}`}
                                        thousandSeparator=" "
                                        onValueChange={(values) => {
                                            props.onChange(values.floatValue);
                                        }}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text>€</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            )}
                        />
                        <ErrorForm errors={errors} name="price" />
                    </Form.Group>
                    <Form.Control name="vehicleInternalId" className="kt-hidden" ref={register} />
                </FormProvider>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={handleClose} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(onSubmit, onError)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.NEXT' })}
                    </Button>
                </div>
            }
        />
    );
};

export default Purchase;
