import React from "react";
import {Col, Row} from "react-bootstrap";
import PriceZone from "./PriceZone";

export default function MakeSegment(props) {
    const { title } = props;

    return (
        <Row className="ranges">
            <Col lg={2} className="ranges__title">
                Gamme
                <br />
                {title}
            </Col>
            { props?.segments?.packagePriceZone &&
                props?.segments?.packagePriceZone.map((zone, key) => {
                    return (
                        <Col key={key}>
                            <PriceZone name={props.name}
                                       makeSegment={props.id}
                                       segments={props.segments}
                                       id={zone.id}
                                       title={zone.name} />
                        </Col>
                    )
                })
            }
        </Row>
    );
}
