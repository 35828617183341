import React from "react";

import { Alerts } from "@app/partials/content/Alerts"
import UserProfile from "@app/partials/layout/UserProfile";

const Topbar = () => (
    <div className="d-none d-lg-flex align-items-center kt-header__topbar">
        <UserProfile showHi showBadge={false} />
        <Alerts />
    </div>
)

export default Topbar;
