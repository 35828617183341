import classNames from 'classnames';
import React, { useContext } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import { NamedPerformances } from '@app/crud/dashboard/achat-cash-types';

import ROUTES from '@app/router/Routes';

import { DashboardContext } from '../../DashboardProvider';
import { getStatsSortValues } from '../../helpers/DashboardHelper';

interface Props {
    performances: NamedPerformances;
    className?: string;
}

const PerformancesBySource = ({ performances, className }: Props) => {
    const { params } = useContext(DashboardContext);

    const gross =
        performances.won + performances.lost > 0
            ? Math.round((100 * performances.won) / (performances.won + performances.lost))
            : '-';
    const net =
        performances.withConfirmAppointment > 0
            ? Math.round((100 * performances.won) / performances.withConfirmAppointment)
            : '-';

    return (
        <div className={classNames('d-flex flex-column', className)}>
            <div className="source-title">{performances.name}</div>
            <div className="source-stats border-top">
                <FormattedHTMLMessage id="DASHBOARD.ONLINE_PERF.SUPPORT" values={{ nb: performances.taken }} />

                <div className="d-flex flex-nowrap font-size-sm font-weight-semibold">
                    <Link
                        className="text-primary text-nowrap"
                        to={{
                            pathname: routeTo(ROUTES.LEADS_ACHAT_CASH.PATH.MAIN, {
                                statusType: 'all',
                            }),
                            state: getStatsSortValues({
                                ...params,
                            }),
                        }}
                    >
                        <u>
                            {performances.inProgress ?? 0} <FormattedMessage id="DASHBOARD.ONLINE_PERF.IN_PROGRESS" />
                        </u>
                    </Link>
                    <span className="mx-1">,</span>
                    <Link
                        className="text-danger text-nowrap"
                        to={{
                            pathname: routeTo(ROUTES.LEADS_ACHAT_CASH.PATH.MAIN, {
                                statusType: 'close',
                            }),
                            state: getStatsSortValues({
                                ...params,
                                leadStatus: 'closed_lost',
                            }),
                        }}
                    >
                        <u>
                            {performances.lost ?? 0} <FormattedMessage id="DASHBOARD.ONLINE_PERF.LOST" />
                        </u>
                    </Link>
                    <span className="mx-1">,</span>
                    <Link
                        className="text-success text-nowrap"
                        to={{
                            pathname: routeTo(ROUTES.LEADS_ACHAT_CASH.PATH.MAIN, {
                                statusType: 'close',
                            }),
                            state: getStatsSortValues({
                                ...params,
                                leadStatus: 'closed_won',
                            }),
                        }}
                    >
                        <u>
                            {performances.won ?? 0} <FormattedMessage id="DASHBOARD.ONLINE_PERF.WON" />
                        </u>
                    </Link>
                </div>

                <FormattedHTMLMessage id="DASHBOARD.ONLINE_PERF.GROSS_TRANSFORM" values={{ nb: gross ?? 0 }} />
                <br />
                <FormattedHTMLMessage id="DASHBOARD.ONLINE_PERF.NET_TRANSFORM" values={{ nb: net ?? 0 }} />
            </div>
        </div>
    );
};

PerformancesBySource.defaultProps = {
    className: '',
};

export default PerformancesBySource;
