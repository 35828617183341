import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { Brand, Informations, Model, Versions } from './stock.type';

const { REACT_APP_LEAD_URL } = process.env;

type StockKeys = 'license_number' | 'serial_number';
export function getStock(params: { [key in StockKeys]: string }) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/stock/search`, {
        params,
    });
}

export function getInformationsByRegistration(registration: string) {
    return ApiGateway.get<ApiResponse<Informations>, CustomAxiosResponse<ApiResponse<Informations>>>(
        `${REACT_APP_LEAD_URL}/part-exchange/plate/${registration}`,
    );
}

export function getBrands() {
    return ApiGateway.get<Brand[], CustomAxiosResponse<Brand[]>>(`${REACT_APP_LEAD_URL}/part-exchange/make`, {
        params: {
            make: '',
        },
    });
}

export function getBrandByName({ make }: { make: string }) {
    return ApiGateway.get<Brand[], CustomAxiosResponse<Brand[]>>(`${REACT_APP_LEAD_URL}/part-exchange/make`, {
        params: {
            make,
        },
    });
}

export function getModels({ makeCode, month, year }: { makeCode: string; month?: string; year?: string }) {
    return ApiGateway.get<Model[], CustomAxiosResponse<Model[]>>(`${REACT_APP_LEAD_URL}/part-exchange/model`, {
        params: {
            makeCode,
            modelName: '',
            month,
            year,
        },
    });
}

export function getVersions({
    vehicleTypeCode,
    makeCode,
    modelCode,
    month,
    year,
}: {
    vehicleTypeCode?: number;
    makeCode: number;
    modelCode: number;
    month: string;
    year: string;
}) {
    return ApiGateway.get<Versions, CustomAxiosResponse<Versions>>(`${REACT_APP_LEAD_URL}/part-exchange/version`, {
        params: {
            vehicleTypeCode,
            makeCode,
            modelCode,
            month,
            year,
        },
    });
}
