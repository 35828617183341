import dayjs from 'dayjs';
import { Divider } from 'primereact/divider';
import React, { useEffect, useRef } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import KTUtil from '@src/_metronic/_assets/js/util';
import { Locale } from '@src/_metronic/i18n/I18nProvider';

import { getDateByFormat } from '@app/helpers/DateHelper';
import { empty } from '@app/helpers/ToolsHelper';

import { Agenda } from '@app/pages/agenda';
import useAction from '@app/pages/customers/Hooks/useAction';
import useLead from '@app/pages/customers/Hooks/useLead';
import CarSearch from '@app/pages/customers/Partials/CarSearch';
import DealershipSelect from '@app/pages/customers/Partials/DealershipSelect';
import LeadTypeSelect from '@app/pages/customers/Partials/LeadTypeSelect';
import { actions } from '@app/store/modal/modal.store';

import EventCaption from '@app/partials/content/Agenda/Event.Caption';
import { DateTimePicker } from '@app/partials/content/DateTimePicker';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import { formatLeadVehicles } from '@app/partials/content/Vehicle/VehicleHelpers';
import ErrorForm from '@app/partials/layout/ErrorForm';

const Callback = ({ showModal, setShowModal }) => {
    const Intl = useIntl();
    const methods = useForm();
    const dispatch = useDispatch();
    const calendar = useRef();
    const { register, control, setValue, getValues, reset, errors, handleSubmit } = methods;
    const { params } = useSelector((state) => state.modal);
    const currentLead = useLead(params?.leadId);
    const { data: customer } = useSelector((state) => state.customer.customer);
    const [loading, createAction, setActionType] = useAction({
        type: 'appointment_callback',
        success: () => {
            setShowModal(false);
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CALLBACK.ERROR' }),
            });
        },
    });

    const onSubmit = async (data) => {
        if (currentLead?.scheduleAppointment) {
            data.scheduleAppointmentId = currentLead.scheduleAppointment.id;
        }

        createAction({
            ...data,
            scheduleType: 'appointment_callback',
        });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const handleClose = () => {
        dispatch(actions.rollBackModal());
    };

    useEffect(() => {
        if (params?.scheduleAppointmentId) {
            setActionType('update_callback');
        } else {
            setActionType('appointment_callback');
        }
    }, [setActionType, params]);

    useEffect(() => {
        let values = {};

        if (currentLead?.scheduleAppointment) {
            values = {
                ...values,
                dateStart: dayjs(currentLead.scheduleAppointment.dateStart, 'YYYY-MM-DD HH:mm').format(
                    'YYYY-MM-DD HH:mm',
                ),
                dateEnd: dayjs(currentLead.scheduleAppointment.dateEnd, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'),
                comment: currentLead.scheduleAppointment.comment,
                subject: currentLead.scheduleAppointment.subject,
            };
        }

        if (currentLead?.leadElements) {
            values = {
                ...values,
                vehicles: formatLeadVehicles(currentLead),
            };
        }

        if (!values?.subject && !empty(customer)) {
            values.subject = Intl.formatMessage(
                { id: 'CUSTOMERS.MODAL.CALLBACK.SUBJECT.DEFAULT' },
                {
                    client: `${customer.lastname || ''} ${customer.firstname || ''}`,
                },
            );
        }

        reset(values);
    }, [reset, currentLead, customer]);

    const date = dayjs();
    const defaultStart = date.minute((Math.round(date.minute() / 30) * 30) % 60);
    const defaultEnd = defaultStart.add(15, 'minute');

    useEffect(() => {
        if (calendar.current) {
            const calendarApi = calendar.current.getApi();
            calendarApi.select(defaultStart.format('YYYY-MM-DD HH:mm'), defaultEnd.format('YYYY-MM-DD HH:mm'));
            calendarApi.setOption('height', '100%');
        }
    }, [calendar.current]);

    return (
        <ModalDefault
            show={showModal}
            size="xl"
            loading={loading}
            hideModal={handleClose}
            body={
                <FormProvider {...methods}>
                    <Row>
                        <Col lg={7}>
                            <Agenda
                                ref={calendar}
                                className="card--noborder d-lg-flex d-none"
                                bodyClassName="p-0"
                                selectable
                                onSelect={(e) => {
                                    setValue('dateStart', dayjs(e.start).format('YYYY-MM-DD HH:mm'));
                                    setValue('dateEnd', dayjs(e.end).format('YYYY-MM-DD HH:mm'));
                                }}
                                weekView={{
                                    titleFormatStart: 'D',
                                    titleFormatEnd: 'D MMMM YYYY',
                                    titleRangeSeparator: ' au ',
                                    dayHeaderFormat: 'ddd DD MMM',
                                }}
                                slotDuration="00:15:00"
                                canSelectUser={false}
                            />
                        </Col>
                        <Col>
                            <div className="modal-title h4 text-center mb-5">
                                <div className="text-primary text-center">
                                    <div>
                                        <i className="la la-2x text-primary la-calendar-plus" />
                                    </div>
                                    {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CALLBACK.TITLE' })}
                                </div>
                            </div>
                            <Form>
                                <Row className="mb-2">
                                    <LeadTypeSelect methods={methods} currentLead={currentLead} />
                                </Row>
                                <Row className="mb-2">
                                    <Col lg={12}>
                                        <DealershipSelect
                                            currentLead={currentLead}
                                            methods={methods}
                                            customer={customer}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col lg={12}>
                                        <Form.Group>
                                            <Form.Label>
                                                {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CALLBACK.SUBJECT' })}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="subject"
                                                className={`${errors.object ? 'is-invalid' : ''}`}
                                                ref={register({
                                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                                })}
                                            />
                                            <ErrorForm errors={errors} name="object" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col lg={6}>
                                        <Form.Group>
                                            <Form.Label>
                                                {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CALLBACK.DATE_START' })}
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="dateStart"
                                                rules={{
                                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                                }}
                                                defaultValue={defaultStart.format('YYYY-MM-DD HH:mm')}
                                                render={(props) => (
                                                    <DateTimePicker
                                                        locale={Locale}
                                                        dateFormat="DD/MM/YYYY HH:mm"
                                                        showTime
                                                        onChange={(e) => {
                                                            const startValue = dayjs(e.value).isValid()
                                                                ? dayjs(e.value)
                                                                : '';

                                                            if (startValue !== '') {
                                                                setValue(
                                                                    'dateEnd',
                                                                    startValue
                                                                        .add(15, 'minute')
                                                                        .format('YYYY-MM-DD HH:mm'),
                                                                );
                                                                props.onChange(startValue.format('YYYY-MM-DD HH:mm'));
                                                            } else {
                                                                setValue('dateEnd', '');
                                                                props.onChange('');
                                                            }
                                                        }}
                                                        value={getDateByFormat(props.value, 'YYYY-MM-DD HH:mm', '')}
                                                    />
                                                )}
                                            />
                                            <ErrorForm errors={errors} name="dateStart" />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group>
                                            <Form.Label>
                                                {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CALLBACK.DATE_END' })}
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="dateEnd"
                                                rules={{
                                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                                }}
                                                defaultValue={defaultEnd.format('YYYY-MM-DD HH:mm')}
                                                render={(props) => (
                                                    <DateTimePicker
                                                        locale={Locale}
                                                        dateFormat="DD/MM/YYYY HH:mm"
                                                        showTime
                                                        minDate={dayjs(
                                                            getValues('dateStart'),
                                                            'YYYY-MM-DD HH:mm',
                                                        ).toDate()}
                                                        showSeconds={false}
                                                        onChange={(e) => {
                                                            const value = dayjs(e.value).isValid()
                                                                ? dayjs(e.value)
                                                                : '';
                                                            if (value !== '') {
                                                                props.onChange(value.format('YYYY-MM-DD HH:mm'));
                                                            } else {
                                                                props.onChange('');
                                                            }
                                                        }}
                                                        value={getDateByFormat(props.value, 'YYYY-MM-DD HH:mm', '')}
                                                    />
                                                )}
                                            />
                                            <ErrorForm errors={errors} name="dateEnd" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col lg={12}>
                                        <Form.Group>
                                            <Form.Label>
                                                {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CALLBACK.COMMENT' })}
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={4}
                                                name="comment"
                                                className={`${errors.comment ? 'is-invalid' : ''}`}
                                                ref={register}
                                            />
                                            <ErrorForm errors={errors} name="comment" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Divider className="my-5" type="dashed" />
                                <CarSearch title={Intl.formatMessage({ id: 'CAR_SEARCH.INTERESTED' })} />
                            </Form>
                        </Col>
                    </Row>
                </FormProvider>
            }
            footer={
                <div className="d-flex align-items-center justify-content-between">
                    {!KTUtil.isMobileDevice() && (
                        <div>
                            <EventCaption />
                        </div>
                    )}
                    <div>
                        <Button variant="outline-secondary" onClick={handleClose} className="mr-5">
                            {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                        </Button>
                        <Button variant="primary" onClick={handleSubmit(onSubmit, onError)}>
                            {Intl.formatMessage({ id: 'TRANSLATOR.PLAN' })}
                        </Button>
                    </div>
                </div>
            }
        />
    );
};

export default Callback;
