import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { getStorage } from '../../_metronic';
import { refreshToken } from '../crud/auth.crud';
import { actions } from '../store/login/login.store';
import store from '../store/store';

import { setToken } from './User';

const { REACT_APP_AUTOUSER_URL } = process.env;

const token = () => (getStorage('accessToken') ? `Bearer ${getStorage('accessToken')}` : '');
const ApiGateway = axios.create();

interface Error {
    error?: string;
    error_status?: number;
    error_code?: string;
    error_description?: string;
    error_data?: object;
}

export interface ApiResponse<T> {
    count?: number;
    result: T & Error;
}

export interface ApiFacetsResponse<T, S> extends ApiResponse<T> {
    facets: S;
}

export type ApiError = AxiosError;

export type CustomAxiosResponse<T> = T & { axios: AxiosResponse } & { result?: { error?: string } };

const requestInterceptorAddAutoUser = (config: AxiosRequestConfig) => {
    if (config.url !== `${REACT_APP_AUTOUSER_URL}/login_check`) {
        config.headers.Authorization = token();
        config.headers['x-autouser-cache'] = getStorage('autouserCache');
    }
    return config;
};

const requestInterceptorPromiseError = (error: any) => Promise.reject(error);

const responseInterceptorApi = (response: AxiosResponse<any>) => {
    if (response.data.constructor === Object) {
        const overridedReponse = { ...response };
        delete overridedReponse.data;

        response.data.axios = overridedReponse;
    }

    return response.data;
};

const responseInterceptorRefreshTokenAndRetry = (error: any) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
        const originalRequest = error.config;

        if (
            ((error?.response?.status === 401 && error?.response?.config?.url?.indexOf?.('token/refresh') === -1) ||
                error?.response?.data?.result?.error?.includes?.('No data found in redis for Redis key') || // CRM/Lead response structure
                error?.response?.data?.error?.includes?.('No data found in redis for Redis key')) && // Stock response structure
            // eslint-disable-next-line no-underscore-dangle
            !error?.response?.config?.__isRetryRequest
        ) {
            const refresh_token = getStorage('refreshToken');

            if (!refresh_token) {
                store.dispatch(actions.logoutUser());
                reject();
            }

            const new_token = await refreshToken(refresh_token);

            setToken(store.dispatch, {
                ...new_token,
                'x-autouser-cache': new_token.axios.headers['x-autouser-cache'],
            });

            // eslint-disable-next-line no-underscore-dangle
            originalRequest.__isRetryRequest = true;
            originalRequest.headers.Authorization = getStorage('accessToken');
            // eslint-disable-next-line no-promise-executor-return
            return ApiGateway(originalRequest).then(resolve, reject);
        }
        reject(error?.response?.data);
    });

ApiGateway.interceptors.request.use(requestInterceptorAddAutoUser, requestInterceptorPromiseError);
ApiGateway.interceptors.response.use(responseInterceptorApi, responseInterceptorRefreshTokenAndRetry);
export default ApiGateway;

export const PureGateway = axios.create();
PureGateway.interceptors.request.use(requestInterceptorAddAutoUser, requestInterceptorPromiseError);
PureGateway.interceptors.response.use(null, responseInterceptorRefreshTokenAndRetry);

export const PureGatewayWithoutAuth = axios.create();
PureGatewayWithoutAuth.interceptors.request.use(null, requestInterceptorPromiseError);
PureGatewayWithoutAuth.interceptors.response.use(responseInterceptorApi, null);
