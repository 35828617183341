import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { useAppSelector } from '@app/hooks';
import DealershipSelect from '@app/pages/customers/Partials/DealershipSelect';

import ErrorForm from '@app/partials/layout/ErrorForm';

import { LeadHomeChargeFormFields } from '../LeadHomeCharge';
import { CreateLeadHomeChargePayload } from '../LeadHomeCharge.type';

type LeadHomeChargeDealershipProps = {
    data: Partial<CreateLeadHomeChargePayload> & LeadHomeChargeFormFields;
};

const LeadHomeChargeDealership = ({ data }: LeadHomeChargeDealershipProps) => {
    const methods = useFormContext();
    const { errors } = methods;
    const { data: customer } = useAppSelector((state) => state.customer.customer);

    return (
        <Form.Group as={Col} md={12}>
            <DealershipSelect methods={methods} customer={customer} value={data?.dealership || null} forceDisplay />
            <ErrorForm errors={errors} name="dealership" />
        </Form.Group>
    );
};

export default LeadHomeChargeDealership;
