import React, { useEffect, useRef, useState } from 'react';
import { Button, Image, Row, Col, ProgressBar } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';

import ModalDefault from '@app/partials/content/modals/Modal.default';

const ModalAlert = ({ show, setShowModal, redirect, history }) => {
    const [valueProgbar, setValueProgbar] = useState(100);
    const interval = useRef(null);
    const redirectLink = () => history.push(routeTo(ROUTES.ACCOUNT_PLANNING.PATH));

    useEffect(() => {
        if (show && redirect) {
            let val = 100;
            interval.current = setInterval(() => {
                val -= 1;
                if (val <= 1) {
                    setValueProgbar(100);
                    clearInterval(interval.current);
                    redirectLink();
                }
                setValueProgbar(val);
            }, 100);

            return () => {
                if (interval.current) {
                    clearInterval(interval.current);
                    interval.current = null;
                }
            };
        }
    }, [show]);

    return (
        <ModalDefault
            show={show}
            hideModal={() => (redirect ? null : setShowModal(false))}
            icon={<i className="text-primary las la-exclamation-triangle fa-2x" />}
            title={Intl.formatMessage({ id: 'AGENDA.AVAILABILITIES.MODAL.AVAILABILITIES.TITLE' })}
            body={
                <Row className="text-center">
                    <Col lg={12}>
                        {redirect ? (
                            <FormattedHTMLMessage id="AGENDA.AVAILABILITIES.MODAL.AVAILABILITIES.CONTENT.CURRENT.USER" />
                        ) : (
                            <FormattedHTMLMessage id="AGENDA.AVAILABILITIES.MODAL.AVAILABILITIES.CONTENT.OTHER.USER" />
                        )}
                    </Col>
                    <Col className="mt-4" lg={12}>
                        <Image src="/media/icons/agenda-configurer-mon-planning.svg" widht="189px" height="173px" />
                    </Col>
                    {redirect && (
                        <Col className="mt-4" lg={12}>
                            <ProgressBar style={{ height: '5px' }} now={valueProgbar} />
                        </Col>
                    )}
                </Row>
            }
            footer={
                redirect ? (
                    <Button variant="primary" onClick={() => redirectLink()}>
                        {Intl.formatMessage({ id: 'AGENDA.AVAILABILITIES.MODAL.REDIRECT.BUTTON' })}
                    </Button>
                ) : (
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.BACK' })}
                    </Button>
                )
            }
        />
    );
};

ModalAlert.defaultProps = {
    show: false,
};

export default ModalAlert;
