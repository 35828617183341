import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';

import { checkDateStatus } from '@app/helpers/UserHelper';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import StatusLead from '@app/pages/customers/Partials/StatusLead';

import RowInfo from '@app/partials/content/RowInfo';

interface Props {
    event: VcuMappedEventDetails;
}
const DetailOpteven = ({ event }: Props) => {
    const Intl = useIntl();
    const { opteven } = event;

    const isActive = opteven?.product.every((item) => checkDateStatus(item.endDate));
    const statusType = isActive ? 'success' : 'secondary';
    const statusName = isActive
        ? Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.STATUS.ACTIVE' })
        : Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.STATUS.INACTIVE' });

    return (
        <>
            <div className="d-md-flex justify-content-md-between align-items-md-stretch border-bottom">
                <div className="col-md-4">
                    <RowInfo
                        label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.SOURCE.DATA' })}
                        value={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.OPTEVEN.NAME' })}
                    />
                    <RowInfo
                        label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.ID' })}
                        value={opteven?.srcProcessId || '-'}
                    />
                </div>
                <div className="col-md-5">
                    <RowInfo
                        label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.OPTEVEN.NAME.GTIE' })}
                        value={opteven?.program || '-'}
                    />
                </div>
                <div className="col-md-5">
                    <RowInfo
                        label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.OPTEVEN.NUMBER.GTIE' })}
                        value={opteven?.numberGuarantee || '-'}
                    />
                </div>
            </div>
            {opteven?.product &&
                opteven.product.map((item) => (
                    <div className="my-5 ml-5 d-md-flex justify-content-md-between align-items-md-stretch border-bottom font-italic">
                        <div className="col-md-4">
                            <RowInfo
                                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.OPTEVEN.LIB.FAMILY' })}
                                value={item?.libFamily || '-'}
                            />
                            <RowInfo
                                label={Intl.formatMessage({
                                    id: 'CUSTOMER.VIEW.LIFE.DETAIL.OPTEVEN.LIB.PRODUCT',
                                })}
                                value={item?.product || '-'}
                            />
                            <RowInfo
                                label={Intl.formatMessage({
                                    id: 'CUSTOMER.VIEW.LIFE.DETAIL.OPTEVEN.CATEGORIE.PRODUCT',
                                })}
                                value={item?.catProduct || '-'}
                            />
                        </div>
                        <div className="col-md-5">
                            <RowInfo
                                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.OPTEVEN.DURATION.CONTRAT' })}
                                value={
                                    `${item?.durationRisk} ${Intl.formatMessage({
                                        id: 'MONTHS',
                                    })}` || '-'
                                }
                            />
                            <RowInfo
                                label={Intl.formatMessage({
                                    id: 'CUSTOMER.VIEW.LIFE.DETAIL.OPTEVEN.PRODUCT.TYPE.REGLEMENT',
                                })}
                                value={item?.productTypeRegement || '-'}
                            />
                        </div>
                        <div className="col-md-5">
                            <RowInfo
                                label={Intl.formatMessage({
                                    id: 'CUSTOMER.VIEW.LIFE.DETAIL.OPTEVEN.START.DATE',
                                })}
                                value={item?.startDate ? dayjs(item.startDate).format('DD/MM/YYYY') : '-'}
                            />
                            <RowInfo
                                label={Intl.formatMessage({
                                    id: 'CUSTOMER.VIEW.LIFE.DETAIL.OPTEVEN.END.DATE',
                                })}
                                value={item?.endDate ? dayjs(item.endDate).format('DD/MM/YYYY') : '-'}
                            />
                            <RowInfo
                                label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.OPTEVEN.STATE' })}
                                value={<StatusLead overridedStatus={statusType} statusName={statusName} />}
                            />
                        </div>
                    </div>
                ))}
        </>
    );
};

export default DetailOpteven;
