import * as dayjs from 'dayjs';
import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Intl, Locale } from '@src/_metronic/i18n/I18nProvider';

import { getDateByFormat } from '@app/helpers/DateHelper';

import { DateTimePicker } from '@app/partials/content/DateTimePicker';

import { CampaignContext } from '../../CampaignProvider';
import { OperationContext } from '../../OperationProvider';

const OperationDate = () => {
    const { operationState, setOperationState } = useContext(OperationContext);
    const { types } = useContext(CampaignContext);

    const currentType = operationState?.type;
    const typeIdx = types?.findIndex((type) => type?.slug === currentType);
    const currentTypeObj = types?.[typeIdx];

    return (
        <div>
            <div className="text-center">
                <i className="las la-calendar la-4x text-primary" />
                <div className="text-primary font-size-h3 font-weight-semibold">
                    <FormattedMessage id="CAMPAIGN.MODALS.SETTINGS.DATE.TITLE" />
                </div>
            </div>
            <Form.Group className="mt-8">
                <Form.Label>
                    <FormattedMessage id="CAMPAIGN.MODALS.SETTINGS.DATE.SENDING" />
                </Form.Label>
                <DateTimePicker
                    locale={Locale}
                    showIcon
                    dateFormat="DD/MM/YYYY HH:mm"
                    onChange={(e) => {
                        const value = dayjs(e.value).isValid() ? dayjs(e.value).format('YYYY-MM-DD HH:mm:ss') : null;
                        setOperationState({
                            ...operationState,
                            dateStart: value,
                        });
                    }}
                    value={getDateByFormat(operationState?.dateStart, 'YYYY-MM-DD HH:mm:ss', null)}
                    minDate={dayjs().add(2, 'days').toDate()}
                    minDateError="minDateCampaign"
                    showTime
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="CAMPAIGN.MODALS.SETTINGS.DATE.DURATION" />
                </Form.Label>
                <Form.Control
                    as="select"
                    onChange={(e) => {
                        setOperationState({
                            ...operationState,
                            duration: parseInt(e.target.value, 10),
                        });
                    }}
                    defaultValue={operationState?.duration ?? 30}
                >
                    {[...Array(30).keys()].map((value) => (
                        <option value={value + 1}>{value + 1}</option>
                    ))}
                    ;
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    <FormattedMessage id="CAMPAIGN.MODALS.SETTINGS.DATE.TYPE" />
                </Form.Label>
                <Form.Control
                    as="select"
                    onChange={(e) => {
                        setOperationState({
                            ...operationState,
                            type: e.target.value,
                        });
                    }}
                    defaultValue={currentTypeObj?.name}
                >
                    <option value={currentTypeObj?.slug}>
                        {currentTypeObj?.name ?? Intl.formatMessage({ id: 'TRANSLATOR.SELECT' })}
                    </option>
                    {types?.map((type) => {
                        if (type?.slug !== currentTypeObj?.slug) {
                            return <option value={type?.slug}>{type?.name}</option>;
                        }
                        return null;
                    })}
                    ;
                </Form.Control>
            </Form.Group>
        </div>
    );
};

export default OperationDate;
