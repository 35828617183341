import React, { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useFetch from '@app/hooks/useFetch';

import { getOneCustomerActivity } from '@app/crud/customers/customer.crud';

import { RootState } from '@app/store/rootDuck';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

import useAction from '../Hooks/useAction';

import { RBRef } from './APVRequest';

type APVRequestReplyProps = {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
};

const APVRequestReply = ({ showModal, setShowModal }: APVRequestReplyProps) => {
    const Intl = useIntl();
    const { register, handleSubmit, errors } = useForm();
    const { params } = useSelector((state: RootState) => state.modal);
    const [loading, createAction] = useAction({
        type: 'reopen_local_apv',
        success: () => {
            setShowModal(false);
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CONTACT.ERROR' }),
            });
        },
    });

    const { fetch: fetchActivity, loading: fetchLoading } = useFetch({
        fetchAction: getOneCustomerActivity,
    });

    useEffect(() => {
        if (params?.leadId && fetchActivity) {
            fetchActivity(params.leadId).then(() => {});
        }
    }, [params, fetchActivity]);

    const onSubmit = (data: { default_dealership: string }) => {
        delete data.default_dealership;

        // @ts-ignore
        createAction(data);
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading || fetchLoading}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-hand-pointer" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LOCAL_APV_REOPEN.TITLE' })}
            body={
                <Form.Group className="mt-5">
                    <Form.Label>
                        <FormattedMessage id="CUSTOMERS.MODAL.LOCAL_APV_REPLY.COMMENT.LABEL" />
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        ref={
                            register({
                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            }) as RBRef
                        }
                        name="comment"
                        placeholder={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LOCAL_APV_REPLY.REPLY.PLACEHOLDER' })}
                        rows={4}
                    />
                    <ErrorForm errors={errors} name="comment" />
                </Form.Group>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(onSubmit, onError)}>
                        {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LOCAL_APV_REOPEN.ACTIONS.SEND' })}
                    </Button>
                </div>
            }
        />
    );
};

export default APVRequestReply;
