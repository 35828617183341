import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFilters from '@app/hooks/useFilters';

import HoCDataTable from '@app/partials/content/HoCDataTable';

import { OperationContext } from '../../OperationProvider';

const OperationAssignmentsManualForm = ({ dealershipId, onChange }) => {
    const { operationState } = useContext(OperationContext);
    const [selectedDealer, setSelectedDealer] = useState(operationState?.selectedDealers?.dealershipId);
    const [selectedContacts, setSelectedContacts] = useState(null);
    const dealership = operationState?.dealerships?.[dealershipId];
    const { items, start } = useFilters({
        name: 'campaign_manual_assignment',
    });

    const contactBodyTemplate = (contact) => `${contact?.firstname ?? ''} ${contact.lastname ?? ''}`;

    const dealerBodyTemplate = (contact) => {
        const affectedDealer = operationState?.affectedDealers?.[dealershipId]?.[contact?.campaignContactId] || null;

        return (
            <Dropdown
                options={dealership?.dealers}
                value={dealership?.dealers?.find?.((dealer) => dealer?.id === affectedDealer)}
                onChange={(e) => {
                    onChange(e.value, [contact]);
                }}
                valueTemplate={(dealer) =>
                    dealer
                        ? `${dealer?.firstname} ${dealer?.lastname}`
                        : Intl.formatMessage({ id: 'TRANSLATOR.SELECT' })
                }
                itemTemplate={(dealer) => (dealer ? `${dealer?.firstname} ${dealer?.lastname}` : '')}
                optionLabel="id"
            />
        );
    };

    const cols = [
        {
            selectionMode: 'multiple',
        },
        {
            field: 'contacts',
            header: (
                <div className="font-size-lg font-weight-bold text-initial ml-1">
                    {Intl.formatMessage({ id: 'CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.TABLE.CONTACT' })}
                </div>
            ),
            body: contactBodyTemplate,
        },
        {
            field: 'zipcode',
            header: (
                <div className="font-size-lg font-weight-bold text-initial">
                    {Intl.formatMessage({ id: 'CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.TABLE.ZIPCODE' })}
                </div>
            ),
        },
        {
            field: 'dealers',
            header: (
                <div className="font-size-lg font-weight-bold text-initial">
                    {Intl.formatMessage({ id: 'CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.TABLE.DEALERS' })}
                </div>
            ),
            style: { width: '180px' },
            body: dealerBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <div>
            <div className="d-flex align-items-center">
                <div className="text-nowrap">
                    <FormattedHTMLMessage id="CAMPAIGN.MODALS.SETTINGS.ASSIGNMENTS.ASSIGN" />
                </div>
                <Dropdown
                    value={selectedDealer}
                    className="flex-grow-1 mx-4"
                    options={dealership?.dealers}
                    onChange={(e) => {
                        setSelectedDealer(e.value);
                    }}
                    valueTemplate={(dealer) =>
                        dealer
                            ? `${dealer?.firstname} ${dealer?.lastname} (${
                                  operationState?.dealersContact[dealer.id]?.length || 0
                              })`
                            : Intl.formatMessage({ id: 'TRANSLATOR.SELECT' })
                    }
                    itemTemplate={(dealer) =>
                        dealer
                            ? `${dealer?.firstname} ${dealer?.lastname} (${
                                  operationState?.dealersContact[dealer.id]?.length || 0
                              })`
                            : ''
                    }
                    showClear
                    optionLabel="id"
                />
                <Button
                    variant="primary"
                    onClick={() => {
                        onChange(selectedDealer, selectedContacts);
                        setSelectedDealer(null);
                        setSelectedContacts(null);
                    }}
                    disabled={!selectedDealer || selectedContacts?.length === 0}
                >
                    <FormattedHTMLMessage id="TRANSLATOR.OK" />
                </Button>
            </div>
            <HoCDataTable
                value={dealership?.contacts?.filter((c) => !c?.dealer)}
                className="mt-8 p-datatable-contacts"
                autoLayout
                selection={selectedContacts}
                onSelectionChange={(e) => setSelectedContacts(e.value)}
                paginator
                paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                rowsPerPageOptions={[25, 50, 100]}
                rows={items}
                first={start}
                totalRecords={dealership?.contacts?.filter((c) => !c?.dealer).length}
            >
                {dynamicColumns}
            </HoCDataTable>
        </div>
    );
};

export default OperationAssignmentsManualForm;
