import dayjs from 'dayjs';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { Order } from '@app/crud/apv/order.type';

interface CardVehicleInfoProps {
    order: Order;
}

const formatDate = (date: string) => (date ? dayjs(date).format('DD/MM/YYYY') : '-');
const formatMileage = (mileage: number) =>
    mileage ? <NumberFormat value={mileage} thousandSeparator=" " displayType="text" suffix=" km" /> : '-';

const CardVehicleInfo = ({ order }: CardVehicleInfoProps) => {
    const intl = useIntl();

    const vehicleDetails = [
        { labelId: 'APV.ORDER.VIEW.BRAND', value: order?.make || '-' },
        { labelId: 'APV.ORDER.VIEW.MODEL', value: order?.model || '-' },
        { labelId: 'APV.ORDER.VIEW.VERSION', value: order?.version || '-' },
        { labelId: 'APV.ORDER.VIEW.DATE_REGISTRATION', value: formatDate(order?.dateFirstRegistration) },
        { labelId: 'APV.ORDER.VIEW.MILEAGE', value: formatMileage(order?.mileage) },
        { labelId: 'APV.ORDER.VIEW.REGISTRATION', value: order?.registration || '-' },
    ];

    return (
        <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
                <div>
                    <i className="card__icon fas fa-car" />
                    {intl.formatMessage({ id: 'APV.ORDER.VIEW.VEHICLE_DETAILS' })}
                </div>
            </Card.Header>
            <Card.Body>
                {vehicleDetails.map(({ labelId, value }) => (
                    <Row className="mb-4" key={labelId}>
                        <Col lg={6}>{intl.formatMessage({ id: labelId })} :</Col>
                        <Col className="font-weight-semibold" lg={6}>
                            {value}
                        </Col>
                    </Row>
                ))}
            </Card.Body>
        </Card>
    );
};

export default CardVehicleInfo;
