import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import { AppointmentPerformances as DataAppointmentPerformances } from '@app/crud/dashboard/achat-cash-types';

import ROUTES from '@app/router/Routes';

import { DashboardContext } from '../../DashboardProvider';
import { getStatsSortValues } from '../../helpers/DashboardHelper';

interface Props {
    title: string;
    icon: string;
    data?: DataAppointmentPerformances;
    loading?: boolean;
    className?: string;
}

const AppointmentPerformances = ({ title, icon, data, loading, className }: Props) => {
    const { params } = useContext(DashboardContext);

    if (loading) {
        return <Skeleton borderRadius="10px" className="d-flex flex-column flex-lg-grow-1 mt-lg-2 mt-6 p-15" />;
    }

    return (
        <div className={classNames('dashboard-appointment-perf d-flex flex-column', className)}>
            <div className="flex-grow-1">
                <div className="font-size-lg font-weight-bold d-flex flex-wrap align-items-center justify-content-between">
                    <i className={classNames('dashboard-general-perf__icon las la-2x mr-2', icon)} />
                    <div className="flex-grow-1 d-flex align-items-center">
                        <FormattedMessage id={title} />
                    </div>
                </div>
                <div className="d-flex flex-wrap flex-column flex-lg-row justify-content-between mt-3 mb-1">
                    <Link
                        className="text-secondary d-flex align-items-end"
                        to={{
                            pathname: routeTo(ROUTES.LEADS_ONLINE.PATH, {
                                statusType: 'all',
                            }),
                            state: getStatsSortValues({
                                ...params,
                            }),
                        }}
                    >
                        <div className="dashboard-processed-lead__number">{data?.created ?? 0}</div>
                        <div className="dashboard-processed-lead__ratio">
                            <FormattedHTMLMessage id="DASHBOARD.APPOINTMENT_PERF.CREATED" />
                        </div>
                    </Link>
                    <Link
                        className="text-primary d-flex align-items-end"
                        to={{
                            pathname: routeTo(ROUTES.LEADS_ONLINE.PATH, {
                                statusType: 'close',
                            }),
                            state: getStatsSortValues({
                                ...params,
                                leadStatus: 'closed_lost',
                            }),
                        }}
                    >
                        <div className="dashboard-processed-lead__number">{data?.confirmed ?? 0}</div>
                        <div className="dashboard-processed-lead__ratio">
                            <FormattedHTMLMessage
                                id="DASHBOARD.APPOINTMENT_PERF.CONFIRMED"
                                values={{
                                    pourcent: data?.created
                                        ? Math.round((100 * (data?.confirmed || 0)) / data.created)
                                        : '-',
                                }}
                            />
                        </div>
                    </Link>
                    <Link
                        className="text-danger d-flex align-items-end"
                        to={{
                            pathname: routeTo(ROUTES.LEADS_ONLINE.PATH, {
                                statusType: 'close',
                            }),
                            state: getStatsSortValues({
                                ...params,
                                leadStatus: 'closed_won',
                            }),
                        }}
                    >
                        <div className="dashboard-processed-lead__number">{data?.notShown ?? 0}</div>
                        <div className="dashboard-processed-lead__ratio">
                            <FormattedHTMLMessage
                                id="DASHBOARD.APPOINTMENT_PERF.NOT_SHOWN"
                                values={{
                                    pourcent: data?.confirmed
                                        ? Math.round((100 * (data?.notShown || 0)) / data.confirmed)
                                        : '-',
                                }}
                            />
                        </div>
                    </Link>
                    <Link
                        className="text-success d-flex align-items-end"
                        to={{
                            pathname: routeTo(ROUTES.LEADS_ONLINE.PATH, {
                                statusType: 'close',
                            }),
                            state: getStatsSortValues({
                                ...params,
                                leadStatus: 'closed_won',
                            }),
                        }}
                    >
                        <div className="dashboard-processed-lead__number">{data?.honored ?? 0}</div>
                        <div className="dashboard-processed-lead__ratio">
                            <FormattedHTMLMessage
                                id="DASHBOARD.APPOINTMENT_PERF.HONORED"
                                values={{
                                    pourcent: data?.created
                                        ? Math.round((100 * (data?.honored || 0)) / data.created)
                                        : '-',
                                }}
                            />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

AppointmentPerformances.defaultProps = {
    data: null,
    loading: null,
    className: null,
};

export default AppointmentPerformances;
