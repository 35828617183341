import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'react-bootstrap';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { useAppSelector } from '@app/hooks';

import ModalDefault from '@app/partials/content/modals/Modal.default';

import DashboardAgendaTabs from '../partials/Agenda/DashboardAgendaTabs';

type AgendaDetailsProps = {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
};

const AgendaDetails = ({ showModal, setShowModal }: AgendaDetailsProps) => {
    const { params } = useAppSelector((state) => state.modal);
    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-hand-pointer" />}
            title={Intl.formatMessage(
                { id: 'DASHBOARD.MODAL.AGENDA.TITLE' },
                {
                    firstname: params?.user?.firstname || '',
                    lastname: params?.user?.lastname || '',
                },
            )}
            body={
                <div className="dashboard-agenda dashboard-agenda__modal">
                    {params?.user?.id && <DashboardAgendaTabs userData={params?.user} />}
                </div>
            }
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button variant="primary" onClick={() => setShowModal(false)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default AgendaDetails;
