import React, {useState} from 'react';
import {Panel} from "primereact/panel";
import classNames from "classnames";
import {Card} from "react-bootstrap";

const CollapsedCard = ({collapse, header, children}) => {
    const [collapsed, setCollapsed] = useState(collapse);

    return (
            <Panel className={classNames('card p-panel--transparent', {
                'card--collapsed': collapsed
            })}
                   headerTemplate={header({
                       collapsed,
                       setCollapsed
                   })}
                   onToggle={() => setCollapsed(!collapsed)}
                   collapsed={collapsed}
                   toggler="header"
                   toggleable>
                <Card.Body>
                    {children}
                </Card.Body>
            </Panel>
    );
};

export default CollapsedCard;
