import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import useFetch from '@app/hooks/useFetch';

import { getOneCustomerActivities } from '@app/crud/customers/customer.crud';

import ActivitiesTimeline from '@app/pages/customers/Partials/customer-life/EventDetailActivities/ActivitiesTimeline';

import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

const Activities = ({ show, setShowActivitiesModal, event }) => {
    const Intl = useIntl();
    const { fetch, data, loading, error } = useFetch({
        fetchAction: getOneCustomerActivities,
    });

    useEffect(() => {
        if (show) {
            if (error) {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                });
                setShowActivitiesModal(false);
            } else {
                fetch(event?.informations?.identifier).then(() => {});
            }
        }
    }, [show, error]);

    return (
        <ModalDefault
            show={show}
            hideModal={() => setShowActivitiesModal(false)}
            icon={<i className="text-primary las la-history fa-2x" />}
            title={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES_DETAIL' })}
            body={
                loading ? (
                    <div style={{ height: '100px' }}>
                        <Loader style={{ width: '5rem', height: '5rem' }} overlay />
                    </div>
                ) : (
                    <ActivitiesTimeline data={data} event={event} modal />
                )
            }
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button variant="primary" onClick={() => setShowActivitiesModal(false)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default Activities;
