// eslint-disable-next-line import/prefer-default-export
export const mapUpdateFinance = (finance) => {
    const updatedFinance = { ...finance };

    delete updatedFinance?.status;
    delete updatedFinance?.id;
    delete updatedFinance?.deadline;

    return {
        ...updatedFinance,
        financialBudget: updatedFinance?.financialBudget > 0 ? updatedFinance?.financialBudget : null,
        financialInput: updatedFinance?.financialInput > 0 ? updatedFinance?.financialInput : null,
        financialRent: updatedFinance?.financialRent > 0 ? updatedFinance?.financialRent : null,
    };
};
