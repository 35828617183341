import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { TimeSlotResponse } from '../apv/dealership.crud';
import { FetchOrderParams, Order, OrdersApiResponse } from '../apv/order.type';

import { OrderResponse } from './order.type';

const { REACT_APP_APV_SC_URL } = process.env;

export function getOrders(params: FetchOrderParams) {
    const formattedParams = { ...params, orderStatus: ['Commande'], orderBy: 'created|DESC' };
    return ApiGateway.get<OrdersApiResponse<Order[]>, CustomAxiosResponse<OrdersApiResponse<Order[]>>>(
        `${REACT_APP_APV_SC_URL}/api/order`,
        { params: formattedParams },
    );
}

export function getOrder(id: string) {
    return ApiGateway.get<ApiResponse<OrderResponse>, CustomAxiosResponse<ApiResponse<OrderResponse>>>(
        `${REACT_APP_APV_SC_URL}/api/order/${id}`,
    );
}

type OrderAppointmentUpdateParams = {
    type?: 'automanager';
    startDate?: string;
    affected?: boolean;
};

export function updateOrderAppointment(id: string, params: OrderAppointmentUpdateParams) {
    return ApiGateway.put(`${REACT_APP_APV_SC_URL}/api/order/appointment/${id}`, params);
}

export interface OrderStatusReason {
    slug: string;
    name: string;
    groupSlug: string;
    groupName: string;
}

export type OrderStatusReasonParams = {
    q?: string;
    created?: string[];
    orderBy?: string;
    items?: number;
    start?: number;
    groupSlug: string;
};

export function getOrderStatusReason(params: OrderStatusReasonParams) {
    return ApiGateway.get<ApiResponse<OrderStatusReason[]>, CustomAxiosResponse<ApiResponse<OrderStatusReason[]>>>(
        `${REACT_APP_APV_SC_URL}/api/order/statusReason`,
        { params },
    );
}

type OrderAppointmentCancelParams = {
    orderStatusReason: string;
    type: 'automanager';
    orderStatusReasonComment?: string;
};

export function cancelOrderAppointment(id: string, params: OrderAppointmentCancelParams) {
    return ApiGateway.put(`${REACT_APP_APV_SC_URL}/api/order/appointment/${id}/cancel`, params);
}

export function getSlotsByOrderAppointmentId(id: string, date: string) {
    return ApiGateway.get<ApiResponse<TimeSlotResponse>, CustomAxiosResponse<ApiResponse<TimeSlotResponse>>>(
        `${REACT_APP_APV_SC_URL}/api/order/${id}/timeslot/${date}`,
    );
}

export async function getDealershipOrdersCounter() {
    const { result } = await ApiGateway.get<
        ApiResponse<{ order: number }>,
        CustomAxiosResponse<ApiResponse<{ order: number }>>
    >(`${REACT_APP_APV_SC_URL}/api/indicator/order`);
    return result?.order;
}

export function setConfirmAffectationOrderAppointment(id: string, param: OrderAppointmentUpdateParams) {
    return ApiGateway.put<{ affected: boolean }, CustomAxiosResponse<ApiResponse<{ affected: boolean }>>>(
        `${REACT_APP_APV_SC_URL}/api/order/appointment/${id}/confirm_affectation`,
        param,
    );
}

export function setConfirmOrderAppointment(id: string) {
    return ApiGateway.put<object, CustomAxiosResponse<ApiResponse<object>>>(
        `${REACT_APP_APV_SC_URL}/api/order/appointment/${id}/accepted`,
    );
}
