import dayjs from 'dayjs';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import { PricingEvent } from '@app/store/stock/stock.type';

import Label from '@app/partials/content/Label';

import Price from '@app/components/formatters/price/Price';

import eventStatusHandler from './helpers/EventStatusHandler';

type StockPricingToDoHistoryEventRecoStatusProps = {
    event: PricingEvent;
};

const StockPricingToDoHistoryEventRecoStatus = ({ event }: StockPricingToDoHistoryEventRecoStatusProps) => (
    <>
        <div className="font-weight-bold">
            <Label variant={eventStatusHandler(event.status.slug)} className="mr-1">
                <strong>{event.status.name}</strong>
            </Label>
            {event.status.slug === 'modified' && (
                <span className={`text-${eventStatusHandler(event.status.slug)}`}>
                    &bull; <Price value={event.newPrice} />
                </span>
            )}
        </div>
        <div className="text-primary">
            <Label variant="primary" className="font-weight-bold">
                {event.author}
            </Label>{' '}
            &bull; {event.role}
        </div>
        <div className="font-size-sm">
            <FormattedHTMLMessage
                id="STOCK.PRICING.HISTORY.ACTION_DATE"
                values={{
                    date: dayjs(event.createdAt.date).format('DD/MM/YYYY [à] HH[h]mm'),
                }}
            />
        </div>
    </>
);

export default StockPricingToDoHistoryEventRecoStatus;
