import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';

import { getPricingReasonsByStatusSlug } from '@app/crud/stock/stock.crud';

import { useFetch } from '@app/hooks';

import ModalDefault from '@app/partials/content/modals/Modal.default';

type ConfirmModalProps = {
    showModal: boolean;
    setShowModal: Function;
    setShowMainModal?: Function;
    setSubModalActive?: Function;
    modified: boolean;
    setReason?: Function;
    setComment?: Function;
    onConfirm: Function;
    loading: boolean;
    warning: boolean;
};

const ConfirmModal = ({
    showModal,
    setShowModal,
    setShowMainModal,
    setSubModalActive,
    modified,
    setReason,
    setComment,
    onConfirm,
    loading,
    warning = false,
}: ConfirmModalProps) => {
    const Intl = useIntl();
    const [internalReason, setInternalReason] = useState(null);
    const [internalComment, setInternalComment] = useState('');
    const [reasons, setReasons] = useState(null);
    const commentRequiredLength = 10;
    const [enabledComment, setEnabledComment] = useState(false);

    const { fetch: fetchReasons, data } = useFetch({
        // @ts-ignore
        fetchAction: getPricingReasonsByStatusSlug,
        resultInterceptor: (response) => response,
    });

    const closeModal = () => {
        setShowModal && setShowModal(false);
        setSubModalActive && setSubModalActive(false);
        setShowMainModal && setShowMainModal(true);
    };

    useEffect(() => {
        fetchReasons('modified');
    }, [fetchReasons]);

    useEffect(() => {
        setInternalReason(null);
        setInternalComment('');
    }, [showModal]);

    useEffect(() => {
        if (setReason) setReason(internalReason);
    }, [internalReason]);

    useEffect(() => {
        if (setComment) setComment(internalComment);
    }, [internalComment]);

    useEffect(() => {
        if (data.result) {
            setReasons(
                // @ts-ignore
                data.result.map((item: any) => ({
                    label: item.id,
                    value: item.description,
                })),
            );
        }
    }, [data]);

    const changeReason = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInternalReason(parseInt(e.target.value));
        setInternalComment('');

        if (data.result) {
            const otherReason = data.result.find((reason) => reason.description === 'Autre');
            if (otherReason && otherReason.id === Number(e.target.value)) {
                setEnabledComment(true);
            } else {
                setEnabledComment(false);
            }
        }
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => closeModal()}
            icon={
                <i
                    className={`text-${modified ? 'primary' : 'success'} las ${
                        modified ? 'la-edit' : 'la-check-circle'
                    } fa-2x`}
                />
            }
            title={
                <span className={`text-${modified ? 'primary' : 'success'}`}>
                    {modified ? (
                        <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.MODIFY.TITLE" />
                    ) : (
                        <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CONFIRM.TITLE" />
                    )}
                </span>
            }
            body={
                <div className="d-flex flex-column align-items-center h-100" style={{ minHeight: '10vh' }}>
                    <p
                        className={`${warning && 'text-dark-orange'} ${
                            modified ? 'd-flex align-items-center order-2 w-100 mb-0 text-left' : 'text-center'
                        }`}
                    >
                        {modified && (
                            <i
                                className={`las la-info-circle fa-3x ${
                                    warning ? 'text-dark-orange' : 'text-dark'
                                } mr-2`}
                            />
                        )}
                        {warning ? (
                            <FormattedHTMLMessage id="STOCK_VEHICLES.MODAL.PRICE.CONFIRM.TEXT_WARNING" />
                        ) : (
                            <FormattedHTMLMessage id="STOCK_VEHICLES.MODAL.PRICE.CONFIRM.TEXT" />
                        )}
                    </p>
                    {modified && (
                        <>
                            <Form.Group className="mt-2 w-100">
                                <Form.Label>
                                    <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.MODIFY.REASON.TITLE" />
                                </Form.Label>
                                <Form.Control as="select" defaultValue={-1} onChange={changeReason}>
                                    <option value={-1} disabled>
                                        {Intl.formatMessage({
                                            id: 'STOCK_VEHICLES.MODAL.PRICE.MODIFY.REASON.PLACEHOLDER',
                                        })}
                                    </option>
                                    {reasons &&
                                        reasons.map((reason: any, key: any) => (
                                            <option key={key} value={reason.label}>
                                                {reason.value}
                                            </option>
                                        ))}
                                </Form.Control>
                            </Form.Group>
                            {enabledComment && (
                                <Form.Group className="mt-5 w-100">
                                    <Form.Label>
                                        <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CONFIRM.REASON.TITLE" />
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setInternalComment(e.target.value);
                                        }}
                                    />
                                    <p className="mt-1 mb-0">
                                        <FormattedHTMLMessage
                                            id="STOCK_VEHICLES.MODAL.PRICE.CONFIRM.REASON.TITLE.REQUIRED"
                                            values={{
                                                caracters: internalComment.trim().length,
                                                requiredLength: commentRequiredLength,
                                                color:
                                                    internalComment.trim().length >= commentRequiredLength
                                                        ? 'success'
                                                        : 'danger',
                                            }}
                                        />
                                    </p>
                                </Form.Group>
                            )}
                        </>
                    )}
                </div>
            }
            footer={
                <div className="d-flex justify-content-center">
                    <Button className="mr-2" variant="outline-primary" onClick={() => closeModal()}>
                        <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.CANCEL" />
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => onConfirm()}
                        disabled={
                            loading ||
                            (modified && enabledComment && internalComment.trim().length < commentRequiredLength)
                        }
                    >
                        <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.CANCEL.REASON.CONFIRM" />
                    </Button>
                </div>
            }
        />
    );
};

export default ConfirmModal;
