export type UserCreationData = {
    email: string;
};

export type GetUsersParams = {
    firtName?: string;
    lastName?: string;
    email?: string;
    research?: string;
    callTransfer?: boolean;
    role?: number;
    outlookPlanning?: boolean;
    leadAlert?: boolean;
    tags?: string;
    dealershipCode?: string;
    active?: boolean;
    roles?: string;
    permissions?: boolean;
};

export interface UsersResponse<T> {
    count: number;
    result: T;
}

export type GetUsersFromAdParams = {
    q: string;
};

export interface UpdateUserParams {
    last_name: string;
    first_name: string;
    email: string;
    cell_phone: undefined;
    phone: undefined;
    job: undefined;
}

export interface AuthorizeResponse {
    url: string;
}

interface DealershipPerimeter {
    code: string;
    name: string;
    type: string;
}

export interface UserReturn {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    dealership_perimeter: DealershipPerimeter[];
    perimeter_codes: string[];
}

export interface DealershipReturn {
    [key: string]: UserReturn[];
}

export interface SearchUserGDPR {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
}

export interface UserGDPR {
    id: number;
    application: string;
    table: string;
    alias: string;
    firstName: string;
    lastName: string;
    updatedDate: UpdatedDate;
    zipCode?: string;
    city?: string;
    email?: string;
    phoneNumber?: string;
    pending: boolean;
}

export interface UpdatedDate {
    date: string;
    timezone_type: number;
    timezone: string;
}

export enum SlugApplication {
    LEAD_CRM = 'lead_crm',
    AUTOCALL_EFF = 'autocall_eff',
    AUTOCALL_BERNARD = 'autocall_bernard',
}

export interface AnonymizeUsers {
    application: UserGDPR['application'];
    table: UserGDPR['table'];
    id: string;
    alias: UserGDPR['alias'];
}

export enum UserPartnerAttributeSlug {
    SALESFORCE_BMW_ID = 'salesforce_bmw_id',
}

export interface UpdateUserPartnerParams {
    user_id: number;
    attributes: {
        [key in UserPartnerAttributeSlug]: string;
    };
}
