import * as dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Image, Row, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';

import { Intl } from '../../../../_metronic/i18n/I18nProvider';
import { routeTo } from '../../../helpers/RoutesHelper';
import useModal from '../../../hooks/useModal';
import Loader from '../../../partials/content/Loader';
import Permission from '../../../partials/content/Permission';
import toast from '../../../partials/content/Toast';
import HeadBand from '../../../partials/layout/HeadBand';
import ROUTES from '../../../router/Routes';
import { fetchOrder } from '../../../store/smart-repair/order/order.thunk';

import { getStateBySlug, HIDDEN_STATUS } from './Helpers/Status';
import Details from './Modals/Details';
import Update from './Modals/Update';

function Order({ history }) {
    const params = useParams();
    const dispatch = useDispatch();
    const [firstLoad, setFirstLoad] = useState(true);
    const { order, error, loading } = useSelector((state) => state.smartRepair.order);
    const [showUpdateModal, toggleUpdateModal] = useModal(false);
    const [showDetailsModal, toggleDetailsModal] = useModal(false);

    const showOrders = useCallback(() => {
        history.push(routeTo(ROUTES.SMARTREPAIR_ORDERS.PATH.ALL));
    }, [history]);

    useEffect(() => {
        dispatch(fetchOrder(params.id));
        setFirstLoad(false);
    }, [dispatch, params.id]);

    useEffect(() => {
        if (error !== null && !firstLoad) {
            toast({
                onShow: showOrders,
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    }, [error, firstLoad, showOrders]);

    if (loading || order === null) {
        return <Loader style={{ width: '5rem', height: '5rem' }} />;
    }

    return (
        <Form className="package">
            <div className="mb-5">
                <HeadBand variant={getStateBySlug(order)}>
                    <Row className="col-lg-12">
                        <Col lg={7}>
                            <Row>
                                <Col lg={6}>
                                    <Image
                                        className="float-right"
                                        src="/media/illustrations/rendez-vous-confirme.svg"
                                    />
                                </Col>
                                <Col className="headband__col" lg={6}>
                                    <div className="headband__title">
                                        <i className="la la-bullhorn mr-3" />
                                        <span className="font-weight-semibold">{order?.orderStatus?.name}</span>
                                    </div>
                                    <span className="font-weight-semibold">
                                        {' '}
                                        {order?.customer?.firstName} {order?.customer?.lastName}{' '}
                                    </span>
                                    a rendez-vous
                                    <span className="font-weight-semibold">
                                        {order?.orderAppointment &&
                                            dayjs(order.orderAppointment.startDate).format(
                                                ' [le] DD MMMM[, à ] HH [h] mm',
                                            )}
                                    </span>
                                    ,
                                    <br />
                                    dans la concession
                                    <span className="font-weight-semibold"> {order?.dealership?.name}</span>.
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </HeadBand>
            </div>
            <Row className="mb-5">
                <Col lg={4}>
                    <Card>
                        <Card.Header className="d-flex align-items-center justify-content-between">
                            <div>
                                <i className="card__icon fas fa-file-invoice" />
                                {Intl.formatMessage({ id: 'SR.ORDER.VIEW.BILLING' })}
                            </div>
                            <Button variant="outline-light" onClick={() => toggleDetailsModal(true)}>
                                <i className="mr-1 fas fa-file-invoice" />
                                {Intl.formatMessage({ id: 'SR.ORDER.VIEW.MOREDETAILS' })}
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.VIEW.NAME' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.customer?.lastName && <span>{order.customer.lastName}</span>}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.VIEW.FIRSTNAME' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.customer?.firstName && <span>{order.customer.firstName}</span>}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.VIEW.MAIL' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.customer?.email && <span>{order.customer.email}</span>}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.VIEW.MOBILE' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.customer?.phone && <span>{order.customer.phone}</span>}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.VIEW.ADDRESS' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.customer?.postalCode && <span>{order.customer.postalCode}</span>}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <Card.Header className="d-flex align-items-center justify-content-between">
                            <div>
                                <i className="card__icon fas fa-calendar-alt" />
                                {Intl.formatMessage({ id: 'SR.ORDER.VIEW.APPOINTMENT' })}
                            </div>
                            {order?.orderAppointment && !HIDDEN_STATUS.includes(order?.orderStatus?.slug) && (
                                <Permission permissions={['SR_UPDATE_ORDER']}>
                                    <Button
                                        variant="outline-light"
                                        disabled={order?.dealership?.isActiveMecaplanning}
                                        onClick={() => toggleUpdateModal(true)}
                                    >
                                        {Intl.formatMessage({ id: 'SR.ORDER.VIEW.UPDATE' })}
                                    </Button>
                                </Permission>
                            )}
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.VIEW.HOUR' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.orderAppointment &&
                                        dayjs(order.orderAppointment.startDate).format(' [le] DD MMMM[, à ] HH [h] mm')}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.VIEW.DEALERSHIP' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.dealership && <span>{order.dealership.name}</span>}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.VIEW.DEALERSHIP' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.dealership && (
                                        <span>
                                            {order.dealership.address}
                                            <br />
                                            {order.dealership.postalCode} {order.dealership.locality}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.VIEW.APPOINTMENT.ID' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.orderAppointment?.rdv && <span>{order.orderAppointment.rdv}</span>}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.VIEW.STATUS' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.orderAppointment?.mecaplanningStatus && (
                                        <span>{order.orderAppointment.mecaplanningStatus}</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.VIEW.RECEIVER' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.orderAppointment?.reception && (
                                        <span>{order.orderAppointment.reception}</span>
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <Card.Header className="d-flex align-items-center justify-content-between">
                            <div>
                                <i className="card__icon fas fa-car" />
                                {Intl.formatMessage({ id: 'SR.ORDER.VIEW.BILLING' })}
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.VIEW.BRAND' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.make}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.VIEW.MODEL' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.model}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'SR.ORDER.VIEW.REGISTRATION' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.registration && <span>{order.registration}</span>}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Header className="border-bottom-0">
                            <i className="card__icon fas fa-tasks" />
                            {Intl.formatMessage({ id: 'SR.ORDER.VIEW.DETAILS' })}
                        </Card.Header>
                        <Card.Body className="p-0">
                            <Table className="m-0" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th width="10%">
                                            {Intl.formatMessage({ id: 'SR.ORDER.TABLE.HEAD.REFERENCE' })}
                                        </th>
                                        <th width="70%">{Intl.formatMessage({ id: 'SR.ORDER.TABLE.HEAD.PRODUCT' })}</th>
                                        <th width="20%">
                                            {Intl.formatMessage({ id: 'SR.ORDER.TABLE.HEAD.FINAL_PRICE' })}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {order?.orderRepairs &&
                                        order.orderRepairs.map((packageItem) => {
                                            if (packageItem?.accepted) {
                                                return packageItem?.price.map((priceItem) => (
                                                    <tr key={priceItem.slug}>
                                                        <td>{priceItem?.slug}</td>
                                                        <td>{priceItem?.name}</td>
                                                        <td>
                                                            {priceItem?.priceTtc && (
                                                                <NumberFormat
                                                                    value={priceItem.priceTtc}
                                                                    thousandSeparator=" "
                                                                    decimalSeparator=","
                                                                    decimalScale="2"
                                                                    fixedDecimalScale
                                                                    displayType="text"
                                                                    suffix=" €"
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                ));
                                            }
                                            return null;
                                        })}
                                </tbody>
                                <tfoot className="tfoot tfoot--total">
                                    <tr>
                                        <td> </td>
                                        <td className="font-weight-bold">
                                            <div className="tfoot__bordered tfoot__float-right tfoot__total">
                                                {Intl.formatMessage({ id: 'SR.ORDER.VIEW.TOTAL' })}:
                                            </div>
                                        </td>
                                        <td className="font-weight-bold">
                                            <div className="tfoot__bordered tfoot__number">
                                                <NumberFormat
                                                    value={order?.totalAmount}
                                                    thousandSeparator=" "
                                                    decimalSeparator=","
                                                    decimalScale="2"
                                                    fixedDecimalScale
                                                    displayType="text"
                                                    suffix=" €"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Update showModal={showUpdateModal} setShowModal={toggleUpdateModal} />
            <Details showModal={showDetailsModal} order={order} setShowModal={toggleDetailsModal} />
        </Form>
    );
}

export default withRouter(Order);
