import { createPackage, updatePackage } from '../../../../store/smart-repair/package/package.thunk';

/**
 * Form submit
 *
 * @param params
 */
// eslint-disable-next-line import/prefer-default-export
export const formSubmit = (params) => {
    const { newPackage, dispatch, data, history, id } = params;

    if (newPackage) {
        dispatch(
            createPackage({
                data,
                history,
            }),
        );
    } else {
        dispatch(updatePackage(id, data));
    }
};
