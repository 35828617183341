import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { alphabeticalSort } from '@app/helpers/ArrayHelper';

import { Dealerships } from '@app/crud/dashboard/dashboard.crud';

import DashboardActivityDealership from './DashboardActivityDealership';

type DashboardActivityDirectorProps = {
    data: Dealerships;
    className?: string;
};

const DashboardActivityDirector = ({ data, className }: DashboardActivityDirectorProps) => (
    <div className={classNames('dashboard-activity', className)}>
        <div className="d-flex align-items-center mb-5">
            <i className="dashboard-activity__icon las la-2x la-sitemap mr-2" />
            <span className="font-size-lg font-weight-bold">
                <FormattedMessage id="DASHBOARD.ACTIVITY.DIRECTOR.TITLE" />
            </span>
        </div>
        <div className="dashboard-activity__dealerships">
            {Object.values(data || {})
                ?.sort?.(alphabeticalSort('usualName'))
                ?.map?.((dealership) => {
                    if (dealership?.usualName) {
                        return <DashboardActivityDealership dealership={dealership} key={dealership.dealershipId} />;
                    }
                    return null;
                })}
        </div>
    </div>
);

DashboardActivityDirector.defaultProps = {
    className: '',
};

DashboardActivityDirector.Skeleton = () => <Skeleton borderRadius="10px" className="w-100 mt-8 flex-grow-1" />;

export default DashboardActivityDirector;
