import React, { useEffect, useState } from 'react';
import { Image, Tab } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import sourcesPictures from '@app/pages/stock/helpers/SourcesPictures';
import StockModalPictures from '@app/pages/stock/modals/StockModalPictures';

import {
    getNbPhotos,
    getPhotosDefaults,
    getPlayer360,
    getRegistrationTemplate,
    getSource360,
} from '@app/partials/content/Vehicle/VehicleHelpers';
import PortletTab from '@app/partials/layout/PortletTab';

const StockVehiclePictures = ({ vehicle, isVN, className }) => {
    const Intl = useIntl();
    const [show, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState([]);
    const [medias, setMedias] = useState([]);
    const [mediasType, setMediasType] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const photosDefaults = getPhotosDefaults(vehicle);
    const source360 = getSource360(vehicle);
    const player360 = getPlayer360(vehicle);
    const nbPhotos = getNbPhotos(vehicle);
    const [activeKey, setActiveKey] = useState(nbPhotos > 0 ? 1 : 2);

    const mediasOnClick = (listMedias, title, idx) => {
        setMedias(listMedias);
        setMediasType(null);
        setTitleModal(title);
        setShowModal(true);
        setActiveIndex(idx);
    };

    const photosQuickView = () => {
        const photos = [...(vehicle?.images?.m || [])];

        return photos
            .splice(0, 4)
            .map((photo, index) => (
                <Image
                    key={index}
                    src={photo}
                    className="cursor-pointer"
                    width="270px"
                    onClick={() =>
                        mediasOnClick(
                            vehicle?.images?.l,
                            Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.MEDIA.PICTURE' }, { nb: nbPhotos || '0' }),
                            index,
                        )
                    }
                />
            ));
    };

    const photosFailuresQuickView = () => {
        const photosFailures = [...(photosDefaults || [])];

        return photosFailures
            .splice(0, 4)
            .map((photo, key) => (
                <Image
                    key={key}
                    src={photo}
                    className="cursor-pointer"
                    width="270px"
                    onClick={() =>
                        mediasOnClick(
                            photosDefaults,
                            Intl.formatMessage(
                                { id: 'STOCK_VEHICLE.CARD.MEDIA.DEFAULT' },
                                { nb: photosDefaults.length || '0' },
                            ),
                            key,
                        )
                    }
                />
            ));
    };

    const media360OnClick = () => {
        const mediaType = source360 && parseInt(source360, 10);
        let player360Medias = player360;

        if (mediaType === 3) {
            const {
                REACT_APP_STAMPY_USER_ID,
                REACT_APP_STAMPY_MARKET_PLACE,
                REACT_APP_STAMPY_TOKEN,
                REACT_APP_STAMPY_SCRIPT_POLYMER_URL,
                REACT_APP_STAMPY_COMPOSANT_URL,
            } = process.env;

            player360Medias = `
                <stampyt-360 token="${REACT_APP_STAMPY_TOKEN}"
                                reference="${btoa(
                                    `${REACT_APP_STAMPY_USER_ID}#${getRegistrationTemplate(
                                        vehicle,
                                        false,
                                    )}#${REACT_APP_STAMPY_MARKET_PLACE}`,
                                )}"></stampyt-360>
                <script type="application/javascript" src="${REACT_APP_STAMPY_SCRIPT_POLYMER_URL}"></script>
                <link rel="import" href="${REACT_APP_STAMPY_COMPOSANT_URL}">
            `;
        }
        setMedias(player360Medias);
        setMediasType(mediaType);
        setTitleModal(Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.MEDIA.360' }));
        setShowModal(true);
    };

    useEffect(() => {
        setActiveKey(nbPhotos > 0 ? 1 : 2);
    }, [setActiveKey, vehicle]);

    if (!nbPhotos > 0 && !photosDefaults.length > 0 && !source360) {
        return null;
    }

    const handleShowModal = (showValue) => {
        setShowModal(showValue);
        if (!showValue) {
            setActiveKey(1);
        }
    };

    return (
        <>
            <PortletTab
                className={className}
                activeKey={activeKey}
                onClick={(e) => {
                    setActiveKey(parseInt(e, 10));
                    if (e === '3') {
                        media360OnClick();
                    }
                }}
            >
                {nbPhotos > 0 && (
                    <Tab
                        eventKey={1}
                        disabled={nbPhotos <= 0}
                        title={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.MEDIA.PICTURE' }, { nb: nbPhotos || '0' })}
                    >
                        <div className="d-flex justify-content-around flex-wrap">
                            {vehicle?.images?.s && photosQuickView()}
                        </div>
                    </Tab>
                )}
                {photosDefaults.length > 0 && !isVN && (
                    <Tab
                        eventKey={2}
                        disabled={photosDefaults.length <= 0}
                        title={Intl.formatMessage(
                            { id: 'STOCK_VEHICLE.CARD.MEDIA.DEFAULT' },
                            { nb: photosDefaults.length || '0' },
                        )}
                    >
                        <div className="d-flex justify-content-around flex-wrap">
                            {photosDefaults && photosFailuresQuickView()}
                        </div>
                    </Tab>
                )}
                {!isVN && source360 && (
                    <Tab eventKey={3} title={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.MEDIA.360' })} />
                )}
            </PortletTab>
            <StockModalPictures
                show={show}
                setShowModal={handleShowModal}
                medias={medias}
                type={mediasType}
                title={titleModal}
                activeIndex={activeIndex}
            />
            {!isVN && source360 && (
                <div style={{ position: 'absolute', right: '60px', top: '25px' }}>
                    <span>{Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.MEDIA.SOURCE' })}</span>
                    <span className=" ml-2 font-weight-bold">{sourcesPictures[parseInt(source360, 10)] || '-'}</span>
                </div>
            )}
        </>
    );
};

export default StockVehiclePictures;
