import apvAdditionalService from './apvAdditionalService.json'
import apvCategory from './apvCategory.json'
import apvDealership from './apvDealership.json'
import apvinStoreDetail from './apvinStoreDetail.json'
import apvInStoreQuotations from './apvInStoreQuotations.json'
import apvOperation from './apvOperation.json'
import apvOrder from './apvOrder.json'
import apvPackage from './apvPackage.json'
import contactLead from './contactLead.json'

const frApv = {
  ...apvPackage,
  ...apvInStoreQuotations,
  ...apvOrder,
  ...apvOperation,
  ...apvAdditionalService,
  ...apvDealership,
  ...apvCategory,
  ...contactLead,
  ...apvinStoreDetail
}

export default frApv;
