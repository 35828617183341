import axios from 'axios';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import type { ColumnProps } from 'primereact/column';
import React, { useEffect, useMemo, useState } from 'react';
import type { ChangeEvent } from 'react';
import { Button, Card, Col, Form, Image, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import useModal from '@app/hooks/useModal';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { isAMail, isAPhoneNumber } from '@app/helpers/ToolsHelper';

import { getUserToAnonymize } from '@app/crud/autouser/user.crud';
import { SearchUserGDPR, SlugApplication, UserGDPR } from '@app/crud/autouser/user.types';

import variantTypes from '@app/constants';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import ManageAnonymization from '@app/pages/administration/rgpd/modals/ManageAnonymization';
import { actions } from '@app/store/rgpd/rgpd.store';

import { CalendarFilter } from '@app/partials/content/CalendarFilter';
import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

const ConsoleRGPD = () => {
    const Intl = useIntl();
    const dispatch = useAppDispatch();

    const { selectedUsers } = useAppSelector((state) => state.rgpd);
    const [paramsUsers, setParamsUsers] = useState(undefined);
    const [showManageAnonymization, toggleManageAnonymization] = useModal(false, 'manage_anonymization');
    const [showLoadingUsers, setLoadingUsersModal] = useModal(false);
    const [cancelToken, setCancelToken] = useState(null);

    const { filters } = useFilters({
        name: 'users_rgpd',
        persisted: false,
    });

    const [doSearch, setDoSearch] = useState(false);

    const extraParams = useMemo(
        () => ({
            doSearch,
            cancelToken: cancelToken?.token,
            ...paramsUsers,
        }),
        [paramsUsers, doSearch, cancelToken?.token],
    );

    const {
        data: users,
        loading: isLoadingUsers,
        handleFilter,
        error,
    } = useTableHandlers({
        filterStore: 'users_rgpd',
        fetchAction: getUserToAnonymize,
        withStore: false,
        extraParams,
        doSearch: (_params, extraParamsSearch) => extraParamsSearch?.doSearch,
        paramsInterceptor: (params, _filters, extraParamsInterceptor) => ({
            ...params,
            email: extraParamsInterceptor?.email,
            phoneNumber: extraParamsInterceptor?.phone,
            firstName: extraParamsInterceptor?.first_name?.trim(),
            lastName: extraParamsInterceptor?.last_name?.trim(),
            cancelToken: extraParamsInterceptor?.cancelToken,
        }),
    });
    const { register, errors, handleSubmit, watch, setValue } = useFormContext();

    const handleAnonymizeClick = (user: UserGDPR[]) => {
        toggleManageAnonymization(true, user);
    };

    const handleAnonymizationComplete = () => {
        dispatch(actions.UpdateUsersSelected([]));
        toggleManageAnonymization(false);
    };

    const last_name = watch('last_name');
    const first_name = watch('first_name');
    const phone = watch('phone');
    const email = watch('email');
    const isFormEmpty = !Object.values(watch()).some((value) => value);
    const nameRegex = /[^\w\s\-']/g;

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue('email', e.target.value?.replace(/[^a-zA-Z0-9@._-]/g, ''));
    };

    const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue('phone', e.target.value?.replace(/[^0-9]/g, ''));
    };

    const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue('first_name', e.target.value?.replace(nameRegex, ''));
    };

    const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue('last_name', e.target.value?.replace(nameRegex, ''));
    };

    // Note : other application slugs will be added
    const applicationMappings: Record<
        string,
        {
            name: string;
            className: string;
        }
    > = {
        [SlugApplication.LEAD_CRM]: {
            name: Intl.formatMessage({ id: 'TRANSLATOR.AUTOMANAGER' }),
            className: 'text-initial',
        },
        [SlugApplication.AUTOCALL_EFF]: {
            name: Intl.formatMessage({ id: 'TRANSLATOR.AUTOCALL_EFF' }),
            className: 'text-purple-light',
        },
        [SlugApplication.AUTOCALL_BERNARD]: {
            name: Intl.formatMessage({ id: 'TRANSLATOR.AUTOCALL_BERNARD' }),
            className: 'text-purple-light',
        },
    };

    const onSubmit = (data: SearchUserGDPR) => {
        const newCancelToken = axios.CancelToken.source();

        setParamsUsers(data);
        setDoSearch(true);
        dispatch(actions.UpdateUsersSelected([]));
        setCancelToken(newCancelToken);
    };

    const handleHideModal = () => {
        setLoadingUsersModal(false);
        if (cancelToken) {
            cancelToken.cancel();
        }
    };

    const idBodyTemplate = (user: UserGDPR) => {
        const alias = user?.alias?.toLowerCase();
        const isClient = ['contact', 'client'].includes(alias);
        const isProspect = alias === 'prospect';

        return (
            <div>
                <div>
                    <FormattedHTMLMessage id="CONSOLE_RGPD.TABLE.CONTENT.ID" />
                    {user?.id && <span className="mr-2">{user.id}</span>}
                </div>
                <span
                    className={classNames('bullet-point', {
                        'text-primary': isClient,
                        'text-danger': isProspect,
                    })}
                >
                    {isClient && Intl.formatMessage({ id: 'TRANSLATOR.CLIENT' })}
                    {isProspect && Intl.formatMessage({ id: 'TRANSLATOR.PROSPECT' })}
                    {!isClient && !isProspect && user?.alias}
                </span>
            </div>
        );
    };

    const nameBodyTemplate = (user: UserGDPR) => (
        <>
            {user?.firstName && <span className="px-1">{user.firstName}</span>}
            {user?.lastName && <span className="px-1">{user.lastName}</span>}
        </>
    );

    const contactBodyTemplate = (user: UserGDPR) => (
        <>
            {user?.phoneNumber && <div>{user.phoneNumber}</div>}
            {user?.email && <div>{user.email}</div>}
        </>
    );

    const cityBodyTemplate = (user: UserGDPR) => (
        <>
            {user?.zipCode && <div>{user.zipCode}</div>}
            {user?.city && <div>{user.city}</div>}
        </>
    );

    const sourceBodyTemplate = (user: UserGDPR) => {
        if (!user?.application) return null;

        const sourceMapped = applicationMappings[user.application] || {
            name: user.application,
            className: 'text-initial',
        };

        return <div className={classNames('bullet-point', sourceMapped.className)}>{sourceMapped.name}</div>;
    };

    const dateCreatedBodyTemplate = (user: UserGDPR) => dayjs(user?.updatedDate?.date).format('DD/MM/YYYY [à] HH[h]mm');

    const handleOnClick = () => {
        if (selectedUsers.length === 0) return;
        handleAnonymizeClick(selectedUsers);
    };

    const actionsBodyTemplate = (user: UserGDPR) => {
        if (user.pending) return;

        const isUserSelected = selectedUsers.some((selectedUser) => selectedUser.id === user.id);
        return (
            <Button className="p-2 px-3" variant="primary" disabled={!isUserSelected} onClick={handleOnClick}>
                {Intl.formatMessage({ id: 'CONSOLE_RGPD.TABLE.ACTION.ANONYMIZE' })}
            </Button>
        );
    };

    const inputFilter = (name: string) => <InputFilter name={name} onChange={handleFilter} />;

    const calendarFilter = (name: string) => <CalendarFilter name={name} onChange={handleFilter} />;

    const cols = [
        {
            selectionMode: 'multiple' as ColumnProps['selectionMode'],
            style: { width: '50px' },
        },
        {
            field: 'id',
            header: <FormattedHTMLMessage id="CONSOLE_RGPD.TABLE.HEAD.INFOS" />,
            body: idBodyTemplate,
            sortable: true,
        },
        {
            field: 'name',
            header: Intl.formatMessage({ id: 'CONSOLE_RGPD.TABLE.HEAD.NAME' }),
            body: nameBodyTemplate,
            sortable: true,
        },
        {
            field: 'contact',
            header: Intl.formatMessage({ id: 'CONSOLE_RGPD.TABLE.HEAD.CONTACT' }),
            body: contactBodyTemplate,
            sortable: true,
        },
        {
            field: 'city',
            header: Intl.formatMessage({ id: 'CONSOLE_RGPD.TABLE.HEAD.CITY' }),
            body: cityBodyTemplate,
            sortable: true,
        },
        {
            field: 'source',
            header: Intl.formatMessage({ id: 'CONSOLE_RGPD.TABLE.HEAD.SOURCE' }),
            filter: true,
            filterElement: inputFilter('source'),
            body: sourceBodyTemplate,
            sortable: true,
        },
        {
            field: 'maj',
            header: Intl.formatMessage({ id: 'CONSOLE_RGPD.TABLE.HEAD.MAJ' }),
            filter: true,
            filterElement: calendarFilter('maj'),
            body: dateCreatedBodyTemplate,
        },
        {
            field: 'action',
            header: Intl.formatMessage({ id: 'CONSOLE_LEADS.TABLE.HEAD.ACTIONS' }),
            body: actionsBodyTemplate,
            style: { width: '125px' },
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    useEffect(() => {
        if (error) {
            toast({
                variant: variantTypes.DANGER,
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    }, [error]);

    useEffect(() => {
        setLoadingUsersModal(isLoadingUsers);
    }, [isLoadingUsers]);

    useEffect(() => {
        dispatch(actions.UpdateButtonVisibility(doSearch));
    }, [doSearch]);

    return (
        <>
            <Card>
                <ModalDefault
                    show={showLoadingUsers}
                    hideModal={handleHideModal}
                    title={Intl.formatMessage({
                        id: 'CONSOLE_RGPD.MODAL.LOADER_TITLE',
                    })}
                    body={
                        <>
                            <div className="text-center">
                                <FormattedHTMLMessage id="CONSOLE_RGPD.MODAL.LOADER_MESSAGE" />
                            </div>
                            <Loader block style={{ width: '5rem', height: '5rem' }} />
                        </>
                    }
                />
                <Card.Body>
                    <Row className="justify-content-md-center ">
                        <div className="mb-10 font-size-h5 font-weight-bold">
                            <FormattedMessage id="CONSOLE_RGPD.HEAD.LABEL" />
                        </div>
                    </Row>
                    <Form className="package" onSubmit={handleSubmit(onSubmit)}>
                        <Row className="justify-content-md-center flex-xs-column mx-40">
                            <Col lg={3} xs={12} className="px-lg-4 px-0 kt-radius radius">
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="CONSOLE_RGPD.HEAD.FORM.MAIL" />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        placeholder={Intl.formatMessage({
                                            id: 'CONSOLE_RGPD.HEAD.PLACEHOLDER.MAIL',
                                        })}
                                        className={classNames({ 'is-invalid': !!errors.email })}
                                        onChange={handleEmailChange}
                                        ref={register({
                                            validate: () => {
                                                if (email && !isAMail(email)) {
                                                    return Intl.formatMessage({
                                                        id: 'CUSTOMERS.MODAL.CREATE.EMAIL.FORMAT',
                                                    });
                                                }

                                                return true;
                                            },
                                        })}
                                    />
                                    <ErrorForm errors={errors} name="email" />
                                </Form.Group>
                            </Col>

                            <Col lg={2} xs={12} className="px-lg-4 px-0 kt-radius radius">
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="CONSOLE_RGPD.HEAD.FORM.PHONE" />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone"
                                        placeholder={Intl.formatMessage({
                                            id: 'CONSOLE_RGPD.HEAD.PLACEHOLDER.PHONE',
                                        })}
                                        className={classNames({ 'is-invalid': !!errors.phone })}
                                        onChange={handlePhoneChange}
                                        ref={register({
                                            validate: () => {
                                                if (phone && !isAPhoneNumber(phone)) {
                                                    return Intl.formatMessage({
                                                        id: 'CUSTOMERS.MODAL.CREATE.PHONE.FORMAT',
                                                    });
                                                }

                                                return true;
                                            },
                                        })}
                                    />
                                    <ErrorForm errors={errors} name="phone" />
                                </Form.Group>
                            </Col>

                            <Col lg={3} xs={12} className="px-lg-4 px-0 kt-radius radius">
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="CONSOLE_RGPD.HEAD.FORM.FIRSTNAME" />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="first_name"
                                        placeholder={Intl.formatMessage({
                                            id: 'CONSOLE_RGPD.HEAD.PLACEHOLDER.FIRSTNAME',
                                        })}
                                        className={classNames({ 'is-invalid': !!errors.first_name })}
                                        onChange={handleFirstNameChange}
                                        ref={register({
                                            validate: () => {
                                                if (last_name.trim().length > 0 && first_name.trim().length === 0) {
                                                    return Intl.formatMessage({
                                                        id: 'FORM.ERROR.REQUIRED',
                                                    });
                                                }

                                                return true;
                                            },
                                        })}
                                    />
                                    <ErrorForm errors={errors} name="first_name" />
                                </Form.Group>
                            </Col>

                            <Col lg={3} xs={12} className="px-lg-4 px-0 kt-radius radius">
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="CONSOLE_RGPD.HEAD.FORM.LASTNAME" />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="last_name"
                                        placeholder={Intl.formatMessage({
                                            id: 'CONSOLE_RGPD.HEAD.PLACEHOLDER.LASTNAME',
                                        })}
                                        className={classNames({ 'is-invalid': !!errors.last_name })}
                                        onChange={handleLastNameChange}
                                        ref={register({
                                            validate: () => {
                                                if (first_name.trim().length > 0 && last_name.trim().length === 0) {
                                                    return Intl.formatMessage({
                                                        id: 'FORM.ERROR.REQUIRED',
                                                    });
                                                }

                                                return true;
                                            },
                                        })}
                                    />
                                    <ErrorForm errors={errors} name="last_name" />
                                </Form.Group>
                            </Col>
                            <Button
                                className="btn-icon kt-radius-lg-5 kt-radius-0 kt-top-right-radius-5 kt-bottom-right-radius-5 mt-8"
                                variant="primary"
                                type="submit"
                                disabled={isFormEmpty}
                            >
                                <i className="fas fa-search" />
                            </Button>
                            {doSearch ? (
                                <div style={{ height: '120px' }}>
                                    {!isLoadingUsers && (
                                        <div className="mb-5 font-size-h4">
                                            <div>
                                                <FormattedHTMLMessage
                                                    id="CONSOLE_RGPD.TABLE.VIEW.FOUND.NUMBER"
                                                    values={{
                                                        count: users?.results?.length ?? 0,
                                                        inputForm: Object.values(paramsUsers)
                                                            .filter((value) => value !== '')
                                                            .join(', '),
                                                        countForm: Object.values(paramsUsers).filter(
                                                            (value) => value !== '',
                                                        ).length,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <Row className="justify-content-md-center">
                                    <Image
                                        className="mt-10"
                                        src="/media/pages/customers/utilisateur-console-rgpd.svg"
                                    />
                                </Row>
                            )}
                        </Row>
                    </Form>

                    {doSearch && users && (
                        <FiltersProvider value={filters}>
                            {/* @ts-ignore */}
                            <HoCDataTable
                                value={users?.results || []}
                                totalRecords={users?.results?.length}
                                selection={selectedUsers}
                                onSelectionChange={(e: { value: UserGDPR[] }): void => {
                                    dispatch(actions.UpdateUsersSelected(e.value));
                                }}
                                onFilter={handleFilter}
                                emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                                rowClassName={(user: UserGDPR) => {
                                    const isPending = user?.pending;

                                    return {
                                        'p-disabled-row': isPending,
                                    };
                                }}
                            >
                                {dynamicColumns}
                            </HoCDataTable>
                        </FiltersProvider>
                    )}
                </Card.Body>
            </Card>
            <ManageAnonymization
                showModal={showManageAnonymization}
                setShowModal={toggleManageAnonymization}
                onComplete={handleAnonymizationComplete}
            />
        </>
    );
};

export default withRouter(ConsoleRGPD);
