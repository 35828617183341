import React from 'react';
import { Card } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Permission from '@app/partials/content/Permission';

import { SettingsItem } from './Settings.type';

type SettingsCardProps = {
    item: SettingsItem;
};

const SettingsCard = ({ item }: SettingsCardProps) => (
    <Permission permissions={item.permissions}>
        <Card className="card--shadowed settings-card">
            <Link to={item.route} className="h-100">
                <Card.Body className="px-6 py-4 text-right d-flex flex-column justify-content-between h-100">
                    <i className={`la la-7x ${item.icon} text-${item.color}`} />
                    <h4 className={`text-${item.color}`}>
                        <FormattedHTMLMessage id={item.messageId} />
                    </h4>
                </Card.Body>
            </Link>
        </Card>
    </Permission>
);

export default SettingsCard;
