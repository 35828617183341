import React, {useEffect, useState} from 'react';
import {debounce} from "../../helpers/ToolsHelper";
import KTUtil from "../../../_metronic/_assets/js/util";

const DimensionsHandler = ({children}) => {
    const [resized, setResized] = useState(false);
    const [orientation, setOrientation] = useState(false);
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    useEffect(() => {
        const handleResize = debounce(() => {
            setResized(false);

            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }, 100);

        const handleOrientation = debounce(() => {
            setResized(false);

            setOrientation(window.innerWidth > window.innerHeight ? 'Landscape' : 'Portrait');
        }, 100);

        const debouncedHandleResize = () => {
            if (KTUtil.isDesktopDevice()) {
                setResized(true);
                handleResize();
            }
        }

        const debounceOrientationChange = () => {
            setResized(true);
            handleOrientation();
        }

        window.addEventListener('orientationchange', debounceOrientationChange);

        return () => {
            window.removeEventListener('orientationchange', debounceOrientationChange);
        }
    });

    return resized ? null : children;
};

export default DimensionsHandler;
