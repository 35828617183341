const getStatusVariant = (slug) => {
    const variants = {
        transaction_finalized: 'success',
        transmission_carried_out: 'danger',
        transaction_canceled: 'danger',
        in_progress: 'warning',
        created: 'warning',
    };

    return variants[slug] || 'primary';
};

export default getStatusVariant;
