import classNames from 'classnames';
import { Panel } from 'primereact/panel';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import useModal from '@app/hooks/useModal';

import SwitchInput from '@app/partials/content/SwitchInput';
import { getVehicleInternalIdTemplate } from '@app/partials/content/Vehicle/VehicleHelpers';

import InStockPreview from './InStockPreview';
import OutStockPreview from './OutStockPreview';

const CarSearch = ({ title, onlyStock, defaultVehicles }) => {
    const [showVehicle, setShowVehicle] = useModal(false, null, true);
    const [inStock, setInStockState] = useState(true);
    const { register, getValues, watch, setValue } = useFormContext();
    const Intl = useIntl();
    const vehicles = watch('vehicles');

    const handleChange = (value) => {
        setInStockState(!value);
    };

    useEffect(() => {
        register('vehicles');

        if (!getValues('vehicles')) {
            setValue('vehicles', defaultVehicles);
        }
    }, [register, setValue]);

    const inStockVehicle = vehicles ? vehicles.filter((vehicle) => getVehicleInternalIdTemplate(vehicle, null)) : [];
    const outStockVehicle = vehicles ? vehicles.filter((vehicle) => !getVehicleInternalIdTemplate(vehicle, null)) : [];

    return (
        <Panel className="p-panel--transparent" header={title} toggler="header" toggleable={!onlyStock}>
            {!onlyStock && (
                <Row>
                    <Col lg={6}>
                        <Button variant="primary" onClick={setShowVehicle}>
                            {Intl.formatMessage({ id: 'CAR_SEARCH.SEARCH_IN_STOCK' })}
                        </Button>
                    </Col>
                    <Col lg={6}>
                        <SwitchInput
                            name="active"
                            className="switch-input--primary"
                            onChange={handleChange}
                            labelFirst={<FormattedMessage id="CAR_SEARCH.IN_STOCK" />}
                            labelSecond={<FormattedMessage id="CAR_SEARCH.OUT_STOCK" />}
                            active={!inStock}
                            form={false}
                        />
                    </Col>
                </Row>
            )}
            <div
                className={classNames({
                    'mt-5': !onlyStock,
                })}
            >
                {inStock || onlyStock ? (
                    <InStockPreview
                        vehicles={inStockVehicle}
                        number={vehicles?.length}
                        showVehicle={showVehicle}
                        setShowVehicle={setShowVehicle}
                    />
                ) : (
                    <OutStockPreview
                        vehicles={outStockVehicle}
                        number={vehicles?.length}
                        showVehicle={showVehicle}
                        setShowVehicle={setShowVehicle}
                    />
                )}
            </div>
        </Panel>
    );
};

CarSearch.defaultProps = {
    title: <FormattedMessage id="CAR_SEARCH.TITLE" />,
    onlyStock: false,
    defaultVehicles: [],
};

export default CarSearch;
