import React from 'react';
import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';
import { LayoutContextConsumer } from '@src/_metronic/layout/LayoutContext';
import BreadCrumbs from '@src/_metronic/layout/sub-header/components/BreadCrumbs';

import { useAppSelector } from '@app/hooks';

import subHeader from '@app/partials/HoC/subHeader';

function UserSubHeader(props) {
    const params = useParams();

    const newUser = typeof params.id === 'undefined';
    const { user } = useAppSelector((state) => state.user);

    const { subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle } = props;

    const title = Intl.formatMessage(
        {
            id: newUser ? 'AUTOUSER.USERS.CREATE' : 'AUTOUSER.USERS.EDIT',
        },
        { name: `${user?.first_name} ${user?.last_name}` || '' },
    );

    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: 'MENU.AUTOUSER' }),
        },
        {
            title: Intl.formatMessage({ id: 'MENU.AUTOUSER.USERS' }),
        },
        {
            title,
        },
    ];

    return (
        title && (
            <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
                <div className={`kt-container ${subheaderContainerCssClasses}`}>
                    <div className="kt-subheader__main">
                        {subheaderMobileToggle && (
                            <button
                                type="button"
                                className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                                id="kt_subheader_mobile_toggle"
                            >
                                <span />
                            </button>
                        )}

                        <LayoutContextConsumer>
                            {() => (
                                <>
                                    <h3 className="kt-subheader__title">{title}</h3>
                                    <BreadCrumbs items={breadcrumb} />
                                </>
                            )}
                        </LayoutContextConsumer>
                    </div>
                    <div className="d-flex align-items-center">
                        <Link to="/autouser/users">
                            <Button className="mr-2" variant="outline-primary">
                                {Intl.formatMessage({ id: 'TRANSLATOR.BACK' })}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        )
    );
}

export default subHeader(UserSubHeader);
