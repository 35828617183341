import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { getTransformation } from '../../helpers/DashboardHelper';

const DashboardOnlineStrongStats = ({ data, className }) => {
    const onlineWithStrongStats = ['vo', 'vn', 'vd', 'reprise'];
    const details = Object.entries(data || {})?.filter?.(([type]) =>
        onlineWithStrongStats.some((allowedType) => type === allowedType),
    );

    const countersStrongTransformations = details.reduce(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (acc, [_type, detail]) => ({
            won: acc.won + (detail?.wonStrong || 0),
            total: acc.total + (detail?.takenStrong || 0),
        }),
        {
            won: 0,
            total: 0,
        },
    );

    const countersGeneralTransformations = details.reduce(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (acc, [_type, detail]) => ({
            won: acc.won + (detail?.won || 0),
            total: acc.total + (detail?.taken || 0),
        }),
        {
            won: 0,
            total: 0,
        },
    );

    const strongTransformationPercentage = getTransformation(
        countersStrongTransformations?.won,
        0,
        countersStrongTransformations?.total,
    );
    const generalTransformationPercentage = getTransformation(
        countersGeneralTransformations?.won,
        0,
        countersGeneralTransformations?.total,
    );

    return (
        <div className={classNames('dashboard-online-perf d-flex flex-column', className)}>
            <div className="flex-grow-1">
                <div className="font-size-lg font-weight-bold d-flex flex-wrap align-items-center justify-content-between">
                    <i className="dashboard-general-perf__icon las la-2x la-mouse-pointer mr-2" />
                    <div className="flex-grow-1 d-flex align-items-center">
                        <FormattedMessage id="DASHBOARD.ONLINE_PERF.TITLE" />
                    </div>
                </div>
                <div className="d-flex flex-wrap flex-column flex-lg-row justify-content-between mt-3 mb-1">
                    <div className="dashboard-block ml-lg-2 d-flex flex-grow-1 flex-wrap align-items-center text-dark-50">
                        <div className="dashboard-general-perf__taux flex-md-grow-0 flex-grow-1 font-weight-semibold">
                            {strongTransformationPercentage}&nbsp;%
                        </div>
                        <div className="font-weight-bold mx-3 line-height-md text-nowrap">
                            <FormattedHTMLMessage id="DASHBOARD.GENERAL_PERF.STRONG_TRANSFORMATION" />
                            &nbsp;({countersStrongTransformations?.won}/{countersStrongTransformations?.total})
                        </div>
                    </div>
                    <div className="dashboard-block ml-lg-2 d-flex flex-grow-1 flex-wrap align-items-center text-dark-50">
                        <div className="dashboard-general-perf__taux flex-md-grow-0 flex-grow-1 font-weight-semibold">
                            {generalTransformationPercentage}&nbsp;%
                        </div>
                        <div className="font-weight-bold mx-3 line-height-md text-nowrap">
                            <FormattedHTMLMessage id="DASHBOARD.GENERAL_PERF.TRANSFORMATION" />
                            &nbsp;({countersGeneralTransformations?.won}/{countersGeneralTransformations?.total})
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

DashboardOnlineStrongStats.Skeleton = () => (
    <Skeleton borderRadius="10px" height="100px" className="d-flex flex-column flex-lg-grow-1 mt-lg-2 mt-6 p-35" />
);
export default DashboardOnlineStrongStats;
