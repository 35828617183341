import React, {useContext, useRef, useState} from 'react';
import {Button} from "react-bootstrap";
import {MultiSelect, MultiSelectChangeEvent} from "primereact/multiselect";
import {FiltersContext} from "./FiltersProvider";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
import {empty} from '../../helpers/ToolsHelper';
import {Intl} from '../../../_metronic/i18n/I18nProvider';

type MultiSelectFilterProps = {
    name: string,
    display: 'chip' | 'comma',
    optionLabel: string,
    optionValue: string,
    options: string[],
    pending: boolean,
    onChange?: Function,
    onPendingChange: Function,
    onRelease?: Function,
    resultType: string,
    labelPanelFooterTemplate: string,
    selectedItemsLabel: string,
    filter: boolean,
    valueHandler?: Function,
    clearButton: React.ReactNode,
    labelClearButton: string,
    placeholder: string
}

const MultiSelectFilter = (
    {
        name,
        display,
        optionLabel,
        optionValue,
        options,
        pending,
        onChange,
        onPendingChange,
        onRelease,
        resultType,
        labelPanelFooterTemplate,
        selectedItemsLabel,
        filter,
        valueHandler,
        clearButton,
        labelClearButton,
        placeholder
    }: MultiSelectFilterProps) => {
    const filters = useContext(FiltersContext);
    const ref = useRef();
    const [changed, setChanged] = useState(false);

    const handleRelease = () => {
        if (onRelease && changed && pending) {
            onRelease();
            setChanged(false);
        }
    }

    const handleClose = () => {
        const body = document.querySelector('body');
        if (body) {
            body.click();
        }
    }

    const handleChange = (e: MultiSelectChangeEvent) => {
        // @ts-ignore
        const removedCross = e?.originalEvent?.target?.classList?.contains?.('p-multiselect-token-icon');
        let currentValue = e.value;
        if (valueHandler) {
            currentValue = valueHandler(currentValue);
        }
        if (resultType === 'string') {
            currentValue = currentValue.join(',');
        }
        const value = {
            [name]: {
                value: currentValue,
                componentValue: e.value
            }
        };

        if (onChange && (removedCross || !pending)) {
            onChange({...value});
        } else {
            setChanged(true);
            onPendingChange({...value});
        }
    }
    const handleClear = () => {
        // @ts-ignore
        const value = {
            [name]: {
                value: '',
                componentValue: []
            }
        };

        if (onChange ) {
            onChange({...value});
        } else {
            setChanged(true);
            onPendingChange({...value});
        }
    }

    const getValue = () => {
        let currentValue = options?.length > 0 ? (filters[name]?.componentValue || filters[name]?.value) : [];

        if (typeof currentValue === 'string' && !empty(currentValue)) {
            currentValue = currentValue.split(',');
        }

        return currentValue;
    }

    return (
        <MultiSelect display={display}
                     filter={filter}
                     emptyFilterMessage={<FormattedMessage id="MULTISELECT.FILTER.NO_RESULT" />}
                     inputRef={ref}
                     optionLabel={optionLabel}
                     optionValue={optionValue}
                     placeholder={placeholder}
                     value={getValue()}
                     options={options?.length > 0 ? options : []}
                     onChange={handleChange}
                     onHide={handleRelease}
                     panelClassName={classNames({
                         'p-multiselect-panel--empty': options?.length === 0
                     })}
                     maxSelectedLabels={0}
                     // @ts-ignore
                     selectedItemsLabel={<FormattedMessage id={selectedItemsLabel} values={{itemCount:getValue()?.length}} />}
                     panelHeaderTemplate={filter ? null : () => <></>}
                     panelFooterTemplate={(option) => {
                         if (options?.length === 0) return <FormattedMessage id="MULTISELECT.NO_RESULT" />;
                         return(
                             <>
                                 {clearButton && getValue()?.length > 0 &&
                                 <Button className={'mx-3 my-2 float-right'}
                                         onClick={() => handleClear()}>
                                     {Intl.formatMessage({id: labelClearButton})}
                                 </Button>
                                 }
                                 <Button className={'mx-3 my-2 float-right'}
                                         onClick={() => {
                                             handleRelease();
                                             handleClose();
                                         }}>
                                     {Intl.formatMessage({id: labelPanelFooterTemplate})}
                                 </Button>;
                             </>
                         )
                     }}/>
    );
};

MultiSelectFilter.defaultProps = {
    display: 'chip',
    optionLabel: 'name',
    optionValue: 'slug',
    options: [],
    pending: true,
    resultType: 'array',
    labelPanelFooterTemplate : 'TRANSLATOR.VALIDATE',
    filter: false,
    valueHandler: null,
    clearButton: false,
    labelClearButton: 'TRANSLATOR.CLEAR'
}

export default MultiSelectFilter;
