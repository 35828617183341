import dayjs from 'dayjs';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = '#FFFFFF';
        tooltipEl.style.borderRadius = '5px';
        tooltipEl.style.color = '#868AA8';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';
        tooltipEl.style.boxShadow = '0px 9px 13px #00000029';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};

// eslint-disable-next-line import/prefer-default-export
export const externalTooltipHandler = (context, chartType, view) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set Text
    if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((b) => b.lines);

        const tableHead = document.createElement('thead');
        tableHead.style.background = '#F2F6F9 0% 0% no-repeat padding-box';

        titleLines.forEach((title) => {
            const tr = document.createElement('tr');

            tr.style.borderWidth = 0;
            const th = document.createElement('th');
            th.style.borderWidth = 0;
            th.style.fontWeight = 'normal';
            th.style.paddingLeft = '18px';
            th.style.paddingRight = '88px';
            th.style.paddingTop = '8px';
            th.style.paddingBottom = '7px';
            th.style.fontSize = '12px';
            const text = document.createTextNode(
                view === 'month' ? dayjs(title).format('[Semaine du] DD MMMM') : dayjs(title).format('dddd DD MMMM'),
            );
            th.appendChild(text);
            tr.appendChild(th);
            tableHead.appendChild(tr);
        });

        const tableBody = document.createElement('tbody');

        bodyLines.forEach((body, i) => {
            const colors = tooltip.labelColors[i];

            const span = document.createElement('span');
            span.style.borderColor = colors.borderColor;
            span.style.borderWidth = '2px';
            span.style.marginRight = '10px';
            span.style.height = '10px';
            span.style.width = '10px';
            span.style.display = 'inline-block';
            span.style.borderRadius = '50%';

            const tr = document.createElement('tr');
            tr.style.backgroundColor = 'inherit';
            tr.style.borderWidth = 0;

            const td = document.createElement('td');
            td.style.borderWidth = 0;
            td.style.paddingLeft = '18px';
            td.style.paddingTop = '8px';
            td.style.paddingBottom = '8px';
            td.style.fontSize = '12px';

            td.appendChild(span);

            let text;
            // Specific to display price
            if (
                body[0].includes(Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.PRICE' }))
            ) {
                span.style.background = '#E1F0FF';
                text = document.createTextNode(`${body} €`);
                td.style.fontWeight = 'bold';
            } else {
                span.style.background = colors.backgroundColor;
                const valueBlock = document.createElement('span');
                valueBlock.style.fontWeight = 'bold';
                valueBlock.style.marginRight = '5px';
                valueBlock.appendChild(document.createTextNode(tooltip.dataPoints[i].formattedValue));
                // specific tooltips to views chart
                if (chartType === 'views') {
                    text = document.createTextNode(
                        Intl.formatMessage(
                            { id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.TOOLTIPS.VIEWS' },
                            {
                                counter: tooltip.dataPoints[i].formattedValue,
                                type: i,
                                content: tooltip.dataPoints[i].dataset.label,
                            },
                        ),
                    );
                } else {
                    text = document.createTextNode(
                        Intl.formatMessage(
                            {
                                id:
                                    tooltip.dataPoints[i].formattedValue > 1
                                        ? 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.LEADS_TITLE'
                                        : 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.LEAD_TITLE',
                            },
                            {
                                content: tooltip.dataPoints[i].dataset.label.includes(
                                    Intl.formatMessage({
                                        id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.LOCALS',
                                    }),
                                )
                                    ? tooltip.dataPoints[i].formattedValue > 1
                                        ? Intl.formatMessage({
                                              id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.LOCALS',
                                          })
                                        : Intl.formatMessage({
                                              id: 'STOCK_VEHICLE.CARD.HISTORICAL_PRICE_CHANGE.CAPTION.LOCAL',
                                          })
                                    : tooltip.dataPoints[i].dataset.label,
                            },
                        ),
                    );
                }
                td.appendChild(valueBlock);
            }

            td.appendChild(text);

            tr.appendChild(td);
            tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector('table');

        // Remove old children
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
    tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
    tooltipEl.style.font = tooltip.options.bodyFont.string;
};
