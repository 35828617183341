import { filter, isEmpty, isNil, omitBy, sumBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Image, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';

import useModal from '@app/hooks/useModal';

import { routeTo } from '@app/helpers/RoutesHelper';

import { setExpertSendQuote, setIncompatibleOrder } from '@app/crud/smartRepair/smart-repair.crud';

import ROUTES from '@app/router/Routes';
import { actions } from '@app/store/smart-repair/quotations/smart-repair.store';
import { fetchQuotation } from '@app/store/smart-repair/quotations/smart-repair.thunk';

import Editor from '@app/partials/content/Editor';
import GalleryFullscreen from '@app/partials/content/GalleryFullscreen';
import Loader from '@app/partials/content/Loader';
import OverlayBtn from '@app/partials/content/OverlayBtn';
import toast from '@app/partials/content/Toast';

import { Intl } from '../../../../../_metronic/i18n/I18nProvider';
import { calculateTotalPrice } from '../Helpers/calculateTotalPrice';
import AskAdditionalInfo from '../Modals/AskAdditionalInfo';
import Packages from '../Modals/Packages';
import TimelineProgress from '../Partials/TimelineProgress';

import QuotationCtaCompatibleOrder from './QuotationCtaCompatibleOrder';
import QuotationCtaIncompatibleOrder from './QuotationCtaIncompatibleOrder';
import QuotationEditingResume from './QuotationEditingResume';
import QuotationGeneralComment from './QuotationGeneralComment';
import QuotationHeader from './QuotationHeader';
import QuotationInfoCustomer from './QuotationInfoCustomer';
import QuotationInputPrice from './QuotationInputPrice';
import QuotationResumed from './QuotationResumed';
import QuotationVehicleUi from './QuotationVehicleUi';

function Quotation({ history }) {
    const { register, handleSubmit, watch } = useForm();
    const params = useParams();
    const dispatch = useDispatch();
    const [firstLoad, setFirstLoad] = useState(true);
    const [selectedOrderRepair, setSelectedOrderRepair] = useState();
    const [displayResume, setDisplayResume] = useState(false);
    const [selectedQuotations, setSelectedQuotations] = useState([]);
    const { quotation, acceptedQuotations, selectedPackages, error, loading } = useSelector(
        (state) => state.smartRepair.quotation,
    );
    const [showPricesModal, togglePricesModal] = useModal(false);
    const { REACT_APP_SMART_REPAIR_URL } = process.env;
    const [totalPrice, setTotalPrice] = useState();
    const [displayUi, setDisplayUi] = useState();
    const [complete, setComplete] = useState(false);
    const [expertCommentSaved, setExpertCommentSaved] = useState();
    const [showAskAdditionalInfo, toggleAskAdditionalInfo] = useState(false);
    const expertComments = watch();
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    const isPending = quotation?.orderStatus?.slug === 'pending';
    const isNewComplement = quotation?.orderStatus?.slug === 'new_complement';
    const isNotPendingOrNewComplement = !(isPending || isNewComplement);
    const orderStatusGroupSlug = quotation?.orderStatus?.groupSlug === 'to_be_appraised';

    const showOrders = useCallback(() => {
        history.push(routeTo(ROUTES.QUOTATIONS.PATH));
    }, [history]);

    const saveComment = (e) => {
        setExpertCommentSaved(e.target);
    };

    useEffect(() => {
        dispatch(actions.setClearAcceptedQuotationsAndSelectedPackages());
        dispatch(fetchQuotation(params.id));
        setFirstLoad(false);
    }, [dispatch, params.id]);

    useEffect(() => {
        // Refresh UX & UI when packege is selected by user
        if (acceptedQuotations && !isEmpty(acceptedQuotations)) {
            setTotalPrice(calculateTotalPrice(acceptedQuotations));
            setDisplayUi(
                Object.values(acceptedQuotations)
                    .flatMap((arr) => arr)
                    .every((obj) => obj.compatible) ?? false,
            );
        } else {
            setDisplayUi(null);
        }
    }, [acceptedQuotations]);

    useEffect(() => {
        if (error !== null && !firstLoad) {
            toast({
                onShow: showOrders,
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    }, [error, firstLoad, showOrders]);

    if (loading || quotation === null) {
        return <Loader style={{ width: '5rem', height: '5rem' }} />;
    }

    const handleAddQuote = async (data) => {
        const acceptedQuotationsValues = Object.values(acceptedQuotations);
        const orderId = acceptedQuotationsValues?.[0][0].orderId;
        const myArrayToApi = [];
        if (!orderId) return;
        setLoadingUpdate(true);
        acceptedQuotationsValues.forEach((repairs) => {
            const repairIds = repairs.map((repair) => repair.id);
            const repairPrices = repairs.map((repair) => repair.slug);
            const expertComment = data[repairs[0].id];
            myArrayToApi.push(
                omitBy(
                    {
                        id: repairIds[0],
                        price: repairPrices[0] !== null ? repairPrices : null,
                        expertComment,
                    },
                    isNil,
                ),
            );
        });

        await setExpertSendQuote(orderId, { orderRepairs: myArrayToApi })
            .then((response) => {
                setLoadingUpdate(false);
                if (response?.result) {
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.INFO.VALID.MESSAGE.API' }),
                    });
                    setComplete(true);
                    dispatch(fetchQuotation(params.id));
                } else {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.INFO.UNVALID.MESSAGE.API' }),
                    });
                }
            })
            .catch(() => {
                setLoadingUpdate(false);
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.INFO.UNVALID.MESSAGE.API' }),
                });
            });
    };

    const handleIncompatibleQuote = async (data) => {
        const acceptedQuotationsValues = Object.values(acceptedQuotations);
        const orderId = acceptedQuotationsValues?.[0][0].orderId;
        const myArrayToApi = [];
        if (!orderId) return;
        setLoadingUpdate(true);
        acceptedQuotationsValues.forEach((repairs) => {
            const repairIds = repairs.map((repair) => repair.id);
            const repairPrices = repairs.map((repair) => repair.slug);
            const compatible = repairs.every((repair) => repair.compatible);
            const expertComment = data[repairs[0].id];
            myArrayToApi.push(
                omitBy(
                    {
                        id: repairIds[0],
                        price: repairPrices[0] !== null ? repairPrices : null,
                        compatible,
                        expertComment,
                    },
                    isNil,
                ),
            );
        });
        await setIncompatibleOrder(orderId, { orderRepairs: myArrayToApi })
            .then((response) => {
                setLoadingUpdate(false);
                if (response?.result) {
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.LEAD.INFO.VALID.MESSAGE.API' }),
                    });
                    setComplete(true);
                    dispatch(fetchQuotation(params.id));
                } else {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.LEAD.INFO.UNVALID.MESSAGE.API' }),
                    });
                }
            })
            .catch(() => {
                setLoadingUpdate(false);
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.LEAD.INFO.UNVALID.MESSAGE.API' }),
                });
            });
    };

    const addAdditionalInfo = () => {
        if (acceptedQuotations) {
            Object.values(acceptedQuotations).map((items) =>
                dispatch(
                    actions.setAcceptedQuotations(
                        items.map((item) => ({
                            id: item.id,
                            orderId: item.orderId,
                            priceTtc: item.priceTtc,
                            totalPackagesPrice: item.totalPackagesPrice,
                            slug: item.slug,
                            zoneName: item.zoneName,
                            package: item.package,
                            compatible: item.compatible,
                            reference: item.reference,
                            expertComment: expertComments[item.id],
                        })),
                    ),
                ),
            );
        }

        toggleAskAdditionalInfo(true);
    };

    const addZoneInfoToFilesObject = (item) => {
        if (item.files) {
            return Object.values(item.files).map((v) => ({
                ...v,
                zoneName: item.zoneBody.zone.name,
                name: item.zoneBody.name,
            }));
        }
    };

    const templateGalleryFullscreen = (item) => {
        if (!item) return;
        return (
            <figure className="smart-repair-fullscreen__container">
                <Image
                    src={`${REACT_APP_SMART_REPAIR_URL}${item.url}`}
                    onError={(e) => {
                        e.target.src = '/media/illustrations/error-photo.svg';
                    }}
                    alt={Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.ALT.FULLSCREEN.IMG' })}
                    style={{ width: '100%', display: 'block' }}
                />
                <figcaption className="smart-repair-fullscreen__figcaption">
                    {item.zoneName} • {item.name}
                    <br />({Intl.formatMessage({ id: 'SMART.REPAIR.PHOTO.CUSTOMER' })} {item.libelle})
                </figcaption>
            </figure>
        );
    };

    const sumOfPackagesSelected = (itemId) => {
        if (!itemId) return;
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const filteredPackages = filter(selectedPackages, (selectedPackages) => selectedPackages.repairId === itemId);
        const sumPriceTtc = sumBy(filteredPackages, 'priceTtc');
        return sumPriceTtc;
    };

    return (
        <Form className="package">
            {loadingUpdate && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <div className="mb-5">
                <QuotationHeader quotation={quotation} />
            </div>
            <QuotationInfoCustomer quotation={quotation} />
            <TimelineProgress events={quotation} />
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Header className="border-bottom-0 smart-repair-card--border-bottom">
                            <i className="card__icon fas fa-wrench" />
                            {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.TABLE.HEAD.REPAIR' })}
                        </Card.Header>
                        <Card.Body className="px-17">
                            {quotation &&
                                quotation?.orderRepairs.map((orderRepair) => (
                                    <div key={orderRepair?.reference}>
                                        <Row className="mt-12">
                                            <Col lg={12} className="text-center mb-10">
                                                <span className="smart-repair-card__zone">
                                                    {orderRepair?.zoneBody?.zone?.name}
                                                </span>
                                            </Col>
                                            <Col lg={6}>
                                                <span className="font-weight-bold">{orderRepair?.zoneBody?.name}</span>
                                                <br />
                                                <span className="font-weight-bold smart-repair-card__repair-color">
                                                    • {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.SAVE.REPAIR' })}{' '}
                                                    •
                                                </span>
                                                <div className="smart-repair-fullscreen__container-thumbnail">
                                                    <GalleryFullscreen
                                                        galleries={addZoneInfoToFilesObject}
                                                        id={orderRepair.reference}
                                                        data={orderRepair}
                                                        templateFullscreen={templateGalleryFullscreen}
                                                    />
                                                </div>
                                                <span className="font-weight-bold smart-repair-card__grey mb-5 d-block">
                                                    {Intl.formatMessage({
                                                        id: 'SMART.REPAIR.QUOTATION.COMMENTS.CUSTOMER',
                                                    })}
                                                </span>
                                                <Editor
                                                    value={
                                                        isEmpty(orderRepair?.comment)
                                                            ? Intl.formatMessage({
                                                                  id: 'SMART.REPAIR.QUOTATION.COMMENT',
                                                              })
                                                            : orderRepair?.comment
                                                    }
                                                    className="smart-repair-card__info-text mb-5 pt-5 pl-2"
                                                    config={{
                                                        minHeight: 25,
                                                        buttons: '',
                                                        statusbar: false,
                                                        readonly: true,
                                                        inline: true,
                                                    }}
                                                />
                                                <span className="font-weight-bold smart-repair-card__grey">
                                                    {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.ESTIMATE' })}
                                                </span>
                                                <div className="mt-5 d-block d-md-flex justify-content-between align-items-center">
                                                    <QuotationInputPrice
                                                        orderRepairItem={orderRepair}
                                                        quotationStored={quotation}
                                                        setSelectedOrderRepairItem={setSelectedOrderRepair}
                                                        displayPackageModal={togglePricesModal}
                                                        sumOfPackages={sumOfPackagesSelected(orderRepair.id)}
                                                    />
                                                    {quotation?.orderStatus?.groupSlug === 'to_be_appraised' && (
                                                        <div className="d-flex">
                                                            {orderStatusGroupSlug && (
                                                                <>
                                                                    <QuotationCtaCompatibleOrder
                                                                        orderRepairItem={orderRepair}
                                                                        displayResume={setDisplayResume}
                                                                        expertComment={expertCommentSaved}
                                                                        selectedQuotations={selectedQuotations}
                                                                        sumOfPackages={sumOfPackagesSelected(
                                                                            orderRepair.id,
                                                                        )}
                                                                    />
                                                                    <QuotationCtaIncompatibleOrder
                                                                        orderRepairItem={orderRepair}
                                                                        displayResume={setDisplayResume}
                                                                        expertComment={expertCommentSaved}
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                <Form.Group>
                                                    {orderStatusGroupSlug && (
                                                        <Form.Control
                                                            ref={register}
                                                            as="textarea"
                                                            type="text"
                                                            rows={2}
                                                            onChange={(e) => saveComment(e, orderRepair)}
                                                            name={orderRepair.id}
                                                            placeholder={Intl.formatMessage({
                                                                id: 'SMART.REPAIR.QUOTATION.TEXTAREA.PLACEHOLDER',
                                                            })}
                                                        />
                                                    )}
                                                    {isNotPendingOrNewComplement && (
                                                        <Form.Control
                                                            readOnly
                                                            as="textarea"
                                                            type="text"
                                                            rows={2}
                                                            name={orderRepair.id}
                                                            placeholder={
                                                                isEmpty(orderRepair?.expertComment)
                                                                    ? Intl.formatMessage({
                                                                          id: 'SMART.REPAIR.QUOTATION.COMMENT',
                                                                      })
                                                                    : orderRepair?.expertComment
                                                            }
                                                        />
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <QuotationVehicleUi item={orderRepair} quotation={quotation} />
                                        </Row>
                                        <hr />
                                    </div>
                                ))}
                            <QuotationGeneralComment quotation={quotation} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col lg={12}>
                    <Card>
                        {displayResume &&
                            !showAskAdditionalInfo &&
                            !complete &&
                            Object.keys(acceptedQuotations).length > 0 && (
                                <QuotationEditingResume
                                    quotation={quotation}
                                    displayUi={displayUi}
                                    complete={complete}
                                    acceptedQuotations={acceptedQuotations}
                                    totalPrice={totalPrice}
                                />
                            )}
                        {orderStatusGroupSlug && !complete && (
                            <div className="mr-10 my-5 d-flex justify-content-end">
                                <span>
                                    <Button variant="btn btn-outline-primary mr-5" onClick={() => addAdditionalInfo()}>
                                        {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.ASK' })}
                                    </Button>
                                </span>
                                <OverlayBtn
                                    tooltipMessage={Intl.formatMessage({
                                        id: 'SMART.REPAIR.QUOTATION.TOOLTIPS.ACTIONS.MISSING',
                                    })}
                                    toggleColor={displayUi}
                                    data={Object.keys(acceptedQuotations).length}
                                    dataCompared={quotation.orderRepairs.length}
                                    validMessage={Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.VALIDATION' })}
                                    unValidMessage={Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.BTN.UNVALID' })}
                                    colorFirst="primary"
                                    colorSecond="danger"
                                    position="top"
                                    ctaOne={handleSubmit(handleAddQuote)}
                                    ctaTwo={handleSubmit(handleIncompatibleQuote)}
                                />
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>
            <Packages
                showModal={showPricesModal}
                quotation={selectedOrderRepair}
                setShowModal={togglePricesModal}
                setSelectedQuotations={setSelectedQuotations}
                selectedPackages={selectedPackages}
            />
            {!!quotation && isNotPendingOrNewComplement && (
                <QuotationResumed quotation={quotation} acceptedQuotations={acceptedQuotations} />
            )}
            {!!quotation && showAskAdditionalInfo && (
                <AskAdditionalInfo
                    acceptedQuotations={acceptedQuotations}
                    showModal={showAskAdditionalInfo}
                    quotation={quotation}
                    setShowModal={toggleAskAdditionalInfo}
                    completeSend={setComplete}
                />
            )}
        </Form>
    );
}

export default withRouter(Quotation);
