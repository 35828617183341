import React from "react";
import {Form} from "react-bootstrap";
import produce from "immer";
import {useFormContext} from "react-hook-form";
import NumberFormat from "react-number-format";

export default function PriceZone(props) {
    const methods = useFormContext();
    const { title } = props;
    const categories = props?.segments?.bodyTypeSegment || [];

    const prices = methods.watch(props.name);

    const handleInputsChange = (inputs, priceIndex) => {
        if (priceIndex !== -1) {
            methods.setValue(props.name, produce(prices, draftPrices => {
                draftPrices[priceIndex] = {
                    ...draftPrices[priceIndex],
                    ...inputs
                }
            }));
        }
    }

    const getCurrentIndex = (category) => {
        const currentPriceIndex = prices.findIndex((price) => {
            return price.makeSegment === props?.makeSegment
                && price.priceZone === props?.id
                && price.bodyType === category?.id;
        });

        const price = currentPriceIndex !== -1
            ? prices[currentPriceIndex]
            : currentPriceIndex;

        return [
            price,
            currentPriceIndex
        ];
    };

    return (
        <div className="range range--separate">
            {prices?.length > 0 &&
            <>
                <div className="range__title kt-align-center">{title}</div>
                {
                    categories.map((category, key) => {
                        const [price, currentPriceIndex] = getCurrentIndex(category);
                        return (
                            <div className="d-flex" key={`${props?.makeSegment}_${props?.id}_${category?.name}`}>
                                <div className="d-flex align-items-center">
                                    <Form.Group>
                                        <Form.Control type="text" className="kt-align-center" disabled
                                                      value={category.name}/>
                                    </Form.Group>
                                    <Form.Group className="ml-5 mr-3">
                                        <Form.Control type="checkbox"
                                                      name="active"
                                                      style={{width: '20px'}}
                                                      checked={price?.active}
                                                      onChange={(e) => {
                                                          handleInputsChange({
                                                              [e.target.name]: e.target.checked,
                                                              code: '',
                                                              price: undefined
                                                          }, currentPriceIndex);
                                                      }}
                                                      className="text-center"/>
                                    </Form.Group>
                                </div>
                                <div className="mx-2">
                                    <Form.Group>
                                        <Form.Control type="text"
                                                      name="code"
                                                      value={price?.code || ''}
                                                      disabled={!price?.active}
                                                      onChange={(e) => {
                                                          handleInputsChange({
                                                              [e.target.name]: e.target.value
                                                          }, currentPriceIndex);
                                                      }}
                                                      className="text-center"/>
                                    </Form.Group>
                                </div>
                                <div className="mx-2">
                                    <Form.Group>
                                        <NumberFormat value={price?.price}
                                                      className="form-control text-center"
                                                      decimalSeparator="."
                                                      thousandSeparator=" "
                                                      disabled={!price?.active}
                                                      allowedDecimalSeparators={[',', '.']}
                                                      onValueChange={(values) => {
                                                          handleInputsChange({
                                                              price: values.floatValue
                                                          }, currentPriceIndex);
                                                      }}/>
                                    </Form.Group>
                                </div>
                            </div>
                        );
                    })
                }
            </>
            }
        </div>
    );
}
