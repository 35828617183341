import React from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import useTags from '@app/pages/customers/Hooks/useTags';
import TagsList from '@app/pages/customers/Partials/TagsList';
import { Lead } from '@app/store/customers/customers.store';
import { actions } from '@app/store/modal/modal.store';

import Editor from '@app/partials/content/Editor';

type CommentProps = {
    comment: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    callback?: Function;
    label?: string;
    containerClassName?: string;
    readOnly: boolean;
    withTags?: boolean;
    lead?: Lead;
};
const Comment = ({ comment, callback, label, containerClassName, readOnly, withTags, lead }: CommentProps) => {
    const { tags, selectedTags, linkTagToLead, unlinkTagToLead } = useTags({
        lead,
    });
    const intl = useIntl();
    const dispatch = useDispatch();

    return (
        <div className={`d-flex flex-column comment ${containerClassName || ''}`}>
            <div className="d-flex justify-content-between">
                <span className="font-weight-semibold">
                    <i className="la la-comment la-lg mr-2" />
                    {intl.formatMessage({ id: `CUSTOMER.VIEW.LIFE.DETAIL.INFO.${label}` })}
                </span>
                {!readOnly && (
                    <Button
                        className="p-0"
                        onClick={() =>
                            dispatch(
                                actions.modalChange('comment', {
                                    callback,
                                    withTags,
                                    comment,
                                    lead,
                                }),
                            )
                        }
                        // @ts-ignore
                        variant="outline-link"
                    >
                        <i className="la la-pen p-0 text-muted" />
                        <u className="ml-2 font-size-sm" />
                    </Button>
                )}
            </div>
            <Editor
                // @ts-ignore
                value={comment || '-'}
                className="mt-4"
                config={{
                    minHeight: 50,
                    buttons: '',
                    statusbar: false,
                    readonly: true,
                    inline: true,
                }}
            />
            <TagsList
                lead={lead}
                tags={tags}
                selectedTags={selectedTags}
                limited
                showAll
                linkTagToLead={linkTagToLead}
                unlinkTagToLead={unlinkTagToLead}
            />
        </div>
    );
};

Comment.defaultProps = {
    callback: () => {},
    label: 'COMMENT',
    containerClassName: '',
    withTags: false,
    lead: {},
};

export default Comment;
