import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useCountdown } from './hooks';

import './countdown.scss';

type CountdownProps = {
    date: number;
    duration: number;
};

const Countdown = ({ date, duration }: CountdownProps) => {
    const targetDate = date;
    const { days, hours, minutes, seconds, distance } = useCountdown(targetDate);

    return (
        distance > 0 &&
        date && (
            <div
                className="countdown"
                style={{
                    height: '40px',
                    borderRadius: '20px',
                    overflow: 'hidden',
                }}
            >
                <span
                    className="d-flex align-items-center text-danger font-weight-bold border-right border-danger pl-3 pr-2"
                    style={{ backgroundColor: '#F8D4D4' }}
                >
                    <FormattedMessage id="STOCK.PRICING.COUNTDOWN.TITLE" values={{ duration }} />{' '}
                    <i className="las la-info-circle ml-2 text-danger" title={new Date(date).toString()} />
                </span>
                <span className="d-flex align-items-center pl-2 pr-3">
                    <FormattedMessage id="STOCK.PRICING.COUNTDOWN.REMAINING_TITLE" />
                    &nbsp;
                    <strong>
                        <span className="digits">{days * 24 + hours}</span>h{' '}
                        <span className="digits">
                            {minutes < 10 && '0'}
                            {minutes}
                        </span>
                        m{' '}
                        <span className="digits">
                            {seconds < 10 && '0'}
                            {seconds}
                        </span>
                        s
                    </strong>
                </span>
            </div>
        )
    );
};

export default Countdown;
