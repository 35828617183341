import dayjs from 'dayjs';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { Locale } from '@src/_metronic/i18n/I18nProvider';
import { vehicleType } from '@src/utils/vehicleType';

import { CustomAxiosResponse } from '@app/helpers/AxiosHelper';
import { getDateByFormat } from '@app/helpers/DateHelper';

import { updateLeadPartExchange } from '@app/crud/customers/customer.crud';
import { LeadDetails, PartExchange } from '@app/crud/customers/customer.type';
import { FuelType, Gearbox, Versions } from '@app/crud/customers/stock.type';
import { mapUpdatePartExchange } from '@app/crud/mapping/partExchange.map';

import BrandModelVersionFinish from '@app/partials/content/BrandModelVersionFinish';
import { DateTimePicker } from '@app/partials/content/DateTimePicker';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

type EditPartExchangeProps = {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    partExchange: PartExchange;
    leadId: number;
    fetchData: (id: string) => Promise<CustomAxiosResponse<LeadDetails>>;
    isLeadAchatCash: boolean;
};
const EditPartExchange = ({
    showModal,
    setShowModal,
    partExchange,
    leadId,
    fetchData,
    isLeadAchatCash,
}: EditPartExchangeProps) => {
    const [loading, setLoading] = useState(false);
    const [hasCertificate, setHasCertificate] = useState(true);
    const [versions, setVersions] = useState(null);
    const methods = useForm();
    const intl = useIntl();
    const { register, errors, control, handleSubmit, reset } = methods;

    const onError = () => {
        toast({
            variant: 'danger',
            message: intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const onSubmit = (data: {
        hasCertificate: boolean;
        registrationCertificate: string;
        registrationCertificateAbsenceReason: string;
        model: string;
        modelOther: string;
    }) => {
        delete data.hasCertificate;

        if (hasCertificate && isLeadAchatCash) {
            data.registrationCertificate = `20${data.registrationCertificate}`;
            data.registrationCertificateAbsenceReason = '';
        } else {
            data.registrationCertificate = '';
        }

        if (data.model === intl.formatMessage({ id: 'CUSTOMERS.MODAL.PART_EXCHANGE.NONE' }) && data.modelOther) {
            data.model = data.modelOther;
            delete data.modelOther;
        }

        setLoading(true);
        updateLeadPartExchange(partExchange.id, mapUpdatePartExchange({ ...partExchange, ...data }))
            .then(() => {
                toast({
                    variant: 'success',
                    message: intl.formatMessage({ id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.SUCCESS' }),
                });
                fetchData(leadId.toString()).then(() => {});
                setLoading(false);
                setShowModal(false);
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.RECOVERY.UPDATE_FAILED' }),
                });
                setLoading(false);
            });
    };

    const onVersionsUpdated = (data: Versions) => {
        setVersions(data);
    };

    const yearNavigatorTemplate = (e: any) => (
        <Dropdown
            value={e.value}
            options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            className="p-ml-2"
            style={{ lineHeight: 1 }}
        />
    );

    useEffect(() => {
        setHasCertificate(!!partExchange.registrationCertificate || !partExchange.registrationCertificateAbsenceReason);
        reset({
            ...(partExchange || {}),
            registrationCertificate: partExchange?.registrationCertificate?.substring(2),
            model: partExchange?.model,
        });
    }, [partExchange]);

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-undo" />}
            title={intl.formatMessage({ id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.TITLE' })}
            body={
                <FormProvider {...methods}>
                    <Form.Group>
                        <Form.Label>
                            {intl.formatMessage({ id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.REGISTRATION' })}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            // @ts-ignore
                            ref={register({
                                required: isLeadAchatCash
                                    ? intl.formatMessage({ id: 'AGENDA.FORM.ERROR.REQUIRED' })
                                    : '',
                            })}
                            name="registration"
                        />
                        <ErrorForm errors={errors} name="registration" />
                    </Form.Group>
                    {isLeadAchatCash && (
                        <Form.Group>
                            <Form.Label>
                                {intl.formatMessage({ id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.VIN' })}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                // @ts-ignore
                                ref={register({
                                    validate: (value) =>
                                        value.length === 0 ||
                                        value.length === 17 ||
                                        intl.formatMessage({ id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.VIN_ERROR' }),
                                })}
                                name="vin"
                            />
                            <ErrorForm errors={errors} name="vin" />
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Form.Label>
                            {intl.formatMessage({ id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.REGISTRATION_DATE' })}
                        </Form.Label>
                        <Controller
                            control={control}
                            name="dateFirstRegistration"
                            defaultValue={null}
                            rules={{ required: intl.formatMessage({ id: 'AGENDA.FORM.ERROR.REQUIRED' }) }}
                            render={({ onChange, value }) => (
                                <DateTimePicker
                                    locale={Locale}
                                    dateFormat="DD/MM/YYYY"
                                    className={`${errors.dateStart ? 'is-invalid' : ''}`}
                                    onChange={(e: { value: string | number | Date | dayjs.Dayjs }) => {
                                        const startValue = dayjs(e.value).isValid() ? dayjs(e.value) : '';
                                        if (startValue) {
                                            onChange(startValue.format('YYYY-MM-DD'));
                                        }
                                    }}
                                    value={getDateByFormat(value, 'YYYY-MM-DD', '')}
                                    yearNavigator
                                    monthNavigator
                                    yearRange={`1980:${new Date().getFullYear()}`}
                                    maxDate={new Date()}
                                    readOnlyInput
                                    yearNavigatorTemplate={yearNavigatorTemplate}
                                />
                            )}
                        />
                        <ErrorForm errors={errors} name="dateFirstRegistration" />
                    </Form.Group>
                    <BrandModelVersionFinish
                        onLoading={setLoading}
                        onVersionsUpdated={onVersionsUpdated}
                        defaultsValues={{
                            make: partExchange.make,
                            model: partExchange.model,
                            version: partExchange.version,
                            typeVarVersPrf: partExchange.typeVarVersPrf,
                            dateFirstRegistration: dayjs(partExchange.dateFirstRegistration).format('DD/MM/YYYY'),
                        }}
                    />
                    {versions?.filters?.fuel && (
                        <Form.Group>
                            <Form.Label>
                                {intl.formatMessage({ id: 'CUSTOMERS.MODAL.PART_EXCHANGE.FUEL_TYPE' })}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="fuelType"
                                rules={{ required: true }}
                                render={({ onChange, value }) => (
                                    <Dropdown
                                        value={value}
                                        options={Object.values(versions.filters.fuel).map((fuelType: FuelType) => ({
                                            value: fuelType.Name,
                                            label: fuelType.Name,
                                        }))}
                                        onChange={(e) => {
                                            onChange(e.value);
                                        }}
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder={intl.formatMessage({ id: 'CUSTOMERS.MODAL.APE_DROPDOWN.TITLE' })}
                                    />
                                )}
                            />
                        </Form.Group>
                    )}
                    {isLeadAchatCash && versions?.filters?.gearbox && (
                        <Form.Group>
                            <Form.Label>
                                {intl.formatMessage({ id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.GEARBOX' })}
                            </Form.Label>
                            <Controller
                                control={control}
                                name="gearbox"
                                rules={{ required: true }}
                                render={({ onChange, value }) => (
                                    <Dropdown
                                        value={value}
                                        options={Object.values(versions.filters.gearbox).map((gearbox: Gearbox) => ({
                                            value: gearbox.Name,
                                            label: gearbox.Name,
                                        }))}
                                        onChange={(e) => {
                                            onChange(e.value);
                                        }}
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder={intl.formatMessage({ id: 'CUSTOMERS.MODAL.APE_DROPDOWN.TITLE' })}
                                    />
                                )}
                            />
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Form.Label>{intl.formatMessage({ id: 'CUSTOMERS.MODAL.PART_EXCHANGE.MILEAGE' })}</Form.Label>
                        <Controller
                            name="mileage"
                            defaultValue={null}
                            control={control}
                            rules={{
                                required: isLeadAchatCash
                                    ? intl.formatMessage({ id: 'AGENDA.FORM.ERROR.REQUIRED' })
                                    : '',
                            }}
                            render={({ onChange, value }) => (
                                <InputGroup className="mb-2">
                                    <NumberFormat
                                        value={value}
                                        className="form-control"
                                        thousandSeparator=" "
                                        onValueChange={(values) => {
                                            onChange(values.floatValue || null);
                                        }}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text>km</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            )}
                        />
                        <ErrorForm errors={errors} name="mileage" />
                    </Form.Group>
                    {!isLeadAchatCash && (
                        <>
                            <Form.Group>
                                <Form.Label>
                                    {intl.formatMessage({ id: 'CUSTOMERS.MODAL.PART_EXCHANGE.COMMENT' })}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    // @ts-ignore
                                    ref={register()}
                                    name="comment"
                                />
                                <ErrorForm errors={errors} name="comment" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {intl.formatMessage({ id: 'CUSTOMERS.MODAL.PART_EXCHANGE.AMOUNT' })}
                                </Form.Label>
                                <Controller
                                    name="estimatedPrice"
                                    defaultValue={null}
                                    control={control}
                                    render={({ onChange, value }) => (
                                        <InputGroup className="mb-2">
                                            <NumberFormat
                                                value={value}
                                                className="form-control"
                                                thousandSeparator=" "
                                                onValueChange={(values) => {
                                                    onChange(values.floatValue || null);
                                                }}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text>€</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    )}
                                />
                            </Form.Group>
                        </>
                    )}
                    {isLeadAchatCash && (
                        <>
                            <Form.Group>
                                <Form.Label>
                                    {intl.formatMessage({ id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.TYPE' })}
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="vehicleType"
                                    rules={{ required: intl.formatMessage({ id: 'AGENDA.FORM.ERROR.REQUIRED' }) }}
                                    render={({ onChange, value }) => (
                                        <Dropdown
                                            value={value}
                                            options={vehicleType}
                                            itemTemplate={(itemValue) => `${itemValue.code} - ${itemValue.title}`}
                                            optionLabel="code"
                                            optionValue="code"
                                            onChange={(e) => {
                                                onChange(e.value);
                                            }}
                                            placeholder={intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.APE_DROPDOWN.TITLE',
                                            })}
                                            emptyFilterMessage={intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.APE_DROPDOWN.SEARCH.NO_RESULT',
                                            })}
                                            filter
                                            filterBy="code"
                                        />
                                    )}
                                />
                                <ErrorForm errors={errors} name="vehicleType" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {intl.formatMessage({ id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.CERTIFICATE' })}
                                </Form.Label>
                                <Controller
                                    name="hasCertificate"
                                    defaultValue={null}
                                    render={() => (
                                        <div className="d-flex">
                                            <div>
                                                <RadioButton
                                                    onChange={() => {
                                                        setHasCertificate(true);
                                                    }}
                                                    checked={hasCertificate}
                                                />
                                                <label htmlFor="dealership" className="ml-3 my-0">
                                                    {intl.formatMessage({
                                                        id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.CERTIFICATE_YES',
                                                    })}
                                                </label>
                                            </div>
                                            <div className="ml-12">
                                                <RadioButton
                                                    onChange={() => {
                                                        setHasCertificate(false);
                                                    }}
                                                    checked={!hasCertificate}
                                                />
                                                <label htmlFor="dealership" className="ml-3 my-0">
                                                    {intl.formatMessage({
                                                        id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.CERTIFICATE_NO',
                                                    })}
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                />
                            </Form.Group>
                            {hasCertificate ? (
                                <Form.Group>
                                    <Form.Label>
                                        {intl.formatMessage({ id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.NUM_FORMULE' })}
                                    </Form.Label>
                                    <div className="d-flex">
                                        <InputGroup.Text id="inputGroupPrepend">20</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            // @ts-ignore
                                            ref={register({
                                                required: intl.formatMessage({ id: 'AGENDA.FORM.ERROR.REQUIRED' }),
                                                validate: (value) =>
                                                    value.length === 9 ||
                                                    intl.formatMessage({
                                                        id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.REGISTRATION_CERTIFICATE_ERROR',
                                                    }),
                                            })}
                                            maxLength={9}
                                            name="registrationCertificate"
                                        />
                                    </div>
                                    <ErrorForm errors={errors} name="registrationCertificate" />
                                </Form.Group>
                            ) : (
                                <Form.Group>
                                    <Form.Label>
                                        {intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.ABSENCE_REASON',
                                        })}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        // @ts-ignore
                                        ref={register({
                                            required: intl.formatMessage({ id: 'AGENDA.FORM.ERROR.REQUIRED' }),
                                        })}
                                        name="registrationCertificateAbsenceReason"
                                    />
                                    <ErrorForm errors={errors} name="registrationCertificateAbsenceReason" />
                                </Form.Group>
                            )}

                            <Form.Group>
                                <Form.Label>
                                    {intl.formatMessage({ id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.CO_HOLDER' })}
                                </Form.Label>
                                <Form.Control type="text" ref={register} name="coHolder" />
                            </Form.Group>
                        </>
                    )}
                    <p className="m-0">{intl.formatMessage({ id: 'CUSTOMERS.MODAL.EDIT.PART_EXCHANGE.SUBTITLE' })}</p>
                </FormProvider>
            }
            footer={
                <div className="d-flex justify-content-end w-100">
                    <Button
                        variant="outline-secondary"
                        onClick={() => setShowModal(false)}
                        className="mr-5"
                        style={{ width: '120px' }}
                    >
                        {intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSubmit(onSubmit, onError)}
                        style={{ width: '120px' }}
                        id="submitPartExchange"
                    >
                        {intl.formatMessage({ id: 'TRANSLATOR.UPDATE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default EditPartExchange;
