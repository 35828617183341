import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import Swal from 'sweetalert2';

import useModal from '@app/hooks/useModal';
import usePermissions from '@app/hooks/usePermissions';

import { CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { deleteLeadPartExchange } from '@app/crud/customers/customer.crud';
import { EventDetailType, LeadDetails } from '@app/crud/customers/customer.type';

import { PartExchange } from '@app/pages/customers/Modals';
import EditPartExchange from '@app/pages/customers/Modals/EditPartExchange';
import Comment from '@app/pages/customers/Partials/Comment';

import CollapsedCard from '@app/partials/content/CollapsedCard';
import Expander from '@app/partials/content/Expander';
import PartExchangeInfo from '@app/partials/content/PartExchange/PartExchangeInfo';
import VehicleInfoPartExchange from '@app/partials/content/Vehicle/VehicleInfoPartExchange';

import InfosAchatCash from './EventDetailPartExchange/InfosAchatCash';
import InfosPartExchange from './EventDetailPartExchange/InfosPartExchange';

type EventDetailPartExchangeProps = {
    event: EventDetailType;
    typeSlug: string;
    isLeadClosed: boolean;
    fetchData: (id: string) => Promise<CustomAxiosResponse<LeadDetails>>;
};
const EventDetailPartExchange = ({ event, typeSlug, isLeadClosed, fetchData }: EventDetailPartExchangeProps) => {
    const { hasPermission } = usePermissions();
    const intl = useIntl();
    const [showPartExchange, setShowPartExchange] = useModal(false);
    const [showAchatCash, setShowAchatCash] = useModal(false);
    const partExchange = event?.partExchange || [];
    const hasPartExchange = partExchange?.length > 0;
    const isLeadReprise = typeSlug === 'reprise';
    const isLeadAchatCash = ['local_achat_cash', 'achat_cash', 'achat_cash_abandon'].includes(typeSlug);
    const isEditableLead =
        typeSlug.startsWith('local') ||
        ['online', 'lead_apv', 'vo', 'vn', 'vd', 'achat_cash', 'achat_cash_abandon', 'constructeur'].includes(typeSlug);
    const [activePartExchange, setActivePartExchange] = useState(0);
    const expanderRef = useRef(null);

    const deletePartExchange = (leadId: number) => {
        deleteLeadPartExchange(leadId).then(() => {
            fetchData(event?.informations?.identifier.toString());
            setActivePartExchange(0);
        });
    };

    const removeVehicle = (index: number) => {
        Swal.fire({
            title: intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.RECOVERY.REMOVE.TITLE' }),
            text: intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.RECOVERY.REMOVE.SUBTITLE' }),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({ id: 'TRANSLATOR.YES' }),
            cancelButtonText: intl.formatMessage({ id: 'TRANSLATOR.NO' }),
            customClass: {
                confirmButton: 'btn mr-3 btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            preConfirm: () => {
                Swal.showLoading();
                const { id } = partExchange[index];
                return deletePartExchange(id);
            },
        });
    };

    return (
        <>
            <CollapsedCard
                collapse={false}
                // @ts-ignore
                header={({ collapsed, setCollapsed }) => (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
                    <div
                        onClick={() => {
                            setCollapsed(!collapsed);
                        }}
                        ref={expanderRef}
                        className={classNames(
                            'card-header d-flex justify-content-between align-items-center position-relative',
                            {
                                [`event__origin--${typeSlug}`]: typeSlug,
                            },
                        )}
                    >
                        <div>
                            <div className={`event__origin-bg--${typeSlug} pin`}>
                                <i className="la la-undo text-white la-lg" />
                            </div>
                            <span className="ml-4">
                                {intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.TITLE' })}
                            </span>
                        </div>
                        {event?.informations?.identifier &&
                            (!isLeadClosed || (isLeadClosed && partExchange.length > 0)) &&
                            !isLeadReprise &&
                            hasPermission('LEAD_ADD_PART_EXCHANGE_LEAD') && <Expander collapsed={collapsed} />}
                    </div>
                )}
            >
                {!hasPartExchange ? (
                    <div className="d-flex h-100 align-items-center justify-content-center">
                        <div className="text-dark-50 text-right mr-6">
                            <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.UNTITLED" />
                        </div>
                        <Button variant="primary" onClick={() => setShowPartExchange()}>
                            <FormattedHTMLMessage id="CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.ADD" />
                        </Button>
                    </div>
                ) : (
                    <>
                        <Row>
                            <Col className="d-flex flex-column">
                                <div className="d-flex justify-content-between mb-6">
                                    <div className="d-flex">
                                        {partExchange?.length > 0 &&
                                            partExchange.map((product, index) => (
                                                <Button
                                                    key={product.id}
                                                    variant="link"
                                                    onClick={() => setActivePartExchange(index)}
                                                    className={`px-4 py-2 project d-flex align-items-center ${
                                                        activePartExchange === index &&
                                                        `event__origin--${typeSlug} event__origin-bg-opacity--${typeSlug}`
                                                    }`}
                                                >
                                                    <span>{`${intl.formatMessage({
                                                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.RECOVERY_VEHICLE',
                                                    })}${index + 1}`}</span>
                                                    {event?.informations?.identifier &&
                                                        !isLeadClosed &&
                                                        !isLeadReprise && (
                                                            <Button
                                                                variant="link"
                                                                onClick={() => removeVehicle(index)}
                                                                className="p-0 m-0"
                                                            >
                                                                <i
                                                                    className={`la la-lg la-trash ml-2 p-0 ${
                                                                        activePartExchange === index &&
                                                                        `event__origin--${typeSlug}`
                                                                    }`}
                                                                />
                                                            </Button>
                                                        )}
                                                    {event?.informations?.identifier &&
                                                        partExchange?.length > 0 &&
                                                        !isLeadClosed &&
                                                        isEditableLead && (
                                                            <Button
                                                                variant="link"
                                                                onClick={() =>
                                                                    isEditableLead ? setShowAchatCash(true) : null
                                                                }
                                                                className="p-0 m-0"
                                                            >
                                                                <i
                                                                    className={`las la-pen ml-2 p-0 ${
                                                                        activePartExchange === index &&
                                                                        `event__origin--${typeSlug}`
                                                                    }`}
                                                                />
                                                            </Button>
                                                        )}
                                                </Button>
                                            ))}
                                    </div>
                                    {event?.informations?.identifier &&
                                        partExchange?.length > 0 &&
                                        !isLeadClosed &&
                                        !isLeadReprise && (
                                            <Button
                                                variant="link"
                                                className={`px-3 py-2 project event__origin-bg-opacity--${typeSlug}`}
                                                onClick={() => setShowPartExchange()}
                                            >
                                                <i className={`la la-plus la-lg p-0 event__origin--${typeSlug}`} />
                                            </Button>
                                        )}
                                </div>
                                {partExchange.length > 0 &&
                                    partExchange.map((veh, index: number) => (
                                        <React.Fragment key={veh.id}>
                                            {activePartExchange === index && (
                                                <>
                                                    {isLeadAchatCash ? (
                                                        <InfosAchatCash partExchange={veh} />
                                                    ) : (
                                                        <InfosPartExchange partExchange={veh} />
                                                    )}

                                                    <Comment
                                                        comment={
                                                            isLeadReprise
                                                                ? event?.project?.customerProject
                                                                : veh?.comment || '-'
                                                        }
                                                        readOnly
                                                        label="COMMENT_PROJECT"
                                                        containerClassName="mb-4"
                                                    />
                                                </>
                                            )}
                                        </React.Fragment>
                                    ))}
                            </Col>
                            {(isLeadAchatCash || isLeadReprise) && (
                                <Col lg={5}>
                                    {/* @ts-ignore */}
                                    <PartExchangeInfo
                                        event={event}
                                        partExchange={partExchange.length ? partExchange[activePartExchange] : null}
                                        reprise
                                    />
                                </Col>
                            )}
                        </Row>
                        {!isLeadReprise && !isLeadAchatCash && (
                            <Row>
                                <Col>
                                    <VehicleInfoPartExchange vehicle={partExchange[activePartExchange] ?? null} />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </CollapsedCard>
            {event?.informations?.identifier && !isLeadClosed && (
                <PartExchange
                    showModal={showPartExchange}
                    event={event}
                    setShowModal={setShowPartExchange}
                    fetchData={fetchData}
                />
            )}
            {event?.informations?.identifier && !isLeadClosed && isEditableLead && hasPartExchange && (
                <EditPartExchange
                    showModal={showAchatCash}
                    setShowModal={setShowAchatCash}
                    partExchange={partExchange[activePartExchange]}
                    leadId={event.informations.identifier}
                    fetchData={fetchData}
                    isLeadAchatCash={isLeadAchatCash}
                />
            )}
        </>
    );
};

export default EventDetailPartExchange;
