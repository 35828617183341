import React, {ReactElement, ReactNode, useEffect} from 'react';
import {FormattedHTMLMessage} from "react-intl";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { Placement } from '@popperjs/core';

type OverlayTooltipProps = {
    id: string,
    label: string,
    paramLabel?: {},
    position?: Placement,
    children: ReactNode,
    show?: boolean,
    translate?: boolean
}

const OverlayTooltip = ({id, label, paramLabel = {}, position = "right", children, show, translate = true}: OverlayTooltipProps) => {
    let ref = React.useRef(null);

    return (
        // @ts-ignore
        <OverlayTrigger
            container={ref.current}
            placement={position}
            rootClose
            overlay={
                <Tooltip id={`${id}-tooltip`}>
                    { translate ?
                        <FormattedHTMLMessage id={label} values={paramLabel}/> :
                        <span dangerouslySetInnerHTML={{__html: label}}/>
                    }
                </Tooltip>
            }
        >
            {/* @ts-ignore */}
            {children}
        </OverlayTrigger>
    );
};

export default OverlayTooltip;
