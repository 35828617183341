import React, { ReactNode } from 'react';

interface InformationNoticeProps {
    children: ReactNode;
}

const InformationNotice = ({ children }: InformationNoticeProps) => {
    return (
        <div className="information-notice font-size-p d-flex p-2 mt-4 text-primary rounded border border-primary font-weight-normal">
            <span className="text-primary las la-3x la-exclamation-triangle" role="presentation" />
            <div className="my-auto ml-10">
                {children}
            </div>
        </div>
    );
};

export default InformationNotice;
