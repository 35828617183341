import { Divider } from 'primereact/divider';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import useAction from '@app/pages/customers/Hooks/useAction';
import CarSearch from '@app/pages/customers/Partials/CarSearch';
import DealershipSelect from '@app/pages/customers/Partials/DealershipSelect';
import LeadTypeSelect from '@app/pages/customers/Partials/LeadTypeSelect';
import { actions } from '@app/store/modal/modal.store';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

const Call = ({ showModal, setShowModal }) => {
    const methods = useForm();
    const dispatch = useDispatch();
    const Intl = useIntl();
    const { register, errors, handleSubmit } = methods;
    const { data: customer } = useSelector((state) => state.customer.customer);
    const [loading, createAction] = useAction({
        type: 'call',
        success: (response) => {
            dispatch(
                actions.modalChange('next_action', {
                    leadId: response.result.lead.id,
                }),
            );
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CALL.ERROR' }),
            });
        },
    });

    const onSubmit = (data) => {
        createAction(data);
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-phone" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CALL.TITLE' })}
            body={
                <FormProvider {...methods}>
                    <Row className="mb-2">
                        <LeadTypeSelect methods={methods} />
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <DealershipSelect methods={methods} customer={customer} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Form.Group>
                                <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CALL.OBJECT' })}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="object"
                                    className={`${errors.object ? 'is-invalid' : ''}`}
                                    ref={register({
                                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    })}
                                />
                                <ErrorForm errors={errors} name="object" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Form.Group>
                                <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CALL.COMMENT' })}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    name="comment"
                                    className={`${errors.comment ? 'is-invalid' : ''}`}
                                    ref={register({
                                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    })}
                                />
                                <ErrorForm errors={errors} name="comment" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Divider className="my-5" type="dashed" />
                    <CarSearch title={Intl.formatMessage({ id: 'CAR_SEARCH.INTERESTED' })} />
                </FormProvider>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(onSubmit, onError)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default Call;
