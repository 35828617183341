import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import React from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import { DateTimePicker } from '@app/partials/content/DateTimePicker';

interface ReportingCardProps {
    headerIcon: string;
    headerTitleId: string;
    startDate: Dayjs | null;
    setStartDate: (date: Dayjs | null) => void;
    endDate: Dayjs | null;
    setEndDate: (date: Dayjs | null) => void;
    onExportClick: () => void;
}

const ReportingCard = ({
    headerIcon,
    headerTitleId,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    onExportClick,
}: ReportingCardProps) => (
    <Card className="h-auto mb-4">
        <Card.Header>
            <i className={`card__icon ${headerIcon}`} />
            <FormattedMessage id={headerTitleId} />
        </Card.Header>
        <Card.Body>
            <Form className="row ">
                <Form.Group className="col">
                    <Form.Label>
                        <FormattedMessage id="SECURYCAR.REPORTING.DATE_START.MANDATORY" />
                    </Form.Label>
                    <DateTimePicker
                        locale={Locale}
                        dateFormat="D MMMM, YYYY"
                        onChange={(e: { value: Date }) => {
                            const value = dayjs(e.value).isValid() ? dayjs(e.value) : null;
                            setStartDate(value);
                        }}
                        value={startDate && startDate.toDate()}
                        minDate={dayjs().subtract(6, 'months').toDate()}
                        maxDate={dayjs().endOf('day').toDate()}
                    />
                    <Form.Label>
                        <FormattedMessage id="TRANSLATOR.REQUIRED_FIELDS" />
                    </Form.Label>
                </Form.Group>
                <Form.Group className="col">
                    <Form.Label>
                        <FormattedMessage id="SECURYCAR.REPORTING.DATE_END.MANDATORY" />
                    </Form.Label>
                    <DateTimePicker
                        locale={Locale}
                        dateFormat="D MMMM, YYYY"
                        onChange={(e: { value: Date }) => {
                            const value = dayjs(e.value).isValid() ? dayjs(e.value) : null;
                            setEndDate(value);
                        }}
                        disabled={!startDate}
                        value={endDate && endDate.toDate()}
                        minDate={startDate ? startDate.toDate() : dayjs().toDate()}
                        maxDate={dayjs().endOf('day').toDate()}
                    />
                </Form.Group>
                <Form.Group>
                    <Button
                        variant="primary"
                        className="mt-8"
                        onClick={onExportClick}
                        disabled={!startDate || !endDate}
                    >
                        <FormattedMessage id="TRANSLATOR.EXPORT" />
                    </Button>
                </Form.Group>
            </Form>
        </Card.Body>
    </Card>
);

export default ReportingCard;
