import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { toAbsoluteUrl } from '@src/_metronic';

import '@src/_metronic/_assets/sass/pages/login/login-1.scss';
import '@src/_metronic/_assets/sass/pages/login/login-ad.scss';

import Login from './Login';

export default function AuthPage() {
    return (
        <div className="kt-grid kt-grid--ver kt-grid--root">
            <div id="kt_login" className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
                <div
                    className="kt-login__background"
                    style={{
                        backgroundImage: `url(${toAbsoluteUrl('/media/bg/home.jpg')})`,
                    }}
                />
                <div className="h-100 kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                    <div className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside d-lg-block d-none" />
                    <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper d-flex align-items-center">
                        <Switch>
                            <Route path="/auth/login" component={Login} />
                            <Redirect from="/auth" exact to="/auth/login" />
                            <Redirect to="/auth/login" />
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
}
