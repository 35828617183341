import * as dayjs from 'dayjs';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import { Intl } from '../../../../../_metronic/i18n/I18nProvider';

export default function QuotationInfoCustomer({ quotation }) {
    return (
        <Row className="mb-5">
            <Col lg={4}>
                <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <div>
                            <i className="card__icon fas fa-file-invoice" />
                            {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.VIEW.CUSTOMER.INFO' })}
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row className="mb-4">
                            <Col lg={6}>{Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.VIEW.NAME' })}</Col>
                            <Col className="font-weight-semibold" lg={6}>
                                <span>{quotation?.customer?.lastName ? quotation?.customer?.lastName : '-'}</span>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col lg={6}>{Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.VIEW.FIRSTNAME' })}</Col>
                            <Col className="font-weight-semibold" lg={6}>
                                <span>{quotation?.customer?.firstName ? quotation?.customer?.firstName : '-'}</span>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col lg={6}>{Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.VIEW.LOGIN' })}</Col>
                            <Col className="font-weight-semibold" lg={6}>
                                <span>Autosphere {quotation?.id}</span>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col lg={6}>{Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.VIEW.MAIL' })}</Col>
                            <Col className="font-weight-semibold" lg={6}>
                                <span>{quotation?.customer?.email ? quotation?.customer?.email : '-'}</span>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col lg={6}>{Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.VIEW.MOBILE' })}</Col>
                            <Col className="font-weight-semibold" lg={6}>
                                {quotation?.customer?.phone || quotation?.customer?.mobile || '-'}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={4}>
                <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <div>
                            <i className="card__icon fas fa-car" />
                            {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.VIEW.VEHICLES' })}
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row className="mb-4">
                            <Col lg={6}>{Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.VIEW.BRAND' })}</Col>
                            <Col className="font-weight-semibold" lg={6}>
                                {quotation?.make ? quotation?.make : '-'}
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col lg={6}>{Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.VIEW.MODEL' })}</Col>
                            <Col className="font-weight-semibold" lg={6}>
                                {quotation?.model ? quotation?.model : '-'}
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col lg={6}>{Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.VIEW.REGISTRATION' })}</Col>
                            <Col className="font-weight-semibold" lg={6}>
                                {quotation?.registration ? quotation?.registration : '-'}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={4}>
                <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <div>
                            <i className="card__icon fas fa-calendar-alt" />
                            {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.VIEW.APPOINTMENT' })}
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row className="mb-4">
                            <Col lg={6}>
                                {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.VIEW.APPOINTMENT.DATE' })} :
                            </Col>
                            <Col className="font-weight-semibold" lg={6}>
                                {quotation?.appointmentDate
                                    ? dayjs(quotation?.appointmentDate).format(
                                          Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.DATE.FORMAT' }),
                                      )
                                    : '-'}
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col lg={6}>{Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.VIEW.DEALERSHIP' })}</Col>
                            <Col className="font-weight-semibold" lg={6}>
                                {quotation?.dealership.name && <span>{quotation?.dealership.name}</span>}
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col lg={6}>
                                {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.VIEW.ADDRESS.DEALERSHIP' })}
                            </Col>
                            <Col className="font-weight-semibold" lg={6}>
                                {quotation?.dealership?.address && (
                                    <span>
                                        {quotation?.dealership.address}
                                        <br />
                                        {quotation?.dealership.postalCode} {quotation?.dealership.locality}
                                    </span>
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}
