import { Column } from 'primereact/column';
import React, { useEffect } from 'react';
import { Button, Card, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';

import useFetch from '@app/hooks/useFetch';
import useFilters from '@app/hooks/useFilters';
import useLocales from '@app/hooks/useLocales';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getActionEmailCompagnies, getActionEmailTypes } from '@app/crud/administration/action-email-type';

import ROUTES from '@app/router/Routes';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import InputFilter from '@app/partials/content/InputFilter';
import Label from '@app/partials/content/Label';
import MultiSelectFilter from '@app/partials/content/MultiSelectFilter';
import RefreshTable from '@app/partials/content/RefreshTable';
import SelectFilter from '@app/partials/content/SelectFilter';
import SwitchInput from '@app/partials/content/SwitchInput';
import { Filters } from '@app/partials/layout/Filters';

import { formSubmit } from './Form/form.submit';

function ActionEmailTypes({ history }) {
    const Intl = useIntl();
    const { locales } = useLocales();
    const { items, start, filters, sortField, sortOrder, updatePendingFilters, releasePendingFilters } = useFilters({
        name: 'administration_actionEmailType',
    });

    const { data, loading, handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: 'administration_actionEmailType',
        fetchAction: getActionEmailTypes,
        withStore: false,
    });
    const {
        fetch: fetchActionEmailCompagnies,
        data: actionEmailCompagnies,
        error: _errorActionEmailCompagnies,
        loading: _loadingActionEmailCompagnies,
    } = useFetch({
        fetchAction: getActionEmailCompagnies,
    });

    useEffect(() => {
        fetchActionEmailCompagnies();
    }, [fetchActionEmailCompagnies]);

    const showAddEmailTemplate = () => {
        history.push(routeTo(ROUTES.EMAIL_TEMPLATE.PATH.CREATE));
    };

    const inputFilter = (name) => <InputFilter name={name} onChange={handleFilter} />;

    const selectFilter = (name, options) => <SelectFilter name={name} options={options} onChange={handleFilter} />;

    const handleActiveChange = (actionEmailType) => {
        formSubmit({
            isCreate: false,
            data: {
                isActive: actionEmailType.isActive,
                brands: actionEmailType?.brands,
            },
            history,
            id: actionEmailType.id,
        }).then(() => {
            refresh();
        });
    };

    const selectCompagniesDropdown = () => (
        <MultiSelectFilter
            name="actionEmailCompagnies"
            display="chip"
            optionLabel="name"
            optionValue="slug"
            options={actionEmailCompagnies}
            pending
            onChange={handleFilter}
            onPendingChange={updatePendingFilters}
            onRelease={releasePendingFilters}
        />
    );

    const localBodyTemplate = (actionEmailType) => {
        if (locales) {
            const locale = locales[actionEmailType.locale];

            if (locale?.name) {
                return locale.name;
            }
        }

        return actionEmailType.locale;
    };

    const activeBodyTemplate = (actionEmailType) => (
        <SwitchInput
            name="isActive"
            form={false}
            onChange={(e) => {
                actionEmailType.isActive = e;
                handleActiveChange(actionEmailType);
            }}
            labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.NO' })}
            labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.YES' })}
            active={actionEmailType?.isActive || false}
        />
    );

    const actionsBodyTemplate = (actionEmailType) => (
        <Link to={`/administration/email-templates/${actionEmailType?.id}`}>
            <Button className="p-2 px-3" variant="secondary">
                <i className="fa fa-pen" />
                {Intl.formatMessage({ id: 'TRANSLATOR.UPDATE' })}
            </Button>
        </Link>
    );

    const cols = [
        {
            field: 'locale',
            header: Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.TABLE.HEAD.LOCALE' }),
            style: { width: '5%' },
            body: localBodyTemplate,
        },
        {
            field: 'name',
            header: Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.TABLE.HEAD.NAME' }),
            style: { width: '10%' },
            filter: true,
            filterElement: inputFilter('name'),
            body: (actionEmailType) => actionEmailType?.name,
        },
        {
            field: 'actionEmailCompagnies',
            header: Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.TABLE.HEAD.COMPAGNIES' }),
            style: { width: '10%' },
            filter: true,
            filterElement: selectCompagniesDropdown(),
            body: (actionEmailType) =>
                actionEmailType?.actionEmailCompagnies &&
                actionEmailType?.actionEmailCompagnies.map((template) => (
                    <Label key={template.slug} variant="primary" className="mr-4 my-1">
                        {template.name}
                    </Label>
                )),
        },
        {
            field: 'brand',
            header: Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.TABLE.HEAD.MAKE' }),
            style: { width: '10%' },
            filter: true,
            filterElement: selectFilter(
                'brand',
                <>
                    <option value="">
                        {Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.TABLE.HEAD.BRANDS.ALL' })}
                    </option>
                    {data?.filter?.brand?.map((brand) => (
                        <option value={brand.slug}>{brand.name}</option>
                    ))}
                </>,
            ),
            body: (actionEmailType) =>
                actionEmailType?.brands &&
                actionEmailType?.brands.map((brand) => (
                    <Label key={brand.slug} variant="primary" className="mr-4 my-1">
                        {brand.name}
                    </Label>
                )),
        },
        {
            field: 'isActive',
            header: Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.TABLE.HEAD.IS_ACTIVE' }),
            style: { width: '10%' },
            filter: true,
            filterElement: selectFilter(
                'isActive',
                <>
                    <option value="">Tous les statuts</option>
                    <option value>Actif</option>
                    <option value={false}>Inactif</option>
                </>,
            ),
            body: activeBodyTemplate,
        },
        {
            field: 'actions',
            header: Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.TABLE.HEAD.ACTIONS' }),
            style: { width: '5%' },
            body: actionsBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <Card>
            <Card.Body>
                <FiltersProvider value={filters}>
                    <Filters>
                        <Row className="justify-content-between">
                            <RefreshTable count={data.count} onClick={refresh} />
                            <Button variant="outline-primary" className="mr-4" onClick={() => showAddEmailTemplate()}>
                                Créer un nouveau template
                            </Button>
                        </Row>
                    </Filters>
                    <HoCDataTable
                        value={data?.result || []}
                        totalRecords={data?.count || 0}
                        lazy
                        loading={loading}
                        paginator
                        onFilter={handleFilter}
                        onPage={handlePagination}
                        onSort={handleSort}
                        paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                        currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                        rows={items}
                        first={start}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        removableSort
                        filterDisplay="row"
                        rowsPerPageOptions={[25, 50, 100]}
                        emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                    >
                        {dynamicColumns}
                    </HoCDataTable>
                </FiltersProvider>
            </Card.Body>
        </Card>
    );
}

export default withRouter(ActionEmailTypes);
