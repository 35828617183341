import classNames from 'classnames';
import { AutoComplete, AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { City } from '@app/crud/city/city.crud';

import ErrorForm from '@app/partials/layout/ErrorForm';

interface ZipcodeAutoCompleteProps {
    label: React.ReactElement;
    name: string;
    required: boolean;
    isInvalid: boolean;
    placeholder?: string;
    className?: string;
    validate?: (value: any) => string | boolean;
    options: City[];
}

const ZipcodeAutoComplete = ({
    label,
    name,
    required,
    isInvalid,
    placeholder = '',
    className = '',
    validate,
    options,
}: ZipcodeAutoCompleteProps) => {
    const Intl = useIntl();
    const methods = useFormContext();
    const {
        control,
        setValue,
        formState: { errors },
    } = methods;
    const hasBeenFocused = useRef(false);

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Controller
                name={name}
                control={control}
                defaultValue=""
                rules={{
                    required: required && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    validate,
                }}
                render={(field) => (
                    <div className="p-inputgroup flex-1">
                        <AutoComplete
                            {...field}
                            suggestions={options}
                            completeMethod={(e: AutoCompleteCompleteEvent) => {
                                field.onChange(e.query);
                            }}
                            field="code"
                            placeholder={placeholder}
                            inputClassName="form-control"
                            className={classNames(` ${className}`, {
                                'is-invalid': isInvalid,
                            })}
                            itemTemplate={(item: City) => (
                                <>
                                    {item.code} - {item.name}
                                </>
                            )}
                            onChange={(e) => {
                                if (field.value !== e.value) {
                                    field.onChange(e.value);
                                }
                            }}
                            onFocus={(e) => {
                                if (!hasBeenFocused.current) {
                                    hasBeenFocused.current = true;
                                    if (e.target.value && e.target.value.length >= 2) {
                                        field.onChange(e.target.value);
                                    }
                                }
                            }}
                            onSelect={(e) => {
                                field.onChange(e.value.code);
                                setValue('city', e.value.name);
                            }}
                            loadingIcon="pi pi-spinner"
                        />
                    </div>
                )}
            />
            <ErrorForm errors={errors} name={name} />
        </Form.Group>
    );
};

export default ZipcodeAutoComplete;
