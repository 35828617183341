import React from "react";
import {Column} from "primereact/column";
import {Button, Card, Col, Row} from "react-bootstrap";
import {Filters} from "../../../partials/layout/Filters";
import {Intl} from "../../../../_metronic/i18n/I18nProvider";
import SearchInput from "../../../partials/content/SearchInput";
import {useSelector} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import {fetchAdditionalServices} from "../../../store/apv/additional-service/additional-service.thunk";
import useTableHandlers from "../../../hooks/useTableHandlers";
import InputFilter from "../../../partials/content/InputFilter";
import SelectFilter from "../../../partials/content/SelectFilter";
import RefreshTable from "../../../partials/content/RefreshTable";
import useFilters from "../../../hooks/useFilters";
import FiltersProvider from "../../../partials/content/FiltersProvider";
import {routeTo} from "../../../helpers/RoutesHelper";
import ROUTES from "../../../router/Routes";
import Permission from "../../../partials/content/Permission";
import HoCDataTable from "../../../partials/content/HoCDataTable";

function AdditionalServices({ history }) {
    const { additionalServices, totalRecords, filtersContent, loading } = useSelector((state) => state.additionalService);
    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: 'apv_additionalServices'
    });
    const { handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: 'apv_additionalServices',
        fetchAction: fetchAdditionalServices
    });

    const showAddAdditionalService = () => {
        history.push(routeTo(ROUTES.ADDITIONAL_SERVICE.PATH.CREATE));
    }

    const inputFilter = (name) => {
        return(
            <InputFilter name={name} onChange={handleFilter} />
        )
    }

    const selectFilter = (name, options) => {
        return (
            <SelectFilter name={name}
                          options={options}
                          onChange={handleFilter} />
        )
    }

    const nameBodyTemplate = (additionalService) => {
        return additionalService?.package ? 'Forfait' : 'Produit';
    }

    const statusBodyTemplate = (additionalService) => {
        return (
            <span className={additionalService?.active ? 'text-success' : 'text-danger'}>
                {additionalService?.active ? '• Actif' : '• Inactif'}
            </span>
        )
    }

    const criteriaBodyTemplate = (additionalService) => {
        return (
            <>
                {(additionalService?.mileageMax && additionalService?.mileageMin) && (
                    <>
                        <span>Entre </span>
                        <NumberFormat value={additionalService?.mileageMin}
                                      thousandSeparator=" "
                                      displayType={'text'}
                                      suffix=" km"/>
                        <br />
                        <span> Et </span>
                        <NumberFormat value={additionalService?.mileageMax}
                                      thousandSeparator=" "
                                      displayType={'text'}
                                      suffix=" km"/>
                        <br /><br />
                    </>
                )}
                { (additionalService?.dateStart && additionalService?.dateEnd) ? (
                    <>
                        <span>Entre le </span>
                        {dayjs(additionalService?.dateStart).format('DD/MM/YYYY')}
                        <br />
                        <span> Et le </span>
                        {dayjs(additionalService?.dateEnd).format('DD/MM/YYYY')}
                    </>
                ) : (
                    'Toujours publié'
                )}
            </>
        );
    }

    const updatedBodyTemplate = (additionalService) => {
        return dayjs(additionalService.updated).format('DD/MM/YYYY [à] HH [h] mm')
    }

    const actionsBodyTemplate = (additionalService) => {
        return <Link to={routeTo(ROUTES.ADDITIONAL_SERVICE.PATH.UPDATE, {
            id: additionalService.id
        })}>
            <Button className="p-2 px-3" variant="secondary">
                <i className="fa fa-pen" />
                {Intl.formatMessage({ id: "TRANSLATOR.UPDATE" })}
            </Button>
        </Link>
    }

    const cols = [
        {
            field: 'reference',
            header: Intl.formatMessage({ id: "APV.ADDITIONAL_SERVICE.TABLE.HEAD.REFERENCE" }),
            style: {width : '10%'},
            filter: true,
            filterElement: inputFilter('reference'),
            body: (additionalService) => additionalService?.package?.code ?? additionalService?.code
        },
        {
            field: 'name',
            header: Intl.formatMessage({ id: "APV.ADDITIONAL_SERVICE.TABLE.HEAD.NAME" }),
            style: {width : '20%'},
            filter: true,
            filterElement: inputFilter('additionalServiceName')
        },
        {
            field: 'status',
            header: Intl.formatMessage({ id: "APV.ADDITIONAL_SERVICE.TABLE.HEAD.STATUS" }),
            style: {width: '10%'},
            filter: true,
            filterElement: selectFilter('active', (
                <>
                    <option value=''>Tout les statuts</option>
                    <option value={true}>Actif</option>
                    <option value={false}>Inactif</option>
                </>
            )),
            body: statusBodyTemplate
        },
        {
            field: 'type',
            header: Intl.formatMessage({ id: "APV.ADDITIONAL_SERVICE.TABLE.HEAD.TYPE" }),
            style: {width : '10%'},
            filter: true,
            filterElement: selectFilter('type', (
                <>
                    <option value=''>Tout les statuts</option>
                    <option value='forfait'>Forfait</option>
                    <option value='produit'>Produit</option>
                </>
            )),
            body: nameBodyTemplate
        },
        {
            field: 'mandatory',
            header: Intl.formatMessage({ id: "APV.ADDITIONAL_SERVICE.TABLE.HEAD.MANDATORY" }),
            style: {width : '10%'},
            filter: true,
            filterElement: selectFilter('mandatory', (
                <>
                    <option value=''>Tout les états</option>
                    <option value={true}>Oui</option>
                    <option value={false}>Non</option>
                </>
            )),
            body: (additionalService) => additionalService?.mandatory ? 'Oui' : 'Non'
        },
        {
            field: 'target',
            header: Intl.formatMessage({ id: "APV.PACKAGE.TABLE.HEAD.TARGET" }),
            style: {width : '10%'},
            filter: true,
            filterElement: selectFilter('additionalServiceCategoryId', (
                <>
                    <option value=''>Toutes les cibles</option>
                    {filtersContent?.categories &&
                        Object.values(filtersContent.categories).map((elm, key) => (
                            <option key={key} value={elm.id}>{elm.name}</option>
                        ))
                    }
                </>
            )),
            body: (additionalService) => additionalService?.additionalServiceCategory?.name || ''
        },
        {
            field: 'criteria',
            header: Intl.formatMessage({ id: "APV.ADDITIONAL_SERVICE.TABLE.HEAD.CRITERIA" }),
            style: {width : '10%'},
            body: criteriaBodyTemplate
        },
        {
            field: 'updated',
            header: Intl.formatMessage({ id: "APV.ADDITIONAL_SERVICE.TABLE.HEAD.UPDATED" }),
            style: {width : '10%'},
            body: updatedBodyTemplate
        },
        {
            field: 'actions',
            header: Intl.formatMessage({ id: "APV.ADDITIONAL_SERVICE.TABLE.HEAD.ACTIONS" }),
            style: {width : '10%'},
            body: actionsBodyTemplate
        }
    ];

    const dynamicColumns = cols.map((col) => {
        return <Column key={col.field} {...col} />;
    });

    return (
        <Card>
            <Card.Body>
                <FiltersProvider value={filters}>
                    <Filters>
                        <Row>
                            <RefreshTable count={totalRecords} onClick={refresh} />
                            <Col className="mr-4" lg={5} offset={2}>
                                <SearchInput onChange={handleFilter}
                                             name="research"
                                             placeholder="Prestations, type, cible, critère, etc."/>
                            </Col>
                            <Permission permissions={['APV_ADD_ADDITIONAL_SERVICE']}>
                                <Button variant="outline-primary" onClick={() => showAddAdditionalService()}>Créer une vente additionnelle</Button>
                            </Permission>
                        </Row>
                    </Filters>
                    <HoCDataTable
                        value={additionalServices}
                        totalRecords={totalRecords}
                        lazy
                        loading={loading}
                        paginator
                        onFilter={handleFilter}
                        onPage={handlePagination}
                        onSort={handleSort}
                        paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                        currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                        rows={items}
                        first={start}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        removableSort
                        filterDisplay="row"
                        rowsPerPageOptions={[25,50,100]}
                        emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                    >
                        {dynamicColumns}
                    </HoCDataTable>
                </FiltersProvider>
            </Card.Body>
        </Card>
    )
}

export default withRouter(AdditionalServices);
