import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import LeadStatusRow from '@app/pages/customers/Partials/LeadStatusRow';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

import useAction from '../Hooks/useAction';

const UpdateLeadStatus = ({ showModal, setShowModal }) => {
    const [enableVehicleWebAd, _] = useState(false);
    const [status, setStatus] = useState();
    const Intl = useIntl();
    const { params } = useSelector((state) => state.modal);
    const [loading, createAction] = useAction({
        type: 'change_status',
        success: () => {
            setShowModal(false);
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.UPDATE_LEAD_STATUS.ERROR' }),
            });
        },
    });
    const actualLead = params?.lead;

    const onSubmit = () => {
        createAction({
            enableVehicleWebAd,
        });
    };

    const statusList = [
        {
            leadStatus: {
                slug: 'in_progress_action_to_do',
                name: 'En cours',
                subName: 'Action à faire',
            },
        },
    ];

    useEffect(() => {
        setStatus(undefined);
    }, []);

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-sync" />}
            title={Intl.formatMessage({ id: 'CUSTOMER.MODAL.UPDATE_LEAD_STATUS.TITLE' })}
            body={
                <>
                    <Form.Group>
                        <Form.Control as="select" value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="">
                                {Intl.formatMessage({ id: 'CUSTOMER.MODAL.UPDATE_LEAD_STATUS.STATUS.PLACEHOLDER' })}
                            </option>
                            {statusList.map((item, idx) => (
                                <option key={item.leadStatus.slug} value={idx}>
                                    {item.leadStatus.name} - {item.leadStatus.subName}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <div className="mt-15 d-flex justify-content-center align-items-center">
                        <div style={{ height: '60px' }} className="text-right">
                            {Intl.formatMessage({ id: 'CUSTOMER.MODAL.UPDATE_LEAD_STATUS.STATUS.ACTUAL' })}
                            <LeadStatusRow
                                leadStatusSlug={actualLead?.statusSlug}
                                leadStatusName={actualLead?.status}
                                leadStatusSubName={actualLead?.subStatus}
                                shift={false}
                                className="mt-2 d-flex align-items-center"
                            />
                        </div>
                        <i className="mx-10 las la-sync la-6x" />
                        <div style={{ height: '60px' }}>
                            {Intl.formatMessage({ id: 'CUSTOMER.MODAL.UPDATE_LEAD_STATUS.STATUS.NEW' })}
                            {status && (
                                <LeadStatusRow
                                    leadStatusSlug={statusList[status].leadStatus.slug}
                                    leadStatusName={statusList[status].leadStatus.name}
                                    leadStatusSubName={statusList[status].leadStatus.subName}
                                    shift={false}
                                    className="mt-2 d-flex align-items-center"
                                />
                            )}
                        </div>
                    </div>
                </>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" disabled={!status} onClick={onSubmit}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default UpdateLeadStatus;
