import React from 'react';
import './chat-message.scss';

type ChatMessageProps = {
    message: string;
    variant?: 'primary' | 'success' | 'danger';
    arrowPosition?: 'left' | 'right';
    className?: string;
};

const ChatMessage = ({ message, variant, arrowPosition = 'left', className }: ChatMessageProps) => {
    const messageLines = message?.split('\n').map((line, index, array) => (
        <React.Fragment key={index}>
            {line}
            {index < array.length - 1 && <br />}
        </React.Fragment>
    ));

    return (
        <div className="wrapper">
            <div className={`chat-message arrow--${arrowPosition} ${className}`} data-variant={variant}>
                {messageLines}
            </div>
        </div>
    );
};

export default ChatMessage;
