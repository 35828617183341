import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Swal from 'sweetalert2';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFilters from '@app/hooks/useFilters';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { addFastNote, getAllFastNotes, removeFastNote } from '@app/crud/administration/fast-notes.crud';

import HoCDataTable from '@app/partials/content/HoCDataTable';

const LeadTags = () => {
    const { items, start, sortField, sortOrder } = useFilters({
        name: 'administration_leadtags',
    });
    const { data, loading, handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: 'administration_leadtags',
        fetchAction: getAllFastNotes,
        withStore: false,
    });

    const handleAddTag = async () => {
        Swal.fire({
            input: 'text',
            inputLabel: Intl.formatMessage({ id: 'LEAD_TAGS.ADD.SUBTITLE' }),
            showCancelButton: true,
            customClass: {
                confirmButton: 'btn mr-3 btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            inputValidator: (value) => {
                if (!value) {
                    return Intl.formatMessage({ id: 'TRANSLATOR.REQUIRED' });
                }
            },
            preConfirm: async (name) => {
                Swal.showLoading();
                await addFastNote({ name });
                refresh();
            },
        });
    };

    const handleRemoveTag = async (id) => {
        Swal.fire({
            title: Intl.formatMessage({ id: `LEAD_TAGS.REMOVE.TITLE` }),
            text: Intl.formatMessage({ id: `LEAD_TAGS.REMOVE.SUBTITLE` }),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
            cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
            customClass: {
                confirmButton: 'btn mr-3 btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            preConfirm: async () => {
                Swal.showLoading();
                await removeFastNote(id);
                refresh();
            },
        });
    };

    const createdBodyTemplate = (fastNote) => dayjs(fastNote.created).format('DD/MM/YYYY [à] HH [h] mm');

    const actionsBodyTemplate = (fastNote) => (
        <Button variant="danger" onClick={() => handleRemoveTag(fastNote?.id)}>
            <FormattedMessage id="TRANSLATOR.REMOVE" />
        </Button>
    );

    const cols = [
        {
            field: 'id',
            header: Intl.formatMessage({ id: 'LEAD_TAGS.TABLE.ID' }),
        },
        {
            field: 'name',
            header: Intl.formatMessage({ id: 'LEAD_TAGS.TABLE.NAME' }),
        },
        {
            field: 'created',
            header: Intl.formatMessage({ id: 'LEAD_TAGS.TABLE.CREATED' }),
            body: createdBodyTemplate,
        },
        {
            field: 'actions',
            header: Intl.formatMessage({ id: 'LEAD_TAGS.TABLE.ACTION' }),
            body: actionsBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <Card>
            <Card.Header className="h-auto d-flex justify-content-end">
                <Button variant="primary" onClick={handleAddTag}>
                    <FormattedMessage id="LEAD_TAGS.ADD" />
                </Button>
            </Card.Header>
            <Card.Body>
                <HoCDataTable
                    value={data?.result || []}
                    totalRecords={data?.count || 0}
                    lazy
                    loading={loading}
                    paginator
                    onFilter={handleFilter}
                    onPage={handlePagination}
                    onSort={handleSort}
                    paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                    currentPageReportTemplate={Intl.messages['DATATABLE.REPORT_TEMPLATE']}
                    rows={items}
                    first={start}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    removableSort
                    rowsPerPageOptions={[25, 50, 100]}
                    emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                >
                    {dynamicColumns}
                </HoCDataTable>
            </Card.Body>
        </Card>
    );
};

export default LeadTags;
