import React from 'react';

type TextOverflowProps = {
    content: string;
    lines?: number;
    className?: string;
};

/**
 * TextOverflow component truncates content and shows an ellipsis when the content exceeds a specified number of lines.
 * @param content The content to be displayed.
 * @param [lines=1] Number of lines to be displayed. Default value: 1
 * @param [className=] Optional - CSS classes to style component.
 * @returns The formatted text.
 */
const TextOverflow = ({ content, lines, className }: TextOverflowProps) => (
    <div
        style={{
            WebkitLineClamp: lines,
            display: '-webkit-box',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
        }}
        className={className}
    >
        {content}
    </div>
);

TextOverflow.defaultProps = {
    lines: 1,
    className: '',
};

export default TextOverflow;
