import React from "react";
import { Button } from "react-bootstrap";

type MenuButtonProps = {
    label: string | React.ReactNode,
    iconURL?: string,
    backgroundColor: string,
    disabled: boolean,
    className?: string,
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
}

const MenuButton = ({label, backgroundColor, className, iconURL, disabled = false, onClick}: MenuButtonProps) => (
    <Button
        size="sm"
        className={`d-flex justify-content-center font-weight-bold text-right my-5 ${className}`}
        onClick={onClick}
        disabled={disabled}
        style={{
            width: '100%',
            fontSize: '10px',
            lineHeight: 1.2,
            backgroundColor,
            border: 0,
            borderRadius: '7px',
            boxShadow: `0px 4px 4px 0px ${backgroundColor}25`}}
    >
        {label}
        {iconURL &&
            <img src={iconURL} alt="" className="ml-3" height="18" />
        }
    </Button>
)

MenuButton.defaultProps = {
    iconURL: null,
    className: null,
    onClick: null,
}

export default MenuButton;
