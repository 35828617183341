export const APECodes = [
    {
        "code": "0111Z",
        "value": "Culture de céréales (à l'exception du riz), de légumineuses et de graines oléagineuses"
    },
    {
        "code": "0112Z",
        "value": "Culture du riz"
    },
    {
        "code": "0113Z",
        "value": "Culture de légumes, de melons, de racines et de tubercules"
    },
    {
        "code": "0114Z",
        "value": "Culture de la canne à sucre"
    },
    {
        "code": "0115Z",
        "value": "Culture du tabac"
    },
    {
        "code": "0116Z",
        "value": "Culture de plantes à fibres"
    },
    {
        "code": "0119Z",
        "value": "Autres cultures non permanentes"
    },
    {
        "code": "0121Z",
        "value": "Culture de la vigne"
    },
    {
        "code": "0122Z",
        "value": "Culture de fruits tropicaux et subtropicaux"
    },
    {
        "code": "0123Z",
        "value": "Culture d'agrumes"
    },
    {
        "code": "0124Z",
        "value": "Culture de fruits à pépins et à noyau"
    },
    {
        "code": "0125Z",
        "value": "Culture d'autres fruits d'arbres ou d'arbustes et de fruits à coque"
    },
    {
        "code": "0126Z",
        "value": "Culture de fruits oléagineux"
    },
    {
        "code": "0127Z",
        "value": "Culture de plantes à boissons"
    },
    {
        "code": "0128Z",
        "value": "Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques"
    },
    {
        "code": "0129Z",
        "value": "Autres cultures permanentes"
    },
    {
        "code": "0130Z",
        "value": "Reproduction de plantes"
    },
    {
        "code": "0141Z",
        "value": "Élevage de vaches laitières"
    },
    {
        "code": "0142Z",
        "value": "Élevage d'autres bovins et de buffles"
    },
    {
        "code": "0143Z",
        "value": "Élevage de chevaux et d'autres équidés"
    },
    {
        "code": "0144Z",
        "value": "Élevage de chameaux et d'autres camélidés"
    },
    {
        "code": "0145Z",
        "value": "Élevage d'ovins et de caprins"
    },
    {
        "code": "0146Z",
        "value": "Élevage de porcins"
    },
    {
        "code": "0147Z",
        "value": "Élevage de volailles"
    },
    {
        "code": "0149Z",
        "value": "Élevage d'autres animaux"
    },
    {
        "code": "0150Z",
        "value": "Culture et élevage associés"
    },
    {
        "code": "0161Z",
        "value": "Activités de soutien aux cultures"
    },
    {
        "code": "0162Z",
        "value": "Activités de soutien à la production animale"
    },
    {
        "code": "0163Z",
        "value": "Traitement primaire des récoltes"
    },
    {
        "code": "0164Z",
        "value": "Traitement des semences"
    },
    {
        "code": "0170Z",
        "value": "Chasse, piégeage et services annexes"
    },
    {
        "code": "0210Z",
        "value": "Sylviculture et autres activités forestières"
    },
    {
        "code": "0220Z",
        "value": "Exploitation forestière"
    },
    {
        "code": "0230Z",
        "value": "Récolte de produits forestiers non ligneux poussant à l'état sauvage"
    },
    {
        "code": "0240Z",
        "value": "Services de soutien à l'exploitation forestière"
    },
    {
        "code": "0311Z",
        "value": "Pêche en mer"
    },
    {
        "code": "0312Z",
        "value": "Pêche en eau douce"
    },
    {
        "code": "0321Z",
        "value": "Aquaculture en mer"
    },
    {
        "code": "0322Z",
        "value": "Aquaculture en eau douce"
    },
    {
        "code": "0510Z",
        "value": "Extraction de houille"
    },
    {
        "code": "0520Z",
        "value": "Extraction de lignite"
    },
    {
        "code": "0610Z",
        "value": "Extraction de pétrole brut"
    },
    {
        "code": "0620Z",
        "value": "Extraction de gaz naturel"
    },
    {
        "code": "0710Z",
        "value": "Extraction de minerais de fer"
    },
    {
        "code": "0721Z",
        "value": "Extraction de minerais d'uranium et de thorium"
    },
    {
        "code": "0729Z",
        "value": "Extraction d'autres minerais de métaux non ferreux"
    },
    {
        "code": "0811Z",
        "value": "Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise"
    },
    {
        "code": "0812Z",
        "value": "Exploitation de gravières et sablières, extraction d’argiles et de kaolin"
    },
    {
        "code": "0891Z",
        "value": "Extraction des minéraux chimiques et d'engrais minéraux"
    },
    {
        "code": "0892Z",
        "value": "Extraction de tourbe"
    },
    {
        "code": "0893Z",
        "value": "Production de sel"
    },
    {
        "code": "0899Z",
        "value": "Autres activités extractives nca"
    },
    {
        "code": "0910Z",
        "value": "Activités de soutien à l'extraction d'hydrocarbures"
    },
    {
        "code": "0990Z",
        "value": "Activités de soutien aux autres industries extractives"
    },
    {
        "code": "1011Z",
        "value": "Transformation et conservation de la viande de boucherie"
    },
    {
        "code": "1011Z",
        "value": "Transformation et conservation de la viande de boucherie"
    },
    {
        "code": "1012Z",
        "value": "Transformation et conservation de la viande de volaille"
    },
    {
        "code": "1013A",
        "value": "Préparation industrielle de produits à base de viande"
    },
    {
        "code": "1013B",
        "value": "Charcuterie"
    },
    {
        "code": "1020Z",
        "value": "Transformation et conservation de poisson, de crustacés et de mollusques"
    },
    {
        "code": "1031Z",
        "value": "Transformation et conservation de pommes de terre"
    },
    {
        "code": "1032Z",
        "value": "Préparation de jus de fruits et légumes"
    },
    {
        "code": "1039A",
        "value": "Autre transformation et conservation de légumes"
    },
    {
        "code": "1039B",
        "value": "Transformation et conservation de fruits"
    },
    {
        "code": "1041A",
        "value": "Fabrication d'huiles et graisses brutes"
    },
    {
        "code": "1041B",
        "value": "Fabrication d'huiles et graisses raffinées"
    },
    {
        "code": "1042Z",
        "value": "Fabrication de margarine et graisses comestibles similaires"
    },
    {
        "code": "1051A",
        "value": "Fabrication de lait liquide et de produits frais"
    },
    {
        "code": "1051B",
        "value": "Fabrication de beurre"
    },
    {
        "code": "1051C",
        "value": "Fabrication de fromage"
    },
    {
        "code": "1051D",
        "value": "Fabrication d'autres produits laitiers"
    },
    {
        "code": "1052Z",
        "value": "Fabrication de glaces et sorbets"
    },
    {
        "code": "1061A",
        "value": "Meunerie"
    },
    {
        "code": "1061B",
        "value": "Autres activités du travail des grains"
    },
    {
        "code": "1062Z",
        "value": "Fabrication de produits amylacés"
    },
    {
        "code": "1071A",
        "value": "Fabrication industrielle de pain et de pâtisserie fraîche"
    },
    {
        "code": "1071B",
        "value": "Cuisson de produits de boulangerie"
    },
    {
        "code": "1071C",
        "value": "Boulangerie et boulangerie-pâtisserie"
    },
    {
        "code": "1071D",
        "value": "Pâtisserie"
    },
    {
        "code": "1072Z",
        "value": "Fabrication de biscuits, biscottes et pâtisseries de conservation"
    },
    {
        "code": "1073Z",
        "value": "Fabrication de pâtes alimentaires"
    },
    {
        "code": "1081Z",
        "value": "Fabrication de sucre"
    },
    {
        "code": "1082Z",
        "value": "Fabrication de cacao, chocolat et de produits de confiserie"
    },
    {
        "code": "1083Z",
        "value": "Transformation du thé et du café"
    },
    {
        "code": "1084Z",
        "value": "Fabrication de condiments et assaisonnements"
    },
    {
        "code": "1085Z",
        "value": "Fabrication de plats préparés"
    },
    {
        "code": "1086Z",
        "value": "Fabrication d'aliments homogénéisés et diététiques"
    },
    {
        "code": "1089Z",
        "value": "Fabrication d'autres produits alimentaires nca"
    },
    {
        "code": "1091Z",
        "value": "Fabrication d'aliments pour animaux de ferme"
    },
    {
        "code": "1092Z",
        "value": "Fabrication d'aliments pour animaux de compagnie"
    },
    {
        "code": "1101Z",
        "value": "Production de boissons alcooliques distillées"
    },
    {
        "code": "1102A",
        "value": "Fabrication de vins effervescents"
    },
    {
        "code": "1102B",
        "value": "Vinification"
    },
    {
        "code": "1103Z",
        "value": "Fabrication de cidre et de vins de fruits"
    },
    {
        "code": "1104Z",
        "value": "Production d'autres boissons fermentées non distillées"
    },
    {
        "code": "1105Z",
        "value": "Fabrication de bière"
    },
    {
        "code": "1106Z",
        "value": "Fabrication de malt"
    },
    {
        "code": "1107A",
        "value": "Industrie des eaux de table"
    },
    {
        "code": "1107B",
        "value": "Production de boissons rafraîchissantes"
    },
    {
        "code": "1200Z",
        "value": "Fabrication de produits à base de tabac"
    },
    {
        "code": "1310Z",
        "value": "Préparation de fibres textiles et filature"
    },
    {
        "code": "1320Z",
        "value": "Tissage"
    },
    {
        "code": "1330Z",
        "value": "Ennoblissement textile"
    },
    {
        "code": "1391Z",
        "value": "Fabrication d'étoffes à mailles"
    },
    {
        "code": "1392Z",
        "value": "Fabrication d'articles textiles, sauf habillement"
    },
    {
        "code": "1393Z",
        "value": "Fabrication de tapis et moquettes"
    },
    {
        "code": "1394Z",
        "value": "Fabrication de ficelles, cordes et filets"
    },
    {
        "code": "1395Z",
        "value": "Fabrication de non-tissés, sauf habillement"
    },
    {
        "code": "1396Z",
        "value": "Fabrication d'autres textiles techniques et industriels"
    },
    {
        "code": "1399Z",
        "value": "Fabrication d'autres textiles nca"
    },
    {
        "code": "1411Z",
        "value": "Fabrication de vêtements en cuir"
    },
    {
        "code": "1412Z",
        "value": "Fabrication de vêtements de travail"
    },
    {
        "code": "1413Z",
        "value": "Fabrication de vêtements de dessus"
    },
    {
        "code": "1414Z",
        "value": "Fabrication de vêtements de dessous"
    },
    {
        "code": "1419Z",
        "value": "Fabrication d'autres vêtements et accessoires"
    },
    {
        "code": "1420Z",
        "value": "Fabrication d'articles en fourrure"
    },
    {
        "code": "1431Z",
        "value": "Fabrication d'articles chaussants à mailles"
    },
    {
        "code": "1439Z",
        "value": "Fabrication d'autres articles à mailles"
    },
    {
        "code": "1511Z",
        "value": "Apprêt et tannage des cuirs ; préparation et teinture des fourrures"
    },
    {
        "code": "1512Z",
        "value": "Fabrication d'articles de voyage, de maroquinerie et de sellerie"
    },
    {
        "code": "1520Z",
        "value": "Fabrication de chaussures"
    },
    {
        "code": "1610A",
        "value": "Sciage et rabotage du bois, hors imprégnation"
    },
    {
        "code": "1610B",
        "value": "Imprégnation du bois"
    },
    {
        "code": "1621Z",
        "value": "Fabrication de placage et de panneaux de bois"
    },
    {
        "code": "1622Z",
        "value": "Fabrication de parquets assemblés"
    },
    {
        "code": "1623Z",
        "value": "Fabrication de charpentes et d'autres menuiseries"
    },
    {
        "code": "1624Z",
        "value": "Fabrication d'emballages en bois"
    },
    {
        "code": "1629Z",
        "value": "Fabrication d'objets divers en bois ; fabrication d'objets en liège, vannerie et sparterie"
    },
    {
        "code": "1629Z",
        "value": "Fabrication d'objets divers en bois; fabrication d'objets en liège, vannerie et sparterie"
    },
    {
        "code": "1711Z",
        "value": "Fabrication de pâte à papier"
    },
    {
        "code": "1712Z",
        "value": "Fabrication de papier et de carton"
    },
    {
        "code": "1721A",
        "value": "Fabrication de carton ondulé"
    },
    {
        "code": "1721B",
        "value": "Fabrication de cartonnages"
    },
    {
        "code": "1721C",
        "value": "Fabrication d'emballages en papier"
    },
    {
        "code": "1722Z",
        "value": "Fabrication d'articles en papier à usage sanitaire ou domestique"
    },
    {
        "code": "1723Z",
        "value": "Fabrication d'articles de papeterie"
    },
    {
        "code": "1724Z",
        "value": "Fabrication de papiers peints"
    },
    {
        "code": "1729Z",
        "value": "Fabrication d'autres articles en papier ou en carton"
    },
    {
        "code": "1811Z",
        "value": "Imprimerie de journaux"
    },
    {
        "code": "1812Z",
        "value": "Autre imprimerie (labeur)"
    },
    {
        "code": "1813Z",
        "value": "Activités de pré-presse"
    },
    {
        "code": "1814Z",
        "value": "Reliure et activités connexes"
    },
    {
        "code": "1820Z",
        "value": "Reproduction d'enregistrements"
    },
    {
        "code": "1910Z",
        "value": "Cokéfaction"
    },
    {
        "code": "1920Z",
        "value": "Raffinage du pétrole"
    },
    {
        "code": "2011Z",
        "value": "Fabrication de gaz industriels"
    },
    {
        "code": "2012Z",
        "value": "Fabrication de colorants et de pigments"
    },
    {
        "code": "2013A",
        "value": "Enrichissement et retraitement de matières nucléaires"
    },
    {
        "code": "2013B",
        "value": "Fabrication d'autres produits chimiques inorganiques de base nca"
    },
    {
        "code": "2014Z",
        "value": "Fabrication d'autres produits chimiques organiques de base"
    },
    {
        "code": "2015Z",
        "value": "Fabrication de produits azotés et d'engrais"
    },
    {
        "code": "2016Z",
        "value": "Fabrication de matières plastiques de base"
    },
    {
        "code": "2017Z",
        "value": "Fabrication de caoutchouc synthétique"
    },
    {
        "code": "2020Z",
        "value": "Fabrication de pesticides et d’autres produits agrochimiques"
    },
    {
        "code": "2030Z",
        "value": "Fabrication de peintures, vernis, encres et mastics"
    },
    {
        "code": "2041Z",
        "value": "Fabrication de savons, détergents et produits d'entretien"
    },
    {
        "code": "2042Z",
        "value": "Fabrication de parfums et de produits pour la toilette"
    },
    {
        "code": "2051Z",
        "value": "Fabrication de produits explosifs"
    },
    {
        "code": "2052Z",
        "value": "Fabrication de colles"
    },
    {
        "code": "2053Z",
        "value": "Fabrication d'huiles essentielles"
    },
    {
        "code": "2059Z",
        "value": "Fabrication d'autres produits chimiques nca"
    },
    {
        "code": "2060Z",
        "value": "Fabrication de fibres artificielles ou synthétiques"
    },
    {
        "code": "2110Z",
        "value": "Fabrication de produits pharmaceutiques de base"
    },
    {
        "code": "2120Z",
        "value": "Fabrication de préparations pharmaceutiques"
    },
    {
        "code": "2211Z",
        "value": "Fabrication et rechapage de pneumatiques"
    },
    {
        "code": "2219Z",
        "value": "Fabrication d'autres articles en caoutchouc"
    },
    {
        "code": "2221Z",
        "value": "Fabrication de plaques, feuilles, tubes et profilés en matières plastiques"
    },
    {
        "code": "2222Z",
        "value": "Fabrication d'emballages en matières plastiques"
    },
    {
        "code": "2223Z",
        "value": "Fabrication d'éléments en matières plastiques pour la construction"
    },
    {
        "code": "2229A",
        "value": "Fabrication de pièces techniques à base de matières plastiques"
    },
    {
        "code": "2229B",
        "value": "Fabrication de produits de consommation courante en matières plastiques"
    },
    {
        "code": "2311Z",
        "value": "Fabrication de verre plat"
    },
    {
        "code": "2312Z",
        "value": "Façonnage et transformation du verre plat"
    },
    {
        "code": "2313Z",
        "value": "Fabrication de verre creux"
    },
    {
        "code": "2314Z",
        "value": "Fabrication de fibres de verre"
    },
    {
        "code": "2319Z",
        "value": "Fabrication et façonnage d'autres articles en verre, y compris verre technique"
    },
    {
        "code": "2320Z",
        "value": "Fabrication de produits réfractaires"
    },
    {
        "code": "2331Z",
        "value": "Fabrication de carreaux en céramique"
    },
    {
        "code": "2332Z",
        "value": "Fabrication de briques, tuiles et produits de construction, en terre cuite"
    },
    {
        "code": "2341Z",
        "value": "Fabrication d'articles céramiques à usage domestique ou ornemental"
    },
    {
        "code": "2342Z",
        "value": "Fabrication d'appareils sanitaires en céramique"
    },
    {
        "code": "2343Z",
        "value": "Fabrication d'isolateurs et pièces isolantes en céramique"
    },
    {
        "code": "2344Z",
        "value": "Fabrication d'autres produits céramiques à usage technique"
    },
    {
        "code": "2349Z",
        "value": "Fabrication d'autres produits céramiques"
    },
    {
        "code": "2351Z",
        "value": "Fabrication de ciment"
    },
    {
        "code": "2352Z",
        "value": "Fabrication de chaux et plâtre"
    },
    {
        "code": "2361Z",
        "value": "Fabrication d'éléments en béton pour la construction"
    },
    {
        "code": "2362Z",
        "value": "Fabrication d'éléments en plâtre pour la construction"
    },
    {
        "code": "2363Z",
        "value": "Fabrication de béton prêt à l'emploi"
    },
    {
        "code": "2364Z",
        "value": "Fabrication de mortiers et bétons secs"
    },
    {
        "code": "2365Z",
        "value": "Fabrication d'ouvrages en fibre-ciment"
    },
    {
        "code": "2369Z",
        "value": "Fabrication d'autres ouvrages en béton, en ciment ou en plâtre"
    },
    {
        "code": "2370Z",
        "value": "Taille, façonnage et finissage de pierres"
    },
    {
        "code": "2391Z",
        "value": "Fabrication de produits abrasifs"
    },
    {
        "code": "2399Z",
        "value": "Fabrication d'autres produits minéraux non métalliques nca"
    },
    {
        "code": "2410Z",
        "value": "Sidérurgie"
    },
    {
        "code": "2420Z",
        "value": "Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier"
    },
    {
        "code": "2431Z",
        "value": "Étirage à froid de barres"
    },
    {
        "code": "2432Z",
        "value": "Laminage à froid de feuillards"
    },
    {
        "code": "2433Z",
        "value": "Profilage à froid par formage ou pliage"
    },
    {
        "code": "2434Z",
        "value": "Tréfilage à froid"
    },
    {
        "code": "2441Z",
        "value": "Production de métaux précieux"
    },
    {
        "code": "2442Z",
        "value": "Métallurgie de l'aluminium"
    },
    {
        "code": "2443Z",
        "value": "Métallurgie du plomb, du zinc ou de l'étain"
    },
    {
        "code": "2444Z",
        "value": "Métallurgie du cuivre"
    },
    {
        "code": "2445Z",
        "value": "Métallurgie des autres métaux non ferreux"
    },
    {
        "code": "2446Z",
        "value": "Élaboration et transformation de matières nucléaires"
    },
    {
        "code": "2451Z",
        "value": "Fonderie de fonte"
    },
    {
        "code": "2452Z",
        "value": "Fonderie d'acier"
    },
    {
        "code": "2453Z",
        "value": "Fonderie de métaux légers"
    },
    {
        "code": "2454Z",
        "value": "Fonderie d'autres métaux non ferreux"
    },
    {
        "code": "2511Z",
        "value": "Fabrication de structures métalliques et de parties de structures"
    },
    {
        "code": "2512Z",
        "value": "Fabrication de portes et fenêtres en métal"
    },
    {
        "code": "2521Z",
        "value": "Fabrication de radiateurs et de chaudières pour le chauffage central"
    },
    {
        "code": "2529Z",
        "value": "Fabrication d'autres réservoirs, citernes et conteneurs métalliques"
    },
    {
        "code": "2530Z",
        "value": "Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central"
    },
    {
        "code": "2540Z",
        "value": "Fabrication d'armes et de munitions"
    },
    {
        "code": "2550A",
        "value": "Forge, estampage, matriçage ; métallurgie des poudres"
    },
    {
        "code": "2550B",
        "value": "Découpage, emboutissage"
    },
    {
        "code": "2561Z",
        "value": "Traitement et revêtement des métaux"
    },
    {
        "code": "2562A",
        "value": "Décolletage"
    },
    {
        "code": "2562B",
        "value": "Mécanique industrielle"
    },
    {
        "code": "2571Z",
        "value": "Fabrication de coutellerie"
    },
    {
        "code": "2572Z",
        "value": "Fabrication de serrures et de ferrures"
    },
    {
        "code": "2573A",
        "value": "Fabrication de moules et modèles"
    },
    {
        "code": "2573B",
        "value": "Fabrication d'autres outillages"
    },
    {
        "code": "2591Z",
        "value": "Fabrication de fûts et emballages métalliques similaires"
    },
    {
        "code": "2592Z",
        "value": "Fabrication d'emballages métalliques légers"
    },
    {
        "code": "2593Z",
        "value": "Fabrication d'articles en fils métalliques, de chaînes et de ressorts"
    },
    {
        "code": "2594Z",
        "value": "Fabrication de vis et de boulons"
    },
    {
        "code": "2599A",
        "value": "Fabrication d'articles métalliques ménagers"
    },
    {
        "code": "2599B",
        "value": "Fabrication d'autres articles métalliques"
    },
    {
        "code": "2611Z",
        "value": "Fabrication de composants électroniques"
    },
    {
        "code": "2612Z",
        "value": "Fabrication de cartes électroniques assemblées"
    },
    {
        "code": "2620Z",
        "value": "Fabrication d'ordinateurs et d'équipements périphériques"
    },
    {
        "code": "2630Z",
        "value": "Fabrication d'équipements de communication"
    },
    {
        "code": "2640Z",
        "value": "Fabrication de produits électroniques grand public"
    },
    {
        "code": "2651A",
        "value": "Fabrication d'équipements d'aide à la navigation"
    },
    {
        "code": "2651B",
        "value": "Fabrication d'instrumentation scientifique et technique"
    },
    {
        "code": "2652Z",
        "value": "Horlogerie"
    },
    {
        "code": "2660Z",
        "value": "Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques"
    },
    {
        "code": "2670Z",
        "value": "Fabrication de matériels optique et photographique"
    },
    {
        "code": "2680Z",
        "value": "Fabrication de supports magnétiques et optiques"
    },
    {
        "code": "2711Z",
        "value": "Fabrication de moteurs, génératrices et transformateurs électriques"
    },
    {
        "code": "2712Z",
        "value": "Fabrication de matériel de distribution et de commande électrique"
    },
    {
        "code": "2720Z",
        "value": "Fabrication de piles et d'accumulateurs électriques"
    },
    {
        "code": "2731Z",
        "value": "Fabrication de câbles de fibres optiques"
    },
    {
        "code": "2732Z",
        "value": "Fabrication d'autres fils et câbles électroniques ou électriques"
    },
    {
        "code": "2733Z",
        "value": "Fabrication de matériel d'installation électrique"
    },
    {
        "code": "2740Z",
        "value": "Fabrication d'appareils d'éclairage électrique"
    },
    {
        "code": "2751Z",
        "value": "Fabrication d'appareils électroménagers"
    },
    {
        "code": "2752Z",
        "value": "Fabrication d'appareils ménagers non électriques"
    },
    {
        "code": "2790Z",
        "value": "Fabrication d'autres matériels électriques"
    },
    {
        "code": "2811Z",
        "value": "Fabrication de moteurs et turbines, à l'exception des moteurs d’avions et de véhicules"
    },
    {
        "code": "2812Z",
        "value": "Fabrication d'équipements hydrauliques et pneumatiques"
    },
    {
        "code": "2813Z",
        "value": "Fabrication d'autres pompes et compresseurs"
    },
    {
        "code": "2814Z",
        "value": "Fabrication d'autres articles de robinetterie"
    },
    {
        "code": "2815Z",
        "value": "Fabrication d'engrenages et d'organes mécaniques de transmission"
    },
    {
        "code": "2821Z",
        "value": "Fabrication de fours et brûleurs"
    },
    {
        "code": "2822Z",
        "value": "Fabrication de matériel de levage et de manutention"
    },
    {
        "code": "2823Z",
        "value": "Fabrication de machines et d'équipements de bureau (à l'exception des ordinateurs et équipements périphériques)"
    },
    {
        "code": "2824Z",
        "value": "Fabrication d'outillage portatif à moteur incorporé"
    },
    {
        "code": "2825Z",
        "value": "Fabrication d'équipements aérauliques et frigorifiques industriels"
    },
    {
        "code": "2829A",
        "value": "Fabrication d'équipements d'emballage, de conditionnement et de pesage"
    },
    {
        "code": "2829B",
        "value": "Fabrication d'autres machines d'usage général"
    },
    {
        "code": "2830Z",
        "value": "Fabrication de machines agricoles et forestières"
    },
    {
        "code": "2841Z",
        "value": "Fabrication de machines-outils pour le travail des métaux"
    },
    {
        "code": "2849Z",
        "value": "Fabrication d'autres machines-outils"
    },
    {
        "code": "2891Z",
        "value": "Fabrication de machines pour la métallurgie"
    },
    {
        "code": "2892Z",
        "value": "Fabrication de machines pour l'extraction ou la construction"
    },
    {
        "code": "2893Z",
        "value": "Fabrication de machines pour l'industrie agro-alimentaire"
    },
    {
        "code": "2894Z",
        "value": "Fabrication de machines pour les industries textiles"
    },
    {
        "code": "2895Z",
        "value": "Fabrication de machines pour les industries du papier et du carton"
    },
    {
        "code": "2896Z",
        "value": "Fabrication de machines pour le travail du caoutchouc ou des plastiques"
    },
    {
        "code": "2899A",
        "value": "Fabrication de machines d'imprimerie"
    },
    {
        "code": "2899B",
        "value": "Fabrication d'autres machines spécialisées"
    },
    {
        "code": "2910Z",
        "value": "Construction de véhicules automobiles"
    },
    {
        "code": "2920Z",
        "value": "Fabrication de carrosseries et remorques"
    },
    {
        "code": "2931Z",
        "value": "Fabrication d'équipements électriques et électroniques automobiles"
    },
    {
        "code": "2932Z",
        "value": "Fabrication d'autres équipements automobiles"
    },
    {
        "code": "3011Z",
        "value": "Construction de navires et de structures flottantes"
    },
    {
        "code": "3012Z",
        "value": "Construction de bateaux de plaisance"
    },
    {
        "code": "3020Z",
        "value": "Construction de locomotives et d'autre matériel ferroviaire roulant"
    },
    {
        "code": "3030Z",
        "value": "Construction aéronautique et spatiale"
    },
    {
        "code": "3040Z",
        "value": "Construction de véhicules militaires de combat"
    },
    {
        "code": "3091Z",
        "value": "Fabrication de motocycles"
    },
    {
        "code": "3092Z",
        "value": "Fabrication de bicyclettes et de véhicules pour invalides"
    },
    {
        "code": "3099Z",
        "value": "Fabrication d’autres équipements de transport nca"
    },
    {
        "code": "3101Z",
        "value": "Fabrication de meubles de bureau et de magasin"
    },
    {
        "code": "3102Z",
        "value": "Fabrication de meubles de cuisine"
    },
    {
        "code": "3103Z",
        "value": "Fabrication de matelas"
    },
    {
        "code": "3109A",
        "value": "Fabrication de sièges d'ameublement d'intérieur"
    },
    {
        "code": "3109B",
        "value": "Fabrication d’autres meubles et industries connexes de l’ameublement"
    },
    {
        "code": "3109B",
        "value": "Fabrication d'autres meubles et industries connexes de l'ameublement"
    },
    {
        "code": "3211Z",
        "value": "Frappe de monnaie"
    },
    {
        "code": "3212Z",
        "value": "Fabrication d’articles de joaillerie et bijouterie"
    },
    {
        "code": "3213Z",
        "value": "Fabrication d’articles de bijouterie fantaisie et articles similaires"
    },
    {
        "code": "3220Z",
        "value": "Fabrication d'instruments de musique"
    },
    {
        "code": "3230Z",
        "value": "Fabrication d'articles de sport"
    },
    {
        "code": "3240Z",
        "value": "Fabrication de jeux et jouets"
    },
    {
        "code": "3250A",
        "value": "Fabrication de matériel chirurgical et dentaire"
    },
    {
        "code": "3250A",
        "value": "Fabrication de matériel médico-chirurgical et dentaire"
    },
    {
        "code": "3250A",
        "value": "Fabrication de matériels médico-chirurgical et dentaire"
    },
    {
        "code": "3250B",
        "value": "Fabrication de lunettes"
    },
    {
        "code": "3291Z",
        "value": "Fabrication d’articles de brosserie"
    },
    {
        "code": "3299Z",
        "value": "Autres activités manufacturières nca"
    },
    {
        "code": "3311Z",
        "value": "Réparation d'ouvrages en métaux"
    },
    {
        "code": "3312Z",
        "value": "Réparation de machines et équipements mécaniques"
    },
    {
        "code": "3313Z",
        "value": "Réparation de matériels électroniques et optiques"
    },
    {
        "code": "3314Z",
        "value": "Réparation d'équipements électriques"
    },
    {
        "code": "3315Z",
        "value": "Réparation et maintenance navale"
    },
    {
        "code": "3316Z",
        "value": "Réparation et maintenance d'aéronefs et d'engins spatiaux"
    },
    {
        "code": "3317Z",
        "value": "Réparation et maintenance d'autres équipements de transport"
    },
    {
        "code": "3319Z",
        "value": "Réparation d'autres équipements"
    },
    {
        "code": "3320A",
        "value": "Installation de structures métalliques, chaudronnées et de tuyauterie"
    },
    {
        "code": "3320B",
        "value": "Installation de machines et équipements mécaniques"
    },
    {
        "code": "3320C",
        "value": "Conception d'ensemble et assemblage sur site industriel d'équipements de contrôle des processus industriels"
    },
    {
        "code": "3320D",
        "value": "Installation d'équipements électriques, de matériels électroniques et optiques ou d'autres matériels"
    },
    {
        "code": "3511Z",
        "value": "Production d'électricité"
    },
    {
        "code": "3512Z",
        "value": "Transport d'électricité"
    },
    {
        "code": "3513Z",
        "value": "Distribution d'électricité"
    },
    {
        "code": "3514Z",
        "value": "Commerce d'électricité"
    },
    {
        "code": "3521Z",
        "value": "Production de combustibles gazeux"
    },
    {
        "code": "3522Z",
        "value": "Distribution de combustibles gazeux par conduites"
    },
    {
        "code": "3523Z",
        "value": "Commerce de combustibles gazeux par conduites"
    },
    {
        "code": "3530Z",
        "value": "Production et distribution de vapeur et d'air conditionné"
    },
    {
        "code": "3600Z",
        "value": "Captage, traitement et distribution d'eau"
    },
    {
        "code": "3700Z",
        "value": "Collecte et traitement des eaux usées"
    },
    {
        "code": "3811Z",
        "value": "Collecte des déchets non dangereux"
    },
    {
        "code": "3812Z",
        "value": "Collecte des déchets dangereux"
    },
    {
        "code": "3821Z",
        "value": "Traitement et élimination des déchets non dangereux"
    },
    {
        "code": "3822Z",
        "value": "Traitement et élimination des déchets dangereux"
    },
    {
        "code": "3831Z",
        "value": "Démantèlement d'épaves"
    },
    {
        "code": "3832Z",
        "value": "Récupération de déchets triés"
    },
    {
        "code": "3900Z",
        "value": "Dépollution et autres services de gestion des déchets"
    },
    {
        "code": "4110A",
        "value": "Promotion immobilière de logements"
    },
    {
        "code": "4110B",
        "value": "Promotion immobilière de bureaux"
    },
    {
        "code": "4110C",
        "value": "Promotion immobilière d'autres bâtiments"
    },
    {
        "code": "4110D",
        "value": "Supports juridiques de programmes"
    },
    {
        "code": "4120A",
        "value": "Construction de maisons individuelles"
    },
    {
        "code": "4120B",
        "value": "Construction d'autres bâtiments"
    },
    {
        "code": "4211Z",
        "value": "Construction de routes et autoroutes"
    },
    {
        "code": "4212Z",
        "value": "Construction de voies ferrées de surface et souterraines"
    },
    {
        "code": "4213A",
        "value": "Construction d'ouvrages d'art"
    },
    {
        "code": "4213B",
        "value": "Construction et entretien de tunnels"
    },
    {
        "code": "4221Z",
        "value": "Construction de réseaux pour fluides"
    },
    {
        "code": "4222Z",
        "value": "Construction de réseaux électriques et de télécommunications"
    },
    {
        "code": "4291Z",
        "value": "Construction d'ouvrages maritimes et fluviaux"
    },
    {
        "code": "4299Z",
        "value": "Construction d'autres ouvrages de génie civil nca"
    },
    {
        "code": "4311Z",
        "value": "Travaux de démolition"
    },
    {
        "code": "4312A",
        "value": "Travaux de terrassement courants et travaux préparatoires"
    },
    {
        "code": "4312B",
        "value": "Travaux de terrassement spécialisés ou de grande masse"
    },
    {
        "code": "4313Z",
        "value": "Forages et sondages"
    },
    {
        "code": "4321A",
        "value": "Travaux d'installation électrique dans tous locaux"
    },
    {
        "code": "4321B",
        "value": "Travaux d'installation électrique sur la voie publique"
    },
    {
        "code": "4322A",
        "value": "Travaux d'installation d'eau et de gaz en tous locaux"
    },
    {
        "code": "4322B",
        "value": "Travaux d' installation d'équipements thermiques et de climatisation"
    },
    {
        "code": "4329A",
        "value": "Travaux d'isolation"
    },
    {
        "code": "4329B",
        "value": "Autres travaux d'installation nca"
    },
    {
        "code": "4331Z",
        "value": "Travaux de plâtrerie"
    },
    {
        "code": "4332A",
        "value": "Travaux de menuiserie bois et PVC"
    },
    {
        "code": "4332B",
        "value": "Travaux de menuiserie métallique et serrurerie"
    },
    {
        "code": "4332C",
        "value": "Agencement de lieux de vente"
    },
    {
        "code": "4333Z",
        "value": "Travaux de revêtement des sols et des murs"
    },
    {
        "code": "4334Z",
        "value": "Travaux de peinture et vitrerie"
    },
    {
        "code": "4339Z",
        "value": "Autres travaux de finition"
    },
    {
        "code": "4391A",
        "value": "Travaux de charpente"
    },
    {
        "code": "4391B",
        "value": "Travaux de couverture par éléments"
    },
    {
        "code": "4399A",
        "value": "Travaux d'étanchéification"
    },
    {
        "code": "4399B",
        "value": "Travaux de montage de structures métalliques"
    },
    {
        "code": "4399C",
        "value": "Travaux de maçonnerie générale et gros œuvre de bâtiment"
    },
    {
        "code": "4399D",
        "value": "Autres travaux spécialisés de construction"
    },
    {
        "code": "4399E",
        "value": "Location avec opérateur de matériel de construction"
    },
    {
        "code": "4511Z",
        "value": "Commerce de voitures et de véhicules automobiles légers"
    },
    {
        "code": "4519Z",
        "value": "Commerce d'autres véhicules automobiles"
    },
    {
        "code": "4520A",
        "value": "Entretien et réparation de véhicules automobiles légers"
    },
    {
        "code": "4520B",
        "value": "Entretien et réparation d'autres véhicules automobiles"
    },
    {
        "code": "4531Z",
        "value": "Commerce de gros d'équipements automobiles"
    },
    {
        "code": "4532Z",
        "value": "Commerce de détail d'équipements automobiles"
    },
    {
        "code": "4540Z",
        "value": "Commerce et réparation de motocycles"
    },
    {
        "code": "4611Z",
        "value": "Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis"
    },
    {
        "code": "4612A",
        "value": "Centrales d'achat de carburant"
    },
    {
        "code": "4612B",
        "value": "Autres intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques"
    },
    {
        "code": "4613Z",
        "value": "Intermédiaires du commerce en bois et matériaux de construction"
    },
    {
        "code": "4614Z",
        "value": "Intermédiaires du commerce en machines, équipements industriels, navires et avions"
    },
    {
        "code": "4615Z",
        "value": "Intermédiaires du commerce en meubles, articles de ménage et quincaillerie"
    },
    {
        "code": "4616Z",
        "value": "Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir"
    },
    {
        "code": "4617A",
        "value": "Centrales d'achat alimentaires"
    },
    {
        "code": "4617B",
        "value": "Autres intermédiaires du commerce en denrées, boissons et tabac"
    },
    {
        "code": "4618Z",
        "value": "Intermédiaires spécialisés dans le commerce d'autres produits spécifiques"
    },
    {
        "code": "4619A",
        "value": "Centrales d'achat non alimentaires"
    },
    {
        "code": "4619B",
        "value": "Autres intermédiaires du commerce en produits divers"
    },
    {
        "code": "4621Z",
        "value": "Commerce de gros (commerce interentreprises) de céréales, de tabac non manufacturé, de semences et d'aliments pour le bétail"
    },
    {
        "code": "4622Z",
        "value": "Commerce de gros (commerce interentreprises) de fleurs et plantes"
    },
    {
        "code": "4623Z",
        "value": "Commerce de gros (commerce interentreprises) d'animaux vivants"
    },
    {
        "code": "4624Z",
        "value": "Commerce de gros (commerce interentreprises) de cuirs et peaux"
    },
    {
        "code": "4631Z",
        "value": "Commerce de gros (commerce interentreprises) de fruits et légumes"
    },
    {
        "code": "4632A",
        "value": "Commerce de gros (commerce interentreprises) de viandes de boucherie"
    },
    {
        "code": "4632B",
        "value": "Commerce de gros (commerce interentreprises) de produits à base de viande"
    },
    {
        "code": "4632C",
        "value": "Commerce de gros (commerce interentreprises) de volailles et gibier"
    },
    {
        "code": "4633Z",
        "value": "Commerce de gros (commerce interentreprises) de produits laitiers, œufs, huiles et matières grasses comestibles"
    },
    {
        "code": "4634Z",
        "value": "Commerce de gros (commerce interentreprises) de boissons"
    },
    {
        "code": "4635Z",
        "value": "Commerce de gros (commerce interentreprises) de produits à base de tabac"
    },
    {
        "code": "4636Z",
        "value": "Commerce de gros (commerce interentreprises) de sucre, chocolat et confiserie"
    },
    {
        "code": "4637Z",
        "value": "Commerce de gros (commerce interentreprises) de café, thé, cacao et épices"
    },
    {
        "code": "4638A",
        "value": "Commerce de gros (commerce interentreprises) de poissons, crustacés et mollusques"
    },
    {
        "code": "4638B",
        "value": "Commerce de gros (commerce interentreprises) alimentaire spécialisé divers"
    },
    {
        "code": "4639A",
        "value": "Commerce de gros (commerce interentreprises) de produits surgelés"
    },
    {
        "code": "4639B",
        "value": "Commerce de gros (commerce interentreprises) alimentaire non spécialisé"
    },
    {
        "code": "4641Z",
        "value": "Commerce de gros (commerce interentreprises) de textiles"
    },
    {
        "code": "4642Z",
        "value": "Commerce de gros (commerce interentreprises) d'habillement et de chaussures"
    },
    {
        "code": "4643Z",
        "value": "Commerce de gros (commerce interentreprises) d'appareils électroménagers"
    },
    {
        "code": "4644Z",
        "value": "Commerce de gros (commerce interentreprises) de vaisselle, verrerie et produits d'entretien"
    },
    {
        "code": "4645Z",
        "value": "Commerce de gros (commerce interentreprises) de parfumerie et de produits de beauté"
    },
    {
        "code": "4646Z",
        "value": "Commerce de gros (commerce interentreprises) de produits pharmaceutiques"
    },
    {
        "code": "4647Z",
        "value": "Commerce de gros (commerce interentreprises) de meubles, de tapis et d'appareils d'éclairage"
    },
    {
        "code": "4648Z",
        "value": "Commerce de gros (commerce interentreprises) d'articles d'horlogerie et de bijouterie"
    },
    {
        "code": "4649Z",
        "value": "Commerce de gros (commerce interentreprises) d'autres biens domestiques"
    },
    {
        "code": "4651Z",
        "value": "Commerce de gros (commerce interentreprises) d'ordinateurs, d'équipements informatiques périphériques et de logiciels"
    },
    {
        "code": "4652Z",
        "value": "Commerce de gros (commerce interentreprises) de composants et d'équipements électroniques et de télécommunication"
    },
    {
        "code": "4661Z",
        "value": "Commerce de gros (commerce interentreprises) de matériel agricole"
    },
    {
        "code": "4662Z",
        "value": "Commerce de gros (commerce interentreprises) de machines-outils"
    },
    {
        "code": "4663Z",
        "value": "Commerce de gros (commerce interentreprises) de machines pour l'extraction, la construction et le génie civil"
    },
    {
        "code": "4664Z",
        "value": "Commerce de gros (commerce interentreprises) de machines pour l'industrie textile et l'habillement"
    },
    {
        "code": "4665Z",
        "value": "Commerce de gros (commerce interentreprises) de mobilier de bureau"
    },
    {
        "code": "4666Z",
        "value": "Commerce de gros (commerce interentreprises) d'autres machines et équipements de bureau"
    },
    {
        "code": "4669A",
        "value": "Commerce de gros (commerce interentreprises) de matériel électrique"
    },
    {
        "code": "4669B",
        "value": "Commerce de gros (commerce interentreprises) de fournitures et équipements industriels divers"
    },
    {
        "code": "4669C",
        "value": "Commerce de gros (commerce interentreprises) de fournitures et équipements divers pour le commerce et les services"
    },
    {
        "code": "4671Z",
        "value": "Commerce de gros (commerce interentreprises) de combustibles et de produits annexes"
    },
    {
        "code": "4672Z",
        "value": "Commerce de gros (commerce interentreprises) de minerais et métaux"
    },
    {
        "code": "4673A",
        "value": "Commerce de gros (commerce interentreprises) de bois et de matériaux de construction"
    },
    {
        "code": "4673B",
        "value": "Commerce de gros (commerce interentreprises) d'appareils sanitaires et de produits de décoration"
    },
    {
        "code": "4674A",
        "value": "Commerce de gros (commerce interentreprises) de quincaillerie"
    },
    {
        "code": "4674B",
        "value": "Commerce de gros (commerce interentreprises) de fournitures pour la plomberie et le chauffage"
    },
    {
        "code": "4675Z",
        "value": "Commerce de gros (commerce interentreprises) de produits chimiques"
    },
    {
        "code": "4676Z",
        "value": "Commerce de gros (commerce interentreprises) d'autres produits intermédiaires"
    },
    {
        "code": "4677Z",
        "value": "Commerce de gros (commerce interentreprises) de déchets et débris"
    },
    {
        "code": "4690Z",
        "value": "Commerce de gros (commerce interentreprises) non spécialisé"
    },
    {
        "code": "4711A",
        "value": "Commerce de détail de produits surgelés"
    },
    {
        "code": "4711B",
        "value": "Commerce d'alimentation générale"
    },
    {
        "code": "4711C",
        "value": "Supérettes"
    },
    {
        "code": "4711D",
        "value": "Supermarchés"
    },
    {
        "code": "4711E",
        "value": "Magasins multi-commerces"
    },
    {
        "code": "4711F",
        "value": "Hypermarchés"
    },
    {
        "code": "4719A",
        "value": "Grands magasins"
    },
    {
        "code": "4719B",
        "value": "Autres commerces de détail en magasin non spécialisé"
    },
    {
        "code": "4721Z",
        "value": "Commerce de détail de fruits et légumes en magasin spécialisé"
    },
    {
        "code": "4722Z",
        "value": "Commerce de détail de viandes et de produits à base de viande en magasin spécialisé"
    },
    {
        "code": "4723Z",
        "value": "Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé"
    },
    {
        "code": "4724Z",
        "value": "Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé"
    },
    {
        "code": "4725Z",
        "value": "Commerce de détail de boissons en magasin spécialisé"
    },
    {
        "code": "4726Z",
        "value": "Commerce de détail de produits à base de tabac en magasin spécialisé"
    },
    {
        "code": "4729Z",
        "value": "Autres commerces de détail alimentaires en magasin spécialisé"
    },
    {
        "code": "4730Z",
        "value": "Commerce de détail de carburants en magasin spécialisé"
    },
    {
        "code": "4741Z",
        "value": "Commerce de détail d'ordinateurs, d'unités périphériques et de logiciels en magasin spécialisé"
    },
    {
        "code": "4742Z",
        "value": "Commerce de détail de matériels de télécommunication en magasin spécialisé"
    },
    {
        "code": "4743Z",
        "value": "Commerce de détail de matériels audio et vidéo en magasin spécialisé"
    },
    {
        "code": "4751Z",
        "value": "Commerce de détail de textiles en magasin spécialisé"
    },
    {
        "code": "4752A",
        "value": "Commerce de détail de quincaillerie, peintures et verres en petites surfaces (moins de 400 m2)"
    },
    {
        "code": "4752B",
        "value": "Commerce de détail de quincaillerie, peintures et verres en grandes surfaces (400 m2 et plus)"
    },
    {
        "code": "4753Z",
        "value": "Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé"
    },
    {
        "code": "4754Z",
        "value": "Commerce de détail d'appareils électroménagers en magasin spécialisé"
    },
    {
        "code": "4759A",
        "value": "Commerce de détail de meubles"
    },
    {
        "code": "4759B",
        "value": "Commerce de détail d'autres équipements du foyer"
    },
    {
        "code": "4761Z",
        "value": "Commerce de détail de livres en magasin spécialisé"
    },
    {
        "code": "4762Z",
        "value": "Commerce de détail de journaux et papeterie en magasin spécialisé"
    },
    {
        "code": "4763Z",
        "value": "Commerce de détail d'enregistrements musicaux et vidéo en magasin spécialisé"
    },
    {
        "code": "4764Z",
        "value": "Commerce de détail d'articles de sport en magasin spécialisé"
    },
    {
        "code": "4765Z",
        "value": "Commerce de détail de jeux et jouets en magasin spécialisé"
    },
    {
        "code": "4771Z",
        "value": "Commerce de détail d'habillement en magasin spécialisé"
    },
    {
        "code": "4772A",
        "value": "Commerce de détail de la chaussure"
    },
    {
        "code": "4772B",
        "value": "Commerce de détail de maroquinerie et d'articles de voyage"
    },
    {
        "code": "4773Z",
        "value": "Commerce de détail de produits pharmaceutiques en magasin spécialisé"
    },
    {
        "code": "4774Z",
        "value": "Commerce de détail d'articles médicaux et orthopédiques en magasin spécialisé"
    },
    {
        "code": "4775Z",
        "value": "Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé"
    },
    {
        "code": "4776Z",
        "value": "Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé"
    },
    {
        "code": "4777Z",
        "value": "Commerce de détail d'articles d'horlogerie et de bijouterie en magasin spécialisé"
    },
    {
        "code": "4778A",
        "value": "Commerces de détail d'optique"
    },
    {
        "code": "4778B",
        "value": "Commerces de détail de charbons et combustibles"
    },
    {
        "code": "4778C",
        "value": "Autres commerces de détail spécialisés divers"
    },
    {
        "code": "4779Z",
        "value": "Commerce de détail de biens d'occasion en magasin"
    },
    {
        "code": "4781Z",
        "value": "Commerce de détail alimentaire sur éventaires et marchés"
    },
    {
        "code": "4782Z",
        "value": "Commerce de détail de textiles, d'habillement et de chaussures sur éventaires et marchés"
    },
    {
        "code": "4789Z",
        "value": "Autres commerces de détail sur éventaires et marchés"
    },
    {
        "code": "4791A",
        "value": "Vente à distance sur catalogue général"
    },
    {
        "code": "4791B",
        "value": "Vente à distance sur catalogue spécialisé"
    },
    {
        "code": "4799A",
        "value": "Vente à domicile"
    },
    {
        "code": "4799B",
        "value": "Vente par automates et autres commerces de détail hors magasin, éventaires ou marchés nca"
    },
    {
        "code": "4910Z",
        "value": "Transport ferroviaire interurbain de voyageurs"
    },
    {
        "code": "4920Z",
        "value": "Transports ferroviaires de fret"
    },
    {
        "code": "4931Z",
        "value": "Transports urbains et suburbains de voyageurs"
    },
    {
        "code": "4932Z",
        "value": "Transports de voyageurs par taxis"
    },
    {
        "code": "4939A",
        "value": "Transports routiers réguliers de voyageurs"
    },
    {
        "code": "4939B",
        "value": "Autres transports routiers de voyageurs"
    },
    {
        "code": "4939C",
        "value": "Téléphériques et remontées mécaniques"
    },
    {
        "code": "4941A",
        "value": "Transports routiers de fret interurbains"
    },
    {
        "code": "4941B",
        "value": "Transports routiers de fret de proximité"
    },
    {
        "code": "4941C",
        "value": "Location de camions avec chauffeur"
    },
    {
        "code": "4942Z",
        "value": "Services de déménagement"
    },
    {
        "code": "4950Z",
        "value": "Transports par conduites"
    },
    {
        "code": "5010Z",
        "value": "Transports maritimes et côtiers de passagers"
    },
    {
        "code": "5020Z",
        "value": "Transports maritimes et côtiers de fret"
    },
    {
        "code": "5030Z",
        "value": "Transports fluviaux de passagers"
    },
    {
        "code": "5040Z",
        "value": "Transports fluviaux de fret"
    },
    {
        "code": "5110Z",
        "value": "Transports aériens de passagers"
    },
    {
        "code": "5121Z",
        "value": "Transports aériens de fret"
    },
    {
        "code": "5122Z",
        "value": "Transports spatiaux"
    },
    {
        "code": "5210A",
        "value": "Entreposage et stockage frigorifique"
    },
    {
        "code": "5210B",
        "value": "Entreposage et stockage non frigorifique"
    },
    {
        "code": "5221Z",
        "value": "Services auxiliaires des transports terrestres"
    },
    {
        "code": "5222Z",
        "value": "Services auxiliaires des transports par eau"
    },
    {
        "code": "5223Z",
        "value": "Services auxiliaires des transports aériens"
    },
    {
        "code": "5224A",
        "value": "Manutention portuaire"
    },
    {
        "code": "5224B",
        "value": "Manutention non portuaire"
    },
    {
        "code": "5229A",
        "value": "Messagerie, fret express"
    },
    {
        "code": "5229B",
        "value": "Affrètement et organisation des transports"
    },
    {
        "code": "5310Z",
        "value": "Activités de poste dans le cadre d'une obligation de service universel"
    },
    {
        "code": "5320Z",
        "value": "Autres activités de poste et de courrier"
    },
    {
        "code": "5510Z",
        "value": "Hôtels et hébergement similaire"
    },
    {
        "code": "5520Z",
        "value": "Hébergement touristique et autre hébergement de courte durée"
    },
    {
        "code": "5530Z",
        "value": "Terrains de camping et parcs pour caravanes ou véhicules de loisirs"
    },
    {
        "code": "5590Z",
        "value": "Autres hébergements"
    },
    {
        "code": "5610A",
        "value": "Restauration traditionnelle"
    },
    {
        "code": "5610B",
        "value": "Cafétérias et autres libres-services"
    },
    {
        "code": "5610C",
        "value": "Restauration de type rapide"
    },
    {
        "code": "5621Z",
        "value": "Services des traiteurs"
    },
    {
        "code": "5629A",
        "value": "Restauration collective sous contrat"
    },
    {
        "code": "5629B",
        "value": "Autres services de restauration nca"
    },
    {
        "code": "5630Z",
        "value": "Débits de boissons"
    },
    {
        "code": "5811Z",
        "value": "Édition de livres"
    },
    {
        "code": "5812Z",
        "value": "Édition de répertoires et de fichiers d'adresses"
    },
    {
        "code": "5813Z",
        "value": "Édition de journaux"
    },
    {
        "code": "5814Z",
        "value": "Édition de revues et périodiques"
    },
    {
        "code": "5819Z",
        "value": "Autres activités d'édition"
    },
    {
        "code": "5821Z",
        "value": "Édition de jeux électroniques"
    },
    {
        "code": "5829A",
        "value": "Édition de logiciels système et de réseau"
    },
    {
        "code": "5829B",
        "value": "Edition de logiciels outils de développement et de langages"
    },
    {
        "code": "5829C",
        "value": "Edition de logiciels applicatifs"
    },
    {
        "code": "5911A",
        "value": "Production de films et de programmes pour la télévision"
    },
    {
        "code": "5911B",
        "value": "Production de films institutionnels et publicitaires"
    },
    {
        "code": "5911C",
        "value": "Production de films pour le cinéma"
    },
    {
        "code": "5912Z",
        "value": "Post-production de films cinématographiques, de vidéo et de programmes de télévision"
    },
    {
        "code": "5913A",
        "value": "Distribution de films cinématographiques"
    },
    {
        "code": "5913B",
        "value": "Edition et distribution vidéo"
    },
    {
        "code": "5914Z",
        "value": "Projection de films cinématographiques"
    },
    {
        "code": "5920Z",
        "value": "Enregistrement sonore et édition musicale"
    },
    {
        "code": "6010Z",
        "value": "Édition et diffusion de programmes radio"
    },
    {
        "code": "6020A",
        "value": "Edition de chaînes généralistes"
    },
    {
        "code": "6020B",
        "value": "Edition de chaînes thématiques"
    },
    {
        "code": "6110Z",
        "value": "Télécommunications filaires"
    },
    {
        "code": "6120Z",
        "value": "Télécommunications sans fil"
    },
    {
        "code": "6130Z",
        "value": "Télécommunications par satellite"
    },
    {
        "code": "6190Z",
        "value": "Autres activités de télécommunication"
    },
    {
        "code": "6201Z",
        "value": "Programmation informatique"
    },
    {
        "code": "6202A",
        "value": "Conseil en systèmes et logiciels informatiques"
    },
    {
        "code": "6202B",
        "value": "Tierce maintenance de systèmes et d’applications informatiques"
    },
    {
        "code": "6203Z",
        "value": "Gestion d'installations informatiques"
    },
    {
        "code": "6209Z",
        "value": "Autres activités informatiques"
    },
    {
        "code": "6311Z",
        "value": "Traitement de données, hébergement et activités connexes"
    },
    {
        "code": "6312Z",
        "value": "Portails Internet"
    },
    {
        "code": "6391Z",
        "value": "Activités des agences de presse"
    },
    {
        "code": "6399Z",
        "value": "Autres services d'information nca"
    },
    {
        "code": "6411Z",
        "value": "Activités de banque centrale"
    },
    {
        "code": "6419Z",
        "value": "Autres intermédiations monétaires"
    },
    {
        "code": "6420Z",
        "value": "Activités des sociétés holding"
    },
    {
        "code": "6430Z",
        "value": "Fonds de placement et entités financières similaires"
    },
    {
        "code": "6491Z",
        "value": "Crédit-bail"
    },
    {
        "code": "6492Z",
        "value": "Autre distribution de crédit"
    },
    {
        "code": "6499Z",
        "value": "Autres activités des services financiers, hors assurance et caisses de retraite, nca"
    },
    {
        "code": "6511Z",
        "value": "Assurance vie"
    },
    {
        "code": "6512Z",
        "value": "Autres assurances"
    },
    {
        "code": "6520Z",
        "value": "Réassurance"
    },
    {
        "code": "6530Z",
        "value": "Caisses de retraite"
    },
    {
        "code": "6611Z",
        "value": "Administration de marchés financiers"
    },
    {
        "code": "6612Z",
        "value": "Courtage de valeurs mobilières et de marchandises"
    },
    {
        "code": "6619A",
        "value": "Supports juridiques de gestion de patrimoine mobilier"
    },
    {
        "code": "6619B",
        "value": "Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite, nca"
    },
    {
        "code": "6621Z",
        "value": "Évaluation des risques et dommages"
    },
    {
        "code": "6622Z",
        "value": "Activités des agents et courtiers d'assurances"
    },
    {
        "code": "6629Z",
        "value": "Autres activités auxiliaires d'assurance et de caisses de retraite"
    },
    {
        "code": "6630Z",
        "value": "Gestion de fonds"
    },
    {
        "code": "6810Z",
        "value": "Activités des marchands de biens immobiliers"
    },
    {
        "code": "6820A",
        "value": "Location de logements"
    },
    {
        "code": "6820B",
        "value": "Location de terrains et d'autres biens immobiliers"
    },
    {
        "code": "6831Z",
        "value": "Agences immobilières"
    },
    {
        "code": "6832A",
        "value": "Administration d'immeubles et autres biens immobiliers"
    },
    {
        "code": "6832B",
        "value": "Supports juridiques de gestion de patrimoine immobilier"
    },
    {
        "code": "6910Z",
        "value": "Activités juridiques"
    },
    {
        "code": "6920Z",
        "value": "Activités comptables"
    },
    {
        "code": "7010Z",
        "value": "Activités des sièges sociaux"
    },
    {
        "code": "7021Z",
        "value": "Conseil en relations publiques et communication"
    },
    {
        "code": "7022Z",
        "value": "Conseil pour les affaires et autres conseils de gestion"
    },
    {
        "code": "7111Z",
        "value": "Activités d'architecture"
    },
    {
        "code": "7112A",
        "value": "Activité des géomètres"
    },
    {
        "code": "7112B",
        "value": "Ingénierie, études techniques"
    },
    {
        "code": "7120A",
        "value": "Contrôle technique automobile"
    },
    {
        "code": "7120B",
        "value": "Analyses, essais et inspections techniques"
    },
    {
        "code": "7211Z",
        "value": "Recherche-développement en biotechnologie"
    },
    {
        "code": "7219Z",
        "value": "Recherche-développement en autres sciences physiques et naturelles"
    },
    {
        "code": "7220Z",
        "value": "Recherche-développement en sciences humaines et sociales"
    },
    {
        "code": "7311Z",
        "value": "Activités des agences de publicité"
    },
    {
        "code": "7312Z",
        "value": "Régie publicitaire de médias"
    },
    {
        "code": "7320Z",
        "value": "Études de marché et sondages"
    },
    {
        "code": "7410Z",
        "value": "Activités spécialisées de design"
    },
    {
        "code": "7420Z",
        "value": "Activités photographiques"
    },
    {
        "code": "7430Z",
        "value": "Traduction et interprétation"
    },
    {
        "code": "7490A",
        "value": "Activité des économistes de la construction"
    },
    {
        "code": "7490B",
        "value": "Activités spécialisées, scientifiques et techniques diverses"
    },
    {
        "code": "7500Z",
        "value": "Activités vétérinaires"
    },
    {
        "code": "7711A",
        "value": "Location de courte durée de voitures et de véhicules automobiles légers"
    },
    {
        "code": "7711B",
        "value": "Location de longue durée de voitures et de véhicules automobiles légers"
    },
    {
        "code": "7712Z",
        "value": "Location et location-bail de camions"
    },
    {
        "code": "7721Z",
        "value": "Location et location-bail d'articles de loisirs et de sport"
    },
    {
        "code": "7722Z",
        "value": "Location de vidéocassettes et disques vidéo"
    },
    {
        "code": "7729Z",
        "value": "Location et location-bail d'autres biens personnels et domestiques"
    },
    {
        "code": "7731Z",
        "value": "Location et location-bail de machines et équipements agricoles"
    },
    {
        "code": "7732Z",
        "value": "Location et location-bail de machines et équipements pour la construction"
    },
    {
        "code": "7733Z",
        "value": "Location et location-bail de machines de bureau et de matériel informatique"
    },
    {
        "code": "7734Z",
        "value": "Location et location-bail de matériels de transport par eau"
    },
    {
        "code": "7735Z",
        "value": "Location et location-bail de matériels de transport aérien"
    },
    {
        "code": "7739Z",
        "value": "Location et location-bail d'autres machines, équipements et biens matériels nca"
    },
    {
        "code": "7740Z",
        "value": "Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright"
    },
    {
        "code": "7810Z",
        "value": "Activités des agences de placement de main-d'œuvre"
    },
    {
        "code": "7820Z",
        "value": "Activités des agences de travail temporaire"
    },
    {
        "code": "7830Z",
        "value": "Autre mise à disposition de ressources humaines"
    },
    {
        "code": "7911Z",
        "value": "Activités des agences de voyage"
    },
    {
        "code": "7912Z",
        "value": "Activités des voyagistes"
    },
    {
        "code": "7990Z",
        "value": "Autres services de réservation et activités connexes"
    },
    {
        "code": "8010Z",
        "value": "Activités de sécurité privée"
    },
    {
        "code": "8020Z",
        "value": "Activités liées aux systèmes de sécurité"
    },
    {
        "code": "8030Z",
        "value": "Activités d'enquête"
    },
    {
        "code": "8110Z",
        "value": "Activités combinées de soutien lié aux bâtiments"
    },
    {
        "code": "8121Z",
        "value": "Nettoyage courant des bâtiments"
    },
    {
        "code": "8122Z",
        "value": "Autres activités de nettoyage des bâtiments et nettoyage industriel"
    },
    {
        "code": "8129A",
        "value": "Désinfection, désinsectisation, dératisation"
    },
    {
        "code": "8129B",
        "value": "Autres activités de nettoyage nca"
    },
    {
        "code": "8130Z",
        "value": "Services d'aménagement paysager"
    },
    {
        "code": "8211Z",
        "value": "Services administratifs combinés de bureau"
    },
    {
        "code": "8219Z",
        "value": "Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau"
    },
    {
        "code": "8220Z",
        "value": "Activités de centres d'appels"
    },
    {
        "code": "8230Z",
        "value": "Organisation de foires, salons professionnels et congrès"
    },
    {
        "code": "8291Z",
        "value": "Activités des agences de recouvrement de factures et des sociétés d'information financière sur la clientèle"
    },
    {
        "code": "8292Z",
        "value": "Activités de conditionnement"
    },
    {
        "code": "8299Z",
        "value": "Autres activités de soutien aux entreprises nca"
    },
    {
        "code": "8411Z",
        "value": "Administration publique générale"
    },
    {
        "code": "8412Z",
        "value": "Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale"
    },
    {
        "code": "8413Z",
        "value": "Administration publique (tutelle) des activités économiques"
    },
    {
        "code": "8421Z",
        "value": "Affaires étrangères"
    },
    {
        "code": "8422Z",
        "value": "Défense"
    },
    {
        "code": "8423Z",
        "value": "Justice"
    },
    {
        "code": "8424Z",
        "value": "Activités d’ordre public et de sécurité"
    },
    {
        "code": "8425Z",
        "value": "Services du feu et de secours"
    },
    {
        "code": "8430A",
        "value": "Activités générales de sécurité sociale"
    },
    {
        "code": "8430B",
        "value": "Gestion des retraites complémentaires"
    },
    {
        "code": "8430C",
        "value": "Distribution sociale de revenus"
    },
    {
        "code": "8510Z",
        "value": "Enseignement pré-primaire"
    },
    {
        "code": "8520Z",
        "value": "Enseignement primaire"
    },
    {
        "code": "8531Z",
        "value": "Enseignement secondaire général"
    },
    {
        "code": "8532Z",
        "value": "Enseignement secondaire technique ou professionnel"
    },
    {
        "code": "8541Z",
        "value": "Enseignement post-secondaire non supérieur"
    },
    {
        "code": "8542Z",
        "value": "Enseignement supérieur"
    },
    {
        "code": "8551Z",
        "value": "Enseignement de disciplines sportives et d'activités de loisirs"
    },
    {
        "code": "8552Z",
        "value": "Enseignement culturel"
    },
    {
        "code": "8553Z",
        "value": "Enseignement de la conduite"
    },
    {
        "code": "8559A",
        "value": "Formation continue d'adultes"
    },
    {
        "code": "8559B",
        "value": "Autres enseignements"
    },
    {
        "code": "8560Z",
        "value": "Activités de soutien à l'enseignement"
    },
    {
        "code": "8610Z",
        "value": "Activités hospitalières"
    },
    {
        "code": "8621Z",
        "value": "Activité des médecins généralistes"
    },
    {
        "code": "8622A",
        "value": "Activités de radiodiagnostic et de radiothérapie"
    },
    {
        "code": "8622B",
        "value": "Activités chirurgicales"
    },
    {
        "code": "8622C",
        "value": "Autres activités des médecins spécialistes"
    },
    {
        "code": "8623Z",
        "value": "Pratique dentaire"
    },
    {
        "code": "8690A",
        "value": "Ambulances"
    },
    {
        "code": "8690B",
        "value": "Laboratoires d'analyses médicales"
    },
    {
        "code": "8690C",
        "value": "Centres de collecte et banques d'organes"
    },
    {
        "code": "8690D",
        "value": "Activités des infirmiers et des sages-femmes"
    },
    {
        "code": "8690E",
        "value": "Activités des professionnels de la rééducation, de l’appareillage et des pédicures-podologues"
    },
    {
        "code": "8690F",
        "value": "Activités de santé humaine non classées ailleurs"
    },
    {
        "code": "8710A",
        "value": "Hébergement médicalisé pour personnes âgées"
    },
    {
        "code": "8710B",
        "value": "Hébergement médicalisés pour enfants handicapés"
    },
    {
        "code": "8710C",
        "value": "Hébergement médicalisé pour adultes handicapés et autre hébergement médicalisé "
    },
    {
        "code": "8720A",
        "value": "Hébergement social pour handicapés mentaux et malades mentaux"
    },
    {
        "code": "8720B",
        "value": "Hébergement social pour toxicomanes"
    },
    {
        "code": "8730A",
        "value": "Hébergement social pour personnes âgées"
    },
    {
        "code": "8730B",
        "value": "Hébergement social pour handicapés physiques"
    },
    {
        "code": "8790A",
        "value": "Hébergement social pour enfants en difficultés"
    },
    {
        "code": "8790B",
        "value": "Hébergement social pour adultes et familles en difficultés et autre hébergement social"
    },
    {
        "code": "8810A",
        "value": "Aide à domicile"
    },
    {
        "code": "8810B",
        "value": "Accueil ou accompagnement sans hébergement d’adultes handicapés ou de personnes âgées"
    },
    {
        "code": "8810C",
        "value": "Aide par le travail"
    },
    {
        "code": "8891A",
        "value": "Accueil de jeunes enfants"
    },
    {
        "code": "8891B",
        "value": "Accueil ou accompagnement sans hébergement d’enfants handicapés"
    },
    {
        "code": "8899A",
        "value": "Autre accueil ou accompagnement sans hébergement ?\u0019enfants et d’adolescents "
    },
    {
        "code": "8899B",
        "value": "Action sociale sans hébergement nca"
    },
    {
        "code": "9001Z",
        "value": "Arts du spectacle vivant"
    },
    {
        "code": "9002Z",
        "value": "Activités de soutien au spectacle vivant"
    },
    {
        "code": "9003A",
        "value": "Création artistique relevant des arts plastiques"
    },
    {
        "code": "9003B",
        "value": "Autre création artistique"
    },
    {
        "code": "9004Z",
        "value": "Gestion de salles de spectacles"
    },
    {
        "code": "9101Z",
        "value": "Gestion des bibliothèques et des archives"
    },
    {
        "code": "9102Z",
        "value": "Gestion des musées"
    },
    {
        "code": "9103Z",
        "value": "Gestion des sites et monuments historiques et des attractions touristiques similaires"
    },
    {
        "code": "9104Z",
        "value": "Gestion des jardins botaniques et zoologiques et des réserves naturelles"
    },
    {
        "code": "9200Z",
        "value": "Organisation de jeux de hasard et d'argent"
    },
    {
        "code": "9311Z",
        "value": "Gestion d'installations sportives"
    },
    {
        "code": "9312Z",
        "value": "Activités de clubs de sports"
    },
    {
        "code": "9313Z",
        "value": "Activités des centres de culture physique"
    },
    {
        "code": "9319Z",
        "value": "Autres activités liées au sport"
    },
    {
        "code": "9321Z",
        "value": "Activités des parcs d'attractions et parcs à thèmes"
    },
    {
        "code": "9329Z",
        "value": "Autres activités récréatives et de loisirs"
    },
    {
        "code": "9411Z",
        "value": "Activités des organisations patronales et consulaires"
    },
    {
        "code": "9412Z",
        "value": "Activités des organisations professionnelles"
    },
    {
        "code": "9420Z",
        "value": "Activités des syndicats de salariés"
    },
    {
        "code": "9491Z",
        "value": "Activités des organisations religieuses"
    },
    {
        "code": "9492Z",
        "value": "Activités des organisations politiques"
    },
    {
        "code": "9499Z",
        "value": "Autres organisations fonctionnant par adhésion volontaire"
    },
    {
        "code": "9511Z",
        "value": "Réparation d'ordinateurs et d'équipements périphériques"
    },
    {
        "code": "9512Z",
        "value": "Réparation d'équipements de communication"
    },
    {
        "code": "9521Z",
        "value": "Réparation de produits électroniques grand public"
    },
    {
        "code": "9522Z",
        "value": "Réparation d'appareils électroménagers et d'équipements pour la maison et le jardin"
    },
    {
        "code": "9523Z",
        "value": "Réparation de chaussures et d'articles en cuir"
    },
    {
        "code": "9524Z",
        "value": "Réparation de meubles et d'équipements du foyer"
    },
    {
        "code": "9525Z",
        "value": "Réparation d'articles d'horlogerie et de bijouterie"
    },
    {
        "code": "9529Z",
        "value": "Réparation d'autres biens personnels et domestiques"
    },
    {
        "code": "9601A",
        "value": "Blanchisserie-teinturerie de gros"
    },
    {
        "code": "9601B",
        "value": "Blanchisserie-teinturerie de détail"
    },
    {
        "code": "9602A",
        "value": "Coiffure"
    },
    {
        "code": "9602B",
        "value": "Soins de beauté"
    },
    {
        "code": "9603Z",
        "value": "Services funéraires"
    },
    {
        "code": "9604Z",
        "value": "Entretien corporel"
    },
    {
        "code": "9609Z",
        "value": "Autres services personnels nca"
    },
    {
        "code": "9700Z",
        "value": "Activités des ménages en tant qu'employeurs de personnel domestique"
    },
    {
        "code": "9810Z",
        "value": "Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre"
    },
    {
        "code": "9820Z",
        "value": "Activités indifférenciées des ménages en tant que producteurs de services pour usage propre"
    },
    {
        "code": "9900Z",
        "value": "Activités des organisations et organismes extraterritoriaux"
    }
];