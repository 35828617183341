import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import toast from '@app/partials/content/Toast';

export interface Props {
    label: React.ReactNode;
    route: () => Promise<AxiosResponse<ArrayBuffer>>;
}

const DownloadButton = ({ label, route }: Props) => {
    const [downloading, setDownloading] = React.useState(false);

    const Intl = useIntl();

    const download = () => {
        setDownloading(true);

        route()
            .then((response) => {
                const filename = response.headers['content-disposition'].replace(/^.*filename=(.*)$/, '$1');
                setDownloading(false);
                saveAs(new Blob([response.data]), filename);
            })
            .catch(() => {
                setDownloading(false);
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({
                        id: 'CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.ACTIVITIES.MODAL.DOWNLOADING_ERROR',
                    }),
                });
            });
    };

    return (
        <Button variant="outline-primary" onClick={() => download()} className="d-flex align-items-center">
            {downloading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />}
            {label}
        </Button>
    );
};

export default DownloadButton;
