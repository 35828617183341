import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getDashboardPendingCounters } from '@app/crud/dashboard/dashboard.crud';

import { DashboardContext } from '@app/pages/dashboard/DashboardProvider';
import ROUTES from '@app/router/Routes';

import useFetch from '../../../hooks/useFetch';

interface DashboardLeadProps {
    className?: string;
    displayApv?: boolean;
}
interface DashboardLeadSkeletonProps {
    displayApv?: boolean;
}

const DashboardLead = ({ className, displayApv }: DashboardLeadProps) => {
    const { overriddenParams } = useContext(DashboardContext);
    const { data, loading, fetch } = useFetch({
        fetchAction: getDashboardPendingCounters,
        resultInterceptor: (response) => response,
    });

    useEffect(() => {
        if (overriddenParams) {
            fetch({ params: overriddenParams }).then();
        }
    }, [overriddenParams]);

    const { result: pendingCounters, facets } = data;

    if (loading) {
        return <DashboardLead.Skeleton displayApv />;
    }

    return (
        <>
            <Link
                className={classNames('dashboard-lead', className)}
                to={{
                    pathname: displayApv
                        ? routeTo(ROUTES.APV_LEADS.PATH, {
                              statusType: 'new',
                          })
                        : routeTo(ROUTES.LEADS_ONLINE.PATH, {
                              statusType: 'new',
                          }),
                }}
            >
                <div className="font-size-lg font-weight-bold d-flex text-white align-items-center">
                    <i className="text-white las la-2x la-bullseye mr-2" />
                    {displayApv ? (
                        <FormattedMessage id="DASHBOARD.APV.TITLE_INLINE" />
                    ) : (
                        <FormattedMessage id="DASHBOARD.LEAD.TITLE_INLINE" />
                    )}
                </div>
                <div className="dashboard-lead__counters text-white d-flex align-items-center">
                    <div className="dashboard-lead__total font-weight-bold">
                        {displayApv ? pendingCounters?.apv : pendingCounters?.lead}
                    </div>
                    <div className="dashboard-lead__scroll">
                        {displayApv ? (
                            <p className="mt-9 font-size-h5 line-height-lg">
                                <FormattedHTMLMessage id="DASHBOARD.LEAD.APV.TITLE" />
                            </p>
                        ) : (
                            <div className="dashboard-lead__list font-size-lg">
                                {facets?.lead &&
                                    facets?.lead
                                        .filter((leadType) => leadType?.count)
                                        .map((leadType) => (
                                            <div key={leadType.slug}>
                                                {leadType?.name} : <strong>{leadType?.count}</strong>
                                            </div>
                                        ))}
                            </div>
                        )}
                    </div>
                </div>
            </Link>
            {!displayApv && pendingCounters?.note !== null && (
                <Link
                    className={classNames('dashboard-lead mt-2', className)}
                    to={{
                        pathname: routeTo(ROUTES.NOTES.PATH, {
                            status: 'toTake',
                        }),
                    }}
                >
                    <div className="font-size-lg font-weight-bold d-flex text-white align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <i className="text-white las la-2x la-pencil-alt mr-2" />
                            <FormattedMessage id="DASHBOARD.NOTE.TITLE_INLINE" />
                        </div>
                        <div className="font-size-xl font-weight-bold">{pendingCounters?.note}</div>
                    </div>
                </Link>
            )}
        </>
    );
};

DashboardLead.Skeleton = ({ displayApv = false }: DashboardLeadSkeletonProps) => (
    <Skeleton borderRadius="10px" className={`w-100 ${displayApv ? 'p-20' : 'p-30'} `} />
);

export default DashboardLead;
