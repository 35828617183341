import classNames from 'classnames';
import { Divider } from 'primereact/divider';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import Loader from '@app/partials/content/Loader';

import { OperationContext } from '../../OperationProvider';

import OperationReconciliationUserRow from './OperationReconciliationUserRow';

const OperationReconciliationToLink = ({ setExpandedPanel }) => {
    const { operationState, setOperationState } = useContext(OperationContext);
    const [selectedContact, setSelectedContact] = useState(null);
    const [contact, setContact] = useState(null);

    const { notCloseContact, contactToClose, contactToIgnore, contactToCreate, withoutContact } = operationState;

    const notClosedContactWithoutActions = () =>
        notCloseContact
            ? Object.keys(notCloseContact)?.filter(
                  (c) =>
                      !(contactToClose && c in contactToClose) &&
                      !(contactToIgnore && contactToIgnore.includes(c)) &&
                      !(contactToCreate && contactToCreate.includes(Number(c))),
              )
            : [];

    const getFirstNotCloseContact = () => {
        if (notClosedContactWithoutActions().length < 1) {
            setExpandedPanel(null);
        }
        return notClosedContactWithoutActions()[0] ?? null;
    };

    useEffect(() => {
        setContact(notCloseContact && getFirstNotCloseContact() ? notCloseContact[getFirstNotCloseContact()] : null);
    }, [operationState, setOperationState, notCloseContact, contactToClose, contactToIgnore, contactToCreate]);

    const handleReconcile = () => {
        setOperationState({
            ...operationState,
            oldContact: operationState.oldContact + 1,
            contactToClose: {
                ...(contactToClose || {}),
                [contact?.campaignContactId]: selectedContact,
            },
        });
        setSelectedContact(null);
    };

    const handleCreate = () => {
        setOperationState({
            ...operationState,
            newContact: operationState.newContact + 1,
            contactToCreate: [...(contactToCreate || []), contact?.campaignContactId],
        });
    };

    const handleIgnoreAll = () => {
        setOperationState({
            ...operationState,
            contactToIgnore: [...(contactToIgnore || []), ...notClosedContactWithoutActions()],
        });
    };

    return (
        <Row className="align-items-start py-5">
            <Col className="col-4 campaign-user-infos pr-6">
                <div className="mb-1 font-weight-bold font-size-xs">
                    <FormattedMessage
                        id="CAMPAIGN.MODALS.SETTINGS.RECONCILIATION.SOURCE"
                        values={{
                            source: 'Salesforce',
                        }}
                    />
                </div>
                <div className="mb-2 text-danger font-weight-bold font-size-lg">
                    <FormattedMessage
                        id="CAMPAIGN.MODALS.SETTINGS.RECONCILIATION.CONTACT"
                        values={{
                            from: withoutContact - notClosedContactWithoutActions().length + 1,
                            to: withoutContact,
                        }}
                    />
                </div>
                <div className="already-exists__reconciled mb-3">
                    <div className="font-weight-bold text-center">
                        {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CREATE_ALREADY_EXIST.RECONCILED' })}
                    </div>
                    <Divider className="my-2 mb-4 already-exists__divider" />
                    {contact ? (
                        <OperationReconciliationUserRow source={contact} />
                    ) : (
                        <Loader style={{ width: '5rem', height: '5rem' }} overlay />
                    )}
                </div>
                <Button
                    variant="primary"
                    className="px-1 mb-3 w-100"
                    disabled={!selectedContact}
                    onClick={handleReconcile}
                >
                    <FormattedMessage id="CAMPAIGN.MODALS.SETTINGS.RECONCILIATION.RECONCILE" />
                </Button>
                <Button
                    variant="outline-primary"
                    className="px-1 mb-3 w-100"
                    disabled={selectedContact}
                    onClick={handleCreate}
                >
                    <FormattedMessage id="CAMPAIGN.MODALS.SETTINGS.RECONCILIATION.CREATE" />
                </Button>
                <Button variant="outline-danger" className="px-1 w-100" onClick={handleIgnoreAll}>
                    <FormattedMessage id="CAMPAIGN.MODALS.SETTINGS.RECONCILIATION.IGNORE_ALL" />
                </Button>
            </Col>
            <Col className="col-8 offset-4 d-flex flex-column">
                <div className="mb-1 font-weight-bold font-size-xs">
                    <FormattedMessage
                        id="CAMPAIGN.MODALS.SETTINGS.RECONCILIATION.SOURCE"
                        values={{
                            source: 'AutoManager',
                        }}
                    />
                </div>
                <div className="pr-5">
                    {contact?.contactMatches?.length > 0 ? (
                        contact?.contactMatches.map((user, idx) => (
                            <Form.Check
                                type="radio"
                                className={classNames('campaign-user__row', {
                                    'mb-4': idx + 1 < notCloseContact.length,
                                    'campaign-user__row--active': selectedContact === user,
                                })}
                                name="vcuId"
                                label={
                                    <div className="ml-4 text-left">
                                        <OperationReconciliationUserRow source={user} />
                                    </div>
                                }
                                onClick={() => {
                                    setSelectedContact(selectedContact !== user ? user : null);
                                }}
                                checked={selectedContact === user}
                                id={`contact_${idx}`}
                                // eslint-disable-next-line react/no-array-index-key
                                key={`contact_${idx}`}
                                value={`contact_${idx}`}
                            />
                        ))
                    ) : (
                        <Loader style={{ 'margin-top': '10rem', width: '5rem', height: '5rem' }} overlay />
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default OperationReconciliationToLink;
