import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { CustomAxiosResponse } from '@app/helpers/AxiosHelper';
import { removeEmptyFields } from '@app/helpers/ObjectHelper';

import { Versions } from '@app/crud/customers/stock.type';

import BrandModelVersion from '@app/partials/content/BrandModelVersion';
import ModalDefault from '@app/partials/content/modals/Modal.default';

interface VehicleOutStockProps {
    showModal: boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onSelect: Function;
    setShowModal: Dispatch<SetStateAction<boolean>>;
}
const VehicleOutStock = ({ showModal, onSelect, setShowModal }: VehicleOutStockProps) => {
    const Intl = useIntl();
    const methods = useForm();
    const [loading, setLoading] = useState(false);
    const [versions, setVersions] = useState<CustomAxiosResponse<Versions>>();
    const { register, reset, handleSubmit } = methods;

    const onSubmit = (data: {
        fuelType: string;
        gearsType: string;
        make: string;
        model: string;
        modelOther?: string;
    }) => {
        if (data.model === Intl.formatMessage({ id: 'CUSTOMERS.MODAL.PART_EXCHANGE.NONE' }) && data.modelOther) {
            data.model = data.modelOther;
            delete data.modelOther;
        }
        onSelect(removeEmptyFields(data));
        setShowModal(false);
    };

    const onVersionsChange = (e: CustomAxiosResponse<Versions>) => {
        setVersions(e);
    };

    useEffect(
        () => () => {
            reset({});
        },
        [reset],
    );

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-car" />}
            title={<FormattedMessage id="CUSTOMERS.VEHICLE.MODEL.TITLE" />}
            body={
                <FormProvider {...methods}>
                    <BrandModelVersion
                        onLoading={(value: boolean) => setLoading(value)}
                        onVersionsUpdated={onVersionsChange}
                        brandLabel={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE.BRAND' })}
                        modelLabel={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE.MODEL' })}
                        noVersion
                        defaultsValues={{ make: '', model: '', version: '', dateFirstRegistration: '' }}
                    />
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMERS.MODAL.VEHICLE.FUEL_TYPE" /> :
                        </Form.Label>
                        <Form.Control as="select" name="fuelType" defaultValue="" disabled={!versions} ref={register}>
                            <option value="">
                                {Intl.formatMessage({ id: 'CUSTOMERS.VEHICLE.MODEL.FUEL_TYPE.PLACEHOLDER' })}
                            </option>
                            {versions?.filters?.fuel &&
                                Object.values(versions.filters.fuel).map((fuelType) => (
                                    <option key={fuelType.Name}>{fuelType.Name}</option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMERS.MODAL.VEHICLE.GEARBOX" /> :
                        </Form.Label>
                        <Form.Control as="select" name="gearsType" defaultValue="" disabled={!versions} ref={register}>
                            <option value="">
                                {Intl.formatMessage({ id: 'CUSTOMERS.VEHICLE.MODEL.GEARBOX.PLACEHOLDER' })}
                            </option>
                            {versions?.filters?.gearbox &&
                                Object.values(versions.filters.gearbox).map((gearbox) => (
                                    <option key={gearbox.Name}>{gearbox.Name}</option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                </FormProvider>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" className="mr-5" onClick={() => setShowModal(false)}>
                        <FormattedMessage id="TRANSLATOR.CANCEL" />
                    </Button>
                    <Button variant="primary" disabled={!versions} onClick={handleSubmit(onSubmit)}>
                        <FormattedMessage id="TRANSLATOR.VALIDATE" />
                    </Button>
                </div>
            }
        />
    );
};

export default VehicleOutStock;
