import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { updateVehiclePrice } from '@app/crud/stock/stock.crud';

import { useAppDispatch, useModal } from '@app/hooks';
import { actions } from '@app/store/stock/stock.store';

import toast from '@app/partials/content/Toast';

import { ConfirmModal } from '../../modals';

type StockPriceChangeProps = {
    sku: string;
};

const StockPriceChange = ({ sku }: StockPriceChangeProps) => {
    const Intl = useIntl();
    const methods = useForm();
    const { control, setValue } = methods;
    const [newPrice, setNewPrice] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useModal(false, 'confirm_price');
    const dispatch = useAppDispatch();

    const confirmPriceChange = () => {
        updateVehiclePrice({ [sku]: newPrice })
            .then(() => {
                setConfirmModalOpen(false);
                dispatch(actions.updateVehiclePrice(newPrice));
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'STOCK_VEHICLES.MODAL.PRICE.CONFIRM.REASON.CONFIRMED' }),
                });
            })
            .catch(() => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'STOCK_VEHICLES.MODAL.PRICE.CONFIRM.REASON.FAILED' }),
                });
            });
    };

    return (
        <>
            <div className="text-center">
                <hr className="mt-0" style={{ borderTop: '1px dashed #E8E8E9' }} />
                <div className="d-flex justify-content-between align-items-center">
                    <p className="text-center font-weight-bold mb-0" style={{ fontSize: '15px', color: '#525672' }}>
                        <FormattedMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.PRICE" />
                    </p>
                    <Controller
                        render={() => (
                            <NumberFormat
                                customInput={InputText}
                                thousandSeparator=" "
                                suffix=" €"
                                onValueChange={(e) => {
                                    setValue('price', e.value);
                                    setNewPrice(parseInt(e.value, 10));
                                }}
                                className="text-center"
                                style={{ fontSize: '16px', fontWeight: 700, borderColor: '#E6E6E6', color: '#525672' }}
                            />
                        )}
                        name="price"
                        control={control}
                    />
                </div>
                <hr style={{ borderTop: '1px dashed #E8E8E9' }} />
                <Button
                    onClick={() => {
                        setConfirmModalOpen(true);
                    }}
                    className="d-block px-15 ml-auto"
                    variant="primary"
                    size="lg"
                    style={{ fontSize: '14px', border: 0 }}
                >
                    <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.PRICE_CHANGE" />
                </Button>
            </div>
            <ConfirmModal
                showModal={confirmModalOpen}
                setShowModal={setConfirmModalOpen}
                modified={false}
                warning={false}
                loading={false}
                onConfirm={confirmPriceChange}
            />
        </>
    );
};

export default StockPriceChange;
