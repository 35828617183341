export const actionTypes = {
    FetchOrders: 'APVINSTORE_FETCH_ORDERS',
    FetchOrder: 'APVINSTORE_FETCH_ORDER',
    FetchedOrders: 'APVINSTORE_FETCHED_ORDERS',
    FetchedOrder: 'APVINSTORE_FETCHED_ORDER',
    FetchOrdersFailed: 'APVINSTORE_FETCH_ORDER_FAILED',
    FetchOrderFailed: 'APVINSTORE_FETCH_ORDER_FAILED',
    SetOrdersTotalRecords: 'APVINSTORE_SET_ORDERS_TOTALRECORDS',
};

const initialState = {
    loading: false,
    orders: [],
    totalRecords: 0,
    filtersContent: [],
    order: null,
    error: null,
};

export function apvInStoreReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FetchOrders:
        case actionTypes.FetchOrder: {
            return {
                ...state,
                loading: true,
                order: null,
                error: null,
            };
        }
        case actionTypes.FetchedOrders: {
            return {
                ...state,
                loading: false,
                orders: action.payload.orders,
                totalRecords: action.payload.count,
                filtersContent: action.payload.filters,
            };
        }
        case actionTypes.FetchedOrder: {
            return {
                ...state,
                loading: false,
                order: action.payload,
            };
        }
        case actionTypes.FetchOrderFailed:
        case actionTypes.FetchOrdersFailed: {
            return {
                ...state,
                loading: false,
                orders: [],
                error: action.payload.error,
            };
        }
        case actionTypes.SetOrdersTotalRecords: {
            return {
                ...state,
                totalRecords: action.payload,
            };
        }
        default:
            return state;
    }
}

export const actions = {
    fetchOrders: () => ({ type: actionTypes.FetchOrders }),
    fetchedOrders: (orders) => ({ type: actionTypes.FetchedOrders, payload: orders }),
    fetchOrder: () => ({ type: actionTypes.FetchOrder }),
    fetchedOrder: (order) => ({ type: actionTypes.FetchedOrder, payload: order }),
    fetchOrderFailed: (error) => ({ type: actionTypes.FetchOrderFailed, payload: error }),
    fetchOrdersFailed: (error) => ({ type: actionTypes.FetchOrdersFailed, payload: error }),
    setOrdersTotalRecords: (totalRecords) => ({ type: actionTypes.SetOrdersTotalRecords, payload: totalRecords }),
};
