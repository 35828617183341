import React from 'react';
import { Card } from 'react-bootstrap';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import Permission from '@app/partials/content/Permission';
import RowInfo from '@app/partials/content/RowInfo';

const StockVehicleDealership = ({ vehicle }) => (
    <Card>
        <Card.Header>
            <i className="card__icon la-lg las la-building text-dark" />
            {Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.TITLE' })}
        </Card.Header>
        <Card.Body>
            <RowInfo
                label={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.CONTENT.NUM_VO' })}
                value={vehicle?.sku || '-'}
                colLabel={6}
                colValue={6}
            />
            <Permission permissions={['STOCK_VIEW_INTERNAL_DATA']}>
                <RowInfo
                    label={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.CONTENT.DEALERSHIP_CODE' })}
                    value={vehicle?.dealer_id || '-'}
                    colLabel={6}
                    colValue={6}
                />
            </Permission>
            <RowInfo
                label={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.CONTENT.DEALERSHIP' })}
                value={vehicle?.dealer_usual_name || '-'}
                colLabel={6}
                colValue={6}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.CONTENT.POLE' })}
                value={vehicle?.dealer_pole_usual_name || '-'}
                colLabel={6}
                colValue={6}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.CONTENT.HOLDING' })}
                value={vehicle?.dealer_holding_usual_name || '-'}
                colLabel={6}
                colValue={6}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.CONTENT.SOURCE' })}
                value={vehicle?.product || '-'}
                colLabel={6}
                colValue={6}
            />
            <RowInfo
                label={Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.DEALERSHIP.CONTENT.PVO_CODE' })}
                value={vehicle?.code_pvo || '-'}
                colLabel={6}
                colValue={6}
            />
        </Card.Body>
    </Card>
);

export default StockVehicleDealership;
