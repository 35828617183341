import { Chip } from 'primereact/chip';
import React, { useEffect, useState } from 'react';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

type StockVehiclesFilterChipsBlockProps = {
    resetFilter: Function;
    resetFilters: Function;
    updateFilters: Function;
    updatedFilters: Function;
    setIsDisabledResetButton: (value: boolean) => void;
};

const StockVehiclesFilterChipsBlock = ({
    resetFilter,
    resetFilters,
    updateFilters,
    updatedFilters,
    setIsDisabledResetButton,
}: StockVehiclesFilterChipsBlockProps) => {
    const [data, setData] = useState([]);

    const resetHandler = (name: string, filter: any = null, valueDeleted: string = null) => {
        switch (name) {
            case 'is_360':
                resetFilters([name, 'is_degraded_360']);
                break;
            case 'internal_type':
            case 'model':
            case 'body_color':
            case 'brand': {
                const lastValue = filter.value.split(',');
                lastValue.splice(lastValue.indexOf(valueDeleted), 1);
                updateFilters({
                    filters: {
                        [name]: {
                            value: lastValue.join(','),
                            componentValue: lastValue,
                        },
                    },
                });
                break;
            }
            default:
                resetFilter(name);
        }
    };

    const chipHandler = (name: string, filter: any) => {
        let currentValue;
        let chips;
        switch (name) {
            // vo,vd,vn
            case 'internal_type':
            case 'model':
            case 'body_color':
            case 'brand':
                currentValue = filter.value.split(',');
                chips = (
                    <div key={name}>
                        {currentValue.map((value: string) => (
                            <Chip
                                key={value}
                                label={value}
                                className="ml-4"
                                removable
                                onRemove={() => resetHandler(name, filter, value)}
                                removeIcon="la la-times"
                            />
                        ))}
                    </div>
                );
                break;
            case 'is_degraded_360':
                if (filter.value) {
                    chips = (
                        <Chip
                            key={name}
                            label={filter?.labelFirst || filter?.labelSecond}
                            className="ml-4"
                            removable
                            onRemove={() => resetHandler(name)}
                            removeIcon="la la-times"
                        />
                    );
                }
                break;
            case 'is_360':
            case 'is_photos_classic':
            case 'is_damages':
            case 'is_waiting_images':
                chips = (
                    <Chip
                        key={name}
                        label={filter?.labelFirst || filter?.labelSecond}
                        className="ml-4"
                        removable
                        onRemove={() => resetHandler(name)}
                        removeIcon="la la-times"
                    />
                );
                break;
            case 'is_eligible_afr':
            case 'is_afr':
            case 'publishable_in_indices':
            case 'is_eligible_fw':
            case 'is_eligible_cs':
            case 'is_eligible_fluidite':
            case 'is_eligible_credit':
            case 'is_eligible_loa':
            case 'is_eligible_classic':
            case 'is_eligible_balloon':
            case 'is_crvo':
            case 'has_pricing_reco':
            case 'has_photos': {
                let filterValue = filter.value;
                if (name === 'publishable_in_indices') {
                    filterValue = filterValue.split(':')[1] === 'true';
                }
                chips = (
                    <Chip
                        key={name}
                        label={`${filter?.filterName} - ${filterValue ? 'Oui' : 'Non'}`}
                        className="ml-4"
                        removable
                        onRemove={() => resetHandler(name)}
                        removeIcon="la la-times"
                    />
                );
                break;
            }
            case 'mileage':
            case 'age':
            case 'price':
                currentValue = filter.value.split(',');
                chips = (
                    <Chip
                        key={name}
                        className="ml-4"
                        removable
                        onRemove={() => resetHandler(name)}
                        label={Intl.formatMessage(
                            { id: 'STOCK_VEHICLES.FILTERS.CHIPS.SLIDERS' },
                            {
                                start: currentValue[0],
                                end: currentValue[1],
                                suffix: filter?.suffix || '',
                            },
                        )}
                        removeIcon="la la-times"
                    />
                );
                break;
            case 'nb_photos':
                chips = (
                    <Chip
                        key={name}
                        label={filter?.key}
                        className="ml-4"
                        removable
                        onRemove={() => resetHandler(name)}
                        removeIcon="la la-times"
                    />
                );
                break;
            case 'in_stock': {
                let values = filter?.value;

                if (values === '0,500') {
                    // default value => remove filter
                    resetHandler(name);
                } else {
                    values = values.split(',');
                    let label = Intl.formatMessage(
                        { id: 'STOCK_VEHICLES.FILTERS.BUTTON.DAYS_IN_STOCK' },
                        {
                            start: values[0],
                            end: values[1],
                        },
                    );

                    if (values[0] === '0') {
                        label = Intl.formatMessage(
                            { id: 'STOCK_VEHICLES.FILTERS.BUTTON.MORE_LESS_DAYS_IN_STOCK' },
                            {
                                operator: '-',
                                nbDays: Number(values[1]) + 1,
                            },
                        );
                    } else if (values[1] === '500') {
                        label = Intl.formatMessage(
                            { id: 'STOCK_VEHICLES.FILTERS.BUTTON.MORE_LESS_DAYS_IN_STOCK' },
                            {
                                operator: '+',
                                nbDays: Number(values[0]) - 1,
                            },
                        );
                    }
                    chips = (
                        <Chip
                            key={name}
                            label={label}
                            className="ml-4"
                            removable
                            onRemove={() => resetHandler(name)}
                            removeIcon="la la-times"
                        />
                    );
                }

                break;
            }
            default:
                chips = (
                    <Chip
                        key={name}
                        label={filter?.value}
                        className="ml-4"
                        removable
                        onRemove={() => resetHandler(name)}
                        removeIcon="la la-times"
                    />
                );
                break;
        }

        return chips;
    };

    useEffect(() => {
        const currentFilters = Object.entries(updatedFilters).filter(
            ([name, filter]) => filter.value !== '' && !['group_internal_id', 'dealer_id'].includes(name),
        );
        setData(currentFilters);
        if (currentFilters.length > 0) {
            setIsDisabledResetButton(false);
        } else {
            setIsDisabledResetButton(true);
        }
    }, [updatedFilters]);

    return <div className="stock-chip d-flex">{data.map(([name, filter]) => chipHandler(name, filter))}</div>;
};

export default StockVehiclesFilterChipsBlock;
