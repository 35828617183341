import ApiGateway, { ApiResponse, CustomAxiosResponse } from '../../helpers/AxiosHelper';

const { REACT_APP_APV_URL } = process.env;

export function getDealerships(params: any) {
    return ApiGateway.get(`${REACT_APP_APV_URL}/dealership`, {
        params,
    });
}

export function getDealership(sourceId: string) {
    return ApiGateway.get(`${REACT_APP_APV_URL}/dealership/${sourceId}`);
}

export function updateDealershipById(id: string, data: any) {
    return ApiGateway.put(`${REACT_APP_APV_URL}/dealership/${id}`, data);
}

export function getDealershipsByDepartment(department: string) {
    return ApiGateway.post(`${REACT_APP_APV_URL}/dealership/department/${department}`);
}

export function getSlotsByDealership(dealershipId: string, date: string, duration: number) {
    return ApiGateway.post<ApiResponse<TimeSlotResponse>, CustomAxiosResponse<ApiResponse<TimeSlotResponse>>>(
        `${REACT_APP_APV_URL}/dealership/timeslot/${dealershipId}`,
        {
            force: true,
            duration,
            date,
        },
    );
}

export interface TimeSlotResponse {
    dateRequest: string;
    dateRequestDay: string;
    dateNext: string;
    months: Record<string, string>;
    days: Record<
        string,
        {
            day: string;
            label: string;
            open: boolean;
        }
    >;
    slots: {
        hour: string;
        label: string;
        booked: boolean;
    }[];
}
