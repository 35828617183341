/***
 * Create Gallery Fullscreen from Primereact
 */
import React from 'react';
import { Galleria } from 'primereact/galleria';
import { Image } from 'react-bootstrap';
import { Intl } from '../../../_metronic/i18n/I18nProvider';

/**
 * 
 * Create Gallery with fullscreen option
 * 
 *  param {object} galleries
 * @returns object
 * @example : [{
    "orderRepair": 1185,
    "type": "damage",
    "url": "/images/ODIyIzExODUjZGFtYWdl/damage",
    ...
 *  }]
 *
 *  param {array} data
 * @returns object
 *  
 *  param {()} templateFullscreen
 * @returns html
 * 
 * Todo: add loader on Image components to add loader on all app  
 * 
 */

function GalleryFullscreen({ galleries, id, data, templateFullscreen }) {
    const { REACT_APP_SMART_REPAIR_URL } = process.env;

    const [activeIndex, setActiveIndex] = React.useState(0);
    const gallerieItem = React.useRef({});

    return (
        <>
            <Galleria
                ref={el => (gallerieItem.current[id] = el)}
                value={galleries(data)}
                numVisible={7}
                style={{ maxWidth: "550px" }}
                activeIndex={activeIndex}
                onItemChange={(e) => setActiveIndex(e.index)}
                circular
                fullScreen
                showItemNavigators
                showThumbnails={false}
                item={templateFullscreen}
            />
            {galleries(data) &&
                galleries(data).map((image, index) => {
                    return (
                        <figure key={index}>
                            <Image
                                src={`${REACT_APP_SMART_REPAIR_URL}${image.url}`}
                                onError={(e) =>
                                (e.target.src =
                                    "/media/illustrations/error-photo.svg")
                                }
                                alt={Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.ALT.FULLSCREEN.IMG' })}
                                className="smart-repair-fullscreen__img"
                                onClick={() => {
                                    setActiveIndex(index);
                                    gallerieItem.current[id].show()
                                }}
                            />
                        </figure>
                    )
                })
            }
        </>
    )
}

export default GalleryFullscreen;