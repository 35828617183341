import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { EventTimeline } from '@app/crud/manufacturer/order.type';

import { getEventStateBySlug } from '@app/pages/apv/orders/Helpers/EventStatus';

import ModalDefault from '@app/partials/content/modals/Modal.default';

interface VertictTimeLineProps {
    order: { eventsForTimeline: EventTimeline[] };
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
}

export default function VerticalTimeLine({ order, showModal, setShowModal }: VertictTimeLineProps) {
    const Intl = useIntl();
    const { eventsForTimeline: events } = order;

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <ModalDefault
            show={showModal}
            size="xl"
            hideModal={handleClose}
            icon={<i className="las la-wrench la-2x text-primary" />}
            title={Intl.formatMessage({ id: 'APV.ORDER.MODAL.DETAILS' })}
            body={
                <div className="timeline w-100">
                    {events &&
                        events.map((event: EventTimeline, key: number) => (
                            <div key={key} className="timeline-item align-items-start">
                                <div className="timeline-label text-right font-weight-bold text-dark-75 font-size-lg">
                                    {dayjs(event?.created).format('DD/MM/YY [•] HH:mm')} -
                                </div>
                                <div className="timeline-badge">
                                    <i
                                        className={`fa fa-genderless text-${getEventStateBySlug({
                                            ...event,
                                            eventType: event.orderEventType,
                                        })} icon-xl`}
                                    />
                                </div>
                                <div className="d-flex">
                                    {event?.name && (
                                        <div className="font-weight-normal font-size-lg timeline-content pl-3">
                                            {event.name}
                                        </div>
                                    )}
                                    {event?.user && (
                                        <div className="font-weight-normal font-size-lg timeline-content">
                                            &nbsp;|&nbsp;{event.user.lastName} {event.user.firstName}
                                        </div>
                                    )}
                                    {event?.orderEventSource?.name && (
                                        <div className="font-weight-normal font-size-lg timeline-content">
                                            &nbsp;|&nbsp;{event.orderEventSource.name}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                </div>
            }
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button variant="primary" onClick={handleClose}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                </div>
            }
        />
    );
}
