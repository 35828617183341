import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useAction from '@app/pages/customers/Hooks/useAction';
import { RootState } from '@app/store/rootDuck';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

type APVRequestProps = {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
};

const APVRequest = ({ showModal, setShowModal }: APVRequestProps) => {
    const Intl = useIntl();
    const { params } = useSelector((state: RootState) => state.modal);
    const [loading, createAction] = useAction({
        type: 'close_lead_done',
        success: () => {
            setShowModal(false);
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CONTACT.ERROR' }),
            });
        },
    });

    const closeAPVRequest = () => {
        // @ts-ignore
        createAction({
            lead: {
                id: params.leadId,
            },
        });
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-pen" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CLOSE_LOCAL_APV.TITLE' })}
            body={
                <p className="text-center">
                    <FormattedMessage id="CUSTOMERS.MODAL.CLOSE_LOCAL_APV.TEXT" />
                </p>
            }
            footer={
                <div className="d-flex justify-content-center">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            closeAPVRequest();
                        }}
                    >
                        {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CLOSE_LOCAL_APV.CLOSE.TEXT' })}
                    </Button>
                </div>
            }
        />
    );
};

export default APVRequest;
