import classNames from 'classnames';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import SwitchInput from '@app/partials/content/SwitchInput';

const LeadWonWebAd = ({ params, type }) => {
    const Intl = useIntl();
    const { watch } = useFormContext();
    const { vehicleInternalId } = watch(['vehicleInternalId']);

    if (type !== 'VO' || !vehicleInternalId) return null;

    return (
        <Form.Group>
            <SwitchInput
                name="disableVehicleWebAd"
                className={classNames([
                    'switch-input--active-label-right-success',
                    'switch-input--active-toggle-success',
                    'switch-input--inactive-toggle-light-light',
                ])}
                disabled={!!params?.event}
                labelFirst=""
                active
                labelSecond={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_WON.WEBAD' })}
            />
        </Form.Group>
    );
};

export default LeadWonWebAd;
