import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import { Button, Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFadeOut from '@app/hooks/useFadeOut';

import { routeTo } from '@app/helpers/RoutesHelper';

import getAppointmentSource from '@app/pages/agenda/helpers/AgendaHelper';
import { apiToCalendarObject } from '@app/pages/agenda/partials/Event.Handler';
import ROUTES from '@app/router/Routes';

import { TODAY } from '../../helpers/DashboardHelper';

const DashboardAgendaTimeline = ({ appointments, activeTab, setEvent, picture }) => {
    const { onBottom, handleScroll, fadeOutRef } = useFadeOut();
    const calendar = useRef();

    const handleClick = (e, appointmentIdx) => {
        e.preventDefault();

        if (calendar?.current) {
            const calendarApi = calendar.current.getApi();
            const events = calendarApi.getEvents();
            setEvent(events[appointmentIdx]);
        }
    };

    const timelines = appointments
        ?.filter?.((item) => !item?.isPrivate)
        ?.map?.((item, idx) => {
            const src = getAppointmentSource(item);

            return (
                <div key={item.id} className="timeline-item align-items-start">
                    <div className="timeline-label font-size-lg">
                        {item?.dateStart ? dayjs(item.dateStart).format('HH:mm') : ''}
                    </div>
                    <div className="timeline-badge">
                        <Image src={src} widht="15px" height="19px" />
                    </div>
                    <div className="d-flex flex-column font-weight-normal timeline-content">
                        <span className="font-weight-semibold">
                            {item?.isOutlookSpecific ? (
                                item.organizer
                            ) : (
                                <>
                                    {item?.contact?.firstname || ''} {item?.contact?.lastname || ''}
                                </>
                            )}
                        </span>
                        <span className="font-size-sm">{item?.subject}</span>
                        <div
                            className="text-primary cursor-pointer"
                            aria-hidden="true"
                            onClick={(e) => handleClick(e, idx)}
                        >
                            <FormattedMessage id="DASHBOARD.CALENDAR.TIMELINE.VIEW_FULL_EMAIL" />
                        </div>
                        {item?.contact?.id && (
                            <Link
                                to={routeTo(ROUTES.CUSTOMER.PATH, {
                                    id: item?.contact?.id,
                                })}
                            >
                                <Button className="mt-1" variant="primary">
                                    <FormattedMessage id="DASHBOARD.CALENDAR.TIMELINE.VIEW_CLIENT_PAGE" />
                                </Button>
                            </Link>
                        )}
                    </div>
                </div>
            );
        });

    return appointments?.length > 0 ? (
        <>
            <div className="timeline" ref={fadeOutRef} onScroll={handleScroll}>
                {timelines}
            </div>
            <div hidden>
                <FullCalendar
                    ref={calendar}
                    events={appointments ? appointments.map((appointment) => apiToCalendarObject(appointment)) : []}
                    plugins={[timeGridPlugin]}
                    initialView="timeGridWeek"
                />
            </div>
            {!onBottom && <div className="timeline__fadeout" />}
        </>
    ) : (
        <div className="h-100 d-flex flex-column align-items-center justify-content-center">
            <span className="text-center text-dark-50 font-weight-semibold font-size-lg">
                Vous n’avez aucun rendez-vous
                <br />
                de prévu {` `}
                {parseInt(activeTab, 10) === TODAY
                    ? Intl.formatMessage({ id: 'DASHBOARD.CALENDAR.TODAY' }).toLowerCase()
                    : Intl.formatMessage({ id: 'DASHBOARD.CALENDAR.TOMORROW' }).toLowerCase()}
                …
            </span>
            {picture && <Image className="mt-8" src="/media/pages/dashboard/dashboard-aucun-rdv.svg" />}
        </div>
    );
};

DashboardAgendaTimeline.defaultProps = {
    picture: true,
};

export default DashboardAgendaTimeline;
