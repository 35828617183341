import classNames from 'classnames';
import React from 'react';

import Label from '@app/partials/content/Label';

import { getStatusColor } from '../helpers/CampaignHelper';

const CampaignStatusRow = ({ campaignStatusSlug, campaignStatusName, campaignStatusSubName, className, shift }) => {
    const status = getStatusColor(campaignStatusSlug);

    return (
        <span
            className={classNames('flex-wrap font-size-sm', className, {
                [`text-${status}`]: status,
            })}
        >
            <Label
                className={classNames('font-weight-bold label-lg', {
                    [shift]: shift,
                    [`label-light-${status}`]: status,
                    'text-nowrap': campaignStatusSlug,
                })}
            >
                {campaignStatusName || ''}
            </Label>
            {campaignStatusSubName && (
                <span className="ml-2 font-weight-bold">&bull;&nbsp;{campaignStatusSubName}</span>
            )}
        </span>
    );
};

CampaignStatusRow.defaultProps = {
    className: '',
    shift: '',
};

export default CampaignStatusRow;
