import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import Label from '@app/partials/content/Label';

interface RefreshTableProps {
    count: number;
    onClick: () => void;
    variant?: string;
}

const RefreshTable = ({ count, onClick, variant }: RefreshTableProps) => (
    <Col lg={3}>
        <Label variant={variant} size="label-full-height" fontSize="font-size-lg" className="mr-5">
            {count || 0}
        </Label>
        <Button variant="outline-primary" onClick={onClick}>
            <i className="mr-3 fas fa-sync-alt" />
            <FormattedMessage id="TABLE.REFRESH" />
        </Button>
    </Col>
);

RefreshTable.defaultProps = {
    variant: 'secondary',
};

export default RefreshTable;
