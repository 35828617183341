import { AnyAction } from 'redux';

import { clearToken, restoreToken } from '@app/helpers/User';

export const actionTypes = {
    FetchCurrentUser: 'FETCH_CURRENT_USER',
    FetchedCurrentUser: 'FETCHED_CURRENT_USER',
    LogUser: 'LOG_USER',
    LoggedUser: 'LOGGED_USER',
    LogoutUser: 'LOGOUT_USER',
    RefreshUser: 'LOG_USER',
    RefreshedUser: 'LOGGED_USER',
    StopImpersonateUser: 'STOP_IMPERSONATION',
};

export interface Dealership {
    title: string;
    codePvo: string;
    brand: string;
    address: string;
    zipCode: string;
    city: string;
    isActiveRpa?: boolean;
    refDealershipId?: string;
}
export interface DealershipPerimeter {
    code: string;
    name: string;
    type: string;
}
export interface Tag {
    zone_action: string[];
    typologie_lead: string[];
}
export interface Job {
    id?: number;
    name: string;
    slug: string;
}
export interface Role {
    name: string;
    description: string;
    slug: string;
    shortName?: string | null;
}
export interface User {
    context?: string;
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    cell_phone: string;
    role: Role;
    job: Job;
    permissions: { [key: string]: string[] };
    tags: Tag;
    dealership_perimeter: DealershipPerimeter[];
    external?: boolean;
    default_dealership?: string;
    dealerships?: { [key: string]: Dealership };
    perimeter_codes?: [];
    securyweb_id?: string;
    securyweb_email?: string;
}

export interface LoginState {
    loading: boolean;
    user: User;
    userData: User;
    accessToken: string;
    error: null;
}

const initialState: LoginState = {
    loading: false,
    user: null,
    userData: null,
    accessToken: null,
    error: null,
};

export function loginReducer(state = initialState, action: AnyAction): LoginState {
    switch (action.type) {
        case actionTypes.LogoutUser:
            clearToken();
            return initialState;
        case actionTypes.LogUser:
            return {
                ...state,
                loading: true,
                userData: null,
                accessToken: null,
                error: null,
            };
        case actionTypes.LoggedUser:
            return {
                ...state,
                loading: false,
                accessToken: action.payload.token.token,
            };
        case actionTypes.FetchedCurrentUser: {
            const { userData } = action.payload;
            return {
                ...state,
                userData,
                user: userData,
            };
        }
        case actionTypes.StopImpersonateUser:
            restoreToken();
            return state;
        default:
            return state;
    }
}

export const actions = {
    fetchCurrentUser: () => ({ type: actionTypes.FetchCurrentUser }),
    fetchedCurrentUser: (userData: User) => ({ type: actionTypes.FetchedCurrentUser, payload: { userData } }),
    loggedUser: (token: { token: string }) => ({ type: actionTypes.LoggedUser, payload: token }),
    logoutUser: () => ({ type: actionTypes.LogoutUser }),
    logUser: (username: string, password: string, context: string) => ({
        type: actionTypes.LogUser,
        payload: { username, password, context },
    }),
    stopImpersonateUser: () => ({ type: actionTypes.StopImpersonateUser }),
};
