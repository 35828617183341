import React from "react";
import {SelectButton} from 'primereact/selectbutton';
import {Controller, useFormContext} from "react-hook-form";

const ButtonSelect = (
  {
    options,
    value,
    name,
    form,
    className
  }) => {
  let methods = useFormContext();
  const {setValue, control} = methods;

  return (
    <div className={`${className}`}>
      {form ? (
        <Controller
          control={control}
          name={name}
          defaultValue={value}
          render={(props) => (
            <SelectButton
              value={props.value}
              options={options}
              onChange={(e) => {
                props.onChange(e.value);
              }}
            />
          )}/>
      ) : (
        <SelectButton
          name={name}
          defaultValue={value}
          value={value}
          options={options}
          onChange={(e) => {
            value = e.value;
            setValue(e.value);
          }}
        />
      )}
    </div>
  );
}

ButtonSelect.defaultProps = {
  'options':  [
    { value: 'fonctionnel', label: 'Fonctionnel'},
    { value: 'support', label: 'Support' }
  ],
  'value': '',
  'className': '',
  'form': true
}

export default ButtonSelect;