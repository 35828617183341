import React, {useCallback, useContext, useLayoutEffect, useEffect, useMemo, useRef} from 'react';
import DropdownTreeSelect from "react-dropdown-tree-select";
import ReactDOM from "react-dom";
import {Button} from "react-bootstrap";
import {Intl} from "../../../../_metronic/i18n/I18nProvider";
import useMutationObserver from "../../../hooks/useMutationObserver";
import {FiltersContext} from "../FiltersProvider";

const TreeSelect = React.forwardRef((props, forwardedRef) => {
    const {data} = props;
    const validateButtonRef = useRef();
    const dropdownContentRef = useRef(null);
    const removedFromCross = useRef(false);
    const filters = useContext(FiltersContext);

    /**
     * Update valid button
     */
    const showValidateButton = useCallback(() => {
        validateButtonRef?.current?.remove?.();
        validateButtonRef.current = undefined;

        dropdownContentRef.current = forwardedRef?.current?.node?.getElementsByClassName('dropdown-content')[0];
        if(dropdownContentRef?.current && props?.onValidate) {
            validateButtonRef.current = document.createElement('div');
            validateButtonRef.current.classList.add('text-right', 'dropdown-content-validate');

            ReactDOM.render(
                <Button variant="primary" className="mr-4 mt-2" onClick={() => {
                    props.onValidate()
                }}>{Intl.formatMessage({id: 'TRANSLATOR.VALIDATE'})}</Button>
                , validateButtonRef.current);

            dropdownContentRef?.current?.appendChild?.(validateButtonRef.current);
        }
    }, [props?.onValidate]);

    /**
     * Mutations handler
     *
     * @type {(function(*): void)|*}
     */
    const mutationsHandler = useCallback((mutations) => {
        mutations?.forEach?.(mutation => {
            [...mutation.addedNodes, mutation.removedNodes]?.forEach?.(node => {
                const hasNextSibling = validateButtonRef?.current?.nextSibling !== null;
                const isNotAlreadyMounted = typeof validateButtonRef?.current === "undefined";

                if(!node?.classList?.contains?.('dropdown-content-validate') && (hasNextSibling || isNotAlreadyMounted)) {
                    showValidateButton();
                } else if(node[0]?.classList?.contains?.('dropdown-content')) {
                    showValidateButton()
                }
            })
        })
    }, [showValidateButton]);

    useMutationObserver(forwardedRef?.current?.node, mutationsHandler)

    /**
     * Validate form is removedFromCross
     * Update removers events listeners
     */
    useEffect(() => {
        if (removedFromCross.current && props?.onValidate) {
            props.onValidate();
        }

        removedFromCross.current = false;

        updateTagRemoversEventListener();
    }, [filters]);

    useEffect(() => {
        showValidateButton();
    }, [showValidateButton])

    const handleOnChange = (currentNode, selectedNodes) => {
        props?.onChange?.(currentNode, selectedNodes);
    }

    const tagRemoverClickHandler = (e) => {
        removedFromCross.current = true;
    }

    const updateTagRemoversEventListener = () => {
        const tagRemovers = document.getElementsByClassName('tag-remove');

        if(Array.isArray(tagRemovers))
            tagRemovers?.forEach((tag) => {
                tag.removeEventListener('click', tagRemoverClickHandler);
                tag.addEventListener('click', tagRemoverClickHandler);
            })
    }

    return useMemo(() => {
        return <DropdownTreeSelect {...props}
                                   onChange={handleOnChange}
                                   ref={(ref) => forwardedRef.current = ref}/>
    }, [data]);
});

export default TreeSelect;
