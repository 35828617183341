import dayjs from 'dayjs';
import { Dropdown } from 'primereact/dropdown';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Image, Tab, Tabs } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';

import { alphabeticalSort } from '@app/helpers/ArrayHelper';
import { apvRole } from '@app/helpers/RoleHelper';

import { getTeamSchedules } from '@app/crud/schedule/schedule.crud';
import { TeamSchedule } from '@app/crud/schedule/schedule.type';

import { leadTypes } from '@app/store/customers/customers.store';
import { User } from '@app/store/login/login.store';

import Loader from '@app/partials/content/Loader';
import Sticky from '@app/partials/content/Sticky';

import { getNotSpecifiedAvailabilities, getUserName } from '../Helpers/Team';

import AccountPresence from './AccountPresence';
import AccountTeamPlanning from './AccountTeamPlanning';
import AccountTeamSummary from './AccountTeamSummary';

const TODAY = 0;
const TOMORROW = 1;

const APV_USERS_CALL = [apvRole.agentCall, apvRole.managerCall];
const APV_USER_ROLES = [...APV_USERS_CALL, apvRole.ccs, apvRole.rapv];

const AccountTeam = ({ userData }: { userData: User }) => {
    const notSpecifiedToastRef = useRef(null);
    const [activeTab, setActiveTab] = useState(TODAY);
    const [date, setDate] = useState(dayjs().weekday(0));
    const dealerships = useMemo(
        () =>
            Object.entries(userData?.dealerships)
                ?.sort?.(alphabeticalSort('title'))
                ?.map?.(([key, dealership]) => ({
                    ...dealership,
                    refDealershipId: key,
                })),
        [userData],
    );
    const [dealership, setDealership] = useState(
        dealerships.find((item) => item?.refDealershipId === userData?.default_dealership) || dealerships[0],
    );
    const {
        fetch: fetchSchedules,
        data: teamSchedulesData,
        loading: loadingSchedules,
    } = useFetch({
        fetchAction: getTeamSchedules,
    });

    const teamSchedules = useMemo(() => {
        if (!teamSchedulesData?.length || !userData?.role?.slug) return [];

        const isApv = userData?.tags?.typologie_lead.includes(leadTypes.localApv);

        // if the current user is not APV, remove APV users from team schedules
        if (!isApv) {
            return teamSchedulesData.filter((user) => !APV_USER_ROLES.includes(user?.information?.role?.slug));
        }

        // if the current user is APV and specifically responsable-apv,
        // only show conseiller-commercial-service users
        if (userData?.role?.slug === apvRole.rapv) {
            return teamSchedulesData.filter((user) => user?.information?.role?.slug === apvRole.ccs);
        }

        // if the current user is APV and specifically manager-autosphere-contact,
        // only show agent-autosphere-contact users
        if (userData?.role?.slug === apvRole.managerCall) {
            return teamSchedulesData.filter((user) => user?.information?.role?.slug === apvRole.agentCall);
        }

        return teamSchedulesData.filter((user) => !APV_USERS_CALL.includes(user?.information?.role?.slug));
    }, [userData, teamSchedulesData]);

    const showNotSpecifiedToast = useCallback(
        (notSpecifiedUser: TeamSchedule[]) => {
            notSpecifiedToastRef?.current?.show?.({
                severity: 'error',
                sticky: true,
                content: (
                    <div className="account-schedule-alert">
                        <div className="font-weight-normal font-size-lg">
                            <Image
                                className="account-schedule-alert__picture"
                                src="/media/pages/account/mes-equipes-semaine-type-non-renseignee.svg"
                            />
                            <FormattedHTMLMessage
                                id="USER.PROFILE.TEAM.ALERT.MESSAGE.NUMBER"
                                values={{
                                    number: notSpecifiedUser?.length,
                                }}
                            />
                            <br />
                            <span className="text-danger">
                                {notSpecifiedUser?.map?.((user) => getUserName(user))?.join(',')}
                            </span>
                            <br />
                            <FormattedHTMLMessage id="USER.PROFILE.TEAM.ALERT.MESSAGE.ASK" />
                        </div>
                    </div>
                ),
            });
        },
        [teamSchedules],
    );

    useEffect(() => {
        if (dealership?.refDealershipId) {
            notSpecifiedToastRef?.current?.clear?.();
            fetchSchedules({
                dealershipId: dealership.refDealershipId,
                dayView:
                    Number(activeTab) === TODAY
                        ? dayjs().format('YYYY-MM-DD')
                        : dayjs().add(1, 'day').format('YYYY-MM-DD'),
                dateStart: date.format('YYYY-MM-DD'),
                dateEnd: date.add(1, 'week').format('YYYY-MM-DD'),
            });
        }
    }, [dealership, activeTab, date]);

    useEffect(() => {
        const summary: TeamSchedule[] = getNotSpecifiedAvailabilities(teamSchedules);

        if (teamSchedules?.length > 0 && summary?.length > 0) {
            showNotSpecifiedToast(summary);
        }
    }, [showNotSpecifiedToast, teamSchedules]);

    return (
        <div className="account-team">
            {loadingSchedules && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <div className="mb-7 d-flex align-items-center justify-content-between">
                <div className="font-size-h5 font-weight-bold">
                    {Intl.formatMessage({ id: 'USER.PROFILE.TEAM.VIEW.TITLE' })}
                </div>
                <Dropdown
                    value={dealership}
                    options={dealerships}
                    onChange={(e) => setDealership(e.value)}
                    style={{ height: '45px' }}
                    className="p-dropdown--light-primary"
                    optionLabel="title"
                />
            </div>
            <div className="text-center">
                <Tabs
                    className="d-inline-flex nav-tabs-line nav-tabs-line-primary nav-tabs-line-2x"
                    defaultActiveKey={activeTab}
                    activeKey={activeTab}
                    id={String(activeTab)}
                    onSelect={(e) => setActiveTab(Number(e))}
                >
                    <Tab
                        eventKey={TODAY}
                        tabClassName="font-size-h3 font-weight-semibold"
                        title={`${Intl.formatMessage({ id: 'TRANSLATOR.TODAY' })} (${dayjs().format('DD MMMM')})`}
                    />
                    <Tab
                        eventKey={TOMORROW}
                        tabClassName="font-size-h3 font-weight-semibold"
                        title={Intl.formatMessage({ id: 'TRANSLATOR.TOMORROW' })}
                    />
                </Tabs>
            </div>
            <AccountTeamSummary users={teamSchedules} />
            <AccountPresence users={teamSchedules} />
            <AccountTeamPlanning users={teamSchedules} date={date} setDate={setDate} />
            <Sticky ref={notSpecifiedToastRef} className="w-auto" position="bottom-right" />
        </div>
    );
};

export default AccountTeam;
