import { Divider } from 'primereact/divider';
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { ucwords } from '@app/helpers/StringHelper';

import WeekRow from '../AccountPlanning/WeekRow';
import { getUserName } from '../Helpers/Team';

const ALL = -1;

const AccountTeamPlanning = ({ users, date, setDate }) => {
    const [activeTab, setActiveTab] = useState(ALL);

    const firstMondayOfWeek = date;
    const firstSaturdayOfWeek = date.weekday(0).add(6, 'day');

    const days = new Array(7).fill(undefined).map((value, idx) => date.add(idx, 'day'));

    const getAvailabilities = (availabilities) => {
        if (!availabilities) {
            return null;
        }

        return days.reduce((acc, day) => {
            const formattedDay = day.format('YYYY-MM-DD');

            if (availabilities[formattedDay]) {
                const dayName = day.locale('en').format('dddd').toLowerCase();
                acc[dayName] = availabilities[formattedDay];
            }

            return acc;
        }, {});
    };

    return (
        <div className="mt-15">
            <div className="d-flex justify-content-center align-items-center font-size-h3">
                <i
                    className="las font-size-h3 la-angle-left mr-5 cursor-pointer"
                    onClick={() => {
                        setDate(date.subtract(1, 'week'));
                    }}
                />
                Semaine du&nbsp;
                <strong>{ucwords(firstMondayOfWeek?.format('dddd DD'))}</strong>
                &nbsp;au&nbsp;
                <strong>{ucwords(firstSaturdayOfWeek?.format('dddd DD MMMM'))}</strong>
                <i
                    className="las font-size-h3 la-angle-right ml-5 cursor-pointer"
                    onClick={() => {
                        setDate(date.add(1, 'week'));
                    }}
                />
            </div>
            <Tabs
                className="mt-5 d-flex nav-tabs-line nav-tabs-line-primary nav-tabs-line-2x"
                defaultActiveKey={activeTab}
                activeKey={activeTab}
                id={activeTab}
                onSelect={(e) => setActiveTab(e)}
            >
                <Tab eventKey={ALL} title={ucwords(Intl.formatMessage({ id: 'TRANSLATOR.ALL' }))} />
                {users?.map?.((user) => (
                    <Tab key={user?.information?.id} eventKey={user?.information?.id} title={getUserName(user)} />
                ))}
            </Tabs>
            {users
                ?.filter?.((user) => +activeTab === ALL || +activeTab === user?.information?.id)
                ?.map?.((user) => {
                    const availabilities = getAvailabilities(user?.availabilities);

                    return (
                        <div key={user.information.id}>
                            <Divider type="dashed" className="my-10" />
                            <div className="font-size-lg mb-4">
                                <strong>{getUserName(user)}</strong>
                            </div>
                            <WeekRow days={days} canUpdate={false} availabilities={availabilities} />
                        </div>
                    );
                })}
        </div>
    );
};

export default AccountTeamPlanning;
