import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { FastNote } from '@app/crud/administration/fast-notes.type';

const { REACT_APP_LEAD_URL } = process.env;

export function getAllFastNotes() {
    return ApiGateway.get<ApiResponse<FastNote[]>, CustomAxiosResponse<ApiResponse<FastNote[]>>>(
        `${REACT_APP_LEAD_URL}/fast-note`,
    );
}

export function linkFastNoteToLead(id: number, data: { fastNotes: FastNote[] }) {
    return ApiGateway.post(`${REACT_APP_LEAD_URL}/lead/${id}/link-fast-notes`, data);
}

export function addFastNote(data: { name: string }) {
    return ApiGateway.post(`${REACT_APP_LEAD_URL}/fast-note`, data);
}

export function removeFastNote(id: number) {
    return ApiGateway.delete(`${REACT_APP_LEAD_URL}/fast-note/${id}`);
}
