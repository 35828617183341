import React, { useEffect, useState } from 'react';
import { Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Intl } from '../../../../_metronic/i18n/I18nProvider';
import { routeTo } from '../../../helpers/RoutesHelper';
import PortletTab from '../../../partials/layout/PortletTab';
import ROUTES from '../../../router/Routes';

import OrderTable from './OrderTable';

const Orders = () => {
    const { type } = useParams();
    const [activeKey, setActiveKey] = useState('in_progress');
    const { totalRecords } = useSelector((state) => state.smartRepair.order);

    useEffect(() => {
        setActiveKey(type || 'in_progress');
    }, [setActiveKey, type]);

    return (
        <PortletTab
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onClick={setActiveKey}
            redirects={{
                in_progress: routeTo(ROUTES.SMARTREPAIR_ORDERS.PATH.ALL, {
                    orderAppointmentType: 'in_progress',
                }),
                closed: routeTo(ROUTES.SMARTREPAIR_ORDERS.PATH.ALL, {
                    orderAppointmentType: 'closed',
                }),
            }}
            id="orderlist-portlet"
        >
            <Tab
                eventKey="in_progress"
                title={
                    <>
                        {Intl.formatMessage({ id: 'SR.ORDER.TABS.ORDERS.IN_PROGRESS' })}
                        <span className="ml-5 label label-light-primary font-weight-bold">
                            {totalRecords.in_progress}
                        </span>
                    </>
                }
            >
                {activeKey === 'in_progress' && <OrderTable type="in_progress" />}
            </Tab>
            <Tab
                eventKey="closed"
                title={
                    <>
                        {Intl.formatMessage({ id: 'SR.ORDER.TABS.ORDERS.CLOSED' })}
                        <span className="ml-5 label label-light-primary font-weight-bold">{totalRecords.closed}</span>
                    </>
                }
            >
                {activeKey === 'closed' && <OrderTable type="closed" />}
            </Tab>
        </PortletTab>
    );
};

export default Orders;
