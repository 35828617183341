import React, {useEffect, useState} from 'react';
import * as ReactDOM from "react-dom";
import {Sidebar} from "primereact/sidebar";
import {Button} from "react-bootstrap";
import {Intl} from "../../../_metronic/i18n/I18nProvider";

const SidebarWrapper = ({appendTo, children, visible, setVisible, className, header, position, isMenu}) => {
    const [container, setContainer] = useState(undefined);

    useEffect(() => {
        const element = document.querySelector(appendTo);
        setContainer(element);
    }, [setContainer, appendTo]);

    if (container) {
        return ReactDOM.createPortal(
            <Sidebar visible={visible} position={position} blockScroll onHide={() => setVisible(false)} className={className} showCloseIcon={!header}>
                {
                    header &&
                    <div className="sidebar-header p-4 d-flex align-items-center justify-content-between font-weight-bold">
                        <div className="d-flex align-items-center">
                            <i className={`la ${header.icon} la-2x text-light`} />
                            <span>{Intl.formatMessage({ id: header.title })}</span>
                        </div>
                        <Button className="ml-2 p-2" onClick={() => setVisible(false)} variant="outline-link">
                            <i className="la la-times p-0 text-muted"/>
                        </Button>
                    </div>
                }
                <div className={`sidebar-body${isMenu ? '' : ' p-4'}`}>
                    {children}
                </div>
            </Sidebar>,
            container);
    }

    return <></>;
};

SidebarWrapper.defaultProps = {
    appendTo: '#kt_quick_sidebar',
    children: <></>,
    visible: false,
    position: 'right',
    isMenu: false,
    setVisible: () => {},
    className: 'p-sidebar-md px-0 pt-0'
};

export default SidebarWrapper;
