import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';

import Loader from '@app/partials/content/Loader';
import subHeader from '@app/partials/HoC/subHeader';

import { LayoutContextConsumer } from '../../../../../_metronic';
import { Intl } from '../../../../../_metronic/i18n/I18nProvider';
import BreadCrumbs from '../../../../../_metronic/layout/sub-header/components/BreadCrumbs';

function QuotationSubHeader(props) {
    const { quotation, loading } = useSelector((state) => state.smartRepair.quotation);

    const { subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle } = props;

    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: 'MENU.QUOTATION' }),
        },
        {
            title: Intl.formatMessage({ id: 'MENU.QUOTATION.ORDER' }, { number: quotation?.reference || '' }),
        },
    ];
    if (loading || quotation === null) {
        return <Loader style={{ width: '5rem', height: '5rem' }} />;
    }

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            type="button"
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}

                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">
                                    {Intl.formatMessage({ id: 'MENU.QUOTATION.SMART.REPAIR' })}
                                </h3>
                                <BreadCrumbs items={breadcrumb} />
                            </>
                        )}
                    </LayoutContextConsumer>
                </div>
                <div className="d-flex align-items-center">
                    <Link
                        to={
                            quotation?.orderStatus.groupSlug
                                ? routeTo(ROUTES.QUOTATIONS.PATH, {
                                      orderType: quotation?.orderStatus.groupSlug,
                                  })
                                : routeTo(ROUTES.QUOTATIONS.PATH, {
                                      orderType: 'to_be_appraised',
                                  })
                        }
                    >
                        <Button className="mr-2" variant="outline-light">
                            Retour
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default subHeader(QuotationSubHeader);
