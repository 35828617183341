import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

interface Props {
    event: VcuMappedEventDetails;
}
const SecurycarRenewal = ({ event }: Props) => {
    const Intl = useIntl();
    return (
        <div className="d-md-flex justify-content-md-between align-items-md-stretch">
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RENEWAL.SOURCE' })}
                    value={event?.securycar.renewal[0].source ? event?.securycar.renewal[0].source : '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RENEWAL.CONTRACT.NUMBER' })}
                    value={
                        event?.securycar.renewal[0].contractNumber ? event?.securycar.renewal[0].contractNumber : '-'
                    }
                />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RENEWAL.STARTDATE' })}
                    value={
                        event?.securycar.renewal[0].startDate
                            ? dayjs(event?.securycar.renewal[0].startDate).format('DD/MM/YYYY')
                            : '-'
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RENEWAL.ENDDATE' })}
                    value={
                        event?.securycar.renewal[0].endDate
                            ? dayjs(event?.securycar.renewal[0].endDate).format('DD/MM/YYYY')
                            : '-'
                    }
                />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RENEWAL.PAYMENTMODE' })}
                    value={event?.securycar.renewal[0].paymentMode ? event?.securycar.renewal[0].paymentMode : '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RENEWAL.SUBSCRIPTION.DATE' })}
                    value={
                        event?.securycar.renewal[0].subscriptionDate
                            ? dayjs(event?.securycar.renewal[0].subscriptionDate).format('DD/MM/YYYY')
                            : '-'
                    }
                />
            </div>
        </div>
    );
};

export default SecurycarRenewal;
