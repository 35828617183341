import parseMax from 'libphonenumber-js/max';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useCities } from '@app/hooks/useCitySearch';

import { isAMail, isAZipCode } from '@app/helpers/ToolsHelper';

import { Order } from '@app/crud/apv/order.type';
import { getCities } from '@app/crud/city/city.crud';

import { useAppSelector, useModal } from '@app/hooks';

import Loader from '@app/partials/content/Loader';

import { FormCivility } from '@app/components/forms/FormCivility';
import { FormInputText } from '@app/components/forms/FormInputText';

import APVInStoreContext from '../APVInStoreContext';

import ZipcodeAutoComplete from './ZipcodeAutoComplete';

const APVInStoreCustomer = () => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { stepperState } = useContext(APVInStoreContext);
    const [_show, _toggle, params] = useModal(false, 'apv_in_store') as [
        boolean,
        (show: boolean) => void,
        { order: Order },
    ];
    const { data: customer } = useAppSelector((state) => state.customer.customer);
    const [query, setQuery] = useState('');
    const { cities, loading: loadingCities } = useCities(query);
    const { formState, setValue, watch } = methods;

    const { errors } = formState;

    const watchZipcode = watch('zipcode');

    useEffect(() => {
        const orderOrCustomer = params?.order || customer;

        if (orderOrCustomer) {
            setValue('civility', orderOrCustomer.civility || customer?.civility);
            setValue('firstname', customer?.firstname);
            setValue('lastname', customer?.lastname);
            setValue('email', orderOrCustomer.email || customer?.email);
            setValue('mobile', orderOrCustomer.mobile || customer?.mobile);
            setValue('address', orderOrCustomer.address || customer?.address);
            setValue('zipcode', orderOrCustomer.zipcode || customer?.zipcode);
            setValue('city', orderOrCustomer.city || customer?.city);
        }
    }, [params?.order, customer]);

    // In first time user enter to this view with zipcode, we fetch the city and set it in the form
    useEffect(() => {
        const handleZipcodeChange = async () => {
            if (watchZipcode && watchZipcode.length >= 2) {
                setQuery(watchZipcode);
                try {
                    const response = await getCities(watchZipcode, 'FRA');
                    if (Array.isArray(response.result) && response.result.length > 0) {
                        setValue('city', response.result[0].name);
                    }
                } catch (error) {
                    console.error('Error fetching city:', error);
                }
            }
        };

        handleZipcodeChange();
    }, [watchZipcode]);

    useEffect(() => {
        if (watchZipcode) {
            setQuery(watchZipcode);
        }
    }, [watchZipcode]);

    return (
        <div>
            <Row>
                <Col md={12} lg={12}>
                    <FormCivility
                        label={
                            <>
                                <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.CIVILITY" />*
                            </>
                        }
                        name="civility"
                        required={stepperState.currentStep === 2}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6} lg={6}>
                    <FormInputText
                        label={
                            <>
                                <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.LASTNAME" />*
                            </>
                        }
                        name="lastname"
                        isInvalid={!!errors.lastname}
                        required={stepperState.currentStep === 2}
                        disabled
                    />
                </Col>
                <Col md={6} lg={6}>
                    <FormInputText
                        label={
                            <>
                                <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.FIRSTNAME" />*
                            </>
                        }
                        name="firstname"
                        isInvalid={!!errors.firstname}
                        required={stepperState.currentStep === 2}
                        disabled={!!customer?.firstname}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6} lg={6}>
                    <FormInputText
                        label={
                            <>
                                <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.EMAIL" />*
                            </>
                        }
                        name="email"
                        isInvalid={!!errors.email}
                        required={stepperState.currentStep === 2}
                        validate={(value) => {
                            if (value && !isAMail(value)) {
                                return Intl.formatMessage({
                                    id: 'CUSTOMERS.MODAL.CREATE.EMAIL.FORMAT',
                                });
                            }
                        }}
                    />
                </Col>
                <Col md={6} lg={6}>
                    <FormInputText
                        label={
                            <>
                                <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.MOBILE" />*
                            </>
                        }
                        name="mobile"
                        isInvalid={!!errors.mobile}
                        required={stepperState.currentStep === 2}
                        validate={(value) => {
                            if (value) {
                                const parsedPhoneNumberFR = parseMax(value, 'FR');
                                const parsedPhoneNumberBE = parseMax(value, 'BE');
                                const isMobile =
                                    parsedPhoneNumberFR?.getType() === 'MOBILE' ||
                                    parsedPhoneNumberBE?.getType() === 'MOBILE';

                                if (
                                    (!parsedPhoneNumberFR?.isValid() && !parsedPhoneNumberBE?.isValid()) ||
                                    (!isMobile && stepperState.currentStep === 2)
                                ) {
                                    return Intl.formatMessage({
                                        id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ERROR.MOBILE',
                                    });
                                }
                            }
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={12}>
                    <FormInputText
                        label={
                            <>
                                <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.ADDRESS" />*
                            </>
                        }
                        placeholder={Intl.formatMessage({
                            id: 'CUSTOMER.MODAL.APVINSTORE.PLACEHOLDER.ADDRESS',
                        })}
                        name="address"
                        isInvalid={!!errors.address}
                        required={stepperState.currentStep === 2}
                    />
                </Col>
            </Row>
            <Row>
                {loadingCities && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                <Col md={6} lg={6}>
                    <ZipcodeAutoComplete
                        label={
                            <>
                                <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.ZIPCODE" />*
                            </>
                        }
                        name="zipcode"
                        options={cities}
                        isInvalid={!!errors.zipcode}
                        required={stepperState.currentStep === 2}
                        placeholder={Intl.formatMessage({
                            id: 'CUSTOMER.MODAL.APVINSTORE.PLACEHOLDER.ZIPCODE',
                        })}
                        validate={(value: string) => {
                            if (value && !isAZipCode(value)) {
                                return Intl.formatMessage({
                                    id: 'CUSTOMERS.MODAL.CREATE.ZIPCODE.FORMAT',
                                });
                            }
                        }}
                    />
                </Col>
                <Col md={6} lg={6}>
                    <FormInputText
                        label={
                            <>
                                <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.CITY" />*
                            </>
                        }
                        placeholder={Intl.formatMessage({
                            id: 'CUSTOMERS.MODAL.APVINSTORE.CITY',
                        })}
                        name="city"
                        isInvalid={!!errors.city}
                        required={stepperState.currentStep === 2}
                        disabled
                    />
                </Col>
            </Row>
        </div>
    );
};

export default APVInStoreCustomer;
