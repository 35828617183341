import { Divider } from 'primereact/divider';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import type { Dispatch } from 'redux';

import { capitalize } from '@app/helpers/StringHelper';

import { Customer } from '@app/crud/customers/customer.type';

import { actions } from '@app/store/modal/modal.store';

import Permission from '@app/partials/content/Permission';

interface ProInfoProps {
    dispatch: Dispatch;
    client: Customer;
    updateCustomerCallback: () => void;
    hasDifferentialVCU: boolean;
    isVisiblePhoneNumber: boolean;
}

const ProInfo = ({
    dispatch,
    client,
    updateCustomerCallback,
    hasDifferentialVCU,
    isVisiblePhoneNumber,
}: ProInfoProps) => (
    <>
        <div className="d-flex align-items-center justify-content-between">
            <div className="font-weight-bold font-size-h2">{capitalize(client?.companyName) || ''}</div>
            <div className="btn-status btn-status--pro font-weight-bold">
                <FormattedHTMLMessage id="CUSTOMER.VIEW.INFO.STATUS.PRO" />
            </div>
        </div>
        <Permission permissions={['LEAD_UPDATE_CONTACT']}>
            <div className="d-flex align-items-center py-3">
                <Button
                    className="p-0 text-primary"
                    onClick={() =>
                        dispatch(
                            actions.modalChange('create', {
                                customer: client,
                                update: true,
                                callbackCustomer: updateCustomerCallback,
                            }),
                        )
                    }
                    variant="outline-link"
                >
                    <u>
                        <FormattedHTMLMessage id="CUSTOMERS.MODAL.UPDATE.TITLE" />
                    </u>
                </Button>
                {hasDifferentialVCU && (
                    <Permission permissions={['LEAD_UPDATE_VCU_CONTACT']}>
                        <button
                            type="button"
                            className="label label-inline label-warning text-dark d-flex align-items-center px-4 ml-5 d-block font-weight-bold flag-new-data border-0"
                            onClick={() =>
                                dispatch(
                                    actions.modalChange('rollback_update', {
                                        differentialsVCU: true,
                                        customerFieldChange: client?.differentialsVCU,
                                        callback: updateCustomerCallback,
                                    }),
                                )
                            }
                        >
                            <FormattedMessage id="CUSTOMER.VIEW.INFO.NEW_DATA" />
                        </button>
                    </Permission>
                )}
            </div>
        </Permission>
        <div className="d-flex">
            <div className="mr-2 p-0 col-4">
                <FormattedHTMLMessage id="CUSTOMER.VIEW.INFO.LEGAL.FORM" />
            </div>
            {client?.companyLegalStatus ? (
                <span className="font-weight-bold font-size-h7">{client?.companyLegalStatus}</span>
            ) : (
                <strong>-</strong>
            )}
        </div>
        <div className="d-flex">
            <div className="mr-2 p-0 col-4">
                <FormattedHTMLMessage id="CUSTOMER.VIEW.INFO.SIRET" />
            </div>
            {client?.siret ? (
                <span className="font-weight-bold font-size-h7">{client?.siret}</span>
            ) : (
                <strong>-</strong>
            )}
        </div>
        <div className="d-flex">
            <div className="mr-2 p-0 col-4">
                <FormattedHTMLMessage id="CUSTOMER.VIEW.INFO.SIREN" />
            </div>
            {client?.siren ? (
                <span className="font-weight-bold font-size-h7">{client?.siren}</span>
            ) : (
                <strong>-</strong>
            )}
        </div>
        <div className="d-flex">
            <div className="mr-2 p-0 col-4">
                <FormattedHTMLMessage id="CUSTOMER.VIEW.INFO.ADDRESS" />
            </div>
            {client?.address ? (
                <span className="font-weight-bold font-size-h7">
                    {client?.address}, {client?.zipcode} {client?.city}
                </span>
            ) : (
                <strong>-</strong>
            )}
        </div>
        <div className="d-flex">
            <div className="mr-2 p-0 col-4">
                <FormattedHTMLMessage id="CUSTOMERS.MODAL.CREATE.COMPANY_PHONE" />
            </div>
            {client?.companyPhone ? (
                <span className="font-weight-bold font-size-h7">{client?.companyPhone}</span>
            ) : (
                <strong>-</strong>
            )}
        </div>

        <Divider type="dashed" className="my-6" />
        <div className="d-flex align-items-center justify-content-between">
            <div className="font-weight-bold font-size-h2">{`${capitalize(client?.firstname) || ''} ${
                client?.lastname?.toUpperCase() || ''
            }`}</div>
            <div className="btn-status btn-status--pro font-weight-bold">
                <FormattedHTMLMessage id="CUSTOMER.VIEW.INFO.STATUS.CONTACT.PRO" />
            </div>
        </div>
        <Permission permissions={['LEAD_UPDATE_CONTACT']}>
            <div className="d-flex align-items-center py-3">
                <Button
                    className="p-0 text-primary"
                    onClick={() =>
                        dispatch(
                            actions.modalChange('create', {
                                customer: client,
                                update: true,
                                callbackCustomer: updateCustomerCallback,
                                step: 1,
                            }),
                        )
                    }
                    variant="outline-link"
                >
                    <u>
                        <FormattedHTMLMessage id="CUSTOMERS.MODAL.UPDATE.CONTACT_PRO" />
                    </u>
                </Button>
                {hasDifferentialVCU && (
                    <Permission permissions={['LEAD_UPDATE_VCU_CONTACT']}>
                        <button
                            type="button"
                            className="label label-inline label-warning text-dark d-flex align-items-center px-4 ml-5 d-block font-weight-bold flag-new-data border-0"
                            onClick={() =>
                                dispatch(
                                    actions.modalChange('rollback_update', {
                                        differentialsVCU: true,
                                        customerFieldChange: client?.differentialsVCU,
                                        callback: updateCustomerCallback,
                                    }),
                                )
                            }
                        >
                            <FormattedMessage id="CUSTOMER.VIEW.INFO.NEW_DATA" />
                        </button>
                    </Permission>
                )}
            </div>
        </Permission>

        {!isVisiblePhoneNumber ? (
            <Button
                variant="primary"
                className="w-lg-auto w-100"
                onClick={() => dispatch(actions.modalChange('contactAudi'))}
            >
                <FormattedMessage id="CUSTOMER.SHOW_MAIN_PHONE_NUMBER" />
            </Button>
        ) : (
            <div className="d-flex">
                <div className="mr-2 p-0 col-4">
                    <FormattedHTMLMessage id="CUSTOMER.VIEW.INFO.PRO.PHONE" />
                </div>
                {client?.proPhone ? (
                    <span className="font-weight-bold font-size-h7">{client?.proPhone}</span>
                ) : (
                    <strong>-</strong>
                )}
            </div>
        )}
    </>
);

export default ProInfo;
