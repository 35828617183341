import { Divider } from 'primereact/divider';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { ucwords } from '@app/helpers/StringHelper';

import { getUserAvailabilites, getUserName, isUserHasAvailabilities } from '../Helpers/Team';

const AccountPresenceRow = ({ user, variant }) => {
    const availabilities = getUserAvailabilites(user);

    return (
        <div>
            <div className="d-flex align-items-center font-weight-bolder">
                <div className={`font-size-h5 w-25 px-0 pl-0 text-${variant}`}>
                    {getUserName(user)}
                    {!isUserHasAvailabilities(user) && (
                        <div className="font-size-xs text-nowrap">
                            <FormattedMessage id="USER.PROFILE.TEAM.NOT_SPECIFIED" />
                        </div>
                    )}
                </div>
                <div className="font-size-lg flex-grow-1 flex-shrink-1 flex-basis-0 mx-5 text-center">
                    {!availabilities?.am ? (
                        <div className="py-1 kt-bg-light-dark text-dark">
                            {ucwords(Intl.formatMessage({ id: 'TRANSLATOR.NOT_SPECIFIED' }))}&nbsp;!
                        </div>
                    ) : !availabilities?.am?.absent ? (
                        <div className="py-1 kt-bg-light-success text-success">
                            <FormattedMessage id="TRANSLATOR.FROM" />
                            &nbsp;{availabilities?.am?.start}&nbsp;
                            <FormattedMessage id="TRANSLATOR.TO" />
                            &nbsp;{availabilities?.am?.end}
                        </div>
                    ) : (
                        <div className="py-1 kt-bg-light-warning text-warning">
                            {ucwords(Intl.formatMessage({ id: 'TRANSLATOR.ABSENT' }))}
                        </div>
                    )}
                </div>
                <div className="font-size-lg flex-grow-1 flex-shrink-1 flex-basis-0 mx-5 text-center">
                    {!availabilities?.pm ? (
                        <div className="py-1 kt-bg-light-dark text-dark">
                            {ucwords(Intl.formatMessage({ id: 'TRANSLATOR.NOT_SPECIFIED' }))}&nbsp;!
                        </div>
                    ) : !availabilities?.pm?.absent ? (
                        <div className="py-1 kt-bg-light-success text-success">
                            <FormattedMessage id="TRANSLATOR.FROM" />
                            &nbsp;{availabilities?.pm?.start}&nbsp;
                            <FormattedMessage id="TRANSLATOR.TO" />
                            &nbsp;{availabilities?.pm?.end}
                        </div>
                    ) : (
                        <div className="py-1 text-center kt-bg-light-warning text-warning">
                            {ucwords(Intl.formatMessage({ id: 'TRANSLATOR.ABSENT' }))}
                        </div>
                    )}
                </div>
            </div>
            <Divider type="dashed" className="my-4" />
        </div>
    );
};

export default AccountPresenceRow;
