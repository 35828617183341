import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';
import OrderTable from './OrderTable';
import { getOrders } from '@app/crud/apv/order.crud';
import { actions } from '@app/store/apv/order/order.store';
import { OrderType } from '@app/crud/apv/order.type';

const Orders = () => {
    const dispatch = useDispatch();

    //Get counts
    useEffect(() => {
        (async () => {
            const { count } = await getOrders({
                orderType: OrderType.COMMANDE,
            });
            dispatch(
                actions.setOrdersTotalRecords({
                    commande: count || 0,
                }),
            );
        })();
    }, [dispatch]);

    return (
        <Card>
            <Card.Body>
                <OrderTable type={OrderType.COMMANDE} />
            </Card.Body>
        </Card>
    );
};

export default Orders;
