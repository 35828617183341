import dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { getVehicles } from '@app/crud/securyweb/securyweb.crud';
import { Vehicle } from '@app/crud/securyweb/securyweb.types';

import variantTypes from '@app/constants';
import { useFetch } from '@app/hooks';

import HoCDataTable from '@app/partials/content/HoCDataTable';
import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

type SecuryCarContractStepSearchVehicleProps = {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    onSelectVehicle: (vehicle: Vehicle) => void;
    params?: { immat?: string; vin?: string };
};
const SecuryCarContractStepSearchVehicle = ({
    showModal,
    setShowModal,
    params,
    onSelectVehicle,
}: SecuryCarContractStepSearchVehicleProps) => {
    const Intl = useIntl();
    const [immat, setImmat] = useState<string>(params?.immat);
    const [vin, setVin] = useState<string>(params?.vin);

    useEffect(() => {
        setImmat(params.immat);
        setVin(params.vin);
    }, [params]);

    const { fetch, data, loading, error } = useFetch({
        fetchAction: getVehicles,
        resultInterceptor: (response) => response?.result,
    });
    const handleSearch = () => {
        if (!immat && !vin) {
            return toast({
                variant: variantTypes.DANGER,
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ERROR.VEHICLE_EMPTY' }),
            });
        }
        fetch({ immat, vin });
    };

    const selectVehicle = (vehicle: Vehicle) => {
        onSelectVehicle(vehicle);
        setShowModal(false);
    };

    const immatBodyTemplate = (vehicle: Vehicle) => <div>{vehicle?.immatriculation || ''}</div>;
    const vinBodyTemplate = (vehicle: Vehicle) => <div>{vehicle?.codeVin || ''}</div>;
    const kindBodyTemplate = (vehicle: Vehicle) => <div>{vehicle?.genre || ''}</div>;
    const cnitBodyTemplate = (vehicle: Vehicle) => <div>{vehicle?.typeCnit || ''}</div>;
    const mecBodyTemplate = (vehicle: Vehicle) => {
        const dateMec = vehicle?.datePremiereMiseEnCirculation ? dayjs(vehicle.datePremiereMiseEnCirculation) : null;
        return <div>{dateMec?.format('DD/MM/YYYY') || ''}</div>;
    };
    const brandBodyTemplate = (vehicle: Vehicle) => <div>{vehicle?.modele?.marque?.libelle || ''}</div>;
    const modelBodyTemplate = (vehicle: Vehicle) => <div>{vehicle?.modele?.libelle || ''}</div>;
    const actionBodyTemplate = (vehicle: Vehicle) => (
        <Button onClick={() => selectVehicle(vehicle)}>
            <FormattedMessage id="TRANSLATOR.ADD" />
        </Button>
    );

    useEffect(() => {
        if (error) {
            toast({
                variant: variantTypes.DANGER,
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    }, [error]);

    const cols = [
        {
            field: 'immat',
            header: <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SEARCH.VEHICLE.IMMAT.TITLE" />,
            body: immatBodyTemplate,
        },
        {
            field: 'vin',
            header: <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SEARCH.VEHICLE.VIN.TITLE" />,
            body: vinBodyTemplate,
        },
        {
            field: 'kind',
            header: <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SEARCH.VEHICLE.KIND.TITLE" />,
            body: kindBodyTemplate,
        },
        {
            field: 'cnit',
            header: <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SEARCH.VEHICLE.CNIT.TITLE" />,
            body: cnitBodyTemplate,
        },
        {
            field: 'mec',
            header: <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SEARCH.VEHICLE.MEC.TITLE" />,
            body: mecBodyTemplate,
        },
        {
            field: 'brand',
            header: <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SEARCH.VEHICLE.BRAND.TITLE" />,
            body: brandBodyTemplate,
        },
        {
            field: 'model',
            header: <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SEARCH.VEHICLE.MODEL.TITLE" />,
            body: modelBodyTemplate,
        },
        {
            field: 'action',
            header: <FormattedMessage id="TRANSLATOR.ACTIONS" />,
            body: actionBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <ModalDefault
            size="xl"
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-automobile la-2x" />}
            title={<FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SEARCH.VEHICLE" />}
            body={
                <>
                    <Row>
                        <Col lg={5}>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.IMMAT" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    defaultValue={params?.immat ?? ''}
                                    onChange={(e) => setImmat(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={5}>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="CUSTOMER.VIEW.LIFE.DETAIL.PROJECT.VIN" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    defaultValue={params?.vin ?? ''}
                                    onChange={(e) => setVin(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={2}>
                            <Form.Group>
                                <Button className="mt-8" onClick={handleSearch}>
                                    <FormattedMessage id="TRANSLATOR.SEARCH" />
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                        <Col>
                            {/* @ts-ignore */}
                            <HoCDataTable
                                value={data}
                                totalRecords={100}
                                emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
                            >
                                {dynamicColumns}
                            </HoCDataTable>
                        </Col>
                    </Row>
                </>
            }
            footer={
                <div className="d-flex justify-content-center">
                    <Button variant="primary" onClick={() => setShowModal(false)}>
                        <FormattedMessage id="TRANSLATOR.CLOSE" />
                    </Button>
                </div>
            }
        />
    );
};

export default SecuryCarContractStepSearchVehicle;
