import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

import useFetch from '@app/hooks/useFetch';

import { alphabeticalSort } from '@app/helpers/ArrayHelper';
import { empty, isAMail } from '@app/helpers/ToolsHelper';

import { getRoles } from '@app/crud/autouser/role.crud';
import { getTags } from '@app/crud/autouser/tags.crud';
import { addUser, deleteUser, getAllPerimeter, getUser, updateUser } from '@app/crud/autouser/user.crud';

import variantTypes from '@app/constants';
import { formHandler } from '@app/pages/autouser/users/Form/Form.submit';
import {
    getCheckedDealerships,
    getCheckedPerimeters,
    getPerimeterNode,
    getUserPerimeter,
    mapDealershipNodes,
    updateDatas,
} from '@app/pages/autouser/users/Helpers/Dealership';
import ROUTES from '@app/router/Routes';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import SwitchInput from '@app/partials/content/SwitchInput';
import toast from '@app/partials/content/Toast';
import { TreeSelect } from '@app/partials/content/Tree';
import ErrorForm from '@app/partials/layout/ErrorForm';

const ManageUser = ({ showModal, setShowModal, refresh }) => {
    const Intl = useIntl();
    const methods = useForm();
    const { register, control, setValue, getValues, handleSubmit, watch, reset, errors } = methods;
    const [dealerships, setDealerships] = useState(null);
    const [isExternal, setIsExternal] = useState(false);
    const { params } = useSelector((state) => state.modal);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const treeRef = useRef();
    const [showSecureCarInput, setShowSecureCarInput] = useState(false);
    const {
        fetch: fetchUser,
        data: user,
        loading: loadingUser,
        setData: setUser,
    } = useFetch({
        fetchAction: getUser,
        resultInterceptor: (response) => response?.data || {},
    });
    const { data: roles, loading: loadingRoles } = useFetch({
        fetchAction: getRoles,
        resultInterceptor: (response) => {
            let result = response?.result;

            if (location?.pathname === ROUTES.DEALERSHIPS_PERIMETER.PATH) {
                result = result.filter((role) => role?.type === 'fonctionnel');
            }

            return result;
        },
        autoFetch: true,
    });

    const { data: tags, loading: loadingTags } = useFetch({
        fetchAction: getTags,
        autoFetch: true,
    });
    const {
        fetch: fetchPerimeter,
        data: perimeter,
        loading: loadingPerimeter,
    } = useFetch({
        fetchAction: getAllPerimeter,
        resultInterceptor: (response) => {
            let result = updateDatas(response);

            if (location?.pathname === ROUTES.DEALERSHIPS_PERIMETER.PATH) {
                result = getUserPerimeter(result);
            }

            return result;
        },
        axios: false,
    });
    const role = watch('role');

    useEffect(() => {
        if (params?.user?.id) {
            setIsExternal(params?.user?.external);
            fetchUser(params.user.id);
        } else {
            setIsExternal(true);
            setUser({});
        }

        fetchPerimeter(params?.user?.id);
    }, [fetchPerimeter, params]);

    useEffect(() => {
        register('dealership_perimeter');
    }, [register]);

    useEffect(() => {
        const typologieLead = user.tags?.typologie_lead;

        reset({
            ...user,
            role: user?.role?.slug || '',
            job: user?.job?.slug || '',
            noTags: user.id === undefined ? false : typologieLead?.length === 0,
        });

        setValue('role', user?.role?.slug || '');
        setValue('noTags', user.id === undefined ? false : typologieLead?.length === 0);
        const hasSecuryCarTag = typologieLead?.includes('securycar');
        setShowSecureCarInput(hasSecuryCarTag);

        if (perimeter?.length > 0) {
            const userPerimeter = getCheckedPerimeters(perimeter);
            setDealerships(
                getCheckedDealerships(userPerimeter)
                    .sort(alphabeticalSort('title'))
                    .map((dealership) => ({
                        ...dealership,
                        label: dealership.title,
                        id: dealership.id,
                    })),
            );
        }
    }, [reset, user, perimeter]);

    const handleError = (response) => {
        if (response?.length > 0) {
            const tag = response.at(0)?.tags.toUpperCase();

            toast({
                variant: variantTypes.DANGER,
                message: Intl.formatMessage({ id: `AUTOUSER.${tag}` }),
            });
        } else {
            toast({
                variant: variantTypes.DANGER,
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    };

    const onSubmit = async (form_values) => {
        setLoading(true);
        if (user?.id) {
            await updateUser(user?.id, formHandler(form_values)).catch(handleError);
        } else {
            await Promise.resolve(addUser(formHandler(form_values))).catch((response) => {
                if (response.length === 1 && response.at(0)?.email !== undefined) {
                    const pattern = 'A user with this email .* already exists in the database.';
                    if (response.at(0)?.email.match(pattern)) {
                        Swal.fire({
                            title: Intl.formatMessage({ id: 'ERROR.OH_NO' }),
                            text: Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.ADD.EMAIL.EXIST' }),
                            icon: 'warning',
                        });
                    }
                } else {
                    handleError(response);
                }
            });
        }
        setLoading(false);
        setShowModal(false);

        if (refresh) {
            refresh();
        }
        if (params?.refresh) {
            params.refresh();
        }
    };

    const handleRemove = () => {
        Swal.fire({
            title: Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.DELETE.TITLE' }),
            text: Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.DELETE.TEXT' }),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
            cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
            customClass: {
                confirmButton: 'btn mr-3 btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            preConfirm: async () => {
                Swal.showLoading();

                await deleteUser(user?.id);

                setShowModal(false);
                if (refresh) {
                    refresh();
                }
            },
        });
    };

    const onChange = (currentNode, selectedNodes) => {
        const nodes = selectedNodes.map((selectedNode) => getPerimeterNode(perimeter, selectedNode?.path));
        setDealerships(getCheckedDealerships(nodes));
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const checkTags = (tags) => {
        if (getValues().noTags) {
            return !tags || tags?.length === 0
                ? true
                : Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.NO_JOBS_ERROR' });
        }
        return tags?.length > 0 ? true : Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' });
    };

    const checkEmail = (value) =>
        empty(value) ||
        isAMail(value) ||
        Intl.formatMessage({
            id: 'CUSTOMERS.MODAL.CREATE.EMAIL.FORMAT',
        });

    return (
        <ModalDefault
            show={showModal}
            size="xl"
            loading={[loading, loadingUser, loadingRoles, loadingTags, loadingPerimeter].some((isLoading) => isLoading)}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-user" />}
            title={Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.TITLE' })}
            body={
                <FormProvider {...methods}>
                    <Row>
                        <Col lg={4}>
                            <Form.Group>
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.LASTNAME' })}
                                </Form.Label>
                                <Form.Control ref={register} name="last_name" disabled={!isExternal} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.FIRSTNAME' })}
                                </Form.Label>
                                <Form.Control ref={register} name="first_name" disabled={!isExternal} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.EMAIL' })}
                                </Form.Label>
                                <Form.Control ref={register} name="email" disabled={!isExternal} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.PHONE' })}
                                </Form.Label>
                                <Form.Control ref={register} name="phone" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.MOBILE' })}
                                </Form.Label>
                                <Form.Control
                                    ref={register({
                                        validate: (value) => {
                                            const call_transfer = getValues('call_transfer') === true;
                                            return empty(value) && call_transfer
                                                ? Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                                : true;
                                        },
                                    })}
                                    className={`${errors.cell_phone ? 'is-invalid' : ''}`}
                                    name="cell_phone"
                                />
                                <ErrorForm errors={errors} name="cell_phone" />
                            </Form.Group>
                        </Col>
                        <Col lg={8}>
                            <Form.Group>
                                <Form.Label>{Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.ROLE' })}</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="role"
                                    onChange={(e) => {
                                        setValue('call_transfer', e?.target?.value === 'conseiller-commercial');
                                    }}
                                    ref={register({
                                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    })}
                                >
                                    <option value="">
                                        {Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.ROLE.PLACEHOLDER' })}
                                    </option>
                                    {roles?.length > 0 &&
                                        roles.map((roleItem) => (
                                            <option key={roleItem.slug} value={roleItem.slug}>
                                                {roleItem.name}
                                            </option>
                                        ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.DEALERSHIP' })}
                                </Form.Label>
                                <Controller
                                    name="dealership_perimeter"
                                    control={control}
                                    rules={{
                                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    }}
                                    render={(props) => (
                                        <TreeSelect
                                            data={perimeter}
                                            texts={{
                                                placeholder: 'Ajouter...',
                                            }}
                                            ref={treeRef}
                                            keepOpenOnSelect
                                            onChange={(currentNode, selectedNodes) => {
                                                const nodes = mapDealershipNodes(selectedNodes);
                                                props?.onChange(nodes?.length > 0 ? nodes : null);
                                                onChange(currentNode, selectedNodes);
                                            }}
                                        />
                                    )}
                                />
                                <ErrorForm errors={errors} name="dealership_perimeter" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    {Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.DEFAULT_DEALERSHIP' })}
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    ref={register({
                                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                    })}
                                    disabled={!dealerships?.length}
                                    name="default_dealership"
                                >
                                    {dealerships?.length > 0 &&
                                        dealerships.map((dealership) => (
                                            <option key={dealership.id} value={dealership.id}>
                                                {dealership.title} - {dealership.id}
                                                {dealership?.codePvo && ` - ${dealership.codePvo}`}
                                            </option>
                                        ))}
                                </Form.Control>
                                <ErrorForm errors={errors} name="default_dealership" />
                            </Form.Group>
                            {tags?.length > 0 && (
                                <>
                                    <Form.Group>
                                        <Form.Label>
                                            {Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.JOBS' })}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="tags[typologie_lead]"
                                            rules={{
                                                validate: checkTags,
                                            }}
                                            render={(props) => {
                                                const handleChange = (e) => {
                                                    const { value } = e;
                                                    props.onChange(value);
                                                    setShowSecureCarInput(value.includes('securycar'));
                                                };

                                                return (
                                                    <MultiSelect
                                                        value={props.value}
                                                        options={
                                                            tags?.length > 0 &&
                                                            tags.filter((tag) => tag.category === 'typologie_lead')
                                                        }
                                                        onChange={handleChange}
                                                        optionLabel="name"
                                                        optionValue="slug"
                                                        placeholder={Intl.formatMessage({
                                                            id: 'AUTOUSER.USER.MODAL.MANAGE.JOBS.PLACEHOLDER',
                                                        })}
                                                        display="chip"
                                                    />
                                                );
                                            }}
                                        />
                                        <ErrorForm errors={errors} name="tags[typologie_lead]" />
                                    </Form.Group>
                                    <Form.Group className="d-flex justify-content-end m-2">
                                        <Form.Label>
                                            <FormattedMessage id="AUTOUSER.USER.MODAL.MANAGE.NO_JOBS" />
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="noTags"
                                            render={(props) => (
                                                <Checkbox
                                                    onChange={(e) => props.onChange(e.checked)}
                                                    checked={props.value}
                                                    className="mx-2"
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                    {showSecureCarInput && (
                                        <Form.Group>
                                            <Form.Label>
                                                {Intl.formatMessage({
                                                    id: 'AUTOUSER.USER.MODAL.MANAGE.SECURY_CAR_MAIL',
                                                })}
                                            </Form.Label>
                                            <Form.Control
                                                ref={register({
                                                    validate: (value) => checkEmail(value),
                                                })}
                                                name="securyweb_email"
                                                className={`${errors.securyweb_email ? 'is-invalid' : ''}`}
                                                placeholder={Intl.formatMessage({
                                                    id: 'AUTOUSER.USERS.VIEW.EMAIL.PLACEHOLDER',
                                                })}
                                            />
                                            <ErrorForm errors={errors} name="securyweb_email" />
                                        </Form.Group>
                                    )}
                                    <Form.Group className="d-none">
                                        <Form.Label>
                                            {Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.JOBS_CATEGORIES' })}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="tags[zone_action]"
                                            defaultValue={[]}
                                            render={(props) => (
                                                <MultiSelect
                                                    value={props.value}
                                                    options={
                                                        tags?.length > 0 &&
                                                        tags.filter((tag) => tag.category === 'zone_action')
                                                    }
                                                    onChange={(e) => props.onChange(e.value)}
                                                    optionLabel="name"
                                                    optionValue="slug"
                                                    placeholder={Intl.formatMessage({
                                                        id: 'AUTOUSER.USER.MODAL.MANAGE.JOBS_CATEGORIES.PLACEHOLDER',
                                                    })}
                                                    display="chip"
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </>
                            )}
                            <div className="d-flex">
                                <Form.Group className="d-flex col-4 flex-column">
                                    <Form.Label>
                                        {Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.CALL_TRANSFER' })}
                                    </Form.Label>
                                    <SwitchInput
                                        name="call_transfer"
                                        labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.NO' })}
                                        labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.YES' })}
                                    />
                                </Form.Group>
                                {!['conseiller-commercial'].includes(role) && (
                                    <Form.Group className="d-flex col-4 flex-column">
                                        <Form.Label>
                                            {Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.LEAD_ALERT' })}
                                        </Form.Label>
                                        <SwitchInput
                                            name="lead_alert"
                                            labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.NO' })}
                                            labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.YES' })}
                                        />
                                    </Form.Group>
                                )}
                                {!['conseiller-commercial'].includes(role) && (
                                    <Form.Group className="d-flex col-4 flex-column">
                                        <Form.Label>
                                            {Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.PLANNING' })}
                                        </Form.Label>
                                        <SwitchInput
                                            name="outlook_planning"
                                            labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.NO' })}
                                            labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.YES' })}
                                        />
                                    </Form.Group>
                                )}
                            </div>
                        </Col>
                    </Row>
                </FormProvider>
            }
            footer={
                <div className="d-flex w-100 justify-content-between">
                    <div>
                        {isExternal && user?.id && (
                            <Button variant={variantTypes.DANGER} onClick={handleRemove}>
                                {Intl.formatMessage({ id: 'AUTOUSER.USER.MODAL.MANAGE.REMOVE' })}
                            </Button>
                        )}
                    </div>
                    <div>
                        <Button className="mr-5" variant="outline-secondary" onClick={() => setShowModal(false)}>
                            {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                        </Button>
                        <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                            {Intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                        </Button>
                    </div>
                </div>
            }
        />
    );
};

export default ManageUser;
