import React from 'react';

import DashboardActions from '../../partials/DashboardActions';
import DashboardProgress from '../../partials/DashboardProgress';
import DashboardProgressCircle from '../../partials/Stats/DashboardProgressCircle';

export interface DisplayProgressBar {
    displayProgressCircle: boolean;
}

const DashboardSeller = ({ displayProgressCircle }: DisplayProgressBar) => (
    <div className="dashboard-role d-flex flex-column">
        <div className="d-flex gap-2">
            <DashboardProgress className="d-flex align-items-end" />
            {displayProgressCircle && <DashboardProgressCircle className="d-flex align-items-end" />}
        </div>
        <DashboardActions className="d-flex mt-8 h-100" />
    </div>
);

export default DashboardSeller;
