import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import useActionHandler from '@app/pages/customers/Hooks/useActionHandler';

import ModalDefault from '@app/partials/content/modals/Modal.default';

const ForceTreatment = ({ showModal, setShowModal }) => {
    const { params } = useSelector((state) => state.modal);
    const handleActionClick = useActionHandler(params?.lead);

    const handleClose = () => {
        setShowModal(false);
        handleActionClick(params?.action);
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            title={
                <div className="d-flex mt-6 align-items-center justify-content-center">
                    <Image
                        className="h-100 mr-10"
                        src="/media/pages/customers/vous-n-etes-pas-le-referent-principal.svg"
                    />
                    <div className="font-size-h3 text-left">
                        <FormattedHTMLMessage id="CUSTOMERS.MODAL.FORCE_TREATMENT.TITLE" />
                    </div>
                </div>
            }
            body={
                <div className="mt-5 text-center font-size-lg">
                    <FormattedHTMLMessage id="CUSTOMERS.MODAL.FORCE_TREATMENT.TEXT" />
                </div>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        <FormattedMessage id="TRANSLATOR.CANCEL" />
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        <FormattedMessage id="TRANSLATOR.CONTINUE" />
                    </Button>
                </div>
            }
        />
    );
};

export default ForceTreatment;
