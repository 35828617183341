import React, { useEffect, useState } from 'react';
import { Button, Card, Row, Tab } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getAdminAlerts, getAlertLevels } from '@app/crud/administration/alerts';

import { useFetch, useModal, useTableHandlers } from '@app/hooks';
import ROUTES from '@app/router/Routes';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import { Filters } from '@app/partials/layout/Filters';
import PortletTab from '@app/partials/layout/PortletTab';

import AlertsDatatable from './AlertsDatatable';
import { AlertCreationModal, AlertDeletionModal } from './modals';

function Alerts() {
    const Intl = useIntl();
    const { status } = useParams<{ status: string }>();
    const [activeKey, setActiveKey] = useState(status);

    const [alertModalOpen, setAlertModalOpen] = useModal(false, 'alert_modal_edit');
    const [alertDeletionModalOpen, setAlertDeletionModalOpen] = useModal(false, 'alert_modal_delete');

    const { fetch: fetchAlertLevels, data: alertLevels } = useFetch({
        fetchAction: getAlertLevels,
    });

    const { filters } = useFilters({
        name: 'administration_alerts',
    });

    const { data, loading, handleFilter, handlePagination, refresh } = useTableHandlers({
        filterStore: 'administration_alerts',
        fetchAction: getAdminAlerts,
        withStore: false,
        paramsInterceptor: (params) => ({
            ...params,
            isArchive: status && status === 'archive' ? 1 : 0,
        }),
    });

    const tabs = [
        {
            status: 'current',
            title: Intl.formatMessage({ id: 'ADMINISTRATION.ALERTS.CURRENT' }),
            counter: 'alerts_to_come',
        },
        {
            status: 'archive',
            title: Intl.formatMessage({ id: 'ADMINISTRATION.ALERTS.PAST' }),
            counter: 'alerts_expired',
        },
    ];

    useEffect(() => {
        if (alertLevels.length === 0) fetchAlertLevels().then().catch();
    }, []);

    return (
        <Card>
            <Card.Body>
                <FiltersProvider value={filters}>
                    <Filters>
                        <Row className="justify-content-end">
                            <Button variant="outline-primary" className="mr-4" onClick={() => setAlertModalOpen(true)}>
                                <FormattedMessage id="ADMINISTRATION.ALERTS.ACTION.CREATE.LABEL" />
                            </Button>
                        </Row>
                    </Filters>
                    <PortletTab
                        defaultActiveKey={activeKey}
                        activeKey={activeKey}
                        id={activeKey}
                        onClick={setActiveKey}
                        redirects={{
                            current: routeTo(ROUTES.ALERTS.PATH.CURRENT),
                            archive: routeTo(ROUTES.ALERTS.PATH.ARCHIVE, {
                                status: 'archive',
                            }),
                        }}
                    >
                        {Object.values(tabs).map((item) => (
                            <Tab
                                key={item.status}
                                eventKey={item.status}
                                title={`${item.title} (${(data.facets && data?.facets[item.counter]) || 0})`}
                            >
                                <AlertsDatatable
                                    data={data}
                                    status={status}
                                    loading={loading}
                                    handleFilter={handleFilter}
                                    handlePagination={handlePagination}
                                    alertLevels={alertLevels}
                                />
                            </Tab>
                        ))}
                    </PortletTab>
                </FiltersProvider>
                <AlertCreationModal
                    showModal={alertModalOpen}
                    setShowModal={setAlertModalOpen}
                    alertLevels={alertLevels}
                    refresh={refresh}
                />
                <AlertDeletionModal
                    showModal={alertDeletionModalOpen}
                    setShowModal={setAlertDeletionModalOpen}
                    refresh={refresh}
                />
            </Card.Body>
        </Card>
    );
}

export default Alerts;
