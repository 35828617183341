import ApiGateway, { ApiResponse, CustomAxiosResponse } from '../../helpers/AxiosHelper';
import { OrderStatusReason, OrderStatusReasonParams } from '../manufacturer/order.crud';

const { REACT_APP_SMART_REPAIR_URL } = process.env;

export function getOrders(params: any) {
    return ApiGateway.get(`${REACT_APP_SMART_REPAIR_URL}/api/order`, { params });
}

export function getOrder(id: string) {
    return ApiGateway.get(`${REACT_APP_SMART_REPAIR_URL}/api/order/${id}`);
}

export function updateOrderAppointment(id: string, params: any) {
    return ApiGateway.put(`${REACT_APP_SMART_REPAIR_URL}/api/order/appointment/${id}`, params);
}

export function getOrderStatusReason(params: OrderStatusReasonParams) {
    return ApiGateway.get<ApiResponse<OrderStatusReason[]>, CustomAxiosResponse<ApiResponse<OrderStatusReason[]>>>(
        `${REACT_APP_SMART_REPAIR_URL}/api/order/statusReason`,
        { params },
    );
}

export function cancelOrderAppointment(id: string, params: any) {
    return ApiGateway.put(`${REACT_APP_SMART_REPAIR_URL}/api/order/appointment/${id}/cancel`, params);
}

export function getSlotsByOrderAppointmentId(id: string, date: string) {
    return ApiGateway.get<ApiResponse<any>, CustomAxiosResponse<ApiResponse<any>>>(
        `${REACT_APP_SMART_REPAIR_URL}/api/order/${id}/timeslot/${date}`,
    );
}
