import React, { useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { capitalize } from '@app/helpers/StringHelper';

import { getOneCustomerActivity } from '@app/crud/customers/customer.crud';
import { ApiLeadReason, CustomerEvent, LeadDetails } from '@app/crud/customers/customer.type';

import { useAppSelector, useFetch } from '@app/hooks';
import { CustomerParams, Lead } from '@app/store/customers/customers.store';

import InformationNotice from '@app/partials/content/InformationNotice';
import Loader from '@app/partials/content/Loader';
import SwitchInput from '@app/partials/content/SwitchInput';
import ErrorForm from '@app/partials/layout/ErrorForm';

interface Props {
    params?: {
        event?: CustomerEvent;
    };
    lead: Lead;
    listingParams: CustomerParams;
    leadReasons: ApiLeadReason;
}

const LeadWonFid = ({ params, lead, listingParams, leadReasons }: Props) => {
    const Intl = useIntl();
    const { data: customer } = useAppSelector((state) => state.customer.customer);
    const { userData } = useAppSelector((state) => state.auth);

    const {
        fetch: fetchActivity,
        data: leadData,
        loading,
    } = useFetch<string, LeadDetails>({
        fetchAction: getOneCustomerActivity,
    });
    const { register, unregister, errors, watch } = useFormContext();
    const salesType = watch('salesType');
    const SalesTypeSlug = {
        WITH_FINANCING: 'with_financing',
        WITHOUT_FINANCING: 'without_financing',
        RENEWAL: 'renewal',
    };
    const watchIsSaleWithFinancing = salesType === SalesTypeSlug.WITH_FINANCING;

    useEffect(() => {
        if (lead?.id && fetchActivity) {
            fetchActivity(lead.id.toString());
        }
    }, [lead, fetchActivity]);

    useEffect(() => {
        if (watchIsSaleWithFinancing === false) {
            unregister('partner');
        }
    }, [unregister, watchIsSaleWithFinancing]);

    const clientName = useMemo(
        () => `${capitalize(customer.civility)} ${capitalize(customer.firstname)} ${capitalize(customer.lastname)}`,
        [customer],
    );

    const isCredipar = leadData?.finance?.isCredipar;

    const isCrediparReasons =
        (salesType === SalesTypeSlug.WITH_FINANCING || salesType === SalesTypeSlug.WITHOUT_FINANCING) && isCredipar;
    const reasons = ['same_brand', 'other_brand_stellantis', 'other_brand_not_stellantis'].map(
        (key) => leadReasons[key],
    );

    const renderSalesTypeSection = () => {
        if (isCredipar) {
            if (!salesType || salesType === SalesTypeSlug.WITH_FINANCING) {
                return (
                    <>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMERS.MODAL.LEAD_WON.FID.CONTRACT_NUMBER_LABEL" />
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="newContractNumber"
                            className={errors?.newContractNumber ? 'is-invalid' : ''}
                            placeholder={Intl.formatMessage({
                                id: 'CUSTOMERS.MODAL.LEAD_WON.FID.PLACEHOLDER.NUMBER_LABEL',
                            })}
                            ref={register({
                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            })}
                        />
                        <ErrorForm errors={errors} name="newContractNumber" />
                    </>
                );
            } else if (salesType === SalesTypeSlug.WITHOUT_FINANCING || salesType === SalesTypeSlug.RENEWAL) {
                return (
                    <>
                        <Form.Label>
                            <FormattedMessage id="CUSTOMERS.MODAL.LEAD_WON.FID.PURCHASE_NUMBER_LABEL" />
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="purchaseOrderNumber"
                            placeholder={Intl.formatMessage({
                                id: 'CUSTOMERS.MODAL.LEAD_WON.FID.PLACEHOLDER.NUMBER_LABEL',
                            })}
                            className={errors?.purchaseOrderNumber ? 'is-invalid' : ''}
                            ref={register({
                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            })}
                        />
                        <ErrorForm errors={errors} name="purchaseOrderNumber" />
                    </>
                );
            }
        } else {
            return (
                <>
                    <Form.Label>
                        <FormattedMessage id="CUSTOMERS.MODAL.LEAD_WON.FID.FILE_NUMBER_LABEL" />
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name="contractNumber"
                        defaultValue={leadData?.finance?.contractNumber}
                        disabled
                    />
                    <ErrorForm errors={errors} name="contractNumber" />
                </>
            );
        }
        return null;
    };

    return (
        <>
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            {!isCrediparReasons && (
                <Form.Control
                    type="hidden"
                    name="reason"
                    defaultValue="close_lead_won_reason"
                    ref={register({
                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    })}
                />
            )}
            <Form.Control
                type="hidden"
                name="contactId"
                defaultValue={customer.id}
                ref={register({
                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                })}
            />
            <Form.Control
                type="hidden"
                name="result"
                defaultValue="success_fid"
                ref={register({
                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                })}
            />
            <Form.Control
                type="hidden"
                name="user.id"
                defaultValue={userData.id}
                ref={register({
                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                })}
            />
            <Form.Control
                type="hidden"
                name="user.firstname"
                defaultValue={userData.first_name}
                ref={register({
                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                })}
            />
            <Form.Control
                type="hidden"
                name="user.lastname"
                defaultValue={userData.last_name}
                ref={register({
                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                })}
            />
            <Form.Control
                type="hidden"
                name="user.email"
                defaultValue={userData.email}
                ref={register({
                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                })}
            />
            <Form.Control
                type="hidden"
                name="lead.id"
                defaultValue={lead.id}
                ref={register({
                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                })}
            />
            <Form.Label>
                <FormattedMessage id="CUSTOMERS.MODAL.LEAD_WON.FID.IS_SALE_WITH_FINANCE_LABEL" />
            </Form.Label>
            <Form.Control
                as="select"
                name="salesType"
                defaultValue=""
                disabled={!!params?.event}
                className={errors?.salesType ? 'is-invalid' : ''}
                ref={register({
                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                })}
            >
                <option value="">
                    {Intl.formatMessage({
                        id: 'CUSTOMERS.MODAL.LEAD_WON.TYPE.PLACEHOLDER',
                    })}
                </option>
                <option value={SalesTypeSlug.WITH_FINANCING}>
                    {Intl.formatMessage({
                        id: 'CUSTOMERS.MODAL.LEAD_WON.FID.IS_SALE_WITH_FINANCE_WITH',
                    })}
                </option>
                <option value={SalesTypeSlug.WITHOUT_FINANCING}>
                    {Intl.formatMessage({
                        id: 'CUSTOMERS.MODAL.LEAD_WON.FID.IS_SALE_WITH_FINANCE_WITHOUT',
                    })}
                </option>
                {isCredipar && (
                    <option value={SalesTypeSlug.RENEWAL}>
                        {Intl.formatMessage({
                            id: 'CUSTOMERS.MODAL.LEAD_WON.FID.RENEWAL',
                        })}
                    </option>
                )}
            </Form.Control>
            <ErrorForm errors={errors} name="salesType" />
            {isCrediparReasons && (
                <>
                    <Form.Label>
                        {Intl.formatMessage({
                            id: 'CUSTOMERS.MODAL.LEAD_WON.FID.REASON',
                        })}
                    </Form.Label>
                    <Form.Control
                        as="select"
                        name="reason"
                        defaultValue=""
                        disabled={!!params?.event}
                        className={errors.reason ? 'is-invalid' : ''}
                        ref={register({
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                        })}
                    >
                        <option value="">
                            {Intl.formatMessage({
                                id: 'CUSTOMERS.MODAL.LEAD_WON.TYPE.PLACEHOLDER',
                            })}
                        </option>
                        {reasons &&
                            reasons?.map((value) => (
                                <option key={value.id} value={value.slug}>
                                    {value.name}
                                </option>
                            ))}
                    </Form.Control>
                    <ErrorForm errors={errors} name="reason" />
                </>
            )}
            {watchIsSaleWithFinancing === true && (
                <>
                    <Form.Label>
                        {Intl.formatMessage({
                            id: isCredipar
                                ? 'CUSTOMERS.MODAL.LEAD_WON.FID.NEW_PARTNER'
                                : 'CUSTOMERS.MODAL.LEAD_WON.FID.FINANCING_AGENCY_LEAD_WON_LABEL',
                        })}
                    </Form.Label>
                    <Form.Control
                        as="select"
                        name="partner"
                        defaultValue=""
                        disabled={!!params?.event}
                        className={errors?.partner ? 'is-invalid' : ''}
                        ref={register({
                            required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                        })}
                    >
                        <option value="">
                            {Intl.formatMessage({
                                id: 'CUSTOMERS.MODAL.LEAD_WON.TYPE.PLACEHOLDER',
                            })}
                        </option>

                        {listingParams?.funding_agency.listingParams
                            .filter((agency) => agency.isActive)
                            .map((item) => (
                                <option value={item.slug} key={item.slug}>
                                    {item.name}
                                </option>
                            ))}
                    </Form.Control>
                    <ErrorForm errors={errors} name="partner" />
                </>
            )}
            {renderSalesTypeSection()}
            <Form.Label>
                <FormattedMessage id="CUSTOMERS.MODAL.LEAD_WON.FID.CLIENT_NAME_LABEL" />
            </Form.Label>
            <Form.Control type="text" name="customerName" defaultValue={clientName} disabled />

            <Form.Group>
                <InformationNotice>
                    <>
                        {
                            // TODO: upgrade react-numeric-format to v5 and replace NumberFormat with numericFormatter
                        }
                        <FormattedHTMLMessage id="CUSTOMERS.MODAL.LEAD_WON.FID.NOTICE" />{' '}
                        <strong>
                            <NumberFormat
                                value={leadData?.finance?.residualValue}
                                thousandSeparator=" "
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale
                                displayType="text"
                                suffix=" €"
                            />
                        </strong>{' '}
                        <FormattedHTMLMessage id="CUSTOMERS.MODAL.LEAD_WON.FID.NOTICE_SUFFIX" />
                    </>
                </InformationNotice>
                <SwitchInput
                    name="isResidualBlocked"
                    labelFirst=""
                    labelSecond={Intl.formatMessage({
                        id: 'CUSTOMERS.MODAL.LEAD_CLOSE.FID.VR_BLOCK',
                    })}
                    className="mt-4"
                />
            </Form.Group>
        </>
    );
};

LeadWonFid.defaultProps = {
    params: {},
};

export default LeadWonFid;
