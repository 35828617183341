import React from 'react';

export const FiltersContext = React.createContext();

const FiltersProvider = ({value, children}) => {
    return (
        <FiltersContext.Provider value={value}>
            {children}
        </FiltersContext.Provider>
    );
};

export default FiltersProvider;
