import Swal from 'sweetalert2';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { routeTo } from '@app/helpers/RoutesHelper';

import {
    addPackage,
    deletePackage,
    getPackage,
    getPackages,
    updatePackageById,
} from '@app/crud/manufacturer/package.crud';

import ROUTES from '@app/router/Routes';

import toast from '@app/partials/content/Toast';

import { actions } from './package.store';

export function fetchPackages(params) {
    return (dispatch) => {
        dispatch(actions.fetchPackages());

        getPackages(params)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(
                        actions.fetchedPackages({
                            ...response.result,
                            count: response?.count || 0,
                        }),
                    );
                } else {
                    dispatch(actions.fetchPackagesFailed({}));
                }
            })
            .catch(() => {
                dispatch(actions.fetchPackagesFailed({}));
            });
    };
}

export function fetchPackage(id) {
    return (dispatch) => {
        if (id) {
            dispatch(actions.fetchPackage());
            getPackage(id)
                .then((value) => {
                    const packageItem = value || null;

                    dispatch(
                        actions.fetchedPackage({
                            package: packageItem?.result || [],
                            categories: packageItem?.categories || [],
                        }),
                    );
                })
                .catch(() => {
                    dispatch(actions.fetchPackageFailed({}));
                });
        }
    };
}

export function createPackage(params) {
    return (dispatch) => {
        dispatch(actions.addPackage());

        addPackage(params.data)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.addedPackage(response.result));
                    params.history.push(
                        routeTo(ROUTES.PACKAGE_MANUFACTURER.PATH.UPDATE, {
                            id: response.result.cryptedId,
                        }),
                    );
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'APV.PACKAGE.ADD.SUCCESS' }),
                    });
                } else {
                    dispatch(actions.addPackageFailed(response.result));
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'APV.PACKAGE.ADD.FAILED' }),
                    });
                }
            })
            .catch(() => {
                dispatch(actions.addPackageFailed({}));
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'APV.PACKAGE.ADD.FAILED' }),
                });
            });
    };
}

export function updatePackage(id, data) {
    return (dispatch) => {
        dispatch(actions.updatePackage());

        updatePackageById(id, data)
            .then((response) => {
                if (!response?.result?.error) {
                    dispatch(actions.updatedPackage(response.result));
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'APV.PACKAGE.UPDATE.SUCCESS' }),
                    });
                } else {
                    dispatch(actions.updatePackageFailed(response.result));
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'APV.PACKAGE.UPDATE.FAILED' }),
                    });
                }
            })
            .catch(() => {
                dispatch(actions.updatePackageFailed({}));
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'APV.PACKAGE.UPDATE.FAILED' }),
                });
            });
    };
}

export function removePackage(params) {
    return (dispatch) => {
        Swal.fire({
            title: Intl.formatMessage({ id: 'APV.PACKAGE.REMOVE.TITLE' }),
            text: Intl.formatMessage({ id: 'APV.PACKAGE.REMOVE.TEXT' }),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
            cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
            customClass: {
                confirmButton: 'btn mr-3 btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            preConfirm: () => {
                Swal.showLoading();
                dispatch(actions.removePackage());

                return deletePackage(params.id)
                    .then((response) => {
                        if (!response?.result?.error) {
                            params.history.push(
                                routeTo(ROUTES.PACKAGES.PATH.TYPE, {
                                    type: 'manufacturer',
                                }),
                            );
                            toast({
                                variant: 'success',
                                message: Intl.formatMessage({ id: 'APV.PACKAGE.REMOVE.SUCCESS' }),
                            });
                        } else {
                            toast({
                                variant: 'danger',
                                message: Intl.formatMessage({ id: 'APV.PACKAGE.REMOVE.FAILED' }),
                            });
                        }
                    })
                    .catch(() => {
                        toast({
                            variant: 'danger',
                            message: Intl.formatMessage({ id: 'APV.PACKAGE.REMOVE.FAILED' }),
                        });
                    });
            },
        });
    };
}
