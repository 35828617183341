import { Column } from 'primereact/column';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';

import { getRoles } from '@app/crud/autouser/role.crud';
import { getTags } from '@app/crud/autouser/tags.crud';

import DropdownFilter from '@app/partials/content/DropdownFilter';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import MultiSelectFilter from '@app/partials/content/MultiSelectFilter';
import SelectFilter from '@app/partials/content/SelectFilter';

import ActionsButtons from './Partials/ActionsButtons';

function UsersDataTable({
    users,
    totalRecords,
    handleFilter,
    updatePendingFilters,
    releasePendingFilters,
    handlePagination,
    handleSort,
    sortField,
    sortOrder,
    loading,
    items,
    start,
    filters,
    refresh,
    isInternal,
    addToDealership,
}) {
    const [createLoading, setCreateLoading] = useState(false);
    const { data: roles, loading: loadingRoles } = useFetch({
        fetchAction: getRoles,
        autoFetch: true,
    });
    const { data: tags, loading: loadingTags } = useFetch({
        fetchAction: getTags,
        autoFetch: true,
    });

    const selectRolesDropdown = () => (
        <DropdownFilter
            optionLabel="name"
            filterBy="name"
            name="role"
            options={Object.values(roles)?.map?.((elm) => ({
                name: elm.name,
                key: elm.id,
            }))}
            onChange={handleFilter}
        />
    );

    const selectTagsDropdown = () => (
        <MultiSelectFilter
            name="tags"
            display="chip"
            optionLabel="name"
            optionValue="slug"
            options={Object.values(tags)
                ?.filter((tag) => tag?.category === 'typologie_lead')
                ?.map?.((elm) => ({
                    ...elm,
                    name: elm.name,
                    key: elm.id,
                }))}
            pending
            valueHandler={(value) => (value?.length > 0 ? `typologie_lead:${value.join(',')}` : '')}
            onChange={handleFilter}
            onPendingChange={updatePendingFilters}
            onRelease={releasePendingFilters}
        />
    );

    const nameBodyTemplate = (_user) => (
        <>
            {_user?.first_name || ''}
            {_user?.first_name && _user?.last_name && ' '}
            {_user?.last_name || ''}
        </>
    );

    const activeBodyTemplate = (_user) =>
        _user.active ? (
            <div className="label label-light-success label-inline label-lg">
                {Intl.formatMessage({ id: 'AUTOUSER.USERS.VIEW.ACTIVE' })}
            </div>
        ) : (
            <div className="label label-light-danger label-inline label-lg">
                {Intl.formatMessage({ id: 'AUTOUSER.USERS.VIEW.DISABLE' })}
            </div>
        );

    const roleBodyTemplate = (_user) => _user?.role?.name || _user?.role;

    const emailBodyTemplate = (_user) => _user.email;

    const externalBodyTemplate = (_user) =>
        _user.external ? (
            <div className="label label-light-danger label-inline label-lg">
                {Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.EXTERNAL' })}
            </div>
        ) : (
            <div className="label label-light-primary label-inline label-lg">
                {Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.INTERNAL' })}
            </div>
        );

    const phoneBodyTemplate = (_user) => {
        if (_user.phone && _user.cell_phone) {
            return (
                <>
                    <i className="pi pi-comment" />
                    {_user.phone}
                    <br />
                    <i className="pi pi-mobile" />
                    {_user.cell_phone}
                </>
            );
        }
        if (_user.phone) {
            return (
                <>
                    <i className="pi pi-comment" />
                    {_user.phone}
                </>
            );
        }
        if (_user.cell_phone) {
            return (
                <>
                    <i className="pi pi-mobile" />
                    {_user.cell_phone}
                </>
            );
        }
    };

    const perimeterBodyTemplate = (_user) => (
        <div className="d-flex align-items-end justify-content-between">
            <div>
                {_user?.dealership_perimeter?.slice?.(0, 2)?.map?.((perimeter) => (
                    <div className="label m-1 label-light-secondary label-inline label-lg">{perimeter?.name}</div>
                ))}
            </div>
            {_user?.dealership_perimeter?.length > 2 && (
                <div className="label m-1 label-light-secondary label-inline label-lg">
                    {Number(_user?.dealership_perimeter?.length) - 2}
                </div>
            )}
        </div>
    );

    const tagsBodyTemplate = (_user) =>
        _user?.tags?.typologie_lead?.map?.((tag) => {
            const tagData = tags?.find?.((t) => t?.slug === tag);
            return (
                <div className="label m-1 label-primary label-inline label-lg">{tagData?.name?.toUpperCase?.()}</div>
            );
        });

    const actionsBodyTemplate = (_user) => (
        <ActionsButtons
            _user={_user}
            setCreateLoading={setCreateLoading}
            refresh={refresh}
            addToDealership={addToDealership}
        />
    );

    const selectFilter = (name, options) => (
        <SelectFilter value={filters?.[name]?.value} name={name} options={options} onChange={handleFilter} />
    );

    const cols = [
        {
            field: 'first_name',
            header: Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.FIRST_NAME' }),
            body: nameBodyTemplate,
        },
        {
            field: 'active',
            header: Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.ACTIVE' }),
            body: activeBodyTemplate,
            filter: true,
            filterElement: selectFilter(
                'active',
                <>
                    <option value="">Tous les statuts</option>
                    <option value>Actif</option>
                    <option value={false}>Inactif</option>
                </>,
            ),
        },
        {
            field: 'role',
            header: Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.ROLE' }),
            body: roleBodyTemplate,
            filter: true,
            filterElement: selectRolesDropdown(),
        },
        {
            field: 'external',
            header: Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.INTERNAL_EXTERNAL' }),
            style: { textAlign: 'center' },
            filter: true,
            filterElement: selectFilter(
                'external',
                <>
                    <option value="">Interne/Externe</option>
                    <option value="false">Interne</option>
                    <option value="true">Externe</option>
                </>,
            ),
            body: externalBodyTemplate,
        },
        {
            field: 'email',
            header: Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.EMAIL' }),
            body: emailBodyTemplate,
        },
        {
            field: 'phone',
            header: Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.PHONE' }),
            body: phoneBodyTemplate,
        },
        {
            field: 'perimeter',
            header: Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.PERIMETER' }),
            body: perimeterBodyTemplate,
        },
        {
            field: 'tags',
            header: Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.TAGS' }),
            body: tagsBodyTemplate,
            filter: true,
            filterElement: selectTagsDropdown(),
        },
        {
            field: 'actions',
            header: Intl.formatMessage({ id: 'AUTOUSER.USERS.TABLE.HEAD.ACTIONS' }),
            body: actionsBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <HoCDataTable
            className="users-datatable"
            value={users}
            totalRecords={totalRecords}
            lazy
            {...(!isInternal && {
                paginator: true,
                paginatorTemplate:
                    'RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport',
                currentPageReportTemplate: Intl.messages['DATATABLE.REPORT_TEMPLATE'],
            })}
            loading={[loading, createLoading, loadingRoles, loadingTags].some((load) => load)}
            onFilter={handleFilter}
            onPage={handlePagination}
            onSort={handleSort}
            rows={items}
            first={start}
            sortField={sortField}
            sortOrder={sortOrder}
            removableSort
            filterDisplay="row"
            rowsPerPageOptions={[25, 50, 100]}
            emptyMessage={Intl.messages['DATATABLE.EMPTY_RESULT']}
        >
            {dynamicColumns}
        </HoCDataTable>
    );
}

export default withRouter(UsersDataTable);
