import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { RootState } from '@app/store/rootDuck';

import type { AppDispatch } from '../store/store';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useThunkDispatch: () => ThunkDispatch<RootState, unknown, AnyAction> = useDispatch;
