import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import usePermissions from '@app/hooks/usePermissions';
import './rollbackUpdateForm.scss';

const RollbackUpdateForm = ({
    customerFieldsChange,
    updateType = 'update_contact',
    actualDataLabel,
    previousDataLabel,
}) => {
    const { hasPermission } = usePermissions();
    const { watch, setValue, register } = useFormContext();
    const formUpdated = watch();
    const [selectedFields, setSelectedFields] = useState({});
    const Intl = useIntl();

    // Set the default selected state for each field to true for old values when the component mounts
    useEffect(() => {
        const initialSelectedFields = {};
        if (customerFieldsChange) {
            Object.keys(customerFieldsChange).forEach((key) => {
                const fieldKey = `${customerFieldsChange[key]?.field}_toggle`;
                initialSelectedFields[fieldKey] = 'old';
                setValue(fieldKey, false);
            });
            setSelectedFields(initialSelectedFields);
        }
    }, [customerFieldsChange, setValue]);

    const reformatValue = (value) => {
        switch (value) {
            case 'true':
                return Intl.formatMessage({ id: 'CUSTOMERS.MODAL.ROLLBACK.CONSENT.OK' });
            case 'false':
                return Intl.formatMessage({ id: 'CUSTOMERS.MODAL.ROLLBACK.CONSENT.KO' });
            default:
                return value;
        }
    };

    const handleClick = (key) => {
        const fieldKey = `${customerFieldsChange[key]?.field}`;
        const toggleFieldKey = `${fieldKey}_toggle`;
        const newValue = !formUpdated[toggleFieldKey];
        setValue(toggleFieldKey, newValue); // update the form state

        // Update selectedFields state to toggle between old and new values
        setSelectedFields((prev) => ({
            ...prev,
            [toggleFieldKey]: prev[toggleFieldKey] === 'old' ? 'new' : 'old',
        }));
    };

    const getFormatValue = (key, value) => {
        if (!['', 'NULL'].includes(reformatValue(customerFieldsChange[key]?.[`${value}_value`]))) {
            return reformatValue(customerFieldsChange[key]?.[`${value}_value`]);
        } else if (value === 'new' && updateType !== 'update_contact') {
            Intl.formatMessage({ id: 'CUSTOMERS.MODAL.ROLLBACK.CONSENT.NULL' });
        }
        return Intl.formatMessage({ id: 'CUSTOMER.VIEW.ROLLBACK.NO.DATA' });
    };

    const renderBadge = (key, value, selectedField) => (
        <Badge
            pill
            className={`rollbackUpdateForm__cursor ${
                selectedFields[selectedField] === value
                    ? 'rollbackUpdateForm__selected'
                    : 'rollbackUpdateForm__unSelected'
            }`}
            onClick={() => hasPermission('LEAD_RESTORE_UPDATE_CONTACT') && handleClick(key)}
            {...register(selectedField)}
        >
            <span className="rollbackUpdateForm__wrap">{getFormatValue(key, value)}</span>
        </Badge>
    );

    return (
        <form>
            <div className="rollbackUpdateForm__grid-container">
                <div className="rollbackUpdateForm__data-header">
                    <div className="rollbackUpdateForm__label-column" />
                    <div className="rollbackUpdateForm__current-data-column">
                        <h5 className="font-weight-bold mb-5">{actualDataLabel}</h5>
                    </div>
                    <div className="rollbackUpdateForm__new-data-column">
                        <h5 className="font-weight-bold mb-5">{previousDataLabel}</h5>
                    </div>
                </div>
                {customerFieldsChange &&
                    Object.keys(customerFieldsChange).map((key) => {
                        const fieldKey = `${customerFieldsChange[key]?.field}_toggle`;
                        return (
                            <div className="rollbackUpdateForm__data-row" key={fieldKey}>
                                <div className="rollbackUpdateForm__label-column">
                                    {!customerFieldsChange[key]?.label
                                        ? Intl.formatMessage({
                                              id: `CUSTOMERS.MODAL.CREATE.${customerFieldsChange[
                                                  key
                                              ]?.field.toUpperCase()}`,
                                          })
                                        : customerFieldsChange[key]?.label}
                                </div>
                                <div className="rollbackUpdateForm__current-data-column">
                                    {renderBadge(key, 'old', fieldKey)}
                                </div>
                                <div className="rollbackUpdateForm__new-data-column">
                                    {renderBadge(key, 'new', fieldKey)}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </form>
    );
};

export default RollbackUpdateForm;
