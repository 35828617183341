import { AutoComplete } from 'primereact/autocomplete';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import useFetch from '@app/hooks/useFetch';

import { userIsAssociateToDealership } from '@app/helpers/UserHelper';

import { getStock } from '@app/crud/customers/stock.crud';

import ErrorForm from '@app/partials/layout/ErrorForm';

const LeadWonLicenseNumber = ({ type, vehicles, params }) => {
    const { register, setValue, control, trigger, errors } = useFormContext();
    const Intl = useIntl();
    const { fetch, data } = useFetch({
        fetchAction: getStock,
        resultInterceptor: (response) => {
            const result = response?.result;

            if (result?.vehicles) {
                result.vehicles = result.vehicles.filter((vehicle) => userIsAssociateToDealership(vehicle?.dealer_id));
            }

            return result;
        },
    });

    const vehicleItemTemplate = (vehicle) => `${vehicle.brand} ${vehicle.model} ${vehicle.version}`;

    if (!type) return null;

    return (
        <Form.Group>
            <Form.Label>
                {type === 'VN' ? (
                    <FormattedHTMLMessage id="CUSTOMERS.MODAL.LEAD_WON.VIN" />
                ) : (
                    <FormattedHTMLMessage id="CUSTOMERS.MODAL.LEAD_WON.REGISTRATION" />
                )}
            </Form.Label>
            {vehicles?.length ? (
                <Form.Control
                    as="select"
                    name={type === 'VN' ? 'serialNumber' : 'licenseNumber'}
                    ref={register({
                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    })}
                    disabled={!!params?.event}
                    onChange={(e) => {
                        const { selectedIndex } = e.target;
                        const sku = e.target[selectedIndex].getAttribute('data-sku');
                        setValue('vehicleInternalId', sku);
                    }}
                    defaultValue=""
                >
                    <option value="">
                        {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LEAD_WON.REGISTRATION.PLACEHOLDER' })}
                    </option>
                    {vehicles.map(({ vehicle }) => {
                        const param = type === 'VN' ? 'serialNumber' : 'registration';

                        return (
                            <option
                                key={vehicle.vehicleInternalId}
                                data-sku={vehicle.vehicleInternalId}
                                value={vehicle[param]}
                            >
                                {`${vehicle.make} ${vehicle.model} ${vehicle.version} (${vehicle[param]})`}
                            </option>
                        );
                    })}
                </Form.Control>
            ) : (
                <Controller
                    name={type === 'VN' ? 'serialNumber' : 'licenseNumber'}
                    control={control}
                    rules={{
                        required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    }}
                    defaultValue={[]}
                    render={(props) => (
                        <AutoComplete
                            value={props.value}
                            onChange={(e) => {
                                setValue('vehicleInternalId', '');
                                props.onChange(e.value);
                            }}
                            disabled={!!params?.event}
                            suggestions={data?.vehicles || null}
                            completeMethod={(e) => {
                                const param = type === 'VN' ? 'serial_number' : 'license_number';

                                fetch({
                                    [param]: e.query,
                                });
                            }}
                            onSelect={(e) => {
                                const param = type === 'VN' ? 'serial_number' : 'license_number';

                                setValue('vehicleInternalId', e.value.sku);
                                props.onChange(e.value[param]);
                                trigger('vehicleInternalId');
                            }}
                            itemTemplate={vehicleItemTemplate}
                            selectedItemTemplate={(vehicle) => {
                                const param = type === 'VN' ? 'serial_number' : 'license_number';

                                return vehicle[param];
                            }}
                            className="w-100"
                            inputClassName={`form-control ${errors.vehicleInternalId ? 'is-invalid' : ''}`}
                        />
                    )}
                />
            )}
            <ErrorForm errors={errors} name={type === 'VN' ? 'serialNumber' : 'licenseNumber'} />
        </Form.Group>
    );
};

export default LeadWonLicenseNumber;
