import React, { useEffect, useState } from 'react';
import { Button, Card, Tab, Tabs } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getSecurywebContracts } from '@app/crud/securyweb/securyweb.crud';
import { StatusContractSlug } from '@app/crud/securyweb/securyweb.types';

import { useTableHandlers } from '@app/hooks';
import { StatusMapper } from '@app/pages/securyweb/Helpers/SecurywebHelper';
import SecurywebContractsDataTable from '@app/pages/securyweb/SecurywebContractsDataTable';
import ROUTES from '@app/router/Routes';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import Loader from '@app/partials/content/Loader';

const SecuryWebContracts = () => {
    const FILTER_STORE_NAME = 'securycar_contract';
    const [activeTab, setActiveTab] = useState<string>(StatusContractSlug.TO_SIGN);
    const Intl = useIntl();

    const { items, start, filters } = useFilters({
        name: FILTER_STORE_NAME,
    });

    const { data, loading, handleFilter, handlePagination } = useTableHandlers({
        filterStore: FILTER_STORE_NAME,
        fetchAction: getSecurywebContracts,
        withStore: false,
        paramsInterceptor: (params, interceptorFilters) => {
            const { contractStatus } = interceptorFilters?.filters || {};
            if (!contractStatus) return;

            return {
                ...params,
                statuts: [contractStatus],
                numeroPage: interceptorFilters.page,
                contratsParPage: interceptorFilters.items,
            };
        },
    });
    const { contrats: contracts = [], totalContrats: totalContracts = 0 } = data?.result || {};

    useEffect(() => {
        handleFilter({
            contractStatus: activeTab,
        });
    }, [handleFilter, activeTab]);

    const tabs = Object.entries(StatusMapper).map(([key, { labelId }]) => ({
        key,
        label: Intl.formatMessage({ id: labelId }),
    }));

    return (
        <FiltersProvider value={filters}>
            <Card className="p-4 position-relative">
                {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                <div className="btn-legend d-flex flex-wrap align-items-center justify-content-between">
                    <Link to={routeTo(ROUTES.CUSTOMERS.PATH)}>
                        <Button variant="primary" className="mr-4">
                            <FormattedMessage id="SECURYCAR.CONTRACT.BUTTON.CREATE" />
                        </Button>
                    </Link>
                </div>

                <Tabs
                    className="nav-tabs-line nav-tabs-line-primary nav-tabs-line-2x"
                    activeKey={activeTab}
                    id={activeTab}
                    onSelect={(e: string) => setActiveTab(e)}
                >
                    {contracts &&
                        tabs.map((tab) => (
                            <Tab
                                tabClassName="px-4 font-weight-bold"
                                eventKey={tab.key}
                                title={tab.label}
                                key={`${tab.label}-${tab.key}`}
                            >
                                {activeTab === tab.key && (
                                    <SecurywebContractsDataTable
                                        items={items}
                                        start={start}
                                        filters={filters}
                                        onStatusChange={(newStatus: StatusContractSlug) => setActiveTab(newStatus)}
                                        data={{ contracts, counter: tabs, totalContracts }}
                                        loading={loading}
                                        handleFilter={handleFilter}
                                        handlePagination={handlePagination}
                                    />
                                )}
                            </Tab>
                        ))}
                </Tabs>
            </Card>
        </FiltersProvider>
    );
};

export default SecuryWebContracts;
