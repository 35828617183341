import { useIntl } from 'react-intl';

import { CustomerEvent } from '@app/crud/customers/customer.type';

interface EventTypeFunctions {
    [key: string]: (event?: CustomerEvent) => string | JSX.Element;
}
const useEventNameTemplate = () => {
    const Intl = useIntl();

    const eventNameTemplate: EventTypeFunctions = {
        DATASTORE_FUNDING: () => Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.FUNDING.VEHICLE' }),
        DCSNET_SALE_PRODUCT: (event) => `${event?.eventName}` || '-',
        ICAR_SALE_PRODUCT: (event) => `${event?.eventName}` || '-',
        BCS_AUDIT_TSCANS: () => Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.TSCAN.AUDIT' }),
        MECAPLANNING_AFTER_SALES_APPOINTMENT: () =>
            Intl.formatMessage({
                id: 'CUSTOMER.VIEW.LIFE.DETAIL.MECAPLANNING.TYPE',
            }),
        CIRANO_WARRANTY_CONTRACT: (event) =>
            `${event?.eventName} ${Intl.formatMessage({ id: 'TRANSLATOR.VO' })}` || '-',
        PLANET_VO_PURCHASE_ORDER_PRODUCT: (event) => event?.eventName || '-',
        BCS_AUDIT_FOLLOWUP: (event) => event?.eventName || '-',
        BCS_AUDIT_RESUME_FB: (event) => event?.eventName || '-',
        BCS_AUDIT_RESUME_OTHER: (event) => event?.eventName || '-',
        SALEFORCE_AUTOSPHERE_SALEFORCE_CONSENT: (event) => event?.eventName || '-',
        SALEFORCE_DEALERSHIP_SALEFORCE_CONSENT: (event) => event?.eventName || '-',
        SECURYCAR_SC_TERMINATION: (event) => event?.eventName || '-',
        SECURYCAR_SC_SERVICE_RESUME: (event) => event?.eventName || '-',
        SECURYCAR_SC_RENEWAL: (event) => event?.eventName || '-',
        SECURYCAR_SC_SERVICE_FOLLOWUP: (event) => event?.eventName || '-',
        SECURYCAR_SC_DISASTER: (event) => event?.eventName || '-',
        ICARE_MAINTENANCE_CONTRACT: (event) => event?.eventName || '-',
        ICAR_TECHNICAL_INTERVENTION: (event) => event?.eventName || '-',
        ICAR_COMMERCIAL_PROPOSITION_PRODUCT: (event) => event?.eventName || '-',
        ICAR_PURCHASE_ORDER_PRODUCT: (event) => event?.eventName || '-',
        AUTOSPHERE_FR_TECHNICAL_INTERVENTION_APPOINTMENT_CANCELED: (event) => event?.eventName || '-',
        AUTOSPHERE_FR_PART_EXCHANGE_ESTIMATE: (event) => event?.eventName || '-',
        AUTOSPHERE_FR_TECHNICAL_INTERVENTION_APPOINTMENT: (event) => event?.eventName || '-',
        AUTOSPHERE_FR_AFR_SUBSCRIBER: (event) => event?.eventName || '-',
        AUTOSPHERE_FR_PART_EXCHANGE_ESTIMATE_FOLLOWUP: (event) => event?.eventName || '-',
        AUTOSPHERE_FR_PART_EXCHANGE_RESUME: (event) => event?.eventName || '-',
        AUTOLEAD_LEAD: (event) => event?.eventName || '-',
        AUTOLEAD_LEAD_CLOSING: (event) => event?.eventName || '-',
        AUTOLEAD_LEAD_FOLLOWUP: (event) => event?.eventName || '-',
        AUTOLEAD_LEAD_RESUME: (event) => event?.eventName || '-',
        AUTODELIVERY_ORDERS_WEB: (event) => event?.eventName || '-',
        AUTODELIVERY_ORDERS_WEB_FOLLOWUP: (event) => event?.eventName || '-',
        AUTODELIVERY_ORDERS_WEB_RESUME: (event) => event?.eventName || '-',
        SIV_REGISTRATION: (event) => event?.eventName || '-',
        DCSNET_TECHNICAL_INTERVENTION: (event) => event?.eventName || '-',
        ADICTIZ_JEU_FESTIVAL_AUTOSPHERE: (event) => event?.eventName || '-',
        OPTEVEN_WARRANTY_CONTRACT_OPTEVEN: () =>
            Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.OPTEVEN.PRODUCT.CONTRACT' }),
        PLANET_VO2_COMMERCIAL_PROPOSAL: (event) => event?.eventName || '-',
    };

    // Function to retrieve the EventName template
    const getEventNameTemplate = (event: CustomerEvent) => {
        const eventType = `${event?.srcApplicationName}_${event?.processType}`;
        const templateFunction = eventNameTemplate[eventType] || (() => event?.leadTypeName || '-');

        return templateFunction(event);
    };
    return getEventNameTemplate;
};

export default useEventNameTemplate;
