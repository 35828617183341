import ApiGateway from '@app/helpers/AxiosHelper';
import { getFormData } from '@app/helpers/ObjectHelper';

const { REACT_APP_APV_SC_URL } = process.env;

export function getPackages(params) {
    return ApiGateway.get(`${REACT_APP_APV_SC_URL}/api/package`, {
        params,
    });
}

export function getPackage(id) {
    return ApiGateway.get(`${REACT_APP_APV_SC_URL}/api/package/${id}`);
}

export function getCategories() {
    return ApiGateway.get(`${REACT_APP_APV_SC_URL}/api/package-category`);
}

export function addPackage(data) {
    return ApiGateway.post(`${REACT_APP_APV_SC_URL}/api/package`, getFormData(data));
}

export function updatePackageById(id, data) {
    return ApiGateway.post(`${REACT_APP_APV_SC_URL}/api/package/${id}`, getFormData(data));
}

export function deletePackage(id) {
    return ApiGateway.delete(`${REACT_APP_APV_SC_URL}/api/package/${id}`);
}
