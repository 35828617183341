import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Vehicle } from '@app/store/stock/stock.type';

import Label from '@app/partials/content/Label';
import OverlayTooltip from '@app/partials/content/OverlayTooltip';

type UnPublishedLabelProps = {
    variant?: string;
    vehicle: Vehicle;
};

const UnPublishedLabel = ({ variant = 'danger', vehicle }: UnPublishedLabelProps) => {
    const Intl = useIntl();

    return (
        <OverlayTooltip
            label={`${Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.NO_PUBLICATION_TOOLTIP' })}
                    ${
                        Number.isInteger(vehicle.price) && vehicle.price === 0
                            ? ` - ${Intl.formatMessage({
                                  id: 'STOCK_VEHICLES.TABLE.NO_PUBLICATION_ZERO_PRICE_TOOLTIP',
                              })}`
                            : ''
                    }`}
            id="publication"
            translate={false}
        >
            <Label variant={variant} className="font-weight-bold">
                <FormattedMessage id="STOCK_VEHICLES.TABLE.NOT_PUBLISHED" />
            </Label>
        </OverlayTooltip>
    );
};

UnPublishedLabel.defaultProps = {
    variant: 'danger',
};

export default UnPublishedLabel;
