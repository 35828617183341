import {useCallback, useEffect, useState} from 'react';

import { ucwords } from '@app/helpers/StringHelper';

import {Locale} from "../../_metronic/i18n/I18nProvider";

const useLocales = () => {
    const [locales, setLocales] = useState(null);

    const getLocaleByKey = useCallback((search) => {
        if (!locales) return null;

        return Object.values(locales).find((locale) => locale.key === search) || null;
    }, [locales]);

    const getLocales = useCallback(async (locale) => import(`@formatjs/intl-displaynames/dist/locale-data/${locale}.json`)
            .then((response) => {
                let localesResponse = response?.default?.data[locale]?.types?.language?.long || null;

                if (localesResponse) {
                    localesResponse = Object.entries(localesResponse).reduce((acc, [locale, name]) => {
                        acc[locale] = {
                            key: locale,
                            name: ucwords(name),
                        }
                        return acc;
                    }, {});
                }

                setLocales(localesResponse);
            }), [setLocales]);

    useEffect(() => {
        if (!locales) {
            getLocales(Locale)
                .catch(() => {
                    // Fallback
                    getLocales('fr');
                })
        }
    }, [getLocales, locales]);

    return {
        locales,
        getLocaleByKey
    };
};

export default useLocales;
