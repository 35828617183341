import { isNil } from 'lodash';
import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

import { Intl } from '../../../../../_metronic/i18n/I18nProvider';

function QuotationEditingResume({ quotation, displayUi, complete, acceptedQuotations, totalPrice }) {
    return (
        <Row className="mt-8">
            <Col lg={12}>
                <>
                    <Card.Header className="border-bottom-0 smart-repair-card--border-bottom">
                        <i className="card__icon fas fa-tasks" />
                        {Intl.formatMessage(
                            { id: 'SMART.REPAIR.QUOTATION.HEAD.DETAILS' },
                            { ref: quotation?.reference ? quotation?.reference : '' },
                        )}
                    </Card.Header>
                    {displayUi <= 0 && !complete && (
                        <p className="p-7 text-danger">
                            {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.INCOMPATIBLE.MESSAGE' })}
                        </p>
                    )}
                    <Card.Body className="p-0">
                        <Table className="m-0" borderless hover>
                            <thead>
                                <tr>
                                    <th width="10%">
                                        {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.TABLE.HEAD.REF' })}
                                    </th>
                                    <th width="70%">
                                        {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.TABLE.HEAD.REPAIR.NAME' })}
                                    </th>
                                    <th width="20%">
                                        {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.TABLE.HEAD.PRICE.TTC' })}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {acceptedQuotations &&
                                    Object.entries(acceptedQuotations).map(([reference, items]) => (
                                        <React.Fragment key={reference}>
                                            <tr className="smart-repair-details__table-header">
                                                <th>{items[0].reference}</th>
                                                <th>{items[0].zoneName}</th>
                                                <th>
                                                    {items[0].compatible && items[0].totalPackagesPrice && (
                                                        <NumberFormat
                                                            value={items[0].totalPackagesPrice}
                                                            thousandSeparator=" "
                                                            decimalSeparator=","
                                                            decimalScale="2"
                                                            fixedDecimalScale
                                                            displayType="text"
                                                            suffix=" €"
                                                        />
                                                    )}
                                                    {!items[0].compatible && (
                                                        <span className="text-danger">
                                                            {Intl.formatMessage({
                                                                id: 'SMART.REPAIR.QUOTATION.INCOMPATIBLE',
                                                            })}
                                                        </span>
                                                    )}
                                                    {isNil(items[0].compatible) && (
                                                        <span className="text-danger"> - </span>
                                                    )}
                                                </th>
                                            </tr>
                                            {items.map((item) => (
                                                <tr key={item.slug}>
                                                    <td> </td>
                                                    <td>{item.package}</td>
                                                    {item.compatible && (
                                                        <td>
                                                            {item.priceTtc ? (
                                                                <NumberFormat
                                                                    value={item.priceTtc}
                                                                    thousandSeparator=" "
                                                                    decimalSeparator=","
                                                                    decimalScale="2"
                                                                    fixedDecimalScale
                                                                    displayType="text"
                                                                    suffix=" €"
                                                                />
                                                            ) : (
                                                                <span className="text-danger">
                                                                    {Intl.formatMessage({
                                                                        id: 'SMART.REPAIR.QUOTATION.INCOMPATIBLE',
                                                                    })}
                                                                </span>
                                                            )}
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    ))}
                            </tbody>

                            <tfoot className="tfoot tfoot--total">
                                <tr>
                                    <td> </td>
                                    <td className="font-weight-bold">
                                        <div className="tfoot__bordered tfoot__float-right tfoot__total">
                                            {Intl.formatMessage({
                                                id: 'SMART.REPAIR.QUOTATION.TABLE.HEAD.FINAL_PRICE',
                                            })}
                                            :
                                        </div>
                                    </td>
                                    <td className="font-weight-bold">
                                        <div className="tfoot__bordered tfoot__number">
                                            {totalPrice > 0 && (
                                                <NumberFormat
                                                    value={totalPrice}
                                                    thousandSeparator=" "
                                                    decimalSeparator=","
                                                    decimalScale="2"
                                                    fixedDecimalScale
                                                    displayType="text"
                                                    suffix=" €"
                                                />
                                            )}
                                            {totalPrice <= 0 && (
                                                <span className="text-danger">
                                                    {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.INCOMPATIBLE' })}
                                                </span>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>
                    </Card.Body>
                </>
            </Col>
        </Row>
    );
}

export default QuotationEditingResume;
