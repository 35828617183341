import React, { useContext, useEffect } from 'react';

import useFetch from '@app/hooks/useFetch';

import { getDashboardCrewActivityForDirector } from '@app/crud/dashboard/dashboard.crud';

import { DashboardContext } from '@app/pages/dashboard/DashboardProvider';
import DashboardActivityDirector from '@app/pages/dashboard/partials/Activity/DashboardActivityDirector';
import DashboardActivityStock from '@app/pages/dashboard/partials/Activity/DashboardActivityStock';
import DashboardPendingCounters from '@app/pages/dashboard/partials/DashboardPendingCounters';
import DashboardQlik from '@app/pages/dashboard/partials/Stats/DashboardQlik';

import Permission from '@app/partials/content/Permission';

import './_dashboardDirector.scss';

const DashboardDirector = () => {
    const { overriddenParams } = useContext(DashboardContext);
    const { data, loading, fetch } = useFetch({
        fetchAction: getDashboardCrewActivityForDirector,
    });

    useEffect(() => {
        if (overriddenParams) {
            fetch({ params: overriddenParams }).then();
        }
    }, [overriddenParams]);

    return (
        <div className="dashboard-role d-flex flex-column">
            <div className="dashboard-actions h-100 d-flex flex-column">
                {loading ? (
                    <>
                        <DashboardPendingCounters.Skeleton />
                        <DashboardActivityDirector.Skeleton />
                    </>
                ) : (
                    <>
                        <DashboardPendingCounters className="font-size-lg" />
                        <DashboardActivityDirector data={data} className="w-100 mt-8 flex-grow-1" />
                    </>
                )}
            </div>
            <Permission permissions={['STOCK_LIST_VEHICLES']}>
                <DashboardActivityStock />
            </Permission>
            <DashboardQlik className="w-100 mt-8" />
        </div>
    );
};

export default DashboardDirector;
