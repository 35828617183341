import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getCivility, getCustomerGdprs } from '../Helpers/Customer';

import Consent from './Consent';

const SidebarConsent = ({ updateCustomerCallback }) => {
    const { data: customer } = useSelector((state) => state.customer.customer);
    const { userData } = useSelector((state) => state.auth);
    const [selectedDealershipConsent, setSelectedDealershipConsent] = useState();
    const Intl = useIntl();

    const updateConsents = (data) => {
        updateCustomerCallback({ ...customer, ...data });
    };

    const consentsTemplate = (consents) => {
        if (consents[0]?.refDealership && consents?.length > 5) {
            if (!selectedDealershipConsent) {
                setSelectedDealershipConsent(consents[0]?.name);
            }
            return (
                <>
                    <Dropdown
                        value={selectedDealershipConsent}
                        options={consents}
                        onChange={(e) => setSelectedDealershipConsent(e.value)}
                        optionLabel="name"
                        optionValue="name"
                        className="mt-6"
                    />

                    {selectedDealershipConsent && (
                        <Consent
                            consent={consents.find((consent) => selectedDealershipConsent === consent?.name)}
                            hasEmail={!!customer.email}
                            canUpdate={
                                Object.values(userData?.dealerships).find?.(
                                    (dealership) => dealership?.title === selectedDealershipConsent,
                                ) !== undefined
                            }
                            updateConsents={updateConsents}
                        />
                    )}
                </>
            );
        }
        return consents.map((consent) => {
            if (Array.isArray(consent)) {
                return consentsTemplate(consent);
            }
            return (
                <Consent
                    consent={consent}
                    key={consent?.name}
                    hasEmail={!!customer.email}
                    canUpdate={
                        Object.keys(userData?.dealerships).find?.(
                            (dealershipId) => dealershipId === consent.refDealership,
                        ) !== undefined
                    }
                    updateConsents={updateConsents}
                />
            );
        });
    };

    return (
        <div className="sidebar-consent">
            <div className="d-flex flex-column">
                <span className="font-weight-bold">
                    {getCivility(customer.civility)} {customer.firstname} {customer.lastname}
                </span>
                <span>
                    {Intl.formatMessage({
                        id: `CUSTOMER.VIEW.INFO.${customer?.isClient ? 'CLIENT' : 'PROSPECT'}.TITLE`,
                    })}
                </span>
            </div>
            <Divider className="p-divider-dashed" />
            <div className="consents">
                <span className="text-light-closed">
                    <i className="la la-bullhorn text-light-closed mr-2 la-lg" />
                    {Intl.formatMessage(
                        { id: 'CUSTOMER.SIDEBAR_CONSENT.CONSENTS' },
                        { value: customer?.consents?.length || 0 },
                    )}
                </span>
                {customer?.consents && !customer?.email && (
                    <div className="alert alert-danger font-size-base mt-2">
                        {Intl.formatMessage({ id: 'CUSTOMER.SIDEBAR_CONSENT.ALERT_EMAIL.USER' })}
                    </div>
                )}
                {customer?.consents && customer?.email && consentsTemplate(getCustomerGdprs(customer, true))}
            </div>
        </div>
    );
};

export default SidebarConsent;
