import classNames from 'classnames';
import { InputNumber } from 'primereact/inputnumber';
import React, { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext, Validate } from 'react-hook-form';
import { useIntl } from 'react-intl';

import ErrorForm from '@app/partials/layout/ErrorForm';

import './forminputnumber.scss';

interface FormInputNumberProps {
    label: ReactElement;
    name: string;
    required: boolean;
    isInvalid: boolean;
    placeholder?: string;
    format?: boolean;
    max?: number;
    min?: number;
    className?: string;
    validate?: Validate;
    disabled?: boolean;
    inputGroupBefore?: ReactElement;
    inputGroupAfter?: ReactElement;
}

const FormInputNumber = ({
    label,
    name,
    format,
    required,
    isInvalid,
    placeholder,
    className,
    validate,
    disabled,
    min,
    max,
    inputGroupBefore,
    inputGroupAfter,
}: FormInputNumberProps) => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { control, errors } = methods;

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Controller
                control={control}
                name={name}
                rules={{
                    required: required && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    validate,
                }}
                render={({ value, onChange }) => (
                    <div className="p-inputgroup p-inputnumbergroup flex-1">
                        {inputGroupBefore}
                        <InputNumber
                            value={value}
                            onChange={(e) => onChange(e.value)}
                            format={format}
                            min={min}
                            max={max}
                            locale={Intl.locale}
                            className={classNames(`${className}`, {
                                'is-invalid': isInvalid,
                            })}
                            placeholder={
                                placeholder
                                    ? Intl.formatMessage({
                                          id: placeholder,
                                      })
                                    : ''
                            }
                            disabled={disabled}
                        />
                        {inputGroupAfter}
                    </div>
                )}
            />
            <ErrorForm errors={errors} name={name} />
        </Form.Group>
    );
};

FormInputNumber.defaultProps = {
    placeholder: '',
    className: '',
    validate: null,
    disabled: false,
    format: false,
    inputGroupBefore: null,
    inputGroupAfter: null,
    min: null,
    max: null,
};

export default FormInputNumber;
