import React, { useEffect } from 'react';

import useFetch from '@app/hooks/useFetch';

import { getCampaign } from '@app/crud/campaigns/campaign.crud';

import CampaignInfos from '@app/pages/campaigns/partials/CampaignInfos';

const EventCampaignInfo = ({ event }) => {
    const { informations } = event;
    const { fetch, data } = useFetch({
        fetchAction: getCampaign,
        resultInterceptor: (response) => response,
    });

    useEffect(() => {
        fetch(informations?.campaignId);
    }, [informations]);

    const { result: campaignDetails, statistics } = data;

    return <CampaignInfos campaign={campaignDetails} totalCount={statistics?.globalContact} editable={false} />;
};

export default EventCampaignInfo;
