import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { LayoutContextConsumer } from '@src/_metronic';
import { Intl } from '@src/_metronic/i18n/I18nProvider';
import BreadCrumbs from '@src/_metronic/layout/sub-header/components/BreadCrumbs';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';

import subHeader from '@app/partials/HoC/subHeader';

function StockVehicleSubHeader({ subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle }) {
    const { data: vehicle, loading } = useSelector((state) => state.stock.vehicle);

    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: 'MENU.STOCK.VEHICLES' }),
        },
        {
            title: vehicle?.brand_model || '',
        },
    ];

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            type="button"
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}
                    {!loading && (
                        <LayoutContextConsumer>
                            {() => (
                                <>
                                    <h3 className="kt-subheader__title">
                                        {Intl.formatMessage({ id: 'MENU.STOCK' })} {vehicle?.type}
                                    </h3>
                                    <Link to={routeTo(ROUTES.STOCK_VEHICLES.PATH)}>
                                        <BreadCrumbs items={breadcrumb} />
                                    </Link>
                                </>
                            )}
                        </LayoutContextConsumer>
                    )}
                </div>
            </div>
        </div>
    );
}

export default subHeader(StockVehicleSubHeader);
