import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import { getActions } from '@app/pages/customers/Helpers/Actions';
import useAction from '@app/pages/customers/Hooks/useAction';
import useLead from '@app/pages/customers/Hooks/useLead';
import useTags from '@app/pages/customers/Hooks/useTags';
import TagsList from '@app/pages/customers/Partials/TagsList';
import { actions } from '@app/store/modal/modal.store';

import ActionButton from '@app/partials/content/ActionButton';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

const Consign = ({ showModal, setShowModal }) => {
    const dispatch = useDispatch();
    const Intl = useIntl();
    const [selectedAction, setSelectedAction] = useState(undefined);
    const { register, handleSubmit, setValue } = useForm();
    const { params } = useSelector((state) => state.modal);
    const [buttonActions, setButtonActions] = useState([]);
    const lead = useLead(params?.leadId);
    const { loadingTags, tags, selectedTags, updateTagOnLead, linkTagToLead, unlinkTagToLead } = useTags({
        lead,
    });
    const [loading, createAction, setActionType] = useAction({
        success: (response) => {
            let modal = 'next_action';

            if (response.result.lead.leadStatus.slug === 'in_progress_appointment_confirmed') {
                modal = 'consign';
                setSelectedAction(undefined);
            }

            dispatch(
                actions.modalChange(modal, {
                    leadId: response.result.lead.id,
                }),
            );
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VISIT.ERROR' }),
            });
        },
    });

    const onSubmit = async (data) => {
        await updateTagOnLead();
        createAction({
            ...data,
            scheduleAppointmentId: lead.scheduleAppointment.id,
        });
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const cancelSwalHandler = ({ title, text, callback }) => {
        Swal.fire({
            title,
            text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
            cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
            customClass: {
                confirmButton: 'btn mr-3 btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
        }).then((result) => {
            if (result.isConfirmed) {
                callback();
            } else {
                setSelectedAction(undefined);
            }
        });
    };

    const handleClick = (action = undefined) => {
        setSelectedAction(action);

        switch (action) {
            case 'DONE_APPOINTMENT':
                setValue('result', "Le rendez-vous a bien eu lieu à la date et l'heure prévues.");
                setActionType('consign_appointment_done');
                break;
            case 'CONFIRM_APPOINTMENT':
                setValue('result', Intl.formatMessage({ id: 'CUSTOMERS.ACTIONS.CONFIRM_APPOINTMENT.COMMENT' }));
                setActionType('confirm_appointment');
                break;
            case 'UNCONFIRM_APPOINTMENT':
                setValue('result', Intl.formatMessage({ id: 'CUSTOMERS.ACTIONS.UNCONFIRM_APPOINTMENT' }));
                setActionType('consign_appointment_fail');
                break;
            case 'CONFIRM_CALL':
                setValue('result', "J'ai bien eu le client au téléphone. \nIl est intéressé par un véhicule.");
                setActionType('consign_call_done');
                break;
            case 'UNCONFIRM_CALL':
                setValue('result', Intl.formatMessage({ id: 'CUSTOMERS.ACTIONS.UNCONFIRM_CALL' }));
                setActionType('consign_call_fail');
                break;
            case 'UPDATE_APPOINTMENT':
                dispatch(
                    actions.modalChange('appointment', {
                        leadId: lead.id,
                        scheduleAppointmentId: lead.scheduleAppointment.id,
                    }),
                );
                break;
            case 'UPDATE_CALL':
                dispatch(
                    actions.modalChange('callback', {
                        leadId: lead.id,
                        scheduleAppointmentId: lead.scheduleAppointment.id,
                    }),
                );
                break;
            case 'CANCEL_CALL':
                setActionType('cancel_callback');
                cancelSwalHandler({
                    title: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CANCEL_CALLBACK.TITLE' }),
                    text: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CANCEL_CALLBACK.TEXT' }),
                    callback: () => {
                        createAction({
                            scheduleAppointmentId: lead.scheduleAppointment.id,
                        });
                    },
                });
                break;
            case 'CANCEL_APPOINTMENT':
                setActionType('cancel_appointment');
                cancelSwalHandler({
                    title: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CANCEL_APPOINTMENT.TITLE' }),
                    text: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CANCEL_APPOINTMENT.TEXT' }),
                    callback: () => {
                        createAction({
                            scheduleAppointmentId: lead.scheduleAppointment.id,
                        });
                    },
                });
                break;
            default:
                setValue('result', '');
                break;
        }
    };

    const getModalTitle = () => {
        if (lead?.leadStatus?.slug) {
            if (lead.leadStatus.slug.includes('appointment')) {
                return Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CONSIGN.APPOINTMENT.TITLE' });
            } else if (lead.leadStatus.slug.includes('relaunch')) {
                return Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CONSIGN.RELAUNCH.TITLE' });
            }
        }

        return '';
    };

    const getModalIcon = () => {
        if (lead?.leadStatus?.slug) {
            if (lead.leadStatus.slug.includes('appointment')) {
                return <i className="la la-2x text-primary la-calendar" />;
            } else if (lead.leadStatus.slug.includes('relaunch')) {
                return <i className="la la-2x text-primary la-phone" />;
            }
        }

        return '';
    };

    useEffect(() => {
        setSelectedAction(undefined);
    }, [showModal]);

    useEffect(() => {
        setButtonActions(getActions(lead?.nextActions?.primary ?? []));
    }, [setButtonActions, lead]);

    return (
        <ModalDefault
            show={showModal}
            loading={loading || loadingTags}
            hideModal={() => setShowModal(false)}
            icon={getModalIcon()}
            title={getModalTitle()}
            body={
                <>
                    <div className="d-flex flex-lg-nowrap flex-wrap justify-content-center mt-lg-4 mt-0 mb-10">
                        {buttonActions &&
                            buttonActions?.length &&
                            buttonActions.map(([action, properties], key) => (
                                <ActionButton
                                    {...properties}
                                    key={action}
                                    active={!selectedAction ? undefined : selectedAction === action}
                                    onClick={() => handleClick(action)}
                                    className={`mt-lg-0 mt-4 mx-lg-0 mx-2 flex-grow-1 px-4 ${
                                        key + 1 < buttonActions.length ? 'mr-lg-6' : ''
                                    }`}
                                />
                            ))}
                    </div>
                    <div className="mb-10">
                        <TagsList
                            lead={lead}
                            tags={tags}
                            selectedTags={selectedTags}
                            linkTagToLead={linkTagToLead}
                            unlinkTagToLead={unlinkTagToLead}
                        />
                    </div>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CONSIGN.RESULT' })}</Form.Label>
                        <Form.Control
                            as="textarea"
                            ref={register({
                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            })}
                            name="result"
                            rows={4}
                        />
                    </Form.Group>
                </>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                    </Button>
                    <Button variant="primary" disabled={!selectedAction} onClick={handleSubmit(onSubmit, onError)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default Consign;
