import dayjs from 'dayjs';

export const DEFAULT_WEEK = 0;
export const PLANNING = 1;

const daysMap = {
    '1': 'monday',
    '2': 'tuesday',
    '3': 'wednesday',
    '4': 'thursday',
    '5': 'friday',
    '6': 'saturday',
    '0': 'sunday',
};

/**
 * Determines if a planning event is older than current time
 * Precision unit: hour
 * @param day : date Object - the date of the clicked event
 * @param availabilities: {
 *  monday?: {am: {absent: boolean, start: string, end: string}, pm: {absent: boolean, start: string, end: string},
 *  tuesday?: {am: {absent: boolean, start: string, end: string}, pm: {absent: boolean, start: string, end: string},
 *  wendesday?: {am: {absent: boolean, start: string, end: string}, pm: {absent: boolean, start: string, end: string},
 *  thursday?: {am: {absent: boolean, start: string, end: string}, pm: {absent: boolean, start: string, end: string},
 *  friday?: {am: {absent: boolean, start: string, end: string}, pm: {absent: boolean, start: string, end: string},
 *  saturday?: {am: {absent: boolean, start: string, end: string}, pm: {absent: boolean, start: string, end: string},
 *  sunday?: {am: {absent: boolean, start: string, end: string}, pm: {absent: boolean, start: string, end: string},
 * }
 * @param hour : 'am' | 'pm'
 * @returns {boolean}
 */
export const isEventPast = (day, availabilities, hour) => {
    const selectedDay = daysMap[day.$d.getDay()];
    const lastHourOfEvent = parseInt(availabilities?.[selectedDay]?.[hour]?.end?.substring(0, 2), 10);
    if (lastHourOfEvent) day.$d.setHours(lastHourOfEvent);
    day.$d.setMinutes(0);

    const isInactive = !availabilities?.[selectedDay]?.[hour];
    if (isInactive) {
        return !(!dayjs().isAfter(day, 'date') && availabilities?.[selectedDay]?.[hour] === undefined);
    }
    return dayjs(day.$d).diff(dayjs(new Date())) < 0;
};

/**
 * Determines if any halfday already has a status ( === is part of availabilities object )
 * @param day : date Object
 * @param availabilities: {
 *  monday?: {am: {absent: boolean, start: string, end: string}, pm: {absent: boolean, start: string, end: string},
 *  tuesday?: {am: {absent: boolean, start: string, end: string}, pm: {absent: boolean, start: string, end: string},
 *  wendesday?: {am: {absent: boolean, start: string, end: string}, pm: {absent: boolean, start: string, end: string},
 *  thursday?: {am: {absent: boolean, start: string, end: string}, pm: {absent: boolean, start: string, end: string},
 *  friday?: {am: {absent: boolean, start: string, end: string}, pm: {absent: boolean, start: string, end: string},
 *  saturday?: {am: {absent: boolean, start: string, end: string}, pm: {absent: boolean, start: string, end: string},
 *  sunday?: {am: {absent: boolean, start: string, end: string}, pm: {absent: boolean, start: string, end: string},
 * }
 * @param hour : 'am' | 'pm'
 * @returns {boolean}
 */
export const hasInactiveStatus = (day, availabilities, hour) => {
    const selectedDay = daysMap[day.$d.getDay()];
    return !(
        selectedDay &&
        Object.prototype.hasOwnProperty.call(availabilities, selectedDay) &&
        availabilities[selectedDay][hour]
    );
};
