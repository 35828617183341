import React, {useEffect, useState} from "react";
import {Intl} from "../../../_metronic/i18n/I18nProvider";
import {Controller, useFormContext} from "react-hook-form";
import {CascadeSelect} from "primereact/cascadeselect";
import {withRouter} from "react-router-dom";
import {getPackagesTree} from "../../store/apv/package/package.store";

const defaultProps = {
    'disabled': false,
    'validate': true
}

function SelectPackage(props) {
    const { packages, packageId, disabled, validate } = {...defaultProps, ...props};

    const methods = useFormContext();
    const [_package, setPackage] = useState([]);
    const [_options, setOptions] = useState([]);

    useEffect(() => {
        if (packages) {
            const [options, selectedPackage] = getPackagesTree(packages, packageId);
            setPackage(selectedPackage);
            setOptions(options);
        }
    }, [packages, packageId])

    return (
        <Controller
            control={methods.control}
            name="packageId"
            rules={{
                validate: (value) => {
                    return !value && validate ? Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }) : true
                }
            }}
            render={(props) => (
                <CascadeSelect
                    value={_package}
                    options={_options}
                    optionLabel={"name"}
                    optionGroupLabel={"cname"}
                    optionGroupChildren={['package']}
                    className={`${methods.errors.packageId ? 'is-invalid' : ''}`}
                    style={{width: '100%'}}
                    placeholder={Intl.formatMessage({ id: "APV.OPERATION.FORM.SELECT.PACKAGE.PLACEHOLDER" })}
                    onChange={(e) => {
                        setPackage(e.value);
                        props.onChange(e.value.id);
                        methods.trigger('packageId');
                    }}
                    disabled={disabled}
                />
            )}
            />
    )
}

export default withRouter(SelectPackage);
