import React from 'react';
import { Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useAction from '@app/pages/customers/Hooks/useAction';
import useLead from '@app/pages/customers/Hooks/useLead';
import { LeadRow } from '@app/pages/customers/Partials/LeadRow';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

const UnlinkLead = ({ showModal, setShowModal }) => {
    const { params } = useSelector((state) => state.modal);
    const methods = useForm();
    const { control, handleSubmit } = methods;
    const lead = useLead(params?.parentId);
    const Intl = useIntl();
    const [loading, createAction] = useAction({
        type: 'dissociate',
        success: () => {
            setShowModal(false);
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LINK_LEAD.ERROR' }),
            });
        },
    });

    const onSubmit = (data) => {
        createAction(data);
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-unlink" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.UNLINK_LEAD.TITLE' })}
            body={
                <>
                    <div className="text-center mb-8">
                        <FormattedHTMLMessage id="CUSTOMERS.MODAL.UNLINK_LEAD.SUBTITLE" />
                    </div>
                    <div style={{ width: '90%', margin: '0 auto' }}>
                        {lead?.linkedLeads && (
                            <Controller
                                name="leadIds"
                                control={control}
                                defaultValue={[lead.id, ...lead.linkedLeads.map((LinkedLead) => LinkedLead.id)]}
                                render={(props) => (
                                    <LeadRow
                                        key={lead.id}
                                        lead={lead}
                                        flatChild
                                        separator={
                                            <div className="lead-row__separator">
                                                <i className="la la-2x text-white la-unlink" />
                                            </div>
                                        }
                                        isSelected={props.value.includes(lead.id)}
                                        hasActions={false}
                                    />
                                )}
                            />
                        )}
                    </div>
                </>
            }
            footer={
                <div className="float-right">
                    <Button
                        variant="outline-secondary"
                        style={{ width: '80px' }}
                        onClick={() => setShowModal(false)}
                        className="mr-5"
                    >
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" style={{ width: '80px' }} onClick={handleSubmit(onSubmit, onError)}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.SEPARATE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default UnlinkLead;
