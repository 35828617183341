import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useFetch from '@app/hooks/useFetch';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getUserPerimeter } from '@app/crud/autouser/user.crud';

import { updateDatas } from '@app/pages/autouser/users/Helpers/Dealership';
import StockTreeSelect from '@app/pages/stock/partials/StockTreeSelect';
import ROUTES from '@app/router/Routes';

import SearchInput from '@app/partials/content/SearchInput';

const StockActionsToDoHeader = ({
    handleFilter,
    updatePendingFilters,
    releasePendingFilters,
    setLoadedPerimeter,
    isHistory,
}) => {
    const Intl = useIntl();
    const { userData } = useSelector((state) => state.auth);
    const history = useHistory();

    const { data: perimeter } = useFetch({
        fetchAction: getUserPerimeter,
        resultInterceptor: (response) => {
            setLoadedPerimeter(true);
            return updateDatas(response);
        },
        axios: false,
        autoFetch: true,
    });

    return (
        !['conseiller-commercial'].includes(userData?.role?.slug) && (
            <div className="mt-5 d-flex flex-wrap align-items-center">
                <div className="mr-5 flex-grow-1" style={{ maxWidth: '43%' }}>
                    <StockTreeSelect
                        perimeter={perimeter}
                        updatePendingFilters={updatePendingFilters}
                        releasePendingFilters={releasePendingFilters}
                    />
                </div>
                <SearchInput
                    className="flex-grow-1"
                    onChange={handleFilter}
                    name="immat"
                    inputClassName="m-0"
                    placeholder={Intl.formatMessage({
                        id: 'STOCK_VEHICLES.FILTERS.SEARCH.TODO.REGISTRATION.PLACEHOLDER',
                    })}
                />
                <Button
                    variant="outline-lighten"
                    className="border-light-light text-secondary"
                    style={{ borderRadius: '3px' }}
                    onClick={() =>
                        history.push(routeTo(ROUTES.STOCK_ACTION_TO_DO.PATH, { page: isHistory ? 'list' : 'history' }))
                    }
                >
                    {isHistory ? (
                        <>
                            <i className="las la-arrow-left" />
                            <FormattedMessage id="STOCK.ACTION.SHOW.BUTTON.TODO" />
                        </>
                    ) : (
                        <>
                            <i className="las la-history" />
                            <FormattedMessage id="STOCK.ACTION.SHOW.BUTTON.HISTORY" />
                        </>
                    )}
                </Button>
            </div>
        )
    );
};

export default StockActionsToDoHeader;
