import React, {ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {Row} from "react-bootstrap";
import ReactDOM from "react-dom";
import {HeadBandHelper} from "../../helpers/HeadBandHelper";
import classNames from "classnames";
import KTUtil from "../../../_metronic/_assets/js/util";

type HeadBandProps = {
    variant: string,
    children: ReactNode,
    fixed?: boolean,
    parent?: string,
    height?: string,
    scrollHeight?: string,
    onScroll?: Function
}
const HeadBand = ({variant, children, fixed, parent, height, scrollHeight, onScroll = undefined}: HeadBandProps) => {
    const headbandRef = useRef<HTMLDivElement>();
    const lastScrollTop = useRef(undefined);
    const [inScroll, setInScroll] = useState(false);
    const [container, setContainer] = useState(undefined);

    const handleScroll = useCallback(() => {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        var scroll = false

        if ((scrollTop >= (parseInt(height) - parseInt(scrollHeight)) && scrollTop <= lastScrollTop.current)
        || scrollTop > lastScrollTop.current) {
            if (!inScroll) {
                scroll = true;
            }
        }

        setInScroll(scroll);

        if (onScroll) {
            onScroll(scroll);
        }

        if (!scroll) {
            HeadBandHelper.updateSubHeader();
        }

        lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
    }, [setInScroll, inScroll, height, scrollHeight]);

    useLayoutEffect(() => {
        if (parent) {
            setContainer(document.getElementById(parent));
        }
    }, [setContainer, parent]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            HeadBandHelper.updateSubHeader(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const content = (
        <div ref={(ref) => {
            if (!headbandRef.current) {
                headbandRef.current = ref;
                HeadBandHelper.updateSubHeader()
            }
        }} className={classNames('headband', {
            [`headband--${variant}`]: variant,
            'headband--header': parent,
            'headband--scroll': inScroll || KTUtil.isMobileDevice()
        })}>
            {children}
        </div>
    );

    if (container) {
        return ReactDOM.createPortal(content, container)
    } else if (!fixed) {
        return (
            <Row>
                {content}
            </Row>
        )
    }

    return <></>;
}

HeadBand.defaultProps = {
    height: 'auto',
    scrollHeight: 'auto'
}

export default HeadBand;
