import React from 'react';
import { useFormContext } from 'react-hook-form';

import { LayoutContextConsumer } from '@src/_metronic';
import { Intl } from '@src/_metronic/i18n/I18nProvider';
import BreadCrumbs from '@src/_metronic/layout/sub-header/components/BreadCrumbs';

import useFilters from '@app/hooks/useFilters';

import ButtonsFilter from '@app/partials/content/Filters/ButtonsFilter';
import FiltersProvider from '@app/partials/content/FiltersProvider';
import subHeader from '@app/partials/HoC/subHeader';

import { GROUPS } from '../helpers/StockRules';

function StockActionsToDoSubHeader({ subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle }) {
    const { watch } = useFormContext();
    const perimeter = watch('perimeter');
    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: 'MENU.STOCK.ACTION_TO_DO' }),
        },
    ];

    const { updateFilters, filters } = useFilters({
        name: 'stock_action_to_do',
        initialize: false,
    });

    const groups = GROUPS?.map?.((group) => {
        const hasPerimeter = perimeter?.find?.((perimeterItem) => perimeterItem?.id === group?.id);

        if (hasPerimeter) {
            group.key = hasPerimeter?.title;
            return group;
        }

        return null;
    })?.filter?.((group) => !!group);

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            type="button"
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}

                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">{Intl.formatMessage({ id: 'MENU.STOCK' })}</h3>
                                <BreadCrumbs items={breadcrumb} />
                            </>
                        )}
                    </LayoutContextConsumer>
                    {groups?.length > 0 && (
                        <FiltersProvider value={filters}>
                            <div className="ml-2">
                                <ButtonsFilter
                                    name="group_internal_id"
                                    values={groups}
                                    onChange={updateFilters}
                                    defaultValue={groups[0]}
                                    unCheckable={false}
                                />
                            </div>
                        </FiltersProvider>
                    )}
                </div>
            </div>
        </div>
    );
}

export default subHeader(StockActionsToDoSubHeader);
