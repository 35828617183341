import React from 'react';

import { PricingEvent, Vehicle } from '@app/store/stock/stock.type';

import StockPricingToDoHistoryEventCheckStatus from './StockPricingToDoHistoryEventCheckStatus';
import StockPricingToDoHistoryEventPrice from './StockPricingToDoHistoryEventPrice';
import StockPricingToDoHistoryRecoStatus from './StockPricingToDoHistoryEventRecoStatus';

type StockPricingToDoHistoryItemProps = {
    vehicle: Vehicle;
    event: PricingEvent;
};

const StockPricingToDoHistoryItem = ({ vehicle, event }: StockPricingToDoHistoryItemProps) => (
    <div className="d-flex flex-column align-items-start gap-1">
        <StockPricingToDoHistoryEventPrice price={vehicle.price} event={event} />
        <StockPricingToDoHistoryRecoStatus event={event} />
        <StockPricingToDoHistoryEventCheckStatus event={event} />
    </div>
);

export default StockPricingToDoHistoryItem;
