import { useCallback, useEffect, useState } from 'react';

import { linkFastNoteToLead } from '@app/crud/administration/fast-notes.crud';
import { FastNote } from '@app/crud/administration/fast-notes.type';

import { useAppSelector } from '@app/hooks';
import { getLeadId } from '@app/pages/customers/Helpers/Lead';
import { Lead } from '@app/store/customers/customers.store';

type UseTagsProps = {
    lead: Lead;
};

const useTags = ({ lead }: UseTagsProps) => {
    const [loadingTags, setLoadingTags] = useState(false);
    const { tags } = useAppSelector((state) => state.customer);
    const [selectedTags, setSelectedTags] = useState([]);

    /**
     * Link tag to lead
     * @type {(function(*=): Promise<void>)|*}
     */
    const linkTagToLead: (() => Promise<void>) | any = useCallback((tag: FastNote) => {
        setSelectedTags((state) => [...state, tag]);
    }, []);

    /**
     * Unlink tag to lead
     * @type {(function(*=): Promise<void>)|*}
     */
    const unlinkTagToLead: (() => Promise<void>) | any = useCallback(
        async (tag: FastNote) => {
            setSelectedTags(selectedTags?.filter?.((selectedTag) => selectedTag?.id !== tag?.id));
        },
        [selectedTags],
    );

    /**
     * Update linked tags on lead
     * @type {(function(): void)|*}
     */
    const updateTagOnLead: (() => void) | any = useCallback(async () => {
        setLoadingTags(true);
        return linkFastNoteToLead(getLeadId(lead), {
            fastNotes: selectedTags?.map?.((selectedTag) => selectedTag?.id),
        })
            ?.then?.(() => {
                setLoadingTags(false);
            })
            ?.catch?.(() => {
                setLoadingTags(false);
            });
    }, [lead, selectedTags]);

    useEffect(() => {
        if (getLeadId(lead)) {
            setSelectedTags(
                tags?.filter?.((fetchedTag) => lead?.fastNotes?.find?.((fastNote) => fastNote?.id === fetchedTag?.id)),
            );
        }
    }, [tags, lead]);

    return {
        tags,
        loadingTags,
        selectedTags,
        updateTagOnLead,
        linkTagToLead,
        unlinkTagToLead,
    };
};

export default useTags;
