/**
 * Get state color by order slug
 *
 * @param order
 * @returns {string}
 */
export function getStateBySlug(order) {
    let variant = '';

    switch (order?.appointment?.orderAppointmentStatus?.slug) {
        case 'to_confirm':
            variant = 'warning';
            break;
        case 'canceled':
            variant = 'info';
            break;
        case 'confirmed':
            variant = 'success';
            break;
        case 'closed':
            variant = 'secondary';
            break;
        case 'expired':
        default:
            variant = 'secondary';
            break;
    }
    return variant;
}

export const HIDDEN_STATUS = ['expiree', 'annulee', 'rendez_vous_termine'];
export const EXCLUDED_APPOINTMENT_STATUS = ['canceled', 'closed', 'expired'];
export const CONSIDERED_CONFIRMED_APPOINTMENT_STATUS = ['confirmed', 'closed', 'canceled'];
