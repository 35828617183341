import React from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { LayoutContextConsumer } from '@src/_metronic/layout/LayoutContext';
import BreadCrumbs from '@src/_metronic/layout/sub-header/components/BreadCrumbs';

import useModal from '@app/hooks/useModal';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';

import Permission from '@app/partials/content/Permission';
import subHeader from '@app/partials/HoC/subHeader';

import { HIDDEN_STATUS } from './Helpers/OrderStatus';
import Cancel from './Modals/Cancel';
import TimeLine from './Modals/TimeLine';

function OrderSubHeader(props) {
    const [showCancelModal, toggleCancelModal] = useModal(false);
    const [showTimeLineModal, toggleTimeLineModal] = useModal(false);
    const order = useSelector(({ order }) => order.order);
    const Intl = useIntl();

    const { subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle } = props;

    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: 'MENU.APV.ONLINE_APPOINTEMENT' }),
        },
        {
            title: Intl.formatMessage({ id: 'MENU.APV.AUTOSPHERE_ORDER' }, { number: order?.id || '' }),
        },
    ];

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}

                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">{Intl.formatMessage({ id: 'MENU.APV.ORDERS' })}</h3>
                                <BreadCrumbs items={breadcrumb} />
                            </>
                        )}
                    </LayoutContextConsumer>
                </div>
                <div className="d-flex align-items-center">
                    <Link
                        to={
                            order?.orderType?.slug
                                ? routeTo(ROUTES.ORDERS.PATH.TYPE, {
                                      type: order?.orderType?.slug,
                                  })
                                : routeTo(ROUTES.ORDERS.PATH.ALL)
                        }
                    >
                        <Button className="mr-2" variant="outline-light">
                            {Intl.formatMessage({ id: 'APV.ORDER.VIEW.BACK' })}
                        </Button>
                    </Link>
                    {!HIDDEN_STATUS.includes(order?.orderStatus?.slug) && (
                        <Permission permissions={['APV_CANCEL_ORDER']}>
                            <Button className="mr-2" variant="outline-light" onClick={() => toggleCancelModal(true)}>
                                {Intl.formatMessage({ id: 'APV.ORDER.VIEW.CANCEL' })}
                            </Button>
                        </Permission>
                    )}
                    <Button variant="outline-light" onClick={() => toggleTimeLineModal(true)}>
                        <i className="fa fa-history" />
                        {Intl.formatMessage({ id: 'APV.ORDER.VIEW.TIMELINE' })}
                    </Button>
                </div>
            </div>
            <Cancel showModal={showCancelModal} setShowModal={toggleCancelModal} />
            <TimeLine showModal={showTimeLineModal} order={order} setShowModal={toggleTimeLineModal} />
        </div>
    );
}

export default subHeader(OrderSubHeader);
