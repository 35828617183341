import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { CSSProperties, ReactElement } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Permission from './Permission';
import Typology from './Typology';

type ActionButtonProps = {
    icon: ReactElement;
    title: ReactElement;
    variant: string;
    style?: CSSProperties;
    background?: string;
    className?: string;
    active?: boolean;
    onClick?: () => void;
    disabled?: () => boolean;
    disabledTooltip?: ReactElement;
    view?: string;
    permissions?: string[];
    typologies?: string[] | null;
};
const ActionButton = ({
    icon,
    title,
    variant,
    disabled,
    style,
    background,
    className,
    active,
    onClick,
    disabledTooltip,
    view,
    permissions,
    typologies,
}: ActionButtonProps) => {
    const isDisabled = disabled ? disabled() : false;

    const handleClick = () => {
        if (onClick && !isDisabled) {
            onClick();
        }
    };

    const variants = {
        hidden: {
            scale: 0,
            opacity: 0,
        },
        visible: {
            scale: 1,
            opacity: 1,
            transition: {
                duration: 0.3,
            },
        },
    };

    return (
        <Permission permissions={permissions}>
            <Typology typologies={typologies} hasAny>
                <motion.div variants={variants}>
                    <OverlayTrigger
                        trigger={isDisabled ? 'hover' : null}
                        placement="bottom"
                        overlay={<Tooltip id="tooltip-disabled">{isDisabled && disabledTooltip}</Tooltip>}
                    >
                        <div
                            style={style}
                            className={classNames(`action-button action-button--${variant}`, className, {
                                [`action-button--background-${background}`]: background,
                                'action-button--inactive': active === false,
                                'action-button--active': active === true,
                                'action-button--disabled': isDisabled,
                                [`action-button--${view}`]: view,
                            })}
                            onClick={handleClick}
                        >
                            <div
                                className={classNames('action-button__symbol symbol symbol-40', {
                                    'symbol-transparent': background,
                                    [`symbol-${variant}`]: !background && active !== false,
                                })}
                            >
                                <span className="action-button__label symbol-label">{icon}</span>
                            </div>
                            <div className="action-button__title">{title}</div>
                        </div>
                    </OverlayTrigger>
                </motion.div>
            </Typology>
        </Permission>
    );
};

ActionButton.defaultProps = {
    active: undefined,
    background: undefined,
    style: {},
    view: 'grid',
    className: '',
    onClick: null,
    disabled: null,
    disabledTooltip: false,
    permissions: [],
    typologies: null,
};

export default ActionButton;
