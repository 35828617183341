import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { availableLanguages, Locale } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';
import useLocales from '@app/hooks/useLocales';

import { getActionEmailTypes } from '@app/crud/administration/action-email-type';
import { getDealershipActiveSignatures } from '@app/crud/administration/signature.crud';

import { formatEmailValue } from '@app/pages/administration/email-template/Helpers/email-tags';
import { getLeadsVehicles } from '@app/pages/customers/Helpers/Lead';
import useAction from '@app/pages/customers/Hooks/useAction';
import useLead from '@app/pages/customers/Hooks/useLead';
import CarSearch from '@app/pages/customers/Partials/CarSearch';
import DealershipSelect from '@app/pages/customers/Partials/DealershipSelect';
import { actions } from '@app/store/modal/modal.store';

import Editor, { LIGHT_BUTTONS } from '@app/partials/content/Editor';
import { FilesUpload } from '@app/partials/content/FilesUpload/index';
import toast from '@app/partials/content/Toast';
import { getBrandTemplate } from '@app/partials/content/Vehicle/VehicleHelpers';
import ErrorForm from '@app/partials/layout/ErrorForm';

import { JoditButton, JoditCollection, JoditPopup } from '../../../partials/content/Jodit';
import ModalDefault from '../../../partials/content/modals/Modal.default';

const Mail = ({ showModal, setShowModal }) => {
    const filesUploadRef = useRef();
    const refEditor = useRef();
    const methods = useForm();
    const dispatch = useDispatch();
    const Intl = useIntl();
    const { register, setValue, errors, handleSubmit, control, watch } = methods;
    const { params } = useSelector((state) => state.modal);
    const currentLead = useLead(params?.leadId);
    const { data: customer } = useSelector((state) => state.customer.customer);
    const { userData } = useSelector((state) => state.auth);
    const [tagsData, setTagsData] = useState();
    const [emptyTags, setEmptyTags] = useState(0);
    const [locale, setLocale] = useState(Locale);
    const [content, setContent] = useState('');
    const { locales, getLocaleByKey } = useLocales();
    const { emailType, signatureType, vehicles, dealership } = watch([
        'emailType',
        'vehicles',
        'signatureType',
        'dealership',
    ]);
    const [emailSignature, setEmailSignature] = useState();
    const {
        fetch: fetchActionEmailTypes,
        data: actionEmailTypes,
        loading: loadingActionEmailTypes,
    } = useFetch({
        fetchAction: getActionEmailTypes,
    });
    const {
        fetch: fetchDealershipActiveSignatures,
        data: dealershipActiveSignatures,
        loading: loadingDealershipActiveSignatures,
    } = useFetch({
        fetchAction: getDealershipActiveSignatures,
    });
    const [loading, createAction] = useAction({
        type: 'email',
        success: () => {
            if (params?.leadId) {
                dispatch(
                    actions.modalChange('next_action', {
                        leadId: params.leadId,
                    }),
                );
            } else {
                setShowModal(false);
            }
            toast({
                variant: 'success',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.EMAIL.SUCCESS' }),
            });
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.EMAIL.ERROR' }),
            });
        },
    });

    const onSubmit = (data) => {
        const localData = { ...data };
        delete localData.vehicles;
        delete localData.signatureType;

        localData.content = content;
        createAction(
            {
                ...localData,
                recipient: customer?.email,
            },
            true,
        );
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    useEffect(() => {
        const regex = /text-danger/gi;
        const count = (content.match(regex) || []).length;

        setEmptyTags(count);
    }, [content]);

    useEffect(() => {
        fetchActionEmailTypes({ isActive: true });
    }, [fetchActionEmailTypes]);

    useEffect(() => {
        if (dealership !== undefined) fetchDealershipActiveSignatures(dealership);
    }, [currentLead, dealership]);

    useEffect(() => {
        if (dealershipActiveSignatures.length)
            setValue('signatureType', dealershipActiveSignatures.filter((item) => item?.isDefault === true)[0]?.id);
    }, [dealershipActiveSignatures]);

    const handleClose = () => {
        if (params?.leadId) {
            dispatch(actions.rollBackModal());
        } else {
            setShowModal(false);
        }
    };

    useEffect(() => {
        if (actionEmailTypes?.length > 0) {
            const emailTypeName = actionEmailTypes.find((type) => type.slug === emailType);

            setValue('object', emailTypeName?.name || '');
            setValue(
                'content',
                formatEmailValue(`${emailTypeName?.value || ''}<br />${emailSignature?.value || ''}`, tagsData),
            );
        }
    }, [setValue, emailType, actionEmailTypes, emailSignature, tagsData, signatureType]);

    useEffect(() => {
        setEmailSignature(dealershipActiveSignatures.filter((item) => item.id === parseInt(signatureType, 10))[0]);
    }, [dealershipActiveSignatures, signatureType, tagsData]);

    useEffect(() => {
        setTagsData((prevState) => ({
            ...prevState,
            vehicles,
            vehicle: vehicles?.length > 0 ? vehicles[0] : {},
        }));
    }, [setTagsData, vehicles]);

    useEffect(() => {
        const value = refEditor?.current?.currentPlace?.editor?.innerHTML;

        if (value) {
            setValue('content', formatEmailValue(value, tagsData));
        }
    }, [setValue, tagsData]);

    useEffect(() => {
        setTagsData((prevState) => ({
            ...prevState,
            userData,
            customer,
            dealership,
        }));
    }, [setValue, setTagsData, userData, customer, currentLead, dealership]);

    const getExtraButtons = () => [
        {
            name: 'Véhicule',
            tooltip: 'Sélectionner le véhicule par défaut',
            popup: (editor, _current, _self, close) =>
                JoditPopup(
                    editor,
                    <JoditCollection>
                        {vehicles?.length > 0 ? (
                            <>
                                <div className="font-weight-bold my-3 px-3 font-size-lg">
                                    Sélectionner le véhicule par défaut :
                                </div>
                                {vehicles.map((vehicle) => (
                                    <JoditButton
                                        key={vehicle.vehicleUuid}
                                        onClick={() => {
                                            setTagsData((prevState) => ({
                                                ...prevState,
                                                vehicle,
                                            }));
                                            close();
                                        }}
                                    >
                                        {getBrandTemplate(vehicle)} {vehicle?.model || ''} {vehicle?.version || ''}
                                    </JoditButton>
                                ))}
                            </>
                        ) : (
                            <div className="font-weight-bold my-3 px-3 font-size-lg">Aucun véhicule disponible</div>
                        )}
                    </JoditCollection>,
                ),
        },
        {
            name: 'Concession',
            tooltip: 'Sélectionner la concession par défaut',
            popup: (editor, current, self, close) =>
                JoditPopup(
                    editor,
                    <JoditCollection>
                        {userData?.dealerships ? (
                            <>
                                <div className="font-weight-bold my-3 px-3 font-size-lg">
                                    Sélectionner la concession par défaut :
                                </div>
                                {Object.entries(userData.dealerships).map(([code, userDealership]) => (
                                    <JoditButton
                                        key={code}
                                        onClick={() => {
                                            setTagsData((prevState) => ({
                                                ...prevState,
                                                userDealership,
                                            }));
                                            close();
                                        }}
                                    >
                                        {userDealership?.title || ''}
                                    </JoditButton>
                                ))}
                            </>
                        ) : (
                            <div className="font-weight-bold my-3 px-3 font-size-lg">Aucune concession disponible</div>
                        )}
                    </JoditCollection>,
                ),
        },
    ];

    const filterActionEmailType = () => {
        const brandList = Object.values(userData.dealerships).map((dealershipItem) =>
            dealershipItem.brand.toLowerCase(),
        );
        let filteredActionEmailType = actionEmailTypes.filter((model) => model.locale === locale);
        filteredActionEmailType = filteredActionEmailType.filter(
            (model) =>
                model.brands === null ||
                model.brands?.length === 0 ||
                model.brands?.some((brand) => brandList.includes(brand.slug)),
        );

        return filteredActionEmailType.map((model) => (
            <option key={model.id} value={model.slug}>
                {model.name}
            </option>
        ));
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading || loadingActionEmailTypes || loadingDealershipActiveSignatures}
            hideModal={handleClose}
            icon={<i className="la la-2x text-primary la-envelope" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.MAIL.TITLE' })}
            body={
                <FormProvider {...methods}>
                    <Row>
                        <DealershipSelect
                            methods={methods}
                            customer={customer}
                            currentLead={currentLead}
                            additionnalClass="col-12 col-md-10"
                        />
                        <Form.Group as={Col} className="col-12 col-md-10">
                            <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.MAIL.MODEL' })}</Form.Label>
                            <Form.Control
                                as="select"
                                name="emailType"
                                className={`${errors.emailType ? 'is-invalid' : ''}`}
                                defaultValue=""
                                ref={register({
                                    validate: (value) =>
                                        value === '' ? Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }) : true,
                                })}
                            >
                                <option value="">
                                    {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.MAIL.MODEL_PLACEHOLDER' })}
                                </option>
                                {actionEmailTypes?.length > 0 && filterActionEmailType()}
                            </Form.Control>
                            <ErrorForm errors={errors} name="emailType" />
                        </Form.Group>
                        <Form.Group as={Col} className="col-12 col-md-2">
                            <Form.Label>
                                {Intl.formatMessage({ id: 'ADMINISTRATION.EMAIL_TEMPLATE.VIEW.LOCALE' })}
                            </Form.Label>
                            <Dropdown
                                value={getLocaleByKey(locale)}
                                className="w-100"
                                onChange={(e) => {
                                    setLocale(e?.value?.key);
                                }}
                                options={
                                    locales
                                        ? Object.values(locales).filter((elm) => availableLanguages.includes(elm.key))
                                        : []
                                }
                                valueTemplate={(value) => value?.key.toUpperCase() || ''}
                                itemTemplate={(value) => value?.key.toUpperCase() || ''}
                                style={{ width: 'fit-content', borderColor: '#e4e6ef' }}
                                optionLabel="name"
                            />
                        </Form.Group>
                        <Form.Group as={Col} className="col-12 col-md-12">
                            <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.MAIL.SIGNATURE' })}</Form.Label>
                            <Form.Control
                                as="select"
                                name="signatureType"
                                className={`${errors.signatureType ? 'is-invalid' : ''}`}
                                defaultValue={
                                    dealershipActiveSignatures.filter((item) => item.isDefault === true)[0]?.id
                                }
                                ref={register}
                            >
                                {dealershipActiveSignatures?.length > 0 &&
                                    dealershipActiveSignatures.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                <option value="">
                                    {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.MAIL.SIGNATURE_PLACEHOLDER' })}
                                </option>
                            </Form.Control>
                            <ErrorForm errors={errors} name="emailType" />
                        </Form.Group>
                    </Row>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.MAIL.OBJECT' })}</Form.Label>
                        <Form.Control
                            type="text"
                            name="object"
                            className={`${errors.object ? 'is-invalid' : ''}`}
                            ref={register({
                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            })}
                        />
                        <ErrorForm errors={errors} name="object" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.MAIL.CONTENT' })}</Form.Label>
                        <Controller
                            control={control}
                            name="content"
                            rules={{
                                required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                            }}
                            render={(props) => (
                                <Editor
                                    ref={refEditor}
                                    className={`${errors.content ? 'is-invalid' : ''}`}
                                    value={props.value}
                                    onChange={(value) => setContent(value)}
                                    refresh={tagsData}
                                    config={{
                                        minHeight: 250,
                                        maxHeight: 300,
                                        buttons: [...getExtraButtons(), ...LIGHT_BUTTONS],
                                        extraIcons: {
                                            car: '<i class="las la-car la-xl text-secondary"></i>',
                                            building: '<i class="las la-building la-lg text-secondary"></i>',
                                        },
                                    }}
                                />
                            )}
                        />
                        <ErrorForm errors={errors} name="content" />
                    </Form.Group>
                    <Row>
                        <Form.Group as={Col} className="col-12 col-md-6 w-md-auto w-100">
                            <CarSearch onlyStock defaultVehicles={getLeadsVehicles(customer?.leads)} title="" />
                        </Form.Group>
                        <Form.Group as={Col} className="col-12 col-md-6 w-md-auto w-100">
                            <Controller
                                name="files"
                                control={control}
                                defaultValue={null}
                                render={(props) => (
                                    <FilesUpload
                                        value={props.value}
                                        className="mb-3"
                                        ref={filesUploadRef}
                                        onChange={props.onChange}
                                    />
                                )}
                            />
                            <Button variant="primary" onClick={() => filesUploadRef.current.click()}>
                                {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.MAIL.ATTACHMENT_ADD' })}
                            </Button>
                        </Form.Group>
                    </Row>
                </FormProvider>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={handleClose} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    {emptyTags ? (
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-disabled">
                                    {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.MAIL.MISSING_TAGS' })}
                                </Tooltip>
                            }
                        >
                            <span className="d-inline-block">
                                <Button disabled style={{ pointerEvents: 'none' }}>
                                    {Intl.formatMessage({ id: 'TRANSLATOR.SEND' })}
                                </Button>
                            </span>
                        </OverlayTrigger>
                    ) : (
                        <Button variant="primary" onClick={handleSubmit(onSubmit, onError)} disabled={emptyTags}>
                            {Intl.formatMessage({ id: 'TRANSLATOR.SEND' })}
                        </Button>
                    )}
                </div>
            }
        />
    );
};

export default Mail;
