import classNames from 'classnames';
import { Checkbox } from 'primereact/checkbox';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { useContext, useEffect, useRef } from 'react';
import { Button, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import KTUtil from '@src/_metronic/_assets/js/util';
import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { FiltersContext } from '@app/partials/content/FiltersProvider';
import Permission from '@app/partials/content/Permission';

import { LEAD_FLAGS } from '../Helpers/Flags';

const LegendButton = ({ updatePendingFilters, releasePendingFilters, resetFilter, activeTab }) => {
    const filters = useContext(FiltersContext);
    const op = useRef(null);

    useEffect(() => {
        resetFilter('flags');
    }, [activeTab]);

    const handleCheckedFilter = (checked, slug) => {
        const flags = filters?.flags?.value?.filter?.((flag) => flag !== slug) || [];

        if (checked) {
            flags.push(slug);
        }

        updatePendingFilters({
            flags: {
                value: flags,
            },
        });
    };

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
            <div
                className="font-weight-bold ml-2 p-2 d-flex align-items-center cursor-pointer"
                role="button"
                onClick={(e) => op.current.toggle(e)}
            >
                <i className="la la-2x la-filter mr-2 p-0 text-initial" />
                <span>{Intl.formatMessage({ id: 'ICONS.LEGEND' })}</span>
            </div>
            <OverlayPanel
                ref={op}
                style={{ width: KTUtil.isInResponsiveRange('mobile') ? 'auto' : '450px' }}
                className="legend-icon-panel"
            >
                <div className="mx-1 d-flex flex-wrap">
                    {Object.entries(LEAD_FLAGS).map(([slug, flag], idx) => {
                        const lastRow = Math.floor((Object.values(LEAD_FLAGS).length - 1) / 2);

                        return (
                            <Permission key={slug} permissions={flag?.permissions || []}>
                                <Col
                                    md={6}
                                    className={classNames('px-0 py-3 d-flex align-items-center', {
                                        'border-bottom': idx / 2 < lastRow,
                                    })}
                                >
                                    <Checkbox
                                        className="p-checkbox--small mr-3"
                                        disabled={flag?.filterable === false}
                                        onChange={(e) => handleCheckedFilter(e.checked, slug)}
                                        checked={filters?.flags?.value?.includes?.(slug)}
                                    />
                                    {flag.icon}
                                    <span className="ml-2">{flag.name}</span>
                                </Col>
                            </Permission>
                        );
                    })}
                </div>
                <div className="my-5 d-flex justify-content-center">
                    <Button
                        variant="outline-secondary mr-5"
                        onClick={() => resetFilter('flags')}
                        style={{ width: '120px' }}
                    >
                        <FormattedMessage id="TRANSLATOR.RESET" />
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            releasePendingFilters();
                            op.current.hide();
                        }}
                        style={{ width: '120px' }}
                    >
                        <FormattedMessage id="TRANSLATOR.VALIDATE" />
                    </Button>
                </div>
            </OverlayPanel>
        </>
    );
};

export default LegendButton;
