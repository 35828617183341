import React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import { htmlToString } from '@app/helpers/StringHelper';

import { addLeadFinance } from '@app/crud/customers/customer.crud';

import { financingInterest } from '@app/pages/customers/Helpers/Events';

import Editor from '@app/partials/content/Editor';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

const Financing = ({ event, showModal, updateFinance, fetchData, setShowModal }) => {
    const { register, control, errors, handleSubmit, reset } = useForm({
        defaultValues: { ...event?.finance },
    });
    const { params: listingParams } = useSelector((state) => state.customer);
    const Intl = useIntl();

    const onSubmit = (data) => {
        const leadId = event?.informations?.identifier;

        if (data?.comment) {
            data.comment = htmlToString(data?.comment);
        }

        if (!Array.isArray(event?.finance)) {
            updateFinance(data);
            reset({ ...data });
        } else {
            addLeadFinance(leadId, data)
                .then(() => {
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.FUNDING.UPDATE_SUCCESS' }),
                    });
                    reset({ ...data });
                    setShowModal(false);
                    fetchData(leadId);
                })
                .catch(() => {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.FUNDING.UPDATE_FAILED' }),
                    });
                });
        }
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-calculator" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.FINANCING.TITLE' })}
            body={
                <>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.FINANCING.INTEREST' })}</Form.Label>
                        <Form.Control as="select" name="interest" ref={register}>
                            <option value="">{Intl.formatMessage({ id: 'TRANSLATOR.SELECT' })}</option>
                            {financingInterest.map((interest) => (
                                <option key={interest} value={interest}>
                                    {interest}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.FINANCING.FINANCING' })}</Form.Label>
                        <Form.Control as="select" name="financialType" ref={register}>
                            <option value="">{Intl.formatMessage({ id: 'TRANSLATOR.SELECT' })}</option>
                            {listingParams &&
                                listingParams.finance_type &&
                                listingParams.finance_type.listingParams.map((reason) => (
                                    <option key={reason.id} value={reason.slug}>
                                        {reason.name}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.FINANCING.ORGANISM' })}</Form.Label>
                        <Form.Control
                            as="select"
                            className={`${errors.financialAgency ? 'is-invalid' : ''}`}
                            name="financialAgency"
                            ref={register}
                        >
                            <option value="">{Intl.formatMessage({ id: 'TRANSLATOR.SELECT' })}</option>
                            {listingParams &&
                                listingParams.funding_agency &&
                                listingParams.funding_agency.listingParams.map((reason) => (
                                    <option key={reason.id} value={reason.slug}>
                                        {reason.name}
                                    </option>
                                ))}
                        </Form.Control>
                        <ErrorForm errors={errors} name="financialAgency" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.FINANCING.BUDGET' })}</Form.Label>
                        <Controller
                            name="financialBudget"
                            defaultValue={null}
                            control={control}
                            render={(props) => (
                                <InputGroup className="mb-2">
                                    <NumberFormat
                                        value={props.value}
                                        className="form-control"
                                        thousandSeparator=" "
                                        onValueChange={(values) => {
                                            props.onChange(values.floatValue || null);
                                        }}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text>€</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            )}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.FINANCING.INCOME' })}</Form.Label>
                        <Controller
                            name="financialInput"
                            defaultValue={null}
                            control={control}
                            render={(props) => (
                                <InputGroup className="mb-2">
                                    <NumberFormat
                                        value={props.value}
                                        className="form-control"
                                        thousandSeparator=" "
                                        onValueChange={(values) => {
                                            props.onChange(values.floatValue || null);
                                        }}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text>€</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            )}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.FINANCING.AMOUNT' })}</Form.Label>
                        <Controller
                            name="financialRent"
                            defaultValue={null}
                            control={control}
                            render={(props) => (
                                <InputGroup className="mb-2">
                                    <NumberFormat
                                        value={props.value}
                                        className="form-control"
                                        thousandSeparator=" "
                                        onValueChange={(values) => {
                                            props.onChange(values.floatValue || null);
                                        }}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text>€</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            )}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.FINANCING.COMMENT' })}</Form.Label>
                        <Controller
                            control={control}
                            name="comment"
                            defaultValue=""
                            render={(props) => (
                                <Editor
                                    value={props.value}
                                    onChange={props.onChange}
                                    config={{
                                        minHeight: 150,
                                        buttons: '',
                                        statusbar: false,
                                        placeholder: Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.FINANCING.COMMENT.PLACEHOLDER',
                                        }),
                                    }}
                                    html={false}
                                />
                            )}
                        />
                    </Form.Group>
                </>
            }
            footer={
                <div className="d-flex justify-content-end w-100">
                    <Button
                        variant="outline-secondary"
                        className="mr-5"
                        onClick={() => setShowModal(false)}
                        style={{ width: '120px' }}
                    >
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(onSubmit, onError)} style={{ width: '120px' }}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.SAVE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default Financing;
