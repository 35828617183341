import * as dayjs from 'dayjs';
import { Column } from 'primereact/column';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { routeTo } from '@app/helpers/RoutesHelper';
import { ucwords } from '@app/helpers/StringHelper';

import ROUTES from '@app/router/Routes';
import { fetchPackages } from '@app/store/manufacturer/package/package.thunk';

import DropdownFilter from '@app/partials/content/DropdownFilter';
import FiltersProvider from '@app/partials/content/FiltersProvider';
import HoCDataTable from '@app/partials/content/HoCDataTable';
import Permission from '@app/partials/content/Permission';
import RefreshTable from '@app/partials/content/RefreshTable';
import SearchInput from '@app/partials/content/SearchInput';
import SelectFilter from '@app/partials/content/SelectFilter';
import { Filters } from '@app/partials/layout/Filters';

const PackageTable = ({ history }) => {
    const intl = useIntl();
    const { packages, totalRecords, filtersContent, loading } = useSelector((state) => state.manufacturer.package);
    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: 'apv_packages_manufacturer',
    });
    const { handleFilter, handlePagination, handleSort, refresh } = useTableHandlers({
        filterStore: 'apv_packages_manufacturer',
        fetchAction: fetchPackages,
        paramsInterceptor: (params, interceptorFilters) => ({
            ...params,
            start: interceptorFilters.start,
            items: interceptorFilters.items,
        }),
    });

    const showAddPackage = () => {
        history.push(routeTo(ROUTES.PACKAGE_MANUFACTURER.PATH.CREATE));
    };

    const selectFilter = (name, options) => <SelectFilter name={name} options={options} onChange={handleFilter} />;

    const selectDropdown = (name, filter) => (
        <DropdownFilter
            optionLabel="name"
            filterBy="name"
            name={name}
            options={
                filtersContent?.[filter] &&
                Object.values(filtersContent[filter]).map((elm) => ({
                    name: elm.name,
                    key: elm.slug,
                }))
            }
            onChange={handleFilter}
        />
    );

    const updatedBodyTemplate = (pack) => dayjs(pack.updated).format('DD/MM/YYYY [à] HH [h] mm');

    const actionsBodyTemplate = (pack) => (
        <Link
            to={routeTo(ROUTES.PACKAGE_MANUFACTURER.PATH.UPDATE, {
                id: pack.cryptedId,
            })}
        >
            <Button className="p-2 px-3" variant="secondary">
                <i className="fa fa-pen" />
                {intl.formatMessage({ id: 'TRANSLATOR.UPDATE' })}
            </Button>
        </Link>
    );

    const statusBodyTemplate = (pack) => (
        <span className={pack?.active ? 'text-success' : 'text-danger'}>
            {pack?.active
                ? intl.formatMessage({ id: 'APV.PACKAGE.TABLE.BODY.STATUS.ACTIVE' })
                : intl.formatMessage({ id: 'APV.PACKAGE.TABLE.BODY.STATUS.INACTIVE' })}
        </span>
    );

    const cols = [
        {
            field: 'name',
            header: intl.formatMessage({ id: 'APV.PACKAGE.TABLE.HEAD.PACKAGE' }),
            style: { width: '25%' },
            filter: true,
            filterElement: selectDropdown('slug', 'packages'),
        },
        {
            field: 'status',
            header: intl.formatMessage({ id: 'APV.PACKAGE.TABLE.HEAD.STATUS' }),
            style: { width: '15%' },
            filter: true,
            filterElement: selectFilter(
                'active',
                <>
                    <option value="">{intl.formatMessage({ id: 'TRANSLATOR.ALL' })}</option>
                    <option value>{ucwords(intl.formatMessage({ id: 'TRANSLATOR.YES' }))}</option>
                    <option value={false}>{ucwords(intl.formatMessage({ id: 'TRANSLATOR.NO' }))}</option>
                </>,
            ),
            body: statusBodyTemplate,
        },
        {
            field: 'updated',
            header: intl.formatMessage({ id: 'APV.PACKAGE.TABLE.HEAD.DATEUPDATE' }),
            style: { width: '15%' },
            body: updatedBodyTemplate,
        },
        {
            field: 'actions',
            header: intl.formatMessage({ id: 'APV.PACKAGE.TABLE.HEAD.ACTIONS' }),
            style: { width: '15%', 'text-align': 'center' },
            body: actionsBodyTemplate,
        },
    ];

    const dynamicColumns = cols.map((col) => <Column key={col.field} {...col} />);

    return (
        <FiltersProvider value={filters}>
            <Filters>
                <Row>
                    <RefreshTable count={totalRecords} onClick={refresh} />
                    <Col lg={5} offset={2}>
                        <SearchInput
                            onChange={handleFilter}
                            name="research"
                            placeholder={intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.SEARCHFIELD' })}
                        />
                    </Col>
                    {/* TODO change permission */}
                    <Permission permissions={['APV_ADD_PACKAGE']}>
                        <Button variant="outline-primary" onClick={() => showAddPackage()}>
                            {intl.formatMessage({ id: 'MANUFACTURER.PACKAGE.NEW' })}
                        </Button>
                    </Permission>
                </Row>
            </Filters>
            <HoCDataTable
                value={packages}
                totalRecords={totalRecords}
                lazy
                loading={loading}
                paginator
                onFilter={handleFilter}
                onPage={handlePagination}
                onSort={handleSort}
                paginatorTemplate="RowsPerPageDropdown LastPageLink NextPageLink PageLinks PrevPageLink FirstPageLink CurrentPageReport"
                currentPageReportTemplate={intl.messages['DATATABLE.REPORT_TEMPLATE']}
                rows={items}
                first={start}
                sortField={sortField}
                sortOrder={sortOrder}
                removableSort
                filterDisplay="row"
                rowsPerPageOptions={[25, 50, 100]}
                emptyMessage={intl.messages['DATATABLE.EMPTY_RESULT']}
            >
                {dynamicColumns}
            </HoCDataTable>
        </FiltersProvider>
    );
};

export default PackageTable;
