import React from 'react';
import { useIntl } from 'react-intl';

import SwitchInput from '@app/partials/content/SwitchInput';

type CustomSwitchProps = {
    name: string;
    handleToggleFormProModal: (isProValue: boolean) => void;
    active: boolean;
};
const CustomSwitch = ({ name, handleToggleFormProModal, active }: CustomSwitchProps) => {
    const Intl = useIntl();

    return (
        <div>
            <SwitchInput
                className="switch-input--primary"
                name={name}
                onChange={(isProValue: boolean) => handleToggleFormProModal(isProValue)}
                labelFirst={Intl.formatMessage({ id: 'TRANSLATOR.INDIVIDUAL' })}
                labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.COMPANY' })}
                active={active}
            />
        </div>
    );
};

export default CustomSwitch;
