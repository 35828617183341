import React, {useEffect, useRef} from 'react';
import lottie from "lottie-web";
import {useLayoutContext} from "../../../_metronic";

const Lottie = ({options, style}) => {
    const loaded = useRef(false);
    const lottieContainer = useRef();
    const context = useLayoutContext();

    useEffect(() => {
        const body = document.querySelector('body');

        if (!loaded.current && !body?.classList?.contains('kt-body__splashscreen')) {
            loaded.current = true;
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                ...options
            });
        }
    }, [context]);

    return (
        <div style={style} ref={lottieContainer} />
    );
};

Lottie.defaultProps = {
    options: {},
    style: {
        width: 'auto',
        height: '250px'
    }
}

export default Lottie;
