import dayjs from 'dayjs';
import { Timeline } from 'primereact/timeline';
import React from 'react';
import { Card } from 'react-bootstrap';

import Label from '@app/partials/content/Label';

import { Intl } from '../../../../../_metronic/i18n/I18nProvider';
import { getStateBySlug } from '../Helpers/Status';

const TimelineProgress = (events) => {
    const customizedContent = (item) => {
        const statusSlug = item?.id !== null ? getStateBySlug(item.orderEventType?.slug) : '';
        return (
            <div className="smart-repair-timeline__text-tranform">
                <Label
                    className="font-weight-bold smart-repair-timeline__textLabel--color smart-repair-timeline__textLabel--bg mr-1"
                    variant={statusSlug}
                >
                    {item?.orderEventType?.name}
                </Label>
                <span
                    className={`smart-repair-timeline__textLabel--color smart-repair-timeline__status-text-${statusSlug}`}
                >
                    {item?.orderEventType?.subName !== null ? `• ${item?.orderEventType?.subName}` : ''}
                </span>
            </div>
        );
    };

    const topContent = (item) => {
        const formattedDate = item?.id !== null ? dayjs(item.created).format('DD/MM/YYYY') : '';
        const formattedTime = item.id !== null ? `à ${dayjs(item.created).format('HH [h] mm')}` : '';
        return (
            <div className="smart-repair-timeline__text-tranform">
                {formattedDate} <br />
                {formattedTime}
            </div>
        );
    };

    const customizedMarker = (item) => {
        const statusSlug = item?.id !== null ? getStateBySlug(item.orderEventType?.slug) : 'lighten';
        return <div className={`smart-repair-timeline__rounded smart-repair-timeline__label-${statusSlug}`} />;
    };

    return (
        <Card className="my-12">
            <Card.Header className="d-flex justify-content-between align-items-center position-relative">
                <span className="font-weight-semibold">
                    <i className="card__icon las la-newspaper la-lg" />{' '}
                    {Intl.formatMessage({ id: 'SMART.REPAIR.QUOTATION.PROGRESS.TITLE' })}
                </span>
            </Card.Header>
            <Card.Body className="ml-15">
                <Timeline
                    value={events?.events?.orderEvents}
                    align="top"
                    layout="horizontal"
                    opposite={customizedContent}
                    content={topContent}
                    marker={customizedMarker}
                />
            </Card.Body>
        </Card>
    );
};

export default TimelineProgress;
