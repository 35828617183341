import { useEffect, useState } from 'react';

import { actions } from '@app/store/modal/modal.store';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

const useModal = (defaultState = false, modalName: string | null = null, superImposed = false) => {
    const dispatch = useAppDispatch();
    const { modal, params } = useAppSelector((state) => state.modal);
    const [showModal, setShowModal] = useState(defaultState);

    function toggle(state: any = null, modalParams: any = null, rollback = false) {
        const newState = state !== null ? state : !showModal;
        if (!superImposed) {
            dispatch(actions.modalChange(newState ? modalName : null, modalParams, rollback));
        }
        setShowModal(state !== null ? state : !showModal);
    }

    useEffect(() => {
        if (!superImposed) {
            if (modal && modalName === modal) {
                setShowModal(true);
            } else if (modalName !== modal) {
                setShowModal(false);
            }
        }
    }, [setShowModal, modal, modalName, superImposed]);

    // Register modal
    useEffect(() => {
        dispatch(actions.registerModal(modalName));
    }, [dispatch, modalName]);

    return [showModal, toggle, params];
};

export default useModal;
