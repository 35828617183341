import classNames from 'classnames';
import React from 'react';

import KTUtil from '@src/_metronic/_assets/js/util';

import { Customer } from '@app/crud/customers/customer.type';

import { useAppSelector } from '@app/hooks';
import { leadsCanBeLinked } from '@app/pages/customers/Helpers/Lead';
import useActionHandler from '@app/pages/customers/Hooks/useActionHandler';
import LeadActions from '@app/pages/customers/Partials/LeadRow/LeadActions';
import { Lead } from '@app/store/customers/customers.store';

import HeadBand from '@app/partials/layout/HeadBand';

import ActionHandBand from './ActionHandBand';
import AppointmentHandBand from './AppointmentHandBand';
import CallbackHandBand from './CallbackHandBand';
import CallbackMarketingHandBand from './CallbackMarketingHandBand';
import FullHandBand from './FullHandBand';
import LinkLeadHandBand from './LinkLeadHandBand';
import PendingHandBand from './PendingHandBand';

type CustomerHandBandProps = {
    customer: Customer;
    lead: Lead;
};
const CustomerHandBand = ({ customer, lead }: CustomerHandBandProps) => {
    const height = '150px';
    const scrollHeight = '60px';
    const handleActionClick = useActionHandler(lead);
    const toLink = leadsCanBeLinked(customer?.leads);
    const { userData } = useAppSelector((state) => state.auth);
    const isAgentFid = userData.role.slug === 'agent-fid';

    const haveOnlyCentralLeads = customer?.leads?.every((customerLead) => customerLead.flags.includes('is_central'));
    const hasCentralLeadsNotAgentFid = !isAgentFid && haveOnlyCentralLeads;

    const getHandBand = () => {
        if (!lead || hasCentralLeadsNotAgentFid) {
            return <FullHandBand customer={customer} />;
        } else if (toLink) {
            return <LinkLeadHandBand lead={lead} customer={customer} />;
        } else if (isAgentFid) {
            return (
                <ActionHandBand
                    lead={lead}
                    customer={customer}
                    actions={<LeadActions lead={lead} onClick={handleActionClick} details={false} />}
                />
            );
        } else if (
            lead?.leadStatus?.slug === 'in_progress_action_to_do' &&
            lead?.leadType?.slug?.includes?.('marketing_campaign')
        ) {
            return (
                <CallbackMarketingHandBand
                    lead={lead}
                    customer={customer}
                    actions={<LeadActions lead={lead} onClick={handleActionClick} details={false} />}
                />
            );
        } else if (
            ['in_progress_action_to_do', 'taken', 'taken_30_min', 'taken_90_min'].includes(lead?.leadStatus?.slug)
        ) {
            return (
                <ActionHandBand
                    lead={lead}
                    customer={customer}
                    actions={<LeadActions lead={lead} onClick={handleActionClick} details={false} />}
                />
            );
        } else if (
            ['in_progress_relaunch_to_come', 'in_progress_relaunch_to_qualify'].includes(lead?.leadStatus?.slug)
        ) {
            return (
                <CallbackHandBand
                    lead={lead}
                    customer={customer}
                    actions={<LeadActions lead={lead} onClick={handleActionClick} details={false} />}
                />
            );
        } else if (
            [
                'in_progress_appointment_to_come',
                'in_progress_appointment_confirmed',
                'in_progress_appointment_to_qualify',
                'in_progress_appointment_to_confirm',
            ].includes(lead?.leadStatus?.slug)
        ) {
            return (
                <AppointmentHandBand
                    lead={lead}
                    customer={customer}
                    actions={<LeadActions lead={lead} onClick={handleActionClick} details={false} />}
                />
            );
        } else if (['pending', 'pending_30_min', 'pending_90_min'].includes(lead?.leadStatus?.slug)) {
            return (
                <PendingHandBand
                    lead={lead}
                    customer={customer}
                    actions={<LeadActions lead={lead} onClick={handleActionClick} details={false} />}
                />
            );
        }

        return null;
    };

    return (
        <>
            {!lead && KTUtil.isDesktopDevice() && (
                <HeadBand variant="customers-primary">
                    <FullHandBand customer={customer} />
                </HeadBand>
            )}
            {(lead || KTUtil.isMobileDevice()) && (
                <HeadBand
                    fixed
                    height={height}
                    scrollHeight={scrollHeight}
                    parent="kt_subheader"
                    variant={classNames({
                        'customers-danger': toLink && !isAgentFid,
                        'customers-green': !toLink && isAgentFid,
                        'customers-primary': !toLink && !isAgentFid,
                    })}
                >
                    {getHandBand()}
                </HeadBand>
            )}
        </>
    );
};

export default CustomerHandBand;
