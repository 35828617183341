import React from 'react';

import LeadRowAction from './LeadRowAction';

const LeadRow = ({
    lead,
    setLoadingExistedCustomers,
    clientBodyTemplate,
    immatBodyTemplate,
    statusBodyTemplate,
    dateCreatedBodyTemplate,
    flagsBodyTemplate,
    dealershipBodyTemplate,
}) => (
    <LeadRowAction lead={lead} setLoadingExistedCustomers={setLoadingExistedCustomers}>
        <div className="d-flex justify-content-between align-items-start">
            <div className="flex-grow-1 pr-3">{clientBodyTemplate(lead)}</div>
            <div>{flagsBodyTemplate(lead)}</div>
        </div>
        <div>{immatBodyTemplate(lead)}</div>
        <div>{dealershipBodyTemplate(lead)}</div>
        <div>
            {statusBodyTemplate(lead, false, {
                divider: '',
                className: 'd-flex flex-nowrap align-items-center my-2',
            })}
        </div>
        <div className="ml-3 status-date">{dateCreatedBodyTemplate(lead)}</div>
        <div className="lead__row-icon">
            <i className="las text-white la-angle-right" />
        </div>
    </LeadRowAction>
);

export default LeadRow;
