import { generatePath } from 'react-router-dom';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import {
    addApplication,
    getApplication,
    updateApplicationById,
    updateApplicationPermissionsById,
} from '@app/crud/autouser/application.crud';

import ROUTES from '@app/router/Routes';

import toast from '@app/partials/content/Toast';

import { actions } from './application.store';

export function fetchApplication(id) {
    return (dispatch) => {
        dispatch(actions.fetchApplication());

        let application = null;

        if (id) {
            Promise.resolve(getApplication(id))
                .then((values) => {
                    application = values || null;

                    if (application?.data?.error && id) {
                        dispatch(actions.fetchApplicationFailed());
                    }
                })
                .catch(() => {
                    dispatch(actions.fetchApplicationFailed());
                })
                .finally(() => {
                    dispatch(
                        actions.fetchedApplication({
                            application: application?.data || null,
                        }),
                    );
                });
        }

        dispatch(
            actions.fetchedApplication({
                application: null,
            }),
        );
    };
}

export function createApplication(params) {
    return (dispatch) => {
        dispatch(actions.addApplication());
        addApplication(params.data)
            .then((response) => {
                if (response?.id) {
                    dispatch(actions.addedApplication(response.result));
                    params.history.push(generatePath(ROUTES.APPLICATION.PATH.UPDATE, { id: response.id }));
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'AUTOUSER.APPLICATIONS.ADD.SUCCESS' }),
                    });
                }
            })
            .catch((error) => {
                const { code } = error.response.data[0].name;
                dispatch(actions.addApplicationFailed({}));
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: `${code}` }),
                });
            });
    };
}

export const updateApplication = (id, data) => (dispatch) => {
    dispatch(actions.updateApplication());

    const {name, ...permissions} = data

    Promise.all([updateApplicationById(id, {name}), updateApplicationPermissionsById(permissions)])
      .then(response => {
        if(!response[0]?.result?.error) {
          dispatch(actions.updatedApplication(response));
          toast({
            variant: 'success',
            message: Intl.formatMessage({id: 'AUTOUSER.APPLICATIONS.UPDATE.SUCCESS'})
          });
        } else {
          dispatch(actions.updateApplicationFailed(response.result));
          toast({
            variant: 'danger',
            message: Intl.formatMessage({id: 'AUTOUSER.APPLICATIONS.UPDATE.FAILED'})
          });
        }
      })
      .catch(() => {
        dispatch(actions.updateApplicationFailed({}));
        toast({
          variant: 'danger',
          message: Intl.formatMessage({id: 'AUTOUSER.APPLICATIONS.UPDATE.FAILED'})
        });
    });
};
