import dayjs from 'dayjs';
import { Skeleton } from 'primereact/skeleton';
import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';

import { getSchedules } from '@app/crud/schedule/schedule.crud';

import EventModal from '@app/pages/agenda/partials/Event.Modal';

import { TODAY, TOMORROW } from '../../helpers/DashboardHelper';

import DashboardAgendaTimeline from './DashboardAgendaTimeline';

const DashboardAgendaTabs = ({ picture, userData }) => {
    const [activeTab, setActiveTab] = useState(TODAY);
    const [event, setEvent] = useState(false);
    const {
        fetch: fetchEvents,
        data,
        loading,
    } = useFetch({
        fetchAction: getSchedules,
    });
    const appointments = data?.appointments || [];

    const updateAgenda = useCallback(() => {
        let dateStart = '';

        switch (parseInt(activeTab, 10)) {
            case TODAY:
                dateStart = dayjs().format('YYYY-MM-DD');
                break;
            case TOMORROW:
                dateStart = dayjs().add(1, 'day').format('YYYY-MM-DD');
                break;
            default:
                break;
        }

        fetchEvents({
            userId: userData?.id,
            userEmail: userData?.email,
            dateStart,
        });
    }, [fetch, activeTab]);

    useEffect(() => {
        updateAgenda();
    }, [updateAgenda]);

    if (loading) {
        return <DashboardAgendaTabs.Skeleton />;
    }
    return (
        <>
            <div>
                <Tabs
                    className="nav-tabs-line nav-tabs-line-primary nav-tabs-line-2x"
                    defaultActiveKey={TODAY}
                    activeKey={activeTab}
                    onSelect={(e) => setActiveTab(e)}
                    id="dashboard-agenda-tabs"
                >
                    <Tab
                        eventKey={TODAY}
                        tabClassName="text-decoration-none"
                        title={Intl.formatMessage({ id: `DASHBOARD.CALENDAR.TODAY` })}
                    />
                    <Tab
                        eventKey={TOMORROW}
                        tabClassName="text-decoration-none"
                        title={Intl.formatMessage({ id: `DASHBOARD.CALENDAR.TOMORROW` })}
                    />
                </Tabs>
            </div>
            <DashboardAgendaTimeline
                appointments={appointments}
                activeTab={activeTab}
                picture={picture}
                setEvent={setEvent}
            />
            <EventModal event={event} user={userData} />
        </>
    );
};

DashboardAgendaTabs.defaultProps = {
    picture: true,
};

DashboardAgendaTabs.Skeleton = () => (
    <Skeleton borderRadius="10px" className="nav-tabs-line nav-tabs-line-primary nav-tabs-line-2x p-30" />
);

export default DashboardAgendaTabs;
