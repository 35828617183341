import React from 'react';
import { FormattedMessage } from 'react-intl';
import {Image} from "react-bootstrap";
import { Tag } from 'primereact/tag';

import "./Event.Caption.scss"

export default function EventCaption() {

    return (
        <div className="agenda-event-caption">
            <Tag rounded={true} style={{backgroundColor: '#0072C6'}}>
                <Image src={'/media/icons/agenda-source-outlook-white.svg'} width="19px" className="img-tag"/>
                <FormattedMessage id="AGENDA.HEADER.TAG.OUTLOOK" />
            </Tag>
            <Tag rounded={true} style={{backgroundColor: '#494B73'}}>
                <Image src={'/media/icons/agenda-source-automanager-white.svg'} width="19px" className="img-tag"/>
                <FormattedMessage id="AGENDA.HEADER.TAG.AUTOMANAGER" />
            </Tag>
            <Tag rounded={true} style={{backgroundColor: '#F84C00'}}>
                <Image src={'/media/icons/agenda-source-automanager-white.svg'} width="19px" className="img-tag"/>
                <FormattedMessage id="AGENDA.HEADER.TAG.CALLBACKS" />
            </Tag>
            <Tag rounded={true} style={{backgroundColor: '#EDF0FF', paddingBottom: '5px'}}>
                <span style={{color: '#868aa8'}}>
                    <FormattedMessage id="AGENDA.HEADER.TAG.REST" />
                </span>
            </Tag>
        </div>
    );
};
