import classNames from 'classnames';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { ApiResponse } from '@app/helpers/AxiosHelper';

import { Order } from '@app/crud/apv/order.type';
import { getVehicleByPlate } from '@app/crud/apv/vehicle.crud';
import { Vehicle } from '@app/crud/apv/vehicle.type';

import { useAppSelector, useFetch, useModal } from '@app/hooks';
import DealershipSelect from '@app/pages/customers/Partials/DealershipSelect';
import VehicleKnowSelect from '@app/pages/customers/Partials/VehicleKnowSelect';

import Loader from '@app/partials/content/Loader';
import toast from '@app/partials/content/Toast';

import FormBrandDropdownAutovista from '@app/components/forms/apv/FormBrandDropdownAutovista';
import FormDateTimePicker from '@app/components/forms/apv/FormDateTimePicker';
import FormModelDropdownAutovista from '@app/components/forms/apv/FormModelDropdownAutovista';
import FormVersionDropdownAutovista from '@app/components/forms/apv/FormVersionDropdownAutovista';
import FormInputNumber from '@app/components/forms/FormInputNumber/FormInputNumber';
import FormInputText from '@app/components/forms/FormInputText/FormInputText';

import APVInStoreContext from '../APVInStoreContext';

interface APVInStoreDealershipProps {
    setIsCompatibleApvInStore: (show: boolean) => void;
}

const APVInStoreDealership = ({ setIsCompatibleApvInStore }: APVInStoreDealershipProps) => {
    const Intl = useIntl();
    const { stepperState } = useContext(APVInStoreContext);
    const [_show, _toggle, params] = useModal(false, 'apv_in_store') as [
        boolean,
        (show: boolean) => void,
        { order: Order },
    ];
    const { data: customer } = useAppSelector((state) => state.customer.customer);
    const [loading, setLoading] = useState(false);
    const [plateError, setPlateError] = useState(false);

    const methods = useFormContext();
    const { formState, watch, setValue, getValues, clearErrors } = methods;

    const { errors } = formState;

    const formVehicleKnown = watch('vehicleKnow');
    const formImmat = watch('immat');

    const {
        fetch: fetchVehicleByPlate,
        data: vehicle,
        error: errorVehicleByPlate,
        loading: loadingVehicleByPlate,
    } = useFetch<{ plate: string }, ApiResponse<Vehicle>, Vehicle>({
        fetchAction: getVehicleByPlate,
        resultInterceptor: (response: ApiResponse<Vehicle>) => response?.result || null,
    });

    const clearInputs = () => {
        setValue('dateFirstRegistration', '');
        setValue('brand', '');
        setValue('model', '');
        setValue('version', '');
        setValue('energy', '');
        setValue('gearbox', '');
    };

    const searchVehicle = () => {
        clearInputs();
        clearErrors();
        setValue('kms', '');
        if (formImmat) {
            fetchVehicleByPlate({ plate: formImmat });
        }
    };

    const emptyValues = () => {
        clearErrors();
        setValue('immat', '');
        setValue('dateFirstRegistration', '');
        setValue('brand', '');
        setValue('model', '');
        setValue('version', '');
        setValue('energy', '');
        setValue('gearbox', '');
        setValue('kms', '');
    };
    // Set values when user selects a vehicle from the list
    useEffect(() => {
        if (formVehicleKnown) {
            if (get(formVehicleKnown, 'vehicleUuid') || get(formVehicleKnown, 'registrationNumber')) {
                setValue('immat', get(formVehicleKnown, 'registrationNumber', ''));
                setValue('dateFirstRegistration', get(formVehicleKnown, 'circulationDate', ''));
                fetchVehicleByPlate({ plate: get(formVehicleKnown, 'registrationNumber') });
            } else {
                emptyValues();
            }
        }
    }, [formVehicleKnown]);

    useEffect(() => {
        (async () => {
            if (params?.order) {
                try {
                    setLoading(true);

                    setValue('id', params.order.id);
                    setValue('dealership', params.order.dealership?.dealershipSource?.id);
                    setValue('immat', params.order.registration);
                    setValue('dateFirstRegistration', params.order.dateFirstRegistration);
                    setValue('kms', params.order.mileage);

                    fetchVehicleByPlate({
                        plate: params.order.registration,
                    });

                    setIsCompatibleApvInStore(true);
                } catch (err) {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.APVINSTORE.REGISTRATION.SEARCH.ERROR' }),
                    });
                } finally {
                    setLoading(false);
                }
            }
        })();
    }, [params?.order]);

    useEffect(() => {
        if (errorVehicleByPlate) {
            setPlateError(true);
            setIsCompatibleApvInStore(false);
            toast({
                variant: 'danger',
                message: Intl.formatMessage({
                    id: 'CUSTOMERS.MODAL.APVINSTORE.REGISTRATION.SEARCH.INCOMPATIBLE.ERROR',
                }),
                life: 6000,
            });
        } else if (vehicle) {
            setPlateError(false);
            setIsCompatibleApvInStore(true);
        }
    }, [errorVehicleByPlate, vehicle]);

    useEffect(() => {
        if (formImmat) {
            fetchVehicleByPlate({ plate: formImmat });
        }
    }, []);

    // Clear inputs and errors when the user changes the registration number before searching
    useEffect(() => {
        if (formImmat) {
            clearInputs();
            clearErrors();
        }
    }, [formImmat]);

    // Set values on draft order
    useEffect(() => {
        if (vehicle?.dateMEC) {
            setValue('dateFirstRegistration', dayjs(vehicle.dateMEC).format('YYYY-MM-DD'));
        }
        if (vehicle?.make) {
            setValue('brand', { name: vehicle.make });
        }
        if (vehicle?.model) {
            setValue('model', { name: vehicle.model });
        }
    }, [vehicle]);

    // Set Version on draft order
    useEffect(() => {
        if (vehicle?.version && params?.order) {
            const foundVersion = Object.values(vehicle?.version).find(
                (version) => Number(version.NationalVehicleCode) === params.order.nationalCode,
            );
            setValue('version', foundVersion);
        }
    }, [vehicle?.version, params?.order]);

    return (
        <div>
            {(loadingVehicleByPlate || loading) && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}

            <DealershipSelect methods={methods} customer={customer} required={stepperState.currentStep === 0} />
            <VehicleKnowSelect customer={customer} />

            <div className={classNames({ hide: !formVehicleKnown, 'd-block': !!formVehicleKnown || !!params?.order })}>
                <Row>
                    <Col md={6} lg={6}>
                        <Container fluid>
                            <Row>
                                <Col md={10} lg={10} className="px-0">
                                    <FormInputText
                                        label={
                                            <>
                                                <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.REGISTRATION" />*
                                            </>
                                        }
                                        name="immat"
                                        isInvalid={!!errors.immat}
                                        required={stepperState.currentStep === 0}
                                        pattern={{
                                            value: /^[A-Za-z]{2}[-]?[0-9]{3}[-]?[A-Za-z]{2}$/,
                                            message: Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.APVINSTORE.REGISTRATION.ERROR',
                                            }),
                                        }}
                                    />
                                    {!!plateError && (
                                        <div className="error-form">
                                            {Intl.formatMessage({
                                                id: 'CUSTOMERS.MODAL.APVINSTORE.REGISTRATION.SEARCH.INCOMPATIBLE.ERROR',
                                            })}
                                        </div>
                                    )}
                                </Col>
                                <Col md={2} lg={2} className="px-0 mt-8 pl-6">
                                    <Button onClick={searchVehicle} disabled={!formImmat}>
                                        <i className="las la-search p-0" />
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col md={6} lg={6}>
                        <FormDateTimePicker
                            label={
                                <>
                                    <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.DATE_FIRST_CIRCULATION" />*
                                </>
                            }
                            name="dateFirstRegistration"
                            dateFormat="DD/MM/YYYY"
                            maxDate={dayjs().toDate()}
                            placeholder="JJ/MM/AAAA"
                            isInvalid={!!errors.dateFirstRegistration}
                            required={stepperState.currentStep === 0}
                            disabled={!plateError}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} lg={6}>
                        <FormBrandDropdownAutovista
                            label={
                                <>
                                    <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.BRAND" />*
                                </>
                            }
                            name="brand"
                            placeholder={Intl.formatMessage({
                                id: 'CUSTOMERS.MODAL.APVINSTORE.BRAND.PLACEHOLDER',
                            })}
                            vehicle={vehicle}
                            isInvalid={!!errors.brand}
                            required={stepperState.currentStep === 0}
                            disabled
                        />
                    </Col>
                    <Col md={6} lg={6}>
                        <FormModelDropdownAutovista
                            label={
                                <>
                                    <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.MODEL" />*
                                </>
                            }
                            name="model"
                            placeholder={Intl.formatMessage({
                                id: 'CUSTOMERS.MODAL.APVINSTORE.MODEL.PLACEHOLDER',
                            })}
                            vehicle={vehicle}
                            isInvalid={!!errors.model}
                            required={stepperState.currentStep === 0}
                            disabled
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={12}>
                        <FormVersionDropdownAutovista
                            label={
                                <>
                                    <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.VERSION" />*
                                </>
                            }
                            name="version"
                            vehicle={
                                Array.isArray(vehicle?.version) && vehicle?.version?.length > 0
                                    ? vehicle
                                    : getValues('version')
                            }
                            isInvalid={!!errors.version}
                            placeholder={Intl.formatMessage({
                                id: 'CUSTOMERS.MODAL.APVINSTORE.VERSION.PLACEHOLDER',
                            })}
                            required={stepperState.currentStep === 0}
                            disabled={!formImmat || !!plateError}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={12}>
                        <FormInputNumber
                            label={
                                <>
                                    <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.MILEAGE" />*
                                </>
                            }
                            name="kms"
                            isInvalid={!!errors.kms}
                            inputGroupAfter={
                                <span className="p-inputgroup-addon">
                                    <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.MILEAGE.HINT.KM" />
                                </span>
                            }
                            required={stepperState.currentStep === 0}
                            format
                            min={0}
                            max={999999}
                            disabled={!formImmat || !!plateError}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default APVInStoreDealership;
