import { Slider } from 'primereact/slider';
import React, { useEffect, useRef } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import Permission from '@app/partials/content/Permission';
import SwitchInput from '@app/partials/content/SwitchInput';

const VehicleFilters = ({ data, onSubmit }) => {
    const mileageInput = useRef();
    const facets = data?.facets || [];
    const { register, control, getValues, setValue } = useFormContext();
    const Intl = useIntl();

    const handleInternalTypeChange = (value, onChange) => {
        const internalTypes = getValues('internal_type').split(',');
        const idx = internalTypes.indexOf(value);

        if (idx !== -1) {
            internalTypes.splice(idx, 1);
        } else {
            internalTypes.push(value);
        }

        onChange(internalTypes.join(','));
        onSubmit();
    };

    useEffect(() => {
        register('mileage');
        register('price');
    }, [register]);

    const handleSort = (a, b) => {
        const keyA = a.toUpperCase();
        const keyB = b.toUpperCase();

        let comparison = 0;
        if (keyA > keyB) {
            comparison = 1;
        } else if (keyA < keyB) {
            comparison = -1;
        }
        return comparison;
    };

    /* eslint-disable jsx-a11y/control-has-associated-label */
    return (
        <div>
            <Controller
                name="internal_type"
                control={control}
                defaultValue=""
                render={(props) => (
                    <div className="d-flex">
                        <SwitchInput
                            name="vo"
                            form={false}
                            onChange={() => handleInternalTypeChange('vo', props.onChange)}
                            labelFirst=""
                            labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.VO' })}
                            className="mr-3 switch-input--active-label-right-secondary switch-input--inactive-toggle-lighten switch-input--active-toggle-primary"
                            active={props.value.includes('vo')}
                        />
                        <SwitchInput
                            name="vn"
                            form={false}
                            onChange={() => handleInternalTypeChange('vn', props.onChange)}
                            labelFirst=""
                            labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.VN' })}
                            className="mr-3 switch-input--active-label-right-secondary switch-input--inactive-toggle-lighten switch-input--active-toggle-primary"
                            active={props.value.includes('vn')}
                        />
                        <SwitchInput
                            name="vd"
                            form={false}
                            onChange={() => handleInternalTypeChange('vd', props.onChange)}
                            labelFirst=""
                            labelSecond={Intl.formatMessage({ id: 'TRANSLATOR.VD' })}
                            className="switch-input--active-label-right-secondary switch-input--inactive-toggle-lighten switch-input--active-toggle-primary"
                            active={props.value.includes('vd')}
                        />
                    </div>
                )}
            />
            <Form.Group className="mt-5">
                <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE.BRAND' })}</Form.Label>
                <Form.Control as="select" name="brand" defaultValue="" onChange={onSubmit} ref={register}>
                    <option value="">{Intl.formatMessage({ id: 'TRANSLATOR.SELECT' })}</option>
                    {facets?.brands?.buckets &&
                        facets?.brands?.buckets
                            .sort((a, b) => handleSort(a.key, b.key))
                            .map(({ key: brand }) => (
                                <option key={brand} value={brand}>
                                    {brand}
                                </option>
                            ))}
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE.MODEL' })}</Form.Label>
                <Form.Control as="select" name="model" defaultValue="" onChange={onSubmit} ref={register}>
                    <option value="">{Intl.formatMessage({ id: 'TRANSLATOR.SELECT' })}</option>
                    {facets?.models?.buckets &&
                        facets?.models?.buckets
                            .sort((a, b) => handleSort(a.key, b.key))
                            .map(({ key: model }) => (
                                <option key={model} value={model}>
                                    {model}
                                </option>
                            ))}
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE.BODY_TYPE' })}</Form.Label>
                <Form.Control as="select" name="body_type" defaultValue="" onChange={onSubmit} ref={register}>
                    <option value="">{Intl.formatMessage({ id: 'TRANSLATOR.SELECT' })}</option>
                    {facets?.body_types?.buckets &&
                        facets?.body_types?.buckets
                            .sort((a, b) => handleSort(a.key, b.key))
                            .map(({ key: body_type }) => (
                                <option key={body_type} value={body_type}>
                                    {body_type}
                                </option>
                            ))}
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE.REGISTRATION' })}</Form.Label>
                <input
                    name="license_number"
                    className="form-control"
                    defaultValue=""
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') onSubmit();
                    }}
                    ref={register}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE.VIN' })}</Form.Label>
                <input
                    name="serial_number"
                    className="form-control"
                    defaultValue=""
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') onSubmit();
                    }}
                    ref={register}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE.REFERENCE' })}</Form.Label>
                <input
                    name="reference"
                    className="form-control"
                    defaultValue=""
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') onSubmit();
                    }}
                    ref={register}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label className="mb-0">
                    {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE.PRICE_RANGE' })}
                </Form.Label>
                <Controller
                    name="price_slider"
                    control={control}
                    defaultValue={[0, 100000]}
                    render={(props) => (
                        <>
                            <Form.Label className="my-3 d-flex justify-content-between text-right">
                                <NumberFormat
                                    value={props.value[0]}
                                    thousandSeparator=" "
                                    displayType="text"
                                    suffix=" €"
                                />
                                <NumberFormat
                                    value={props.value[1]}
                                    thousandSeparator=" "
                                    displayType="text"
                                    prefix={props.value[1] === 100000 ? '+ ' : ''}
                                    suffix=" €"
                                />
                            </Form.Label>
                            <Slider
                                value={props.value}
                                min={0}
                                max={100000}
                                range
                                onChange={(e) => {
                                    let value = `${e.value[0]},${e.value[1]}`;
                                    if (e.value[0] === 0 && e.value[1] === 100000) {
                                        value = '';
                                    } else if (e.value[1] === 100000) {
                                        value = `${e.value[0]},1000000`;
                                    }
                                    props.onChange(e.value);
                                    setValue('price', value);
                                }}
                                onSlideEnd={() => {
                                    onSubmit();
                                }}
                            />
                        </>
                    )}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label className="mb-0">
                    {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE.MILEAGE_RANGE' })}
                </Form.Label>
                <Controller
                    name="mileage_slider"
                    control={control}
                    defaultValue={[0, 100000]}
                    render={(props) => (
                        <>
                            <Form.Label className="my-3 d-flex justify-content-between text-right">
                                <NumberFormat
                                    value={props.value[0]}
                                    thousandSeparator=" "
                                    displayType="text"
                                    suffix=" km"
                                />
                                <NumberFormat
                                    value={props.value[1]}
                                    thousandSeparator=" "
                                    displayType="text"
                                    prefix={props.value[1] === 100000 ? '+ ' : ''}
                                    suffix=" km"
                                />
                            </Form.Label>
                            <Slider
                                value={props.value}
                                min={0}
                                max={100000}
                                range
                                onChange={(e) => {
                                    let value = `${e.value[0]},${e.value[1]}`;
                                    if (e.value[0] === 0 && e.value[1] === 100000) {
                                        value = '';
                                    } else if (e.value[1] === 100000) {
                                        value = `${e.value[0]},1000000`;
                                    }
                                    props.onChange(e.value);
                                    setValue('mileage', value);
                                }}
                                onSlideEnd={() => {
                                    onSubmit();
                                }}
                            />
                        </>
                    )}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>{Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE.MILEAGE_EXACT' })}</Form.Label>
                <InputGroup>
                    <Controller
                        name="mileage_input"
                        control={control}
                        defaultValue=""
                        render={(props) => (
                            <NumberFormat
                                value={props.value}
                                getInputRef={(el) => {
                                    if (el && !mileageInput.current) {
                                        mileageInput.current = el;
                                        el.addEventListener('keyup', (e) => {
                                            if (e.key === 'Enter') onSubmit();
                                        });
                                    }
                                }}
                                className="form-control"
                                thousandSeparator=" "
                                onValueChange={(values) => {
                                    const value = values.floatValue;

                                    if (values.floatValue) {
                                        const difference = value * 0.05;
                                        setValue('mileage', `${value - difference},${value + difference}`);
                                    } else {
                                        setValue('mileage', '');
                                    }

                                    props.onChange(value);
                                }}
                            />
                        )}
                    />
                    <InputGroup.Append>
                        <InputGroup.Text>km</InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
            </Form.Group>
            <Form.Group>
                <Permission permissions={['LEAD_LIST_FLUIDITE']}>
                    <SwitchInput
                        name="is_fluidite"
                        onChange={onSubmit}
                        labelFirst=""
                        labelSecond={Intl.formatMessage({ id: 'FLUIDITY.FILTER.SWITCH.AVAILABLE.VEHICLE' })}
                        className="mr-5 switch-input--active-label-right-closed switch-input--inactive-toggle-lighten switch-input--active-toggle-closed"
                    />
                </Permission>
            </Form.Group>
            <Button className="w-100" variant="primary" onClick={onSubmit}>
                {Intl.formatMessage({ id: 'TRANSLATOR.SEARCH' })}
            </Button>
        </div>
    );
};

export default VehicleFilters;
