import React, {useEffect} from "react";
import {Card, Col, Form} from "react-bootstrap";
import {Intl} from "../../../../../_metronic/i18n/I18nProvider";
import CardGeneralInformation from "../card/Card.general.information";
import CardCriteria from "../card/Card.criteria";
import CardDiscount from "../card/Card.discount";
import CardDetail from "../card/Card.detail";
import {Controller, useFormContext} from "react-hook-form";
import SelectPackage from "../../../../partials/content/Select.package";
import {MultiSelect} from "primereact/multiselect";
import * as dayjs from "dayjs";
import ErrorForm from "../../../../partials/layout/ErrorForm";

export default function FormLocal({ operation, packages, dealerships, showOperations, children }) {
    const {reset, errors, control} = useFormContext();

    useEffect(() => {
        reset({
            packageId: operation?.package?.id || null,
            dealerships: operation?.dealerships ? Object.values(operation.dealerships).map(dealership => dealership.id) : null,
            name: operation?.name || '',
            active: operation?.active || false,
            dateStart: operation && operation.dateStart ? dayjs(operation.dateStart).toDate() : null,
            dateEnd: operation && operation.dateEnd ? dayjs(operation.dateEnd).toDate() : null,
            discount: operation ? operation.percentage ? 'percentageDiscount' : operation.price ? 'priceDiscount' : 'noDiscount' : 'noDiscount',
            percentage: operation?.percentage || '',
            price: operation?.price || '',
            seoDescription: operation?.seoDescription || '',
        });
    }, [reset, operation]);

    return (
        <div>
            <Form.Row className="mb-4">
                <Col lg={12}>
                    <Form.Label>{Intl.formatMessage({ id: "APV.OPERATION.FORM.LINK.TYPE.PACKAGE" })}</Form.Label>
                </Col>
                <Col lg={4}>
                    <SelectPackage
                        packages={packages}
                        packageId={operation?.package?.id || null}
                    />
                    <ErrorForm errors={errors} name="packageId" />
                </Col>
            </Form.Row>
            <Form.Row className="mb-4">
                <Col lg={12}>
                    <Card className="shadow-sm">
                        <Card.Header>
                            <i className="card__icon far fa-building"/>
                            {Intl.formatMessage({ id: 'APV.OPERATION.FORM.CARD.DEALERSHIPS.CHOICE' })}
                        </Card.Header>
                        <Card.Body>
                            <Col lg={12}>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <Controller
                                                control={control}
                                                name="dealerships"
                                                rules={{
                                                    // required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                                    validate: (value) => {
                                                        return !value || value.length < 1 ? Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' })
                                                            : true
                                                    }
                                                }}
                                                render={(props) => (
                                                    <MultiSelect
                                                        name='dealerships'
                                                        value={props.value}
                                                        options={dealerships.map((dealership) => {
                                                            return {
                                                                name: dealership.dealershipSource.usual_name,
                                                                id: dealership.id
                                                            };
                                                        })}
                                                        onChange={(e) => props.onChange(e.value)}
                                                        optionLabel="name"
                                                        optionValue="id"
                                                        placeholder={Intl.formatMessage({ id: 'APV.OPERATION.FORM.BUTTON.MODAL.DEALERSHIPS.LISTING' })}
                                                        display="chip"
                                                        filter
                                                        required={true}
                                                        className={`${errors.dealerships ? 'is-invalid' : ''}`}
                                                    />
                                                )}/>
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <ErrorForm errors={errors} name="dealerships" />
                                    </div>
                                </div>
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>
            </Form.Row>
            <Form.Row className="mb-4">
                <Col lg={4}>
                    <CardGeneralInformation />
                </Col>
                <Col lg={4}>
                    <CardCriteria />
                </Col>
                <Col lg={4}>
                    <CardDiscount />
                </Col>
            </Form.Row>
            <Form.Row>
                <Col lg={12}>
                    <CardDetail height="250"/>
                </Col>
            </Form.Row>
            {children}
        </div>
    )
}

