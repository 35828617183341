/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import { LayoutContextProvider } from '@src/_metronic';
import Layout from '@src/_metronic/layout/Layout';

import AuthPage from '@app/pages/auth/AuthPage';
import Identifier from '@app/pages/autouser/identifier/Identifier';
import Consent from '@app/pages/consent';
import ErrorsPage from '@app/pages/errors/ErrorsPage';
import HomePage from '@app/pages/home/HomePage';

import * as routerHelpers from './RouterHelpers';

const RoutesWrapper = () => {
    const lastLocation = useLastLocation();
    routerHelpers.saveLastLocation(lastLocation);
    const {
        isAuthorized,
        menuConfig: config,
        userLastLocation,
    } = useSelector(
        ({ auth, builder: { menuConfig } }) => ({
            menuConfig,
            isAuthorized: auth.user != null,
            userLastLocation: routerHelpers.getLastLocation(),
        }),
        shallowEqual,
    );

    const history = useHistory();
    const userLastLocationPathName = userLastLocation?.pathname || '';

    return (
        /* Create `LayoutContext` from current `history` and `menuConfig`. */
        <LayoutContextProvider history={history} menuConfig={config}>
            <Switch>
                <Route exact path="/consent/:token">
                    <Consent />
                </Route>
                <Route exact path="/autouser/login">
                    <Identifier />
                </Route>

                {!isAuthorized ? (
                    /* Render auth page when user at `/auth` and not authorized. */
                    <AuthPage />
                ) : (
                    /* Otherwise redirect to root page (`/`) */
                    <Redirect
                        from="/auth"
                        to={!userLastLocationPathName.includes('error') ? userLastLocation : '/dashboard'}
                    />
                )}

                <Route path="/error">
                    <Layout>
                        <ErrorsPage />
                    </Layout>
                </Route>

                {!isAuthorized ? (
                    /* Redirect to `/auth` when user is not authorized */
                    <Redirect to="/auth/login" />
                ) : (
                    <Layout>
                        <HomePage />
                    </Layout>
                )}
            </Switch>
        </LayoutContextProvider>
    );
};

export default RoutesWrapper;
