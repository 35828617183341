import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

import { VehicleHistoricalPrice } from '@app/store/stock/stock.type';

import Price from '@app/components/formatters/price/Price';

type StockPriceHistoryRecommendationItemProps = {
    item: VehicleHistoricalPrice;
};

const StockPriceHistoryRecommendationItem = ({ item }: StockPriceHistoryRecommendationItemProps) => {
    const [priceHistory, setPriceHistory] = useState(null);

    const colorHandler = (status: string) => {
        let item = {};

        switch (status) {
            case 'confirmed':
                item = {
                    type: 'accepted',
                    color: 'text-success',
                    message: 'STOCK_VEHICLES.MODAL.PRICE.PRICE_RECO.ACCEPTATION',
                };
                break;
            case 'refused':
                item = {
                    type: 'refused',
                    color: 'text-danger',
                    message: 'STOCK_VEHICLES.MODAL.PRICE.PRICE_RECO.REFUSAL',
                };
                break;
            case 'modified':
                item = {
                    type: 'modified',
                    color: 'text-primary',
                    message: 'STOCK_VEHICLES.MODAL.PRICE.PRICE_RECO.MODIFICATION',
                };
                break;
            default:
                item = {
                    type: 'creation',
                    color: '',
                    message: 'STOCK_VEHICLES.MODAL.PRICE.PRICE_RECO.CREATION',
                };
                break;
        }
        return item;
    };

    const renderTooltip = (props: any) => (
        <Tooltip {...props}>
            {priceHistory.type === 'modified' && (
                <>
                    <strong>
                        <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.PRICE_RECO.TITLE" />
                        <NumberFormat value={item.recoPrice} thousandSeparator=" " displayType="text" suffix=" €" />
                    </strong>
                    <br />
                    <br />
                </>
            )}
            {item.pricingReason && (
                <>
                    <strong>
                        <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.PRICE_REASON.TITLE" />
                    </strong>
                    <br />
                    <p>{item.pricingReason.description}</p>
                </>
            )}
            {item.comment && (
                <>
                    <strong>
                        <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.PRICE_COMMENT.TITLE" />
                    </strong>
                    <br />
                    <p>{item.comment}</p>
                </>
            )}
        </Tooltip>
    );

    useEffect(() => {
        setPriceHistory(colorHandler(item.pricingStatus?.slug));
    }, [item]);

    return (
        priceHistory && (
            <div className={`d-flex flex-wrap align-items-center ${priceHistory?.color}`}>
                {priceHistory.type === 'creation' ? (
                    <>
                        <strong>
                            <FormattedMessage id={priceHistory?.message} />
                        </strong>
                        &nbsp;
                        <Price value={item.recoPrice} />
                    </>
                ) : (
                    <>
                        <FormattedMessage id={priceHistory?.message} />{' '}
                        <FormattedMessage id="STOCK_VEHICLES.MODAL.PRICE.PRICE_RECO.SUFFIX" />
                        &nbsp;
                        <Price value={priceHistory?.type !== 'modified' ? item.recoPrice : item.newPrice} />
                        {priceHistory?.type !== 'accepted' && (
                            <OverlayTrigger placement="left" overlay={renderTooltip}>
                                <i className={`las la-info-circle ml-2 ${priceHistory?.color}`} />
                            </OverlayTrigger>
                        )}
                        {item.author && item.authorRole && (
                            <div className="d-block w-100" style={{ fontSize: '11px' }}>
                                <FormattedMessage id="STOCK_VEHICLE.CARD.PRICE_HANDLER.CONTENT.LEGEND.MODIFIED_BY" />
                                &nbsp;{item.author} {item.authorRole !== 'EFF-ADMIN' && <>&bull; {item.authorRole}</>}
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    );
};

export default StockPriceHistoryRecommendationItem;
