import usePermissions from '@app/hooks/usePermissions';
import useTypologies from '@app/hooks/useTypologies';

const useApvInStore = () => {
    const { hasTypology } = useTypologies();
    const { hasPermission } = usePermissions();
    const isAllowedApvInStore = hasTypology('local_apv_instore') && hasPermission('LEAD_APV_IN_STORE');

    return {
        isAllowedApvInStore,
    };
};

export default useApvInStore;
