import React, { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';

import EventCaption from '@app/partials/content/Agenda/Event.Caption';
import ModalDefault from '@app/partials/content/modals/Modal.default';

interface MobileCaptionModalProps {
    show?: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
}
const MobileCaptionModal = ({ show, setShow }: MobileCaptionModalProps) => {
    const Intl = useIntl();

    const handleClose = () => {
        setShow(false);
    };

    return (
        <ModalDefault
            centered
            show={show}
            hideModal={handleClose}
            icon={<i className="las la-2x text-primary la-info-circle" />}
            title={Intl.formatMessage({ id: 'AGENDA.MODAL.MOBILE.CAPTURE.TITLE' })}
            body={
                <div style={{ margin: '20px auto', textAlign: 'center' }}>
                    <EventCaption />
                </div>
            }
        />
    );
};

MobileCaptionModal.defaultProps = {
    show: false,
};

export default MobileCaptionModal;
