import classNames from 'classnames';
import React, { useEffect } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import { getObjectiveLocalLeads } from '@app/crud/dashboard/dashboard.crud';

import { useFetch } from '@app/hooks';

import Loader from '@app/partials/content/Loader';
import ProgressCircle from '@app/partials/content/ProgressCircle/ProgressCircle';

interface Props {
    className?: string;
}

const DashboardProgressCircle = ({ className }: Props) => {
    const { data, loading, error, fetch } = useFetch({
        fetchAction: getObjectiveLocalLeads,
    });

    useEffect(() => {
        fetch();
    }, [fetch]);

    useEffect(() => {
        console.error('API Error:', error);
    }, [error]);
    const maxObjectives = data.objective ?? 5;
    const leadsNumberCompleted = data.created_local_lead_count ?? 0;
    const percentage = leadsNumberCompleted > maxObjectives ? 100 : leadsNumberCompleted * (100 / maxObjectives);

    return (
        <div className={classNames('dashboard-progressCircle__container', className)}>
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <h1 className="dashboard-progressCircle__title">
                <FormattedHTMLMessage id="DASHBOARD.PROGRESS.CIRCLE.TITLE" />
            </h1>
            <div className="d-flex align-items-center">
                <ProgressCircle percentage={percentage} max={maxObjectives} />
                <p className="ml-3 mb-0">
                    <FormattedHTMLMessage id="DASHBOARD.PROGRESS.CIRCLE.INFO" />
                </p>
            </div>
        </div>
    );
};

DashboardProgressCircle.defaultProps = {
    className: '',
};

export default DashboardProgressCircle;
