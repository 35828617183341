/**
 * Check if persisted reducer is out to date
 *
 * @param state
 * @returns {boolean}
 */
// eslint-disable-next-line import/prefer-default-export
export const stateIsOutToDate = (state) => {
    const appVersion = localStorage.getItem('APP_VERSION');
    const stateVersion = state?._persist?.version;

    return appVersion !== stateVersion;
};
