import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { showDocument } from '@app/crud/administration/alerts';

import { useModal } from '@app/hooks';
import { Alert } from '@app/pages/administration/alerts/alerts.type';

import ModalDefault from '@app/partials/content/modals/Modal.default';

import { addSeenAlert, checkForExpiredAlerts } from '../helpers/AlertsOngoingHelper';

import AlertsDocumentDownloadButton from './AlertsDocumentDownloadButton';

type AlertsModalProps = {
    alerts: Alert[];
};

const severityColor = {
    high: 'text-danger',
    medium: 'text-warning',
    low: 'text-primary',
};

const AlertsModal = ({ alerts }: AlertsModalProps) => {
    const [currentAlert, setCurrentAlert] = useState(0);
    const [showModal, setShowModal] = useModal(false, 'alerts');
    const alert = alerts[currentAlert];
    const alertColor = severityColor[alert.alertLevel.slug];

    useEffect(() => {
        if (alerts.length) checkForExpiredAlerts(alerts);
    }, [alerts]);

    const markCurrentAlertAsSeen = () => {
        addSeenAlert(alerts[currentAlert].id.toString());
    };

    const onAlertChange = (direction: 'prev' | 'next') => {
        markCurrentAlertAsSeen();
        setCurrentAlert(currentAlert + (direction === 'prev' ? -1 : 1));
    };

    const onModalClose = () => {
        setShowModal(false);
        setCurrentAlert(0);
        markCurrentAlertAsSeen();
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => onModalClose()}
            body={
                <>
                    <div className="text-center modal-title mt-0 h4">
                        <div className={`px-lg-0 px-5 text-center ${alertColor}`}>
                            <i className={`la la-2x la-bell ${alertColor}`} />
                            <div>{alert.title}</div>
                        </div>
                    </div>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div className="modal-body text-center" dangerouslySetInnerHTML={{ __html: alert.content }} />
                    <div className="d-flex flex-wrap align-items-center justify-content-center gap-3">
                        {alert.alertDocuments.length > 0 &&
                            alert.alertDocuments.map((item) => (
                                <AlertsDocumentDownloadButton
                                    key={item.document.id}
                                    title={item.label}
                                    route={() => showDocument(item.document.id)}
                                />
                            ))}
                    </div>
                </>
            }
            footer={
                <div className="text-center">
                    {currentAlert > 0 && (
                        <Button variant="outline-secondary" onClick={() => onAlertChange('prev')} className="mr-5">
                            <FormattedMessage id="TRANSLATOR.PREVIOUS" />
                        </Button>
                    )}
                    <Button variant="primary" onClick={() => onModalClose()}>
                        <FormattedMessage id="TRANSLATOR.OK" />
                    </Button>
                    {currentAlert < alerts.length - 1 && (
                        <Button variant="outline-secondary" onClick={() => onAlertChange('next')} className="ml-5">
                            <FormattedMessage id="TRANSLATOR.NEXT" />
                        </Button>
                    )}
                </div>
            }
        />
    );
};

export default AlertsModal;
