import ApiGateway from '@app/helpers/AxiosHelper';
import { getFormData } from '@app/helpers/ObjectHelper';

const { REACT_APP_APV_URL } = process.env;

const baseUrl = `${REACT_APP_APV_URL}/commercial/operation`;

export function getSettings() {
    return ApiGateway.get(`${baseUrl}/settings`);
}

export function getOperations(params) {
    return ApiGateway.get(`${baseUrl}/back`, {
        params,
    });
}

export function getOperation(id) {
    return ApiGateway.get(`${baseUrl}/${id}`);
}

export function addOperation(data) {
    return ApiGateway.post(`${baseUrl}`, getFormData(data));
}

export function updateOperationById(id, data) {
    return ApiGateway.post(`${baseUrl}/${id}`, getFormData(data));
}

export function deleteOperation(id) {
    return ApiGateway.delete(`${baseUrl}/${id}`);
}
