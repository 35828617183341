import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { AutoComplete, AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

import useFetch from '@app/hooks/useFetch';

import { ApiResponse } from '@app/helpers/AxiosHelper';
import { getDateByFormat } from '@app/helpers/DateHelper';

import {
    addLeadPartExchange,
    getAFRPartExchangeByReference,
    getPartExchangePictures,
    LeadPartExchange,
} from '@app/crud/customers/customer.crud';
import { EventDetailType } from '@app/crud/customers/customer.type';
import { getInformationsByRegistration } from '@app/crud/customers/stock.crud';
import { Gearbox, Informations, Version, Versions } from '@app/crud/customers/stock.type';
import { mapAddPartExchange } from '@app/crud/mapping/eventDetail.map';

import { useAppSelector } from '@app/hooks';

import BrandModelVersionFinish from '@app/partials/content/BrandModelVersionFinish';
import { DateTimePicker } from '@app/partials/content/DateTimePicker';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import SwitchInput from '@app/partials/content/SwitchInput';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

interface PartExchangeProps {
    event: EventDetailType;
    showModal: boolean;
    setShowModal: (v: boolean) => void;
    // eslint-disable-next-line @typescript-eslint/ban-types
    fetchData: Function;
}

const PartExchange = ({ event, showModal, fetchData, setShowModal }: PartExchangeProps) => {
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [isPartExchangeAFR, setIsPartExchangeAFR] = useState(false);
    const [filteredPartExchangeAFR, setFilteredPartExchangeAFR] = useState([]);
    const [selectedPartExchangeAFR, setSelectedPartExchangeAFR] = useState(null);
    const { userData } = useAppSelector((state) => state.auth);
    const context = userData.context.toUpperCase();
    const [versions, setVersions] = useState(null);
    const Intl = useIntl();
    const { fetch } = useFetch<string, ApiResponse<LeadPartExchange>>({
        fetchAction: getAFRPartExchangeByReference,
    });
    const methods = useForm();
    const { register, getValues, setValue, errors, control, handleSubmit } = methods;
    const [defaultBrandModelVersionFieldsValues, setDefaultBrandModelVersionFieldsValues] = useState({
        make: '',
        model: '',
        version: '',
        typeVarVersPrf: '',
        dateFirstRegistration: '',
    });

    const handleNext = () => {
        if (step < 2 && !isPartExchangeAFR) {
            setStep(step + 1);
        } else {
            const data: LeadPartExchange = (
                isPartExchangeAFR ? mapAddPartExchange(selectedPartExchangeAFR) : { ...getValues(), priceRebate: null }
            ) as LeadPartExchange;
            const leadId = event?.informations?.identifier;
            if (!isPartExchangeAFR) {
                data.partExchangeReference = null;
            }

            setLoading(true);

            addLeadPartExchange(leadId.toString(), data)
                .then(() => {
                    setLoading(false);
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.RECOVERY.UPDATE_SUCCESS' }),
                    });
                    fetchData(leadId).then((responseData: any) => {
                        if (responseData?.result?.partExchange.length === 1) {
                            setSelectedPartExchangeAFR(null);
                            setFilteredPartExchangeAFR([]);
                        }
                    });
                    setShowModal(false);
                    setStep(1);
                })
                .catch(() => {
                    setLoading(false);
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'CUSTOMERS.VIEW.LIFE.DETAIL.RECOVERY.UPDATE_FAILED' }),
                    });
                });
        }
    };

    const handlePrev = () => {
        if (step > 1) {
            setStep(step - 1);
        } else {
            setShowModal(false);
        }
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    const searchPartExchangeAFR = (eventData: AutoCompleteCompleteEvent) => {
        setTimeout(() => {
            if (eventData?.query?.trim().length) {
                fetch(eventData?.query?.trim()).then((response) => {
                    if (response && !Array.isArray(response) && response.result) {
                        setFilteredPartExchangeAFR([response?.result]);
                    } else {
                        setFilteredPartExchangeAFR([]);
                    }
                });
            } else {
                setFilteredPartExchangeAFR([]);
            }
        }, 250);
    };

    const itemTemplate = (item: {
        reference: string;
        partExchangeVehicle: {
            make: string;
            model: string;
            fuelType: string;
            mileage: string | number;
        };
    }) => (
        <div className="part-exchange-item d-flex flex-column">
            <strong>{item?.reference}</strong>
            <span>
                {item?.partExchangeVehicle?.make} {item?.partExchangeVehicle?.model}
            </span>
            <span>{item?.partExchangeVehicle?.fuelType}</span>
            <span>
                <NumberFormat
                    value={item?.partExchangeVehicle?.mileage}
                    thousandSeparator=" "
                    displayType="text"
                    suffix=" km"
                />
            </span>
        </div>
    );

    useEffect(() => {
        if (selectedPartExchangeAFR) {
            handleSubmit(handleNext, onError)();
        }
    }, [selectedPartExchangeAFR]);

    const onVersionsUpdated = (data: Versions) => {
        setVersions(data);

        if (Object.keys(data.filters.gearbox).length === 1) {
            setValue('gearbox', data.filters.gearbox[Object.keys(data.filters.gearbox)[0]].Name);
        }
    };

    const onVersionChange = (value: string) => {
        const version = versions?.version?.find(
            (versionToFind: Version) => `${versionToFind.Name} ${versionToFind.TrimLineName}` === value,
        );
        setValue('fuelType', version?.Engine?.Fuel?.NameEx || '');

        setValue('gearbox', version?.Transmission?.GearBox?.NameEx || '');

        if (version?.NationalVehicleCode) {
            getPartExchangePictures(version.NationalVehicleCode, 'front-left').then((response: AxiosResponse) => {
                const pictures = response.data.list;

                const picture = pictures.pop();
                if (picture) {
                    setValue('image', picture.url);
                }
            });
        }
    };

    useEffect(() => {
        if (getValues().version) {
            onVersionChange(getValues().version);
        }
    }, [versions]);

    const yearNavigatorTemplate = (e: any) => (
        <Dropdown
            value={e.value}
            options={e.options}
            onChange={(eventChange) => e.onChange(eventChange.originalEvent, eventChange.value)}
            className="p-ml-2"
            style={{ lineHeight: 1 }}
        />
    );

    const { fetch: fetchInformationsByRegistration } = useFetch<string, ApiResponse<Informations>, Informations>({
        fetchAction: getInformationsByRegistration,
        resultInterceptor: (response: ApiResponse<Informations>) => {
            const newDefaultBrandModelVersionFieldsValues = { ...defaultBrandModelVersionFieldsValues };

            const result = response.result as Informations;

            if (result?.dateMEC) {
                const dateRegex = /^(\d{4})(\d{2})(\d{2})$/;
                if (dateRegex.test(result.dateMEC)) {
                    setValue('dateFirstRegistration', result.dateMEC.replace(dateRegex, '$1-$2-$3'));
                    newDefaultBrandModelVersionFieldsValues.dateFirstRegistration = result.dateMEC.replace(
                        dateRegex,
                        '$1-$2-$3',
                    );
                }
            }

            if (result?.make) {
                newDefaultBrandModelVersionFieldsValues.make = result.make;
            }

            if (result?.model) {
                newDefaultBrandModelVersionFieldsValues.model = result.model;
            }

            if (result?.version.length > 0) {
                newDefaultBrandModelVersionFieldsValues.version = result.version[0].Version;
            }

            setDefaultBrandModelVersionFieldsValues(newDefaultBrandModelVersionFieldsValues);

            setValue('make', newDefaultBrandModelVersionFieldsValues.make);
            setValue('model', newDefaultBrandModelVersionFieldsValues.model);
            setValue('version', newDefaultBrandModelVersionFieldsValues.version);

            if (result?.vin) {
                setValue('vin', result.vin);
            }

            if (result?.typeVarVersPrf) {
                setValue('typeVarVersPrf', result.typeVarVersPrf);
            }

            if (result?.typeCG) {
                setValue('vehicleType', result.typeCG);
            }

            return result;
        },
    });

    const onRegistrationChange = (e: BaseSyntheticEvent) => {
        let plate = e.target.value;

        const shortSIVRegex = /^([a-zA-Z]{2})[- ]?(\d{3,4})[- ]?([a-zA-Z]{2})$/;

        if (shortSIVRegex.test(plate)) {
            plate = plate.replace(shortSIVRegex, '$1-$2-$3').toUpperCase();
            setValue('registration', plate);

            fetchInformationsByRegistration(plate).then(() => {});

            return;
        }

        const shortFNIRegex = /^(\d{3,4})[- ]?([a-zA-Z]{2})[- ]?(\d{1,2})$/;

        if (shortFNIRegex.test(plate)) {
            plate = plate.replace(shortFNIRegex, '$1 $2 $3').toUpperCase();
            setValue('registration', plate);

            fetchInformationsByRegistration(plate).then(() => {});
        }
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-undo" />}
            title={
                isPartExchangeAFR
                    ? Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.RECOVERY.TITLE' })
                    : Intl.formatMessage(
                          { id: 'CUSTOMERS.MODAL.PART_EXCHANGE.TITLE' },
                          {
                              step,
                              totalStep: 2,
                          },
                      )
            }
            body={
                <FormProvider {...methods}>
                    {context === 'EFF' && (
                        <Form.Group>
                            <SwitchInput
                                active={isPartExchangeAFR}
                                form={false}
                                name="isParExchangeAFR"
                                onChange={() => setIsPartExchangeAFR(!isPartExchangeAFR)}
                                labelFirst="Reprise classique"
                                labelSecond="Reprise AFR"
                            />
                        </Form.Group>
                    )}
                    {isPartExchangeAFR ? (
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id="CUSTOMERS.MODAL.PART_EXCHANGE.REFERENCE" />
                            </Form.Label>
                            <Controller
                                control={control}
                                name="partExchangeReference"
                                defaultValue={null}
                                render={({ onChange, value }) => (
                                    <AutoComplete
                                        value={value}
                                        suggestions={filteredPartExchangeAFR}
                                        field="partExchangeReference"
                                        placeholder="REP_B31215"
                                        completeMethod={searchPartExchangeAFR}
                                        onChange={(e) => onChange(e.value)}
                                        onSelect={(e) => {
                                            setSelectedPartExchangeAFR(e.value);
                                            onChange(e.value?.reference);
                                        }}
                                        className="w-100"
                                        inputClassName="form-control"
                                        itemTemplate={itemTemplate}
                                    />
                                )}
                            />
                        </Form.Group>
                    ) : (
                        <>
                            <div hidden={step === 2}>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="CUSTOMERS.MODAL.PART_EXCHANGE.REGISTRATION" />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        ref={register}
                                        name="registration"
                                        onChange={onRegistrationChange}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="CUSTOMERS.MODAL.PART_EXCHANGE.VIN" />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        // @ts-ignore
                                        ref={register({
                                            validate: (value) =>
                                                value.length === 0 ||
                                                value.length === 17 ||
                                                Intl.formatMessage({ id: 'CUSTOMERS.MODAL.PART_EXCHANGE.VIN_ERROR' }),
                                        })}
                                        name="vin"
                                    />
                                    <ErrorForm errors={errors} name="vin" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="CUSTOMERS.MODAL.PART_EXCHANGE.FIRST_REGISTRATION" />
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="dateFirstRegistration"
                                        defaultValue={null}
                                        rules={{ required: true }}
                                        render={({ onChange, value }) => (
                                            <DateTimePicker
                                                locale={Locale}
                                                dateFormat="DD/MM/YYYY"
                                                className={`${errors.dateStart ? 'is-invalid' : ''}`}
                                                onChange={(e: { value: string | number | Date | dayjs.Dayjs }) => {
                                                    const startValue = dayjs(e.value).isValid() ? dayjs(e.value) : '';
                                                    onChange(startValue ? startValue.format('YYYY-MM-DD') : '');
                                                }}
                                                value={getDateByFormat(value, 'YYYY-MM-DD', '')}
                                                yearNavigator
                                                monthNavigator
                                                yearRange={`1980:${new Date().getFullYear()}`}
                                                maxDate={new Date()}
                                                readOnlyInput
                                                yearNavigatorTemplate={yearNavigatorTemplate}
                                            />
                                        )}
                                    />
                                </Form.Group>
                                <BrandModelVersionFinish
                                    onLoading={setLoading}
                                    onVersionsUpdated={onVersionsUpdated}
                                    defaultsValues={{
                                        make: defaultBrandModelVersionFieldsValues.make,
                                        model: defaultBrandModelVersionFieldsValues.model,
                                        version: defaultBrandModelVersionFieldsValues.version,
                                        typeVarVersPrf: defaultBrandModelVersionFieldsValues.typeVarVersPrf,
                                        dateFirstRegistration: dayjs(
                                            defaultBrandModelVersionFieldsValues.dateFirstRegistration,
                                        ).format('MM/YYYY'),
                                    }}
                                />
                                {versions?.filters?.gearbox && (
                                    <Form.Group>
                                        <Form.Label>
                                            <FormattedMessage id="CUSTOMERS.MODAL.PART_EXCHANGE.GEARBOX" />
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="gearbox"
                                            defaultValue=""
                                            rules={{ required: true }}
                                            render={({ onChange, value }) => (
                                                <Dropdown
                                                    value={value}
                                                    options={Object.values(versions?.filters?.gearbox).map(
                                                        (gearbox: Gearbox) => ({
                                                            value: gearbox.Name,
                                                            label: gearbox.Name,
                                                        }),
                                                    )}
                                                    onChange={(e) => {
                                                        onChange(e.value);
                                                    }}
                                                    optionLabel="label"
                                                    optionValue="value"
                                                    placeholder={Intl.formatMessage({
                                                        id: 'CUSTOMERS.MODAL.APE_DROPDOWN.TITLE',
                                                    })}
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                )}
                            </div>
                            <div hidden={step === 1}>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="CUSTOMERS.MODAL.PART_EXCHANGE.FUEL_TYPE" />
                                    </Form.Label>
                                    <Form.Control type="text" ref={register} name="fuelType" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="CUSTOMERS.MODAL.PART_EXCHANGE.TYPE" />
                                    </Form.Label>
                                    <Form.Control type="text" ref={register} name="vehicleType" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="CUSTOMERS.MODAL.PART_EXCHANGE.MILEAGE" />
                                    </Form.Label>
                                    <Controller
                                        name="mileage"
                                        defaultValue={null}
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <InputGroup className="mb-2">
                                                <NumberFormat
                                                    value={value}
                                                    className="form-control"
                                                    thousandSeparator=" "
                                                    onValueChange={(values) => {
                                                        onChange(values.floatValue || null);
                                                    }}
                                                />
                                                <InputGroup.Append>
                                                    <InputGroup.Text>km</InputGroup.Text>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        )}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="CUSTOMERS.MODAL.PART_EXCHANGE.AMOUNT" />
                                    </Form.Label>
                                    <Controller
                                        name="estimatedPrice"
                                        defaultValue={null}
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <InputGroup className="mb-2">
                                                <NumberFormat
                                                    value={value}
                                                    className="form-control"
                                                    thousandSeparator=" "
                                                    onValueChange={(values) => {
                                                        onChange(values.floatValue || null);
                                                    }}
                                                />
                                                <InputGroup.Append>
                                                    <InputGroup.Text>€</InputGroup.Text>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        )}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="CUSTOMERS.MODAL.PART_EXCHANGE.COMMENT" />
                                    </Form.Label>
                                    <Form.Control as="textarea" ref={register} rows={4} name="comment" />
                                </Form.Group>
                                <Form.Group>
                                    <input name="image" type="hidden" ref={register} />
                                </Form.Group>
                            </div>
                        </>
                    )}
                </FormProvider>
            }
            footer={
                <div className="d-flex justify-content-end w-100">
                    <Button
                        variant="outline-secondary"
                        className="mr-5"
                        onClick={() => handlePrev()}
                        style={{ width: '120px' }}
                    >
                        {step === 1
                            ? Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })
                            : Intl.formatMessage({ id: 'TRANSLATOR.PREVIOUS' })}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSubmit(handleNext, onError)}
                        style={{ width: '120px' }}
                        id="submitPartExchange"
                    >
                        {step === 1 && !isPartExchangeAFR
                            ? Intl.formatMessage({ id: 'TRANSLATOR.NEXT' })
                            : Intl.formatMessage({ id: 'TRANSLATOR.SAVE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default PartExchange;
