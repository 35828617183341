import React from 'react';

const JoditCollection = ({children}) => {
    return (
        <div className="jodit-toolbar-editor-collection jodit-toolbar-editor-collection_mode_vertical jodit-toolbar-editor-collection_size_middle">
            <div className="jodit-ui-group jodit-ui-group_size_middle">
                <div className="jodit-ui-group jodit-ui-group_size_middle">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default JoditCollection;
