import React from 'react';
import { Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Customer } from '@app/crud/customers/customer.type';

import { getCivility } from '@app/pages/customers/Helpers/Customer';

import ActionsHandBand from './ActionsHandBand';

type FullHandBandProps = {
    customer: Customer;
};
const FullHandBand = ({ customer }: FullHandBandProps) => (
    <div className="customer-handband fullheadband">
        <div className="fullheadband__part">
            <div className="fullheadband__pic">
                <Image
                    className="customer-handband__pic"
                    src="/media/pages/customers/fiche-client-aucune-activite-en-cours.svg"
                />
            </div>
            <div className="fullheadband__col">
                <div className="text-white font-size-h5 border-bottom px-3 px-lg-0 pb-3">
                    <span className="font-weight-bold">
                        {' '}
                        {getCivility(customer?.civility) || ''} {customer?.firstname || ''} {customer?.lastname || ''}{' '}
                    </span>{' '}
                    n&apos;a
                    <span className="text-warning font-weight-bold">
                        {' '}
                        <FormattedMessage id="CUSTOMERS.HANDBAND.ACTIVITY.DEFAULT" />{' '}
                    </span>
                    <br className="fullheadband__divider" />
                    <FormattedMessage id="CUSTOMERS.HANDBAND.ACTIVITY.WHAT_TODO" />
                </div>
            </div>
        </div>
        <ActionsHandBand
            actionTypes={[
                'lead_manufacturer',
                'dealership_visit',
                'call',
                'appointment',
                'callback',
                'order_offer',
                'email',
                'local_apv',
                'local_home_charge',
                'contract_securycar',
                'apv_in_store',
                'local_commerce',
            ]}
        />
    </div>
);

export default FullHandBand;
