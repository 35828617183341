import React from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { LayoutContextConsumer } from '@src/_metronic/layout/LayoutContext';
import BreadCrumbs from '@src/_metronic/layout/sub-header/components/BreadCrumbs';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';

import subHeader from '@app/partials/HoC/subHeader';

import { getCivility } from './Helpers/Customer';

function CustomerSubHeader(props) {
    const { subheaderCssClasses, subheaderContainerCssClasses, subheaderMobileToggle } = props;

    const { data: customer } = useSelector((state) => state.customer.customer);
    const Intl = useIntl();

    const breadcrumb = [
        {
            title: `${getCivility(customer?.civility) || ''} ${customer?.firstname || ''} ${customer?.lastname || ''}`,
        },
    ];

    return (
        <div id="kt_subheader" className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            type="button"
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}

                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">
                                    {Intl.formatMessage({ id: 'MENU.CUSTOMERS.CARD' }, { id: customer?.id })}
                                </h3>
                                <BreadCrumbs items={breadcrumb} />
                            </>
                        )}
                    </LayoutContextConsumer>
                </div>
                <div className="d-flex align-items-center">
                    <Link to={routeTo(ROUTES.CUSTOMERS.PATH)}>
                        <Button className="ml-0 ml-lg-2" variant="outline-light">
                            Retour
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default subHeader(CustomerSubHeader);
