import { displayOrderPackageCode } from '@app/helpers/ApvPackageHelper';
import { Divider } from 'primereact/divider';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import type { Package } from '@app/pages/apv/orders/Order';

interface PackageProps {
    orderPackage: Package;
}
const PackageRow: React.FC<PackageProps> = ({ orderPackage }) => (
    <>
        <Row>
            <Col md={7} lg={7}>
                <span className="font-weight-semibold">{orderPackage.name}</span>&nbsp;
                {displayOrderPackageCode(orderPackage, false) && (
                    <>
                        <span>&#8226;</span>&nbsp;
                        <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.PACKAGE_REF" />
                        &nbsp;
                        <span>{displayOrderPackageCode(orderPackage)}</span>
                    </>
                )}
            </Col>
            <Col md={5} lg={5}>
                <div className="justify-content-end d-flex font-weight-semibold">
                    {orderPackage.totalPrice > 0 ? (
                        `${orderPackage.totalPrice?.toFixed(2)} € `
                    ) : (
                        <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.FREE_PACKAGE" />
                    )}
                </div>
            </Col>
        </Row>
        <Divider type="solid" />
    </>
);

export default PackageRow;
