import { Application } from '@app/crud/autouser/application.types';

import { createApplication, updateApplication } from '@app/store/autouser/application/application.thunk';

interface FormSubmitParams {
    newApplication: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: any;
    data: Application;
    history: unknown;
    id: string;
}

// eslint-disable-next-line import/prefer-default-export
export const formSubmit = (params: FormSubmitParams) => {
    const { newApplication, dispatch, data, history, id } = params;

    if (newApplication) {
        dispatch(
            createApplication({
                data,
                history,
            }),
        );
    } else {
        dispatch(updateApplication(id, data));
    }
};
