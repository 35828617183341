import React from 'react';
import {useHistory} from "react-router-dom";

let globalHistory = null;

export const getHistory = () => {
    return globalHistory;
}

const GlobalHistory  = () => {
    globalHistory = useHistory();
    return null;
};

export default GlobalHistory ;
