import { User } from '@app/store/login/login.store';
import { useAppSelector } from './useAppSelector'
import { useMemo } from 'react'

export const getPermissions = (userData: User) => {
    if (!userData?.permissions) {
        return [];
    }

    return Object.values(userData.permissions).reduce((acc, curr) => {
        return [...acc, ...curr];
    }, []);
}

export const checkPermission = (permissions: string[], permission: string) => {
    if (!permission) {
        return true;
    }

    return permissions.includes(permission);
}

const usePermissions = () => {
    const {userData} = useAppSelector((state) => state.auth);

    const permissions = useMemo(() => {
        return getPermissions(userData)
    }, [userData]);

    const hasPermission = (permission: string) => {
        return checkPermission(permissions, permission)
    }

    const hasAllPermissions = (permissions: string[]) => {
        if (!permissions) {
            return true;
        }

        return permissions.every(permission => hasPermission(permission));
    }

    const hasAnyPermissions = (permissions: string[]) => {
        if (!permissions) {
            return true;
        }

        return permissions.some(permission => hasPermission(permission));
    }

    return {
        hasPermission,
        hasAllPermissions,
        hasAnyPermissions
    };
};

export default usePermissions;
