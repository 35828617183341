import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { VcuMappedEventDetails } from '@app/crud/customers/customer.type';

import RowInfo from '@app/partials/content/RowInfo';

interface Props {
    event: VcuMappedEventDetails;
}
const SecurycarResume = ({ event }: Props) => {
    const Intl = useIntl();
    return (
        <div className="d-md-flex justify-content-md-between align-items-md-stretch">
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.CONTRAT' })}
                    value={event?.securycar.resume[0].contrat ? event?.securycar.resume[0].contrat : '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DATE_SOUSCRIPTION' })}
                    value={
                        event?.securycar.resume[0].subscriptionDate
                            ? dayjs(event?.securycar.resume[0].subscriptionDate).format('DD/MM/YYYY')
                            : '-'
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DATE_START' })}
                    value={
                        event?.securycar.resume[0].startDate
                            ? dayjs(event?.securycar.resume[0].startDate).format('DD/MM/YYYY')
                            : '-'
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DATE_END' })}
                    value={
                        event?.securycar.resume[0].endDate
                            ? dayjs(event?.securycar.resume[0].endDate).format('DD/MM/YYYY')
                            : '-'
                    }
                />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.PRICE' })}
                    value={
                        <NumberFormat
                            value={event?.securycar.resume[0].price || '-'}
                            decimalSeparator="."
                            thousandSeparator=" "
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" €"
                            displayType="text"
                        />
                    }
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.FOLLOW_BY' })}
                    value={event?.securycar.resume[0].leadFollowby ? event?.securycar.resume[0].leadFollowby : '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.SOURCE' })}
                    value={event?.securycar.resume[0].source ? event?.securycar.resume[0].source : '-'}
                />
            </div>
            <div className="col-md-4">
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.TITLE.CONTRACT_NUMBER' })}
                    value={event?.securycar.resume[0].contratNumber ? event?.securycar.resume[0].contratNumber : '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.PAYMENT_MODE' })}
                    value={event?.securycar.resume[0].paymentMode ? event?.securycar.resume[0].paymentMode : '-'}
                />
            </div>
        </div>
    );
};

export default SecurycarResume;
