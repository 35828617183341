import React from "react";
import { Image } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import "./progressCircle.scss";


type ChartOptions = {
  plugins: {
    datalabels: {
      display: boolean;
    },
    legend: {
      display: boolean;
    },
    tooltip: {
      enabled: boolean;
    },
  },
  aspectRatio: number;
  hover: {
    mode: null;
  },
  responsive: boolean,
  cutout: number,
};

const ProgressCircle = ({ percentage, max }: any) => {
  const getColor = (percentage: number) => {
    const colors = ["#FF4D4D", "#FF792E", "#FFBC30", "#25BD62", "#25BDA4"];
    const index = Math.floor((percentage / 100) * colors.length - 1);
    return colors[index];
  };

  const color = getColor(percentage);
  const data = {
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [color, "#E6E6E6"],
        borderWidth: 0,
      },
    ],
  };

  const options: ChartOptions = {
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    aspectRatio: 1,
    hover: {
      mode: null
    },
    responsive: true,
    cutout: 21
  };
  const centerText = `${(percentage * max) / 100}/${max}`;
  return (
    <div className="progressCircle__container">
      <Image src="/media/icons/star.svg" className={`progressCircle__star--left ${color !== "#25BDA4" ? "d-none" : ""}`} />
        <div className="progressCircle__content">
          <Doughnut width={55} height={55} options={options} data={data} />
          <p className={`progressCircle__scores`} style={{color: color}}>{centerText}</p>
        </div>
        <Image src="/media/icons/star.svg" className={`progressCircle__star--right ${color !== "#25BDA4" ? "d-none" : ""}`} />
    </div>
  );
};

export default ProgressCircle;
