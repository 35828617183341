import { Divider } from 'primereact/divider';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import toast from '@app/partials/content/Toast';
import { getVehicleInternalIdTemplate, MAX_VEHICLES } from '@app/partials/content/Vehicle/VehicleHelpers';

import useLead from '../Hooks/useLead';
import { VehicleSearch } from '../Modals';

import Car from './Car';

const InStockPreview = ({ vehicles, number, showVehicle, setShowVehicle }) => {
    const { setValue, getValues } = useFormContext();
    const { params } = useSelector((state) => state.modal);
    const currentLead = useLead(params?.leadId);
    const Intl = useIntl();

    const handleSelect = (vehicle) => {
        if (number < MAX_VEHICLES) {
            setValue('vehicles', [...(getValues('vehicles') || []), vehicle]);

            if (getValues('dealership') !== vehicle.dealer_id && currentLead === null) {
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'STOCK_VEHICLE.MODAL.DEALERSHIP.CHANGED' }),
                });
                setValue('dealership', vehicle.dealer_id);
            }
        } else {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CAR_SEARCH.ADD.TOO_MUCH' }),
            });
        }
    };

    const handleRemove = (data) => {
        setValue(
            'vehicles',
            getValues('vehicles').filter(
                (vehicle) => getVehicleInternalIdTemplate(vehicle) !== getVehicleInternalIdTemplate(data),
            ),
        );
    };

    return (
        <>
            {vehicles && vehicles.length > 0 && (
                <div className="mb-5">
                    {vehicles.map((car, idx) => (
                        <div key={getVehicleInternalIdTemplate(car)}>
                            <Car data={car} onRemove={handleRemove} />
                            {idx + 1 !== vehicles.length && <Divider />}
                        </div>
                    ))}
                </div>
            )}
            <VehicleSearch
                showModal={showVehicle}
                onSelect={handleSelect}
                excludedVehicles={getValues('vehicles')}
                setShowModal={setShowVehicle}
            />
        </>
    );
};

export default InStockPreview;
