import React from 'react';
import { FormattedMessage } from 'react-intl';

const CampaignAssignmentBlock = ({ text, color, value, total, icon, floatPrecision }) => (
    <div className="pr-4 pl-0 flex-grow-1 flex-basis-0">
        <div className={`campaign-card kt-bg-light-${color} text-${color}`}>
            <FormattedMessage
                id={text}
                values={{
                    br: <br />,
                }}
            />
            <div className="d-flex justify-content-between align-items-end">
                <div>
                    <span className="mt-1 font-size-h3 font-weight-bold">{value ?? '-'}</span>
                    {total && <span className="ml-4">{((value / total) * 100).toFixed(floatPrecision)}&nbsp;%</span>}
                </div>
                {icon && <i className={`text-${color} la-4x las ${icon}`} />}
            </div>
        </div>
    </div>
);

CampaignAssignmentBlock.defaultProps = {
    floatPrecision: 1,
    value: 0,
};
export default CampaignAssignmentBlock;
