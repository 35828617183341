import React from "react";
import {LayoutContextConsumer} from "../../../../_metronic";
import BreadCrumbs from "../../../../_metronic/layout/sub-header/components/BreadCrumbs";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {Intl} from "../../../../_metronic/i18n/I18nProvider";
import {useFormContext} from "react-hook-form";
import formSubmit from "./form/Form.Submit";
import subHeader from "../../../partials/HoC/subHeader";
import {routeTo} from "../../../helpers/RoutesHelper";
import ROUTES from "../../../router/Routes";
import Permission from "../../../partials/content/Permission";

function CommercialOperationSubHeader(props) {
    const methods = useFormContext();
    const params = useParams();
    const dispatch = useDispatch();

    const newOperation = typeof params.id === 'undefined';
    let operation = useSelector((state) => state.commercialOperation.operation);

    const onSubmit = data => formSubmit({data, newOperation, dispatch, params, history: props.history});

    const {
        subheaderCssClasses,
        subheaderContainerCssClasses,
        subheaderMobileToggle
    } = props;

    const breadcrumb = [
        {
            title: Intl.formatMessage({ id: "MENU.APV.COMMERCIAL_ANIMATION" })
        },
        {
            title: Intl.formatMessage({
                id: newOperation ? "MENU.APV.OPERATION_CREATION" : "MENU.APV.OPERATION_EDITION"
            }, { name: operation?.name || '' })
        }
    ]

    return (
        <div
            id="kt_subheader"
            className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
            <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className="kt-subheader__main">
                    {subheaderMobileToggle && (
                        <button
                            className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}

                    <LayoutContextConsumer>
                        {() => (
                            <>
                                <h3 className="kt-subheader__title">{Intl.formatMessage({ id: "MENU.APV" })}</h3>
                                <BreadCrumbs items={breadcrumb} />
                            </>
                        )}
                    </LayoutContextConsumer>
                </div>
                <div className="d-flex align-items-center">
                    <Link to={routeTo(ROUTES.OPERATIONS.PATH)}>
                        <Button className="mr-2" variant="outline-primary">
                            {Intl.formatMessage({ id: "TRANSLATOR.BACK" })}
                        </Button>
                    </Link>
                    <Permission permissions={['APV_UPDATE_COMMERCIAL_OPERATION']}>
                        <Button className="mr-2"
                                onClick={methods.handleSubmit(onSubmit)}
                                variant="primary">{Intl.formatMessage({ id: "TRANSLATOR.SAVE" })}</Button>
                    </Permission>
                </div>
            </div>
        </div>
    );
}

export default subHeader(CommercialOperationSubHeader);
