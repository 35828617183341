import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useQueryParams from '@app/hooks/useQueryParams';

import { getAllLeads } from '@app/crud/customers/customer.crud';

import { checkIdenticalData } from '../Helpers/LeadOnlineHelper';

const ActivityLeadOnline = ({ setLoading }) => {
    const queryParams = useQueryParams();
    const history = useHistory();
    const location = useLocation();

    const fetchOneLead = useCallback(async () => {
        if (queryParams?.action === 'take' && queryParams?.leadId) {
            history.replace(location.pathname);

            setLoading(true);

            const leads = await getAllLeads({
                id: queryParams?.leadId,
            });
            const lead = leads?.result?.[0];

            if (lead?.id) {
                checkIdenticalData(lead, true, setLoading);
            } else {
                setLoading(false);
            }
        }
    }, [queryParams]);

    useEffect(() => {
        fetchOneLead();
    }, [fetchOneLead]);

    return null;
};

export default ActivityLeadOnline;
