import React from 'react';
import { Button, Image, Row } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import { Customer } from '@app/crud/customers/customer.type';

import useActionHandler from '@app/pages/customers/Hooks/useActionHandler';
import { Lead } from '@app/store/customers/customers.store';

type LinkLeadHandBandProps = {
    customer: Customer;
    lead: Lead;
};
const LinkLeadHandBand = ({ customer, lead }: LinkLeadHandBandProps) => {
    const handleActionClick = useActionHandler(lead);
    const Intl = useIntl();

    const leadActions = (
        <>
            <Button variant="outline-danger" onClick={() => handleActionClick('link_lead')} className="ml-0 mr-5">
                {Intl.formatMessage({ id: 'CUSTOMERS.HANDBAND.LINK.LINK' })}
            </Button>
            <Button variant="outline-danger" onClick={() => handleActionClick('treat_separately')}>
                {Intl.formatMessage({ id: 'CUSTOMERS.HANDBAND.LINK.UNLINK' })}
            </Button>
        </>
    );

    return (
        <div className="customer-handband my-lg-0 my-2">
            <Row className="font-size-h4 text-white headband__content flex-nowrap">
                <Image
                    className="customer-handband__pic linkheadband__pic ml-5"
                    src="/media/pages/customers/fiche-client-liaison-de-leads.svg"
                />
                <div className="linkheadband__row ml-5">
                    <div className="border-bottom linkheadband__text">
                        <FormattedHTMLMessage
                            id="CUSTOMERS.HANDBAND.LINK.NUMBER"
                            values={{
                                number: customer?.leads?.length ? customer.leads.length : 0,
                                name: `${customer?.firstname || ''} ${customer?.lastname || ''}`,
                            }}
                        />
                        <br />
                        <FormattedHTMLMessage id="CUSTOMERS.HANDBAND.LINK.QUESTION" />
                    </div>
                    <div className="linkheadband__actions">{leadActions}</div>
                </div>
            </Row>
            <Row className="font-size-h4 text-white headband__content headband__content--scroll">
                <Image
                    className="customer-handband__pic--small ml-5"
                    src="/media/pages/customers/fiche-client-liaison-de-leads.svg"
                />
                <div className="ml-lg-5 mx-lg-0 mx-5 text-lg-left text-center">
                    <FormattedHTMLMessage
                        id="CUSTOMERS.HANDBAND.LINK.NUMBER"
                        values={{
                            number: customer?.leads?.length ? customer.leads.length : 0,
                            name: `${customer?.firstname || ''} ${customer?.lastname || ''}`,
                        }}
                    />
                    {` `}
                    <FormattedHTMLMessage id="CUSTOMERS.HANDBAND.LINK.QUESTION" />
                </div>
                <div className="mt-lg-0 mt-2 ml-lg-5 mx-lg-0 mx-5 d-lg-block d-flex">{leadActions}</div>
            </Row>
        </div>
    );
};

export default LinkLeadHandBand;
