import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import Label from '@app/partials/content/Label';

interface ConfirmationAffectionButtonProps {
    orderAppointmentId: string;
    isAffected: boolean;
    displayLabel?: boolean;
    btnVariant?: string;
    updateAffectation: (id: string) => void;
}

const ConfirmAffectationButton = ({
    orderAppointmentId,
    isAffected,
    updateAffectation,
    btnVariant,
    displayLabel,
}: ConfirmationAffectionButtonProps) => {
    const styleLabel = displayLabel ? 'label--valid--long' : 'label--valid--short btn-icon ml-2';

    return isAffected === true ? (
        <Label className={styleLabel} variant="success">
            <i className="las la-calendar-check" />
            {displayLabel && <FormattedMessage id="MANUFACTURER.ORDER.COMPANION_BUTTON.CONFIRMED" />}
        </Label>
    ) : (
        <Button
            className={`${!displayLabel ? 'btn-icon' : ''} ml-2`}
            variant={btnVariant}
            onClick={() => updateAffectation(orderAppointmentId)}
        >
            <i className="las la-calendar" />
            {displayLabel && <FormattedMessage id="MANUFACTURER.ORDER.COMPANION_BUTTON.TO_CONFIRM" />}
        </Button>
    );
};

ConfirmAffectationButton.defaultProps = {
    displayLabel: false,
    btnVariant: 'outline-primary',
};

export default ConfirmAffectationButton;
