import React from 'react';
import { Card } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import Label from '@app/partials/content/Label';
import {
    getEligibleBalloon,
    getEligibleClassic,
    getEligibleCredit,
    getEligibleLOA,
    getHasCredit,
    getHasLOA,
} from '@app/partials/content/Vehicle/VehicleHelpers';
import VehicleInfo from '@app/partials/content/Vehicle/VehicleInfo';

const StockVehicleInfo = ({ vehicle, isVN }) => {
    const Intl = useIntl();
    const isContextEFB = useSelector((state) => state.auth.userData.context === 'efb');

    const ribbonsHandler = () => {
        let ribbons = [];

        if (isContextEFB) {
            const isEligibleClassic = getEligibleClassic(vehicle);
            const isEligibleBalloon = getEligibleBalloon(vehicle);

            ribbons = [
                ...ribbons,
                {
                    label: Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.CLASSIC' }),
                    color: isEligibleClassic ? 'success' : 'danger',
                    icon: isEligibleClassic ? 'check' : 'times',
                    published: false,
                },
            ];
            ribbons = [
                ...ribbons,
                {
                    label: Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.BALLOON' }),
                    color: isEligibleBalloon ? 'success' : 'danger',
                    icon: isEligibleBalloon ? 'check' : 'times',
                    published: false,
                },
            ];
        } else {
            const isEligibleCredit = getEligibleCredit(vehicle);
            const isEligibleLOA = getEligibleLOA(vehicle);
            const hasCredit = getHasCredit(vehicle);
            const hasLoa = getHasLOA(vehicle);

            if (hasLoa) {
                ribbons = [
                    ...ribbons,
                    {
                        label: Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.VEHICLE_INFO.RIBBON.PUBLISH_LOA' }),
                        color: 'success',
                        icon: 'thumbs-up',
                        published: true,
                    },
                ];
            } else {
                ribbons = [
                    ...ribbons,
                    {
                        label: Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.LOA' }),
                        color: isEligibleLOA ? 'success' : 'danger',
                        icon: isEligibleLOA ? 'check' : 'times',
                        published: false,
                    },
                ];
            }

            if (hasCredit) {
                ribbons = [
                    ...ribbons,
                    {
                        label: Intl.formatMessage({ id: 'STOCK_VEHICLE.CARD.VEHICLE_INFO.RIBBON.PUBLISH_CREDIT' }),
                        color: 'success',
                        icon: 'thumbs-up',
                        published: true,
                    },
                ];
            } else {
                ribbons = [
                    ...ribbons,
                    {
                        label: Intl.formatMessage({ id: 'STOCK_VEHICLES.TABLE.CREDIT' }),
                        color: isEligibleCredit ? 'success' : 'danger',
                        icon: isEligibleCredit ? 'check' : 'times',
                        published: false,
                    },
                ];
            }
        }

        return (
            <>
                {isVN &&
                    vehicle?.catalog_price &&
                    vehicle.catalog_price > 0 &&
                    vehicle.catalog_price !== vehicle.price && (
                        <div
                            className="kt-ribbon__target text-white bg-warning-gold"
                            style={{
                                position: 'absolute',
                                left: '-2px',
                                top: '10px',
                                borderRadius: '0px 13px 13px 0px',
                                maxHeight: '26px',
                                boxShadow: '0px 3px 6px #00000029',
                            }}
                        >
                            <span className="font-weight-bold">
                                <NumberFormat
                                    className="price-content"
                                    value={vehicle.price - vehicle.catalog_price}
                                    decimalSeparator="."
                                    thousandSeparator=" "
                                    displayType="text"
                                    suffix=" €"
                                />
                            </span>
                        </div>
                    )}
                <div
                    className="d-flex flex-column"
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '-2px',
                    }}
                >
                    {ribbons.map((ribbon, key) =>
                        ribbon.published ? (
                            <div
                                key={key}
                                className={`text-${ribbon.color} bg-${ribbon.color}-light mb-1 d-flex`}
                                style={{
                                    padding: '5px',
                                    borderRadius: '13px 0 0 13px',
                                    maxHeight: '26px',
                                    boxShadow: '0px 3px 6px #00000029',
                                }}
                            >
                                <i className={`las la-${ribbon.icon} la-xl text-${ribbon.color}`} />
                                &nbsp;
                                <span className="font-weight-bold">{ribbon.label}</span>
                            </div>
                        ) : (
                            <Label
                                variant="light"
                                className={`font-weight-bold text-${ribbon.color} justify-content-start`}
                                style={{
                                    padding: '5px',
                                    marginBottom: '5px',
                                    borderRadius: '13px 0 0 13px',
                                    maxHeight: '26px',
                                    boxShadow: '0px 3px 6px #00000029',
                                }}
                            >
                                <i
                                    className={`mr-1 las la-${ribbon.icon} la-2x text-${
                                        ribbon.color === 'success' ? ribbon.color : `${ribbon.color}-lite`
                                    }`}
                                />
                                {ribbon.label}
                            </Label>
                        ),
                    )}
                </div>
            </>
        );
    };

    return (
        <Card>
            <VehicleInfo
                vehicle={vehicle}
                canUpdate={false}
                canView
                className="vehicle-container--full"
                viewDetails
                ribbons={ribbonsHandler()}
                isVN={isVN}
            />
        </Card>
    );
};

StockVehicleInfo.defaultProps = {
    isVN: false,
};

export default StockVehicleInfo;
