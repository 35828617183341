import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';

import ROUTES from '@app/router/Routes';

import { linkToLeadWithFilters } from '../../helpers/DashboardHelper';

const DashboardPerfState = ({ detail, leadType }) => {
    const history = useHistory();
    const { updateFilters } = useFilters({
        name: `leads_online_all`,
    });

    const { updateFilters: updateFiltersClosed } = useFilters({
        name: `leads_online_close`,
    });

    const { updateFilters: updateApvFilters } = useFilters({
        name: `leads_apv_action_to_do`,
    });
    const isAPV = leadType === 'local_apv';
    return (
        <div className="d-flex flex-nowrap font-size-sm font-weight-semibold">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a
                className="text-primary text-nowrap"
                onClick={() =>
                    isAPV
                        ? linkToLeadWithFilters(
                              history,
                              true,
                              updateApvFilters,
                              null,
                              null,
                              'all',
                              ROUTES.APV_LEADS.PATH,
                          )
                        : linkToLeadWithFilters(
                              history,
                              true,
                              updateFilters,
                              null,
                              null,
                              'all',
                              ROUTES.LEADS_ONLINE.PATH,
                              { leadType: { value: leadType } },
                          )
                }
            >
                <u>
                    {detail?.inProgress ?? 0} <FormattedMessage id="DASHBOARD.ONLINE_PERF.IN_PROGRESS" />
                </u>
            </a>
            <span className="mx-1">,</span>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a
                className="text-danger text-nowrap"
                onClick={() =>
                    isAPV
                        ? linkToLeadWithFilters(
                              history,
                              true,
                              updateApvFilters,
                              null,
                              null,
                              'close',
                              ROUTES.APV_LEADS.PATH,
                          )
                        : linkToLeadWithFilters(
                              history,
                              true,
                              updateFiltersClosed,
                              null,
                              null,
                              'close',
                              ROUTES.LEADS_ONLINE.PATH,
                              {
                                  leadType: { value: leadType },
                                  leadStatus: {
                                      value: 'closed_lost',
                                      componentValue: {
                                          name: 'Clôturé Perdu',
                                          key: 'closed_lost',
                                      },
                                  },
                              },
                          )
                }
            >
                <u>
                    {detail?.lost ?? 0} <FormattedMessage id="DASHBOARD.ONLINE_PERF.LOST" />
                </u>
            </a>
            <span className="mx-1">,</span>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a
                className="text-success text-nowrap"
                onClick={() =>
                    isAPV
                        ? linkToLeadWithFilters(
                              history,
                              true,
                              updateApvFilters,
                              null,
                              null,
                              'close',
                              ROUTES.APV_LEADS.PATH,
                          )
                        : linkToLeadWithFilters(
                              history,
                              true,
                              updateFiltersClosed,
                              null,
                              null,
                              'close',
                              ROUTES.LEADS_ONLINE.PATH,
                              {
                                  leadType: { value: leadType },
                                  leadStatus: { value: 'closed_won' },
                                  componentValue: {
                                      name: 'Clôturé Gagné',
                                      key: 'closed_won',
                                  },
                              },
                          )
                }
            >
                <u>
                    {detail?.won ?? 0} <FormattedMessage id="DASHBOARD.ONLINE_PERF.WON" />
                </u>
            </a>
        </div>
    );
};

export default DashboardPerfState;
