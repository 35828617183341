import React from "react";

export default function Slot({onSelect, slot, booked, selected}){
    return (
        <div
            onClick={() => onSelect(slot.hour)}
            className={`slot ${selected ? 'slot--selected' : ''} ${booked ? 'slot--booked' : ''}`}
        >
            {slot.label}
        </div>
    )
}
