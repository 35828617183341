import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';

import useFadeOut from '@app/hooks/useFadeOut';

const CONTENT_VARIANTS = {
    opened: {
        height: 'auto',
        overflow: 'hidden',
        opacity: 1,
        transition: {
            ease: 'easeOut',
        },
    },
    closed: {
        height: 0,
        overflow: 'hidden',
        opacity: 0,
        transition: {
            ease: 'easeOut',
        },
    },
};

const PANEL_VARIANTS = {
    show: {
        opacity: 1,
        height: 'auto',
        overflow: 'hidden',
        transition: {
            ease: 'easeOut',
        },
    },
    hide: {
        opacity: 0,
        height: 0,
        overflow: 'hidden',
        border: 'none',
        transition: {
            ease: 'easeOut',
        },
    },
};

const OperationPanel = ({ state, title, variant, expandedPanel, setExpandedPanel, children, toggleable }) => {
    const { onBottom, handleScroll, fadeOutRef } = useFadeOut();

    const template = () => {
        const toggleIcon = `cursor-pointer las la-2x text-${variant} ${expandedPanel ? 'la-minus' : 'la-plus'}`;

        return (
            <>
                <div className="d-flex align-items-center">
                    <i className={`las la-2x la-check-circle text-${variant}`} />
                    <div className="ml-4 font-size-lg">{title}</div>
                </div>
                {toggleable && <i className={toggleIcon} />}
            </>
        );
    };

    return (
        <motion.div
            variants={PANEL_VARIANTS}
            initial="show"
            className={classNames('campaign-panel', {
                [`campaign-panel--${variant}`]: variant,
                'campaign-panel--toggleable': toggleable,
                'mb-4': expandedPanel === null,
            })}
            animate={expandedPanel !== null && expandedPanel !== state ? 'hide' : 'show'}
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
            <div
                className="campaign-panel__header cursor-pointer"
                role="button"
                onClick={() => setExpandedPanel(!expandedPanel ? state : null)}
            >
                {template()}
            </div>
            <motion.div
                className="campaign-panel__content"
                variants={CONTENT_VARIANTS}
                initial="closed"
                animate={expandedPanel === state ? 'opened' : 'closed'}
            >
                <div
                    className="my-4 campaign-panel__scroll"
                    key={expandedPanel}
                    ref={fadeOutRef}
                    onScroll={handleScroll}
                >
                    {children}
                </div>
                {!onBottom && <div className="already-exists__fadeout" />}
            </motion.div>
        </motion.div>
    );
};

export default OperationPanel;
