import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { stripAccents } from '@app/helpers/StringHelper';

import {
    DealershipTreeSelectNode,
    getCheckedDealerships,
    getPerimeterNode,
    updateDatas,
} from '@app/pages/autouser/users/Helpers/Dealership';
import { getNewPerimeter } from '@app/pages/stock/helpers/Perimeter';

import { FiltersContext } from '@app/partials/content/FiltersProvider';
import { TreeSelect } from '@app/partials/content/Tree';

type StockTreeSelectProps = {
    perimeter: DealershipTreeSelectNode[];
    handleFilter: Function;
    updatePendingFilters: Function;
    releasePendingFilters: Function;
};

const StockTreeSelect = ({
    perimeter,
    handleFilter,
    updatePendingFilters,
    releasePendingFilters,
}: StockTreeSelectProps) => {
    const { register, setValue } = useFormContext();
    const treeRef = useRef();
    const filters = useContext(FiltersContext);
    const [displayedPerimeter, setDisplayedPerimeter] = useState<DealershipTreeSelectNode[]>([]);

    const setDealerIds = useCallback(
        (dealerships: string) => {
            if (updatePendingFilters) {
                updatePendingFilters({
                    dealer_id: {
                        value: dealerships,
                    },
                });
            } else {
                handleFilter({
                    dealer_id: {
                        value: dealerships,
                    },
                });
            }
        },
        [handleFilter],
    );

    const onValidate = useCallback(() => {
        releasePendingFilters();
        const dropDownElement = document.querySelector('.dropdown-trigger') as HTMLElement;
        if (dropDownElement.classList.contains('top')) {
            dropDownElement.click();
        }
    }, [filters, releasePendingFilters]);

    useEffect(() => {
        register('perimeter');
    }, [register]);

    useEffect(() => {
        if (Object.keys(perimeter)?.length > 0) {
            setValue('perimeter', perimeter);

            const dealer_ids = filters?.dealer_id?.value && filters?.dealer_id?.value?.split(',');
            const updatedPerimeter = updateDatas(perimeter, '', null, dealer_ids);
            const newPerimeter = getNewPerimeter(filters, updatedPerimeter);

            setDisplayedPerimeter(newPerimeter);
        }
    }, [perimeter]);

    useEffect(() => {
        if (Object.keys(perimeter)?.length > 0) {
            const updatedPerimeter = updateDatas(perimeter, '', false);
            const newPerimeter = getNewPerimeter(filters, updatedPerimeter);
            setDealerIds(null);
            setDisplayedPerimeter(newPerimeter);
        }
    }, [filters?.group_internal_id]);

    useEffect(() => {
        (document.getElementsByClassName('search')[0] as HTMLInputElement).placeholder = Intl.formatMessage({
            id: `STOCK_VEHICLES.PERIMETER${filters?.dealer_id?.value ? '_ADD' : ''}.PLACEHOLDER`,
        });
    }, [filters?.dealer_id]);

    return (
        <TreeSelect
            // @ts-ignore
            data={displayedPerimeter}
            texts={{
                placeholder: Intl.formatMessage({
                    id: `STOCK_VEHICLES.PERIMETER${filters?.dealer_id?.value ? '_ADD' : ''}.PLACEHOLDER`,
                }),
            }}
            className="react-dropdown-tree-select--placeholder"
            keepOpenOnSelect
            keepTreeOnSearch
            ref={treeRef}
            onValidate={onValidate}
            searchPredicate={(node: DealershipTreeSelectNode, searchTerm: string) =>
                stripAccents(node?.tagLabel).toLowerCase().includes(stripAccents(searchTerm).toLowerCase())
            }
            onChange={(currentNode: DealershipTreeSelectNode, selectedNodes: DealershipTreeSelectNode[]) => {
                const nodes = selectedNodes.map((selectedNode) => getPerimeterNode(perimeter, selectedNode?.path));
                setDealerIds(
                    getCheckedDealerships(nodes)
                        .map((dealership) => dealership?.id)
                        .join(',') || null,
                );
            }}
        />
    );
};

export default StockTreeSelect;
