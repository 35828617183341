import React from 'react';
import { FormattedMessage } from 'react-intl';

import { toAbsoluteUrl } from '@src/_metronic';

import '@src/_metronic/_assets/sass/pages/error/error-1.scss';

export default function ErrorPage1() {
    return (
        <div className="kt-grid kt-grid--ver kt-grid--root" style={{ height: '100%' }}>
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1">
                <div className="kt-error-v1__container text-center d-block">
                    <img
                        src={toAbsoluteUrl('/media/logos/logo-dark.svg')}
                        alt="logo-automamager"
                        className="my-12 logo-amng"
                    />
                    <img src={toAbsoluteUrl('/media/error/error.svg')} width="100%" alt="error" />
                    <p className="kt-error-v1__desc">
                        <span>
                            <FormattedMessage id="ERROR.OH_NO" />
                        </span>
                        <br />
                        <span>
                            <FormattedMessage id="ERROR.PAGE_NOT_FOUND" />
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
}
