import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getCustomerGdpr } from '@app/pages/customers/Helpers/Customer';
import RollbackUpdateForm from '@app/pages/customers/Partials/RollbackUpdateForm';

import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import Permission from '@app/partials/content/Permission';

const RollbackUpdate = ({
    title,
    subtitle,
    showModal,
    setShowModal,
    alwaysValidate,
    validateLabel,
    actualDataLabel,
    previousDataLabel,
}) => {
    const { params } = useSelector((state) => state.modal);
    const { data: customer } = useSelector((state) => state.customer.customer);
    const methods = useForm();
    const customerField = params?.differentialsVCU
        ? params?.customerFieldChange[Object.keys(params?.customerFieldChange)[0]]
        : params?.customerFieldChange;
    const { handleSubmit, watch } = methods;
    const fieldsUpdated = watch();
    const [fieldRollback, setFieldRollback] = useState(false);
    const [loading, setLoading] = useState(false);
    const Intl = useIntl();

    const onSubmit = (data) => {
        setLoading(true);

        if (params?.dataInterceptor) {
            return params?.dataInterceptor(data);
        }

        const rollback = Object.entries(data).reduce((acc, [key, value]) => {
            const splittedKey = key.split(/_(?=[^_]+$)/); // split sur la dernière occurrence de _
            const property = splittedKey.length > 0 ? splittedKey[0] : undefined;
            const customerHasProperty = customer ? Object.prototype.hasOwnProperty.call(customer, property) : true;
            let gdpr = false;

            if (value) {
                gdpr = customer?.consents?.find?.((consent) => property.includes(consent?.slug));

                if (property && customerHasProperty) {
                    if (params?.differentialsVCU) {
                        acc[property] =
                            params?.customerFieldChange[Object.keys(params?.customerFieldChange)[0]][
                                property
                            ]?.new_value;
                    } else if (property === 'vehicleKnow') {
                        acc[property] = JSON.parse(params?.customerFieldChange[property]?.new_value);
                    } else {
                        acc[property] = params?.customerFieldChange[property]?.new_value;
                    }
                } else if (gdpr) {
                    if (!acc.consents) {
                        acc.consents = { ...customer?.consents };
                    }

                    const indexGdpr = customer?.consents.indexOf(gdpr);

                    if (property === gdpr?.slug) {
                        acc.consents[indexGdpr] = getCustomerGdpr(gdpr?.name);
                    } else {
                        gdpr = { ...acc.consents[indexGdpr] };
                        if (property.includes('email')) {
                            gdpr.gdprEmail = params?.customerFieldChange[property].new_value;
                        } else if (property.includes('sms')) {
                            gdpr.gdprSms = params?.customerFieldChange[property].new_value;
                        } else if (property.includes('phone')) {
                            gdpr.gdprPhone = params?.customerFieldChange[property].new_value;
                        } else if (property.includes('post')) {
                            gdpr.gdprPost = params?.customerFieldChange[property].new_value;
                        }
                        acc.consents[indexGdpr] = gdpr;
                    }
                }
            }

            return acc;
        }, {});

        const updatedCustomer = { ...customer, ...rollback };

        if (params?.differentialsVCU) {
            // eslint-disable-next-line prefer-destructuring
            updatedCustomer.md5 = Object.keys(params?.customerFieldChange)[0];
        }
        params?.callback(updatedCustomer);
    };

    useEffect(() => {
        if (fieldsUpdated) {
            setFieldRollback(Object.entries(fieldsUpdated).some((value) => !!value));
        }
    }, [fieldsUpdated]);

    useEffect(() => {
        methods.reset();
        setLoading(false);
    }, [showModal]);

    return (
        <ModalDefault
            size="lg"
            show={showModal}
            loading={loading}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-user" />}
            title={
                <p className="mx-5">
                    {title || (
                        <FormattedHTMLMessage
                            id={`${
                                !params?.updateType || params?.updateType !== 'update_consent_dealership'
                                    ? 'CUSTOMERS.MODAL.ROLLBACK.TITLE'
                                    : 'CUSTOMERS.MODAL.ROLLBACK.CONSENT.TITLE'
                            }${params?.differentialsVCU ? '.DIFFERENTIAL_VCU' : ''}`}
                        />
                    )}
                </p>
            }
            subtitle={
                subtitle || (
                    <FormattedHTMLMessage
                        id={`CUSTOMERS.MODAL.ROLLBACK.SUBTITLE${params?.differentialsVCU ? '.DIFFERENTIAL_VCU' : ''}`}
                    />
                )
            }
            body={
                <FormProvider {...methods}>
                    <div className="d-flex">
                        <div className="p-5 flex-grow-1">
                            {customerField ? (
                                <RollbackUpdateForm
                                    actualDataLabel={
                                        actualDataLabel ||
                                        Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.ROLLBACK.ACTUAL_DATA',
                                        }).toUpperCase()
                                    }
                                    previousDataLabel={
                                        previousDataLabel ||
                                        Intl.formatMessage({
                                            id: `CUSTOMERS.MODAL.ROLLBACK.${
                                                params?.differentialsVCU ? 'NEW_DATA' : 'PREVIOUS_DATA'
                                            }`,
                                        }).toUpperCase()
                                    }
                                    customerFieldsChange={customerField}
                                    customerConsents={customer?.consents}
                                    updateType={params?.updateType}
                                />
                            ) : (
                                <Loader />
                            )}
                        </div>
                    </div>

                    {params?.differentialsVCU && (
                        <p className="text-center mt-9">
                            <FormattedHTMLMessage id="CUSTOMER.VIEW.ROLLBACK.INFO" />
                        </p>
                    )}
                </FormProvider>
            }
            footer={
                fieldRollback || alwaysValidate ? (
                    <div className="d-flex justify-content-center w-100">
                        <Button
                            variant="outline-secondary"
                            className="mr-3"
                            style={{ width: '100px' }}
                            onClick={() => setShowModal(false)}
                        >
                            {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                        </Button>
                        <Permission permissions={['LEAD_RESTORE_UPDATE_CONTACT']}>
                            <Button
                                variant="primary"
                                className="position-relative"
                                style={{ width: '100px' }}
                                onClick={handleSubmit(onSubmit)}
                            >
                                {validateLabel ||
                                    Intl.formatMessage({
                                        id: `TRANSLATOR.${params?.differentialsVCU ? 'VALIDATE' : 'RESTORE'}`,
                                    })}
                            </Button>
                        </Permission>
                    </div>
                ) : (
                    <div className="d-flex justify-content-center w-100">
                        <Button
                            variant="outline-secondary"
                            className="mr-3"
                            style={{ width: '100px' }}
                            onClick={() => setShowModal(false)}
                        >
                            {Intl.formatMessage({ id: 'TRANSLATOR.CLOSE' })}
                        </Button>
                    </div>
                )
            }
        />
    );
};

RollbackUpdate.defaultProps = {
    title: false,
    subtitle: false,
    alwaysValidate: false,
    canValidate: false,
    validateLabel: null,
    actualDataLabel: null,
    previousDataLabel: null,
};

export default RollbackUpdate;
