import { createContext, Dispatch, SetStateAction } from 'react';

import { CustomerEvent } from '@app/crud/customers/customer.type';

interface CustomerLifeContextInterface {
    expandedRows: CustomerEvent[];
    setExpandedRows: Dispatch<SetStateAction<CustomerEvent[]>>;
    isExpandedRow: (data: CustomerEvent) => boolean;
}

const CustomerLifeContext = createContext({} as CustomerLifeContextInterface);

export const CustomerLifeProvider = CustomerLifeContext.Provider;
export const CustomerLifeConsumer = CustomerLifeContext.Consumer;

export default CustomerLifeContext;
