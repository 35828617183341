import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { getSlotsByDealership } from '@app/crud/apv/dealership.crud';
import { updateOrder } from '@app/crud/apv/order.crud';

import { useAppSelector } from '@app/hooks';
import { fetchOrder } from '@app/store/apv/order/order.thunk';

import Days from '@app/partials/content/Day/Days';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import Months from '@app/partials/content/Month/Months';
import Slots from '@app/partials/content/Slot/Slots';
import toast from '@app/partials/content/Toast';

interface Props {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}

export default function Update({ showModal, setShowModal }: Props) {
    const Intl = useIntl();
    const params = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const order = useAppSelector((state) => state.order.order);

    const [loading, setLoading] = useState(false);

    const dateStart = dayjs(order?.appointment?.dateStart).toDate();
    const [currentSlot, setCurrentSlot] = useState<string | undefined>();
    const [currentDay, setCurrentDay] = useState<string | undefined>();
    const [currentMonth, setCurrentMonth] = useState<string | undefined>();
    const [date, setDate] = useState<string | undefined>();

    const initialize = () => {
        setCurrentSlot(dayjs(dateStart).format('HH:mm'));
        setCurrentDay(dayjs(dateStart).format('YYYY-MM-DD'));
        setCurrentMonth(dayjs(dateStart).format('YYYY-MM'));
        setDate(dayjs(dateStart).format('YYYY-MM-DD'));
    };

    useEffect(() => {
        initialize();
    }, []);

    const [slots, setSlots] = useState<{ hour: string; label: string; booked: boolean }[]>([]);
    const [days, setDays] = useState<Record<string, { day: string; label: string; open: boolean }>>({});
    const [months, setMonths] = useState<Record<string, string>>({});

    const loadModalContent = useCallback(() => {
        setLoading(true);

        if (order?.dealership?.id && date) {
            getSlotsByDealership(order.dealership.id, date || '', order?.mecaplanning?.duration)
                .then((response) => {
                    setMonths(response.result.months);
                    setDays(response.result.days);
                    setSlots(response.result.slots);
                    setLoading(false);
                })
                .catch(() => {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CONTACT.ERROR' }),
                    });
                });
        }
    }, [date, order]);

    useEffect(() => {
        loadModalContent();
    }, [loadModalContent]);

    const handleCurrentMonth = (monthsSelected: string) => {
        setCurrentMonth(monthsSelected);
        setCurrentDay(undefined);
        setCurrentSlot(undefined);
        setDate(monthsSelected);
    };

    const handleCurrentDay = (day: string) => {
        setCurrentDay(day);
        setCurrentSlot(undefined);
        setDate(day);
    };

    const handleClose = () => {
        setShowModal(false);
        initialize();
    };

    const onConfirmUpdate = () => {
        setLoading(true);

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
            confirmButtonText: Intl.formatMessage({ id: 'CONFIRMATION' }),
            showCloseButton: true,
        });

        updateOrder(parseInt(params.id, 10), {
            action: 'updated',
            appointment: {
                dateStart: `${dayjs(date).format('YYYY-MM-DD')} ${currentSlot || ''}:00`,
            },
            orderStatusReasonId: 0,
        }).then(() => {
            setShowModal(false);
            swalWithBootstrapButtons.fire(
                `<i class="las la-check-circle fa-2x text-primary"></i> <br /> ${Intl.formatMessage({
                    id: 'MANUFACTURER.ORDER.MODAL.UPDATE.CONFIRM_TITLE',
                })}`,
                Intl.formatMessage({ id: 'MANUFACTURER.ORDER.MODAL.UPDATE.CONFIRM_TXT' }, { order: order?.reference }),
            );
            dispatch(fetchOrder(params.id));
        });
    };

    const isUpdateDisabled = !currentMonth || !currentDay || !currentSlot;

    return (
        <ModalDefault
            show={showModal}
            loading={loading}
            onEnter={loadModalContent}
            hideModal={handleClose}
            icon={<i className="las la-wrench la-2x text-primary" />}
            title={Intl.formatMessage({ id: 'APV.ORDER.VIEW.MODAL.UPDATE' })}
            body={
                <div className="px-5">
                    <div className="mt-4 mb-3">
                        <Months months={months} currentMonth={currentMonth || ''} onSelect={handleCurrentMonth} />
                    </div>
                    <hr />
                    <div className="mt-4 mb-3">
                        <Days days={days} currentDay={currentDay || ''} onSelect={handleCurrentDay} />
                    </div>
                    <hr />
                    <div className="mt-4 mb-3">
                        <Slots
                            slots={slots}
                            currentSlot={currentSlot || ''}
                            onSelect={setCurrentSlot}
                            unclickable={currentDay === ''}
                        />
                    </div>
                </div>
            }
            footer={
                <div className="d-flex justify-content-center w-100">
                    <Button
                        variant="outline-secondary"
                        className="mr-3"
                        style={{ width: '100px' }}
                        onClick={handleClose}
                    >
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button
                        variant="primary"
                        className="position-relative"
                        style={{ width: '100px' }}
                        onClick={onConfirmUpdate}
                        disabled={isUpdateDisabled}
                    >
                        {Intl.formatMessage({ id: 'TRANSLATOR.UPDATE' })}
                    </Button>
                </div>
            }
        />
    );
}
