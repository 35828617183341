import React from "react";
import {FormattedHTMLMessage} from "react-intl";
import useCounter from "../../../app/hooks/useCounters";

const MenuItemText = ({item, parentItem}) => {
    const counter = useCounter(item?.counter?.id);

    const params = {
        counter
    };

    return (
      <>
        {item.icon ? (
            <i className={`kt-menu__link-icon ${item.icon}`} />
        ) : item.svg ? (
            <span className="kt-menu__link-icon">
                <item.svg />
            </span>
        ) : (
            <></>
        )}

        {parentItem && parentItem.bullet === "dot" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
            <span />
          </i>
        )}

        {parentItem && parentItem.bullet === "line" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
            <span />
          </i>
        )}

        <span className="kt-menu__link-text" style={{ whiteSpace: "nowrap" }}>
          {!item.translate ? (
            item.title
          ) : (
            <FormattedHTMLMessage id={item.translate} values={params}/>
          )}

        </span>

        {item.badge && (
          <span className="kt-menu__link-badge">
            <span className={`kt-badge ${item.badge.type}`}>
              {item.badge.value}
            </span>
          </span>
        )}

        {item.submenu && <i className="kt-menu__ver-arrow la la-angle-right" />}
      </>
    );
}

export default MenuItemText;
