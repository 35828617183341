export const actionTypes = {
    AddApplication: 'ADD_APPLICATION',
    AddApplicationFailed: 'ADD_APPLICATON_FAILED',
    AddedApplication: 'ADDED_APPLICATON',
    UpdateApplication: 'UPDATE_APPLICATION',
    UpdateApplicationFailed: 'UPDATE_APPLICATION_FAILED',
    UpdatedApplication: 'UPDATED_APPLICATION',
    FetchApplication: 'FETCH_APPLICATION',
    FetchApplicationFailed: 'FETCHED_FAILED_APPLICATION',
    FetchedApplication: 'FETCHED_APPLICATION',
};

const initialState = {
    loading: false,
    application: null,
};

export const autoUserApplicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FetchApplication: {
            return {
                ...state,
                loading: true,
            };
        }
        case actionTypes.FetchedApplication:
            return {
                ...state,
                application: action.payload?.result?.application,
                loading: false,
            };
        case actionTypes.FetchApplicationFailed: {
            return initialState;
        }
        default:
            return state;
    }
};

export const actions = {
    addApplication: () => ({ type: actionTypes.AddApplication }),
    addedApplication: (result) => ({ type: actionTypes.AddedApplication, payload: result }),
    addApplicationFailed: (error) => ({ type: actionTypes.AddApplicationFailed, payload: error }),
    fetchApplication: (data) => ({ type: actionTypes.FetchApplication, payload: { data } }),
    fetchApplicationFailed: () => ({ type: actionTypes.FetchApplicationFailed }),
    fetchedApplication: (result) => ({ type: actionTypes.FetchedApplication, payload: { result } }),
    updateApplication: () => ({ type: actionTypes.UpdateApplication }),
    updateApplicationFailed: (error) => ({ type: actionTypes.UpdateApplicationFailed, payload: error }),
    updatedApplication: (result) => ({ type: actionTypes.UpdatedApplication, payload: result }),
};
