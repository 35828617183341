import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import LeadLooseDefault from './LeadLooseDefault';
import LeadLooseReprise from './LeadLooseReprise';

const LeadLooseLocal = ({ params, leadReasons, lead }) => {
    const [leadType, setLeadType] = useState(null);
    const Intl = useIntl();

    return (
        <>
            <Form.Group>
                <Form.Label>{Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.TYPE' })}</Form.Label>
                <Form.Control
                    as="select"
                    name="leadType"
                    readOnly="readonly"
                    defaultValue=""
                    onChange={(e) => {
                        setLeadType(e.target.value);
                    }}
                >
                    {['VO / VD / VN  ', 'Reprise'].map((type) => (
                        <option key={type} value={type.toLowerCase()}>
                            {type}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
            {leadType === 'reprise' ? (
                <LeadLooseReprise params={params} leadReasons={leadReasons} lead={lead} />
            ) : (
                <LeadLooseDefault params={params} leadReasons={leadReasons} lead={lead} />
            )}
        </>
    );
};

export default LeadLooseLocal;
