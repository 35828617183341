import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { TreeSelect } from '@app/partials/content/Tree';

interface TreeSelectNode {
    label: string;
    id: string;
    checked?: boolean;
    _children?: string[];
}

interface Filters {
    q?: { value: string };
    roles?: { value: string[] };
}

interface RolesSelectProps {
    roles: { [key: string]: string };
    filters: Filters;
    handleFilter: (f: Filters) => void;
}
const RolesSelect = React.forwardRef(({ roles, filters, handleFilter }: RolesSelectProps, forwardedRef) => {
    const Intl = useIntl();
    const selectRoles = useMemo(() => {
        const nodes: TreeSelectNode[] = [];
        const neverSelected = !filters?.roles;
        const filteredRoles = filters?.roles?.value || [];
        Object.entries(roles)
            .sort((a, b) => a[1].localeCompare(b[1]))
            .forEach(([key, value]) => {
                nodes.push({
                    label: value,
                    id: key,
                    checked: filteredRoles.includes(key) || neverSelected,
                });
            });
        return {
            label: Intl.formatMessage({ id: 'AUTOUSER.ROLES.SELECT_ALL' }),
            id: '0',
            expanded: true,
            checked: Object.keys(roles).length === filteredRoles.length || neverSelected,
            children: nodes,
        };
    }, [roles]);

    const onChange = (currentNode: TreeSelectNode, selectedNodes: TreeSelectNode[]) => {
        let selectedRoles = selectedNodes.map((selectedNode) => selectedNode.id);
        if (currentNode.id === '0') {
            selectedRoles = currentNode.checked ? currentNode._children : [];
        }
        handleFilter({
            roles: {
                value: selectedRoles,
            },
        });
    };

    return (
        <TreeSelect
            // @ts-ignore
            data={selectRoles}
            ref={forwardedRef}
            onChange={onChange}
        />
    );
});

export default RolesSelect;
