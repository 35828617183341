import React from 'react';
import ReactDOM from "react-dom";

const JoditPopup = (editor, children) => {
    const wrapper = editor.create.fromHTML('<div></div>');
    ReactDOM.render(children, wrapper);
    return wrapper;
};

export default JoditPopup;
