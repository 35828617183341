import ApiGateway from '@app/helpers/AxiosHelper';

const { REACT_APP_SMART_REPAIR_URL } = process.env;

export function getQuotations(params) {
    return ApiGateway.get(`${REACT_APP_SMART_REPAIR_URL}/api/order`, { params });
}

export function getQuotation(id) {
    return ApiGateway.get(`${REACT_APP_SMART_REPAIR_URL}/api/order/${id}`);
}

export function getPrices(params) {
    return ApiGateway.get(`${REACT_APP_SMART_REPAIR_URL}/api/price`, { params });
}

export function getPrice(id) {
    return ApiGateway.get(`${REACT_APP_SMART_REPAIR_URL}/api/price/${id}`);
}

export function addPrice(data) {
    return ApiGateway.post(`${REACT_APP_SMART_REPAIR_URL}/api/price`, data);
}

export function updatePriceById(id, data) {
    return ApiGateway.post(`${REACT_APP_SMART_REPAIR_URL}/api/price/${id}`, data);
}

export function deletePrice(id) {
    return ApiGateway.delete(`${REACT_APP_SMART_REPAIR_URL}/api/price/${id}`);
}

export function getZonesWithChildren(params) {
    return ApiGateway.get(`${REACT_APP_SMART_REPAIR_URL}/api/zone`, { params: { ...params, with: 'zoneBodys' } });
}
export function setIncompatibleOrder(id, data) {
    return ApiGateway.put(`${REACT_APP_SMART_REPAIR_URL}/api/order/${id}/action/expert_incompatible`, {
        ...data,
    });
}

export function setExpertSendQuote(id, data) {
    return ApiGateway.put(`${REACT_APP_SMART_REPAIR_URL}/api/order/${id}/action/expert_send_quote`, {
        ...data,
    });
}

export function setOrderRequestComplement(id, data) {
    return ApiGateway.put(`${REACT_APP_SMART_REPAIR_URL}/api/order/${id}/action/expert_request_complement`, {
        ...data,
    });
}

export async function getQuotationToBeAppraisedCounter() {
    const { result } = await ApiGateway.get(`${REACT_APP_SMART_REPAIR_URL}/api/indicator/to_be_appraised`);
    return result?.to_be_appraised;
}
export async function getQuotationWaitingCustomerCounter() {
    const { result } = await ApiGateway.get(`${REACT_APP_SMART_REPAIR_URL}/api/indicator/waiting_for_customer`);
    return result?.waiting_for_customer;
}
export async function getQuotationClosedCounter() {
    const { result } = await ApiGateway.get(`${REACT_APP_SMART_REPAIR_URL}/api/indicator/closed`);
    return result?.closed;
}

export async function getQuotationAppointmentInProgressCounter() {
    const { result } = await ApiGateway.get(`${REACT_APP_SMART_REPAIR_URL}/api/indicator/appointment/in_progress`);
    return result?.in_progress;
}
