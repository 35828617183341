import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Locale } from '@src/_metronic/i18n/I18nProvider';

const AlreadyExistUserRow = ({ customer }) => {
    const mobile = parsePhoneNumberFromString(customer?.mobile || '', Locale.toUpperCase());
    const phone = parsePhoneNumberFromString(customer?.phone || '', Locale.toUpperCase());

    return (
        <>
            <div className="d-flex mb-2">
                {customer?.pro || customer?.isPro ? (
                    <div className="btn-status btn-status--pro font-weight-bold">
                        <FormattedMessage id="CUSTOMER.VIEW.INFO.STATUS.PRO" />
                    </div>
                ) : (
                    <div className="btn-status btn-status--customer font-weight-bold">
                        <FormattedMessage id="CUSTOMER.VIEW.INFO.STATUS.CUSTOMER" />
                    </div>
                )}
            </div>
            {(customer?.companyName || customer?.pro?.company_name) && (
                <div className="d-flex align-items-start font-weight-bold mb-1">
                    <i className="mr-2 text-secundary las la-building" />
                    {customer?.companyName || ''} {customer?.pro?.company_name || ''}
                </div>
            )}
            <div className="d-flex align-items-start font-weight-bold">
                <i className="mr-2 text-secundary las la-user" />
                {customer?.lastname || ''} {customer?.firstname || ''}
            </div>
            {(mobile || phone) && (
                <div className="mt-1 d-flex align-items-start">
                    <i className="mr-2 las la-phone" />
                    {mobile?.formatNational?.() || phone?.formatNational?.() || ''}
                </div>
            )}
            {customer?.email && (
                <div className="mt-1 d-flex align-items-start">
                    <i className="mr-2 las la-envelope" />
                    {customer?.email}
                </div>
            )}
            {(customer?.address || customer?.zipcode || customer?.city) && (
                <div className="mt-1  d-flex align-items-start">
                    <i className="mr-2 las la-map-marker" />
                    <div className="">
                        {customer?.address && (
                            <>
                                {customer?.address || ''}
                                <br />
                            </>
                        )}
                        {customer?.zipcode || ''} {customer?.city || ''}
                    </div>
                </div>
            )}
        </>
    );
};

export default AlreadyExistUserRow;
