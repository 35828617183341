import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { sleep } from '@app/helpers/ToolsHelper';

import {
    campaignAssignContact,
    campaignContactClosing,
    getCampaignNotAssignedContact,
    getCampaignNotCloseContact,
    updateCampaign,
} from '@app/crud/campaigns/campaign.crud';

import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

import {
    CONTACTS_STEP,
    contactsHasValidReconciliation,
    DATE_STEP,
    DEALERS_STEP,
    dealershipsHasValidAssignments,
    operationHasValidSettings,
    SETTINGS_STEPS,
} from '../helpers/CampaignHelper';
import OperationProvider from '../OperationProvider';
import OperationAssignments from '../partials/Operation/OperationAssignments';
import OperationDate from '../partials/Operation/OperationDate';
import OperationReconciliation from '../partials/Operation/OperationReconciliation';

const OperationSettings = ({ showModal, setShowModal, campaign, statistics }) => {
    const [operationState, setOperationState] = useState({
        dateStart: campaign?.dateStart,
        duration: campaign?.duration,
        type: campaign?.campaignType?.slug,
        oldContact: statistics?.oldContact,
        newContact: statistics?.newContact,
        withoutContact: statistics?.withoutContact,
        ignoredContact: statistics?.ignoredContact,
        withAssignment: statistics?.assignedContact,
        withoutAssignment: statistics?.notAssignedContact,
    });
    const [currentStep, setCurrentStep] = useState(DATE_STEP);
    const [loading, setLoading] = useState(false);
    const { userData } = useSelector((state) => state.auth);

    const BODY_STEPS = {
        [DATE_STEP]: <OperationDate />,
        [CONTACTS_STEP]: <OperationReconciliation />,
        [DEALERS_STEP]: <OperationAssignments statistics={statistics} />,
    };

    const stepIsValid = (step = undefined) => {
        const validators = {
            [DATE_STEP]: operationHasValidSettings(operationState),
            [CONTACTS_STEP]: contactsHasValidReconciliation(operationState),
            [DEALERS_STEP]: dealershipsHasValidAssignments(operationState),
        };

        if (step !== undefined) {
            const isPreviousStep = step < currentStep;
            const isLastStep = step === DEALERS_STEP && currentStep === DEALERS_STEP;

            return validators?.[step] && (isPreviousStep || isLastStep);
        }

        return validators?.[currentStep];
    };

    const stepProgress = () => {
        if (currentStep === DEALERS_STEP) {
            setShowModal(false);
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    const dateStepAction = () => {
        const dateStart = dayjs(operationState?.dateStart).isBefore(dayjs().add(2, 'days'))
            ? dayjs().add(2, 'days')
            : dayjs(operationState?.dateStart);
        if (
            dateStart.format('YYYY-MM-DD HH:mm:ss') !== dayjs(campaign?.dateStart).format('YYYY-MM-DD HH:mm:ss') ||
            operationState?.duration !== campaign?.duration ||
            operationState?.type !== campaign?.campaignType?.slug
        ) {
            updateCampaign(campaign.id, {
                dateStart: dateStart.format('YYYY-MM-DD HH:mm:ss'),
                duration: operationState?.duration ?? 30,
                campaignType: operationState?.type,
                user: {
                    id: userData.id,
                    firstname: userData.first_name,
                    lastname: userData.last_name,
                    email: userData.email,
                },
            })
                .then(() => {
                    stepProgress();
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'CAMPAIGN.UPDATE.MESSAGE.SUCCESS' }),
                    });
                    setLoading(false);
                    return true;
                })
                .catch(() => {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'CAMPAIGN.UPDATE.MESSAGE.FAILED' }),
                    });
                    return false;
                });
        } else {
            stepProgress();
            return true;
        }
    };

    const contactStepAction = async () => {
        let contactQtyDone = 0;
        const contactQtyToDo =
            (operationState?.contactToClose?.length || 0) +
            (operationState?.contactToIgnore?.length || 0) +
            (operationState?.contactToCreate?.length || 0);

        const limit = 200;
        let it = 0;

        let ajaxInProgress = false;
        do {
            const contactsData = [];
            if (!ajaxInProgress) {
                ajaxInProgress = true;
                if (operationState?.contactToClose) {
                    for (const [key, c] of Object.entries(operationState?.contactToClose).slice(
                        it * limit,
                        it * limit + limit,
                    )) {
                        contactsData.push({
                            campaignContactId: key,
                            contactId: c.id || null,
                            vcuId: c.vcuId || null,
                        });
                    }
                }
                const contactsToCreate = operationState?.contactToCreate?.slice(it * limit, it * limit + limit);
                const contactsToIgnore = operationState?.contactToIgnore?.slice(it * limit, it * limit + limit);

                if (contactsData?.length > 0 || contactsToIgnore?.length > 0 || contactsToCreate?.length > 0) {
                    campaignContactClosing(campaign.id, {
                        contacts: contactsData,
                        ignore: contactsToIgnore,
                        create: contactsToCreate,
                    })
                        .then(() => {
                            ajaxInProgress = false;
                            contactQtyDone +=
                                (contactsData.length || 0) +
                                (contactsToIgnore?.length || 0) +
                                (contactsToCreate?.length || 0);
                        })
                        .catch(() => {
                            toast({
                                variant: 'danger',
                                message: Intl.formatMessage({ id: 'CAMPAIGN.UPDATE.MESSAGE.FAILED' }),
                            });
                            return false;
                        });
                } else {
                    break;
                }
                ++it;
            }

            await sleep(500);
        } while (contactQtyDone < contactQtyToDo);
        setLoading(false);
        stepProgress();
        return true;
    };

    const dealerStepAction = async () => {
        const assignmentData = [];

        if (operationState?.affectedDealers) {
            for (const [_key, dealershipData] of Object.entries(operationState?.affectedDealers || {}) || []) {
                for (const [contactId, dealerId] of Object.entries(dealershipData) || []) {
                    assignmentData.push({
                        campaignContactId: parseInt(contactId, 10),
                        userId: dealerId,
                    });
                }
            }
        }

        if (operationState?.selectedDealers) {
            for (const [dealershipId, dealers] of Object.entries(operationState?.selectedDealers) || []) {
                const dealership = operationState?.dealerships?.[dealershipId];
                const dealershipContacts = dealership?.contacts?.filter((c) => !c?.dealer) || [];
                const contactCount = dealershipContacts?.length;
                const dealersSelectedCount = dealers?.length || 1;

                const quantityPerDealers = Math.floor(contactCount / dealersSelectedCount);
                let remainders = contactCount % dealersSelectedCount;

                for (const [_key, dealer] of Object.entries(dealers) || []) {
                    const quantity = quantityPerDealers + (remainders-- > 0 ? 1 : 0);
                    for (let i = 0; i < quantity; i++) {
                        const contact = dealershipContacts.shift();
                        assignmentData.push({
                            campaignContactId: contact?.campaignContactId,
                            userId: dealer,
                        });
                    }
                }
            }
        }
        if (assignmentData?.length > 0) {
            const qtyTodo = assignmentData?.length;
            const limit = 300;
            let it = 0;
            let qtyDone = 0;

            let ajaxInProgress = false;
            do {
                if (!ajaxInProgress) {
                    ajaxInProgress = true;
                    const assignmentDataPart = assignmentData.slice(limit * it, limit * it + limit);

                    if (assignmentDataPart.length <= 0) {
                        break;
                    }
                    campaignAssignContact(campaign.id, {
                        assignments: assignmentDataPart,
                        user: {
                            id: userData.id,
                            firstname: userData.first_name,
                            lastname: userData.last_name,
                            email: userData.email,
                        },
                    }).catch(() => {
                        toast({
                            variant: 'danger',
                            message: Intl.formatMessage({ id: 'CAMPAIGN.UPDATE.MESSAGE.FAILED' }),
                        });
                        return false;
                    });

                    qtyDone += assignmentData?.slice(limit * it, limit * it + limit)?.length;
                    ajaxInProgress = false;

                    ++it;
                } else {
                    await sleep(500);
                }
            } while (qtyDone < qtyTodo);
        }

        await campaignAssignContact(campaign.id, {
            finished: true,
            user: {
                id: userData.id,
                firstname: userData.first_name,
                lastname: userData.last_name,
                email: userData.email,
            },
        });

        stepProgress();
        setLoading(false);
        return true;
    };

    const stepAction = (step = undefined) => {
        setLoading(true);
        const actions = {
            [DATE_STEP]: dateStepAction,
            [CONTACTS_STEP]: contactStepAction,
            [DEALERS_STEP]: dealerStepAction,
        };

        if (step !== undefined && actions?.[currentStep]) {
            return actions?.[currentStep]();
        }
    };

    useEffect(() => {
        if (currentStep === CONTACTS_STEP) {
            setLoading(true);
            getCampaignNotCloseContact(campaign.id)
                .then(({ result }) => {
                    setOperationState({
                        ...operationState,
                        notCloseContact: result,
                    });
                    setLoading(false);
                })
                .catch(() => {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'CAMPAIGN.UPDATE.MESSAGE.FAILED' }),
                    });
                });
        } else if (currentStep === DEALERS_STEP) {
            setLoading(true);
            getCampaignNotAssignedContact(campaign.id)
                .then(({ result, statistics }) => {
                    setOperationState({
                        ...operationState,
                        dealersContact: result?.dealers,
                        dealerships: result?.dealerships,
                        withAssignment: statistics?.assignedContact,
                        withoutAssignment: statistics?.notAssignedContact,
                        ignoredContact: statistics?.ignoredContact,
                    });
                    setLoading(false);
                })
                .catch(() => {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'CAMPAIGN.UPDATE.MESSAGE.FAILED' }),
                    });
                });
        }
    }, [currentStep]);

    return (
        <ModalDefault
            show={showModal}
            hideModal={() => {
                setShowModal(false);
            }}
            bodyClassName="campaign-settings"
            title={
                <div className="campaign-timeline campaign-timeline--settings">
                    {SETTINGS_STEPS.map((step, idx) => {
                        const validStep = stepIsValid(step?.state);

                        return (
                            <div
                                key={step.state}
                                className={classNames('campaign-timeline__item', {
                                    'campaign-timeline__item--active': validStep,
                                    'campaign-timeline__item--current': idx !== 0 && idx === currentStep && !validStep,
                                })}
                            >
                                <div className="campaign-timeline__connector" />
                                <div className="campaign-timeline__badge">
                                    <i className={`las ${step?.icon} campaign-timeline__icon la-xl`} />
                                </div>
                                <div className="campaign-timeline__content mt-2 font-weight-normal font-size-sm text-center">
                                    {step?.name}
                                </div>
                            </div>
                        );
                    })}
                </div>
            }
            body={
                loading ? (
                    <Loader style={{ width: '5rem', height: '5rem' }} overlay />
                ) : (
                    <OperationProvider value={{ operationState, setOperationState }}>
                        {BODY_STEPS[currentStep]}
                    </OperationProvider>
                )
            }
            footer={
                <div className="w-100 d-flex justify-content-between">
                    <div>
                        {currentStep !== DATE_STEP && (
                            <Button
                                variant="outline-secondary"
                                onClick={() => {
                                    setCurrentStep(currentStep - 1);
                                }}
                            >
                                <FormattedMessage id="TRANSLATOR.PREVIOUS" />
                            </Button>
                        )}
                    </div>
                    <div>
                        <Button
                            variant="outline-secondary"
                            onClick={() => {
                                setShowModal(false);
                            }}
                            disabled={loading}
                            className="mr-3"
                        >
                            <FormattedMessage id="TRANSLATOR.CANCEL" />
                        </Button>
                        <Button
                            variant="primary"
                            disabled={!stepIsValid() || loading}
                            onClick={() => {
                                stepAction(currentStep, operationState);
                            }}
                        >
                            {currentStep === DEALERS_STEP ? (
                                <FormattedMessage id="TRANSLATOR.VALIDATE" />
                            ) : (
                                <FormattedMessage id="TRANSLATOR.NEXT" />
                            )}
                        </Button>
                    </div>
                </div>
            }
        />
    );
};

export default OperationSettings;
