import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

export const DATE_STEP = 0;
export const CONTACTS_STEP = 1;
export const DEALERS_STEP = 2;

export const LINKED = 'linked';
export const CREATED = 'created';
export const TO_LINK = 'to_link';

export const ASSIGNED = 'assigned';
export const TO_ASSIGN = 'to_assign';

export const EQUAL_ASSIGN = 'equal_assign';
export const MANUAL_ASSIGN = 'manual_assign';

export const CONFIG_REQUIRED_STATUS = 'config_required';
export const SCHEDULED_STATUS = 'scheduled';
export const WAITING_BLOCTEL_STATUS = 'waiting_bloctel';
export const BLOCTEL_OK_STATUS = 'bloctel_ok';
export const LAUNCHED_STATUS = 'launched';
export const CANCELED_STATUS = 'canceled';
export const FINISHED_STATUS = 'finished';
export const BLOCTEL_PROCESSED_STEP_ORDER = 3;
export const LAUNCHED_PROCESSED_STEP_ORDER = 5;

/**
 * Modal Settings steps
 * @type {[{name: JSX.Element, icon: string}, {name: JSX.Element, icon: string}, {name: JSX.Element, icon: string}]}
 */
export const SETTINGS_STEPS = [
    {
        name: <FormattedHTMLMessage id="CAMPAIGN.MODALS.SETTINGS.STEP.DATE" />,
        icon: 'la-calendar',
        state: DATE_STEP,
    },
    {
        name: <FormattedHTMLMessage id="CAMPAIGN.MODALS.SETTINGS.STEP.CONTACTS" />,
        icon: 'la-user-friends',
        state: CONTACTS_STEP,
    },
    {
        name: <FormattedHTMLMessage id="CAMPAIGN.MODALS.SETTINGS.STEP.DEALERS" />,
        icon: 'la-bullseye',
        state: DEALERS_STEP,
    },
];

/**
 * Get status color
 *
 * @param slug
 * @returns {string}
 */
export const getStatusColor = (slug) => {
    const colors = {
        scheduled: 'primary',
        config_required: 'danger',
        waiting_bloctel: 'warning',
        bloctel_ok: 'primary',
        launched: 'primary',
        canceled: 'dark',
        finished: 'success',
    };

    return colors[slug] || 'primary';
};

/**
 * Get source color
 *
 * @param source
 * @returns {string}
 */
export const getActionSourceColor = (source) => {
    const colors = {
        marketing: 'closed',
    };

    return colors[source] || 'primary';
};

/**
 * Get user source color
 *
 * @param source
 * @returns {string}
 */
export const getUserSourceColor = (source) => {
    const colors = {
        salesforce: 'primary',
    };

    return colors[source] || 'primary';
};

/**
 * Check if operation has valid settings
 *
 * @param state
 * @returns {boolean | undefined}
 */
export const operationHasValidSettings = (state) => {
    const values = ['dateStart', 'type'];

    return values?.every?.((value) => !!state?.[value]);
};

export const getReconciliationCounter = ({
    withoutContact,
    contactToClose,
    contactToCreate,
    contactToIgnore,
    ignoredContact,
}) => {
    const contactToCloseLength = contactToClose ? Object.keys(contactToClose)?.length : 0;
    const counterFromStats = withoutContact || 0;
    const counterFromContext = contactToCloseLength + (contactToCreate?.length || 0) + (contactToIgnore?.length || 0);

    return counterFromStats - counterFromContext;
};

/**
 * Check if contacts has valid reconciliation
 *
 * @param state
 * @returns {boolean}
 */
export const contactsHasValidReconciliation = (state) => getReconciliationCounter(state) === 0;

/**
 * Check dealership assignments quantity to do
 *
 * @param dealershipId
 * @param state
 * @returns {number}
 */
export const dealershipAssignmentsTodo = (dealershipId, state) => {
    if (!state?.dealerships?.[dealershipId]?.name) {
        return 0;
    }
    return state?.dealerships[dealershipId]?.contacts?.filter((c) => !c?.dealer).length || 0;
};

/**
 * Check dealership assignments quantity done
 *
 * @param dealershipId
 * @param state
 * @returns {number}
 */
export const dealershipAssignmentsDone = (dealershipId, state) => {
    if (!state?.dealerships?.[dealershipId]?.name) {
        return 0;
    }
    const selectedDealersCount = state?.selectedDealers?.[dealershipId]?.length || 0;

    return selectedDealersCount > 0
        ? dealershipAssignmentsTodo(dealershipId, state)
        : Object.values(state?.affectedDealers?.[dealershipId] || {})?.length || 0;
};

/**
 * Check if dealership has valid assignments
 *
 * @param dealershipId
 * @param state
 * @returns {boolean}
 */
export const dealershipHasValidAssignments = (dealershipId, state) =>
    dealershipAssignmentsTodo(dealershipId, state) - dealershipAssignmentsDone(dealershipId, state) === 0;

/**
 * Check if dealerships has assignments to do
 *
 * @returns {number}
 * @param state
 */
export const dealershipsAssignmentsTodo = (state) =>
    Object.keys(state?.dealerships || {})?.reduce(
        (previous, key) => previous + dealershipAssignmentsTodo(key, state),
        0,
    );

/**
 * Check if dealerships has assignments done
 *
 * @returns {number}
 * @param state
 */
export const dealershipsAssignmentsDone = (state) =>
    Object.keys(state?.dealerships || {})?.reduce(
        (previous, key) => previous + dealershipAssignmentsDone(key, state),
        0,
    );

/**
 * Check if dealerships has valid assignments
 *
 * @returns {boolean}
 * @param state
 */
export const dealershipsHasValidAssignments = (state) =>
    dealershipsAssignmentsTodo(state) - dealershipsAssignmentsDone(state) === 0;

/**
 * Check if campaign his in final state
 *
 * @returns {boolean}
 * @param status
 */
export const campaignIsInFinalState = (status) =>
    [
        SCHEDULED_STATUS,
        WAITING_BLOCTEL_STATUS,
        BLOCTEL_OK_STATUS,
        LAUNCHED_STATUS,
        CANCELED_STATUS,
        FINISHED_STATUS,
    ].includes(status?.slug);

/**
 * Check if campaign is after bloctel process step
 *
 * @returns {boolean}
 * @param status
 */
export const campaignBloctelProcessed = (status) => status?.stepOrder > BLOCTEL_PROCESSED_STEP_ORDER;

/**
 * Check if campaign is after bloctel process step
 *
 * @returns {boolean}
 * @param status
 */
export const campaignDisplaySynthesis = (status) => status?.stepOrder > BLOCTEL_PROCESSED_STEP_ORDER;

/**
 * Check if campaign is after bloctel process step
 *
 * @returns {boolean}
 * @param status
 */
export const campaignDisplayStats = (status) => status?.stepOrder > LAUNCHED_PROCESSED_STEP_ORDER;
