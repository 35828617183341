import classNames from 'classnames';
import { Divider } from 'primereact/divider';
import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import DashboardPerfState from './DashboardPerfState';

const DashboardLocalPerf = ({ data, className }) => {
    if (!Object.entries(data || {})?.length) {
        return null;
    }

    const includedTypes = ['local'];
    const details = Object.entries(data).filter(([type]) => includedTypes.some((r) => type.startsWith(r)));

    const customOrder = ['local_reprise', 'local_vd', 'local_vn', 'local_vo'];

    details.sort((a, b) => {
        const aIndex = customOrder.indexOf(a[0]) ?? 100;
        const bIndex = customOrder.indexOf(b[0]) ?? 100;
        return bIndex - aIndex;
    });

    return (
        <div className={classNames('dashboard-online-perf', className)}>
            <div className="font-size-lg font-weight-bold d-flex align-items-center">
                <i className="dashboard-general-perf__icon las la-2x la-building mr-2" />
                <FormattedMessage id="DASHBOARD.LOCAL_PERF.TITLE" />
            </div>
            {details?.length > 0 && (
                <div className="d-flex flex-lg-nowrap flex-wrap justify-content-between dashboard-online-perf__list">
                    {details.map(([leadType, detail]) => (
                        <div key={detail?.name} className="dashboard-online-perf__item p-0 mt-2">
                            <div className="d-inline-block">
                                <div className="font-weight-bold font-size-lg">{detail?.name}</div>
                                <Divider className="my-2" />
                                <div>
                                    <strong>{detail?.created}</strong>{' '}
                                    <FormattedMessage id="DASHBOARD.ONLINE_PERF.CREATED" />
                                    &nbsp;&bull;&nbsp;
                                    <strong>{detail?.taken}</strong>{' '}
                                    <FormattedMessage id="DASHBOARD.ONLINE_PERF.TAKEN" />
                                </div>
                                <DashboardPerfState detail={detail} leadType={leadType} />
                                {/* hide until we get the right data for this section - cf: AMNG-2454 */}
                                {/* <div>
                                    <strong>
                                        {getTransformation(detail?.wonStrong, 0, detail?.takenStrong)}
                                        &nbsp;% <FormattedMessage id="DASHBOARD.ONLINE_PERF.TRANSFORMATION" />
                                    </strong>
                                    {` `}
                                    <FormattedMessage id="DASHBOARD.ONLINE_PERF.STRONG" />
                                    <span className="font-size-xs font-weight-bold">&nbsp;({detail?.wonStrong})</span>
                                </div> */}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

DashboardLocalPerf.Skeleton = () => (
    <Skeleton borderRadius="10px" className="d-flex flex-column flex-lg-grow-1 mt-lg-2 mt-6 mr-2 p-15" />
);
export default DashboardLocalPerf;
