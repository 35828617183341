import React from 'react';
import { Card } from 'react-bootstrap';

import OrderTable from './OrderTable';

const Orders = () => (
    <Card>
        <Card.Body>
            <OrderTable />
        </Card.Body>
    </Card>
);

export default Orders;
