import React, { useEffect, useState } from 'react';
import { Tab } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';

import PortletTab from '@app/partials/layout/PortletTab';

import FluidityTable from './FluidityTable';

const Fluidities = () => {
    const { status } = useParams();
    const [activeKey, setActiveKey] = useState(status);
    const Intl = useIntl();

    useEffect(() => {
        setActiveKey(status);
    }, [setActiveKey, status]);

    return (
        <PortletTab
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onClick={setActiveKey}
            redirects={{
                in_progress: routeTo(ROUTES.FLUIDITIES.PATH, {
                    status: 'in_progress',
                }),
                close: routeTo(ROUTES.FLUIDITIES.PATH, {
                    status: 'close',
                }),
            }}
        >
            <Tab eventKey="in_progress" title={<>{Intl.formatMessage({ id: 'FLUIDITY.IN_PROGRESS' })}</>}>
                {activeKey === 'in_progress' && <FluidityTable counter="fluidity_in_progress" status="in_progress" />}
            </Tab>
            <Tab eventKey="close" title={<>{Intl.formatMessage({ id: 'FLUIDITY.CLOSED' })}</>}>
                {activeKey === 'close' && <FluidityTable status="close" />}
            </Tab>
        </PortletTab>
    );
};

export default Fluidities;
