import React from 'react';

const Overflow = ({maxHeight, children}) => {
    return (
        <div className="overflow" style={{
            maxHeight: `${maxHeight}px`
        }}>
            {children}
        </div>
    );
};

export default Overflow;
