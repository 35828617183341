import React from "react";

export default function Day({day, label, open, number, onSelect, selected}){
    return (
        <div onClick={() => onSelect(day)} className={`day ${selected ? 'day--selected' : ''} ${!open ? 'day--closed' : ''}`}>
            <div>{label}</div>
            <div>{number}</div>
        </div>
    )
}
